/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useState } from 'react'
import { font } from '../../../components/css'
import Icon from '../../../components/Icon'

import PersonCell from '../../../components/List/PersonCell'
import PlayerNumber from './PlayerNumber'
import RosterEditStatusButton from './RosterEditStatusButton'

const RosterEditRow = ({
  player,
  onPlayerChange,
  isEditing,
  setIsEditing,
  existingPlayerNums,
  playerTypes,
  startingGoalie,
  isStartingGoalie,
  isGenericGame = false,
  requireUniqueJerseyNumbers = true,
  goaliePositionId = null,
  allOtherStartingGoalies = [],
  onUpdateGoalieStarting = null,
  teamId = null,
}) => {
  const [isSaving, setIsSaving] = useState(false)

  const onStatusClick = async (playerId, settings) => {
    setIsSaving(true)
    try {
      const result = await onPlayerChange(playerId, settings)
      setIsSaving(false)
      return result
    } catch (e) {
      setIsSaving(false)
    }
  }

  return (
    <tr
      css={css`
        padding: 8px 0px;
        opacity: ${isSaving ? '0.7' : '1'};

        .name-cell {
          padding: 0 8px;
          text-align: left;
        }
      `}
    >
      <td className='name-cell'>
        <PersonCell
          item={player}
          subTitle={
            player.customer_identifier
              ? `ID #${player.customer_identifier}`
              : ''
          }
        />
      </td>
      <td>
        <PlayerNumber
          player={player}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          onPlayerChange={onPlayerChange}
          existingPlayerNums={existingPlayerNums}
          requireUniqueJerseyNumbers={requireUniqueJerseyNumbers}
        />
      </td>

      <td
        css={css`
          position: relative;

          &:hover {
            background-color: rgba(255, 255, 255, 0.1);

            i {
              color: white;
            }
          }
        `}
      >
        <select
          value={player.player_type.id}
          css={css`
            ${font.body}
            font-size: 14px;
            height: inherit;
            width: 100%;
            border: 0;
            color: white;
            padding-left: 10px;
            cursor: pointer;

            option {
              background-color: ${colors.HEADER};
            }
          `}
          onChange={async ({ target }) => {
            setIsSaving(true)
            try {
              await onPlayerChange(player.id, {
                player_type_id: target.value,
              })
            } finally {
              setIsSaving(false)
            }
          }}
        >
          {playerTypes.map((playerType) => {
            const isDisabled =
              player.is_starting && startingGoalie && playerType.is_goalie === 1

            return (
              <option
                key={playerType.id}
                value={playerType.id}
                disabled={isDisabled}
              >
                {playerType.name_full}
              </option>
            )
          })}
        </select>
        <Icon
          css={css`
            pointer-events: none;
            position: absolute;
            right: 10px;
            top: 18px;
            opacity: ${isEditing ? 0 : 1};
          `}
          name='pencil-alt'
          color={colors.DEFAULT_FLAIR}
        />
      </td>
      {player.is_suspended ? (
        <td
          colSpan={3}
          css={css`
            text-align: center;
            color: ${colors.FIERY_RED};
          `}
        >
          Suspended
        </td>
      ) : (
        <>
          {!isGenericGame ? (
            <RosterEditStatusButton
              isSaving={isSaving}
              onPlayerChange={onStatusClick}
              existingPlayerNums={existingPlayerNums}
              player={player}
              settingName='is_starting'
              startingGoalie={startingGoalie}
              isStartingGoalie={isStartingGoalie}
              goaliePositionId={goaliePositionId}
              requireUniqueJerseyNumbers={requireUniqueJerseyNumbers}
              allOtherStartingGoalies={allOtherStartingGoalies}
              onUpdateGoalieStarting={onUpdateGoalieStarting}
              teamId={teamId}
            />
          ) : null}
          <RosterEditStatusButton
            isSaving={isSaving}
            onPlayerChange={onStatusClick}
            existingPlayerNums={existingPlayerNums}
            player={player}
            settingName='is_playing'
            startingGoalie={startingGoalie}
            requireUniqueJerseyNumbers={requireUniqueJerseyNumbers}
          />
          <RosterEditStatusButton
            isSaving={isSaving}
            onPlayerChange={onStatusClick}
            existingPlayerNums={existingPlayerNums}
            player={player}
            settingName='is_injured'
            backgroundColor={colors.FIERY_RED}
            iconName='times'
            startingGoalie={startingGoalie}
            requireUniqueJerseyNumbers={requireUniqueJerseyNumbers}
          />
        </>
      )}
    </tr>
  )
}

export default RosterEditRow
