/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import req from '@sportninja/common/api/request'
import {
  addNodeUnderParent,
  insertNode,
  removeNodeAtPath,
} from '@nosferatu500/react-sortable-tree'
import colors from '@sportninja/common/constants/appColors'

import TextModal from '../../../components/Modal/TextModal'
import Icon from '../../../components/Icon'
import { FormButton } from '../../../components/Form/css'
import { Flex } from '../../../components/Layout'
import utils from './utils'
import DeleteModal from 'src/components/Modal/DeleteModal'
import { useState } from 'react'

const getRemovalMessage = (hasChildren, subtitle, error) => {
  if (hasChildren) {
    return 'Remove any child competitions or teams before removing this competition.'
  } else {
    let message = `Are you sure you want to remove this ${subtitle}?`
    if (subtitle === 'Team') {
      message =
        'WARNING! ' +
        message +
        ' Please note that this will also delete the teams roster that is linked to this competition'
    }
    if (error) {
      message += ` Error: ${error}`
    }
    return message
  }
}

const BaseButton = styled.button`
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  transition: opacity 0.2s ease-in-out;

  color: white;
  font-size: 18px;

  i {
    transition: color 0.15s ease-in-out;
  }

  &:not([disabled]):hover {
    i {
      color: white;
    }
  }
`

export const AddChildButton = ({
  disabled,
  clear,
  depthMetadata,
  path,
  setCursor,
  setSearchQuery,
  setTreeData,
}) => {
  return (
    <BaseButton
      disabled={disabled}
      css={css`
        margin-left: 8px;
      `}
      key='button-add-child'
      onClick={(e) => {
        e.stopPropagation()
        clear()

        setTreeData(
          (state) =>
            addNodeUnderParent({
              treeData: state,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey: utils.getNodeKey,
              newNode: {
                expanded: true,
                id: 'New',
                title: '',
                subtitle: depthMetadata[path.length]
                  ? depthMetadata[path.length].type
                  : '',
              },
              addAsFirstChild: false,
            }).treeData
        )

        setTimeout(() => {
          setCursor([...path, 'New'])
          setSearchQuery('New')
        })
      }}
    >
      <Icon
        faType='fas'
        name='folder-plus'
        color={colors.DEFAULT_FLAIR}
        fontSize={17}
        title='Add Child'
      />
    </BaseButton>
  )
}

export const AddTeamButton = ({
  isSibling,
  clear,
  disabled,
  lastTreeIndex,
  path,
  setCursor,
  setSearchQuery,
  setTreeData,
}) => {
  const newNode = {
    expanded: true,
    nodeType: 'Team',
    id: 'New',
    title: '',
    subtitle: 'Team',
  }

  return (
    <BaseButton
      disabled={disabled}
      css={css`
        margin-left: 8px;
        padding: 8px;
        margin-right: -8px;
      `}
      key='button-add-team'
      onClick={(e) => {
        e.stopPropagation()
        clear()

        setTreeData((state) =>
          isSibling
            ? insertNode({
                treeData: state,
                depth: 0,
                minimumTreeIndex: lastTreeIndex + 1,
                getNodeKey: utils.getNodeKey,
                newNode,
                addAsFirstChild: true,
              }).treeData
            : addNodeUnderParent({
                treeData: state,
                parentKey: path[path.length - 1],
                expandParent: true,
                getNodeKey: utils.getNodeKey,
                newNode,
                addAsFirstChild: false,
              }).treeData
        )

        setTimeout(() => {
          setCursor(isSibling ? ['New'] : [...path, 'New'])
          setSearchQuery('New')
        })
      }}
    >
      <Icon
        faType='fas'
        name='user-plus'
        color={colors.DEFAULT_FLAIR}
        title='Add Team'
        fontSize={16}
      />
    </BaseButton>
  )
}

export const DeleteButton = ({
  disabled,
  node,
  path,
  setTreeData,
  setCursor,
  setIsBusy,
  setSearchQuery,
}) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const hasChildren = node.children && node.children?.length > 0

  return (
    <DeleteModal
      title={hasChildren ? 'Remove Children First' : `Remove ${node.subtitle}`}
      message={getRemovalMessage(hasChildren, node.subtitle)}
      Button={({ ...props }) => {
        return (
          <BaseButton
            css={css`
              position: absolute;
              right: -26px;
              top: 13px;
              /* font-size: inherit; */
            `}
            key='button-delete'
            {...props}
          >
            <Icon
              fontSize={16}
              faType='far'
              name='trash'
              color='#E55C5C'
              title='Delete'
            />
          </BaseButton>
        )
      }}
      onDelete={async (shouldForce = false) => {
        const query = {}
        if (shouldForce) {
          query.force = 1
        }
        setCursor(null)
        setSearchQuery('')
        setIsBusy(true)
        try {
          const url = utils.isTeamNode(node)
            ? `/schedules/${node.scheduleId}/teams/${node.teamId}`
            : `/schedules/${node.id}`
          const response = await req(url, { method: 'DELETE' })

          // if (response.status >= 400) {
          //   console.log('caiu aqui??')
          //   throw new Error(response.status)
          // }
          setTreeData((state) =>
            removeNodeAtPath({
              treeData: state,
              path,
              getNodeKey: utils.getNodeKey,
            })
          )
        } catch (e) {
          console.error(e)
          throw e
        } finally {
          setIsBusy(false)
        }
      }}
    />
  )

  // return (
  //   <TextModal
  //     title={hasChildren ? 'Remove Children First' : `Remove ${node.subtitle}`}
  //     link={(toggleOpen) => {
  //       return (
  //         <BaseButton
  //           css={css`
  //             position: absolute;
  //             right: -26px;
  //             top: 13px;
  //             /* font-size: inherit; */
  //           `}
  //           key='button-delete'
  //           onClick={(e) => {
  //             e.preventDefault()
  //             e.stopPropagation()
  //             toggleOpen()
  //           }}
  //         >
  //           <Icon
  //             fontSize={16}
  //             faType='far'
  //             name='trash'
  //             color='#E55C5C'
  //             title='Delete'
  //           />
  //         </BaseButton>
  //       )
  //     }}
  //   >
  //     {(toggleOpen) => (
  //       <div>
  //         {hasChildren
  //           ? 'Remove any child competitions or teams before removing this competition.'
  //           : `${
  //               node.subtitle === 'Team' ? 'WARNING!' : ''
  //             } Are you sure you want to remove this ${node.subtitle}? ${
  //               node.subtitle === 'Team'
  //                 ? 'Please note that this will also delete the teams roster that is linked to this competition'
  //                 : ''
  //             }`}
  //         <Flex>
  //           <FormButton
  //             onClick={toggleOpen}
  //             css={css`
  //               margin-top: 20px;
  //             `}
  //           >
  //             {hasChildren ? 'Dismiss' : 'Cancel'}
  //           </FormButton>
  //           {!hasChildren && (
  //             <FormButton
  //               isSubmit
  //               onClick={async (e) => {
  //                 e.stopPropagation()
  //                 if (disabled) return toggleOpen()

  //                 setCursor(null)
  //                 setSearchQuery('')
  //                 setIsBusy(true)
  //                 try {
  //                   const url = utils.isTeamNode(node)
  //                     ? `/schedules/${node.scheduleId}/teams/${node.teamId}`
  //                     : `/schedules/${node.id}`
  //                   const response = await req(url, { method: 'DELETE' })

  //                   if (response.status >= 400) throw new Error(response.status)
  //                   setTreeData((state) =>
  //                     removeNodeAtPath({
  //                       treeData: state,
  //                       path,
  //                       getNodeKey: utils.getNodeKey,
  //                     })
  //                   )
  //                 } catch (e) {
  //                   alert(e.message)
  //                 } finally {
  //                   setIsBusy(false)
  //                 }
  //               }}
  //               css={css`
  //                 margin-top: 20px;
  //               `}
  //             >
  //               Remove
  //             </FormButton>
  //           )}
  //         </Flex>
  //       </div>
  //     )}
  //   </TextModal>
  // )
}
