/** @jsxImportSource @emotion/react */
import {
  ClockCircleOutlined,
  DownOutlined,
  RightOutlined,
  UserOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { formatCurrency, formatDate } from '@sportninja/common/utils/utils'
import { Alert, App, Image, Input, QRCode, Steps } from 'antd'
import { useContext, useEffect, useMemo, useState } from 'react'
import { EventCard } from 'src/components/RegistrationEventCard'
import { EventProgress } from 'src/components/RegistrationEventProgress'
import { RegistrationContext } from '../context/RegistrationContext'
import {
  PageTitle,
  buttonStyle,
  headingSixStyle,
  headingStyle,
  paragraphMediumStyle,
  paragraphSmallBoldStyle,
  paragraphSmallStyle,
} from './Main'
import { ROUTES } from '@sportninja/common/constants/app'
import { calculateDiscountAndFormatCurrency } from './New_TeamRegister'
import { weightSemiBold } from 'src/components/css'
import Text from 'src/components/ts-components/Text'

async function updateTeamLogo(teamId, base64) {
  const res = await req(`/teams/${teamId}/image`, {
    method: 'POST',
    body: base64,
  })

  return res
}

async function updatePlayerImage(teamId, playerId, base64) {
  const res = await req(`/teams/${teamId}/players/${playerId}/image`, {
    method: 'POST',
    body: base64,
  })

  return res
}

const NextContent = ({ title, content, route }) => {
  const [isOpened, setIsOpened] = useState(false)
  if (route) {
    return (
      <a
        href={route}
        target='_blank'
        rel='noreferrer'
        css={css`
          display: flex;
          padding: 8px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          border-radius: 8px;
          background: ${colors.NEUTRAL_700};
          flex-direction: column;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <div
          onClick={() => setIsOpened(!isOpened)}
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            cursor: pointer;
            @media (max-width: 768px) {
              width: 100%;
            }
          `}
        >
          {content ? (
            <>
              {isOpened ? (
                <DownOutlined
                  css={css`
                    color: ${colors.PRIMARY};
                  `}
                />
              ) : (
                <RightOutlined
                  css={css`
                    color: ${colors.PRIMARY};
                  `}
                />
              )}
            </>
          ) : (
            <RightOutlined
              css={css`
                color: ${colors.PRIMARY};
              `}
            />
          )}
          <p
            css={css`
              ${paragraphMediumStyle}
              color: ${colors.PRIMARY};
            `}
          >
            {title}
          </p>
        </div>
        {isOpened && content ? <>{content}</> : null}
      </a>
    )
  }
  return (
    <div
      css={css`
        display: flex;
        padding: 8px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: ${colors.NEUTRAL_700};
        flex-direction: column;
        @media (max-width: 768px) {
          width: 100%;
        }
      `}
    >
      <div
        onClick={() => setIsOpened(!isOpened)}
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          cursor: pointer;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        {isOpened ? (
          <DownOutlined
            css={css`
              color: ${colors.PRIMARY};
            `}
          />
        ) : (
          <RightOutlined
            css={css`
              color: ${colors.PRIMARY};
            `}
          />
        )}
        <p
          css={css`
            ${paragraphMediumStyle}
            color: ${colors.PRIMARY};
          `}
        >
          {title}
        </p>
      </div>
      {isOpened && content ? <>{content}</> : null}
    </div>
  )
}

const accept = 'image/*'

const RegisterReview = () => {
  const {
    division,
    selectedTeam,
    registration,
    session,
    email,
    deleteSession,
    currency,
    couponInfo,
    role,
    firstName,
    lastName,
    selectedPlayer,
    isTeamRep,
    userInfo,
    setSession,
  } = useContext(RegistrationContext)

  const { message } = App.useApp()
  const isPlayerRegistration = useMemo(
    () => role?.name === 'Player',
    [role?.name]
  )
  const waiverRequired = registration?.registration?.show_waiver
  const autoApprove = registration?.registration?.auto_approve

  const teamCost = useMemo(() => {
    return calculateDiscountAndFormatCurrency(
      couponInfo?.discount,
      couponInfo?.type || couponInfo?.discount_type,
      isPlayerRegistration ? division?.player_cost : division?.team_cost,
      currency?.name
    )
  }, [
    couponInfo?.discount,
    couponInfo?.type,
    couponInfo?.discount_type,
    isPlayerRegistration,
    division?.player_cost,
    division?.team_cost,
    currency?.name,
  ])

  const isZero = teamCost?.includes('$0.00') || false

  const {
    team_name,
    division_name,
    subtotal,
    paid,
    team_id,
    billing_schedule,
    root_schedule_id,
  } = session

  const depositValue = isPlayerRegistration
    ? division?.player_deposit
    : division?.deposit

  const [installmentScheduleOpen, setInstallmentScheduleOpen] = useState(false)
  const iosAppLink = isCanlan
    ? 'https://apps.apple.com/ca/app/canlan-sports-stats/id1606251092'
    : 'https://apps.apple.com/br/app/sportninja/id1436064312'
  const androidAppLink = isCanlan
    ? 'https://play.google.com/store/apps/details?id=com.sportninjainc.canlansports'
    : 'https://play.google.com/store/apps/details?id=com.sportninjainc.sportninja'
  const [newTeamImage, setNewTeamImage] = useState(null)
  const [newTeamLoading, setNewTeamLoading] = useState(false)
  const [newTeamError, setNewTeamError] = useState(null)
  const [newTeamSuccess, setNewTeamSuccess] = useState(null)
  const [newPlayerImage, setNewPlayerImage] = useState(null)
  const [newPlayerLoading, setNewPlayerLoading] = useState(false)
  const [newPlayerError, setNewPlayerError] = useState(null)
  const [newPlayerSuccess, setNewPlayerSuccess] = useState(null)

  const playerDisplayName = useMemo(() => {
    // If not a player registration, return empty string
    if (!isPlayerRegistration) {
      return ''
    }

    // If there's a player name in the session, use it
    if (session?.player?.name_first && session?.player?.name_last) {
      return `${session?.player?.name_first} ${session?.player?.name_last}`
    }

    // If there's a selected existing player, use their first and last name
    if (selectedPlayer && selectedPlayer?.id !== 'new') {
      return `${selectedPlayer?.name_first || ''} ${
        selectedPlayer?.name_last || ''
      }`
    }

    // For new players, use firstName/lastName if provided
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }

    // Fall back to userInfo first name or empty string
    return `${userInfo?.name_first || ''} ${userInfo?.name_last || ''}`
  }, [
    isPlayerRegistration,
    session?.player?.name_first,
    session?.player?.name_last,
    selectedPlayer,
    firstName,
    lastName,
    userInfo?.name_first,
    userInfo?.name_last,
  ])

  console.log('playerDisplayName', playerDisplayName)

  useEffect(() => {
    message.open({
      type: 'success',
      content: `${
        isPlayerRegistration ? 'Player Registration' : 'Team Registration'
      } submitted successfully`,
      duration: 4,
    })
  }, [isPlayerRegistration, message])

  return (
    <>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
          background-color: ${colors.NEUTRAL_800};
          width: 1080px;
          padding: 0 35px 35px 35px;
          border-radius: 16px 16px 0px 0px;
          @media (max-width: 768px) {
            min-width: 0;
            width: 100%;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            margin-top: 16px;
            margin-bottom: -8px;
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              gap: 8px;
            }
          `}
        >
          <a
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
            `}
            href={`${ROUTES.PROFILE.ROOT}`}
            target='_blank'
            rel='noreferrer'
          >
            <UserOutlined
              css={css`
                color: ${colors.PRIMARY};
              `}
            />
            <p
              css={css`
                ${paragraphSmallStyle}
                color: ${colors.PRIMARY};
              `}
            >
              {email}{' '}
            </p>
          </a>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
            `}
            onClick={() => {
              deleteSession()
              window.location.reload()
            }}
          >
            <PlusCircleOutlined
              css={css`
                color: ${colors.PRIMARY};
              `}
            />
            <p
              css={css`
                ${paragraphSmallStyle}
                color: ${colors.PRIMARY};
              `}
            >
              {'New Registration'}
            </p>
          </div>
        </div>
        <EventCard eventInfo={registration} />
        <EventProgress
          eventProgress={6}
          isWaiverRequired={waiverRequired}
          isTeamRep={isTeamRep}
        />
        <PageTitle
          title={`${
            isPlayerRegistration ? 'Player Registration' : 'Team Registration'
          } Submitted`}
          subtitle={
            "Congratulations! Your account has been successfully verified. We've also emailed you the registration status and the receipt for your payment."
          }
          showAlert={false}
          showSuccess={true}
        />
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                width: 100%;
                align-items: center;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                padding: 16px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 2px;
                align-self: stretch;
                border-radius: 8px;
                border: 1px solid ${colors.NEUTRAL_700};
                margin-bottom: 16px;
              `}
            >
              {isPlayerRegistration ? (
                <>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 8px;
                      margin-bottom: 8px;
                    `}
                  >
                    <Image
                      src={
                        getImageThumbnailId(session?.player) ||
                        '/images/generic_placeholders/placeholder_player.png'
                      }
                      // eslint-disable-next-line react-native/no-inline-styles
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: 4,
                        padding: 2,
                        backgroundColor: 'white',
                      }}
                      onError={(e) => {
                        e.target.onerror = null
                        e.target.src =
                          '/images/generic_placeholders/placeholder_competition.png'
                      }}
                      preview={false}
                    />
                    <p
                      css={css`
                        ${paragraphMediumStyle}
                        ${weightSemiBold}
                        overflow: hidden;
                        color: ${colors.WHITE};
                        text-overflow: ellipsis;
                      `}
                    >
                      {playerDisplayName}
                    </p>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 8px;
                    `}
                  >
                    <Image
                      preview={false}
                      src={
                        selectedTeam?.is_bucket
                          ? '/images/bucket_image.png'
                          : getImageThumbnailId(selectedTeam) ||
                            '/images/generic_placeholders/placeholder_team.png'
                      }
                      // eslint-disable-next-line react-native/no-inline-styles
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: selectedTeam?.is_bucket ? 8 : 4,
                        padding: selectedTeam?.is_bucket ? 0 : 2,
                        backgroundColor: 'white',
                      }}
                      onError={(e) => {
                        e.target.onerror = null
                        e.target.src =
                          '/images/generic_placeholders/placeholder_competition.png'
                      }}
                    />
                    <p
                      css={css`
                        ${paragraphMediumStyle}
                        overflow: hidden;
                        color: ${colors.WHITE};
                        text-overflow: ellipsis;
                      `}
                    >
                      {team_name}
                    </p>
                  </div>
                </>
              ) : (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                  `}
                >
                  <Image
                    src={
                      getImageThumbnailId(selectedTeam) ||
                      '/images/generic_placeholders/placeholder_team.png'
                    }
                    // eslint-disable-next-line react-native/no-inline-styles
                    style={{
                      width: 24,
                      height: 24,
                      borderRadius: 4,
                      padding: 2,
                      backgroundColor: 'white',
                    }}
                    onError={(e) => {
                      e.target.onerror = null
                      e.target.src =
                        '/images/generic_placeholders/placeholder_competition.png'
                    }}
                  />

                  <p
                    css={css`
                      ${paragraphMediumStyle}
                      ${weightSemiBold}
                      overflow: hidden;
                      color: ${colors.WHITE};
                      text-overflow: ellipsis;
                    `}
                  >
                    {team_name}
                  </p>
                </div>
              )}
              <br />
              <p
                css={css`
                  ${paragraphSmallBoldStyle}
                  overflow: hidden;
                  color: ${colors.WHITE};
                  text-overflow: ellipsis;
                  overflow: hidden;
                `}
              >
                Division:{' '}
                <span
                  css={css`
                    ${paragraphSmallStyle}
                    overflow: hidden;
                    color: ${colors.WHITE};
                    text-overflow: ellipsis;
                  `}
                >
                  {division_name}
                </span>
              </p>
              {!division?.single_payment && depositValue ? (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                  `}
                >
                  <p
                    css={css`
                      ${paragraphSmallBoldStyle}
                      overflow: hidden;
                      color: ${colors.WHITE};
                      text-overflow: ellipsis;
                    `}
                  >
                    Deposit:{' '}
                    <span
                      css={css`
                        ${paragraphSmallStyle}
                        overflow: hidden;
                        color: ${colors.WHITE};
                        text-overflow: ellipsis;
                      `}
                    >
                      {formatCurrency(
                        isPlayerRegistration
                          ? division?.player_deposit
                          : division?.deposit,
                        currency?.name
                      )}
                    </span>
                  </p>
                </div>
              ) : null}
              {!isZero ? (
                <p
                  css={css`
                    ${paragraphSmallBoldStyle}
                    overflow: hidden;
                    color: ${colors.WHITE};
                    text-overflow: ellipsis;
                  `}
                >
                  {isPlayerRegistration ? 'Cost: ' : 'Cost Per Team: '}
                  <span
                    css={css`
                      ${paragraphSmallStyle}
                      overflow: hidden;
                      color: ${colors.WHITE};
                      text-overflow: ellipsis;
                    `}
                  >
                    {teamCost}
                  </span>
                </p>
              ) : null}
              {couponInfo && couponInfo?.discount > 0 ? (
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                    gap: 4px;
                  `}
                >
                  <p
                    css={css`
                      ${paragraphSmallBoldStyle}
                      overflow: hidden;
                      color: ${colors.SUCCESS_100};
                      text-overflow: ellipsis;
                    `}
                  >
                    {couponInfo?.type === 'percentage'
                      ? `Coupon Code: ${couponInfo?.code}`
                      : `Coupon Code: ${couponInfo?.code}`}
                  </p>
                  {couponInfo?.discount_amount &&
                  couponInfo?.discount_amount > 0 ? (
                    <Text
                      variant='paragraphMedium'
                      color={colors.SUCCESS_100}
                      weight='semiBold'
                      style={`
                    align-self: flex-end;
                  `}
                    >
                      -{''}
                      {formatCurrency(
                        couponInfo?.discount_amount,
                        currency?.name
                      )}
                    </Text>
                  ) : null}
                </div>
              ) : null}
              <br />
              <div>
                <p
                  css={css`
                    ${paragraphSmallBoldStyle}
                    overflow: hidden;
                    color: ${colors.WHITE};
                    text-overflow: ellipsis;
                    margin-bottom: 16px;
                  `}
                >
                  Registration Status:{' '}
                </p>
                <div
                  css={css`
                    .ant-steps {
                      padding: 0 0 0 0;
                    }
                    .ant-steps-item-title {
                      color: ${colors.WHITE} !important;
                    }
                    .ant-steps-item-description {
                      color: ${colors.STORM_GRAY} !important;
                    }
                    .ant-steps-item-wait .ant-steps-item-icon {
                      background-color: ${colors.NEUTRAL_600} !important;
                      border-color: ${colors.NEUTRAL_600} !important;
                    }
                    .ant-steps-item-wait
                      .ant-steps-item-icon
                      > .ant-steps-icon {
                      color: ${colors.STORM_GRAY} !important;
                    }
                  `}
                >
                  <Steps
                    direction='vertical'
                    size='small'
                    items={[
                      {
                        title: (
                          <Text
                            variant='paragraphSmall'
                            color={colors.WHITE}
                            weight='regular'
                          >
                            {paid ? 'Payment Approved' : 'Processing Payment'}
                          </Text>
                        ),
                        status: paid ? 'finish' : 'process',
                        icon: paid ? (
                          <CheckCircleOutlined
                            style={{ color: colors.SUCCESS_300 }}
                          />
                        ) : (
                          <ClockCircleOutlined
                            style={{ color: colors.WARNING_300 }}
                          />
                        ),
                        description: !paid
                          ? 'This Could Take A Few Minutes'
                          : '',
                      },
                      {
                        title: autoApprove ? (
                          <Text
                            variant='paragraphSmall'
                            color={colors.WHITE}
                            weight='regular'
                          >
                            Registration Approved
                          </Text>
                        ) : (
                          <Text
                            variant='paragraphSmall'
                            color={colors.WHITE}
                            weight='regular'
                          >
                            Pending League Approval
                          </Text>
                        ),
                        status: autoApprove
                          ? 'finish'
                          : paid
                          ? 'process'
                          : 'wait',
                        icon: autoApprove ? (
                          <CheckCircleOutlined
                            style={{ color: colors.SUCCESS_300 }}
                          />
                        ) : paid ? (
                          <ClockCircleOutlined
                            style={{
                              color: paid
                                ? colors.WARNING_300
                                : colors.NEUTRAL_100,
                            }}
                          />
                        ) : undefined,
                        description: autoApprove
                          ? ''
                          : paid
                          ? "You'll Receive A Confirmation Once It's Reviewed."
                          : '',
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            {billing_schedule &&
            billing_schedule.length > 0 &&
            !division?.single_payment ? (
              <div
                css={css`
                  display: flex;
                  padding: 8px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 16px;
                  align-self: stretch;
                  border-radius: 8px;
                  background: ${colors.NEUTRAL_700};
                  margin-bottom: 16px;
                  /* on mobile the gap should be 8 */
                  @media (max-width: 768px) {
                    gap: 8px;
                  }
                `}
              >
                {/* Title */}
                <div
                  onClick={() =>
                    setInstallmentScheduleOpen(!installmentScheduleOpen)
                  }
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;
                  `}
                >
                  {installmentScheduleOpen ? (
                    <DownOutlined
                      size={24}
                      css={css`
                        color: ${colors.WHITE};
                      `}
                    />
                  ) : (
                    <RightOutlined
                      size={24}
                      css={css`
                        color: ${colors.WHITE};
                      `}
                    />
                  )}
                  <p
                    css={css`
                      ${paragraphMediumStyle}
                      color: ${colors.WHITE}
                    `}
                  >
                    Payment Schedule
                  </p>
                </div>
                {installmentScheduleOpen ? (
                  <div
                    css={css`
                      width: 100%;
                      height: 1px;
                      background: ${colors.CHARADE};
                    `}
                  />
                ) : null}
                {installmentScheduleOpen &&
                  billing_schedule?.map((b, index) => (
                    <div
                      key={index}
                      css={css`
                        display: flex;
                        padding: 0px 8px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 8px;
                        align-self: stretch;
                      `}
                    >
                      <p
                        css={css`
                          ${paragraphSmallStyle}
                          color: ${colors.WHITE}
                        `}
                      >
                        <span
                          css={css`
                            ${paragraphSmallBoldStyle}
                          `}
                        >
                          {b?.payment_type === 'deposit'
                            ? 'Deposit'
                            : 'Payment'}{' '}
                          {b?.payment_type !== 'deposit' ? index + 1 : ''}:{' '}
                        </span>
                        {formatCurrency(b?.subtotal, currency?.name)} due{' '}
                        {formatDate(b?.due_date)}
                      </p>
                    </div>
                  ))}
                {installmentScheduleOpen ? (
                  <>
                    <div
                      css={css`
                        width: 100%;
                        height: 1px;
                        background: ${colors.CHARADE};
                      `}
                    />
                    <p
                      css={css`
                        ${paragraphSmallStyle}
                        color: ${colors.STORM_GRAY}
                      `}
                    >
                      All monthly payments will now be paid online.
                    </p>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
          <div
            css={css`
              background: ${colors.NEUTRAL_700};
              min-height: 300px;
              width: 1px;
              /* change the orientation no mobile */
              @media (max-width: 768px) {
                width: 100%;
                height: 1px;
                min-height: 0;
              }
            `}
          />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 16px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                width: 100%;
              }
            `}
          >
            <p
              css={css`
                ${headingStyle}
                color: ${colors.WHITE};
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
            >
              {"What's Next?"}
            </p>
            <NextContent
              title='Download the Mobile App'
              content={
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    align-items: flex-start;
                    @media (max-width: 768px) {
                      width: 100%;
                    }
                  `}
                >
                  <p
                    css={css`
                      ${paragraphMediumStyle}
                      color: ${colors.WHITE};
                    `}
                  >
                    Download the mobile app to receive notifications and updates
                    on your registration.
                  </p>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                      gap: 8px;
                      width: 100%;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                      `}
                    >
                      <QRCode
                        value={androidAppLink}
                        bgColor={colors.HEADER}
                        bordered={true}
                        css={css`
                          margin-bottom: 4px;
                        `}
                      />
                      <p
                        css={css`
                          ${paragraphSmallStyle}
                          color: ${colors.WHITE};
                        `}
                      >
                        Android
                      </p>
                    </div>
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                      `}
                    >
                      <QRCode
                        bgColor={colors.HEADER}
                        bordered={true}
                        value={iosAppLink}
                        css={css`
                          margin-bottom: 4px;
                        `}
                      />
                      <p
                        css={css`
                          ${paragraphSmallStyle}
                          color: ${colors.WHITE};
                        `}
                      >
                        iOS
                      </p>
                    </div>
                  </div>
                </div>
              }
            />
            {!isPlayerRegistration ? (
              <NextContent
                title='Update Team Logo'
                content={
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: 8px;
                      align-items: flex-start;
                      width: 100%;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        align-items: flex-end;
                        gap: 8px;
                        flex: 1 0 0;
                        align-self: stretch;
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                          gap: 4px;
                          flex: 1 0 0;
                        `}
                      >
                        <Input
                          name='teamLogo'
                          placeholder=''
                          autoComplete='off'
                          autoCorrect='off'
                          autoCapitalize='none'
                          type='file'
                          id='team-logo'
                          accept={accept}
                          onChange={(e) => setNewTeamImage(e.target.files[0])}
                          css={css`
                            cursor: pointer;
                          `}
                          disabled={newTeamLoading || newTeamSuccess}
                        />
                      </div>
                      {newTeamImage ? (
                        <div
                          css={css`
                            margin-bottom: 2px;
                            border: 1px solid ${colors.ATTENDANCE_GRAY};
                            border-radius: 4px;
                            width: 40px;
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 4px;
                          `}
                        >
                          <Image
                            src={URL.createObjectURL(newTeamImage)}
                            preview={true}
                          />
                        </div>
                      ) : null}
                    </div>
                    {newTeamImage && !newTeamSuccess ? (
                      <button
                        css={css`
                          ${buttonStyle}
                          ${headingSixStyle}
                          width: 100%;
                          cursor: pointer;
                          align-self: stretch;
                        `}
                        onClick={async () => {
                          setNewTeamLoading(true)
                          setNewTeamError(null)
                          setNewTeamSuccess(null)
                          try {
                            await updateTeamLogo(team_id, newTeamImage)
                            setNewTeamSuccess('Team logo updated successfully')
                            // Refresh session data
                            const { data } = await req(
                              `/registration/session/${session?.id}`
                            )
                            setSession(data)
                          } catch (e) {
                            setNewTeamError(
                              e?.message ||
                                'An error occurred while updating the team logo'
                            )
                          } finally {
                            setNewTeamLoading(false)
                          }
                        }}
                        disabled={newTeamLoading}
                      >
                        Upload logo
                      </button>
                    ) : null}
                    {newTeamSuccess ? (
                      <Alert
                        message={'Logo Uploaded Successfully'}
                        type='success'
                        showIcon
                        css={css`
                          width: 100%;
                        `}
                      />
                    ) : null}
                    {newTeamError ? (
                      <Alert
                        message={newTeamError || 'An error occurred'}
                        type='error'
                        showIcon
                        css={css`
                          width: 100%;
                        `}
                      />
                    ) : null}
                  </div>
                }
              />
            ) : null}
            {selectedTeam?.is_bucket ? null : (
              <NextContent
                route={`${ROUTES.TEAM_ROOT}/${team_id}/roster`}
                title='Go To My Team'
                content={null}
              />
            )}
            <NextContent
              route={`${ROUTES.SCHEDULE_ROOT}/${root_schedule_id}/standings`}
              title='Go To My Competition'
              content={null}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterReview
