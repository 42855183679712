/* eslint-disable no-unsafe-optional-chaining */

/* @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'
import req from '@sportninja/common/api/request'
import {
  goalieStatisticsHockeyColumns,
  goalieSummaryHockeyColumns,
  keeperStatisticsSoccerColumns,
  keeperSummarySoccerColumns,
  playerStatisticsSoccerColumns,
  playerSummarySoccerColumns,
  skaterStatisticsHockeyColumns,
  skaterSummaryHockeyColumns,
} from '@sportninja/common/constants/StatsTypes'
import colors from '@sportninja/common/constants/appColors'
import {
  buildStatsColumns,
  transformPlayerData,
} from '@sportninja/common/helpers/StatsHelper'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import {
  getAllPlayerTypes,
  getHockeySport,
  getSoccerSport,
} from '@sportninja/common/selectors/types'
import { Avatar, Select, Table, Typography } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { isCanlan } from '@sportninja/common/utils/customer-name'
import {
  getGoalieSummaryForWeb,
  getKeeperSummaryForWeb,
  getPlayerSummaryForWeb,
  getSkaterSummaryForWeb,
} from '@sportninja/common/utils/utils'
import Icon from 'src/components/Icon'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { media } from '../../../../components/Responsive'
import { font } from '../../../../components/css'

const fetchSkaterData = async (playerId, direction, sortBy, sport_id) => {
  const skater = await req(`/players/${playerId}/stats/profile/list`, {
    query: { goalie: 0, sort: direction, sortBy, sport_id },
  })
  return skater
}

const fetchGoalieData = async (playerId, direction, sortBy, sport_id) => {
  const skater = await req(`/players/${playerId}/stats/profile/list`, {
    query: { goalie: 1, sort: direction, sortBy, sport_id },
  })
  return skater
}

// this is an unnecessary function but I don't have too much time
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const Title = ({ children, trailing }) => {
  return (
    <div
      css={css`
        font-size: 24px;
        font-weight: 700;
        line-height: 120%; /* 33.6px */
        font-family: ${isCanlan
          ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
          : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
        padding-bottom: 20px;
        margin-bottom: -8px;
        text-transform: uppercase;
        border-bottom: 1px solid #3f4753;
        display: flex;
        justify-content: space-between;
        align-items: center;

        ${media.mobile} {
          padding-left: 12px;
          font-size: 20px;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
        }
      `}
    >
      <div
        css={css`
          flex: 8;
          ${media.mobile} {
            flex: 1;
            width: 100%;
          }
        `}
      >
        {children}
      </div>
      {trailing && (
        <div
          css={css`
            flex: 4;
            ${media.mobile} {
              flex: 1;
              width: 100%;
            }
          `}
        >
          {trailing}
        </div>
      )}
    </div>
  )
}

export const TitleWithoutBorderBottom = ({ children, trailing }) => {
  return (
    <div
      css={css`
        font-size: 24px;
        font-weight: 700;
        line-height: 120%; /* 33.6px */
        font-family: ${isCanlan
          ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
          : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
        padding-bottom: 20px;
        margin-bottom: -8px;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;

        ${media.mobile} {
          padding-left: 12px;
          font-size: 20px;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
        }
      `}
    >
      <div
        css={css`
          flex: 8;
          ${media.mobile} {
            flex: 1;
            width: 100%;
          }
        `}
      >
        {children}
      </div>
      {trailing && (
        <div
          css={css`
            flex: 4;
            ${media.mobile} {
              flex: 1;
              width: 100%;
            }
          `}
        >
          {trailing}
        </div>
      )}
    </div>
  )
}

// When player is supplied, this is for a player. Otherwise, it's the user profile
const StatsBySeason = ({
  playerIds,
  isPublicRoute,
  setShowStats = () => {},
  soccerSportId,
  hockeySportId,
  inFrame = false,
}) => {
  const [state, setState] = useState({
    goalieStats: [],
    skaterStats: [],
    playerStats: [],
    keeperStats: [],
    loading: true,
    error: null,
    hasSoccerStats: false,
    hasHockeyStats: false,
    skaterColumns: [],
    skaterData: [],
    goalieColumns: [],
    goalieData: [],
    playerColumns: [],
    playerData: [],
    keeperColumns: [],
    keeperData: [],
  })

  const [direction, setDirection] = useState('desc')
  const [sortBy, setSortBy] = useState('')
  const [selectedSport, setSelectedSport] = useState('hockey')

  useEffect(() => {
    if (!state?.hasHockeyStats && !state?.hasSoccerStats && !state?.loading) {
      setShowStats(false)
    } else {
      setShowStats(true)
    }
  }, [state?.hasHockeyStats, state?.hasSoccerStats, state?.loading])

  const commonColumns = [
    {
      title: 'Team',
      dataIndex: 'team',
      sorter: (a, b, sortOrder) => {
        setSortBy('team.name')
        const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
        setDirection(direction)
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (item) => {
        return (
          <Link
            to={`/team/${item?.team?.id}?schedule_id=${
              item?.schedule?.id || item?.season?.id
            }`}
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              ${media.mobile} {
                flex-direction: column;
                align-items: flex-start;
              }
            `}
          >
            <Avatar
              src={
                getImageThumbnailId(item?.team) ||
                '/images/placeholders/hockey/team.png'
              }
              icon={<Icon name='user-friends' fontSize={14} />}
              css={css`
                border: 1px solid #dbdbdb;
                background-color: white;
                margin: 0;
              `}
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 4px;
                max-width: 270px;
                ${media.mobile} {
                  max-width: 100%;
                }
              `}
            >
              <Typography.Text strong ellipsis>
                {item?.team?.name_full}
              </Typography.Text>
              <Typography.Text ellipsis>
                {item?.season?.name_full}
              </Typography.Text>
            </div>
          </Link>
        )
      },
      key: 'team',
      width: 270,
    },
  ]

  const [availableSports, setAvailableSports] = useState(['hockey', 'soccer'])
  const [sportToDisplay, setSportToDisplay] = useState(null)

  const skaterSummary = useMemo(
    () => getSkaterSummaryForWeb([...state?.skaterData]),
    [state?.skaterData, selectedSport]
  )

  const goalieSummary = useMemo(
    () => getGoalieSummaryForWeb([...state?.goalieData]),
    [state?.goalieData, selectedSport]
  )

  const playerSummary = useMemo(
    () => getPlayerSummaryForWeb([...state?.playerData]),
    [state?.playerData, selectedSport]
  )

  const keeperSummary = useMemo(
    () => getKeeperSummaryForWeb([...state?.keeperData]),
    [state?.keeperData, selectedSport]
  )

  useEffect(() => {
    const readNewStats = async () => {
      if (!soccerSportId || !hockeySportId) {
        return
      }
      try {
        setState((value) => ({
          ...value,
          ...{ loading: true, error: null, skaterStats: [], goalieStats: [] },
        }))
        // Ice Hockey, Skater
        const promisesForSkater = playerIds.map((playerId) =>
          fetchSkaterData(playerId, direction, sortBy, hockeySportId)
        )
        const resultsForSkater = await Promise.all(promisesForSkater)
        const skater = resultsForSkater.flat()

        // Ice Hockey, Goalie
        const promisesForGoalie = playerIds.map((playerId) =>
          fetchGoalieData(playerId, direction, sortBy, hockeySportId)
        )
        const resultsForGoalie = await Promise.all(promisesForGoalie)
        const goalie = resultsForGoalie.flat()

        // Soccer, Player
        const promisesForPlayer = playerIds.map((playerId) =>
          fetchSkaterData(playerId, direction, sortBy, soccerSportId)
        )
        const resultForPlayer = await Promise.all(promisesForPlayer)
        const player = resultForPlayer.flat()

        // Ice Hockey, Goalie
        const promisesForKeeper = playerIds.map((playerId) =>
          fetchGoalieData(playerId, direction, sortBy, soccerSportId)
        )
        const resultsForKeeper = await Promise.all(promisesForKeeper)
        const keeper = resultsForKeeper.flat()

        // Ice Hockey
        const skaterStats = skater
          .filter((item) => item !== false && item !== true)
          .filter((item) => {
            return item?.sport_id !== soccerSportId
          })
        const goalieStats = goalie
          .filter((item) => item !== false && item !== true)
          .filter((item) => {
            return item?.sport_id !== soccerSportId
          })

        // Soccer
        const playerStats = player
          .filter((item) => item !== false && item !== true)
          .filter((item) => {
            return item?.sport_id === soccerSportId
          })
        const keeperStats = keeper
          .filter((item) => item !== false && item !== true)
          .filter((item) => {
            return item?.sport_id === soccerSportId
          })

        const skaterColumns = buildStatsColumns(
          skaterStatisticsHockeyColumns,
          'hockey'
        )

        const skaterData = transformPlayerData(
          skaterStats,
          skaterStatisticsHockeyColumns
        )

        const goalieColumns = buildStatsColumns(
          goalieStatisticsHockeyColumns,
          'hockey'
        )
        const goalieData = transformPlayerData(
          goalieStats,
          goalieStatisticsHockeyColumns
        )

        const playerColumns = buildStatsColumns(
          playerStatisticsSoccerColumns,
          'soccer'
        )
        const playerData = transformPlayerData(
          playerStats,
          playerStatisticsSoccerColumns
        )

        const keeperColumns = buildStatsColumns(
          keeperStatisticsSoccerColumns,
          'soccer'
        )
        const keeperData = transformPlayerData(
          keeperStats,
          keeperStatisticsSoccerColumns
        )

        setState((value) => ({
          ...value,
          ...{
            skaterStats: skaterStats,
            keeperStats: keeperStats,
            goalieStats: goalieStats,
            playerStats: playerStats,
            hasSoccerStats: keeperStats.length > 0 || playerStats.length > 0,
            hasHockeyStats: skaterStats.length > 0 || goalieStats.length > 0,
            skaterColumns: skaterColumns,
            skaterData: skaterData,
            goalieColumns: goalieColumns,
            goalieData: goalieData,
            playerColumns: playerColumns,
            playerData: playerData,
            keeperColumns: keeperColumns,
            keeperData: keeperData,
          },
        }))
      } catch (e) {
        setState((value) => ({ ...value, ...{ error: e?.message } }))
      } finally {
        setState((value) => ({ ...value, ...{ loading: false } }))
      }
    }
    if (playerIds && playerIds.length > 0) {
      readNewStats()
    }
  }, [
    playerIds,
    soccerSportId,
    direction,
    sortBy,
    soccerSportId,
    hockeySportId,
  ])

  useEffect(() => {
    if (state?.hasSoccerStats && state?.hasHockeyStats) {
      setSelectedSport('hockey')
    } else if (state?.hasSoccerStats) {
      setSelectedSport('soccer')
    } else {
      setSelectedSport('hockey')
    }
  }, [state?.hasSoccerStats, state?.hasHockeyStats])

  const renderDropdown = useCallback(() => {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          ${media.mobile} {
            width: 100%;
          }
        `}
      >
        <Select
          css={css`
            width: 100%;
            ${inFrame &&
            css`
              .ant-select-selection-item {
                color: black;
              }
            `}
          `}
          disabled={availableSports?.length <= 1}
          value={selectedSport}
          onChange={(value) => {
            setSelectedSport(value)
          }}
          options={availableSports?.map((sport) => ({
            label: capitalize(sport),
            value: sport,
          }))}
        />
      </div>
    )
  }, [availableSports, sportToDisplay, selectedSport])

  const renderTable = useCallback(() => {
    return (
      <div
        css={css`
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        `}
      >
        <Table
          css={css`
            * {
              border-color: ${colors.SOFT_STEEL} !important;
            }
            .ant-table-column-sorter {
              display: none;
            }
            ${media.mobile} {
              white-space: nowrap;
              .ant-table {
                min-width: 800px;
              }
            }
          `}
          columns={commonColumns.concat(
            selectedSport === 'soccer'
              ? state?.playerColumns
              : state?.skaterColumns
          )}
          bordered={true}
          loading={state?.loading}
          size='small'
          dataSource={
            selectedSport === 'soccer' ? state?.playerData : state?.skaterData
          }
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
      </div>
    )
  }, [state?.skaterData, state?.loading, commonColumns, state?.skaterColumns])

  const renderGoalieTable = useCallback(() => {
    return (
      <div
        css={css`
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        `}
      >
        <Table
          css={css`
            * {
              border-color: ${colors.SOFT_STEEL} !important;
            }
            .ant-table-column-sorter {
              display: none;
            }
            ${media.mobile} {
              white-space: nowrap;
              .ant-table {
                min-width: 800px;
              }
            }
          `}
          columns={commonColumns.concat(
            selectedSport === 'soccer'
              ? state?.keeperColumns
              : state?.goalieColumns
          )}
          bordered={true}
          loading={state?.loading}
          size='small'
          dataSource={
            selectedSport === 'soccer' ? state?.keeperData : state?.goalieData
          }
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
      </div>
    )
  }, [
    state?.goalieData,
    state?.keeperData,
    state?.loading,
    commonColumns,
    state?.goalieColumns,
    state?.keeperColumns,
  ])

  const renderSkaterSummary = useCallback(() => {
    const skaterSummaryColumns = buildStatsColumns(
      skaterSummaryHockeyColumns,
      'hockey'
    )

    if (!skaterSummary) {
      return null
    }

    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
        `}
      >
        <span
          css={css`
            padding-bottom: 20px;
            margin-bottom: -8px;
            ${font.title}
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0.015em;
            text-transform: uppercase;
            ${media.mobile} {
              padding-left: 12px;
            }
          `}
        >
          Skater
        </span>
        <div
          css={css`
            margin-bottom: 16px;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            * {
              border-color: ${colors.SOFT_STEEL} !important;
            }
            .ant-table-column-sorter {
              display: none;
            }
            ${media.mobile} {
              .ant-table {
                min-width: 800px;
              }
            }
          `}
        >
          <Table
            loading={state?.loading}
            bordered
            pagination={false}
            columns={skaterSummaryColumns}
            dataSource={[skaterSummary]}
            locale={{
              emptyText: 'No Data Available',
            }}
            size='small'
            scroll={{ x: 'max-content' }}
          />
        </div>
      </div>
    )
  }, [skaterSummary, state?.loading])

  const renderGoalieSummary = useCallback(() => {
    const goalieSummaryColumns = buildStatsColumns(
      goalieSummaryHockeyColumns,
      'hockey'
    )

    if (!goalieSummary) {
      return null
    }

    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
        `}
      >
        <span
          css={css`
            padding-bottom: 20px;
            margin-bottom: -8px;
            ${font.title}
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0.015em;
            text-transform: uppercase;
            ${media.mobile} {
              padding-left: 12px;
            }
          `}
        >
          Goalie
        </span>
        <div
          css={css`
            margin-bottom: 16px;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            * {
              border-color: ${colors.SOFT_STEEL} !important;
            }
            .ant-table-column-sorter {
              display: none;
            }
            ${media.mobile} {
              .ant-table {
                min-width: 800px;
              }
            }
          `}
        >
          <Table
            loading={state?.loading}
            bordered
            pagination={false}
            columns={goalieSummaryColumns}
            dataSource={[goalieSummary]}
            locale={{
              emptyText: 'No Data Available',
            }}
            size='small'
            scroll={{ x: 'max-content' }}
          />
        </div>
      </div>
    )
  }, [goalieSummary, state?.loading])

  const renderPlayerSummary = useCallback(() => {
    const playerSummaryColumns = buildStatsColumns(
      playerSummarySoccerColumns,
      'soccer'
    )
    if (!playerSummary) {
      return null
    }

    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
        `}
      >
        <span
          css={css`
            padding-bottom: 20px;
            margin-bottom: -8px;
            ${font.title}
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0.015em;
            text-transform: uppercase;
            ${media.mobile} {
              padding-left: 12px;
            }
          `}
        >
          Player
        </span>
        <div
          css={css`
            margin-bottom: 16px;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            * {
              border-color: ${colors.SOFT_STEEL} !important;
            }
            .ant-table-column-sorter {
              display: none;
            }
            ${media.mobile} {
              .ant-table {
                min-width: 800px;
              }
            }
          `}
        >
          <Table
            loading={state?.loading}
            bordered
            pagination={false}
            columns={playerSummaryColumns}
            dataSource={[playerSummary]}
            locale={{
              emptyText: 'No Data Available',
            }}
            size='small'
            scroll={{ x: 'max-content' }}
          />
        </div>
      </div>
    )
  }, [playerSummary, state?.loading])

  const renderKeeperSummary = useCallback(() => {
    const keeperSummaryColumns = buildStatsColumns(
      keeperSummarySoccerColumns,
      'soccer'
    )

    if (!keeperSummary) {
      return null
    }

    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
        `}
      >
        <span
          css={css`
            padding-bottom: 20px;
            margin-bottom: -8px;
            ${font.title}
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0.015em;
            text-transform: uppercase;
            ${media.mobile} {
              padding-left: 12px;
            }
          `}
        >
          Keeper
        </span>
        <div
          css={css`
            margin-bottom: 16px;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            * {
              border-color: ${colors.SOFT_STEEL} !important;
            }
            .ant-table-column-sorter {
              display: none;
            }
            ${media.mobile} {
              .ant-table {
                min-width: 800px;
              }
            }
          `}
        >
          <Table
            loading={state?.loading}
            bordered
            pagination={false}
            columns={keeperSummaryColumns}
            dataSource={[keeperSummary]}
            locale={{
              emptyText: 'No Data Available',
            }}
            size='small'
            scroll={{ x: 'max-content' }}
          />
        </div>
      </div>
    )
  }, [keeperSummary, state?.loading])

  const hasAtLeastOneSummaryDataForSelectedSport = useMemo(() => {
    if (selectedSport === 'hockey') {
      return skaterSummary || goalieSummary
    }
    if (selectedSport === 'soccer') {
      return playerSummary || keeperSummary
    }
    return false
  }, [
    selectedSport,
    skaterSummary,
    goalieSummary,
    playerSummary,
    keeperSummary,
  ])

  const hasAtLeastOneDataForGoalieForSelectedSport = useMemo(() => {
    if (selectedSport === 'hockey') {
      return state?.goalieData?.length > 0
    }
    if (selectedSport === 'soccer') {
      return state?.keeperData?.length > 0
    }
    return false
  }, [selectedSport, state?.goalieData, state?.keeperData])

  const hasAtLeastOneDataForSkaterForSelectedSport = useMemo(() => {
    if (selectedSport === 'hockey') {
      return state?.skaterData?.length > 0
    }
    if (selectedSport === 'soccer') {
      return state?.playerData?.length > 0
    }
    return false
  }, [selectedSport, state?.skaterData, state?.playerData])

  return (
    <div
      className='stats-by-season'
      css={css`
        display: flex;
        flex-direction: column;
        min-height: 98px;
        margin-top: 24px;
        padding: 20px 16px;
        background-color: #27303e;
        border: 1px solid #3f4753;
        border-radius: 8px;

        ${media.mobile} {
          padding: 16px 8px;
        }
      `}
    >
      {state?.loading && (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
          `}
        >
          <LoadingSpinner size={4} />
        </div>
      )}
      {!state?.loading &&
        !hasAtLeastOneDataForSkaterForSelectedSport &&
        !hasAtLeastOneDataForGoalieForSelectedSport && (
          <TitleWithoutBorderBottom trailing={renderDropdown()}>
            There are no statistics available
          </TitleWithoutBorderBottom>
        )}
      {!state?.loading && hasAtLeastOneSummaryDataForSelectedSport && (
        <>
          <TitleWithoutBorderBottom trailing={renderDropdown()}>
            Career Statistics
          </TitleWithoutBorderBottom>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex: 2;
              gap: 16px;
            `}
          >
            {selectedSport === 'hockey' ? (
              <>
                {renderSkaterSummary()} {renderGoalieSummary()}
              </>
            ) : null}
            {selectedSport === 'soccer' ? (
              <>
                {renderPlayerSummary()} {renderKeeperSummary()}
              </>
            ) : null}
          </div>
        </>
      )}
      {hasAtLeastOneDataForSkaterForSelectedSport ? (
        <>
          {!state?.loading && (
            <Title
              trailing={
                !hasAtLeastOneSummaryDataForSelectedSport && renderDropdown()
              }
            >
              {selectedSport === 'hockey' ? 'Skater' : 'Player'} Stats
            </Title>
          )}
          {!state?.loading ? <>{renderTable()}</> : null}
        </>
      ) : null}
      {hasAtLeastOneDataForGoalieForSelectedSport ? (
        <>
          <div
            css={css`
              margin-top: 24px;
              margin-bottom: 24px;
              border-top: 1px solid #3f4753;
            `}
          />
          {!state?.loading && (
            <Title>
              {selectedSport === 'hockey' ? 'Goalie' : 'Keeper'} Stats
            </Title>
          )}
          {!state?.loading ? <>{renderGoalieTable()}</> : null}
        </>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  const soccerSportId = getSoccerSport(state)?.id
  const hockeySportId = getHockeySport(state)?.id

  return {
    inFrame: state.auth.inFrame,
    playerTypes: getAllPlayerTypes(state),
    soccerSportId,
    hockeySportId,
  }
}

export default connect(mapStateToProps, null)(StatsBySeason)
