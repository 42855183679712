import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import colors from '@sportninja/common/constants/appColors'

import { Flex } from '../Layout'
import { media, minMedia } from '../Responsive'
import {
  errorColor,
  font,
  flairColor,
  loadingSpinner,
  flairColorLight,
  flairColorRgb,
  paragraphXSmall,
} from '../css'

import gradient from './gradient.svg'
import gradientHover from './gradient-hover.svg'

export const inputCss = (props) => css`
  height: 46px;
  width: 100%;
  padding: 0 12px;
  border: none;

  border: ${props.fixBorder
    ? 'none'
    : `${isCanlan ? 1 : 1}px solid
  ${isCanlan ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.2)'}`};
  border-radius: ${props.fixBorder ? 'none' : `${isCanlan ? 0 : 2}px`};

  ${font.body}
  color: ${colors.WHITE};
  letter-spacing: 0.74px;
  line-height: normal;
  font-size: 16px; /* Values smaller than this will cause zooming in iOS */

  @media (hover: hover) {
    font-size: 15px;
  }

  :placeholder {
    opacity: 0.5;
  }

  :not([readonly]):not([disabled]) {
    @media (hover: hover) {
      &:hover {
        border: ${props.fixBorder
          ? 'none'
          : `${isCanlan ? 2 : 1}px solid ${colors.WHITE}`};
      }
    }

    &:focus {
      border: ${props.fixBorder
        ? 'none'
        : `${isCanlan ? 2 : 1}px solid ${colors.WHITE}`};
    }
  }

  cursor: ${(props.disabled || props.readOnly) && 'default'};
  border-color: ${props.hasError && `${errorColor} !important`};

  &.error {
    border-color: ${errorColor} !important;
  }

  &::-webkit-calendar-picker-indicator:hover {
    background-color: transparent;
  }

  &::-webkit-clear-button,
  &::-webkit-search-cancel-button,
  &::-webkit-datetime-edit {
    fill: ${colors.WHITE};
  }

  ${props.autoComplete === 'off' &&
  css`
    ::-webkit-contacts-auto-fill-button,
    ::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
  `}

  &[type="checkbox"] {
    & + label {
      font-size: 14px;
      padding-top: 3px;
    }
    /* Base for label styling */
    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;
    }

    &:not(:checked) + label,
    &:checked + label {
      position: relative;
      padding-left: 1.95em;
      cursor: pointer;
    }

    /* checkbox aspect */
    &:not(:checked) + label:before,
    &:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background: transparent;
      border-radius: 2px;
    }

    /* checked mark aspect */
    &:not(:checked) + label:after,
    &:checked + label:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      background-color: ${flairColor};
      width: 14px;
      height: 14px;
      border-radius: 3px;
    }
    /* checked mark aspect changes */
    &:not(:checked) + label:after {
      opacity: 0;
    }
    &:checked + label:after {
      opacity: 1;
    }
    /* disabled checkbox */
    &:disabled:not(:checked) + label:before,
    &:disabled:checked + label:before {
      box-shadow: none;
      opacity: 0.7;
    }
    &:disabled:checked + label:after {
      color: #999;
    }
    &:disabled + label {
      color: #aaa;
    }

    /* hover style just for information */
    & + label:hover:before,
    &:checked + label:before {
      border-color: ${colors.WHITE};
    }
  }
`

export const FormRow = styled(Flex)`
  margin-bottom: 20px;

  .datetime-reset-button {
    display: none;
  }
`

export const InputWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;

  ${minMedia.eight} {
    :first-of-type {
      padding-right: 8px;
    }

    :last-of-type {
      padding-left: 8px;
    }

    :only-child {
      padding: 0;

      ${(props) =>
        props.noFlex &&
        `
        width: 50%;
        padding-right: 8px;
      `}
    }
  }

  ${media.mobile} {
    /* Apply a margin, but only if this is an nth input on a given FormRow */
    :not(:first-of-type) {
      margin-top: 20px;
    }
  }

  transition: opacity 0.1s ease-in-out;
  ${(props) =>
    (props.disabled || props.readOnly) &&
    css`
      opacity: 0.5;
    `};

  textarea {
    padding-top: 12px;
    height: 82px;
  }
`

export const Label = styled.label`
  margin-bottom: 4px;
  ${font.body}
  ${paragraphXSmall}
  text-transform: capitalize;
  opacity: ${(props) => props.disabled && 0.6};
  ${(props) =>
    props.hasError &&
    css`
      color: ${errorColor};
    `};
`

export const FormButton = styled.button`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  padding: 0 4px;
  color: ${colors.WHITE};
  ${font.title}
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 18px;
  font-weight: 600;
  padding-bottom: ${isCanlan ? 2 : 1}px;
  outline: none;
  appearance: none;
  text-transform: uppercase;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;

  &:nth-of-type(2) {
    margin-left: 16px;
  }

  ${(props) =>
    !props.isSubmit
      ? css`
          border-style: solid;
          border-width: ${isCanlan ? 2 : 1}px;
          border-color: rgba(
            ${isCanlan ? flairColorRgb : '255, 255, 255'},
            0.3
          );

          :hover,
          :active,
          :focus {
            &:not([disabled]) {
              border-color: ${isCanlan ? flairColor : colors.WHITE};
            }
          }
        `
      : isCanlan
      ? css`
          border: none;
          background-color: ${flairColor};

          :hover,
          :active,
          :focus {
            &:not([disabled]) {
              border-width: 2px;
              background-color: ${flairColorLight};
            }
          }
        `
      : css`
          border: none;
          background-image: url(${gradient});

          :hover,
          :active,
          :focus {
            &:not([disabled]) {
              background-image: url(${gradientHover});
            }
          }
        `}

  ${(props) => props.busy && loadingSpinner}

  ${isCanlan &&
  css`
    &::after {
      border-color: rgba(255, 255, 255, 0.25);
      border-top-color: white;
    }
  `}
`

export const Right = styled(Flex)`
  align-self: center;
  align-items: center;
  justify-content: center;
  padding-right: 8px;

  .green {
    color: green;
  }

  .red {
    color: red;
  }
`

export const AdjacentInputWrapper = styled.div`
  width: 50%;
  position: relative;

  span {
    display: flex;
    height: 46px;
    align-items: center;
    top: 0;
    right: 18px;
    position: absolute;
  }

  input {
    ${inputCss}
    padding-right: 0;

    &.left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.right {
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`

export const FilterButton = styled.button`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  padding: 0 4px;

  color: ${colors.WHITE};
  ${font.title}
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.24px;
  padding-bottom: ${isCanlan ? 2 : 1}px;

  outline: none;
  appearance: none;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;

  border-radius: ${isCanlan ? 0 : 2}px;

  &:nth-of-type(2) {
    margin-left: 16px;
  }

  ${(props) =>
    !props.isSubmit
      ? css`
          border-style: solid;
          border-width: ${isCanlan ? 2 : 1}px;
          border-color: rgba(
            ${isCanlan ? flairColorRgb : '255, 255, 255'},
            0.3
          );

          :hover,
          :active,
          :focus {
            &:not([disabled]) {
              border-color: ${isCanlan ? flairColor : colors.WHITE};
            }
          }
        `
      : isCanlan
      ? css`
          border: none;
          background-color: ${flairColor};

          :hover,
          :active,
          :focus {
            &:not([disabled]) {
              border-width: 2px;
              background-color: ${flairColorLight};
            }
          }
        `
      : css`
          border: none;
          background-image: url(${gradient});

          :hover,
          :active,
          :focus {
            &:not([disabled]) {
              background-image: url(${gradientHover});
            }
          }
        `}

  ${(props) => props.busy && loadingSpinner}

  ${isCanlan &&
  css`
    &::after {
      border-color: rgba(255, 255, 255, 0.25);
      border-top-color: white;
    }
  `}
`
