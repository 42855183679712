/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import {
  HomeOutlined,
  InfoCircleOutlined,
  RightOutlined,
} from '@ant-design/icons'
import Text from '../ts-components/Text'
import useWindowSize from 'src/hooks/useWindowSize'
import { useMemo } from 'react'
import { ROUTES } from '@sportninja/common/constants/app'
import { Link } from 'react-router-dom'
import { formatShortDate } from '@sportninja/common/ts-utils/date-utils'
import dayjs from 'dayjs'

// const getPlaceholderImage = ({
//   isSchedule,
//   isTeam,
//   isOrganization,
// }: {
//   isSchedule: boolean
//   isTeam: boolean
//   isOrganization: boolean
// }) => {
//   if (isSchedule) {
//     return '/images/generic_placeholders/placeholder_competition.png'
//   } else if (isTeam) {
//     return '/images/generic_placeholders/placeholder_team.png'
//   } else if (isOrganization) {
//     return '/images/generic_placeholders/placeholder_organization.png'
//   } else {
//     return '/images/generic_placeholders/placeholder_competition.png'
//   }
// }

const getImageSrc = ({
  imageId,
  isSchedule,
  isTeam,
  isOrganization,
}: {
  imageId: string
  isSchedule: boolean
  isTeam: boolean
  isOrganization: boolean
}) => {
  if (imageId) {
    return imageId
  } else if (isSchedule) {
    return '/images/generic_placeholders/placeholder_competition.png'
  } else if (isTeam) {
    return '/images/generic_placeholders/placeholder_team.png'
  } else if (isOrganization) {
    return '/images/generic_placeholders/placeholder_organization.png'
  } else {
    return '/images/generic_placeholders/placeholder_competition.png'
  }
}

const getBackgroundImage = ({
  genericSportForGame,
  isSoccer,
  isDesktop,
  sport,
  isOrganization,
}: {
  genericSportForGame: any
  isSoccer: boolean
  isDesktop: boolean
  sport: any
  isOrganization: boolean
}) => {
  if (isOrganization) {
    return null
  }
  if (!genericSportForGame) {
    if (isSoccer) {
      return '/images/soccer_game_background'
    } else {
      if (sport && sport?.name) {
        if (sport?.name === 'lacrosse') {
          return '/images/grass'
        }
        if (sport?.name === 'fieldhockey') {
          return '/images/grass'
        }
        if (sport?.name === 'floorball') {
          return '/images/floorball'
        }
      }
      return '/images/game_hockey'
    }
  } else {
    return isDesktop
      ? genericSportForGame?.background_web_image?.replace('.png', '')
      : // : genericSportForGame?.background_mobile_image?.replace('.png', '')
        genericSportForGame?.background_web_image?.replace('.png', '')
  }
}

const isContactInfoAvailable = (contactInfo: any) => {
  return (
    contactInfo?.support_phone ||
    contactInfo?.support_email ||
    contactInfo?.support_website ||
    contactInfo?.phone ||
    contactInfo?.email ||
    contactInfo?.website
  )
}

type PageHeaderProps = {
  isSchedule: boolean
  isTeamOrSchedule: boolean
  isOrganization: boolean
  isTeam: boolean
  imageId: string
  breadcrumbs: any
  title: string
  titleActions: any
  subTitle: string
  history?: any
  setIsContactInfoModalOpen: any
  baseCrumb?: string
  baseRoute?: string
  backgroundImageUrl: string
  genericSport: any
  isSoccer: boolean
  sport: any
  contactInfo: {
    support_phone: string
    support_email: string
    support_website: string
    phone: string
    email: string
    website: string
  }
  competitionStartDate: string | null
  competitionEndDate: string | null
}

const hasCompetitionEnded = (competitionEndDate: string | null) => {
  if (!competitionEndDate) {
    return false
  }
  return dayjs(competitionEndDate).isBefore(dayjs())
}

const PageHeaderWithBackground = ({
  isSchedule,
  isOrganization,
  isTeam = false,
  imageId,
  breadcrumbs,
  title,
  titleActions,
  subTitle,
  setIsContactInfoModalOpen,
  // baseCrumb,
  // baseRoute,
  backgroundImageUrl,
  genericSport,
  isSoccer,
  sport,
  contactInfo,
  competitionStartDate = null,
  competitionEndDate = null,
}: PageHeaderProps) => {
  const { width } = useWindowSize()

  const isDesktop = useMemo(() => {
    return width > 768
  }, [width])

  const backgroundImage = getBackgroundImage({
    genericSportForGame: genericSport,
    isSoccer,
    isDesktop,
    sport,
    isOrganization,
  })
  const url = isSchedule
    ? backgroundImageUrl || `${backgroundImage}.png`
    : backgroundImageUrl || imageId || `${backgroundImage}.png`
  const isContactAvailable = isContactInfoAvailable(contactInfo)

  if (!isDesktop) {
    return (
      <div
        css={css`
          display: flex;
          padding: 80px 0px 24px 0px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          width: 100%;
          background: ${colors.NEUTRAL_900};
          min-height: 250px;
          position: relative;
          z-index: 1;
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.7) 0%,
                rgba(0, 0, 0, 0.7) 100%
              ),
              no-repeat center center / cover url(${url});
            filter: blur(4px);
            z-index: -1;
            border-bottom: 1px solid ${colors.NEUTRAL_100};
          `}
        />
        <img
          src={getImageSrc({
            imageId,
            isSchedule,
            isTeam,
            isOrganization,
          })}
          alt='Page Header'
          width={80}
          height={80}
          css={css`
            border-radius: 8px;
            border: 1px solid ${colors.SECONDARY_300};
            background-color: ${colors.NEUTRAL_0};
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
          `}
        />
        <Text variant='heading4' color={colors.NEUTRAL_0} weight='semiBold'>
          {title}
        </Text>
        {subTitle && (
          <Text
            variant='captionSmall'
            color={colors.NEUTRAL_100}
            weight='semiBold'
          >
            {subTitle}
          </Text>
        )}
        {isTeam && competitionStartDate && competitionEndDate && (
          <Text
            variant='captionSmall'
            color={colors.NEUTRAL_100}
            weight='semiBold'
          >
            {formatShortDate(competitionStartDate, true)} -{' '}
            {formatShortDate(competitionEndDate, true)} (
            {hasCompetitionEnded(competitionEndDate) ? ' (Ended)' : ''})
          </Text>
        )}
        {isContactAvailable ? (
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              cursor: pointer;
            `}
            onClick={() => {
              setIsContactInfoModalOpen(true)
            }}
          >
            <Text
              variant='paragraphSmall'
              color={colors.PRIMARY}
              weight='regular'
            >
              Contact Info
            </Text>
            <InfoCircleOutlined
              color={colors.PRIMARY}
              width={16}
              height={16}
              css={css`
                font-size: 16px;
                color: ${colors.PRIMARY};
              `}
            />
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div
      css={css`
        display: flex;
        padding: 0px 88px;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        align-self: stretch;
        border-radius: 8px 8px 0px 0px;
        border-bottom: 1px solid ${colors.SOFT_STEEL};
        background: ${colors.NEUTRAL_900};
        height: 220px;
        position: relative;
        z-index: 1;
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.7) 0%,
                rgba(0, 0, 0, 0.7) 100%
              ),
              no-repeat center center / cover url(${url});
            filter: blur(4px);
            z-index: -1;
            border-bottom: 1px solid ${colors.NEUTRAL_100};
          `}
        />
      </div>
      <div
        css={css`
          max-width: 1424px;
          margin: 0px auto;
          width: 100%;
          align-self: stretch;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <img
            src={getImageSrc({
              imageId,
              isSchedule,
              isTeam,
              isOrganization,
            })}
            alt='Page Header'
            width={80}
            height={80}
            css={css`
              border-radius: 8px;
              border: 1px solid ${colors.SECONDARY_300};
              background-color: ${colors.NEUTRAL_0};
              object-fit: contain;
              max-width: 100%;
              max-height: 100%;
            `}
          />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
            `}
          >
            {breadcrumbs && breadcrumbs?.length > 0 ? (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                `}
              >
                {/* first breadcrumb is always to the home page */}
                <a href={ROUTES.HOME}>
                  <HomeOutlined
                    width={16}
                    height={16}
                    color={colors.NEUTRAL_100}
                    css={css`
                      font-size: 16px;
                      color: ${colors.PRIMARY};
                    `}
                  />
                </a>
                {breadcrumbs?.map((breadcrumb, idx) => {
                  const isLast = idx === breadcrumbs.length - 1
                  return (
                    <Link
                      key={breadcrumb?.id}
                      to={
                        !breadcrumb?.to
                          ? ''
                          : `${ROUTES.ORG_ROOT}/${breadcrumb?.id}`
                      }
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        pointer-events: ${!breadcrumb?.to ? 'none' : 'auto'};
                        opacity: ${!breadcrumb?.to ? 0.9 : 1};
                      `}
                    >
                      <RightOutlined
                        color={colors.NEUTRAL_100}
                        width={10}
                        height={10}
                        css={css`
                          font-size: 10px;
                          color: ${colors.NEUTRAL_100};
                        `}
                      />
                      <Text
                        variant='captionSmall'
                        color={isLast ? colors.NEUTRAL_100 : colors.PRIMARY}
                        weight='medium'
                      >
                        {breadcrumb?.text || ''}
                      </Text>
                    </Link>
                  )
                })}
              </div>
            ) : null}
            <div
              css={css`
                display: flex;
                align-items: flex-end;
                gap: 8px;
              `}
            >
              <Text
                variant='displaySmall'
                color={colors.NEUTRAL_0}
                weight='semiBold'
              >
                {title}
              </Text>

              {titleActions ? (
                <div
                  css={css`
                    display: flex;
                    padding: 8px 0px;
                    align-items: center;
                    gap: 8px;
                  `}
                >
                  {titleActions}
                </div>
              ) : null}
            </div>
            {!isTeam && subTitle && (
              <Text
                variant='captionSmall'
                color={colors.NEUTRAL_100}
                weight='semiBold'
              >
                {subTitle}
              </Text>
            )}
            {isTeam && competitionStartDate && competitionEndDate && (
              <Text
                variant='captionSmall'
                color={colors.NEUTRAL_100}
                weight='semiBold'
              >
                {formatShortDate(competitionStartDate, true)} -{' '}
                {formatShortDate(competitionEndDate, true)}
                {hasCompetitionEnded(competitionEndDate) ? ' (Ended)' : ''}
              </Text>
            )}
            {isTeam && subTitle && (
              <div
                css={css`
                  display: flex;
                  align-items: flex-start;
                  justify-content: center;
                  gap: 8px;
                `}
              >
                <Text
                  variant='captionSmall'
                  color={colors.NEUTRAL_100}
                  weight='semiBold'
                >
                  {subTitle}
                </Text>
              </div>
            )}
          </div>
        </div>
        {isContactAvailable ? (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 8px;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                cursor: pointer;
                border: 1px solid ${colors.PRIMARY};
                padding: 8px;
                border-radius: 8px;
              `}
              onClick={() => {
                setIsContactInfoModalOpen(true)
              }}
            >
              <Text variant='paragraphSmall' color={colors.PRIMARY}>
                Contact Info
              </Text>
              <InfoCircleOutlined
                color={colors.PRIMARY}
                width={16}
                height={16}
                css={css`
                  font-size: 16px;
                  color: ${colors.PRIMARY};
                `}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default PageHeaderWithBackground
