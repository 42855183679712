/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import authActions from '@sportninja/common/actions/auth'
import userActions from '@sportninja/common/actions/user'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import {
  CANLAN_NAV_LINKS,
  CANLAN_NAV_LINKS_WITH_REGISTRATIONS,
  NAV_LINKS,
  ROUTES,
} from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { SearchContext } from '../../pages/Search/SearchContextProvider'
import Chat from '../Chat'
import EnvSelect from '../EnvSelect'
import Icon from '../Icon'
import { Flex } from '../Layout'
import MenuLink from '../MenuLink'
import Notifications from '../Notifications'
import { Desktop, Mobile } from '../Responsive'
import { font, MENU_HEIGHT } from '../css'
import MenuIcon from './MenuIcon'
// import ProfileSheet from './ProfileSheet'
import {
  BellOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import req from '@sportninja/common/api/request'
import Text from '../ts-components/Text'
import { Profile } from './Profile'
import { Header, Logo, Wrap } from './css'
import { ReactComponent as LogoIcon } from './logo.svg'
import { Input } from 'antd'

const MainNav = ({
  minimal = false,
  user,
  readUser,
  location,
  impersonate,
  logout,
}) => {
  const selectRef = useRef(null)
  const searchContext = useContext(SearchContext)
  const [isTeamOrSchedulePage, setIsTeamOrSchedulePage] = useState(false)
  const [menuHeight, setMenuHeight] = useState(MENU_HEIGHT)
  const [notificationsCount, setNotificationsCount] = useState(null)
  const [hasRegistrations, setHasRegistrations] = useState(false)

  const isPaymentPage = location?.pathname?.includes('pay')
  const isRegistrationInvitePage =
    location?.pathname?.includes('/registration/')
  const isLoginPage = location?.pathname?.includes('/login')
  const isRegisterPage = location?.pathname?.includes('/signup')
  const isTestPage = location?.pathname?.includes('/ant-test')

  const readNotificationsCount = async () => {
    // Also don't show the notifications if the user is not logged in
    if (!user) {
      return
    }
    // Detect if the URL contain the word public, if so, don't show the notifications
    if (location?.pathname?.includes('public')) {
      return
    }
    try {
      const result = await req('/notifications/count')
      if (result && result?.notifications) {
        setNotificationsCount(result?.notifications)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    readNotificationsCount()
  }, [location])

  // useEffect(() => {
  //   readNotificationsCount()
  // }, [])

  // Removing this due to the new page menu
  // useEffect(() => {
  //   const newValue = /team\/|schedule\//.test(location?.pathname)

  //   setIsTeamOrSchedulePage(newValue)
  //   setMenuHeight(newValue ? 64 : 50)
  // }, [location?.pathname])

  useEffect(() => {
    const checkPendingRegistrations = async () => {
      try {
        const result = await req('/users/me/has_registrations')
        if (result?.has_registrations && result?.has_registrations === true) {
          setHasRegistrations(true)
        } else {
          setHasRegistrations(false)
        }
      } catch (e) {
        setHasRegistrations(false)
      }
    }
    if (!user || !isCanlan) {
      setHasRegistrations(false)
      return
    }
    if (user && user.isValidated !== undefined) {
      checkPendingRegistrations()
    } else {
      setHasRegistrations(false)
    }
  }, [user])

  useEffect(() => {
    if (!minimal) {
      readUser()
    }
  }, [minimal])

  const logoLink = (
    <NavLink
      to={ROUTES.HOME}
      title={`${isCanlan ? 'Canlan Stats' : 'SportNinja'} | Home`}
    >
      {isCanlan ? (
        <img
          src='/images/canlan_logo.svg'
          alt='Canlan Logo'
          style={{ width: 52, height: '100%', marginLeft: -8 }}
        />
      ) : (
        <Logo>
          <LogoIcon />
        </Logo>
      )}
    </NavLink>
  )

  const inviteCountComponent = notificationsCount?.unread > 0 && (
    <div
      css={css`
        position: absolute;
        top: -10px;
        right: -10px;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background-color: ${colors.RED};
        padding-bottom: 1px;
      `}
    >
      <div
        css={css`
          color: ${colors.WHITE};
          ${font.title};
          font-size: 13px;
          font-weight: 700;
          text-align: center;
          letter-spacing: 1.2px;
        `}
      >
        {notificationsCount?.unread}
      </div>
    </div>
  )

  const hasSearchText = searchContext.searchText.length > 0

  if (
    isPaymentPage ||
    isLoginPage ||
    isRegistrationInvitePage ||
    isRegisterPage ||
    isTestPage
  ) {
    return <EnvSelect user={user} impersonate={impersonate} />
  }

  return (
    <Wrap menuHeight={menuHeight}>
      <EnvSelect user={user} impersonate={impersonate} />
      <Header menuHeight={menuHeight} alignItems='center' as='header'>
        <Flex as='nav' aria-labelledby='site-menu' alignItems='center'>
          <Mobile>
            {(isMobile) => {
              return searchContext.isActive && isMobile ? false : logoLink
            }}
          </Mobile>
          <div
            className={cn('main-nav-search-container', {
              active: searchContext.isActive,
              'is-team-or-schedule': isTeamOrSchedulePage,
              'is-filter-active': hasSearchText && !isCanlan,
              'is-filter-active-canlan': hasSearchText && isCanlan,
            })}
          >
            <input
              ref={searchContext.searchInputRef}
              type='text'
              className='main-nav-search-input'
              onFocus={() => {
                searchContext.setIsActive(true)
              }}
              onChange={({ target }) => {
                searchContext.setText(target.value)
              }}
              value={searchContext.searchText}
              placeholder='Search'
            />

            {hasSearchText && (
              <Flex
                className='search-filter-container'
                noFlex
                alignItems='center'
                css={css`
                  position: relative;
                  border: ${isCanlan ? 2 : 1}px solid #3f4753;
                  border-radius: ${isCanlan ? 0 : 4}px;
                  margin: 0 8px;
                  height: 32px;

                  &:hover {
                    border-color: white;
                  }

                  select:active {
                    border-color: white;
                  }

                  ${searchContext.filter.length > 0 &&
                  css`
                    border-color: ${colors.DEFAULT_FLAIR};
                  `}
                `}
              >
                <select
                  ref={selectRef}
                  css={css`
                    ${font.body}
                    color: white;
                    font-size: 15px;
                    font-weight: 500;

                    padding: 0 32px 0 8px;
                    border: 0;
                    background-color: ${colors.HEADER};
                  `}
                  value={searchContext.filter}
                  onChange={({ target }) => {
                    searchContext.setFilter(target.value)
                  }}
                >
                  <option value=''>All</option>

                  <option value='organization'>
                    {t('util:capitalize', {
                      text: t('common:organization', { count: 2 }),
                    })}
                  </option>
                  <option value='schedule'>
                    {t('util:capitalize', {
                      text: t('common:competition', { count: 2 }),
                    })}
                  </option>
                  <option value='team'>
                    {t('util:capitalize', {
                      text: t('common:team', { count: 2 }),
                    })}
                  </option>
                  <option value='player'>
                    {t('util:capitalize', {
                      text: t('common:player', { count: 2 }),
                    })}
                  </option>
                  <option value='venue'>
                    {t('util:capitalize', {
                      text: isCanlan ? 'Sports Complexes' : 'Locations',
                    })}
                  </option>
                </select>
                <div
                  className='search-filter-icon'
                  css={css`
                    display: flex;
                    pointer-events: none;
                    position: absolute;
                    right: 8px;
                  `}
                >
                  <Icon name='filter' fontSize={11} />
                </div>
              </Flex>
            )}
            <SearchOutlined
              css={css`
                position: absolute;
                cursor: normal;
                left: 16px;
                pointer-events: none;
                font-size: 16px;
                color: ${colors.NEUTRAL_50};
              `}
            />
            <Desktop>
              {hasSearchText && (
                <button
                  type='button'
                  css={css`
                    position: absolute;
                    cursor: normal;
                    left: ${isCanlan ? 284 : 248}px;
                    ${font.body}
                    color: ${colors.DEFAULT_FLAIR};
                    z-index: 1;
                  `}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    searchContext.setText('')
                    searchContext.searchInputRef.current.focus()
                  }}
                >
                  Clear
                </button>
              )}
            </Desktop>
            <Flex
              noFlex
              className='mobile-search-close-btn'
              alignItems='center'
              justifyContent='center'
              css={css`
                min-height: 30px;
                min-width: 30px;
                height: 30px;
                width: 30px;
                border: 1px solid white;
                border-radius: 6px;
              `}
              onClick={(e) => {
                e.stopPropagation()
                searchContext.setIsActive(false)
              }}
            >
              <Icon fontSize={18} name='times' color={colors.DEFAULT_FLAIR} />
            </Flex>
          </div>
          <MenuIcon
            color={searchContext.isActive ? colors.DEFAULT_FLAIR : undefined}
            name={searchContext.isActive ? 'times' : 'search'}
            onClick={(e) => {
              if (searchContext.isActive) {
                e.stopPropagation()
                e.preventDefault()
                searchContext.setIsActive(false)
                return
              } else {
                searchContext.setIsActive(true)
                setTimeout(() => {
                  searchContext.searchInputRef.current.focus()
                }, 0)
              }
            }}
            title={t('common:search')}
            css={css`
              /* margin-top: 4px; */
            `}
            customIcon={
              searchContext.isActive ? (
                <CloseCircleOutlined
                  css={css`
                    font-size: 24px;
                    color: ${colors.NEUTRAL_50};
                    margin-left: 16px;
                    position: absolute;
                    left: ${hasSearchText ? 444 : 300}px;
                    top: -11px;
                    z-index: 10000;
                  `}
                />
              ) : (
                <Input
                  prefix={
                    <SearchOutlined
                      css={css`
                        font-size: 16px;
                        color: ${colors.NEUTRAL_50};
                      `}
                    />
                  }
                  placeholder='Search'
                  css={css`
                    margin-left: 12px;
                    width: 400px;
                    background-color: ${colors.SECONDARY_500};
                    border: 1px solid ${colors.SECONDARY_200};
                    border-radius: 8px;
                    color: ${colors.WHITE};
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20.3px;
                    height: 36px;
                    margin-top: 2px;
                    .ant-input {
                      background-color: ${colors.SECONDARY_500};
                      color: ${colors.WHITE};
                    }
                    .ant-input-prefix {
                      color: ${colors.NEUTRAL_100};
                    }
                    &::placeholder {
                      color: ${colors.NEUTRAL_100};
                    }
                    @media (max-width: 768px) {
                      width: 100px;
                    }
                  `}
                />
              )
            }
          />
        </Flex>
        <Mobile>
          {(isMobile) => {
            return (
              !minimal &&
              (!isMobile || !searchContext.isActive) && (
                <Flex row alignItems='center' justifyContent='flex-end'>
                  <Desktop>
                    {!minimal && !isTeamOrSchedulePage && (
                      <Flex
                        as='ul'
                        className={cn('nav', {
                          // 'search-active': searchContext.isActive,
                          'search-active': false,
                        })}
                        noFlex
                      >
                        {isCanlan
                          ? hasRegistrations
                            ? CANLAN_NAV_LINKS_WITH_REGISTRATIONS().map(
                                MenuLink
                              )
                            : CANLAN_NAV_LINKS().map(MenuLink)
                          : NAV_LINKS().map(MenuLink)}
                      </Flex>
                    )}
                  </Desktop>

                  <Chat
                    loggedInUserId={user.id}
                    isTeamOrSchedulePage={isTeamOrSchedulePage}
                  />
                  <Notifications
                    refreshNotificationCount={readNotificationsCount}
                    Button={(toggle) => (
                      <MenuIcon
                        name='bell'
                        onClick={toggle}
                        title={t('Web:viewInvitations')}
                        css={css`
                          margin-top: 4px;
                          margin-right: 24px;
                        `}
                        customIcon={
                          <BellOutlined
                            css={css`
                              font-size: 24px;
                              color: ${colors.NEUTRAL_50};
                            `}
                          />
                        }
                      >
                        {inviteCountComponent}
                      </MenuIcon>
                    )}
                  />
                  <a
                    href={
                      isCanlan
                        ? 'https://faq.canlansports.com/hc/en-us/categories/6661539820187-Canlan-Stats-App'
                        : 'https://support.sportninja.com/'
                    }
                    rel='noreferrer'
                    target='_blank'
                    css={css`
                      margin-right: 24px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    `}
                  >
                    <MenuIcon
                      title='Support Documentation'
                      name='question-circle'
                      css={css`
                        margin-top: 3px;
                      `}
                      customIcon={
                        <QuestionCircleOutlined
                          css={css`
                            font-size: 24px;
                            color: ${colors.NEUTRAL_50};
                          `}
                        />
                      }
                    />
                  </a>
                  <Profile onLogoutClick={logout} />
                  {/* <ProfileSheet
                    Button={(toggle) => (
                      <button
                        type='button'
                        onClick={toggle}
                        css={css`
                          border: 2px solid transparent;

                          :hover {
                            border-radius: 50%;
                            border: 2px solid ${colors.PRIMARY};
                          }
                        `}
                      >
                        <Mobile>
                          {(isMobile) => (
                            <Picture
                              size={isMobile ? 'kxxsmall' : 'xxsmall'}
                              imageId={user?.imageId}
                            />
                          )}
                        </Mobile>
                      </button>
                    )}
                  /> */}
                </Flex>
              )
            )
          }}
        </Mobile>
      </Header>
    </Wrap>
  )
}

MainNav.propTypes = {
  user: PropTypes.object,
  unreadNotificationCount: PropTypes.number,
  minimal: PropTypes.bool.isRequired,
  readUser: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: bindActionToPromise(dispatch, authActions.logout.request),
    readUser: bindActionToPromise(dispatch, userActions.read.request),
    impersonate: bindActionToPromise(dispatch, authActions.impersonate.request),
  }
}

export default connect(null, mapDispatchToProps)(MainNav)
