/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useMemo, useState } from 'react'
import colors from '@sportninja/common/constants/appColors'
import Text from '../ts-components/Text'
import {
  HeartOutlined,
  HeartFilled,
  MoreOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { Dropdown, Image } from 'antd'
import sportninjaLogo from './sportninja-post.png'
import canlanLogo from './canlan-post.png'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { getFeedPostDate } from '@sportninja/common/utils/utils'
import VerifiedIcon from './VerifiedIcon'
import { paragraphSmall, weightBold, weightRegular } from '../css'

const convertMarkdownToHtml = (markdown) => {
  if (!markdown) {
    return ''
  }

  // Helper function to escape HTML special characters
  const escapeHtml = (text) => {
    const htmlEntities = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
    }
    return text?.replace(/[&<>"']/g, (char) => htmlEntities[char])
  }

  // Convert headers
  markdown = markdown?.replace(
    /^(#{1,6})\s(.+)$/gm,
    (_match, hashes, content) => {
      const level = hashes.length
      return `<h${level}>${escapeHtml(content)}</h${level}>`
    }
  )

  // Convert a single * to bold
  markdown = markdown.replace(/\*(.*?)\*/g, '<strong>$1</strong>')
  markdown = markdown.replace(/<strong>/g, '<span style="font-weight: bold;">')
  markdown = markdown.replace(/<\/strong>/g, '</span>')

  // Convert to italic
  markdown = markdown.replace(/\b_(.*?)_\b/g, '<em>$1</em>')
  markdown = markdown.replace(/<em>/g, '<span style="font-style: italic;">')
  markdown = markdown.replace(/<\/em>/g, '</span>')

  // Convert to strikethrough considering the ~
  markdown = markdown.replace(/~(.*?)~/g, '<s>$1</s>')
  markdown = markdown.replace(
    /<s>/g,
    '<span style="text-decoration: line-through;">'
  )
  markdown = markdown.replace(/<\/s>/g, '</span>')

  markdown = markdown.replace(/```(.*?)```/g, '<pre><code>$1</code></pre>')
  markdown = markdown.replace(
    /<pre>/g,
    '<span style="font-family: monospace;">'
  )
  markdown = markdown.replace(/<\/pre>/g, '</span>')

  // Convert links
  markdown = markdown.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>')

  // Convert unordered lists
  markdown = markdown.replace(/^\s*[-*+]\s(.+)$/gm, '<li>$1</li>')
  markdown = markdown.replace(/(<li>.*<\/li>)/s, '<ul>$1</ul>')

  // Convert ordered lists
  markdown = markdown.replace(/^\s*\d+\.\s(.+)$/gm, '<li>$1</li>')
  markdown = markdown.replace(/(<li>.*<\/li>)/s, '<ol>$1</ol>')

  // Convert code blocks
  markdown = markdown.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>')

  // Convert inline code
  markdown = markdown.replace(/`([^`]+)`/g, '<code>$1</code>')

  // Convert paragraphs (must be done last)
  markdown = markdown.replace(/^(?!<[uo]l|<li|<h\d|<pre)(.+)$/gm, '<p>$1</p>')

  return markdown
}

interface FeedItemProps {
  entityType: 'team' | 'schedule' | 'organization'
  title: string
  body: string
  imageUrl?: string
  linkUrl?: string
  likesCount: number
  commentsCount?: number
  createdAt: string
  avatarUrl?: string
  onCommentClick?: () => void
  onDeleteClick: () => void
  onReplySubmit?: (replyContent: string) => void
  onLikeClick: () => void
  entityName: string
  canEdit?: boolean
  canDelete?: boolean
  isAppPost?: boolean
  userAvatarUrl?: string
  entityId: string
  isLiked?: boolean
  userId?: string
  userName?: string
  userProfilePicture?: string
  onEditClick?: () => void
}

const parseUrlHost = (url: string): string | null => {
  try {
    const parsedUrl = new URL(url)
    return parsedUrl.host.replace(/^www\./, '')
  } catch (error) {
    console.error('Error parsing URL:', error)
    return url
  }
}

const FeedItem: React.FC<FeedItemProps> = ({
  userProfilePicture,
  entityId,
  entityType,
  title,
  body,
  imageUrl,
  linkUrl,
  likesCount,
  createdAt,
  avatarUrl,
  onDeleteClick,
  onLikeClick,
  entityName,
  canEdit,
  canDelete,
  isAppPost = false,
  isLiked: initialIsLiked = false,
  userName,
  onEditClick,
}) => {
  const isOrgOrSchedulePost =
    entityType === 'organization' || entityType === 'schedule'
  const htmlContent = convertMarkdownToHtml(body)
  const [isLiked, setIsLiked] = useState(initialIsLiked)
  const navigationUrl = useMemo(() => {
    if (isAppPost) {
      return undefined
    }
    if (isOrgOrSchedulePost) {
      return `/${
        entityType === 'organization' ? 'org' : 'schedule'
      }/${entityId}`
    }
    return `/${'team'}/${entityId}`
  }, [entityId, entityType, isAppPost, isOrgOrSchedulePost])

  const getPlaceholderImage = (isUser = false) => {
    if (isUser) {
      return '/images/generic_placeholders/placeholder_player.png'
    }
    switch (entityType) {
      case 'organization':
        return '/images/generic_placeholders/placeholder_organization.png'
      case 'team':
        return '/images/generic_placeholders/placeholder_team.png'
      case 'schedule':
        return '/images/generic_placeholders/placeholder_competition.png'
    }
  }
  const formattedHost = linkUrl ? parseUrlHost(linkUrl) : null

  return (
    <div
      css={css`
        background-color: ${colors.SECONDARY_300};
        border: 1px solid ${colors.SECONDARY_200};
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 16px;
      `}
    >
      {/* Header */}
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
        `}
      >
        {isOrgOrSchedulePost ? (
          <a
            href={navigationUrl}
            css={css`
              display: flex;
              align-items: center;
              gap: 12px;
              cursor: ${isAppPost ? 'default' : 'pointer'};
            `}
          >
            <img
              src={
                isAppPost
                  ? isCanlan
                    ? canlanLogo
                    : sportninjaLogo
                  : avatarUrl || getPlaceholderImage()
              }
              alt='Entity logo'
              css={css`
                width: 40px;
                height: 40px;
                border-radius: ${isAppPost ? '8px' : '4px'};
                object-fit: contain;
                background-color: ${colors.WHITE};
                border: ${!isAppPost
                  ? `1px solid ${colors.NEUTRAL_100}`
                  : 'none'};
              `}
            />
            <div>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 4px;
                `}
              >
                <Text
                  variant='paragraphMedium'
                  weight='bold'
                  color={colors.WHITE}
                >
                  {entityName || 'SportNinja'}
                </Text>
                {isAppPost && <VerifiedIcon />}
              </div>
              <Text
                variant='paragraphSmall'
                weight='regular'
                color={colors.NEUTRAL_100}
              >
                {getFeedPostDate(createdAt)}
              </Text>
            </div>
          </a>
        ) : (
          <a
            href={navigationUrl}
            css={css`
              display: flex;
              align-items: center;
              gap: 12px;
              cursor: ${isAppPost ? 'default' : 'pointer'};
            `}
          >
            <div
              css={css`
                position: relative;
                width: 40px;
                height: 40px;
              `}
            >
              <img
                src={
                  isAppPost
                    ? isCanlan
                      ? canlanLogo
                      : sportninjaLogo
                    : avatarUrl || getPlaceholderImage()
                }
                alt='Entity logo'
                css={css`
                  width: 100%;
                  height: 100%;
                  border-radius: ${isAppPost ? '8px' : '4px'};
                  object-fit: contain;
                  background-color: ${colors.WHITE};
                  border: ${!isAppPost
                    ? `1px solid ${colors.NEUTRAL_100}`
                    : 'none'};
                `}
              />
              <img
                src={userProfilePicture || getPlaceholderImage(true)}
                alt='User profile picture'
                css={css`
                  position: absolute;
                  bottom: -4px;
                  right: -4px;
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  object-fit: contain;
                  background-color: ${colors.WHITE};
                  border: 1px solid ${colors.NEUTRAL_100};
                `}
              />
            </div>
            <div>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 4px;
                `}
              >
                <Text
                  variant='paragraphMedium'
                  weight='bold'
                  color={colors.WHITE}
                >
                  {entityName}
                </Text>
              </div>
              <Text
                variant='paragraphSmall'
                weight='regular'
                color={colors.NEUTRAL_100}
              >
                {userName
                  ? `${userName} - ${getFeedPostDate(createdAt)}`
                  : getFeedPostDate(createdAt)}
              </Text>
            </div>
          </a>
        )}
        {canDelete || canEdit ? (
          <Dropdown
            menu={{
              items: [
                {
                  label: 'Edit',
                  key: 'edit',
                  icon: <EditOutlined />,
                  onClick: onEditClick,
                },
                {
                  label: 'Delete',
                  key: 'delete',
                  icon: <DeleteOutlined />,
                  onClick: onDeleteClick,
                },
              ],
            }}
            trigger={['click']}
          >
            <MoreOutlined
              css={css`
                color: ${colors.WHITE};
                font-size: 24px;
                cursor: pointer;
              `}
            />
          </Dropdown>
        ) : null}
      </div>

      {/* Content */}
      <div
        css={css`
          margin-bottom: 16px;
        `}
      >
        <Text
          variant='paragraphMedium'
          weight='bold'
          color={colors.WHITE}
          style={`
            margin-bottom: 8px;
          `}
        >
          {title}
        </Text>
        {/* <Text variant='paragraphSmall' weight='regular' color={colors.WHITE}>
          {body}
        </Text> */}
        <div
          dangerouslySetInnerHTML={{ __html: htmlContent }}
          className='home-post-body'
          css={css`
            ${paragraphSmall}
            ${weightRegular}
            line-height: 20px;
            color: ${colors.NEUTRAL_30};
            white-space: pre-wrap;
          `}
        />
      </div>
      {/* Image */}
      {imageUrl && (
        <div
          css={css`
            margin-bottom: 16px;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            margin-top: 16px;
            justify-content: center;
            align-items: center;
            display: flex;
            background-color: ${colors.SECONDARY_400};
            overflow: hidden;
            .ant-image {
              width: 100% !important;
              height: 100% !important;
              border-radius: 8px !important;
            }
            .ant-image .ant-image-img {
              height: 100% !important;
              object-fit: cover !important;
            }
          `}
        >
          <Image
            src={imageUrl}
            preview={{
              mask: <div>Click to Zoom</div>,
              maskStyle: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                backdropFilter: 'blur(2px)',
              },
            }}
            draggable={false}
          />
        </div>
      )}

      {/* Actions */}
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          /* margin-bottom: 16px; */
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
              border-radius: 8px;
              background: ${colors.SECONDARY_200};
              padding: 8px;
            `}
            onClick={() => {
              setIsLiked(!isLiked)
              onLikeClick()
            }}
          >
            {isLiked ? (
              <HeartFilled
                css={css`
                  color: ${colors.PRIMARY};
                `}
              />
            ) : (
              <HeartOutlined
                css={css`
                  color: ${colors.PRIMARY};
                `}
              />
            )}
            {likesCount > 0 && (
              <Text
                variant='paragraphSmall'
                weight='regular'
                color={colors.WHITE}
              >
                {likesCount} {likesCount === 1 ? 'Like' : 'Likes'}
              </Text>
            )}
          </div>
          {/* <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
              border-radius: 8px;
              background: ${colors.SECONDARY_200};
              padding: 8px;
            `}
            onClick={onCommentClick}
          >
            <MessageOutlined
              css={css`
                color: ${colors.PRIMARY};
              `}
            />
            {commentsCount > 0 && (
              <Text
                variant='paragraphSmall'
                weight='regular'
                color={colors.WHITE}
              >
                {commentsCount} {commentsCount === 1 ? 'Comment' : 'Comments'}
              </Text>
            )}
          </div> */}
        </div>
        {formattedHost && (
          <a
            href={linkUrl}
            target='_blank'
            rel='noopener noreferrer'
            css={css`
              text-decoration: none;
              display: flex;
              padding: 8px 16px;
              align-items: center;
              gap: 4px;
              border-radius: 8px;
              border: 1px solid ${colors.PRIMARY};
              /* max-width: 150px; */
            `}
          >
            <Text
              variant='paragraphSmall'
              weight='regular'
              color={colors.PRIMARY}
              style={`
                display: flex;
                align-items: center;
                gap: 4px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              `}
            >
              <span
                css={css`
                  ${paragraphSmall}
                  ${weightBold}
                  color: ${colors.PRIMARY};
                `}
              >
                Go To:
              </span>{' '}
              {formattedHost}
            </Text>
          </a>
        )}
      </div>

      {/* <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        <img
          src={
            userAvatarUrl ||
            '/images/generic_placeholders/placeholder_player.png'
          }
          alt='User avatar'
          css={css`
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: contain;
            border: 1px solid ${colors.NEUTRAL_100};
          `}
        />
        <Input.TextArea
          value={replyContent}
          onChange={(e) => setReplyContent(e.target.value)}
          placeholder='Add a comment'
          autoSize={{ minRows: 1, maxRows: 4 }}
          onPressEnter={(e) => {
            e.preventDefault()
            handleReplySubmit()
          }}
          css={css`
            border-radius: 8px;
            border: 1px solid ${colors.SECONDARY_100};
            background: ${colors.SECONDARY_200};
            color: ${colors.WHITE};
            &::placeholder {
              color: ${colors.NEUTRAL_100};
            }
          `}
        />
      </div> */}
    </div>
  )
}

export default FeedItem
