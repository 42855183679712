import req from '@sportninja/common/api/request'
import { useCallback, useState } from 'react'

type Image = {
  id: string
  filename: string
  full_path: string
  mime_type: string
  width: number
  height: number
  formats?: {
    id: string
    filename: string
    full_path: string
    mime_type: string
    width: number
    height: number
  }[]
}

export type TeamOfficial = {
  id: string
  name_first: string
  name_middle: string | null
  name_last: string
  full_name: string | null
  birth_date: string | null
  height: number | null
  weight: number | null
  created_at: string
  gender: string | null
  image: Image | null
  customer_identifier: string
  sf_id: string | null
  email: string
  phone_number: string
  is_accepted: boolean
  is_invited: boolean
  league_registration_number: string | null
  player_type: string | null
}

export const useUserTeamOfficials = () => {
  const [data, setData] = useState<TeamOfficial[] | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const response = await req('/users/me/team-officials2')
      setData(response.data)
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error('An error occurred while fetching user team officials')
      )
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    data,
    loading,
    error,
    refetch: fetchData,
  }
}
