/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { Alert, App, Input, Select } from 'antd'
import dayjs from 'dayjs'
import { useContext, useState } from 'react'
import { EventCard } from 'src/components/RegistrationEventCard'
import { EventProgress } from 'src/components/RegistrationEventProgress'
import { RegistrationContext } from '../context/RegistrationContext'
import {
  PageTitle,
  REGISTRATION_STEPS,
  alternateButtonStyle,
  buttonStyle,
  paragraphSmallStyle,
  paragraphXSmallStyle,
} from './Main'
import Button from 'src/components/ts-components/Button'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirthYear: '',
  dateOfBirthMonth: '',
  dateOfBirthDay: '',
  password: '',
  confirmPassword: '',
  dateOfBirth: '',
}

export const SignUp = () => {
  const {
    session,
    setStep,
    registration,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    loginRegistration,
    isLogged,
    setBirthDate,
    updateSessionSignUp,
    division,
  } = useContext(RegistrationContext)
  const { message } = App.useApp()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [dateOfBirthYear, setDateOfBirthYear] = useState('')
  const [dateOfBirthMonth, setDateOfBirthMonth] = useState('01')
  const [dateOfBirthDay, setDateOfBirthDay] = useState('01')
  const [verifyError, setVerifyError] = useState(false)
  const [verifySuccess, setVerifySuccess] = useState(false)
  const [signUpSuccess, setSignUpSuccess] = useState(false)
  const [fieldErrors, setFieldErrors] = useState([])
  const [customError, setCustomError] = useState(initialValues)
  const [isLoading, setIsLoading] = useState(false)

  // if (isLogged) {
  //   setStep(REGISTRATION_STEPS.SELECT_YOUR_ROLE)
  // }

  const verifyEmail = async () => {
    try {
      setIsLoading(true)
      setVerifyError(false)
      setVerifySuccess(false)
      setFieldErrors([])
      setCustomError(initialValues)
      await req('/auth/exists', {
        method: 'POST',
        requiresAuth: false,
        body: JSON.stringify({
          email,
        }),
      })
      setVerifySuccess(true)
      setEmail(email)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      if (
        e?.invalid_fields &&
        !errorMessage.includes('The e-mail address has already been taken')
      ) {
        setCustomErrors(e.invalid_fields)
        setVerifySuccess(false)
        return
      }
      if (errorMessage.includes('The e-mail address has already been taken')) {
        setStep(REGISTRATION_STEPS.LOGIN)
        return
      }
      message.open({
        type: 'error',
        content: getErrorMessage(e),
        duration: 5,
      })
      setVerifyError(errorMessage)
      setVerifySuccess(false)
    } finally {
      setIsLoading(false)
    }
  }

  const signUp = async () => {
    try {
      setIsLoading(true)
      message.open({
        type: 'loading',
        content: 'Creating your account',
        duration: 0,
      })
      setVerifyError(false)
      setFieldErrors([])
      setCustomError(initialValues)
      const dateOfBirth = `${dateOfBirthYear}-${dateOfBirthMonth}-${dateOfBirthDay.padStart(
        2,
        '0'
      )}`
      const body = JSON.stringify({
        name_first: firstName,
        name_last: lastName,
        email: email,
        password: password,
        confirm_password: confirmPassword,
        birth_date: dateOfBirth,
        user_signup_type: 'registration',
        registration_session_id: session?.id,
      })
      await req(`/registration/${registration.id}/signup`, {
        method: 'POST',
        requiresAuth: false,
        body,
      })
      setBirthDate(dateOfBirth)
      message.destroy()
      // await 2 seconds
      message.open({
        type: 'success',
        content: 'Account created! Logging you in',
        duration: 5,
      })
      setStep(REGISTRATION_STEPS.CONFIRM_YOUR_EMAIL)
      setSignUpSuccess(true)
      await new Promise((resolve) => setTimeout(resolve, 2000))
      const response = await loginRegistration({ email, password })
      await updateSessionSignUp(
        response.access_token,
        division?.schedule_id,
        response?.user?.id
      )
    } catch (e) {
      message.destroy()
      if (e?.invalid_fields) {
        setCustomErrors(e.invalid_fields)
        return
      }
      const errorMessage = getErrorMessage(e)
      message.open({
        type: 'error',
        content: errorMessage,
        duration: 5,
      })
      setVerifyError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const setCustomErrors = (invalid_fields) => {
    if (invalid_fields.name_first) {
      setCustomErroByField('firstName', invalid_fields.name_first)
    }
    if (invalid_fields.name_last) {
      setCustomErroByField('lastName', invalid_fields.name_last)
    }
    if (invalid_fields.email) {
      setCustomErroByField('email', invalid_fields.email)
    }
    if (invalid_fields.birth_date) {
      setCustomErroByField('dateOfBirth', invalid_fields.birth_date)
    }
    if (invalid_fields.password) {
      setCustomErroByField('password', invalid_fields.password)
    }
    if (invalid_fields.confirm_password) {
      setCustomErroByField('confirmPassword', invalid_fields.confirm_password)
    }
  }

  const setCustomErroByField = (field, message) => {
    setCustomError((prev) => ({ ...prev, [field]: message }))
    setFieldErrors((prevErrors) => [...new Set([...prevErrors, field])])
  }

  return (
    <>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
          background-color: ${colors.NEUTRAL_800};
          width: 1080px;
          padding: 0 35px 35px 35px;
          border-radius: 16px 16px 0px 0px;
          @media (max-width: 768px) {
            min-width: 0;
            width: 100%;
          }
        `}
      >
        <EventCard eventInfo={registration} />
        <EventProgress eventProgress={1} />
        {!verifySuccess && !signUpSuccess ? (
          <div>
            {/* separator */}
            {/* <div
            css={css`
              width: 100%;
              height: 1px;
              background-color: ${colors.NEUTRAL_600};
              margin: 16px 0;
            `}
          /> */}
            <div
              css={css`
                display: flex;
                flex: 2;
                flex-direction: row;
                gap: 16px;
                margin-bottom: 24px;
                margin-top: 24px;
                /* on mobile, change the flex direction */
                @media (max-width: 600px) {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 100%;
                  align-self: stretch;
                  gap: 16px;
                }
              `}
            >
              {/* message */}
              <div
                css={css`
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  gap: 16px;
                `}
              >
                <p
                  css={css`
                    color: ${colors.WHITE};
                    font-family: 'BarlowCondensed', sans-serif;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%; /* 38.4px */
                  `}
                >
                  {"Let's Get Started!"}
                </p>
                <p
                  css={css`
                    color: ${colors.WHITE};
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 145%;
                  `}
                >
                  Enter your email to access your account. New to SportNinja? No
                  worries! Sign up for a free account using your email address.
                </p>
              </div>
              {/* input */}
              <div
                css={css`
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  justify-content: center;
                  /* on mobile, set the width to 100% */
                  @media (max-width: 600px) {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-self: stretch;
                  }
                `}
              >
                <p
                  css={css`
                    margin-bottom: 8px;
                    color: ${colors.WHITE};
                    ${paragraphSmallStyle}
                  `}
                >
                  Email *
                </p>
                <Input
                  name='email'
                  placeholder='Email'
                  type='email'
                  autoComplete='email'
                  autoCorrect='off'
                  autoCapitalize='none'
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={verifySuccess}
                  status={fieldErrors.includes('email') ? 'error' : undefined}
                />
                <div
                  tabIndex={-1}
                  css={css`
                    margin-top: 8px;
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${fieldErrors.includes('email') ? 1 : 0};
                    font-size: 13px;
                    line-height: 16px;
                    color: ${colors.ERROR_100};
                  `}
                >
                  <span>{customError.email}</span>
                </div>
              </div>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 8px;
                width: 100%;
                justify-content: flex-end;
                align-self: flex-end;
                @media (max-width: 768px) {
                  width: 100%;
                  flex-direction: column-reverse;
                }
              `}
            >
              <Button
                text='BACK'
                onClick={() => {
                  if (!verifySuccess) {
                    setStep(REGISTRATION_STEPS.SELECT_YOUR_DIVISION)
                    return
                  }
                  setVerifySuccess(false)
                }}
                variant='secondary'
                style={css`
                  width: 25%;
                `}
              />
              <Button
                text='NEXT'
                onClick={async () =>
                  !verifySuccess ? await verifyEmail() : await signUp()
                }
                disabled={email === '' || isLoading}
                variant='primary'
                style={css`
                  width: 25%;
                `}
              />
            </div>
          </div>
        ) : (
          <>
            <PageTitle
              title={"Let's Get Started!"}
              subtitle={
                verifySuccess
                  ? "It appears that you don't have an account yet. Let's create one now."
                  : 'Enter your email to access your account. New to SportNinja? No worries! Sign up for a free account using your email address.'
              }
              showAlert={false}
              showSuccess={false}
            />
            <div
              css={css`
                width: 100%;
                margin-bottom: 16px;
              `}
            >
              <p
                css={css`
                  margin-bottom: 8px;
                  color: ${colors.WHITE};
                  ${paragraphSmallStyle}
                `}
              >
                Email *
              </p>
              <Input
                name='email'
                placeholder='Email'
                type='email'
                autoComplete='email'
                autoCorrect='off'
                autoCapitalize='none'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={verifySuccess}
              />
              {verifySuccess ? (
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 8px;
                    align-self: stretch;
                    margin-top: 16px;
                    /* on mobile, change the orientation */
                    @media (max-width: 600px) {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      width: 100%;
                      align-self: stretch;
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 4px;
                      flex: 1 0 0;
                      @media (max-width: 600px) {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        align-self: stretch;
                      }
                    `}
                  >
                    <p
                      css={css`
                        ${paragraphSmallStyle}
                        color: ${colors.WHITE};
                        align-self: stretch;
                      `}
                    >
                      First Name *
                    </p>
                    <Input
                      name='firstName'
                      placeholder='John'
                      type='text'
                      autoComplete='given-name'
                      autoCorrect='off'
                      autoCapitalize='none'
                      required
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      status={
                        fieldErrors.includes('firstName') ? 'error' : undefined
                      }
                    />
                    <div
                      tabIndex={-1}
                      css={css`
                        margin-top: 8px;
                        transition: opacity 0.1s ease-in-out;
                        opacity: ${fieldErrors.includes('firstName') ? 1 : 0};
                        font-size: 13px;
                        line-height: 16px;
                        color: ${colors.ERROR_LIGHT};
                      `}
                    >
                      <span>{customError.firstName}</span>
                    </div>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 4px;
                      flex: 1 0 0;
                      @media (max-width: 600px) {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        align-self: stretch;
                      }
                    `}
                  >
                    <p
                      css={css`
                        ${paragraphSmallStyle}
                        color: ${colors.WHITE};
                        align-self: stretch;
                      `}
                    >
                      Last Name *
                    </p>
                    <Input
                      name='lastName'
                      placeholder='Doe'
                      type='text'
                      autoComplete='family-name'
                      autoCorrect='off'
                      autoCapitalize='none'
                      required
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      status={
                        fieldErrors.includes('lastName') ? 'error' : undefined
                      }
                    />
                    <div
                      tabIndex={-1}
                      css={css`
                        margin-top: 8px;
                        transition: opacity 0.1s ease-in-out;
                        opacity: ${fieldErrors.includes('lastName') ? 1 : 0};
                        font-size: 13px;
                        line-height: 16px;
                        color: ${colors.ERROR_LIGHT};
                      `}
                    >
                      <span>{customError.lastName}</span>
                    </div>
                  </div>
                </div>
              ) : null}
              {verifySuccess ? (
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 8px;
                    align-self: stretch;
                    margin-top: 16px;
                    /* on mobile, change the orientation */
                    @media (max-width: 600px) {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      width: 100%;
                      align-self: stretch;
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 4px;
                      flex: 1 0 0;
                      @media (max-width: 600px) {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        align-self: stretch;
                      }
                    `}
                  >
                    <p
                      css={css`
                        ${paragraphSmallStyle}
                        color: ${colors.WHITE};
                        align-self: stretch;
                      `}
                    >
                      Password *
                    </p>
                    <Input.Password
                      name='password'
                      placeholder='Password'
                      type='password'
                      autoComplete='new-password'
                      autoCorrect='off'
                      autoCapitalize='none'
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      status={
                        fieldErrors.includes('password') ? 'error' : undefined
                      }
                    />
                    <div
                      tabIndex={-1}
                      css={css`
                        margin-top: 8px;
                        transition: opacity 0.1s ease-in-out;
                        opacity: ${fieldErrors.includes('password') ? 1 : 0};
                        font-size: 13px;
                        line-height: 16px;
                        color: ${colors.ERROR_LIGHT};
                      `}
                    >
                      <span>{customError.password}</span>
                    </div>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 4px;
                      flex: 1 0 0;
                      @media (max-width: 600px) {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        align-self: stretch;
                      }
                    `}
                  >
                    <p
                      css={css`
                        ${paragraphSmallStyle}
                        color: ${colors.WHITE};
                        align-self: stretch;
                      `}
                    >
                      Confirm Password *
                    </p>
                    <Input.Password
                      name='confirmPassword'
                      placeholder='Confirm Password'
                      type='password'
                      autoComplete='new-password'
                      autoCorrect='off'
                      autoCapitalize='none'
                      required
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      status={
                        fieldErrors.includes('confirmPassword')
                          ? 'error'
                          : undefined
                      }
                    />
                    <div
                      tabIndex={-1}
                      css={css`
                        margin-top: 8px;
                        transition: opacity 0.1s ease-in-out;
                        opacity: ${fieldErrors.includes('confirmPassword')
                          ? 1
                          : 0};
                        font-size: 13px;
                        line-height: 16px;
                        color: ${colors.ERROR_LIGHT};
                      `}
                    >
                      <span>{customError.confirmPassword}</span>
                    </div>
                  </div>
                </div>
              ) : null}
              {verifySuccess ? (
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    flex: 1;
                    width: 100%;
                    margin-top: 16px;
                    /* if mobile, change the direction */
                    @media (max-width: 600px) {
                      display: flex;
                      flex-direction: column;
                      /* align-items: center; */
                      width: 100%;
                      align-self: stretch;
                    }
                  `}
                >
                  <p
                    tabIndex={-1}
                    css={css`
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 14px;
                      color: ${colors.WHITE};
                    `}
                  >
                    Birth Date *
                  </p>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 8px;
                      flex: 1;
                      height: 40px;
                      width: 50%;
                      flex-direction: row;
                      @media (max-width: 600px) {
                        display: flex;
                        flex-direction: column;
                        /* align-items: center; */
                        width: 100%;
                        align-self: stretch;
                      }
                    `}
                  >
                    <Select
                      options={[
                        { label: 'January', value: '01' },
                        { label: 'February', value: '02' },
                        { label: 'March', value: '03' },
                        { label: 'April', value: '04' },
                        { label: 'May', value: '05' },
                        { label: 'June', value: '06' },
                        { label: 'July', value: '07' },
                        { label: 'August', value: '08' },
                        { label: 'September', value: '09' },
                        { label: 'October', value: '10' },
                        { label: 'November', value: '11' },
                        { label: 'December', value: '12' },
                      ]}
                      defaultValue={'01'}
                      value={dateOfBirthMonth}
                      onChange={(value) => {
                        setDateOfBirthMonth(value)
                        // Reset day if current selection is invalid for new month
                        const daysInMonth = dayjs(
                          `${dateOfBirthYear}-${value}-01`
                        ).daysInMonth()
                        if (parseInt(dateOfBirthDay) > daysInMonth) {
                          setDateOfBirthDay('01')
                        }
                      }}
                      css={css`
                        width: 100%;
                      `}
                      status={
                        fieldErrors.includes('dateOfBirth')
                          ? 'error'
                          : undefined
                      }
                      autoComplete='bday-month'
                    />
                    <Select
                      options={Array.from(
                        {
                          length: dayjs(
                            `${dateOfBirthYear}-${dateOfBirthMonth}-01`
                          ).daysInMonth(),
                        },
                        (_, i) => ({
                          label: (i + 1).toString(),
                          value: (i + 1).toString().padStart(2, '0'),
                        })
                      )}
                      css={css`
                        width: 100%;
                      `}
                      value={dateOfBirthDay}
                      defaultValue={'01'}
                      status={
                        fieldErrors.includes('dateOfBirth')
                          ? 'error'
                          : undefined
                      }
                      onChange={(value) => {
                        setDateOfBirthDay(value)
                      }}
                      autoComplete='bday-day'
                    />

                    <Input
                      name='dateOfBirthYear'
                      placeholder='YYYY'
                      type='number'
                      autoComplete='bday-year'
                      autoCorrect='off'
                      autoCapitalize='none'
                      required
                      value={dateOfBirthYear}
                      onChange={(e) => setDateOfBirthYear(e.target.value)}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 4)
                      }}
                      status={
                        fieldErrors.includes('dateOfBirth')
                          ? 'error'
                          : undefined
                      }
                      maxLength={4}
                      min={1900}
                      max={dayjs().year()}
                    />
                  </div>
                  <div
                    css={css`
                      transition: opacity 0.1s ease-in-out;
                      opacity: ${fieldErrors.includes('dateOfBirth') ? 1 : 0};
                      font-size: 13px;
                      line-height: 16px;
                      color: ${colors.ERROR_LIGHT};
                      margin-top: 4px;
                    `}
                  >
                    <span>{customError.dateOfBirth}</span>
                  </div>
                </div>
              ) : null}
            </div>
            {verifyError ? (
              <Alert
                message='Error'
                description={verifyError}
                type='error'
                showIcon
                css={css`
                  width: 100%;
                  max-width: 1000px;
                  margin-bottom: 16px;
                  /* limit the max-width on mobile */
                  @media (max-width: 600px) {
                    max-width: 100%;
                  }
                `}
              />
            ) : null}
            {signUpSuccess ? (
              <Alert
                message='Success'
                description='In a few seconds, you will be redirected to verify your email.'
                type='success'
                showIcon
                css={css`
                  width: 100%;
                  max-width: 1000px;
                  margin-bottom: 16px;
                  /* limit the max-width on mobile */
                  @media (max-width: 600px) {
                    max-width: 100%;
                  }
                `}
              />
            ) : null}
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 8px;
                width: 100%;
                justify-content: flex-end;
                align-self: flex-end;
                @media (max-width: 768px) {
                  width: 100%;
                  flex-direction: column-reverse;
                }
              `}
            >
              <button
                onClick={() => {
                  if (!verifySuccess) {
                    setStep(REGISTRATION_STEPS.SELECT_YOUR_DIVISION)
                    return
                  }
                  setVerifySuccess(false)
                }}
                css={css`
                  ${alternateButtonStyle}
                  width: 25%;
                  @media (max-width: 768px) {
                    width: 100%;
                  }
                `}
              >
                BACK
              </button>
              <button
                onClick={async () =>
                  !verifySuccess ? await verifyEmail() : await signUp()
                }
                disabled={email === '' || isLoading}
                css={css`
                  ${buttonStyle}
                  width: 25%;
                  opacity: ${isLoading ? 0.5 : 1};
                  cursor: ${isLoading ? 'not-allowed' : 'pointer'};
                  @media (max-width: 768px) {
                    width: 100%;
                  }
                `}
              >
                {'NEXT'}
              </button>
            </div>
            {verifySuccess ? (
              <p
                css={css`
                  ${paragraphXSmallStyle}
                  color: ${colors.WHITE};
                  align-self: stretch;
                  margin-top: 16px;
                `}
              >
                By proceeding you agree to the{' '}
                <a
                  css={css`
                    color: ${colors.PRIMARY};
                  `}
                  href='https://www.sportninja.com/privacy-policy'
                  target='_blank'
                  rel='noreferrer'
                >
                  Terms & Conditions
                </a>{' '}
                and the{' '}
                <a
                  href='https://www.sportninja.com/terms-of-use'
                  target='_blank'
                  rel='noreferrer'
                  css={css`
                    color: ${colors.PRIMARY};
                  `}
                >
                  Privacy Policy
                </a>
                .
              </p>
            ) : null}
          </>
        )}
      </div>
    </>
  )
}
