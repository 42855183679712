/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import css from '@emotion/css/macro'
import { connect } from 'react-redux'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import { t } from '@sportninja/common/i18n'
import teamActions from '@sportninja/common/actions/team'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'

import { playerForm } from '../../components/Form/form-configs'
import { SheetInnerForm } from '../../components/Form/FormSheet'
import Sheet from '../../components/Sheet'
import { SearchMenu } from '../../components/SearchMenu'
import { getSearchTypes } from '../../components/SearchMenu/search-menu-helpers'
import { Flex } from '../../components/Layout'
import { FormButton } from '../../components/Form/css'
import { FollowBtnButton } from '../../components/FollowBtn'
import NewButton from '../../components/Page/NewButton'
import Checkbox from 'antd/es/checkbox/Checkbox'
import colors from '@sportninja/common/constants/appColors'
import req from '@sportninja/common/api/request'

const PlayerSearchResult = ({ onClick }) => {
  return (
    <FollowBtnButton onClick={onClick}>{t('common:select')}</FollowBtnButton>
  )
}

const SearchStep = ({
  isHidden,
  onCreateClick,
  onResultClick,
  players,
  orgId,
  orgName,
}) => {
  const searchTypes = getSearchTypes()
  const [enableGlobalSearch, setEnableGlobalSearch] = useState(false)

  return (
    <Flex
      column
      css={css`
        max-height: 500px;
        display: ${isHidden ? 'none' : 'flex'};
      `}
    >
      <Checkbox
        checked={enableGlobalSearch}
        css={css`
          cursor: default !important;
          font-family: 'Maison Neue', sans-serif !important;
          color: ${colors.WHITE};
          margin-bottom: 8px;
        `}
        onChange={(e) => setEnableGlobalSearch(e.target.checked)}
      >
        Enable Global Search
      </Checkbox>
      <SearchMenu
        enableGlobalSearch={enableGlobalSearch}
        alwaysShowPager
        noResultsText={`Search For Players In The "${orgName}" ${t(
          'common:organization'
        )}`}
        noEmptySearch
        searchTypes={{
          [ENTITY_TYPES.player]: {
            ...searchTypes[ENTITY_TYPES.player],
            searchText: 'Search For An Existing Player To Add',
            isAdminSearch: true,
            slug: 'search/admin/players',
            // this endpoint requires the organization of the team to be sent along
            query: enableGlobalSearch ? null : `&organization=${orgId}`,
          },
        }}
        onResultClick={onResultClick}
        ResultButton={({ result }) => (
          <PlayerSearchResult onClick={() => onResultClick(result)} />
        )}
        filter={(result) => {
          if (!Array.isArray(players)) {
            return true
          }
          return players.findIndex((p) => p.id === result.id) === -1
        }}
      />
      <p
        css={css`
          margin-top: 8px;
          margin-bottom: 20px;
          text-align: center;
        `}
      >
        - OR -
      </p>
      <FormButton onClick={onCreateClick}>Create a new player</FormButton>
    </Flex>
  )
}

const InnerCreatePlayer = ({
  addExistingPlayer,
  createPlayer,
  isOpen,
  orgId,
  orgName,
  players,
  toggleOpen,
  hasAdminPermission = false,
  creatingPlayersRequiresEmail = false,
  sportName = 'hockey',
  triedToCreateExistingPlayer = false,
  setTriedToCreateExistingPlayer = () => {},
  numberOfSubmissions = 0,
  setNumberOfSubmissions = () => {},
  sportId = null,
}) => {
  const [player, setPlayer] = useState({})
  const [step, setStep] = useState(0)

  useEffect(() => {
    if (!isOpen) {
      setStep(0)
    }
  }, [isOpen])

  const useExistingPlayer = Object.keys(player).length > 0

  return (
    <>
      <SearchStep
        isHidden={step !== 0}
        onCreateClick={() => setStep(1)}
        onResultClick={(result) => {
          setPlayer(result)
          setStep(1)
        }}
        players={players}
        orgId={orgId}
        orgName={orgName}
      />
      <div
        css={css`
          display: ${step === 0 ? 'none' : 'block'};
        `}
      >
        {step !== 0 && (
          <SheetInnerForm
            disableToggleOnCancel
            form={playerForm(
              player,
              useExistingPlayer,
              creatingPlayersRequiresEmail,
              hasAdminPermission,
              sportName,
              triedToCreateExistingPlayer,
              numberOfSubmissions,
              sportId
            )}
            onCancel={() => {
              setNumberOfSubmissions(0)
              setTriedToCreateExistingPlayer(false)
              setPlayer({})
              setStep(0)
            }}
            onSubmit={
              useExistingPlayer
                ? addExistingPlayer.bind(this, player.id)
                : createPlayer
            }
            toggleOpen={toggleOpen}
          />
        )}
      </div>
    </>
  )
}

const CreatePlayerSheet = ({
  sportId = null,
  sportName = 'hockey',
  teamId,
  orgId,
  orgName,
  rosterId,
  players = [],
  hasAdminPermission = false,
  creatingPlayersRequiresEmail = false,
  onSuccess,

  // Redux
  addExistingPlayer,
  createPlayer,
}) => {
  const [triedToCreateExistingPlayer, setTriedToCreateExistingPlayer] =
    useState(false)
  const [numberOfSubmissions, setNumberOfSubmissions] = useState(0)

  const EXIST_PLAYER_ERROR_MESSAGE =
    'Player with same email exists. Please add existing player using Add Player Search.'

  const ANOTHER_EXIST_PLAYER_ERROR_MESSAGE =
    'Player with same name and email exists. Please add existing player using Add Player Search.'

  return (
    <Sheet
      button={(toggleOpen) => {
        return (
          <NewButton
            onClick={() => {
              setTriedToCreateExistingPlayer(false)
              setNumberOfSubmissions(0)
              toggleOpen()
            }}
          />
        )
      }}
      title={t('Web:addPlayer')}
    >
      {(toggleOpen, setDirty, isOpen) => (
        <InnerCreatePlayer
          sportName={sportName}
          addExistingPlayer={async (...args) => {
            // We are replacing the addExistingPlayer action with a custom request
            // due to the changes on SN-990
            // const response = await addExistingPlayer(teamId, rosterId, ...args)
            const playerId = args[0]
            const playerData = args[1]
            const body = {
              player_number: playerData?.player_number,
              player_type_id: playerData?.player_type_id,
              is_affiliate: playerData?.is_affiliate || false,
            }
            const response = await req(
              `/teams/${teamId}/rosters/${rosterId}/players/${args[0]}/copy`,
              {
                method: 'PUT',
                body: JSON.stringify(body),
              }
            )
            await onSuccess()
            return response
          }}
          createPlayer={async (...args) => {
            try {
              const response = await createPlayer(teamId, rosterId, ...args)
              await onSuccess()
              setTriedToCreateExistingPlayer(false)
              setNumberOfSubmissions(0)
              return response
            } catch (e) {
              // The error itself is already handled
              // We just need to set the flag to show the error message
              if (
                (e?.invalid_fields &&
                  e?.invalid_fields?.player &&
                  e?.invalid_fields?.player === EXIST_PLAYER_ERROR_MESSAGE) ||
                e?.invalid_fields?.player === ANOTHER_EXIST_PLAYER_ERROR_MESSAGE
              ) {
                setTriedToCreateExistingPlayer(true)
                setNumberOfSubmissions(numberOfSubmissions + 1)
                return
              }
              throw e
            }
          }}
          isOpen={isOpen}
          toggleOpen={toggleOpen}
          orgId={orgId}
          orgName={orgName}
          players={players}
          hasAdminPermission={hasAdminPermission}
          creatingPlayersRequiresEmail={creatingPlayersRequiresEmail}
          triedToCreateExistingPlayer={triedToCreateExistingPlayer}
          setTriedToCreateExistingPlayer={setTriedToCreateExistingPlayer}
          numberOfSubmissions={numberOfSubmissions}
          setNumberOfSubmissions={setNumberOfSubmissions}
          sportId={sportId}
        />
      )}
    </Sheet>
  )
}

const mapDispatchToProps = (dispatch) => ({
  createPlayer: bindActionToPromise(
    dispatch,
    teamActions.roster.player.create.request
  ),
  addExistingPlayer: bindActionToPromise(
    dispatch,
    teamActions.roster.player.update.request
  ),
})

export default connect(null, mapDispatchToProps)(CreatePlayerSheet)
