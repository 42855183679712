/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Checkbox } from 'antd'
import React from 'react'
import { ErrorLabel } from '../ErrorLabel'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import Text from '../Text'

type CheckBoxInputProps = {
  label: string | React.ReactNode
  hint?: string
  disabled: boolean
  name: string
  value: boolean
  onChange: (e: CheckboxChangeEvent) => void
  fieldErrors: any
  customError: any
  checkBoxStyle?: any
  extraSmallFont?: boolean
}

export const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
  label,
  disabled,
  name,
  value,
  onChange,
  fieldErrors,
  customError,
  checkBoxStyle,
  hint,
  extraSmallFont = false,
}) => {
  return (
    <>
      <Checkbox
        disabled={disabled}
        onChange={(e) => onChange(e)}
        checked={value}
        css={[
          css`
            width: 100%;
          `,
          checkBoxStyle,
        ]}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
          width: 100%;
        `}
      >
        <Text
          variant={extraSmallFont ? 'paragraphXSmall' : 'paragraphSmall'}
          weight='regular'
          color={colors.WHITE}
        >
          {label}
        </Text>
        {hint && (
          <Text
            variant='paragraphXSmall'
            weight='regular'
            color={colors.NEUTRAL_100}
          >
            {hint}
          </Text>
        )}
      </div>
      <ErrorLabel
        fieldErrors={fieldErrors}
        customError={customError}
        name={name}
      />
    </>
  )
}
