/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'
import colors from '@sportninja/common/constants/appColors'
import debounce from 'lodash.debounce'
import { useMemo } from 'react'

import { zIndex } from '../../css'
import LoadingSpinner from '../../LoadingSpinner'
import { Pager } from '../../Pagination'
import ThumbnailAndName from '../../ThumbnailAndName'

const NUM_ADJACENT_PAGINATION = 1

const SuspensionSearchSelectResults = ({
  getSubTitle,
  items,
  isOpen,
  meta,
  noImage,
  noSearch,
  onChange,
  onPageChange,
  loadingResults,
  searchTerm,
  setIsOpen,
  iconName,
  useInitials,
  squareThumbnail,
  isFocused,
  useInfiniteScrolling,
}) => {
  const onScroll = useMemo(
    () =>
      debounce(
        (e) => {
          if (
            e.target.scrollTop > 140 &&
            useInfiniteScrolling &&
            meta?.current_page < meta?.total_pages
          ) {
            onPageChange(meta?.current_page + 1)
          }
        },
        300,
        {
          trailing: true,
        }
      ),
    [useInfiniteScrolling, meta?.current_page, meta?.total_pages]
  )

  if (!isOpen) {
    return false
  }

  const hasMorePages = meta?.current_page < meta?.total_pages

  return (
    <div
      onScroll={onScroll}
      className='suspension-search-results'
      css={css`
        overflow-y: auto;
        max-height: 440px;
        padding: ${searchTerm?.length > 0 || noSearch || isFocused
          ? '8px 8px 0px 8px'
          : '0'};
        z-index: ${zIndex.modal};
      `}
    >
      <div
        css={css`
          .item-row {
            ${noImage &&
            css`
              margin-bottom: 8px;
            `}
          }
        `}
      >
        {loadingResults ? (
          <div className='suspension-search-results-loading-spinner'>
            <LoadingSpinner size={2} borderSize={2} />
          </div>
        ) : items.length > 0 ? (
          items?.map((item, idx) => {
            const subtitle = getSubTitle(item)

            return (
              <div
                key={item.id}
                className='item-row'
                onClick={() => {
                  onChange(item)
                  setIsOpen(false)
                }}
              >
                {noImage ? (
                  <div
                    css={css`
                      position: relative;
                    `}
                  >
                    <div className='no-image-title'>{item.fullName}</div>
                    {subtitle && (
                      <div className='item-subtitle'>{subtitle}</div>
                    )}
                    {idx !== items?.length - 1 && (
                      <div className='no-image-item-bottom-border' />
                    )}
                  </div>
                ) : (
                  <ThumbnailAndName
                    size='kxsmall'
                    row={item}
                    iconName={iconName}
                    useInitials={useInitials}
                    fit={squareThumbnail}
                    square={squareThumbnail}
                    initialsSize={40}
                    textClassName='item-row-name'
                    subTitle={
                      subtitle && (
                        <div className='item-subtitle'>{subtitle}</div>
                      )
                    }
                  />
                )}
              </div>
            )
          })
        ) : searchTerm?.length > 0 ? (
          <div className='search-results-no-results'>No Results</div>
        ) : (
          false
        )}
      </div>
      <div
        css={
          meta?.total_pages > 1
            ? css`
                margin-top: 8px;
                height: 1px;
                background-color: #3f4753;
              `
            : css`
                height: ${!loadingResults && items?.length > 0 && '8px'};
              `
        }
      />
      {useInfiniteScrolling && !loadingResults && (
        <div
          css={css`
            margin: 16px 0;
          `}
        >
          {hasMorePages ? (
            <LoadingSpinner size={2} borderSize={2} />
          ) : (
            <div
              css={css`
                color: ${colors.ATTENDANCE_GRAY};
                font-size: 13px;
                text-align: center;
              `}
            >
              End of results
            </div>
          )}
        </div>
      )}

      {!useInfiniteScrolling && (
        <Pager
          page={meta?.current_page}
          total={meta?.total_pages}
          noBackground
          noBorder
          noPositionFixed
          onClick={onPageChange}
          numAdjacentPages={NUM_ADJACENT_PAGINATION}
        />
      )}
    </div>
  )
}

export default SuspensionSearchSelectResults
