import { useEffect } from 'react'
import { connect } from 'react-redux'
import { registerError } from 'src/utils/sentry'

const ErrorController = ({ error }) => {
  useEffect(() => {
    try {
      if (error) {
        if (error?.url) {
          registerError(error, `(${error?.status}) ${error?.url}`)
        } else {
          registerError(
            error,
            error?.message || 'Error Captured by the ErrorController'
          )
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [error])

  return null
}

const mapStateToProps = (state) => {
  return {
    error: state?.appError?.error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorController)
