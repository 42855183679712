import React, { useEffect, useMemo, useState } from 'react'
import queryString from 'query-string'
import req from '@sportninja/common/api/request'

import { FilterButtons } from '../components/SearchMenu/SearchFilterButtons'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'

const _getPath = (linkRoot, id, page) => `${linkRoot}/${id}/${page}`

// Will receive paths of the format `word-word` and produce a string like `Word Word`
const _format = (string) => {
  if (typeof string !== 'string') {
    return string
  }
  return string
    .split('-')
    .map((s) => `${s.charAt(0).toUpperCase()}${s.slice(1)}`)
    .join(' ')
}

/**
 * Utility for creating "subPages" objects that get passed to PageMenu via an
 * "entity" page like Team, Org, etc.
 *
 * @param {string} linkRoot - for creating React-Router Link's; e.g. ROUTES.TEAM_ROOT
 * @param {string} entityId - the unique ID for this particular entity (from the API)
 * @param {array} pages - list of pages to show in the menu
 * @param {object} options - Right now, just the keys `privatePageCount` and `hasPermission`
 * @param {number} options.privatePageCount - The # of pages (from the right) that are
 * shown only when `hasPermission` is true
 * @param {boolean} options.hasPermission - API tells us if the current user has
 * "admin" permissions
 */
export const createSubPages = (linkRoot, entityId, pages, options = {}) => {
  const menu = []
  const routes = {}

  let list
  const isOld = Array.isArray(pages)
  if (!isOld) {
    list = Object.keys(pages)
  } else {
    list = pages
  }
  const pageCount = list.length

  list.forEach((page, idx) => {
    const to =
      idx === 0 ? `${linkRoot}/${entityId}` : _getPath(linkRoot, entityId, page)
    const settings = {
      exact: true,
      title: !isOld ? pages[page] : _format(page),
      to,
    }

    // Tell the menu links to render as "orange" if the link is a private one,
    // and don't render at all if we don't have permission to see it.
    if (Object.prototype.hasOwnProperty.call(options, 'privatePageCount')) {
      // An undefined value for hasPermission is acceptable
      const isPrivate = idx > pageCount - 1 - options.privatePageCount
      settings.isOrange = isPrivate
      settings.isHidden = isPrivate && !options.hasPermission
    }

    menu.push(settings)
    routes[page] = to
  })

  // Sign-In Sheet
  const signInSheetPage = menu?.find((p) => p.title === 'Sign-In Sheet')
  if (signInSheetPage) {
    signInSheetPage.isHidden = false
    signInSheetPage.isOrange = false
  }

  // Timeline
  const timelinePage = menu?.find((p) => p.title === 'Timeline')
  if (timelinePage) {
    timelinePage.isHidden = false
    timelinePage.isOrange = false
  }

  // Scoresheet
  const scoresheetPage = menu?.find((p) => p.title === 'scoresheet')
  if (scoresheetPage) {
    scoresheetPage.isHidden = false
    scoresheetPage.isOrange = false
  }

  // Visitor Roster
  const visitorRosterPage = menu?.find((p) => p.title === 'Visitor Roster')
  if (visitorRosterPage) {
    visitorRosterPage.isHidden = false
    visitorRosterPage.isOrange = false
  }

  // "Home Roster"
  const homeRosterPage = menu?.find((p) => p.title === 'Home Roster')
  if (homeRosterPage) {
    homeRosterPage.isHidden = false
    homeRosterPage.isOrange = false
  }

  // "Details"
  const detailsPage = menu?.find((p) => p.title === 'Details')
  if (detailsPage) {
    detailsPage.isHidden = false
    detailsPage.isOrange = false
  }

  return [menu, routes]
}

const _constructActionsList = (
  permission,
  actions,
  { before = [], after = [] }
) => {
  let list = []

  if (Array.isArray(before)) {
    list.push(...before)
  }
  if (permission && Array.isArray(actions)) {
    list.push(...actions)
  }
  if (Array.isArray(after)) {
    list.push(...after)
  }

  return list
}

export const memoizedActionsList = (permission, actions, optional = {}) => {
  const { before = [], after = [] } = optional
  return useMemo(() =>
    _constructActionsList(permission, actions, { before, after }, [permission])
  )
}

export const useTabNavigation = (tabs, defaultTab, history) => {
  const parsedHash = queryString.parse(window.location.hash)

  useEffect(() => {
    if (!parsedHash.tab) {
      history.replace({ ...history.location, hash: `tab=${defaultTab}` })
    }
  }, [])

  const [selectedTabKey, setSelectedTabKey] = useState(
    parsedHash.tab || defaultTab
  )
  const selectedTab = tabs[selectedTabKey]

  useEffect(() => {
    const tab = parsedHash.tab
    if (tab) {
      setSelectedTabKey(tab)
    }
  }, [parsedHash, setSelectedTabKey])

  const component = (props) => (
    <FilterButtons
      onClick={(type) => {
        history.replace({ ...history.location, hash: `tab=${type}` })
        setSelectedTabKey(type)
      }}
      selectedType={selectedTabKey}
      types={tabs}
      {...props}
    />
  )

  return { component, selectedTab }
}

export const RECENTS_KEY_NAME = 'sn:home-recents'

export const useStoreRecent = (
  entityType,
  item,
  error,
  entityId,
  inFrame = false
) => {
  useEffect(() => {
    if (error && error?.status === 404) {
      const recentsString = inFrame
        ? ''
        : localStorage.getItem(RECENTS_KEY_NAME)
      const recents = JSON.parse(recentsString) || {}
      recents[entityType] = recents[entityType] || []
      const existingIndex = recents[entityType].findIndex(
        (r) => r.id === entityId
      )

      if (existingIndex > -1) {
        recents[entityType].splice(existingIndex, 1)
        if (!inFrame) {
          localStorage.setItem(RECENTS_KEY_NAME, JSON.stringify(recents))
        }
      }
    } else if (entityType && item && Object.keys(item).length > 0) {
      const { id, name_full } = item
      if (!id || !name_full) {
        console.error(
          'You must include both id and name_full to useStoreRecent!'
        )
        return
      }

      const recentsString = inFrame
        ? ''
        : localStorage.getItem(RECENTS_KEY_NAME)
      const recents = inFrame ? {} : JSON.parse(recentsString) || {}
      recents[entityType] = recents[entityType] || []

      const existingIndex = recents[entityType].findIndex((r) => r.id === id)
      if (existingIndex > -1) {
        recents[entityType].splice(existingIndex, 1)
      }

      recents[entityType].unshift({
        id: item.id,
        fullName: item.name_full,
        imageId: getImageThumbnailId(item),
        subTitle: item.organization?.name_full,
      })

      // Return the first 3 items
      recents[entityType] = recents[entityType].slice(0, 3)

      if (!inFrame) {
        localStorage.setItem(RECENTS_KEY_NAME, JSON.stringify(recents))
      }
    }
  }, [error, entityType, item])

  // Delete
  return (id) => {
    const recentsString = inFrame ? '' : localStorage.getItem(RECENTS_KEY_NAME)
    const recents = inFrame ? {} : JSON.parse(recentsString) || {}
    recents[entityType] = recents[entityType] || []
    const existingIndex = recents[entityType].findIndex((r) => r.id === id)

    if (existingIndex > -1) {
      recents[entityType].splice(existingIndex, 1)
      if (!inFrame) {
        localStorage.setItem(RECENTS_KEY_NAME, JSON.stringify(recents))
      }
    }
  }
}

export const useReadItem = () => {
  const [refreshKey, setRefreshKey] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [item, setItem] = useState({})
  const [permission, setPermission] = useState({})
  const [hierarchy, setHierarchy] = useState([])
  const [features, setFeatures] = useState({})

  const readItem = async (entityType, id) => {
    try {
      // It's important to reset state each time we read the item!
      setLoading(true)
      setError(false)
      setItem({})
      setPermission({})
      setHierarchy([])

      const response = await req(`/${entityType}/${id}`)
      setItem(response.data)

      setPermission(response.meta?.permissions?.[id] || {})
      setHierarchy(response.meta.hierarchy)
      setFeatures(response.meta?.features?.[id] || {})
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const triggerRefresh = () => {
    setTimeout(() => {
      setRefreshKey((key) => ++key)
    }, 100)
  }

  return {
    loading,
    error,
    item,
    permission,
    hierarchy,
    readItem,
    refreshKey,
    triggerRefresh,
    features,
  }
}

const mapper = (root, h) => {
  return {
    id: h.uid,
    to: `${root}/${h.uid}`,
    text: h.name,
  }
}

export const getBreadcrumbs = (root, item, hierarchy) => {
  const breadcrumbs = hierarchy.map(mapper.bind(this, root)) || []

  if (Object.keys(item).length > 0) {
    breadcrumbs.push({ id: item.id, text: item.name_full })
  }
  return breadcrumbs
}
