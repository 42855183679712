import React, { useRef, useState } from 'react'
import { ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'

import Page from '../../components/Page'
import TeamList from './TeamList'
import { connect } from 'react-redux'
import {
  getHockeySport,
  getSoccerSport,
} from '@sportninja/common/selectors/types'
import { NewModal } from 'src/components/NewModal'
import GeneratePluginModal from 'src/components/GeneralPluginModal'

const Teams = ({ hockeySport, soccerSport }) => {
  const [teamCount, setTeamCount] = useState('')
  const [favCount, setFavCount] = useState('')
  const [slug, setSlug] = useState('/users/me/teams')
  const generatePluginTagModalRef = useRef(null)

  const [selectedTeam, setSelectedTeam] = useState(null)
  const onClickWebPluginOnTeamList = (schedule) => {
    setSelectedTeam(schedule)
    generatePluginTagModalRef?.current?.openModal()
  }

  const pages = [
    {
      to: `${ROUTES.TEAMS.ROOT}/my`,
      text: `${t('common:team', { count: 2 })} ${
        teamCount ? `(${teamCount})` : ''
      }`,
      component: (
        <TeamList
          key='teams'
          slug={slug}
          setCount={setTeamCount}
          soccerSport={soccerSport}
          hockeySport={hockeySport}
          onClickWebPluginOnTeamList={onClickWebPluginOnTeamList}
        />
      ),
    },
    {
      to: `${ROUTES.TEAMS.FAVOURITES}`,
      text: `following ${favCount ? `(${favCount})` : ''}`,
      component: (
        <TeamList
          key='favs'
          slug='/favorites/teams'
          setCount={setFavCount}
          soccerSport={soccerSport}
          hockeySport={hockeySport}
          onClickWebPluginOnTeamList={onClickWebPluginOnTeamList}
        />
      ),
    },
  ]

  const BASE_ROUTE = ROUTES.TEAMS.ROOT

  return (
    <>
      <Page
        displayMobileActions={false}
        isOrgsPage={true}
        baseRoute={BASE_ROUTE}
        pages={pages}
        baseTitle={t('common:team', { count: 2 })}
        title={t('util:capitalize', {
          text: t('common:team', { count: 2 }),
        })}
      />
      <NewModal
        ref={generatePluginTagModalRef}
        shouldCloseOnOverlayClick={false}
      >
        <GeneratePluginModal
          onPressClose={() => {
            generatePluginTagModalRef?.current?.closeModal()
            setSelectedTeam(null)
          }}
          scheduleId={selectedTeam?.id}
          isOrganization={false}
          isTeam={true}
        />
      </NewModal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    hockeySportId: getHockeySport(state)?.id,
    soccerSportId: getSoccerSport(state)?.id,
    hockeySport: getHockeySport(state),
    soccerSport: getSoccerSport(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Teams)
