/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import dayjs from 'dayjs'
import { Event } from '../../../../common/types/index'
import { paragraphXSmall, weightRegular } from '../css'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import Text from '../ts-components/Text'

type EventDetailsProps = {
  value: string
}

type EventCardProps = {
  eventInfo: Event
}

export const EventDetails = ({ value }: EventDetailsProps) => (
  <p
    css={css`
      ${paragraphXSmall}
      ${weightRegular}
      color: ${colors.WHITE};
    `}
  >
    {value}
  </p>
)

export const EventCard = ({ eventInfo }: EventCardProps) => {
  return (
    <div
      css={css`
        background-color: ${colors.NEUTRAL_700};
        padding: 16px;
        border-radius: 16px;
        margin-top: 32px;
        display: flex;
        align-items: center;
        gap: 16px;
        color: ${colors.WHITE};
        @media (max-width: 768px) {
          padding: 16px;
          flex-direction: column;
        }
      `}
    >
      <div
        css={css`
          max-width: 100px;
          max-height: 100px;
          border-radius: 8px;
          border: 2px solid ${colors.WHITE};
        `}
      >
        <img
          src={
            getImageThumbnailId(eventInfo) ||
            '/images/generic_placeholders/placeholder_competition.png'
          }
          css={css`
            width: 100%;
            height: 100%;
          `}
          onError={(e: any) => {
            e.target.onerror = null
            e.target.src =
              '/images/generic_placeholders/placeholder_competition.png'
          }}
        />
      </div>
      <div css={css``}>
        <Text variant='heading3' color={colors.WHITE} weight='semiBold'>
          {eventInfo?.name || ''}
        </Text>
        <EventDetails
          value={eventInfo?.registration?.organization_name || ''}
        />
        {eventInfo?.registration?.root_schedule ? (
          <EventDetails
            value={`${dayjs(eventInfo?.registration?.root_schedule?.starts_at)
              .utc()
              .format('MMM D')} - ${dayjs(
              eventInfo?.registration?.root_schedule?.ends_at
            )
              .utc()
              .format('MMM D, YYYY')} `}
          />
        ) : null}
        {eventInfo?.registration?.location && (
          <EventDetails value={eventInfo?.location} />
        )}
      </div>
    </div>
  )
}
