import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import { isCanlan } from '@sportninja/common/utils/customer-name'

import { Flex } from '../Layout'
import { media, minMedia } from '../Responsive'
import { zIndex, font } from '../css'
import { ActionButtonWrapper } from '../ActionButton'

export const pageTitleCss = css`
  margin-top: 36px;
  margin-bottom: 30px;

  ${media.mobile} {
    margin-top: 44px;
  }
`

export const H1 = styled.h1`
  ${font.title}
  font-weight: 600;
  font-size: 44px;
  letter-spacing: 0.64px;
  color: white;

  margin-top: ${isCanlan
    ? 0
    : 4}px; /* Rift font is messed; this reduces the apparent line height */

  ${media.mobile} {
    text-align: center;
    font-size: 40px;
    letter-spacing: 0.58px;
  }
`

export const TopTitle = styled.h2`
  ${font.body}
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.24px;
  text-transform: uppercase;

  margin-bottom: 20px;

  ${minMedia.eight} {
    min-height: 12px;
    max-height: 12px;
    line-height: 12px;
  }

  ${media.mobile} {
    text-align: center;
  }
`

export const FixedTitle = styled(Flex)`
  pointer-events: none;

  div {
    display: flex;
    justify-content: center; /* Horizontal center */
    align-items: center; /* Vertical center */
    position: fixed;
    top: 0;
    height: 56px;
    width: 45%;
    z-index: ${zIndex.header};
  }

  h2 {
    max-height: 36px;
    ${font.title}
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.24px;
    color: white;
    text-align: center;

    transition: opacity 0.2s ease-in-out;
    opacity: ${(props) => (props.visible ? 1 : 0)};

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

export const BottomTitle = styled.h3`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.24px;
  text-transform: uppercase;

  margin-top: 22px;

  color: #b0b1b4;
  visibility: ${(props) => props.isHidden && 'hidden'};
`

export const ActionsWrapper = styled(Flex)`
  flex: initial;

  ${ActionButtonWrapper}:not(:nth-of-type(1)) {
    margin-left: 20px;
  }

  ${media.mobile} {
    max-width: 100vw;
    min-height: 90px;
    overflow-x: scroll;
    overflow-y: visible;
    margin: 0px 0 0px;
  }
`
