/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import flattenDeep from 'lodash.flattendeep'
import { t } from '@sportninja/common/i18n'

import { arrayToObject } from '../../utils/utils'
import { Flex } from '../Layout'
import { Mobile } from '../Responsive'
import { errorColor, paragraphXSmall } from '../../components/css'

import { FormButton, FormRow } from './css'
import FormInput from './FormInput'

import { Alert, Space } from 'antd'
import colors from '@sportninja/common/constants/appColors'

const getInitialForm = (initialValues) => {
  const nestedValues = []
  const requiredFields = []
  const validators = []

  const flattenedValues = arrayToObject(
    flattenDeep(initialValues),
    (o) => {
      if (o.validators) {
        validators.push(o)
      }
      if (o.required) {
        requiredFields.push(o)
      }

      if (o.defaultValues) {
        // Required fields for nested values handled separately below
        nestedValues.push(o.defaultValues)
        return
      }

      if (o.value || o.defaultValue) {
        return o.value || o.defaultValue
      }
    },
    'name'
  )

  nestedValues.forEach((defaultValues) => {
    for (const keyName in defaultValues) {
      const defaultValue = defaultValues[keyName]
      if (!defaultValue) {
        continue
      }

      if (defaultValue.required) {
        requiredFields.push({
          name: keyName,
          label: defaultValue.label,
        })
      }

      if (typeof defaultValue.value === 'undefined') {
        continue
      }

      Object.assign(flattenedValues, { [keyName]: defaultValue.value })
    }
  })

  return [flattenedValues, requiredFields, validators]
}

const useFormHandler = (
  setDirty,
  setHasChanged,
  onFormChanged,
  initialValues = {}
) => {
  const [initValues, requiredFields, validators] = useMemo(
    () => getInitialForm(initialValues),
    [getInitialForm, initialValues]
  )

  const [values, setValues] = useState(initValues)

  const onChange = ({ target }) => {
    setDirty && setDirty(true)
    setHasChanged(true)

    setValues((values) => {
      const newValues = {
        ...values,
        [target.name]:
          target.type === 'checkbox' ? target.checked : target.value,
      }

      // Remove empty string values from the form entirely
      // if (typeof target.value === 'string' && target.value.length === 0) {
      //   delete newValues[target.name]
      // }

      return newValues
    })
  }

  useEffect(() => {
    onFormChanged(values)
  }, [values])

  return {
    initialValues: initValues,
    values,
    requiredFields,
    onChange,
    validators,
  }
}

/**
 * Returns an object of errors based on required fields which aren't filled out
 * @param {array} requiredFields - A list of objects containing a name and label key
 * @param {object} values - The form's internal state of entered values for each field
 * @returns {object} Object where key is field name and value is error message to display
 */
const getRequiredFieldErrors = (requiredFields, values) => {
  return requiredFields
    .filter((item) => typeof values[item.name] === 'undefined')
    .reduce(
      (errors, field) => ({
        ...errors,
        [field.name]: `${field.label} is required`,
      }),
      {}
    )
}

/**
 *
 * @param {array} fieldsWithValidators - A list of objects with a validators array
 * @param {array} fieldsWithValidators[*].validators - A list of functions which should return an object
 * where key is the field name and value is the error message to display (or boolean true)
 * @param {object} values - The form's internal state of entered values for each field
 * @returns {object} Object where key is field name and valid is error message to display
 */
const runValidators = (fieldsWithValidators, values) => {
  return fieldsWithValidators
    .map((field) => {
      return (
        field.validators
          // Validators should return an object of error messages to display
          .map((validator) => validator(values, field))
          .filter(
            (result) =>
              typeof result !== 'undefined' && Object.keys(result).length > 0
          )
          .reduce(
            (errors, validatorResult) => ({ ...errors, ...validatorResult }),
            {}
          )
      )
    })
    .filter(
      (result) =>
        typeof result !== 'undefined' && Object.keys(result).length > 0
    )
    .reduce(
      (errors, validatorResult) => ({ ...errors, ...validatorResult }),
      {}
    )
}

/**
 * @param {array} form - An array of arrays. Each sub-array (of max length 3) index should be an object
 * @param {object} form[*]
 * @param {string} form[*].name - The html 'name' value to use
 * @param {string} form[*].label - The text to show for each input label
 */
const Form = ({
  cancelText = t('common:cancel'),
  children,
  className,
  doNotDisable = false,
  onError,
  form,
  hideCancel,
  onCancel,
  onSubmit,
  setDirty,
  submitButton,
  submitText = t('common:submit'),
  onFormChanged = () => {},
  finishedSubmitting = false,
  setFinishedSubmitting = () => {},
}) => {
  const mounted = useRef(true)
  const [error, setError] = useState(false)
  const [busy, setBusy] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)

  // https://sportninja.atlassian.net/browse/SN-3902. Currenlty this only affects
  // the Setup ( ScheduleTree )
  useEffect(() => {
    if (
      setFinishedSubmitting &&
      finishedSubmitting &&
      finishedSubmitting === true
    ) {
      setBusy(false)
      setHasChanged(false)
      setFinishedSubmitting(false)
    }
  }, [finishedSubmitting])

  // Instance variable to track mounted state; avoid performing state updates if
  // we're unmounted.
  useEffect(() => () => (mounted.current = false), [])

  const { values, requiredFields, onChange, validators } = useFormHandler(
    setDirty,
    setHasChanged,
    onFormChanged,
    form
  )

  const submitHandler = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    // This doesn't quite work as-is: if we have values that are required to be
    // sent, but don't change, they won't be sent. i.e. a team id
    // This is what we'll send to the onSubmit method - it's whatever changed from
    // the initial form values we were provided.
    // const diff = Object.keys(values).reduce((finalForm, key) => {
    //   if (values[key] !== initialValues[key]) finalForm[key] = values[key]
    //   return finalForm
    // }, {})

    const formData = new FormData(e.target)

    if (formData.get('fill-this-if-you-are-human')) {
      // report as bot submission for tracking

      // early return and finish this task
      return
    }

    setError(false)

    const validationErrors = runValidators(validators, values)
    if (Object.keys(validationErrors).length > 0) {
      return setError(validationErrors)
    }

    const requiredFieldErrors = getRequiredFieldErrors(requiredFields, values)
    if (Object.keys(requiredFieldErrors).length > 0) {
      return setError(requiredFieldErrors)
    }

    try {
      setBusy(true)
      // await onSubmit(diff)
      await onSubmit(values)
    } catch (err) {
      // This is not the ideal adjustment, but it's a quick fix for now
      // This error is thrown when we are not throwing the err
      // when the API call fails
      //
      if (
        err?.message === "Cannot read properties of undefined (reading 'data')"
      ) {
        console.log('Error: ', err)
        return
      }
      // if an onError handler is provided, call it
      // but if it doesn't return anything, just display the generic error message
      if (onError) {
        const customErrors = onError(values, err)
        if (customErrors) {
          return setError(customErrors)
        }
      }

      let error = {}
      if (err && err.invalid_fields) {
        error = { ...err.invalid_fields }
      } else if (err && err.message) {
        if (err.message === 'Insufficient privileges to perform this action') {
          error = { message: t('errors:insufficientPrivilege') }
        } else {
          error = { message: err.message }
        }
      } else if (err && !err.message) {
        error = { message: t('errors:somethingWentWrongPleaseTryAgain') }
      }

      setError(error)
    } finally {
      mounted.current && setBusy(false)
    }
  }

  // Scroll to the position of the error message on error
  useLayoutEffect(() => {
    if (error) {
      const magicScrollPosition = 163
      // For Safari
      document.body.scrollTop = magicScrollPosition
      // For Chrome, Firefox, IE and Opera
      document.documentElement.scrollTop = magicScrollPosition
    }
  }, [error])

  const SubmitButton = submitButton ? submitButton : FormButton

  return (
    <Mobile>
      {(isMobile) => (
        // The <form> element itself
        <form
          action='/'
          className={className}
          method='POST'
          onSubmit={submitHandler}
        >
          <input name='fill-this-if-you-are-human' type='hidden' />
          <div
            css={css`
              ${paragraphXSmall}
              color: ${colors.NEUTRAL_100};
              text-align: right;
              margin-bottom: 16px;
            `}
          >
            * {t('common:mandatoryFields')}
          </div>
          {/* Wrap the rows so we can flex the buttons to bottom of container */}
          <fieldset>
            {form &&
              form.map((row, rowIdx) => {
                // Form row
                return (
                  <FormRow key={rowIdx} column={isMobile}>
                    {row.map((input, idx) => {
                      if (input.type === 'break') {
                        return (
                          <div
                            key={`break-${rowIdx}`}
                            css={css`
                              width: 100%;
                              border-top: 1px solid rgba(255, 255, 255, 0.1);
                              margin: 8px 0;
                            `}
                          />
                        )
                      }

                      const hasError = !!(error && error[input.name])

                      // Custom element
                      if (
                        Object.prototype.hasOwnProperty.call(
                          input,
                          'component'
                        ) ||
                        typeof input === 'function'
                      ) {
                        const Input = input.component || input
                        const formProps = typeof input === 'object' ? input : {}

                        return (
                          <Input
                            key={input.name || idx}
                            disabled={busy}
                            hasError={hasError}
                            errors={error}
                            onChange={onChange}
                            values={values}
                            {...formProps}
                          />
                        )
                      } else {
                        // Basic form input element
                        return (
                          <FormInput
                            key={input.name}
                            disabled={busy}
                            hasError={hasError}
                            input={input}
                            onChange={onChange}
                          />
                        )
                      }
                    })}
                  </FormRow>
                )
              })}
          </fieldset>
          {/* {error && (
            <div
              css={css`
                color: ${errorColor};
                margin-bottom: 16px;
                line-height: 1.25;
                word-break: break-word;
                font-size: 16px;
              `}
            >
              {Object.keys(error).map((keyName) => {
                return <div key={keyName}>{error[keyName]}</div>
              })}
            </div>
          )} */}
          {error && (
            <Space direction='vertical' style={{ width: '100%' }}>
              <Alert
                message={Object.values(error).join(' ')}
                type='error'
                showIcon
              />
            </Space>
          )}
          {children}
          {/* If we don't set min-height, this will look broken on mobile */}
          <Flex
            className='form-buttons'
            noFlex
            alignItems='center'
            css={css`
              min-height: 52px;
              margin-top: 18px;
            `}
          >
            {/* Make sure type=button if you don't want a warning in the console */}
            {!hideCancel && (
              <FormButton
                disabled={busy}
                title={cancelText}
                onClick={onCancel}
                type='button'
              >
                {cancelText}
              </FormButton>
            )}
            <SubmitButton
              disabled={(!doNotDisable && !hasChanged) || busy}
              busy={busy}
              isSubmit
              title={submitText}
              type='submit'
              id='form-buttom-submit'
            >
              {submitText}
            </SubmitButton>
          </Flex>
        </form>
      )}
    </Mobile>
  )
}

Form.propTypes = {
  busy: PropTypes.bool,
  cancelText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  hideCancel: PropTypes.bool,
  form: PropTypes.array.isRequired,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  setDirty: PropTypes.func, // Callback that returns true when the form has been edited
  submitText: PropTypes.string,
}

export default Form
