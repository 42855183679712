/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Badge, Table, Tag, Tooltip, message } from 'antd'
import {
  InfoCircleOutlined,
  TagOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import colors from '@sportninja/common/constants/appColors'
import {
  formatCurrency,
  getImageThumbnailId,
} from '@sportninja/common/utils/utils'
import { formatShortDate } from '@sportninja/common/ts-utils/date-utils'
import dayjs from 'dayjs'
import type { TableColumnsType } from 'antd'
import {
  RegisteredPlayer,
  Currency,
} from '@sportninja/common/types/Registration'
import React, { memo, useCallback, useMemo } from 'react'
import { SelectInput } from 'src/components/ts-components/SelectInput'

// Types
interface PlayerDetailsTableProps {
  players: RegisteredPlayer[]
  teamId: string
  teamRosterId: string
  currency: Currency
  scheduleId: string
  setIsRegistrationDetailsModalOpen: (isOpen: boolean) => void
  setRegistrationDetailId: (id: string) => void
  handlePlayerStatusChange: (
    status: number,
    id: string,
    player: RegisteredPlayer,
    divisionId: string
  ) => void
  loadingPlayerIds: string[]
  refetch: () => void
  divisionId: string
  selectedRowKeys: React.Key[]
  setSelectedRowKeys: (keys: React.Key[]) => void
  selectedPlayers: RegisteredPlayer[]
  setSelectedPlayers: (players: RegisteredPlayer[]) => void
  setIsManagePlayersModalOpen: (isOpen: boolean) => void
}

interface ExpandedDataType {
  key: React.Key
  playerName: string
  memberSince: string
  age: string
  registrationDate: string
  paid: string
  zeroPaid: boolean
  status: string
  hasPaymentDelayed: boolean
  player: RegisteredPlayer & {
    player_id: string
    is_coupon: boolean
    coupon: {
      id: string
      code: string
      discount: number
      discount_amount: number
      discount_type: 'percentage' | 'fixed'
      subtotal: number
      is_active: boolean
      registration_id: string
      start_date: string | null
      end_date: string | null
    } | null
  }
  teamId: string
  outstandingValue: number
  isDepositPaid: boolean
  image: any
  registration_status: number
  hasDeposit: boolean
  isSinglePayment: boolean
}

const registrationOptions = [
  {
    label: 'Submitted',
    value: 1,
  },
  {
    label: 'Pending',
    value: 2,
  },
  {
    label: 'Approved',
    value: 3,
  },
  // {
  //   label: 'Approved w/ conditions',
  //   value: 4,
  // },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsSubmitted = [
  {
    label: 'Submitted',
    value: 1,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsPending = [
  {
    label: 'Pending',
    value: 2,
  },
  {
    label: 'Approved',
    value: 3,
  },
  // {
  //   label: 'Approved w/ conditions',
  //   value: 4,
  // },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsApproved = [
  {
    label: 'Approved',
    value: 3,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsApprovedWithConditions = [
  {
    label: 'Approved w/ Conditions',
    value: 4,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsCancelled = [
  {
    label: 'Cancelled',
    value: 5,
  },
]

/**
 * Memoized team status selector component
 */
const PlayerStatusSelector = memo(
  ({
    teamId,
    onChange,
    disabled,
    options,
    registrationStatus,
  }: {
    status?: number
    teamId: string
    onChange: (status: number, id: string) => void
    disabled: boolean
    options: { label: string; value: number }[]
    registrationStatus: number
  }) => {
    // Get color based on status
    const getStatusColor = (s: number) => {
      switch (s) {
        case 1: // Submitted
          return colors.INFO_200
        case 2: // Pending
          return colors.WARNING_200
        case 3: // Approved
          return colors.SUCCESS_200
        case 4: // Approved w/ Conditions
          return colors.SUCCESS_200
        case 5: // Cancelled
          return colors.ERROR_200
        default:
          return colors.NEUTRAL_50
      }
    }

    const optionsWithBadge = options.map((opt) => ({
      ...opt,
      label: (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <Badge
            color={getStatusColor(opt.value)}
            css={css`
              .ant-badge-status-dot {
                width: 8px;
                height: 8px;
              }
            `}
          />
          {opt.label}
        </div>
      ),
      value: opt.value,
    }))

    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
        `}
      >
        <SelectInput
          options={optionsWithBadge}
          // @ts-ignore
          value={registrationStatus}
          onChange={(value) => onChange(Number(value), teamId)}
          label=''
          disabled={disabled}
          name='playerStatus'
          fieldErrors={[]}
          customError={{}}
          style={`
            width: 200px;
            @media (max-width: 768px) {
              width: 100%;
            }
            height: 32px !important;
          `}
        />
      </div>
    )
  }
)

PlayerStatusSelector.displayName = 'PlayerStatusSelector'

// Memoize expensive calculations
const useTableData = (
  players: RegisteredPlayer[],
  teamId: string,
  currency: Currency
) => {
  return useMemo(() => {
    return players.map((player, index) => {
      // Calculate total paid amount and check for delayed payments
      const { totalPaid, hasPaymentDelayed } = calculatePaymentDetails(player)
      const hasDeposit = player?.billing_schedule?.find(
        (item) => item.payment_type === 'deposit'
      )
        ? true
        : false
      const isSinglePayment = player?.billing_schedule?.length === 1
      const isDepositPaid = player?.billing_schedule?.find(
        (item) => item.payment_type === 'deposit' && item.paid
      )
        ? true
        : false
      /**
       * Calculate the outstanding value for a player
       * Takes into account partial refunds by subtracting refund_subtotal from subtotal
       */
      const outstandingValue = (() => {
        if (!player?.billing_schedule) {
          return 0
        }
        let total = 0
        player.billing_schedule
          .filter((item) => item.user_type === 'player')
          .filter((item) => !item.paid || item.refunded)
          .forEach((item) => {
            // Handle partial refunds
            if (item.refunded && item.refund_subtotal < item.subtotal) {
              total += item.subtotal - item.refund_subtotal
            } else {
              total += item.subtotal
            }
          })

        return total
      })()

      // Create an enhanced player object with the required coupon properties
      const enhancedPlayer = {
        ...player,
        key: player?.id,
        is_coupon: player.is_coupon ?? false,
        coupon: player.coupon ?? null,
      }

      return {
        key: player?.id || index,
        playerName: player?.name_full || '-',
        memberSince: player?.created_at
          ? formatShortDate(player?.created_at, false)
          : '-',
        age: player?.birth_date ? calculateAge(player?.birth_date) : '-',
        registrationDate: player?.registered_at
          ? formatShortDate(player?.registered_at, false)
          : '-',
        paid: formatCurrency(totalPaid, currency.name) || '0',
        hasPaymentDelayed,
        zeroPaid: totalPaid === 0,
        status: getPlayerStatus(player?.registration_status),
        registration_status: player?.registration_status || 0,
        player: enhancedPlayer,
        teamId,
        isDepositPaid: isDepositPaid || false,
        outstandingValue: outstandingValue || 0,
        image: player?.image,
        hasDeposit,
        isSinglePayment,
      }
    })
  }, [players, teamId, currency.name])
}

// Memoize table columns configuration
const useTableColumns = (
  players: RegisteredPlayer[],
  currency: Currency,
  handlePlayerStatusChange: (
    status: number,
    id: string,
    player: RegisteredPlayer,
    divisionId: string
  ) => void,
  loadingPlayerIds: string[],
  setIsRegistrationDetailsModalOpen: (isOpen: boolean) => void,
  setRegistrationDetailId: (id: string) => void,
  getRegistrationOptions: (
    status: number
  ) => { label: string; value: number }[],
  selectedRowKeys: React.Key[],
  setIsManagePlayersModalOpen: (isOpen: boolean) => void,
  selectedPlayers: RegisteredPlayer[],
  divisionId: string
): TableColumnsType<ExpandedDataType> => {
  return useMemo(() => {
    return [
      {
        title: 'Player',
        dataIndex: 'playerName',
        key: 'playerName',
        render: (text: string, record: ExpandedDataType) => {
          return (
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 12px;
                /* on hover, show the link */
                &:hover {
                  cursor: pointer;
                  text-decoration: underline;
                }
              `}
              onClick={() => {
                setIsRegistrationDetailsModalOpen(true)
                setRegistrationDetailId(record.player.id)
              }}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  align-self: center;
                `}
              >
                <img
                  css={css`
                    width: 24px;
                    height: 24px;
                    background: ${colors.WHITE};
                    border-radius: 50%;
                    padding: 2px;
                    object-fit: contain;
                  `}
                  src={
                    getImageThumbnailId(record) ||
                    '/images/generic_placeholders/placeholder_player.png'
                  }
                  alt={text}
                  onError={(e) => {
                    const img = e.target as HTMLImageElement
                    img.onerror = null
                    img.src =
                      '/images/generic_placeholders/placeholder_player.png'
                  }}
                />
              </div>
              <span>{text}</span>
            </div>
          )
        },
        filterMode: 'menu' as const,
        filters: players
          .filter((player) => player.name_full)
          .map((player) => ({
            text: player.name_full,
            value: player.name_full,
          })),
        onFilter: (value, record: ExpandedDataType) =>
          String(record.playerName) === String(value),
        filterSearch: true,
        sorter: (a, b) => a.playerName.localeCompare(b.playerName),
      },
      {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
        filters: [
          { text: '0-12', value: '0-12' },
          { text: '13-17', value: '13-17' },
          { text: '18-25', value: '18-25' },
          { text: '26+', value: '26+' },
        ],
        onFilter: (value, record: ExpandedDataType) => {
          if (record.age === '-') {
            return false
          }
          const age = parseInt(record.age)
          const [min, max] = String(value).split('-').map(Number)
          if (max) {
            return age >= min && age <= max
          }
          return age >= min
        },
        sorter: (a, b) => {
          if (a.age === '-' || b.age === '-') {
            return 0
          }
          return parseInt(a.age) - parseInt(b.age)
        },
      },
      {
        title: 'Registered At',
        dataIndex: 'registrationDate',
        key: 'registrationDate',
        sorter: (a, b) => {
          if (a.registrationDate === '-' || b.registrationDate === '-') {
            return 0
          }
          return (
            dayjs(a.registrationDate).unix() - dayjs(b.registrationDate).unix()
          )
        },
      },
      {
        title: 'Dep. Paid',
        dataIndex: 'isDepositPaid',
        key: 'isDepositPaid',
        render: (isDepositPaid: boolean, record: ExpandedDataType) => {
          if (record.isSinglePayment) {
            return <span>--</span>
          }
          if (!record.hasDeposit) {
            return <span>--</span>
          }
          return (
            <Tag color={isDepositPaid ? 'green' : 'red'}>
              {isDepositPaid ? 'Yes' : 'No'}
            </Tag>
          )
        },
        filters: [
          { text: 'Paid', value: 'true' },
          { text: 'Not Paid', value: 'false' },
        ],
        onFilter: (value, record: ExpandedDataType) => {
          if (record.isSinglePayment || !record.hasDeposit) {
            return false
          }
          return String(record.isDepositPaid) === value
        },
      },
      {
        title: 'Outstanding',
        dataIndex: 'outstandingValue',
        key: 'outstandingValue',
        align: 'right',
        render: (outstandingValue: number) => {
          return (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 8px;
              `}
            >
              <span>
                {formatCurrency(Math.round(outstandingValue), currency.name)}
              </span>
            </div>
          )
        },
        sorter: (a, b) => a.outstandingValue - b.outstandingValue,
        filters: [
          { text: 'No Outstanding', value: '0' },
          { text: 'Has Outstanding', value: 'outstanding' },
        ],
        onFilter: (value, record: ExpandedDataType) => {
          if (record.isSinglePayment || !record.hasDeposit) {
            return false
          }
          if (value === '0') {
            return record.outstandingValue === 0
          }
          return record.outstandingValue > 0
        },
      },
      {
        title: 'Paid',
        dataIndex: 'paid',
        key: 'paid',
        align: 'right',
        render: (text: string, record: ExpandedDataType) => {
          const hasCoupon = record?.player?.is_coupon === true
          const { code, discount_type, discount, discount_amount } =
            record?.player?.coupon || {}
          const discountText =
            discount_type === 'percentage'
              ? `${discount}%`
              : formatCurrency(discount_amount || 0, currency?.name)
          return (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 8px;
              `}
            >
              {hasCoupon ? (
                <Tooltip
                  title={`Coupon: ${code} - ${discountText} ${
                    discount_type === 'percentage' ? 'off' : 'discount'
                  }`}
                >
                  <TagOutlined
                    style={{ color: colors.PRIMARY, fontSize: 20 }}
                    color={colors.PRIMARY}
                  />
                </Tooltip>
              ) : null}
              <span
                css={css`
                  color: ${getPaidAmountColor(record)};
                `}
              >
                {text}
              </span>
            </div>
          )
        },
        sorter: (a, b) => {
          const aValue = parseFloat(a.paid.replace(/[^0-9.-]+/g, ''))
          const bValue = parseFloat(b.paid.replace(/[^0-9.-]+/g, ''))
          return aValue - bValue
        },
      },
      {
        title: 'Status',
        key: 'status',
        width: 150,
        render: (_: unknown, record: any) => {
          return (
            <PlayerStatusSelector
              status={record.status}
              registrationStatus={record.registration_status}
              teamId={record.id}
              onChange={(status, id) =>
                handlePlayerStatusChange(status, id, record, divisionId)
              }
              disabled={loadingPlayerIds.includes(record.id)}
              options={getRegistrationOptions(record.registration_status)}
            />
          )
        },
        filters: registrationOptions.map((option) => ({
          text: option.label,
          value: option.value,
        })),
        onFilter: (value, record: ExpandedDataType) =>
          record.registration_status === value,
      },
      {
        title: (
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding-right: 8px;
              width: 100%;
            `}
          >
            <Tooltip
              title={
                selectedRowKeys.length > 0
                  ? 'Trade Players'
                  : 'Select At Least One Player To Be Traded'
              }
            >
              <UserSwitchOutlined
                css={css`
                  color: ${selectedRowKeys.length > 0
                    ? colors.PRIMARY
                    : colors.NEUTRAL_100};
                  font-size: 20px;
                  cursor: ${selectedRowKeys.length > 0
                    ? 'pointer'
                    : 'not-allowed'};
                  &:hover {
                    opacity: ${selectedRowKeys.length > 0 ? '0.8' : '1'};
                  }
                `}
                onClick={(e) => {
                  if (selectedRowKeys.length === 0) {
                    return
                  }
                  e.stopPropagation()
                  if (selectedPlayers.length > 0) {
                    setIsManagePlayersModalOpen(true)
                  } else {
                    message.warning(
                      'Please select players from the same team to trade.'
                    )
                  }
                }}
              />
            </Tooltip>
          </div>
        ),
        key: 'action',
        className: 'action-column',
        render: (_text: string, record: ExpandedDataType) => {
          return (
            <div
              css={css`
                display: flex;
                align-items: flex-end;
                gap: 8px;
                justify-content: center;
              `}
            >
              <InfoCircleOutlined
                onClick={() => {
                  setIsRegistrationDetailsModalOpen(true)
                  setRegistrationDetailId(record.player.id)
                }}
                css={css`
                  color: ${colors.PRIMARY};
                  font-size: 20px;
                  cursor: pointer;
                `}
              />
            </div>
          )
        },
      },
    ]
  }, [
    players,
    currency.name,
    handlePlayerStatusChange,
    loadingPlayerIds,
    setIsRegistrationDetailsModalOpen,
    setRegistrationDetailId,
    getRegistrationOptions,
    selectedRowKeys,
    setIsManagePlayersModalOpen,
    selectedPlayers,
    divisionId,
  ])
}

/**
 * PlayerDetailsTable - Displays detailed information about registered players
 * @param {PlayerDetailsTableProps} props - Component props
 * @returns {JSX.Element} Rendered component
 */
const PlayerDetailsTable: React.FC<PlayerDetailsTableProps> = ({
  players,
  teamId,
  teamRosterId,
  currency,
  setIsRegistrationDetailsModalOpen,
  setRegistrationDetailId,
  handlePlayerStatusChange,
  loadingPlayerIds,
  divisionId,
  selectedRowKeys,
  setSelectedRowKeys,
  selectedPlayers,
  setSelectedPlayers,
  setIsManagePlayersModalOpen,
}) => {
  const getRegistrationOptions = useCallback((status) => {
    switch (status) {
      case 1:
        return registrationOptionsSubmitted
      case 2:
        return registrationOptionsPending
      case 3:
        return registrationOptionsApproved
      case 4:
        return registrationOptionsApprovedWithConditions
      case 5:
        return registrationOptionsCancelled
      default:
        return registrationOptions
    }
  }, [])

  // Use memoized table data
  const tableData = useTableData(players, teamId, currency)

  // Use memoized columns
  const columns = useTableColumns(
    players,
    currency,
    handlePlayerStatusChange,
    loadingPlayerIds,
    setIsRegistrationDetailsModalOpen,
    setRegistrationDetailId,
    getRegistrationOptions,
    selectedRowKeys,
    setIsManagePlayersModalOpen,
    selectedPlayers,
    divisionId
  )

  // Handle row selection changes
  const handleSelectionChange = useCallback(
    (selectedKeys: React.Key[], _selectedRows: ExpandedDataType[]) => {
      setSelectedRowKeys(selectedKeys)
      setSelectedPlayers(
        players.filter(
          (p) =>
            selectedKeys.includes(p.id || '') &&
            p.team_roster_id === teamRosterId
        )
      )
    },
    [players, teamRosterId]
  )

  // Row selection configuration
  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: handleSelectionChange,
      type: 'checkbox' as const,
      getCheckboxProps: (record: ExpandedDataType) => ({
        disabled: record.registration_status === 5,
      }),
    }),
    [selectedRowKeys, handleSelectionChange]
  )

  return (
    <>
      <Table
        css={css`
          /* table head background */
          .ant-table-thead > tr > th {
            background: #21262f !important;
            color: ${colors.NEUTRAL_70} !important;
          }
          .ant-table-cell {
            border-radius: 0 !important;
          }
          th.ant-table-cell::before {
            display: none !important;
          }
          .action-column {
            width: 51px;
            @media (min-width: 2560px) {
              width: 55px;
            }
          }
          .ant-table-selection-column {
            padding-left: 16px !important;
            width: 48px !important;
            min-width: 48px !important;
          }
          .ant-table-tbody > tr:nth-of-type(even) {
            background-color: ${colors.SECONDARY_500} !important;
          }
          .ant-table-tbody > tr:nth-of-type(odd) {
            background-color: ${colors.SECONDARY_500} !important;
          }
        `}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={[...tableData].sort((a, b) => {
          const nameCompare = a.playerName.localeCompare(b.playerName)
          if (nameCompare !== 0) {
            return nameCompare
          }
          return a.registrationDate.localeCompare(b.registrationDate)
        })}
        pagination={false}
        size='small'
        rowKey='key'
      />
    </>
  )
}

// Helper functions
const calculatePaymentDetails = (player: RegisteredPlayer) => {
  let totalPaid = 0
  let hasPaymentDelayed = false

  player.billing_schedule
    ?.filter((el) => el.user_type === 'player')
    ?.forEach((el) => {
      if (el.paid) {
        totalPaid += el.display_subtotal
      } else if (dayjs(el?.due_date).isBefore(dayjs())) {
        hasPaymentDelayed = true
      }
    })

  return { totalPaid, hasPaymentDelayed }
}

const calculateAge = (birthDate: string | undefined): string => {
  return birthDate ? dayjs().diff(birthDate, 'year').toString() : '-'
}

const getPlayerStatus = (statusValue: number): string => {
  return (
    registrationOptions.find((option) => option.value === statusValue)?.label ||
    '-'
  )
}

const getPaidAmountColor = (record: ExpandedDataType): string => {
  if (record.zeroPaid) {
    return colors.NEUTRAL_100
  }
  if (record.hasPaymentDelayed) {
    return colors.ERROR_LIGHT
  }
  return colors.SUCCESS_GREEN
}

export default React.memo(PlayerDetailsTable)
