/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Spin } from 'antd'
import colors from '@sportninja/common/constants/appColors'

const LoadingRegistration = () => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: ${colors.NEUTRAL_900};
      `}
    >
      <Spin
        css={css`
          font-size: 48px;
          color: ${colors.PRIMARY};
          .ant-spin-dot-item {
            background-color: ${colors.PRIMARY};
          }
        `}
        size='large'
        fullscreen={true}
      />
    </div>
  )
}

export default LoadingRegistration
