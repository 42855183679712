/* globals arguments */

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useRef, useState, useEffect } from 'react'

import {
  DEFAULT_SORT_DIRECTION,
  DEFAULT_SORT_COLUMN,
  TABLE_TYPES,
  baseRoles,
} from '../constants/app'
import { isCanlan } from './customer-name'

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export const getImageThumbnailId = (data) => {
  if (!data || (!data.image && !Array.isArray(data.images))) {
    return
  }

  // On canlan we are not using the new CDN for now
  if (isCanlan || __DEV__) {
    if (data.image) {
      // Why not add even more variations of a schema, thanks API devs
      if (typeof data.image === 'string') {
        return `https://${
          isCanlan ? 'canlan-images-production' : 'sportninja-images-production'
        }.s3.amazonaws.com/original/${data.image}`
      } else if (
        Array.isArray(data.image.formats) &&
        data.image.formats.length === 3
      ) {
        if (data.image.formats[0].full_path) {
          return data.image.formats[0].full_path
        } else if (data.image.formats[0].filename) {
          return `https://${
            isCanlan
              ? 'canlan-images-production'
              : 'sportninja-images-production'
          }.s3.amazonaws.com/resized/${data.image.formats[0].filename}`
        }
      } else {
        if (data.image.full_path) {
          return data.image.full_path
        } else if (data.image.filename) {
          return `https://${
            isCanlan
              ? 'canlan-images-production'
              : 'sportninja-images-production'
          }.s3.amazonaws.com/original/${data.image.filename}`
        }
      }
    } else if (Array.isArray(data.images) && data.images.length > 0) {
      return data.images[0].full_path
    } else if (data.team_image) {
      return data.team_image.full_path
    }
    return ''
  }
  // Return an 80x80 image if available
  if (data.image) {
    // Why not add even more variations of a schema, thanks API devs
    if (typeof data.image === 'string') {
      return `https://${
        isCanlan ? 'canlan-images-production' : 'cdn-images.sportninja.com'
      }/original/${data.image}`
    } else if (
      Array.isArray(data.image.formats) &&
      data.image.formats.length === 3
    ) {
      // if (data.image.formats[0].full_path) {
      //   return data.image.formats[0].full_path
      // } else if (data.image.formats[0].filename) {
      //   return `https://${
      //     isCanlan ? 'canlan-images-production' : 'cdn-images.sportninja.com'
      //   }/resized/${data.image.formats[0].filename}`
      // }
      if (data.image.formats[0].filename) {
        return `https://${
          isCanlan ? 'canlan-images-production' : 'cdn-images.sportninja.com'
        }/resized/${data.image.formats[0].filename}`
      }
    } else {
      // if (data.image.full_path) {
      //   return data.image.full_path
      // } else if (data.image.filename) {
      //   return `https://${
      //     isCanlan ? 'canlan-images-production' : 'cdn-images.sportninja.com'
      //   }/original/${data.image.filename}`
      // }
      if (data.image.filename) {
        return `https://${
          isCanlan ? 'canlan-images-production' : 'cdn-images.sportninja.com'
        }/original/${data.image.filename}`
      }
    }
  } else if (Array.isArray(data.images) && data.images.length > 0) {
    return data.images[0].full_path?.replace(
      'https://sportninja-images-production.s3.amazonaws',
      'https://cdn-images.sportninja'
    )
  } else if (data.team_image) {
    return data.team_image.full_path?.replace(
      'https://sportninja-images-production.s3.amazonaws',
      'https://cdn-images.sportninja'
    )
  }

  return ''
}

export const getHighQualityImageThumbnailId = (data) => {
  if (!data || (!data.image && !Array.isArray(data.images))) {
    return
  }

  // Return an 80x80 image if available
  if (data.image) {
    // Why not add even more variations of a schema, thanks API devs
    if (typeof data.image === 'string') {
      return `https://${
        isCanlan ? 'canlan-images-production' : 'cdn-images.sportninja.com'
      }/original/${data.image}`
    } else if (
      Array.isArray(data.image.formats) &&
      data.image.formats.length === 3
    ) {
      // lastest item of the array instead of the first
      if (data.image.formats[data.image.formats.length - 1].full_path) {
        return data.image.formats[data.image.formats.length - 1].full_path
      } else if (data.image.formats[data.image.formats.length - 1].filename) {
        return `https://${
          isCanlan ? 'canlan-images-production' : 'cdn-images.sportninja.com'
        }/resized/${data.image.formats[data.image.formats.length - 1].filename}`
      }
    } else {
      if (data.image.full_path) {
        return data.image.full_path?.replace(
          'https://sportninja-images-production.s3.amazonaws',
          'https://cdn-images.sportninja'
        )
      } else if (data.image.filename) {
        return `https://${
          isCanlan ? 'canlan-images-production' : 'cdn-images.sportninja.com'
        }/original/${data.image.filename}`
      }
    }
  } else if (Array.isArray(data.images) && data.images.length > 0) {
    return data.images[0].full_path?.replace(
      'https://sportninja-images-production.s3.amazonaws',
      'https://cdn-images.sportninja'
    )
  } else if (data.team_image) {
    return data.team_image.full_path?.replace(
      'https://sportninja-images-production.s3.amazonaws',
      'https://cdn-images.sportninja'
    )
  }

  return ''
}

export const noop = () => undefined

/**
 * Retrieve data from a given row object
 *
 * @param {object} row - The object at an array index containing information
 * @param {string|function} accessor - A period separated nested key, or custom function
 * @returns {*} The value of row as defined by accessor
 */
export const getNestedData = (row, accessor) => {
  if (!row || !accessor) {
    return {}
  }

  if (typeof accessor === 'function') {
    return accessor(row)
  } else if (typeof accessor === 'string') {
    const accessorList = accessor.split('.')

    let data = Object.assign({}, row)
    for (let i = 0; i < accessorList.length - 1; i++) {
      data = data[accessorList[i]]

      if (!data) {
        break
      }
    }

    if (!data) {
      return {}
    }

    return { value: data[accessorList.slice(-1)] }
  }
}

export const arrayToObject = (array, mapper = (o) => o, idName = 'id') => {
  // Probably just gonna result in weird bugs - but this will prevent the app
  // from crashing in the case of applying this transformation to something that
  // doesn't support it.
  if (typeof array.reduce === 'undefined') {
    return array
  }

  return array.reduce((returnedObject, currentArrayItem) => {
    if (currentArrayItem) {
      const mappedObject = mapper(currentArrayItem)
      const keyName = mappedObject && mappedObject[idName]
      if (mappedObject && keyName) {
        returnedObject[keyName] = mappedObject
      }
    }

    return returnedObject
  }, {})
}

export const toFilteredArray = (
  obj,
  filterFn = () => true,
  transform = (o) => o
) => {
  return Object.keys(obj)
    .filter((key) => filterFn(obj[key]))
    .reduce((arr, key) => [...arr, transform(obj[key])], [])
}

export const toArray = (obj = {}, transform = (o) => o) =>
  toFilteredArray(obj, undefined, transform)

// Deprecated
export const actionPrefixer = (orig, prefix) => {
  const obj = { ...orig }
  const keys = Object.keys(obj)

  keys.forEach((key) => {
    if (typeof obj[key] === 'object') {
      obj[key] = actionPrefixer(obj[key], `${prefix ? `${prefix}.` : ''}${key}`)
    } else {
      obj[key] = `${prefix}.${obj[key]}`
    }
  })

  return obj
}

/**
 * @param {object} day - Expects a dayjs object
 * @returns {string} - An ISO formatted datetime string
 */
export const toISOString = (day) => {
  if (!day.toISOString) {
    return day
  }
  return day.toISOString().replace(/\.\d+Z/, 'Z')
}

export const isUnderAge = (age, requiredAge) => {
  return dayjs().diff(age, 'year') < requiredAge
}

export const formatURL = (string) => {
  const hasHttp = /^http(s?):\/\//i.test(string)
  return encodeURI(hasHttp ? string : `//${string}`)
}

export const timeStringFromMS = (ms, skipFormatting = false) => {
  const durationInSec = ms / 1000
  const min = Math.floor(durationInSec / 60)
  const sec = durationInSec % 60

  return skipFormatting ? [min, sec] : `${min}:${sec < 10 ? '0' : ''}${sec}`
}

/**
 * To be used in conjunction with mobile/src/components/FollowButton. Provide the
 * return value of this function as the `followProps` prop.
 *
 * If the entity is public, if the user already follows the entity, or if they
 * have permission over it (i.e. own it, or has staff membership) then allow
 * the FollowButton to be displayed.
 *
 * @param {object} item - The entity information. Must contain the following keys:
 * isPublic, isFollowing, permission, id, entityType
 */
export const shouldDisplayFollow = (item) => {
  const { isPublic, isFollowing, permission } = item
  if (isPublic || isFollowing || permission) {
    return item
  }
  return false
}

export const getFeedPostDate = (date) => {
  if (typeof date !== 'string') {
    return date
  }
  return dayjs(date).fromNow()
}

// A shared React hook for "entity" pages like Team, Org, etc. Basically holds
// some 'loaded' state which will be set to true after we fetch data (which this
// will also do).
export const useReadWithPagination = (method, id, options = {}) => {
  const {
    sortId: _sortId,
    sortContext = {},
    sortDirection: _sortDirection,
    tableType,
  } = options
  const mounted = useRef(true)

  const [error, setError] = useState(false)
  const [loaded, setLoaded] = useState(typeof method !== 'function')
  const [pages, setPages] = useState(false)
  const [currPage, setCurrPage] = useState(1)

  let initialSortDirection = _sortDirection || DEFAULT_SORT_DIRECTION
  let initialSortId = _sortId || DEFAULT_SORT_COLUMN
  const { sortState = {}, changeSort } = sortContext

  if (
    tableType &&
    Object.keys(sortState).length > 0 &&
    Object.prototype.hasOwnProperty.call(sortState, tableType) &&
    Object.prototype.hasOwnProperty.call(TABLE_TYPES, tableType)
  ) {
    initialSortDirection = sortState[tableType].direction
    initialSortId = sortState[tableType].sort
  }

  const [sortDirection, _setSortDirection] = useState(initialSortDirection)
  const [sortId, _setSortId] = useState(initialSortId)

  const handleSorting = (sortId, direction) => {
    if (tableType) {
      changeSort({ ...sortState, [tableType]: { sort: sortId, direction } })
    }

    _setSortId(sortId)
    _setSortDirection(direction)
  }

  useEffect(() => () => (mounted.current = false), [])

  const read = () => {
    if (
      !mounted.current ||
      (arguments?.length === 2 && typeof id === 'undefined')
    ) {
      return
    }
    if (typeof method !== 'function') {
      return setLoaded(true)
    }

    setError(false)
    setLoaded(false)

    const args = [currPage, sortId, sortDirection]
    if (typeof id === 'string') {
      args.unshift(id)
    }

    method(...args)
      .then((response) => {
        if (!mounted.current) {
          return
        }

        setLoaded(true)
        setError(false)

        // If the saga provided returns a response object, rather than just the data
        // then process the 'pagination' metadata it provides.
        // see Games/index.jsx
        if (response.meta && response.meta.pagination) {
          setPages({
            ...response.meta.pagination,
            ids: response.data.map((d) => d.id),
            onPageClick: setCurrPage,
          })
        }
      })
      .catch((e) => {
        if (mounted.current) {
          setError(e)
        }
      })
  }

  useEffect(() => {
    read()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currPage, id, sortDirection, sortId])

  const sorting = {
    sortId,
    direction: sortDirection,
    changeSort: handleSorting,
  }

  return [loaded, error, pages, read, sorting]
}

/**
 * Check if the e-mail is a sportninja employee email
 * @example
 * // returns true
 * isSportNinjaEmail(felipe@sportninja.com)
 * @param {String} email - The email to check
 * @returns {boolean} Returns true if the e-mail contains '@sportninja.com' domain
 */
export const isSportNinjaEmail = (email) => {
  var regExp = new RegExp('[a-z0-9.-_]*@sportninja.com$', 'i')
  let match = email.match(regExp)
  if (match) {
    match = true
  } else {
    match = false
  }
  return match
}

export const getDayjsTZDate = (dateString, timezoneString) => {
  if (typeof dateString !== 'string' || dateString.length === 0) {
    return dateString
  }

  const date = dayjs(dateString)

  if (typeof timezoneString === 'string' && timezoneString.length > 0) {
    return date.tz(timezoneString)
  }

  return date
}

/**
 * Formats a phone number string as (XXX) XXX-XXXX.
 * @param text The phone number string to format.
 * @returns The formatted phone number string.
 */
export const formatPhoneNumber = (text) => {
  if (!text) {
    return ''
  }
  try {
    // Remove all non-numeric characters from the input
    const cleaned = text.replace(/\D/g, '')
    // Format the phone number as (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`
    }
    return cleaned
  } catch (e) {
    return text
  }
}

/**
 * Checks if a given string is a valid HTTP or HTTPS URL.
 *
 * @param {string} string - The string to be checked.
 * @returns {boolean} - Returns true if the string is a valid HTTP or HTTPS URL, false otherwise.
 */
export const isValidHttpUrl = (string) => {
  if (!string) {
    return false
  }
  if (string.startsWith('http://') || string.startsWith('https://')) {
    return true
  }
  if (string.startsWith('www.')) {
    return true
  }
  if (string.includes('.') && string.split('.')[1].length > 0) {
    return true
  }
  let url
  try {
    // If the string doesn't start with a protocol, prepend 'https://'
    const urlString =
      string.startsWith('http://') || string.startsWith('https://')
        ? string
        : `https://${string}`
    url = new URL(urlString)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

const HOCKEY_OVERTIME_ONE_ID = 4
const SOCCER_EXTRATIME_ONE_ID = 16

/**
 * @deprecated Determines if a game is in overtime based on the sport and period ID.
 *
 * @param {boolean} isSoccer - Whether the sport is soccer or not.
 * @param {number} periodId - The ID of the current period.
 * @param {boolean} isGenericGame - Whether the game is a generic game or not.
 * @param {boolean} isHockey - Whether the sport is hockey or not.
 * @param {string} periodName - The name of the current period.
 * @returns {boolean} - Whether the game is in overtime or not.
 */
export const isOvertime = (
  isSoccer,
  periodId,
  isGenericGame,
  isHockey = false,
  periodName = null
) => {
  if (!periodId) {
    return false
  }
  if (isGenericGame) {
    return false
  }
  // Other full stats sports
  if (!isHockey && !isSoccer) {
    // check if the period name is overtime, if so return true. Translate the period name to lowercase
    return periodName?.toLowerCase() === 'overtime'
  }
  return isSoccer
    ? periodId >= SOCCER_EXTRATIME_ONE_ID
    : periodId >= HOCKEY_OVERTIME_ONE_ID
}

/**
 * Transforms an array of games into a grouped structure based on the root schedule ID.
 *
 * @param {Array} data - The array of games to be transformed.
 * @return {Array} The transformed array of games grouped by the root schedule ID.
 */
export const transformGames = (data = []) => {
  const groupedGames = data.reduce((acc, game) => {
    const scheduleId = game?.rootSchedule?.id || game?.schedule?.id
    if (!acc[scheduleId]) {
      if (game?.rootSchedule?.id) {
        acc[scheduleId] = {
          schedule: { ...game?.rootSchedule, sport_id: game?.sport_id },
          games: [],
        }
      } else {
        acc[scheduleId] = {
          schedule: { ...game?.schedule, sport_id: game?.sport_id },
          games: [],
        }
      }
    }
    acc[scheduleId].games.push(game)
    return acc
  }, {})
  return groupedGames ? Object.values(groupedGames) : []
}

/**
 * Groups the games by date.
 *
 * @param {Array} data - The array of games data.
 * @return {Array} An array of objects representing the games grouped by date.
 */
export const groupGamesByDate = (data = []) => {
  const gamesByDate = data.reduce((acc, game) => {
    let date = getDayjsTZDate(game?.starts_at, game?.venue?.timezone)
    if (date) {
      date = date.format('YYYY-MM-DD')
    } else {
      date = dayjs(game?.starts_at).utc().format('YYYY-MM-DD')
    }
    if (!acc[date]) {
      acc[date] = {
        date,
        gamesGroupedByDate: [],
      }
    }
    acc[date].gamesGroupedByDate.push(game)
    return acc
  }, {})
  const gamesByDateAsArray = gamesByDate ? Object.values(gamesByDate) : []
  return gamesByDateAsArray
}

/**
 * Check if players have received any red cards based on offenses.
 *
 * @param {Array} players - The array of player objects.
 * @param {Array} offenses - The array of offense objects.
 * @return {Array} The modified array of player objects with the "has_red_card" property.
 */
export const filterPlayersWithoutRedCard = (players, offenses) => {
  players.forEach((player) => {
    const hasRedCard = offenses.some((offense) => {
      return (
        offense.player_id === player.id &&
        offense?.penalty?.card &&
        offense?.penalty?.card?.type?.id === '2'
      )
    })
    player.has_red_card = hasRedCard
  })
  return players.filter((player) => !player.has_red_card)
}

/**
 * Retrieves an array of membership subtitles based on the given roles.
 *
 * @param {Array} roles - The roles to retrieve the membership subtitles for.
 * @return {Array} An array of membership subtitles.
 */
export const getMembershipsByRoles = (roles) => {
  let subtitles = []
  if (roles?.length > 0) {
    for (let i = 0; i < roles?.length; i++) {
      const text = baseRoles?.[roles[i]?.role_id]
      if (text) {
        // First lets check if the text is already in the array. If yes, we shouldn't add it again
        if (subtitles.includes(text)) {
          continue
        }
        subtitles.push(text)
      } else {
        subtitles.push('Member')
      }
    }
  }
  return subtitles
}

/**
 * Calculates the summary of skater statistics.
 *
 * @param {Array} stats - The array of skater statistics.
 * @returns {Object|null} - The summary of skater statistics or null if stats is empty.
 */
export const getSkaterSummary = (stats) => {
  if (!stats || stats.length === 0) {
    return null
  }
  const summary = stats?.reduce(
    (acc, curr) => {
      acc.gp += parseInt(curr?.gp) || 0
      acc.g += parseInt(curr?.g) || 0
      acc.a += parseInt(curr?.a) || 0
      acc.pts += parseInt(curr?.pts) || 0
      acc.pim += parseInt(curr?.pim) || 0
      acc.ppg += parseInt(curr?.ppg) || 0
      return acc
    },
    { gp: 0, g: 0, a: 0, pts: 0, pim: 0, ppg: 0 }
  )
  return summary
}

/**
 * Calculates the summary of goalie statistics.
 *
 * @param {Array} stats - The array of goalie statistics.
 * @returns {Object|null} - The summary of goalie statistics, or null if stats is empty.
 */
export const getGoalieSummary = (stats) => {
  if (!stats || stats.length === 0) {
    return null
  }
  const summary = stats?.reduce(
    (acc, curr) => {
      acc.gp += parseInt(curr?.gp) || 0
      acc.w += parseInt(curr?.w) || 0
      acc.l += parseInt(curr?.l) || 0
      acc.t += parseInt(curr?.t) || 0
      acc.otl += parseInt(curr?.otl) || 0
      acc.so += parseInt(curr?.so) || 0
      acc.min += parseInt(curr?.min) || 0
      acc.pim += parseInt(curr?.pim) || 0
      acc.ppg += parseInt(curr?.ppg) || 0
      acc.sa += parseInt(curr?.sa) || 0
      acc.sv += parseInt(curr?.sv) || 0
      acc.ga += parseInt(curr?.ga) || 0
      return acc
    },
    {
      gp: 0,
      w: 0,
      l: 0,
      t: 0,
      otl: 0,
      so: 0,
      min: 0,
      pim: 0,
      ppg: 0,
      sa: 0,
      sv: 0,
      ga: 0,
    }
  )
  return summary
}

/**
 * Calculates the summary of player statistics.
 *
 * @param {Array} stats - The array of player statistics.
 * @returns {Object|null} - The summary of player statistics or null if stats is empty.
 */
export const getPlayerSummary = (stats) => {
  if (!stats || stats.length === 0) {
    return null
  }
  const summary = stats?.reduce(
    (acc, curr) => {
      acc.mp += parseInt(curr?.mp) || 0
      acc.mins += parseInt(curr?.mins) || 0
      acc.g += parseInt(curr?.g) || 0
      acc.a += parseInt(curr?.a) || 0
      acc.ga += parseInt(curr?.ga) || 0
      acc.fc += parseInt(curr?.fc) || 0
      acc.rc += parseInt(curr?.rc) || 0
      acc.yc += parseInt(curr?.yc) || 0
      acc.bc += parseInt(curr?.bc) || 0
      return acc
    },
    { mp: 0, g: 0, a: 0, ga: 0, fc: 0, rc: 0, yc: 0, bc: 0, mins: 0 }
  )
  return summary
}

/**
 * Calculates the summary of keeper stats.
 *
 * @param {Array} stats - The array of stats objects.
 * @returns {Object|null} - The summary object containing the total stats.
 */
export const getKeeperSummary = (stats) => {
  if (!stats || stats.length === 0) {
    return null
  }
  const summary = stats?.reduce(
    (acc, curr) => {
      acc.mp += parseInt(curr?.mp) || 0
      acc.mins += parseInt(curr?.mins) || 0
      acc.gf += parseInt(curr?.gf) || 0
      acc.ga += parseInt(curr?.ga) || 0
      acc.sv += parseInt(curr?.sv) || 0
      acc.cs += parseInt(curr?.cs) || 0
      acc.w += parseInt(curr?.w) || 0
      acc.l += parseInt(curr?.l) || 0
      acc.d += parseInt(curr?.d) || 0
      return acc
    },
    { mp: 0, gf: 0, ga: 0, sv: 0, cs: 0, mins: 0, w: 0, l: 0, d: 0 }
  )
  return summary
}

/**
 * Validates if a given URL is valid.
 *
 * @param {string} url - The URL to be validated.
 * @return {boolean} True if the URL is valid, false otherwise.
 */
export const isValidUrl = (url) => {
  if (!url) {
    return false
  }
  if (typeof url !== 'string') {
    return false
  }
  const pattern =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
  return pattern.test(url)
}

/**
 * Returns the full name of a period given its abbreviation.
 *
 * @param {string} periodAbbreviation - The abbreviation of the period.
 * @return {string} The full name of the period.
 */
export const getPeriodFullName = (periodAbbreviation, isSoccer = false) => {
  switch (periodAbbreviation) {
    case '1st':
      return `1st ${isSoccer ? 'Half' : 'Period'}`
    case '2nd':
      return `2nd ${isSoccer ? 'Half' : 'Period'}`
    case '3rd':
      return `3rd ${isSoccer ? 'Half' : 'Period'}`
    case 'OT1':
      return 'Overtime 1st'
    case 'OT2':
      return 'Overtime 2nd'
    case 'OT3':
      return 'Overtime 3rd'
    case 'OT4':
      return 'Overtime 4th'
    case 'OT5':
      return 'Overtime 5th'
    case 'OT6':
      return 'Overtime 6th'
    case 'OT7':
      return 'Overtime 7th'
    case 'OT8':
      return 'Overtime 8th'
    case 'OT9':
      return 'Overtime 9th'
    case 'PS':
      return 'Penalty Shootout'
    case 'ET1':
      return '1st Extra Time '
    case 'ET2':
      return '2nd Extra Time '
    default:
      return ''
  }
}

/**
 * Generates an error message based on the given object.
 *
 * @deprecated This function is deprecated. Use getErrorMessage instead.
 * @param {object} obj - The object containing the fields for the error message.
 * @return {string|null} The generated error message or null if the object is empty or undefined.
 */
export const createErrorMessage = (obj) => {
  if (!obj || Object.keys(obj).length === 0) {
    return null
  }
  const fields = Object.keys(obj).filter((key) => key !== 'name')
  const message = fields.map((field) => obj[field]).join('\n')
  return message
}

/**
 * Transforms a message object into a new format.
 *
 * @param {object} message - The message object to be transformed.
 * @param {string} tz - The timezone to be used for date conversion.
 * @param {string} defaultImage - The default image to be used for the user avatar.
 * @return {object} The transformed message object.
 */
export const transformMessage = (message, tz, defaultImage) => {
  const date = dayjs.utc(message?.created_at, 'YYYY-MM-DD hh:mm:ss')
  const localTime = date.tz(tz)
  const transformedMessage = {
    _id: message?.local_id,
    api_id: message?.id,
    text: message?.gyphy_image_aspect_ratio || message?.message,
    image:
      message?.gyphy_image_id ||
      getHighQualityImageThumbnailId(message) ||
      message?.image_url,
    image_url: getHighQualityImageThumbnailId(message) || message?.image_url,
    thumbnail_image_url: getImageThumbnailId(message),
    high_quality_image_url: getHighQualityImageThumbnailId(message),
    createdAt: localTime,
    is_liked: message?.is_liked,
    like_count: message?.like_count,
    likes: message?.likes,
  }
  transformedMessage[message?.team_chat_type_id !== 1 ? 'system' : 'user'] = {
    _id: message?.user?.id,
    name: message?.user?.name,
    avatar: message?.user?.image?.filename || defaultImage,
  }
  return transformedMessage
}

/**
 * Formats an international phone number into a specific format.
 *
 * @param {string} phoneNumber - The phone number to be formatted.
 * @return {string} The formatted phone number.
 */
export const formatInternationalPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return ''
  }
  if (phoneNumber?.length === 11) {
    return phoneNumber?.replace(
      /(\d{1})(\d{3})(\d{3})(\d{4})/,
      '+$1 ($2) $3-$4'
    )
  }
  if (phoneNumber?.length === 14) {
    return phoneNumber?.replace(
      /(\d{2})(\d{3})(\d{5})(\d{4})/,
      '+$1 ($2) $3-$4'
    )
  }
  return phoneNumber
}

/**
 * Cleans a phone number by removing any non-digit characters.
 *
 * @param {string} phoneNumber - The phone number to be cleaned.
 * @return {string} The cleaned phone number with only digits.
 */
export const cleanPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return ''
  }
  return phoneNumber?.replace(/\D/g, '')
}

/**
 * Removes empty keys from an object, based on the provided includeKeys array.
 *
 * @param {Object} values - The object containing the keys to be filtered.
 * @param {Array} includeKeys - An optional array of keys to be included even if they are empty.
 * @return {Object} - The filtered object with non-empty keys.
 */
export const _removeEmptyKeys = (values, includeKeys = []) => {
  const form = {}
  for (let key in values) {
    if (
      includeKeys.includes(key) ||
      (values[key] !== null && values[key].length !== 0)
    ) {
      form[key] = values[key]
    }
  }
  return form
}

/**
 * Returns the error message from a form error object.
 *
 * @deprecated This function is deprecated. Use getErrorMessage instead.
 *
 * @param {Object|string} error - The form error object or error message.
 * @return {string} The formatted error message.
 */
export const getErrorMessageFromFormError = (error) => {
  if (typeof error === 'string') {
    return error
  }
  let errorMessage = ''
  for (let field in error) {
    const { type, message } = error[field]
    if (message) {
      errorMessage = `${field} ${message}`
    } else {
      errorMessage = `${field} is ${type}`
    }
    // Stop at the first error message
    break
  }

  if (!errorMessage) {
    return ''
  }
  return (
    errorMessage
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .charAt(0)
      .toUpperCase() + errorMessage.slice(1)
  )
}

/**
 * Checks if the given value is a valid email address.
 *
 * @param {string} value - The value to be checked.
 * @return {boolean} Returns true if the value is a valid email address, otherwise returns false.
 */
export const isValidEmail = (value) => {
  if (!value) {
    return false
  }
  var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(value)
}

/**
 * Calculates the skater summary for web based on the provided stats.
 * @param {Array} stats - The array of skater stats.
 * @returns {Object|null} - The skater summary object or null if stats is empty.
 */
export const getSkaterSummaryForWeb = (stats) => {
  if (!stats || stats.length === 0) {
    return null
  }
  const summary = stats?.reduce(
    (acc, curr) => {
      acc.GP += parseInt(curr?.GP) || 0
      acc.G += parseInt(curr?.G) || 0
      acc.A += parseInt(curr?.A) || 0
      acc.P += parseInt(curr?.P) || 0
      acc.PiM += parseInt(curr?.PiM) || 0
      acc.PPG += parseInt(curr?.PPG) || 0
      return acc
    },
    { GP: 0, G: 0, A: 0, P: 0, PiM: 0, PPG: 0 }
  )
  return summary
}

/**
 * Calculates the player summary for web based on the given stats.
 *
 * @param {Array} stats - The array of stats for the player.
 * @returns {Object|null} - The player summary object containing the total MP, G, A, SV, and FC.
 */
export const getPlayerSummaryForWeb = (stats) => {
  if (!stats || stats.length === 0) {
    return null
  }
  const summary = stats?.reduce(
    (acc, curr) => {
      acc.MP += parseInt(curr?.MP) || 0
      acc.Mins += parseInt(curr?.Mins) || 0
      acc.G += parseInt(curr?.G) || 0
      acc.A += parseInt(curr?.A) || 0
      acc.GA += parseInt(curr?.GA) || 0
      acc.FC += parseInt(curr?.FC) || 0
      acc.YC += parseInt(curr?.YC) || 0
      acc.RC += parseInt(curr?.RC) || 0
      acc.BC += parseInt(curr?.BC) || 0
      return acc
    },
    { MP: 0, Mins: 0, G: 0, A: 0, GA: 0, FC: 0, YC: 0, RC: 0, BC: 0 }
  )
  return summary
}

/**
 * Calculates the goalie summary for the web based on the provided stats.
 *
 * @param {Array} stats - The array of goalie stats.
 * @returns {Object|null} - The goalie summary object or null if stats is empty.
 */
export const getGoalieSummaryForWeb = (stats) => {
  if (!stats || stats.length === 0) {
    return null
  }
  const summary = stats?.reduce(
    (acc, curr) => {
      acc.GP += parseInt(curr?.GP) || 0
      acc.W += parseInt(curr?.W) || 0
      acc.L += parseInt(curr?.L) || 0
      acc.T += parseInt(curr?.T) || 0
      acc.OTL += parseInt(curr?.OTL) || 0
      acc.SO += parseInt(curr?.SO) || 0
      acc.MP += parseInt(curr?.MP) || 0
      acc.PiM += parseInt(curr?.PIM) || 0
      acc.PPG += parseInt(curr?.PPG) || 0
      acc.SA += parseInt(curr?.SA) || 0
      acc.SV += parseInt(curr?.SV) || 0
      acc.GA += parseInt(curr?.GA) || 0
      return acc
    },
    {
      GP: 0,
      W: 0,
      L: 0,
      T: 0,
      OTL: 0,
      SO: 0,
      MP: 0,
      PiM: 0,
      PPG: 0,
      SA: 0,
      SV: 0,
      GA: 0,
    }
  )
  return summary
}

/**
 * Calculates the summary of goalkeeper statistics for the web.
 *
 * @param {Array} stats - The array of goalkeeper statistics.
 * @returns {Object|null} - The summary object containing the total number of matches played (mp),
 * goals scored (gf), goals conceded (gc), saves made (sv), and clean sheets (cs). Returns null if
 * the stats array is empty or undefined.
 */
export const getKeeperSummaryForWeb = (stats) => {
  if (!stats || stats.length === 0) {
    return null
  }
  const summary = stats?.reduce(
    (acc, curr) => {
      acc.mp += parseInt(curr?.MP) || 0
      acc.mins += parseInt(curr?.Mins) || 0
      acc.gf += parseInt(curr?.GF) || 0
      acc.ga += parseInt(curr?.GA) || 0
      acc.sv += parseInt(curr?.SV) || 0
      acc.cs += parseInt(curr?.CS) || 0
      acc.w += parseInt(curr?.W) || 0
      acc.l += parseInt(curr?.L) || 0
      acc.d += parseInt(curr?.d) || 0
      return acc
    },
    { mp: 0, gf: 0, ga: 0, sv: 0, cs: 0, mins: 0, w: 0, l: 0, d: 0 }
  )
  return summary
}

/**
 * Returns an error message based on the provided error object.
 * @param {Error|string|any} error - The error object or error message.
 * @returns {string} - The error message.
 */
export const getErrorMessage = (error) => {
  if (!error) {
    return 'An error occurred. Please try again later.'
  }

  if (typeof error === 'string') {
    return error // Directly return the error message if it's a string
  }

  // Specific error handling based on properties:
  if (error?.invalid_fields) {
    // Handle array of error messages
    if (Array.isArray(error.invalid_fields)) {
      return error.invalid_fields.join('\n')
    }

    // Handle object of error messages
    const invalidFields = Object.keys(error.invalid_fields).map(
      (field) => error.invalid_fields[field]
    )
    return `Invalid fields:\n${invalidFields.join('\n')}`
  }

  if (error?.message) {
    return error?.message // Prioritize error.message
  }

  // Fallback generic message
  return error || 'An error occurred. Please try again later.'
}

/**
 * Formats a value as currency.
 *
 * @param {number} value - The value to format as currency.
 * @param {string} [currency='USD'] - The currency code to use for formatting.
 * @returns {string} The formatted currency value.
 */
export const formatCurrency = (value, currency = 'USD') => {
  // Ensure the value is a number
  const numericValue = Number(value) || 0

  // Format as currency
  const formattedValue = numericValue.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  // Construct the final string
  return `$${formattedValue} ${currency.toUpperCase()}`
}

/**
 * Formats a date string into a specific format.
 * @param {string} date - The date string to be formatted.
 * @param {string} [defaultValue='-'] - The default value to be returned if the date string is invalid.
 * @returns {string} The formatted date string.
 */
export const formatDate = (date, defaultValue = '-') => {
  const dateObj = new Date(date)
  if (isNaN(dateObj)) {
    return defaultValue
  }
  const day = String(dateObj.getUTCDate()).padStart(2, '0')
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
  const year = dateObj.getUTCFullYear()
  return `${day}-${month}-${year}`
}
