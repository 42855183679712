/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ROUTES } from '@sportninja/common/constants/app'
import { useEffect, useMemo } from 'react'
import Page from '../../components/Page'
import { useRegistrationData } from '@sportninja/common/hooks/useRegistrationData'
import { connect } from 'react-redux'
import { getSports } from '@sportninja/common/selectors/types'
import RegistrationDetailsBilling from './RegistrationDetailsBilling'
import RegistrationDetailsInfo from './RegistrationDetailsInfo'
import { useCountries } from '@sportninja/common/hooks/useCountries'
import { useRegistrationDetails } from '@sportninja/common/hooks/useRegistrationDetails'
import { Empty, Spin } from 'antd'

const RegistrationDetails = ({ match }) => {
  const SCHEDULE_ID = match.params.id
  const REGISTRATION_ID = new URLSearchParams(window.location.search).get(
    'registration_id'
  )

  const actions = useMemo(() => {
    return []
  }, [])
  const { types, fetchTypes } = useRegistrationData()
  const {
    data: registrationDetails,
    loading,
    error,
    refetch,
  } = useRegistrationDetails(SCHEDULE_ID, REGISTRATION_ID || undefined)

  const { countries, refetch: refetchCountries } = useCountries()
  useEffect(() => {
    if (!types || types === null) {
      fetchTypes()
    }
  }, [fetchTypes, types])

  useEffect(() => {
    if (!countries || countries === null || countries?.length === 0) {
      refetchCountries()
    }
  }, [countries, refetchCountries])

  const pages = useMemo(
    () => [
      {
        to: `${ROUTES.REGISTRATION_DETAILS.ROOT}/${SCHEDULE_ID}/info`,
        text: 'Registration Info',
        component: (
          <RegistrationDetailsInfo
            registrationDetails={registrationDetails || undefined}
            countries={countries}
            refetch={refetch}
          />
        ),
        actions: actions,
      },
      registrationDetails?.billing &&
        registrationDetails.billing.length > 0 && {
          to: `${ROUTES.REGISTRATION_DETAILS.ROOT}/${SCHEDULE_ID}/billing`,
          text: 'Billing Schedule',
          component: (
            <RegistrationDetailsBilling
              selectedRegistration={registrationDetails || null}
              currencies={types?.currency || []}
            />
          ),
          actions: actions,
        },
    ],
    [
      SCHEDULE_ID,
      actions,
      countries,
      refetch,
      registrationDetails,
      types?.currency,
    ]
  )

  const BASE_ROUTE = `${ROUTES.REGISTRATION_DETAILS.ROOT}/${SCHEDULE_ID}`

  if (loading) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
        `}
      >
        <Spin size='large' />
      </div>
    )
  }

  if (error) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
        `}
      >
        <Empty
          description={error?.message || ''}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      </div>
    )
  }

  return (
    // @ts-ignore
    <Page
      baseRoute={BASE_ROUTE}
      pages={pages}
      baseTitle='Registration Details'
      title={`${registrationDetails?.schedule?.name || ''} - ${
        registrationDetails?.team?.name || ''
      }`}
      isOrgsPage={true}
      preserveQueryFor={[
        `${ROUTES.REGISTRATION_DETAILS.ROOT}/${SCHEDULE_ID}/info`,
        `${ROUTES.REGISTRATION_DETAILS.ROOT}/${SCHEDULE_ID}/billing`,
      ]}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    sports: getSports(state),
  }
}

const mapDispatchToProps = (_) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationDetails)
