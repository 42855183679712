import React, { createContext, useContext, useState } from 'react'

export type CouponFormValues = {
  code: string
  discount: string
  discount_type: 'amount' | 'percentage'
  is_active: boolean
  starts_at?: string
  ends_at?: string
  current_uses: number
  max_uses: number
}

type CouponContextType = {
  id: string
  setId: (id: string) => void
  code: string
  setCode: (code: string) => void
  discount: string
  setDiscount: (discount: string) => void
  discountType: 'amount' | 'percentage'
  setDiscountType: (type: 'amount' | 'percentage') => void
  isActive: boolean
  setIsActive: (active: boolean) => void
  startsAtMonth: string
  setStartsAtMonth: (month: string) => void
  startsAtDay: string
  setStartsAtDay: (day: string) => void
  startsAtYear: string
  setStartsAtYear: (year: string) => void
  endsAtMonth: string
  setEndsAtMonth: (month: string) => void
  endsAtDay: string
  setEndsAtDay: (day: string) => void
  endsAtYear: string
  setEndsAtYear: (year: string) => void
  fieldErrors: string[]
  setFieldErrors: (errors: string[]) => void
  customError: any
  setCustomError: (error: any) => void
  isLoading: boolean
  setIsLoading: (loading: boolean) => void
  currentUses: number
  setCurrentUses: (uses: number) => void
  maxUses: number
  setMaxUses: (uses: number) => void
  emailRestrictions: string[]
  setEmailRestrictions: (restrictions: string[]) => void
}

export const ManageCouponContext = createContext<CouponContextType>(
  {} as CouponContextType
)

export const ManageCouponProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [code, setCode] = useState('')
  const [discount, setDiscount] = useState('0')
  const [discountType, setDiscountType] = useState<'amount' | 'percentage'>(
    'amount'
  )
  const [isActive, setIsActive] = useState(true)
  const [startsAtMonth, setStartsAtMonth] = useState('')
  const [startsAtDay, setStartsAtDay] = useState('')
  const [startsAtYear, setStartsAtYear] = useState('')
  const [endsAtMonth, setEndsAtMonth] = useState('')
  const [endsAtDay, setEndsAtDay] = useState('')
  const [endsAtYear, setEndsAtYear] = useState('')
  const [fieldErrors, setFieldErrors] = useState<string[]>([])
  const [customError, setCustomError] = useState<any>({
    code: '',
    discount: '',
    discount_type: 'amount',
    is_active: true,
    starts_at: '',
    ends_at: '',
    current_uses: 0,
    max_uses: 0,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [id, setId] = useState('')
  const [currentUses, setCurrentUses] = useState(0)
  const [maxUses, setMaxUses] = useState(0)
  const [emailRestrictions, setEmailRestrictions] = useState<string[]>([])

  return (
    <ManageCouponContext.Provider
      value={{
        code,
        setCode,
        discount,
        setDiscount,
        discountType,
        setDiscountType,
        isActive,
        setIsActive,
        startsAtMonth,
        setStartsAtMonth,
        startsAtDay,
        setStartsAtDay,
        startsAtYear,
        setStartsAtYear,
        endsAtMonth,
        setEndsAtMonth,
        endsAtDay,
        setEndsAtDay,
        endsAtYear,
        setEndsAtYear,
        fieldErrors,
        setFieldErrors,
        customError,
        setCustomError,
        isLoading,
        setIsLoading,
        id,
        setId,
        currentUses,
        setCurrentUses,
        maxUses,
        setMaxUses,
        emailRestrictions,
        setEmailRestrictions,
      }}
    >
      {children}
    </ManageCouponContext.Provider>
  )
}

export const useManageCoupon = () => {
  const context = useContext(ManageCouponContext)
  if (!context) {
    throw new Error(
      'useManageCoupon must be used within a ManageCouponProvider'
    )
  }
  return context
}
