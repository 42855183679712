/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useLayoutEffect, useRef, useState } from 'react'

import Icon from '../../../components/Icon'
import LoadingSpinner from '../../../components/LoadingSpinner'

const Tooltip = ({
  as: As,
  baseCss,
  children,
  text,
  tooltipEnabled,
  offset,
  ...props
}) => {
  const ref = useRef(null)
  const [height, setHeight] = useState(8)

  const Wrap = As ? As : 'div'

  useLayoutEffect(() => {
    if (tooltipEnabled) {
      const box = ref.current.getBoundingClientRect()
      setHeight(box.height + 8)
    }
  }, [])

  return (
    <Wrap
      css={css`
        position: relative;

        .tooltip-wrap {
          display: flex;
          justify-content: center;
          position: absolute;
          height: inherit;
          top: 0;
          left: 0;
          right: 0;
          z-index: 1;

          &:after {
            border-top-color: #000;
            border-top-style: solid;
            border-top-width: 6px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            top: -8px;
            left: 50%;
            margin-left: -8px;
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            opacity: 0;
            transition: opacity 0.1s ease-in-out;
          }
        }

        .tooltip {
          pointer-events: none;
          position: absolute;
          top: -${height}px;
          ${offset && `transform: translateX(${offset}px)`};
          width: max-content;

          opacity: 0;
          transition: opacity 0.1s ease-in-out;
          background-color: black;
          border-radius: 8px;
          padding: 12px;
        }

        ${tooltipEnabled &&
        css`
          &:hover {
            .tooltip-wrap::after {
              opacity: 1;
            }
            .tooltip {
              opacity: 1;
            }
          }
        `}

        ${baseCss}
      `}
      {...props}
    >
      <div className='tooltip-wrap'>
        <div className='tooltip' ref={ref}>
          {text}
        </div>
      </div>
      {children}
    </Wrap>
  )
}

const RosterEditStatusButton = ({
  player,
  settingName,
  existingPlayerNums = [],
  onPlayerChange,
  iconName = 'check',
  backgroundColor = 'green',
  startingGoalie,
  requireUniqueJerseyNumbers = true,
  isStartingGoalie = false,
  goaliePositionId = null,
  allOtherStartingGoalies = [],
  onUpdateGoalieStarting = null,
  teamId = null,
}) => {
  const [isSaving, setIsSaving] = useState(false)

  const onClick = async () => {
    const settings = {
      [settingName]: !player[settingName],
    }

    if (settings.is_suspended || settings.is_injured) {
      settings.is_playing = false
    }

    if ((settings.is_starting && !player.is_playing) || settings.is_playing) {
      settings.is_injured = false
    }

    // Ensure that an undefined value is not sent for is_playing when a player
    // is set as "away"
    if (settings.is_playing === 'undefined') {
      settings.is_playing = false
    }

    // Ensure that if a player is set to away that they are not also "starting"
    if (settings.is_playing === false) {
      settings.is_starting = false
    }

    if (settings.is_starting) {
      settings.is_playing = true
    }

    setIsSaving(true)
    try {
      const result = await onPlayerChange(player.id, settings)
      setIsSaving(false)
      return result
    } catch (e) {
      setIsSaving(false)
      throw e
    }
  }

  const onClickStartingGoalie = async () => {
    const settings = {
      [settingName]: !player[settingName],
    }
    if (settings.is_starting === true) {
      settings.player_type_id = goaliePositionId
      settings.is_playing = true
    }
    setIsSaving(true)
    try {
      if (onUpdateGoalieStarting) {
        await onUpdateGoalieStarting(
          player.id,
          settings,
          allOtherStartingGoalies,
          teamId
        )
      }
      setIsSaving(false)
    } catch (e) {
      setIsSaving(false)
      throw e
    }
  }

  // Disable starting button, if the player is a goalie, and there is already
  // another starting goalie
  const isDisabledForGoalie =
    settingName === 'is_starting' &&
    player.player_type.is_goalie === 1 &&
    startingGoalie &&
    startingGoalie.id &&
    player.id !== startingGoalie.id

  // Disable starting button, if the player is not yet starting, but wants to
  // start, but has the same player number as another starting player
  const isDisabledForNumber =
    ((settingName === 'is_starting' &&
      !player.is_starting &&
      !player.is_playing) ||
      (settingName === 'is_playing' && !player.is_playing)) &&
    existingPlayerNums.includes(player.player_number) &&
    requireUniqueJerseyNumbers

  const isDisabled = isDisabledForGoalie || isDisabledForNumber

  if (settingName === 'is_starting') {
    return (
      <Tooltip
        offset={
          settingName === 'is_injured'
            ? -105
            : settingName === 'is_playing'
            ? -28
            : 0
        }
        tooltipEnabled={isDisabledForNumber}
        as='td'
        onClick={isDisabledForNumber ? undefined : onClickStartingGoalie}
        className={isStartingGoalie ? 'is-active' : ''}
        text={`Player number (#${player.player_number}) is already in use`}
        baseCss={css`
          text-align: center;
          cursor: ${isDisabledForNumber ? 'not-allowed' : 'pointer'};

          button {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid gray;
            background-color: ${colors.HEADER};
            line-height: 0px;

            i {
              opacity: 0;
            }
          }

          &.is-active button {
            border-color: ${backgroundColor};
            background-color: ${backgroundColor};

            i {
              opacity: 1;
            }
          }

          button[disabled] {
            opacity: 0.5;
            cursor: not-allowed;
          }

          ${!isDisabled &&
          css`
            &:hover {
              background-color: rgba(255, 255, 255, 0.1);
            }
          `}
        `}
      >
        {isSaving ? (
          <LoadingSpinner size={2} borderSize={2} />
        ) : (
          <button type='button' disabled={isDisabledForNumber}>
            <Icon name={iconName} color='white' fontSize={16} />
          </button>
        )}
      </Tooltip>
    )
  }

  return (
    <Tooltip
      offset={
        settingName === 'is_injured'
          ? -105
          : settingName === 'is_playing'
          ? -28
          : 0
      }
      tooltipEnabled={isDisabled}
      as='td'
      onClick={isDisabled ? undefined : onClick}
      className={player[settingName] ? 'is-active' : ''}
      text={
        isDisabledForNumber
          ? `Player number (#${player.player_number}) is already in use`
          : 'You already have 1 starting goalie'
      }
      baseCss={css`
        text-align: center;
        cursor: ${isDisabled ? 'not-allowed' : 'pointer'};

        button {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 2px solid gray;
          background-color: ${colors.HEADER};
          line-height: 0px;

          i {
            opacity: 0;
          }
        }

        &.is-active button {
          border-color: ${backgroundColor};
          background-color: ${backgroundColor};

          i {
            opacity: 1;
          }
        }

        button[disabled] {
          opacity: 0.5;
          cursor: not-allowed;
        }

        ${!isDisabled &&
        css`
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        `}
      `}
    >
      {isSaving ? (
        <LoadingSpinner size={2} borderSize={2} />
      ) : (
        <button type='button' disabled={isDisabled}>
          <Icon name={iconName} color='white' fontSize={16} />
        </button>
      )}
    </Tooltip>
  )
}

export default RosterEditStatusButton
