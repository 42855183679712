import { call, put } from 'redux-saga/effects'

import req from '@sportninja/common/api/request'

const _read = (entityType) => async (id) => await req(`/${entityType}/${id}`)

export const readFactory = (entityType, action) =>
  function* (payload) {
    const { id } = payload
    const { data, meta } = yield call(_read(entityType), id)

    // Include permissions object if present
    let permission
    if (meta && meta.permissions) permission = meta.permissions[id]

    let hierarchy
    if (meta && meta.hierarchy) hierarchy = meta.hierarchy

    const mergedData = { ...data, permission, hierarchy }
    yield put(action.success({ id, data: mergedData }))
    return data
  }

export const readWithPagesFactory = (method, action) =>
  function* (payload) {
    const { id, page, sort, direction, is_archive } = payload
    const response =
      typeof id === 'string'
        ? yield call(method, id, page, sort, direction, is_archive)
        : yield call(method, page, sort, direction, is_archive)
    const { data, meta } = response

    // Combine in the permission for each item in the list
    yield put(
      action.success({
        id,
        data: data.map((d) => {
          let permission

          if (meta && meta.permissions && meta.permissions[d.id]) {
            permission = meta.permissions[d.id]
          }

          return {
            ...d,
            permission,
          }
        }),
      })
    )
    return response
  }
