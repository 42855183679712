/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import Select from '../Select'
import { TimeContainer } from './css'
import colors from '@sportninja/common/constants/appColors'
const getOptions = (length, zeroIndex = true) => {
  const len = zeroIndex ? length : length + 1
  const arr = []
  for (let i = zeroIndex ? 0 : 1; i < len; i++) {
    arr.push(i)
  }
  return arr
}
const TimePicker = ({ defaultValue, onChange, timezone }) => {
  const firstUpdate = useRef(true)
  const day = timezone ? dayjs(defaultValue).tz(timezone) : dayjs(defaultValue)
  const hour = day.hour()
  const minutes = day.minute()
  const isCustomMinutes = useMemo(() => {
    if (!defaultValue) {
      return false
    }
    return (
      String(minutes) !== '00' ||
      String(minutes) !== '15' ||
      String(minutes) !== '30' ||
      String(minutes) !== '45'
    )
  }, [minutes, defaultValue])
  const [minute, setMinute] = useState(
    !defaultValue ? '00' : minutes < 10 ? `0${minutes}` : String(minutes)
  )
  const [customSelected, setCustomSelected] = useState(isCustomMinutes)
  const [displayHour, setDisplayHour] = useState(
    hour <= 12 ? (hour === 0 ? 12 : hour) : hour % 12
  )
  const [amOrPm, setAmOrPm] = useState(hour <= 11 ? 'AM' : 'PM')
  const handleChange = (method, parseAsInt = true, { target }) => {
    // Select options return strings; we need numbers
    const value = parseAsInt ? parseInt(target.value, 10) : target.value
    method(value)
  }
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    let hour = displayHour
    if (hour === 12) {
      if (amOrPm === 'AM') hour = 0
    } else if (amOrPm === 'PM') hour = hour + 12
    onChange(hour, minute)
  }, [amOrPm, displayHour, minute])
  const wrapProps = {
    css: css`
      height: 32px;
      width: 33%;
    `,
  }
  return (
    <TimeContainer row>
      <Select
        value={displayHour}
        onChange={handleChange.bind(this, setDisplayHour, true)}
        wrapProps={wrapProps}
        css={css`
          height: 100%;
        `}
      >
        {getOptions(12, false).map((h) => (
          <option key={h} value={h}>
            {h}
          </option>
        ))}
      </Select>
      {customSelected ? (
        <input
          type='number'
          value={minute}
          onChange={(e) => {
            if (Number(e.target.value) > 59) {
              return
            } else {
              setMinute(e.target.value)
            }
          }}
          css={css`
            width: 33%;
            height: 40px;
            background: #27303e;
            border: 1px solid #3f4753;
            border-radius: 4px;
            padding-left: 8px;
            font-size: 15px;
            color: #fff;
            letter-spacing: 0.74px;
            line-height: normal;
          `}
        />
      ) : (
        <Select
          value={minute}
          onChange={(e) => {
            if (e?.target?.value === 'custom') {
              setCustomSelected(true)
            } else {
              setMinute(e.target.value)
            }
          }}
          wrapProps={wrapProps}
          css={css`
            height: 100%;
          `}
        >
          <option value='00'>00</option>
          <option value='15'>15</option>
          <option value='30'>30</option>
          <option value='45'>45</option>
          <option value='custom'>Custom</option>
        </Select>
      )}
      <Select
        value={amOrPm}
        onChange={(e) => setAmOrPm(e.target.value)}
        wrapProps={wrapProps}
        css={css`
          height: 100%;
        `}
      >
        <option value='AM'>AM</option>
        <option value='PM'>PM</option>
      </Select>
    </TimeContainer>
  )
}
TimePicker.propTypes = {
  defaultValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}
export default TimePicker
