/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import Text from 'src/components/ts-components/Text'
import { useOrganizationModalContext } from '../../context'
import { TextInput } from 'src/components/ts-components/TextInput'
import { SwitchInput } from 'src/components/ts-components/SwitchInput'
import CopyComponent from 'src/components/CopyComponent'
import { Image, Upload } from 'antd'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import colors from '@sportninja/common/constants/appColors'
import DeleteModal from 'src/components/Modal/DeleteModal'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import req from '@sportninja/common/api/request'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const DeleteButton = ({ ...props }) => {
  return (
    <button
      css={css`
        display: flex;
        padding: 8px 16px 8px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid ${colors.ERROR_200};
        background: ${colors.ERROR_200};
        box-shadow: 0px 2px 0px 0px rgba(5, 145, 255, 0.1);
      `}
      {...props}
    >
      <DeleteOutlined
        css={css`
          font-size: 24px;
          color: ${colors.NEUTRAL_0};
        `}
        width={24}
        height={24}
      />
      <Text variant='heading6' weight='semiBold' color={colors.NEUTRAL_0}>
        DELETE {isCanlan ? 'LEAGUE' : 'ORGANIZATION'}
      </Text>
    </button>
  )
}

const GeneralSettings = () => {
  const {
    state,
    setState,
    isEdit,
    beforeUpload,
    handleChange,
    setIsFormOpen,
    refresh,
    messageApi,
    isOnOwnOrgPage,
    clearState,
    handleChangeBackground,
  } = useOrganizationModalContext()

  return (
    <div
      css={css`
        display: flex;
        padding: 16px;
        gap: 16px;
        flex-direction: column;
        align-items: flex-end;
        .ant-upload-wrapper .ant-upload-select {
          width: 100% !important;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text variant='captionLarge' weight='semiBold'>
          General Info
        </Text>
        {isEdit ? (
          <div>
            <CopyComponent id={state.id} />
          </div>
        ) : null}
      </div>
      {state.image_url ? (
        <div
          css={css`
            display: flex;
            padding: 8px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            background-color: ${colors.SECONDARY_200};
            border: 1px solid ${colors.SECONDARY_100};
            width: 100%;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <Image
              src={
                state.image_url ||
                '/images/generic_placeholders/placeholder_competition.png'
              }
              alt='competition logo'
              width={56}
              height={56}
              css={css`
                border-radius: 4px;
                object-fit: cover;
              `}
              preview={false}
            />
            <DeleteOutlined
              css={css`
                color: ${colors.ERROR_200};
                font-size: 24px;
                cursor: pointer;
              `}
              onClick={() => {
                setState({
                  ...state,
                  image_url: null,
                  image_changed: true,
                })
              }}
            />
          </div>
        </div>
      ) : (
        <Upload
          beforeUpload={beforeUpload}
          onChange={handleChange}
          showUploadList={false}
          action={undefined}
          css={css`
            .ant-upload-wrapper .ant-upload {
              width: 100% !important;
            }
            width: 100%;
          `}
        >
          <div
            css={css`
              display: flex;
              padding: 8px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 8px;
              border: 1px dashed ${colors.SECONDARY_100};
              cursor: pointer;
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;
              `}
            >
              <div
                css={css`
                  display: flex;
                  width: 48px;
                  height: 48px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border-radius: 4px;
                  background: ${colors.PRIMARY};
                `}
              >
                <div
                  css={css`
                    display: flex;
                    width: 24px;
                    height: 24px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: ${colors.WHITE};
                  `}
                >
                  {/* antd plus icon */}
                  <PlusCircleOutlined
                    css={css`
                      font-size: 24px;
                      color: ${colors.SECONDARY_300};
                      background-color: ${colors.PRIMARY};
                    `}
                  />
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 4px;
                  `}
                >
                  <Text
                    variant='paragraphSmall'
                    weight='regular'
                    color={colors.NEUTRAL_0}
                  >
                    {isCanlan ? 'League Logo' : 'Organization Logo'}
                  </Text>
                </div>
                <Text
                  variant='paragraphSmall'
                  weight='medium'
                  color={colors.PRIMARY}
                >
                  Click to Upload
                </Text>
              </div>
            </div>
          </div>
        </Upload>
      )}
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          align-self: stretch;
          flex-direction: row;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <TextInput
            label='Name *'
            value={state.name}
            setValue={(value) => {
              setState({ ...state, name: value })
            }}
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            disabled={false}
            name='name'
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <TextInput
            label='Abbreviation (up to 8 characters)*'
            value={state.abbreviation}
            setValue={(value) => {
              setState({ ...state, abbreviation: value })
            }}
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            disabled={false}
            maxLength={8}
            name='abbreviation'
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 24px;
          align-self: stretch;
        `}
      >
        <SwitchInput
          title={`Set ${isCanlan ? 'League' : 'Organization'} as Private`}
          subtitle={`Private ${
            isCanlan ? 'Leagues' : 'Organizations'
          } Can Only Be Viewed And Followed By People That Have Been Invited By Admins.`}
          value={state.is_private_organization}
          onChange={(value) => {
            setState({ ...state, is_private_organization: value })
          }}
        />
      </div>
      <div
        css={css`
          height: 1px;
          background-color: ${colors.SECONDARY_100};
          width: 100%;
        `}
      />
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text
          variant='captionSmall'
          weight='semiBold'
          color={colors.NEUTRAL_50}
        >
          Customization
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          align-self: stretch;
          flex-direction: row;
          gap: 16px;
          width: 100%;
        `}
      >
        {state.background_image_url ? (
          <div
            css={css`
              display: flex;
              padding: 8px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 8px;
              background-color: ${colors.SECONDARY_200};
              border: 1px solid ${colors.SECONDARY_100};
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                flex: 1 0 0;
              `}
            >
              <Image
                src={state.background_image_url}
                alt='background'
                width={160}
                height={48}
                css={css`
                  border-radius: 4px;
                  object-fit: cover;
                `}
                preview={false}
              />
              <DeleteOutlined
                css={css`
                  color: ${colors.ERROR_200};
                  font-size: 24px;
                  cursor: pointer;
                `}
                onClick={() => {
                  setState({ ...state, background_image_url: null })
                }}
              />
            </div>
          </div>
        ) : (
          <Upload
            beforeUpload={beforeUpload}
            onChange={handleChangeBackground}
            showUploadList={false}
            action={undefined}
            css={css`
              .ant-upload-wrapper .ant-upload {
                width: 100% !important;
              }
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                padding: 8px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                border-radius: 8px;
                border: 1px dashed ${colors.SECONDARY_100};
                cursor: pointer;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  flex: 1 0 0;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    width: 48px;
                    height: 48px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: ${colors.PRIMARY};
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      width: 24px;
                      height: 24px;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      border-radius: 4px;
                      background: ${colors.WHITE};
                    `}
                  >
                    {/* antd plus icon */}
                    <PlusCircleOutlined
                      css={css`
                        font-size: 24px;
                        color: ${colors.SECONDARY_300};
                        background-color: ${colors.PRIMARY};
                      `}
                    />
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 4px;
                    `}
                  >
                    <Text
                      variant='paragraphSmall'
                      weight='regular'
                      color={colors.NEUTRAL_0}
                    >
                      Header Background
                    </Text>
                    <Text
                      variant='paragraphXSmall'
                      weight='medium'
                      color={colors.NEUTRAL_100}
                    >
                      (Recommended: 1440x300)
                    </Text>
                  </div>
                  <Text
                    variant='paragraphSmall'
                    weight='medium'
                    color={colors.PRIMARY}
                  >
                    Click to Upload
                  </Text>
                </div>
              </div>
            </div>
          </Upload>
        )}
      </div>
      {isEdit ? (
        <>
          <div
            css={css`
              height: 1px;
              background-color: ${colors.SECONDARY_100};
              width: 100%;
            `}
          />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
              width: 100%;
            `}
          >
            <Text
              variant='captionSmall'
              weight='semiBold'
              color={colors.NEUTRAL_50}
            >
              Danger Zone
            </Text>
            <Text
              variant='paragraphXSmall'
              weight='regular'
              color={colors.WHITE}
            >
              Permanently Delete The {isCanlan ? 'League' : 'Organization'} And
              All Info Related.
            </Text>

            <DeleteModal
              title='Delete Organization'
              message={`Are you sure you want to delete ${state.name}? This cannot be undone.`}
              onDelete={async (shouldForce = false) => {
                const query: any = {}
                if (shouldForce) {
                  query.force = 1
                }
                await req(`/${ENTITY_TYPES.org}/${state.id}`, {
                  method: 'DELETE',
                  query,
                })
                messageApi.success(`${state.name} has been deleted.`)
                if (isOnOwnOrgPage) {
                  window.location.href = '/orgs'
                }
                clearState()
                setIsFormOpen(false)
                refresh()
              }}
              Button={DeleteButton}
              forceDeleteText={undefined}
              shouldCloseModalAfterDelete={true}
              useForceDelete={true}
              displayBanner={false}
            />
          </div>
        </>
      ) : null}
    </div>
  )
}

export default GeneralSettings
