import { isCanlan } from './customer-name'

/* Felipe Nunes: Updated Fri, 28 Mar 2025 21:17 PM ( BRT ) */
// If it fails (like if the previously set hardcoded token is expired)
// try setting the hardcoded token again (hopefully a new one)
// {
//   "access_token": "v2.local.CFQUeSOJ3wE8zwKH2I5KkXoX6RfFz3jGlbxQ9QC8zIlggcnxwi_FNFm6jeBzChjP6C1JUpL2y2q5J81IHRLvYLiVHBQRYzsT8NQfPVLkkWMHjeAQJwTz3lhjvYB9sSNFgbtQEJ8UbmxRUu5ybgsNcKJIglIxH_db4EOYg6FlSANyJshpFBUyUP8sqt9s3fWRkm1SMS3w2m9FyumCecQdm01Q8L-j33Pot_4xYdX7VeMDq8vqtg-Tdd8K113JssBAbT4NsMtAGS2ki96kiQLiFSQU_Ta6LhI",
//   "token_type": "Bearer",
//   "expires_in": 604800,
//   "expires_at": "2025-04-05T00:16:57.804943Z",
//   "user": {
//     "id": "TAcc3KT6ikeaqhvL",
//     "email": "michael+planeticeservice@sportninja.com",
//     "created_at": "2022-08-29T23:14:34+00:00",
//     "updated_at": "2025-03-29T00:16:48+00:00",
//     "validated_at": "2022-08-29T23:14:42+00:00"
//   }
// }
const planetIceToken =
  'v2.local.CFQUeSOJ3wE8zwKH2I5KkXoX6RfFz3jGlbxQ9QC8zIlggcnxwi_FNFm6jeBzChjP6C1JUpL2y2q5J81IHRLvYLiVHBQRYzsT8NQfPVLkkWMHjeAQJwTz3lhjvYB9sSNFgbtQEJ8UbmxRUu5ybgsNcKJIglIxH_db4EOYg6FlSANyJshpFBUyUP8sqt9s3fWRkm1SMS3w2m9FyumCecQdm01Q8L-j33Pot_4xYdX7VeMDq8vqtg-Tdd8K113JssBAbT4NsMtAGS2ki96kiQLiFSQU_Ta6LhI'

// {
//   "access_token": "v2.local.f6jKig6TnFsQ4BjS4OBHkKuLGxeQ0QDj-DcbjGIq-z_H3Lu7eM9U6N3VlVXuF4hh9AEeDC4jjhIDlfUbtHxVpskbNcGXgn_EmSkv7aNfqp7dOjqgPR8Mv04GRo7YbDhtF0rYt8OkwWNfk6l5uYvJDWlkGkRq9sz3O1WueUcKluHHQTbzt-2pycvqtm-td8ayQjorV0-D8-kDtcM60En81ZdO-mkQ0LN0e1HpJ68gOe2bdeFg7ezLbI6JgwXYOGfvTXiqymsSbi9WkAOYxsWi0g7mhsNux8M",
//   "token_type": "Bearer",
//   "expires_in": 604800,
//   "expires_at": "2025-04-05T00:18:44.399047Z",
//   "user": {
//     "id": "oP37ysYCd57bFlkj",
//     "email": "michael+carhaservice@sportninja.com",
//     "created_at": "2022-05-27T18:35:59+00:00",
//     "updated_at": "2025-03-29T00:18:41+00:00",
//     "validated_at": "2022-05-27T18:35:59+00:00"
//   }
// }
const carhaToken =
  'v2.local.f6jKig6TnFsQ4BjS4OBHkKuLGxeQ0QDj-DcbjGIq-z_H3Lu7eM9U6N3VlVXuF4hh9AEeDC4jjhIDlfUbtHxVpskbNcGXgn_EmSkv7aNfqp7dOjqgPR8Mv04GRo7YbDhtF0rYt8OkwWNfk6l5uYvJDWlkGkRq9sz3O1WueUcKluHHQTbzt-2pycvqtm-td8ayQjorV0-D8-kDtcM60En81ZdO-mkQ0LN0e1HpJ68gOe2bdeFg7ezLbI6JgwXYOGfvTXiqymsSbi9WkAOYxsWi0g7mhsNux8M'

// {
//   "access_token": "v2.local.bTTBg1ObQyid4UWwJ1AQ0RqSNOCbq2o1e2S7CbA57UWqwzvWlCW1Jo94HWX_Rg77hj_t2_POeQJzZVsBPznbyDIPZHS0XX-ANJ4ZzbEKGrB3BYLHX44XekHMLGgm5AD5jZDuWbxpMa0s85NcPS2yGpVYgodGr83YMQXk5rUTST140i2RmLrkMTqYpUsXhwh1RCBWBtbqIHsRXxiLmIWjqZ4BE53oYsNBTYqlCx6kjORzWxfkThQUicCcq5nBnAz9k0zeao5IfrqbTT__9WDTdE1yTfLv",
//   "token_type": "Bearer",
//   "expires_in": 604800,
//   "expires_at": "2025-04-05T00:20:32.336851Z",
//   "user": {
//     "id": "jJkc8qSsffa24rAE",
//     "email": "michael+canlanservice@sportninja.com",
//     "created_at": "2022-05-30T20:39:26+00:00",
//     "updated_at": "2023-05-19T19:59:44+00:00",
//     "validated_at": "2022-05-30T20:39:26+00:00"
//   }
// }
const canlanToken =
  'v2.local.bTTBg1ObQyid4UWwJ1AQ0RqSNOCbq2o1e2S7CbA57UWqwzvWlCW1Jo94HWX_Rg77hj_t2_POeQJzZVsBPznbyDIPZHS0XX-ANJ4ZzbEKGrB3BYLHX44XekHMLGgm5AD5jZDuWbxpMa0s85NcPS2yGpVYgodGr83YMQXk5rUTST140i2RmLrkMTqYpUsXhwh1RCBWBtbqIHsRXxiLmIWjqZ4BE53oYsNBTYqlCx6kjORzWxfkThQUicCcq5nBnAz9k0zeao5IfrqbTT__9WDTdE1yTfLv'

export const getIframeToken = () => {
  const parentUrl =
    window.location !== window.parent.location
      ? window?.document?.referrer
      : window?.document?.location?.href

  let isPlanetIce = false
  if (parentUrl && parentUrl.includes('pacificadulthockey.com')) {
    isPlanetIce = true
  }
  return isCanlan ? canlanToken : isPlanetIce ? planetIceToken : carhaToken
}
