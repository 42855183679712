/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Switch } from 'antd'
import React from 'react'
import Text from '../Text'
import colors from '@sportninja/common/constants/appColors'

type SwitchInputProps = {
  title: string
  subtitle?: string | React.ReactNode
  value: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
}

export const SwitchInput: React.FC<SwitchInputProps> = ({
  title,
  subtitle,
  value,
  onChange,
  disabled,
}) => {
  return (
    <>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;
        `}
      >
        <Text variant='paragraphSmall' weight='regular' color={colors.WHITE}>
          {title}
        </Text>
        {subtitle ? (
          <Text
            variant='paragraphXSmall'
            weight='regular'
            color={colors.NEUTRAL_50}
          >
            {subtitle}
          </Text>
        ) : null}
      </div>
      <Switch
        onChange={onChange}
        value={value}
        size='small'
        css={css`
          align-self: center;
        `}
        disabled={disabled}
      />
    </>
  )
}
