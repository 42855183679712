/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useMobxStore } from 'src/state'
import { MENU_HEIGHT } from '../../css'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import Icon from 'src/components/Icon'
import { ROUTES } from '@sportninja/common/constants/app'
import AccountSettings from 'src/components/AccountSettings'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'

interface ProfileLinkProps {
  onClick?: () => void
  iconName: string
  label: string
  path?: string
}

const ProfileLink = ({ onClick, iconName, label }: ProfileLinkProps) => {
  return (
    <div
      onClick={
        onClick
          ? (e) => {
              e.preventDefault()
              onClick()
            }
          : undefined
      }
      css={css`
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: ${colors.DEFAULT_FLAIR};
        }
      `}
    >
      <Icon name={iconName} color={colors.DEFAULT_FLAIR} fontSize={14} />
      <p
        css={css`
          font-size: 14px;
        `}
      >
        {label}
      </p>
    </div>
  )
}

const Separator = () => (
  <div
    css={css`
      width: 100%;
      height: 1px;
      background-color: ${colors.SOFT_STEEL};
    `}
  />
)

interface Props {
  onLogoutClick: () => void
}

export const Profile = observer(({ onLogoutClick }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    me: {
      state: { me },
      clearUser,
      fetchMe,
    },
  } = useMobxStore()
  const history = useHistory()

  const confirmExit = () => {
    if (window.confirm('Are you sure you want to logout?')) {
      clearUser()
      onLogoutClick()
    }
  }

  useEffect(() => {
    if (!me?.id) {
      fetchMe()
    }
  }, [])

  return (
    <>
      <div
        onClick={() => setIsOpen(!isOpen)}
        css={css`
          cursor: pointer;
          min-height: 34px;
          max-height: 34px;
          min-width: 34px;
          max-width: 34px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: 2px solid ${colors.ATTENDANCE_GRAY};
          transition: all 0.2s ease-in-out;

          &:hover {
            border: 2px solid ${colors.DEFAULT_FLAIR};
          }
        `}
      >
        {me?.image?.id ? (
          <div>
            <img
              alt={me?.name_first}
              src={getImageThumbnailId(me)}
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          </div>
        ) : (
          <div>
            <p
              css={css`
                font-size: 0.9rem;
                text-transform: uppercase;
              `}
            >
              {me?.name_first && me?.name_last
                ? me?.name_first[0] + me?.name_last[0]
                : '?'}
            </p>
          </div>
        )}
      </div>
      <div
        onClick={() => setIsOpen(false)}
        css={css`
          position: fixed;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          z-index: ${isOpen ? '1000' : '100'};
          pointer-events: ${isOpen ? 'all' : 'none'};
          transition: all 0.2s ease-in-out;

          display: ${isOpen ? 'flex' : 'none'};
        `}
      >
        <div
          css={css`
            position: absolute;
            top: ${MENU_HEIGHT + 8}px;
            right: 8px;
            z-index: ${isOpen ? '1001' : '101'};
            opacity: ${isOpen ? 1 : 0};
            display: ${isOpen ? 'flex' : 'none'};
            max-width: 275px;
            overflow: hidden;
            white-space: nowrap;
            transition: all 0.2s ease-in-out;
            background-color: ${colors.HEADER};
            padding: 16px;
            border-radius: 8px;
            flex-direction: column;
            gap: 18px;
            box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.75);
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            <div
              css={css`
                min-height: 40px;
                max-height: 40px;
                min-width: 40px;
                max-width: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border: 2px solid ${colors.ATTENDANCE_GRAY};
              `}
            >
              {me?.image?.id ? (
                <div>
                  <img
                    alt={me?.name_first}
                    src={getImageThumbnailId(me)}
                    css={css`
                      width: 100%;
                      height: 100%;
                    `}
                  />
                </div>
              ) : (
                <div>
                  <p
                    css={css`
                      font-size: 0.9rem;
                      text-transform: uppercase;
                    `}
                  >
                    {me?.name_first && me?.name_last
                      ? me?.name_first[0] + me?.name_last[0]
                      : '?'}
                  </p>
                </div>
              )}
            </div>
            <div
              css={css`
                > p {
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  max-width: 197px;
                }
              `}
            >
              <p
                css={css`
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                  font-size: 22px;
                  font-style: normal;
                  font-weight: 600;
                  color: ${colors.WHITE};
                `}
              >{`${me?.name_first}  ${me?.name_last}`}</p>
              <p
                css={css`
                  font-size: 14px;
                  margin-top: 4px;
                  color: ${colors.ATTENDANCE_GRAY};
                `}
              >
                {me?.email}
              </p>
            </div>
          </div>
          <Separator />
          <ProfileLink
            iconName='user'
            label='Profile'
            onClick={() => history.push(ROUTES.PROFILE.ROOT)}
          />
          <AccountSettings
            button={({ onClick }) => (
              <ProfileLink
                iconName='cog'
                label='User Settings'
                onClick={onClick}
              />
            )}
          />
          {me?.account_id && me?.account_type && me?.account_type > 1 ? (
            <>
              <Separator />
              <ProfileLink
                iconName='credit-card'
                label='Account Settings'
                onClick={() => history.push(ROUTES.GENERAL_SETTINGS)}
              />
            </>
          ) : null}
          <Separator />
          <a href='/docs/privacy.pdf' target='_blank' rel='noopener noreferrer'>
            <ProfileLink iconName='info-circle' label='Privacy Policy' />
          </a>
          <div
            onClick={confirmExit}
            css={css`
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            <Icon name='sign-out-alt' color={colors.RED} />
            <p
              css={css`
                font-size: 14px;
                color: ${colors.RED};
              `}
            >
              Log Out
            </p>
          </div>
        </div>
      </div>
    </>
  )
})
