/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import React from 'react'

type ErrorLabelProps = {
  fieldErrors: any
  customError: any
  name: string
}

export const ErrorLabel: React.FC<ErrorLabelProps> = ({
  fieldErrors,
  customError,
  name,
}) => {
  return (
    <div
      tabIndex={-1}
      css={css`
        text-transform: capitalize;
        transition: opacity 0.1s ease-in-out;
        display: ${fieldErrors[name] || fieldErrors?.includes(name)
          ? 'inline'
          : 'none'};
        font-size: 13px;
        line-height: 16px;
        color: ${colors.ERROR_LIGHT};
      `}
    >
      <span>
        {(customError && customError[name]) || 'This Field Is Mandatory'}
      </span>
    </div>
  )
}
