/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Select } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { ErrorLabel } from '../ErrorLabel'
import Text from '../Text'
import { weightRegular } from 'src/components/css'
import { paragraphMedium } from 'src/components/css'

type DateOfBirthInputProps = {
  name: string
  value: string // YYYY-MM-DD format
  setValue: (value: string) => void
  fieldErrors: any
  customError: any
  label: string
  disabled?: boolean
}

/**
 * DateOfBirthInput component that allows users to select their date of birth
 * using month, day, and year dropdowns
 */
export const DateOfBirthInput: React.FC<DateOfBirthInputProps> = ({
  name,
  value,
  setValue,
  fieldErrors,
  customError,
  label,
  disabled = false,
}) => {
  const [month, setMonth] = useState('')
  const [day, setDay] = useState('')
  const [year, setYear] = useState('')

  // Initialize component state from value prop
  useEffect(() => {
    if (value) {
      const [yearVal, monthVal, dayVal] = value.split('-')
      setYear(yearVal || '')
      setMonth(monthVal || '')
      setDay(dayVal || '')
    }
  }, [value])

  /**
   * Handles month selection change and updates the value
   * @param newMonth - The selected month value
   */
  const handleMonthChange = (newMonth: string) => {
    setMonth(newMonth)
    // Reset day if current selection is invalid for new month
    if (day) {
      const daysInMonth = dayjs(`${year}-${newMonth}-01`).daysInMonth()
      if (parseInt(day, 10) > daysInMonth) {
        setDay('')
      }
    }
    updateValue(year, newMonth, day)
  }

  /**
   * Handles day selection change and updates the value
   * @param newDay - The selected day value
   */
  const handleDayChange = (newDay: string) => {
    setDay(newDay)
    updateValue(year, month, newDay)
  }

  /**
   * Handles year selection change and updates the value
   * @param newYear - The selected year value
   */
  const handleYearChange = (newYear: string) => {
    setYear(newYear)
    updateValue(newYear, month, day)
  }

  /**
   * Updates the complete date value when any component changes
   * @param yearVal - The year value
   * @param monthVal - The month value
   * @param dayVal - The day value
   */
  const updateValue = (yearVal: string, monthVal: string, dayVal: string) => {
    if (yearVal && monthVal && dayVal) {
      setValue(`${yearVal}-${monthVal}-${dayVal}`)
    } else {
      setValue('')
    }
  }

  // Generate year options from 1900 to current year
  const currentYear = new Date().getFullYear()
  const yearOptions = Array.from({ length: currentYear - 1899 }, (_, i) => ({
    label: (currentYear - i).toString(),
    value: (currentYear - i).toString(),
  }))

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;
        width: 100%;
      `}
    >
      <style>
        {`
          .ant-select-dropdown {
            z-index: 999999 !important;
          }
          .ant-select-selector {
            background: ${colors.SECONDARY_200} !important;
            border-radius: 8px !important;
          }
          .ant-select-selection-item {
            font-size: 14px !important;
            font-weight: 400 !important;
            color: ${colors.WHITE} !important;
          }
          .ant-select-dropdown .ant-select-item-option-content {
            font-size: 14px !important;
            font-weight: 400 !important;
            color: ${colors.WHITE} !important;
          }
        `}
      </style>
      <Text
        variant='paragraphSmall'
        weight='regular'
        color={colors.WHITE}
        style={`
          align-self: stretch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
      >
        {label}
      </Text>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          flex: 1;
          height: 40px;
          width: 100%;
          flex-direction: row;
          @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-self: stretch;
            height: auto;
          }
        `}
      >
        <Select
          options={[
            { label: 'January', value: '01' },
            { label: 'February', value: '02' },
            { label: 'March', value: '03' },
            { label: 'April', value: '04' },
            { label: 'May', value: '05' },
            { label: 'June', value: '06' },
            { label: 'July', value: '07' },
            { label: 'August', value: '08' },
            { label: 'September', value: '09' },
            { label: 'October', value: '10' },
            { label: 'November', value: '11' },
            { label: 'December', value: '12' },
          ]}
          value={month}
          onChange={handleMonthChange}
          disabled={disabled}
          status={
            fieldErrors[name] || fieldErrors?.includes(name) ? 'error' : ''
          }
          css={css`
            flex: 1;
            width: 100%;
            min-width: 120px;
            @media (max-width: 600px) {
              width: 100%;
              margin-bottom: 8px;
            }
            ${paragraphMedium}
            ${weightRegular}
            font-size: 16px !important;
            color: ${colors.WHITE};
            width: 100%;
            background: ${colors.SECONDARY_200} !important;
            border-radius: 8px !important;
            height: 40px !important;
            ${disabled && 'opacity: 0.5 !important'}
          `}
        />

        <Select
          options={Array.from(
            {
              length: dayjs(`${year}-${month}-01`).daysInMonth() || 31,
            },
            (_, i) => ({
              label: (i + 1).toString(),
              value: (i + 1).toString().padStart(2, '0'),
            })
          )}
          value={day}
          onChange={handleDayChange}
          disabled={disabled}
          status={
            fieldErrors[name] || fieldErrors?.includes(name) ? 'error' : ''
          }
          css={css`
            flex: 1;
            width: 100%;
            @media (max-width: 600px) {
              width: 100%;
              margin-bottom: 8px;
            }
            ${paragraphMedium}
            ${weightRegular}
            font-size: 16px !important;
            color: ${colors.WHITE};
            width: 100%;
            background: ${colors.SECONDARY_200} !important;
            border-radius: 8px !important;
            height: 40px !important;
            ${disabled && 'opacity: 0.5 !important'}
          `}
        />

        <Select
          placeholder='YYYY'
          options={yearOptions}
          value={year}
          onChange={handleYearChange}
          disabled={disabled}
          showSearch
          filterOption={(input, option) => {
            if (!option || !input) {
              return false
            }
            return option.label.toString().includes(input)
          }}
          status={
            fieldErrors[name] || fieldErrors?.includes(name) ? 'error' : ''
          }
          css={css`
            flex: 1;
            font-size: 14px !important;
            height: 40px !important;
            border-radius: 8px;
            border: 1px solid ${colors.SECONDARY_100};
            background: ${colors.SECONDARY_200};
            width: 100%;
            @media (max-width: 600px) {
              width: 100%;
            }
            ${disabled &&
            css`
              color: ${colors.NEUTRAL_100};
              opacity: 0.5;
            `}
          `}
        />
      </div>

      <ErrorLabel
        fieldErrors={fieldErrors}
        customError={customError}
        name={name}
      />
    </div>
  )
}
