/** @jsxImportSource @emotion/react */
import {
  UserOutlined,
  SafetyCertificateOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useCallback, useContext, useEffect } from 'react'
import { EventCard } from 'src/components/RegistrationEventCard'
import Button from 'src/components/ts-components/Button'
import Text from 'src/components/ts-components/Text'
import { RegistrationContext } from '../context/RegistrationContext'
import { PageTitle, REGISTRATION_STEPS } from './Main'
import RoleSelectionCard from '../RoleSelectionCard'
import RegistrationEntityCard from '../RegistrationEntityCard'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { formatShortDate } from '@sportninja/common/ts-utils/date-utils'
import { App } from 'antd'
import { formatCurrency, getErrorMessage } from '@sportninja/common/utils/utils'

export const roles = {
  PLAYER: 'player',
  TEAM_REP: 'team_rep',
}

// Styled components
const Container = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  background-color: ${colors.NEUTRAL_800};
  width: 1080px;
  padding: 0 35px 35px 35px;
  border-radius: 16px 16px 0 0;

  @media (max-width: 768px) {
    min-width: 0;
    width: 100%;
  }
`

const EmailContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-top: 16px;
  margin-bottom: -8px;
`

const RoleCardsContainer = css`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  align-self: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const EntityGrid = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  margin-top: 16px;
  padding: 0 0;
  /* justify-content: flex-start; */
  justify-content: center;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 0 12px;
  }
`

const ButtonContainer = css`
  background-color: ${colors.NEUTRAL_800};
  z-index: 100;
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`

const ButtonGroup = css`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`

const ButtonStyle = css`
  width: 200px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const SelectYourRole = () => {
  const {
    userId,
    division,
    registration,
    email,
    role,
    setRole,
    setStep,
    updateSessionRole,
    selectedPlayer,
    setSelectedPlayer,
    playerType,
    teamOfficialType,
    players,
    teamOfficials,
    currency,
    resetSession,
  } = useContext(RegistrationContext)
  const { message } = App.useApp()
  const totalSpots = division?.max_teams
  const teamRegistered = division?.team_count_enrolled
  const spotsLeft = totalSpots - teamRegistered
  const shouldDisableTeamRegistration =
    spotsLeft <= 0 && division?.max_teams !== null

  const noPlayers = players?.length === 0
  const noTeamOfficials = teamOfficials?.length === 0
  const playerRegistrationEnabled =
    registration?.registration?.is_player_registration
  const teamRegistrationEnabled =
    registration?.registration?.is_team_registration

  // Handlers
  const handleCreateNewPlayer = useCallback(() => {
    setSelectedPlayer({
      id: 'new',
      name_first: 'Create a New Player',
      name_last: '',
      player_number: '',
      image: null,
      birth_date: null,
    })
  }, [setSelectedPlayer])

  const handleCreateNewTeamOfficial = useCallback(() => {
    setSelectedPlayer({
      id: 'new',
      name_first: 'Create a New Team Official',
      name_last: '',
      birth_date: null,
    })
  }, [setSelectedPlayer])

  // Effects
  useEffect(() => {
    if (noPlayers) {
      handleCreateNewPlayer()
    }
  }, [noPlayers, handleCreateNewPlayer])

  useEffect(() => {
    if (noTeamOfficials) {
      handleCreateNewTeamOfficial()
    }
  }, [noTeamOfficials, handleCreateNewTeamOfficial])

  const handleBack = useCallback(() => {
    setStep(REGISTRATION_STEPS.SELECT_YOUR_DIVISION)
  }, [])

  const handleNext = useCallback(async () => {
    try {
      await updateSessionRole(
        role,
        selectedPlayer?.id === 'new' ? null : selectedPlayer?.id,
        userId
      )
      setStep(REGISTRATION_STEPS.TEAM_REGISTER)
    } catch (error) {
      console.error('Failed to update session role:', error)
      message.open({
        type: 'error',
        content: getErrorMessage(error),
      })
    }
  }, [role, selectedPlayer, updateSessionRole, message, userId])

  useEffect(() => {
    if (shouldDisableTeamRegistration && role?.id === teamOfficialType?.id) {
      setRole(undefined)
      setSelectedPlayer(null)
    }
  }, [
    shouldDisableTeamRegistration,
    role,
    teamOfficialType?.id,
    setRole,
    setSelectedPlayer,
  ])

  // Render helpers
  const renderEmailSection = useCallback(() => {
    if (!email) {
      return null
    }

    return (
      <div css={EmailContainer}>
        <a
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: default;
          `}
        >
          <UserOutlined
            css={css`
              color: ${colors.PRIMARY};
            `}
          />
          <Text
            variant='paragraphSmall'
            color={colors.PRIMARY}
            weight='regular'
          >
            {email}
          </Text>
        </a>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
          `}
          onClick={() => {
            resetSession()
            setTimeout(() => {
              window.location.reload()
            }, 500)
          }}
        >
          <LogoutOutlined
            css={css`
              color: ${colors.ERROR_200};
            `}
          />
          <Text
            variant='paragraphSmall'
            color={colors.ERROR_200}
            weight='regular'
          >
            Start Over
          </Text>
        </div>
      </div>
    )
  }, [email])

  const renderPlayers = useCallback(() => {
    if (role?.id !== playerType?.id || !players) {
      return null
    }

    const allPlayers = [
      ...players,
      {
        id: 'new',
        name_first: 'Create a New Player',
        name_last: '',
        player_number: '',
        image: null,
        birth_date: null,
      },
    ]

    return (
      <div>
        <PageTitle
          title='Select One of Your Players'
          subtitle='Choose the player that you want to register or create a new one.'
          showAlert={false}
          showSuccess={false}
        />
        <div css={EntityGrid}>
          {allPlayers.map((player) => (
            <RegistrationEntityCard
              key={player.id}
              name={`${player.name_first} ${player.name_last}`}
              dateOfBirth={
                player.birth_date
                  ? formatShortDate(player.birth_date?.toString())
                  : ''
              }
              image={getImageThumbnailId(player)}
              onClick={() => setSelectedPlayer(player)}
              selected={selectedPlayer?.id === player.id}
              playerId={player.id}
            />
          ))}
        </div>
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role?.id, playerType?.id, players, selectedPlayer?.id])

  const renderTeamOfficials = useCallback(() => {
    if (role?.id !== teamOfficialType?.id || !teamOfficials) {
      return null
    }

    const allPlayers = [
      ...teamOfficials,
      {
        id: 'new',
        name_first: 'Create a New Team Official',
        name_last: '',
        player_number: '',
        image: null,
        birth_date: null,
      },
    ]

    return (
      <div>
        <PageTitle
          title='Select One of Your Team Officials'
          subtitle='Choose the team official that you want to register or create a new one.'
          showAlert={false}
          showSuccess={false}
        />
        <div css={EntityGrid}>
          {allPlayers.map((player) => (
            <RegistrationEntityCard
              key={player.id}
              name={`${player.name_first} ${player.name_last}`}
              dateOfBirth={
                player.birth_date
                  ? formatShortDate(player.birth_date?.toString())
                  : ''
              }
              image={getImageThumbnailId(player)}
              onClick={() => setSelectedPlayer(player)}
              selected={selectedPlayer?.id === player.id}
              playerId={player.id}
            />
          ))}
        </div>
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role?.id, playerType?.id, players, selectedPlayer?.id])

  return (
    <div css={Container}>
      <style>
        {`
            .card-container {
              display: grid;
              justify-content: center;
              grid-template-columns: repeat(
                auto-fill,
                minmax(300px, 1fr)
              ); /* Creates as many columns as possible with a minimum of 300px */
              gap: 16px; /* Spacing between cards */
            }
            
            /* Media query for desktops */
            @media (min-width: 768px) {
              .card-container {
                grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
              }
            }          
          `}
      </style>
      {renderEmailSection()}
      <EventCard eventInfo={registration} />
      <PageTitle
        title={`Register for ${division?.name || ''}`}
        subtitle={
          'Choose the option that best fits your role in this competition.'
        }
        showAlert={false}
        showSuccess={false}
      />
      <div
        css={[
          RoleCardsContainer,
          (!playerRegistrationEnabled || !teamRegistrationEnabled) &&
            css`
              width: 50%;
              align-self: center;
              @media (max-width: 768px) {
                width: 100%;
                flex-direction: column;
              }
            `,
        ]}
      >
        {playerRegistrationEnabled ? (
          <RoleSelectionCard
            title='Player Registration'
            subtitle='Join or be Assigned to a Team'
            icon={
              <UserOutlined
                css={css`
                  font-size: 40px;
                  color: ${colors.PRIMARY};
                `}
              />
            }
            onClick={() => {
              setRole(playerType)
            }}
            selected={role !== undefined && role?.id === playerType?.id}
            deposit={
              division?.player_deposit === 0 ||
              division?.player_deposit === null
                ? 'Free'
                : formatCurrency(division?.player_deposit, currency?.name)
            }
            cost={
              division?.player_cost === 0 || division?.player_cost === null
                ? 'N/A'
                : formatCurrency(division?.player_cost, currency?.name)
            }
          />
        ) : null}

        {teamRegistrationEnabled ? (
          <RoleSelectionCard
            title='Team Registration'
            subtitle='Register as a Team Rep'
            icon={
              <SafetyCertificateOutlined
                css={css`
                  font-size: 40px;
                  color: ${colors.PRIMARY};
                `}
              />
            }
            onClick={() => {
              setRole(teamOfficialType)
            }}
            selected={role !== undefined && role?.id === teamOfficialType?.id}
            deposit={
              division?.deposit === 0 || division?.deposit === null
                ? 'Free'
                : formatCurrency(division?.deposit, currency?.name)
            }
            cost={
              division?.team_cost === 0 || division?.team_cost === null
                ? 'N/A'
                : formatCurrency(division?.team_cost, currency?.name)
            }
            disabled={shouldDisableTeamRegistration}
          />
        ) : null}
      </div>
      {role?.id === playerType?.id && players && players?.length > 0
        ? renderPlayers()
        : null}
      {role?.id === teamOfficialType?.id &&
      teamOfficials &&
      teamOfficials?.length > 0
        ? renderTeamOfficials()
        : null}
      <div css={ButtonContainer}>
        <div css={ButtonGroup}>
          <Button
            onClick={handleBack}
            text='BACK'
            variant='secondary'
            style={ButtonStyle}
          />
          <Button
            disabled={!role || !selectedPlayer}
            onClick={handleNext}
            text='NEXT'
            variant='primary'
            style={ButtonStyle}
          />
        </div>
      </div>
    </div>
  )
}

export default SelectYourRole
