/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { Alert, Checkbox, Select, Space } from 'antd'
import { useEffect, useState } from 'react'

const LinkPlayerToParent = ({ ...props }) => {
  const { name, onChange, numberOfSubmissions, values } = props
  const [linkPlayerToParent, setLinkPlayerToParent] = useState(
    values?.link_to_existing_player?.linkPlayerToParent || false
  )
  const [selectedRelationship, setSelectedRelationship] =
    useState('Unspecified')

  useEffect(() => {
    onChange({
      target: {
        name,
        value: {
          linkPlayerToParent,
          selectedRelationship,
        },
      },
    })
  }, [linkPlayerToParent])

  useEffect(() => {
    onChange({
      target: {
        name,
        value: {
          linkPlayerToParent,
          selectedRelationship,
        },
      },
    })
  }, [selectedRelationship])

  return (
    <div>
      <Space direction='vertical' style={{ width: '100%', gap: 16 }}>
        <Alert
          message={`Attention: This email belongs to an existing ${
            isCanlan ? 'Canlan' : 'SportNinja'
          } user. Add this player under the ${
            isCanlan ? 'Canlan' : 'SportNinja'
          } User?`}
          type='warning'
          showIcon
        />
        <Checkbox
          onChange={() => setLinkPlayerToParent(!linkPlayerToParent)}
          checked={linkPlayerToParent}
          label='Choose User Relationship to the Player'
        >
          Choose User Relationship to the Player
        </Checkbox>
        {linkPlayerToParent ? (
          <div
            css={css`
              .ant-select {
                background-color: ${colors.HEADER};
                width: 50%;
                min-width: 200px;
              }
            `}
          >
            <Select
              showSearch={false}
              onChange={(e) => {
                setSelectedRelationship(e)
              }}
              options={[
                { label: 'Unspecified', value: 'Unspecified' },
                { label: 'Father', value: 'Father' },
                { label: 'Mother', value: 'Mother' },
                { label: 'Grandfather', value: 'Grandfather' },
                { label: 'Grandmother', value: 'Grandmother' },
                { label: 'Brother', value: 'Brother' },
                { label: 'Sister', value: 'Sister' },
              ]}
              value={selectedRelationship}
            />
          </div>
        ) : null}
        {numberOfSubmissions &&
        numberOfSubmissions > 1 &&
        !linkPlayerToParent ? (
          <Alert
            message='Make sure you check the box and choose a relationship to be able to create an underaged player using the selected email address'
            type='error'
            showIcon
          />
        ) : null}
        <p
          css={css`
            font-size: 12px;
            line-height: 14px;
            color: ${colors.WHITE};
          `}
        >
          You could also press cancel, change the email or use ‘Search Player’
          to add an existing player.
        </p>
        <div
          css={css`
            border-bottom: 1px solid ${colors.SOFT_STEEL};
          `}
        />
      </Space>
    </div>
  )
}

export default LinkPlayerToParent
