/** @jsxImportSource @emotion/react */
import { SearchOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Input, Switch } from 'antd'
import { RegistrationTableDivision } from 'src/pages/Schedule/PlayerRegistration/helpers'
import Text from '../../Text'
import React, { useCallback, useEffect, useState } from 'react'
import { SelectInput } from '../../SelectInput'
import { RegistrationStatusFilter } from '../../RegistrationStatusFilter'

interface SearchControlsProps {
  searchText: string
  setSearchText: (text: string) => void
  selectedDivision: string
  setSelectedDivision: (division: string) => void
  divisions: RegistrationTableDivision[]
  allExpanded: boolean
  toggleAllDivisions: () => void
  onlyBuckets: boolean
  setOnlyBuckets: (show: boolean) => void
  selectedStatuses: number[]
  setSelectedStatuses: (statuses: number[]) => void
}

const searchControlsStyles = {
  container: css`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    width: 100%;
  `,
  leftControls: css`
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  `,
  rightControls: css`
    margin-left: auto;
  `,
  searchInput: css`
    width: 300px;
    color: ${colors.WHITE};
    font-size: 14px;
    font-weight: 400;
    line-height: 20.3px;
    border-radius: 8px;
    border: 1px solid ${colors.SECONDARY_100};
    background: ${colors.SECONDARY_200};
    height: 36px !important;
    @media (max-width: 768px) {
      width: 100%;
    }
    .ant-input {
      background-color: ${colors.SECONDARY_500};
      color: ${colors.WHITE};
    }
    .ant-input-prefix {
      color: ${colors.NEUTRAL_100};
    }
    &::placeholder {
      color: ${colors.NEUTRAL_100};
    }
  `,
  expandAllButton: css`
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid ${colors.SECONDARY_200};
    background: ${colors.SECONDARY_300};
    height: 36px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      border-color: ${colors.PRIMARY};
    }
  `,
  switchContainer: css`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid ${colors.SECONDARY_200};
    background: ${colors.SECONDARY_300};
    height: 36px !important;
    cursor: pointer;
  `,
}

export const SearchControls: React.FC<SearchControlsProps> = ({
  searchText,
  setSearchText,
  selectedDivision,
  setSelectedDivision,
  divisions,
  allExpanded,
  toggleAllDivisions,
  onlyBuckets,
  setOnlyBuckets,
  selectedStatuses,
  setSelectedStatuses,
}) => {
  const [localSearchText, setLocalSearchText] = useState(searchText)

  const debouncedSearch = useCallback(
    (value: string) => {
      const timeoutId = setTimeout(() => {
        setSearchText(value)
      }, 300)

      return () => clearTimeout(timeoutId)
    },
    [setSearchText]
  )

  useEffect(() => {
    const cleanup = debouncedSearch(localSearchText)
    return cleanup
  }, [localSearchText, debouncedSearch])

  return (
    <div css={searchControlsStyles.container}>
      <div css={searchControlsStyles.leftControls}>
        <Input
          placeholder='Search by Division, Team, or Player'
          prefix={
            <SearchOutlined
              css={css`
                font-size: 16px;
                color: ${colors.NEUTRAL_50};
              `}
            />
          }
          value={localSearchText}
          onChange={(e) => setLocalSearchText(e.target.value)}
          allowClear={true}
          css={searchControlsStyles.searchInput}
        />
        <div>
          <SelectInput
            label=''
            value={selectedDivision}
            onChange={setSelectedDivision}
            name='division'
            disabled={false}
            fieldErrors={[]}
            customError=''
            showSearch={true}
            style='width: 200px'
            filterOption={(input, option) =>
              (option?.label?.toString() || '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={[
              { value: 'all', label: 'All Divisions' },
              ...divisions.map((d) => ({
                value: d.id,
                label: d.name,
              })),
            ]}
          />
        </div>
        <div
          css={searchControlsStyles.switchContainer}
          onClick={() => setOnlyBuckets(!onlyBuckets)}
        >
          <Text variant='paragraphSmall' color={colors.WHITE} weight='regular'>
            Only Unassigned Groups
          </Text>
          <Switch checked={onlyBuckets} size='small' />
        </div>
        <RegistrationStatusFilter
          selectedStatuses={selectedStatuses}
          onChange={setSelectedStatuses}
        />
      </div>
      <div css={searchControlsStyles.rightControls}>
        <div
          css={searchControlsStyles.expandAllButton}
          onClick={toggleAllDivisions}
        >
          <Text variant='paragraphSmall' color={colors.WHITE} weight='regular'>
            {allExpanded ? 'Collapse All' : 'Expand All'}
          </Text>
        </div>
      </div>
    </div>
  )
}
