/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import deepEqual from 'fast-deep-equal'
import { useEffect, useRef, useState, useCallback } from 'react'
import { font } from '../../../components/css'
import Icon from '../../../components/Icon'

import { Flex } from '../../../components/Layout'
import { BAR_HEIGHT, POOL_TYPE } from './constants'
import GameInfoPopout from './GameInfoPopout'
import Slot from './Slot'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { ROUTES } from '@sportninja/common/constants/app'
import { Link } from 'react-router-dom'

const Pool = ({
  pool,
  roundNumber,
  roundType,
  selected,
  setSelected,
  stats,
  isLowerPool = false,
  shouldDisplayScoreInsteadOfPoints = false,
  hasLowerPool = false,
  isSingleOrDoubleElimination = false,
}) => {
  const popoutRef = useRef(null)
  const [isPopoutOpen, setIsPopoutOpen] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)

  useEffect(() => {
    const handler = (e) => {
      try {
        const element = popoutRef.current
        if (element instanceof HTMLElement) {
          if (
            element &&
            e?.target !== element &&
            !element?.contains(e?.target)
          ) {
            setIsPopoutOpen(false)
          }
        } else {
          setIsPopoutOpen(false)
        }
      } catch (e) {
        console.error(e)
        setIsPopoutOpen(false)
      }
    }

    if (isPopoutOpen) {
      setTimeout(() => {
        document.addEventListener('click', handler)
      }, 0)
    } else {
      document.removeEventListener('click', handler)
    }

    return () => {
      document.removeEventListener('click', handler)
    }
  }, [isPopoutOpen])

  const {
    id: poolId,
    name_full: poolName,
    children,
    gameSlots,
    pool_type_id,
    ref,
    teamSlots: { data: teams },
  } = pool

  const games = gameSlots?.data || []
  const advance = children?.data?.length

  const gameMap = new Map()
  const gameEndMap = new Map()

  games?.forEach((game) => {
    if (game?.homeTeamSlot?.id) {
      gameMap.set(game.homeTeamSlot.id, game.home_team_score)
      if (game.ended_at === null) {
        gameEndMap.set(game.homeTeamSlot.id, true)
      }
    }
    if (game?.visitingTeamSlot?.id) {
      gameMap.set(game.visitingTeamSlot.id, game.visiting_team_score)
      if (game.ended_at === null) {
        gameEndMap.set(game.visitingTeamSlot.id, true)
      }
    }
  })
  teams?.forEach((team) => {
    if (team?.id) {
      if (gameMap.has(team.id)) {
        team.score = gameMap.get(team.id)
      }
      if (gameEndMap.has(team.id)) {
        team.gameDidntEnd = true
      }
      if (team.team_id === null) {
        team.isMockTeam = true
      }
    }
  })
  const isMockPool = teams.some((team) => team.isMockTeam)

  // If this is single or double elimination, we always have one game per pool.
  // We should get the first game number from the games array and append that to the
  // pool name.
  let alternatePoolName = pool.name_full
  if (isSingleOrDoubleElimination) {
    const firstGame = games?.[0]
    if (firstGame && firstGame?.game_number) {
      alternatePoolName =
        poolName !== '-'
          ? `Game ${firstGame?.game_number} - ${poolName}`
          : `Game ${firstGame?.game_number}`
    }
  }

  const poolStats = stats[poolId]

  // Add the stats to the teams
  teams.forEach((team) => {
    team.stats = poolStats?.[team.team_id]
  })

  // Sort teams by number of points, descending
  const sorted =
    poolStats &&
    Object.keys(poolStats).sort((a, b) => {
      const aTeam = poolStats[a],
        bTeam = poolStats[b]
      const aPoints = aTeam.points,
        bPoints = bTeam.points
      if (aPoints > bPoints) {
        return -1
      } else if (aPoints < bPoints) {
        return 1
      } else {
        return 0
      }
    })

  // We've sorted the pool by points, so take the first index as our de facto
  // top scorer.
  // Second, verify there are no ties by checking for other entries that have the
  // same points.
  // Finally, if we have no ties, set this variable to the ID of the top scorer.
  let potentialTopScore = poolStats?.[sorted?.[0]]
  if (potentialTopScore) {
    const ties = sorted
      .slice(1)
      .find((teamId) => poolStats[teamId].points === potentialTopScore.points)
    if (ties) {
      potentialTopScore = false
    } else {
      potentialTopScore = sorted?.[0]
    }
  }

  // If this is single or  double elimination, we just consider the score to know the top scorer
  if (isSingleOrDoubleElimination && games && games?.length > 0) {
    const game = games?.[0]
    const homeGameTopScore = game?.home_team_score
    const visitingGameTopScore = game?.visiting_team_score
    if (homeGameTopScore === visitingGameTopScore) {
      potentialTopScore = false
    } else if (homeGameTopScore > visitingGameTopScore) {
      potentialTopScore = game?.homeTeamSlot?.team_id
    } else {
      potentialTopScore = game?.visitingTeamSlot?.team_id
    }
  }

  const getRouterLink = () => {
    if (isSingleOrDoubleElimination) {
      // setSelected({ type: 'pool', roundNumber, id: poolId })
      // Redirect to the game page when clicking on a single or double elimination pool
      const firstGame = games?.[0]
      if (firstGame?.game_id) {
        // Use the same navigation pattern as in the mobile version
        // For web, we navigate to the game page using the game_id
        return `${ROUTES.GAME_ROOT}/${firstGame.game_id}`
      }
      return null
    }
    return null
  }

  const handleMouseEnter = useCallback(() => {
    if (isSingleOrDoubleElimination) {
      return
    }
    setIsPopoutOpen(true)
  }, [isSingleOrDoubleElimination])

  const handleMouseEnterSingleOrDoubleElimination = useCallback(() => {
    if (selected?.id !== poolId) {
      setSelected({ type: 'pool', roundNumber, id: poolId })
    }
  }, [poolId, roundNumber, selected?.id, setSelected])

  const handleMouseLeaveSingleOrDoubleElimination = useCallback(() => {
    if (selected?.id === poolId && selected?.type === 'pool') {
      setSelected({})
    }
  }, [poolId, selected?.id, selected?.type, setSelected])

  const handleMouseLeave = useCallback(() => {
    setIsPopoutOpen(false)
  }, [])

  return (
    <Flex
      noFlex
      ref={ref}
      css={css`
        position: relative;
      `}
    >
      <GameInfoPopout
        ref={isSingleOrDoubleElimination ? null : popoutRef}
        slots={games}
        isOpen={isPopoutOpen}
        setIsMouseOver={setIsMouseOver}
        isSingleOrDoubleElimination={isSingleOrDoubleElimination}
      >
        <Flex
          onClick={!isSingleOrDoubleElimination ? handleMouseEnter : null}
          onMouseEnter={
            isSingleOrDoubleElimination
              ? handleMouseEnterSingleOrDoubleElimination
              : null
          }
          onMouseLeave={
            isSingleOrDoubleElimination
              ? handleMouseLeaveSingleOrDoubleElimination
              : null
          }
          className='pool'
          column
          css={css`
            border: 1px solid ${colors.SECONDARY_100};
            border-radius: 8px;
            margin-bottom: 44px;
          `}
        >
          <Flex
            className='pool-bar'
            alignItems='center'
            justifyContent='space-between'
            css={css`
              padding: 0 8px;
              font-size: 13px;
              background-color: ${selected?.roundNumber === roundNumber &&
              deepEqual(selected?.id, poolId)
                ? 'rgba(255, 255, 255, 0.1)'
                : isLowerPool
                ? `${colors.NEUTRAL_300};`
                : `${colors.SECONDARY_500};`};
              cursor: pointer;
              min-height: ${BAR_HEIGHT}px;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              // add a hover effect if its single or double elimination
              &:hover {
                background-color: ${colors.SECONDARY_200};
              }

              // if this is isSingleOrDoubleElimination, we should make this clickable
              ${isSingleOrDoubleElimination
                ? 'cursor: pointer;'
                : ''}// and redirect to the first game as a modal
              // also add a hover effect if its single or double elimination
            `}
            onClick={() => {
              if (selected?.id !== poolId) {
                setSelected({ type: 'pool', roundNumber, id: poolId })
              }
            }}
          >
            <Flex
              noFlex
              column
              justifyContent='center'
              css={css`
                gap: 2px;
              `}
            >
              {/* highlight the link once ho */}
              <Link
                to={getRouterLink()}
                css={css`
                  ${getRouterLink() !== null
                    ? `
                  &:hover {
                    text-decoration: underline;
                  }`
                    : ''}
                `}
              >
                {poolName && shouldDisplayScoreInsteadOfPoints && (
                  <div
                    css={css`
                      font-weight: ${isCanlan ? '700' : '500'};
                      font-size: ${isCanlan ? '16px' : '14px'};
                      font-family: ${isCanlan
                        ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                        : 'inherit'};
                    `}
                  >
                    {isSingleOrDoubleElimination
                      ? alternatePoolName
                      : poolName
                      ? poolName
                      : '-'}
                  </div>
                )}
                {poolName && !shouldDisplayScoreInsteadOfPoints && (
                  <div
                    css={css`
                      font-weight: ${isCanlan ? '700' : '500'};
                      font-size: ${isCanlan ? '16px' : '14px'};
                      font-family: ${isCanlan
                        ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                        : 'inherit'};
                    `}
                  >
                    {roundType === 1 ? poolName : '-'}
                  </div>
                )}
              </Link>
              {isLowerPool
                ? shouldDisplayScoreInsteadOfPoints && (
                    <div
                      css={css`
                        font-weight: 500;
                        font-size: 10px;
                        margin-top: 4px;
                        color: ${colors.NEUTRAL_50};
                      `}
                    >
                      Lower Bracket
                    </div>
                  )
                : shouldDisplayScoreInsteadOfPoints &&
                  hasLowerPool && (
                    <div
                      css={css`
                        font-weight: 500;
                        font-size: 10px;
                        margin-top: 4px;
                        color: ${colors.NEUTRAL_50};
                      `}
                    >
                      Upper Bracket
                    </div>
                  )}
              {games.length > 0 && [1, 2].includes(pool_type_id) && (
                <div
                  css={css`
                    margin-top: 4px;
                    font-weight: 500;
                    font-size: 12px;
                  `}
                >
                  {/* If series */}
                  {pool_type_id === 1 && `${games.length}-Game `}
                  {[1, 2].includes(pool_type_id) &&
                    POOL_TYPE[pool_type_id]}{' '}
                </div>
              )}
            </Flex>

            <Flex
              noFlex
              column
              justifyContent='center'
              alignItems='flex-end'
              css={css`
                ${isSingleOrDoubleElimination ? 'display: none;' : ''}
              `}
            >
              <div
                className='games-button'
                css={css`
                  ${font.body}
                  font-weight: 500;
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                  transform: translateY(-1px);

                  &:hover {
                    color: white;

                    i {
                      color: white;
                    }
                  }

                  &,
                  i {
                    color: ${colors.DEFAULT_FLAIR};
                  }

                  i {
                    margin-left: 6px;
                  }
                `}
                // onClick={(e) => {
                //   setIsPopoutOpen((o) => !o)
                // }}
              >
                Games <Icon name='caret-right' />
              </div>

              <div
                css={css`
                  margin-top: 1px;
                  font-size: 12px;
                  font-weight: 500;
                `}
              >
                {/* Not finals */}
                {roundType !== 4
                  ? `${advance} Advance${advance > 1 ? 's' : ''}`
                  : '1 Winner'}
              </div>
            </Flex>
          </Flex>
          <div
            css={css`
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
              overflow: hidden;
            `}
          >
            {teams
              .sort((a, b) => {
                if (shouldDisplayScoreInsteadOfPoints) {
                  const aPoints = a.score
                  const bPoints = b.score
                  if (aPoints > bPoints) {
                    return -1
                  } else if (aPoints < bPoints) {
                    return 1
                  }
                } else {
                  const aPoints = a.stats?.points
                  const bPoints = b.stats?.points
                  if (aPoints > bPoints) {
                    return -1
                  } else if (aPoints < bPoints) {
                    return 1
                  }
                }
              })
              .map((team) => {
                const teamStats = poolStats?.[team.team_id]

                return (
                  <Slot
                    isMockPool={isMockPool}
                    shouldDisplayScoreInsteadOfPoints={
                      shouldDisplayScoreInsteadOfPoints
                    }
                    key={team.id}
                    team={team}
                    isTopScore={potentialTopScore === team.team_id}
                    selected={selected}
                    teamStats={teamStats}
                    isSingleOrDoubleElimination={isSingleOrDoubleElimination}
                    onClick={() => {
                      const idToUse = team.team_id || team.id
                      if (selected?.id === idToUse) {
                        setSelected({})
                      } else {
                        setSelected({ type: 'slot', id: idToUse })
                      }
                    }}
                    onClickTeamName={() => {
                      // If isSingleOrDoubleElimination, we should redirect to the team page
                      if (isSingleOrDoubleElimination) {
                        return `${ROUTES.TEAM_ROOT}/${team.team_id}`
                      }
                    }}
                  />
                )
              })}
          </div>
        </Flex>
      </GameInfoPopout>
    </Flex>
  )
}

export default Pool
