import styled from '@emotion/styled/macro'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'

import {
  backgroundColor,
  flairColor,
  flairColorLight,
  borderColor,
  zIndex,
} from '../css'
import { media } from '../Responsive'
import { Flex } from '../Layout'

export const StyledDayPicker = styled(DayPicker)`
  width: 100%;

  .mobile & {
    position: initial;
    top: initial;
    z-index: initial;
    margin-top: 4px;
  }

  .DayPicker-wrapper {
    outline: none;
    padding-bottom: 0;
    width: 100%;

    .mobile & {
      background-color: transparent;
      border-color: ${borderColor};
    }
  }

  .DayPicker-NavBar {
    position: absolute;
    right: -2px;
    top: -2px;

    ${media.mobile} {
      top: 5px;
    }
  }

  .DayPicker-NavButton {
    outline: none;

    &:hover {
      opacity: 1;
      filter: brightness(200%);
    }
  }

  .DayPicker-NavButton--prev {
    margin-right: 2em;
  }

  .DayPicker-Month {
    margin-top: 0;
    margin: 11px 3px 3px;
    width: 100%;
  }

  .DayPicker-Day {
    outline: none;

    ${media.mobile} {
      height: 40px;
      width: 40px;
    }

    &:hover {
      border-radius: 4px;
      color: ${backgroundColor};
    }

    &.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
      border-radius: 4px;
      background-color: ${flairColor};
      color: ${backgroundColor};

      &:hover {
        background-color: ${flairColorLight};

        ${media.mobile} {
          background-color: ${flairColor};
        }
      }
    }

    &.DayPicker-Day--outside {
      cursor: pointer;
      &:hover {
        color: #f0f8ff;
      }
    }

    &:not(.DayPicker--interactionDisabled):not(.DayPicker-Day--disabled):not(
        .DayPicker-Day--selected
      ):not(.DayPicker-Day--outside):hover {
      background-color: ${flairColorLight};
    }
  }

  &.range {
    .DayPicker-Day--selected {
      &.DayPicker-Day--start {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.DayPicker-Day--end {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
        background-color: ${flairColorLight};
        border-radius: 0 !important;
      }
    }
  }
`

export const PickerContents = styled.div`
  position: absolute;
  ${(props) =>
    props.position === 'top'
      ? 'bottom: calc(100% + 1px) !important;'
      : 'top: calc(100% + 1px) !important;'}
  z-index: ${zIndex.overlay};
  overflow: hidden;

  width: 100%;
  box-shadow: ${(props) =>
    props.position === 'top'
      ? '0 -2px 8px rgba(0, 0, 0, 0.6)'
      : '0 2px 8px rgba(0, 0, 0, 0.6)'};

  background-color: ${backgroundColor};
  border: 1px solid white;
  border-radius: 2px;
`

export const TimeContainer = styled(Flex)`
  width: 100%;
  padding: 0px 8px 8px;
  display: flex;
  align-items: center;
  gap: 2px;
`
