/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useCallback, useEffect, useState } from 'react'
import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'

import LoadingSpinner from '../LoadingSpinner'
import { font } from '../css'
import RosterSelect, { useRosterSelect } from './RosterSelect'
import { Flex } from '../Layout'
import EditEntitySubTitle from '../../pages/EditEntitySubTitle'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { Link } from 'react-router-dom'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import colors from '@sportninja/common/constants/appColors'
import dayjs from 'dayjs'
import { LockOutlined } from '@ant-design/icons'
import { useUrlManager } from 'src/hooks/useUrlManager'
import { LinkOutlined } from '@ant-design/icons'
const TeamRosterBasedList = ({
  teamId,
  teamName,
  permission,
  refreshKey,
  onComplete,
  slug = 'players',
  children,
  existingRosterIdFromLocationState,
  lockRosterDate = null,
}) => {
  const { setParam } = useUrlManager()
  const {
    loading: loadingRosters,
    rosters,
    selectedRosterId,
    setSelectedRosterId,
    loadRosters,
  } = useRosterSelect(teamId, existingRosterIdFromLocationState)

  const [searchRosterId, setSearchRosterId] = useState(
    existingRosterIdFromLocationState
  )
  const [loadingList, setLoadingList] = useState(true)
  const [error, setError] = useState(false)
  const [roster, setRoster] = useState({})

  const getList = useCallback(
    async (rosterId, sort, direction) => {
      setLoadingList(true)
      try {
        const response = await req(
          `/teams/${teamId}/rosters/${rosterId}/${slug}`,
          {
            query: { sort, direction },
          }
        )
        const roster = rosters.find((r) => r.id === selectedRosterId)
        const { lock_roster_date } = roster || {}
        setRoster(response.data)
        onComplete({
          ...response.data,
          lock_roster_date,
        })
        setLoadingList(false)
      } catch (e) {
        setError(e.message)
      } finally {
        setLoadingList(false)
      }
    },
    [teamId, rosters, selectedRosterId]
  )

  useEffect(() => {
    if (selectedRosterId?.length > 0) {
      getList(selectedRosterId)
    }
  }, [selectedRosterId, refreshKey])

  useEffect(() => {
    if (!loadingRosters && rosters.length === 0) {
      setLoadingList(false)
    }
  }, [loadingRosters, rosters])

  if (loadingRosters) {
    return (
      <div
        css={css`
          margin-top: 24px;
        `}
      >
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div
      css={css`
        margin-top: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Flex
          alignItems='center'
          css={css`
            gap: 20px;
          `}
        >
          {!error && (
            <RosterSelect
              selectedRosterId={selectedRosterId}
              setSelectedRosterId={setSelectedRosterId}
              rosters={rosters}
              loadRosters={loadRosters}
              teamId={teamId}
              teamName={teamName}
              permission={permission}
              setParam={setParam}
            />
          )}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 40px;
              gap: 8px;
              margin-bottom: 8px;
            `}
          >
            <Link
              to={`/schedule/${
                rosters.find((r) => r.id === selectedRosterId)?.schedule_uid ||
                rosters[0]?.schedule_uid
              }`}
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
              `}
            >
              <p
                css={css`
                  font-size: 24px;
                  font-weight: 700;
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                  line-height: 32px;
                `}
              >
                {rosters.find((r) => r.id === selectedRosterId)
                  ?.schedule_name || rosters[0]?.schedule_name}
              </p>
              <LinkOutlined
                css={css`
                  font-size: 24px;
                  color: ${colors.PRIMARY};
                `}
              />
            </Link>
            <p
              css={css`
                color: ${colors.NEUTRAL_50};
              `}
            >
              {rosters.find((r) => r.id === selectedRosterId)?.division_name ||
                rosters[0]?.division_name}
            </p>
          </div>
        </Flex>
        {selectedRosterId ? (
          <div
            css={css`
              margin-left: 24px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              gap: 16px;
            `}
          >
            {lockRosterDate && (permission?.update || permission?.admin) && (
              <div
                css={css`
                  display: flex;
                  padding: 8px;
                  align-items: center;
                  gap: 8px;
                  border-radius: 8px;
                  background: ${colors.SECONDARY_300};
                  align-self: center;
                `}
              >
                <LockOutlined
                  css={css`
                    font-size: 24px;
                    color: ${colors.NEUTRAL_50};
                  `}
                />
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                  `}
                >
                  <p
                    css={css`
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 16px;
                      color: ${colors.NEUTRAL_50};
                    `}
                  >
                    Roster Lock Date
                  </p>
                  <p
                    css={css`
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: 700;
                      color: ${colors.WHITE};
                    `}
                  >
                    {dayjs(lockRosterDate).format('DD MMM, YYYY')}
                  </p>
                </div>
              </div>
            )}
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                align-self: center;
                margin-bottom: 16px;
              `}
            >
              <EditEntitySubTitle
                id={teamId}
                entityType={ENTITY_TYPES.team}
                fixedWidth
              />
              <EditEntitySubTitle
                id={selectedRosterId}
                entityType={ENTITY_TYPES.roster}
                fixedWidth
              />
            </div>
          </div>
        ) : null}
      </div>
      {error && (
        <div
          css={css`
            margin: 0 40px;
            word-break: break-all;
          `}
        >
          <p
            css={css`
              ${font.title}
              font-weight: 700;
              font-size: 20px;
              text-align: center;
              margin-bottom: 16px;
            `}
          >
            Error loading {t('common:competition')} roster list
          </p>
          {error}
        </div>
      )}
      {error ? (
        false
      ) : loadingList ? (
        <LoadingSpinner
          css={css`
            margin-top: 24px;
          `}
        />
      ) : typeof children === 'function' ? (
        children(roster, async () => await getList(selectedRosterId))
      ) : (
        false
      )}
    </div>
  )
}

export default TeamRosterBasedList
