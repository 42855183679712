/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { memo, useEffect, useState } from 'react'
import { App, Spin } from 'antd'
import { LoadingOutlined, DeleteOutlined } from '@ant-design/icons'
import useSocialFeed from '@sportninja/common/hooks/useSocialFeed'
import FeedItem from '../FeedItem'
import colors from '@sportninja/common/constants/appColors'
import { paragraphMedium, weightRegular } from '../css'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import Text from '../ts-components/Text'
import DeleteModal from '../Modal/DeleteModal'
import { User } from '@sportninja/common/types'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'

const DeleteButton = ({ ...props }) => {
  return (
    <button
      id='delete-post-button'
      css={css`
        display: flex;
        padding: 8px 16px 8px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid ${colors.ERROR_200};
        box-shadow: 0px 2px 0px 0px rgba(5, 145, 255, 0.1);
        font-family: ${isCanlan
          ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif !important;'
          : 'BarlowCondensed, Arial, Helvetica, sans-serif !important;'};
        display: none;
      `}
      {...props}
    >
      <DeleteOutlined
        css={css`
          color: ${colors.ERROR_200};
        `}
        width={24}
        height={24}
      />
      <Text variant='paragraphMedium' weight='semiBold' color={colors.WHITE}>
        DELETE POST
      </Text>
    </button>
  )
}

interface FeedPageProps {
  entityType?: 'team' | 'schedule' | 'organization'
  entityId?: string
  canEdit?: boolean
  canDelete?: boolean
  avatarUrl?: string
  refreshNewFeed: any
  setPermission: any
  user: User
  isEntityFeed?: boolean
  setPostToEdit: (post: any) => void
  setModalOpen: (open: boolean) => void
  setIsLoadingFeed: (loading: boolean) => void
}

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: colors.PRIMARY }} spin />
)

const FeedPage: React.FC<FeedPageProps> = ({
  entityType,
  entityId,
  canEdit = false,
  canDelete = false,
  avatarUrl,
  refreshNewFeed,
  setPermission,
  user,
  setPostToEdit,
  setModalOpen,
  setIsLoadingFeed,
}) => {
  const { message } = App.useApp()
  const [postId, setPostId] = useState<string | null>(null)

  const {
    posts,
    loading,
    error,
    hasMore,
    likePost,
    dislikePost,
    deletePost,
    createReply,
    loadMore,
    fetchPosts,
    permission,
  } = useSocialFeed({ id: entityId, type: entityType })

  useEffect(() => {
    setIsLoadingFeed(loading)
  }, [loading])

  useEffect(() => {
    setPermission(permission)
  }, [permission])

  useEffect(() => {
    refreshNewFeed.current = fetchPosts
  }, [])

  useEffect(() => {
    if (error) {
      message.error(error)
    }
  }, [error, message])

  const handleLikePost = async (postId: string) => {
    try {
      await likePost(postId)
    } catch (err) {
      console.error('Failed to like post:', err)
      message.error('Failed to like post. Please try again.')
    }
  }

  const handleDislikePost = async (postId: string) => {
    try {
      await dislikePost(postId)
    } catch (err) {
      console.error('Failed to dislike post:', err)
      message.error('Failed to dislike post. Please try again.')
    }
  }

  const handleCreateReply = async (postId: string, replyContent: string) => {
    if (replyContent.trim()) {
      try {
        await createReply(postId, { body: replyContent })
        message.success('Reply posted successfully')
      } catch (err) {
        console.error('Failed to create reply:', err)
        message.error('Failed to post reply. Please try again.')
      }
    }
  }

  return (
    <>
      <DeleteModal
        title='Delete Post'
        message={
          'Are you sure you want to delete this post? This cannot be undone.'
        }
        onDelete={async (shouldForce = false) => {
          try {
            const query: any = {}
            if (shouldForce) {
              query.force = 1
            }
            await deletePost(postId || '')
            message.success('Post deleted successfully.')
            fetchPosts(1)
            setPostId(null)
          } catch (err: any) {
            console.error('Failed to delete post:', err)
            message.error(err?.message || 'Failed to delete post')
            throw err
          }
        }}
        Button={DeleteButton}
        forceDeleteText={undefined}
        shouldCloseModalAfterDelete={true}
        useForceDelete={true}
        displayBanner={false}
      />
      <div
        id='feed-page'
        css={css`
          width: 100%;
          margin: 0 auto;
        `}
      >
        <Spin spinning={loading} indicator={antIcon}>
          <div id='feed-page-content'>
            {posts.length === 0 && !loading ? (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 16px;
                  flex-shrink: 0;
                `}
              >
                <img
                  src={'/images/empty_feed.png'}
                  alt='Empty Feed'
                  css={css`
                    width: 180px;
                    height: 160px;
                  `}
                />
                <Text
                  variant='heading5'
                  weight='semiBold'
                  color={colors.NEUTRAL_0}
                >
                  Nothing to see here yet
                </Text>
                <Text
                  variant='paragraphSmall'
                  weight='regular'
                  color={colors.NEUTRAL_50}
                >
                  Be the first to post and get things started!
                </Text>
              </div>
            ) : null}
            {posts.map((post) => (
              <FeedItem
                userProfilePicture={getImageThumbnailId(post?.user)}
                userName={post?.user?.name_full}
                userId={post?.user_id}
                key={post?.id}
                entityId={post?.parent_id || ''}
                entityType={
                  post?.parent_type === 'organization'
                    ? 'organization'
                    : post?.parent_type === 'team'
                    ? 'team'
                    : 'schedule'
                }
                entityName={post?.parent?.name}
                title={post?.title}
                body={post?.body}
                imageUrl={getImageThumbnailId(post)}
                linkUrl={post?.url}
                likesCount={post?.like_count || 0}
                commentsCount={post?.replies?.length || 0}
                createdAt={post.created_at}
                avatarUrl={getImageThumbnailId(post?.parent)}
                onCommentClick={() => {
                  /* Open comment input or modal */
                }}
                onLikeClick={() =>
                  post.is_liked
                    ? handleDislikePost(post.id)
                    : handleLikePost(post.id)
                }
                onDeleteClick={() => {
                  setPostId(post.id)
                  document.getElementById('delete-post-button')?.click()
                }}
                onEditClick={() => {
                  setPostToEdit(post)
                  setModalOpen(true)
                }}
                onReplySubmit={(replyContent) =>
                  handleCreateReply(post.id, replyContent)
                }
                canEdit={
                  canEdit ||
                  (post?.user_id === user?.id && post.parent !== null) ||
                  permission?.[post?.parent_id]?.update
                }
                canDelete={
                  canDelete ||
                  (post?.user_id === user?.id && post.parent !== null) ||
                  permission?.[post?.parent_id]?.update
                }
                isAppPost={post.parent === null}
                userAvatarUrl={avatarUrl}
                isLiked={post.is_liked}
              />
            ))}
            {hasMore && !loading && (
              <div
                css={css`
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <button
                  onClick={loadMore}
                  css={css`
                    background-color: ${colors.PRIMARY};
                    color: ${colors.WHITE};
                    padding: 10px 20px;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    margin-top: 20px;
                    align-self: center;
                    ${paragraphMedium}
                    ${weightRegular}
                  `}
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        </Spin>
      </div>
    </>
  )
}

export default memo(FeedPage)
