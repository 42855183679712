/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'

import { SearchContext } from '../../pages/Search/SearchContextProvider'
import { font, zIndex } from '../css'
import Icon from '../Icon'
import { Flex } from '../Layout'
import Picture from '../Picture'

const breadcrumbCss = css`
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: ${colors.DEFAULT_FLAIR};

  &:hover {
    filter: brightness(1.3);
  }

  &.is-last {
    color: ${colors.PALE_SKY};
  }
`

const PageHeader = ({
  children,
  baseCrumb,
  baseTitle,
  title,
  breadcrumbs = [],
  loading,
  iconName,
  imageId,
  isTeamOrSchedule,
  isInFrame,
  titleActions,
  hideBreadcrumbs = false,
}) => {
  const searchContext = useContext(SearchContext)

  return (
    <div
      css={css`
        ${isTeamOrSchedule &&
        css`
          display: flex;
          ${!isInFrame && 'position: fixed;'}
          top: 10px;
          z-index: ${zIndex.header};
          transition: opacity 0.2s ease-in-out;
          opacity: 1;
          pointer-events: all;
          ${searchContext.isActive &&
          `
          pointer-events: none;
            opacity: 0;
          `}
        `}

        margin-bottom: ${isTeamOrSchedule ? 0 : 15}px;
      `}
    >
      {isTeamOrSchedule && (
        <Picture
          square
          fit
          iconName={iconName}
          imageId={imageId}
          size='ksmall'
          css={css`
            margin-right: 16px;
          `}
        />
      )}
      <div>
        <Flex
          row
          alignItems='center'
          css={css`
            margin-bottom: ${isTeamOrSchedule ? 0 : 9}px;
          `}
        >
          {!isTeamOrSchedule && !isInFrame && !hideBreadcrumbs && (
            <>
              <Link
                to={ROUTES.HOME_ROOT}
                css={css`
                  display: flex;
                  align-items: center;
                  transform: translateY(-1px);

                  &:hover {
                    i {
                      color: white;
                    }
                  }
                `}
                title='Home'
              >
                <Icon
                  name='home-alt'
                  color={colors.PALE_SKY}
                  fontSize={11}
                  css={css`
                    margin-right: 7px;
                  `}
                />
              </Link>
              <Icon
                name='angle-right'
                color={colors.WHITE}
                fontSize={11}
                css={css`
                  margin-right: 7px;
                `}
              />
            </>
          )}

          {isTeamOrSchedule ? (
            <Link
              className={breadcrumbs.length === 0 ? 'is-last' : ''}
              to={baseCrumb}
              css={breadcrumbCss}
            >
              <Icon
                name='arrow-left'
                fontSize={11}
                color={colors.DEFAULT_FLAIR}
                css={css`
                  margin-right: 7px;
                  margin-bottom: ${isTeamOrSchedule ? 3 : 0}px;
                `}
              />
              <span
                className='breadcrumb-go-back-to'
                css={css`
                  color: ${colors.DEFAULT_FLAIR};
                `}
              >
                {baseTitle}
              </span>
            </Link>
          ) : isInFrame ? (
            false
          ) : !hideBreadcrumbs ? (
            <Link
              className={breadcrumbs.length === 0 ? 'is-last' : ''}
              to={baseCrumb}
              css={breadcrumbCss}
            >
              {baseTitle}
            </Link>
          ) : null}

          {!isTeamOrSchedule &&
            breadcrumbs.map((breadcrumb, idx) => {
              return (
                <Fragment key={`${breadcrumb.id}-${idx}`}>
                  {(!isInFrame || idx !== 0) && (
                    <Icon
                      name='angle-right'
                      color={colors.WHITE}
                      fontSize={11}
                      css={css`
                        margin: 0 7px;
                      `}
                    />
                  )}
                  {idx === breadcrumbs.length - 1 ? (
                    <p
                      key={breadcrumb.id}
                      className='is-last'
                      css={breadcrumbCss}
                    >
                      {breadcrumb.text}
                    </p>
                  ) : (
                    <Link
                      key={breadcrumb.id}
                      to={breadcrumb.to}
                      css={breadcrumbCss}
                    >
                      {breadcrumb.text}
                    </Link>
                  )}
                </Fragment>
              )
            })}
        </Flex>
        <Flex
          row
          alignItems='center'
          justifyContent='space-between'
          id='sandbox-title'
        >
          <Flex noFlex alignItems='center'>
            {!isTeamOrSchedule && imageId ? (
              <Picture
                square
                fit
                iconName={iconName}
                imageId={imageId}
                size='ksmall'
                css={css`
                  margin-right: 16px;
                `}
              />
            ) : null}
            <h1
              css={css`
                ${font.title}
                font-weight: 700;
                font-size: ${isTeamOrSchedule ? 32 : 44}px;
                line-height: ${isTeamOrSchedule ? 32 : isCanlan ? 36 : 44}px;

                letter-spacing: 0.04em;
                text-transform: uppercase;
                min-height: ${isTeamOrSchedule ? 32 : 44}px;

                pointer-events: none;

                @keyframes fade {
                  0% {
                    opacity: 1;
                  }
                  100% {
                    opacity: 0;
                  }
                }
                ${loading &&
                css`
                  ${isTeamOrSchedule && 'min-height: 24px; margin-top: 7px;'}
                  background-color: rgba(255, 255, 255, 0.1);
                  width: 200px;
                  border-radius: 8px;
                  animation-name: fade;
                  animation-duration: 1s;
                  animation-timing-function: ease-in-out;
                  animation-iteration-count: infinite;
                  animation-direction: alternate;
                `}
              `}
            >
              {title}
            </h1>
            {!isInFrame && titleActions && (
              <div
                css={css`
                  display: flex;

                  .header-action {
                    margin-left: 10px;
                  }
                `}
              >
                {titleActions}
              </div>
            )}
          </Flex>
          <div
            css={css`
              display: flex;

              .header-action {
                margin-left: 16px;
              }
            `}
          >
            {children}
          </div>
        </Flex>
      </div>
    </div>
  )
}

export default PageHeader
