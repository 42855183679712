/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import React from 'react'
import {
  captionLarge,
  captionSmall,
  captionXSmall,
  displayLarge,
  displaySmall,
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  paragraphMedium,
  paragraphSmall,
  paragraphXSmall,
  weightBold,
  weightMedium,
  weightRegular,
  weightSemiBold,
} from 'src/components/css'

type TextProps = {
  children?: React.ReactNode
  variant?:
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'heading4'
    | 'heading5'
    | 'heading6'
    | 'paragraphMedium'
    | 'paragraphSmall'
    | 'paragraphXSmall'
    | 'captionLarge'
    | 'captionSmall'
    | 'captionXSmall'
    | 'displayLarge'
    | 'displaySmall'
  weight?: 'regular' | 'medium' | 'bold' | 'semiBold'
  color?: string
  style?: string
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none'
  disableTitleCase?: boolean
}

const Text = ({
  variant,
  weight,
  color,
  style,
  // textTransform,
  // disableTitleCase,
  children,
}: TextProps) => {
  return (
    <p
      css={css`
        ${style && style}
        ${variant === 'heading1' && heading1}
        ${variant === 'heading2' && heading2}
        ${variant === 'heading3' && heading3}
        ${variant === 'heading4' && heading4}
        ${variant === 'heading5' && heading5}
        ${variant === 'heading6' && heading6}
        ${variant === 'paragraphMedium' && paragraphMedium}
        ${variant === 'paragraphSmall' && paragraphSmall}
        ${variant === 'paragraphXSmall' && paragraphXSmall}
        ${variant === 'captionLarge' && captionLarge}
        ${variant === 'captionSmall' && captionSmall}
        ${variant === 'captionXSmall' && captionXSmall}
        ${variant === 'displayLarge' && displayLarge}
        ${variant === 'displaySmall' && displaySmall}
        ${weight === 'regular' && weightRegular}
        ${weight === 'medium' && weightMedium}
        ${weight === 'bold' && weightBold}
        ${weight === 'semiBold' && weightSemiBold}
        ${color && `color: ${color};`}
        ${variant === 'heading1' ||
        variant === 'heading2' ||
        variant === 'heading3' ||
        variant === 'heading4' ||
        variant === 'heading5' ||
        variant === 'heading6'
          ? `font-family: ${
              isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif !important;'
                : 'BarlowCondensed, Arial, Helvetica, sans-serif !important;'
            };
        `
          : ''}
      `}
    >
      {children}
    </p>
  )
}

export default Text
