/** @jsxImportSource @emotion/react */
import { DeleteOutlined } from '@ant-design/icons'
import { Alert, Input, Select, Upload, Button as AntButton } from 'antd'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Text from 'src/components/ts-components/Text'
import dayjs from 'dayjs'
import Button from 'src/components/ts-components/Button'
import { useState, useEffect } from 'react'
import { Country } from '@sportninja/common/hooks/useCountries'
import { Team } from '@sportninja/common/hooks/useRegistrationDetails'
import TextArea from 'antd/es/input/TextArea'
import CopyComponent from 'src/components/CopyComponent'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { UploadOutlined } from '@ant-design/icons'

const AntButtonStyle = css`
  color: ${colors.WHITE};
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 16px;
  font-weight: 600;
  line-height: 100%; /* 16px */
  text-transform: uppercase;
`

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

interface TeamOfficialFormProps {
  formData: {
    email: string
    firstName: string
    lastName: string
    birthDate: string
    zipCode: string
    country: string
    additionalTeamOfficials: any[]
    fieldErrors: any[]
    customError: Record<string, any>
  }
  updateFormData: (field: string, value: string | boolean) => void
  countries: Country[]
  isRegistrationDetails?: boolean
  teamInfo: Team
  registrationId: string
}

const TeamOfficialForm = ({
  formData,
  updateFormData,
  countries,
  isRegistrationDetails = false,
  teamInfo,
  registrationId,
}: TeamOfficialFormProps) => {
  const [isAddingTeamOfficial, setIsAddingTeamOfficial] = useState(false)
  const [newTeamOfficial, setNewTeamOfficial] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthDateDay: '',
    birthDateMonth: '',
    birthDateYear: '',
    country: '',
    zipCode: '',
  })

  useEffect(() => {
    if (!isRegistrationDetails && registrationId) {
      // Create a URL object from the current location
      const currentUrl = new URL(window.location.href)
      // Set or update the team_registration_id parameter
      currentUrl.searchParams.set('team_registration_id', registrationId)
      // Update the browser URL with the new search params
      window.history.pushState({}, '', currentUrl.toString())

      return () => {
        // On cleanup, remove the team_registration_id parameter
        const cleanupUrl = new URL(window.location.href)
        cleanupUrl.searchParams.delete('team_registration_id')
        window.history.pushState({}, '', cleanupUrl.toString())
      }
    }
  }, [registrationId, isRegistrationDetails])

  const handleAddTeamOfficial = () => {
    setIsAddingTeamOfficial(true)
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        gap: 16px;
        padding: 0 16px;
        margin-top: 16px;
        /* if isRegistrationDetails, adding 16px margin top */
        ${isRegistrationDetails &&
        css`
          padding: 0 0;
        `}
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          align-self: stretch;
          gap: 16px;
          width: 100%;
        `}
      >
        <Text
          variant='captionLarge'
          color={colors.WHITE}
          weight='semiBold'
          style={`
            align-self: center;
          `}
        >
          Team Information
        </Text>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 8px;
          `}
        >
          <CopyComponent
            customText='Team ID'
            id={teamInfo.uid}
            alternativeBackground={isRegistrationDetails}
          />
          <CopyComponent
            customText='Registration ID'
            id={registrationId}
            alternativeBackground={isRegistrationDetails}
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        `}
      >
        <img
          width={56}
          height={56}
          css={css`
            border-radius: 8px;
            border: 2px solid ${colors.SECONDARY_300};
            background: ${colors.NEUTRAL_0};
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
            width: 56px;
            height: 56px;
          `}
          src={'/images/generic_placeholders/placeholder_team.png'}
        />
        <Upload
          beforeUpload={() => false}
          onChange={() => {}}
          showUploadList={false}
          action={undefined}
        >
          <AntButton
            icon={<UploadOutlined />}
            css={css`
              ${AntButtonStyle}
              border-radius: 8px;
              background: ${colors.PRIMARY};
              box-shadow: 0px 2px 0px 0px rgba(5, 145, 255, 0.1);
              color: ${colors.WHITE};
            `}
          >
            Upload
          </AntButton>
        </Upload>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          align-self: stretch;
          gap: 16px;
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            align-self: stretch;
            gap: 16px;
          `}
        >
          <Input
            placeholder='Name'
            value={teamInfo?.name || ''}
            onChange={(e) => updateFormData('name', e.target.value)}
          />
          <Input
            placeholder='Phone'
            value={teamInfo?.phone_number || ''}
            onChange={(e) => updateFormData('phone', e.target.value)}
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            align-self: stretch;
            gap: 16px;
          `}
        >
          <Input
            placeholder='Email'
            value={teamInfo?.email || ''}
            onChange={(e) => updateFormData('email', e.target.value)}
          />
          <Input
            placeholder='Website'
            value={teamInfo?.website || ''}
            onChange={(e) => updateFormData('website', e.target.value)}
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            align-self: stretch;
            gap: 16px;
          `}
        >
          <TextArea
            placeholder='Notes'
            value={teamInfo?.notes || ''}
            onChange={(e) => updateFormData('notes', e.target.value)}
            rows={4}
          />
        </div>
      </div>
      {/* Team Rep */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          align-self: stretch;
          gap: 16px;
        `}
      >
        <Text
          variant='captionLarge'
          color={colors.WHITE}
          weight='semiBold'
          style={`
            align-self: stretch;
          `}
        >
          Team Official Info
        </Text>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            /* on mobile, change direction */
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-self: stretch;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              First Name *
            </Text>
            <Input
              name='firstName'
              placeholder='John'
              type='text'
              autoComplete='given-name'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={formData.firstName}
              onChange={(e) => updateFormData('firstName', e.target.value)}
              disabled={true}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              Last Name *
            </Text>
            <Input
              name='lastName'
              placeholder='Doe'
              type='text'
              autoComplete='family-name'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={formData.lastName}
              onChange={(e) => updateFormData('lastName', e.target.value)}
              disabled={true}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-self: stretch;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              Email *
            </Text>
            <Input
              name='email'
              placeholder='john.doe@me.com'
              type='email'
              autoComplete='email'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={formData.email}
              onChange={(e) => updateFormData('email', e.target.value)}
              disabled={true}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              Birthdate *
            </Text>
            <Input
              name='birthdate'
              placeholder='01/01/2000'
              type='text'
              autoComplete='birthdate'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={
                formData.birthDate
                  ? `${String(
                      new Date(formData.birthDate).getUTCDate()
                    ).padStart(2, '0')}-${String(
                      new Date(formData.birthDate).getUTCMonth() + 1
                    ).padStart(2, '0')}-${new Date(
                      formData.birthDate
                    ).getUTCFullYear()}`
                  : '-'
              }
              onChange={(e) => updateFormData('birthDate', e.target.value)}
              disabled={true}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-self: stretch;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              Country *
            </Text>
            <Select
              css={css`
                width: 100%;
                height: 36px;
              `}
              status={
                formData.fieldErrors?.includes('country_code')
                  ? 'error'
                  : undefined
              }
              showSearch
              filterOption={filterOption}
              placeholder='Select a country'
              onChange={(e) => {
                updateFormData('country', e)
              }}
              value={formData.country}
              options={
                countries
                  ? countries.map((el) => {
                      return {
                        label: el?.name,
                        value: el?.iso_3166_2,
                      }
                    })
                  : []
              }
            />
            <div
              tabIndex={-1}
              css={css`
                transition: opacity 0.1s ease-in-out;
                opacity: ${formData.fieldErrors?.includes('country_code')
                  ? 1
                  : 0};
                font-size: 13px;
                line-height: 16px;
                color: ${colors.ERROR_LIGHT};
                align-self: stretch;
                margin-top: 8px;
              `}
            >
              <span>{formData.customError.country_code}</span>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              Zip/Postal Code *
            </Text>
            <Input
              status={
                formData.fieldErrors?.includes('postal_code')
                  ? 'error'
                  : undefined
              }
              name='zipCode'
              placeholder=''
              type='text'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={formData.zipCode}
              onChange={(e) => updateFormData('zipCode', e.target.value)}
            />
            <div
              tabIndex={-1}
              css={css`
                transition: opacity 0.1s ease-in-out;
                opacity: ${formData.fieldErrors?.includes('postal_code')
                  ? 1
                  : 0};
                font-size: 13px;
                line-height: 16px;
                color: ${colors.ERROR_LIGHT};
                align-self: stretch;
                margin-top: 8px;
              `}
            >
              <span>{formData.customError.postal_code}</span>
            </div>
          </div>
        </div>
        {formData.fieldErrors?.includes(
          'team_official_array.0.team_official_type_id'
        ) ? (
          <div
            tabIndex={-1}
            css={css`
              transition: opacity 0.1s ease-in-out;
              opacity: ${formData.fieldErrors?.includes(
                'team_official_array.0.team_official_type_id'
              )
                ? 1
                : 0};
              font-size: 13px;
              line-height: 16px;
              color: ${colors.ERROR_LIGHT};
              align-self: stretch;
            `}
          >
            <span>
              {
                formData.customError[
                  'team_official_array.0.team_official_type_id'
                ]
              }
            </span>
          </div>
        ) : null}
      </div>
      {/* Additional Team Officials */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          margin-top: 16px;
          margin-bottom: 16px;
          display: none;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            justify-content: space-between;
          `}
        >
          <Text
            variant='paragraphMedium'
            color={colors.WHITE}
            weight='semiBold'
            style={`
                align-self: stretch;
            `}
          >
            Additional Team Officials
          </Text>
          {/* <Button
            onClick={() => {
              updateFormData('isAddingTeamOfficial', !isAddingTeamOfficial)
            }}
            text={!isAddingTeamOfficial ? 'Add Team Official' : 'Cancel'}
            variant={!isAddingTeamOfficial ? 'primary' : 'secondary'}
            style={{
              maxWidth: '100px',
            }}
          /> */}
        </div>
        {!isAddingTeamOfficial &&
        formData.additionalTeamOfficials?.length === 0 ? (
          <Text variant='paragraphSmall'>No Additional Team Officials</Text>
        ) : null}
        {!isAddingTeamOfficial &&
        formData.additionalTeamOfficials?.length > 0 ? (
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            {formData.additionalTeamOfficials.map((official, index) => (
              <div
                key={index}
                css={css`
                  display: flex;
                  padding: 8px 16px;
                  justify-content: space-between;
                  align-items: center;
                  flex: 1 0 0;
                  border-radius: 4px;
                  background: ${colors.VERY_DARK_GRAYISH_BLUE};
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                  `}
                >
                  <Text
                    variant='paragraphSmall'
                    color={colors.WHITE}
                    weight='regular'
                  >
                    {official.firstName} {official.lastName}
                  </Text>
                  <Text
                    variant='paragraphSmall'
                    color={colors.WHITE}
                    weight='regular'
                  >
                    Team Official
                  </Text>
                </div>
                <DeleteOutlined
                  onClick={() => {
                    updateFormData(
                      'additionalTeamOfficials',
                      formData.additionalTeamOfficials.filter(
                        (_el, i) => i !== index
                      ) as any
                    )
                  }}
                  css={css`
                    color: ${colors.ERROR_LIGHT};
                    cursor: pointer;
                  `}
                  size={24}
                />
              </div>
            ))}
          </div>
        ) : null}
        {isAddingTeamOfficial ? (
          <>
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                  @media (max-width: 768px) {
                    flex-direction: column;
                    width: 100%;
                    align-self: stretch;
                  }
                `}
              >
                <Text
                  variant='paragraphSmall'
                  color={colors.WHITE}
                  weight='regular'
                >
                  First Name *
                </Text>
                <Input
                  name='newTeamOfficialFirstName'
                  placeholder='John'
                  type='text'
                  autoComplete='given-name'
                  autoCorrect='off'
                  autoCapitalize='none'
                  required
                  value={newTeamOfficial.firstName}
                  onChange={(e) =>
                    setNewTeamOfficial({
                      ...newTeamOfficial,
                      firstName: e.target.value,
                    })
                  }
                  status={
                    formData.fieldErrors?.includes(
                      'additionalTeamOfficialsFirstName'
                    )
                      ? 'error'
                      : undefined
                  }
                />
                <div
                  tabIndex={-1}
                  css={css`
                    margin-top: 8px;
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${formData.fieldErrors?.includes(
                      'additionalTeamOfficialsFirstName'
                    )
                      ? 1
                      : 0};
                    font-size: 13px;
                    line-height: 16px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>
                    {formData.customError.additionalTeamOfficialsFirstName}
                  </span>
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                  @media (max-width: 768px) {
                    flex-direction: column;
                    width: 100%;
                    align-self: stretch;
                  }
                `}
              >
                <Text
                  variant='paragraphSmall'
                  color={colors.WHITE}
                  weight='regular'
                >
                  Last Name *
                </Text>
                <Input
                  name='newTeamOfficialLastName'
                  placeholder='Doe'
                  type='text'
                  autoComplete='family-name'
                  autoCorrect='off'
                  autoCapitalize='none'
                  required
                  value={newTeamOfficial.lastName}
                  onChange={(e) =>
                    setNewTeamOfficial({
                      ...newTeamOfficial,
                      lastName: e.target.value,
                    })
                  }
                  status={
                    formData.fieldErrors?.includes(
                      'additionalTeamOfficialsLastName'
                    )
                      ? 'error'
                      : undefined
                  }
                />
                <div
                  tabIndex={-1}
                  css={css`
                    margin-top: 8px;
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${formData.fieldErrors?.includes(
                      'additionalTeamOfficialsLastName'
                    )
                      ? 1
                      : 0};
                    font-size: 13px;
                    line-height: 16px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>
                    {formData.customError.additionalTeamOfficialsLastName}
                  </span>
                </div>
              </div>
            </div>
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
                /* on mobile, change the direction */
                @media (max-width: 768px) {
                  flex-direction: column;
                  align-items: stretch;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                `}
              >
                <Text
                  variant='paragraphSmall'
                  color={colors.WHITE}
                  weight='regular'
                >
                  Email *
                </Text>
                <Input
                  name='newTeamOfficialEmail'
                  placeholder='john.doe@me.com'
                  type='email'
                  autoComplete='email'
                  autoCorrect='off'
                  autoCapitalize='none'
                  required
                  value={newTeamOfficial.email}
                  onChange={(e) =>
                    setNewTeamOfficial({
                      ...newTeamOfficial,
                      email: e.target.value,
                    })
                  }
                  status={
                    formData.fieldErrors?.includes(
                      'additionalTeamOfficialsEmail'
                    )
                      ? 'error'
                      : undefined
                  }
                />
                <div
                  tabIndex={-1}
                  css={css`
                    margin-top: 8px;
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${formData.fieldErrors?.includes(
                      'additionalTeamOfficialsEmail'
                    )
                      ? 1
                      : 0};
                    font-size: 13px;
                    line-height: 16px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>
                    {formData.customError.additionalTeamOfficialsEmail}
                  </span>
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                `}
              >
                <Text
                  variant='paragraphSmall'
                  color={colors.WHITE}
                  weight='regular'
                >
                  Birthdate *
                </Text>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    flex: 1;
                    height: 40px;
                    width: 100%;
                    flex-direction: row;
                    @media (max-width: 600px) {
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      align-self: stretch;
                    }
                  `}
                >
                  <Input
                    name='dateOfBirthDay'
                    placeholder='DD'
                    type='number'
                    autoComplete='bday-day'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={newTeamOfficial.birthDateDay}
                    onChange={(e) =>
                      setNewTeamOfficial({
                        ...newTeamOfficial,
                        birthDateDay: e.target.value,
                      })
                    }
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement
                      input.value = Math.max(0, parseInt(input.value, 10))
                        .toString()
                        .slice(0, 2)
                    }}
                    status={
                      formData.fieldErrors?.includes(
                        'additionalTeamOfficialsBirthDate'
                      )
                        ? 'error'
                        : undefined
                    }
                    maxLength={2}
                    min={1}
                    max={31}
                  />
                  <Input
                    name='dateOfBirthMonth'
                    placeholder='MM'
                    type='number'
                    autoComplete='bday-month'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={newTeamOfficial.birthDateMonth}
                    onChange={(e) =>
                      setNewTeamOfficial({
                        ...newTeamOfficial,
                        birthDateMonth: e.target.value,
                      })
                    }
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement
                      input.value = Math.max(0, parseInt(input.value, 10))
                        .toString()
                        .slice(0, 2)
                    }}
                    status={
                      formData.fieldErrors?.includes(
                        'additionalTeamOfficialsBirthDate'
                      )
                        ? 'error'
                        : undefined
                    }
                    maxLength={2}
                    min={1}
                    max={12}
                  />

                  <Input
                    name='dateOfBirthYear'
                    placeholder='YYYY'
                    type='number'
                    autoComplete='bday-year'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={newTeamOfficial.birthDateYear}
                    onChange={(e) =>
                      setNewTeamOfficial({
                        ...newTeamOfficial,
                        birthDateYear: e.target.value,
                      })
                    }
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement
                      input.value = Math.max(0, parseInt(input.value, 10))
                        .toString()
                        .slice(0, 4)
                    }}
                    status={
                      formData.fieldErrors?.includes(
                        'additionalTeamOfficialsBirthDate'
                      )
                        ? 'error'
                        : undefined
                    }
                    maxLength={4}
                    min={1900}
                    max={dayjs().year()}
                  />
                </div>
                <div
                  css={css`
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${formData.fieldErrors?.includes(
                      'additionalTeamOfficialsBirthDate'
                    )
                      ? 1
                      : 0};
                    font-size: 13px;
                    line-height: 16px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>
                    {formData.customError.additionalTeamOfficialsBirthDate}
                  </span>
                </div>
              </div>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 8px;
                width: 25%;
                align-self: flex-end;
                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              <Button
                text='Add Team Official'
                variant='primary'
                onClick={() => {
                  handleAddTeamOfficial()
                }}
              />
            </div>
            <div
              css={css`
                width: 100%;
                height: 1px;
                background: ${colors.VERY_DARK_GRAYISH_BLUE};
              `}
            />
          </>
        ) : null}
        {formData.fieldErrors?.includes('additionalTeamOfficialsFirstName') ? (
          <div
            css={css`
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              max-width: 500px;
              align-self: center;
              flex: 1;
            `}
          >
            <Alert
              message={formData.customError.additionalTeamOfficialsFirstName}
              type='error'
              showIcon
            />
          </div>
        ) : null}
        {formData.fieldErrors?.includes('additionalTeamOfficialsLastName') ? (
          <div
            css={css`
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              max-width: 500px;
              align-self: center;
              flex: 1;
            `}
          >
            <Alert
              message={formData.customError.additionalTeamOfficialsLastName}
              type='error'
              showIcon
            />
          </div>
        ) : null}
        {formData.fieldErrors?.includes('additionalTeamOfficialsEmail') ? (
          <div
            css={css`
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              align-self: center;
              flex: 1;
            `}
          >
            <Alert
              message={formData.customError.additionalTeamOfficialsEmail}
              type='error'
              showIcon
            />
          </div>
        ) : null}
        {formData.fieldErrors?.includes('additionalTeamOfficialsBirthDate') ? (
          <div
            css={css`
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              max-width: 500px;
              align-self: center;
              flex: 1;
            `}
          >
            <Alert
              message={formData.customError.additionalTeamOfficialsBirthDate}
              type='error'
              showIcon
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default TeamOfficialForm
