/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'
import colors from '@sportninja/common/constants/appColors'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { useEffect, useState } from 'react'
import { font } from '../components/css'
import Icon from '../components/Icon'
import { Flex } from '../components/Layout'

const entityTypeToLabel = (entityType) => {
  let label = ''
  switch (entityType) {
    case ENTITY_TYPES.org:
      label = isCanlan ? 'League ' : 'Organization '
      break
    case ENTITY_TYPES.schedule:
      label = isCanlan ? 'Season ' : 'Competition '
      break
    case ENTITY_TYPES.team:
      label = 'Team '
      break
    case ENTITY_TYPES.game:
      label = 'Game '
      break
    case ENTITY_TYPES.salesforce:
      label = 'Salesforce '
      break
    case ENTITY_TYPES.division:
      label = 'Division '
      break
    case ENTITY_TYPES.conference:
      label = 'Conference '
      break
    case ENTITY_TYPES.player:
      label = 'Player '
      break
    case ENTITY_TYPES.roster:
      label = 'Roster '
      break
    default:
      break
  }
  if (entityType === ENTITY_TYPES.salesforce) {
    return `${label}ID`
  }
  if (entityType === ENTITY_TYPES.roster) {
    return `${label}ID`
  }
  return `${isCanlan ? 'Canlan ' : 'SN '}${label}ID`
}

const EditEntitySubTitle = ({ id, entityType, fixedWidth = false }) => {
  const [hasCopied, setHasCopied] = useState(false)

  useEffect(() => {
    if (hasCopied) {
      setTimeout(() => {
        setHasCopied(false)
      }, 2000)
    }
  }, [hasCopied])

  return (
    <Flex
      css={css`
        font-size: 19px;
        margin-top: 16px;
      `}
    >
      <Flex alignItems='center' noFlex>
        <span
          className='edit-entity-sub-title-label'
          css={css`
            ${font.title}
            font-weight: bold;
            width: ${fixedWidth ? '80px' : 'auto'};
          `}
        >
          {entityTypeToLabel(entityType)}:
        </span>
        <Flex
          alignItems='center'
          css={css`
            border-radius: 4px;
            background-color: ${colors.SOFT_STEEL};
            margin-left: 8px;
          `}
        >
          <div
            css={css`
              padding: 2px 8px 0;
              font-size: 16px;
              font-family: 'Courier New', monospace;
              font-weight: normal;
              color: ${colors.WHITE};
              opacity: 0.8;
              min-width: 185px;
            `}
          >
            {hasCopied ? 'Copied' : id}
          </div>
          <button
            type='button'
            title='Copy UID to clipboard'
            onClick={() => {
              navigator?.clipboard?.writeText(id)
              setHasCopied(true)
            }}
            css={css`
              border-left: 1px solid gray;
              height: 100%;
              padding: 6px 6px 6px 8px;
            `}
          >
            <Icon
              faType='far'
              name={hasCopied ? 'clipboard-check' : 'clipboard'}
              color={hasCopied ? colors.DEFAULT_FLAIR : undefined}
              fontSize={17}
            />
          </button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default EditEntitySubTitle
