import { useState, useCallback, useEffect } from 'react'
import req from '../api/request'
import { BillingScheduleItem } from '../types/Registration'

interface UserRegistrationImage {
  id: string
  filename: string
  full_path: string
  mime_type: string
  width: number
  height: number
  formats: {
    id: string
    filename: string
    full_path: string
    mime_type: string
    width: number
    height: number
  }[]
}

export interface UserRegistration {
  uid: string
  registration_type: string
  registration_type_id: number
  team: {
    uid: string
    team_player_roster_id: string
    name: string
    image: UserRegistrationImage | null
    is_bucket: boolean
  }
  schedule: {
    uid: string
    name: string
    start_date: string
    end_date: string
  }
  player?: {
    id: string
    name_first: string
    name_middle: string | null
    name_last: string
    full_name: string | null
    birth_date: string
    height: number | null
    weight: number | null
    created_at: string
    gender: string | null
    invitation_id: string | null
    player_number: string | null
    play_direction: string | null
    is_affiliate: boolean
    is_injured: boolean
    is_playing: boolean
    is_starting: boolean
    is_proposed_starting: boolean
    is_suspended: boolean
    attendance_status: string | null
    customer_identifier: string
    sf_id: string | null
    suspensions: any[]
    email: string
    phone_number: string | null
    is_accepted: boolean
    is_invited: boolean
    is_validated: boolean
    is_signed_waiver: boolean
    league_registration_number: string | null
    player_type: string | null
  }
  registration_info?: {
    id: number
    email: string
    name_first: string
    name_last: string
    birth_date: string
    address: string
    city: string
    province: string
    country: string
    postal_code: string
    parent_first_name: string
    parent_last_name: string
    parent_email: string
    parent_phone_number: string
    emergency_first_name: string
    emergency_last_name: string
    emergency_email: string
    emergency_phone_number: string
    emergency_relationship: string
    doctor_name: string
    doctor_phone_number: string
    known_allergies: string
    medical_conditions: string
    preferred_position: string
    preferred_jersey_number: number
    preferred_dominant_side: string
    preferred_jersey_size: string
    preferred_tshirt_size: string
    preferred_short_size: string
    preferred_governing_body_number_1: string
    preferred_governing_body_number_2: string | null
    preferred_governing_body_number_3: string | null
    preferred_governing_body_number_4: string | null
    notes: string
    created_at: string
    updated_at: string
  }
  conditions: string | null
  condition_type: any[]
  status: string
  status_id: number
  is_comp: number
  is_bucket: boolean
  billing: BillingScheduleItem[]
  created_at: string
  updated_at: string
}

interface UserRegistrationState {
  registrations: UserRegistration[]
  loading: boolean
  error: string | null
}

interface UseUserRegistrationResult {
  registrationState: UserRegistrationState
  fetchUserRegistrations: (refresh?: boolean) => Promise<void>
}

export const useUserRegistrationData = (): UseUserRegistrationResult => {
  const [registrationState, setRegistrationState] =
    useState<UserRegistrationState>({
      registrations: [],
      loading: false,
      error: null,
    })

  const fetchUserRegistrations = useCallback(async (refresh = true) => {
    if (refresh) {
      setRegistrationState((prevState) => ({
        ...prevState,
        loading: true,
        error: null,
      }))
    }

    try {
      const { data } = await req('/users/me/registrations')

      setRegistrationState((prevState) => ({
        ...prevState,
        registrations: data,
        loading: false,
        error: null,
      }))
    } catch (e) {
      setRegistrationState((prevState) => ({
        ...prevState,
        loading: false,
        error: 'An error occurred when trying to fetch user registrations',
      }))
    }
  }, [])

  useEffect(() => {
    fetchUserRegistrations()
  }, [fetchUserRegistrations])

  return { registrationState, fetchUserRegistrations }
}
