/** @jsxImportSource @emotion/react */
// @ts-nocheck
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Banner from 'src/components/Banner'
import BannerController from 'src/components/Banner/BannerController'
import { Button } from 'src/components/Button'
import { Input } from 'src/components/Input'
import { NewUserAddressSelector } from 'src/components/NewSignUpUserAddressSelector'
import PhoneInputComponent from '../../../../components/PhoneFormComponent'
import type { FormDispatcher, State } from '../formController'
import { BusinessAccountData, RequestsService } from '../requests/index'
import { validateFormUsingApi } from './newMember'
import { StepProps } from './userSelection'

interface Props extends StepProps, FormDispatcher {
  form: State
  context: {
    name: string
    id: string
  }
}

export interface BusinessInfoInputFields {
  title: string
  companyEmail: string
  orgAbbreviation: string
  phone: string
  companyName: string
  street: string
  country: string
  state: string
  city: string
  postcode: string
}

const initialValues = {
  title: '',
  companyEmail: '',
  phone: '',
  companyName: '',
  street: '',
  country: '',
  state: '',
  city: '',
  postcode: '',
  orgAbbreviation: '',
}

export type BodyType = Omit<BusinessAccountData, 'postcode'> & {
  zipCode: string
}

const trySignUp = async (data: BodyType): Promise<boolean> => {
  const res = await RequestsService.signUpBusinessAccount(data)

  if (res === 'ERROR') {
    throw new Error('An error happened (ERR: 5)')
  }

  if (res.user && typeof res.user.validated_at === 'string') {
    return true
  }

  return false
}

function createAbbreviation(companyName: string) {
  let text = companyName.replace(/\s/g, '') // Remove all whitespaces
  // clear any special characters or emojis
  text = text.replace(/[^a-zA-Z0-9]/g, '')
  // Check if input is valid
  if (!text) {
    return '-' // Return an empty string if the input is empty or invalid
  }
  if (typeof text !== 'string') {
    return '-' // Return an empty string if the input is not a string
  }
  if (text.length < 5) {
    return text.toUpperCase() // Return the input in uppercase if it has less than 5 characters
  }
  // Get the first 5 characters of the text
  const abbreviation = text.slice(0, 5)

  // Ensure the abbreviation has at least one character and return it in uppercase
  return abbreviation.length > 0 ? abbreviation.toUpperCase() : ''
}

export const BusinessInfo = ({
  form,
  formDispatcher,
  context,
  stepDispatcher,
}: Props) => {
  const { control, handleSubmit, setValue, watch } =
    useForm<BusinessInfoInputFields>({
      defaultValues: {
        companyEmail: form?.user?.email || '',
      },
    })
  const [fieldErrors, setFieldErrors] = useState<string[]>([])
  const [customError, setCustomError] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [country, setCountry] = useState('')

  const history = useHistory()

  const setCustomErrors = (invalid_fields: any) => {
    if (invalid_fields['account.title']) {
      setCustomErroByField('title', invalid_fields['account.title'])
    }
    if (invalid_fields['account.email']) {
      setCustomErroByField('companyEmail', invalid_fields['account.email'])
    }
    if (invalid_fields['account.phone_number']) {
      setCustomErroByField('phone', invalid_fields['account.phone_number'])
    }
    if (invalid_fields['account.company_name']) {
      setCustomErroByField(
        'companyName',
        invalid_fields['account.company_name']
      )
    }
    if (invalid_fields['account.org_abbreviation']) {
      setCustomErroByField(
        'orgAbbreviation',
        invalid_fields['account.org_abbreviation']
      )
    }
    if (invalid_fields['account.address_street']) {
      setCustomErroByField('street', invalid_fields['account.address_street'])
    }
    if (invalid_fields['account.address_country']) {
      setCustomErroByField('country', invalid_fields['account.address_country'])
    }
    if (invalid_fields['account.address_state_province']) {
      setCustomErroByField(
        'state',
        invalid_fields['account.address_state_province']
      )
    }
    if (invalid_fields['account.address_city']) {
      setCustomErroByField('city', invalid_fields['account.address_city'])
    }
    if (invalid_fields['account.address_postal_code']) {
      setCustomErroByField(
        'postcode',
        invalid_fields['account.address_postal_code']
      )
    }
  }

  const setCustomErroByField = (field: string, message: string) => {
    setCustomError((prev) => ({ ...prev, [field]: message }))
    setFieldErrors((prevErrors) => [...new Set([...prevErrors, field])])
  }

  const onSubmit = useCallback(
    (data: BusinessInfoInputFields) => {
      setCustomError(initialValues)
      setFieldErrors([])
      setLoading(true)

      const contactInfo: State['contactInfo'] = {
        title: data.title,
        email: data.companyEmail,
        phone: data.phone,
        companyName: data.companyName,
      }

      const address: State['address'] = {
        street: data.street,
        country,
        state: data.state,
        city: data.city,
        zipCode: data.postcode,
      }

      const body: BodyType = {
        firstName: form.user.firstName,
        lastName: form.user.lastName,
        email: data.companyEmail || form.user.email,
        password: form.user.password,
        confirmPassword: form.user.password,
        dateOfBirth: form.user.dateOfBirth,
        fullName: form.user.firstName + ' ' + form.user.lastName,
        title: data.title,
        companyEmail: data.companyEmail,
        phone: data.phone,
        companyName: data.companyName,
        orgAbbreviation: data.orgAbbreviation,
        context: {
          id: context.id,
          name: context.name,
        },
        ...address,
      }

      const data_to_validate = {
        name_first: body.firstName,
        name_last: body.lastName,
        email: body.email,
        password: body.password,
        confirm_password: body.confirmPassword,
        birth_date: body.dateOfBirth,
        context: body.context.name,
        context_id: body.context.id,
        account: {
          title: body.title,
          email: body.email,
          phone_number: body.phone,
          company_name: body.companyName,
          org_abbreviation: body.orgAbbreviation,
          address_street: body.street,
          address_country: body.country,
          address_state_province: body.state,
          address_city: body.city,
          address_postal_code: body.zipCode,
        },
      }

      validateFormUsingApi(data_to_validate)
        .then((res) => {
          formDispatcher({ type: 'setContactInfo', payload: contactInfo })
          formDispatcher({ type: 'setAddress', payload: address })

          trySignUp(body)
            .then(() => {
              history.push(ROUTES.SIGNUP_SUCCESS, {
                email: data.companyEmail || form.user.email,
              })
            })
            .catch((e) => {
              console.error('ERROR', e)
              setLoading(false)
            })
        })
        .catch((e) => {
          if (
            e?.status === 500 &&
            e?.message.includes('View [errors.401] not found.')
          ) {
            BannerController.add(({ ...props }) => (
              <Banner
                duration={3000}
                onClose={undefined}
                onClick={undefined}
                subChildren={undefined}
                {...props}
                type='failed'
              >
                {
                  'Looks like you are already logged in. Redirecting you to the home page'
                }
              </Banner>
            ))
            setTimeout(() => {
              window.location.href = ROUTES.HOME
            }, 3000)
            return
          }
          if (e?.invalid_fields) {
            setCustomErrors(e.invalid_fields)
            setLoading(false)
            return
          }
          setLoading(false)
          const errorMessage = getErrorMessage(e)
          BannerController.add(({ ...props }) => (
            <Banner
              duration={undefined}
              onClose={undefined}
              onClick={undefined}
              subChildren={undefined}
              {...props}
              type='failed'
            >
              {errorMessage}
            </Banner>
          ))
        })
    },
    [country]
  )

  useEffect(() => {
    watch((value, { name, type }) => {
      if (name === 'companyName') {
        setValue('orgAbbreviation', createAbbreviation(value?.companyName))
      }
    })
  }, [watch])

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit(onSubmit)
      }}
      css={css`
        display: flex;
        flex: 1;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <p
          css={css`
            font-size: 24px;
            font-weight: 700;
            color: ${colors.WHITE};
          `}
        >
          Setup Your Account
        </p>
        <div />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <p
          css={css`
            font-size: 12px;
            font-weight: 500;
            margin-top: 32px;
            color: ${colors.ATTENDANCE_GRAY};
          `}
        >
          All Fields Are Mandatory
        </p>
      </div>
      <div
        css={css`
          margin-top: 16px;
          display: flex;
          flex-direction: column;
        `}
      >
        <p
          css={css`
            font-size: 24px;
            color: ${colors.WHITE};
            font-family: ${isCanlan
              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
              : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
            font-weight: 600;
          `}
        >
          CONTACT INFO
        </p>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          margin-top: 16px;

          @media (max-width: 768px) {
            flex-direction: column;

            > div {
              width: 100%;
            }
          }
        `}
      >
        <Input
          autoComplete='organization'
          // required
          invalidMessage={customError?.companyName}
          invalid={fieldErrors.includes('companyName')}
          fieldName='companyName'
          label='Organization Name *'
          control={control}
        />
        {/* <Input
          autoComplete='off'
          // required
          invalidMessage={customError?.orgAbbreviation}
          invalid={fieldErrors.includes('orgAbbreviation')}
          fieldName='orgAbbreviation'
          label='Abbreviation (8 Or Less) *'
          control={control}
          maxLength={8}
        /> */}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          margin-top: 8px;
          @media (max-width: 768px) {
            flex-direction: column;

            > div {
              width: 100%;
            }
          }
        `}
      >
        <div
          id='title'
          css={css`
            width: 48%; // 50% of the parent container
            display: flex;
            flex-direction: column;
            gap: 8px;
            @media (max-width: 768px) {
              width: 100%;
            }
          `}
        >
          <style>
            {`
              .flag-dropdown {
                height: 40px!important;
              }
            `}
          </style>
          <p
            tabIndex={-1}
            css={css`
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
              color: ${colors.WHITE};
            `}
          >
            Phone Number *
          </p>
          <PhoneInputComponent
            tabIndex={1}
            namePhone={'phone'}
            onChangePhone={(e) => setValue('phone', e.target.value)}
            setPhoneValue={setValue}
            containerStyle={{
              width: '100%',
              height: 40,
            }}
            inputStyle={{
              borderWidth: 1,
              borderColor: fieldErrors.includes('phone')
                ? colors.ERROR_LIGHT
                : '#3F4753',
              height: 40,
              borderRadius: 4,
              display: 'flex',
              backgroundColor: 'transparent',
              color: 'rgba(255, 255, 255, 0.85)',
              fontSize: 16,
              letterSpacing: 0.74,
              width: '100%',
            }}
            disableDropdown={true}
            invalid={fieldErrors.includes('phone')}
            invalidMessage={customError.phone}
          />
          <div
            css={css`
              transition: opacity 0.1s ease-in-out;
              opacity: ${fieldErrors.includes('phone') ? 1 : 0};
              font-size: 12px;
              color: ${colors.ERROR_LIGHT};
            `}
          >
            <span>{customError.phone}</span>
          </div>
        </div>
      </div>
      <div
        css={css`
          margin-top: 16px;
          padding-top: 16px;
          display: flex;
          flex-direction: column;
          border-top: 1px solid ${colors.ATTENDANCE_GRAY};
        `}
      >
        <p
          css={css`
            font-size: 24px;
            color: ${colors.WHITE};
            font-family: ${isCanlan
              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
              : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
            font-weight: 600;
          `}
        >
          ADDRESS
        </p>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 16px;
        `}
      >
        <NewUserAddressSelector
          responsible
          control={control}
          onChangeCountry={(value) => setCountry(value)}
          setFormValue={setValue}
          isModal={false}
          invalidFields={{
            street: fieldErrors.includes('street'),
            city: fieldErrors.includes('city'),
            postcode: fieldErrors.includes('postcode'),
            state: fieldErrors.includes('state'),
            country: fieldErrors.includes('country'),
          }}
          invalidMessages={{
            street: customError.street,
            city: customError.city,
            postcode: customError.postcode,
            state: customError.state,
            country: customError.country,
          }}
          requiredFields={{
            street: true,
            city: true,
            postcode: true,
            state: true,
            country: true,
          }}
        />
      </div>
      <div
        css={css`
          margin-top: 32px;
          display: flex;
          flex-direction: row;
          gap: 16px;
        `}
      >
        <Button
          onClick={(e) => {
            e.preventDefault()
            stepDispatcher({ type: 'set', payload: 2 })
          }}
          label={'Back'}
          variant='secondary'
          disabled={loading}
          isLoading={loading}
        />
        <Button
          type='submit'
          disabled={loading}
          isLoading={loading}
          onClick={handleSubmit(onSubmit)}
          label='CREATE ACCOUNT'
        />
      </div>
    </form>
  )
}
