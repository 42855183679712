/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { connect } from 'react-redux'
import {
  ManageTeamAttendanceModalContextType,
  ManageTeamAttendanceModalProvider,
  ManageTeamAttendanceModalContext,
} from './context'
import { Modal } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Button from '../ts-components/Button'
import Text from '../ts-components/Text'
import { CloseOutlined } from '@ant-design/icons'
import ManageTeamAttendanceModalContent from './ManageTeamAttendanceModalContent'
import useGamePlayerRoster from '@sportninja/common/hooks/useGamePlayerRoster'
import req from '@sportninja/common/api/request'
import { getSoccerSport, getSports } from '@sportninja/common/selectors/types'

const styles = css`
  .my-modal-body {
  }
  .my-modal-mask {
    backdrop-filter: blur(1px);
  }
  .my-modal-header {
    border-bottom: 1px dotted ${colors.FIERY_RED};
  }
  .my-modal-footer {
    color: ${colors.FIERY_RED};
  }
  .my-modal-content {
    border: 1px solid #333;
  }
`
const classNames = {
  body: styles['my-modal-body'],
  mask: styles['my-modal-mask'],
  header: styles['my-modal-header'],
  footer: styles['my-modal-footer'],
  content: styles['my-modal-content'],
}
const modalStyles = {
  header: {
    backgroundColor: colors.SECONDARY_300,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  body: {
    borderBottom: `1px solid ${colors.SOFT_STEEL}`,
    borderTop: `1px solid ${colors.SOFT_STEEL}`,
    backgroundColor: colors.SECONDARY_300,
  },
  content: {
    backgroundColor: colors.SECONDARY_300,
    paddingTop: '8px',
    paddingBottom: '16px',
    paddingLeft: '0',
    paddingRight: '0',
  },
  wrapper: {
    zIndex: 10001,
  },
  mask: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(6, 10, 16, 0.8)',
    zIndex: 10000,
  },
  footer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

const ManageTeamAttendanceModal = ({
  isManageAttendanceModalOpen,
  setIsManageAttendanceModalOpen,
  selectedManageAttendance,
  readAttendanceInformation,
  soccerSportId,
}: // sports,
{
  isManageAttendanceModalOpen: boolean
  setIsManageAttendanceModalOpen: (isOpen: boolean) => void
  selectedManageAttendance: {
    gameId: string
    rosterId: string
    teamId: string
    teamName: string
    showWaiver: boolean
  } | null
  readAttendanceInformation: () => void
  sports: any
  soccerSportId: number
}) => {
  const { roster, refetch } = useGamePlayerRoster(
    selectedManageAttendance?.gameId,
    selectedManageAttendance?.rosterId
  )
  const [settings, setSettings] = useState<any>({})
  const [game, setGame] = useState<any>(null)
  // const playerTypesBySport = useMemo(
  //   () => sports[game?.sport_id]?.players,
  //   [sports, game?.sport_id]
  // )
  const isGenericSport = useMemo(
    () => game?.generic_sport_id !== null,
    [game?.generic_sport_id]
  )
  const isSoccer = useMemo(
    () => game?.sport_id === soccerSportId,
    [game?.sport_id, soccerSportId]
  )
  const requireUniqueJerseyNumbers =
    settings?.settings?.game?.require_unique_jersey_numbers

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await req(
          `/games/${selectedManageAttendance?.gameId}/settings`
        )
        setSettings(data)
      } catch (e) {
        console.error(e)
      }
    }
    if (selectedManageAttendance?.gameId) {
      fetchSettings()
    }
  }, [selectedManageAttendance?.gameId])

  useEffect(() => {
    const fetchGame = async () => {
      try {
        const { data } = await req(`/games/${selectedManageAttendance?.gameId}`)
        setGame(data)
      } catch (e) {
        console.error(e)
      }
    }
    if (selectedManageAttendance?.gameId) {
      fetchGame()
    }
  }, [selectedManageAttendance?.gameId])

  const getFooter = useCallback(
    (_context: ManageTeamAttendanceModalContextType) => {
      return (
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            gap: 16px;
            width: 25%;
            margin-left: auto;
          `}
        >
          <Button
            text='Close'
            onClick={() => {
              setIsManageAttendanceModalOpen(false)
            }}
            variant='secondary'
          />
        </div>
      )
    },
    [setIsManageAttendanceModalOpen]
  )
  const getHeader = useCallback(() => {
    return (
      <Text
        variant='heading3'
        weight='semiBold'
        color={colors.WHITE}
        textTransform='capitalize'
      >
        {`Attendance - ${selectedManageAttendance?.teamName}`}
        {/* Choose Player/Team Official */}
      </Text>
    )
  }, [selectedManageAttendance?.teamName])
  const closeIcon = useCallback(() => {
    return (
      <CloseOutlined
        css={css`
          color: ${colors.SOLITUDE};
          font-size: 16px;
        `}
      />
    )
  }, [])

  return (
    <ManageTeamAttendanceModalProvider>
      {/* consumer */}
      <ManageTeamAttendanceModalContext.Consumer>
        {(context) => {
          if (!context) {
            return null
          }
          return (
            <Modal
              open={isManageAttendanceModalOpen}
              onOk={() => setIsManageAttendanceModalOpen(false)}
              onCancel={() => setIsManageAttendanceModalOpen(false)}
              css={css`
                .ant-modal-wrap {
                  z-index: 10001;
                }
              `}
              width={650}
              centered
              classNames={classNames}
              styles={modalStyles}
              maskClosable={false}
              footer={getFooter(context)}
              title={getHeader()}
              closeIcon={closeIcon()}
            >
              <ManageTeamAttendanceModalContent
                players={roster?.players || []}
                gameId={roster?.game_id}
                rosterId={roster?.id}
                refetch={refetch}
                readAttendanceInformation={readAttendanceInformation}
                requireUniqueJerseyNumbers={requireUniqueJerseyNumbers}
                // playerTypesBySport={playerTypesBySport}
                isGenericSport={isGenericSport}
                isSoccer={isSoccer}
              />
            </Modal>
          )
        }}
      </ManageTeamAttendanceModalContext.Consumer>
    </ManageTeamAttendanceModalProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    sports: getSports(state),
    soccerSportId: getSoccerSport(state)?.id,
  }
}
export default connect(mapStateToProps)(ManageTeamAttendanceModal)
