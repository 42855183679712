/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import ReprocessingOverlay from '../../../pages/Schedule/ReprocessingOverlay'
import GameListFilters from '../../List/GameList/GameListFilters'
import useGetScheduleFiltering from '../../List/useGetScheduleFiltering'
import {
  Avatar,
  Table,
  Typography,
  Collapse,
  Button,
  Spin,
  Empty,
  Tooltip,
} from 'antd'
import Icon from '../../Icon'
import useWindowSize from 'src/hooks/useWindowSize'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { useScheduleSettings } from '@sportninja/common/hooks/useScheduleSettings'
import { getStatTypesBySportId } from '@sportninja/common/selectors/types'
import { connect } from 'react-redux'
import TieBreakingProcedureModal from './TieBreakingProcedureModal'
import ReprocessingSettingsOverlay from 'src/pages/Schedule/ReprocessingSettingsOverlay'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { permission } from 'process'

const genericSportColumns = [
  {
    key: 'GP',
    sorter: (a, b) => a.GP - b.GP,
    title: 'GP',
    dataIndex: 'GP',
    showSorterTooltip: {
      title: 'Games Played',
    },
  },
  {
    key: 'W',
    sorter: (a, b) => a.W - b.W,
    title: 'W',
    dataIndex: 'W',
    showSorterTooltip: {
      title: 'Wins',
    },
  },
  {
    key: 'L',
    sorter: (a, b) => a.L - b.L,
    title: 'L',
    dataIndex: 'L',
    showSorterTooltip: {
      title: 'Losses',
    },
  },
  {
    key: 'T',
    sorter: (a, b) => a.T - b.T,
    title: 'T',
    dataIndex: 'T',
    showSorterTooltip: {
      title: 'Ties',
    },
  },
  {
    key: 'OTL',
    sorter: (a, b) => a.OTL - b.OTL,
    title: 'OTL',
    dataIndex: 'OTL',
    showSorterTooltip: {
      title: 'Overtime Losses',
    },
  },
  {
    key: 'OTW',
    sorter: (a, b) => a.OTW - b.OTW,
    title: 'OTW',
    dataIndex: 'OTW',
    showSorterTooltip: {
      title: 'Overtime Wins',
    },
  },
  {
    key: 'PTS',
    sorter: (a, b) => a.PTS - b.PTS,
    title: 'PTS',
    dataIndex: 'PTS',
    showSorterTooltip: {
      title: 'Points',
    },
  },
  {
    key: 'WPCT',
    sorter: (a, b) => a.WPCT - b.WPCT,
    title: 'WPCT',
    dataIndex: 'WPCT',
    showSorterTooltip: {
      title: 'Winning Percentage',
    },
  },
  {
    key: 'WPCTD',
    sorter: (a, b) => a.WPCTD - b.WPCTD,
    title: 'WPCTD',
    dataIndex: 'WPCTD',
    defaultSortOrder: 'descend',
    showSorterTooltip: {
      title: 'In-Division Winning Percentage',
    },
  },
  // {
  //   key: 'DIV',
  //   title: 'IN-DIV',
  //   dataIndex: 'IN-DIV',
  //   showSorterTooltip: {
  //     title: 'In-Division Record (W-L-T-OTL)',
  //   },
  // },
  {
    key: 'GF',
    sorter: (a, b) => a.GF - b.GF,
    title: 'GF',
    dataIndex: 'GF',
    showSorterTooltip: {
      title: 'Goals For',
    },
  },
  {
    key: 'GA',
    sorter: (a, b) => a.GA - b.GA,
    title: 'GA',
    dataIndex: 'GA',
    showSorterTooltip: {
      title: 'Goals Against',
    },
  },
  {
    key: 'GPCT',
    sorter: (a, b) => a.GPCT - b.GPCT,
    title: 'GPCT',
    dataIndex: 'GPCT',
    showSorterTooltip: {
      title: 'Game Percentage',
    },
  },
  {
    key: 'PIM',
    sorter: (a, b) => a.PIM - b.PIM,
    title: '%FOULS',
    dataIndex: 'PIM',
    showSorterTooltip: {
      title: 'Fouls %',
    },
  },
]

const hockeyColumns = [
  {
    key: 'GP',
    sorter: (a, b) => a.GP - b.GP,
    title: 'GP',
    dataIndex: 'GP',
    showSorterTooltip: {
      title: 'Games Played',
    },
  },
  {
    key: 'W',
    sorter: (a, b) => a.W - b.W,
    title: 'W',
    dataIndex: 'W',
    showSorterTooltip: {
      title: 'Wins',
    },
  },
  {
    key: 'L',
    sorter: (a, b) => a.L - b.L,
    title: 'L',
    dataIndex: 'L',
    showSorterTooltip: {
      title: 'Losses',
    },
  },
  {
    key: 'T',
    sorter: (a, b) => a.T - b.T,
    title: 'T',
    dataIndex: 'T',
    showSorterTooltip: {
      title: 'Ties',
    },
  },
  {
    key: 'OTL',
    sorter: (a, b) => a.OTL - b.OTL,
    title: 'OTL',
    dataIndex: 'OTL',
    showSorterTooltip: {
      title: 'Overtime Losses',
    },
  },
  {
    key: 'OTW',
    sorter: (a, b) => a.OTW - b.OTW,
    title: 'OTW',
    dataIndex: 'OTW',
    showSorterTooltip: {
      title: 'Overtime Wins',
    },
  },
  {
    key: 'SOL',
    sorter: (a, b) => a.SOL - b.SOL,
    title: 'SOL',
    dataIndex: 'SOL',
    showSorterTooltip: {
      title: 'Shootout Losses',
    },
  },
  {
    key: 'PTS',
    sorter: (a, b) => a.PTS - b.PTS,
    title: 'PTS',
    dataIndex: 'PTS',
    showSorterTooltip: {
      title: 'Points',
    },
  },
  {
    key: 'WPCT',
    sorter: (a, b) => a.WPCT - b.WPCT,
    title: 'WPCT',
    dataIndex: 'WPCT',
    showSorterTooltip: {
      title: 'Winning Percentage',
    },
  },
  {
    key: 'WPCTD',
    sorter: (a, b) => a.WPCTD - b.WPCTD,
    title: 'WPCTD',
    dataIndex: 'WPCTD',
    defaultSortOrder: 'descend',
    showSorterTooltip: {
      title: 'In-Division Winning Percentage',
    },
    width: 80,
  },
  {
    key: 'IN-DIV',
    title: 'DIV',
    dataIndex: 'IN-DIV',
    width: 100,
    showSorterTooltip: {
      title: 'In-Division Record (W-L-T-OTL)',
    },
    sorter: (a, b) => a['IN-DIV'].split('-')[0] - b['IN-DIV'].split('-')[0],
  },
  {
    key: 'GF',
    sorter: (a, b) => a.GF - b.GF,
    title: 'GF',
    dataIndex: 'GF',
    showSorterTooltip: {
      title: 'Goals For',
    },
  },
  {
    key: 'GA',
    sorter: (a, b) => a.GA - b.GA,
    title: 'GA',
    dataIndex: 'GA',
    showSorterTooltip: {
      title: 'Goals Against',
    },
  },
  {
    key: 'GPCT',
    sorter: (a, b) => a.GPCT - b.GPCT,
    title: 'GPCT',
    dataIndex: 'GPCT',
    showSorterTooltip: {
      title: 'Game Percentage',
    },
  },
  {
    key: 'PIM',
    sorter: (a, b) => a.PIM - b.PIM,
    title: 'PIM',
    dataIndex: 'PIM',
    showSorterTooltip: {
      title: 'Penalty Minutes',
    },
  },
]

const soccerColumns = [
  {
    key: 'MP',
    sorter: (a, b) => a.MP - b.MP,
    title: 'MP',
    dataIndex: 'MP',
    showSorterTooltip: {
      title: 'Matches Played',
    },
  },
  {
    key: 'W',
    sorter: (a, b) => a.W - b.W,
    title: 'W',
    dataIndex: 'W',
    showSorterTooltip: {
      title: 'Wins',
    },
  },
  {
    key: 'L',
    sorter: (a, b) => a.L - b.L,
    title: 'L',
    dataIndex: 'L',
    showSorterTooltip: {
      title: 'Losses',
    },
  },
  {
    key: 'D',
    sorter: (a, b) => a.D - b.D,
    title: 'D',
    dataIndex: 'D',
    showSorterTooltip: {
      title: 'Draws',
    },
  },
  {
    key: 'GF',
    sorter: (a, b) => a.GF - b.GF,
    title: 'GF',
    dataIndex: 'GF',
    showSorterTooltip: {
      title: 'Goals For',
    },
  },
  {
    key: 'GA',
    sorter: (a, b) => a.GA - b.GA,
    title: 'GA',
    dataIndex: 'GA',
    showSorterTooltip: {
      title: 'Goals Against',
    },
  },
  {
    key: 'GD',
    sorter: (a, b) => a.GF - a.GA - (b.GF - b.GA),
    title: 'GD',
    dataIndex: 'GD',
    showSorterTooltip: {
      title: 'Goals Difference',
    },
  },
  // {
  //   key: 'W%',
  //   sorter: (a, b) => a['W%'] - b['W%'],
  //   title: 'W%',
  //   dataIndex: 'W%',
  //   showSorterTooltip: {
  //     title: 'Winning Percentage',
  //   },
  // },
  // {
  //   key: 'DW%',
  //   sorter: (a, b) => a['DW%'] - b['DW%'],
  //   title: 'DW%',
  //   dataIndex: 'DW%',
  //   showSorterTooltip: {
  //     title: 'In-Division Winning Percentage',
  //   },
  // },
  {
    key: 'PTS',
    sorter: (a, b) => a.PTS - b.PTS,
    title: 'PTS',
    dataIndex: 'PTS',
    // defaultSortOrder: 'descend',
    showSorterTooltip: {
      title: 'Points',
    },
  },
]

const Standings = ({
  areFiltersHidden,
  setIsFilterToggleDisabled = () => {},
  id,
  defaultSort,
  refreshKey,
  scheduleReprocessingListener,
  statWhitelist = [],
  shouldStickyHeader = true,
  hideGroupName = false,
  isSoccer = false,
  isInFrame = false,
  isGenericSport = false,
  sportId,
  statTypes,
  isAdmin = false,
}) => {
  const { scheduleSettings, fetchScheduleSettings } = useScheduleSettings()
  const { standings } = scheduleSettings?.settings ?? {}
  const { sort_order } = standings ?? {}
  const { stat_id, tie_breaker_stats } = sort_order ?? {}
  const defaultStatType = statTypes?.find((s) => s.id === stat_id)
  const [isTieBreakingModalVisible, setIsTieBreakingModalVisible] =
    useState(false)

  /**
   * Memoized helper function to set default sort order and tie breaker stats
   * @param {Array} columns - Array of column configurations
   * @returns {Array} - Updated column configurations with default sort order and tie breaker sorting
   */
  const setDefaultSortOrder = useMemo(() => {
    return (columns) => {
      if (!defaultStatType?.abbreviation) {
        return columns
      }

      return columns.map((column) => {
        // Handle primary sort column
        if (column.key === defaultStatType.abbreviation) {
          return {
            ...column,
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
              // Primary sort
              const primaryDiff = a[column.key] - b[column.key]
              if (primaryDiff !== 0) {
                return primaryDiff
              }

              // Apply tie breakers in order
              if (tie_breaker_stats?.length) {
                for (const tieBreaker of tie_breaker_stats) {
                  const tieBreakerStat = statTypes?.find(
                    (s) => s.id === tieBreaker
                  )
                  if (tieBreakerStat?.abbreviation) {
                    const diff =
                      a[tieBreakerStat.abbreviation] -
                      b[tieBreakerStat.abbreviation]
                    if (diff !== 0) {
                      return diff
                    }
                  }
                }
              }
              return 0
            },
          }
        }

        // Remove any existing default sort order from other columns
        const { defaultSortOrder, ...rest } = column
        return rest
      })
    }
  }, [defaultStatType, tie_breaker_stats, statTypes])

  /**
   * Memoized common columns configuration for the standings table
   * This prevents unnecessary re-renders when other component state changes
   */
  const commonColumns = useMemo(
    () => [
      {
        title: 'TEAM',
        dataIndex: 'team',
        render: (item) => {
          return (
            <Link
              to={`/team/${item?.team?.id}?sn_schedule=${id}`}
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
              `}
            >
              <Avatar
                src={
                  getImageThumbnailId(item?.team) ||
                  '/images/placeholders/hockey/team.png'
                }
                shape='square'
                icon={<Icon name='user-friends' fontSize={14} />}
                css={css`
                  border: 1px solid #dbdbdb;
                  background-color: white;
                  padding: 2px;
                `}
              />
              <Typography.Text strong>{item?.team?.name_full}</Typography.Text>
            </Link>
          )
        },
        key: 'team',
        fixed: 'left',
        width: 300,
      },
    ],
    [id]
  ) // Only re-compute when id changes

  const { filters, state, setState, loaded, currentParams } =
    useGetScheduleFiltering({ schedule_id: id }, { defaultSort: 'desc' })

  const { width } = useWindowSize()
  const isMobile = useMemo(() => {
    return width <= 768
  }, [width])
  const [scheduleId, setScheduleId] = useState(currentParams?.sn_division ?? id)
  const [grouping, setGrouping] = useState(filters?.[filters?.length - 1]?.name)
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [groups, setGroups] = useState([])
  const groupList = useMemo(() => Object.keys(groups), [groups])
  const activeListItems = useMemo(
    () => groupList.map((_, i) => i.toString()),
    [groupList]
  )
  const [expandAllItems, setExpandAllItems] = useState(true)
  const [activeKey, setActiveKey] = useState(null)

  const shouldDisplayTieBreakingModal = useMemo(() => {
    if (!isCanlan) {
      return true
    }
    // Only show if there the user has admin permissions
    return isAdmin
  }, [isAdmin])

  useEffect(() => {
    if (id && !scheduleSettings) {
      fetchScheduleSettings(id)
    }
  }, [id, scheduleSettings, fetchScheduleSettings])

  useEffect(() => {
    return () => {
      setIsFilterToggleDisabled(false)
    }
  }, [])

  useEffect(() => {
    // Wait for schedule filters to finish loading, then set the grouping and load statistics
    if (loaded) {
      setGrouping(filters?.[filters?.length - 1]?.name || '')
      setReady(true)
      if (filters?.length === 0) {
        setIsFilterToggleDisabled(true)
      }
    }
  }, [loaded, filters?.length])

  const getItems = async (page) => {
    setLoading(true)
    let groupBy
    if (grouping) {
      groupBy = grouping
    }

    const slug = `/schedules/${scheduleId}/stats/byType`

    const response = await req(slug, {
      query: { groupBy, page },
    })

    const g = {}
    response.data.forEach((row) => {
      g[row.schedule.id] = g[row.schedule.id] || {}
      g[row.schedule.id].rows = g[row.schedule.id].rows || []
      g[row.schedule.id].name = row.schedule.name
      if (row?.team !== null) {
        g[row.schedule.id].rows.push(row)
      }
    })

    setItems(response.data)
    setGroups(g)
    setLoading(false)
  }

  useEffect(() => {
    ready && getItems()
  }, [ready, scheduleId, grouping, refreshKey])

  const collapseItems = useMemo(
    () => [
      ...groupList.map((groupId, index) => {
        const group = groups[groupId]
        return {
          key: String(index),
          label: group?.name,
          children: (
            <Table
              columns={setDefaultSortOrder(
                commonColumns.concat(
                  isSoccer
                    ? soccerColumns
                    : isGenericSport
                    ? genericSportColumns
                    : hockeyColumns
                )
              )}
              bordered
              size='small'
              scroll={{ x: 1300 }}
              pagination={false}
              dataSource={[
                ...group.rows.map((item, index) => ({
                  key: index,
                  team: item,
                  GP: item?.stats?.find((s) => s?.abbr === 'GP')?.value ?? 0,
                  W: item?.stats?.find((s) => s?.abbr === 'W')?.value ?? 0,
                  L: item?.stats?.find((s) => s?.abbr === 'L')?.value ?? 0,
                  T: item?.stats?.find((s) => s?.abbr === 'T')?.value ?? 0,
                  OTW: item?.stats?.find((s) => s?.abbr === 'OTW')?.value ?? 0,
                  OTL: item?.stats?.find((s) => s?.abbr === 'OTL')?.value ?? 0,
                  SOL: item?.stats?.find((s) => s?.abbr === 'SOL')?.value ?? 0,
                  PTS: item?.stats?.find((s) => s?.abbr === 'PTS')?.value ?? 0,
                  WPCT:
                    item?.stats?.find((s) => s?.abbr === 'WPCT')?.value ?? 0,
                  WPCTD:
                    item?.stats?.find((s) => s?.abbr === 'WPCTD')?.value ?? 0,
                  'IN-DIV':
                    item?.stats?.find((s) => s?.abbr === 'IN-DIV')?.value === 0
                      ? '0-0-0-0'
                      : item?.stats?.find((s) => s?.abbr === 'IN-DIV')?.value ??
                        '0-0-0-0',
                  GF: item?.stats?.find((s) => s?.abbr === 'GF')?.value ?? 0,
                  GA: item?.stats?.find((s) => s?.abbr === 'GA')?.value ?? 0,
                  DIFF:
                    item?.stats?.find((s) => s?.abbr === 'DIFF')?.value ?? 0,
                  GPCT:
                    item?.stats?.find((s) => s?.abbr === 'GPCT')?.value ?? 0,
                  PIM: item?.stats?.find((s) => s?.abbr === 'PiM')?.value ?? 0,
                  MP: item?.stats?.find((s) => s?.abbr === 'MP')?.value ?? 0,
                  D: item?.stats?.find((s) => s?.abbr === 'D')?.value ?? 0,
                  ETL: item?.stats?.find((s) => s?.abbr === 'ETL')?.value ?? 0,
                  PSL: item?.stats?.find((s) => s?.abbr === 'PSL')?.value ?? 0,
                  GC: item?.stats?.find((s) => s?.abbr === 'GC')?.value ?? 0,
                  MPCT:
                    item?.stats?.find((s) => s?.abbr === 'MPCT')?.value ?? 0,
                  ETW: item?.stats?.find((s) => s?.abbr === 'ETW')?.value ?? 0,
                  RC: item?.stats?.find((s) => s?.abbr === 'RC')?.value ?? 0,
                  YC: item?.stats?.find((s) => s?.abbr === 'YC')?.value ?? 0,
                  'W%': item?.stats?.find((s) => s?.abbr === 'W%')?.value ?? 0,
                  'DW%':
                    item?.stats?.find((s) => s?.abbr === 'DW%')?.value ?? 0,
                  GD: item?.stats?.find((s) => s?.abbr === 'GD')?.value ?? 0,
                })),
              ]}
            />
          ),
        }
      }),
    ],
    [
      commonColumns,
      groupList,
      groups,
      isGenericSport,
      isSoccer,
      setDefaultSortOrder,
    ]
  )

  return (
    <>
      {/* <ReprocessingOverlay
        scheduleReprocessingListener={scheduleReprocessingListener}
      /> */}
      <ReprocessingSettingsOverlay
        scheduleReprocessingListener={scheduleReprocessingListener}
      />
      <div
        css={css`
          margin-top: 24px;

          ${scheduleReprocessingListener &&
          css`
            opacity: 0.7;
            filter: blur(2px);
            pointer-events: none;
          `}
        `}
      >
        <GameListFilters
          filters={filters}
          state={state}
          setState={setState}
          loading={loading}
          onScheduleChange={(scheduleId, scheduleName) => {
            if (scheduleId) {
              setScheduleId(scheduleId)
              setGrouping(scheduleName)
            } else {
              setScheduleId(id)
              setGrouping(filters?.[filters?.length - 1]?.name || '')
            }
          }}
          isHidden={areFiltersHidden}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: row;
              gap: 8px;
            `}
          >
            <p
              css={css`
                height: 12px;
                font-size: 12px;
                line-height: 12px;
                margin-bottom: 8px;
                text-transform: capitalize;
              `}
            />
            <Button
              css={
                !isInFrame
                  ? css``
                  : css`
                      /* border should be : rgba(0, 0, 0, 0.3) */
                      border: 1px solid rgba(0, 0, 0, 0.3);
                      color: ${colors.HEADER};
                    `
              }
              type='default'
              onClick={() => {
                if (expandAllItems) {
                  setExpandAllItems(false)
                  setActiveKey(null)
                } else {
                  setExpandAllItems(true)
                  setActiveKey(activeListItems)
                }
              }}
            >
              <span>{expandAllItems ? 'Collapse All' : 'Expand All'}</span>
            </Button>
            {shouldDisplayTieBreakingModal ? (
              <Tooltip title='Tie-Breaking Procedure'>
                <Button
                  css={
                    !isInFrame
                      ? css``
                      : css`
                          border: 1px solid rgba(0, 0, 0, 0.3);
                          color: ${colors.HEADER};
                        `
                  }
                  type='default'
                  icon={<Icon name='info-circle' />}
                  onClick={() => setIsTieBreakingModalVisible(true)}
                />
              </Tooltip>
            ) : null}
          </div>
        </GameListFilters>
        {items?.length === 0 && !loading ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={css`
              margin-top: 24px;
            `}
          />
        ) : null}
        {!ready ||
          (loading && (
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                height: 400px;
              `}
            >
              <Spin />
            </div>
          ))}
        {ready && groupList.length > 0 && !loading && (
          <Collapse
            css={
              !isInFrame
                ? css``
                : css`
                    /* if is inFrame, we should change ant-collapse-header-text color to colors.HEADER */
                    .ant-collapse-header-text {
                      color: ${colors.HEADER};
                    }
                    .ant-collapse-expand-icon {
                      color: ${colors.HEADER};
                    }
                  `
            }
            items={collapseItems}
            activeKey={expandAllItems ? activeListItems : activeKey}
            onChange={(e) => {
              setExpandAllItems(false)
              setActiveKey(e)
            }}
            style={{ marginBottom: 24 }}
          />
        )}
        <TieBreakingProcedureModal
          isOpen={isTieBreakingModalVisible}
          onClose={() => setIsTieBreakingModalVisible(false)}
          tieBreakingStats={tie_breaker_stats}
          statTypes={statTypes}
          defaultSortBy={stat_id}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    statTypes: getStatTypesBySportId(state, sportId),
  }
}

const mapDispatchToProps = (_) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Standings)
