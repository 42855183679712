/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Button from 'src/components/ts-components/Button'
import Text from 'src/components/ts-components/Text'

const EmptyRegistration = ({
  errorMessage,
  title = 'Something went wrong. Please try again.',
}: {
  errorMessage: string
  title?: string
}) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: ${colors.SECONDARY_300};
        padding: 20px;
        gap: 16px;
      `}
    >
      <div
        css={css`
          background-color: ${colors.SECONDARY_400};
          border: 1px solid ${colors.SECONDARY_300};
          border-radius: 16px;
          padding: 40px 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          max-width: 440px;
        `}
      >
        <img
          src={'/images/unavailable_warning.png'}
          alt='Empty Registration'
          css={css`
            width: 100px;
            height: auto;
          `}
        />

        <Text
          variant='heading5'
          color={colors.WHITE}
          weight={'semiBold'}
          style={`
            text-transform: uppercase;
            text-align: center;
          `}
        >
          {title}
        </Text>

        {errorMessage ? (
          <Text
            variant='paragraphMedium'
            color={colors.WHITE}
            weight={'regular'}
            style={`
              text-align: center;
            `}
          >
            {errorMessage}
          </Text>
        ) : null}

        <div
          css={css`
            height: 44px;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <Button
            variant='primary'
            onClick={() => {
              window.location.href = '/'
            }}
            text='Go to Home'
            uppercase={true}
          />
        </div>
      </div>
    </div>
  )
}

export default EmptyRegistration
