/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import Text from 'src/components/ts-components/Text'
import { useOrganizationModalContext } from '../../context'
import { TextInput } from 'src/components/ts-components/TextInput'
import PhoneInput from 'src/components/ts-components/PhoneInput'
import colors from '@sportninja/common/constants/appColors'

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const ContactInfo = () => {
  const { state, setState } = useOrganizationModalContext()

  return (
    <div
      css={css`
        display: flex;
        padding: 16px;
        gap: 16px;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text variant='captionLarge' weight='semiBold'>
          Contact Info
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          align-self: stretch;
          flex-direction: row;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <PhoneInput
            value={state.phone}
            onChange={(value) => {
              setState({ ...state, phone: value })
            }}
            label='Phone Number'
            error={
              state.fieldErrors.includes('phone') ? 'Invalid phone number' : ''
            }
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <TextInput
            label='Email'
            value={state.email}
            setValue={(value) => {
              setState({ ...state, email: value })
            }}
            onBlur={() => {
              if (state.email.length > 0 && !emailRegex.test(state.email)) {
                setState({
                  ...state,
                  fieldErrors: [...state.fieldErrors, 'email'],
                  customError: {
                    ...state.customError,
                    email: 'Invalid email',
                  },
                })
              } else {
                setState({
                  ...state,
                  fieldErrors: state.fieldErrors.filter(
                    (field) => field !== 'email'
                  ),
                  customError: {
                    ...state.customError,
                    email: '',
                  },
                })
              }
            }}
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            disabled={false}
            name='email'
            type='email'
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          align-self: stretch;
          flex-direction: row;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <TextInput
            label='Website'
            value={state.website}
            setValue={(value) => {
              setState({ ...state, website: value })
            }}
            onBlur={() => {
              const regex =
                /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/
              if (state.website.length > 0 && !state.website.match(regex)) {
                setState({
                  ...state,
                  fieldErrors: [...state.fieldErrors, 'website'],
                  // add the field to the customError
                  customError: {
                    ...state.customError,
                    website: 'Invalid website',
                  },
                })
              } else {
                // remove the field from the fieldErrors and customError
                setState({
                  ...state,
                  fieldErrors: state.fieldErrors.filter(
                    (field) => field !== 'website'
                  ),
                  customError: {
                    ...state.customError,
                    website: '',
                  },
                })
              }
            }}
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            disabled={false}
            name='website'
            type='url'
          />
        </div>
      </div>
      <div
        css={css`
          height: 1px;
          width: 100%;
          background-color: ${colors.SECONDARY_200};
        `}
      />
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text variant='captionLarge' weight='semiBold'>
          Support Info
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          align-self: stretch;
          flex-direction: row;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <PhoneInput
            value={state.support_phone}
            onChange={(value) => {
              setState({ ...state, support_phone: value })
            }}
            label='Support Phone Number'
            error={
              state.fieldErrors.includes('support_phone')
                ? 'Invalid phone number'
                : ''
            }
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <TextInput
            label='Support Email'
            value={state.support_email}
            setValue={(value) => {
              setState({ ...state, support_email: value })
            }}
            onBlur={() => {
              if (
                state.support_email.length > 0 &&
                !emailRegex.test(state.support_email)
              ) {
                setState({
                  ...state,
                  fieldErrors: [...state.fieldErrors, 'support_email'],
                  customError: {
                    ...state.customError,
                    support_email: 'Invalid support email',
                  },
                })
              } else {
                setState({
                  ...state,
                  fieldErrors: state.fieldErrors.filter(
                    (field) => field !== 'support_email'
                  ),
                  customError: {
                    ...state.customError,
                    support_email: '',
                  },
                })
              }
            }}
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            disabled={false}
            name='support_email'
            type='email'
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          align-self: stretch;
          flex-direction: row;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <TextInput
            label='Support Website'
            value={state.support_website}
            setValue={(value) => {
              setState({ ...state, support_website: value })
            }}
            onBlur={() => {
              const regex =
                /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/
              if (
                state.support_website.length > 0 &&
                !state.support_website.match(regex)
              ) {
                setState({
                  ...state,
                  fieldErrors: [...state.fieldErrors, 'support_website'],
                  // add the field to the customError
                  customError: {
                    ...state.customError,
                    support_website: 'Invalid Support Website',
                  },
                })
              } else {
                // remove the field from the fieldErrors and customError
                setState({
                  ...state,
                  fieldErrors: state.fieldErrors.filter(
                    (field) => field !== 'support_website'
                  ),
                  customError: {
                    ...state.customError,
                    support_website: '',
                  },
                })
              }
            }}
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            disabled={false}
            name='support_website'
            type='url'
          />
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
