import dayjs from 'dayjs'
import { UpcomingGame } from '../types/PlayerAttendance'
import { DivisionStandings } from '../types/TeamStanding'
// import { getImageThumbnailId } from '../reducers/helpers'

interface UpdateStatus {
  shouldForceUpdate: boolean
  shouldSuggestUpdate: boolean
}

const compareVersions = (v1: string, v2: string): number => {
  const v1Parts = v1.split('.').map(Number)
  const v2Parts = v2.split('.').map(Number)

  for (let i = 0; i < 3; i++) {
    if (v1Parts[i] > v2Parts[i]) {
      return 1
    }
    if (v1Parts[i] < v2Parts[i]) {
      return -1
    }
  }
  return 0
}

export const checkVersion = (
  minimumVersion: string,
  suggestedVersion: string,
  appVersion: string
): UpdateStatus => {
  // Check for invalid parameters
  if (!minimumVersion || !suggestedVersion || !appVersion) {
    return {
      shouldForceUpdate: false,
      shouldSuggestUpdate: false,
    }
  }
  // Compare appVersion with minimumVersion
  if (compareVersions(appVersion, minimumVersion) < 0) {
    return {
      shouldForceUpdate: true,
      shouldSuggestUpdate: false,
    }
  }
  // Compare appVersion with suggestedVersion
  if (compareVersions(appVersion, suggestedVersion) < 0) {
    return {
      shouldForceUpdate: false,
      shouldSuggestUpdate: true,
    }
  }
  // Otherwise
  return {
    shouldForceUpdate: false,
    shouldSuggestUpdate: false,
  }
}

export const formatGameDate = (gameDate: dayjs.Dayjs): string => {
  // const today: dayjs.Dayjs = dayjs().startOf('day')
  // const tomorrow: dayjs.Dayjs = dayjs().add(1, 'day').endOf('day')
  // const gameDay: dayjs.Dayjs = gameDate.startOf('day')
  // let prefix: string = ''
  // if (gameDay.isSame(today, 'day')) {
  //   prefix = 'TODAY - '
  // } else if (gameDay.isSame(tomorrow, 'day')) {
  //   prefix = 'TOMORROW - '
  // }
  const formattedDate: string = gameDate.format('MMM D - h:mm A').toUpperCase()
  return formattedDate
}

export function checkTeamOfficialTeamAssignment(gameData: UpcomingGame): {
  isOfficialOnHomeTeam: boolean
  isOfficialOnAwayTeam: boolean
} {
  if (!gameData) {
    return { isOfficialOnHomeTeam: false, isOfficialOnAwayTeam: false }
  }

  const homeTeamId = gameData?.home_team?.id
  const awayTeamId = gameData?.visiting_team?.id

  const allParticipants = [...(gameData?.myTeamOfficials || [])]

  const isOfficialOnHomeTeam = allParticipants.some(
    (participant) => participant.team_id === homeTeamId
  )
  const isOfficialOnAwayTeam = allParticipants.some(
    (participant) => participant.team_id === awayTeamId
  )

  return { isOfficialOnHomeTeam, isOfficialOnAwayTeam }
}

export function checkPlayerTeamAssignment(gameData: UpcomingGame): {
  isPlayerOnHomeTeam: boolean
  isPlayerOnAwayTeam: boolean
} {
  if (!gameData) {
    return { isPlayerOnHomeTeam: false, isPlayerOnAwayTeam: false }
  }

  const allParticipants = [...(gameData?.myPlayers || [])]

  // Handle case where either team may be missing
  const isPlayerOnHomeTeam = gameData?.home_team
    ? allParticipants.some(
        (participant) => participant.team_id === gameData.home_team.id
      )
    : false

  const isPlayerOnAwayTeam = gameData?.visiting_team
    ? allParticipants.some(
        (participant) => participant.team_id === gameData.visiting_team.id
      )
    : false

  return { isPlayerOnHomeTeam, isPlayerOnAwayTeam }
}

export function checkTeamAssignment(gameData: UpcomingGame): {
  isPlayingOnHomeTeam: boolean
  isPlayingOnAwayTeam: boolean
} {
  if (!gameData) {
    return { isPlayingOnHomeTeam: false, isPlayingOnAwayTeam: false }
  }

  const allParticipants = [
    ...(gameData?.myPlayers || []),
    ...(gameData?.myTeamOfficials || []),
  ]

  // If no home team, can only be playing on away team
  if (!gameData?.home_team && gameData?.visiting_team) {
    const isPlayingOnAwayTeam = allParticipants.some(
      (participant) => participant.team_id === gameData.visiting_team.id
    )
    return { isPlayingOnHomeTeam: false, isPlayingOnAwayTeam }
  }

  // If no away team, can only be playing on home team
  if (gameData?.home_team && !gameData?.visiting_team) {
    const isPlayingOnHomeTeam = allParticipants.some(
      (participant) => participant.team_id === gameData.home_team.id
    )
    return { isPlayingOnHomeTeam, isPlayingOnAwayTeam: false }
  }

  // Both teams exist, check assignment for each
  if (gameData?.home_team && gameData?.visiting_team) {
    const isPlayingOnHomeTeam = allParticipants.some(
      (participant) => participant.team_id === gameData.home_team.id
    )
    const isPlayingOnAwayTeam = allParticipants.some(
      (participant) => participant.team_id === gameData.visiting_team.id
    )
    return { isPlayingOnHomeTeam, isPlayingOnAwayTeam }
  }

  // Neither team exists
  return { isPlayingOnHomeTeam: false, isPlayingOnAwayTeam: false }
}

interface ProcessedTeamStanding {
  team_id: string
  team_name: string
  team_image: string
  rank: number
  schedule_id: string
  schedule_name: string
  schedule_root_name: string
  schedule_root_id: string
  GP: string
  W: string
  L: string
  T: string
}

export const processStandingsData = (
  standingsData: DivisionStandings[]
): ProcessedTeamStanding[] => {
  const processedData: ProcessedTeamStanding[] = []

  standingsData.forEach((division) => {
    division.teamStandings.forEach((team) => {
      if (team.is_own_team) {
        const processedTeam: ProcessedTeamStanding = {
          team_id: team.team.id,
          team_name: team.team.name_full,
          // team_image: getImageThumbnailId(team.team),
          team_image: team?.team?.image,
          rank: team.rank,
          schedule_id: team.schedule.id,
          schedule_name: team.schedule.name,
          schedule_root_name: team.schedule.root_name,
          schedule_root_id: team.schedule.root_id,
          GP: '0',
          W: '0',
          L: '0',
          T: '0',
        }

        team.stats.forEach((stat) => {
          switch (stat.abbr) {
            case 'GP':
              processedTeam.GP = stat.value
              break
            case 'W':
              processedTeam.W = stat.value
              break
            case 'L':
              processedTeam.L = stat.value
              break
            case 'T':
              processedTeam.T = stat.value
              break
          }
        })

        processedData.push(processedTeam)
      }
    })
  })

  return processedData
}

type PositionMap = {
  [key: string]: string
}

const positionMap: PositionMap = {
  GOAL: 'Goalie',
  DEF: 'Defense',
  FWD: 'Forward',
  SKT: 'Skater',
  MIDF: 'Midfielder',
  FORW: 'Forward',
  PLY: 'Player',
  PLA: 'Player',
  G: 'Goalie',
  D: 'Defense',
  F: 'Forward',
  'D/F': 'Skater',
  GK: 'Goalkeeper',
  DF: 'Defender',
  MF: 'Midfielder',
  FW: 'Forward',
  PL: 'Player',
  P: 'Player',
}

export const convertPositionAbbreviation = (abbreviation: string): string => {
  const fullName = positionMap[abbreviation.toUpperCase()]
  return fullName ? fullName : 'Player'
}

/**
 * Returns the appropriate ordinal suffix for a given number
 * @param num The number to get the ordinal suffix for
 * @returns The ordinal suffix (st, nd, rd, or th)
 */
export const getOrdinalSuffix = (num: number): string => {
  if (num <= 0) {
    return 'th'
  }

  const lastDigit = num % 10
  const lastTwoDigits = num % 100

  // Special case for 11, 12, 13
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return 'th'
  }

  switch (lastDigit) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}
