/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Dropdown, Checkbox } from 'antd'
import { FilterFilled } from '@ant-design/icons'
import { css } from '@emotion/react'
import colors from '@sportninja/common/constants/appColors'

// Registration status options from divisionTableHelper
export const registrationOptions = [
  { value: 'all', label: 'All' },
  { value: 1, label: 'Submitted' },
  { value: 2, label: 'Pending' },
  { value: 3, label: 'Approved' },
  { value: 4, label: 'Approved w/ Conditions' },
  { value: 5, label: 'Cancelled' },
]

const statusOptions = registrationOptions.filter(
  (option) => option.value !== 'all'
)

interface Props {
  selectedStatuses: number[]
  onChange: (statuses: number[]) => void
}

export const RegistrationStatusFilter: React.FC<Props> = ({
  selectedStatuses,
  onChange,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  // Set default selection to all statuses except Cancelled (5)
  useEffect(() => {
    if (selectedStatuses.length === 0) {
      onChange(
        statusOptions
          .filter((option) => option.value !== 5)
          .map((option) => option.value as number)
      )
    }
  }, [])

  const handleCheckboxChange = (value: string | number) => {
    if (value === 'all') {
      // If all is selected, select all options including Cancelled
      if (selectedStatuses.length < statusOptions.length) {
        onChange(statusOptions.map((option) => option.value as number))
      } else {
        // If all is unselected, clear all selections
        onChange([])
      }
    } else {
      const newSelectedStatuses = selectedStatuses.includes(value as number)
        ? selectedStatuses.filter((status) => status !== value)
        : [...selectedStatuses, value as number]
      onChange(newSelectedStatuses)
    }
  }

  const menu = {
    items: registrationOptions.map((option) => ({
      key: option.value,
      label: (
        <div css={checkboxContainerStyle} onClick={(e) => e.stopPropagation()}>
          <Checkbox
            checked={
              option.value === 'all'
                ? selectedStatuses.length === statusOptions.length // All options including Cancelled
                : selectedStatuses.includes(option.value as number)
            }
            onChange={() => handleCheckboxChange(option.value)}
          >
            {option.label}
          </Checkbox>
        </div>
      ),
    })),
  }

  return (
    <Dropdown
      menu={menu}
      trigger={['click']}
      placement='bottom'
      open={dropdownOpen}
      onOpenChange={setDropdownOpen}
      getPopupContainer={(triggerNode) =>
        triggerNode.parentElement || document.body
      }
    >
      <div css={filterContainerStyle}>
        <FilterFilled />
      </div>
    </Dropdown>
  )
}

const filterContainerStyle = css`
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid ${colors.SECONDARY_200};
  background: ${colors.SECONDARY_300};
  height: 36px !important;
`

const checkboxContainerStyle = css`
  padding: 8px 12px;
  min-width: 200px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`
