/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Modal } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import Text from '../ts-components/Text'
import { useCallback } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import Button from '../ts-components/Button'
import { PhoneOutlined, MailOutlined, GlobalOutlined } from '@ant-design/icons'

type ContactInfoModalProps = {
  title: string
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  contactInfo: {
    phone: string
    email: string
    website: string
    support_email: string
    support_phone: string
    support_website: string
  }
}

const styles = css`
  .my-modal-body {
  }
  .my-modal-mask {
    backdrop-filter: blur(1px);
  }
  .my-modal-header {
    border-bottom: 1px dotted ${colors.FIERY_RED};
  }
  .my-modal-footer {
    color: ${colors.FIERY_RED};
  }
  .my-modal-content {
    border: 1px solid #333;
  }
`
const classNames = {
  body: styles['my-modal-body'],
  mask: styles['my-modal-mask'],
  header: styles['my-modal-header'],
  footer: styles['my-modal-footer'],
  content: styles['my-modal-content'],
}
const modalStyles = {
  header: {
    backgroundColor: colors.SECONDARY_300,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  body: {
    borderBottom: `1px solid ${colors.SOFT_STEEL}`,
    borderTop: `1px solid ${colors.SOFT_STEEL}`,
    backgroundColor: colors.SECONDARY_300,
  },
  content: {
    backgroundColor: colors.SECONDARY_300,
    paddingTop: '8px',
    paddingBottom: '16px',
    paddingLeft: '0',
    paddingRight: '0',
  },
  wrapper: {
    zIndex: 10001,
  },
  mask: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(6, 10, 16, 0.8)',
    zIndex: 10000,
  },
  footer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

const getWebsite = (website: string) => {
  if (website.includes('http')) {
    return website
  }
  return `https://${website}`
}

const ContactInfoModal = ({
  title,
  isFormOpen,
  setIsFormOpen,
  contactInfo,
}: ContactInfoModalProps) => {
  const getFooter = useCallback(() => {
    return (
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          gap: 16px;
          width: 50%;
          margin-left: auto;
        `}
      >
        <Button
          text='Close'
          onClick={() => {
            setIsFormOpen(false)
          }}
          variant='secondary'
          disabled={false}
        />
      </div>
    )
  }, [setIsFormOpen])

  const closeIcon = useCallback(() => {
    return (
      <CloseOutlined
        css={css`
          color: ${colors.SOLITUDE};
          font-size: 16px;
        `}
      />
    )
  }, [])

  const getHeader = useCallback(() => {
    return (
      <Text variant='heading3' weight='semiBold' color={colors.WHITE}>
        {title}
      </Text>
    )
  }, [title])

  return (
    <Modal
      css={css`
        .ant-modal-wrap {
          z-index: 10001;
        }
      `}
      width={500}
      closeIcon={closeIcon()}
      title={getHeader()}
      footer={getFooter()}
      open={isFormOpen}
      onOk={() => {
        setIsFormOpen(false)
      }}
      onCancel={() => {
        setIsFormOpen(false)
      }}
      centered
      classNames={classNames}
      styles={modalStyles}
      maskClosable={false}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          padding: 16px;
          border-radius: 8px;
        `}
      >
        {contactInfo?.phone ? (
          <div
            css={css`
              display: flex;
              height: 48px;
              padding: 8px;
              align-items: center;
              gap: 8px;
              border-radius: 8px;
            `}
          >
            <PhoneOutlined
              css={css`
                color: ${colors.PRIMARY};
                font-size: 24px;
              `}
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              `}
            >
              <Text
                variant='paragraphXSmall'
                weight='regular'
                color={colors.NEUTRAL_50}
              >
                Phone
              </Text>
              <Text variant='paragraphSmall' weight='bold' color={colors.WHITE}>
                {contactInfo?.phone || 'N/A'}
              </Text>
            </div>
          </div>
        ) : null}
        {contactInfo?.email ? (
          <div
            css={css`
              display: flex;
              height: 48px;
              padding: 8px;
              align-items: center;
              gap: 8px;
              border-radius: 8px;
            `}
          >
            <MailOutlined
              css={css`
                color: ${colors.PRIMARY};
                font-size: 24px;
              `}
            />
            <a
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              `}
              href={`mailto:${contactInfo?.email}`}
              target='_blank'
              rel='noreferrer'
            >
              <Text
                variant='paragraphXSmall'
                weight='regular'
                color={colors.NEUTRAL_50}
              >
                Email
              </Text>
              <Text variant='paragraphSmall' weight='bold' color={colors.WHITE}>
                {contactInfo?.email || 'N/A'}
              </Text>
            </a>
          </div>
        ) : null}
        {contactInfo?.website ? (
          <div
            css={css`
              display: flex;
              height: 48px;
              padding: 8px;
              align-items: center;
              gap: 8px;
              border-radius: 8px;
            `}
          >
            <GlobalOutlined
              css={css`
                color: ${colors.PRIMARY};
                font-size: 24px;
              `}
            />
            <a
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              `}
              href={getWebsite(contactInfo?.website)}
              target='_blank'
              rel='noreferrer'
            >
              <Text
                variant='paragraphXSmall'
                weight='regular'
                color={colors.NEUTRAL_50}
              >
                Website
              </Text>
              <Text variant='paragraphSmall' weight='bold' color={colors.WHITE}>
                {contactInfo?.website || 'N/A'}
              </Text>
            </a>
          </div>
        ) : null}
        {(contactInfo?.phone || contactInfo?.email || contactInfo?.website) &&
        (contactInfo?.support_phone ||
          contactInfo?.support_email ||
          contactInfo?.support_website) ? (
          <div
            css={css`
              height: 1px;
              width: 100%;
              background-color: ${colors.SECONDARY_200};
            `}
          />
        ) : null}
        {contactInfo?.support_phone ? (
          <div
            css={css`
              display: flex;
              height: 48px;
              padding: 8px;
              align-items: center;
              gap: 8px;
              border-radius: 8px;
            `}
          >
            <PhoneOutlined
              css={css`
                color: ${colors.PRIMARY};
                font-size: 24px;
              `}
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              `}
            >
              <Text
                variant='paragraphXSmall'
                weight='regular'
                color={colors.NEUTRAL_50}
              >
                Support Phone
              </Text>
              <Text variant='paragraphSmall' weight='bold' color={colors.WHITE}>
                {contactInfo?.support_phone || 'N/A'}
              </Text>
            </div>
          </div>
        ) : null}
        {contactInfo?.support_email ? (
          <div
            css={css`
              display: flex;
              height: 48px;
              padding: 8px;
              align-items: center;
              gap: 8px;
              border-radius: 8px;
            `}
          >
            <MailOutlined
              css={css`
                color: ${colors.PRIMARY};
                font-size: 24px;
              `}
            />
            <a
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              `}
              href={`mailto:${contactInfo?.support_email}`}
              target='_blank'
              rel='noreferrer'
            >
              <Text
                variant='paragraphXSmall'
                weight='regular'
                color={colors.NEUTRAL_50}
              >
                Support Email
              </Text>
              <Text variant='paragraphSmall' weight='bold' color={colors.WHITE}>
                {contactInfo?.support_email || 'N/A'}
              </Text>
            </a>
          </div>
        ) : null}
        {contactInfo?.support_website ? (
          <div
            css={css`
              display: flex;
              height: 48px;
              padding: 8px;
              align-items: center;
              gap: 8px;
              border-radius: 8px;
            `}
          >
            <GlobalOutlined
              css={css`
                color: ${colors.PRIMARY};
                font-size: 24px;
              `}
            />
            <a
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              `}
              href={getWebsite(contactInfo?.support_website)}
              target='_blank'
              rel='noreferrer'
            >
              <Text
                variant='paragraphXSmall'
                weight='regular'
                color={colors.NEUTRAL_50}
              >
                Support Website
              </Text>
              <Text variant='paragraphSmall' weight='bold' color={colors.WHITE}>
                {contactInfo?.support_website || 'N/A'}
              </Text>
            </a>
          </div>
        ) : null}
      </div>
    </Modal>
  )
}

export default ContactInfoModal
