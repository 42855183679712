import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx'
import { RegistrationService } from './service'
import BannerController from '../../components/Banner/BannerController'
import Banner from '../../components/Banner'
import { createErrorMessage } from '@sportninja/common/utils/utils'

export class RegistrationStore {
  rootStore
  enableRegistrationCog = false
  enableRegistrationSetup = false
  isLoading = false
  error = null
  divisions = []
  registrationSettings = null
  registration = null
  availableTaxes = []
  analytics = null
  hasDepositPaymentAvailable = []
  hasInstallmentsPaymentAvailable = []
  scheduleOwner = null
  teamToCancel = null

  constructor(rootStore) {
    this.rootStore = rootStore
    makeObservable(this, {
      enableRegistrationCog: observable,
      divisions: observable,
      analytics: observable,
      isLoading: observable,
      error: observable,
      registrationSettings: observable,
      enableRegistrationSetup: observable,
      hasDepositPaymentAvailable: observable,
      hasInstallmentsPaymentAvailable: observable,
      scheduleOwner: observable,
      availableTaxes: observable,
      registration: observable,
      teamToCancel: observable,
      setTeamToCancel: action,
      toggleEnableRegistrationTab: action,
      updateRegistrationSettings: action,
      checkIfHasPaymentAvailable: action,
      setScheduleOwner: action,
      cleanUpPaymentData: action,
      updateTeamRegStatus: action,
      updateDivision: action,
      loadDivisions: action,
      createRegistration: action,
      getRegistrationSettings: action,
      state: computed,
    })
  }

  setScheduleOwner = (owner) => {
    runInAction(() => {
      this.scheduleOwner = owner
    })
  }

  setTeamToCancel = (team) => {
    runInAction(() => {
      this.teamToCancel = team
    })
  }

  loadDivisions = (scheduleId) => {
    runInAction(() => {
      this.isLoading = true
    })

    RegistrationService.getDivisions({ scheduleId })
      .then(({ response, analytics }) => {
        runInAction(() => {
          this.divisions = response.divisions
          this.registration = response.registration
          this.analytics = analytics
          this.availableTaxes = response.available_taxes
          this.enableRegistrationCog = response.registration ? true : null
          this.enableRegistrationSetup = true
          this.isLoading = false
        })
      })
      .catch((error) => {
        console.error(error)
        BannerController.add(({ ...props }) => (
          <Banner {...props} type='warning'>
            {'You must create a division first'}
          </Banner>
        ))
        runInAction(() => {
          this.enableRegistrationTab = false
          this.enableRegistrationSetup = false
          this.divisions = null
          this.availableTaxes = []
          this.registration = null
          this.enableRegistrationCog = null
          this.isLoading = false
        })
      })
  }

  createRegistration = ({ level = 'schedule', scheduleId, info }) => {
    runInAction(() => {
      this.isLoading = true
    })

    RegistrationService.createRegistration({ level, scheduleId, info })
      .then(() => {
        runInAction(() => {
          this.isLoading = false
          this.enableRegistrationCog = true
        })
      })
      .catch((error) => {
        console.error(error)
        runInAction(() => {
          this.isLoading = false
          // this.error = error.message
        })
      })
      .finally(() => {
        runInAction(() => {
          this.loadDivisions(this.registration?.schedule_id || scheduleId)
        })
      })
  }

  getRegistrationSettings = ({ level = 'schedule', scheduleId }) => {
    runInAction(() => {
      this.isLoading = true
    })

    RegistrationService.getRegistrationSettings({ level, scheduleId })
      .then((response) => {
        runInAction(() => {
          this.registrationSettings = response
          this.isLoading = false
        })
      })
      .catch((error) => {
        console.error(error)
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  updateRegistrationSettings = (field) => {
    runInAction(() => {
      this.registrationSettings = {
        ...this.registrationSettings,
        ...field,
      }
    })
  }

  updateDivision = ({ scheduleId, info }) => {
    RegistrationService.updateRegistration({
      level: 'division',
      scheduleId,
      info,
    })
      .then(() => {
        runInAction(() => {
          this.isLoading = false
          this.loadDivisions(this.registration.schedule_id)
        })
      })
      .catch((error) => {
        const fields = Object.keys(error?.invalid_fields)
        const message = error?.invalid_fields[fields[0]]

        BannerController.add(({ ...props }) => (
          <Banner {...props} type='failed'>
            {message}
          </Banner>
        ))

        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  updateRegistration = ({ level = 'schedule', scheduleId }) => {
    runInAction(() => {
      this.isLoading = true
    })

    RegistrationService.updateRegistration({
      level,
      scheduleId,
      info: {
        ...this.registrationSettings,
        currency: this.registrationSettings.currency.id,
        payment_type: this.registrationSettings.payment_type.id,
        payment_currency: this.registrationSettings.payment_currency.id,
      },
    })
      .then((response) => {
        runInAction(() => {
          this.registrationSettings = response
          this.isLoading = false
        })
      })
      .catch((error) => {
        console.error(error)
        runInAction(() => {
          this.isLoading = false
          // this.error = error.message
        })
      })
      .finally(() => {
        runInAction(() => {
          this.loadDivisions(scheduleId)
        })
      })
  }

  toggleEnableRegistrationTab = () => {
    runInAction(() => {
      this.enableRegistrationTab = !this.enableRegistrationTab
    })
  }

  updateTeamRegStatus = ({
    scheduleId,
    teamId,
    scheduleRegistrationId,
    status,
    conditions,
    conditionType,
    divisionId,
    teamRosterId,
  }) => {
    runInAction(() => {
      this.isLoading = true
    })

    RegistrationService.updateTeamRegStatus({
      scheduleId,
      teamId,
      scheduleRegistrationId,
      status,
      conditions,
      conditionType,
      divisionId,
      teamRosterId,
    })
      .then(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
      .catch((error) => {
        let errorMessage = 'An error happened'
        if (error?.invalid_fields) {
          errorMessage = createErrorMessage(error?.invalid_fields)
        } else {
          errorMessage = error?.message ?? 'An error happened'
        }
        BannerController.add(({ ...props }) => (
          <Banner {...props} type='failed'>
            {error?.message ?? errorMessage}
          </Banner>
        ))
        runInAction(() => {
          this.isLoading = false
          // this.error = error.message
        })
      })
      .finally(() => {
        runInAction(() => {
          this.loadDivisions(scheduleId)
        })
      })
  }

  checkIfHasPaymentAvailable = () => {
    RegistrationService.checkIfHasPaymentAvailable()
      .then((response) => {
        runInAction(() => {
          this.hasDepositPaymentAvailable = response?.deposits?.filter(
            (d) => d?.deposit_amount > 0
          )
          this.hasInstallmentsPaymentAvailable = response?.installments
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  cleanUpPaymentData = () => {
    runInAction(() => {
      this.hasDepositPaymentAvailable = []
      this.hasInstallmentsPaymentAvailable = []
    })
  }

  get state() {
    return {
      competition: this.competition,
      enableRegistrationCog: this.enableRegistrationCog,
      divisions: this.divisions,
      analytics: this.analytics,
      isLoading: this.isLoading,
      enableRegistrationSetup: this.enableRegistrationSetup,
      error: this.error,
      registrationSettings: this.registrationSettings,
      registration: this.registration,
      hasDepositPaymentAvailable: this.hasDepositPaymentAvailable,
      hasInstallmentsPaymentAvailable: this.hasInstallmentsPaymentAvailable,
      scheduleOwner: this.scheduleOwner,
      availableTaxes: this.availableTaxes,
      teamToCancel: this.teamToCancel,
    }
  }
}
