/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import sportninjaLogo from '../Login/sportninja_dark.svg'
import canlanLogo from '../../RegistrationPayment/canlan_logo.svg'
import colors from '@sportninja/common/constants/appColors'
import { useHistory, useLocation } from 'react-router-dom'
import { ROUTES } from '@sportninja/common/constants/app'
import { useCallback, useMemo, useReducer } from 'react'

import { UserSelection } from './steps/userSelection'
import { NewMember } from './steps/newMember'
import { BusinessInfo } from './steps/businessInfo'

import { stepController } from './stepsController'
import { formController, initialState } from './formController'

// interface Props {
//   register: (values: { email: string; password: string }) => Promise<void>
// }

export const SignUp = () => {
  const [formState, formDispatcher] = useReducer(formController, initialState)
  const { search } = useLocation()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const params = useMemo(() => {
    const registrationRegex = /\?isRegistration=([^&]+)&registrationId=([^&]+)/
    const registrationMatch = registrationRegex.exec(search)

    if (registrationMatch) {
      const isRegistration = registrationMatch[1]
      const registrationId = registrationMatch[2]

      return {
        name: isRegistration ? 'registration' : 'signUp',
        id: registrationId,
      }
    }
  }, [search])

  const [stepState, stepDispatcher] = useReducer(stepController, {
    step: isCanlan || params?.name === 'registration' ? 1 : 0,
    context: {
      name: params?.name || '',
      id: params?.id || '',
    },
  })

  const history = useHistory()

  const renderStep = useCallback(() => {
    switch (stepState.step) {
      case 0:
        return <UserSelection stepDispatcher={stepDispatcher} />
      case 1:
        return (
          <NewMember
            stepDispatcher={stepDispatcher}
            formDispatcher={formDispatcher}
            context={stepState.context}
          />
        )
      case 2:
        return (
          <NewMember
            stepDispatcher={stepDispatcher}
            formDispatcher={formDispatcher}
            context={stepState.context}
            form={formState}
            isBusiness
          />
        )
      case 3:
        return (
          <BusinessInfo
            stepDispatcher={stepDispatcher}
            formDispatcher={formDispatcher}
            context={stepState.context}
            form={formState}
          />
        )
      default:
        return <div />
    }
  }, [stepState.step])

  return (
    <div
      css={css`
        display: flex;
        flex: 1;
      `}
    >
      <div
        css={css`
          display: flex;
          flex: 2.5;
          overflow: hidden;
          justify-content: center;
          height: 100vh;

          @media (max-width: 768px) {
            display: none;
          }
        `}
      >
        {!isMobile ? (
          <video
            autoPlay={true}
            controls={false}
            width={window.innerWidth}
            loop
            muted
          >
            <source
              src={isCanlan ? 'video/sn-web.mp4' : 'video/sn-video-updated.mp4'}
            />
          </video>
        ) : null}
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.7);
          `}
        />
      </div>
      <div
        css={css`
          flex-direction: column;
          flex: 1;
          box-shadow: -15px 0px 18px 0px rgba(0, 0, 0, 0.75);
          padding: 40px;
          height: 100vh;
          min-width: 600px;
          overflow-y: scroll;
          z-index: 1;
          background: no-repeat fixed linear-gradient(#282e38, #181a1d);
          @media (max-width: 768px) {
            padding: 20px; // Reduce padding on smaller screens
            min-width: 0; // Remove minimum width on smaller screens
            > div {
              width: 100%;
            }
          }
        `}
      >
        {stepState.step !== 3 && (
          <div
            css={css`
              display: flex;
              justify-content: center;
              padding-top: 100px;
              padding-bottom: 50px;
            `}
          >
            <img
              src={isCanlan ? canlanLogo : sportninjaLogo}
              css={css`
                width: 20em;
              `}
            />
          </div>
        )}
        {renderStep()}
        <div
          css={css`
            width: 100%;
            height: 1px;
            background-color: ${colors.ATTENDANCE_GRAY};
            margin: 35px 0;
          `}
        />
        <div
          css={css`
            justify-content: center;
            display: flex;
          `}
        >
          <p
            css={css`
              color: ${colors.WHITE};
              font-size: 16px;
            `}
          >
            {'Already Have A Sportninja Account?'}{' '}
            <button
              css={css`
                color: ${colors.DEFAULT_FLAIR};
                font-size: 16px;
              `}
              onClick={() =>
                params?.name === 'registration'
                  ? history.push(`registration/${params.id}`)
                  : history.push(ROUTES.LOGIN)
              }
            >
              <span>Login</span>
            </button>
          </p>
        </div>
      </div>
    </div>
  )
}
