/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useCallback, useEffect, useState } from 'react'
import { App, Modal } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import useSocialFeed, { Post } from '@sportninja/common/hooks/useSocialFeed'
import colors from '@sportninja/common/constants/appColors'
import Text from '../ts-components/Text'
import FeedItemParentPicker from '../FeedParentPicker'
import { TextAreaInput } from '../ts-components/TextAreaInput'
import ImageUploader from '../ts-components/ImageUploader'
import { Organization } from '@sportninja/common/types/Organization'
import { Team } from '@sportninja/common/types/TeamStanding'
import { Schedule } from '@sportninja/common/types/Schedule'
import { TextInput } from '../ts-components/TextInput'
import Button from '../ts-components/Button'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'

const styles = css`
  .my-modal-body {
  }
  .my-modal-mask {
    backdrop-filter: blur(10px);
  }
  .my-modal-header {
    border-bottom: 1px dotted ${colors.FIERY_RED};
  }
  .my-modal-footer {
    color: ${colors.FIERY_RED};
  }
  .my-modal-content {
    border: 1px solid #333;
  }
`
const classNames = {
  body: styles['my-modal-body'],
  mask: styles['my-modal-mask'],
  header: styles['my-modal-header'],
  footer: styles['my-modal-footer'],
  content: styles['my-modal-content'],
}
const modalStyles = {
  header: {
    backgroundColor: colors.SECONDARY_300,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  body: {
    borderBottom: `1px solid ${colors.SOFT_STEEL}`,
    borderTop: `1px solid ${colors.SOFT_STEEL}`,
    backgroundColor: colors.SECONDARY_300,
    padding: '16px',
  },
  content: {
    backgroundColor: colors.SECONDARY_300,
    paddingTop: '8px',
    paddingBottom: '16px',
    paddingLeft: '0',
    paddingRight: '0',
  },
  wrapper: {
    zIndex: 10001,
  },
  mask: {
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(6, 10, 16, 0.8)',
    zIndex: 10000,
    overflow: 'hidden',
  },
  footer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

interface FeedPostCreateModalProps {
  isOpen: boolean
  onClose: () => void
  entityType: string
  entityId: string
  orgs: Organization[]
  teams: Team[]
  scheds: Schedule[]
  refreshNewFeed: (page: number) => void
  postToEdit: Post | null
  setPostToEdit: (post: Post | null) => void
}

const FeedPostCreateModal: React.FC<FeedPostCreateModalProps> = ({
  isOpen,
  onClose,
  entityType,
  entityId,
  orgs,
  teams,
  scheds,
  refreshNewFeed,
  postToEdit,
  setPostToEdit,
}) => {
  const { message } = App.useApp()
  const isHomePost = entityId === undefined
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [url, setUrl] = useState('')
  const [parentId, setParentId] = useState('')
  const [parentEntityType, setParentEntityType] = useState<
    'team' | 'schedule' | 'organization' | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState<string>('')
  const [customError, setCustomError] = useState<{ [key: string]: string }>({})
  const [fieldErrors, setFieldErrors] = useState<string[]>([])
  const [selected, setSelected] = useState<string | undefined>(undefined)
  const [selectedType, setSelectedType] = useState<string | null>(null)
  const [targetAudience, setTargetAudience] = useState<number | null>(null)
  const { createPost, updatePost } = useSocialFeed({
    id: entityId || parentId || undefined,
    type: entityType as 'team' | 'schedule' | 'organization' | undefined,
    shouldFetch: false,
  })
  const [imageChanged, setImageChanged] = useState(false)

  useEffect(() => {
    const orgEmpty = orgs.length === 0
    const teamEmpty = teams.length === 0
    const schedEmpty = scheds.length === 0
    if (!orgEmpty && !teamEmpty && !schedEmpty) {
      return
    }
    if (orgEmpty && !teamEmpty && schedEmpty) {
      setSelected(teams[0].id)
      setSelectedType('team')
      setParentId(teams[0].id)
      setParentEntityType('team')
    }
    if (orgEmpty && teamEmpty && !schedEmpty) {
      setSelected(scheds[0].id)
      setSelectedType('sched')
      setParentId(scheds[0].id)
      setParentEntityType('schedule')
    }
    if (!orgEmpty && teamEmpty && schedEmpty) {
      setSelected(orgs[0].id)
      setSelectedType('org')
      setParentId(orgs[0].id)
      setParentEntityType('organization')
    }
  }, [orgs, teams, scheds, isOpen])

  useEffect(() => {
    if (postToEdit) {
      setImage(getImageThumbnailId(postToEdit))
      setTitle(postToEdit.title)
      setContent(postToEdit.body)
      setUrl(postToEdit.url)
      setParentId(postToEdit.parent_id)
      setParentEntityType(
        postToEdit.parent_type as 'team' | 'schedule' | 'organization'
      )
      setSelected(postToEdit.parent_id)
      setSelectedType(postToEdit.parent_type)
    }
  }, [postToEdit])

  const handleSubmit = useCallback(async () => {
    try {
      console.log('handleSubmit')
      setIsLoading(true)
      let entityTypeToSubmit = parentEntityType || entityType
      if (entityTypeToSubmit === 'sched') {
        entityTypeToSubmit = 'schedule'
      }
      if (entityTypeToSubmit === 'org') {
        entityTypeToSubmit = 'organization'
      }
      if (postToEdit) {
        await updatePost(
          {
            title,
            body: content,
            image: image,
            url:
              url &&
              !url?.startsWith('http') &&
              url?.length > 0 &&
              url?.includes('.')
                ? `https://${url}`
                : url,
          },
          postToEdit.id,
          imageChanged
        )
      } else {
        await createPost(
          {
            title,
            body: content,
            image: image,
            url:
              url &&
              !url?.startsWith('http') &&
              url?.length > 0 &&
              url?.includes('.')
                ? `https://${url}`
                : url,
          },
          entityTypeToSubmit as 'team' | 'schedule' | 'organization'
        )
      }
      message.open({
        type: 'success',
        content: postToEdit
          ? 'Post updated successfully'
          : 'Post created successfully',
      })
      setImage('')
      setFieldErrors([])
      setCustomError({})
      setTitle('')
      setContent('')
      setUrl('')
      setParentId('')
      refreshNewFeed(1)
      setSelected(undefined)
      setSelectedType(null)
      setTargetAudience(null)
      setPostToEdit(null)
      onClose()
    } catch (e: any) {
      console.log(e)
      if (e?.invalid_fields) {
        console.log(e?.invalid_fields)
        if (e?.invalid_fields?.url) {
          setFieldErrors([...fieldErrors, 'website'])
          setCustomError({
            ...customError,
            website: e?.invalid_fields?.url,
          })
          return
        }
      }
      message.open({
        type: 'error',
        content: e?.message || 'Error creating post',
      })
    } finally {
      setIsLoading(false)
    }
  }, [
    parentEntityType,
    entityType,
    postToEdit,
    message,
    refreshNewFeed,
    setPostToEdit,
    onClose,
    updatePost,
    title,
    content,
    image,
    url,
    imageChanged,
    createPost,
    fieldErrors,
    customError,
  ])

  const getHeader = useCallback(() => {
    return (
      <Text
        variant='heading3'
        weight='semiBold'
        color={colors.WHITE}
        textTransform='capitalize'
      >
        {postToEdit ? 'Edit Post' : 'New Post'}
      </Text>
    )
  }, [postToEdit])

  const closeIcon = useCallback(() => {
    return (
      <CloseOutlined
        css={css`
          color: ${colors.SOLITUDE};
          font-size: 16px;
        `}
      />
    )
  }, [])

  const getFooter = useCallback(() => {
    const shouldDisableSave = isHomePost
      ? !parentEntityType || !parentId
      : !entityType || !entityId

    return (
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          gap: 16px;
          width: 50%;
          margin-left: auto;
        `}
      >
        <Button
          text='Cancel'
          onClick={() => {
            setImage('')
            setFieldErrors([])
            setCustomError({})
            setTitle('')
            setContent('')
            setUrl('')
            setSelected(undefined)
            setSelectedType(null)
            setTargetAudience(null)
            setPostToEdit(null)
            onClose()
          }}
          variant='secondary'
          disabled={isLoading}
        />
        <Button
          text='Post'
          onClick={handleSubmit}
          disabled={
            isLoading ||
            fieldErrors.length > 0 ||
            shouldDisableSave ||
            content.length === 0 ||
            title.length === 0
          }
          isLoading={isLoading}
          variant='primary'
        />
      </div>
    )
  }, [
    content.length,
    entityId,
    entityType,
    fieldErrors.length,
    handleSubmit,
    isHomePost,
    isLoading,
    onClose,
    parentEntityType,
    parentId,
    setPostToEdit,
    title.length,
  ])

  return (
    <Modal
      footer={getFooter()}
      closeIcon={closeIcon()}
      title={getHeader()}
      open={isOpen}
      classNames={classNames}
      styles={modalStyles}
      width={800}
      onCancel={() => {
        setImage('')
        setFieldErrors([])
        setCustomError({})
        setTitle('')
        setContent('')
        setUrl('')
        setParentId('')
        setSelected(undefined)
        setSelectedType(null)
        setTargetAudience(null)
        setPostToEdit(null)
        onClose()
      }}
    >
      <div
        css={css`
          display: flex;
          gap: 24px;
          @media (max-width: 768px) {
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 16px;
          `}
        >
          {isHomePost ? (
            <FeedItemParentPicker
              isOpen={isOpen}
              onChange={({ parent_id, entityType: eType }) => {
                setParentId(parent_id)
                setParentEntityType(
                  eType as 'team' | 'schedule' | 'organization'
                )
              }}
              orgs={orgs}
              teams={teams}
              scheds={scheds}
              selected={selected}
              setSelected={setSelected}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              targetAudience={targetAudience}
              setTargetAudience={setTargetAudience}
              setParentId={setParentId}
              setParentEntityType={setParentEntityType}
              disabled={isLoading || postToEdit !== null}
            />
          ) : null}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
            `}
          >
            <TextInput
              name='title'
              value={title}
              disabled={isLoading}
              onBlur={() => {
                if (title.length === 0) {
                  setCustomError({
                    ...customError,
                    title: 'Title is required',
                  })
                  setFieldErrors([...fieldErrors, 'title'])
                } else {
                  setCustomError({
                    ...customError,
                    title: '',
                  })
                  setFieldErrors(
                    fieldErrors.filter((field) => field !== 'title')
                  )
                }
              }}
              setValue={(e) => setTitle(e)}
              fieldErrors={fieldErrors}
              customError={customError}
              label='Title *'
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
            `}
          >
            <TextAreaInput
              maxLength={1000}
              name='content'
              value={content}
              setValue={(e) => setContent(e)}
              placeholder='Type here...'
              fieldErrors={fieldErrors}
              customError={customError}
              disabled={isLoading}
              label='Text *'
              onBlur={() => {
                if (content.length === 0) {
                  setCustomError({
                    ...customError,
                    content: 'Content is required',
                  })
                  setFieldErrors([...fieldErrors, 'content'])
                } else {
                  setCustomError({
                    ...customError,
                    content: '',
                  })
                  setFieldErrors(
                    fieldErrors.filter((field) => field !== 'content')
                  )
                }
              }}
            />
          </div>
        </div>

        <div
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
        >
          <ImageUploader
            setImageChanged={setImageChanged}
            setImageUrl={setImage}
            imageUrl={image}
            label='Image'
            tip=''
            messageApi={message}
          />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <TextInput
              label='Link'
              value={url}
              setValue={(value) => {
                setUrl(value)
              }}
              onBlur={() => {
                setCustomError({
                  ...customError,
                  website: '',
                })
                setFieldErrors(
                  fieldErrors.filter((field) => field !== 'website')
                )
              }}
              fieldErrors={fieldErrors}
              customError={customError}
              disabled={false}
              name='website'
              type='url'
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FeedPostCreateModal
