/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { useTimezoneManager } from '@sportninja/common/hooks/useTimezoneManager'
import { t } from '@sportninja/common/i18n'
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'src/components/Helmet'
import Icon from 'src/components/Icon'
import { ActionsWrapper } from 'src/components/PageTitle/css'
import Picture from 'src/components/Picture'
import {
  ArrowRight,
  AutoPlayButton,
  MatchDateTime,
  ScheduleLink,
} from './components'
import { imageCss } from '../GamePageHeaderMatchupCss'
import { GameStatus } from '@sportninja/common/constants/GameStatus'
import { paragraphMedium } from 'src/components/css'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const getYoutubeVideoId = (url) => {
  const youtubeUrl = new RegExp(
    '^(https?://)?(www.youtube.com|m.youtube.com|youtu.?be)/.+$'
  )
  if (!url) {
    return ''
  }
  if (!youtubeUrl.test(url)) {
    return ''
  }
  const urlObj = new URL(url)
  const hostname = urlObj.hostname
  if (hostname.includes('youtube.com') || hostname.includes('m.youtube.com')) {
    return urlObj.searchParams.get('v')
  } else if (hostname.includes('youtu.be')) {
    return urlObj.pathname.slice(1) // Remove the leading '/'
  }
  return ''
}

const NewGamePageHeader = ({
  videoUrl,
  game,
  organization,
  actions,
  fullGameType,
  isMobile,
  windowWidth,
}) => {
  const isHostedOnS3 = videoUrl?.includes('s3.amazonaws.com')
  const { homeTeam = {}, visitingTeam = {} } = game
  const homePlaceholder = homeTeam?.isPlaceholder
  const visitingPlaceholder = visitingTeam?.isPlaceholder
  const picProps = { iconName: 'user-friends', square: true, fit: true }
  const { locationDate, getDateString } = useTimezoneManager({
    date: game.startsAt,
    timezone: game?.location?.timezone,
  })
  const [autoPlay, setAutoPlay] = useState(false)
  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)
  const isHomeWinner = game.homeTeamScore > game.visitingTeamScore
  const isVisitingWinner = game.visitingTeamScore > game.homeTeamScore
  const gameResult = GameStatus[game?.gameStatus]
  let gameStatus = ''
  if (game?.completed) {
    if (game?.shootout) {
      gameStatus = 'FINAL - SO'
    } else {
      if (game?.current_period?.period_type?.is_overtime) {
        gameStatus = 'FINAL - OT'
      } else {
        gameStatus = 'FINAL'
      }
    }
  } else {
    if (game?.started) {
      gameStatus = 'LIVE'
    } else {
      gameStatus = 'SCHEDULED'
    }
  }

  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      setAutoPlay(true)
    }
    if (event.data === window.YT.PlayerState.PAUSED) {
      setAutoPlay(false)
    }
  }

  useEffect(() => {
    if (window.YT && !isHostedOnS3 && videoUrl) {
      playerRef.current = new window.YT.Player('player', {
        height: '720',
        width: windowWidth,
        playerVars: {
          autoplay: 0,
          controls: 1,
          playlist: getYoutubeVideoId(videoUrl),
          rel: 0,
        },
        videoId: videoUrl?.split('v=')[1],
        events: {
          onStateChange: onPlayerStateChange,
        },
      })
    }
  }, [isHostedOnS3, videoUrl, windowWidth])

  let title = game.gameNumber
    ? `${t('util:capitalize', { text: 'Game' })} #${game.gameNumber}`
    : game.gameNumber === null || game.gameNumber?.length === 0
    ? t('util:capitalize', { text: 'Game' })
    : `${'Loading'}...`

  useEffect(() => {
    if (videoRef.current && autoPlay) {
      videoRef.current.play()
    }
  }, [videoRef, autoPlay])

  return (
    <div
      css={css`
        width: ${windowWidth}px;
        height: 720px;
        background: 'linear-gradient(180deg, rgba(6, 10, 16, 0.3) 0%, #181d26 100%)';
        position: relative;
        margin-bottom: 16px;
        .ytp-more-videos-view {
          display: none !important;
        }
      `}
      onClick={() => {
        if (autoPlay) {
          setAutoPlay(false)
          videoRef.current?.pause()
        }
      }}
    >
      <Helmet title={title} />
      {isHostedOnS3 ? (
        <video
          ref={videoRef}
          src={videoUrl || ''}
          width={windowWidth}
          height='720'
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            border: 'none',
            background:
              'linear-gradient(180deg, rgba(6, 10, 16, 0.3) 0%, #060a10 100%)',
            // objectFit: 'cover',
          }}
          autoPlay={autoPlay}
          loop
          muted
          controls={autoPlay ? true : false}
          onPause={() => {
            setAutoPlay(false)
          }}
        />
      ) : (
        <div
          id='player'
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            width: '100%',
            height: 720,
            position: 'absolute',
            top: 0,
            left: 0,
            border: 'none',
            background:
              'linear-gradient(180deg, rgba(6, 10, 16, 0.3) 0%, #181d26 100%)',
          }}
        />
      )}
      <div
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          ${autoPlay && 'display: none;'}
          ${!isHostedOnS3 && 'opacity: 0;'}
        `}
        onClick={() => {
          setAutoPlay(!autoPlay)
          if (playerRef.current) {
            playerRef.current.playVideo()
          }
        }}
      >
        <AutoPlayButton />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          position: absolute;
          top: 16px;
          left: 16px;
          backdrop-filter: blur(50px);
          padding: 8px;
          border-radius: 8px;
          ${autoPlay && 'display: none;'}
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-left: 8px;
          `}
        >
          <Link to={ROUTES.HOME}>
            <Icon name='home' fontSize={14} color={colors.PALE_SKY} />
          </Link>
          <ArrowRight />
          <ScheduleLink
            as={Link}
            to={`${ROUTES.ORG_ROOT}/${organization?.id}`}
            isDesktop={true}
          >
            {organization && organization?.name ? (
              <Fragment>{organization.name}</Fragment>
            ) : null}
          </ScheduleLink>
          <ArrowRight />
          <ScheduleLink
            as={Link}
            isDesktop={true}
            to={`${ROUTES.SCHEDULE_ROOT}/${game?.rootSchedule?.id}`}
          >
            {game?.rootSchedule?.name_full || game?.rootSchedule?.name}
          </ScheduleLink>
          {game?.parentId !== game?.rootSchedule?.id && (
            <>
              <ArrowRight />
              <ScheduleLink
                as={Link}
                isDesktop={true}
                to={`${ROUTES.SCHEDULE_ROOT}/${game?.rootSchedule?.id}?scheduleType=${game?.parentScheduleType}&schedule_id=${game.parentId}`}
              >
                {game.parentSchedule}
              </ScheduleLink>
            </>
          )}
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            gap: 8px;
            margin-left: 8px;
            align-items: center;
            align-self: stretch;
          `}
        >
          <span
            css={css`
              ${paragraphMedium}
              ${gameResult === 'FINAL' ? colors.PRIMARY : 'color: #ffffff;'}
              color: ${colors.PRIMARY};
            `}
          >
            {gameStatus}
          </span>
          {gameResult !== 'Normal' ? (
            <>
              -<span>{gameResult}</span>
            </>
          ) : null}
        </div>
        {/* Team Info */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
          `}
        >
          {/* Home Team */}
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              align-self: stretch;
            `}
          >
            <Picture
              imageId={homeTeam?.imageId}
              css={imageCss}
              size='small'
              {...picProps}
            />
            <p
              css={css`
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                flex: 1 0 0;
                overflow: hidden;
                color: var(--Neutrals-White, #fff);
                font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
                text-overflow: ellipsis;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                font-size: 40px;
                font-weight: 600;
                line-height: 120%; /* 48px */
              `}
            >
              {homePlaceholder ? 'Home Team' : homeTeam?.fullName}
            </p>
            <div
              css={css`
                display: flex;
                padding: 4px 16px;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                background: ${isHomeWinner ? colors.PRIMARY : '#27303E'};
                width: 60px;
                justify-content: center;
              `}
            >
              <p
                css={css`
                  color: ${isHomeWinner ? '#27303E' : '#ffffff'};
                  text-align: center;
                  font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;

                  /* Heading 1/Semibold */
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 120%; /* 48px */
                `}
              >
                {game?.homeTeamScore}
              </p>
            </div>
          </div>
          {/* Visiting Team */}
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              align-self: stretch;
            `}
          >
            <Picture
              imageId={visitingTeam?.imageId}
              css={imageCss}
              size='small'
              {...picProps}
            />
            <p
              css={css`
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                flex: 1 0 0;
                overflow: hidden;
                color: var(--Neutrals-White, #fff);
                font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
                text-overflow: ellipsis;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                font-size: 40px;
                font-weight: 600;
                line-height: 120%; /* 48px */
              `}
            >
              {visitingPlaceholder ? 'Home Team' : visitingTeam?.fullName}
            </p>
            <div
              css={css`
                display: flex;
                padding: 4px 16px;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                background: ${isVisitingWinner ? colors.PRIMARY : '#27303E'};
                width: 60px;
                justify-content: center;
              `}
            >
              <p
                css={css`
                  color: ${isVisitingWinner ? '#27303E' : '#ffffff'};
                  text-align: center;
                  font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;

                  /* Heading 1/Semibold */
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 120%; /* 48px */
                `}
              >
                {game?.visitingTeamScore}
              </p>
            </div>
          </div>
        </div>
        {/* Date and Time */}

        {game.startsAt && (
          <MatchDateTime isDesktop={true}>
            {getDateString(
              locationDate,
              true ? 'h:mm a ZZZZ - cccc LLL dd/y' : 'h:mm a ZZZZ - LLL dd, y'
            )}
            {game.startsAt && fullGameType && (
              <span> • {fullGameType.name}</span>
            )}
          </MatchDateTime>
        )}
      </div>
      {/* Actions */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          position: absolute;
          top: 16px;
          right: 16px;
          backdrop-filter: blur(50px);
          padding: 8px;
          border-radius: 8px;
          ${autoPlay && 'display: none;'}
          height: 90px;
        `}
      >
        {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
      </div>
    </div>
  )
}

export default NewGamePageHeader
