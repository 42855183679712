/** @jsxImportSource @emotion/react */
import { SearchOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Input, Switch } from 'antd'
import React from 'react'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import Text from 'src/components/ts-components/Text'
import { paragraphMedium, weightRegular } from 'src/components/css'

interface Division {
  id: string
  name: string
}

interface SearchControlsProps {
  searchText: string
  setSearchText: (text: string) => void
  selectedDivision: string
  setSelectedDivision: (division: string) => void
  divisions: Division[]
  showConsole: boolean
  setShowConsole: (show: boolean) => void
}

const searchControlsStyles = {
  container: css`
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: flex-start;
    margin-bottom: 16px;
    flex-wrap: wrap;
  `,
  searchInput: css`
    width: 300px;
    color: ${colors.WHITE};
    font-size: 14px;
    font-weight: 400;
    line-height: 20.3px;
    border-radius: 8px;
    border: 1px solid ${colors.SECONDARY_100};
    background: ${colors.SECONDARY_200};
    height: 36px !important;
    @media (max-width: 768px) {
      width: 100%;
    }
    .ant-input {
      background-color: ${colors.SECONDARY_500};
      color: ${colors.WHITE};
    }
    .ant-input-prefix {
      color: ${colors.NEUTRAL_100};
    }
    &::placeholder {
      color: ${colors.NEUTRAL_100};
    }
  `,
  select: css`
    .ant-select-selector {
      background-color: ${colors.SECONDARY_500} !important;
      border: 1px solid ${colors.SECONDARY_200} !important;
      color: ${colors.WHITE} !important;
    }
    .ant-select-selection-placeholder {
      color: ${colors.NEUTRAL_100} !important;
    }
    .ant-select-arrow {
      color: ${colors.NEUTRAL_100} !important;
    }
  `,
  switchContainer: css`
    ${paragraphMedium}
    ${weightRegular}
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: ${colors.SECONDARY_300};
    border: 1px solid ${colors.SECONDARY_200};
    padding: 10px 16px;
    height: 36px !important;
  `,
}

export const SearchControls: React.FC<SearchControlsProps> = ({
  searchText,
  setSearchText,
  selectedDivision,
  setSelectedDivision,
  divisions,
  showConsole,
  setShowConsole,
}) => {
  return (
    <div css={searchControlsStyles.container}>
      <Input
        placeholder='Search by player name'
        prefix={
          <SearchOutlined
            css={css`
              font-size: 16px;
              color: ${colors.NEUTRAL_50};
            `}
          />
        }
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        allowClear={true}
        css={searchControlsStyles.searchInput}
      />
      <div>
        <SelectInput
          label=''
          value={selectedDivision}
          onChange={setSelectedDivision}
          name='division'
          disabled={false}
          fieldErrors={[]}
          customError=''
          showSearch={true}
          style='width: 200px'
          filterOption={(input, option) =>
            (option?.label?.toString() || '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={[
            { value: 'all', label: 'All Divisions' },
            ...divisions.map((d) => ({
              value: d.id,
              label: d.name,
            })),
          ]}
        />
      </div>
      <div css={searchControlsStyles.switchContainer}>
        <Text variant='paragraphSmall' color={colors.WHITE} weight='regular'>
          Display Cancelled
        </Text>
        <Switch checked={showConsole} onChange={setShowConsole} size='small' />
      </div>
    </div>
  )
}
