/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from 'react'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Input } from '../Input'
import { Control, UseFormSetValue, useForm } from 'react-hook-form'
import { Button } from '../Button'
import { Select } from 'antd'
import { CountryContext, CountryProvider } from './countryContext'

type InputFields = {
  address_line1: string
  address_line2: string
  city: string
  postcode: string
  state: string
  street: string
}

type ReturnArgs = InputFields & {
  country: string
}

interface Props {
  responsible?: boolean
  initialState?: ReturnArgs
  isModal?: boolean
  onCancel?: () => void
  onSubmit?: ({ args }: { args: ReturnArgs }) => void
  control?: Control<any, any>
  onChangeCountry?: (value: string) => void
  setFormValue?: UseFormSetValue<any>
  invalidFields?: {
    street: boolean
    city: boolean
    postcode: boolean
    state: boolean
    country: boolean
  }
  requiredFields?: {
    street: boolean
    city: boolean
    postcode: boolean
    state: boolean
    country: boolean
  }
  invalidMessages?: {
    street: string
    city: string
    postcode: string
    state: string
    country: string
  }
}

const Component = ({
  responsible,
  onCancel,
  onSubmit,
  isModal = true,
  initialState,
  onChangeCountry,
  control: propsControl,
  invalidFields,
  requiredFields,
  invalidMessages,
}: Props) => {
  const [value, setValue] = useState(initialState?.street || '')
  const [country, setCountry] = useState(initialState?.country || 'Select')
  const [fieldErrors, setFieldErrors] = useState<string[]>([])

  const countryList = useContext(CountryContext)

  const {
    control,
    setValue: setValueInternally,
    handleSubmit,
  } = useForm<InputFields>({
    defaultValues: {
      street: initialState?.street || '',
      city: initialState?.city || '',
      postcode: initialState?.postcode || '',
      state: initialState?.state || '',
    },
  })

  const onSubmitPressed = (data: InputFields) => {
    setFieldErrors([])

    if (!country || country === '') {
      setFieldErrors((prev) => [...prev, 'country'])
      return
    }

    const args: ReturnArgs = {
      ...data,
      country,
    }

    onSubmit &&
      onSubmit({
        args,
      })
  }

  const onError = (errors: any) => {
    setFieldErrors(Object.keys(errors))

    if (!country || country === 'Select') {
      setFieldErrors((prev) => [...prev, 'country'])
    }
  }

  const onCancelPressed = () => {
    if (value && !initialState) {
      setValue('')
      setCountry('select')
      // setFormValue('address_line1', '')
      // setFormValue('address_line2', '')
      setValueInternally('city', '')
      setValueInternally('postcode', '')
      setValueInternally('state', '')
    } else {
      onCancel && onCancel()
    }
  }

  // const onPlaceSelect = useCallback(
  //   (result: { properties: AmenityProperties }) => {
  //     console.log('RES', result)
  //     setValue(result?.properties?.name)
  //     setCountry(result?.properties?.country)
  //     onChangeValue && onChangeValue(result?.properties?.name)

  //     if (!isModal) {
  //       setFormValue!('city', result?.properties?.city)
  //       setFormValue!('postcode', result?.properties?.postcode)
  //       setFormValue!('state', result?.properties?.state)
  //     } else {
  //       setValueInternally('city', result?.properties?.city)
  //       setValueInternally('postcode', result?.properties?.postcode)
  //       setValueInternally('state', result?.properties?.state)
  //     }

  //     setStateCode(result?.properties?.state_code)
  //     setTimezone(result?.properties?.timezone?.name)
  //     setLatLong([result?.properties?.lat, result?.properties?.lon])
  //   },
  //   [value, country, isModal, setFormValue, onChangeValue]
  // )

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  useEffect(() => {
    const input = document.querySelector('.geoapify-autocomplete-input')
    if (input) {
      input?.setAttribute('tabindex', '1')
    }
  }, [])

  const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    if (isModal) {
      return (
        <form
          onSubmit={handleSubmit(onSubmitPressed, onError)}
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
          `}
        >
          {children}
        </form>
      )
    }

    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
        `}
      >
        {children}
      </div>
    )
  }

  return (
    <Wrapper>
      <div
        css={css`
          display: flex;
          flex: 1;
          flex-direction: column;

          ${responsible &&
          `
            @media (max-width: 768px) {
              flex-direction: column;
              
              > div {
              width: 100%;
              }
            }
          `}
        `}
      >
        <div
          css={css`
            margin-bottom: 16px;
          `}
        >
          <Input
            fieldName='street'
            // required={requiredFields?.street}
            invalid={invalidFields?.street || fieldErrors.includes('street')}
            invalidMessage={
              invalidMessages?.street || 'This field is mandatory'
            }
            control={propsControl || control}
            label={`Street ${requiredFields?.street ? '*' : ''}`}
            autoComplete='street-address'
          />
          {/* <label>
              <p
                css={css`
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 12px;
                  color: ${colors.WHITE};
                  margin-bottom: 8px;
                `}
              >
                Street *
              </p>
            </label>
            <div
              css={css`
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: space-between;
                max-height: 40px;
                transition: all 0.1s ease-in-out;
                margin-bottom: 16px;

                .geocoder-container {
                  flex: 1;
                  width: 100%;
                  display: flex;
                }

                .geoapify-autocomplete-input {
                  box-sizing: border-box;
                  margin: 0;
                  padding: 4px 11px px;
                  color: rgba(255, 255, 255, 0.85);
                  font-size: 14px;
                  line-height: 1.5714285714285714;
                  list-style: none;
                  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
                    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
                    'Noto Color Emoji';
                  position: relative;
                  display: inline-block;
                  width: 100%;
                  min-width: 0;
                  background-color: transparent;
                  background-image: none;
                  border-width: 1px;
                  border-style: solid;
                  border-color: #3f4753;
                  border-radius: 4px;
                  transition: all 0.2s;
                  height: 40px;
                }

                .geoapify-autocomplete-input:focus-within {
                  border-color: ${colors.PRIMARY_LIGHT};
                }

                .geoapify-autocomplete-input:hover {
                  border-color: ${colors.PRIMARY_LIGHT};
                }

                .geoapify-close-button {
                  display: none;
                }

                .geoapify-autocomplete-item {
                  gap: 6px;
                }

                .geoapify-autocomplete-items {
                  box-shadow: none;
                }
              `}
            >
              <GeoapifyGeocoderAutocomplete
                placeholder='Enter the street address'
                value={value}
                type='street'
                lang='en'
                placeSelect={onPlaceSelect}
              />
            </div> */}
        </div>
        {/* <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              margin-bottom: 8px;
            `}
          >
            <Input
              fieldName='address_line1'
              control={control}
              label='Address Line 1'
            />
            <Input
              fieldName='address_line2'
              control={control}
              label='Address Line 2'
            />
          </div> */}
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 8px;

            ${responsible &&
            `
            @media (max-width: 768px) {
              flex-direction: column;
              
              > div {
              width: 100%;
              }
            }
          `}
          `}
        >
          <Input
            fieldName='city'
            control={propsControl || control}
            // required={requiredFields?.city}
            invalid={invalidFields?.city}
            label={`City ${requiredFields?.city ? '*' : ''}`}
            invalidMessage={invalidMessages?.city || 'This field is mandatory'}
            autoComplete='address-level2'
          />
          <Input
            fieldName='postcode'
            control={propsControl || control}
            label={`Postal Code ${requiredFields?.postcode ? '*' : ''}`}
            // required={requiredFields?.postcode}
            invalid={
              invalidFields?.postcode || fieldErrors.includes('postcode')
            }
            invalidMessage={
              invalidMessages?.postcode || 'This field is mandatory'
            }
            autoComplete='postal-code'
          />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 8px;

            ${responsible &&
            `
            @media (max-width: 768px) {
              flex-direction: column;
              
              > div {
              width: 100%;
              }
            }
          `}
          `}
        >
          <div
            css={css`
              flex: 1;
              position: relative;
            `}
          >
            <p
              tabIndex={-1}
              css={css`
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                color: ${colors.WHITE};
                margin-bottom: 9px;
              `}
            >
              Country {requiredFields?.country && '*'}
            </p>
            <input
              type='text'
              name='country'
              id='country'
              autoComplete='country'
              value={country}
              onChange={(e) => {
                try {
                  const value = e.target.value
                  const option = countryList?.find(
                    (item) => item.name.toLowerCase() === value.toLowerCase()
                  )
                  if (option) {
                    onChangeCountry?.(option.name)
                    setCountry(option.name)
                  }
                } catch (error) {
                  console.error(
                    'Error setting country using autocomplete',
                    error
                  )
                }
              }}
              css={css`
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                z-index: -1;
                background: transparent;
                border: none;
              `}
            />
            <Select
              defaultValue={country}
              tabIndex={1}
              showSearch
              value={country}
              optionFilterProp='children'
              onChange={(value) => {
                onChangeCountry && onChangeCountry(value)
                setCountry(value)
              }}
              filterOption={filterOption}
              css={css`
                width: 100%;
              `}
              options={countryList.map((item) => {
                return {
                  label: item.name,
                  value: item.name,
                }
              })}
              status={invalidFields?.country ? 'error' : undefined}
            />
            <div
              tabIndex={-1}
              css={css`
                margin-top: 8px;
                transition: opacity 0.1s ease-in-out;
                opacity: ${invalidFields?.country ||
                fieldErrors.includes('country')
                  ? 1
                  : 0};
                font-size: 13px;
                line-height: 16px;
                color: ${colors.ERROR_LIGHT};
              `}
            >
              <span>
                {invalidMessages?.country || 'This field is mandatory'}
              </span>
            </div>
          </div>
          <Input
            fieldName='state'
            // required={requiredFields?.state}
            invalid={invalidFields?.state || fieldErrors.includes('state')}
            invalidMessage={invalidMessages?.state || 'This field is mandatory'}
            control={propsControl || control}
            label={`Province/State ${requiredFields?.postcode ? '*' : ''}`}
            autoComplete='address-level1'
          />
        </div>
      </div>
      {isModal && (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
            margin-top: 20px;
          `}
        >
          <Button
            onClick={(e) => {
              e.preventDefault()

              onCancelPressed()
            }}
            label={value && !initialState ? 'Clear' : 'Cancel'}
            variant='secondary'
          />
          <Button
            onClick={handleSubmit(onSubmitPressed, onError)}
            type='submit'
            label='Submit'
          />
        </div>
      )}
    </Wrapper>
  )
}

export const NewUserAddressSelector = ({
  responsible,
  onCancel,
  onSubmit,
  onChangeCountry,
  isModal = true,
  initialState,
  setFormValue,
  control,
  invalidFields,
  requiredFields,
  invalidMessages,
}: Props) => {
  return (
    <CountryProvider>
      <Component
        responsible={responsible}
        onCancel={onCancel}
        onSubmit={onSubmit}
        onChangeCountry={onChangeCountry}
        isModal={isModal}
        initialState={initialState}
        setFormValue={setFormValue}
        control={control}
        invalidFields={invalidFields}
        requiredFields={requiredFields}
        invalidMessages={invalidMessages}
      />
    </CountryProvider>
  )
}
