/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Text from 'src/components/ts-components/Text'
import Button from 'src/components/ts-components/Button'
import { TextInput } from 'src/components/ts-components/TextInput'
import { DateOfBirthInput } from 'src/components/ts-components/DateOfBirthInput'

/**
 * Props for the PlayerStep component
 * @typedef {Object} PlayerStepProps
 * @property {Object} data - The invitation data
 * @property {Function} onNext - Function to call when proceeding to next step
 * @property {Array<string>} fieldErrors - Array of field names with errors
 * @property {Object} customError - Custom error messages by field name
 * @property {string} playerName - Player's first name
 * @property {string} playerLastName - Player's last name
 * @property {string} playerDOB - Player's date of birth
 * @property {string} playerHeightFT - Player's height in feet
 * @property {string} playerHeightIN - Player's height in inches
 * @property {string} playerWeight - Player's weight
 * @property {Function} setPlayerName - Function to set player's first name
 * @property {Function} setPlayerLastName - Function to set player's last name
 * @property {Function} setPlayerDOB - Function to set player's date of birth
 * @property {Function} setPlayerHeightFT - Function to set player's height in feet
 * @property {Function} setPlayerHeightIN - Function to set player's height in inches
 * @property {Function} setPlayerWeight - Function to set player's weight
 * @property {Function} validateForm - Function to validate the form
 * @property {Function} onBack - Function to go back to previous step
 */
type PlayerStepProps = {
  data: any
  onNext: () => void
  fieldErrors?: string[]
  customError?: Record<string, string>
  playerName: string
  playerLastName: string
  playerDOB: string
  playerHeightFT: string
  playerHeightIN: string
  playerWeight: string
  setPlayerName: (value: string) => void
  setPlayerLastName: (value: string) => void
  setPlayerDOB: (value: string) => void
  setPlayerHeightFT: (value: string) => void
  setPlayerHeightIN: (value: string) => void
  setPlayerWeight: (value: string) => void
  validateForm: () => Promise<boolean>
  onBack: () => void
  stepState: number[]
}

/**
 * PlayerStep component for the underage invitation flow
 * Allows user to enter or confirm player information
 *
 * @param {PlayerStepProps} props - Component props
 * @returns {JSX.Element} The rendered component
 */
const PlayerStep = ({
  data,
  onNext,
  fieldErrors = [],
  customError = {},
  playerName,
  playerLastName,
  playerDOB,
  playerHeightFT,
  playerHeightIN,
  playerWeight,
  setPlayerName,
  setPlayerLastName,
  setPlayerDOB,
  setPlayerHeightFT,
  setPlayerHeightIN,
  setPlayerWeight,
  onBack,
  stepState,
}: PlayerStepProps) => {
  return (
    <div
      css={css`
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
      `}
    >
      <Text variant='displaySmall' weight='bold' color={colors.NEUTRAL_0}>
        WELCOME TO SPORTNINJA
      </Text>
      <Text variant='paragraphMedium' weight='regular' color={colors.NEUTRAL_0}>
        {"Let's Review "}
        <span
          css={css`
            font-weight: 700;
          `}
        >
          {data?.name_first}
        </span>
        {" To Confirm It's Accuracy"}
      </Text>
      <form
        css={css`
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 8px;
            @media (max-width: 768px) {
              flex-direction: column;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
              width: 50%;
              @media (max-width: 768px) {
                width: 100%;
              }
            `}
          >
            <TextInput
              name='player.name_first'
              customError={customError}
              fieldErrors={fieldErrors}
              label='First Name *'
              setValue={(value) => setPlayerName(value)}
              value={playerName}
              placeholder='Player First Name'
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
              width: 50%;
              @media (max-width: 768px) {
                width: 100%;
              }
            `}
          >
            <TextInput
              name='player.name_last'
              customError={customError}
              fieldErrors={fieldErrors}
              label='Last Name *'
              setValue={(value) => setPlayerLastName(value)}
              value={playerLastName}
              placeholder='Player Last Name'
            />
          </div>
        </div>
        <div
          css={css`
            margin-top: 16px;
          `}
        >
          <DateOfBirthInput
            name='player.birth_date'
            value={playerDOB}
            setValue={(value) => setPlayerDOB(value)}
            label='Birth Date *'
            fieldErrors={fieldErrors}
            customError={customError}
          />
        </div>
        <div
          css={css`
            margin-top: 16px;
            display: flex;
            gap: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 8px;
              align-items: flex-end;
              flex: 1;
              width: 100%;
              @media (max-width: 768px) {
                width: 100%;
                flex-direction: column;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 172.5px;
                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              <TextInput
                name='player.height'
                customError={customError}
                fieldErrors={fieldErrors}
                label='Height'
                setValue={(value) => setPlayerHeightFT(value)}
                value={playerHeightFT}
                placeholder='Height'
                type='number'
                min={0}
                addonAfter='ft'
                style={css`
                  max-width: 100%;
                `}
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 172.5px;
                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              <TextInput
                name='player.height_in'
                customError={customError}
                fieldErrors={fieldErrors}
                label='Height'
                setValue={(value) => setPlayerHeightIN(value)}
                value={playerHeightIN}
                placeholder='Height'
                type='number'
                min={0}
                addonAfter='in'
                style={css`
                  max-width: 100%;
                `}
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 198.5px;
                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              <TextInput
                name='player.weight'
                customError={customError}
                fieldErrors={fieldErrors}
                label='Weight'
                setValue={(value) => setPlayerWeight(value)}
                value={playerWeight}
                placeholder='Weight'
                type='number'
                min={0}
                addonAfter='lbs'
                style={css`
                  max-width: 100%;
                `}
              />
            </div>
          </div>
        </div>
      </form>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          align-self: flex-end;
          gap: 16px;
          width: 100%;
          @media (max-width: 768px) {
            width: 100%;
            flex-direction: column-reverse;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            width: 50%;
            align-self: flex-end;
            @media (max-width: 768px) {
              width: 100%;
            }
          `}
        >
          {stepState.length > 0 && (
            <Button text='Back' variant='secondary' onClick={onBack} />
          )}
        </div>
        <div
          css={css`
            display: flex;
            width: 50%;
            align-self: flex-end;
            @media (max-width: 768px) {
              width: 100%;
            }
          `}
        >
          <Button text='Next' onClick={onNext} variant='primary' />
        </div>
      </div>
    </div>
  )
}

export default PlayerStep
