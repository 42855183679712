/** @jsxImportSource @emotion/react */
import {
  CheckOutlined,
  UserOutlined,
  LogoutOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  TagOutlined,
  SwapOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { formatCurrency, getErrorMessage } from '@sportninja/common/utils/utils'
import { Alert, App, Image, Input, Select, Tooltip } from 'antd'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { EventCard } from 'src/components/RegistrationEventCard'
import { EventProgress } from 'src/components/RegistrationEventProgress'
import { RegistrationContext } from '../context/RegistrationContext'
import { PageTitle, REGISTRATION_STEPS, paragraphSmallStyle } from './Main'
import Text from 'src/components/ts-components/Text'
import TeamOfficialForm from '../TeamOfficialForm'
import PlayerForm from '../PlayerForm'
import { checkIfUserIsVerified } from './New_ConfirmYourEmail'
import { registerError } from 'src/utils/sentry'
import Button from 'src/components/ts-components/Button'
import { paragraphSmall } from 'src/components/css'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { useTypes } from '@sportninja/common/hooks/useTypes'

const initialValues = {}

// type can be percentage or amount
export const calculateDiscountAndFormatCurrency = (
  discount,
  type,
  cost,
  currency
) => {
  if (discount === null || discount === undefined) {
    return formatCurrency(cost, currency)
  }
  if (type === 'percentage') {
    return formatCurrency(cost - (cost * discount) / 100, currency)
  }
  return formatCurrency(cost - discount, currency)
}

export const calculateDiscount = (discount, type, cost) => {
  if (discount === null || discount === undefined) {
    return 0
  }
  if (type === 'percentage') {
    return (cost * discount) / 100
  }
  return discount
}

const getTeamDropdownOptions = (
  userTeams,
  isTeamRep,
  showExistingRegistrationsPlayers = true
) => {
  const buckets = userTeams?.filter((team) => team.is_bucket) || []
  const teams = userTeams?.filter((team) => !team.is_bucket) || []
  const options = []

  // Only show buckets section if not team rep and buckets exist
  if (!isTeamRep && buckets.length > 0) {
    options.push({
      label: (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <Text
            variant='paragraphSmall'
            weight='semiBold'
            color={colors.PRIMARY}
            style='text-transform: uppercase;'
          >
            Unassigned
          </Text>
          <Tooltip title="An Unassigned Group Is A Temporary Team Used To Group Players Who Don't Have A Team Yet. Players In Unassigned Groups Will Be Drafted Or Assigned To Teams Later.">
            <InfoCircleOutlined
              css={css`
                color: ${colors.PRIMARY};
                font-size: 16px;
                margin-left: 8px;
              `}
            />
          </Tooltip>
        </div>
      ),
      title: 'unassigned groups',
      options: buckets.map((el) => ({
        label: (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 12px;
            `}
          >
            {/* hidden span with the name of the team */}
            <span style={{ display: 'none' }}>{el.name}</span>
            <img
              src={'/images/bucket_image.png'}
              width={24}
              height={24}
              preview={undefined}
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <Text
                variant='paragraphSmall'
                color={colors.WHITE}
                weight='regular'
              >
                {el.name}
              </Text>
              {showExistingRegistrationsPlayers ? (
                <Text
                  variant='captionXSmall'
                  color={colors.NEUTRAL_50}
                  weight='regular'
                >
                  {el.player_count}
                  {el.max_players_per_bucket
                    ? `/${el.max_players_per_bucket}`
                    : ''}{' '}
                  players
                </Text>
              ) : null}
            </div>
          </div>
        ),
        value: el?.team_player_roster_id || el.uid || el.id,
      })),
    })
  }

  if (isTeamRep && teams.length === 0) {
    return [
      {
        label: (
          <Text
            variant='paragraphMedium'
            color={isCanlan ? colors.NEUTRAL_0 : colors.PRIMARY}
            weight='medium'
          >
            Create New Team
          </Text>
        ),
        value: 'create_new_team',
      },
    ]
  }

  // Only show teams section if there are teams or is team rep
  if (teams.length > 0 || isTeamRep) {
    options.push({
      label: (
        <>
          {teams?.length > 0 ? (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
              `}
            >
              <Text
                variant='paragraphSmall'
                weight='semiBold'
                color={colors.PRIMARY}
                style='text-transform: uppercase;'
              >
                Teams
              </Text>
            </div>
          ) : (
            <div
              css={css`
                display: none;
                .ant-select-item ant-select-item-group {
                  display: none;
                }
              `}
            />
          )}
        </>
      ),
      title: 'teams',
      options: [
        ...(teams.length > 0
          ? teams.map((el) => ({
              label: (
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 12px;
                  `}
                >
                  {/* hidden span with the name of the team */}
                  <span style={{ display: 'none' }}>{el.name}</span>
                  <img
                    src={
                      getImageThumbnailId(el) ||
                      '/images/generic_placeholders/placeholder_team.png'
                    }
                    width={24}
                    height={24}
                    preview={undefined}
                    onError={(e) => {
                      e.target.onerror = null
                      e.target.src =
                        '/images/generic_placeholders/placeholder_team.png'
                    }}
                  />
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    <Text
                      variant='paragraphSmall'
                      color={colors.WHITE}
                      weight='regular'
                    >
                      {el.name}
                    </Text>
                    {!isTeamRep && showExistingRegistrationsPlayers ? (
                      <Text
                        variant='captionXSmall'
                        color={
                          el.max_players_per_team &&
                          el.player_count >= el.max_players_per_team
                            ? colors.ERROR_200
                            : colors.NEUTRAL_50
                        }
                        weight='regular'
                      >
                        {el.player_count}
                        {el.max_players_per_team
                          ? `/${el.max_players_per_team}`
                          : ''}{' '}
                        players
                        {el.max_players_per_team &&
                        el.player_count >= el.max_players_per_team
                          ? ' - Team is full'
                          : ''}
                      </Text>
                    ) : null}
                  </div>
                </div>
              ),
              value: el?.team_player_roster_id || el.uid || el.id,
              disabled:
                el.max_players_per_team &&
                el.player_count >= el.max_players_per_team,
            }))
          : []),
        ...(isTeamRep
          ? [
              {
                label: (
                  <Text
                    variant='paragraphMedium'
                    color={isCanlan ? colors.NEUTRAL_0 : colors.PRIMARY}
                    weight='medium'
                  >
                    Create New Team
                  </Text>
                ),
                value: 'create_new_team',
              },
            ]
          : []),
      ],
    })
  }

  return options
}

const TeamRegister = () => {
  const {
    userId,
    session,
    updateSessionPayment,
    updateSessionTeamRegister,
    updateSessionPlayerRegister,
    registration,
    setStep,
    division,
    selectedTeam,
    setSelectedTeam,
    userTeams,
    countries,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    birthDate,
    setBirthDate,
    country,
    setCountry,
    zipCode,
    setZipCode,
    promoCode,
    setPromoCode,
    additionalTeamOfficials,
    setAdditionalTeamOfficials,
    couponInfo,
    setCouponInfo,
    deleteSession,
    resetSession,
    currency,
    role,
    teamOfficialType,
    teamPhone,
    setTeamPhone,
    teamEmail,
    setTeamEmail,
    teamWebsite,
    setTeamWebsite,
    teamNotes,
    setTeamNotes,
    stateProvince,
    setStateProvince,
    updateSessionConfirmSubmission,
    updateSessionWaiver,
    isTeamRep,
    refetchUserTeams,
  } = useContext(RegistrationContext)

  console.log('selectedTeam', selectedTeam)

  const showExistingRegistrationsPlayers =
    division?.show_existing_registrations_players
  const { types, fetchTypes } = useTypes()

  const positions = useMemo(() => {
    // First, lets find the type for the sport id
    const sportType = types?.find(
      (type) => type.sport_id === registration?.sport_id
    )
    // Return empty if no sport type is found
    if (!sportType) {
      return []
    }
    const playerTypes = sportType?.data?.find(
      (type) => type.player_types !== undefined
    )
    return playerTypes?.player_types
  }, [types, registration?.sport_id])

  useEffect(() => {
    if (!types || types?.length === 0) {
      fetchTypes()
    }
  }, [fetchTypes, types])

  useEffect(() => {
    refetchUserTeams()
  }, [refetchUserTeams])

  const showWaiver = registration?.registration?.show_waiver

  const divToScrollWhenOpenScreenRef = useRef(null)
  const { message } = App.useApp()
  const currency_id = currency?.id
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showPromoCode, setShowPromoCode] = useState(!!couponInfo?.id)
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(
    couponInfo?.id ? true : false
  )
  const [promoCodeError, setPromoCodeError] = useState(null)
  const [isAddingNewTeam, setIsAddingNewTeam] = useState(false)
  const [isAddingTeamOfficial, setIsAddingTeamOfficial] = useState(false)
  const [newTeamOfficial, setNewTeamOfficial] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthDateDay: '',
    birthDateMonth: '',
    birthDateYear: '',
  })
  const [newTeamName, setNewTeamName] = useState('')
  const teamImage = selectedTeam ? getImageThumbnailId(selectedTeam) : null
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  const [fieldErrors, setFieldErrors] = useState([])
  const [customError, setCustomError] = useState(initialValues)

  const teamCost = useMemo(() => {
    return calculateDiscountAndFormatCurrency(
      couponInfo?.discount,
      couponInfo?.type || couponInfo?.discount_type,
      isTeamRep ? division?.team_cost : division?.player_cost,
      currency?.name
    )
  }, [
    couponInfo?.discount,
    couponInfo?.type,
    couponInfo?.discount_type,
    isTeamRep,
    division?.team_cost,
    division?.player_cost,
    currency?.name,
  ])

  const isOneHundrerPercentCoupon = useMemo(() => {
    // Check if it's a 100% coupon or if the discount makes the cost zero
    const baseCost = isTeamRep ? division?.team_cost : division?.player_cost
    if (couponInfo?.discount === 100 && couponInfo?.type === 'percentage') {
      return true
    }
    if (couponInfo?.discount && baseCost) {
      return couponInfo?.discount >= baseCost
    }
    return false
  }, [
    couponInfo?.discount,
    couponInfo?.type,
    isTeamRep,
    division?.team_cost,
    division?.player_cost,
  ])

  const deposit = useMemo(() => {
    return calculateDiscountAndFormatCurrency(
      // SN-4962 - Discounts are not applied to deposit anymore. Changes made on ticket SN-4962
      // couponInfo?.discount,
      0,
      couponInfo?.type || couponInfo?.discount_type,
      isTeamRep ? division?.deposit : division?.player_deposit,
      currency?.name
    )
  }, [
    couponInfo?.type,
    couponInfo?.discount_type,
    isTeamRep,
    division?.deposit,
    division?.player_deposit,
    currency?.name,
  ])

  const setCustomErrors = (invalid_fields) => {
    if (invalid_fields['player_array.0.emergency_phone_number']) {
      setCustomErroByField(
        'emergencyPhone',
        invalid_fields['player_array.0.emergency_phone_number']
      )
    }
    if (invalid_fields.postal_code) {
      setCustomErroByField('zipCode', invalid_fields.postal_code)
    }
    if (invalid_fields['player_array.0.medical_conditions']) {
      setCustomErroByField(
        'medicalConditions',
        invalid_fields['player_array.0.medical_conditions']
      )
    }
    if (invalid_fields['player_array.0.preferred_position']) {
      setCustomErroByField(
        'preferredPosition',
        invalid_fields['player_array.0.preferred_position']
      )
    }
    if (invalid_fields['player_array.0.emergency_email']) {
      setCustomErroByField(
        'emergencyEmail',
        invalid_fields['player_array.0.emergency_email']
      )
    }
    if (invalid_fields['player_array.0.city']) {
      setCustomErroByField('city', invalid_fields['player_array.0.city'])
    }
    if (invalid_fields['player_array.0.name_first']) {
      setCustomErroByField(
        'firstName',
        invalid_fields['player_array.0.name_first']
      )
    }
    if (invalid_fields['player_array.0.name_last']) {
      setCustomErroByField(
        'lastName',
        invalid_fields['player_array.0.name_last']
      )
    }
    if (invalid_fields['player_array.0.address']) {
      setCustomErroByField('address', invalid_fields['player_array.0.address'])
    }
    if (invalid_fields['player_array.0.city']) {
      setCustomErroByField('city', invalid_fields['player_array.0.city'])
    }
    if (invalid_fields['player_array.0.country']) {
      setCustomErroByField('country', invalid_fields['player_array.0.country'])
    }
    if (invalid_fields['player_array.0.parent_first_name']) {
      setCustomErroByField(
        'parentFirstName',
        invalid_fields['player_array.0.parent_first_name']
      )
    }
    if (invalid_fields['player_array.0.parent_last_name']) {
      setCustomErroByField(
        'parentLastName',
        invalid_fields['player_array.0.parent_last_name']
      )
    }
    if (invalid_fields['player_array.0.parent_email']) {
      setCustomErroByField(
        'parentEmail',
        invalid_fields['player_array.0.parent_email']
      )
    }
    if (invalid_fields['player_array.0.parent_phone_number']) {
      setCustomErroByField(
        'parentPhone',
        invalid_fields['player_array.0.parent_phone_number']
      )
    }
    if (invalid_fields['player_array.0.emergency_first_name']) {
      setCustomErroByField(
        'emergencyFirstName',
        invalid_fields['player_array.0.emergency_first_name']
      )
    }
    if (invalid_fields['player_array.0.emergency_last_name']) {
      setCustomErroByField(
        'emergencyLastName',
        invalid_fields['player_array.0.emergency_last_name']
      )
    }
    if (invalid_fields['player_array.0.emergency_email']) {
      setCustomErroByField(
        'emergencyEmail',
        invalid_fields['player_array.0.emergency_email']
      )
    }
    if (invalid_fields['player_array.0.emergency_phone_number']) {
      setCustomErroByField(
        'emergencyPhone',
        invalid_fields['player_array.0.emergency_phone_number']
      )
    }
    if (invalid_fields['player_array.0.emergency_relationship']) {
      setCustomErroByField(
        'parentRelationship',
        invalid_fields['player_array.0.emergency_relationship']
      )
    }
    if (invalid_fields['player_array.0.doctor_name']) {
      setCustomErroByField(
        'doctorName',
        invalid_fields['player_array.0.doctor_name']
      )
    }
    if (invalid_fields['player_array.0.doctor_phone_number']) {
      setCustomErroByField(
        'doctorPhone',
        invalid_fields['player_array.0.doctor_phone_number']
      )
    }
    if (invalid_fields['player_array.0.known_allergies']) {
      setCustomErroByField(
        'allergies',
        invalid_fields['player_array.0.known_allergies']
      )
    }
    if (invalid_fields['player_array.0.preferred_jersey_number']) {
      setCustomErroByField(
        'jerseyNumber',
        invalid_fields['player_array.0.preferred_jersey_number']
      )
    }
    if (invalid_fields['player_array.0.preferred_dominant_side']) {
      setCustomErroByField(
        'dominantSide',
        invalid_fields['player_array.0.preferred_dominant_side']
      )
    }
    if (invalid_fields['player_array.0.preferred_jersey_size']) {
      setCustomErroByField(
        'jerseySize',
        invalid_fields['player_array.0.preferred_jersey_size']
      )
    }
    if (invalid_fields['player_array.0.preferred_tshirt_size']) {
      setCustomErroByField(
        'tshirtSize',
        invalid_fields['player_array.0.preferred_tshirt_size']
      )
    }
    if (invalid_fields['player_array.0.preferred_short_size']) {
      setCustomErroByField(
        'shortSize',
        invalid_fields['player_array.0.preferred_short_size']
      )
    }
    if (invalid_fields.team_name) {
      setCustomErroByField('team_name', invalid_fields.team_name)
    }
    if (invalid_fields.team_id) {
      setCustomErroByField('team_id', invalid_fields.team_id)
    }
    if (invalid_fields.country_code) {
      setCustomErroByField('country_code', invalid_fields.country_code)
    }
    if (invalid_fields.postal_code) {
      setCustomErroByField('postal_code', invalid_fields.postal_code)
    }
    if (invalid_fields.officials) {
      setCustomErroByField('officials', invalid_fields.officials)
    }
    if (invalid_fields.additional_team_official_first_name) {
      setCustomErroByField(
        'additionalTeamOfficialsFirstName',
        invalid_fields.additional_team_official_first_name
      )
    }
    if (invalid_fields.additional_team_official_last_name) {
      setCustomErroByField(
        'additionalTeamOfficialsLastName',
        invalid_fields.additional_team_official_last_name
      )
    }
    if (invalid_fields.additional_team_official_email) {
      setCustomErroByField(
        'additionalTeamOfficialsEmail',
        invalid_fields.additional_team_official_email
      )
    }
    if (invalid_fields.additional_team_official_birth_date) {
      setCustomErroByField(
        'additionalTeamOfficialsBirthDate',
        invalid_fields.additional_team_official_birth_date
      )
    }
    if (invalid_fields['team_official_array.0.team_official_type_id']) {
      setCustomErroByField(
        'team_official_array.0.team_official_type_id',
        invalid_fields['team_official_array.0.team_official_type_id']
      )
    }
    additionalTeamOfficials?.forEach((el, index) => {
      if (invalid_fields[`team_official_array.${index + 1}.first_name`]) {
        setCustomErroByField(
          'additionalTeamOfficialsFirstName',
          invalid_fields[`team_official_array.${index + 1}.first_name`]
        )
      }
      if (invalid_fields[`team_official_array.${index + 1}.last_name`]) {
        setCustomErroByField(
          'additionalTeamOfficialsLastName',
          invalid_fields[`team_official_array.${index + 1}.last_name`]
        )
      }
      if (invalid_fields[`team_official_array.${index + 1}.email`]) {
        setCustomErroByField(
          'additionalTeamOfficialsEmail',
          invalid_fields[`team_official_array.${index + 1}.email`]
        )
      }
      if (invalid_fields[`team_official_array.${index + 1}.birth_date`]) {
        setCustomErroByField(
          'additionalTeamOfficialsBirthDate',
          invalid_fields[`team_official_array.${index + 1}.birth_date`]
        )
      }
    })
  }

  const setCustomErroByField = (field, message) => {
    setCustomError((prev) => ({ ...prev, [field]: message }))
    setFieldErrors((prevErrors) => [...new Set([...prevErrors, field])])
  }

  useEffect(() => {
    if (divToScrollWhenOpenScreenRef.current) {
      divToScrollWhenOpenScreenRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [divToScrollWhenOpenScreenRef])

  useEffect(() => {
    if (session && session?.team_name && !session?.team_id) {
      if (!newTeamName) {
        setNewTeamName(session?.team_name)
        setIsAddingNewTeam(true)
      }
    } else if (session && session?.team_id) {
      if (!selectedTeam) {
        const team = userTeams?.find((el) => el.uid === session?.team_id)
        setSelectedTeam(team)
      }
    }
  }, [newTeamName, selectedTeam, session, userTeams])

  useEffect(() => {
    if (userTeams && userTeams?.length <= 0) {
      setIsAddingNewTeam(true)
    }
  }, [userTeams])

  const validateCoupon = async () => {
    try {
      setPromoCodeError(null)
      setIsPromoCodeValid(false)
      const res = await req(
        `/registration/${division?.schedule_id}/coupon/verify`,
        {
          method: 'POST',
          body: JSON.stringify({
            code: promoCode,
            registration_type_id: isTeamRep ? 1 : 2,
          }),
        }
      )
      setCouponInfo({
        discount: res?.data?.discount,
        type: res?.data?.discount_type,
        id: res?.data?.id,
        code: res?.data?.code,
        subtotal: res?.data?.subtotal,
        discount_amount: res?.data?.discount_amount,
        max_uses: res?.data?.max_uses,
      })
      setIsPromoCodeValid(true)
    } catch (e) {
      setPromoCodeError(getErrorMessage(e))
    }
  }

  const handleAddTeamOfficial = async () => {
    try {
      setFieldErrors([])
      setCustomError({})
      if (!newTeamOfficial.firstName) {
        setCustomErroByField(
          'additionalTeamOfficialsFirstName',
          'First name is required'
        )
        return
      }
      if (!newTeamOfficial.lastName) {
        setCustomErroByField(
          'additionalTeamOfficialsLastName',
          'Last name is required'
        )
        return
      }
      if (!newTeamOfficial.email) {
        setCustomErroByField(
          'additionalTeamOfficialsEmail',
          'Email is required'
        )
        return
      }
      if (!newTeamOfficial.email.includes('@')) {
        setCustomErroByField(
          'additionalTeamOfficialsEmail',
          'Email is not valid'
        )
        return
      }
      if (!newTeamOfficial.birthDateDay) {
        setCustomErroByField(
          'additionalTeamOfficialsBirthDate',
          'Birthdate is required'
        )
        return
      }
      if (!newTeamOfficial.birthDateMonth) {
        setCustomErroByField(
          'additionalTeamOfficialsBirthDate',
          'Birthdate is required'
        )
        return
      }
      if (!newTeamOfficial.birthDateYear) {
        setCustomErroByField(
          'additionalTeamOfficialsBirthDate',
          'Birthdate is required'
        )
        return
      }
      const lbirthDate = `${newTeamOfficial.birthDateYear}-${newTeamOfficial.birthDateMonth}-${newTeamOfficial.birthDateDay}`
      setAdditionalTeamOfficials([
        ...additionalTeamOfficials,
        {
          firstName: newTeamOfficial.firstName,
          lastName: newTeamOfficial.lastName,
          email: newTeamOfficial.email,
          birthDate: lbirthDate,
        },
      ])
      setIsAddingTeamOfficial(false)
      setNewTeamOfficial({
        firstName: '',
        lastName: '',
        email: '',
        birthDateDay: '',
        birthDateMonth: '',
        birthDateYear: '',
      })
    } catch (e) {
      console.error(e)
    }
  }

  const submitRegistration = async () => {
    try {
      // if the selected country is USA or Canada, we should enforce the stateProvince
      if (!isTeamRep) {
        if (country === 'US' || country === 'CA') {
          if (!stateProvince) {
            setCustomErroByField('stateProvince', 'State is required')
            return
          }
        }
      }
      setError(null)
      setCustomErrors({})
      setFieldErrors([])
      setLoading(true)
      const composeTeamOfficials = [
        {
          email,
          name_first: firstName,
          name_last: lastName,
          is_treasurer: true,
          phone: teamPhone,
          team_phone: teamPhone,
          team_email: teamEmail,
          team_website: teamWebsite,
          team_notes: teamNotes,
        },
        ...additionalTeamOfficials.map((el) => {
          return {
            email: el.email,
            name_first: el.firstName,
            name_last: el.lastName,
            is_treasurer: false,
            phone: '+1234567890',
          }
        }),
      ]
      const teamId = !newTeamName ? selectedTeam?.uid || selectedTeam?.id : null
      const teamName = selectedTeam?.name || newTeamName
      if (isTeamRep) {
        await updateSessionTeamRegister({
          team_official_array: composeTeamOfficials,
          team_id: teamId,
          team_name: teamName,
          coupon_code_id: couponInfo?.id || '',
          country_code: country,
          postal_code: zipCode,
          user_id: userId,
          currency_id: currency_id,
          player_array: [],
          province: stateProvince || '',
          team_player_roster_id: selectedTeam?.team_player_roster_id || '',
        })
      } else {
        await updateSessionPlayerRegister({
          team_id: teamId,
          team_name: teamName,
          user_id: userId,
          province: stateProvince || '',
          coupon_code_id: couponInfo?.id || '',
          team_player_roster_id: selectedTeam?.team_player_roster_id || '',
        })
      }

      if (!showWaiver || isTeamRep) {
        await updateSessionPayment()
      }

      const depositToCheck = isTeamRep
        ? division?.deposit
        : division?.player_deposit

      const singlePayment = isTeamRep
        ? division?.single_payment
        : division?.player_single_payment

      if (showWaiver && !isTeamRep) {
        await updateSessionWaiver(false)
        setStep(REGISTRATION_STEPS.WAIVER)
      } else if (singlePayment || (depositToCheck && depositToCheck > 0)) {
        if (singlePayment && isOneHundrerPercentCoupon) {
          checkIfUserIsVerified()
            .then(() => {
              updateSessionConfirmSubmission()
                .then(() => {
                  setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
                })
                .catch((e) => {
                  message.open({
                    type: 'error',
                    content:
                      'We were unable to confirm your submission. Please Contact SportNinja Support.',
                    duration: 6,
                  })
                  registerError(e, 'Error confirming submission', {
                    session: session,
                    division: division,
                    couponInfo: couponInfo,
                    teamOfficialType: teamOfficialType,
                    role: role,
                  })
                })
            })
            .catch((e) => {
              setTimeout(() => {
                setStep(REGISTRATION_STEPS.CONFIRM_YOUR_EMAIL)
              }, 2000)
            })
        } else {
          setStep(REGISTRATION_STEPS.REGISTRATION_PAYMENT)
        }
      } else {
        checkIfUserIsVerified()
          .then(() => {
            updateSessionConfirmSubmission()
              .then(() => {
                setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
              })
              .catch((e) => {
                message.open({
                  type: 'error',
                  content:
                    'We were unable to confirm your submission. Please Contact SportNinja Support.',
                  duration: 6,
                })
                registerError(e, 'Error confirming submission', {
                  session: session,
                  division: division,
                  couponInfo: couponInfo,
                  teamOfficialType: teamOfficialType,
                  role: role,
                })
              })
          })
          .catch((e) => {
            setTimeout(() => {
              setStep(REGISTRATION_STEPS.CONFIRM_YOUR_EMAIL)
            }, 2000)
          })
      }
    } catch (e) {
      if (e?.status === 410) {
        deleteSession()
        window.location.reload()
        return
      }
      if (e?.invalid_fields?.coupon_code) {
        message.open({
          type: 'error',
          content: e.invalid_fields.coupon_code,
        })
        return
      }
      // if invalid_fields is an array, set custom errors
      if (Array.isArray(e?.invalid_fields) && e?.invalid_fields?.length > 0) {
        message.open({
          type: 'error',
          content: e.invalid_fields[0],
        })
        return
      }

      if (e?.invalid_fields) {
        setCustomError({})
        setCustomErrors(e.invalid_fields)
        return
      }
      const errorMessages = getErrorMessage(e)
      message.open({
        type: 'error',
        content: errorMessages,
      })
    } finally {
      setLoading(false)
    }
  }

  const teamDropDownItem = useCallback(
    ({ value }) => {
      if (value === 'create_new_team') {
        return null
      }
      const team = userTeams?.find((el) => el.uid === value || el.id === value)
      const imageId = getImageThumbnailId(team)
      if (!team || !imageId) {
        return (
          <Image
            src={'/images/generic_placeholders/placeholder_team.png'}
            width={24}
            height={24}
            preview={undefined}
          />
        )
      }
      return (
        <Image
          src={imageId}
          width={24}
          height={24}
          preview={undefined}
          onError={(e) => {
            e.target.onerror = null
            e.target.src = '/images/generic_placeholders/placeholder_team.png'
          }}
        />
      )
    },
    [userTeams]
  )

  return (
    <div
      ref={divToScrollWhenOpenScreenRef}
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        background-color: ${colors.NEUTRAL_800};
        width: 1080px;
        padding: 0 35px 35px 35px;
        border-radius: 16px 16px 0px 0px;
        @media (max-width: 768px) {
          min-width: 0;
          width: 100%;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          margin-top: 16px;
          margin-bottom: -8px;
          @media (max-width: 768px) {
            flex-direction: column;
            width: 100%;
            gap: 8px;
          }
        `}
      >
        <a
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: default;
          `}
          // href={`${ROUTES.PROFILE.ROOT}`}
          target='_blank'
          rel='noreferrer'
        >
          <UserOutlined
            css={css`
              color: ${colors.PRIMARY};
            `}
          />
          <p
            css={css`
              ${paragraphSmallStyle}
              color: ${colors.PRIMARY};
            `}
          >
            {email}{' '}
          </p>
        </a>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
          `}
          onClick={() => {
            resetSession()
            setTimeout(() => {
              window.location.reload()
            }, 500)
          }}
        >
          <LogoutOutlined
            css={css`
              color: ${colors.ERROR_200};
            `}
          />
          <p
            css={css`
              ${paragraphSmallStyle}
              color: ${colors.ERROR_200};
            `}
          >
            Start Over
          </p>
        </div>
      </div>
      <EventCard eventInfo={registration} />
      <EventProgress
        eventProgress={3}
        isWaiverRequired={showWaiver}
        isTeamRep={isTeamRep}
      />
      <PageTitle
        title={isTeamRep ? 'Register Your Team' : 'Register Player'}
        subtitle={
          'Provide the necessary details to proceed with your registration.'
        }
        showAlert={true}
        showSuccess={false}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        `}
      >
        <Text
          variant='captionLarge'
          weight='semiBold'
          color={colors.NEUTRAL_0}
          style={`
          align-self: flex-start;
          `}
        >
          Selected Division
        </Text>
        <div
          css={css`
            display: flex;
            flex: 1;
            padding: 16px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid ${colors.NEUTRAL_200};
            background-color: ${colors.NEUTRAL_700};
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex: 1 0 0;
                align-self: stretch;
              `}
            >
              <Text
                variant='heading5'
                color={colors.WHITE}
                weight='semiBold'
                style={`
                  align-self: stretch;
                `}
              >
                {division?.name}
              </Text>
              <Text
                variant='paragraphSmall'
                color={colors.MAGNESIUM}
                weight='regular'
              >
                Age: {division?.division_age?.name}
              </Text>
              <Text
                variant='paragraphSmall'
                color={colors.MAGNESIUM}
                weight='regular'
              >
                Gender: {division?.division_gender?.name}
              </Text>
              {couponInfo && couponInfo?.discount ? (
                <Text
                  variant='paragraphMedium'
                  color={colors.SUCCESS_100}
                  weight='semiBold'
                >
                  {couponInfo?.type === 'percentage'
                    ? `Coupon Code: ${couponInfo?.code} - ${couponInfo?.discount}% off`
                    : `Coupon Code: ${couponInfo?.code} - $${couponInfo?.discount} off`}
                </Text>
              ) : null}
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                align-self: stretch;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  align-self: flex-end;
                `}
              >
                <Text
                  variant='paragraphSmall'
                  color={colors.MAGNESIUM}
                  weight='regular'
                >
                  Cost Per {isTeamRep ? 'Team' : 'Player'}
                </Text>
                <Text
                  variant='heading6'
                  color={colors.NEUTRAL_0}
                  weight='semiBold'
                >
                  {couponInfo?.id && couponInfo?.id !== ''
                    ? formatCurrency(couponInfo.subtotal, currency?.name)
                    : teamCost}
                </Text>
              </div>
              {division?.deposit && division?.deposit > 0 ? (
                <Text
                  variant='paragraphSmall'
                  color={colors.MAGNESIUM}
                  weight='regular'
                >
                  Deposit: {deposit}
                </Text>
              ) : null}
              {couponInfo?.discount_amount &&
              couponInfo?.discount_amount > 0 ? (
                <Text
                  variant='paragraphMedium'
                  color={colors.SUCCESS_100}
                  weight='semiBold'
                  style={`
                    align-self: flex-end;
                  `}
                >
                  -{formatCurrency(couponInfo?.discount_amount, currency?.name)}
                </Text>
              ) : null}
            </div>
          </div>
          <div
            css={css`
              width: 100%;
              height: 1px;
              background: ${colors.VERY_DARK_GRAYISH_BLUE};
            `}
          />
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;
            `}
          >
            <p
              onClick={() => {
                setCouponInfo(null)
                setStep(REGISTRATION_STEPS.SELECT_YOUR_DIVISION)
              }}
              css={css`
                color: ${isCanlan ? colors.NEUTRAL_0 : colors.SECONDARY_300};
                background-color: ${colors.PRIMARY};
                ${paragraphSmall}
                cursor: pointer;
                padding: 8px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                gap: 8px;
                opacity: ${registration?.divisions?.length === 1 ? 0 : 1};
              `}
            >
              <SwapOutlined
                style={{
                  fontSize: 16,
                }}
              />{' '}
              Change Division
            </p>
            <p
              onClick={() => {
                setShowPromoCode(!showPromoCode)
                if (showPromoCode === false) {
                  setPromoCode('')
                }
              }}
              css={css`
                color: ${isCanlan ? colors.NEUTRAL_0 : colors.SECONDARY_300};
                background-color: ${colors.PRIMARY};
                ${paragraphSmall}
                cursor: pointer;
                padding: 8px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                gap: 8px;
                background-color: ${!showPromoCode
                  ? colors.PRIMARY
                  : isCanlan
                  ? colors.PRIMARY
                  : colors.PRIMARY_500};
                opacity: ${showPromoCode && isCanlan ? 0.7 : 1};
              `}
            >
              <TagOutlined
                style={{
                  fontSize: 16,
                }}
              />{' '}
              Coupon Code
            </p>
          </div>
        </div>
      </div>
      {/* Promocode */}
      {showPromoCode ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            margin-top: 16px;
          `}
        >
          <Text
            variant='paragraphSmall'
            color={colors.WHITE}
            weight='regular'
            style={`
              align-self: stretch;
            `}
          >
            Coupon Code
          </Text>
          <div
            css={css`
              display: flex;
              align-items: flex-end;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <Input
              name='promoCode'
              placeholder='SUMMER10'
              type='text'
              autoComplete='off'
              autoCorrect='off'
              autoCapitalize='true'
              required
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              disabled={isPromoCodeValid}
            />
            {couponInfo?.id ? (
              <div
                css={css`
                  display: flex;
                  padding: 8px;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;
                  border-radius: 4px;
                  background-color: ${colors.ERROR_200};
                  height: 40px;
                  cursor: pointer;
                  margin-bottom: 2px;
                `}
                onClick={async () => {
                  setCouponInfo({
                    discount: null,
                    type: '',
                    id: '',
                  })
                  setPromoCode('')
                  setIsPromoCodeValid(false)
                }}
              >
                <CloseOutlined />
              </div>
            ) : (
              <div
                css={css`
                  display: flex;
                  padding: 8px;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;
                  border-radius: 4px;
                  background: ${colors.PRIMARY};
                  height: 40px;
                  cursor: ${isPromoCodeValid ? 'not-allowed' : 'pointer'};
                  margin-bottom: 2px;
                `}
                disabled={isPromoCodeValid}
                onClick={async () => {
                  if (!isPromoCodeValid) {
                    await validateCoupon()
                  }
                }}
              >
                <CheckOutlined />
              </div>
            )}
          </div>
          {promoCodeError ? (
            <div
              css={css`
                width: 100%;
              `}
            >
              <Alert message={promoCodeError} type='error' />
            </div>
          ) : null}
          {isPromoCodeValid ? (
            <div
              css={css`
                width: 100%;
              `}
            >
              <Alert
                message={`Coupon Code Applied: You Saved ${
                  couponInfo?.type === 'percentage'
                    ? `- ${couponInfo?.discount}% off`
                    : `- $${couponInfo?.discount} off`
                }! ${
                  division?.single_payment
                    ? 'Your discount will appear in the next screen.'
                    : ''
                }`}
                type='success'
              />
            </div>
          ) : null}
        </div>
      ) : null}
      <div
        css={css`
          width: 100%;
          height: 1px;
          background: ${colors.VERY_DARK_GRAYISH_BLUE};
          margin-top: 16px;
        `}
      />
      {/* Team Info */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          margin-top: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 4px;
              align-self: stretch;
            `}
          >
            <Text
              variant='captionLarge'
              color={colors.WHITE}
              weight='semiBold'
              style={`
              align-self: stretch;
              flex: 1 0 0;
            `}
            >
              {!isTeamRep
                ? 'Choose a Team or Division Unassigned Group'
                : isAddingNewTeam
                ? 'Create a New Team'
                : 'Choose One of Your Teams'}
            </Text>
            {isTeamRep ? (
              <Text
                variant='paragraphSmall'
                color={colors.NEUTRAL_50}
                weight='regular'
              >
                The dropdown below lists all teams you either own, or have Team
                Official permissions for.
                <br />
                Choose any of these teams to enroll them into this competition.
              </Text>
            ) : null}
          </div>
          {isAddingNewTeam && userTeams?.length > 0 ? (
            <p
              onClick={() => {
                setIsAddingNewTeam(false)
                setSelectedTeam(null)
              }}
              css={css`
                color: ${isCanlan ? colors.NEUTRAL_0 : colors.SECONDARY_300};
                background-color: ${colors.PRIMARY};
                ${paragraphSmall}
                cursor: pointer;
                padding: 8px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                gap: 8px;
                align-self: flex-end;
              `}
            >
              <SwapOutlined
                style={{
                  fontSize: 16,
                }}
              />{' '}
              Select Existing Team
            </p>
          ) : null}
          {!isAddingNewTeam && userTeams?.length > 0 && isTeamRep ? (
            <p
              onClick={() => {
                setSelectedTeam(null)
                setIsAddingNewTeam(true)
              }}
              css={css`
                color: ${isCanlan ? colors.NEUTRAL_0 : colors.SECONDARY_300};
                background-color: ${colors.PRIMARY};
                ${paragraphSmall}
                cursor: pointer;
                padding: 8px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                gap: 8px;
                display: none;
                cursor: default;
                pointer-events: none;
              `}
            >
              <PlusOutlined
                style={{
                  fontSize: 16,
                }}
              />{' '}
              Create New Team
            </p>
          ) : null}
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;
          `}
        >
          {!isAddingNewTeam ? (
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              Team *
            </Text>
          ) : null}
          {isAddingNewTeam && isTeamRep ? (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  gap: 16px;
                  width: 100%;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    flex: 1 0 0;
                  `}
                >
                  <Text
                    variant='paragraphSmall'
                    color={colors.WHITE}
                    weight='regular'
                  >
                    Team Name *
                  </Text>
                  <Input
                    status={
                      fieldErrors.includes('team_name') ? 'error' : 'default'
                    }
                    name='teamName'
                    placeholder='The Sport Ninjas'
                    type='text'
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='none'
                    value={newTeamName}
                    onChange={(e) => setNewTeamName(e.target.value)}
                  />
                  <div
                    tabIndex={-1}
                    css={css`
                      transition: opacity 0.1s ease-in-out;
                      opacity: ${fieldErrors.includes('team_name') ? 1 : 0};
                      font-size: 13px;
                      line-height: 16px;
                      color: ${colors.ERROR_LIGHT};
                      align-self: stretch;
                      margin-top: 8px;
                    `}
                  >
                    <span>{customError.team_name}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <Select
                css={css`
                  width: 50%;
                  height: 50px;
                  .ant-select-selection-item {
                    display: flex;
                    align-items: center;
                  }
                  @media (max-width: 768px) {
                    width: 100%;
                  }
                `}
                status={
                  fieldErrors.includes('team_id') ||
                  fieldErrors.includes('team_name')
                    ? 'error'
                    : 'default'
                }
                placeholder='Select a Team'
                defaultValue={
                  selectedTeam?.team_player_roster_id ||
                  selectedTeam?.uid ||
                  selectedTeam?.id
                }
                value={
                  selectedTeam?.team_player_roster_id ||
                  selectedTeam?.uid ||
                  selectedTeam?.id
                }
                onChange={(e) => {
                  console.log('esta chamando aqui tambem?', e)
                  if (!e) {
                    setSelectedTeam(null)
                    return
                  }
                  if (e === 'create_new_team') {
                    setIsAddingNewTeam(true)
                    setSelectedTeam(null)
                    return
                  }
                  const theTeam = userTeams?.find(
                    (team) =>
                      team.uid === e ||
                      team.id === e ||
                      team.team_player_roster_id === e
                  )
                  setSelectedTeam({ ...theTeam })
                  setIsAddingNewTeam(false)
                }}
                options={getTeamDropdownOptions(
                  userTeams,
                  isTeamRep,
                  showExistingRegistrationsPlayers
                )}
                allowClear
                onClear={() => {
                  setSelectedTeam(null)
                }}
                showSearch
                filterOption={(input, option) => {
                  if (!input || !option?.label?.props?.children) {
                    return false
                  }
                  try {
                    const teamName =
                      option.label.props.children[0]?.props?.children || ''
                    return teamName
                      .toString()
                      .toLowerCase()
                      .includes(input?.toLowerCase() || '')
                  } catch (e) {
                    console.error('Error filtering team options:', e)
                    return false
                  }
                }}
              />
              <div
                tabIndex={-1}
                css={css`
                  transition: opacity 0.1s ease-in-out;
                  opacity: ${fieldErrors.includes('team_name') ? 1 : 0};
                  font-size: 13px;
                  line-height: 16px;
                  color: ${colors.ERROR_LIGHT};
                  align-self: stretch;
                  margin-top: 8px;
                `}
              >
                <span>{customError.team_name}</span>
              </div>
            </>
          )}
        </div>
        <div
          tabIndex={-1}
          css={css`
            transition: opacity 0.1s ease-in-out;
            opacity: ${fieldErrors.includes('team_id') ? 1 : 0};
            /* display none also if team_name is error */
            display: ${fieldErrors.includes('team_id') ? 'block' : 'none'};
            font-size: 13px;
            line-height: 16px;
            color: ${colors.ERROR_LIGHT};
            align-self: stretch;
          `}
        >
          <span>{customError.team_id}</span>
        </div>
      </div>

      {/* <div
        css={css`
          width: 100%;
          height: 1px;
          background: ${colors.VERY_DARK_GRAYISH_BLUE};
          margin-top: 8px;
        `}
      /> */}

      {/* Team Rep */}
      {isTeamRep ? (
        <TeamOfficialForm
          teamPhone={teamPhone}
          setTeamPhone={setTeamPhone}
          teamEmail={teamEmail}
          setTeamEmail={setTeamEmail}
          teamWebsite={teamWebsite}
          setTeamWebsite={setTeamWebsite}
          teamNotes={teamNotes}
          setTeamNotes={setTeamNotes}
          handleAddTeamOfficial={handleAddTeamOfficial}
          fieldErrors={fieldErrors}
          customError={customError}
          isAddingTeamOfficial={isAddingTeamOfficial}
          setIsAddingTeamOfficial={setIsAddingTeamOfficial}
          additionalTeamOfficials={additionalTeamOfficials}
          birthDate={birthDate}
          setBirthDate={setBirthDate}
          countries={countries}
          country={country}
          setCountry={setCountry}
          zipCode={zipCode}
          setZipCode={setZipCode}
          newTeamOfficial={newTeamOfficial}
          setNewTeamOfficial={setNewTeamOfficial}
          email={email}
          setEmail={setEmail}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          setAdditionalTeamOfficials={setAdditionalTeamOfficials}
        />
      ) : null}

      {!isTeamRep ? (
        <PlayerForm
          fieldErrors={fieldErrors}
          customError={customError}
          positions={positions}
        />
      ) : null}

      {error ? (
        <div
          css={css`
            margin-bottom: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 500px;
            align-self: center;
            flex: 1;
          `}
        >
          <Alert message={error} type='error' showIcon />
        </div>
      ) : null}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 8px;
          align-self: flex-end;
          width: 50%;
          @media (max-width: 768px) {
            flex-direction: column-reverse;
            width: 100%;
          }
        `}
      >
        <Button
          variant='secondary'
          text='Back'
          disabled={loading}
          onClick={async () => {
            // setStep(REGISTRATION_STEPS.SELECT_YOUR_DIVISION)
            setStep(REGISTRATION_STEPS.SELECT_YOUR_ROLE)
          }}
        />
        <Button
          variant='primary'
          text='Next'
          disabled={loading}
          onClick={async () => {
            await submitRegistration()
          }}
        />
      </div>
    </div>
  )
}

export default TeamRegister
