/* eslint-disable no-alert */
/** @jsxImportSource @emotion/react */
import { PrinterOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import orgActions from '@sportninja/common/actions/org'
import scheduleActions from '@sportninja/common/actions/schedule'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import req from '@sportninja/common/api/request'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { tutorialKeys } from '@sportninja/common/constants/userGuiding'
import { useTimezoneManager } from '@sportninja/common/hooks/useTimezoneManager'
import { t } from '@sportninja/common/i18n'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { convertTimesToISOStrings } from '@sportninja/common/sagas/game'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import {
  getAllGenericSportProperties,
  getAllTeamOfficialTypes,
  getSports,
} from '@sportninja/common/selectors/types'
import { getTutorial } from '@sportninja/common/utils/TutorialHelper'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { App, Dropdown } from 'antd'
import { driver } from 'driver.js'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import GeneratePluginModal from 'src/components/GeneralPluginModal'
import Icon from 'src/components/Icon'
import { NewModal } from 'src/components/NewModal'
import PrintSignSheetsModal from 'src/components/PrintSignInSheetsModal'
import ScheduleModal from 'src/components/ScheduleModal'
import { getScheduleScreenSteps } from 'src/constants/TutorialSteps'
import FileDownload from '../../components/FileDownload'
import FormSheet from '../../components/Form/FormSheet'
import {
  feedItemForm,
  gameForm,
  venueForm,
} from '../../components/Form/form-configs'
import ImportCSV from '../../components/ImportCSV'
import GameList from '../../components/List/GameList'
import OfficialList, { AddOfficial } from '../../components/List/OfficialList'
import UserList, { AddUser } from '../../components/List/UserList'
import NewReports from '../../components/NewReports'
import Page from '../../components/Page'
import BaseActionButton from '../../components/Page/BaseActionButton'
import EditButton from '../../components/Page/EditButton'
import FavouriteButton from '../../components/Page/FavouriteButton'
import NewButton from '../../components/Page/NewButton'
import { Mobile } from '../../components/Responsive'
import { ScheduleReprocessingContext } from '../../components/ScheduleReprocessingContext'
import Suspensions from '../../components/Suspensions'
import AddSuspensionSheet from '../../components/Suspensions/AddSuspensionSheet'
import useGetSuspensions from '../../components/Suspensions/useGetSuspensions'
import Venues from '../../components/Venues'
import Standings from '../../components/v2/Standings'
import Statistics from '../../components/v2/Statistics'
import { FrameContext } from '../App'
import Home from '../Home'
import { getBreadcrumbs, useReadItem, useStoreRecent } from '../utils'
import CloneSchedule from './CloneSchedule'
import { EmptySchedule } from './EmptySchedule'
import GameSlots from './GameSlots'
import ManageCouponModal from './ManageCouponModal'
import PlayerRegistration from './PlayerRegistration'
import RegistrationSetup from './RegistrationSetup'
import ReprocessStats from './ReprocessStats'
import ReprocessingSettingsOverlay from './ReprocessingSettingsOverlay'
import ScheduleBracket from './ScheduleBracket'
import ScheduleTree from './ScheduleTree'

const generateHrefAndDownloadCsv = (string, reportName) => {
  var blob = new Blob([string], { type: 'text/csv;charset=utf-8;' })

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, reportName)
  } else {
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    var csvUrl = URL.createObjectURL(blob)
    a.href = csvUrl
    a.download = reportName
    a.click()
    URL.revokeObjectURL(a.href)
    a.remove()
  }
}

/**
 * Generates a registration name from a schedule object.
 *
 * @param {Object} schedule - The schedule object.
 * @param {string} schedule.name - The name of the competition.
 * @param {string} schedule.starts_at - The start date of the competition.
 * @param {string} schedule.ends_at - The end date of the competition.
 * @returns {string} The registration name in the format: Registrations_${Competition Name}_${DateStartsAt}_${DateEndsAt}.
 */
const generateRegistrationName = (schedule, label = 'Registrations') => {
  const competitionName = schedule.name || 'Unknown'
  const dateStartsAt = schedule.starts_at
    ? new Date(schedule.starts_at).toISOString().split('T')[0].replace(/-/g, '')
    : 'Unknown'
  const dateEndsAt = schedule.ends_at
    ? new Date(schedule.ends_at).toISOString().split('T')[0].replace(/-/g, '')
    : 'Unknown'

  const filename = `${label}_${competitionName}_${dateStartsAt}_${dateEndsAt}`
  return filename.endsWith('.csv') ? filename : `${filename}.csv`
}

const driverObj = driver({
  allowClose: false,
  popoverClass: 'driverjs-theme',
  showProgress: true,
})

const Schedule = ({
  match,
  createVenue,
  isInFrame,
  createVenueUnderOrg,
  genericSportProperties,
  sportTypes,
}) => {
  const frameConfig = useContext(FrameContext)
  const history = useHistory()

  const {
    loading,
    error,
    item,
    hierarchy,
    permission,
    readItem,
    refreshKey,
    triggerRefresh,
    features,
  } = useReadItem()

  const { message } = App.useApp()

  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false)
  const [initialState, setInitialState] = useState(null)
  const SCHEDULE_ID = match.params.id?.replace('?sandbox_tour=1', '')
  const BASE_ROUTE = `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}`
  const IS_PUBLIC = item.is_public
  const TOURNAMENT_READY = item.is_tournament
  const syncSalesforce = item?.organization?.sync_salesforce || false
  const canSyncGameSlots = item?.tournament_can_sync_regular_season

  const [visibleGameIds, setVisibleGameIds] = useState([])
  const [areFiltersHidden, setAreFiltersHidden] = useState(false)
  const [isFilterToggleDisabled, setIsFilterToggleDisabled] = useState(false)
  const [filterForExporting, setFilterForExporting] = useState(null)
  const [ifNecessaryStatus, setIfNecessaryStatus] = useState(false)
  const [selectedGameSlotDivision, setSelectedGameSlotDivision] = useState(null)
  const [selectedGameSlotDivisionName, setSelectedGameSlotDivisionName] =
    useState(null)
  const [gamesCreated, setGamesCreated] = useState(false)
  const [hasTournamentStarted, setHasTournamentStarted] = useState(false)
  const [onFinishUploadGameslots, setOnFinishUploadGameslots] = useState(false)
  const [hasTeams, setHasTeams] = useState(false)
  const [loadingTeams, setLoadingTeams] = useState(true)

  const sports = useMemo(() => {
    if (!sportTypes || Object.keys(sportTypes).length === 0) {
      return []
    }
    return Object.entries(sportTypes).map(([id, sport]) => ({
      id,
      name: sport.name,
      name_full: sport.name_full,
    }))
  }, [sportTypes])

  const sport = useMemo(() => {
    return sports?.find((s) => s.id === item?.sport_id)
  }, [sports, item?.sport_id])
  const isSoccer =
    item?.sport_id === sports?.find((s) => s.name_full === 'Soccer')?.id
  const isHockey =
    item?.sport_id === sports?.find((s) => s.name === 'hockey')?.id

  const generatePluginTagModalRef = useRef(null)
  const printSignInSheetModalRef = useRef(null)
  const isProfessionalOrUltimateAccount = true
  const [isRegistrationFormOpen, setIsRegistrationFormOpen] = useState(false)
  const [isManageCouponFormOpen, setIsManageCouponFormOpen] = useState(false)
  const genericSport = useMemo(() => {
    if (item?.generic_sport_id) {
      return genericSportProperties.find((s) => s.id === item.generic_sport_id)
    }
    return null
  }, [item, genericSportProperties])
  const isGenericSport = genericSport !== null && genericSport?.id !== null
  let shouldDisplayStatistics = true
  if (isGenericSport) {
    shouldDisplayStatistics = false
  }

  const [isRegistrationSetup, setIsRegistrationSetup] = useState(false)
  const [registration, setRegistration] = useState(null)
  const [registrationTypes, setRegistrationTypes] = useState(null)
  const [availableTaxes, setAvailableTaxes] = useState(null)
  const refetchRegistrationFn = useRef(null)
  const [registrationLink, setRegistrationLink] = useState(null)

  useStoreRecent(ENTITY_TYPES.schedule, item, error, SCHEDULE_ID, isInFrame)

  useEffect(() => {
    const readTutorial = async () => {
      try {
        const tutorialInfo = await getTutorial(
          tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_2
        )
        if (tutorialInfo?.tutorialStep?.value === 'true') {
          setTimeout(() => {
            const steps = getScheduleScreenSteps(driverObj)
            driverObj.setSteps(steps)
            driverObj.drive()
          }, 2000)
        }
      } catch (e) {
        console.error(`Failed to read tutorial: ${e}`)
      }
    }

    readTutorial()
  }, [])

  useEffect(() => {
    readItem(ENTITY_TYPES.schedule, SCHEDULE_ID)
  }, [SCHEDULE_ID])

  const { listeners = {}, addPending } = useContext(ScheduleReprocessingContext)
  const scheduleReprocessingListener = listeners[SCHEDULE_ID]

  const {
    loading: isSuspensionsLoading,
    suspensions,
    refresh,
  } = useGetSuspensions(SCHEDULE_ID)

  useEffect(() => {
    const checkTeams = async () => {
      try {
        const response = await req(`/schedules/${SCHEDULE_ID}/teams/simple`)
        if (response?.data?.length > 0) {
          setHasTeams(true)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoadingTeams(false)
      }
    }

    if (
      (!hasTeams &&
        window?.location?.pathname === `/schedule/${SCHEDULE_ID}`) ||
      window?.location?.pathname === `/schedule/${SCHEDULE_ID}/schedule`
    ) {
      checkTeams()
    }
  }, [hasTeams, window?.location?.pathname])

  const onAddVenue = async (...args) => {
    // SN-4038 - We are not creating the venues under the parent organization.
    // so they can be shared across multiple schedules.
    // const response = await createVenue(SCHEDULE_ID, ...args)
    const response = await createVenueUnderOrg(item?.organization_id, ...args)
    triggerRefresh()
    return response
  }

  const { DateTime } = useTimezoneManager({
    date: null,
    timezone: null,
  })

  const onAddGame = async ({ ...form }) => {
    const body = convertTimesToISOStrings(form, ['started_at', 'ended_at'])

    const userTimeStartDate = DateTime.fromISO(form?.starts_at)
    const locationStartDate = userTimeStartDate.setZone(
      form?.location_timezone,
      {
        keepLocalTime: true,
      }
    )

    const userTimeEndDate = DateTime.fromISO(form?.ends_at)
    const locationEndDate = userTimeEndDate.setZone(form?.location_timezone, {
      keepLocalTime: true,
    })

    // For web: if no game_type_id was selected, set the default value as 2 (i.e. regular season)
    if (!body.game_type_id) {
      body.game_type_id = 2
    } else if (Object.prototype.hasOwnProperty.call(body, 'game_type_id')) {
      body.game_type_id = parseInt(body.game_type_id, 10)
    }

    const response = await req(`/schedules/${SCHEDULE_ID}/games`, {
      method: 'POST',
      body: JSON.stringify({
        ...body,
        if_necessary_status: ifNecessaryStatus,
        starts_at: locationStartDate.toUTC().toISO(),
        ends_at: locationEndDate.toUTC().toISO(),
      }),
    })

    triggerRefresh()
    return response
  }

  const uploadCompetition = useCallback(
    (label, onClick) => (
      <BaseActionButton name='upload' title={label} onClick={onClick} />
    ),
    []
  )

  const onClickAddGame = useCallback(({ onClick }) => {
    return <NewButton onClick={onClick} />
  }, [])

  const exportGameSlots = useCallback((load, onClick) => {
    return (
      <BaseActionButton
        // disabled={!gamesCreated || hasTournamentStarted}
        loading={load}
        onClick={onClick}
        name='download'
        title={'Export Game Slots'}
      />
    )
  }, [])

  const exportCompetition = useCallback((load, onClick) => {
    return (
      <BaseActionButton
        loading={load}
        onClick={onClick}
        name='download'
        title={t('common:exportComp')}
      />
    )
  }, [])

  const onUploadGameSlots = useCallback(
    (label, onClick) => {
      return (
        <BaseActionButton
          disabled={!gamesCreated || hasTournamentStarted}
          name='upload'
          title={label}
          onClick={onClick}
        />
      )
    },
    [gamesCreated, hasTournamentStarted]
  )

  const titleActions = item?.id
    ? [
        <FavouriteButton
          key='favourite'
          initialState={item.is_favorite}
          entityType={ENTITY_TYPES.schedule}
          entityId={SCHEDULE_ID}
        />,
        permission.update && (
          <EditButton
            key='schedule_edit_button'
            onClick={() => {
              setInitialState(item)
              setIsScheduleModalOpen(true)
            }}
            title={item?.name_full}
          />
        ),
      ]
    : []

  const baseActions = []

  const filterToggleButton = !isFilterToggleDisabled && (
    <BaseActionButton
      faType={areFiltersHidden ? 'far' : 'fas'}
      key='toggle-filter'
      name='filter'
      title={`Toggle Filters Visibility ${areFiltersHidden ? 'on' : 'off'}`}
      onClick={() => {
        setAreFiltersHidden((v) => !v)
      }}
    />
  )

  const reprocessStatsButton = permission.update && (
    <ReprocessStats
      key='reprocess'
      id={SCHEDULE_ID}
      onSuccess={triggerRefresh}
    />
  )

  const onPressTransactionReport = async () => {
    try {
      const response = await req(
        `/reports/${SCHEDULE_ID}/registration?report_type=transaction&format=csv`,
        { parseJSON: false }
      )
      const text = await response.text()
      generateHrefAndDownloadCsv(
        text,
        `${generateRegistrationName(item, 'Transactions')}`
      )
    } catch (e) {
      console.error(e)
      alert(
        `There was an error generating the transaction report. ${
          e?.message || ''
        } Please try again.`
      )
    }
  }

  const onPressRevenueReport = async () => {
    try {
      const response = await req(
        `/reports/${SCHEDULE_ID}/registration?report_type=revenue&format=csv`,
        { parseJSON: false }
      )
      const text = await response.text()
      generateHrefAndDownloadCsv(
        text,
        `${generateRegistrationName(item, 'Revenue')}`
      )
    } catch (e) {
      console.error(e)
      alert(
        `There was an error generating the revenue report. ${
          e?.message || ''
        } Please try again.`
      )
    }
  }

  const onPressInfoReport = async () => {
    try {
      const response = await req(
        `/reports/${SCHEDULE_ID}/registration?report_type=info&format=csv`,
        { parseJSON: false }
      )
      const text = await response.text()
      generateHrefAndDownloadCsv(
        text,
        `${generateRegistrationName(item, 'Info')}`
      )
    } catch (e) {
      console.error(e)
      alert(
        `There was an error generating the info report. ${
          e?.message || ''
        } Please try again.`
      )
    }
  }

  const items = [
    {
      key: '1',
      label: (
        <a
          id='location-tutorial'
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            if (visibleGameIds?.length > 0) {
              history.push({
                pathname: ROUTES.SCORESHEETS,
                state: {
                  games: visibleGameIds,
                  search: location.search,
                  isSoccer,
                },
              })
            }
          }}
        >
          Print Scoresheets
        </a>
      ),
    },
    isCanlan && {
      key: '2',
      label: (
        <a
          id='location-tutorial'
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            printSignInSheetModalRef?.current?.openModal()
          }}
        >
          Print Sign-in Sheets
        </a>
      ),
    },
  ]

  const pages = [
    TOURNAMENT_READY && {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/bracket`,
      text: t('common:playoff', { count: 2 }),
      component: (
        <ScheduleBracket id={SCHEDULE_ID} canUpdate={permission.update} />
      ),
    },
    {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/schedule`,
      text: `${t('common:schedule')} (${item?.games_count || 0})`,
      tutorialId: 'sandbox-tabs-id-schedule',
      component:
        !loadingTeams && !hasTeams && item?.games_count < 1 ? (
          <EmptySchedule
            isPlayoff={TOURNAMENT_READY}
            canUpdate={permission.update}
            scheduleId={SCHEDULE_ID}
          />
        ) : (
          <GameList
            sportId={item.sport_id}
            schedule_id={SCHEDULE_ID}
            slug={`/schedules/${SCHEDULE_ID}/games`}
            refreshKey={refreshKey}
            setVisibleGameIds={(ids) => setVisibleGameIds(ids)}
            areFiltersHidden={areFiltersHidden}
            setFilterForExporting={setFilterForExporting}
            isSoccer={isSoccer}
            isHockey={isHockey}
            onClickFirstGame={async () => {
              try {
                driverObj?.destroy()
              } catch (e) {
                console.error(`Failed to set tutorial: ${e}`)
              }
            }}
          />
        ),
      actions: [
        filterToggleButton,
        permission.update && isProfessionalOrUltimateAccount && (
          <BaseActionButton
            key='plug'
            name='plug'
            title={'Generate JS Plugin Tag'}
            onClick={() => {
              generatePluginTagModalRef?.current?.openModal()
            }}
          />
        ),
        <Dropdown
          menu={{ items }}
          placement='bottomLeft'
          trigger={['click']}
          key={'dropdown'}
        >
          <div
            css={css`
              cursor: pointer;
              border: 1px solid ${colors.DEFAULT_FLAIR};
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 4px;
              padding-bottom: 4px;
              padding-left: 8px;
              padding-right: 8px;
              margin-left: 16px;
              border-radius: 4px;
            `}
          >
            <PrinterOutlined
              style={{
                color: colors.WHITE,
              }}
            />
          </div>
        </Dropdown>,
        permission.update && (
          <FileDownload
            key='download-comp'
            button={exportCompetition}
            url={`/schedules/${SCHEDULE_ID}/export/csv`}
            fileName={item.name_full}
            query={filterForExporting}
          />
        ),
        permission.update && (
          <ImportCSV
            key='upload-comp'
            button={uploadCompetition}
            entityType={ENTITY_TYPES.schedule}
            id={SCHEDULE_ID}
            supportsXML
            onSuccess={triggerRefresh}
          />
        ),
        permission.update && (
          <FormSheet
            key='add-game'
            Button={onClickAddGame}
            form={gameForm(
              {
                rootSchedule: item,
                parentId: SCHEDULE_ID,
                onSelectIfNecessary: () =>
                  setIfNecessaryStatus(!ifNecessaryStatus),
                ifNecessaryStatus,
                organization_id: item?.organization_id,
              },
              true,
              item?.sport_id
            )}
            label={t('Web:createGame')}
            onSubmit={onAddGame}
            title={t('Web:createANewGame')}
          />
        ),
      ],
    },
    (isCanlan || features?.statistics) && {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/standings`,
      text: t('common:standings'),
      tutorialId: 'sandbox-tabs-id-standings',
      component: (
        <Standings
          scheduleReprocessingListener={scheduleReprocessingListener}
          id={SCHEDULE_ID}
          defaultSort={isSoccer ? 'PTS' : 'WPCTD'}
          areFiltersHidden={areFiltersHidden}
          setIsFilterToggleDisabled={setIsFilterToggleDisabled}
          refreshKey={refreshKey}
          isSoccer={isSoccer}
          isInFrame={isInFrame}
          isGenericSport={isGenericSport}
          sportId={item.sport_id}
          isAdmin={permission.admin}
        />
      ),
      actions: [
        permission.update && (
          <BaseActionButton
            key='plug'
            name='plug'
            title={'Generate JS Plugin Tag'}
            onClick={() => {
              generatePluginTagModalRef?.current?.openModal()
            }}
          />
        ),
        filterToggleButton,
        reprocessStatsButton,
      ],
    },
    shouldDisplayStatistics && {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/statistics`,
      text: t('common:statistics'),
      tutorialId: 'sandbox-tabs-id-statistics',
      component: (
        <Statistics
          scheduleReprocessingListener={scheduleReprocessingListener}
          defaultSort={isSoccer ? 'G' : 'P'}
          goalieDefaultSort={isSoccer ? 'GAA' : 'GAA'}
          entityType={ENTITY_TYPES.schedule}
          entityId={SCHEDULE_ID}
          useTeamColumn
          areFiltersHidden={areFiltersHidden}
          setIsFilterToggleDisabled={setIsFilterToggleDisabled}
          refreshKey={refreshKey}
          isSoccer={isSoccer}
          isHockey={isHockey}
          isInFrame={isInFrame}
        />
      ),
      actions: [
        permission.update && (
          <BaseActionButton
            key='plug'
            name='plug'
            title={'Generate JS Plugin Tag'}
            onClick={() => {
              generatePluginTagModalRef?.current?.openModal()
            }}
          />
        ),
        filterToggleButton,
        reprocessStatsButton,
      ],
    },
    !isInFrame &&
      (isCanlan || features?.suspensions) && {
        to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/suspensions`,
        text: t('common:suspension', { count: 2 }),
        tutorialId: 'sandbox-tabs-id-suspensions',
        component: (
          <Suspensions
            key={refreshKey}
            exportId={SCHEDULE_ID}
            exportFileName={item?.name_full}
            loading={isSuspensionsLoading}
            suspensions={suspensions}
            refresh={refresh}
            scheduleId={SCHEDULE_ID}
            hasPermission={permission.admin}
            onlyDisplayActive={!permission.admin}
          />
        ),
        actions: [
          permission.admin && (
            <AddSuspensionSheet
              key='add-suspension'
              comp={item}
              onComplete={refresh}
            />
          ),
        ],
      },

    {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/locations`,
      text: t('common:location', { count: 2 }),
      component: (
        <Venues
          entityType={ENTITY_TYPES.schedule}
          id={SCHEDULE_ID}
          slug={'/venues'}
          query={{ schedule_id: SCHEDULE_ID }}
          refreshKey={refreshKey}
          triggerRefresh={triggerRefresh}
          parentPermission={permission}
        />
      ),
      actions: [
        permission.update && (
          <FormSheet
            key='add-venue'
            Button={onClickAddGame}
            form={venueForm({}, SCHEDULE_ID, ENTITY_TYPES.schedule)}
            label={t('Web:createLoc')}
            onSubmit={onAddVenue}
            title={t('Web:createANewLocation')}
          />
          // <NewButton onClick={() => locationModalRef.current.openModal()} />
        ),
      ],
    },
    (isCanlan || features?.announcements) && {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/feed`,
      text: t('common:feed'),
      tutorialId: 'sandbox-tabs-id-feed',
      component: (
        <Home
          isEntityFeed
          entity={item}
          entityType={ENTITY_TYPES.schedule}
          entityId={SCHEDULE_ID}
          refreshKey={refreshKey}
          hideSidebar={!permission.update}
          canPost={permission.post || permission.update || permission.admin}
        />
      ),
      actions: [
        permission.update && (
          <Mobile key='add-post'>
            <FormSheet
              label={t('Web:createANewPost')}
              title={t('Web:createANewPost')}
              form={feedItemForm({}, false)}
              onSubmit={async ({ imageData, ...form }) => {
                const body = JSON.stringify(form)
                const response = await req(
                  `/schedules/${SCHEDULE_ID}/social-feed`,
                  {
                    method: 'POST',
                    body,
                  }
                )
                if (imageData) {
                  await req(`/social-feed/${response.data.id}/image`, {
                    method: 'POST',
                    body: imageData,
                  })
                }
                triggerRefresh()
              }}
              Button={({ onClick }) => <NewButton onClick={onClick} />}
            />
          </Mobile>
        ),
      ],
    },
    permission.update && {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/reports`,
      text: 'Reports',
      tutorialId: 'sandbox-tabs-id-reports',
      privatePage: true,
      component: (
        <NewReports
          entity={item}
          entityType={ENTITY_TYPES.schedule}
          entityId={SCHEDULE_ID}
        />
      ),
      actions: [],
    },
    !isInFrame &&
      permission.update && {
        to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/setup`,
        text: t('common:setup'),
        tutorialId: 'sandbox-tabs-id-setup',
        privatePage: true,
        component: (
          <ScheduleTree
            isSoccer={isSoccer}
            id={SCHEDULE_ID}
            orgId={item.organization.id}
            schedule={item}
            showSalesforceSettings={syncSalesforce}
            is_registration_on={item.is_registration_on}
            sportId={item.sport_id}
            genericSportId={item?.generic_sport_id}
          />
        ),
        actions: [
          permission.update && (
            <CloneSchedule
              key='clone'
              schedule={item}
              scheduleId={SCHEDULE_ID}
            />
          ),
        ],
      },
    !isInFrame &&
      TOURNAMENT_READY &&
      permission.update && {
        to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/gameslots`,
        text: t('common:gameSlot', { count: 2 }),
        tutorialId: 'sandbox-tabs-id-gameslots',
        privatePage: true,
        component: (
          <GameSlots
            id={SCHEDULE_ID}
            canSyncGameSlots={canSyncGameSlots}
            bracketsCreated={item?.brackets_created}
            readItem={readItem}
            setSelectedGameSlotDivision={setSelectedGameSlotDivision}
            setSelectedGameSlotDivisionName={setSelectedGameSlotDivisionName}
            setGamesCreated={setGamesCreated}
            setHasTournamentStarted={setHasTournamentStarted}
            setOnFinishUploadGameslots={setOnFinishUploadGameslots}
            sportId={item.sport_id}
          />
        ),
        actions: [
          permission.update && selectedGameSlotDivision && (
            <FileDownload
              key='download-comp'
              button={exportGameSlots}
              url={`/schedules/${selectedGameSlotDivision}/gameslots/export/csv`}
              fileName={`${selectedGameSlotDivisionName}`}
              query={filterForExporting}
              label={`Download ${selectedGameSlotDivisionName} Game Slots`}
            />
          ),
          permission.update && selectedGameSlotDivision && (
            <ImportCSV
              key='upload-game-slots'
              button={onUploadGameSlots}
              entityType={ENTITY_TYPES.gameSlots}
              id={selectedGameSlotDivision}
              supportsXML
              onSuccess={() => {
                triggerRefresh()
                onFinishUploadGameslots()
              }}
            />
          ),
          <BaseActionButton
            key='question'
            name='question'
            title='Help'
            onClick={async () => {
              window.open(
                'https://support.sportninja.com/league-competition-management-6',
                '_blank'
              )
            }}
          />,
        ],
      },
    // !isInFrame &&
    //   permission.admin &&
    //   ENABLE_REGISTRATION &&
    //   features?.registration &&
    //   item?.organization.account_subscription_type_id >= 2 &&
    //   item?.is_registration_on && {
    //     to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/registration`,
    //     tutorialId: 'sandbox-tabs-id-registration',
    //     text: 'Registration',
    //     privatePage: true,
    //     component: (
    //       <Registration
    //         accountOwnerId={item?.owned_by_account_id}
    //         isAdmin={permission?.admin}
    //         sportId={item?.sport_id}
    //         key='registration'
    //         scheduleId={SCHEDULE_ID}
    //         refreshKey={refreshKey}
    //         setIsRegistrationSetup={setIsRegistrationSetup}
    //       />
    //     ),
    //     actions: [
    //       <BaseActionButton
    //         key='download'
    //         name='download'
    //         title='Download CSV'
    //         onClick={async () => {
    //           await onPressPrint()
    //         }}
    //       />,
    //       permission.admin && isRegistrationSetup && (
    //         <BaseActionButton
    //           key='manage-coupons'
    //           name='percent'
    //           title='Manage Coupons'
    //           onClick={async () => {
    //             setIsManageCouponFormOpen(true)
    //           }}
    //         />
    //       ),
    //       permission.admin && (
    //         <EditRegistration
    //           key='edit-registration'
    //           scheduleId={SCHEDULE_ID}
    //         />
    //       ),
    //     ],
    //   },
    !isInFrame &&
      permission.admin &&
      // Removing this as requested on https://sportninja.atlassian.net/browse/SN-5152
      // item?.organization.account_subscription_type_id >= 2 &&
      item?.is_registration_on && {
        to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/registration`,
        tutorialId: 'sandbox-tabs-id-player-registration',
        text: 'Registration',
        privatePage: true,
        component: (
          <PlayerRegistration
            key='player-registration'
            permission={permission}
            scheduleId={SCHEDULE_ID}
            sportId={item.sport_id}
            setRegistration={setRegistration}
            refetchRegistrationFn={refetchRegistrationFn}
            setIsRegistrationFormOpen={setIsRegistrationFormOpen}
            setRegistrationTypes={setRegistrationTypes}
            setAvailableTaxes={setAvailableTaxes}
            setIsRegistrationSetup={setIsRegistrationSetup}
          />
        ),
        actions: [
          <Dropdown
            placement='bottom'
            key='download'
            menu={{
              items: [
                {
                  key: '1',
                  label: 'Registration Revenue Report',
                  onClick: async () => {
                    await onPressRevenueReport()
                  },
                },
                {
                  key: '2',
                  label: 'Registration Transaction Report',
                  onClick: async () => {
                    await onPressTransactionReport()
                  },
                },
                {
                  key: '3',
                  label: 'Registration Info Report',
                  onClick: async () => {
                    await onPressInfoReport()
                  },
                },
              ],
            }}
          >
            <div
              css={css`
                border-radius: 4px;
                border: 1px solid ${colors.PRIMARY};
                padding: 4px;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <Icon name='download' fontSize={13} />
            </div>
          </Dropdown>,
          // registrationLink && (
          //   <BaseActionButton
          //     key='copy-registration-link'
          //     onClick={onCopyRegistrationLink}
          //     name='link'
          //     title='Copy Registration Link'
          //   />
          // ),
          permission.admin && isRegistrationSetup && (
            <BaseActionButton
              key='manage-coupons'
              name='tags'
              title='Manage Coupons'
              onClick={async () => {
                setIsManageCouponFormOpen(true)
              }}
            />
          ),
          <BaseActionButton
            key='edit-registration'
            onClick={() => {
              setIsRegistrationFormOpen((o) => !o)
              setRegistration(registration)
            }}
            name='cog'
            title='Edit Registration'
          />,
        ],
      },
    !isInFrame &&
      permission.update && {
        to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/officials`,
        text: t('common:official', { count: 2 }),
        tutorialId: 'sandbox-tabs-id-officials',
        privatePage: true,
        component: (
          <OfficialList
            key='officials'
            entityType={ENTITY_TYPES.schedule}
            entityId={SCHEDULE_ID}
            slug={`/schedules/${SCHEDULE_ID}/officials`}
            refreshKey={refreshKey}
            isSoccer={isSoccer}
            isOrg={false}
            sportId={item.sport_id}
          />
        ),
        actions: (isCanlan || features?.rolesPermissions) && [
          <AddOfficial
            key='add-official'
            entityType={ENTITY_TYPES.schedule}
            entityId={SCHEDULE_ID}
            permission={permission}
            onComplete={triggerRefresh}
            isSoccer={isSoccer}
            sportId={item.sport_id}
          />,
        ],
      },

    !isInFrame &&
      permission.admin && {
        to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/users`,
        text: t('common:user', { count: 2 }),
        tutorialId: 'sandbox-tabs-id-users',
        privatePage: true,
        component: (
          <UserList
            key='users'
            entityType={ENTITY_TYPES.schedule}
            entityId={SCHEDULE_ID}
            slug={`/schedules/${SCHEDULE_ID}/permissions`}
            refreshKey={refreshKey}
          />
        ),
        actions: (isCanlan || features?.rolesPermissions) && [
          <AddUser
            key='add-user'
            entityType={ENTITY_TYPES.schedule}
            entityId={SCHEDULE_ID}
            permission={permission}
            onComplete={triggerRefresh}
          />,
        ],
      },
  ]

  let filteredPages = pages

  if (frameConfig?.pages) {
    filteredPages = pages.filter((page) => {
      return frameConfig.pages.includes(page?.text)
    })
  }

  return (
    <>
      <Page
        competitionStartDate={item?.starts_at}
        competitionEndDate={item?.ends_at}
        contactInfo={{
          email: item?.email,
          website: item?.website,
          phone: item?.phone,
          support_email: item?.support_email,
          support_website: item?.support_website,
          support_phone: item?.support_phone,
        }}
        backgroundImageUrl={item?.background_image?.full_path}
        sport={sport}
        isSoccer={isSoccer}
        genericSport={genericSport}
        isTeamOrSchedule
        isSchedule={true}
        error={error}
        loading={loading}
        baseCrumb={
          error
            ? ROUTES.SCHEDULES.ROOT
            : `${ROUTES.ORG_ROOT}/${item?.organization?.id}/schedules`
        }
        baseRoute={BASE_ROUTE}
        baseTitle={item?.organization?.name_full}
        breadcrumbs={getBreadcrumbs(ROUTES.SCHEDULE_ROOT, item, hierarchy)}
        baseActions={baseActions}
        pages={filteredPages}
        isPrivate={!IS_PUBLIC}
        title={item?.name_full}
        subTitle={
          item?.generic_sport_name ? `${item?.generic_sport_name}` : null
        }
        iconName={item.is_tournament ? 'trophy' : 'calendar-alt'}
        imageId={getImageThumbnailId(item) || item?.image?.full_path || null}
        titleActions={titleActions}
        preserveQueryFor={[
          `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/bracket`,
          `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/setup`,
          `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/gameslots`,
          `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}`,
          `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/standings`,
          `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/statistics`,
          `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/schedule`,
        ]}
      />
      <ScheduleModal
        isFormOpen={isScheduleModalOpen}
        setIsFormOpen={setIsScheduleModalOpen}
        initialState={initialState || null}
        onCancel={() => {
          setInitialState(null)
          setIsScheduleModalOpen(false)
        }}
        triggerRefresh={() => {
          readItem(ENTITY_TYPES.schedule, SCHEDULE_ID)
        }}
        displayCanlanForm={syncSalesforce}
        addPending={addPending}
        isDaysmartConnectedAtOrgLevel={
          item?.organization?.is_daysmart_connected === true
        }
        sportId={item.sport_id}
      />
      <NewModal
        ref={generatePluginTagModalRef}
        shouldCloseOnOverlayClick={false}
      >
        <GeneratePluginModal
          onPressClose={() => {
            generatePluginTagModalRef?.current?.closeModal()
          }}
          scheduleId={item?.id}
          isOrganization={false}
        />
      </NewModal>
      <NewModal
        ref={printSignInSheetModalRef}
        shouldCloseOnOverlayClick={false}
      >
        <PrintSignSheetsModal
          onPressClose={() => {
            printSignInSheetModalRef?.current?.closeModal()
          }}
          visibleGameIds={visibleGameIds}
          history={history}
        />
      </NewModal>
      <RegistrationSetup
        isRegistrationFormOpen={isRegistrationFormOpen}
        setIsRegistrationFormOpen={setIsRegistrationFormOpen}
        registration={registration}
        registrationTypes={registrationTypes}
        availableTaxes={availableTaxes}
        scheduleId={SCHEDULE_ID}
        accountId={item?.organization?.account_id}
        refetchRegistrationFn={refetchRegistrationFn}
      />
      <ReprocessingSettingsOverlay
        scheduleReprocessingListener={scheduleReprocessingListener}
      />
      <ManageCouponModal
        title={`Coupons - ${item?.name_full}`}
        isFormOpen={isManageCouponFormOpen}
        setIsFormOpen={setIsManageCouponFormOpen}
        scheduleId={SCHEDULE_ID}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isInFrame: state.auth.inFrame,
    genericSportProperties: getAllGenericSportProperties(state),
    teamOfficialTypes: getAllTeamOfficialTypes(state),
    sportTypes: getSports(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createVenue: bindActionToPromise(
      dispatch,
      scheduleActions.venues.create.request
    ),
    createVenueUnderOrg: bindActionToPromise(
      dispatch,
      orgActions.venues.create.request
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
