import { RegistrationData } from '@sportninja/common/hooks/useRegistrationData'
import { Currency } from '@sportninja/common/types'
import { Registration } from '@sportninja/common/types/Registration'
import { Team } from '@sportninja/common/types/Registration'

interface DivisionData {
  id: string
  name: string
  registration: {
    division_age?: {
      name: string
    }
    is_registration_on: number
    division_gender?: {
      name: string
      id: string
    }
    total_players: number
    max_players_per_team: number
    max_teams: number
    deposit: number
    team_cost: number
    total_paid: number
    single_payment: boolean
  }
  teams?: {
    registration_status: number
  }[]
}

export interface RegistrationTableDivision {
  id: string
  name: string
  type: string
  isSeason: boolean
  open: boolean
  gender: string
  genderId: string
  totalPlayers: number
  totalTeams: number
  maxPlayers: number
  maxTeams: number
  maxPlayersPerBucket: number | null
  deposit: number | null
  cost: number | null
  revenue: number
  teams: Team[] // Consider creating a proper type for teams
  registration: Registration
  currency: Currency
  singlePayment: boolean
  playerCost: number | null
  playerDeposit: number | null
  playerSinglePayment: boolean
  playerNumberOfInstallments: number | null
  playerInstallmentFrequency: string | null
  playerInstallmentStartDate: string | null
  dayOfWeek: {
    id: string
    name: string
    name_full: string | null
    abbreviation: string | null
  } | null
  bucketTeams: number
}

/**
 * Computes and transforms division data into a standardized format
 * @param data - Raw registration data from the API
 * @returns Array of formatted division data
 */
export const computeDivisions = (
  data?: RegistrationData
): RegistrationTableDivision[] | null | undefined => {
  if (!data?.detailed?.divisions) {
    return []
  }
  return data.detailed.divisions.map((division) => {
    // Count active teams (not cancelled) and not bucket
    const activeTeams =
      division.registered_teams
        ?.filter((team) => team.registration_status !== 5)
        ?.filter((team) => !team?.is_bucket) || []
    const bucketTeams =
      division.registered_teams?.filter((team) => team?.is_bucket) || []
    return {
      isSeason:
        division?.id === data?.detailed?.id &&
        data?.detailed?.type === 'Season',
      id: division.id,
      name: division.name,
      type: division.registration?.division_age?.name || '',
      open: division.registration?.is_registration_on === 1,
      gender: division.registration?.division_gender?.name || '',
      genderId: division.registration?.division_gender?.id || '',
      totalPlayers: division.registration?.total_players || 0,
      maxPlayers: division.registration?.max_players_per_team || 0,
      totalTeams: activeTeams?.length || 0,
      maxTeams: division.registration?.max_teams || 0,
      deposit: division.registration?.deposit || null,
      depositRequired: division.registration?.deposit_required ? true : false,
      cost: division.registration?.team_cost || null,
      playerCost: division.registration?.player_cost || null,
      playerDeposit: division.registration?.player_deposit || null,
      playerDepositRequired:
        division.registration?.player_deposit_required || false,
      playerRequirePayment:
        division?.registration?.player_require_payment || false,
      playerNumberOfInstallments:
        division?.registration?.player_number_of_installments || null,
      playerInstallmentFrequency:
        division?.registration?.player_installment_frequency || null,
      playerInstallmentStartDate:
        division?.registration?.player_installment_start_date || null,
      revenue: division.registration?.total_paid || 0,
      teams: division.registered_teams || [],
      registration: division.registration,
      currency: data.detailed.registration?.currency || {
        id: '',
        name: '',
        name_full: null,
        abbreviation: null,
      },
      singlePayment:
        division?.registration?.single_payment ||
        division?.registration?.player_single_payment ||
        false,
      dayOfWeek: division.registration?.day_of_week || null,
      playerSinglePayment:
        division.registration?.player_single_payment || false,
      maxPlayersPerBucket:
        division.registration?.max_players_per_bucket || null,
      bucketTeams: bucketTeams?.length || 0,
    }
  })
}

/**
 * Helper function to validate division data
 * @param division - Division data to validate
 * @returns Boolean indicating if division data is valid
 */
export const isValidDivisionData = (
  division: Partial<DivisionData>
): division is DivisionData => {
  return !!(
    division &&
    division.id &&
    division.name &&
    division.registration &&
    typeof division.registration.is_registration_on === 'number'
  )
}

/**
 * Helper function to get default currency object
 * @returns Default currency object
 */
export const getDefaultCurrency = () => ({
  id: '',
  name: '',
  name_full: null,
  abbreviation: null,
})

/**
 * Helper function to calculate active teams count
 * @param teams - Array of team data
 * @returns Number of active teams
 */
export const calculateActiveTeams = (
  teams?: { registration_status: number }[]
): number => {
  if (!teams || !Array.isArray(teams)) {
    return 0
  }
  return teams.filter((team) => team.registration_status !== 5).length
}

/**
 * Helper function to safely get division registration value
 * @param registration - Registration object
 * @param key - Key to access
 * @param defaultValue - Default value if key doesn't exist
 * @returns Value from registration or default
 */
export const getDivisionRegistrationValue = <T,>(
  registration: any,
  key: string,
  defaultValue: T
): T => {
  if (!registration || typeof registration !== 'object') {
    return defaultValue
  }
  return registration[key] ?? defaultValue
}

/**
 * Interface for configuration options
 */
interface TrendPercentageOptions {
  decimalPlaces?: number
  defaultValue?: string
  includeSign?: boolean
}

/**
 * Computes the trend percentage from a given value
 * @param value - The raw trend value (usually between 0 and 1)
 * @param options - Configuration options for the calculation
 * @returns Formatted trend percentage as a string
 */
export const computeTrendPercentage = (
  value?: number | null,
  options: TrendPercentageOptions = {}
): string => {
  const {
    decimalPlaces = 2,
    defaultValue = '0.00',
    includeSign = false,
  } = options

  // Handle invalid inputs
  if (value === null || value === undefined || isNaN(value)) {
    return defaultValue
  }

  try {
    // Convert to percentage and round to specified decimal places
    const percentage = (value * 100).toFixed(decimalPlaces)

    // Handle sign if required
    if (includeSign) {
      const sign = Number(percentage) > 0 ? '+' : ''
      return `${sign}${percentage}`
    }

    return percentage
  } catch (error) {
    console.error('Error computing trend percentage:', error)
    return defaultValue
  }
}

/**
 * Formats the trend direction with an arrow
 * @param value - The trend percentage value
 * @returns String with trend direction arrow
 */
export const formatTrendDirection = (value?: number | null): string => {
  if (value === null || value === undefined || isNaN(value)) {
    return '→'
  }

  if (value > 0) {
    return '↑'
  }
  if (value < 0) {
    return '↓'
  }
  return '→'
}

/**
 * Computes trend color based on the value
 * @param value - The trend percentage value
 * @param options - Color options for positive, negative, and neutral values
 * @returns Color string for the trend
 */
export const computeTrendColor = (
  value?: number | null,
  options = {
    positive: '#4CAF50',
    negative: '#F44336',
    neutral: '#9E9E9E',
  }
): string => {
  if (value === null || value === undefined || isNaN(value)) {
    return options.neutral
  }

  if (value > 0) {
    return options.positive
  }
  if (value < 0) {
    return options.negative
  }
  return options.neutral
}

/**
 * Creates a complete trend display object
 * @param value - The trend percentage value
 * @returns Object containing formatted value, direction, and color
 */
export const createTrendDisplay = (value?: number | null) => {
  return {
    percentage: computeTrendPercentage(value, { includeSign: true }),
    direction: formatTrendDirection(value),
    color: computeTrendColor(value),
    raw: value,
  }
}
