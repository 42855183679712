/** @jsxImportSource @emotion/react */
import { Fragment, useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled/macro'
import css from '@emotion/css/macro'
import queryString from 'query-string'
import EnvHandler, { Environments } from '@sportninja/common/utils/env-handler'
import colors from '@sportninja/common/constants/appColors'

import Modal from '../Modal'
import { font, zIndex } from '../css'
import { Flex } from '../Layout'
import Icon from '../Icon'

const Title = styled.div`
  ${font.title};
  font-size: 24px;
  color: white;
  margin-bottom: 16px;
`

const Btn = styled.button`
  width: 56px;
  justify-content: center;
  align-items: center;
  border: 2px solid darkgray;
  padding: 4px;
`

const Input = styled.input`
  height: 40px;
  width: 224px;
  border: 2px solid darkgray;
  padding: 8px;
  margin-bottom: 16px;
  ${font.body}
  color: white;
`

const Container = ({ children }) => {
  return (
    <Flex
      noFlex
      column
      justifyContent='center'
      alignItems='center'
      css={css`
        margin: 16px 0;
        padding: 24px 50px;
        background-color: ${colors.BLACK_PEARL};
      `}
    >
      {children}
    </Flex>
  )
}

const SubmitButton = ({ children, isSubmit, onClick }) => (
  <button
    css={css`
      width: 100%;
      height: 40px;
      background-color: ${isSubmit ? 'darkgray' : 'transparent'};
      border: 2px solid darkgray;

      ${font.title};
      font-weight: 500;
      font-size: 15px;
      letter-spacing: 1px;
      color: ${isSubmit ? 'rgba(0,0,0,0.8)' : 'white'};
    `}
    onClick={onClick}
  >
    {children}
  </button>
)

export const EnvButton = ({ children, isActive, onClick }) => (
  <Btn
    onClick={onClick}
    css={css`
      flex: 1;
      height: 33px;
      background-color: ${isActive ? 'darkgray' : 'transparent'};
      color: ${isActive ? 'rgba(0,0,0,0.8)' : 'white'};
      margin: 0 2px;
      ${font.title}
      font-weight: 500;
      font-size: 15px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
    `}
  >
    {children}
  </Btn>
)

const IMPERSONATION_STORAGE_KEY = 'sn:existinguser'
const SESSION_TOKEN_STORAGE_KEY = 'session_token'

const EnvSelect = ({ user = {}, impersonate }) => {
  const { email } = user

  const [isOpen, setIsOpen] = useState(false)
  const [env, setEnv] = useState(Environments.PROD)
  const [customEnv, setCustomEnv] = useState('')
  const [showEnvIndicator, setShowEnvIndicator] = useState(false)

  const [existingUser, setExistingUser] = useState(false)
  const [impersonateError, setImpersonateError] = useState('')
  const [impersonateEmail, setImpersonateEmail] = useState('')

  useEffect(() => {
    const existingUserString = localStorage.getItem(IMPERSONATION_STORAGE_KEY)
    let existing = null

    if (existingUserString !== null) {
      existing = JSON.parse(existingUserString)
      setExistingUser(existing)
      setShowEnvIndicator(true)
    }

    EnvHandler.get()
      .then((desiredEnv) => {
        setEnv(desiredEnv)
        return desiredEnv
      })
      .then((desiredEnv) => {
        const parsed = queryString.parse(window.location.search)
        const isSportNinjaUser = /@sportninja\.com$/i.test(email)
        const isOnSportNinjaDomain = /^[a-zA-Z0-9-]+\.sportninja\.com$/.test(
          window.location.hostname
        )
        if (existingUserString !== null) {
          setShowEnvIndicator(true)
          return
        }
        if (__DEV__) {
          setShowEnvIndicator(true)
          return
        }
        if (!isOnSportNinjaDomain) {
          setShowEnvIndicator(false)
          return
        }
        if (!isSportNinjaUser) {
          setShowEnvIndicator(false)
          return
        }
        if (parsed && parsed.show_env === 'true') {
          setShowEnvIndicator(true)
          return
        }
      })
  }, [email])

  useEffect(() => {
    // const parsed = queryString.parse(window.location.search)
    const isSportNinjaUser = /@sportninja\.com$/i.test(email)
    const isOnSportNinjaDomain = /^[a-zA-Z0-9-]+\.sportninja\.com$/.test(
      window.location.hostname
    )
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key.toLowerCase() === 'i') {
        if (__DEV__) {
          setShowEnvIndicator(true)
          setIsOpen(true)
          return
        }
        if (existingUser) {
          setShowEnvIndicator(true)
          setIsOpen(true)
          return
        }
        if (!isOnSportNinjaDomain) {
          setShowEnvIndicator(false)
          return
        }
        if (!isSportNinjaUser) {
          setShowEnvIndicator(false)
          return
        }
        // if (parsed && parsed.show_env === 'true') {
        //   setShowEnvIndicator(true)
        //   setIsOpen(true)
        //   return
        // }
        setShowEnvIndicator(true)
        setIsOpen(true)
        e.preventDefault()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [email, existingUser])

  const onSetEnv = async () => {
    await EnvHandler.set(customEnv ? customEnv : env)
    window.location.reload()
  }

  const onImpersonate = async () => {
    setImpersonateError('')

    try {
      const existingToken = localStorage.getItem(SESSION_TOKEN_STORAGE_KEY)
      const string = JSON.stringify({ email, existingToken })

      await impersonate({ email: impersonateEmail })

      localStorage.setItem(IMPERSONATION_STORAGE_KEY, string)
      window.location.reload()
    } catch (e) {
      setImpersonateError(e.message)
    }
  }

  const stopImpersonating = async () => {
    const existingUserString = localStorage.getItem(IMPERSONATION_STORAGE_KEY)
    if (existingUserString !== null) {
      try {
        const existingUser = JSON.parse(existingUserString)
        localStorage.setItem(
          SESSION_TOKEN_STORAGE_KEY,
          existingUser.existingToken
        )
      } catch (e) {
        console.log(e)
      }
    }
    // Important to remove this key from local storage, which is how we tell
    // if we're impersonating someone.
    localStorage.removeItem(IMPERSONATION_STORAGE_KEY)
    window.location.reload()
  }

  return (
    <Fragment>
      {showEnvIndicator && (
        <div
          onClick={() => setIsOpen(true)}
          css={css`
            z-index: ${zIndex.modal};
            cursor: pointer;
            position: fixed;
            bottom: 0;
            right: 0;
            background-color: rgba(255, 255, 255, 0.5);
            border-top-left-radius: 6px;
            padding: 4px 4px 4px 8px;
            font-size: 12px;
            color: black;
            text-align: right;
          `}
        >
          {existingUser && (
            <>
              <div>Existing user: {existingUser?.email}</div>
              <div
                css={css`
                  color: yellow;
                  margin-bottom: 4px;
                `}
              >
                Impersonating {email}
              </div>
            </>
          )}

          <Flex>
            <Icon
              name='server'
              color='black'
              css={css`
                margin-right: 4px;
              `}
            />
            API Environment: {env}
          </Flex>
        </div>
      )}
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div
          css={css`
            width: 320px;
            max-width: 320px;
          `}
        >
          <Container>
            <Title>Environment</Title>
            <Flex
              justifyContent='space-between'
              css={css`
                width: 100%;
                margin-bottom: 16px;
              `}
            >
              <EnvButton
                isActive={env === Environments.LOCAL}
                onClick={() => setEnv(Environments.LOCAL)}
              >
                Local
              </EnvButton>
              <EnvButton
                isActive={env === Environments.DEV}
                onClick={() => setEnv(Environments.DEV)}
              >
                Dev
              </EnvButton>
              <EnvButton
                isActive={env === Environments.PROD}
                onClick={() => setEnv(Environments.PROD)}
              >
                Prod
              </EnvButton>
            </Flex>
            <Input
              autoCapitalize='none'
              autoCorrect='off'
              onChange={(e) => setCustomEnv(e.target.value)}
              placeholder='Enter a custom origin'
              value={customEnv}
            />

            <SubmitButton onClick={onSetEnv} isSubmit>
              Change Environment
            </SubmitButton>

            <div
              css={css`
                font-size: 12px;
                color: white;
                margin-top: 24px;
              `}
            >
              {customEnv || env}
            </div>
          </Container>
          {existingUser && (
            <Container>
              <Title>Impersonating</Title>
              <p>{email}</p>
              <p
                css={css`
                  text-align: center;
                  font-size: 12px;
                  margin: 16px 0;
                `}
              >
                (Stop impersonating and return to {existingUser?.email}?)
              </p>
              <SubmitButton isSubmit onClick={stopImpersonating}>
                Stop Impersonating
              </SubmitButton>
            </Container>
          )}
          {/@sportninja\.com$/i.test(email) && !existingUser && (
            <Container>
              <Title>Impersonate User</Title>
              {impersonateError && (
                <p
                  css={css`
                    margin-bottom: 16px;
                    color: red;
                  `}
                >
                  {impersonateError}
                </p>
              )}
              <Input
                autoCapitalize='none'
                autoCorrect='off'
                onChange={(e) => setImpersonateEmail(e.target.value)}
                placeholder='Enter a user email'
                value={impersonateEmail}
              />
              <SubmitButton
                disabled={impersonateEmail?.length === 0}
                onClick={onImpersonate}
                isSubmit
              >
                Impersonate
              </SubmitButton>
            </Container>
          )}

          <SubmitButton onClick={() => setIsOpen(false)}>Close</SubmitButton>
        </div>
      </Modal>
    </Fragment>
  )
}

export default EnvSelect
