import req from '@sportninja/common/api/request'
import { useCallback, useState } from 'react'

export interface Waiver {
  id: string
  name: string
  waiver_content: string
}

export const useWaivers = (accountId?: string) => {
  const [data, setData] = useState<Waiver[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = useCallback(async () => {
    if (!accountId) {
      return
    }

    setLoading(true)
    setError(null)

    try {
      const response = await req(`/account/${accountId}/waiver/`)
      setData(response.data)
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error('An error occurred while fetching waivers')
      )
    } finally {
      setLoading(false)
    }
  }, [accountId])

  return {
    data,
    loading,
    error,
    refetch: fetchData,
  }
}
