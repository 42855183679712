/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { Link, useHistory } from 'react-router-dom'

import { Flex } from '../../components/Layout'
import { InitialsThumbnail } from '../../components/List/PersonCell'
import FavouriteButton from '../../components/Page/FavouriteButton'
import Picture from '../../components/Picture'
import { media, minMedia } from '../../components/Responsive'
import ResultCard from './ResultCard'
import { useContext } from 'react'
import { SearchContext } from './SearchContextProvider'

const SearchRow = ({
  children,
  to,
  iconName,
  onClick,
  item,
  pictureProps = {},
  subTitle,
  entityType,
  initials,
  isPlayerRow,
  wrapCss,
  sport = null,
}) => {
  const shouldDisplayFavourite = [
    ENTITY_TYPES.org,
    ENTITY_TYPES.schedule,
    ENTITY_TYPES.team,
  ].includes(entityType)

  const imageId = getImageThumbnailId(item)

  return (
    <Flex
      row
      alignItems='center'
      css={css`
        height: 56px;
        &:nth-of-type(odd) {
          background-color: #292e35;
        }
        padding: 8px 16px 8px 8px;

        transition: background-color 0.1s ease-in-out;
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        ${wrapCss}
      `}
    >
      <Flex
        className='search-row-link'
        as={Link}
        to={to}
        row
        alignItems='center'
        onClick={() => {
          onClick({
            entityType,
            id: item.id,
            imageId,
            initials,
            name: item.name,
          })
        }}
      >
        {entityType === ENTITY_TYPES.player && !imageId && initials ? (
          <InitialsThumbnail
            size={34}
            wrapCss={css`
              margin-right: 16px;
            `}
          >
            {initials}
          </InitialsThumbnail>
        ) : (
          <Picture
            size={isPlayerRow ? 'xxsmall' : 'kxsmall'}
            iconName={iconName}
            imageId={imageId}
            css={css`
              margin-right: ${isPlayerRow ? 16 : 8}px;
            `}
            {...pictureProps}
          />
        )}
        <div
          css={css`
            font-size: 15px;
          `}
        >
          {item.name} {sport ? `(${sport})` : ''}
          <div
            className='search-row-subtitle'
            css={css`
              color: ${colors.ATTENDANCE_GRAY};
              font-size: 13px;
              margin-top: 3px;
            `}
          >
            {subTitle}
          </div>
        </div>
      </Flex>
      {children}
      {shouldDisplayFavourite && (
        <div
          css={css`
            margin-left: 8px;
          `}
        >
          <FavouriteButton
            isHeader={false}
            initialState={item.is_favorite}
            entityType={entityType}
            entityId={item.id}
          />
        </div>
      )}
    </Flex>
  )
}

export const PlayerRow = ({ item, onClick }) => {
  const [first, last] = item.name.split(' ')
  const fInitial = first?.charAt(0) || ''
  const lInitial = last?.charAt(0) || ''
  const initials = `${fInitial}${lInitial}`
  const sport = item?.sport
    ? item?.sport.charAt(0)?.toUpperCase() + item?.sport?.slice(1)
    : ''

  const history = useHistory()
  const searchContext = useContext(SearchContext)

  let teamOne,
    teamString = ''
  if (Array.isArray(item.teams) && item.teams.length > 0) {
    teamOne = item.teams[0]
    if (item.teams.length > 1) {
      const remainder = item.teams.slice(1).length
      teamString = ` & ${remainder} other${remainder === 1 ? '' : 's'}`
    }
  }

  let numAndPos = ''
  if (typeof teamOne?.jersey_number !== 'undefined' && teamOne?.position) {
    numAndPos = `#${teamOne.jersey_number} - ${teamOne.position}`
  }

  const subTitle = teamOne ? (
    <Flex
      alignItems='center'
      justifyContent='space-between'
      css={css`
        flex-basis: 15%;

        ${media.mobile} {
          flex-basis: 0;
        }
      `}
    >
      {numAndPos && <Flex className='player-row-position'>{numAndPos}</Flex>}
      <Flex
        alignItems='center'
        onClick={() => {
          searchContext.setIsActive(false)
          history.push(`${ROUTES.TEAM_ROOT}/${teamOne.id}/roster`, {
            rosterId: teamOne.roster_id,
          })
        }}
        css={css`
          cursor: pointer;
        `}
      >
        <Picture
          size='xxsmall'
          fit
          square
          iconName='user-friends'
          imageId={getImageThumbnailId(item?.teams?.[0])}
        />
        <div
          css={css`
            margin-left: 16px;
          `}
        >
          {teamOne.name}
          <div
            css={css`
              opacity: 0.5;
              font-size: 13px;
              margin-top: 2px;
            `}
          >
            {teamString}
          </div>
        </div>
      </Flex>
    </Flex>
  ) : (
    false
  )

  return (
    <SearchRow
      isPlayerRow
      iconName='user'
      item={item}
      entityType={ENTITY_TYPES.player}
      onClick={onClick}
      to={`${ROUTES.PLAYER_ROOT}/${item.id}`}
      initials={initials}
      sport={sport}
      subTitle={numAndPos}
      wrapCss={css`
        .search-row-link {
          min-width: 150px;
        }

        ${media.mobile} {
          .player-row-position {
            display: none;
          }
        }
        ${minMedia.mobile} {
          .search-row-subtitle {
            display: none;
          }
        }
      `}
    >
      {subTitle}
    </SearchRow>
  )
}

export const TeamRow = ({ item, onClick }) => {
  const sport = item?.sport
    ? item?.sport.charAt(0)?.toUpperCase() + item?.sport?.slice(1)
    : ''
  return (
    <SearchRow
      iconName='user-friends'
      item={item}
      entityType={ENTITY_TYPES.team}
      subTitle={item?.organization?.name}
      onClick={onClick}
      to={`${ROUTES.TEAM_ROOT}/${item.id}`}
      pictureProps={{ square: true, fit: true }}
      sport={sport}
    />
  )
}

export const SchedRow = ({ item, onClick }) => {
  const sport = item?.sport
    ? item?.sport.charAt(0)?.toUpperCase() + item?.sport?.slice(1)
    : ''
  let subTitle = item?.organization ? item?.organization?.name : ''
  subTitle += sport ? ` • ${sport}` : ''
  if (item?.schedule_type_name !== 'Season' && item?.root) {
    subTitle += ` > ${item?.root?.name}`
  }

  subTitle += `${subTitle.length > 0 ? ' • ' : ''}${item.schedule_type_name}`
  return (
    <ResultCard
      to={`${ROUTES.SCHEDULE_ROOT}/${item.id}`}
      onClick={() => {
        onClick({
          entityType: ENTITY_TYPES.schedule,
          id: item.id,
          imageId: getImageThumbnailId(item),
          name: item.name,
        })
      }}
      iconName='calendar-alt'
      imageId={getImageThumbnailId(item)}
      name={item.name}
      subTitle={subTitle}
      sport={sport}
    />
  )
}

export const OrgRow = ({ item, onClick }) => {
  return (
    <SearchRow
      to={`${ROUTES.ORG_ROOT}/${item.id}`}
      entityType={ENTITY_TYPES.org}
      subTitle={item?.parent?.name}
      onClick={onClick}
      iconName='users'
      pictureProps={{ square: true, fit: true }}
      item={item}
    />
  )
}

export const VenueRow = ({ item, onClick }) => {
  const toRoute =
    item?.organizations && item?.organizations?.length > 0
      ? `${ROUTES.ORG_ROOT}/${item.organizations[0].id}/locations`
      : item?.schedules && item?.schedules.length > 0
      ? `${ROUTES.SCHEDULE_ROOT}/${item.schedules[0]?.id}/locations`
      : '/'

  let subtitle = ''
  if (item?.address) {
    if (item?.address?.street_1) {
      subtitle += item.address.street_1
    }
    if (item?.address?.street_2) {
      subtitle += ` ${item.address.street_2}`
    }
    if (item?.address?.city) {
      subtitle += `, ${item.address.city}`
    }
  }
  if (item?.organizations && item?.organizations?.length > 0) {
    subtitle += ` - ${
      item?.organizations[0]?.name_full || item?.organizations[0]?.name || ''
    }`
  } else {
    subtitle += ' - No parent organization'
  }
  if (item?.schedules && item?.schedules?.length > 0) {
    subtitle += ` - ${item?.schedules[0]?.name || ''}`
  } else {
    subtitle += ' - No parent schedule'
  }
  return (
    <SearchRow
      to={toRoute}
      entityType={ENTITY_TYPES.venue}
      subTitle={subtitle}
      onClick={onClick}
      iconName='home'
      pictureProps={{ square: true, fit: true }}
      item={item}
    />
  )
}

export default SearchRow
