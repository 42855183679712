/** @jsxImportSource @emotion/react */
import { CloseOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import {
  getAllDefaultScheduleSettingsTypes,
  getAllGenericSportProperties,
  getAllStatTypes,
  getSports,
} from '@sportninja/common/selectors/types'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { App, Modal, Tabs } from 'antd'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import weekday from 'dayjs/plugin/weekday'
import { connect } from 'react-redux'
import GeneralForm from './GeneralForm'
import SettingsForm from './SettingsForm'
import './styles.scss'
import { ScheduleProvider, useScheduleContext } from './ScheduleContext'
import { useCallback, useEffect, useMemo, useState } from 'react'
import DaysmartForm from './DaysmartForm'
// import CustomizationForm from './CustomizationForm'
import ContactInfoForm from './ContactInfoForm'
import Button from '../ts-components/Button'
import Text from '../ts-components/Text'
import { paragraphMedium, weightRegular } from '../css'
import useWindowSize from 'src/hooks/useWindowSize'
import { useModalScroll } from 'src/hooks/useModalScroll'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const labelById = {
  0: 'General',
  1: 'Settings',
  2: 'Contact Info',
  3: 'Daysmart',
  // 4: 'Customization',
}

const styles = css`
  .my-modal-body {
  }
  .my-modal-mask {
    backdrop-filter: blur(1px);
  }
  .my-modal-header {
    border-bottom: 1px dotted ${colors.FIERY_RED};
  }
  .my-modal-footer {
    color: ${colors.FIERY_RED};
  }
  .my-modal-content {
    border: 1px solid #333;
  }
`
const classNames = {
  body: styles['my-modal-body'],
  mask: styles['my-modal-mask'],
  header: styles['my-modal-header'],
  footer: styles['my-modal-footer'],
  content: styles['my-modal-content'],
}
const modalStyles = {
  header: {
    backgroundColor: colors.SECONDARY_300,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  body: {
    borderBottom: `1px solid ${colors.SOFT_STEEL}`,
    borderTop: `1px solid ${colors.SOFT_STEEL}`,
    backgroundColor: colors.SECONDARY_300,
  },
  content: {
    backgroundColor: colors.SECONDARY_300,
    paddingTop: '8px',
    paddingBottom: '16px',
    paddingLeft: '0',
    paddingRight: '0',
  },
  wrapper: {
    zIndex: 10001,
  },
  mask: {
    // backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(6, 10, 16, 0.8)',
    zIndex: 10000,
  },
  footer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

const closeStyle = {
  color: colors.WHITE,
  fontSize: 20,
}

const ScheduleModalContent = ({
  onCancel,
  triggerRefresh,
  addPending,
  isEdit,
  isDaysmartConnectedAtOrgLevel = false,
  isFormOpen,
  setIsFormOpen,
}) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width <= 768, [width])
  const [activeTab, setActiveTab] = useState('0')
  const {
    daySmartSeasonName,
    onSubmitGeneralForm,
    onSubmitContactInfo,
    onSubmitSettings,
    onSubmitCustomization,
    onSubmitDaysmart,
    isLoading,
    isSyncing,
    hasFilledNameAndAbbreviationAndDates,
  } = useScheduleContext()

  const shouldDisableSettingsTab =
    !hasFilledNameAndAbbreviationAndDates && !isEdit
  const shouldDisableDaysmartTab = !daySmartSeasonName || !isEdit

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onCancel()
      }
    }

    document.addEventListener('keydown', handleEscapeKey)

    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [onCancel])

  const getFormById = (id) => {
    switch (id) {
      case '0':
        return (
          <GeneralForm
            onCancelPressed={onCancel}
            triggerRefresh={triggerRefresh}
            isMobile={isMobile}
          />
        )
      case '1':
        return (
          <SettingsForm
            onCancelPressed={onCancel}
            triggerRefresh={triggerRefresh}
            addPending={addPending}
            isMobile={isMobile}
          />
        )
      case '2':
        return (
          <ContactInfoForm
            onCancelPressed={onCancel}
            triggerRefresh={triggerRefresh}
            isMobile={isMobile}
          />
        )
      case '3':
        return (
          <DaysmartForm
            onCancelPressed={onCancel}
            triggerRefresh={triggerRefresh}
            isMobile={isMobile}
          />
        )
      // case '4':
      //   return (
      //     <CustomizationForm
      //       onCancelPressed={onCancel}
      //       triggerRefresh={triggerRefresh}
      //     />
      //   )
      default:
        return null
    }
  }

  const getFooter = useCallback(() => {
    const isFirstTab = activeTab === '0'
    let isLastTab = false
    if (isEdit) {
      // if it's an edit, the last tab is always true
      isLastTab = true
    } else {
      if (isDaysmartConnectedAtOrgLevel && !shouldDisableDaysmartTab) {
        isLastTab = activeTab === '3'
      } else {
        isLastTab = activeTab === '2'
      }
    }
    const handleSaveClick = async () => {
      if (isEdit || isLastTab) {
        if (activeTab === '0') {
          await onSubmitGeneralForm()
          return
        }
        if (activeTab === '1') {
          await onSubmitSettings()
          return
        }
        if (activeTab === '2') {
          await onSubmitContactInfo()
          return
        }
        if (activeTab === '3') {
          await onSubmitDaysmart()
          return
        }
        if (activeTab === '4') {
          await onSubmitCustomization()
          return
        }
      } else {
        setActiveTab((parseInt(activeTab, 10) + 1).toString())
      }
    }

    const handleCancelClick = () => {
      if (isEdit) {
        onCancel()
        return
      }
      if (isFirstTab) {
        onCancel()
        return
      }
      setActiveTab((parseInt(activeTab, 10) - 1).toString())
      return
    }

    return (
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          gap: 16px;
          width: 50%;
          margin-left: auto;
          ${isMobile &&
          css`
            width: 100%;
            flex-direction: column-reverse;
          `}
        `}
      >
        <Button
          text={!isEdit && !isFirstTab ? 'Back' : 'Cancel'}
          onClick={handleCancelClick}
          variant='secondary'
          disabled={isLoading}
        />
        <Button
          text={!isEdit && !isLastTab ? 'Next' : isEdit ? 'Save' : 'Finish'}
          onClick={handleSaveClick}
          variant='primary'
          disabled={
            isLoading || isSyncing || !hasFilledNameAndAbbreviationAndDates
          }
        />
      </div>
    )
  }, [
    activeTab,
    isEdit,
    isMobile,
    isLoading,
    isSyncing,
    hasFilledNameAndAbbreviationAndDates,
    isDaysmartConnectedAtOrgLevel,
    shouldDisableDaysmartTab,
    onSubmitGeneralForm,
    onSubmitContactInfo,
    onSubmitSettings,
    onSubmitDaysmart,
    onSubmitCustomization,
    onCancel,
  ])

  const getHeader = useCallback(() => {
    return (
      <Text variant='heading3' weight='semiBold' color={colors.WHITE}>
        {isEdit
          ? `${isCanlan ? 'Season' : 'Competition'} Details`
          : `Create New ${isCanlan ? 'Season' : 'Competition'}`}
      </Text>
    )
  }, [isEdit])

  const closeIcon = useCallback(() => {
    return (
      <CloseOutlined
        css={css`
          color: ${colors.SOLITUDE};
          font-size: 16px;
        `}
      />
    )
  }, [])

  const tabItems = [
    {
      label: labelById[0],
      key: '0',
      children: getFormById('0'),
    },
    {
      label: labelById[1],
      key: '1',
      children: getFormById('1'),
    },
    {
      label: labelById[2],
      key: '2',
      children: getFormById('2'),
      // disabled: shouldDisableSettingsTab,
    },
  ]

  if (isDaysmartConnectedAtOrgLevel && !shouldDisableDaysmartTab) {
    tabItems.push({
      label: labelById[3],
      key: '3',
      children: getFormById('3'),
      disabled: shouldDisableDaysmartTab,
    })
  }

  // tabItems.push({
  //   label: labelById[4],
  //   key: '4',
  //   children: getFormById('4'),
  // })

  return (
    <Modal
      css={css`
        display: flex;
        flex: 1;
        height: ${isMobile ? '100%' : '90vh'} !important;
        margin: ${isMobile ? '0' : '24px'};
        padding: 0;
        max-width: ${isMobile ? '100%' : '1080px'};
        top: ${isMobile ? '0' : '50%'};

        .ant-modal-content {
          height: ${isMobile ? '100%' : '90vh'} !important;
          border-radius: ${isMobile ? '0' : '8px'};
          display: flex;
          flex-direction: column;
        }
        .ant-modal-body {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
        }
        .ant-tabs-tabpane {
          background: ${colors.SECONDARY_300} !important;
        }
        .ant-tabs-nav {
          min-height: ${isMobile ? 'auto' : '600px'} !important;
        }
        .ant-tabs-tab {
          height: 40px !important;
        }
        /* if the tab is active, change the background color */
        .ant-tabs-tab-active {
          background-color: #26303e;
        }
        .ant-tabs-tab-btn {
          ${paragraphMedium}
          ${weightRegular}
        }
        .ant-tabs-tab-btn[aria-selected='true'] {
          color: ${colors.PRIMARY}!important;
        }
        .ant-tabs-tab-btn[aria-selected='false'] {
          color: ${colors.NEUTRAL_30}!important;
        }
        .ant-tabs-tab.ant-tabs-tab-disabled {
          color: ${colors.NEUTRAL_30}!important;
          cursor: not-allowed !important;
          opacity: 0.5 !important;
        }
      `}
      width={isMobile ? '100%' : 1080}
      closeIcon={closeIcon()}
      title={getHeader()}
      footer={getFooter()}
      open={isFormOpen}
      onCancel={onCancel}
      centered
      classNames={classNames}
      styles={modalStyles}
      maskClosable={false}
    >
      <Tabs
        activeKey={activeTab}
        defaultActiveKey='0'
        onChange={(key) => setActiveTab(key)}
        tabPosition={isMobile ? 'top' : 'left'}
        css={css`
          display: flex;
          flex: 1;
          height: 100%;
          background: ${colors.SECONDARY_400} !important;
          ${isMobile &&
          css`
            background: inherit !important;
          `}
          .ant-tabs-top > .ant-tabs-nav {
            margin: 0 !important;
          }
          .ant-tabs-tabpane {
            background: ${colors.SECONDARY_300} !important;
          }
          .ant-tabs-nav {
            min-height: ${isMobile ? 'auto' : '600px'} !important;
          }
          .ant-tabs-tab {
            ${isMobile ? 'height: 40px !important;' : ''}
            ${isMobile ? 'padding: 0 16px !important;' : ''}
          }
          /* if the tab is active, change the background color */
          .ant-tabs-tab-active {
            background-color: #26303e;
          }
          .ant-tabs-tab-btn {
            ${paragraphMedium}
            ${weightRegular}
          }
          .ant-tabs-tab-btn[aria-selected='true'] {
            color: ${colors.PRIMARY}!important;
          }
          .ant-tabs-tab-btn[aria-selected='false'] {
            color: ${colors.NEUTRAL_30}!important;
          }
          .ant-tabs-tab.ant-tabs-tab-disabled {
            color: ${colors.NEUTRAL_30}!important;
            cursor: not-allowed !important;
            opacity: 0.5 !important;
          }
        `}
        items={tabItems}
      />
    </Modal>
  )
}

const ScheduleModal = ({
  initialState = null,
  onCancel,
  triggerRefresh,
  parentOrgId,
  displayCanlanForm = false,
  genericSports,
  sports,
  defaultScheduleSettingsForAllSports,
  addPending = () => {},
  onFinishTutorial = () => {},
  isDaysmartConnectedAtOrgLevel = false,
  isFormOpen,
  setIsFormOpen,
  setIsCompetitionSetupModalOpen,
  setNewCompetitionId,
  allStatTypes,
}) => {
  const isEdit = initialState?.name_full ? true : false
  const [existingScheduleSettings, setExistingScheduleSettings] = useState(null)
  const { message } = App.useApp()

  const { openModal, closeModal } = useModalScroll()
  useEffect(() => {
    if (isFormOpen) {
      openModal()
    } else {
      closeModal()
    }
  }, [closeModal, isFormOpen, openModal])

  useEffect(() => {
    const fetchExistingScheduleSettings = async () => {
      try {
        const { data } = await req(`/schedules/${initialState?.id}/settings`)
        setExistingScheduleSettings(data)
      } catch (e) {
        setExistingScheduleSettings(null)
      }
    }
    if (initialState?.id) {
      fetchExistingScheduleSettings()
    }
  }, [initialState?.id])

  if (!isFormOpen) {
    return null
  }

  return (
    <ScheduleProvider
      id={initialState?.id}
      initialState={initialState}
      sports={sports}
      genericSports={genericSports}
      closeForm={onCancel}
      parentOrgId={parentOrgId}
      defaultScheduleSettingsForAllSports={defaultScheduleSettingsForAllSports}
      existingScheduleSettings={existingScheduleSettings}
      displayCanlanForm={displayCanlanForm}
      onFinishTutorial={onFinishTutorial}
      message={message}
      triggerRefresh={triggerRefresh}
      setIsCompetitionSetupModalOpen={setIsCompetitionSetupModalOpen}
      setNewCompetitionId={setNewCompetitionId}
      allStatTypes={allStatTypes?.filter((stat) => stat.is_team_stat === true)}
    >
      <ScheduleModalContent
        onCancel={onCancel}
        triggerRefresh={triggerRefresh}
        addPending={addPending}
        isEdit={isEdit}
        isDaysmartConnectedAtOrgLevel={isDaysmartConnectedAtOrgLevel}
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
      />
    </ScheduleProvider>
  )
}

const mapStateToProps = (state, { sportId }) => {
  const allStatTypes = getAllStatTypes(state)
  return {
    genericSports: getAllGenericSportProperties(state),
    sports: Object.values(getSports(state)),
    defaultScheduleSettingsForAllSports:
      getAllDefaultScheduleSettingsTypes(state),
    allStatTypes,
  }
}

const mapDispatchToProps = (_) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleModal)
