/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useState } from 'react'
import { toArray } from '@sportninja/common/utils/utils'
import { t } from '@sportninja/common/i18n'
import { getGoalTypesBySportId } from '@sportninja/common/selectors/types'
import { connect } from 'react-redux'

import { SelectFormInput } from './Select'

const GoalPicker = ({
  defaultValues,
  disabled,
  goalTypes,
  onChange,
  roster,
  isSoccer = false,
  isEdit = false,
}) => {
  const { assists, player_id, goal_type_id } = defaultValues

  let players = []
  if (roster) {
    players = toArray(roster)
  }

  const assistOneId = assists && assists[0] ? assists[0].assistId : null
  const assistTwoId = assists && assists[1] ? assists[1].assistId : null

  // This is the player id - we track the assist id's separately
  const assistOne = assists && assists[0] ? assists[0].id : ''
  const assistTwo = assists && assists[1] ? assists[1].id : ''

  const [scorer, setScorer] = useState(
    player_id ? player_id : isEdit && !player_id ? 'unassigned' : ''
  )

  const [firstAssist, setFirstAssist] = useState(assistOne)
  const [secondAssist, setSecondAssist] = useState(assistTwo)
  const [goalTypeId, setGoalTypeId] = useState(goal_type_id)

  const assistHandler = (name, value) => {
    onChange({
      target: {
        name,
        value: {
          assistId: name === 'first_assist' ? assistOneId : assistTwoId,
          playerId: value,
        },
      },
    })
  }

  const handleChange = (setState, event) => {
    const name = event.target.name
    let value = event.target.value

    // This just handles ensuring that a player can be selected in only one
    // field at a time
    if (setState !== setFirstAssist && value !== '' && value === firstAssist) {
      setFirstAssist('')
      assistHandler('first_assist', '')
    }
    if (
      setState !== setSecondAssist &&
      value !== '' &&
      value === secondAssist
    ) {
      setSecondAssist('')
      assistHandler('second_assist', '')
    }
    if (setState !== setScorer && value === scorer) {
      setScorer('')
      onChange({ target: { name: 'player_id', value: '' } })
    }

    // We always want to update whatever state we just changed
    setState(value)

    // As far as updating the form, handle assists differently since we include
    // additional information
    if (setState !== setScorer) {
      assistHandler(name, value)
    } else {
      onChange(event)
    }
  }

  const playerOptions = players.map((player) => (
    <option key={player.id} value={player.id}>
      {player.number} - {player.fullName}
    </option>
  ))

  // Colors the option text to emulate placeholder text
  const selectCss = (value) => {
    if (value === '') {
      return css`
        select {
          color: rgba(255, 255, 255, 0.5);
        }
      `
    }
  }

  return (
    // FormRows are display: flex so we need to add a single block-level element
    // to make these full width.
    <div
      css={css`
        width: 100%;
      `}
    >
      <SelectFormInput
        css={css`
          padding: 0 !important;
        `}
        value={scorer}
        disabled={disabled}
        label={t('Web:goalScorer')}
        name='player_id'
        onChange={handleChange.bind(this, setScorer)}
        required
      >
        <option value=''>{t('Scoring:selectGoalScorer')}:</option>
        <option disabled>-----</option>
        {/* unassigned */}
        <option value='unassigned'>Unassigned</option>
        {playerOptions}
      </SelectFormInput>

      <SelectFormInput
        css={[
          css`
            padding: 0 !important;
            margin-top: 20px;
          `,
          selectCss(firstAssist),
        ]}
        value={firstAssist}
        disabled={disabled || scorer === 'unassigned'}
        label={t('Scoring:firstAssist')}
        name='first_assist'
        onChange={handleChange.bind(this, setFirstAssist)}
      >
        <option value=''>{t('common:none')}</option>
        <option disabled>-----</option>
        {playerOptions}
      </SelectFormInput>

      <SelectFormInput
        css={[
          css`
            padding: 0 !important;
            margin-top: 20px;
          `,
          selectCss(secondAssist),
        ]}
        value={secondAssist}
        disabled={disabled || scorer === 'unassigned'}
        label={t('Scoring:secondAssist')}
        name='second_assist'
        onChange={handleChange.bind(this, setSecondAssist)}
      >
        <option value=''>{t('common:none')}</option>
        <option disabled>-----</option>
        {playerOptions}
      </SelectFormInput>

      <SelectFormInput
        css={[
          css`
            padding: 0 !important;
            margin-top: 20px;
          `,
          selectCss(goalTypeId),
        ]}
        value={goalTypeId}
        disabled={disabled}
        label='Goal Type'
        name='goal_type_id'
        onChange={(event) => {
          setGoalTypeId(event.target.value)
          onChange(event)
        }}
      >
        <option value=''>{t('common:none')}</option>
        <option disabled>-----</option>
        {goalTypes.map((type) => {
          return (
            <option key={type.id} value={type.id}>
              {type?.name}
            </option>
          )
        })}
      </SelectFormInput>
    </div>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    goalTypes: getGoalTypesBySportId(state, sportId),
  }
}

export default connect(mapStateToProps)(GoalPicker)
