/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import colors from '@sportninja/common/constants/appColors'

import { font } from '../css'
import Icon from '../Icon'
import LoadingSpinner from '../LoadingSpinner'

import uploadMenu from './upload_menu.svg'
import { Tooltip } from 'antd'

const BaseActionButton = ({
  baseCss,
  disabled,
  loading,
  onClick,
  name,
  title,
  faType,
  customIcon,
}) => {
  return (
    <Tooltip key={name} title={title} placement='bottom' mouseEnterDelay={1}>
      <button
        disabled={disabled}
        // Replace title with Tooltip
        // title={title}
        className='header-action'
        type='button'
        onClick={onClick}
        css={css`
          ${baseCss}
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;

          border: ${isCanlan ? 2 : 1}px solid ${colors.DEFAULT_FLAIR};
          border-radius: 4px;

          ${font.title}
          font-weight: 600;
          font-size: 18px;

          letter-spacing: 0.015em;
          text-transform: uppercase;
          color: white;

          transition: border-color 0.1s ease-in-out;

          &:hover:not([disabled]) {
            border-color: white;
          }

          opacity: ${disabled || loading ? 0.5 : 1};
          &,
          .is--loading {
            cursor: ${disabled || loading ? 'not-allowed' : 'pointer'};
          }
        `}
      >
        {loading ? (
          <LoadingSpinner size={1} borderSize={2} />
        ) : name === 'multi-roster' ? (
          <div
            css={css`
              width: 18px;
              height: 18px;
              justify-content: center;
              align-items: center;
              display: flex;
              overflow: hidden;
            `}
          >
            <img
              css={css`
                width: 100%;
                height: 100%;
                resize: contain;
              `}
              src={uploadMenu}
              alt={name}
            />
          </div>
        ) : name === 'export-multi-roster' ? (
          <div
            css={css`
              width: 18px;
              height: 18px;
              justify-content: center;
              align-items: center;
              display: flex;
              overflow: hidden;
            `}
          >
            <img
              css={css`
                width: 100%;
                height: 100%;
                resize: contain;
                transform: rotate(180deg); // Rotate the image 180 degrees
              `}
              src={uploadMenu}
              alt={name}
            />
          </div>
        ) : customIcon ? (
          customIcon
        ) : (
          <Icon faType={faType} name={name} fontSize={13} />
        )}
      </button>
    </Tooltip>
  )
}

export default BaseActionButton
