import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { ROLE_TYPE_ID_TO_STRING, ROUTES } from '../constants/app'
import { ENTITY_TYPES } from '../sagas/utils'
import { getAddress, getBirthDateString, getImageThumbnailId } from './helpers'
import Roles from '../constants/officialTypes'

dayjs.extend(customParseFormat)

const venue = (input) => {
  return {
    id: input.id,
    address: input.address,
    addressString: getAddress({ primary_venue: input }).value,
    coords: input &&
      input.address &&
      input.address.latitude && [
        input.address.latitude,
        input.address.longitude,
      ],
    fullName: input.name_full,
    facilities: input.facilities,
    timezone: input.timezone,
  }
}

const inviteParser = (input = {}) => ({
  userId: input.id,
  email: input.email,
  invited: input.is_invited,
  accepted: input.is_accepted,
  roleTypeId: input.role_type_id,
  invitationId: input.invitation_id,
})

const entityUsers = (input) => {
  return {
    id: input.id,
    invite: inviteParser(input),
    message_text: input.message,
    description: input.description,
  }
}

const _entityDetails = (input = {}) => {
  if (input === null) {
    return {}
  }

  const mapped = {
    abbrev: input.abbreviation,
    fullName: input.name_full,
    id: input.id,
    imageId: getImageThumbnailId(input),
    backgroundImageId: getImageThumbnailId({
      image: input?.background_image,
    }),

    // Contact information
    email: input.email,
    phone: input.phone,
    website: input.website,

    // Support information
    support_website: input?.support_website || null,
    support_email: input?.support_email || null,
    support_phone: input?.support_phone || null,

    // Parent Org
    parentAbbrev: input.organization && input.organization.abbreviation,
    parentName: input.organization && input.organization.name_full,
    parentId: input.organization && input.organization.id,
    parentImageId: getImageThumbnailId(input.organization),

    permission: input.permission,
    isPublic: input.is_public,
    isVisible: input.is_visible,
    isFollowing: input.is_favorite,
    isOwner: input?.is_owner || false,

    hierarchy: Array.isArray(input.hierarchy)
      ? input.hierarchy.filter((item) => item.uid !== input.id)
      : undefined,
  }

  if (Array.isArray(input.venues)) {
    mapped.locations = input.venues.map(venue)
  }
  if (Array.isArray(input.users)) {
    mapped.users = input.users.map(entityUsers)
  }
  if (Array.isArray(input.officials)) {
    mapped.officials = input.officials.map(officialParser)
  }

  return mapped
}

const player = (input) => {
  // TODO: This is bad. This maintains its own copy of a team (instead of referencing
  // the team from the store)
  const teams = input.team_player_rosters.map((roster) => {
    return {
      abbreviation: roster.team.abbreviation,
      fullName: roster.team.name_full,
      activeRosterId: roster.id,
      id: roster.team.id,
      imageId: getImageThumbnailId(roster.team),
      isAffiliate: roster.team.is_affiliate,
      is_suspended: roster.team?.is_suspended === true,
      is_injured: roster.team?.is_injured === true,
      name: roster.team.name,
      number: roster.player_number,
      position: roster.player_type && roster.player_type.name_full,
      playerTypeId: roster.player_type_id,
      to: `${ROUTES.TEAM_ROOT}/${roster.team.id}`,
      parentAbbrev:
        roster.team.organization && roster.team.organization.abbreviation,
      parentId: roster.team.organization && roster.team.organization.id,
      parentName:
        roster.team.organization && roster.team.organization.name_full,
      playerCount: roster.team.players_count,
    }
  })

  return {
    id: input.id,
    leagueNumber: input.league_registration_number,
    teams,
  }
}

const user = (input) => {
  const mapped = {
    // TODO: This might get removed and you'll have to find a different way to
    // determine if the user has accepted an invitation or not!!!
    email: input.email,
    birthDate: input.birth_date,
    height: input.height,
    isValidated: input.is_validated,
    id: input.id,
    imageId: getImageThumbnailId(input),
    isPublic: input.is_public,
    isVisible: input.is_visible,
    nameFirst: input.name_first,
    nameMiddle: input.name_middle,
    nameLast: input.name_last,
    weight: input.weight,
    account_type: input.account_type,
    to: `${ROUTES.USER_ROOT}/${input.id}`,
    roleType: ROLE_TYPE_ID_TO_STRING[input.role_type_id],
    phone_number: input?.phone_number,
  }

  if (
    typeof input?.name_first === 'string' ||
    typeof input?.name_last === 'string'
  ) {
    mapped.fullName = `${input.name_first ? input.name_first : ''} ${
      input.name_last ? input.name_last : ''
    }`
  }

  return mapped
}

const org = (input) => {
  const mapped = {
    ..._entityDetails(input),
    to: `${ROUTES.ORG_ROOT}/${input.id}`,
    entityType: ENTITY_TYPES.org,

    canContainOrgs: input.can_contain_organizations,
    canContainTeams: input.can_contain_teams,
    canContainSchedules:
      typeof input.can_contain_schedules === 'boolean'
        ? input.can_contain_schedules
        : true,
    canContainLocations:
      typeof input.can_contain_locations === 'boolean'
        ? input.can_contain_locations
        : true,
    canContainFeed:
      typeof input.can_contain_feeds === 'boolean'
        ? input.can_contain_feeds
        : true,

    childrenCount: input.children_count,
    scheduleCount: input.schedules_count,
    teamsCount: input.teams_count,
    // orgTypeId: input.organization_type_id,
    syncSalesforce: input?.sync_salesforce || false,
    salesforce_id: input?.sf_id || null,
  }

  if (Array.isArray(input.schedules)) {
    mapped.schedules = input.schedules.map((s) => s.id)
  }

  return mapped
}

const schedule = (input = {}) => {
  let rootSchedule
  if (input?.root_schedule) {
    rootSchedule = {}
    rootSchedule.id = input.root_schedule.id
    rootSchedule.name = input.root_schedule.name
    rootSchedule.fullName = input.root_schedule.name_full
    rootSchedule.org = input.root_schedule.organization
  }

  const mapped = {
    ..._entityDetails(input),
    to: `${ROUTES.SCHEDULE_ROOT}/${input.id}`,
    entityType: ENTITY_TYPES.schedule,
    start: input.starts_at,
    end: input.ends_at,
    gamesCount: input.games_count,
    teamsCount: input.teams_count,
    // Canlan-specific fields
    division_group: input.division_group,
    division_age: input.division_age,
    division_gender: input.division_gender,
    deposit: input.deposit,
    team_cost: input.team_cost,
    is_active: input.is_active,
    day_of_week: input.day_of_week,
    type: input.type,
    isTournament: input.is_tournament,
    bracketsCreated: input.brackets_created,
    rootSchedule,
    settings: input.settings,
    season_type: input?.season_type,
    online_registration_open: input?.online_registration_open,
    season_currency: input?.season_currency,
    tax_rate: input?.tax_rate,
    sport_id: input?.sport_id,
    sort_order: input?.sort_order,
    is_registration_on: input?.is_registration_on,
    generic_sport_id: input?.generic_sport_id,
    generic_sport_name: input?.generic_sport_name,
    lock_roster_date: input?.lock_roster_date,
    is_archive: input?.is_archive,
  }

  return mapped
}

export const individualPlayer = (player) => {
  const nameFirst = player.name_first
  const nameLast = player.name_last

  const invite = inviteParser(player)

  const info = {
    email: player.email,
    birthDate: getBirthDateString(player),
    fullName: `${nameFirst} ${nameLast}`,
    id: player.id,
    height: player.height,
    imageId: getImageThumbnailId(player),
    nameFirst,
    nameLast,

    invite,
    /*
     * Simplifies prop checking in React components if this is a string value
     * In particular, if this is 0 and we have a check like prop && <div>prop</div>
     * it will resolve to just 0 instead.
     */
    number: `${player.player_number}`,
    position: player?.player_type?.name_full,
    playerTypeId: player?.player_type?.id,
    positionAbbrev: player?.player_type?.abbreviation,
    weight: player.weight,
    isAffiliate: player.is_affiliate,
    to: `${ROUTES.PLAYER_ROOT}/${player.id}`,
    is_suspended: player?.is_suspended === true,
    is_injured: player?.is_injured === true,
    customer_identifier: player?.customer_identifier,
    suspensions: player?.suspensions,
    is_signed_waiver: player?.is_signed_waiver
      ? player?.is_signed_waiver
      : false,
    sf_id: player?.sf_id,
    phone_number: player?.phone_number,
  }

  return info
}

export const activePlayerRoster = (input) => {
  if (!input || !input.players) {
    return []
  }

  const cache = {}

  const roster = input.players.map((player) => {
    if (!player) {
      return
    }
    const info = individualPlayer(player)
    cache[player.id] = info
    return info
  })

  return { cache, roster }
}

const team = (input = {}) => {
  if (input === null) {
    return {}
  }

  const mapped = {
    ..._entityDetails(input),
    isPlaceholder: input.is_placeholder,
    entityType: ENTITY_TYPES.team,
    sportId: (input.sport && input.sport.id) || input.sport_id,
    playerCount: input.players_count,
    shouldShowWaiver: input?.should_show_waiver || false,
    sf_id: input?.sf_id,
    generic_sport_id: input?.generic_sport_id,
    generic_sport_name: input?.generic_sport_name,
    sport_id: (input.sport && input.sport.id) || input.sport_id,
    sync_salesforce: input?.organization?.sync_salesforce || false,
  }

  if (!mapped.isPlaceholder) {
    mapped.to = `${ROUTES.TEAM_ROOT}/${input.id}`
  }

  return mapped
}

const teamOfficialParser = (input) => ({
  nameFirst: input.name_first,
  nameMiddle: input.name_middle,
  nameLast: input.name_last,
  email: input.email,
  phone_number: input?.phone_number,
  fullName:
    input.full_name || input.name_first || input.name_last
      ? `${input.name_first} ${input.name_last}`
      : '',
  id: input.id,
  teamOfficialTypeId: parseInt(input.team_official_type, 10),
  isAttending: input.is_attending,
  invite: inviteParser(input),
})

const officialParser = (input) => ({
  nameFirst: input.name_first,
  nameMiddle: input.name_middle,
  nameLast: input.name_last,
  email: input.email,
  phone_number: input?.phone_number,
  fullName:
    input.full_name || input.name_first || input.name_last
      ? `${input.name_first} ${input.name_last}`
      : '',
  id: input.id,
  officialTypeId: parseInt(input.official_type, 10),
  invite: inviteParser(input),
})

const teamPlayerRoster = (input = {}) => {
  const roster = activePlayerRoster(input)

  return {
    roster: roster.roster,
    playerCache: roster.cache,
    active_player_roster: input,
    activeRosterId: input.id,
    rosterId: input.id,
    require_email: input?.require_email,
  }
}

const _gameOfficialParser = (input) => ({
  nameFirst: input.name_first,
  nameMiddle: input.name_middle,
  nameLast: input.name_last,
  fullName:
    input.full_name || input.name_first || input.name_last
      ? `${input.name_first} ${input.name_last}`
      : '',
  teamId: input.team_id,
  id: input.id,
  roleType: ROLE_TYPE_ID_TO_STRING[input.role_type_id],
  roleTypeId: input.role_type_id,
  description: input.description,
  official_type_id: input?.official_type_id,
  officialType: input?.official_type_id ? Roles[input?.official_type_id] : '',
})

export const countTeamShots = (homeTeamId, visitingTeamId, shots) => {
  let homeShotCount = 0,
    visitingShotCount = 0
  Array.isArray(shots) &&
    shots.forEach((shot) => {
      if (shot.team_id === homeTeamId) {
        homeShotCount++
      } else if (shot.team_id === visitingTeamId) {
        visitingShotCount++
      }
    })

  return [homeShotCount, visitingShotCount]
}

export const countTeamOffenses = (homeTeamId, visitingTeamId, offenses) => {
  if (!homeTeamId || !visitingTeamId || !Array.isArray(offenses)) {
    return [0, 0]
  }
  let homeOffenseCount = 0,
    visitingOffenseCount = 0

  offenses.forEach((offense = {}) => {
    const teamId = offense?.team_id
    if (!teamId) {
      return
    }

    if (teamId === homeTeamId) {
      homeOffenseCount++
    } else if (teamId === visitingTeamId) {
      visitingOffenseCount++
    }
  })

  return [homeOffenseCount, visitingOffenseCount]
}

export const countTeamGoals = (homeTeamId, visitingTeamId, goals) => {
  if (!homeTeamId || !visitingTeamId || !Array.isArray(goals)) {
    return ['', '']
  }
  let homeGoalCount = 0,
    visitingGoalCount = 0

  goals.forEach((goal = {}) => {
    if (!goal.shot?.team_id) {
      return
    }

    if (goal.shot.team_id === homeTeamId) {
      homeGoalCount++
    } else if (goal.shot.team_id === visitingTeamId) {
      visitingGoalCount++
    }
  })

  return [homeGoalCount, visitingGoalCount]
}

const parseCurrentPeriod = (input) => {
  if (!input || !input.periods || input.periods.length === 0) {
    return {}
  }
  const period = input.periods[input.periods.length - 1]
  return {
    currentPeriodName: period?.period_type?.name,
    currentPeriodFullName: period?.period_type?.name_full,
    currentPeriodTime:
      period?.clock_time && dayjs(period.clock_time, 'hh:mm:ss').format('m:ss'),
    periodInProgress: period?.started_at && !period?.ended_at,
    isOvertime: period?.period_type?.is_overtime || false,
  }
}

const timeline = (input) => {
  return {
    ...parseCurrentPeriod(input),

    shootout: input.shootout,

    endedAt: input.ended_at,
    endsAt: input.ends_at,
    startedAt: input.started_at,
    startsAt: input.starts_at,
    completed: typeof input.ended_at === 'string',
    started: typeof input.started_at === 'string',

    goals: input.goals,
    offenses: input.offenses,
    goalieChanges: input.goalieChanges,
    periods: input?.periods || [],
    shots: input.shots,

    homeTeamScore: input.home_team_score,
    visitingTeamScore: input.visiting_team_score,

    gameStatus: input.game_status_id,
    gameNumber: input.league_game_number,
    gameType: input.game_type_id,
    stats_processing: input.stats_processing,
    stats_rolledback: input.stats_rolledback,
  }
}

// TODO: Group together all team-based things into 'home' and 'visiting' objects?
const game = (input) => {
  const mapped = {
    ...timeline(input),
    generic_sport_id: input?.generic_sport_id,
    id: input.id,
    canScore: !!input?.permission?.score,
    isSubscribed: input.is_subscribed,

    parentId: input.schedule && input.schedule.id,
    parentSchedule:
      input.schedule && (input.schedule.name_full || input.schedule.name),
    parentScheduleType: input?.schedule?.type,
    parentOrg:
      input.schedule &&
      input.schedule.organization &&
      input.schedule.organization.name_full,
    parentOrgId:
      input.schedule &&
      input.schedule.organization &&
      input.schedule.organization.id,

    // These are the lists of players/users associated with the game specifically
    referees: input.referees && input.referees.map(_gameOfficialParser),
    scorekeepers:
      input.scorekeepers && input.scorekeepers.map(_gameOfficialParser),
    timekeepers:
      input.timekeepers && input.timekeepers.map(_gameOfficialParser),
    teamOfficials:
      input.team_officials && input.team_officials.map(_gameOfficialParser),
    officials: input.officials && input.officials.map(_gameOfficialParser),

    // Just point to the team - and retrieve it from store at access time
    homeTeamId:
      input.homeTeam?.id ||
      input.homeTeamSlot?.team_id ||
      input.homeTeamSlot?.id,

    // Just point to the team - and retrieve it from store at access time
    visitingTeamId:
      input.visitingTeam?.id ||
      input.visitingTeamSlot?.team_id ||
      input.visitingTeamSlot?.id,

    to: `${ROUTES.GAME_ROOT}/${input.id}`,
    permission: input.permission,
    facility: input.facility && input.facility.name,
    facilityId: input.facility && input.facility.id,
    scheduleId: input.schedule && input.schedule.id,
    rootSchedule: input.rootSchedule,

    broadcastId: input.broadcast_id,
    broadcast_video_url: input?.broadcast_video_url || null,

    // Used for desktop game list item, because periods don't exist in game list responses
    current_period: input.current_period,
    stats_rolledback: input?.stats_rolledback ? input?.stats_rolledback : false,
    should_show_waiver: input?.should_show_waiver
      ? input?.should_show_waiver
      : false,
    homeTeamActiveRoster: input?.homeTeam?.active_player_roster,
    visitingTeamActiveRoster: input?.visitingTeam?.active_player_roster,
    homeTeamSlot: input?.homeTeamSlot || {},
    visitingTeamSlot: input?.visitingTeamSlot || {},
    ifNecessaryStatus: input?.if_necessary_status,
    sport_id: input?.sport_id || null,
    organization_name: input?.organization_name,
    user_active_game_roles: input?.user_active_game_roles,
  }

  if (input?.playerRosters && Array.isArray(input.playerRosters)) {
    mapped.homeGameRosterId = input.playerRosters.find(
      (r) => r.team_id === mapped.homeTeamId
    )?.id
    mapped.visitingGameRosterId = input.playerRosters.find(
      (r) => r.team_id === mapped.visitingTeamId
    )?.id
  }

  if (input.venue) {
    mapped.location = venue(input.venue)
  }
  return mapped
}

const feedItem = (input) => {
  const { images, ...item } = input
  let image = {}

  if (images && images[0] && typeof images[0].full_path === 'string') {
    const img = images[0]

    if (Object.keys(img).length > 0) {
      image = {
        imageId: img.id,
        imagePath: img.full_path,
        imageWidth: img.width,
        imageHeight: img.height,
      }
    }
  }

  return { ...item, ...image }
}

const _wrap =
  (parser) =>
  (input, ...rest) => {
    if (Array.isArray(input)) {
      return input.map(parser)
    } else {
      return parser(input, ...rest)
    }
  }

// Each parser can take two argument types: array or object. The below code will
// wrap each parser in a convenience method that checks if an array or object
// was provided as argument.
const listOfParsers = {
  [ENTITY_TYPES.org]: org,
  [ENTITY_TYPES.schedule]: schedule,
  [ENTITY_TYPES.team]: team,
  [ENTITY_TYPES.game]: game,
  [ENTITY_TYPES.player]: player,

  teamPlayerRoster,
  entityUsers,
  user,
  venue,
  activePlayerRoster,
  feedItem,
  timeline,
  gameOfficial: _gameOfficialParser,
  teamOfficial: teamOfficialParser,
  official: officialParser,
  individualPlayer,
}

export default (() => {
  for (const parserName in listOfParsers) {
    const parser = listOfParsers[parserName]
    listOfParsers[parserName] = _wrap(parser)
  }

  return listOfParsers
})()
