import dayjs from 'dayjs'

/**
 * Formats a game date into uppercase format
 * Example output: "JAN 1 - 11:59 AM"
 * @param gameDate - dayjs date object to format
 * @returns Formatted date string in uppercase
 */
export const formatGameDate = (gameDate: dayjs.Dayjs): string => {
  const formattedDate: string = gameDate.format('MMM D - h:mm A').toUpperCase()
  return formattedDate
}

/**
 * Formats a date string to display in a long format with ordinal day
 * Example output: "January 1, 2024 11:59 AM"
 * @param dateString - ISO date string to format
 * @returns Formatted date string
 */
export const formatLongDateTime = (dateString: string): string => {
  return dayjs(dateString).utc().format('MMMM D, YYYY h:mm A')
}

/**
 * Formats a date string to display in a short format with time
 * Example output: "Jan 1, 2024 11:59 AM"
 * @param dateString - ISO date string to format
 * @param useUtc - Whether to use UTC timezone (default: true)
 * @param timezone - Optional timezone to convert to (e.g. 'America/New_York')
 * @returns Formatted date string
 */
export const formatShortDateTime = (
  dateString: string,
  useUtc: boolean = true,
  timezone?: string
): string => {
  let date = useUtc ? dayjs(dateString).utc() : dayjs(dateString)
  if (timezone) {
    date = date.tz(timezone)
  }
  return date.format('MMM D, YYYY h:mm A')
}

/**
 * Formats a date string to display in a long format with ordinal day
 * Example output: "January 1, 2024"
 * @param dateString - ISO date string to format
 * @returns Formatted date string
 */
export const formatLongDate = (dateString: string): string => {
  return dayjs(dateString).utc().format('MMMM D, YYYY')
}

/**
 * Formats a date string to display in a short format
 * Example output: "Jan 1, 2024"
 * @param dateString - ISO date string to format
 * @returns Formatted date string
 */
export const formatShortDate = (
  dateString: string,
  useUtc: boolean = true
): string => {
  const date = useUtc ? dayjs(dateString).utc() : dayjs(dateString)
  return date.format('MMM D, YYYY')
}
