/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { Organization } from '@sportninja/common/types/Organization'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { MessageInstance } from 'antd/es/message/interface'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

const createAbbreviation = (companyName) => {
  let text = companyName.replace(/\s/g, '') // Remove all whitespaces
  // clear any special characters or emojis
  text = text.replace(/[^a-zA-Z0-9]/g, '')
  // Check if input is valid
  if (!text) {
    return '-' // Return an empty string if the input is empty or invalid
  }
  if (typeof text !== 'string') {
    return '-' // Return an empty string if the input is not a string
  }
  if (text.length < 8) {
    return text.toUpperCase() // Return the input in uppercase if it has less than 5 characters
  }
  // Get the first 5 characters of the text
  const abbreviation = text.slice(0, 8)

  // Ensure the abbreviation has at least one character and return it in uppercase
  return abbreviation.length > 0 ? abbreviation.toUpperCase() : ''
}

const OrganizationModalContext = createContext<
  OrganizationModalContextType | undefined
>(undefined)

const useOrganizationModalContext = () => {
  const context = useContext(OrganizationModalContext)
  if (!context) {
    throw new Error(
      'useOrganizationModalContext must be used within a OrganizationModalContextWrapper'
    )
  }
  return context
}

type OrganizationModalState = {
  id: string
  error: string
  name: string
  abbreviation: string
  phone: string
  website: string
  email: string
  visibility: boolean
  is_public: boolean
  show_waiver: boolean
  waiver_id: string
  waivers: any[]
  daysmart_api_key: string
  daysmart_api_secret: string
  accepted_terms: boolean
  existing_daysmart_connection: boolean
  fieldErrors: string[]
  customError: any
  loading: boolean
  daysmart_success: boolean
  daysmart_error: string
  daysmart_loading: boolean
  daysmart_test_success: boolean
  daysmart_test_loading: boolean
  daysmart_existing_connection: boolean
  daysmart_credentials_cleared: boolean
  image_url: string | null
  background_image_url: string | null
  image_changed: boolean
  image_loading: boolean
  background_image_loading: boolean
  background_image_changed: boolean
  is_private_organization: boolean
  support_email: string
  support_phone: string
  support_website: string
  contact_info_error: boolean
}

export interface OrganizationModalContextType {
  state: OrganizationModalState
  setState: React.Dispatch<React.SetStateAction<OrganizationModalState>>
  clearState: () => void
  isEdit: boolean
  onSubmitDaysmartCredentials: () => void
  onClearCredentials: () => void
  onPressTest: () => void
  onUpdate: () => void
  onCreate: () => void
  beforeUpload: (file: File) => boolean
  handleChange: (info: any) => void
  handleChangeBackground: (info: any) => void
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  refresh: () => void
  messageApi: MessageInstance
  isOnOwnOrgPage: boolean
}

type OrganizationModalContextWrapperProps = {
  children: React.ReactNode
  organization: Organization | null
  isEdit: boolean
  messageApi: MessageInstance
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  refresh: () => void
  parentOrgId: string | null
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
  isOnOwnOrgPage: boolean
  accountId: string | null
  setOrganization: any
}

const OrganizationModalContextWrapper = ({
  children,
  organization,
  isEdit,
  messageApi,
  setIsFormOpen,
  refresh = () => {},
  parentOrgId,
  setActiveTab,
  isOnOwnOrgPage,
  accountId,
  setOrganization,
}: OrganizationModalContextWrapperProps) => {
  const [state, setState] = useState<OrganizationModalState>({
    id: organization?.id || '',
    error: '',
    accepted_terms: false,
    existing_daysmart_connection: false,
    daysmart_api_key: '',
    daysmart_api_secret: '',
    waivers: [],
    waiver_id: organization?.waiver_id || '',
    show_waiver: organization?.show_waiver || false,
    is_public: organization?.is_public || false,
    fieldErrors: [],
    customError: '',
    name: organization?.name || organization?.name_full || '',
    abbreviation: organization?.abbreviation || '',
    phone: organization?.phone || '',
    website: organization?.website || '',
    email: organization?.email || '',
    visibility: organization?.is_public || false,
    daysmart_success: false,
    daysmart_error: '',
    daysmart_loading: false,
    daysmart_test_success: false,
    daysmart_test_loading: false,
    daysmart_existing_connection: false,
    daysmart_credentials_cleared: false,
    loading: false,
    image_url: getImageThumbnailId(organization) || '',
    background_image_url: organization?.background_image?.full_path || '',
    image_changed: false,
    image_loading: false,
    is_private_organization: organization?.is_public === false || false,
    support_email: organization?.support_email || '',
    support_phone: organization?.support_phone || '',
    support_website: organization?.support_website || '',
    background_image_loading: false,
    background_image_changed: false,
    contact_info_error: false,
  })

  useEffect(() => {
    if (state.fieldErrors.includes('phone')) {
      setState((prev) => ({ ...prev, contact_info_error: true }))
    } else if (state.fieldErrors.includes('email')) {
      setState((prev) => ({ ...prev, contact_info_error: true }))
    } else if (state.fieldErrors.includes('website')) {
      setState((prev) => ({ ...prev, contact_info_error: true }))
    } else if (state.fieldErrors.includes('support_phone')) {
      setState((prev) => ({ ...prev, contact_info_error: true }))
    } else if (state.fieldErrors.includes('support_email')) {
      setState((prev) => ({ ...prev, contact_info_error: true }))
    } else if (state.fieldErrors.includes('support_website')) {
      setState((prev) => ({ ...prev, contact_info_error: true }))
    } else {
      setState((prev) => ({ ...prev, contact_info_error: false }))
    }
  }, [state.fieldErrors])

  useEffect(() => {
    if (organization) {
      setState((prev) => ({
        ...prev,
        id: organization?.id,
        name: organization?.name || organization?.name_full || '',
        abbreviation: organization?.abbreviation || '',
        phone: organization?.phone || '',
        website: organization?.website || '',
        email: organization?.email || '',
        visibility: organization?.is_public || false,
        is_public: organization?.is_public || false,
        show_waiver: organization?.show_waiver || false,
        waiver_id: organization?.waiver_id || '',
        image_url: getImageThumbnailId(organization) || '',
        background_image_url: organization?.background_image?.full_path || '',
        is_private_organization: organization?.is_public === false || false,
        support_email: organization?.support_email || '',
        support_phone: organization?.support_phone || '',
        support_website: organization?.support_website || '',
      }))
    }
  }, [organization])

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    if (img) {
      reader.readAsDataURL(img)
    }
  }
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      messageApi.open({
        type: 'error',
        content: 'You can only upload JPG/PNG file!',
        duration: 5,
      })
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      messageApi.open({
        type: 'error',
        content: 'Image must smaller than 2MB!',
        duration: 5,
      })
    }
    return isJpgOrPng && isLt2M
  }

  const handleChangeBackground = (info) => {
    try {
      setState((prev) => ({
        ...prev,
        background_image_loading: true,
        background_image_changed: true,
      }))
      getBase64(info.file.originFileObj, (url) => {
        setState((prev) => ({
          ...prev,
          background_image_url: url,
          background_image_changed: true,
        }))
      })
    } catch (e) {
      messageApi.open({
        type: 'error',
        content: 'Error uploading background image',
        duration: 5,
      })
    } finally {
      setState((prev) => ({ ...prev, background_image_loading: false }))
    }
  }

  const handleChange = (info) => {
    try {
      setState((prev) => ({
        ...prev,
        image_loading: true,
        image_changed: true,
      }))
      getBase64(info.file.originFileObj, (url) => {
        setState((prev) => ({
          ...prev,
          image_url: url,
          image_changed: true,
        }))
      })
    } catch (e) {
      messageApi.open({
        type: 'error',
        content: 'Error uploading image',
        duration: 5,
      })
    } finally {
      setState((prev) => ({ ...prev, image_loading: false }))
    }
  }

  const onUpdate = async () => {
    try {
      setState((prev) => ({
        ...prev,
        loading: true,
        customError: {},
        fieldErrors: [],
      }))
      if (state.image_changed && !state.image_url) {
        await req(`/organizations/${state.id}/image`, {
          method: 'DELETE',
        })
      }
      if (state.image_changed && state.image_url) {
        await req(`/organizations/${state.id}/image`, {
          method: 'POST',
          body: state.image_url,
        })
      }
      if (state.background_image_changed && state.background_image_url) {
        await req(`/organizations/${state.id}/background-image`, {
          method: 'POST',
          body: state.background_image_url,
        })
      }
      if (state.background_image_changed && !state.background_image_url) {
        await req(`/organizations/${state.id}/background-image`, {
          method: 'DELETE',
        })
      }
      await req(`/organizations/${state.id}`, {
        method: 'PUT',
        body: JSON.stringify({
          ...state,
          name_full: state.name,
          is_public: !state.is_private_organization,
        }),
      })
      setState((prev) => ({ ...prev, loading: false }))
      refresh()
      messageApi.open({
        type: 'success',
        content: `${state.name} Updated`,
        duration: 5,
      })
      setIsFormOpen(false)
      setOrganization(null)
      clearState()
    } catch (e: any) {
      const errorMessage = getErrorMessage(e)
      if (e?.invalid_fields) {
        setCustomErrors(e?.invalid_fields)
        setState((prev) => ({ ...prev, loading: false }))
        return
      }
      setState((prev) => ({ ...prev, error: errorMessage, loading: false }))
      messageApi.open({
        type: 'error',
        content: errorMessage,
        duration: 5,
      })
    }
  }

  const onCreate = async () => {
    try {
      setState((prev) => ({
        ...prev,
        loading: true,
        customError: {},
        fieldErrors: [],
      }))
      const response = await req('/organizations', {
        method: 'POST',
        body: JSON.stringify({
          ...state,
          is_public: !state.is_private_organization,
          name_full: state.name,
          parent_id: parentOrgId,
          can_contain_organizations: true,
          can_contain_teams: true,
        }),
      })
      if (state.image_changed && state.image_url) {
        await req(`/organizations/${response.data.id}/image`, {
          method: 'POST',
          body: state.image_url,
        })
      }
      if (state.background_image_changed && state.background_image_url) {
        await req(`/organizations/${response.data.id}/background-image`, {
          method: 'POST',
          body: state.background_image_url,
        })
      }
      setState((prev) => ({ ...prev, loading: false }))
      refresh()
      messageApi.open({
        type: 'success',
        content: (
          <a
            href={`/org/${response.data.id}`}
            target='_blank'
            rel='noreferrer'
            css={css`
              text-decoration: underline;
            `}
          >
            {state.name} Created
          </a>
        ),
        duration: 5,
      })
      setTimeout(() => {
        messageApi.destroy()
      }, 5000)
      setIsFormOpen(false)
      setOrganization(null)
      clearState()
    } catch (e: any) {
      const errorMessage = getErrorMessage(e)
      if (e?.invalid_fields) {
        setCustomErrors(e?.invalid_fields)
        setState((prev) => ({ ...prev, loading: false }))
        return
      }
      setState((prev) => ({ ...prev, error: errorMessage, loading: false }))
      messageApi.open({
        type: 'error',
        content: errorMessage,
        duration: 5,
      })
    }
  }

  const clearState = () => {
    setState({
      ...state,
      daysmart_api_key: '',
      daysmart_api_secret: '',
      accepted_terms: false,
      existing_daysmart_connection: false,
      fieldErrors: [],
      customError: '',
      name: '',
      abbreviation: '',
      phone: '',
      website: '',
      email: '',
      visibility: false,
      is_public: false,
      show_waiver: false,
      waiver_id: '',
      loading: false,
      image_url: null,
      image_changed: false,
      image_loading: false,
      is_private_organization: false,
      background_image_url: null,
      background_image_changed: false,
      background_image_loading: false,
      id: '',
    })
    setActiveTab('0')
  }

  const onSubmitDaysmartCredentials = async () => {
    try {
      setState((prev) => ({
        ...prev,
        daysmart_error: '',
        daysmart_loading: true,
      }))
      await req(`/organizations/${state.id}/daysmart-api-keys`, {
        method: 'POST',
        body: JSON.stringify({
          api_key: state.daysmart_api_key,
          api_secret: state.daysmart_api_secret,
        }),
      })
      setState((prev) => ({
        ...prev,
        daysmart_test_success: true,
        daysmart_test_submitted: true,
        daysmart_success: true,
        daysmart_existing_connection: true,
        daysmart_credentials_cleared: false,
        daysmart_loading: false,
        daysmart_test_loading: false,
        existing_daysmart_connection: true,
      }))
      messageApi.open({
        type: 'success',
        content: 'Credentials saved',
        duration: 5,
      })
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setState((prev) => ({
        ...prev,
        daysmart_loading: false,
      }))
      messageApi.open({
        type: 'error',
        content: errorMessage,
        duration: 5,
      })
    }
  }

  const onClearCredentials = async () => {
    try {
      setState((prev) => ({
        ...prev,
        daysmart_error: '',
        daysmart_loading: true,
      }))
      await req(`/organizations/${state.id}/daysmart-api-keys`, {
        method: 'DELETE',
        body: JSON.stringify({
          api_key: null,
          api_secret: null,
        }),
      })
      setState((prev) => ({
        ...prev,
        daysmart_api_key: '',
        daysmart_api_secret: '',
        daysmart_test_success: false,
        daysmart_test_submitted: false,
        daysmart_success: false,
        daysmart_test_loading: false,
        daysmart_loading: false,
        accepted_terms: false,
        daysmart_existing_connection: false,
        daysmart_credentials_cleared: true,
        existing_daysmart_connection: false,
      }))
      messageApi.open({
        type: 'success',
        content: 'Credentials cleared',
        duration: 5,
      })
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setState((prev) => ({
        ...prev,
        daysmart_loading: false,
      }))
      messageApi.open({
        type: 'error',
        content: errorMessage,
        duration: 5,
      })
    }
  }

  const onPressTest = async () => {
    try {
      setState((prev) => ({
        ...prev,
        daysmart_error: '',
        daysmart_test_success: false,
        daysmart_test_loading: true,
        customError: {
          ...prev.customError,
          daysmart_api_key: '',
          daysmart_api_secret: '',
        },
        fieldErrors: prev.fieldErrors.filter(
          (field) =>
            field !== 'daysmart_api_key' && field !== 'daysmart_api_secret'
        ),
      }))
      await req(`/organizations/${state.id}/daysmart-authentication-test`, {
        method: 'POST',
        body: JSON.stringify({
          api_key: state.daysmart_api_key,
          api_secret: state.daysmart_api_secret,
        }),
      })
      setState((prev) => ({
        ...prev,
        daysmart_test_success: true,
        daysmart_test_loading: false,
      }))
      messageApi.open({
        type: 'success',
        content: 'Credentials Validated, you can now connect to Daysmart',
        duration: 5,
      })
    } catch (e: any) {
      if (e?.invalid_fields) {
        setCustomErrors(e?.invalid_fields)
        setState((prev) => ({ ...prev, daysmart_test_loading: false }))
        return
      }
      const errorMessage = getErrorMessage(e)
      setState((prev) => ({
        ...prev,
        daysmart_test_loading: false,
      }))
      messageApi.open({
        type: 'error',
        content: errorMessage,
        duration: 5,
      })
    }
  }

  useEffect(() => {
    const fetchDaysmartKeys = async () => {
      try {
        const { data } = await req(
          `/organizations/${organization?.id}/daysmart-api-keys`
        )
        const { api_key, api_secret } = data
        if (api_key) {
          setState((prev) => ({ ...prev, daysmart_api_key: api_key }))
        }
        if (api_secret) {
          setState((prev) => ({ ...prev, daysmart_api_secret: api_secret }))
        }
        if (api_key && api_secret) {
          setState((prev) => ({
            ...prev,
            accepted_terms: true,
            existing_daysmart_connection: true,
          }))
        }
      } catch (e) {
        const errorMessage = getErrorMessage(e)
        setState((prev) => ({ ...prev, error: errorMessage }))
      }
    }
    if (organization?.id) {
      fetchDaysmartKeys()
    }
  }, [organization?.id])

  const fetchData = useCallback(async () => {
    try {
      // If its edit, we fetch the waivers from the organization's account
      // If its create, we fetch the waivers from the account_id
      const isToUse = isEdit ? organization?.account_id : accountId
      if (!isToUse) {
        return
      }
      const { data } = await req(`/account/${isToUse}/waiver/`)
      if (data) {
        const updatedData = data.map((item) => ({
          label: item?.name,
          value: item?.id,
        }))
        setState((prev) => ({ ...prev, waivers: updatedData }))
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      messageApi.open({
        type: 'error',
        content: errorMessage,
        duration: 5,
      })
    }
  }, [isEdit, organization?.account_id, accountId, messageApi])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const setCustomErrors = (invalid_fields: any) => {
    if (invalid_fields.name || invalid_fields.name_full) {
      setCustomErroByField('name', invalid_fields.name)
    }
    if (invalid_fields.api_key) {
      setCustomErroByField('daysmart_api_key', invalid_fields.api_key)
    }
    if (invalid_fields.api_secret) {
      setCustomErroByField('daysmart_api_secret', invalid_fields.api_secret)
    }
  }

  const setCustomErroByField = (field: string, message: string) => {
    setState((prev) => ({ ...prev, fieldErrors: [...prev.fieldErrors, field] }))
    setState((prev) => ({
      ...prev,
      customError: { ...prev.customError, [field]: message },
    }))
  }

  useEffect(() => {
    if (state.name) {
      setState((prev) => ({
        ...prev,
        abbreviation: createAbbreviation(state.name),
      }))
    }
  }, [state.name])

  const value = {
    state,
    setState,
    setCustomErrors,
    clearState,
    isEdit,
    onSubmitDaysmartCredentials,
    onClearCredentials,
    onPressTest,
    onUpdate,
    onCreate,
    beforeUpload,
    handleChange,
    handleChangeBackground,
    setIsFormOpen,
    refresh,
    messageApi,
    isOnOwnOrgPage,
  }

  return (
    <OrganizationModalContext.Provider value={value}>
      {children}
    </OrganizationModalContext.Provider>
  )
}

export {
  OrganizationModalContext,
  useOrganizationModalContext,
  OrganizationModalContextWrapper,
}
