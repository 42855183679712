/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Text from 'src/components/ts-components/Text'

/**
 * UnderAgeError component for the underage invitation flow
 * Displays a message when a user is under 13 years old
 *
 * @returns {JSX.Element} The rendered component
 */
const UnderAgeError = () => {
  return (
    <div
      css={css`
        padding-top: 32px;
        width: 100%;
        @media (max-width: 768px) {
          width: 100%;
        }
      `}
    >
      <Text variant='displaySmall' weight='bold' color={colors.NEUTRAL_0}>
        HEADS UP!
      </Text>
      <div
        css={css`
          margin-top: 32px;
        `}
      >
        <Text
          variant='paragraphMedium'
          weight='regular'
          color={colors.NEUTRAL_0}
        >
          Seems like you&apos;re not 13 yet. <br />
          <br /> Please have a parent or guardian create your SportNinja
          account. Thanks!
        </Text>
      </div>
    </div>
  )
}

export default UnderAgeError
