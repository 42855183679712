/** @jsxImportSource @emotion/react */
import { ROUTES } from '@sportninja/common/constants/app'
import { useMemo } from 'react'
import Page from '../../components/Page'
import RegistrationList from './RegistrationsList'

const Registrations = () => {
  const actions = useMemo(() => {
    return []
  }, [])

  const pages = useMemo(
    () => [
      {
        to: `${ROUTES.REGISTRATIONS.ROOT}/my`,
        text: 'My Registrations',
        component: <RegistrationList />,
        actions: actions,
      },
    ],
    [actions]
  )

  const BASE_ROUTE = ROUTES.REGISTRATIONS.ROOT

  return (
    // @ts-ignore
    <Page
      baseRoute={BASE_ROUTE}
      pages={pages}
      baseTitle='Registrations'
      title='Registrations'
      isOrgsPage={true}
      hideBreadcrumbs={true}
      displayMobileActions={false}
    />
  )
}

export default Registrations
