import { useMemo } from 'react'

/**
 * Interface representing a division with its properties
 * @interface Division
 * @property {string} id - Unique identifier for the division
 * @property {string} name - Name of the division
 * @property {string} schedule_id - ID of the division's schedule
 * @property {number} team_cost - Cost per team in the division
 * @property {number} deposit - Required deposit amount
 * @property {number} max_teams - Maximum number of teams allowed
 * @property {number} team_count_enrolled - Current number of enrolled teams
 * @property {Object} division_age - Age category information
 * @property {string} division_age.name - Name of the age category
 * @property {Object} division_gender - Gender category information
 * @property {string} division_gender.name - Name of the gender category
 */
interface Division {
  id: string
  name: string
  schedule_id: string
  team_cost: number
  deposit: number
  max_teams: number
  team_count_enrolled: number
  division_age: {
    name: string
  }
  division_gender: {
    name: string
  }
  day_of_week: {
    name: string
  }
}

/**
 * Custom hook for filtering divisions based on age and gender criteria
 * @param {Division[]} divisions - Array of divisions to filter
 * @param {string | null} selectedAge - Selected age category to filter by
 * @param {string | null} selectedGender - Selected gender category to filter by
 * @returns {Division[]} Filtered array of divisions matching the criteria
 */
export const useDivisionFiltering = (
  divisions: Division[],
  selectedAge: string | null,
  selectedGender: string | null,
  selectedDayOfWeek: string | null
) => {
  return useMemo(() => {
    if (!divisions) {
      return []
    }
    return divisions.filter((division) => {
      const matchesAge =
        !selectedAge || division?.division_age?.name === selectedAge
      const matchesGender =
        !selectedGender || division?.division_gender?.name === selectedGender
      const matchesDayOfWeek =
        !selectedDayOfWeek || division?.day_of_week?.name === selectedDayOfWeek
      return matchesAge && matchesGender && matchesDayOfWeek
    })
  }, [divisions, selectedAge, selectedGender, selectedDayOfWeek])
}
