/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import { getAllPlayerTypes } from '@sportninja/common/selectors/types'
import { useRef, useState } from 'react'
import { connect } from 'react-redux'
import Papa from 'papaparse'

import { FormButton } from '../../Form/css'
import { Flex } from '../../Layout'
import ImportCSVErrorTable from '../ImportCSVErrorTable'
import ImportCSVBanner, { BANNER_TYPES } from '../ImportCSVBanner'
import ImportOfficialsDuplicatePlayer from './ImportOfficialsDuplicatePlayer'
import { font } from '../../css'

const ImportOfficialsErrorDuplicates = ({
  conflicts = [],
  playerTypes,
  teamName,
  resetFile,
  parsedCSV,
  file,
  submitUrl,
  onUploadComplete,
  orgId,
  isMultiTeamRoster,
}) => {
  const formRef = useRef(null)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(false)
  const [completeSteps, setCompleteSteps] = useState({})
  const [shouldHighlightDiff, setShouldHighlightDiff] = useState(false)

  const isReadyToSubmit =
    Object.values(completeSteps).filter((v) => v === true).length ===
    conflicts.length

  const onSubmit = async (e) => {
    e.preventDefault()

    const data = new FormData(formRef?.current)
    // The 2-dimensional nature of parsedCSV means we must deeply clone the array
    // to avoid mutations later
    const newCSV = JSON.parse(JSON.stringify(parsedCSV))

    for (const entry of data) {
      // Add one to line number to account for header
      const lineNumber = parseInt(entry[0], 10)
      const choice = entry[1]

      switch (choice) {
        case 'ignore':
          newCSV[lineNumber - 1] = ['']
          break
        case 'create':
          break
        default:
          // If we are uploading to an team roster, we need to
          // replace the SN Player ID with the selected existing player ID in the first column
          if (!orgId) {
            const { uid } = JSON.parse(choice) || null
            newCSV[lineNumber - 1][0] = uid || ''
          } else {
            // SN Official ID Column, it's the first one in the CSV
            const { uid } = JSON.parse(choice) || null
            newCSV[lineNumber - 1][0] = uid || ''
          }
          break
      }
    }

    const csvString = Papa.unparse(newCSV, { skipEmptyLines: true })
    const body = new FormData()
    const newFile = new File([csvString], file?.name, { type: file?.type })

    body.append('file', newFile)
    body.append('conflicts', 'ignore')

    try {
      setSubmitting(true)
      const response = await req(submitUrl, {
        body,
        method: 'POST',
        skipAutoContentType: true,
      })
      onUploadComplete(response)
    } catch (e) {
      setError(e.message)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <ImportCSVBanner
        type={BANNER_TYPES.info.type}
        message={`${parsedCSV.length - 1} officials to import. Please review ${
          conflicts.length
        } potential duplicates below:`}
      />
      <form
        id='team-roster-duplicates'
        action='/'
        method='GET'
        onSubmit={onSubmit}
        ref={formRef}
        css={css`
          overflow: auto;
          margin-bottom: 16px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        `}
      >
        {error && (
          <ImportCSVBanner type={BANNER_TYPES.error.type} message={error} />
        )}
        <ImportCSVErrorTable
          body={
            Array.isArray(conflicts) &&
            conflicts?.map((player, i) => {
              const lineNumber = player.line
              const duplicatedTeamName = isMultiTeamRoster
                ? parsedCSV?.[lineNumber]?.[1]
                : null
              const duplicatedTeamRosterName = isMultiTeamRoster
                ? parsedCSV?.[lineNumber]?.[2]
                : null

              return (
                <tr
                  key={i}
                  className='row'
                  css={
                    i !== conflicts.length - 1 &&
                    css`
                      border-bottom: 1px solid ${colors.PALE_SKY};
                    `
                  }
                >
                  <td>
                    <ImportOfficialsDuplicatePlayer
                      // https://sportninja.atlassian.net/browse/SN-4491
                      importTeamName={duplicatedTeamName}
                      importTeamRosterName={duplicatedTeamRosterName}
                      // importOfficialsName={parsedCSV?.[lineNumber]?.[2]}
                      importPlayerId={parsedCSV?.[lineNumber]?.[0]}
                      player={player}
                      playerTypes={playerTypes}
                      teamName={teamName}
                      line={lineNumber}
                      setCompleteSteps={(line) => {
                        setCompleteSteps((prev) => {
                          const newSteps = { ...prev }
                          newSteps[line] = true
                          return newSteps
                        })
                      }}
                      shouldHighlightDiff={shouldHighlightDiff}
                    />
                  </td>
                </tr>
              )
            })
          }
        />
      </form>
      <Flex alignItems='center'>
        <button
          type='button'
          className={shouldHighlightDiff ? 'active' : ''}
          onClick={() => {
            setShouldHighlightDiff(!shouldHighlightDiff)
          }}
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: 18px;

            span {
              margin-left: 10px;
              ${font.body}
              font-size: 16px;
              color: ${shouldHighlightDiff ? 'white' : '#9fa3a9'};
            }
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              width: 20px;
              height: 10px;
              border-radius: 5px;
              padding: 0 2px;
              background-color: ${shouldHighlightDiff
                ? colors.DEFAULT_FLAIR
                : '#9fa3a9'};

              .baton {
                background-color: #191b1f;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                transition: transform 0.1s ease-in-out;
              }

              .active & {
                .baton {
                  transform: translateX(9px);
                }
              }
            `}
          >
            <div className='baton' />
          </div>
          <span>Highlight differences</span>
        </button>
      </Flex>
      <Flex alignItems='center' justifyContent='space-between'>
        <FormButton
          disabled={submitting}
          css={css`
            width: 160px;
            height: 40px;
            border: 2px solid ${colors.DEFAULT_FLAIR};
            background-image: none;
            color: white;

            &:hover {
              background-image: none !important;
            }
          `}
          onClick={resetFile}
        >
          Cancel {t('common:import')}
        </FormButton>
        <FormButton
          form='team-roster-duplicates'
          busy={submitting}
          disabled={submitting || !isReadyToSubmit}
          isSubmit
          css={css`
            width: 160px;
            height: 40px;
            background-color: ${colors.DEFAULT_FLAIR};
            background-image: none;

            &:hover {
              background-image: none !important;
            }
          `}
        >
          Confirm & {t('common:import')}
        </FormButton>
      </Flex>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    playerTypes: getAllPlayerTypes(state),
  }
}

export default connect(mapStateToProps)(ImportOfficialsErrorDuplicates)
