// create a context for the manage team attendance modal
import React, { createContext, useContext, useState } from 'react'

export interface ManageRefreshmentsModalContextType {
  selectedTeamName: string | null
  setSelectedTeamName: (teamName: string | null) => void
  selectedTeamId: string | null
  setSelectedTeamId: (teamId: string | null) => void
  selectedTeamRosterId: string | null
  setSelectedTeamRosterId: (teamRosterId: string | null) => void
  onSubmit: () => void
}

export const ManageRefreshmentsModalContext = createContext<
  ManageRefreshmentsModalContextType | undefined
>(undefined)

export const useManageRefreshmentsModalContext = () => {
  const context = useContext(ManageRefreshmentsModalContext)
  if (!context) {
    throw new Error(
      'useManageRefreshmentsModalContext must be used within a ManageRefreshmentsModalProvider'
    )
  }
  return context
}

export const ManageRefreshmentsModalProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [selectedTeamName, setSelectedTeamName] = useState<string | null>(null)
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null)
  const [selectedTeamRosterId, setSelectedTeamRosterId] = useState<any>(null)

  const onSubmit = () => {
    console.log('onSubmit')
  }

  return (
    <ManageRefreshmentsModalContext.Provider
      value={{
        selectedTeamName,
        setSelectedTeamName,
        selectedTeamId,
        setSelectedTeamId,
        selectedTeamRosterId,
        setSelectedTeamRosterId,
        onSubmit,
      }}
    >
      {children}
    </ManageRefreshmentsModalContext.Provider>
  )
}
