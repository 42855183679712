/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { ActionButton } from './Details'
// import { useForm } from 'react-hook-form'
import { Input } from 'src/components/Input'
import GenericTable from 'src/components/GenericTable'
import { observer } from 'mobx-react-lite'
import { useMobxStore } from 'src/state'
import dayjs from 'dayjs'
import { useI18n } from '@shopify/react-i18n'
import Icon from 'src/components/Icon'
import { NewModal } from 'src/components/NewModal'
import { UpgradeModal } from './UpgradeModal'
import { useEffect, useMemo, useRef, useState } from 'react'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { handlePlanName } from './utils'
import { PaymentManagerModal, handleCardIcon } from './PaymentManagerModal'

export const Billing = observer(() => {
  const {
    me: {
      state: { account },
    },
    payment: {
      state: { paymentList, loading: loadingPaymentList },
      fetchPaymentList,
    },
    subscription: {
      loadInvoices,
      state: { invoiceList },
    },
  } = useMobxStore()
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(true)
  const [hasFetchedPaymentList, setHasFetchedPaymentList] = useState(false)
  const [hasFetchedInvoices, setHasFetchedInvoices] = useState(false)

  const upgradePlanModal = useRef<NewModal>()
  const paymentManagerModal = useRef<NewModal>()
  const [i18n] = useI18n()

  const currentDefaultPaymentMethodId = useMemo(
    () => paymentList?.find((payment) => payment.default),
    [paymentList]
  )

  useEffect(() => {
    if (!hasFetchedPaymentList) {
      fetchPaymentList()
      setHasFetchedPaymentList(true)
    }
    setIsLoadingInvoices(true)
    if (account?.uid) {
      if (!hasFetchedInvoices) {
        loadInvoices(account.uid)
        setHasFetchedInvoices(true)
      }
    }
  }, [account, paymentList, hasFetchedInvoices, hasFetchedPaymentList])

  useEffect(() => {
    if (invoiceList.length >= 1) {
      setIsLoadingInvoices(false)
    } else {
      setTimeout(() => {
        setIsLoadingInvoices(false)
      }, 1500)
    }
  }, [invoiceList])

  if (!account?.subscription_type?.features) {
    return (
      <div
        css={css`
          background-color: ${colors.HEADER};
          padding: 16px;
          border-radius: 8px;
          border: 1px solid ${colors.SOFT_STEEL};
          max-width: 820px;
          margin: 0 auto;
          margin-top: 16px;
        `}
      >
        <LoadingSpinner size={6} />
      </div>
    )
  }

  if (!account?.subscription) {
    return (
      <div
        css={css`
          background-color: ${colors.HEADER};
          padding: 16px;
          border-radius: 8px;
          border: 1px solid ${colors.SOFT_STEEL};
          max-width: 820px;
          margin: 0 auto;
          margin-top: 16px;
        `}
      >
        <p>No Data Found</p>
      </div>
    )
  }

  return (
    <>
      <div
        css={css`
          background-color: ${colors.HEADER};
          padding: 16px;
          border-radius: 8px;
          border: 1px solid ${colors.SOFT_STEEL};
          max-width: 820px;
          margin: 0 auto;
          margin-top: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              border-radius: 8px;
              border: 1px dashed ${colors.SOFT_STEEL};
              padding: 16px;
              justify-content: space-around;
              align-items: flex-start;
              flex: 1;
              min-height: 125px;
            `}
          >
            <p
              css={css`
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 4px;
              `}
            >
              Your subscription
            </p>
            <p
              css={css`
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                margin: 8px 0;
                text-transform: capitalize;
              `}
            >
              {handlePlanName(account?.subscription_type?.name)} Plan{' '}
              <span
                css={css`
                  font-size: 14px;
                  color: ${colors.ATTENDANCE_GRAY};
                `}
              >
                ({account?.subscription?.interval})
              </span>
            </p>
            <p
              css={css`
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                color: ${colors.ATTENDANCE_GRAY};
                margin-bottom: 8px;
              `}
            >
              Subscribed on{' '}
              {dayjs(account?.subscription?.current_period_start).format(
                'DD MMMM YYYY'
              )}
            </p>
            {account?.subscription?.interval === 'year' &&
            account?.subscription_type?.id === 3 ? null : (
              <ActionButton
                removeMargin
                label='Upgrade Your Plan'
                iconName='dollar-sign'
                onClick={() => upgradePlanModal?.current?.openModal()}
              />
            )}
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              border-radius: 8px;
              border: 1px dashed ${colors.SOFT_STEEL};
              padding: 16px;
              justify-content: space-around;
              align-items: flex-start;
              flex: 1;
              min-height: 125px;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-bottom: 4px;
              `}
            >
              <p
                css={css`
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                `}
              >
                Your next bill
              </p>
            </div>
            {account?.subscription?.usage_type === 'licensed' ? (
              <p
                css={css`
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 600;
                  margin: 8px 0;
                `}
              >
                {i18n.formatCurrency(Number(account?.subscription?.amount), {
                  currency: (
                    account?.subscription?.currency || 'USD'
                  ).toUpperCase(),
                  form: 'explicit',
                })}
              </p>
            ) : (
              <p
                css={css`
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 600;
                  margin: 8px 0;
                `}
              >
                {i18n.formatCurrency(
                  Number(account?.subscription_upcoming_invoice?.amount_due),
                  {
                    currency: (
                      account?.subscription_upcoming_invoice?.currency || 'USD'
                    ).toUpperCase(),
                    form: 'explicit',
                  }
                )}
              </p>
            )}

            <p
              css={css`
                font-size: 12px;
                font-weight: normal;
                font-style: italic;
                color: ${colors.ATTENDANCE_GRAY};
                margin-bottom: 8px;
              `}
            >
              {account?.subscription?.usage_type === 'licensed'
                ? '(Flat Rate)'
                : account?.subscription?.usage_type === 'metered'
                ? '(Metered Usage)'
                : ''}
            </p>
            <p
              css={css`
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                color: ${colors.ATTENDANCE_GRAY};
                margin-bottom: 8px;
              `}
            >
              on{' '}
              {dayjs(account?.subscription?.current_period_end).format(
                'DD MMMM YYYY'
              )}
            </p>
            {/* {account?.subscription?.interval !== 'year' && (
              <ActionButton
                removeMargin
                label='Switch to Annual'
                iconName='dollar-sign'
                onClick={() => upgradePlanModal?.current?.openModal()}
              />
            )} */}
          </div>
        </div>

        <div
          css={css`
            display: flex;
            height: 1px;
            background-color: ${colors.SOFT_STEEL};
            margin: 16px 0;
          `}
        />

        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 16px;
          `}
        >
          <p
            css={css`
              font-family: 'Rift', sans-serif;
              font-size: 24px;
              font-weight: 500;
            `}
          >
            Billing Info
          </p>
          <div>
            {loadingPaymentList ? (
              <div
                css={css`
                  padding-right: 8px;
                `}
              >
                <LoadingSpinner size={1.2} />
              </div>
            ) : (
              <ActionButton
                label='Edit'
                onClick={() => paymentManagerModal.current?.openModal()}
                iconName='pen'
              />
            )}
          </div>
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
            gap: 16px;
          `}
        >
          <div
            css={css`
              flex: 2;
            `}
          >
            <Input
              isMockInput
              disabled
              fieldName='cardNumber'
              label='Card'
              mockValue={`**** **** **** ${currentDefaultPaymentMethodId?.last_four}`}
              icon={
                <Icon
                  name={handleCardIcon(
                    currentDefaultPaymentMethodId?.card_type
                  )}
                  faType='fab'
                  color={colors.ATTENDANCE_GRAY}
                  fontSize={16}
                />
              }
            />
          </div>
          <div
            css={css`
              flex: 1.5;
              display: flex;
              gap: 8px;
            `}
          >
            <Input
              fieldName='expirationDate'
              label='&nbsp;'
              disabled
              isMockInput
              mockValue={`${String(
                currentDefaultPaymentMethodId?.exp_month
              ).padStart(2, '0')}/${currentDefaultPaymentMethodId?.exp_year}`}
            />
          </div>
        </div>

        <div
          css={css`
            display: flex;
            height: 1px;
            background-color: ${colors.SOFT_STEEL};
            margin: 16px 0;
          `}
        />

        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 16px;
          `}
        >
          <p
            css={css`
              font-family: 'Rift', sans-serif;
              font-size: 24px;
              font-weight: 500;
            `}
          >
            Billing Address
          </p>
          <div>
            {/* <ActionButton label='Edit' onClick={() => {}} iconName='pen' /> */}
          </div>
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
          `}
        >
          <div>
            <Input
              fieldName='street'
              label='Street'
              isMockInput
              disabled
              mockValue={account?.address_street}
            />
          </div>
          <div
            css={css`
              display: flex;
              gap: 16px;
              align-items: center;
              margin-top: 8px;
            `}
          >
            <Input
              fieldName='country'
              label='Country'
              isMockInput
              disabled
              mockValue={account?.address_country}
            />
            <Input
              fieldName='state'
              label='State/Province'
              isMockInput
              disabled
              mockValue={account?.address_state_province}
            />
          </div>
          <div
            css={css`
              display: flex;
              gap: 16px;
              margin-top: 8px;
              align-items: center;
            `}
          >
            <Input
              fieldName='city'
              label='City'
              isMockInput
              disabled
              mockValue={account?.address_city}
            />
            <Input
              fieldName='addressZipCode'
              label='Zip/Postal Code'
              isMockInput
              disabled
              mockValue={account?.address_postal_code}
            />
          </div>
        </div>

        <div
          css={css`
            display: flex;
            height: 1px;
            background-color: ${colors.SOFT_STEEL};
            margin: 16px 0;
          `}
        />

        <div
          css={css`
            display: flex;
            justify-content: flex-start;
            padding-bottom: 16px;
            flex-flow: column;
          `}
        >
          <p
            css={css`
              font-family: 'Rift', sans-serif;
              font-size: 24px;
              font-weight: 500;
            `}
          >
            TRANSACTION HISTORY
          </p>
          <div
            css={css`
              display: flex;
              gap: 8px;
              margin: 8px 0;
            `}
          >
            <p
              css={css`
                color: ${colors.ATTENDANCE_GRAY};
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
              `}
            >
              {
                'Your monthly subscription charge is based on the total number of unique participants during the monthly billing period as well as future competitions. Subscription levels are tiered in increments of 500 participants and calculated based upon the product-level you have chosen.'
              }
            </p>
          </div>
        </div>
        {isLoadingInvoices ? (
          <LoadingSpinner size={2} />
        ) : (
          <div>
            <GenericTable
              headers={[
                'DATE',
                'TRANSACTION',
                'AMOUNT',
                'PAYMENT STATUS',
                'VIEW INVOICE',
              ]}
              headersFlexSize={[1.1, 1.5, 1, 1.3, 1]}
              content={() =>
                invoiceList.map((invoice, index) => {
                  const isUpdate =
                    invoice.billing_reason === 'subscription_update'
                  const isCreate =
                    invoice.billing_reason === 'subscription_create'

                  return (
                    <div
                      key={invoice.id}
                      css={css`
                        display: flex;
                        align-items: center;
                        padding: 10px 8px;
                        background-color: ${index % 2 === 0
                          ? colors.SOFT_STEEL
                          : 'transparent'};
                      `}
                    >
                      <div
                        css={css`
                          flex: 1.1;
                        `}
                      >
                        {dayjs(invoice.effective_at).format('MMMM D, YYYY')}
                      </div>
                      <div
                        css={css`
                          flex: 1.5;
                        `}
                      >
                        {isCreate
                          ? 'Subscription Created'
                          : isUpdate
                          ? 'Subscription Updated'
                          : 'Subscription Payment'}
                      </div>
                      <div
                        css={css`
                          flex: 1;
                        `}
                      >
                        {isCreate || isUpdate
                          ? '--'
                          : i18n.formatCurrency(invoice?.amount_paid || 0, {
                              currency: (
                                invoice?.currency || 'USD'
                              ).toUpperCase(),
                              form: 'short',
                            })}
                      </div>
                      <div
                        css={css`
                          flex: 1.3;
                          color: ${isCreate || isUpdate
                            ? colors.WHITE
                            : colors.SUCCESS_GREEN};
                        `}
                      >
                        {isCreate || isUpdate
                          ? '--'
                          : invoice?.amount_due === invoice?.amount_paid
                          ? 'Paid'
                          : 'Processing Payment'}
                      </div>
                      <div
                        css={css`
                          flex: 1;
                        `}
                      >
                        <a
                          href={invoice?.invoice_pdf}
                          title='Download invoice'
                          download={`${invoice?.id}.pdf`}
                          css={css`
                            display: flex;
                            align-items: center;
                            gap: 8px;
                          `}
                        >
                          <Icon name='download' color={colors.DEFAULT_FLAIR} />
                          <span
                            css={css`
                              color: ${colors.DEFAULT_FLAIR};
                              font-size: 14px;
                            `}
                          >
                            Invoice
                          </span>
                        </a>
                      </div>
                    </div>
                  )
                })
              }
            />
          </div>
        )}
      </div>
      <NewModal ref={upgradePlanModal} shouldCloseOnOverlayClick={false}>
        <UpgradeModal
          userHasCard={!!account?.payment_method?.id}
          hideComparePlansButton
          onCloseModal={() => upgradePlanModal?.current?.closeModal()}
          isUltimate={Number(account?.subscription_type?.id) === 3}
          onChangeCardsPress={() => {
            upgradePlanModal?.current?.closeModal()
            paymentManagerModal?.current?.openModal()
          }}
        />
      </NewModal>
      <NewModal ref={paymentManagerModal} shouldCloseOnOverlayClick={false}>
        <PaymentManagerModal
          onClose={() => paymentManagerModal?.current?.closeModal()}
        />
      </NewModal>
    </>
  )
})
