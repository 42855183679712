/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { t } from '@sportninja/common/i18n'

import TextModal from './TextModal'
import ActionButton from '../ActionButton'
import { Flex } from '../Layout'
import { FormButton } from '../Form/css'
import BannerController from '../Banner/BannerController'
import Banner from '../Banner'
import FormInput from '../Form/FormInput'

const DeleteModalContents = ({
  toggle,
  buttonText,
  message,
  secondaryMessage = null,
  onDelete,
  useForceDelete,
  forceDeleteText,
  shouldCloseModalAfterDelete = false,
  displayBanner = true,
  bannerText,
}) => {
  const [shouldForce, setShouldForce] = useState(false)
  const [shouldShowForceMessage, setShouldShowForceMessage] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    return () => {
      setShouldForce(false)
      setShouldShowForceMessage(false)
      setIsDeleting(false)
      setError(false)
    }
  }, [])

  return (
    <Fragment>
      {message}
      {secondaryMessage && (
        <div>
          <br />
          <p>{secondaryMessage}</p>
        </div>
      )}
      {error && (
        <div
          css={css`
            color: red;
            margin-top: 16px;
            word-break: break-word;
          `}
        >
          {error}
        </div>
      )}
      {shouldShowForceMessage && (
        <div>
          <FormInput
            wrapperClass={css`
              margin: 16px 0 18px;
              padding: 0 !important;
            `}
            onChange={() => setShouldForce(!shouldForce)}
            disabled={isDeleting}
            input={{
              name: 'force_remove',
              label: t('Web:ignoreScheduleTeamDeleteWarning'),
              type: 'checkbox',
              checked: shouldForce,
            }}
          />
          {shouldForce && <p>{forceDeleteText}</p>}
        </div>
      )}
      <Flex
        css={css`
          margin-top: 24px;
        `}
      >
        <FormButton disabled={isDeleting} onClick={toggle}>
          {t('common:cancel')}
        </FormButton>
        <FormButton
          disabled={isDeleting}
          busy={isDeleting}
          isSubmit
          className='delete-button'
          onClick={async () => {
            setIsDeleting(true)
            try {
              await onDelete(shouldForce)
              if (displayBanner) {
                BannerController.add(({ ...props }) => (
                  <Banner {...props}>
                    {bannerText || t('Web:deletionSuccessful')}
                  </Banner>
                ))
              }
              // if (shouldCloseModalAfterDelete && !useForceDelete) {
              if (shouldCloseModalAfterDelete) {
                toggle()
              }
            } catch (e) {
              setError(e.message)
              setIsDeleting(false)

              if (useForceDelete && e.status === 409) {
                setShouldShowForceMessage(true)
              }
            }
          }}
        >
          {buttonText}
        </FormButton>
      </Flex>
    </Fragment>
  )
}

const DeleteModal = ({
  Button,
  bannerText,
  buttonText = t('common:delete'),
  message = t('Web:deletionCantBeUndone'),
  secondaryMessage = '',
  onDelete,
  title = t('Web:areYouSure'),
  unmountOnClose = false,
  useForceDelete = false,
  forceDeleteText,
  shouldCloseModalAfterDelete = false,
  displayBanner = true,
  disabled = false,
}) => {
  return (
    <TextModal
      unmountOnClose={unmountOnClose}
      className={`delete-modal${unmountOnClose ? ' unmount-on-close' : ''}`}
      title={title}
      link={(toggle) => {
        return Button ? (
          <Button
            onClick={
              disabled
                ? () => {}
                : () => {
                    toggle()
                  }
            }
            // onClick={toggle}
            disabled={disabled}
          />
        ) : (
          <ActionButton
            alwaysShowLabel
            icon='trash'
            title={t('Web:permanentlyDelete')}
            label={t('common:delete')}
            onClick={toggle}
          />
        )
      }}
    >
      {(toggle) => (
        <DeleteModalContents
          toggle={toggle}
          buttonText={buttonText}
          message={message}
          secondaryMessage={secondaryMessage}
          onDelete={onDelete}
          useForceDelete={useForceDelete}
          forceDeleteText={forceDeleteText}
          shouldCloseModalAfterDelete={shouldCloseModalAfterDelete}
          displayBanner={displayBanner}
          bannerText={bannerText}
        />
      )}
    </TextModal>
  )
}

DeleteModal.propTypes = {
  Button: PropTypes.func,
  buttonText: PropTypes.string,
  message: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  title: PropTypes.string,
  bannerText: PropTypes.string,
}

export default DeleteModal
