/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import authActions from '@sportninja/common/actions/auth'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { Alert, App, Input } from 'antd'
import { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { EventCard } from 'src/components/RegistrationEventCard'
import { EventProgress } from 'src/components/RegistrationEventProgress'
import { RegistrationContext } from '../context/RegistrationContext'
import { PageTitle, REGISTRATION_STEPS, paragraphSmallStyle } from './Main'
import Text from 'src/components/ts-components/Text'
import { ROUTES } from '@sportninja/common/constants/app'
import { Link, useHistory } from 'react-router-dom'
import Button from 'src/components/ts-components/Button'

const Login = ({ login }) => {
  const {
    setStep,
    registration,
    isLogged,
    email,
    setEmail,
    isTeamRep,
    session,
  } = useContext(RegistrationContext)
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { message } = App.useApp()
  const history = useHistory()

  if (isLogged) {
    setStep(REGISTRATION_STEPS.SELECT_YOUR_ROLE)
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        background-color: ${colors.NEUTRAL_800};
        width: 1080px;
        padding: 0 35px 35px 35px;
        border-radius: 16px 16px 0px 0px;
        @media (max-width: 768px) {
          min-width: 0;
          width: 100%;
        }
      `}
    >
      <EventCard eventInfo={registration} />
      <EventProgress eventProgress={1} isTeamRep={isTeamRep} />
      <PageTitle
        title='Login'
        showAlert={false}
        showSuccess={false}
        subtitle={'Please Enter Your Email and Password'}
      />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          @media (max-width: 768px) {
            flex-direction: column;
            gap: 0;
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            margin-bottom: 16px;
          `}
        >
          <p
            css={css`
              margin-bottom: 8px;
              color: ${colors.WHITE};
              ${paragraphSmallStyle}
            `}
          >
            Email *
          </p>
          <Input
            name='email'
            placeholder='Email'
            type='email'
            autoComplete='email'
            autoCorrect='off'
            autoCapitalize='none'
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div
          css={css`
            width: 100%;
            margin-bottom: 16px;
          `}
        >
          <p
            css={css`
              margin-bottom: 8px;
              color: ${colors.WHITE};
              ${paragraphSmallStyle}
            `}
          >
            Password *
          </p>
          <Input.Password
            name='password'
            placeholder='Password'
            type='password'
            autoComplete='password'
            autoCorrect='off'
            autoCapitalize='none'
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
      {error ? (
        <div
          css={css`
            margin-bottom: 16px;
          `}
        >
          <Alert message={error} type='error' showIcon />
        </div>
      ) : null}
      <Text
        variant='paragraphSmall'
        color={colors.NEUTRAL_50}
        weight='regular'
        style={'align-self: flex-end; margin-bottom: 16px;'}
      >
        <Link
          css={css`
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          `}
          to={`${ROUTES.FORGOT_PASSWORD}?is_registration=true&registration_session_id=${session?.id}&schedule_id=${registration?.id}`}
        >
          Forgot Your Password?
        </Link>
      </Text>
      <div
        css={css`
          border-bottom: 1px solid ${colors.VERY_DARK_GRAYISH_BLUE};
          margin-bottom: 16px;
          width: 100%;
        `}
      />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 8px;
          width: 50%;
          justify-content: flex-end;
          align-self: flex-end;
          @media (max-width: 768px) {
            width: 100%;
            flex-direction: column-reverse;
          }
        `}
      >
        <Button
          onClick={() => {
            setStep(REGISTRATION_STEPS.SIGN_UP)
          }}
          variant='secondary'
          text='BACK'
          disabled={loading}
          isLoading={loading}
        />

        <Button
          onClick={async () => {
            try {
              setLoading(true)
              await login({ email, password })
            } catch (e) {
              if (e?.status === 401) {
                message.error(t('errors:invalidLogin'))
                return
              }
              const erroMessage = getErrorMessage(e)
              message.error(erroMessage)
            } finally {
              setLoading(false)
            }
          }}
          disabled={email === '' || password === '' || loading}
          variant='primary'
          text='NEXT'
          isLoading={loading}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex: 1;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 16px;
        `}
      >
        <img
          src={'/images/powered_by_sportninja.png'}
          css={css`
            height: 40px;
            max-width: 250px;
          `}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: bindActionToPromise(dispatch, authActions.login.request),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
