/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import React from 'react'
import PhoneInput from 'src/components/ts-components/PhoneInput'
import Text from 'src/components/ts-components/Text'
import { TextInput } from 'src/components/ts-components/TextInput'
import { Separator } from '../GeneralForm'
import { useScheduleContext } from '../ScheduleContext'
import '../styles.scss'

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const websiteRegex =
  /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/

const ContactInfoForm: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const {
    customError,
    fieldErrors,
    setFieldErrors,
    setCustomError,
    contactInfo,
    setContactInfo,
  } = useScheduleContext()

  return (
    <div
      css={css`
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        gap: 16px;
        border-bottom: 1px solid #3f4753;
        ${isMobile &&
        css`
          padding-left: 8px;
          padding-right: 8px;
          padding-bottom: 16px;
        `}
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <Text
              variant='captionLarge'
              weight='semiBold'
              color={colors.NEUTRAL_0}
            >
              Contact Info
            </Text>
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            align-self: stretch;
            flex-direction: row;
            gap: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <PhoneInput
              value={contactInfo?.phone || ''}
              onChange={(value) => {
                setContactInfo({ ...contactInfo, phone: value })
              }}
              label='Phone Number'
              error={
                fieldErrors.includes('phone') ? 'Invalid phone number' : ''
              }
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <TextInput
              label='Email'
              value={contactInfo?.email || ''}
              setValue={(value) => {
                setContactInfo({ ...contactInfo, email: value })
              }}
              onBlur={() => {
                if (
                  contactInfo.email &&
                  contactInfo.email.length > 0 &&
                  !emailRegex.test(contactInfo.email)
                ) {
                  setFieldErrors([...fieldErrors, 'email'])
                  setCustomError({
                    ...customError,
                    email: 'Invalid email',
                  })
                } else {
                  setFieldErrors(
                    fieldErrors.filter((field) => field !== 'email')
                  )
                  setCustomError({
                    ...customError,
                    email: '',
                  })
                }
              }}
              fieldErrors={fieldErrors}
              customError={customError}
              disabled={false}
              name='email'
              type='email'
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            align-self: stretch;
            flex-direction: row;
            gap: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <TextInput
              label='Website'
              value={contactInfo?.website || ''}
              setValue={(value) => {
                setContactInfo({ ...contactInfo, website: value })
              }}
              onBlur={() => {
                if (
                  contactInfo.website &&
                  contactInfo.website.length > 0 &&
                  !contactInfo.website.match(websiteRegex)
                ) {
                  setFieldErrors([...fieldErrors, 'website'])
                  setCustomError({
                    ...customError,
                    website: 'Invalid website',
                  })
                } else {
                  // remove the field from the fieldErrors and customError
                  setFieldErrors(
                    fieldErrors.filter((field) => field !== 'website')
                  )
                  setCustomError({
                    ...customError,
                    website: '',
                  })
                }
              }}
              fieldErrors={fieldErrors}
              customError={customError}
              disabled={false}
              name='website'
              type='url'
            />
          </div>
        </div>
        <Separator />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <Text
              variant='captionLarge'
              weight='semiBold'
              color={colors.NEUTRAL_0}
            >
              Support Info
            </Text>
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            align-self: stretch;
            flex-direction: row;
            gap: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <PhoneInput
              value={contactInfo?.support_phone || ''}
              onChange={(value) => {
                setContactInfo({ ...contactInfo, support_phone: value })
              }}
              label='Support Phone'
              error={
                fieldErrors.includes('support_phone')
                  ? 'Invalid phone number'
                  : ''
              }
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <TextInput
              label='Support Email'
              value={contactInfo?.support_email || ''}
              setValue={(value) => {
                setContactInfo({ ...contactInfo, support_email: value })
              }}
              onBlur={() => {
                if (
                  contactInfo.support_email &&
                  contactInfo.support_email.length > 0 &&
                  !emailRegex.test(contactInfo.support_email)
                ) {
                  setFieldErrors([...fieldErrors, 'support_email'])
                  setCustomError({
                    ...customError,
                    support_email: 'Invalid Support Email',
                  })
                } else {
                  setFieldErrors(
                    fieldErrors.filter((field) => field !== 'support_email')
                  )
                  setCustomError({
                    ...customError,
                    support_email: '',
                  })
                }
              }}
              fieldErrors={fieldErrors}
              customError={customError}
              disabled={false}
              name='support_email'
              type='email'
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            align-self: stretch;
            flex-direction: row;
            gap: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <TextInput
              label='Support Website'
              value={contactInfo?.support_website || ''}
              setValue={(value) => {
                setContactInfo({ ...contactInfo, support_website: value })
              }}
              onBlur={() => {
                if (
                  contactInfo.support_website &&
                  contactInfo.support_website.length > 0 &&
                  !contactInfo.support_website.match(websiteRegex)
                ) {
                  setFieldErrors([...fieldErrors, 'support_website'])
                  setCustomError({
                    ...customError,
                    support_website: 'Invalid Support Website',
                  })
                } else {
                  // remove the field from the fieldErrors and customError
                  setFieldErrors(
                    fieldErrors.filter((field) => field !== 'support_website')
                  )
                  setCustomError({
                    ...customError,
                    support_website: '',
                  })
                }
              }}
              fieldErrors={fieldErrors}
              customError={customError}
              disabled={false}
              name='support_website'
              type='url'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInfoForm
