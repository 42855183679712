/** @jsxImportSource @emotion/react */
import { UserOutlined, LogoutOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { App, Select } from 'antd'
import { useCallback, useContext, useEffect, useState } from 'react'
import { EventCard } from 'src/components/RegistrationEventCard'
import Button from 'src/components/ts-components/Button'
import Text from 'src/components/ts-components/Text'
import { useDivisionFiltering } from 'src/hooks/useDivisionFiltering'
import { RegistrationContext } from '../context/RegistrationContext'
import DivisionCard from '../DivisionCard'
import { PageTitle, REGISTRATION_STEPS } from './Main'
import { checkIfUserIsVerified } from './New_ConfirmYourEmail'

const selectStyle = css`
  border-radius: 8px;
  border: 1px solid ${colors.STORM_GRAY};
  background: ${colors.VERY_DARK_GRAYISH_BLUE};
  display: flex;
  overflow: hidden;
  color: ${colors.WHITE};
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 145%; /* 20.3px */
  min-width: 150px;
`

const SelectYourDivision = () => {
  const {
    registration,
    setStep,
    setDivision,
    isLogged,
    genderFilters,
    ageFilters,
    dayOfWeekFilters,
    updateSessionDivision,
    session,
    deleteSession,
    currency,
    email,
    resetSession,
    logout,
    userId,
  } = useContext(RegistrationContext)

  const { message } = App.useApp()
  const [selectedDivision, setSelectedDivision] = useState(null)
  const [selectedAge, setSelectedAge] = useState(null)
  const [selectedGender, setSelectedGender] = useState(null)
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(null)
  const divisions = useDivisionFiltering(
    registration?.divisions,
    selectedAge,
    selectedGender,
    selectedDayOfWeek
  )

  const isTeamRegistrationEnabled =
    registration?.registration?.is_team_registration
  const isPlayerRegistrationEnabled =
    registration?.registration?.is_player_registration

  const divisionsAvailable = divisions?.length > 0

  const handleDivisionSelect = (division) => {
    if (selectedDivision?.id === division?.id) {
      setSelectedDivision(null)
    } else {
      setSelectedDivision(division)
    }
  }

  const handleAgeChange = (value) => {
    setSelectedAge(value)
  }

  const handleGenderChange = (value) => {
    setSelectedGender(value)
  }

  const handleDayOfWeekChange = (value) => {
    setSelectedDayOfWeek(value)
  }

  useEffect(() => {
    if (session && session?.division_id) {
      setSelectedDivision(
        divisions?.find(
          (division) => division?.schedule_id === session?.division_id
        )
      )
    }
  }, [session, divisions])

  const onPressNext = useCallback(async () => {
    try {
      await updateSessionDivision(selectedDivision?.schedule_id, userId)
      setDivision(selectedDivision)
      if (isLogged) {
        // setStep(REGISTRATION_STEPS.TEAM_REGISTER)
        checkIfUserIsVerified()
          .then(() => {
            setStep(REGISTRATION_STEPS.SELECT_YOUR_ROLE)
          })
          .catch(() => {
            setStep(REGISTRATION_STEPS.CONFIRM_YOUR_EMAIL)
          })
      } else {
        setStep(REGISTRATION_STEPS.SIGN_UP)
      }
    } catch (e) {
      if (e?.status === 410) {
        deleteSession()
        window.location.reload()
        return
      }
      message.error(
        getErrorMessage(e) ||
          e?.invalid_fields?.registration_step ||
          'Something Went Wrong, Please Try Again Later'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDivision, setDivision, isLogged, setStep, message, deleteSession])

  return (
    <>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
          background-color: ${colors.NEUTRAL_800};
          width: 1080px;
          padding: 0 24px 24px 24px;
          border-radius: 16px 16px 0px 0px;
          @media (max-width: 768px) {
            min-width: 0;
            width: 100%;
          }
        `}
      >
        <style>
          {`
          .card-container {
            padding: 16px 0;
            display: grid;
            justify-content: center;
            grid-template-columns: repeat(
              auto-fill,
              minmax(300px, 1fr)
            ); /* Creates as many columns as possible with a minimum of 300px */
            gap: 16px; /* Spacing between cards */
          }
          
          /* Media query for desktops */
          @media (min-width: 768px) {
            .card-container {
              grid-template-columns: repeat(2, 1fr); /* 2 columns on desktop */
            }
          }          
        `}
        </style>
        {email && isLogged ? (
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;
              margin-top: 16px;
              margin-bottom: -8px;
            `}
          >
            <a
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: default;
              `}
              target='_blank'
              rel='noreferrer'
            >
              <UserOutlined
                css={css`
                  color: ${colors.PRIMARY};
                `}
              />
              <Text
                variant='paragraphSmall'
                color={colors.PRIMARY}
                weight='regular'
              >
                {email}{' '}
              </Text>
            </a>
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;
              `}
              onClick={() => {
                logout()
                resetSession()
                window.location.reload()
              }}
            >
              <LogoutOutlined
                css={css`
                  color: ${colors.ERROR_200};
                `}
              />
              <Text
                variant='paragraphSmall'
                color={colors.ERROR_200}
                weight='regular'
              >
                Logout
              </Text>
            </div>
          </div>
        ) : null}
        <EventCard eventInfo={registration} />
        <PageTitle
          title='Choose a Division'
          subtitle={
            'Start your registration by choosing the appropriate division. '
          }
          showAlert={false}
          showSuccess={false}
        />
        <>
          {ageFilters?.length > 1 ||
          genderFilters?.length > 1 ||
          dayOfWeekFilters?.length > 1 ? (
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                margin-bottom: 16px;
                flex-wrap: wrap;
                @media (max-width: 768px) {
                  flex-direction: column;
                  width: 100%;

                  .ant-select {
                    width: 100%;
                  }
                }
              `}
            >
              {/* Age */}
              {ageFilters?.length > 1 ? (
                <Select
                  defaultValue={''}
                  css={selectStyle}
                  bordered={false}
                  onChange={handleAgeChange}
                  options={[
                    { value: '', label: 'Select an Age' },
                    ...ageFilters.map((age) => ({
                      value: age,
                      label: age,
                    })),
                  ]}
                />
              ) : null}
              {/* Gender */}
              {genderFilters?.length > 1 ? (
                <Select
                  defaultValue={''}
                  css={selectStyle}
                  bordered={false}
                  onChange={handleGenderChange}
                  options={[
                    { value: '', label: 'Select a Gender' },
                    ...genderFilters.map((gender) => ({
                      value: gender,
                      label: gender,
                    })),
                  ]}
                />
              ) : null}
              {/* Day of Week */}
              {dayOfWeekFilters?.length > 1 ? (
                <Select
                  defaultValue={''}
                  css={selectStyle}
                  bordered={false}
                  onChange={handleDayOfWeekChange}
                  options={[
                    { value: '', label: 'Select a Day of Week' },
                    ...dayOfWeekFilters.map((dayOfWeek) => ({
                      value: dayOfWeek,
                      label: dayOfWeek,
                    })),
                  ]}
                />
              ) : null}
            </div>
          ) : null}
        </>
        {!divisionsAvailable ? (
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              height: 500px;
              width: 100%;
              align-self: center;
            `}
          >
            <Text
              variant='paragraphMedium'
              color={colors.WHITE}
              weight='regular'
            >
              No Divisions Available
            </Text>
          </div>
        ) : (
          <div className='card-container'>
            {divisions &&
              divisions?.length > 0 &&
              divisions?.map((division, index) => {
                return (
                  <DivisionCard
                    key={index}
                    division={division}
                    isSelected={selectedDivision?.id === division.id}
                    currencyName={currency?.name || ''}
                    onSelect={handleDivisionSelect}
                    isPlayerRegistrationEnabled={isPlayerRegistrationEnabled}
                    isTeamRegistrationEnabled={isTeamRegistrationEnabled}
                    hasSelectedDivision={!!selectedDivision}
                  />
                )
              })}
          </div>
        )}
        {divisionsAvailable ? (
          <div
            css={css`
              height: 150px;
            `}
          />
        ) : null}
      </div>
      <div
        css={css`
          position: fixed;
          bottom: 0;
          background: linear-gradient(
            0deg,
            #21252a 37.24%,
            rgba(51, 54, 59, 0) 100%
          );
          z-index: 100;
          width: 1080px;
          min-height: 150px;
          padding-top: 32px;
          padding-bottom: 16px;
          padding-left: 35px;
          padding-right: 35px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          gap: 8px;
          ${selectedDivision ? 'display: flex;' : 'display: none;'}
          @media (max-width: 768px) {
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 0;
          }
        `}
      >
        <div
          css={css`
            align-self: flex-end;
            @media (max-width: 768px) {
              width: 100%;
            }
          `}
        >
          <Button
            onClick={onPressNext}
            text='NEXT'
            disabled={!selectedDivision}
            variant='primary'
            style={css`
              width: 200px;
              @media (max-width: 768px) {
                width: 100%;
              }
            `}
          />
        </div>
        <Text
          variant='paragraphXSmall'
          color={colors.WHITE}
          weight='regular'
          style={`
                text-align: center;
                align-self: flex-end;
              `}
        >
          * Applicable taxes will be applied to all payments
        </Text>
      </div>
    </>
  )
}

export default SelectYourDivision
