/* eslint-disable react-native/no-inline-styles */
/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Badge, Spin, Table, TableProps } from 'antd'
import React, { useEffect } from 'react'
import {
  paragraphSmall,
  paragraphXSmall,
  weightRegular,
} from 'src/components/css'
import { TextInput } from 'src/components/ts-components/TextInput'
import { SyncOutlined } from '@ant-design/icons'
import req from '@sportninja/common/api/request'
import { DaysmartSyncIntervalEnum } from '@sportninja/common/constants/DaysmartSyncInterval'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { CheckBoxInput } from 'src/components/ts-components/CheckBoxInput'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import Text from 'src/components/ts-components/Text'
import { useScheduleContext } from '../ScheduleContext'
import '../styles.scss'

type DaysmartFormProps = {
  onCancelPressed: () => void
  triggerRefresh: () => void
}

interface DataType {
  id: string
  title: string
  details: string
  is_success: boolean
  created_at: string
  user_email: string
  daysmart_send_invitation: boolean
  daysmart_sync_interval: string
  daysmart_facilities_as_sub_resources: boolean
}

const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Message',
    dataIndex: 'title',
    key: 'title',
    width: '35%',
    render: (title: string, record: DataType) => {
      return (
        <div>
          <p
            css={css`
              margin: 4px 0 0;
              font-size: 14px;
              white-space: normal;
              word-break: break-word;
            `}
          >
            {new Date(record.created_at)
              .toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })
              .replace(/(\d+)(?=(,\s\d{4}))/, (match) => {
                const day = parseInt(match)
                return `${day}`
              })}
          </p>
          <p
            css={css`
              margin: 4px 0 0;
              font-size: 12px;
              color: ${colors.SECONDARY_75};
              white-space: normal;
              word-break: break-word;
            `}
          >
            {title}
          </p>
        </div>
      )
    },
  },
  {
    title: 'Status',
    dataIndex: 'is_success',
    key: 'is_success',
    width: '12%',
    render: (isSuccess: boolean, record: DataType) => {
      return isSuccess ? (
        <Badge status='success' text='Success' />
      ) : (
        <div>
          <Badge status='error' text='Failed' />
          {record.details && (
            <p
              css={css`
                margin: 4px 0 0;
                font-size: 12px;
                color: ${colors.SECONDARY_75};
                white-space: normal;
                word-break: break-word;
              `}
            >
              {record.details}
            </p>
          )}
        </div>
      )
    },
  },
  {
    title: 'Email',
    dataIndex: 'user_email',
    key: 'user_email',
    width: '20%',
    render: (email: string) => (
      <p
        css={css`
          margin: 4px 0 0;
          font-size: 12px;
          white-space: normal;
          word-break: break-word;
        `}
      >
        {email}
      </p>
    ),
  },
  {
    title: 'Invites',
    dataIndex: 'daysmart_send_invitation',
    key: 'daysmart_send_invitation',
    width: '11%',
    render: (sendInvitation: boolean) => (
      <Badge
        status={sendInvitation ? 'success' : 'default'}
        text={sendInvitation ? 'Yes' : 'No'}
      />
    ),
  },
  {
    title: 'Sub-res.',
    dataIndex: 'daysmart_facilities_as_sub_resources',
    key: 'daysmart_facilities_as_sub_resources',
    width: '11%',
    render: (facilitiesAsSubResources: boolean) => (
      <Badge
        status={facilitiesAsSubResources ? 'success' : 'default'}
        text={facilitiesAsSubResources ? 'Yes' : 'No'}
      />
    ),
  },
  {
    title: 'Interval',
    dataIndex: 'daysmart_sync_interval',
    key: 'daysmart_sync_interval',
    width: '11%',
    render: (interval: string) => (
      <p
        css={css`
          margin: 4px 0 0;
          font-size: 12px;
          white-space: normal;
          word-break: break-word;
        `}
      >
        {interval}
      </p>
    ),
  },
]

const DaysmartForm: React.FC<DaysmartFormProps> = () => {
  const {
    id,
    daySmartSeasonId,
    sportId,
    genericSportId,
    fieldErrors,
    customError,
    setError,
    daySmartSeasonName,
    daySmartSyncInterval,
    setDaySmartSyncInterval,
    daySmartSendInvitation,
    setDaySmartSendInvitation,
    daySmartFacilitiesAsSubResources,
    setDaySmartFacilitiesAsSubResources,
    isSyncing,
    setIsSyncing,
    message,
  } = useScheduleContext()

  const [isLoadingDaysmartLogs, setIsLoadingDaysmartLogs] =
    React.useState(false)
  const [daysmartLogs, setDaysmartLogs] = React.useState<DataType[]>([])

  const fetchDaysmartLogs = async () => {
    try {
      setIsLoadingDaysmartLogs(true)
      const { data } = await req(`/schedules/${id}/daysmart/log`)
      setDaysmartLogs(data)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      message?.open({
        type: 'error',
        content: errorMessage,
      })
    } finally {
      setIsLoadingDaysmartLogs(false)
    }
  }

  const syncNow = async () => {
    try {
      setIsSyncing(true)
      await req(`/schedules/${id}/daysmart/import`, {
        method: 'POST',
        body: JSON.stringify({
          season_id: daySmartSeasonId,
          sport_id: sportId,
          generic_sport_id: genericSportId,
        }),
      })
      await fetchDaysmartLogs()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
    } finally {
      setIsSyncing(false)
    }
  }

  useEffect(() => {
    fetchDaysmartLogs()
  }, [])

  return (
    <div
      css={css`
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: -16px;
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: auto;
        /* hide the scrollbar */
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
        height: 100%;
        gap: 16px;
        border-bottom: 1px solid #3f4753;
      `}
    >
      <style>
        {`
          .ant-tooltip {
            z-index: 20000 !important;
          }
          .ant-tooltip-inner {
            z-index: 20000 !important;
          }
          .ant-table-cell {
            white-space: normal !important;
            word-break: break-word !important;
            padding: 4px !important;
          }
        `}
      </style>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <Text
              variant='captionLarge'
              weight='semiBold'
              color={colors.NEUTRAL_0}
            >
              Daysmart Sync Service
            </Text>
            <p
              css={css`
                ${paragraphSmall}
                ${weightRegular}
                color: ${colors.SECONDARY_75};
              `}
            >
              The Daysmart Sync Service allows you to sync your competition with
              a pre-determined frequency.
            </p>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
            `}
          >
            <div
              css={css`
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
              `}
            >
              <TextInput
                name={'daysmartSeason'}
                value={daySmartSeasonName}
                setValue={(value) =>
                  console.log('Daysmart Season value:', value)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Daysmart Season:'
                type='text'
                disabled={true}
              />
            </div>
            <div
              css={css`
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 6px;
                align-self: stretch;
              `}
            >
              <SelectInput
                label={'Automatic Sync:'}
                options={[
                  {
                    label: 'None',
                    value: DaysmartSyncIntervalEnum.NONE,
                  },
                  {
                    label: 'Every 24 hours',
                    value: DaysmartSyncIntervalEnum.TWENTY_FOUR_HOURS,
                  },
                  {
                    label: 'Every 48 hours',
                    value: DaysmartSyncIntervalEnum.FOURTY_EIGHT_HOURS,
                  },
                  {
                    label: 'Every 72 hours',
                    value: DaysmartSyncIntervalEnum.SEVENTY_TWO_HOURS,
                  },
                ]}
                name={'daySmartSyncInterval'}
                value={daySmartSyncInterval}
                showSearch={false}
                onChange={(value) => {
                  setDaySmartSyncInterval(value as DaysmartSyncIntervalEnum)
                }}
                fieldErrors={fieldErrors}
                customError={customError}
                disabled={false}
              />
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              width: 100%;
              align-items: center;
              justify-content: start;
            `}
          >
            <div
              css={css`
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                align-self: stretch;
              `}
            >
              <CheckBoxInput
                customError={customError}
                fieldErrors={fieldErrors}
                disabled={false}
                label='Send Invitations to Players/Team Officials during Sync'
                name='send_invitations'
                onChange={(value) =>
                  setDaySmartSendInvitation(value.target.checked)
                }
                value={daySmartSendInvitation}
                checkBoxStyle={css`
                  width: unset;
                `}
                extraSmallFont={true}
              />
            </div>
            <div
              css={css`
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                align-self: stretch;
              `}
            >
              <CheckBoxInput
                customError={customError}
                fieldErrors={fieldErrors}
                disabled={false}
                label='Facilities are assigned as Sub-resources in DaySmart'
                name='facilities_as_sub_resources'
                onChange={(value) =>
                  setDaySmartFacilitiesAsSubResources(value.target.checked)
                }
                value={daySmartFacilitiesAsSubResources}
                checkBoxStyle={css`
                  width: unset;
                `}
                extraSmallFont={true}
              />
            </div>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;
            `}
          >
            {isSyncing ? (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                `}
                onClick={() => {
                  setIsSyncing(true)
                  setTimeout(() => {
                    setIsSyncing(false)
                  }, 2000)
                }}
              >
                <Spin
                  size='small'
                  style={{
                    color: colors.PRIMARY,
                  }}
                />
              </div>
            ) : (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                `}
                onClick={async () => {
                  await syncNow()
                }}
              >
                <SyncOutlined
                  style={{
                    fontSize: '16px',
                    color: colors.PRIMARY,
                  }}
                />
                <span
                  css={css`
                    ${paragraphXSmall}
                    ${weightRegular}
                  color: ${colors.PRIMARY};
                  `}
                >
                  Sync Now
                </span>
              </div>
            )}
          </div>
          <Table
            size='small'
            bordered={true}
            columns={columns}
            dataSource={daysmartLogs}
            style={{
              alignSelf: 'stretch',
              width: '100%',
            }}
            loading={isLoadingDaysmartLogs}
            scroll={{ x: true }}
            pagination={{
              pageSize: 4,
              hideOnSinglePage: true,
            }}
          />
        </div>
        {/* <Separator /> */}
      </div>
    </div>
  )
}

export default DaysmartForm
