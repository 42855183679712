/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import Text from 'src/components/ts-components/Text'
import { useRegistrationSetupContext } from '../../context'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import SelectMultiInput from 'src/components/ts-components/SelectMultiInput'
import { Alert } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import { ROUTES } from '@sportninja/common/constants/app'
import { Link } from 'react-router-dom'
import { WarningFilled } from '@ant-design/icons'

const Payments = () => {
  const { state, setState, availableTaxes, registrationTypes } =
    useRegistrationSetupContext()

  return (
    <div
      css={css`
        display: flex;
        padding: 16px;
        gap: 16px;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text variant='captionLarge' weight='semiBold'>
          Payments
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
        `}
      >
        {/* <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <SelectInput
            label='Payment Provider'
            options={[
              { label: 'SportsPay', value: '2' },
              { label: 'Stripe', value: '1' },
            ]}
            value={state.payment_provider}
            onChange={(value) => {
              setState({
                ...state,
                payment_provider: value,
              })
            }}
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            disabled={false}
            name='payment_provider'
          />
        </div> */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <SelectInput
            label='Currency'
            options={
              registrationTypes?.currency
                ?.sort((a, b) => {
                  if (a.name === 'USD') {
                    return -1
                  }
                  if (b.name === 'USD') {
                    return 1
                  }
                  if (a.name === 'CAD') {
                    return -1
                  }
                  if (b.name === 'CAD') {
                    return 1
                  }
                  return 0
                })
                ?.map((currency) => ({
                  label: currency.name,
                  value: currency.id,
                })) || []
            }
            value={state.currency}
            onChange={(value) => {
              setState({
                ...state,
                currency: value,
              })
            }}
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            disabled={false}
            name='currency'
          />
        </div>

        {/* SN-5040 Removing as requested "Do we need these options?" */}
        {/* <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <SelectInput
            label='Issue Payment As'
            options={
              registrationTypes.registration_payment_issue?.map((payment) => ({
                label: payment.name,
                value: payment.id,
              })) || []
            }
            value={state.payment_type}
            onChange={(value) => {
              setState({
                ...state,
                payment_type: value,
              })
            }}
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            disabled={false}
            name='payment_type'
          />
        </div> */}
      </div>
      <SelectMultiInput
        label='Taxes'
        options={
          availableTaxes?.map((tax) => ({
            label: tax.name,
            value: tax.id,
          })) || []
        }
        value={state.taxes}
        onChange={(value) => {
          setState({
            ...state,
            taxes: value,
          })
        }}
        fieldErrors={state.fieldErrors}
        customError={state.customError}
        name='taxes'
      />
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          width: 100%;
        `}
      >
        <Alert
          message={
            <Text
              variant='paragraphSmall'
              weight='regular'
              color={colors.PRIMARY}
              style={'width: 100%; margin-left: 8px;'}
            >
              Taxes And Your Payment Provider Can Be Configured In The{' '}
              <Link
                to={`${ROUTES.GENERAL_SETTINGS}?origin=payment_provider`}
                target='_blank'
              >
                Account Settings
              </Link>
              .
            </Text>
          }
          type='warning'
          showIcon
          icon={
            <WarningFilled
              css={css`
                font-size: 20px;
                color: ${colors.PRIMARY};
              `}
            />
          }
          css={css`
            width: 100%;
          `}
        />
      </div>
    </div>
  )
}

export default Payments
