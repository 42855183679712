/* eslint-disable react-native/no-inline-styles */
/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import {
  CloseOutlined,
  InboxOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import colors from '@sportninja/common/constants/appColors'
import { captionLarge, heading6, paragraphMedium, weightSemiBold } from '../css'
import { Upload, Spin, Alert, Tabs, Tag } from 'antd'
import type { UploadProps } from 'antd'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { TextInput } from '../ts-components/TextInput'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import req, { getHeaders } from '@sportninja/common/api/request'
// @ts-ignore
import EnvHandler from '@sportninja/common/utils/env-handler'

const VIDEO_TYPE = {
  VIDEO_UPLOAD: '1',
  YOUTUBE_URL: '2',
}

const closeStyle = {
  color: colors.WHITE,
  fontSize: 20,
}

const { Dragger } = Upload

interface UploadVideoModalProps {
  onPressClose?: () => void
  gameId?: string | null
  readGame: (id: string | null) => void
  broadcastVideoUrl?: string
}

const UploadVideoModal: React.FC<UploadVideoModalProps> = ({
  onPressClose = () => {},
  gameId = null,
  readGame = () => {},
  broadcastVideoUrl = '',
}) => {
  const isEditing = broadcastVideoUrl !== null

  const isHostedOnS3 = broadcastVideoUrl?.includes('s3.amazonaws.com')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [videoUrl, setVideoUrl] = useState<string>(
    !isHostedOnS3 ? broadcastVideoUrl || '' : ''
  )

  const [authHeaders, setAuthHeaders] = useState<any>(null)
  const [authUrl, setAuthUrl] = useState<any>(null)
  const [activeTab, setActiveTab] = useState(
    !isHostedOnS3 && isEditing
      ? VIDEO_TYPE.YOUTUBE_URL
      : VIDEO_TYPE.VIDEO_UPLOAD
  )
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [uploadFileName, setUploadFileName] = useState<string | null>(null)

  useEffect(() => {
    const fetchAuthHeaders = async () => {
      const headers = await getHeaders(true)
      const auth = headers.get('authorization')
      setAuthHeaders(auth)
    }
    fetchAuthHeaders()
    EnvHandler.get().then((value) => {
      setAuthUrl(value)
    })
  }, [])

  const props: UploadProps = useMemo(
    () => ({
      accept: 'video/*',
      showUploadList: true,
      disabled: isLoading || uploadSuccess,
      name: 'video',
      multiple: false,
      action: `${authUrl}/games/${gameId}/upload`,
      headers: {
        Authorization: `${authHeaders}`,
      },
      beforeUpload(file) {
        console.log('beforeUpload', file)
        setIsLoading(true)
      },
      onChange(info) {
        setError(null)
        setUploadSuccess(false)
        const { status } = info.file
        if (status !== 'uploading') {
          console.log(info.file, info.fileList)
        }
        if (status === 'done') {
          setUploadFileName(info.file.name)
          setUploadSuccess(true)
        } else if (status === 'error') {
          let e = info?.file?.error?.message
            ? `An error occurred (${info.file.error.status}): ${info.file.error.message}`
            : 'An error occurred while uploading the file. (No message from server)'
          setError(e)
          setUploadSuccess(false)
        }
        setIsLoading(false)
      },
      onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files)
      },
    }),
    [isLoading, uploadSuccess, authUrl, gameId, authHeaders]
  )

  const tabs = useMemo(
    () => [
      {
        key: VIDEO_TYPE.VIDEO_UPLOAD,
        label: (
          <p
            css={css`
              ${captionLarge}
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
              color: ${colors.WHITE};
            `}
          >
            Upload Video
          </p>
        ),
        children: (
          <>
            <Dragger {...props}>
              {
                <p className='ant-upload-drag-icon'>
                  {uploadSuccess ? (
                    <CheckCircleOutlined
                      style={{ color: colors.GREEN_GOBLIN }}
                    />
                  ) : (
                    <InboxOutlined />
                  )}
                </p>
              }
              {uploadFileName ? (
                <p
                  css={css`
                    ${paragraphMedium}
                    color: ${colors.WHITE};
                    margin-bottom: 0;
                  `}
                >
                  {uploadFileName} uploaded successfully
                </p>
              ) : null}
              {!uploadSuccess ? (
                <>
                  <p className='ant-upload-text'>
                    Click here to upload your video
                  </p>
                  <p className='ant-upload-hint'>
                    Supported formats: MP4, WEBM, OGG (up to 200 MB)
                  </p>
                </>
              ) : null}
            </Dragger>
          </>
        ),
      },
      {
        key: VIDEO_TYPE.YOUTUBE_URL,
        label: (
          <p
            css={css`
              ${captionLarge}
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
              color: ${colors.WHITE};
            `}
          >
            YouTube (URL)
          </p>
        ),
        children: (
          <TextInput
            name={'videoUrl'}
            value={videoUrl}
            setValue={(value) => setVideoUrl(value)}
            label='URL'
            type='text'
            customError={{}}
            fieldErrors={[]}
          />
        ),
      },
    ],
    [props, uploadFileName, uploadSuccess, videoUrl]
  )

  const onChangeTabs = (key) => {
    setActiveTab(key)
  }

  const isSaveDisabled = useCallback(() => {
    if (isLoading) {
      return true
    }
    if (activeTab === VIDEO_TYPE.VIDEO_UPLOAD) {
      if (uploadSuccess) {
        return false
      }
      return true
    }
    if (activeTab === VIDEO_TYPE.YOUTUBE_URL) {
      if (videoUrl) {
        return false
      }
      return true
    }
  }, [isLoading, activeTab, uploadSuccess, videoUrl])

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true)
      if (isSaveDisabled()) {
        return
      }
      // If that's a video upload
      // we just need to read the game and close the modal
      if (activeTab === VIDEO_TYPE.VIDEO_UPLOAD) {
        onPressClose()
        if (isEditing) {
          window.location.reload()
        } else {
          readGame(gameId)
        }
        return
      }
      // If that's a youtube video
      // we need to validate the URL
      // and update the game with the new video URL
      if (activeTab === VIDEO_TYPE.YOUTUBE_URL) {
        const youtubeUrl = new RegExp(
          '^(https?://)?(www.youtube.com|m.youtube.com|youtu.?be)/.+$'
        )
        if (!youtubeUrl.test(videoUrl)) {
          setError('Please enter a valid Youtube URL')
          return
        }
      }
      await req(`/games/${gameId}`, {
        method: 'PUT',
        body: JSON.stringify({
          broadcast_video_url: videoUrl,
        }),
      })
      if (isEditing) {
        window.location.reload()
      } else {
        await readGame(gameId)
      }
      onPressClose()
    } catch (e: any) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }, [
    activeTab,
    isEditing,
    gameId,
    videoUrl,
    onPressClose,
    readGame,
    isSaveDisabled,
    setError,
    setIsLoading,
  ])

  return (
    <div
      css={css`
        background: #26303e;
        width: 600px;
        border-radius: 8px;
        @media (max-width: 768px) {
          width: 90vw;
        }
      `}
    >
      <style>
        {`
          .ant-input {
            background-color: ${colors.HEADER};
          }
        `}
      </style>
      <div
        css={{
          padding: '24px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '16px',
        }}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
        >
          <>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                gap: 8px;
                align-self: stretch;
              `}
            >
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  align-self: stretch;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;
                  `}
                >
                  <h1
                    css={css`
                      font-size: 32px;
                      font-family: ${isCanlan
                        ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                        : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                      font-weight: 600;
                      color: ${colors.WHITE};
                    `}
                  >
                    {'Add Game Video'}
                  </h1>
                  <Tag
                    color={'rgba(242, 180, 0, 0.10)'}
                    style={{
                      marginTop: 4,
                      color: colors.PRIMARY,
                    }}
                  >
                    Beta
                  </Tag>
                </div>
                <div
                  css={css`
                    display: flex;
                    gap: 16px;
                  `}
                >
                  <button
                    css={css`
                      cursor: ${isLoading ? 'not-allowed' : 'pointer'};
                    `}
                    onClick={() => {
                      if (isLoading) {
                        return
                      }
                      onPressClose()
                    }}
                  >
                    <CloseOutlined style={closeStyle} />
                  </button>
                </div>
              </div>
            </div>
            <div
              css={css`
                border-bottom: 1px solid ${colors.SOFT_STEEL};
              `}
            />
          </>
          <span
            css={css`
              ${heading6}
              ${weightSemiBold}
                      color: ${colors.WHITE};
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
            `}
          >
            You can upload your video or add the URL of the video you want to
            share with players and fans.
          </span>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 16px;
          gap: 16px;
          .ant-tabs > .ant-tabs-nav {
            background: ${'#26303e'} !important;
          }
        `}
      >
        <Tabs
          defaultActiveKey={
            !isHostedOnS3 && isEditing
              ? VIDEO_TYPE.YOUTUBE_URL
              : VIDEO_TYPE.VIDEO_UPLOAD
          }
          items={tabs}
          onChange={onChangeTabs}
          style={{ width: '100%', minHeight: 205 }}
          tabBarStyle={{
            height: 40,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: colors.SOFT_STEEL,
            textTransform: 'uppercase',
          }}
        />
        {error ? (
          <Alert
            message={error}
            type='error'
            css={css`
              width: 100%;
            `}
          />
        ) : null}
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            gap: 16px;
          `}
        >
          {broadcastVideoUrl ? (
            <div
              css={css`
                max-width: 160px;
                display: flex;
                padding: 8px 24px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;
                border-radius: 8px;
                border: 1px solid ${colors.ERROR_LIGHT};
                cursor: ${isLoading ? 'not-allowed' : 'pointer'};
                opacity: ${isLoading ? 0.7 : 1};
                align-self: flex-start;
              `}
              onClick={async () => {
                try {
                  setIsLoading(true)
                  await req(`/games/${gameId}/video`, {
                    method: 'DELETE',
                  })
                  await readGame(gameId)
                } catch (e) {
                  const errorMessage = getErrorMessage(e)
                  setError(errorMessage)
                } finally {
                  setIsLoading(false)
                  onPressClose()
                }
              }}
            >
              <p
                css={css`
                  ${captionLarge}
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
                  color: ${colors.ERROR_LIGHT};
                `}
              >
                Remove Video
              </p>
            </div>
          ) : (
            <div />
          )}
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 8px;
            `}
          >
            <div
              css={css`
                max-width: 80px;
                display: flex;
                padding: 8px 24px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;
                border-radius: 8px;
                border: 1px solid var(--Neutrals-neutrals-0, #fff);
                cursor: ${isLoading ? 'not-allowed' : 'pointer'};
                opacity: ${isLoading ? 0.7 : 1};
              `}
              onClick={() => {
                if (isLoading) {
                  return
                }
                onPressClose()
              }}
            >
              <p
                css={css`
                  ${captionLarge}
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
                  color: ${colors.WHITE};
                `}
              >
                Cancel
              </p>
            </div>
            <div
              css={css`
                max-width: 80px;
                display: flex;
                padding: 8px 24px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;
                border-radius: 8px;
                background: ${colors.PRIMARY};
                cursor: ${isSaveDisabled() ? 'not-allowed' : 'pointer'};
                opacity: ${isSaveDisabled() ? 0.7 : 1};
              `}
              onClick={handleClick}
            >
              <>
                {isLoading ? (
                  <Spin
                    size='small'
                    css={css`
                      color: ${colors.WHITE};
                    `}
                  />
                ) : (
                  <p
                    css={css`
                      ${captionLarge}
                      font-family: ${isCanlan
                        ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                        : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
                      color: ${colors.WHITE};
                    `}
                  >
                    {activeTab === VIDEO_TYPE.VIDEO_UPLOAD ? 'Finish' : 'Save'}
                  </p>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadVideoModal
