/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Spin } from 'antd'
import Text from 'src/components/ts-components/Text'
import colors from '@sportninja/common/constants/appColors'

/**
 * Props for the AlmostThere component
 * @typedef {Object} AlmostThereProps
 */

/**
 * AlmostThere component for the underage invitation flow
 * Displays a loading screen while the profile is being set up
 *
 * @param {AlmostThereProps} props - Component props
 * @returns {JSX.Element} The rendered component
 */
const AlmostThere = () => {
  return (
    <div
      css={css`
        padding-top: 32px;
        width: 30%;
        width: 100%;
        @media (max-width: 768px) {
          width: 100%;
        }
      `}
    >
      <Text variant='displaySmall' weight='bold' color={colors.NEUTRAL_0}>
        Almost there!
      </Text>
      <div
        css={css`
          margin: 32px 0;
          display: flex;
          gap: 16px;
          align-items: center;
        `}
      >
        <Text
          variant='paragraphMedium'
          weight='regular'
          color={colors.NEUTRAL_0}
        >
          We are setting up your profile.
        </Text>
        <div>
          <Spin />
        </div>
      </div>
    </div>
  )
}

export default AlmostThere
