/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Route, Switch } from 'react-router-dom'
import PageMenu from '../../components/Page/Menu'
import { media } from '../../components/Responsive'
import Text from 'src/components/ts-components/Text'

export const ProfileWrap = ({ baseRoute, children, loaded, pages }) => {
  return (
    <div
      css={css`
        max-width: 920px;
        margin: 32px auto;
        ${media.mobile} {
          margin: 8px;
        }
      `}
    >
      <>
        {children}
        {loaded && (
          <Switch>
            {pages.map((page, idx) => {
              let to = page.to
              if (idx === 0) {
                to = baseRoute
              }
              return (
                <Route key={page.to} exact path={to}>
                  {page.component}
                </Route>
              )
            })}
          </Switch>
        )}
      </>
    </div>
  )
}

export const ProfileTop = ({ children }) => {
  return (
    <div
      className='profile-top'
      css={css`
        background-color: ${colors.HEADER};
        border: 1px solid ${colors.SOFT_STEEL};
        border-radius: 8px 8px 0px 0px;
        padding: 16px;
        ${media.mobile} {
          min-height: 150px;
        }
      `}
    >
      {children}
    </div>
  )
}

export const ProfileName = ({ item }) => {
  return (
    <Text
      variant='displaySmall'
      weight='semiBold'
      color={colors.WHITE}
      style={`
        text-transform: capitalize;
      `}
    >
      {item.name_first} {item.name_last}
    </Text>
  )
}

export const ProfileMenu = ({ baseRoute, pages, isInFrame }) => {
  return (
    <div
      className='profile-menu'
      css={css`
        background-color: #1b1f25;
        padding: 0 16px;
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: #3f4753;
        border-radius: 0px 0px 8px 8px;
        margin-bottom: -1px;

        ${media.mobile} {
          height: 44px;
          padding: 0;
          overflow: hidden;
        }
      `}
    >
      <PageMenu
        isProfile
        baseRoute={baseRoute}
        pages={pages}
        isInFrame={isInFrame}
      />
    </div>
  )
}
