/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Link } from 'react-router-dom'

import { Flex } from '../Layout'
import ListCell from './Cell'
import React from 'react'

const ListRow = ({
  item,
  headers = [],
  options,
  sort,
  bgColor = '#292e35',
  highlightSorted,
  width,
  isMobile,
  alternativeOptionsIcon = false,
}) => {
  return (
    <Flex
      key={item?.id}
      noFlex
      row
      alignItems='center'
      justifyContent='space-between'
      className='list-row'
      css={css`
        position: relative;
        height: 56px;

        ${isMobile &&
        css`
          width: ${width}px;

          &,
          .left-buffer,
          .right-buffer {
            background-color: ${colors.HEADER};
          }
        `}

        transition: background-color 0.2s ease-in-out;

        .left-buffer,
        .right-buffer {
          transition: background-color 0.2s ease-in-out;
        }

        &:nth-of-type(even),
        &:nth-of-type(even) .first-cell,
        &:nth-of-type(even) .left-buffer,
        &:nth-of-type(even) .right-buffer {
          background-color: ${bgColor};
        }
      `}
    >
      <div
        className='left-buffer'
        css={css`
          position: absolute;
          width: 8px;
          top: 0;
          bottom: 0;
          left: -8px;
        `}
      />

      {headers.map((header, idx) => {
        let accessorValue
        try {
          if (typeof header.accessor === 'function') {
            accessorValue = header.accessor(item)
          } else if (header.accessor) {
            const accessors = header.accessor.split('.')
            if (accessors.length > 1) {
              let object = item
              for (let i = 0; i < accessors.length; i++) {
                object = object[accessors[i]]
              }
              accessorValue = object
            } else {
              accessorValue = item[accessors[0]]
            }
          }
        } catch (e) {
          console.error(e)
        }
        const isSorted =
          typeof header?.sort === 'string' && sort === header?.sort
        return (
          <ListCell
            to={header?.to ? header?.to(item) : undefined}
            as={header?.to && Link}
            key={header?.text || header?.idx || idx}
            sizePercent={header?.sizePercent}
            isSorted={highlightSorted ? isSorted : undefined}
            fontSize={header?.fontSize}
            minWidth={header?.minWidth}
            isFirstCell={idx === 0}
            isMobile={isMobile}
          >
            {header?.Cell ? (
              <header.Cell item={item} isSorted={isSorted} />
            ) : typeof accessorValue !== 'undefined' ? (
              accessorValue
            ) : (
              ''
            )}
          </ListCell>
        )
      })}

      {typeof options === 'function' && !alternativeOptionsIcon && (
        <Flex
          noFlex
          row
          alignItems='center'
          css={css`
            position: relative;
            width: 150px;
            min-width: 90px;
            margin-left: 20px;
            justify-content: flex-end;

            > * {
              margin-right: 12px;
            }
          `}
        >
          {options(item).map((option, index) => (
            <React.Fragment key={`option-${index}`}>{option}</React.Fragment>
          ))}
        </Flex>
      )}
      {typeof options === 'function' && alternativeOptionsIcon && (
        <Flex
          noFlex
          row
          alignItems='center'
          css={css`
            position: relative;
            width: 50px;
            justify-content: flex-end;

            > * {
              margin-right: 12px;
            }
          `}
        >
          {options(item).map((option, index) => (
            <React.Fragment key={`option-${index}`}>{option}</React.Fragment>
          ))}
        </Flex>
      )}

      <div
        className='right-buffer'
        css={css`
          position: absolute;
          width: 8px;
          top: 0;
          bottom: 0;
          right: -8px;
        `}
      />
    </Flex>
  )
}

export default ListRow
