import req from '@sportninja/common/api/request'
import { useCallback, useEffect, useState } from 'react'

export type Province = {
  id: number
  country_id: number
  name: string
  iso_3166_2: string
  geonames_id: number
  geonames_code: string
}

export const useProvinces = (countryId?: number) => {
  const [provinces, setProvinces] = useState<Province[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchProvinces = useCallback(async () => {
    if (!countryId) {
      setProvinces([])
      setLoading(false)
      return
    }

    setLoading(true)
    setError(null)
    try {
      const response = await req(`/countries/${countryId}/provinces`)
      setProvinces(response)
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error('An error occurred while fetching provinces')
      )
    } finally {
      setLoading(false)
    }
  }, [countryId])

  useEffect(() => {
    fetchProvinces()
  }, [fetchProvinces])

  return {
    provinces,
    loading,
    error,
    refetch: fetchProvinces,
  }
}
