/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { TOURNAMENT_STATUS } from '../../pages/Schedule/ScheduleBracket/constants'

import { font } from '../css'
import { FormButton } from '../Form/css'
import Icon from '../Icon'
import { Flex } from '../Layout'
import { media } from '../Responsive'
import { useUrlManager } from 'src/hooks/useUrlManager'

export const useRosterSelect = (
  teamId,
  existingRosterIdFromLocationState = ''
) => {
  const { currentQueryParams } = useUrlManager()

  const currentParams = useMemo(() => {
    return {
      sn_schedule: currentQueryParams.get('sn_schedule'),
      sn_division: currentQueryParams.get('sn_division'),
      sn_team: currentQueryParams.get('sn_team'),
      sn_team_name: currentQueryParams.get('sn_team_name'),
      sn_starts_at: currentQueryParams.get('sn_starts_at'),
      sn_ends_at: currentQueryParams.get('sn_ends_at'),
      sn_sort: currentQueryParams.get('sn_sort') || 'asc',
      sn_today: Boolean(currentQueryParams.get('sn_today')),
      sn_sort_by: currentQueryParams.get('sn_sort_by'),
      sn_schedule_presentation: currentQueryParams.get(
        'sn_schedule_presentation'
      ),
    }
  }, [currentQueryParams])

  const { sn_schedule } = currentParams
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [rosters, setRosters] = useState([])
  const [selectedRosterId, setSelectedRosterId] = useState('')
  const [preexistingRosterId, setPreexistingRosterId] = useState(
    sn_schedule || existingRosterIdFromLocationState
  )

  const loadRosters = useCallback(async () => {
    try {
      const data = await req(`/teams/${teamId}/rosters`)

      const mapped = data.map((d) => {
        const { uid, name } = d

        return {
          ...d,
          id: uid,
          name,
        }
      })
      setRosters(mapped)
      setTimeout(() => {
        if (sn_schedule) {
          const roster = mapped.find((r) => r.schedule_uid === sn_schedule)
          if (roster) {
            setSelectedRosterId(roster.id)
          }
        } else if (preexistingRosterId) {
          setSelectedRosterId(existingRosterIdFromLocationState)
          setPreexistingRosterId(null)
        } else {
          if (mapped.length > 0) {
            setSelectedRosterId(mapped[0].id)
          }
        }
      }, 0)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }, [teamId])

  useEffect(() => {
    loadRosters()
  }, [loadRosters])

  return {
    error,
    loading,
    rosters,
    selectedRosterId,
    setSelectedRosterId,
    loadRosters,
    currentParams,
  }
}

export const RosterOption = ({
  option,
  isActive,
  isSelected,
  onClick,
  getSubTitle,
  getTitle,
  placeholderText = 'Select an option',
  disabled = false,
}) => {
  const subTitleAlternativeStyleForGameSlotsDropDown =
    option?.tournament_status &&
    option?.tournament_status !== TOURNAMENT_STATUS.NOT_CONFIGURED
      ? css`
          text-decoration: underline;
          font-weight: 700;
        `
      : css``

  return (
    <div
      className='team-roster-select'
      onClick={!disabled ? onClick : null}
      css={css`
        opacity: ${disabled ? 0.5 : 1};
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        background-color: ${colors.HEADER};
        border-radius: 4px;
        ${isActive
          ? css`
              border-radius: 4px;
              border: 1px solid ${colors.SOFT_STEEL};
              padding: 0 12px;

              &:hover {
                border-color: white;
              }
            `
          : css`
              padding: 0 4px 0 ${isSelected ? 4 : 28}px;
              margin: 0 8px;
              &:hover {
                background-color: rgba(255, 255, 255, 0.05);
              }
            `}
      `}
    >
      <Flex
        alignItems='center'
        noFlex
        css={css`
          overflow: hidden;
          ${!isSelected &&
          css`
            padding-right: 8px;
          `}
        `}
      >
        {isSelected && (
          <Icon
            name='check'
            css={css`
              margin-right: 8px;
            `}
          />
        )}
        <div
          css={css`
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 18px;
          `}
        >
          <div
            css={css`
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            `}
          >
            {getTitle(option) || placeholderText}
          </div>
          {typeof getSubTitle === 'function' && option && (
            <div
              css={[
                css`
                  opacity: 0.6;
                  margin-top: 2px;
                  font-size: 12px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                `,
                subTitleAlternativeStyleForGameSlotsDropDown,
              ]}
            >
              {getSubTitle(option)}
            </div>
          )}
        </div>
      </Flex>

      {isActive && <Icon name='caret-down' color='white' />}
    </div>
  )
}

export const NewSelect = ({
  label,
  options,
  selectedOptionId,
  onClick,
  getTitle,
  getSubTitle,
  useFullWidth = false,
  disabled = false,
  fixedWidth = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handler = () => {
      if (isOpen) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      setTimeout(() => {
        document.addEventListener('click', handler)
      }, 0)
    } else {
      document.removeEventListener('click', handler)
    }

    return () => {
      document.removeEventListener('click', handler)
    }
  }, [isOpen])

  const selectedOption = options.find((r) => r.id === selectedOptionId)

  return (
    <div
      css={css`
        position: relative;
        width: ${useFullWidth
          ? '100%'
          : fixedWidth
          ? `${fixedWidth}px`
          : '280px'};
        z-index: 2;
      `}
    >
      <div
        css={css`
          height: 18px;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 8px;
          text-transform: capitalize;
        `}
      >
        {label}
      </div>

      <RosterOption
        option={selectedOption}
        isActive
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        getTitle={getTitle}
        getSubTitle={getSubTitle}
        disabled={disabled}
      />

      {isOpen && (
        <div
          className='team-roster-select-dropdown'
          css={css`
            top: 63px;
            position: absolute;
            z-index: 1;
            background-color: ${colors.HEADER};
            border: 1px solid ${colors.SOFT_STEEL};
            border-radius: 4px;
            padding: 8px 0;
            width: ${useFullWidth
              ? '100%'
              : fixedWidth
              ? `${fixedWidth}px`
              : '280px'};
          `}
        >
          {options.map((option) => {
            return (
              <RosterOption
                key={option.id}
                option={option}
                isSelected={option.id === selectedOptionId}
                onClick={() => {
                  onClick(option)
                  setIsOpen(false)
                }}
                getTitle={getTitle}
                getSubTitle={getSubTitle}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

const RosterSelect = ({
  teamName,
  teamId,
  permission,
  selectedRosterId,
  setSelectedRosterId,
  rosters,
  loadRosters,
  inFrame,
  setParam = () => {},
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const getSubTitle = (roster) => {
    let subTitle = 'Unlinked Roster'

    if (roster.starts_at) {
      const starts = dayjs(roster.starts_at, 'YYYY-MM-DD hh:mm:ss')
      const ends = dayjs(roster.ends_at, 'YYYY-MM-DD hh:mm:ss')
      subTitle = `${starts.format('MMM D, YYYY')} - ${ends.format(
        'MMM D, YYYY'
      )}`
    }
    return subTitle
  }

  return (
    <div>
      {rosters.length === 0 ? (
        !inFrame && (permission?.update || permission?.admin) ? (
          <Flex
            column
            alignItems='center'
            css={css`
              padding-bottom: 16px;
              margin-bottom: -24px;
              border-bottom: 1px solid #3f4753;
            `}
          >
            <h2
              css={css`
                ${font.title}
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 16px;
              `}
            >
              No team rosters exist!
            </h2>
            <FormButton
              busy={isSubmitting}
              disabled={isSubmitting}
              isSubmit
              css={css`
                font-size: 14px;
                width: 186px;
                height: 40px;
              `}
              onClick={async () => {
                const body = JSON.stringify({
                  name: `${teamName} ${dayjs().format('YYYY')}`,
                })
                try {
                  setIsSubmitting(true)
                  await req(`/teams/${teamId}/rosters`, {
                    method: 'POST',
                    body,
                  })
                  await loadRosters()
                } finally {
                  setIsSubmitting(false)
                }
              }}
            >
              Create a new team roster
            </FormButton>
          </Flex>
        ) : (
          false
        )
      ) : (
        <div
          css={css`
            margin-bottom: 8px;

            ${media.mobile} {
              margin-left: 16px;
            }
          `}
        >
          <NewSelect
            label='Team Rosters'
            options={rosters}
            onClick={(roster) => {
              setParam('sn_schedule', roster.schedule_uid)
              setSelectedRosterId(roster.id)
            }}
            selectedOptionId={selectedRosterId}
            getSubTitle={getSubTitle}
            getTitle={(r) => r?.schedule_name || r?.name}
            fixedWidth={400}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    inFrame: state.auth.inFrame,
  }
}

export default connect(mapStateToProps)(RosterSelect)
