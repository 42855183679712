/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import {
  ConditionType,
  Currency,
  CurrencyType,
  PlayerType,
  RegisteredPlayer,
  Registration,
  Team,
  TeamOfficialType,
} from '@sportninja/common/types/Registration'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { Alert, App } from 'antd'
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  Suspense,
  useEffect,
} from 'react'
import { useSearchAndFilter } from 'src/hooks/useSearchAndFilter'
import { SideModal } from 'src/pages/Schedule/Registration/Division/SideModal'
import DivisionTable from './DivisionTable'
import { EmptySearch } from './EmptySearch'
import { useTableColumns } from './helpers/divisionTableHelper'
import { SearchControls } from './SearchControls'
import colors from '@sportninja/common/constants/appColors'
import { WarningFilled } from '@ant-design/icons'
import { RegistrationTableDivision } from 'src/pages/Schedule/PlayerRegistration/helpers'
import Text from '../Text'
import { ROUTES } from '@sportninja/common/constants/app'
import { Country } from '@sportninja/common/types/Country'
import DeleteModal from 'src/components/Modal/DeleteModal'
import req from '@sportninja/common/api/request'

const AdminPlayerRegisterModal = React.lazy(
  () =>
    import(
      'src/pages/Schedule/PlayerRegistration/components/AdminPlayerRegisterModal'
    )
)
const AdminTeamRegisterModal = React.lazy(
  () =>
    import(
      'src/pages/Schedule/PlayerRegistration/components/AdminTeamRegisterModal'
    )
)
const BucketModal = React.lazy(() => import('./DivisionTable/BucketModal'))

const ManagePlayersModal = React.lazy(
  () => import('./DivisionTable/PlayerDetailsTable/ManagePlayersModal')
)

const BillingScheduleModal = React.lazy(
  () => import('src/pages/Schedule/PlayerRegistration/BillingScheduleModal')
)

const registrationOptions = [
  {
    label: 'Submitted',
    value: 1,
  },
  {
    label: 'Pending',
    value: 2,
  },
  {
    label: 'Approved',
    value: 3,
  },
  {
    label: 'Approved w/ Conditions',
    value: 4,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const DeleteButton = ({ ...props }) => <div id='delete-button' {...props} />

const DeleteBucketButton = ({ ...props }) => (
  <div id='delete-bucket-button' {...props} />
)

const PlayerDeleteButton = ({ ...props }) => (
  <div id='player-delete-button' {...props} />
)

type RegistrationTableProps = {
  playerTypes: PlayerType[]
  divisions: RegistrationTableDivision[]
  setDivisions: React.Dispatch<
    React.SetStateAction<RegistrationTableDivision[]>
  >
  onPressDivisionSetup: (division: RegistrationTableDivision) => void
  scheduleId: string
  updateTeamRegistrationStatus: (props: any) => void
  conditionType: ConditionType[]
  refetch: (force?: boolean) => void
  currencies: CurrencyType[]
  registration: Registration | null | undefined
  teamOfficialTypes: TeamOfficialType[]
  setDivisionToDelete: (division: RegistrationTableDivision) => void
  setIsDivisionCloneOpen: (isOpen: boolean) => void
  setDivisionToClone: (division: RegistrationTableDivision) => void
  setIsRegistrationDetailsModalOpen: (isOpen: boolean) => void
  setRegistrationDetailId: (id: string) => void
  updatePlayerRegistrationStatus: (props: any) => void
  countries: Country[]
  fetchAnalytics: () => void
}

const getUpdateMessage = (entity: string, status: string) => {
  switch (status) {
    case 'Submitted':
      return `${entity} has been Submitted.`
    case 'Pending':
      return `${entity} is now Pending.`
    case 'Approved':
      return `${entity} has been Approved. They can now participate in the competition.`
    case 'Approved With Conditions':
      return `${entity} has been Approved With Conditions. They can now participate in the competition.`
    case 'Cancelled':
      return `${entity} has been Cancelled. They have been removed from the the competition.`
    default:
      return `${entity} has been ${status}.`
  }
}

const RegistrationTable = ({
  divisions,
  setDivisions,
  onPressDivisionSetup,
  scheduleId,
  updateTeamRegistrationStatus,
  updatePlayerRegistrationStatus,
  conditionType,
  currencies,
  refetch,
  registration,
  teamOfficialTypes,
  setDivisionToDelete,
  setIsDivisionCloneOpen,
  setDivisionToClone,
  setIsRegistrationDetailsModalOpen,
  setRegistrationDetailId,
  countries,
  fetchAnalytics,
  playerTypes,
}: RegistrationTableProps) => {
  const { message } = App.useApp()
  const sideModalRef = useRef(null)
  const [adminTeamRegisterModalOpen, setAdminTeamRegisterModalOpen] =
    useState(false)
  const [loadingTeamIds, setLoadingTeamIds] = useState<string[]>([])
  const [loadingPlayerIds, setLoadingPlayerIds] = useState<string[]>([])
  const [teamToCancel, setTeamToCancel] = useState<any>(null)
  const [playerToCancel, setPlayerToCancel] = useState<any>(null)
  const [currentDivision, setCurrentDivision] = useState<
    RegistrationTableDivision | null | undefined
  >(null)
  const [isBillingScheduleModalOpen, setIsBillingScheduleModalOpen] =
    useState(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)
  const [selectedPlayer, setSelectedPlayer] = useState<RegisteredPlayer | null>(
    null
  )
  const [selectedPlayerTeamId, setSelectedPlayerTeamId] = useState<
    string | null
  >(null)
  const [isBucketModalOpen, setIsBucketModalOpen] = useState(false)
  const [isPlayerAdminModalOpen, setIsPlayerAdminModalOpen] = useState(false)
  const [parentTeamRegistrationId, setParentTeamRegistrationId] = useState<
    string | null
  >(null)
  const [teamId, setTeamId] = useState<string | null>(null)
  const [teamName, setTeamName] = useState<string | null>(null)
  const [divisionId, setDivisionId] = useState<string | null>(null)
  const [division, setDivision] = useState<RegistrationTableDivision | null>(
    null
  )
  const [scheduleRegistrationId, setScheduleRegistrationId] = useState<
    string | null
  >(null)
  const [bucketScheduleRegistrationId, setBucketScheduleRegistrationId] =
    useState<string | null>(null)
  const [bucketToEdit, setBucketToEdit] = useState<any>(null)
  const [bucketToDelete, setBucketToDelete] = useState<any>(null)
  const [isManagePlayersModalOpen, setIsManagePlayersModalOpen] =
    useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedPlayers, setSelectedPlayers] = useState<RegisteredPlayer[]>([])
  const handleClearSelection = useCallback(() => {
    setSelectedRowKeys([])
  }, [])
  const [expandedRows, setExpandedRows] = useState<string[]>([])

  // Use the new search and filter hook
  const {
    searchText,
    setSearchText,
    selectedDivision,
    setSelectedDivision,
    getFilteredTeams,
    getFilteredDivisions,
    openDivisions,
    setOpenDivisions,
    allExpanded,
    setAllExpanded,
    getFilteredPlayers,
    onlyBuckets,
    setOnlyBuckets,
    selectedStatuses,
    setSelectedStatuses,
  } = useSearchAndFilter({ divisions, setExpandedRows })

  // Toggles expansion state of all divisions at once
  // Optimized by using reduce instead of forEach to build the new state object
  // and avoiding the intermediate newState variable
  const toggleAllDivisions = useCallback(() => {
    // Set all divisions open/closed state at once
    setOpenDivisions(
      divisions.reduce((acc, _, index) => {
        acc[index] = !allExpanded
        return acc
      }, {})
    )

    if (!allExpanded) {
      // Get all team roster IDs in one pass through divisions
      const teamsRosterIds = divisions.reduce((ids, division) => {
        if (division.teams) {
          division.teams.forEach((team) => {
            if (team.team_roster_id) {
              ids.push(team.team_roster_id)
            }
          })
        }
        return ids
      }, [] as string[])
      setExpandedRows(teamsRosterIds)
    } else {
      setExpandedRows([])
    }

    setAllExpanded(!allExpanded)
  }, [allExpanded, divisions])

  // Update openDivisions when search text changes
  React.useEffect(() => {
    if (searchText) {
      setOpenDivisions(
        divisions.reduce((acc, _, index) => {
          acc[index] = true
          return acc
        }, {})
      )
      setAllExpanded(true)
    }
  }, [searchText, divisions])

  const toggleDivision = (index: number) => {
    setOpenDivisions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  // Check for registration_id in URL
  useEffect(() => {
    if (divisions.length === 0) {
      return
    }
    const urlParams = new URLSearchParams(window.location.search)
    const registrationIdFromUrl = urlParams.get('registration_id')

    if (!registrationIdFromUrl) {
      return
    }

    // We need to find the division that has the registration_id, getting the index of the division
    const divisionIndexFromUrl = divisions.findIndex((d) =>
      d.teams?.some((t) =>
        t.registered_players?.some((p) => p.id === registrationIdFromUrl)
      )
    )

    if (divisionIndexFromUrl === -1) {
      return
    }

    // We need to get the team_roster_id for the registration_id
    const teamRosterIdFromUrl = divisions[divisionIndexFromUrl].teams?.find(
      (t) => t.registered_players?.some((p) => p.id === registrationIdFromUrl)
    )?.team_roster_id

    // Expand the row for the team_roster_id
    if (teamRosterIdFromUrl) {
      setExpandedRows((prev) => [...prev, teamRosterIdFromUrl])
    }

    // Now, we need to expand the division using toggleDivision
    toggleDivision(divisionIndexFromUrl)

    if (registrationIdFromUrl) {
      setRegistrationDetailId(registrationIdFromUrl)
      setIsRegistrationDetailsModalOpen(true)
      // Remove the registration_id from URL without refreshing the page
      const newUrl = window.location.pathname
      window.history.replaceState({}, '', newUrl)
    }
  }, [divisions])

  // Check for team_registration_id in URL
  useEffect(() => {
    if (divisions.length === 0) {
      return
    }
    const urlParams = new URLSearchParams(window.location.search)
    const teamRegistrationIdFromUrl = urlParams.get('team_registration_id')

    if (!teamRegistrationIdFromUrl) {
      return
    }

    // We need to find the division that has the registration_id, getting the index of the division
    const divisionIndexFromUrl = divisions.findIndex((d) =>
      d.teams?.some((t) => t.id === teamRegistrationIdFromUrl)
    )

    if (divisionIndexFromUrl === -1) {
      return
    }

    // We need to get the team_roster_id for the registration_id
    const teamRosterIdFromUrl = divisions[divisionIndexFromUrl].teams?.find(
      (t) => t.id === teamRegistrationIdFromUrl
    )?.team_roster_id

    // Expand the row for the team_roster_id
    if (teamRosterIdFromUrl) {
      setExpandedRows((prev) => [...prev, teamRosterIdFromUrl])
    }

    // Now, we need to expand the division using toggleDivision
    toggleDivision(divisionIndexFromUrl)

    if (teamRegistrationIdFromUrl) {
      setRegistrationDetailId(teamRegistrationIdFromUrl)
      setIsRegistrationDetailsModalOpen(true)
      // Remove the team_registration_id from URL without refreshing the page
      const newUrl = window.location.pathname
      window.history.replaceState({}, '', newUrl)
    }
  }, [divisions])

  const onApprovedWithConditions = useCallback(
    async ({
      scheduleId: scheduleIdValue,
      scheduleRegistrationId: scheduleRegistrationIdValue,
      status: statusValue,
      teamRosterId: teamRosterIdValue,
      teamId: teamIdValue,
      conditions: conditionsValue,
      conditionType: conditionTypeValue,
      divisionId: divisionIdValue,
      team_registration_id: teamRegistrationIdValue,
      team: teamValue,
    }) => {
      try {
        await updateTeamRegistrationStatus({
          scheduleId: scheduleIdValue,
          scheduleRegistrationId: scheduleRegistrationIdValue,
          status: statusValue,
          teamRosterId: teamRosterIdValue,
          teamId: teamIdValue,
          conditions: conditionsValue,
          conditionType: conditionTypeValue,
          divisionId: divisionIdValue,
          team_registration_id: teamRegistrationIdValue,
        })
        // Update local state
        setDivisions((prevDivisions) =>
          prevDivisions.map((div) => {
            if (div.id === teamValue?.division_id) {
              console.log(`found division ${div.id}`)
              return {
                ...div,
                teams: div.teams?.map((t) => {
                  if (t.id === teamValue?.id) {
                    console.log(`found team ${t.id}`)
                    return {
                      ...t,
                      registration_status: Number(statusValue),
                    }
                  }
                  return t
                }),
              }
            }
            return div
          })
        )
        fetchAnalytics()
        const statusText = registrationOptions?.find(
          (option) => option.value === statusValue
        )?.label
        message.success(
          getUpdateMessage(teamValue?.name_full, statusText || '')
        )
      } catch (e: any) {
        message.error(getErrorMessage(e))
      }
    },
    [fetchAnalytics, updateTeamRegistrationStatus]
  )

  const onRegStatusChange = useCallback(
    async ({ value, team, division }) => {
      try {
        await updateTeamRegistrationStatus({
          scheduleId: scheduleId,
          status: value,
          conditions: null,
          conditionType: null,
          teamRegistrationId: team?.id,
          divisionId: division?.id,
        })
        // Update local state
        setDivisions((prevDivisions) =>
          prevDivisions.map((div) => {
            if (div.id === team?.division_id) {
              return {
                ...div,
                teams: div.teams?.map((t) => {
                  if (t.id === team?.id) {
                    console.log(`Achou o team ${t.id}`)
                    return {
                      ...t,
                      registration_status: value,
                    }
                  }
                  return t
                }),
              }
            }
            return div
          })
        )
        fetchAnalytics()
        const teamName = team?.name
        const status = registrationOptions?.find(
          (option) => option.value === value
        )?.label
        message.success(getUpdateMessage(teamName, status || ''))
      } catch (e: any) {
        message.error(getErrorMessage(e))
      }
    },
    [message, scheduleId, updateTeamRegistrationStatus, fetchAnalytics]
  )

  const onPlayerRegStatusChange = useCallback(
    async ({ value, player, division }) => {
      try {
        await updatePlayerRegistrationStatus({
          scheduleId: scheduleId,
          status: value,
          conditions: null,
          conditionType: null,
          divisionId: division?.id || null,
          teamRegistrationId: player?.id,
        })

        // Update local state
        setDivisions((prevDivisions) =>
          prevDivisions.map((div) => {
            if (div.id === division?.id) {
              return {
                ...div,
                teams: div.teams?.map((team) => ({
                  ...team,
                  registered_players: team.registered_players?.map((p) => {
                    if (
                      p.id === player?.id &&
                      p.team_roster_id === player.team_roster_id
                    ) {
                      return {
                        ...p,
                        registration_status: value,
                      }
                    }
                    return p
                  }),
                })),
              }
            }
            return div
          })
        )
        fetchAnalytics()
        const playerName = player?.name || player?.name_full
        const status = registrationOptions.find(
          (option) => option.value === value
        )?.label
        message.success(getUpdateMessage(playerName, status || ''))
      } catch (e: any) {
        message.error(getErrorMessage(e))
      }
    },
    [message, scheduleId, updatePlayerRegistrationStatus, fetchAnalytics]
  )

  const handleStatusChange = useCallback(
    async (value: number, teamId: string, team: any) => {
      try {
        const division = divisions.find((d) => d.id === team?.division_id)
        setCurrentDivision(division)
        setLoadingTeamIds((prev) => [...prev, teamId])
        // 5 is cancelled
        if (value === 5) {
          setTeamToCancel(team)
          // click the div with id delete-button
          const deleteButton = document.getElementById('delete-button')
          if (deleteButton) {
            deleteButton.click()
          }
          // 4 is approved with conditions
        } else if (Number(value) === 4) {
          // @ts-ignore
          sideModalRef.current?.handleOpen(
            team,
            division?.id,
            scheduleId,
            division?.registration?.id
          )
        } else {
          onRegStatusChange({ value, team, division })
        }
      } catch (error: any) {
        message.error(error?.message)
      } finally {
        setLoadingTeamIds((prev) => prev.filter((id) => id !== teamId))
      }
    },
    [divisions, message, onRegStatusChange, scheduleId]
  )

  const handlePlayerStatusChange = useCallback(
    async (
      value: number,
      playerId: string,
      player: any,
      divisionId: string
    ) => {
      try {
        const division = divisions.find((d) => d.id === divisionId)
        setCurrentDivision(division)
        setLoadingPlayerIds((prev) => [...prev, playerId])
        // 5 is cancelled
        if (value === 5) {
          setPlayerToCancel(player)
          // click the div with id player-delete-button
          const deleteButton = document.getElementById('player-delete-button')
          if (deleteButton) {
            deleteButton.click()
          }
          // 4 is approved with conditions
        } else if (Number(value) === 4) {
          // @ts-ignore
          sideModalRef.current?.handleOpen(
            player,
            division?.id,
            scheduleId,
            division?.registration?.id
          )
        } else {
          onPlayerRegStatusChange({
            value,
            player: player?.player || player,
            division,
          })
        }
      } catch (error: any) {
        message.error(error?.message)
      } finally {
        setLoadingPlayerIds((prev) => prev.filter((id) => id !== playerId))
      }
    },
    [divisions, message, onPlayerRegStatusChange, scheduleId]
  )

  const columns = useTableColumns({
    divisions,
    scheduleId,
    handleStatusChange,
    loadingTeamIds,
    setIsRegistrationDetailsModalOpen,
    setRegistrationDetailId,
    setBucketToEdit,
    setBucketToDelete,
    setIsBucketModalOpen,
    setBucketScheduleRegistrationId,
    setIsPlayerAdminModalOpen,
    setParentTeamRegistrationId,
    setTeamId,
    setTeamName,
    setDivisionId,
    setDivision,
    setScheduleRegistrationId,
  })

  const getColumnsBasedOnPaymentType = useCallback(
    (d: RegistrationTableDivision) => {
      if (d?.singlePayment) {
        return columns.filter((column) => column.key !== 'deposit_paid')
      }
      return columns
    },
    [columns]
  )

  const filteredDivisions = getFilteredDivisions()
  const divisionsNotSet = useMemo(
    () => divisions.filter((d) => d?.registration === null),
    [divisions]
  )
  const hasOnlySeasonDivisions = useMemo(
    () => divisions.every((d) => d?.isSeason),
    [divisions]
  )

  const cancelRegistration = useCallback(async () => {
    try {
      onRegStatusChange({
        value: 5,
        team: teamToCancel,
        division: null,
      })
    } catch (e: any) {
      message.error(e?.message)
    }
  }, [onRegStatusChange, teamToCancel, message])

  const cancelPlayerRegistration = useCallback(async () => {
    try {
      onPlayerRegStatusChange({
        value: 5,
        player: playerToCancel?.player,
        division: currentDivision,
      })
    } catch (e: any) {
      message.error(e?.message)
    }
  }, [onPlayerRegStatusChange, playerToCancel, currentDivision, message])

  return (
    <>
      <div
        css={css`
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          @media (max-width: 768px) {
            padding: 0px 16px;
          }
        `}
      >
        <SearchControls
          searchText={searchText}
          setSearchText={setSearchText}
          selectedDivision={selectedDivision}
          setSelectedDivision={setSelectedDivision}
          divisions={divisions}
          allExpanded={allExpanded}
          toggleAllDivisions={toggleAllDivisions}
          onlyBuckets={onlyBuckets}
          setOnlyBuckets={setOnlyBuckets}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
        />
        {hasOnlySeasonDivisions && (
          <div
            css={css`
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <Alert
              message={
                <div>
                  <Text
                    variant='paragraphXSmall'
                    weight='regular'
                    color={colors.PRIMARY}
                  >
                    You must have at least one division configured for
                    registration.{' '}
                    <a
                      href={`${ROUTES.SCHEDULE_ROOT}/${scheduleId}/setup`}
                      css={css`
                        color: ${colors.PRIMARY};
                        text-decoration: underline;
                      `}
                    >
                      Click here go to setup
                    </a>{' '}
                    and create a division.
                  </Text>
                </div>
              }
              type='warning'
              showIcon
              css={css`
                width: 100%;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: ${colors.PRIMARY};
                border: 1px solid ${'#5B4810'};
                padding: 8px 16px;
                border-radius: 8px;
              `}
              icon={
                <WarningFilled
                  css={css`
                    color: ${colors.WARNING_300};
                    font-size: 14px;
                  `}
                />
              }
            />
          </div>
        )}
        {divisionsNotSet?.length > 0 && (
          <div
            css={css`
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <Alert
              message={`There ${divisionsNotSet?.length > 1 ? 'are' : 'is'} ${
                divisionsNotSet?.length
              } ${
                divisionsNotSet?.length > 1 ? 'divisions' : 'division'
              } that have not been configured for registration. To configure ${
                divisionsNotSet?.length > 1 ? 'them' : 'it'
              }, click the setup gear icon.`}
              type='warning'
              showIcon
              css={css`
                width: 100%;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: ${colors.PRIMARY};
                border: 1px solid ${'#5B4810'};
                padding: 8px 16px;
                border-radius: 8px;
              `}
              icon={
                <WarningFilled
                  css={css`
                    color: ${colors.WARNING_300};
                    font-size: 14px;
                  `}
                />
              }
            />
          </div>
        )}
        {filteredDivisions.length > 0 ? (
          filteredDivisions?.map((division, index) => (
            <DivisionTable
              key={index}
              division={division}
              index={index}
              openDivisions={openDivisions}
              toggleDivision={toggleDivision}
              onPressDivisionSetup={onPressDivisionSetup}
              setAdminTeamRegisterModalOpen={setAdminTeamRegisterModalOpen}
              setCurrentDivision={setCurrentDivision}
              columns={getColumnsBasedOnPaymentType(division)}
              getFilteredTeams={getFilteredTeams}
              getFilteredPlayers={getFilteredPlayers}
              teamToCancel={teamToCancel}
              onRegStatusChange={onRegStatusChange}
              message={message}
              DeleteButton={DeleteButton}
              DeleteBucketButton={DeleteBucketButton}
              currency={division?.currency}
              scheduleId={scheduleId}
              setDivisionToDelete={setDivisionToDelete}
              setIsDivisionCloneOpen={setIsDivisionCloneOpen}
              setDivisionToClone={setDivisionToClone}
              setIsRegistrationDetailsModalOpen={
                setIsRegistrationDetailsModalOpen
              }
              setRegistrationDetailId={setRegistrationDetailId}
              handlePlayerStatusChange={handlePlayerStatusChange}
              loadingPlayerIds={loadingPlayerIds}
              playerToCancel={playerToCancel}
              onPlayerRegStatusChange={onPlayerRegStatusChange}
              PlayerDeleteButton={PlayerDeleteButton}
              refetch={refetch}
              setIsBucketModalOpen={setIsBucketModalOpen}
              setBucketScheduleRegistrationId={setBucketScheduleRegistrationId}
              allDivisions={divisions}
              setScheduleRegistrationId={setScheduleRegistrationId}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              selectedPlayers={selectedPlayers}
              setSelectedPlayers={setSelectedPlayers}
              setIsManagePlayersModalOpen={setIsManagePlayersModalOpen}
              expandedRows={expandedRows}
              setExpandedRows={setExpandedRows}
            />
          ))
        ) : (
          <EmptySearch />
        )}
        <SideModal
          // @ts-ignore
          // onConfirm={updateTeamRegistrationStatus}
          onConfirm={onApprovedWithConditions}
          divisions={divisions}
          ref={sideModalRef}
          conditionType={conditionType}
          refetch={refetch}
        />
        <Suspense fallback={null}>
          <ManagePlayersModal
            isOpen={isManagePlayersModalOpen}
            onClose={() => setIsManagePlayersModalOpen(false)}
            selectedPlayers={selectedPlayers}
            onClearSelection={handleClearSelection}
            scheduleId={scheduleId}
            onSuccess={() => {
              setIsManagePlayersModalOpen(false)
              refetch()
            }}
            divisions={divisions}
          />
        </Suspense>
        <Suspense fallback={null}>
          <AdminPlayerRegisterModal
            isOpen={isPlayerAdminModalOpen}
            onClose={() => {
              setIsPlayerAdminModalOpen(false)
              setParentTeamRegistrationId(null)
              setTeamId(null)
              setDivisionId(null)
              setDivision(null)
            }}
            scheduleId={divisionId || ''}
            parentTeamRegistrationId={parentTeamRegistrationId || ''}
            teamId={teamId || ''}
            playerTypes={playerTypes || []}
            scheduleRegistrationId={scheduleRegistrationId || ''}
            countries={countries}
            refetch={refetch}
            organizationId={registration?.organization_id || ''}
            teamName={teamName || ''}
            division={division || ({} as RegistrationTableDivision)}
            currency={registration?.currency || ({} as Currency)}
          />
        </Suspense>
        <Suspense fallback={null}>
          <AdminTeamRegisterModal
            isOpen={adminTeamRegisterModalOpen}
            onClose={() => {
              setAdminTeamRegisterModalOpen(false)
              setParentTeamRegistrationId(null)
              setTeamId(null)
              setDivisionId(null)
            }}
            countries={countries}
            organizationId={registration?.organization_id || ''}
            scheduleId={scheduleId}
            scheduleRegistrationId={scheduleRegistrationId || ''}
            refetch={refetch}
            teamName={teamName || ''}
            teamOfficialTypes={teamOfficialTypes}
            sportId={registration?.sport_id || ''}
            division={currentDivision || ({} as RegistrationTableDivision)}
            currency={registration?.currency || ({} as Currency)}
          />
        </Suspense>
        <Suspense fallback={null}>
          <BillingScheduleModal
            isOpen={isBillingScheduleModalOpen}
            onClose={() => {
              setIsBillingScheduleModalOpen(false)
              setSelectedTeam(null)
              setSelectedPlayer(null)
              setSelectedPlayerTeamId(null)
            }}
            selectedBillingSchedule={
              selectedTeam || selectedPlayer || ({} as Team)
            }
            currencies={currencies}
            selectedPlayerTeamId={selectedPlayerTeamId}
          />
        </Suspense>
        <Suspense fallback={null}>
          <BucketModal
            isOpen={isBucketModalOpen}
            onClose={() => {
              setIsBucketModalOpen(false)
              setBucketToEdit(null)
              setBucketScheduleRegistrationId(null)
            }}
            scheduleId={scheduleId}
            registrationId={bucketScheduleRegistrationId || ''}
            refetch={refetch}
            bucketToEdit={bucketToEdit}
          />
        </Suspense>
        <DeleteModal
          title='Cancel this registration?'
          message={`Are you sure you want to cancel ${
            teamToCancel?.name || teamToCancel?.name_full
          } registration? \n This cannot be undone.`}
          onDelete={cancelRegistration}
          Button={DeleteButton}
          useForceDelete={true}
          displayBanner={false}
          forceDeleteText=''
          shouldCloseModalAfterDelete={true}
        />
        <DeleteModal
          title='Cancel this Player Registration?'
          message={`Are you sure you want to cancel ${
            playerToCancel?.player?.name || playerToCancel?.player?.name_full
          } registration? \n This cannot be undone.`}
          onDelete={cancelPlayerRegistration}
          Button={PlayerDeleteButton}
          useForceDelete={true}
          displayBanner={false}
          forceDeleteText=''
          shouldCloseModalAfterDelete={true}
        />

        <DeleteModal
          title={`Delete ${bucketToDelete?.name}?`}
          message={`Are you sure you want to delete ${bucketToDelete?.name}? \n This cannot be undone.`}
          onDelete={async (shouldForce = false) => {
            const query: any = {}
            if (shouldForce) {
              query.force = 1
            }
            try {
              await req(
                `/registration/${scheduleId}/admin/bucket/${bucketToDelete?.id}`,
                {
                  method: 'DELETE',
                  query,
                }
              )
              message.success(`${bucketToDelete?.name} deleted`)
              refetch(false)
            } catch (e: any) {
              message.error(e?.message)
              return false
            } finally {
              setBucketToDelete(null)
            }
          }}
          Button={DeleteBucketButton}
          useForceDelete={true}
          displayBanner={false}
          forceDeleteText=''
          shouldCloseModalAfterDelete={true}
        />
      </div>
    </>
  )
}

export default RegistrationTable
