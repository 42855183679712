/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { Link } from 'react-router-dom'

import { font } from '../../css'
import { Flex } from '../../Layout'
import Picture from '../../Picture'

const GameListRowTeam = ({
  team = {},
  isHome,
  teamSlot = {},
  scheduleId = null,
}) => {
  const to = team?.id
    ? `${ROUTES.TEAM_ROOT}/${team.id}${
        scheduleId ? `?sn_schedule=${scheduleId}` : ''
      }`
    : undefined
  const disableLink = !to || window.location.pathname === to

  return (
    <Flex
      noFlex
      as={disableLink ? undefined : Link}
      to={disableLink ? undefined : to}
      alignItems='center'
      flexDirection={isHome ? 'row' : 'row-reverse'}
      css={css`
        width: 25%;
        padding: 0 8px;
        ${!disableLink &&
        `
          transition: background-color 0.2s ease-in-out;
          &:hover {
            background-color: rgba(255, 255, 255, 0.05);
            border-radius: 8px;
          }
        `}
      `}
    >
      <Picture
        square
        fit
        imageId={getImageThumbnailId(team)}
        iconName='user-friends'
        size='sm-md'
      />
      <div
        css={css`
          ${!isHome && 'text-align: right;'}
          ${isHome ? 'margin-left: 8px;' : 'margin-right: 8px;'}
        `}
      >
        <p
          css={css`
            ${font.title}
            font-weight: 700;
            font-size: 24px;
            line-height: 100%;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            margin-bottom: 8px;
          `}
        >
          {team
            ? team?.name_full
            : teamSlot
            ? teamSlot?.name_full
            : isHome
            ? 'Home Team'
            : 'Visiting Team'}
        </p>
        <p
          css={css`
            font-size: 12px;
            font-weight: 500;
            color: ${colors.LINK_WATER};
          `}
        >
          {isHome ? 'HOME' : 'VISITOR'}
        </p>
      </div>
    </Flex>
  )
}

export default GameListRowTeam
