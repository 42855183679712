/** @jsxImportSource @emotion/react */
import { connect } from 'react-redux'
import css from '@emotion/css/macro'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { t } from '@sportninja/common/i18n'
import { getGameStatusTypesBySportId } from '@sportninja/common/selectors/types'
import Periods from '@sportninja/common/constants/periodTypes'

import { Flex } from '../../components/Layout'
import Picture from '../../components/Picture'
import {
  font,
  flairColor,
  paragraphMedium,
  paragraphSmall,
  weightMedium,
} from '../../components/css'
import LoadingSpinner from '../../components/LoadingSpinner'
import { getGameStatusLabel } from '../Games/DesktopGameListItem'

import {
  Final,
  IndicatorLine,
  imageCss,
  MobileHomeVisitorText,
  mobileImageCss,
  Score,
  SidewayText,
  TeamName,
  Time,
  VS,
  Wrap,
} from './GamePageHeaderMatchupCss'
import colors from '@sportninja/common/constants/appColors'

const ScoreSection = ({
  isDesktop,
  game,
  gameStatusTypes,
  isSoccer = false,
}) => {
  let finalString = t('common:final')
  if (finalString.length > 5) {
    finalString = `${finalString.slice(0, 5)}.`
  }
  const isGenericSport =
    game?.generic_sport_id && game?.generic_sport_id != null

  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      css={css`
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        margin-top: 8px;
        margin-bottom: 8px;
        ${!isDesktop &&
        css`
          flex: 1 1 100%;
          padding-top: 16px;
        `}
      `}
    >
      <Score
        justifyContent='flex-end'
        className={cn({
          winner:
            game.completed &&
            (game.homeTeamScore > game.visitingTeamScore ||
              game.gameStatus === 3),
        })}
        isDesktop={isDesktop}
      >
        <span
          css={css`
            position: relative;
          `}
        >
          {game.homeTeamScore}
          <IndicatorLine isDesktop={isDesktop} className='indicator-line' />
        </span>
      </Score>
      {game.completed ? (
        <Final isDesktop={isDesktop}>
          <div
            className='final-string'
            css={css`
              color: ${colors.PRIMARY};
            `}
          >
            {finalString}
          </div>
          {game.shootout ? (
            <div
              css={css`
                margin-top: 2px;
              `}
            >
              SO
            </div>
          ) : (
            game.isOvertime && (
              <div
                css={css`
                  margin-top: 2px;
                `}
              >
                {isSoccer ? 'Extra Time' : t('common:ot')}
              </div>
            )
          )}
          {game.gameStatus > 1 && (
            <div
              css={css`
                font-size: ${isDesktop ? 16 : 12}px;
                line-height: 1;
                margin-top: 4px;
              `}
            >
              {getGameStatusLabel(game.gameStatus, gameStatusTypes)}
            </div>
          )}
        </Final>
      ) : game.started ? (
        <Time isDesktop={isDesktop}>
          <div
            css={css`
              margin-bottom: 4px;
              ${isGenericSport &&
              css`
                display: none;
              `}
            `}
          >
            {game.periodInProgress
              ? game.currentPeriodTime
              : game.currentPeriodName}
          </div>
          {!isGenericSport ? (
            <div
              css={css`
                text-transform: uppercase;
                margin-bottom: ${isDesktop && '-4px'};
              `}
            >
              {game.periodInProgress ? (
                Periods[game?.current_period?.period_type?.id]
              ) : (
                <span
                  css={css`
                    font-size: 17px;
                    text-transform: capitalize;
                  `}
                >
                  {isSoccer ? 'Half' : t('GameScreen:intermission')}
                </span>
              )}
            </div>
          ) : null}
          {isGenericSport && game.periodInProgress ? (
            <div
              css={css`
                text-transform: uppercase;
                margin-bottom: ${isDesktop && '-4px'};
              `}
            >
              <span
                css={css`
                  font-size: 16px;
                  text-transform: capitalize;
                `}
              >
                In Progress
              </span>
            </div>
          ) : null}
        </Time>
      ) : (
        <VS isDesktop={isDesktop}>VS</VS>
      )}
      <Score
        justifyContent='flex-start'
        className={cn({
          winner:
            game.completed &&
            (game.visitingTeamScore > game.homeTeamScore ||
              game.gameStatus === 2),
        })}
        isDesktop={isDesktop}
      >
        <span
          css={css`
            position: relative;
          `}
        >
          {game.visitingTeamScore}
          <IndicatorLine isDesktop={isDesktop} className='indicator-line' />
        </span>
      </Score>
    </Flex>
  )
}

const GamePageHeaderMatchup = ({
  game = {},
  isDesktop,
  gameStatusTypes,
  isSoccer = false,
}) => {
  if (!game || !game.homeTeam || !game.visitingTeam) {
    return (
      <LoadingSpinner
        css={css`
          margin-top: 20px;
        `}
      />
    )
  }
  const wrapClasses = cn('game-page-header-matchup', {
    completed: game.completed,
    'in-progress': game.started && !game.completed,
  })
  const picProps = { iconName: 'user-friends', square: true, fit: true }

  const scheduleQuery =
    typeof game?.parentScheduleType === 'string'
      ? `?${game.parentScheduleType}=${game.parentId}`
      : ''

  const { homeTeam = {}, visitingTeam = {} } = game
  const homePlaceholder = homeTeam?.isPlaceholder
  const visitingPlaceholder = visitingTeam?.isPlaceholder

  return isDesktop ? (
    <Wrap className={wrapClasses} isDesktop>
      <Flex
        as={homePlaceholder ? Flex : Link}
        to={homePlaceholder ? undefined : `${homeTeam.to}${scheduleQuery}`}
        alignItems='center'
        css={css`
          position: relative;
        `}
      >
        {/* <SidewayText left>{t('common:home')}</SidewayText> */}
        <Picture
          imageId={homeTeam?.imageId}
          css={imageCss}
          size='medium'
          {...picProps}
        />
        <div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
            `}
          >
            <TeamName className='left'>{homeTeam?.fullName}</TeamName>
            <p
              css={css`
                ${paragraphSmall}
                ${weightMedium}
                text-transform: uppercase;
                margin-left: 12px;
              `}
            >
              {t('common:home')}
            </p>
          </div>
          {homePlaceholder ? (
            <p
              css={css`
                margin-left: 12px;
                margin-top: 8px;
                color: ${colors.PRIMARY};
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0.71px;
                line-height: 16px;
              `}
            >
              {t('common:placeholder')}
            </p>
          ) : null}
        </div>
      </Flex>
      <ScoreSection
        isDesktop
        game={game}
        gameStatusTypes={gameStatusTypes}
        isSoccer={isSoccer}
      />
      <Flex
        as={visitingPlaceholder ? Flex : Link}
        to={
          visitingPlaceholder ? undefined : `${visitingTeam.to}${scheduleQuery}`
        }
        alignItems='center'
        justifyContent='flex-end'
        css={css`
          position: relative;
        `}
      >
        <div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              gap: 8px;
            `}
          >
            <TeamName className='right'>{visitingTeam?.fullName}</TeamName>
            <p
              css={css`
                ${paragraphSmall}
                ${weightMedium}
                text-transform: uppercase;
                margin-right: 12px;
              `}
            >
              {t('common:visitor')}
            </p>
          </div>
          {visitingPlaceholder ? (
            <p
              css={css`
                margin-right: 12px;
                margin-top: 8px;
                color: ${colors.PRIMARY};
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0.71px;
                line-height: 16px;
                text-align: right;
              `}
            >
              {t('common:placeholder')}
            </p>
          ) : null}
        </div>
        <Picture
          imageId={visitingTeam?.imageId}
          css={imageCss}
          size='medium'
          {...picProps}
        />
        {/* <SidewayText right>{t('common:visitor')}</SidewayText> */}
      </Flex>
    </Wrap>
  ) : (
    <Wrap column className={wrapClasses}>
      <Flex justifyContent='space-between'>
        <Flex
          column
          as={homePlaceholder ? Flex : Link}
          to={homePlaceholder ? undefined : `${homeTeam.to}${scheduleQuery}`}
        >
          <MobileHomeVisitorText>{t('common:home')}</MobileHomeVisitorText>
          <Picture
            imageId={homeTeam?.imageId}
            css={mobileImageCss}
            {...picProps}
          />
        </Flex>
        <ScoreSection
          game={game}
          gameStatusTypes={gameStatusTypes}
          isSoccer={isSoccer}
        />
        <Flex
          column
          as={visitingPlaceholder ? Flex : Link}
          to={
            visitingPlaceholder
              ? undefined
              : `${visitingTeam.to}${scheduleQuery}`
          }
          alignItems='flex-end'
        >
          <MobileHomeVisitorText>{t('common:visitor')}</MobileHomeVisitorText>
          <Picture
            imageId={visitingTeam?.imageId}
            css={mobileImageCss}
            {...picProps}
          />
        </Flex>
      </Flex>
      <Flex
        justifyContent='space-between'
        css={css`
          margin-top: 8px;
          ${font.title}
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0.42px;
          line-height: 18px;
          text-transform: uppercase;

          > div {
            width: 45%;
          }
        `}
      >
        <Flex
          column
          as={homePlaceholder ? Flex : Link}
          to={homePlaceholder ? undefined : `${homeTeam.to}${scheduleQuery}`}
        >
          {game.homeTeam.fullName}
        </Flex>
        <Flex
          column
          as={visitingPlaceholder ? Flex : Link}
          to={
            visitingPlaceholder
              ? undefined
              : `${visitingTeam.to}${scheduleQuery}`
          }
          css={css`
            text-align: right;
          `}
        >
          {game.visitingTeam.fullName}
        </Flex>
      </Flex>
    </Wrap>
  )
}

GamePageHeaderMatchup.propTypes = {
  game: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  gameStatusTypes: PropTypes.array,
}

const mapStateToProps = (state, { game }) => {
  return {
    gameStatusTypes: getGameStatusTypesBySportId(state, game?.sport_id),
  }
}

export default connect(mapStateToProps)(GamePageHeaderMatchup)
