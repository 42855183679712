import req from '@sportninja/common/api/request'

/**
 * Creates a new organization with the given form data, image data, and parent organization ID.
 *
 * @param {Object} form - The form data containing organization information.
 * @param {Object} imageData - The image data to be uploaded along with the organization.
 * @param {string} parentOrgId - The ID of the parent organization.
 * @return {Promise<Object>} The response object containing the created organization data.
 */
export const createOrg = async (form, imageData, parentOrgId) => {
  try {
    if (!form) {
      return null
    }

    // Duplicate name_full since API requires it
    if (parentOrgId) {
      form.parent_id = parentOrgId
    }
    form.name_full = form.organization_name
    form.name = form.name_full
    form.can_contain_organizations = true
    form.can_contain_teams = true

    if (typeof form.privacy_settings === 'undefined') {
      form.is_public = true
    }
    if (form?.privacy_settings === 'public') {
      form.is_public = true
    }
    if (form?.privacy_settings === 'private') {
      form.is_public = false
    }

    const body = JSON.stringify(form)
    const response = await req('/organizations', {
      method: 'POST',
      body,
    })
    if (imageData) {
      await req(`/organizations/${response.data.id}/image`, {
        method: 'POST',
        body: imageData,
      })
    }
    return response
  } catch (e) {
    console.error(e)
    throw e
  }
}

/**
 * Edits an organization.
 *
 * @param {Object} form - The form data for editing the organization.
 * @param {Object} imageData - The image data for the organization.
 * @param {string} orgId - The ID of the organization to be edited.
 * @param {boolean} imageChanged - Whether the image was changed.
 * @return {Promise} A promise that resolves to the response from the server.
 */
export const editOrg = async (form, imageData, orgId, imageChanged = false) => {
  try {
    if (!form) {
      return null
    }

    if (!imageData) {
      await req(`/organizations/${orgId}/image`, {
        method: 'DELETE',
      })
    }

    form.name_full = form.organization_name
    form.name = form.name_full
    if (typeof form.privacy_settings === 'undefined') {
      form.is_public = true
    }
    if (form?.privacy_settings === 'public') {
      form.is_public = true
    }
    if (form?.privacy_settings === 'private') {
      form.is_public = false
    }

    const body = JSON.stringify(form)
    const response = await req(`/organizations/${orgId}`, {
      method: 'PUT',
      body,
    })
    if (imageData && imageChanged) {
      await req(`/organizations/${orgId}/image`, {
        method: 'POST',
        body: imageData,
      })
    }
    return response
  } catch (e) {
    console.error(e)
    throw e
  }
}

/**
 * Reads the countries from the server.
 *
 * @return {Promise<Object|null>} The result of the request, or null if there was an error.
 */
export const readCountries = async () => {
  const result = await req('/countries')
  if (result) {
    return result
  }
  return null
}

/**
 * Reads the provinces of a given country.
 *
 * @param {string} countryId - The ID of the country.
 * @return {Promise} A promise that resolves to the provinces of the country, or null if the request fails.
 */
export const readProvinces = async (countryId) => {
  const result = await req(`/countries/${countryId}/provinces`)
  if (result) {
    return result
  }
  return null
}

/**
 * Reads the timezones for a given country.
 *
 * @param {string} countryId - The ID of the country.
 * @return {Promise} A promise that resolves to the timezones for the country, or null if the request fails.
 */
export const readTimezones = async (countryId) => {
  const result = await req(`/countries/${countryId}/timezones`)
  if (result) {
    return result
  }
  return null
}

/**
 * Retrieves the coordinates for a given address.
 *
 * @param {Object} address - The address object containing the street, city, province, and country.
 * @param {string} address.street_1 - The first line of the street address.
 * @param {string} address.city - The city of the address.
 * @param {Object} address.province - The province object containing the name.
 * @param {string} address.province.name - The name of the province.
 * @param {Object} address.country - The country object containing the name_full.
 * @param {string} address.country.name_full - The full name of the country.
 * @return {Object} The coordinates object containing the latitude and longitude.
 */
export const getCoord = async ({ street_1, city, province = {}, country }) => {
  try {
    if (!process.env.REACT_APP_GOOGLE_API_KEY) {
      console.log('No google api key found')
      return {
        latitude: 0,
        longitude: 0,
      }
    }
    await window.GMWait
    window.geocoder = window.geocoder || new window.google.maps.Geocoder()
    const address = `${street_1}, ${city}, ${
      province.name ? `${province.name}, ` : ''
    }${country.name_full}`
    const { results } = await window.geocoder.geocode({ address })
    if (results && results.length > 0) {
      return {
        latitude: results[0].geometry.location.lat(),
        longitude: results[0].geometry.location.lng(),
      }
    }
    // return a default value
    return {
      latitude: 0,
      longitude: 0,
    }
  } catch (e) {
    console.log(e)
    return {
      latitude: 0,
      longitude: 0,
    }
  }
}

/**
 * Create a venue with the given venue, facilities, and entity ID.
 *
 * @param {Object} venue - The venue object to save.
 * @param {Array} facilities - The facilities associated with the venue.
 * @param {string} entityId - The ID of the entity to associate the venue with.
 * @return {Promise} A promise that resolves when the venue is saved.
 */
export const saveVenue = async (venue, facilities, entityId) => {
  try {
    const response = await req(`/organizations/${entityId}/venues`, {
      method: 'POST',
      body: JSON.stringify(venue),
    })
    if (response?.data?.id) {
      for (let index = 0; index < facilities?.length; index++) {
        const facility = facilities[index]
        const facilityBody = {
          name: facility?.name,
        }
        await req(
          `/organizations/${entityId}/venues/${response?.data?.id}/facilities`,
          {
            method: 'POST',
            body: JSON.stringify(facilityBody),
          }
        )
      }
    }
    return response
  } catch (error) {
    console.error(error)
    throw e
  }
}

/**
 * Updates a venue with the given data.
 *
 * @param {Object} venue - The venue object to update.
 * @param {Array} facilities - The facilities associated with the venue.
 * @param {string} entityId - The ID of the entity.
 * @param {string} entity - The entity type.
 * @param {Object} selectedVenue - The selected venue object.
 * @return {Promise} A promise that resolves when the venue is updated.
 */
export const updateVenue = async (
  venue,
  facilities,
  entityId,
  entity,
  selectedVenue
) => {
  try {
    await req(`/${entity}/${entityId}/venues/${selectedVenue?.id}`, {
      method: 'PUT',
      body: JSON.stringify(venue),
    })
    let remainingFacilities = [...(selectedVenue?.facilities || [])]
    if (facilities) {
      for (let index = 0; index < facilities.length; index++) {
        const facility = facilities[index]
        const existingFacilityIndex = remainingFacilities.findIndex(
          (f) => f.id === facility.id
        )
        if (existingFacilityIndex !== -1) {
          const existingFacility = remainingFacilities[existingFacilityIndex]
          if (facility.name !== existingFacility.name) {
            await req(
              `/${entity}/${entityId}/venues/${selectedVenue.id}/facilities/${facility.id}`,
              {
                method: 'PUT',
                body: JSON.stringify({ name: facility.name }),
              }
            )
          }
          // Remove the facility from the remaining facilities
          remainingFacilities.splice(existingFacilityIndex, 1)
        } else {
          await req(
            `/${entity}/${entityId}/venues/${selectedVenue.id}/facilities`,
            {
              method: 'POST',
              body: JSON.stringify({ name: facility.name }),
            }
          )
        }
      }
    }
    // Any facilities remaining in remainingFacilities were removed
    for (let facility of remainingFacilities) {
      await req(
        `/${entity}/${entityId}/venues/${selectedVenue.id}/facilities/${facility.id}`,
        {
          method: 'DELETE',
        }
      )
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const createSchedule = async (
  data,
  orgId,
  imageData,
  backgroundImageUrl,
  imageChanged = false,
  backgroundImageChanged = false
) => {
  try {
    const response = await req(`/organizations/${orgId}/schedules`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
    if (
      imageData &&
      response?.data?.id &&
      response?.data?.organization_id === orgId &&
      imageChanged
    ) {
      await req(`/schedules/${response.data.id}/image`, {
        method: 'POST',
        body: imageData,
      })
    }
    if (
      backgroundImageUrl &&
      response?.data?.id &&
      response?.data?.organization_id === orgId &&
      backgroundImageChanged
    ) {
      await req(`/schedules/${response.data.id}/background-image`, {
        method: 'POST',
        body: backgroundImageUrl,
      })
    }
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const updateSchedule = async (
  data,
  scheduleId,
  imageData,
  backgroundImageUrl,
  imageChanged = false,
  backgroundImageChanged = false
) => {
  try {
    const response = await req(`/schedules/${scheduleId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    })
    if (imageData && imageChanged) {
      await req(`/schedules/${response.data.id}/image`, {
        method: 'POST',
        body: imageData,
      })
    }
    if (!imageData && imageChanged) {
      await req(`/schedules/${response.data.id}/image`, {
        method: 'DELETE',
      })
    }
    if (backgroundImageUrl && backgroundImageChanged) {
      await req(`/schedules/${response.data.id}/background-image`, {
        method: 'POST',
        body: backgroundImageUrl,
      })
    }
    if (!backgroundImageUrl && backgroundImageChanged) {
      await req(`/schedules/${response.data.id}/background-image`, {
        method: 'DELETE',
      })
    }
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const updateScheduleSettings = async (scheduleId, data) => {
  const response = await req(`/schedules/${scheduleId}/settings`, {
    method: 'PUT',
    body: JSON.stringify({
      settings: data,
    }),
  })
  return response
}

/**
 * Deletes a facility.
 *
 * @param {Object} params - The parameters for deleting the facility.
 * @param {string} params.entityId - The ID of the facility.
 * @param {string} params.venueId - The ID of the venue.
 * @param {string} params.facilityId - The ID of the facility.
 * @param {string} params.entityType - The type of the entity.
 * @returns {Promise<void>} - A promise that resolves when the facility is deleted.
 */
export const deleteFacility = async ({
  entityId,
  venueId,
  facilityId,
  entityType,
}) => {
  await req(
    `/${entityType}/${entityId}/venues/${venueId}/facilities/${facilityId}`,
    {
      method: 'DELETE',
    }
  )
}
