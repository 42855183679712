/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React from 'react'
import { Spin } from 'antd'
import colors from '@sportninja/common/constants/appColors'

interface LoadingViewProps {
  size?: 'small' | 'default' | 'large'
  fullPage?: boolean
}

const styles = {
  container: (fullPage: boolean) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${fullPage ? '100vh' : '60vh'};
    width: 100%;

    .ant-spin {
      &-text {
        margin-top: 8px;
        color: ${colors.NEUTRAL_60};
      }
      &-dot {
        &-item {
          background-color: ${colors.PRIMARY};
        }
      }
    }
  `,
  inline: css`
    display: inline-flex;
    align-items: center;
    gap: 8px;

    .ant-spin {
      &-text {
        color: ${colors.NEUTRAL_60};
      }
      &-dot {
        &-item {
          background-color: ${colors.PRIMARY};
        }
      }
    }
  `,
}

export const LoadingView: React.FC<LoadingViewProps> = ({
  size = 'large',
  fullPage = false,
}) => (
  <div css={styles.container(fullPage)}>
    <Spin size={size} />
  </div>
)

export const InlineLoadingView: React.FC<
  Omit<LoadingViewProps, 'fullPage'>
> = ({ size = 'small' }) => (
  <div css={styles.inline}>
    <Spin size={size} />
  </div>
)

export default LoadingView

// Usage in component:
/*
// Standard loading view
if (loading) {
  return <LoadingView tip="Loading registration data..." />
}

// Full page loading
<LoadingView tip="Initializing..." fullPage />

// Inline loading for buttons
<button disabled={isLoading}>
  {isLoading ? <InlineLoadingView tip="Saving..." /> : 'Save'}
</button>
*/
