import { useCallback } from 'react'

/**
 * A custom hook that manages document scroll behavior when opening/closing modals.
 *
 * This hook prevents the main document from scrolling when a modal is open by setting
 * overflow-y to 'hidden', and restores scrolling when the modal is closed.
 *
 * @returns {Object} An object containing openModal and closeModal functions
 * @returns {Function} openModal - Function to disable document scrolling
 * @returns {Function} closeModal - Function to re-enable document scrolling
 *
 * @example
 * ```tsx
 * const MyModal = () => {
 *   const [isOpen, setIsOpen] = useState(false);
 *   const { openModal, closeModal } = useModalScroll();
 *
 *   const handleOpen = () => {
 *     setIsOpen(true);
 *     openModal();
 *   };
 *
 *   const handleClose = () => {
 *     setIsOpen(false);
 *     closeModal();
 *   };
 *
 *   return (
 *     <>
 *       <button onClick={handleOpen}>Open Modal</button>
 *       {isOpen && (
 *         <Modal onClose={handleClose}>
 *           Modal Content
 *         </Modal>
 *       )}
 *     </>
 *   );
 * };
 * ```
 */
export const useModalScroll = () => {
  const openModal = useCallback(() => {
    document.documentElement.style.overflowY = 'hidden'
  }, [])

  const closeModal = useCallback(() => {
    document.documentElement.style.overflowY = ''
  }, [])

  return {
    openModal,
    closeModal,
  }
}
