/** @jsxImportSource @emotion/react */
import { CloseOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import orgActions from '@sportninja/common/actions/org'
import typeActions from '@sportninja/common/actions/types'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import req from '@sportninja/common/api/request'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { tutorialKeys } from '@sportninja/common/constants/userGuiding'
import { useRegistrationData } from '@sportninja/common/hooks/useRegistrationData'
import { useSports } from '@sportninja/common/hooks/useSports'
import { t } from '@sportninja/common/i18n'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import {
  getAllGenericSportProperties,
  getHockeySport,
  getSoccerSport,
  getSports,
} from '@sportninja/common/selectors/types'
import { getTutorial } from '@sportninja/common/utils/TutorialHelper'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { Button, Dropdown } from 'antd'
import { driver } from 'driver.js'
import { observer } from 'mobx-react-lite'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import GeneratePluginModal from 'src/components/GeneralPluginModal'
import ImportCSV from 'src/components/ImportCSV'
import ImportOfficials from 'src/components/ImportCSV/ImportOfficials'
import LoadingSpinner from 'src/components/LoadingSpinner'
import NewDaySmartForm from 'src/components/NewDaySmartForm'
import { NewLocationAddressSelector } from 'src/components/NewLocationAddressSelector'
import { NewModal } from 'src/components/NewModal'
import OrganizationModal from 'src/components/OrganizationModal'
import ScheduleModal from 'src/components/ScheduleModal'
import { ScheduleReprocessingContext } from 'src/components/ScheduleReprocessingContext'
import { font } from 'src/components/css'
import {
  getOrgScreenCreateYourLeagueSteps,
  getOrgScreenCreateYourLocationSteps,
  getOrgScreenCreateYourScheduleSteps,
  getOrgScreenSteps,
} from 'src/constants/TutorialSteps'
import { saveVenue } from 'src/helpers/EntityCreationHelper'
import { useMobxStore } from 'src/state'
import FileDownload from '../../components/FileDownload'
import FormSheet from '../../components/Form/FormSheet'
import banners from '../../components/Form/banner-helpers'
import { feedItemForm, teamForm } from '../../components/Form/form-configs'
import ImportTeamRoster from '../../components/ImportCSV/ImportTeamRoster'
import OfficialList, { AddOfficial } from '../../components/List/OfficialList'
import UserList, { AddUser } from '../../components/List/UserList'
import NewReports from '../../components/NewReports'
import Page from '../../components/Page'
import BaseActionButton from '../../components/Page/BaseActionButton'
import EditButton from '../../components/Page/EditButton'
import FavouriteButton from '../../components/Page/FavouriteButton'
import NewButton from '../../components/Page/NewButton'
import { Mobile } from '../../components/Responsive'
import Venues from '../../components/Venues'
import Home from '../Home'
import OrgList from '../Orgs/OrgList'
import RegistrationSetup from '../Schedule/RegistrationSetup'
import ReprocessingSettingsOverlay from '../Schedule/ReprocessingSettingsOverlay'
import ScheduleList from '../Schedules/ScheduleList'
import TeamList from '../Teams/TeamList'
import { getBreadcrumbs, useReadItem, useStoreRecent } from '../utils'
import EmptyOrg from './EmptyOrg'
import daysmartIcon from './daysmart-icon.png'
import CompetitionSetupModal from 'src/components/CompetitionSetupModal'

const driverObj = driver({
  allowClose: false,
  popoverClass: 'driverjs-theme',
  showProgress: true,
})

const Organization = observer(
  ({
    createVenue,
    match,
    isInFrame,
    soccerSport,
    hockeySport,
    isLoggedIn,
    readProvinces,
    genericSportsForDaysmart,
    sportsForDaysmart,
  }) => {
    const {
      me: { account },
    } = useMobxStore()

    const history = useHistory()
    const {
      loading,
      error,
      item,
      hierarchy,
      permission,
      readItem,
      refreshKey,
      triggerRefresh,
      features,
    } = useReadItem()

    const isProfessionalOrUltimateAccount = true
    const [isLoadingVenue, setIsLoadingVenue] = useState(false)
    const locationModalRef = useRef(null)
    const daySmartImportModalRef = useRef(null)
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false)
    const [isCompetitionSetupModalOpen, setIsCompetitionSetupModalOpen] =
      useState(false)
    const [newCompetitionId, setNewCompetitionId] = useState(null)
    const generatePluginTagModalRef = useRef(null)
    const [selectedSchedule, setSelectedSchedule] = useState(null)
    const [selectedSubOrg, setSelectedSubOrg] = useState(null)
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [registrationScheduleId, setRegistrationScheduleId] = useState(null)
    const [isRegistrationFormOpen, setIsRegistrationFormOpen] = useState(false)
    const { data, types, availableTaxes, refetch, fetchTypes } =
      useRegistrationData(registrationScheduleId)
    const refetchRegistrationFn = useRef(refetch)

    // const isProfessionalOrUltimateAccount =
    //   item?.account_subscription_type_id === SubscriptionType.PROFESSIONAL ||
    //   item?.account_subscription_type_id === SubscriptionType.ULTIMATE

    const onClickWebPluginOnScheduleList = (schedule) => {
      setSelectedSubOrg(null)
      setSelectedTeam(null)
      setSelectedSchedule(schedule)
      generatePluginTagModalRef?.current?.openModal()
    }

    const onClickWebPluginOnSubOrgList = (schedule) => {
      setSelectedSchedule(null)
      setSelectedTeam(null)
      setSelectedSubOrg(schedule?.id)
      generatePluginTagModalRef?.current?.openModal()
    }

    const onClickWebPluginOnTeamList = (schedule) => {
      setSelectedSchedule(null)
      setSelectedSubOrg(null)
      setSelectedTeam(schedule)
      generatePluginTagModalRef?.current?.openModal()
    }

    const [initialState, setInitialState] = useState(null)
    const [scheduleInitialState, setScheduleInitialState] = useState(null)
    const [scheduleInitialStateId, setScheduleInitialStateId] = useState(null)
    const [entityCount, setEntityCount] = useState(null)
    const [reloadWindowAfterCreate, setReloadWindowAfterCreate] =
      useState(false)
    const { sports, sportOptions, fetchSports } = useSports()

    useEffect(() => {
      if (!sports || sports?.length === 0) {
        fetchSports()
      }
    }, [fetchSports, sports])

    const { listeners = {}, addPending } = useContext(
      ScheduleReprocessingContext
    )
    const scheduleReprocessingListener = listeners[scheduleInitialStateId]

    const [organizationModalOpen, setOrganizationModalOpen] = useState(false)
    const [organization, setOrganization] = useState(null)
    const isDaysmartConnectedAtOrgLevel = useMemo(
      () => item?.is_daysmart_connected === true,
      [item]
    )

    const ORG_ID = useMemo(
      () => match.params.id?.replace('?sandbox_tour=1', ''),
      [match]
    )

    const isEmptyOrg = useMemo(
      () =>
        entityCount === null ||
        (entityCount?.org_count === 0 &&
          entityCount?.team_count === 0 &&
          entityCount?.schedule_count === 0 &&
          entityCount?.venue_count === 0),
      [entityCount]
    )
    const urlParams = new URLSearchParams(window.location.search)
    const sandboxTour = urlParams.get('sandbox_tour')
    const gettingStarted = urlParams.get('getting_started')
    const BASE_ROUTE = `${ROUTES.ORG_ROOT}/${ORG_ID}`
    const IS_PUBLIC = item.is_public
    const syncSalesforce = item?.sync_salesforce || false
    let shouldDisplayMergeButton = false
    if (isCanlan) {
      // Only display merge button for Canlan if the org is Canlan root
      if (permission?.update && ORG_ID === 'H9YZBLskm0BLnlNU') {
        shouldDisplayMergeButton = true
      }
    } else {
      if (permission?.update) {
        shouldDisplayMergeButton = true
      }
    }
    if (isEmptyOrg) {
      shouldDisplayMergeButton = false
    }

    useStoreRecent(ENTITY_TYPES.org, item, error, ORG_ID, isInFrame)

    useEffect(() => {
      if (!types || types === null) {
        fetchTypes()
      }
    }, [fetchTypes, types])

    useEffect(() => {
      if (registrationScheduleId && data) {
        refetchRegistrationFn.current = refetch
      }
    }, [data, registrationScheduleId])

    useEffect(() => {
      const readTutorial = async () => {
        try {
          const tutorialInfo = await getTutorial(
            tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_1
          )
          if (tutorialInfo.tutorialStep.value === 'true') {
            setTimeout(() => {
              const steps = getOrgScreenSteps(driverObj)
              driverObj.setSteps(steps)
              driverObj.drive()
            }, 2000)
          }
        } catch (e) {
          console.error(`Failed to read tutorial: ${e}`)
        }
      }
      const readGettingStartedTutorial = async () => {
        try {
          const tutorialInfoCreateYourLeague = await getTutorial(
            tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1
          )
          if (tutorialInfoCreateYourLeague?.tutorialStep?.value === 'true') {
            setTimeout(() => {
              const steps = getOrgScreenCreateYourLeagueSteps(driverObj)
              driverObj.setSteps(steps)
              driverObj.drive()
            }, 1000)
          }
        } catch (e) {
          console.error(`Failed to read tutorial: ${e}`)
        }
      }
      if (sandboxTour === '1') {
        readTutorial()
      }
      if (gettingStarted === '1') {
        setTimeout(() => {
          const organizationEmpty =
            document?.getElementById('organization-empty')
          if (organizationEmpty) {
            readGettingStartedTutorial()
          }
        }, 500)
      }
    }, [sandboxTour])

    const readyEntityCount = useCallback(async () => {
      try {
        const { results } = await req(`/organizations/${ORG_ID}/entity-count`)
        if (results) {
          setEntityCount(results)
        }
      } catch (e) {
        console.error(e)
        setEntityCount(null)
      }
    }, [ORG_ID])

    useEffect(() => {
      if (ORG_ID) {
        readyEntityCount()
      }
    }, [ORG_ID])

    useEffect(() => {
      readItem(ENTITY_TYPES.org, ORG_ID)
    }, [ORG_ID])

    const onAddTeam = async (...args) => {
      const [values] = args
      const { imageData, backgroundImageData, ...form } = values

      form.organization_id = ORG_ID
      form.name = form.name_full

      if (typeof form.is_public === 'undefined') {
        form.is_public = IS_PUBLIC
      }

      const body = JSON.stringify(form)
      const response = await req('/teams', {
        method: 'POST',
        body,
      })

      if (imageData) {
        await req(`/teams/${response.data.id}/image`, {
          method: 'POST',
          body: imageData,
        })
      }
      if (backgroundImageData) {
        await req(`/teams/${response.data.id}/background-image`, {
          method: 'POST',
          body: backgroundImageData,
        })
      }

      triggerRefresh()
      readyEntityCount()
      banners.entityCreate(ENTITY_TYPES.team)(response)
      try {
        driverObj?.moveNext()
      } catch (e) {
        console.error(e)
      }
      return response
    }

    const onAddVenue = useCallback(async (venue, facilities) => {
      setIsLoadingVenue(true)
      try {
        const response = await saveVenue(venue, facilities, ORG_ID)
        setIsLoadingVenue(false)
        triggerRefresh()
        readyEntityCount()
        locationModalRef.current.closeModal()
        banners.entityCreate(ENTITY_TYPES.venue)(response)
        const button = document.getElementById('hidden-schedule-tutorial')
        button?.click()
      } catch (e) {
        console.error(e)
        setIsLoadingVenue(false)
        triggerRefresh()
        readyEntityCount()
        locationModalRef.current.closeModal()
      }
    }, [])

    const titleActions = item?.id
      ? [
          <FavouriteButton
            key='favourite'
            initialState={item.is_favorite}
            entityType={ENTITY_TYPES.org}
            entityId={ORG_ID}
          />,
          permission.update && (
            <EditButton
              key='org_edit_button'
              onClick={() => {
                setOrganization(item)
                setOrganizationModalOpen(true)
              }}
              title={item?.name_full}
            />
          ),
        ]
      : []

    const baseActions = [
      permission.admin && item?.is_daysmart_connected && (
        <BaseActionButton
          key='import-daysmart'
          loading={loading}
          onClick={() => {
            daySmartImportModalRef?.current?.openModal()
          }}
          name='upload'
          title={'Import DaySmart Season'}
          customIcon={
            <img
              src={daysmartIcon}
              css={css`
                width: 16px;
                height: 16px;
              `}
            />
          }
        />
      ),
      shouldDisplayMergeButton && (
        <BaseActionButton
          key='merge'
          name='code-merge'
          title={t('Web:mergePlayers')}
          onClick={() => {
            history.push(ROUTES.MERGE_PLAYERS, {
              organizationName: item?.name_full,
              organizationId: ORG_ID,
            })
          }}
        />
      ),
      permission?.update && isProfessionalOrUltimateAccount && (
        <BaseActionButton
          key='plug'
          name='plug'
          title={'Generate JS Plugin Tag'}
          onClick={() => {
            generatePluginTagModalRef?.current?.openModal()
          }}
        />
      ),
    ]

    const items = [
      {
        key: '1',
        label: (
          <a
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => {
              // setReloadWindowAfterCreate(true)
              // setInitialState(null)
              setOrganization(null)
              setOrganizationModalOpen(true)
            }}
          >
            {isCanlan ? 'League' : 'Organization'}
          </a>
        ),
      },
      {
        key: '2',
        label: (
          <a
            id='schedule-tutorial'
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => {
              setReloadWindowAfterCreate(true)
              setScheduleInitialState(null)
              setScheduleInitialStateId(null)
              setIsScheduleModalOpen(true)
            }}
          >
            {isCanlan ? 'Season' : 'Competition'}
          </a>
        ),
      },
      {
        key: '3',
        label: (
          <FormSheet
            key='add-team'
            Button={({ onClick }) => (
              <a
                target='_blank'
                rel='noopener noreferrer'
                onClick={onClick}
                id='team-tutorial'
              >
                Team
              </a>
            )}
            form={teamForm(
              { isParentPublic: IS_PUBLIC, permission },
              true,
              sportOptions ? sportOptions[0]?.name_full : null,
              sportOptions,
              sports,
              sports ? sports[0]?.id : null,
              true
            )}
            label={t('Web:createTeam')}
            onSubmit={onAddTeam}
            title={t('Web:createANewTeam')}
          />
        ),
      },
      {
        key: '4',
        label: (
          <a
            id='location-tutorial'
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => {
              locationModalRef.current.openModal()
            }}
          >
            {isCanlan ? 'Sports Complex' : 'Location'}
          </a>
        ),
      },
    ]

    const pages = useMemo(
      () =>
        isEmptyOrg
          ? [
              {
                to: `${ROUTES.ORG_ROOT}/${ORG_ID}`,
                text: '',
                component: (
                  <EmptyOrg
                    parentId={ORG_ID}
                    IS_PUBLIC={IS_PUBLIC}
                    syncSalesforce={syncSalesforce}
                    hockeySport={hockeySport}
                    onAddTeam={onAddTeam}
                    // onAddSchedule={onAddSchedule}
                    onAddSchedule={() => {
                      setReloadWindowAfterCreate(true)
                      setIsScheduleModalOpen(true)
                    }}
                    onAddOrg={() => {
                      setReloadWindowAfterCreate(true)
                      setOrganizationModalOpen(true)
                      setOrganization(null)
                    }}
                    permission={permission}
                    sportOptions={sportOptions}
                    sports={sports}
                  />
                ),
                actions: [
                  permission.admin && (
                    <div id='tutorial-dropdown' key='org_dropdown_div_one'>
                      <Dropdown
                        menu={{ items }}
                        placement='bottomLeft'
                        trigger={['click']}
                        key='org_dropdown_one'
                      >
                        <Button
                          id='tutorial-button'
                          css={css`
                            margin-left: 16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: ${64}px;
                            height: 32px;

                            border: ${isCanlan ? 2 : 1}px solid
                              ${colors.DEFAULT_FLAIR};
                            border-radius: 4px;

                            ${font.title}
                            font-weight: 600;
                            font-size: 18px;

                            letter-spacing: 0.015em;
                            text-transform: uppercase;
                            color: white;

                            /* ${isCanlan && 'padding-bottom: 2px;'} */

                            transition: border-color 0.1s ease-in-out;

                            &:hover {
                              border-color: white;
                            }
                          `}
                        >
                          + NEW
                        </Button>
                      </Dropdown>
                    </div>
                  ),
                ],
              },
              !isInFrame &&
                permission.admin && {
                  to: `${ROUTES.ORG_ROOT}/${ORG_ID}/reports`,
                  text: 'Reports',
                  privatePage: true,
                  component: (
                    <NewReports
                      entity={item}
                      entityType={ENTITY_TYPES.org}
                      entityId={ORG_ID}
                    />
                  ),
                },
              !isInFrame &&
                permission.admin && {
                  to: `${ROUTES.ORG_ROOT}/${ORG_ID}/officials`,
                  text: t('common:official', { count: 2 }),
                  component: (
                    <OfficialList
                      key='officials'
                      entityType={ENTITY_TYPES.org}
                      entityId={ORG_ID}
                      slug={`/organizations/${ORG_ID}/officials`}
                      refreshKey={refreshKey}
                      isOrg={true}
                    />
                  ),
                  privatePage: true,
                  actions: [
                    permission.update && (
                      <FileDownload
                        key='download-official'
                        button={(loading, onClick) => {
                          return (
                            <BaseActionButton
                              loading={loading}
                              onClick={onClick}
                              name='download'
                              title={'Export Officials'}
                            />
                          )
                        }}
                        url={`/organizations/${ORG_ID}/officials/export/csv`}
                        fileName={`${item?.name_full}_officials`}
                      />
                    ),
                    permission.update && (
                      // <ImportCSV
                      //   key='import-official'
                      //   button={(label, onClick) => {
                      //     return (
                      //       <BaseActionButton
                      //         name='upload'
                      //         title={label}
                      //         onClick={onClick}
                      //       />
                      //     )
                      //   }}
                      //   entityType={ENTITY_TYPES.org}
                      //   id={ORG_ID}
                      //   importType={ENTITY_TYPES.officials}
                      //   onSuccess={triggerRefresh}
                      // />
                      <ImportOfficials
                        key='import-official'
                        teamName={item?.name_full}
                        onSuccess={triggerRefresh}
                        orgId={ORG_ID}
                      />
                    ),
                    (isCanlan || features?.rolesPermissions) && (
                      <AddOfficial
                        key='add-official'
                        entityType={ENTITY_TYPES.org}
                        entityId={ORG_ID}
                        permission={permission}
                        onComplete={triggerRefresh}
                        isOrg={true}
                      />
                    ),
                  ],
                },
              !isInFrame &&
                permission.admin && {
                  to: `${ROUTES.ORG_ROOT}/${ORG_ID}/users`,
                  text: t('common:user', { count: 2 }),
                  component: (
                    <UserList
                      key='users'
                      entityType={ENTITY_TYPES.org}
                      entityId={ORG_ID}
                      slug={`/organizations/${ORG_ID}/permissions`}
                      refreshKey={refreshKey}
                    />
                  ),
                  privatePage: true,
                  actions: (isCanlan || features?.rolesPermissions) && [
                    <AddUser
                      key='add-user'
                      entityType={ENTITY_TYPES.org}
                      entityId={ORG_ID}
                      permission={permission}
                      onComplete={triggerRefresh}
                    />,
                  ],
                },
            ]
          : [
              item.can_contain_organizations &&
                entityCount?.org_count > 0 && {
                  to: `${ROUTES.ORG_ROOT}/${ORG_ID}/children`,
                  text: `${t('Web:subOrg', { count: 2 })} ${
                    entityCount?.org_count ? `(${entityCount?.org_count})` : ''
                  }`,
                  component: (
                    <OrgList
                      refreshKey={refreshKey}
                      slug={`/organizations/${ORG_ID}/children`}
                      setInitialState={setOrganization}
                      onEditOrg={() => {
                        setOrganizationModalOpen(true)
                      }}
                      onClickWebPluginOnScheduleList={
                        onClickWebPluginOnSubOrgList
                      }
                    />
                  ),
                  actions: [
                    permission.admin && (
                      <Dropdown
                        key='org_dropdown_two'
                        menu={{ items }}
                        placement='bottomLeft'
                        trigger={['click']}
                      >
                        <Button
                          css={css`
                            margin-left: 16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: ${64}px;
                            height: 32px;

                            border: ${isCanlan ? 2 : 1}px solid
                              ${colors.DEFAULT_FLAIR};
                            border-radius: 4px;

                            ${font.title}
                            font-weight: 600;
                            font-size: 18px;

                            letter-spacing: 0.015em;
                            text-transform: uppercase;
                            color: white;

                            /* ${isCanlan && 'padding-bottom: 2px;'} */

                            transition: border-color 0.1s ease-in-out;

                            &:hover {
                              border-color: white;
                            }
                          `}
                        >
                          + NEW
                        </Button>
                      </Dropdown>
                    ),
                  ],
                },
              item.can_contain_schedules &&
                entityCount?.schedule_count > 0 && {
                  to: `${ROUTES.ORG_ROOT}/${ORG_ID}/schedules`,
                  text: `${t('common:competition', { count: 2 })} ${
                    entityCount?.schedule_count
                      ? `(${entityCount?.schedule_count})`
                      : ''
                  }`,
                  component: (
                    <ScheduleList
                      refreshKey={refreshKey}
                      accountTypeId={item?.account_subscription_type_id}
                      parentId={ORG_ID}
                      slug={`/organizations/${ORG_ID}/schedules`}
                      defaultSort='starts_at'
                      defaultDirection='desc'
                      syncSalesforce={syncSalesforce}
                      orgFeatures={features}
                      onClickSandboxTour={
                        sandboxTour && sandboxTour === '1'
                          ? async () => {
                              driverObj.destroy()
                            }
                          : () => {}
                      }
                      onEditSchedule={() => {
                        setIsScheduleModalOpen(true)
                      }}
                      setInitialState={setScheduleInitialState}
                      setScheduleInitialStateId={setScheduleInitialStateId}
                      onClickWebPluginOnScheduleList={
                        onClickWebPluginOnScheduleList
                      }
                      isParentOrgProfessionalOrUltimateAccount={
                        isProfessionalOrUltimateAccount
                      }
                      onClickRegistrationSetup={() => {
                        setIsRegistrationFormOpen(true)
                      }}
                      setRegistrationScheduleId={setRegistrationScheduleId}
                    />
                  ),
                  actions: [
                    permission.admin && (
                      <Dropdown
                        key='org_dropdown_three'
                        menu={{ items }}
                        placement='bottomLeft'
                        trigger={['click']}
                      >
                        <Button
                          css={css`
                            margin-left: 16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: ${64}px;
                            height: 32px;

                            border: ${isCanlan ? 2 : 1}px solid
                              ${colors.DEFAULT_FLAIR};
                            border-radius: 4px;

                            ${font.title}
                            font-weight: 600;
                            font-size: 18px;

                            letter-spacing: 0.015em;
                            text-transform: uppercase;
                            color: white;

                            /* ${isCanlan && 'padding-bottom: 2px;'} */

                            transition: border-color 0.1s ease-in-out;

                            &:hover {
                              border-color: white;
                            }
                          `}
                        >
                          + NEW
                        </Button>
                      </Dropdown>
                    ),
                  ],
                },
              item.can_contain_teams &&
                entityCount?.team_count > 0 && {
                  to: `${ROUTES.ORG_ROOT}/${ORG_ID}/teams`,
                  text: `${t('common:team', { count: 2 })} ${
                    entityCount?.team_count
                      ? `(${entityCount?.team_count})`
                      : ''
                  }`,
                  component: (
                    <TeamList
                      refreshKey={refreshKey}
                      parentId={ORG_ID}
                      slug={`/organizations/${ORG_ID}/teams`}
                      soccerSport={soccerSport}
                      hockeySport={hockeySport}
                      onClickWebPluginOnTeamList={onClickWebPluginOnTeamList}
                      isParentOrgProfessionalOrUltimateAccount={
                        isProfessionalOrUltimateAccount
                      }
                    />
                  ),
                  actions: [
                    <FileDownload
                      key='download-teams'
                      button={(loading, onClick) => {
                        return (
                          <BaseActionButton
                            loading={loading}
                            onClick={onClick}
                            name='download'
                            title={t('common:exportTeams')}
                          />
                        )
                      }}
                      url={`/organizations/${ORG_ID}/teams/export/csv`}
                      fileName={item?.name_full}
                    />,
                    permission.update && (
                      <ImportCSV
                        key='import-teams'
                        button={(label, onClick) => {
                          return (
                            <BaseActionButton
                              name='upload'
                              title={label}
                              onClick={onClick}
                            />
                          )
                        }}
                        entityType={ENTITY_TYPES.org}
                        id={ORG_ID}
                        importType={ENTITY_TYPES.team}
                        onSuccess={triggerRefresh}
                      />
                    ),
                    permission.update && (
                      <FileDownload
                        key='download-multiroster-teams'
                        button={(loading, onClick) => {
                          return (
                            <BaseActionButton
                              loading={loading}
                              onClick={onClick}
                              name='export-multi-roster'
                              title={'Export Multi Roster Teams'}
                            />
                          )
                        }}
                        url={`/organizations/${ORG_ID}/teams/exportmulti/csv`}
                        fileName={`exportmulti_${item?.name_full}`}
                      />
                    ),
                    permission.update && (
                      <ImportTeamRoster
                        key='import-roster'
                        teamName={item.name_full}
                        onSuccess={triggerRefresh}
                        orgId={ORG_ID}
                      />
                    ),
                    permission.admin && (
                      <div
                        id='tutorial-dropdown-teams'
                        key='org_dropdown_div_two'
                      >
                        <Dropdown
                          key='tutorial-dropdown-teams'
                          menu={{ items }}
                          placement='bottomLeft'
                          trigger={['click']}
                        >
                          <Button
                            id='tutorial-button-teams'
                            css={css`
                              margin-left: 16px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              width: ${64}px;
                              height: 32px;

                              border: ${isCanlan ? 2 : 1}px solid
                                ${colors.DEFAULT_FLAIR};
                              border-radius: 4px;

                              ${font.title}
                              font-weight: 600;
                              font-size: 18px;

                              letter-spacing: 0.015em;
                              text-transform: uppercase;
                              color: white;

                              /* ${isCanlan && 'padding-bottom: 2px;'} */

                              transition: border-color 0.1s ease-in-out;

                              &:hover {
                                border-color: white;
                              }
                            `}
                          >
                            + NEW
                          </Button>
                        </Dropdown>
                      </div>
                    ),
                  ],
                },
              item?.can_contain_locations &&
                entityCount?.venue_count > 0 && {
                  to: `${ROUTES.ORG_ROOT}/${ORG_ID}/locations`,
                  text: `${t('common:location', { count: 2 })} ${
                    entityCount?.venue_count
                      ? `(${entityCount?.venue_count})`
                      : ''
                  }`,
                  component: (
                    <Venues
                      entityType={ENTITY_TYPES.org}
                      id={ORG_ID}
                      slug={`/organizations/${ORG_ID}/venues`}
                      refreshKey={refreshKey}
                      triggerRefresh={triggerRefresh}
                      parentPermission={permission}
                    />
                  ),
                  actions: [
                    permission.admin && (
                      <Dropdown
                        key='org_dropdown_four'
                        menu={{ items }}
                        placement='bottomLeft'
                        trigger={['click']}
                      >
                        <Button
                          css={css`
                            margin-left: 16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: ${64}px;
                            height: 32px;

                            border: ${isCanlan ? 2 : 1}px solid
                              ${colors.DEFAULT_FLAIR};
                            border-radius: 4px;

                            ${font.title}
                            font-weight: 600;
                            font-size: 18px;

                            letter-spacing: 0.015em;
                            text-transform: uppercase;
                            color: white;

                            /* ${isCanlan && 'padding-bottom: 2px;'} */

                            transition: border-color 0.1s ease-in-out;

                            &:hover {
                              border-color: white;
                            }
                          `}
                        >
                          + NEW
                        </Button>
                      </Dropdown>
                    ),
                  ],
                },
              features?.announcements && {
                to: `${ROUTES.ORG_ROOT}/${ORG_ID}/feed`,
                text: t('common:feed'),
                component: (
                  <Home
                    isEntityFeed
                    entity={item}
                    entityType={ENTITY_TYPES.org}
                    entityId={ORG_ID}
                    refreshKey={refreshKey}
                    triggerRefresh={triggerRefresh}
                    hideSidebar={!permission.update}
                    canPost={
                      permission.post || permission.update || permission.admin
                    }
                  />
                ),
                actions: [
                  permission.admin && (
                    <Mobile key='add-post'>
                      <FormSheet
                        label={t('Web:createANewPost')}
                        title={t('Web:createANewPost')}
                        form={feedItemForm({}, false)}
                        onSubmit={async ({ imageData, ...form }) => {
                          const body = JSON.stringify(form)
                          const response = await req(
                            `/organizations/${ORG_ID}/social-feed`,
                            {
                              method: 'POST',
                              body,
                            }
                          )
                          if (imageData) {
                            await req(
                              `/social-feed/${response.data.id}/image`,
                              {
                                method: 'POST',
                                body: imageData,
                              }
                            )
                          }
                          triggerRefresh()
                        }}
                        Button={({ onClick }) => (
                          <NewButton onClick={onClick} />
                        )}
                      />
                    </Mobile>
                  ),
                ],
              },
              !isInFrame &&
                permission.admin && {
                  to: `${ROUTES.ORG_ROOT}/${ORG_ID}/reports`,
                  text: 'Reports',
                  privatePage: true,
                  component: (
                    <NewReports
                      entity={item}
                      entityType={ENTITY_TYPES.org}
                      entityId={ORG_ID}
                    />
                  ),
                },
              !isInFrame &&
                permission.admin && {
                  to: `${ROUTES.ORG_ROOT}/${ORG_ID}/officials`,
                  text: t('common:official', { count: 2 }),
                  component: (
                    <OfficialList
                      key='officials'
                      entityType={ENTITY_TYPES.org}
                      entityId={ORG_ID}
                      slug={`/organizations/${ORG_ID}/officials`}
                      refreshKey={refreshKey}
                      isOrg={true}
                    />
                  ),
                  privatePage: true,
                  actions: [
                    permission.update && (
                      <FileDownload
                        key='download-official'
                        button={(loading, onClick) => {
                          return (
                            <BaseActionButton
                              loading={loading}
                              onClick={onClick}
                              name='download'
                              title={'Export Officials'}
                            />
                          )
                        }}
                        url={`/organizations/${ORG_ID}/officials/export/csv`}
                        fileName={`${item?.name_full}_officials`}
                      />
                    ),
                    permission.update && (
                      // <ImportCSV
                      //   key='import-official'
                      //   button={(label, onClick) => {
                      //     return (
                      //       <BaseActionButton
                      //         name='upload'
                      //         title={label}
                      //         onClick={onClick}
                      //       />
                      //     )
                      //   }}
                      //   entityType={ENTITY_TYPES.org}
                      //   id={ORG_ID}
                      //   importType={ENTITY_TYPES.officials}
                      //   onSuccess={triggerRefresh}
                      // />
                      <ImportOfficials
                        key='import-official'
                        teamName={item?.name_full}
                        onSuccess={triggerRefresh}
                        orgId={ORG_ID}
                      />
                    ),
                    (isCanlan || features?.rolesPermissions) && (
                      <AddOfficial
                        key='add-official'
                        entityType={ENTITY_TYPES.org}
                        entityId={ORG_ID}
                        permission={permission}
                        onComplete={triggerRefresh}
                        isOrg={true}
                      />
                    ),
                  ],
                },
              !isInFrame &&
                permission.admin && {
                  to: `${ROUTES.ORG_ROOT}/${ORG_ID}/users`,
                  text: t('common:user', { count: 2 }),
                  component: (
                    <UserList
                      key='users'
                      entityType={ENTITY_TYPES.org}
                      entityId={ORG_ID}
                      slug={`/organizations/${ORG_ID}/permissions`}
                      refreshKey={refreshKey}
                    />
                  ),
                  privatePage: true,
                  actions: (isCanlan || features?.rolesPermissions) && [
                    <AddUser
                      key='add-user'
                      entityType={ENTITY_TYPES.org}
                      entityId={ORG_ID}
                      permission={permission}
                      onComplete={triggerRefresh}
                    />,
                  ],
                },
            ],
      [
        item,
        refreshKey,
        permission,
        features,
        ORG_ID,
        syncSalesforce,
        soccerSport,
        hockeySport,
        triggerRefresh,
        isInFrame,
        entityCount,
        IS_PUBLIC,
        isEmptyOrg,
        items,
        sportOptions,
        sports,
        sandboxTour,
      ]
    )

    const isOrganization = (selectedSchedule, selectedTeam, selectedSubOrg) => {
      if (!selectedSchedule && !selectedTeam && selectedSubOrg) {
        return true
      }
      if (!selectedSchedule && !selectedTeam && !selectedSubOrg) {
        return true
      }
      return false
    }

    return (
      <>
        <Page
          contactInfo={{
            support_email: item?.support_email,
            support_website: item?.support_website,
            support_phone: item?.support_phone,
            email: item?.email,
            website: item?.website,
            phone: item?.phone,
          }}
          backgroundImageUrl={item?.background_image?.full_path}
          error={error}
          loading={loading}
          baseCrumb={ROUTES.ORGS.ROOT}
          baseRoute={BASE_ROUTE}
          baseTitle={t('common:organization', { count: 2 })}
          breadcrumbs={getBreadcrumbs(ROUTES.ORG_ROOT, item, hierarchy)}
          baseActions={baseActions}
          pages={pages}
          isPrivate={!IS_PUBLIC}
          title={item.name_full}
          titleActions={titleActions}
          imageId={getImageThumbnailId(item)}
          isOrganization={true}
        />
        <a
          id='hidden-location-tutorial'
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            driverObj.setConfig({
              allowClose: false,
              popoverClass: 'driverjs-theme',
              showProgress: true,
            })
            driverObj.setSteps(getOrgScreenCreateYourLocationSteps(driverObj))
            driverObj.drive()
          }}
        />
        <a
          id='hidden-schedule-tutorial'
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            if (gettingStarted !== '1') {
              return
            }
            driverObj.setConfig({
              allowClose: false,
              popoverClass: 'driverjs-theme',
              showProgress: true,
            })
            driverObj.setSteps(getOrgScreenCreateYourScheduleSteps(driverObj))
            setTimeout(() => {
              driverObj.drive()
            }, 5000)
          }}
        />
        <NewModal ref={locationModalRef} shouldCloseOnOverlayClick={false}>
          <div
            css={css`
              width: 40vw;
              background: no-repeat fixed linear-gradient(#282e38, #181a1d);
              max-height: 80vh;
              // border-radius: 8px;
              padding: 20px;
              display: flex;
              flex-direction: column;
              overflow-y: auto;
              transition: all 0.1s ease-in-out;
            `}
            id='location-modal'
          >
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 16px;
                border-bottom: 1px solid ${colors.SOFT_STEEL};
                margin-bottom: 16px;
              `}
            >
              <h1
                css={css`
                  font-size: 36px;
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                  font-weight: 700;
                `}
              >
                {t('Web:createANewLocation')}
              </h1>
              <button
                onClick={() => locationModalRef.current.closeModal()}
                disabled={isLoadingVenue}
                css={css`
                  margin-bottom: 20px;
                `}
              >
                <CloseOutlined
                  fontSize={20}
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: colors.WHITE,
                    fontSize: 20,
                  }}
                />
              </button>
            </div>
            <div
              css={css`
                flex: 1;
                flex-grow: 1;
                display: flex;
                transition: all 0.1s ease-in-out;
              `}
            >
              {isLoadingVenue ? (
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                  `}
                >
                  <LoadingSpinner />
                </div>
              ) : (
                <NewLocationAddressSelector
                  onCancel={() => locationModalRef.current.closeModal()}
                  onSubmit={onAddVenue}
                  readProvinces={readProvinces}
                />
              )}
            </div>
          </div>
        </NewModal>
        <NewModal
          ref={daySmartImportModalRef}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEscPreventDefault={false}
        >
          <NewDaySmartForm
            daySmartImportModalRef={daySmartImportModalRef}
            readItem={readItem}
            readyEntityCount={readyEntityCount}
            key={Math?.random()?.toString()}
            account={account}
            account_id={item?.account_id}
            ORG_ID={ORG_ID}
            genericSports={genericSportsForDaysmart}
            sports={sportsForDaysmart}
          />
        </NewModal>
        <ScheduleModal
          setNewCompetitionId={setNewCompetitionId}
          setIsCompetitionSetupModalOpen={setIsCompetitionSetupModalOpen}
          isFormOpen={isScheduleModalOpen}
          setIsFormOpen={setIsScheduleModalOpen}
          initialState={scheduleInitialState || null}
          onCancel={() => {
            setScheduleInitialState(null)
            setIsScheduleModalOpen(false)
          }}
          triggerRefresh={() => {
            readyEntityCount()
            readItem(ENTITY_TYPES.org, ORG_ID)
          }}
          parentOrgId={ORG_ID}
          displayCanlanForm={syncSalesforce}
          addPending={addPending}
          onFinishTutorial={() => {
            try {
              driverObj?.moveTo(4)
            } catch (e) {
              console.error(e)
            }
          }}
          isDaysmartConnectedAtOrgLevel={isDaysmartConnectedAtOrgLevel}
          sportId={item.sport_id}
        />
        <NewModal
          ref={generatePluginTagModalRef}
          shouldCloseOnOverlayClick={false}
        >
          <GeneratePluginModal
            onPressClose={() => {
              generatePluginTagModalRef?.current?.closeModal()
              setSelectedSchedule(null)
              setSelectedSubOrg(null)
              setSelectedTeam(null)
            }}
            scheduleId={
              selectedSchedule?.id ||
              selectedSubOrg ||
              selectedTeam?.id ||
              item?.id
            }
            isOrganization={isOrganization(
              selectedSchedule,
              selectedTeam,
              selectedSubOrg
            )}
            isTeam={selectedTeam}
          />
        </NewModal>
        <ReprocessingSettingsOverlay
          scheduleReprocessingListener={scheduleReprocessingListener}
        />
        <OrganizationModal
          isFormOpen={organizationModalOpen}
          setIsFormOpen={setOrganizationModalOpen}
          organization={organization}
          account_id={account && JSON.parse(JSON.stringify(account?.uid))}
          setOrganization={setOrganization}
          refresh={() => {
            readyEntityCount()
            readItem(ENTITY_TYPES.org, ORG_ID)
          }}
          parentOrgId={ORG_ID}
        />
        <RegistrationSetup
          isRegistrationFormOpen={isRegistrationFormOpen}
          setIsRegistrationFormOpen={setIsRegistrationFormOpen}
          registration={data?.detailed?.registration}
          registrationTypes={types}
          availableTaxes={availableTaxes}
          scheduleId={registrationScheduleId}
          refetchRegistrationFn={refetchRegistrationFn}
        />
        <CompetitionSetupModal
          isOpen={isCompetitionSetupModalOpen}
          setIsOpen={setIsCompetitionSetupModalOpen}
          onContinue={() => {
            history.push(ROUTES.SCHEDULE_ROOT + `/${newCompetitionId}/setup`)
            setNewCompetitionId(null)
          }}
          onCancel={() => {
            setNewCompetitionId(null)
          }}
        />
      </>
    )
  }
)

const mapStateToProps = (state) => {
  return {
    hockeySport: getHockeySport(state),
    soccerSport: getSoccerSport(state),
    isInFrame: state.auth.inFrame,
    isLoggedIn: state.auth.login.success,
    genericSportsForDaysmart: getAllGenericSportProperties(state),
    sportsForDaysmart: Object.values(getSports(state)),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createVenue: bindActionToPromise(
      dispatch,
      orgActions.venues.create.request
    ),
    readProvinces: bindActionToPromise(dispatch, typeActions.provinces.request),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Organization)
