/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Text from 'src/components/ts-components/Text'
import { ReactNode } from 'react'
import { paragraphSmall, weightBold } from 'src/components/css'
import { Tooltip } from 'antd'

interface RoleSelectionCardProps {
  onClick?: () => void
  disabled?: boolean
  title: string
  subtitle: string
  icon: ReactNode
  selected?: boolean
  deposit: string
  cost: string
}

const RoleSelectionCard = ({
  onClick,
  disabled = false,
  title,
  subtitle,
  icon,
  selected = false,
  deposit,
  cost,
}: RoleSelectionCardProps) => {
  return (
    <Tooltip title={disabled ? 'Registration is Full' : ''}>
      <div
        css={css`
          background-color: ${selected ? colors.PRIMARY : colors.NEUTRAL_600};
          /* if not selected, add a border */
          border: ${selected ? 'none' : `1px solid ${colors.NEUTRAL_100}`};
          border-radius: 16px;
          padding: 32px;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: ${disabled ? 'not-allowed' : 'pointer'};
          opacity: ${disabled ? 0.5 : 1};
          transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
          min-width: 300px;
          width: 100%;
          &:hover {
            transform: ${disabled ? 'none' : 'translateY(-4px)'};
            box-shadow: ${disabled ? 'none' : '0 4px 12px rgba(0, 0, 0, 0.2)'};
          }
          @media (max-width: 768px) {
            min-width: 100%;
          }
        `}
        onClick={disabled ? undefined : onClick}
      >
        <div
          css={css`
            background-color: ${colors.NEUTRAL_800};
            border-radius: 50%;
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
          `}
        >
          {icon}
        </div>

        <Text
          variant='heading2'
          color={selected ? colors.SECONDARY_300 : colors.NEUTRAL_0}
          style={`
            text-align: center;
            margin-bottom: 8px;
          `}
        >
          {title}
        </Text>

        <Text
          variant='paragraphMedium'
          color={selected ? colors.SECONDARY_300 : colors.NEUTRAL_50}
          style={`
            text-align: center;
          `}
        >
          {subtitle}
        </Text>
        {/* divider */}
        <div
          css={css`
            width: 100%;
            height: 1px;
            background-color: ${selected
              ? colors.NEUTRAL_600
              : colors.NEUTRAL_300};
            border-bottom: 1px solid
              ${selected ? colors.NEUTRAL_600 : colors.NEUTRAL_300};
            margin-top: 8px;
            margin-bottom: 8px;
          `}
        />
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 16px;
            align-self: stretch;
          `}
        >
          {deposit !== 'Free' ? (
            <Text
              variant='paragraphXSmall'
              color={selected ? colors.NEUTRAL_600 : colors.NEUTRAL_50}
              weight='regular'
            >
              Deposit:
              <br />
              <span
                css={css`
                  ${paragraphSmall}
                  ${weightBold}
                color: ${selected ? colors.NEUTRAL_600 : colors.NEUTRAL_0};
                `}
              >
                {deposit}
              </span>
            </Text>
          ) : (
            <div />
          )}

          <Text
            variant='paragraphXSmall'
            color={selected ? colors.NEUTRAL_600 : colors.NEUTRAL_50}
            weight='regular'
          >
            Cost:
            <br />
            <span
              css={css`
                ${paragraphSmall}
                ${weightBold}
                color: ${selected ? colors.NEUTRAL_600 : colors.NEUTRAL_0};
              `}
            >
              {cost}
            </span>
          </Text>
        </div>
      </div>
    </Tooltip>
  )
}

export default RoleSelectionCard
