/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Text from 'src/components/ts-components/Text'

interface RegistrationEntityCardProps {
  onClick?: () => void
  disabled?: boolean
  name: string
  dateOfBirth: string
  image?: string
  selected?: boolean
  playerId?: string
}

const RegistrationEntityCard = ({
  onClick,
  disabled = false,
  name,
  dateOfBirth,
  image = '/images/generic_placeholders/placeholder_player.png',
  selected = false,
  playerId,
}: RegistrationEntityCardProps) => {
  return (
    <div
      css={css`
        background-color: ${selected ? colors.PRIMARY : colors.NEUTRAL_600};
        border: ${selected ? 'none' : `1px solid ${colors.NEUTRAL_100}`};
        border-radius: 16px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        opacity: ${disabled ? 0.5 : 1};
        transition: all 0.2s ease-in-out;
        height: 200px;
        width: 200px;

        &:hover {
          transform: ${disabled ? 'none' : 'translateY(-4px)'};
          box-shadow: ${disabled ? 'none' : '0 4px 12px rgba(0, 0, 0, 0.2)'};
        }

        @media (max-width: 768px) {
          min-width: 150px;
          padding: 16px;
        }
      `}
      onClick={disabled ? undefined : onClick}
    >
      <div
        css={css`
          background-color: ${colors.NEUTRAL_800};
          border-radius: 50%;
          width: 72px;
          height: 72px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 16px;
          overflow: hidden;

          @media (max-width: 768px) {
            width: 64px;
            height: 64px;
          }
        `}
      >
        <img
          src={image}
          alt={name}
          css={css`
            width: 100%;
            height: 100%;
            object-fit: cover;
          `}
        />
      </div>

      <Text
        variant='heading6'
        color={selected ? colors.SECONDARY_300 : colors.NEUTRAL_0}
        style={`
          text-align: center;
          margin-bottom: 8px;
          word-break: break-word;
        `}
      >
        {name}
      </Text>
      {playerId !== 'new' && (
        <Text
          variant='paragraphXSmall'
          color={selected ? colors.SECONDARY_300 : colors.NEUTRAL_50}
          style={`
            text-align: center;
          `}
        >
          {dateOfBirth}
        </Text>
      )}
    </div>
  )
}

export default RegistrationEntityCard
