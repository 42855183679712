/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'
import colors from '@sportninja/common/constants/appColors'
import { useEffect, useState } from 'react'
import { Flex } from '../Layout'
import Icon from '../Icon'

const CopyComponent = ({
  id,
  customText = 'ID',
  alternativeBackground = false,
}) => {
  const [hasCopied, setHasCopied] = useState(false)

  useEffect(() => {
    if (hasCopied) {
      setTimeout(() => {
        setHasCopied(false)
      }, 2000)
    }
  }, [hasCopied])

  return (
    <Flex
      css={css`
        /* width: 100%; */
      `}
    >
      <Flex alignItems='center' noFlex>
        <Flex
          justifyContent='center'
          alignItems='center'
          css={css`
            border-radius: 8px;
            background-color: ${!alternativeBackground
              ? '#1B222B'
              : colors.SECONDARY_300};
            margin-left: 8px;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 4px;
            padding-bottom: 4px;
          `}
        >
          <span
            css={css`
              color: #6a6c70;
              font-size: 14px;
              line-height: 1.5;
            `}
          >
            {customText}
          </span>
          <div
            css={css`
              padding: 4px 8px 0;
              font-size: 16px;
              font-family: 'Courier New', monospace;
              font-weight: normal;
              color: ${colors.WHITE};
              min-width: 185px;
            `}
          >
            {hasCopied ? 'Copied' : id}
          </div>
          <button
            type='button'
            title='Copy UID to clipboard'
            onClick={() => {
              navigator.clipboard.writeText(id)
              setHasCopied(true)
            }}
            css={css`
              height: 100%;
            `}
          >
            <Icon
              faType='far'
              name={hasCopied ? 'clipboard-check' : 'clipboard'}
              color={hasCopied ? colors.DEFAULT_FLAIR : undefined}
              fontSize={16}
            />
          </button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CopyComponent
