/** @jsxImportSource @emotion/react */
import req from '@sportninja/common/api/request'
import { useRegistrationData } from '@sportninja/common/hooks/useRegistrationData'
import { useUserPlayers, Player } from '@sportninja/common/hooks/useUserPlayers'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useProvinces } from '@sportninja/common/hooks/useProvinces'
import {
  useUserTeamOfficials,
  TeamOfficial,
} from '@sportninja/common/hooks/useUserTeamOfficials'
import {
  useUserRegistrationTeams,
  Team,
} from '@sportninja/common/hooks/useUserRegistrationTeams'
import useSessionManagement, {
  Role,
  TeamRegistrationParams,
  PlayerRegistrationParams,
} from '@sportninja/common/hooks/useSessionManagement'
import {
  RegistrationTypes,
  RegistrationType,
} from '@sportninja/common/types/Registration'
import { Currency, Division } from '@sportninja/common/types'
import {
  useRegistration,
  RegistrationData,
} from '@sportninja/common/hooks/useRegistration'
import { useCountries } from '@sportninja/common/hooks/useCountries'

interface DivisionGender {
  name: string
}

interface DivisionAge {
  name: string
}

interface DivisionDayOfWeek {
  name: string
}

interface Event {
  division_gender?: DivisionGender
  division_age?: DivisionAge
  day_of_week?: DivisionDayOfWeek
}

interface PlayerInfoState {
  email: string
  firstName: string
  lastName: string
  birthDate: string | null
  password: string
}

interface TeamInfoState {
  teamPhone: string
  teamEmail: string
  teamWebsite: string
  teamNotes: string
}

interface AddressState {
  address: string
  zipCode: string
  stateProvince: string
  country: string
  city: string
}

interface ParentInfoState {
  firstName: string
  lastName: string
  email: string
  phone: string
}

interface EmergencyInfoState {
  firstName: string
  lastName: string
  phone: string
  relationship: string
  email: string
  doctorName: string
  doctorPhone: string
  allergies: string
  medicalConditions: string
}

interface PreferencesState {
  preferredPosition: string
  jerseyNumber: string
  dominantSide: string
  jerseySize: string
  tshirtSize: string
  shortSize: string
  hockeyUSANumber: string
  hockeyCanadaNumber: string
  otherMembershipName: string
  otherMembershipNumber: string
  notes: string
}

interface SessionState {
  session: any | null
  registration: any | null
  billing: any | null
  step: number
  division: any | null
  selectedTeam: any | null
}

interface CouponInfo {
  discount: number
  type: string
  id: string
}

interface RegistrationContextProps {
  children: React.ReactNode
  login: () => void
  logout: () => void
  isLogged: boolean
  loginRegistration: () => void
  setCredentials: (credentials: any) => void
  selfUser: any
  isOnRegistration?: boolean
  genericSportProperties: any
}

// Helper Functions
const getUniqueDivisionGenders = (events: Event[] | undefined): string[] => {
  if (!events) {
    return []
  }
  const uniqueGenders = new Set<string>()
  events.forEach((event) => {
    if (event.division_gender && event.division_gender.name) {
      uniqueGenders.add(event.division_gender.name)
    }
  })
  return Array.from(uniqueGenders)
}

const getUniqueDivisionDaysOfWeek = (events: Event[] | undefined): string[] => {
  if (!events) {
    return []
  }

  const uniqueDaysOfWeek = new Set<string>()
  events.forEach((event) => {
    if (event.day_of_week && event.day_of_week.name) {
      uniqueDaysOfWeek.add(event.day_of_week.name)
    }
  })

  return Array.from(uniqueDaysOfWeek)
}

const getUniqueDivisionAges = (events: Event[] | undefined): string[] => {
  if (!events) {
    return []
  }
  const uniqueAges = new Set<string>()
  events.forEach((event) => {
    if (event.division_age && event.division_age.name) {
      uniqueAges.add(event.division_age.name)
    }
  })
  return Array.from(uniqueAges)
}

interface RegistrationContextType {
  fetchUserInfo: () => void
  fetchTypes: () => void
  fetchCountries: () => void
  isTeamRep: boolean
  email: string
  firstName: string
  lastName: string
  birthDate: string | null
  address: string
  zipCode: string
  stateProvince: string
  country: string
  city: string
  parentFirstName: string
  parentLastName: string
  parentEmail: string
  parentPhone: string
  emergencyEmail: string
  emergencyFirstName: string
  emergencyLastName: string
  emergencyPhone: string
  parentRelationship: string
  doctorName: string
  doctorPhone: string
  allergies: string
  medicalConditions: string
  preferredPosition: string
  jerseyNumber: string
  dominantSide: string
  jerseySize: string
  tshirtSize: string
  shortSize: string
  hockeyUSANumber: string
  hockeyCanadaNumber: string
  notes: string
  otherMembershipName: string
  otherMembershipNumber: string
  setSession: (session: any) => void
  setStep: (step: number) => void
  setDivision: (division: Division) => void
  setSelectedTeam: (team: Team) => void
  updateSessionConfirmSubmission: () => Promise<void>
  updateSessionVerifyEmail: () => Promise<void>
  updateSessionPayment: () => Promise<void>
  updateSessionWaiver: (signed: boolean) => Promise<void>
  updateSessionTeamRegister: (params: TeamRegistrationParams) => Promise<void>
  updateSessionDivision: (divisionId: string, userId?: string) => Promise<void>
  updateSessionPlayerRegister: (
    params: PlayerRegistrationParams
  ) => Promise<void>
  userTeams: Team[] | null
  countries: any[]
  login: () => void
  isLogged: boolean
  setSessionState: (state: any) => void
  setEmail: (email: string) => void
  setFirstName: (firstName: string) => void
  setLastName: (lastName: string) => void
  setBirthDate: (birthDate: string | null) => void
  setPassword: (password: string) => void
  setAddress: (address: string) => void
  setZipCode: (zipCode: string) => void
  setStateProvince: (stateProvince: string) => void
  setCountry: (country: string) => void
  setCity: (city: string) => void
  setParentFirstName: (firstName: string) => void
  setParentLastName: (lastName: string) => void
  setParentEmail: (email: string) => void
  setParentPhone: (phone: string) => void
  setEmergencyFirstName: (firstName: string) => void
  setEmergencyLastName: (lastName: string) => void
  setEmergencyPhone: (phone: string) => void
  setParentRelationship: (relationship: string) => void
  setDoctorName: (name: string) => void
  setDoctorPhone: (phone: string) => void
  setAllergies: (allergies: string) => void
  setMedicalConditions: (conditions: string) => void
  setEmergencyEmail: (email: string) => void
  setPreferredPosition: (position: string) => void
  setJerseyNumber: (number: string) => void
  setDominantSide: (side: string) => void
  setJerseySize: (size: string) => void
  setTshirtSize: (size: string) => void
  setShortSize: (size: string) => void
  setHockeyUSANumber: (number: string) => void
  setHockeyCanadaNumber: (number: string) => void
  setNotes: (notes: string) => void
  setOtherMembershipName: (name: string) => void
  setOtherMembershipNumber: (number: string) => void
  genderFilters: string[]
  ageFilters: string[]
  promoCode: string
  setPromoCode: (code: string) => void
  loginRegistration: () => void
  setCredentials: (credentials: any) => void
  additionalTeamOfficials: any[]
  setAdditionalTeamOfficials: (officials: any[]) => void
  couponInfo: any
  setCouponInfo: (info: any) => void
  userId: string | null
  deleteSession: () => void
  currency: Currency | null
  setCurrency: (currency: Currency) => void
  updateSessionSignUp: (
    token: string,
    divisionId: string,
    newUserId: string
  ) => Promise<void>
  resetSession: () => void
  validateCoupon: (code: string, divisionId: string) => Promise<void>
  updateSessionRole: (
    selectedRole: Role,
    selectedPlayerId: string,
    userId?: string
  ) => Promise<void>
  selectedPlayer: any
  setSelectedPlayer: (player: any) => void
  types: RegistrationTypes | null
  playerType: RegistrationType
  teamOfficialType: RegistrationType
  players: Player[] | null
  refetchPlayers: () => void
  playersLoading: boolean
  provinces: any[]
  userInfo: any
  teamOfficials: TeamOfficial[] | null
  refetchTeamOfficials: () => void
  teamOfficialsLoading: boolean
  userTeamsLoading: boolean
  refetchUserTeams: () => void
  role: Role | null
  setRole: (role: Role) => void
  session: any | null
  billing: any | null
  step: number
  division: Division | null
  selectedTeam: Team | null
  error: Error | null
  setError: (error: Error) => void
  loading: boolean
  setLoading: (loading: boolean) => void
  id: string | null
  setId: (id: string) => void
  registration: RegistrationData | null | undefined
  refetchRegistration: () => void
  teamPhone: string
  setTeamPhone: (phone: string) => void
  teamEmail: string
  setTeamEmail: (email: string) => void
  teamWebsite: string
  setTeamWebsite: (website: string) => void
  teamNotes: string
  setTeamNotes: (notes: string) => void
  dayOfWeekFilters: string[]
  genericSportProperties: any
  logout: () => void
}

// Context Creation
export const RegistrationContext = createContext<RegistrationContextType>(
  {} as RegistrationContextType
)

const RegistrationContextWrapper: React.FC<RegistrationContextProps> = ({
  children,
  login,
  logout,
  isLogged,
  loginRegistration,
  setCredentials,
  selfUser,
  isOnRegistration = false,
  genericSportProperties,
}) => {
  const { types, fetchTypes } = useRegistrationData()
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(true)
  const [id, setId] = useState<string | null>(null)
  const { data: registration, refetch: refetchRegistration } = useRegistration(
    id || undefined
  )

  // State Management
  const [userInfo, setUserInfo] = useState<any>(null)
  const [sessionState, setSessionState] = useState<SessionState>({
    session: null,
    registration: registration || null,
    billing: null,
    step: 0,
    division: null,
    selectedTeam: null,
  })

  const { countries, refetch: fetchCountries } = useCountries()
  const [userId, setUserId] = useState<string | null>(null)

  const [teamInfoState, setTeamInfoState] = useState<TeamInfoState>({
    teamPhone: '',
    teamEmail: '',
    teamWebsite: '',
    teamNotes: '',
  })

  const [playerInfoState, setPlayerInfoState] = useState<PlayerInfoState>({
    email: '',
    firstName: '',
    lastName: '',
    birthDate: null,
    password: '',
  })

  const [addressState, setAddressState] = useState<AddressState>({
    address: '',
    zipCode: '',
    stateProvince: '',
    country: '',
    city: '',
  })

  const [parentInfoState, setParentInfoState] = useState<ParentInfoState>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  })

  const [emergencyInfoState, setEmergencyInfoState] =
    useState<EmergencyInfoState>({
      firstName: '',
      lastName: '',
      phone: '',
      relationship: '',
      email: '',
      doctorName: '',
      doctorPhone: '',
      allergies: '',
      medicalConditions: '',
    })

  const [preferencesState, setPreferencesState] = useState<PreferencesState>({
    preferredPosition: '',
    jerseyNumber: '',
    dominantSide: '',
    jerseySize: '',
    tshirtSize: '',
    shortSize: '',
    hockeyUSANumber: '',
    hockeyCanadaNumber: '',
    otherMembershipName: '',
    otherMembershipNumber: '',
    notes: '',
  })

  const [selectedPlayer, setSelectedPlayer] = useState<any>(null)
  const [promoCode, setPromoCode] = useState<string>('')
  const [couponInfo, setCouponInfo] = useState<CouponInfo>({
    discount: 0,
    type: '',
    id: '',
  })
  const [additionalTeamOfficials, setAdditionalTeamOfficials] = useState<any[]>(
    []
  )
  const [currency, setCurrency] = useState<Currency | null>(null)
  const [role, setRole] = useState<Role | null>(null)

  const selectedCountryId = useMemo(() => {
    return countries.find((c) => c.iso_3166_2 === addressState?.country)?.id
  }, [addressState?.country, countries])

  const { provinces } = useProvinces(selectedCountryId)

  const genderFilters = useMemo(
    () => getUniqueDivisionGenders(sessionState?.registration?.divisions),
    [sessionState?.registration?.divisions]
  )

  const ageFilters = useMemo(
    () => getUniqueDivisionAges(sessionState?.registration?.divisions),
    [sessionState?.registration?.divisions]
  )

  const dayOfWeekFilters = useMemo(
    () => getUniqueDivisionDaysOfWeek(sessionState?.registration?.divisions),
    [sessionState?.registration?.divisions]
  )

  const playerType =
    types?.registration?.find((type) => type.name === 'Player') ||
    ({} as RegistrationType)
  const teamOfficialType =
    types?.registration?.find((type) => type.name === 'Team') ||
    ({} as RegistrationType)

  const {
    data: players,
    refetch: refetchPlayers,
    loading: playersLoading,
  } = useUserPlayers()

  const {
    data: teamOfficials,
    refetch: refetchTeamOfficials,
    loading: teamOfficialsLoading,
  } = useUserTeamOfficials()

  const {
    data: userTeams,
    refetch: refetchUserTeams,
    loading: userTeamsLoading,
  } = useUserRegistrationTeams(
    role?.name === 'Team' ? id : sessionState?.division?.schedule_id || '',
    role?.name === 'Player'
      ? 'player'
      : role?.name === 'Team'
      ? 'team-official'
      : null
  )

  const isTeamRep = useMemo(() => {
    if (role !== null && role?.id === teamOfficialType?.id) {
      return true
    }
    return false
  }, [role, teamOfficialType])

  // Typed State Setters
  const setTeamPhone = (phone: string): void => {
    setTeamInfoState((prev) => ({
      ...prev,
      teamPhone: phone,
    }))
  }

  const setTeamEmail = (email: string): void => {
    setTeamInfoState((prev) => ({
      ...prev,
      teamEmail: email,
    }))
  }

  const setTeamWebsite = (website: string): void => {
    setTeamInfoState((prev) => ({
      ...prev,
      teamWebsite: website,
    }))
  }

  const setTeamNotes = (notes: string): void => {
    setTeamInfoState((prev) => ({
      ...prev,
      teamNotes: notes,
    }))
  }

  const setNotes = (notes: string): void => {
    setPreferencesState((prev) => ({
      ...prev,
      notes,
    }))
  }

  const setOtherMembershipNumber = (otherMembershipNumber: string): void => {
    setPreferencesState((prev) => ({
      ...prev,
      otherMembershipNumber,
    }))
  }

  const setOtherMembershipName = (otherMembershipName: string): void => {
    setPreferencesState((prev) => ({
      ...prev,
      otherMembershipName,
    }))
  }

  const setHockeyCanadaNumber = (hockeyCanadaNumber: string): void => {
    setPreferencesState((prev) => ({
      ...prev,
      hockeyCanadaNumber,
    }))
  }

  const setHockeyUSANumber = (hockeyUSANumber: string): void => {
    setPreferencesState((prev) => ({
      ...prev,
      hockeyUSANumber,
    }))
  }

  const setShortSize = (shortSize: string): void => {
    setPreferencesState((prev) => ({
      ...prev,
      shortSize,
    }))
  }

  const setTshirtSize = (tshirtSize: string): void => {
    setPreferencesState((prev) => ({
      ...prev,
      tshirtSize,
    }))
  }

  const setJerseySize = (jerseySize: string): void => {
    setPreferencesState((prev) => ({
      ...prev,
      jerseySize,
    }))
  }

  const setDominantSide = (dominantSide: string): void => {
    setPreferencesState((prev) => ({
      ...prev,
      dominantSide,
    }))
  }

  const setJerseyNumber = (jerseyNumber: string): void => {
    setPreferencesState((prev) => ({
      ...prev,
      jerseyNumber,
    }))
  }

  const setPreferredPosition = (preferredPosition: string): void => {
    setPreferencesState((prev) => ({
      ...prev,
      preferredPosition,
    }))
  }

  const setEmergencyEmail = (email: string): void => {
    setEmergencyInfoState((prev) => ({
      ...prev,
      email,
    }))
  }

  const setMedicalConditions = (medicalConditions: string): void => {
    setEmergencyInfoState((prev) => ({
      ...prev,
      medicalConditions,
    }))
  }

  const setAllergies = (allergies: string): void => {
    setEmergencyInfoState((prev) => ({
      ...prev,
      allergies,
    }))
  }

  const setDoctorName = (doctorName: string): void => {
    setEmergencyInfoState((prev) => ({
      ...prev,
      doctorName,
    }))
  }

  const setDoctorPhone = (doctorPhone: string): void => {
    setEmergencyInfoState((prev) => ({
      ...prev,
      doctorPhone,
    }))
  }

  const setParentRelationship = (relationship: string): void => {
    setEmergencyInfoState((prev) => ({
      ...prev,
      relationship,
    }))
  }

  const setEmergencyPhone = (phone: string): void => {
    setEmergencyInfoState((prev) => ({
      ...prev,
      phone,
    }))
  }

  const setEmergencyLastName = (lastName: string): void => {
    setEmergencyInfoState((prev) => ({
      ...prev,
      lastName,
    }))
  }

  const setEmergencyFirstName = (firstName: string): void => {
    setEmergencyInfoState((prev) => ({
      ...prev,
      firstName,
    }))
  }

  const setParentFirstName = (firstName: string): void => {
    setParentInfoState((prev) => ({
      ...prev,
      firstName,
    }))
  }

  const setParentLastName = (lastName: string): void => {
    setParentInfoState((prev) => ({
      ...prev,
      lastName,
    }))
  }

  const setParentEmail = (email: string): void => {
    setParentInfoState((prev) => ({
      ...prev,
      email,
    }))
  }

  const setParentPhone = (phone: string): void => {
    setParentInfoState((prev) => ({
      ...prev,
      phone,
    }))
  }

  const setAddress = (address: string): void => {
    setAddressState((prev) => ({
      ...prev,
      address,
    }))
  }

  const setZipCode = (zipCode: string): void => {
    setAddressState((prev) => ({
      ...prev,
      zipCode,
    }))
  }

  const setStateProvince = (stateProvince: string): void => {
    setAddressState((prev) => ({
      ...prev,
      stateProvince,
    }))
  }

  const setCountry = (country: string): void => {
    setAddressState((prev) => ({
      ...prev,
      country,
    }))
  }

  const setCity = (city: string): void => {
    setAddressState((prev) => ({
      ...prev,
      city,
    }))
  }

  const setFirstName = (firstName: string): void => {
    setPlayerInfoState((prev) => ({
      ...prev,
      firstName,
    }))
  }

  const setLastName = (lastName: string): void => {
    setPlayerInfoState((prev) => ({
      ...prev,
      lastName,
    }))
  }

  const setEmail = (email: string): void => {
    setPlayerInfoState((prev) => ({
      ...prev,
      email,
    }))
  }

  const setBirthDate = (birthDate: string | null): void => {
    setPlayerInfoState((prev) => ({
      ...prev,
      birthDate,
    }))
  }

  const setPassword = (password: string): void => {
    setPlayerInfoState((prev) => ({
      ...prev,
      password,
    }))
  }

  const setSession = (session: any): void => {
    setSessionState((prev) => ({
      ...prev,
      session,
    }))
  }

  const setStep = (step: number): void => {
    setSessionState((prev) => ({
      ...prev,
      step,
    }))
  }

  const setDivision = (division: Division): void => {
    setSessionState((prev) => ({
      ...prev,
      division,
    }))
  }

  const setSelectedTeam = (team: Team): void => {
    setSessionState((prev) => ({
      ...prev,
      selectedTeam: team,
    }))
  }

  const {
    updateSessionRole,
    updateSessionDivision,
    updateSessionTeamRegister,
    updateSessionPlayerRegister,
    updateSessionSignUp,
    updateSessionWaiver,
    updateSessionPayment,
    updateSessionVerifyEmail,
    updateSessionConfirmSubmission,
    deleteSession,
    resetSession,
    validateCoupon,
  } = useSessionManagement({
    teamOfficialType,
    role,
    currency,
    selectedPlayer,
    playerInfoState,
    addressState,
    parentInfoState,
    emergencyInfoState,
    preferencesState,
    setZipCode,
    setCountry,
    setAdditionalTeamOfficials,
    sessionState,
    setSessionState,
  })

  useEffect(() => {
    if (registration) {
      setSessionState((prev) => ({
        ...prev,
        registration,
      }))
    }
  }, [registration])

  useEffect(() => {
    if (sessionState?.registration?.registration?.currency) {
      setCurrency(sessionState.registration.registration.currency)
    }
  }, [sessionState])

  useEffect(() => {
    if (selectedPlayer?.birth_date) {
      setBirthDate(selectedPlayer.birth_date.split('T')[0])
    }
  }, [selectedPlayer])

  useEffect(() => {
    if (isTeamRep) {
      setFirstName(userInfo?.name_first)
      setLastName(userInfo?.name_last)
      setBirthDate(userInfo?.birth_date?.split('T')[0])
    }
  }, [isTeamRep, userInfo])

  const fetchUserInfo = async (): Promise<void> => {
    try {
      if (!selfUser || !selfUser?.id || !isLogged || !isOnRegistration) {
        return
      }
      const { data } = await req('/users/me')
      setUserId(data?.id)
      // Removing this due to https://sportninja.atlassian.net/browse/SN-5387
      // setFirstName(data?.name_first)
      // setLastName(data?.name_last)
      // setBirthDate(data?.birth_date?.split('T')[0])
      setEmail(data?.email)
      setUserInfo(data)
    } catch (e) {
      if (selfUser?.email) {
        setEmail(selfUser?.email)
      }
      console.log('error', e)
    }
  }

  useEffect(() => {
    if (sessionState?.session?.user && sessionState?.session?.user?.email) {
      // Removing this due to https://sportninja.atlassian.net/browse/SN-5387
      // setFirstName(sessionState?.session?.user?.name_first)
      // setLastName(sessionState?.session?.user?.name_last)
      // setBirthDate(sessionState?.session?.user?.birth_date?.split('T')[0])
      setEmail(sessionState?.session?.user?.email)
      setUserInfo(sessionState?.session?.user)
    }
  }, [sessionState])

  // useEffect(() => {
  //   const fetchUserInfo = async (): Promise<void> => {
  //     try {
  //       if (!selfUser || !selfUser?.id || !isLogged || !isOnRegistration) {
  //         return
  //       }
  //       const { data } = await req('/users/me')
  //       setUserId(data?.id)
  //       setFirstName(data?.name_first)
  //       setLastName(data?.name_last)
  //       setEmail(data?.email)
  //       setBirthDate(data?.birth_date?.split('T')[0])
  //       setUserInfo(data)
  //     } catch (e) {
  //       setFirstName('')
  //       setLastName('')
  //       setEmail('')
  //       setBirthDate(null)
  //     }
  //   }
  //   if (selfUser && selfUser?.id && isLogged && isOnRegistration) {
  //     fetchUserInfo()
  //   }
  // }, [isLogged, selfUser, isOnRegistration])

  // Context value
  const value = {
    ...sessionState,
    ...playerInfoState,
    email: playerInfoState?.email,
    firstName: playerInfoState?.firstName,
    lastName: playerInfoState?.lastName,
    birthDate: playerInfoState?.birthDate,
    address: addressState?.address,
    zipCode: addressState?.zipCode,
    stateProvince: addressState?.stateProvince,
    country: addressState?.country,
    city: addressState?.city,
    parentFirstName: parentInfoState?.firstName,
    parentLastName: parentInfoState?.lastName,
    parentEmail: parentInfoState?.email,
    parentPhone: parentInfoState?.phone,
    emergencyEmail: emergencyInfoState?.email,
    emergencyFirstName: emergencyInfoState?.firstName,
    emergencyLastName: emergencyInfoState?.lastName,
    emergencyPhone: emergencyInfoState?.phone,
    parentRelationship: emergencyInfoState?.relationship,
    doctorName: emergencyInfoState?.doctorName,
    doctorPhone: emergencyInfoState?.doctorPhone,
    allergies: emergencyInfoState?.allergies,
    medicalConditions: emergencyInfoState?.medicalConditions,
    preferredPosition: preferencesState?.preferredPosition,
    jerseyNumber: preferencesState?.jerseyNumber,
    dominantSide: preferencesState?.dominantSide,
    jerseySize: preferencesState?.jerseySize,
    tshirtSize: preferencesState?.tshirtSize,
    shortSize: preferencesState?.shortSize,
    hockeyUSANumber: preferencesState?.hockeyUSANumber,
    hockeyCanadaNumber: preferencesState?.hockeyCanadaNumber,
    notes: preferencesState?.notes,
    otherMembershipName: preferencesState?.otherMembershipName,
    otherMembershipNumber: preferencesState?.otherMembershipNumber,
    teamPhone: teamInfoState?.teamPhone,
    setTeamPhone,
    teamEmail: teamInfoState?.teamEmail,
    setTeamEmail,
    teamWebsite: teamInfoState?.teamWebsite,
    setTeamWebsite,
    teamNotes: teamInfoState?.teamNotes,
    setTeamNotes,
    setSession,
    setStep,
    setDivision,
    setSelectedTeam,
    updateSessionConfirmSubmission,
    updateSessionVerifyEmail,
    updateSessionPayment,
    updateSessionWaiver,
    updateSessionTeamRegister,
    updateSessionDivision,
    updateSessionPlayerRegister,
    userTeams: userTeams || null,
    countries,
    login,
    isLogged,
    setSessionState,
    // Player Info setters
    setEmail,
    setFirstName,
    setLastName,
    setBirthDate,
    setPassword,
    // Address setters
    setAddress,
    setZipCode,
    setStateProvince,
    setCountry,
    setCity,
    // Parents/Guardian Info setters
    setParentFirstName,
    setParentLastName,
    setParentEmail,
    setParentPhone,
    // Emergency Contact Info setters
    setEmergencyFirstName,
    setEmergencyLastName,
    setEmergencyPhone,
    setParentRelationship,
    setDoctorName,
    setDoctorPhone,
    setAllergies,
    setMedicalConditions,
    setEmergencyEmail,
    // Preferences setters
    setPreferredPosition,
    setJerseyNumber,
    setDominantSide,
    setJerseySize,
    setTshirtSize,
    setShortSize,
    setHockeyUSANumber,
    setHockeyCanadaNumber,
    setNotes,
    setOtherMembershipName,
    setOtherMembershipNumber,
    // Filters and other props
    genderFilters,
    ageFilters,
    promoCode,
    setPromoCode,
    loginRegistration,
    setCredentials,
    additionalTeamOfficials,
    setAdditionalTeamOfficials,
    couponInfo,
    setCouponInfo,
    userId,
    deleteSession,
    currency,
    setCurrency,
    updateSessionSignUp,
    resetSession,
    role,
    setRole,
    validateCoupon,
    updateSessionRole,
    selectedPlayer,
    setSelectedPlayer,
    types,
    playerType,
    teamOfficialType,
    players,
    refetchPlayers,
    playersLoading,
    provinces,
    userInfo,
    teamOfficials,
    refetchTeamOfficials,
    teamOfficialsLoading,
    userTeamsLoading,
    refetchUserTeams,
    error,
    setError,
    loading,
    setLoading,
    id,
    setId,
    registration,
    refetchRegistration,
    dayOfWeekFilters,
    genericSportProperties,
    logout,
    isTeamRep,
    fetchCountries,
    fetchTypes,
    fetchUserInfo,
  }

  return (
    <RegistrationContext.Provider value={value}>
      {children}
    </RegistrationContext.Provider>
  )
}

export default RegistrationContextWrapper
