/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import dayjs from 'dayjs'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import { TextInput } from 'src/components/ts-components/TextInput'
import { useManageCoupon } from '../context/ManageCouponContext'
import { DeleteOutlined } from '@ant-design/icons'
import MultipleOptionsInput from 'src/components/ts-components/MultipleOptionsInput'

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const CouponForm = () => {
  const {
    code,
    setCode,
    discount,
    setDiscount,
    discountType,
    setDiscountType,
    isActive,
    setIsActive,
    startsAtMonth,
    setStartsAtMonth,
    startsAtDay,
    setStartsAtDay,
    startsAtYear,
    setStartsAtYear,
    endsAtMonth,
    setEndsAtMonth,
    endsAtDay,
    setEndsAtDay,
    endsAtYear,
    setEndsAtYear,
    fieldErrors,
    customError,
    maxUses,
    setMaxUses,
    emailRestrictions,
    setEmailRestrictions,
  } = useManageCoupon()

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media (max-width: 768px) {
          padding: 0 8px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 16px;
          @media (max-width: 768px) {
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
          `}
        >
          <TextInput
            name='code'
            value={code}
            setValue={(value) => {
              console.log('valueeeee', value)
              setCode(value)
            }}
            fieldErrors={fieldErrors}
            customError={customError}
            label='Code *'
            maxLength={10}
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
          `}
        >
          <SelectInput
            label='Status *'
            disabled={false}
            options={[
              { label: 'Active', value: 'true' },
              { label: 'Inactive', value: 'false' },
            ]}
            name='is_active'
            value={isActive.toString()}
            onChange={(value) => setIsActive(value === 'true')}
            fieldErrors={fieldErrors}
            customError={customError}
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex: 4;
          gap: 16px;
          @media (max-width: 768px) {
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
            flex: 2;
          `}
        >
          <TextInput
            name='discount'
            value={discount}
            setValue={setDiscount}
            fieldErrors={fieldErrors}
            customError={customError}
            label='Discount *'
            type='number'
            onBlur={() => {
              if (discount === '') {
                setDiscount('0')
              }
              // If its negative, set it to 0
              if (Number(discount) < 0) {
                setDiscount('0')
              }
              // If its starts with 0, remove it
              if (discount.startsWith('0')) {
                setDiscount(discount.slice(1))
              }
            }}
          />
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
            flex: 1;
          `}
        >
          <SelectInput
            label='Discount Type *'
            disabled={false}
            options={[
              { label: 'Amount', value: 'amount' },
              { label: 'Percentage', value: 'percentage' },
            ]}
            name='discount_type'
            value={discountType}
            onChange={(value) =>
              setDiscountType(value as 'amount' | 'percentage')
            }
            fieldErrors={fieldErrors}
            customError={customError}
          />
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
            flex: 1;
          `}
        >
          <TextInput
            name='max_uses'
            value={maxUses.toString()}
            setValue={(value: string) => {
              // Remove any negative signs from input
              const sanitizedValue = value.replace(/-/g, '')
              const num = parseInt(sanitizedValue, 10)
              if (isNaN(num)) {
                setMaxUses(0)
              } else {
                setMaxUses(num)
              }
            }}
            fieldErrors={fieldErrors}
            customError={customError}
            label='Max Usage'
            type='number'
            onBlur={() => {
              if (maxUses === 0 || maxUses < 0) {
                setMaxUses(0)
              }
            }}
          />
        </div>
      </div>

      <div
        css={css`
          display: flex;
          gap: 16px;
          @media (max-width: 768px) {
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            flex: 1;
            width: 100%;
          `}
        >
          <p
            css={css`
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
              color: ${colors.WHITE};
              margin-bottom: 8px;
            `}
          >
            Starts At
          </p>
          <div
            css={css`
              display: flex;
              flex: 3;
              align-items: center;
              width: 100%;
            `}
          >
            <div
              css={css`
                flex: 1;
              `}
            >
              <SelectInput
                label=''
                disabled={false}
                options={[
                  { label: 'January', value: '01' },
                  { label: 'February', value: '02' },
                  { label: 'March', value: '03' },
                  { label: 'April', value: '04' },
                  { label: 'May', value: '05' },
                  { label: 'June', value: '06' },
                  { label: 'July', value: '07' },
                  { label: 'August', value: '08' },
                  { label: 'September', value: '09' },
                  { label: 'October', value: '10' },
                  { label: 'November', value: '11' },
                  { label: 'December', value: '12' },
                ]}
                name='starts_at_month'
                value={startsAtMonth}
                onChange={(value) => {
                  setStartsAtMonth(value)
                  const daysInMonth = dayjs(
                    `${startsAtYear}-${value}-01`
                  ).daysInMonth()
                  if (parseInt(startsAtDay, 10) > daysInMonth) {
                    setStartsAtDay('01')
                  }
                }}
                fieldErrors={fieldErrors}
                customError={customError}
                style={'flex: 1; width: 100%;'}
              />
            </div>
            <div
              css={css`
                width: 8px;
              `}
            />
            <div
              css={css`
                flex: 1;
              `}
            >
              <SelectInput
                label=''
                disabled={false}
                options={Array.from(
                  {
                    length: dayjs(
                      `${startsAtYear || '2024'}-${startsAtMonth || '01'}-01`
                    ).daysInMonth(),
                  },
                  (_, i) => ({
                    label: (i + 1).toString(),
                    value: (i + 1).toString().padStart(2, '0'),
                  })
                )}
                name='starts_at_day'
                value={startsAtDay}
                onChange={setStartsAtDay}
                fieldErrors={fieldErrors}
                customError={customError}
                style={'flex: 1; width: 100%;'}
              />
            </div>
            <div
              css={css`
                width: 8px;
              `}
            />
            <div
              css={css`
                flex: 1;
              `}
            >
              <TextInput
                name='starts_at_year'
                value={startsAtYear}
                setValue={setStartsAtYear}
                fieldErrors={fieldErrors}
                customError={customError}
                label=''
                type='number'
                maxLength={4}
                onBlur={() => {
                  if (startsAtYear === '') {
                    setStartsAtYear('2024')
                  }
                  if (parseInt(startsAtYear, 10) < 1900) {
                    setStartsAtYear('1900')
                  }
                }}
              />
            </div>
            {(startsAtMonth || startsAtDay || startsAtYear) && (
              <DeleteOutlined
                css={css`
                  color: ${colors.ERROR_200};
                  font-size: 16px;
                  cursor: pointer;
                  margin-left: 8px;
                `}
                onClick={() => {
                  setStartsAtMonth('')
                  setStartsAtDay('')
                  setStartsAtYear('')
                }}
              />
            )}
          </div>
          <div
            css={css`
              transition: opacity 0.1s ease-in-out;
              opacity: ${fieldErrors.includes('starts_at') ? 1 : 0};
              font-size: 13px;
              line-height: 16px;
              color: ${colors.ERROR_LIGHT};
              margin-top: 4px;
            `}
          >
            <span>{customError.starts_at}</span>
          </div>
        </div>

        <div
          css={css`
            flex: 1;
            width: 100%;
          `}
        >
          <p
            css={css`
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
              color: ${colors.WHITE};
              margin-bottom: 8px;
            `}
          >
            Ends At
          </p>
          <div
            css={css`
              display: flex;
              flex: 3;
              align-items: center;
              width: 100%;
            `}
          >
            <div
              css={css`
                flex: 1;
              `}
            >
              <SelectInput
                label=''
                disabled={false}
                options={[
                  { label: 'January', value: '01' },
                  { label: 'February', value: '02' },
                  { label: 'March', value: '03' },
                  { label: 'April', value: '04' },
                  { label: 'May', value: '05' },
                  { label: 'June', value: '06' },
                  { label: 'July', value: '07' },
                  { label: 'August', value: '08' },
                  { label: 'September', value: '09' },
                  { label: 'October', value: '10' },
                  { label: 'November', value: '11' },
                  { label: 'December', value: '12' },
                ]}
                name='ends_at_month'
                value={endsAtMonth}
                onChange={(value) => {
                  setEndsAtMonth(value)
                  const daysInMonth = dayjs(
                    `${endsAtYear}-${value}-01`
                  ).daysInMonth()
                  if (parseInt(endsAtDay, 10) > daysInMonth) {
                    setEndsAtDay('01')
                  }
                }}
                fieldErrors={fieldErrors}
                customError={customError}
                style={'flex: 1; width: 100%;'}
              />
            </div>
            <div
              css={css`
                width: 8px;
              `}
            />
            <div
              css={css`
                flex: 1;
              `}
            >
              <SelectInput
                label=''
                disabled={false}
                options={Array.from(
                  {
                    length: dayjs(
                      `${endsAtYear || '2024'}-${endsAtMonth || '01'}-01`
                    ).daysInMonth(),
                  },
                  (_, i) => ({
                    label: (i + 1).toString(),
                    value: (i + 1).toString().padStart(2, '0'),
                  })
                )}
                name='ends_at_day'
                value={endsAtDay}
                onChange={setEndsAtDay}
                fieldErrors={fieldErrors}
                customError={customError}
                style={'flex: 1; width: 100%;'}
              />
            </div>
            <div
              css={css`
                width: 8px;
              `}
            />
            <div
              css={css`
                flex: 1;
              `}
            >
              <TextInput
                name='ends_at_year'
                value={endsAtYear}
                setValue={setEndsAtYear}
                fieldErrors={fieldErrors}
                customError={customError}
                label=''
                type='number'
                maxLength={4}
                onBlur={() => {
                  if (endsAtYear === '') {
                    setEndsAtYear('2024')
                  }
                  if (parseInt(endsAtYear, 10) < 1900) {
                    setEndsAtYear('1900')
                  }
                }}
              />
            </div>
            {(endsAtMonth || endsAtDay || endsAtYear) && (
              <DeleteOutlined
                css={css`
                  color: ${colors.ERROR_200};
                  font-size: 16px;
                  cursor: pointer;
                  margin-left: 8px;
                `}
                onClick={() => {
                  setEndsAtMonth('')
                  setEndsAtDay('')
                  setEndsAtYear('')
                }}
              />
            )}
          </div>
          <div
            css={css`
              transition: opacity 0.1s ease-in-out;
              opacity: ${fieldErrors.includes('ends_at') ? 1 : 0};
              font-size: 13px;
              line-height: 16px;
              color: ${colors.ERROR_LIGHT};
              margin-top: 4px;
            `}
          >
            <span>{customError.ends_at}</span>
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
        `}
      >
        <MultipleOptionsInput
          label='Limit to Specific Emails'
          tip='(Comma Separated, Press "Enter" to Add)'
          value={emailRestrictions}
          onChange={(value) => {
            setEmailRestrictions(value)
          }}
          placeholder='Enter email addresses'
          type='email'
          validator={emailRegex}
          fieldErrors={fieldErrors}
          customError={customError}
          name='emailRestrictions'
        />
      </div>
    </div>
  )
}
