import { useState, useEffect, useCallback, useRef } from 'react'
import req from '@sportninja/common/api/request'

// Types
export interface Image {
  id: string
  filename: string
  full_path: string
  mime_type: string
  width: number
  height: number
  formats: {
    id: string
    filename: string
    full_path: string
    mime_type: string
    width: number
    height: number
  }[]
}

export interface Parent {
  name: string
  image: Image
}

export interface Post {
  id: string
  title: string
  body: string
  body_push_notification: string | null
  body_email: string | null
  url: string
  is_public: boolean
  is_global: boolean
  parent_type: string
  parent_id: string
  parent: Parent
  is_liked: boolean
  like_count: number
  created_at: string
  images: Image[]
  replies?: Reply[]
  user_id: string
  user: {
    id: string
    name_full: string
    email?: string
    image?: {
      id: string
      filename: string
      full_path: string
      mime_type: string
      width: number
      height: number
    }
  }
}

export interface Reply {
  id: string
  body: string
  created_at: string
  user: {
    id: string
    name: string
    avatar?: string
  }
}

interface SocialFeedResponse {
  data: Post[]
  meta: {
    permissions: Record<
      string,
      {
        admin: boolean
        viewAny: boolean
        view: boolean
        update: boolean
        create: boolean
        delete: boolean
        score: boolean
        video: boolean
        officiate: boolean
      }
    >
    pagination: {
      total: number
      count: number
      per_page: number
      current_page: number
      total_pages: number
    }
  }
}

interface UseSocialFeedProps {
  id?: string
  type?: 'team' | 'schedule' | 'organization' | 'teams' | 'schedules'
  shouldFetch?: boolean
}

interface CreatePostData {
  title: string
  body: string
  url?: string
  url_title?: string
  image?: string // base64 image data
}

const useSocialFeed = ({
  id,
  type,
  shouldFetch = true,
}: UseSocialFeedProps = {}) => {
  const [posts, setPosts] = useState<Post[]>([])
  const [permission, setPermission] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  // 1. Use a ref to track if we've already fetched
  const hasFetchedRef = useRef(false)

  const fetchPosts = useCallback(
    async (pageNumber: number) => {
      setLoading(true)
      setError(null)
      try {
        let url: string
        if (!type || !id) {
          url = `social-feed?page=${pageNumber}&order=desc&per_page=30`
        } else {
          url =
            type === 'team' || type === 'teams'
              ? `teams/${id}/social-feed?order=desc&method=GET&page=${pageNumber}&per_page=30`
              : type === 'schedule' || type === 'schedules'
              ? `schedules/${id}/social-feed?order=desc&method=GET&page=${pageNumber}&per_page=30`
              : `organizations/${id}/social-feed?order=desc&method=GET&page=${pageNumber}&per_page=30`
        }

        const { data, meta } = (await req(`/${url}`)) as SocialFeedResponse

        setPermission((p) => ({ ...p, ...meta.permissions }))
        setPosts((prevPosts) =>
          pageNumber === 1 ? data : [...prevPosts, ...data]
        )
        setHasMore(meta.pagination.current_page < meta.pagination.total_pages)
        setPage(meta.pagination.current_page)
      } catch (err: any) {
        setError(err?.message || 'Failed to fetch posts')
      } finally {
        setLoading(false)
      }
    },
    [id, type]
  )

  useEffect(() => {
    if (!hasFetchedRef.current) {
      if (shouldFetch) {
        fetchPosts(1)
      }
      hasFetchedRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch])

  const likePost = async (postId: string) => {
    try {
      await req(`/social-feed/${postId}/like`, { method: 'POST' })
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? { ...post, is_liked: true, like_count: post.like_count + 1 }
            : post
        )
      )
    } catch (err: any) {
      throw new Error(err?.message || 'Failed to like post')
    }
  }

  const dislikePost = async (postId: string) => {
    try {
      await req(`/social-feed/${postId}/like`, { method: 'DELETE' })
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? { ...post, is_liked: false, like_count: post.like_count - 1 }
            : post
        )
      )
    } catch (err: any) {
      throw new Error(err?.message || 'Failed to dislike post')
    }
  }

  const createPost = async (
    postData: CreatePostData,
    entityType?: 'team' | 'schedule' | 'organization' | 'teams' | 'schedules'
  ) => {
    let url: string
    if (entityType && id) {
      url =
        entityType === 'team' || entityType === 'teams'
          ? `teams/${id}/social-feed`
          : entityType === 'schedule' || entityType === 'schedules'
          ? `schedules/${id}/social-feed`
          : `organizations/${id}/social-feed`
    } else if (type && id) {
      url =
        type === 'team'
          ? `teams/${id}/social-feed`
          : type === 'schedule'
          ? `schedules/${id}/social-feed`
          : `organizations/${id}/social-feed`
    } else {
      throw new Error('Invalid entity type or id for creating post')
    }

    const { data: newPost } = await req(`/${url}`, {
      method: 'POST',
      body: JSON.stringify(postData),
    })

    if (postData.image) {
      await req(`/social-feed/${newPost.id}/image`, {
        method: 'POST',
        body: postData.image,
      })
    }
    setPosts((prevPosts) => [newPost, ...prevPosts])
  }

  const updatePost = async (
    postData: CreatePostData,
    postId: string,
    imageChanged: boolean = false
  ) => {
    const { data: newPost } = await req(`/social-feed/${postId}`, {
      method: 'PUT',
      body: JSON.stringify(postData),
    })
    if (postData.image && imageChanged) {
      await req(`/social-feed/${newPost.id}/image`, {
        method: 'POST',
        body: postData.image,
      })
    }
    if (!postData.image && imageChanged) {
      await req(`/social-feed/${newPost.id}/image`, {
        method: 'DELETE',
      })
    }
    setPosts((prevPosts) => [newPost, ...prevPosts])
  }

  const deletePost = async (postId: string) => {
    try {
      await req(`/social-feed/${postId}`, { method: 'DELETE' })
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId))
    } catch (err: any) {
      throw new Error(err?.message || 'Failed to delete post')
    }
  }

  const createReply = async (postId: string, replyData: { body: string }) => {
    try {
      const { data: newReply } = await req(`/social-feed/${postId}/reply`, {
        method: 'POST',
        body: JSON.stringify(replyData),
      })
      // Update the post with the new reply
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? { ...post, replies: [...(post.replies || []), newReply] }
            : post
        )
      )
    } catch (err: any) {
      throw new Error(err?.message || 'Failed to create reply')
    }
  }

  const deleteReply = async (replyId: string) => {
    try {
      await req(`/social-feed-reply/${replyId}`, { method: 'DELETE' })
      // Remove the reply from the post
      setPosts((prevPosts) =>
        prevPosts.map((post) => ({
          ...post,
          replies: post.replies?.filter((reply) => reply.id !== replyId),
        }))
      )
    } catch (err: any) {
      throw new Error(err?.message || 'Failed to delete reply')
    }
  }

  const loadMore = () => {
    if (!loading && hasMore) {
      fetchPosts(page + 1)
    }
  }

  return {
    posts,
    permission,
    loading,
    error,
    hasMore,
    likePost,
    dislikePost,
    createPost,
    updatePost,
    deletePost,
    createReply,
    deleteReply,
    loadMore,
    fetchPosts,
  }
}

export default useSocialFeed
