/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { UserOutlined, ArrowUpOutlined } from '@ant-design/icons'
import React, { useMemo } from 'react'
import Text from '../Text'
import useWindowSize from 'src/hooks/useWindowSize'

type IndicatorCardProps = {
  icon?: React.ReactNode
  title: string
  value: string
  subtitle: string
  subtitleIcon?: React.ReactNode
  hideSmallIndicator?: boolean
  alternateBackgroundColor?: boolean | string
  dynamicHeight?: boolean
  hideSubtitle?: boolean
  actionButtons?: React.ReactNode
  customSubtitle?: React.ReactNode
  smallerFontForValue?: boolean
}

const IndicatorCard = ({
  icon,
  title,
  value,
  subtitle,
  subtitleIcon,
  hideSmallIndicator = false,
  alternateBackgroundColor = false,
  dynamicHeight = false,
  hideSubtitle = false,
  actionButtons,
  customSubtitle,
  smallerFontForValue = false,
}: IndicatorCardProps) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < 768, [width])

  return (
    <div
      css={css`
        display: flex;
        padding: 8px 16px;
        align-items: center;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 8px;
        background: ${alternateBackgroundColor
          ? alternateBackgroundColor
          : colors.SECONDARY_300};
        width: ${isMobile ? '100%' : 'min(500px, 100%)'};
        height: ${dynamicHeight ? 'auto' : '100px'};
        overflow: ${isMobile ? 'visible' : 'hidden'};
      `}
    >
      {icon ? (
        icon
      ) : (
        <UserOutlined
          css={css`
            font-size: ${isMobile ? '24px' : '32px'};
            color: ${colors.NEUTRAL_100};
            flex-shrink: 0;
          `}
        />
      )}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          align-self: center;
          gap: ${isMobile ? '2px' : '4px'};
          flex: 1 0 0;
          height: 100%;
          min-width: 0;
        `}
      >
        <Text
          variant={isMobile ? 'paragraphSmall' : 'paragraphMedium'}
          weight='bold'
          color={colors.PRIMARY}
        >
          {title}
        </Text>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 2px;
            width: 100%;
          `}
        >
          <Text
            variant={'heading6'}
            weight='semiBold'
            color={colors.WHITE}
            style={`
              word-break: break-word;
              overflow-wrap: break-word;
              max-width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              ${
                smallerFontForValue
                  ? `
                    font-size: 16px!important;
                    margin-bottom: 8px!important;
                  `
                  : ''
              }
            `}
          >
            {value}
          </Text>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: flex-start;
              align-self: stretch;
              gap: 4px;
              min-width: 0;
            `}
          >
            {!hideSmallIndicator ? (
              <>
                {subtitleIcon ? (
                  subtitleIcon
                ) : (
                  <ArrowUpOutlined
                    css={css`
                      color: ${colors.SUCCESS_200};
                      font-size: ${isMobile ? '10px' : '12px'};
                      flex-shrink: 0;
                    `}
                  />
                )}
              </>
            ) : null}
            {!hideSubtitle && (
              <Text
                variant='paragraphXSmall'
                weight='regular'
                color={colors.NEUTRAL_50}
              >
                {subtitle}
              </Text>
            )}
            {customSubtitle}
            {actionButtons}
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndicatorCard
