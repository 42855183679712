/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { CopyOutlined } from '@ant-design/icons'
import { TeamConflict } from '@sportninja/common/types'
import {
  heading6,
  paragraphMedium,
  paragraphXSmall,
  weightRegular,
  weightSemiBold,
} from 'src/components/css'
import { ROUTES } from '@sportninja/common/constants/app'

type TeamConflictProps = {
  key: string
  team: TeamConflict
  selectedTeamConflicts: any
  handleTeamRadioChange: (daysmartId: number, value: string) => void
  setAllTeamConflictsToCreate: () => void
  setAllTeamConflictsToIgnore: () => void
}

const TeamConflictItem = ({
  key,
  team,
  selectedTeamConflicts,
  handleTeamRadioChange,
  setAllTeamConflictsToCreate,
  setAllTeamConflictsToIgnore,
}: TeamConflictProps) => {
  return (
    <div
      key={key}
      css={css`
        display: flex;
        gap: 16px;
        padding: 8px;
        border-radius: 8px;
        background-color: ${colors.SECONDARY_400};
        margin-top: 16px;
        flex: 3;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          border: 1px solid ${colors.SECONDARY_300};
          padding: 8px;
          align-self: flex-start;
          flex: 1;
          gap: 8px;
        `}
      >
        <span
          css={css`
            color: ${colors.WHITE};
            ${heading6}
            ${weightSemiBold}
            line-height: 100%;
          `}
        >
          {team?.name}
        </span>
        <span
          css={css`
            color: ${colors.ATTENDANCE_GRAY};
            ${paragraphXSmall}
            ${weightRegular}
            line-height: 100%;
          `}
        >
          Daysmart ID: {team?.daysmart_id}
        </span>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 2;
        `}
      >
        <div
          onChange={(e) =>
            handleTeamRadioChange(
              team.daysmart_id,
              (e.target as HTMLInputElement).value
            )
          }
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            cursor: pointer;
          `}
        >
          {team.team_conflicts.map((conflict, index) => (
            <label
              key={`${conflict.uid}_${index}`}
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
                align-self: stretch;
              `}
            >
              <input
                type='radio'
                name={`conflict_${team.daysmart_id}`}
                value={conflict.uid}
                checked={
                  selectedTeamConflicts[team.daysmart_id] === conflict.uid
                }
                onChange={(e) => {
                  handleTeamRadioChange(team.daysmart_id, e.target.value)
                }}
                css={css`
                  cursor: pointer;
                  margin-right: 8px;
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                  border: 1px solid ${colors.WHITE};
                  /* change the style when checked */
                  &:checked {
                    background-color: ${colors.PRIMARY};
                    border: 1px solid ${colors.PRIMARY};
                  }
                `}
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                `}
              >
                <span
                  css={css`
                    color: ${colors.WHITE};
                    ${paragraphMedium}
                    ${weightSemiBold}
                    line-height: 100%;
                  `}
                >
                  {conflict?.name}{' '}
                  {conflict?.uid ? (
                    <a
                      css={css`
                        color: ${colors.ATTENDANCE_GRAY};
                        ${paragraphXSmall}
                        ${weightRegular}
                        line-height: 100%; /* 12px */
                        max-width: 400px;
                      `}
                      href={`${ROUTES.TEAM_ROOT}/${conflict.uid}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      ({conflict.uid})
                    </a>
                  ) : null}
                </span>
              </div>
            </label>
          ))}
          <label
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <input
              type='radio'
              name={`conflict_${team.daysmart_id}`}
              value={'998'}
              checked={selectedTeamConflicts[team.daysmart_id] === '998'}
              onChange={(e) =>
                handleTeamRadioChange(team.daysmart_id, e.target.value)
              }
              css={css`
                cursor: pointer;
                margin-right: 8px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid ${colors.WHITE};
                /* change the style when checked */
                &:checked {
                  background-color: ${colors.PRIMARY};
                  border: 1px solid ${colors.PRIMARY};
                }
              `}
            />
            <div
              css={css`
                display: flex;
                flex: 1 0 0;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <span
                css={css`
                  ${paragraphMedium}
                  ${weightRegular}
                  color: ${colors.WHITE};
                  line-height: 100%; /* 16px */
                  flex: 1 0 0;
                `}
              >
                Create a New Team
              </span>
              <div
                onClick={() => {
                  setAllTeamConflictsToCreate()
                }}
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                  display: ${selectedTeamConflicts[team.daysmart_id] === '998'
                    ? 'flex'
                    : 'none'};
                `}
              >
                <CopyOutlined
                  css={css`
                    color: ${colors.PRIMARY};
                    font-size: 12px;
                  `}
                />
                <span
                  css={css`
                    ${paragraphXSmall}
                    ${weightRegular}
                    color: ${colors.PRIMARY};
                  `}
                >
                  Apply to all
                </span>
              </div>
            </div>
          </label>
          <label
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <input
              type='radio'
              name={`conflict_${team.daysmart_id}`}
              value={'999'}
              checked={selectedTeamConflicts[team.daysmart_id] === '999'}
              onChange={(e) => {
                handleTeamRadioChange(team.daysmart_id, e.target.value)
              }}
              css={css`
                margin-right: 8px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid ${colors.WHITE};
                /* change the style when checked */
                &:checked {
                  background-color: ${colors.PRIMARY};
                  border: 1px solid ${colors.PRIMARY};
                }
                cursor: pointer;
              `}
            />
            <div
              css={css`
                display: flex;
                flex: 1 0 0;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <span
                css={css`
                  color: var(--Caution-Light, #fff);
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 100%; /* 16px */
                  flex: 1 0 0;
                `}
              >
                Ignore (do not import)
              </span>
              <div
                onClick={() => {
                  setAllTeamConflictsToIgnore()
                }}
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                  display: ${selectedTeamConflicts[team.daysmart_id] === '999'
                    ? 'flex'
                    : 'none'};
                `}
              >
                <CopyOutlined
                  css={css`
                    color: ${colors.PRIMARY};
                    font-size: 12px;
                  `}
                />
                <span
                  css={css`
                    ${paragraphXSmall}
                    ${weightRegular}
                    color: ${colors.PRIMARY};
                  `}
                >
                  Apply to all
                </span>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  )
}

export default TeamConflictItem
