/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RegistrationContext } from './context/RegistrationContext'
import { Main } from './steps'
import { RegistrationSteps } from '@sportninja/common/types'
import EmptyRegistration from './EmptyRegistration'
import LoadingRegistration from './LoadingRegistration'
import dayjs from 'dayjs'
import RegistrationNotOpen from './RegistrationNotOpen'
export const REGISTRATION_STEPS = {
  SELECT_YOUR_DIVISION: 0,
  SIGN_UP: 1,
  LOGIN: 2,
  TEAM_REGISTER: 3,
  REGISTRATION_PAYMENT: 4,
  CONFIRM_YOUR_EMAIL: 5,
  REGISTER_REVIEW: 6,
  SELECT_YOUR_ROLE: 7,
  WAIVER: 8,
}

const RegistrationInvite = ({
  login,
  logout,
  isLogged,
  genericSportProperties,
}) => {
  const { id } = useParams()
  const {
    session,
    setSession,
    registration,
    setStep,
    setZipCode,
    setCountry,
    setBirthDate,
    setDivision,
    setCouponInfo,
    setAdditionalTeamOfficials,
    userId,
    setPromoCode,
    setCredentials,
    playerType,
    teamOfficialType,
    setRole,
    validateCoupon,
    setSelectedPlayer,
    selectedTeam,
    setSelectedTeam,
    players,
    userTeams,
    types,
    setCity,
    setEmergencyEmail,
    setOtherMembershipName,
    setOtherMembershipNumber,
    // Player Info
    setFirstName,
    setLastName,
    setPassword,

    // Address
    setAddress,
    setStateProvince,

    // Parents/Guardian Info
    setParentFirstName,
    setParentLastName,
    setParentEmail,
    setParentPhone,

    // Emergency Contact Info
    setEmergencyFirstName,
    setEmergencyLastName,
    setEmergencyPhone,
    setParentRelationship,
    setDoctorName,
    setDoctorPhone,
    setAllergies,
    setMedicalConditions,

    // Preferences
    setPreferredPosition,
    setJerseyNumber,
    setDominantSide,
    setJerseySize,
    setTshirtSize,
    setShortSize,
    setHockeyUSANumber,
    setHockeyCanadaNumber,
    setNotes,
    error,
    setError,
    loading,
    setLoading,
    setId,
    fetchCountries,
    countries,
    refetchPlayers,
    refetchTeamOfficials,
    fetchTypes,
    fetchUserInfo,
    userInfo,
  } = useContext(RegistrationContext)

  const isRegistrationLive = registration?.registration?.is_live

  const sessionIdFromUrl =
    new URLSearchParams(window.location.search).get(
      'registration_session_uid'
    ) ||
    new URLSearchParams(window.location.search).get('registration_session_id')
  const [registrationPeriodError, setRegistrationPeriodError] = useState({
    message: '',
    isBeforeStart: false,
    isAfterEnd: false,
  })

  useEffect(() => {
    if (!isLogged) {
      return
    }
    fetchUserInfo()
  }, [isLogged])

  useEffect(() => {
    if (!types || types === null) {
      fetchTypes()
    }
  }, [fetchTypes, types])

  useEffect(() => {
    if (countries.length === 0) {
      fetchCountries()
    }
  }, [countries.length])

  // Effects
  useEffect(() => {
    if (userId) {
      refetchPlayers()
    }
  }, [refetchPlayers, userId])

  useEffect(() => {
    if (userId) {
      refetchTeamOfficials()
    }
  }, [refetchTeamOfficials, userId])

  useEffect(() => {
    if (isRegistrationLive) {
      return
    }
    const startDate = dayjs.utc(registration?.registration?.starts_at)
    const endDate = dayjs.utc(registration?.registration?.ends_at)
    const currentDate = dayjs.utc()
    // If its outside the registration period, set an error
    if (currentDate.isBefore(startDate) || currentDate.isAfter(endDate)) {
      setRegistrationPeriodError({
        message: currentDate.isBefore(startDate)
          ? 'Registration is not currently open. Please check back later for updates.'
          : 'Registration is closed. Please check back next season for updates.',
        isBeforeStart: currentDate.isBefore(startDate),
        isAfterEnd: currentDate.isAfter(endDate),
      })
    } else {
      setRegistrationPeriodError({
        message: '',
        isBeforeStart: false,
        isAfterEnd: false,
      })
    }
  }, [registration])

  useEffect(() => {
    setId(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (session?.team_id && !selectedTeam && userTeams) {
      const sTeam = userTeams?.find((t) => t?.uid === session?.team_id)
      if (sTeam) {
        setSelectedTeam(sTeam)
      }
    }
  }, [userTeams, session, selectedTeam])

  useEffect(() => {
    const createSession = async () => {
      try {
        setError(null)
        setLoading(true)
        const { data } = await req(`/registration/${id}/session/`, {
          method: 'POST',
        })
        localStorage.setItem(
          `registration_session_${id}`,
          JSON.stringify(data?.id)
        )
        setSession(data)
      } catch (e) {
        const errorMessage = new Error(
          `Registration not found for id: ${id} | e: ${e?.message}`
        )
        setError(errorMessage)
      } finally {
        setLoading(false)
      }
    }
    const setSessionData = async (data) => {
      const step = data?.registration_step
      switch (step) {
        case RegistrationSteps.SELECT_YOUR_DIVISION:
          // If we already selected the division, we should just go to the next step
          if (data?.division_id && userId && userInfo?.is_validated) {
            setStep(REGISTRATION_STEPS.SELECT_YOUR_ROLE)
            break
          }
          setStep(REGISTRATION_STEPS.CONFIRM_YOUR_EMAIL)
          break
        case RegistrationSteps.SELECT_YOUR_ROLE:
          setStep(REGISTRATION_STEPS.SELECT_YOUR_ROLE)
          break
        case RegistrationSteps.TEAM_REGISTER:
          setStep(REGISTRATION_STEPS.TEAM_REGISTER)
          break
        case RegistrationSteps.REGISTRATION_PAYMENT:
          setStep(REGISTRATION_STEPS.REGISTRATION_PAYMENT)
          break
        case RegistrationSteps.VALIDATE_EMAIL:
          setStep(REGISTRATION_STEPS.CONFIRM_YOUR_EMAIL)
          break
        case RegistrationSteps.CONFIRM:
          setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
          break
        case RegistrationSteps.WAIVER:
          setStep(REGISTRATION_STEPS.WAIVER)
          break
        default:
          setStep(REGISTRATION_STEPS.SELECT_YOUR_DIVISION)
          break
      }
      if (data?.player_id) {
        setSelectedPlayer(players?.find((p) => p?.id === data?.player_id))
      }
      if (String(data?.registration_type_id) === String(playerType?.id)) {
        setRole(playerType)
      } else if (
        String(data?.registration_type_id) === String(teamOfficialType?.id)
      ) {
        setRole(teamOfficialType)
      }
      if (data?.coupon_code) {
        setCouponInfo(data?.coupon_code)
        setPromoCode(data?.coupon_code?.code)
        await validateCoupon(data?.coupon_code?.code, data?.division_id)
      }
      if (data?.postal_code) {
        setZipCode(data?.postal_code)
      }
      if (data?.country_code) {
        setCountry(data?.country_code)
      }
      if (data?.division_id) {
        const selectedDivision = registration?.divisions?.find((d) => {
          return d?.schedule_id === data?.division_id
        })
        setDivision(selectedDivision)
      }
      // We should set the additional team officials if they exist
      // the first one is the main team official, the logged in user
      if (data?.team_official_array && data?.team_official_array.length > 1) {
        const teamOfficials = data?.team_official_array
          .map((official) => {
            return {
              email: official?.email,
              firstName: official?.name_first,
              lastName: official?.name_last,
              teamOfficialTypeId: official?.team_official_type_id,
              isTreasurer: official?.is_treasurer,
              phone: official?.phone,
            }
          })
          .slice(1)
        setAdditionalTeamOfficials(teamOfficials)
      }
      if (data?.player_array && data?.player_array.length > 0) {
        const player = data?.player_array[0]
        setFirstName(player?.name_first)
        setLastName(player?.name_last)
        setPassword(player?.password)

        setAddress(player?.address)
        setZipCode(player?.postal_code)
        setStateProvince(player?.state_province)
        setCountry(player?.country)
        setBirthDate(player?.birth_date?.split('T')[0] || null)

        setParentFirstName(player?.parent_first_name)
        setParentLastName(player?.parent_last_name)
        setParentEmail(player?.parent_email)
        setParentPhone(player?.parent_phone_number)

        setEmergencyFirstName(player?.emergency_first_name)
        setEmergencyLastName(player?.emergency_last_name)
        setEmergencyPhone(player?.emergency_phone_number)
        setParentRelationship(player?.emergency_relationship)
        setDoctorName(player?.doctor_name)
        setDoctorPhone(player?.doctor_phone_number)
        setAllergies(player?.known_allergies)
        setMedicalConditions(player?.medical_conditions)

        setPreferredPosition(player?.player_type_id)
        setJerseyNumber(player?.preferred_jersey_number)
        setDominantSide(player?.preferred_dominant_side)
        setJerseySize(player?.preferred_jersey_size)
        setTshirtSize(player?.preferred_tshirt_size)
        setShortSize(player?.preferred_short_size)
        setHockeyUSANumber(player?.hockey_usa_number)
        setHockeyCanadaNumber(player?.hockey_canada_number)
        setNotes(player?.additional_comments_notes)

        setCity(player?.city)
        setEmergencyEmail(player?.emergency_email)
        setOtherMembershipName(player?.custom_membership_name)
        setOtherMembershipNumber(player?.custom_membership_number)
      }
    }
    const fetchPreviousSession = async (sessionId) => {
      try {
        setError(null)
        setLoading(true)
        const { data } = await req(`/registration/session/${sessionId}`)
        if (
          data?.root_schedule_id !==
          registration?.registration?.root_schedule_id
        ) {
          localStorage.removeItem(`registration_session_${id}`)
          createSession()
          return
        }
        setSession(data)
        setSessionData(data)
        if (data?.bearer_token && data?.user) {
          setCredentials(data?.bearer_token, data?.user)
        }
      } catch (e) {
        localStorage.removeItem(`registration_session_${id}`)
        createSession()
      } finally {
        setLoading(false)
      }
    }
    if (
      sessionIdFromUrl &&
      !session &&
      registration &&
      types &&
      (!isLogged || (isLogged && players !== null))
    ) {
      fetchPreviousSession(sessionIdFromUrl)
    } else if (
      registration &&
      !session &&
      (!isLogged || (isLogged && players !== null)) &&
      types
    ) {
      const previousSession = localStorage.getItem(`registration_session_${id}`)
      if (!previousSession) {
        createSession()
      } else {
        fetchPreviousSession(previousSession.split('"').join(''))
      }
    }
  }, [
    id,
    registration,
    session,
    userId,
    sessionIdFromUrl,
    types,
    players,
    playerType,
    teamOfficialType,
  ])

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        overflow-y: scroll;
        /* Adjustment for the Background issue */
        background-color: ${colors.NEUTRAL_900};
      `}
    >
      {loading ? <LoadingRegistration /> : null}
      {error ? <EmptyRegistration errorMessage={error?.message} /> : null}
      {registrationPeriodError && registrationPeriodError?.isBeforeStart ? (
        <RegistrationNotOpen
          startsAt={registration?.registration?.starts_at}
          name={registration?.registration?.name}
        />
      ) : null}
      {registrationPeriodError &&
      registrationPeriodError?.message !== '' &&
      registrationPeriodError?.isAfterEnd ? (
        <EmptyRegistration
          title='Registration is closed'
          errorMessage={registrationPeriodError?.message}
        />
      ) : null}
      {!loading &&
      !error &&
      registrationPeriodError?.message === '' &&
      session ? (
        <Main />
      ) : null}
    </div>
  )
}

export default RegistrationInvite
