/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import Text from 'src/components/ts-components/Text'
import { useRegistrationSetupContext } from '../../context'
import SelectMultiInput from 'src/components/ts-components/SelectMultiInput'
import { SwitchInput } from 'src/components/ts-components/SwitchInput'
import { Alert } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import colors from '@sportninja/common/constants/appColors'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import { Link } from 'react-router-dom'
import { ROUTES } from '@sportninja/common/constants/app'

const Waiver = () => {
  const { state, setState, apiWaivers, registrationTypes } =
    useRegistrationSetupContext()

  return (
    <div
      css={css`
        display: flex;
        padding: 16px;
        gap: 16px;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text variant='captionLarge' weight='semiBold'>
          Waivers
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 24px;
          align-self: stretch;
        `}
      >
        <SwitchInput
          title='Require Waiver Signature'
          subtitle={
            ' Participants Will Be Required To Sign The Waiver(S) During The Registration Process.'
          }
          value={state.show_waiver}
          onChange={(value) => {
            setState({ ...state, show_waiver: value })
          }}
        />
      </div>
      {state?.show_waiver ? (
        <SelectMultiInput
          label='Select Waiver'
          options={
            apiWaivers?.map((waiver) => ({
              label: waiver.name,
              value: waiver.id,
            })) || []
          }
          value={state.waivers}
          onChange={(value) => {
            setState({
              ...state,
              waivers: value,
            })
          }}
          fieldErrors={state.fieldErrors}
          customError={state.customError}
          name='waivers'
        />
      ) : null}
      {state?.show_waiver ? (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            width: 100%;
          `}
        >
          <Alert
            message={
              <Text
                variant='paragraphSmall'
                weight='regular'
                color={colors.PRIMARY}
                style={'width: 100%; margin-left: 8px;'}
              >
                Waivers Can Be Added From Account Settings &gt;{' '}
                <Link
                  to={`${ROUTES.GENERAL_SETTINGS}?origin=waiver`}
                  target='_blank'
                >
                  Waivers Section
                </Link>
                .
              </Text>
            }
            type='warning'
            showIcon
            icon={
              <WarningFilled
                css={css`
                  font-size: 20px;
                  color: ${colors.PRIMARY};
                `}
              />
            }
            css={css`
              width: 100%;
            `}
          />
        </div>
      ) : null}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;
          opacity: 0;
          cursor: default;
        `}
      >
        <SelectInput
          style={css`
            opacity: 0;
            cursor: default;
          `}
          disabled={true}
          label='Currency'
          options={
            registrationTypes?.currency
              ?.sort((a, b) => {
                if (a.name === 'USD') {
                  return -1
                }
                if (b.name === 'USD') {
                  return 1
                }
                if (a.name === 'CAD') {
                  return -1
                }
                if (b.name === 'CAD') {
                  return 1
                }
                return 0
              })
              ?.map((currency) => ({
                label: currency.name,
                value: currency.id,
              })) || []
          }
          value={state.currency}
          onChange={(value) => {
            setState({
              ...state,
              currency: value,
            })
          }}
          fieldErrors={state.fieldErrors}
          customError={state.customError}
          name='currency'
        />
      </div>
    </div>
  )
}

export default Waiver
