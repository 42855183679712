/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React from 'react'
import Text from 'src/components/ts-components/Text'
import colors from '@sportninja/common/constants/appColors'

interface ErrorStateViewProps {
  imageSrc?: string
  title?: string
  subtitle?: string
  buttonText?: string
  height?: string
}

const styles = {
  container: (height: string) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: ${height};
    gap: 16px;
    padding: 24px;
    text-align: center;
  `,
  image: css`
    width: 240px;
    height: 220px;
    object-fit: contain;
  `,
  button: css`
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px 16px;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  `,
}

const ErrorStateView: React.FC<ErrorStateViewProps> = ({
  imageSrc = '/images/empty_registration.png',
  title = 'LOOKS LIKE THERE WAS AN ERROR',
  subtitle = 'Please try again later or contact support if the problem persists.',
  buttonText = 'Try Again',
  height = '60vh',
}) => {
  const handleTryAgainClick = React.useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <div css={styles.container(height)}>
      <img src={imageSrc} alt='empty-state' css={styles.image} />

      <Text variant='heading5' weight='semiBold' color={colors.NEUTRAL_0}>
        {title}
      </Text>

      {subtitle && (
        <Text
          variant='paragraphMedium'
          weight='regular'
          color={colors.NEUTRAL_60}
        >
          {subtitle}
        </Text>
      )}

      <button css={styles.button} onClick={handleTryAgainClick}>
        <Text variant='paragraphSmall' weight='semiBold' color={colors.PRIMARY}>
          {buttonText}
        </Text>
      </button>
    </div>
  )
}

// Generic version that can be used for other empty states
export const GenericEmptyState: React.FC<
  Omit<ErrorStateViewProps, 'setIsRegistrationFormOpen' | 'setRegistration'> & {
    onButtonClick?: () => void
  }
> = ({
  imageSrc,
  title,
  subtitle,
  buttonText,
  height = '400px',
  onButtonClick,
}) => (
  <div css={styles.container(height)}>
    <img src={imageSrc} alt='empty-state' css={styles.image} />

    <Text variant='heading5' weight='semiBold' color={colors.NEUTRAL_0}>
      {title}
    </Text>

    {subtitle && (
      <Text
        variant='paragraphMedium'
        weight='regular'
        color={colors.NEUTRAL_60}
      >
        {subtitle}
      </Text>
    )}

    {buttonText && onButtonClick && (
      <button css={styles.button} onClick={onButtonClick}>
        <Text variant='paragraphSmall' weight='semiBold' color={colors.PRIMARY}>
          {buttonText}
        </Text>
      </button>
    )}
  </div>
)

export default ErrorStateView

// Usage examples:
/*
// Registration empty state
<ErrorStateView
  setIsRegistrationFormOpen={setIsRegistrationFormOpen}
  setRegistration={setRegistration}
/>

// Custom empty state
<ErrorStateView
  imageSrc="/images/no_results.png"
  title="No Results Found"
  subtitle="Try adjusting your search criteria"
  buttonText="Clear Filters"
  height="400px"
/>

// Generic empty state
<GenericEmptyState
  imageSrc="/images/no_data.png"
  title="No Data Available"
  subtitle="Start by adding some data"
  buttonText="Add Data"
  onButtonClick={() => console.log('Add data clicked')}
/>
*/
