/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Flex } from '../../../components/Layout'
import { ROUND_TYPE } from './constants'
import Pool from './Pool'

/**
 * Determines the title to display for a bracket round based on tournament type and round position.
 *
 * @param {boolean} isFinals - Whether the current round is the finals
 * @param {boolean} isSemifinals - Whether the current round is the semifinals
 * @param {boolean} isSingleElimination - Whether the tournament is single elimination
 * @param {boolean} isDoubleElimination - Whether the tournament is double elimination
 * @param {string} defaultTitle - The default title to display if no special case applies
 * @returns {string} - The formatted title to display for the current round
 */
const getBracketRoundTitle = (
  isFinals,
  isSemifinals,
  isSingleElimination,
  isDoubleElimination,
  defaultTitle
) => {
  if (isDoubleElimination) {
    if (isFinals) {
      return 'Finals (If Necessary)'
    } else if (isSemifinals) {
      return 'Finals'
    }
  }
  if (isFinals) {
    return 'FINALS'
  } else if (isSemifinals) {
    return 'SEMIFINALS'
  } else {
    return defaultTitle
  }
}

const Round = ({
  round,
  roundNumber,
  selected,
  setSelected,
  stats,
  shouldDisplayScoreInsteadOfPoints = false,
  hasLowerPoolsOnAnyRounds = false,
  isSingleOrDoubleElimination = false,
  isFinals,
  isSemifinals,
  isSingleElimination = false,
  isDoubleElimination = false,
}) => {
  const { pools, lowerPools, upperPools } = round
  const poolData = pools || []
  let upperPoolData = []
  let lowerPoolData = []
  if (upperPools.length === 0 && lowerPools.length === 0) {
    upperPoolData = poolData
  } else {
    upperPoolData = upperPools || []
    lowerPoolData = lowerPools || []
  }

  return (
    <Flex column>
      <Flex
        noFlex
        alignItems='center'
        justifyContent='center'
        className='round-name'
        css={css`
          font-weight: 500;
          font-size: 12px;
          text-transform: uppercase;
          margin-bottom: 16px;
          height: 28px;
          background-color: ${colors.HEADER};
        `}
      >
        {/* {round.round_type_id === 1
          ? round.name_full
          : ROUND_TYPE[round.round_type_id]} */}
        {getBracketRoundTitle(
          isFinals,
          isSemifinals,
          isSingleElimination,
          isDoubleElimination,
          round.round_type_id === 1
            ? round.name_full
            : ROUND_TYPE[round.round_type_id]
        )}
      </Flex>
      <Flex />
      <Flex column justifyContent='space-evenly' className='round'>
        {upperPoolData.map((pool) => {
          return (
            <Pool
              pool={pool}
              key={pool.id}
              roundNumber={roundNumber}
              roundType={round.round_type_id}
              setSelected={setSelected}
              selected={selected}
              stats={stats}
              round={round}
              isLowerPool={false}
              shouldDisplayScoreInsteadOfPoints={
                shouldDisplayScoreInsteadOfPoints
              }
              hasLowerPool={hasLowerPoolsOnAnyRounds}
              isSingleOrDoubleElimination={isSingleOrDoubleElimination}
            />
          )
        })}
      </Flex>
      <Flex />
      <Flex
        column
        justifyContent='space-evenly'
        className='round'
        css={css`
          margin-top: 16px;
        `}
      >
        {lowerPoolData.map((pool) => {
          return (
            <Pool
              pool={pool}
              key={pool.id}
              roundNumber={roundNumber}
              roundType={round.round_type_id}
              setSelected={setSelected}
              selected={selected}
              stats={stats}
              round={round}
              isLowerPool={true}
              shouldDisplayScoreInsteadOfPoints={
                shouldDisplayScoreInsteadOfPoints
              }
              hasLowerPool={hasLowerPoolsOnAnyRounds}
              isSingleOrDoubleElimination={isSingleOrDoubleElimination}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default Round
