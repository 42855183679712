/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ManOutlined,
  CalendarOutlined,
} from '@ant-design/icons'
import colors from '@sportninja/common/constants/appColors'
// import { formatCurrency } from '@sportninja/common/utils/utils'
import Text from 'src/components/ts-components/Text'
import React from 'react'
import { formatCurrency } from '@sportninja/common/utils/utils'
import { Division } from '@sportninja/common/types'

/**
 * Props interface for the DivisionCard component
 */
interface DivisionCardProps {
  division: Division
  isSelected: boolean
  currencyName?: string
  onSelect: (division: Division) => void
  isTeamRegistrationEnabled: boolean
  isPlayerRegistrationEnabled: boolean
  hasSelectedDivision: boolean
}

/**
 * Styles for the DivisionCard component
 */
const styles = {
  card: (
    isSelected: boolean,
    fullDivision: boolean,
    hasSelectedDivision: boolean
  ) => css`
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 16px;
    border: ${isSelected
      ? `2px solid ${colors.PRIMARY}`
      : `1px solid ${colors.NEUTRAL_500}`};
    cursor: ${fullDivision ? 'not-allowed' : 'pointer'};
    opacity: ${fullDivision
      ? 0.7
      : hasSelectedDivision && !isSelected
      ? 0.5
      : 1};
    background-color: ${isSelected ? colors.NEUTRAL_700 : 'transparent'};
    min-height: 150px;
  `,

  infoContainer: css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    /* min-height: 80px; */
  `,

  detailsColumn: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: flex-start;
  `,

  costColumn: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
  `,

  statusContainer: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    flex: 1;
    flex-wrap: wrap;
    align-self: flex-end;
  `,

  detailsRow: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
  `,

  divider: css`
    border-bottom: 1px solid ${colors.NEUTRAL_500};
    width: 100%;
    margin: 8px 0;
  `,

  statusBadge: (backgroundColor: string, borderColor: string) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 8px 8px;
    border-radius: 8px;
    background-color: ${backgroundColor};
    height: 30px;
    align-self: flex-end;
    border: 1px solid ${borderColor};
  `,
}

/**
 * DivisionCard component displays information about a division including
 * its details, costs, and availability status
 */
export const DivisionCard: React.FC<DivisionCardProps> = ({
  division,
  isSelected,
  currencyName,
  onSelect,
  isTeamRegistrationEnabled,
  isPlayerRegistrationEnabled,
  hasSelectedDivision,
}) => {
  const showExistingRegistrationsTeams =
    division.show_existing_registrations_teams
  // Calculate division statistics
  const totalSpots = division.max_teams
  const teamRegistered = division.team_count_enrolled
  const spotsLeft = totalSpots - teamRegistered
  const areLessThanOneThirdSpotsLeft = spotsLeft < totalSpots / 3
  const fullDivision =
    division?.max_teams === null || division?.max_teams === undefined
      ? false
      : spotsLeft <= 0 && !isPlayerRegistrationEnabled

  /**
   * Handle card selection
   */
  const handleClick = () => {
    if (fullDivision) {
      return
    }
    onSelect(division)
  }

  /**
   * Render the status badges for the division
   */
  const renderStatusBadges = () => {
    if (fullDivision) {
      return (
        <>
          <div
            css={styles.statusBadge(
              'rgba(121, 123, 126, 0.20)',
              colors.NEUTRAL_500
            )}
          >
            <CheckCircleOutlined style={{ color: colors.WHITE }} />
            <Text
              variant='paragraphXSmall'
              color={colors.WHITE}
              weight='regular'
            >
              Division Full
            </Text>
          </div>
          <div
            css={styles.statusBadge(
              'rgba(121, 123, 126, 0.20)',
              colors.NEUTRAL_500
            )}
          >
            <ClockCircleOutlined style={{ color: colors.WHITE }} />
            <Text
              variant='paragraphXSmall'
              color={colors.WHITE}
              weight='regular'
            >
              0 Spots Left
            </Text>
          </div>
        </>
      )
    }

    return (
      <>
        {division.team_count_enrolled > 0 && showExistingRegistrationsTeams && (
          <div
            css={styles.statusBadge(
              'rgba(57, 187, 85, 0.20)',
              colors.SUCCESS_200
            )}
          >
            <CheckCircleOutlined style={{ color: colors.WHITE }} />
            <Text
              variant='paragraphXSmall'
              color={colors.WHITE}
              weight='regular'
            >
              {division.team_count_enrolled} Teams{' '}
              {areLessThanOneThirdSpotsLeft && spotsLeft > 0
                ? 'Regd.'
                : 'Registered'}
            </Text>
          </div>
        )}
        {areLessThanOneThirdSpotsLeft &&
          spotsLeft > 0 &&
          showExistingRegistrationsTeams && (
            <div
              css={styles.statusBadge(
                'rgba(223, 66, 66, 0.20)',
                colors.ERROR_200
              )}
            >
              <ClockCircleOutlined style={{ color: colors.WHITE }} />
              <Text
                variant='paragraphXSmall'
                color={colors.WHITE}
                weight='regular'
              >
                {spotsLeft} Spots Left
              </Text>
            </div>
          )}
      </>
    )
  }

  return (
    <div
      onClick={handleClick}
      css={styles.card(isSelected, fullDivision, hasSelectedDivision)}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1;
          `}
        >
          {isSelected && (
            <CheckCircleOutlined
              css={css`
                color: ${colors.PRIMARY};
                font-size: 20px;
              `}
            />
          )}
          <Text
            variant='heading5'
            weight='semiBold'
            color={isSelected ? colors.PRIMARY : colors.WHITE}
          >
            {division.name}
          </Text>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            gap: 8px;
            flex-wrap: wrap;
          `}
        >
          {renderStatusBadges()}
        </div>
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
          margin-top: 8px;
        `}
      >
        <div css={styles.detailsRow}>
          <ManOutlined
            css={css`
              color: ${colors.PRIMARY};
              font-size: 16px;
            `}
          />
          <Text variant='paragraphXSmall' color={colors.WHITE} weight='medium'>
            {division?.division_gender?.name}
          </Text>
        </div>
        <div css={styles.detailsRow}>
          <ClockCircleOutlined
            css={css`
              color: ${colors.PRIMARY};
              font-size: 14px;
            `}
          />
          <Text variant='paragraphXSmall' color={colors.WHITE} weight='medium'>
            {division?.division_age?.name}
          </Text>
        </div>
        <div
          css={[
            styles.detailsRow,
            css`
              display: ${division?.day_of_week?.name === 'N/A'
                ? 'none'
                : 'flex'};
            `,
          ]}
        >
          <CalendarOutlined
            css={css`
              color: ${colors.PRIMARY};
              font-size: 16px;
            `}
          />
          <Text variant='paragraphXSmall' color={colors.WHITE} weight='medium'>
            {division?.day_of_week?.name}
          </Text>
        </div>
      </div>

      <div css={styles.divider} />

      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          gap: 8px;
        `}
      >
        {/* Left side - Team costs */}
        {isTeamRegistrationEnabled && (
          <div
            css={css`
              display: flex;
              gap: 16px;
              align-items: center;
              flex: 1;
              ${isPlayerRegistrationEnabled && 'max-width: 50%'};
            `}
          >
            <div>
              <Text
                variant='paragraphXSmall'
                color={colors.NEUTRAL_50}
                weight='regular'
              >
                Team Fee
              </Text>
              <Text
                variant='paragraphSmall'
                // color={isSelected ? colors.PRIMARY : colors.NEUTRAL_0}
                color={colors.PRIMARY}
                weight='bold'
              >
                {division.team_cost > 0
                  ? formatCurrency(division.team_cost, currencyName)
                  : 'No Fee'}
              </Text>
            </div>
            {division.deposit_required && division.deposit > 0 && (
              <div>
                <Text
                  variant='paragraphXSmall'
                  color={colors.NEUTRAL_50}
                  weight='regular'
                >
                  Team Deposit
                </Text>
                <Text
                  variant='paragraphSmall'
                  color={colors.NEUTRAL_0}
                  weight='bold'
                >
                  {formatCurrency(division.deposit, currencyName)}
                </Text>
              </div>
            )}
          </div>
        )}

        {/* Vertical Separator */}
        {isTeamRegistrationEnabled && isPlayerRegistrationEnabled && (
          <div
            css={css`
              width: 1px;
              height: 40px;
              background-color: ${colors.NEUTRAL_500};
              /* margin: 0 8px; */
              flex-shrink: 0;
            `}
          />
        )}

        {/* Player costs */}
        {isPlayerRegistrationEnabled && (
          <div
            css={css`
              display: flex;
              gap: 16px;
              align-items: center;
              flex: 1;
              ${isTeamRegistrationEnabled && 'max-width: 50%'};
              ${isTeamRegistrationEnabled && 'margin-left: 8px'};
            `}
          >
            <div>
              <Text
                variant='paragraphXSmall'
                color={colors.NEUTRAL_50}
                weight='regular'
              >
                Player Fee
              </Text>
              <Text
                variant='paragraphSmall'
                // color={isSelected ? colors.PRIMARY : colors.NEUTRAL_0}
                color={colors.PRIMARY}
                weight='bold'
              >
                {Number(division.player_cost) > 0
                  ? formatCurrency(Number(division.player_cost), currencyName)
                  : 'N/A'}
              </Text>
            </div>
            {division.player_deposit_required &&
              division.player_deposit != null &&
              division.player_deposit > 0 && (
                <div>
                  <Text
                    variant='paragraphXSmall'
                    color={colors.NEUTRAL_50}
                    weight='regular'
                  >
                    Player Deposit
                  </Text>
                  <Text
                    variant='paragraphSmall'
                    color={colors.NEUTRAL_0}
                    weight='bold'
                  >
                    {formatCurrency(division.player_deposit, currencyName)}
                  </Text>
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  )
}

export default DivisionCard
