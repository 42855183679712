/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'

import { font, zIndex } from '../../components/css'
import { Flex } from '../../components/Layout'

const ReprocessingSettingsOverlay = ({ scheduleReprocessingListener }) => {
  if (!scheduleReprocessingListener) {
    return false
  }

  return (
    <Flex
      column
      alignItems='center'
      css={css`
        position: fixed;
        top: calc(100vh - 100%);
        left: 0;
        right: 0;
        bottom: 0;
        z-index: ${zIndex.base};
        /* fill the screen with a blur overlay */
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(2px);
      `}
    >
      <Flex
        column
        alignItems='center'
        noFlex
        css={css`
          background-color: ${colors.HEADER};
          border-radius: 4px;
          padding: 30px;
          box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.2);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          h2 {
            ${font.title}
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 24px;
          }
        `}
      >
        <h2>Reprocessing {t('common:competition')} Statistics</h2>
        <p>This can take some time to complete.</p>
        <p
          css={css`
            margin-top: 4px;
          `}
        >
          It&apos;s safe to leave this page.
        </p>
        <Flex
          noFlex
          alignItems='center'
          css={css`
            position: relative;
            height: 32px;
            border: 2px solid white;
            border-radius: 2px;
            padding: 2px;
            margin: 24px 0 0;
            width: 100%;
          `}
        >
          <div
            className='pending'
            css={css`
              border-radius: 2px;
              width: ${scheduleReprocessingListener?.progress || 2}%;
              height: 100%;
              margin: 0 1px;
              background-color: white;

              @keyframes fade {
                0% {
                  opacity: 1;
                }
                100% {
                  opacity: 0.3;
                }
              }

              &.pending {
                animation-name: fade;
                animation-duration: 1s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-direction: alternate;
              }
            `}
          />
          <div
            css={css`
              position: absolute;
              left: 0;
              right: 0;
              text-align: center;
              color: white;
              mix-blend-mode: difference;
            `}
          >
            {scheduleReprocessingListener?.progress || 0}%
          </div>
        </Flex>
        {typeof scheduleReprocessingListener?.count === 'number' && (
          <div
            css={css`
              margin-top: 12px;
              text-align: center;
            `}
          >
            Processing {scheduleReprocessingListener.count} games
          </div>
        )}
      </Flex>
    </Flex>
  )
}

export default ReprocessingSettingsOverlay
