/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Empty } from 'antd'
import colors from '@sportninja/common/constants/appColors'

export const EmptySearch = () => {
  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 40px;
        background: ${colors.SECONDARY_500};
        border-radius: 8px;
      `}
    >
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <span style={{ color: colors.NEUTRAL_100 }}>
            No Data Found Matching your Search Criteria
          </span>
        }
      />
    </div>
  )
}
