import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { Image } from 'antd'
import React, { useCallback } from 'react'
import styled from 'styled-components'

interface ManageRefreshmentsModalContentProps {
  players?: any[]
  gameId?: string
  rosterId?: string
  refetch?: () => void
  setIsManageRefreshmentsModalOpen?: (isOpen: boolean) => void
  readAttendanceInformation?: () => void
}

const Container = styled.div`
  background-color: ${colors.SECONDARY_300};
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  max-height: 500px;
  min-height: 500px;
  overflow-y: auto;
`

const Table = styled.table`
  width: 100%;
`

const TableHead = styled.thead`
  text-align: left;
  background-color: ${colors.SECONDARY_200};
  color: ${colors.NEUTRAL_50};
  font-size: 14px;
  line-height: 20px;
  /* These styles are not being applied to the table header cells */
  /* We need to target the th elements directly */
  & th {
    padding: 8px;
    /* only th */
    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
    }
  }
`

const TableRow = styled.tr<{
  isDuplicate?: boolean
  clickable?: boolean
}>`
  color: ${(props) => (props.isDuplicate ? colors.ERROR_200 : 'inherit')};
  &:nth-child(even) {
    background-color: ${colors.SECONDARY_200};
  }
`

const TableCell = styled.td<{
  isDuplicate?: boolean
  clicable?: boolean
}>`
  padding: 8px;
  overflow: hidden;
  color: ${(props) =>
    props.isDuplicate ? colors.ERROR_200 : colors.NEUTRAL_0};
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.2px; /* 145% */
  text-align: center;
  vertical-align: middle;
  cursor: ${(props) => (props.clicable ? 'pointer' : 'default')};
`

const PlayerImageWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  overflow: hidden;
  border-radius: 50%;
  background-color: ${colors.NEUTRAL_0};
`

const PlayerPositionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const ManageRefreshmentsModalContent: React.FC<
  ManageRefreshmentsModalContentProps
> = ({
  players = [],
  gameId,
  rosterId,
  refetch,
  setIsManageRefreshmentsModalOpen,
  readAttendanceInformation,
}) => {
  // const getDuplicateJerseyNumbers = () => {
  //   const jerseyNumbers = players.map((player) => player?.player_number)
  //   return jerseyNumbers.filter(
  //     (number, index) => jerseyNumbers.indexOf(number) !== index
  //   )
  // }

  // const duplicateJerseyNumbers = getDuplicateJerseyNumbers()

  const updateRefreshments = useCallback(
    async (playerId: string) => {
      try {
        await req(
          `/games/${gameId}/rosters/${rosterId}/players/${playerId}/attendance`,
          {
            method: 'PUT',
            body: JSON.stringify({
              bringing_refreshments: true,
            }),
          }
        )
        refetch?.()
        readAttendanceInformation?.()
        setIsManageRefreshmentsModalOpen?.(false)
      } catch (e) {
        console.error(e)
      }
    },
    [
      gameId,
      rosterId,
      refetch,
      readAttendanceInformation,
      setIsManageRefreshmentsModalOpen,
    ]
  )

  const updateRefreshmentsOfficial = useCallback(
    async (teamOfficialId: string) => {
      try {
        await req(
          `/games/${gameId}/rosters/${rosterId}/team-officials/${teamOfficialId}/attendance`,
          {
            method: 'PUT',
            body: JSON.stringify({
              bringing_refreshments: true,
            }),
          }
        )
        refetch?.()
        readAttendanceInformation?.()
        setIsManageRefreshmentsModalOpen?.(false)
      } catch (e) {
        console.error(e)
      }
    },
    [
      gameId,
      rosterId,
      refetch,
      readAttendanceInformation,
      setIsManageRefreshmentsModalOpen,
    ]
  )

  if (players.length === 0) {
    return <Container>No players available.</Container>
  }

  return (
    <Container>
      <Table>
        <TableHead>
          <tr>
            <th>#</th>
            <th>Player Name</th>
            <th>Position</th>
          </tr>
        </TableHead>
        <tbody>
          {players.map((player) => (
            <TableRow
              clickable={true}
              onClick={() => {
                if (player?.player_type) {
                  updateRefreshments(player.id)
                } else {
                  updateRefreshmentsOfficial(player.id)
                }
              }}
              key={player.id}
              // isDuplicate={duplicateJerseyNumbers.includes(
              //   player?.player_number
              // )}
            >
              <TableCell style={{ textAlign: 'center', borderWidth: 1 }}>
                <TableCell
                  clicable={true}
                  onClick={() => {
                    if (player?.player_type) {
                      updateRefreshments(player.id)
                    } else {
                      updateRefreshmentsOfficial(player.id)
                    }
                  }}
                >
                  {player?.player_number || '#'}
                </TableCell>
              </TableCell>
              <TableCell
                clicable={true}
                onClick={() => {
                  if (player?.player_type) {
                    updateRefreshments(player.id)
                  } else {
                    updateRefreshmentsOfficial(player.id)
                  }
                }}
                // isDuplicate={duplicateJerseyNumbers.includes(
                //   player?.player_number
                // )}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <PlayerImageWrapper>
                    <Image
                      src={player.image}
                      alt={player?.full_name}
                      preview={false}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      fallback={
                        '/images/generic_placeholders/placeholder_player.png'
                      }
                    />
                  </PlayerImageWrapper>
                  {player?.name_first} {player?.name_last}
                </div>
              </TableCell>
              <TableCell
                clicable={true}
                onClick={() => {
                  if (player?.player_type) {
                    updateRefreshments(player.id)
                  } else {
                    updateRefreshmentsOfficial(player.id)
                  }
                }}
              >
                <PlayerPositionContainer>
                  <div>{player?.player_type?.name_full || 'Team Official'}</div>
                </PlayerPositionContainer>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default ManageRefreshmentsModalContent
