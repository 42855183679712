/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Text from 'src/components/ts-components/Text'
import { useEffect, useState } from 'react'

type TimeLeft = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const RegistrationNotOpen = ({
  startsAt,
  name,
}: {
  startsAt: string
  name: string
}) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  useEffect(() => {
    const targetDate = new Date(startsAt)

    const calculateTimeLeft = () => {
      const now = new Date()
      const difference = targetDate.getTime() - now.getTime()

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        })
      }
    }

    calculateTimeLeft()
    const timer = setInterval(calculateTimeLeft, 1000)

    return () => clearInterval(timer)
  }, [startsAt])

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: ${colors.SECONDARY_400};
        padding: 20px;
        gap: 16px;
      `}
    >
      <div
        css={css`
          background-color: ${colors.SECONDARY_300};
          border-radius: 8px;
          padding: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
        `}
      >
        <Text variant='heading1' color={colors.WHITE} weight='bold'>
          {name || ''}
        </Text>

        <Text variant='heading6' color={colors.WHITE} weight='medium'>
          REGISTRATION WILL BE OPEN IN
        </Text>

        <div
          css={css`
            display: flex;
            gap: 32px;
            margin: 32px 0;
          `}
        >
          {Object.entries(timeLeft).map(([unit, value]) => (
            <div
              key={unit}
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
              `}
            >
              <Text variant='displayLarge' color={colors.WHITE} weight='bold'>
                {value}
              </Text>
              <Text
                variant='paragraphSmall'
                color={colors.WHITE}
                weight='medium'
                textTransform='uppercase'
              >
                {unit}
              </Text>
            </div>
          ))}
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 32px;
          `}
        >
          <img
            src='/images/powered_by_sportninja.png'
            alt='SportNinja'
            css={css`
              height: 30px;
              width: auto;
            `}
          />
        </div>
      </div>
    </div>
  )
}

export default RegistrationNotOpen
