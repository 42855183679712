/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { App } from 'antd'
import React, { useCallback, useEffect, useMemo, Suspense } from 'react'
import { connect } from 'react-redux'

// Components
import RegistrationTable from 'src/components/ts-components/RegistrationTable'

// Utils & Constants
import colors from '@sportninja/common/constants/appColors'
import {
  RegistrationData,
  useRegistrationData,
} from '@sportninja/common/hooks/useRegistrationData'
import {
  getPlayerTypesBySportId,
  getTeamOfficialTypesBySportId,
} from '@sportninja/common/selectors/types'

// Types
import { CalendarOutlined, DeleteOutlined } from '@ant-design/icons'
import req from '@sportninja/common/api/request'
import { useCountries } from '@sportninja/common/hooks/useCountries'
import { formatShortDate } from '@sportninja/common/ts-utils/date-utils'
import type {
  AnalyticsData,
  PlayerType,
  Registration,
  RegistrationTypes,
  Tax,
  TeamOfficialType,
} from '@sportninja/common/types/Registration'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import DeleteModal from 'src/components/Modal/DeleteModal'
import Text from 'src/components/ts-components/Text'
import EmptyStateView from './EmptyStateView'
import ErrorStateView from './ErrorStateView'
import { computeDivisions, RegistrationTableDivision } from './helpers'
import IndicatorCards from './IndicatorCards'
import LoadingView from './LoadingView'

const DivisionCloneModal = React.lazy(() => import('./DivisionCloneModal'))
const DivisionSetup = React.lazy(() => import('../DivisionSetup'))
const RegistrationDetailsModal = React.lazy(
  () => import('../RegistrationDetailsModal')
)
const MemoizedRegistrationTable = React.memo(RegistrationTable)

const DeleteButton = ({ ...props }) => {
  return (
    <button
      id='delete-division-button'
      css={css`
        /* invisible and not clickable */
        display: none;
        padding: 8px 16px 8px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid ${colors.ERROR_200};
        box-shadow: 0px 2px 0px 0px rgba(5, 145, 255, 0.1);
        font-family: ${isCanlan
          ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif !important;'
          : 'BarlowCondensed, Arial, Helvetica, sans-serif !important;'};
      `}
      {...props}
    >
      <DeleteOutlined
        css={css`
          color: ${colors.ERROR_200};
        `}
        width={24}
        height={24}
      />
      <Text variant='paragraphMedium' weight='semiBold' color={colors.WHITE}>
        DELETE DIVISION
      </Text>
    </button>
  )
}

interface PlayerRegistrationProps {
  scheduleId: string
  sportId: string // Added missing prop
  setIsRegistrationFormOpen: (isOpen: boolean) => void
  setRegistration: (registration: Registration | null) => void
  setRegistrationTypes: (registrationTypes: RegistrationTypes) => void
  setAvailableTaxes: (availableTaxes: Tax[]) => void
  refetchRegistrationFn: React.MutableRefObject<() => void>
  setIsRegistrationSetup: (isOpen: boolean) => void
  teamOfficialTypes: TeamOfficialType[]
  playerTypes: PlayerType[]
  setRegistrationLink: (registrationLink: string) => void
}

// Styled components
const styles = {
  container: css`
    padding: 16px 0;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  cardContainer: css`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    width: 100%;
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0 16px;
    }
  `,
  loadingContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  `,
  emptyStateContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    gap: 16px;
  `,
  emptyStateImage: css`
    width: 240px;
    height: 220px;
  `,
  icon: css`
    font-size: 32px;
    color: ${colors.NEUTRAL_100};
  `,
  copyContainer: css`
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  `,
  copyIcon: css`
    color: ${colors.PRIMARY};
    font-size: 12px;
  `,
}

const PlayerRegistration: React.FC<PlayerRegistrationProps> = ({
  scheduleId,
  setIsRegistrationFormOpen,
  setRegistration,
  setRegistrationTypes,
  setAvailableTaxes,
  refetchRegistrationFn,
  setIsRegistrationSetup,
  teamOfficialTypes,
  playerTypes,
}) => {
  const { message } = App.useApp()
  const {
    isRegistrationEmpty,
    loading,
    error,
    availableTaxes,
    types,
    data,
    refetch,
    updateTeamRegistrationStatus,
    updatePlayerRegistrationStatus,
    fetchTypes,
    fetchAnalytics,
    analytics,
  } = useRegistrationData(scheduleId)
  const { countries, refetch: fetchCountries } = useCountries()

  const [isRegistrationDetailsModalOpen, setIsRegistrationDetailsModalOpen] =
    React.useState(false)
  const [isDivisionCloneOpen, setIsDivisionCloneOpen] = React.useState(false)
  const [isDivisionSetupOpen, setIsDivisionSetupOpen] = React.useState(false)
  const [selectedDivision, setSelectedDivision] =
    React.useState<RegistrationTableDivision | null>(null)
  const [divisionToDelete, setDivisionToDelete] =
    React.useState<RegistrationTableDivision | null>(null)
  const [divisionToClone, setDivisionToClone] =
    React.useState<RegistrationTableDivision | null>(null)
  const [registrationDetailId, setRegistrationDetailId] = React.useState<
    string | null
  >(null)
  const [divisions, setDivisions] = React.useState<RegistrationTableDivision[]>(
    []
  )

  const dateRange = React.useMemo(() => {
    const startDate = formatShortDate(
      data?.detailed?.registration?.starts_at || ''
    )
    const endDate = formatShortDate(data?.detailed?.registration?.ends_at || '')
    return `${startDate} - ${endDate}`
  }, [
    data?.detailed?.registration?.starts_at,
    data?.detailed?.registration?.ends_at,
  ])

  // Memoized values
  useEffect(() => {
    const computedDivisions = computeDivisions(data || undefined)
    setDivisions(computedDivisions || [])
  }, [data])

  const isPlayerRegistrationEnabled = useMemo(
    () => data?.detailed?.registration?.is_player_registration || false,
    [data?.detailed?.registration?.is_player_registration]
  )
  const isTeamRegistrationEnabled = useMemo(
    () => data?.detailed?.registration?.is_team_registration || false,
    [data?.detailed?.registration?.is_team_registration]
  )

  // Callbacks
  const onCopyRegistrationLink = useCallback(() => {
    navigator.clipboard.writeText(
      data?.detailed?.registration?.custom_link || ''
    )
    message.info('Registration link copied to clipboard')
  }, [data?.detailed?.registration?.custom_link])

  const handleDivisionSetupOpen = useCallback(
    (division: RegistrationTableDivision) => {
      setIsDivisionSetupOpen(true)
      setSelectedDivision(division)
    },
    []
  )

  useEffect(() => {
    if (!types || types === null) {
      fetchTypes()
    }
  }, [fetchTypes, types])

  useEffect(() => {
    if (countries.length === 0) {
      fetchCountries()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries.length])

  // Effects
  useEffect(() => {
    refetchRegistrationFn.current = refetch
  }, [refetch, refetchRegistrationFn])

  useEffect(() => {
    if (data) {
      setRegistration(data.detailed.registration)
    }
    if (types) {
      setRegistrationTypes(types)
    }
    if (availableTaxes) {
      setAvailableTaxes(availableTaxes)
    }
  }, [data, types, availableTaxes])

  useEffect(() => {
    if (!isRegistrationEmpty) {
      setIsRegistrationSetup(true)
    }
  }, [isRegistrationEmpty])

  // Check for registration_id in URL
  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search)
  //   const registrationIdFromUrl = urlParams.get('registration_id')
  //   if (registrationIdFromUrl) {
  //     setRegistrationDetailId(registrationIdFromUrl)
  //     setIsRegistrationDetailsModalOpen(true)
  //     // Remove the registration_id from URL without refreshing the page
  //     const newUrl = window.location.pathname
  //     window.history.replaceState({}, '', newUrl)
  //   }
  // }, [])

  if (loading && !error) {
    return <LoadingView size='small' />
  }

  if (error) {
    return <ErrorStateView subtitle={error?.message} />
  }

  if (isRegistrationEmpty && !loading) {
    return (
      <EmptyStateView
        setIsRegistrationFormOpen={setIsRegistrationFormOpen}
        setRegistration={setRegistration}
        subtitle='Make sure to create all your divisions in the Setup tab before beginning to configure registration.'
      />
    )
  }

  return (
    <div css={styles.container}>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <CalendarOutlined
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            fontSize: 20,
            color: colors.NEUTRAL_100,
          }}
        />
        <div>
          <Text
            variant='captionSmall'
            weight='semiBold'
            color={colors.NEUTRAL_100}
          >
            Registration Period
          </Text>
          <Text
            variant='captionLarge'
            weight='semiBold'
            color={colors.NEUTRAL_0}
          >
            {dateRange}
          </Text>
        </div>
      </div>
      <div css={styles.cardContainer}>
        <IndicatorCards
          data={data as RegistrationData}
          analyticsData={analytics || ({} as AnalyticsData)}
          onCopyRegistrationLink={onCopyRegistrationLink}
        />
      </div>
      <MemoizedRegistrationTable
        onPressDivisionSetup={handleDivisionSetupOpen}
        divisions={divisions}
        setDivisions={setDivisions}
        scheduleId={scheduleId}
        updateTeamRegistrationStatus={updateTeamRegistrationStatus}
        updatePlayerRegistrationStatus={updatePlayerRegistrationStatus}
        conditionType={types?.condition || []}
        currencies={types?.currency || []}
        refetch={refetch}
        fetchAnalytics={fetchAnalytics}
        registration={data?.detailed?.registration}
        teamOfficialTypes={teamOfficialTypes}
        setDivisionToDelete={setDivisionToDelete}
        setIsDivisionCloneOpen={setIsDivisionCloneOpen}
        setDivisionToClone={setDivisionToClone}
        setIsRegistrationDetailsModalOpen={setIsRegistrationDetailsModalOpen}
        setRegistrationDetailId={setRegistrationDetailId}
        countries={countries || []}
        playerTypes={playerTypes || []}
      />
      <Suspense fallback={null}>
        <DivisionCloneModal
          isOpen={isDivisionCloneOpen}
          onClose={() => setIsDivisionCloneOpen(false)}
          division={divisionToClone || ({} as RegistrationTableDivision)}
          refetch={refetch}
        />
      </Suspense>
      <Suspense fallback={null}>
        <DivisionSetup
          isFormOpen={isDivisionSetupOpen}
          setIsFormOpen={setIsDivisionSetupOpen}
          division={selectedDivision || ({} as RegistrationTableDivision)}
          setSelectedDivision={setSelectedDivision}
          scheduleCurrency={data?.detailed?.registration?.currency}
          types={types || {}}
          refetch={refetch}
          divisions={divisions || []}
          playerRegistrationEnabled={isPlayerRegistrationEnabled}
          teamRegistrationEnabled={isTeamRegistrationEnabled}
          availableTaxes={availableTaxes || []}
        />
      </Suspense>
      <Suspense fallback={null}>
        <RegistrationDetailsModal
          isOpen={isRegistrationDetailsModalOpen}
          onClose={() => setIsRegistrationDetailsModalOpen(false)}
          scheduleId={scheduleId}
          registrationId={registrationDetailId}
          countries={countries || []}
          types={types || {}}
          refetchList={refetch}
        />
      </Suspense>
      <DeleteModal
        title='Delete Division'
        message={`Are you sure you want to delete ${divisionToDelete?.name}? This cannot be undone.`}
        onDelete={async (shouldForce = false) => {
          const query: any = {}
          if (shouldForce) {
            query.force = 1
          }
          await req(`/registration/${divisionToDelete?.id}`, {
            method: 'DELETE',
            query,
          })
          message.success(`${divisionToDelete?.name} has been deleted.`)
          setDivisionToDelete(null)
          refetch()
        }}
        Button={DeleteButton}
        forceDeleteText={undefined}
        shouldCloseModalAfterDelete={true}
        useForceDelete={true}
        displayBanner={false}
      />
    </div>
  )
}

const mapStateToProps = (state, { sportId }) => ({
  teamOfficialTypes: getTeamOfficialTypesBySportId(state, sportId),
  playerTypes: getPlayerTypesBySportId(state, sportId),
})

export default connect(mapStateToProps)(PlayerRegistration)
