import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import {
  CheckCircleOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  RightOutlined,
  TeamOutlined,
  SwapOutlined,
} from '@ant-design/icons'
import {
  MyPlayer,
  MyTeamOfficial,
  UpcomingGame,
} from '@sportninja/common/types/PlayerAttendance'
import { status } from '@sportninja/common/constants/attendanceStatus'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'

export interface GameAttendanceCardProps {
  homeTeam: {
    name: string
    logo: string
  }
  awayTeam: {
    name: string
    logo: string
  }
  gameTime: string
  leagueName: string
  arenaName: string
  facilityNumber: string
  onAttendanceSelect: ({
    playerId,
    gameId,
    rosterId,
    status,
  }: {
    playerId: string
    gameId: string
    rosterId: string
    status: number
  }) => void
  isPlayingOnHomeTeam: boolean
  isPlayingOnAwayTeam: boolean
  displayPlayerAttendance: boolean
  displayTeamRepAttendance: boolean
  playersAttendance: MyPlayer[]
  teamRepsAttendance: MyTeamOfficial[]
  rowItem: UpcomingGame
  isFirstGame: boolean
  onPressManageAttendance: (
    gameId: string,
    rosterId: string,
    teamId: string,
    teamName: string,
    showWaiver: boolean
  ) => void
  onPressManageRefreshments: (
    gameId: string,
    rosterId: string,
    teamId: string,
    teamName: string,
    showWaiver: boolean
  ) => void
}

const GameAttendanceCard: React.FC<GameAttendanceCardProps> = ({
  isFirstGame,
  rowItem,
  homeTeam,
  awayTeam,
  gameTime,
  leagueName,
  arenaName,
  facilityNumber,
  onAttendanceSelect,
  isPlayingOnHomeTeam,
  isPlayingOnAwayTeam,
  displayPlayerAttendance,
  displayTeamRepAttendance,
  playersAttendance,
  teamRepsAttendance,
  onPressManageAttendance,
  onPressManageRefreshments,
}) => {
  const isTeamRepOnHomeTeam = useMemo(() => {
    if (!teamRepsAttendance || teamRepsAttendance?.length === 0) {
      return false
    }
    const teamRep = teamRepsAttendance.find(
      (t) => t.team_id === rowItem?.home_team?.id
    )
    return !!teamRep
  }, [teamRepsAttendance, rowItem])

  const isTeamRepOnAwayTeam = useMemo(() => {
    if (!teamRepsAttendance || teamRepsAttendance?.length === 0) {
      return false
    }
    const teamRep = teamRepsAttendance.find(
      (t) => t.team_id === rowItem?.visiting_team?.id
    )
    return !!teamRep
  }, [teamRepsAttendance, rowItem])

  return (
    <CardContainer key={rowItem.id}>
      <Header>
        <Title>{isFirstGame ? 'Next Game' : 'Ready for your game?'}</Title>
        <a href={`/game/${rowItem.id}`}>
          <ViewMore>
            <ViewMoreText>View more</ViewMoreText>
            <RightOutlined style={{ fontSize: '12px' }} />
          </ViewMore>
        </a>
      </Header>
      <a href={`/game/${rowItem.id}`}>
        <TeamsContainer>
          <TeamInfo>
            <TeamLogo
              src={
                homeTeam?.logo ||
                '/images/generic_placeholders/placeholder_team.png'
              }
              alt={homeTeam?.name || 'Placeholder'}
              onError={(e) => {
                e.currentTarget.src =
                  '/images/generic_placeholders/placeholder_team.png'
              }}
            />
            <TeamName>{homeTeam?.name || 'Placeholder'}</TeamName>
          </TeamInfo>
          <VsText>VS</VsText>
          <TeamInfo>
            <TeamLogo
              src={
                awayTeam?.logo ||
                '/images/generic_placeholders/placeholder_team.png'
              }
              alt={awayTeam?.name || 'Placeholder'}
              onError={(e) => {
                e.currentTarget.src =
                  '/images/generic_placeholders/placeholder_team.png'
              }}
            />
            <TeamName>{awayTeam?.name || 'Placeholder'}</TeamName>
          </TeamInfo>
        </TeamsContainer>
        <GameInfo>
          <GameTime>{gameTime}</GameTime>
          <LeagueName>{leagueName}</LeagueName>
          <ArenaInfo>{`${facilityNumber} ${
            arenaName ? `- ${arenaName}` : ''
          }`}</ArenaInfo>
        </GameInfo>
      </a>
      {displayPlayerAttendance ? (
        <>
          {playersAttendance.map((player, index) => {
            const isAttending = player?.attendance_status === status.ATTENDING
            const maybeAttending = player?.attendance_status === status.MAYBE
            const notAttending =
              player?.attendance_status === status.NOT_ATTENDING
            const teamName =
              playersAttendance.length > 1
                ? player?.team_id === rowItem?.home_team?.id
                  ? `(${rowItem?.home_team?.name})`
                  : `(${rowItem?.visiting_team?.name})`
                : ''
            const isLastItemOfList = index === playersAttendance.length - 1
            return (
              <>
                {playersAttendance?.length > 0 && isLastItemOfList ? (
                  <EmptySpace />
                ) : null}
                <AttendanceQuestion>
                  Will you be attending next game?{' '}
                  {teamName ? `${teamName}` : ''}
                </AttendanceQuestion>
                <AttendanceOptions key={player.id}>
                  <AttendanceButton
                    onClick={() => {
                      onAttendanceSelect({
                        status: status.ATTENDING,
                        playerId: player?.id,
                        rosterId: player?.game_player_roster_id,
                        gameId: rowItem.id,
                      })
                    }}
                    color={isAttending ? 'transparent' : colors.SECONDARY_200}
                    borderColor={
                      isAttending ? colors.SUCCESS_200 : colors.NEUTRAL_100
                    }
                    textColor={
                      isAttending ? colors.SUCCESS_200 : colors.NEUTRAL_50
                    }
                  >
                    <CheckIcon
                      color={
                        isAttending ? colors.SUCCESS_200 : colors.NEUTRAL_50
                      }
                    />
                    YES
                  </AttendanceButton>
                  <AttendanceButton
                    onClick={() => {
                      onAttendanceSelect({
                        status: status.MAYBE,
                        playerId: player?.id,
                        rosterId: player?.game_player_roster_id,
                        gameId: rowItem.id,
                      })
                    }}
                    color={
                      maybeAttending ? 'transparent' : colors.SECONDARY_200
                    }
                    borderColor={
                      maybeAttending ? colors.WARNING_200 : colors.NEUTRAL_100
                    }
                    textColor={
                      maybeAttending ? colors.WARNING_200 : colors.NEUTRAL_50
                    }
                  >
                    <QuestionIcon
                      color={
                        maybeAttending ? colors.WARNING_200 : colors.NEUTRAL_50
                      }
                    />
                    MAYBE
                  </AttendanceButton>
                  <AttendanceButton
                    onClick={() => {
                      onAttendanceSelect({
                        status: status.NOT_ATTENDING,
                        playerId: player?.id,
                        rosterId: player?.game_player_roster_id,
                        gameId: rowItem.id,
                      })
                    }}
                    color={notAttending ? 'transparent' : colors.SECONDARY_200}
                    borderColor={
                      notAttending ? colors.ERROR_200 : colors.NEUTRAL_100
                    }
                    textColor={
                      notAttending ? colors.ERROR_200 : colors.NEUTRAL_50
                    }
                  >
                    <CrossIcon
                      color={
                        notAttending ? colors.ERROR_200 : colors.NEUTRAL_50
                      }
                    />
                    NO
                  </AttendanceButton>
                </AttendanceOptions>
              </>
            )
          })}
        </>
      ) : null}

      {displayTeamRepAttendance && teamRepsAttendance.length > 0 ? (
        <>
          {' '}
          {teamRepsAttendance.map((teamRep) => {
            const teamName =
              teamRepsAttendance.length > 1
                ? teamRep?.team_id === rowItem?.home_team?.id
                  ? `(${rowItem?.home_team?.name})`
                  : `(${rowItem?.visiting_team?.name})`
                : ''
            const teamNameForNavigation =
              teamRepsAttendance.length > 1
                ? teamRep?.team_id === rowItem?.home_team?.id
                  ? `${rowItem?.home_team?.name}`
                  : `${rowItem?.visiting_team?.name}`
                : isPlayingOnHomeTeam
                ? rowItem?.home_team?.name
                : rowItem?.visiting_team?.name

            return (
              <ManageAttendanceButton
                key={teamRep.id}
                onClick={() => {
                  onPressManageAttendance(
                    rowItem?.id,
                    teamRep?.game_player_roster_id,
                    teamRep?.team_id,
                    teamNameForNavigation,
                    rowItem?.show_waiver
                  )
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                  <StyledTeamOutlined />
                  Manage Team Attendance
                </div>
                {teamName ? (
                  <ManageAttendanceText>{teamName}</ManageAttendanceText>
                ) : null}
              </ManageAttendanceButton>
            )
          })}
        </>
      ) : null}

      {isPlayingOnHomeTeam && rowItem?.home_team?.attendance_numbers ? (
        <>
          <div style={{ height: 16 }} />
          {isPlayingOnHomeTeam && isPlayingOnAwayTeam ? (
            <CounterTeamText>{rowItem?.home_team?.name}</CounterTeamText>
          ) : null}
          <AttendanceCounterContainer>
            <TitleAndTextContainer>
              <YesTitle>Yes</YesTitle>
              <YesCounterText>
                {rowItem?.home_team?.attendance_numbers?.accepted}
              </YesCounterText>
            </TitleAndTextContainer>
            <Separator />
            <TitleAndTextContainer>
              <MaybeTitle>Maybe</MaybeTitle>
              <MaybeCounterText>
                {rowItem?.home_team?.attendance_numbers?.tentative}
              </MaybeCounterText>
            </TitleAndTextContainer>
            <Separator />
            <TitleAndTextContainer>
              <NoTitle>No</NoTitle>
              <NoCounterText>
                {rowItem?.home_team?.attendance_numbers?.declined}
              </NoCounterText>
            </TitleAndTextContainer>
            <Separator />
            <TitleAndTextContainer>
              <UnknownTitle>Unknown</UnknownTitle>
              <UnknownCounterText>
                {rowItem?.home_team?.attendance_numbers?.unknown}
              </UnknownCounterText>
            </TitleAndTextContainer>
          </AttendanceCounterContainer>
        </>
      ) : null}

      {isPlayingOnAwayTeam && rowItem?.visiting_team?.attendance_numbers ? (
        <>
          <div style={{ height: 16 }} />
          {isPlayingOnHomeTeam && isPlayingOnAwayTeam ? (
            <CounterTeamText>{rowItem?.visiting_team?.name}</CounterTeamText>
          ) : null}
          <AttendanceCounterContainer>
            <TitleAndTextContainer>
              <YesTitle>Yes</YesTitle>
              <YesCounterText>
                {rowItem?.visiting_team?.attendance_numbers?.accepted}
              </YesCounterText>
            </TitleAndTextContainer>
            <Separator />
            <TitleAndTextContainer>
              <MaybeTitle>Maybe</MaybeTitle>
              <MaybeCounterText>
                {rowItem?.visiting_team?.attendance_numbers?.tentative}
              </MaybeCounterText>
            </TitleAndTextContainer>
            <Separator />
            <TitleAndTextContainer>
              <NoTitle>No</NoTitle>
              <NoCounterText>
                {rowItem?.visiting_team?.attendance_numbers?.declined}
              </NoCounterText>
            </TitleAndTextContainer>
            <Separator />
            <TitleAndTextContainer>
              <UnknownTitle>Unknown</UnknownTitle>
              <UnknownCounterText>
                {rowItem?.visiting_team?.attendance_numbers?.unknown}
              </UnknownCounterText>
            </TitleAndTextContainer>
          </AttendanceCounterContainer>
        </>
      ) : null}

      {isPlayingOnHomeTeam ? (
        <SnacksContainer
          isTeamRepOnTeam={isTeamRepOnHomeTeam}
          onClick={() => {
            if (!isTeamRepOnHomeTeam) {
              return
            }
            onPressManageRefreshments(
              rowItem?.id,
              rowItem?.home_team?.game_roster_id,
              rowItem?.home_team?.id,
              rowItem?.home_team?.name,
              rowItem?.show_waiver
            )
          }}
        >
          <SnacksImage
            src={
              getImageThumbnailId(rowItem?.home_team?.refreshments_player) ||
              getImageThumbnailId(
                rowItem?.home_team?.refreshments_team_official
              ) ||
              '/images/generic_placeholders/placeholder_player.png'
            }
            alt='Snacks'
            onError={(e) => {
              e.currentTarget.src =
                '/images/generic_placeholders/placeholder_player.png'
            }}
          />
          <SnacksLabelAndTextContainer>
            <SnacksLabel>
              Drinks/Snacks{' '}
              {isPlayingOnHomeTeam && isPlayingOnAwayTeam
                ? `(${rowItem?.home_team?.name})`
                : ''}
            </SnacksLabel>
            <SnacksText>
              {rowItem?.home_team?.refreshments_player?.name_full
                ? rowItem?.home_team?.refreshments_player?.name_full
                : rowItem?.home_team?.refreshments_team_official?.name_full
                ? rowItem?.home_team?.refreshments_team_official?.name_full
                : isTeamRepOnHomeTeam
                ? 'Choose a Person'
                : 'Not Set'}
            </SnacksText>
          </SnacksLabelAndTextContainer>
          {isTeamRepOnHomeTeam ? <StyledSwapOutlined /> : null}
        </SnacksContainer>
      ) : null}
      {isPlayingOnAwayTeam ? (
        <SnacksContainer
          isTeamRepOnTeam={isTeamRepOnAwayTeam}
          onClick={() => {
            if (!isTeamRepOnAwayTeam) {
              return
            }
            onPressManageRefreshments(
              rowItem?.id,
              rowItem?.visiting_team?.game_roster_id,
              rowItem?.visiting_team?.id,
              rowItem?.visiting_team?.name,
              rowItem?.show_waiver
            )
          }}
        >
          <SnacksImage
            src={
              getImageThumbnailId(
                rowItem?.visiting_team?.refreshments_player
              ) ||
              getImageThumbnailId(
                rowItem?.visiting_team?.refreshments_team_official
              ) ||
              '/images/generic_placeholders/placeholder_player.png'
            }
            alt='Snacks'
            onError={(e) => {
              e.currentTarget.src =
                '/images/generic_placeholders/placeholder_player.png'
            }}
          />
          <SnacksLabelAndTextContainer>
            <SnacksLabel>
              Drinks/Snacks{' '}
              {isPlayingOnHomeTeam && isPlayingOnAwayTeam
                ? `(${rowItem?.visiting_team?.name})`
                : ''}
            </SnacksLabel>
            <SnacksText>
              {rowItem?.visiting_team?.refreshments_player?.name_full
                ? rowItem?.visiting_team?.refreshments_player?.name_full
                : rowItem?.visiting_team?.refreshments_team_official?.name_full
                ? rowItem?.visiting_team?.refreshments_team_official?.name_full
                : isTeamRepOnAwayTeam
                ? 'Choose a Person'
                : 'Not Set'}
            </SnacksText>
          </SnacksLabelAndTextContainer>
          {isTeamRepOnAwayTeam ? <StyledSwapOutlined /> : null}
        </SnacksContainer>
      ) : null}
    </CardContainer>
  )
}

const ManageAttendanceText = styled.p`
  color: ${colors.NEUTRAL_50};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
`

const ViewMoreText = styled.p`
  color: ${colors.PRIMARY};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.4px;
  width: 100%;
`

const ViewMore = styled.a`
  color: ${colors.PRIMARY};
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
`

const StyledSwapOutlined = styled(SwapOutlined)`
  font-size: 24px;
  color: ${colors.NEUTRAL_0};
`

// ant design image component
const SnacksImage = styled.img`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 2px solid ${colors.NEUTRAL_0};
  /* lightgray 50% / cover no-repeat */
  background: lightgray 50% / cover no-repeat;
  object-fit: contain;
`

const SnacksContainer = styled.div<{ isTeamRepOnTeam: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 8px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${colors.NEUTRAL_30};
  background: transparent;
  width: 100%;
  margin-top: 16px;
  cursor: ${(props) => (props.isTeamRepOnTeam ? 'pointer' : 'default')};
`

const SnacksLabelAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`

const SnacksLabel = styled.p`
  color: ${colors.NEUTRAL_100};
  font-size: 12px;
  font-weight: 400;
  line-height: 17.4px;
`

const SnacksText = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${colors.NEUTRAL_0};
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.2px;
`

const StyledTeamOutlined = styled(TeamOutlined)`
  font-size: 16px;
  color: ${colors.PRIMARY};
`

const ManageAttendanceButton = styled.button`
  display: flex;
  flex-direction: column;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid ${colors.PRIMARY};
  width: 100%;
  color: ${colors.NEUTRAL_0};
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 16px;
`
const EmptySpace = styled.div`
  height: 16px;
`

const CheckIcon = styled(CheckCircleOutlined)<{ color: string }>`
  font-size: 16px;
  color: ${(props) => props.color || colors.NEUTRAL_50};
`

const QuestionIcon = styled(QuestionCircleOutlined)`
  font-size: 16px;
  color: ${(props) => props.color || colors.NEUTRAL_50};
`

const CrossIcon = styled(CloseCircleOutlined)`
  font-size: 16px;
  color: ${(props) => props.color || colors.NEUTRAL_50};
`

const CardContainer = styled.div`
  border: 1px solid ${colors.SOFT_STEEL};
  background-color: ${colors.SECONDARY_300};
  border-radius: 8px;
  padding: 16px;
  color: ${colors.NEUTRAL_0};
  max-width: 400px;
  margin: 0 auto;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${colors.SOFT_STEEL};
`

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: ${colors.NEUTRAL_0};
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
`

// const StyledLinkOutlined = styled(RightOutlined)`
//   font-size: 16px;
//   color: ${colors.PRIMARY};
// `

// const IconsContainer = styled.div`
//   display: flex;
//   gap: 10px;
// `

const TeamsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
  height: 80px;
`

const TeamInfo = styled.div`
  display: flex;
  flex: 1;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  height: 100%;
`
// ant design image component
const TeamLogo = styled.img`
  width: 32px !important;
  height: 32px !important;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  object-fit: contain;
`

const TeamName = styled.p`
  color: ${colors.NEUTRAL_0};
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  text-align: center;
`

const VsText = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: ${colors.NEUTRAL_50};
  font-weight: 500;
  align-self: center;
`

const GameInfo = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: ${colors.NEUTRAL_50};
  text-align: center;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const GameTime = styled.p`
  color: ${colors.NEUTRAL_50};
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-transform: capitalize;
`

const LeagueName = styled.p`
  color: ${colors.NEUTRAL_50};
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
`

const ArenaInfo = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: ${colors.NEUTRAL_50};
`

const CounterTeamText = styled.p`
  color: ${colors.NEUTRAL_0};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
  text-overflow: ellipsis;
`

const AttendanceQuestion = styled.p`
  color: ${colors.NEUTRAL_0};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  margin-bottom: 16px;
`

const AttendanceOptions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

const AttendanceCounterContainer = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${colors.NEUTRAL_30};
  background: transparent;
  margin-top: 8px;
`

const TitleAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
`

const YesTitle = styled.p`
  color: ${colors.SUCCESS_200};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14.4px;
  letter-spacing: 1.44px;
  text-transform: uppercase;
`

const YesCounterText = styled.p`
  align-self: stretch;
  color: ${colors.SUCCESS_200};
  text-align: center;
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28.8px; /* 120% */
`

const MaybeTitle = styled.p`
  color: ${colors.WARNING_200};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14.4px;
  letter-spacing: 1.44px;
  text-transform: uppercase;
`

const MaybeCounterText = styled.p`
  align-self: stretch;
  color: ${colors.WARNING_200};
  text-align: center;
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28.8px; /* 120% */
`

const NoTitle = styled.p`
  color: ${colors.ERROR_200};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14.4px;
  letter-spacing: 1.44px;
  text-transform: uppercase;
`

const NoCounterText = styled.p`
  align-self: stretch;
  color: ${colors.ERROR_200};
  text-align: center;
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28.8px; /* 120% */
`

const UnknownTitle = styled.p`
  color: ${colors.NEUTRAL_100};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14.4px;
  letter-spacing: 1.44px;
  text-transform: uppercase;
`

const UnknownCounterText = styled.p`
  align-self: stretch;
  color: ${colors.NEUTRAL_100};
  text-align: center;
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28.8px; /* 120% */
`

const Separator = styled.div`
  width: 1px;
  height: 44px;
  background-color: ${colors.NEUTRAL_30};
`

const AttendanceButton = styled.button<{
  color: string
  borderColor: string
  textColor: string
}>`
  background-color: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor || props.color};
  border-radius: 8px;
  padding: 8px 16px;
  gap: 8px;
  color: ${(props) => props.textColor};
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  width: 100%;
`

export default GameAttendanceCard
