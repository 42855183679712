import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export function useUrlManager() {
  const history = useHistory()
  const location = useLocation()
  const currentQueryParams = useQuery()

  const deleteQueryParam = (key: string) => {
    currentQueryParams.delete(key)
    history.push({
      pathname: location.pathname,
      search: currentQueryParams.toString(),
    })
  }

  const deleteMultipleQueryParams = (keys: string[]) => {
    for (const key of keys) {
      deleteQueryParam(key)
    }
  }

  const setParam = (param: string, value: string) => {
    let setter: URLSearchParams

    if (currentQueryParams) {
      setter = currentQueryParams
    } else {
      setter = new URLSearchParams()
    }

    if (value === '' || !value) {
      return deleteQueryParam(param)
    }

    setter.set(param, value)

    history.push({
      pathname: location.pathname,
      search: setter.toString(),
    })
  }

  return { currentQueryParams, setParam, deleteMultipleQueryParams }
}
