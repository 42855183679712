import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'
import parsers from '@sportninja/common/reducers/parsers'
import { getTeamOfficialTypesBySportId } from '@sportninja/common/selectors/types'
import { useState } from 'react'
import { connect } from 'react-redux'

import { InviteAction } from '../ActionPanel/ActionPanelActions'
import { teamOfficialForm } from '../Form/form-configs'
import FormSheet from '../Form/FormSheet'
import NewButton from '../Page/NewButton'
import OptionButtonDelete from './OptionButtons/Delete'
import OptionButtonEdit from './OptionButtons/Edit'
import PersonCell from './PersonCell'
import TeamRosterBasedList from './TeamRosterBasedList'
import List from '.'
import MoreOptions from './OptionButtons/More'

export const AddTeamOfficial = ({
  permission,
  teamId,
  rosterId,
  onComplete,
  teamOfficialTypes = [],
  sportId,
}) => {
  const onAddTeamOfficial = async (...args) => {
    const [values] = args
    const { message_text, official_type_id, ...form } = values
    if (typeof message_text === 'string') {
      form.message = message_text
    }
    if (typeof official_type_id === 'string') {
      form.official_type_id = parseInt(official_type_id, 10)
    } else {
      form.official_type_id = official_type_id
    }
    const body = JSON.stringify(form)
    const response = await req(
      `/teams/${teamId}/rosters/${rosterId}/team-officials`,
      {
        method: 'POST',
        body,
      }
    )
    onComplete()
    return response
  }

  return (
    permission.create && (
      <FormSheet
        key='add'
        Button={({ onClick }) => <NewButton onClick={onClick} />}
        form={teamOfficialForm(
          { teamOfficialTypeId: teamOfficialTypes[0]?.id },
          sportId
        )}
        label={t('Web:createTeamOfficial')}
        onSubmit={onAddTeamOfficial}
        title={t('Web:createTeamOfficial')}
      />
    )
  )
}

const TeamOfficialList = ({
  teamId,
  teamName,
  permission,
  teamOfficialTypes,
  refreshKey,
  onComplete,
  sportId,
}) => {
  const [sort, setSort] = useState('name_last')
  const [direction, setDirection] = useState('asc')
  const headers = [
    {
      text: t('common:name'),
      sizePercent: 34,
      // sort: 'name_full',
      Cell: PersonCell,
    },
    {
      text: t('common:role'),
      sizePercent: 19,
      // sort: 'official_type',
      minWidth: 90,
      accessor: (item) => {
        return teamOfficialTypes.find((t) => t.id === item.team_official_type)
          ?.name_full
      },
    },
    {
      text: t('common:email'),
      sizePercent: 34,
      minWidth: 240,
      // sort: 'email',
      accessor: 'email',
    },
    {
      text: 'Invite Status',
      sizePercent: 13,
      // sort: 'is_accepted',
      minWidth: 80,
      accessor: (item) => {
        let status = ''
        if (item?.is_invited) {
          status = 'Invited'
        }
        if (item?.is_accepted) {
          status = 'Accepted'
        }

        return status
      },
    },
  ]

  return (
    <TeamRosterBasedList
      teamId={teamId}
      teamName={teamName}
      permission={permission}
      slug='team-officials'
      refreshKey={refreshKey}
      onComplete={onComplete}
    >
      {(roster, refresh) => {
        const options = (item) => {
          const slug = `/teams/${teamId}/rosters/${roster.id}/team-officials/${item.id}`
          const onEdit = async (...args) => {
            const [values] = args

            const body = JSON.stringify(values)
            const response = await req(slug, {
              method: 'PUT',
              body,
            })
            await refresh()
            return response
          }

          const opts = [
            <OptionButtonEdit
              key='edit'
              form={teamOfficialForm(parsers.teamOfficial(item), sportId)}
              onSubmit={onEdit}
              title={t('Web:editTeamOfficial')}
            />,
            <InviteAction
              key='invite'
              invite={{
                is_invited: item.is_invited,
                is_accepted: item.is_accepted,
                invitation_id: item.invitation_id,
              }}
              user={{
                id: item.id,
                name_first: item.name_first,
                name_last: item.name_last,
                team_official_type_id: item.team_official_type,
                email: item?.email,
              }}
              slug={slug}
              onComplete={refresh}
            />,
            <MoreOptions
              key='more'
              options={[
                {
                  key: 'remove',
                  Component: OptionButtonDelete,
                  componentProps: {
                    buttonText: 'remove',
                    slug,
                    iconName: 'user-minus',
                    title: `Remove ${item.name_full}`,
                    type: t('common:teamOfficial'),
                    itemId: item.id,

                    onComplete: refresh,
                  },
                  title: 'remove',
                  iconName: 'user-minus',
                },
              ]}
            />,
          ]

          return opts.filter((o) => o !== undefined)
        }

        return (
          <List
            headers={headers.filter((o) => o !== undefined)}
            options={options}
            listProps={{
              items: roster?.team_officials || [],
              sort,
              direction,
              setSort: (s) => {
                setSort(s)
              },
              setDirection: (d) => {
                setDirection(d)
              },
            }}
            noItemsText={t('common:teamOfficial', { count: 2 })}
          />
        )
      }}
    </TeamRosterBasedList>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    teamOfficialTypes: getTeamOfficialTypesBySportId(state, sportId),
  }
}

export default connect(mapStateToProps)(TeamOfficialList)
