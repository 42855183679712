/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import dayjs from 'dayjs'
import RangePicker from 'src/components/ts-components/RangePicker'
import Text from 'src/components/ts-components/Text'
import { useRegistrationSetupContext } from '../../context'
import { SwitchInput } from 'src/components/ts-components/SwitchInput'
// import { SelectInput } from 'src/components/ts-components/SelectInput'
import MultipleOptionsInput from 'src/components/ts-components/MultipleOptionsInput'
import colors from '@sportninja/common/constants/appColors'

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const GeneralSettings = () => {
  const { state, setState } = useRegistrationSetupContext()

  return (
    <div
      css={css`
        display: flex;
        padding: 16px;
        gap: 16px;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text variant='captionLarge' weight='semiBold'>
          General Settings
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          align-self: stretch;
        `}
      >
        <RangePicker
          fieldErrors={state.fieldErrors}
          customError={state.customError}
          name='competitionDateRange'
          label='Registration Start/End'
          onChange={(e) => {
            const [starts_at, ends_at] = e
            setState({
              ...state,
              competitionDateRange: {
                starts_at: dayjs(starts_at).utc().format('YYYY-MM-DD'),
                ends_at: dayjs(ends_at).utc().format('YYYY-MM-DD'),
              },
            })
          }}
          starts_at={dayjs(state?.competitionDateRange?.starts_at).utc()}
          ends_at={dayjs(state?.competitionDateRange?.ends_at).utc()}
        />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 24px;
          align-self: stretch;
        `}
      >
        <SwitchInput
          title='Go Live'
          subtitle='Registration Will Be Immediately Available'
          value={state.goLive}
          onChange={(value) => {
            setState({ ...state, goLive: value })
          }}
        />
      </div>
      <div
        css={css`
          width: 100%;
          height: 1px;
          background: ${colors.NEUTRAL_100};
          border-bottom: 1px solid ${colors.NEUTRAL_100};
        `}
      />
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text variant='captionSmall' weight='semiBold'>
          Registration Type
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 24px;
          align-self: stretch;
        `}
      >
        <SwitchInput
          title='Enable Team Registration'
          subtitle='Allow Team Officials To Register And Pay For Their Team'
          value={state.enableTeamRegistration}
          onChange={(value) => {
            setState({ ...state, enableTeamRegistration: value })
          }}
        />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 24px;
          align-self: stretch;
        `}
      >
        <SwitchInput
          title='Enable Player Registration'
          subtitle='Allow Players To Register Into Teams And Pay For Their Registration'
          value={state.enablePlayerRegistration}
          onChange={(value) => {
            setState({
              ...state,
              enablePlayerRegistration: value,
            })
          }}
        />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 24px;
          align-self: stretch;
        `}
      >
        <SwitchInput
          title='Add Players Outside Of Registration'
          subtitle='Allow Team Officials To Be Able To Add Players Outside Of Registration'
          value={state.addPlayersOutsideOfRegistration}
          onChange={(value) => {
            setState({ ...state, addPlayersOutsideOfRegistration: value })
          }}
        />
      </div>
      {/* Removing this for now in favor of the switch input */}
      {/* <div
        css={css`
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <SelectInput
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            label='Registration Type'
            options={[
              { label: 'Team', value: 'team' },
              { label: 'Player', value: 'player' },
            ]}
            value={state.registrationType}
            onChange={(value) => {
              setState({
                ...state,
                registrationType: value as 'player' | 'team',
              })
            }}
            disabled={false}
            name='registrationType'
          />
        </div>
      </div> */}
      {/* separator */}
      <div
        css={css`
          width: 100%;
          height: 1px;
          background: ${colors.NEUTRAL_100};
          border-bottom: 1px solid ${colors.NEUTRAL_100};
        `}
      />
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text variant='captionSmall' weight='semiBold'>
          Other Settings
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 24px;
          align-self: stretch;
        `}
      >
        <SwitchInput
          title='Auto-Approve Registrations'
          subtitle='Registrations Will Be Automatically Approved'
          value={state.autoApproveRegistrations}
          onChange={(value) => {
            setState({ ...state, autoApproveRegistrations: value })
          }}
        />
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
        `}
      >
        <MultipleOptionsInput
          label='BCC Registration Emails:'
          tip='(Comma Separated, Press Enter To Add)'
          value={state.bccEmails}
          onChange={(value) => {
            setState({ ...state, bccEmails: value })
          }}
          placeholder='Enter email addresses'
          type='email'
          validator={emailRegex}
          fieldErrors={state.fieldErrors}
          customError={state.customError}
          name='bccEmails'
        />
      </div>
    </div>
  )
}

export default GeneralSettings
