/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Icon from '../Icon'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '@sportninja/common/constants/app'
import useWindowSize from 'src/hooks/useWindowSize'
import req from '@sportninja/common/api/request'
import { isCanlan } from '@sportninja/common/utils/customer-name'

export const BOTTOM_TAB_BAR_HEIGHT = '60px'

const TabBarIcon = ({
  iconName,
  label,
  onClick,
  isSelected,
}: {
  iconName: string
  label: string
  onClick: () => void
  isSelected: boolean
}) => (
  <button
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}
    css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      outline: none;
      color: white;

      transition: all 0.2s ease-in-out;

      i {
        color: white;
      }

      &:hover {
        color: ${colors.DEFAULT_FLAIR};

        i {
          color: ${colors.DEFAULT_FLAIR};
        }
      }

      ${isSelected &&
      `
        color: ${colors.DEFAULT_FLAIR} !important;

        i {
          color: ${colors.DEFAULT_FLAIR} !important;
        }
      `}
    `}
  >
    <Icon name={iconName} fontSize={16} />
    <p
      css={css`
        font-size: 14px;
        font-weight: 500;
        font-family: Rift, Arial, Helvetica, sans-serif;
      `}
    >
      {label}
    </p>
  </button>
)

export const BottomTabBar = ({ user }: { user: any }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const history = useHistory()
  const { width } = useWindowSize()
  const isMobile = useMemo(() => {
    return width <= 1024
  }, [width])
  const [hasRegistrations, setHasRegistrations] = useState(false)

  useEffect(() => {
    const checkPendingRegistrations = async () => {
      try {
        const result = await req('/users/me/has_registrations')
        if (result?.has_registrations && result?.has_registrations === true) {
          setHasRegistrations(true)
        } else {
          setHasRegistrations(false)
        }
      } catch (e) {
        setHasRegistrations(false)
      }
    }
    if (!user || !isCanlan) {
      setHasRegistrations(false)
      return
    }
    if (user && user.isValidated !== undefined) {
      checkPendingRegistrations()
    } else {
      setHasRegistrations(false)
    }
  }, [user])

  useEffect(() => {
    switch (window.location.pathname) {
      case '/':
      case '/home':
        setSelectedTab(0)
        break
      case '/orgs':
        setSelectedTab(1)
        break
      case '/schedules':
        setSelectedTab(2)
        break
      case '/teams':
        setSelectedTab(3)
        break
      case '/registrations':
        setSelectedTab(4)
        break
      default:
        setSelectedTab(0)
        break
    }
  }, [window.location.pathname])

  if (!isMobile) {
    return <div />
  }

  return (
    <div
      css={css`
        background-color: ${colors.HEADER};
        height: ${BOTTOM_TAB_BAR_HEIGHT};
        width: 100vw;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        padding: 0 16px;
        z-index: 5000;
      `}
    >
      <TabBarIcon
        label='home'
        iconName='home'
        onClick={() => {
          setSelectedTab(0)
          history.push(ROUTES.HOME_ROOT)
        }}
        isSelected={selectedTab === 0}
      />
      <TabBarIcon
        label='organizations'
        iconName='building'
        onClick={() => {
          setSelectedTab(1)
          history.push(ROUTES.ORGS.ROOT)
        }}
        isSelected={selectedTab === 1}
      />
      <TabBarIcon
        label='competitions'
        iconName='trophy'
        onClick={() => {
          setSelectedTab(2)
          history.push(ROUTES.SCHEDULES.ROOT)
        }}
        isSelected={selectedTab === 2}
      />
      <TabBarIcon
        label='teams'
        iconName='shield-alt'
        onClick={() => {
          setSelectedTab(3)
          history.push(ROUTES.TEAMS.ROOT)
        }}
        isSelected={selectedTab === 3}
      />
      {isCanlan ? (
        hasRegistrations ? (
          <TabBarIcon
            label='registrations'
            iconName='tablet-alt'
            onClick={() => {
              setSelectedTab(4)
              history.push(ROUTES.REGISTRATIONS.ROOT)
            }}
            isSelected={selectedTab === 4}
          />
        ) : null
      ) : (
        <TabBarIcon
          label='registrations'
          iconName='tablet-alt'
          onClick={() => {
            setSelectedTab(4)
            history.push(ROUTES.REGISTRATIONS.ROOT)
          }}
          isSelected={selectedTab === 4}
        />
      )}
    </div>
  )
}
