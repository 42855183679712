import { generateActions } from './utils'

const authDefinition = {
  login: ['form'],
  loginRegistration: ['form'],
  logout: ['goToHome'],
  refresh: ['isIframe'],
  register: ['form'],
  new_register: ['form'],
  verify: ['token'],
  forgot: ['email'],
  forgot_registration: ['email', 'scheduleId'],
  resend: ['email'],
  reset: ['password', 'token'],
  change: ['form'],
  setNewUserInviteId: ['newUserInviteId'],
  impersonate: ['form'],
  setCredentials: ['token', 'user'],
}

export const authActions = generateActions(['AUTH'], authDefinition)

export default authActions
