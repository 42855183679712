import { useState, useEffect } from 'react'
import req from '@sportninja/common/api/request'
import { TeamStanding } from '../types/TeamStanding'

interface DivisionStandings {
  teamStandings: TeamStanding[]
}

interface ApiResponse {
  data: DivisionStandings[]
}

const useHomeDivisionStandings = () => {
  const [data, setData] = useState<DivisionStandings[]>([])
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchData = async () => {
    try {
      setLoading(true)
      const response: ApiResponse = await req('/home/division-standings')
      if (response?.data && response.data.length > 0) {
        setData(response.data.filter((item) => item.teamStandings.length > 0))
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return {
    standingsData: data,
    standingsError: error,
    standingsLoading: loading,
    readStandings: fetchData,
  }
}

export default useHomeDivisionStandings
