/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

import req from '@sportninja/common/api/request'
import FormInput from '../../../components/Form/FormInput'
import { FormButton, FormRow } from '../../../components/Form/css'
import Icon from '../../../components/Icon'
import { Flex } from '../../../components/Layout'
import TextModal from '../../../components/Modal/TextModal'
import SuspensionSubmitButton from '../../../components/Suspensions/SuspensionSubmitButton'
import { font } from '../../../components/css'
import PlacementOrderTable from './PlacementOrderTable'
import styled from 'styled-components'
import { set } from 'react-hook-form'
import Modal from 'src/components/Modal'
import { useSports } from '@sportninja/common/hooks/useSports'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'

const Styles = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    margin-top: 16px;
    tr {
      td:last-child {
        color: ${colors.AMERICAN_YELLOW};
      }
      td:first-child {
        color: ${colors.ATTENDANCE_GRAY};
      }
      td {
        font-size: 16px;
        color: white;
        font-weight: 500;
        vertical-align: middle;
      }
    }
    th:last-child {
      color: ${colors.AMERICAN_YELLOW};
    }
    th,
    td {
      text-align: left;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
      ${font.title}
      margin: 0;
      padding: 8px;
    }
  }
`

const STANDINGS_WIDTH = 524

const getData = (data) => {
  const response = []
  if (!data) {
    return response
  }
  let order = 1
  data.forEach((d, index) => {
    const object = {}
    object.checked = d?.checked || false
    object.team_id = d?.team?.id
    // only add order if checked. If not, set order as #
    if (object.checked) {
      object.ORDER = order
      order++
    } else {
      object.ORDER = '#'
    }
    object.id = `row-${d?.team?.id}`
    object.TEAM = d?.team?.name_full
    object.image = getImageThumbnailId(d?.team)
    d?.stats?.forEach((s) => {
      if (s?.abbr === 'GP') {
        object.GP = s?.value
      }
      if (s?.abbr === 'W') {
        object.W = s?.value
      }
      if (s?.abbr === 'L') {
        object.L = s?.value
      }
      if (s?.abbr === 'T') {
        object.T = s?.value
      }
      if (s?.abbr === 'WPCTD') {
        object.WPCTD = s?.value
      }
    })
    response.push(object)
  })
  return response
}

const getSoccerData = (data) => {
  const response = []
  if (!data) {
    return response
  }
  let order = 1
  data.forEach((d, index) => {
    const object = {}
    object.checked = d?.checked || false
    object.team_id = d?.team?.id
    // only add order if checked. If not, set order as #
    if (object.checked) {
      object.ORDER = order
      order++
    } else {
      object.ORDER = '#'
    }
    object.id = `row-${d?.team?.id}`
    object.TEAM = d?.team?.name_full
    object.image = getImageThumbnailId(d?.team)
    d?.stats?.forEach((s) => {
      if (s?.abbr === 'MP') {
        object.MP = s?.value
      }
      if (s?.abbr === 'W') {
        object.W = s?.value
      }
      if (s?.abbr === 'L') {
        object.L = s?.value
      }
      if (s?.abbr === 'D') {
        object.D = s?.value
      }
      if (s?.abbr === 'DW%') {
        object['DW%'] = s?.value
      }
    })
    response.push(object)
  })
  return response
}

const SyncGameSlots = ({
  onSubmit,
  regularSeason,
  selectedComp = {},
  comps = {},
  isDirty = false,
  sportId = null,
  selectedCompObject = null,
}) => {
  const { sports, fetchSports } = useSports()

  useEffect(() => {
    if (!sports || sports?.length === 0) {
      fetchSports()
    }
  }, [fetchSports, sports])

  const isSoccer =
    sportId === sports?.find((sport) => sport.name_full === 'Soccer')?.id
  const [syncModalOpen, setSyncModalOpen] = useState(false)
  const [dataForSyncTeams, setDataForSyncTeams] = useState({})
  const [loading, setLoading] = useState(false)
  const [carryOverSuspensions, setCarryOverSuspensions] = useState(false)
  const [numGamesPlayed, setNumGamesPlayed] = useState(0)
  const [originalPlacementOrderData, setOriginalPlacementOrderData] = useState(
    []
  )
  const [placementOrderData, setPlacementOrderData] = useState([])
  const [loadingPlacementOrder, setLoadingPlacementOrder] = useState(false)
  const competition = comps.filter((c) => c?.id === selectedComp)[0] || {}
  const tableData = isSoccer
    ? getSoccerData(placementOrderData)
    : getData(placementOrderData)

  const updateCheckedById = (arr, id, checked) => {
    const index = arr?.findIndex((obj) => obj.team.id === id)
    if (index !== -1) {
      arr[index].checked = checked
    }
  }

  const setAllChecked = (arr, checked) => {
    arr.forEach((obj) => {
      obj.checked = checked
    })
  }

  const columns = !isSoccer
    ? [
        {
          Header: '#',
          accessor: 'ORDER',
        },
        {
          accessor: 'CHECKBOX',
          Header: ({ row }) => {
            const allChecked = tableData.every((obj) => obj.checked === true)
            return (
              <FormInput
                onChange={() => {
                  const myData = [...placementOrderData]
                  setAllChecked(myData, !allChecked)
                  setPlacementOrderData(myData)
                }}
                wrapperClass={css`
                  position: relative;
                  top: -12px;
                `}
                input={{
                  name: 'is_debug',
                  label: ' ',
                  type: 'checkbox',
                  checked: allChecked,
                }}
              />
            )
          },
          Cell: ({ row }) => {
            const { original } = row
            return (
              <FormInput
                wrapperClass={css`
                  position: relative;
                  top: -6px;
                `}
                onChange={(e) => {
                  const myData = [...placementOrderData]
                  updateCheckedById(
                    myData,
                    original?.team_id,
                    e?.target?.checked
                  )
                  setPlacementOrderData(myData)
                }}
                input={{
                  name: `is_checked_${original?.team_id}`,
                  label: ' ',
                  type: 'checkbox',
                  checked: original?.checked,
                }}
              />
            )
          },
        },
        {
          Header: 'TEAM',
          accessor: 'TEAM',
        },
        {
          Header: 'GP',
          accessor: 'GP',
        },
        {
          Header: 'W',
          accessor: 'W',
        },
        {
          Header: 'L',
          accessor: 'L',
        },
        {
          Header: 'T',
          accessor: 'T',
        },
        {
          Header: 'WPCTD',
          accessor: 'WPCTD',
        },
      ]
    : [
        {
          Header: '#',
          accessor: 'ORDER',
        },
        {
          accessor: 'CHECKBOX',
          Header: ({ row }) => {
            const allChecked = tableData.every((obj) => obj.checked === true)
            return (
              <FormInput
                onChange={() => {
                  const myData = [...placementOrderData]
                  setAllChecked(myData, !allChecked)
                  setPlacementOrderData(myData)
                }}
                wrapperClass={css`
                  position: relative;
                  top: -12px;
                `}
                input={{
                  name: 'is_debug',
                  label: ' ',
                  type: 'checkbox',
                  checked: allChecked,
                }}
              />
            )
          },
          Cell: ({ row }) => {
            const { original } = row
            return (
              <FormInput
                wrapperClass={css`
                  position: relative;
                  top: -6px;
                `}
                onChange={(e) => {
                  const myData = [...placementOrderData]
                  updateCheckedById(
                    myData,
                    original?.team_id,
                    e?.target?.checked
                  )
                  setPlacementOrderData(myData)
                }}
                input={{
                  name: `is_checked_${original?.team_id}`,
                  label: ' ',
                  type: 'checkbox',
                  checked: original?.checked,
                }}
              />
            )
          },
        },
        {
          Header: 'TEAM',
          accessor: 'TEAM',
        },
        {
          Header: 'MP',
          accessor: 'MP',
        },
        {
          Header: 'W',
          accessor: 'W',
        },
        {
          Header: 'L',
          accessor: 'L',
        },
        {
          Header: 'D',
          accessor: 'D',
        },
        {
          Header: 'DW%',
          accessor: 'DW%',
        },
      ]

  const unSelectedColumns = !isSoccer
    ? [
        {
          Header: '#',
          accessor: 'ORDER',
        },
        {
          accessor: 'CHECKBOX',
          Header: '#',
          Cell: ({ row }) => {
            const { original } = row
            return (
              <FormInput
                wrapperClass={css`
                  position: relative;
                  top: -6px;
                `}
                onChange={(e) => {
                  const myData = [...placementOrderData]
                  updateCheckedById(
                    myData,
                    original?.team_id,
                    e?.target?.checked
                  )
                  setPlacementOrderData(myData)
                }}
                input={{
                  name: `is_checked_${original?.team_id}`,
                  label: ' ',
                  type: 'checkbox',
                  checked: original?.checked,
                }}
              />
            )
          },
        },
        {
          Header: 'TEAM',
          accessor: 'TEAM',
        },
        {
          Header: 'GP',
          accessor: 'GP',
        },
        {
          Header: 'W',
          accessor: 'W',
        },
        {
          Header: 'L',
          accessor: 'L',
        },
        {
          Header: 'T',
          accessor: 'T',
        },
        {
          Header: 'WPCTD',
          accessor: 'WPCTD',
        },
      ]
    : [
        {
          Header: '#',
          accessor: 'ORDER',
        },
        {
          accessor: 'CHECKBOX',
          Header: '#',
          Cell: ({ row }) => {
            const { original } = row
            return (
              <FormInput
                wrapperClass={css`
                  position: relative;
                  top: -6px;
                `}
                onChange={(e) => {
                  const myData = [...placementOrderData]
                  updateCheckedById(
                    myData,
                    original?.team_id,
                    e?.target?.checked
                  )
                  setPlacementOrderData(myData)
                }}
                input={{
                  name: `is_checked_${original?.team_id}`,
                  label: ' ',
                  type: 'checkbox',
                  checked: original?.checked,
                }}
              />
            )
          },
        },
        {
          Header: 'TEAM',
          accessor: 'TEAM',
        },
        {
          Header: 'MP',
          accessor: 'MP',
        },
        {
          Header: 'W',
          accessor: 'W',
        },
        {
          Header: 'L',
          accessor: 'L',
        },
        {
          Header: 'D',
          accessor: 'D',
        },
        {
          Header: 'DW%',
          accessor: 'DW%',
        },
      ]

  // Define readData function outside of useEffect
  const readData = async () => {
    try {
      setLoadingPlacementOrder(true)
      let groupBy = 'Division'
      if (selectedCompObject?.type === 'Conference') {
        groupBy = 'Conference'
      }
      if (selectedCompObject?.type === 'Season') {
        groupBy = 'Season'
      }
      const { data } = await req(
        `/schedules/${regularSeason.division_id}/stats/tournament?groupBy=${groupBy}`
      )
      if (data && data?.length > 0) {
        !isSoccer
          ? data?.sort(
              (a, b) =>
                b?.stats?.find((stat) => stat?.type?.abbr === 'WPCTD')?.value -
                a?.stats?.find((stat) => stat?.type?.abbr === 'WPCTD')?.value
            )
          : data?.sort(
              (a, b) =>
                b?.stats?.find((stat) => stat?.type?.abbr === 'DW%')?.value -
                a?.stats?.find((stat) => stat?.type?.abbr === 'DW%')?.value
            )
        // add a property check === true for all
        data.forEach((d) => {
          d.checked = true
        })
        setPlacementOrderData(data)
        setOriginalPlacementOrderData(data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingPlacementOrder(false)
    }
  }

  useEffect(() => {
    if (regularSeason?.division_id) {
      readData()
    }
  }, [regularSeason])

  const reset = () => {
    setLoading(false)
    setNumGamesPlayed(0)
    setSyncModalOpen(false)
  }

  const hasUnselectedTeams = placementOrderData.filter((v) => !v?.checked)
  const hasSelectedTeams = placementOrderData.filter((v) => v?.checked)

  return (
    <TextModal
      css={css`
        transform: translateX(-${STANDINGS_WIDTH / 2}px);
        max-width: 500px;
        border-radius: 8px;
        h1 {
          width: 100%;
          font-size: 32px;
          font-weight: 700;
          line-height: 32px;
          text-transform: uppercase;
        }
      `}
      unmountOnClose
      onClose={reset}
      title='Sync Teams'
      link={(toggleOpen) => (
        <SuspensionSubmitButton
          width='200px'
          css={css`
            height: 42px;
            margin-left: 16px;
          `}
          type='button'
          onClick={() => {
            // Refresh data when modal is opened
            readData()
            toggleOpen()
          }}
          disabled={isDirty}
        >
          Sync Teams
        </SuspensionSubmitButton>
      )}
      titleSeparator={
        <div
          css={css`
            border-bottom: 1px solid ${colors.SOFT_STEEL};
            width: 100%;
            margin-top: -24px;
            margin-bottom: 24px;
          `}
        />
      }
    >
      {(toggleOpen) => {
        return (
          <div
            css={css`
              position: relative;
            `}
          >
            <Modal
              isOpen={syncModalOpen}
              onClose={() => {
                setSyncModalOpen(false)
              }}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-evenly;
                  max-width: 500px;
                  border-radius: 4px;
                  border: 2px solid red;
                  background-color: ${colors.HEADER};
                  padding: 16px;
                `}
              >
                <label
                  css={css`
                    align-self: center;
                    text-align: left;
                    font-size: 28px;
                    font-weight: bold;
                    ${font.title}
                    margin-bottom: 16px;
                  `}
                >
                  Warning!
                </label>
                {hasUnselectedTeams.length === 0 ? (
                  <label
                    css={css`
                      text-align: center;
                      margin-bottom: 16px;
                    `}
                  >
                    Are you sure you want to sync teams from the regular season?
                  </label>
                ) : null}
                {hasUnselectedTeams.length > 0 &&
                hasSelectedTeams.length > 0 ? (
                  <label
                    css={css`
                      text-align: center;
                      margin-bottom: 16px;
                      color: ${colors.WHITE};
                    `}
                  >
                    You have chosen to remove teams from this division. If you
                    proceed, game slots will be reset and will need to be
                    re-entered.
                  </label>
                ) : null}
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                  `}
                >
                  <FormButton
                    onClick={() => {
                      setSyncModalOpen(false)
                    }}
                    css={css`
                      border: 1px solid ${colors.ATTENDANCE_GRAY};
                      padding: 0 16px;
                    `}
                    disabled={loading}
                    busy={loading}
                  >
                    {'Cancel'}
                  </FormButton>
                  <FormButton
                    onClick={async () => {
                      try {
                        setLoading(true)
                        const teamsArray = placementOrderData
                          .filter((v) => v?.checked && v?.team !== null)
                          .map((obj) => {
                            return {
                              team_id: obj.team.id,
                            }
                          })
                        if (teamsArray.length === 0) {
                          alert('Please select at least one team')
                          setLoading(false)
                          return
                        }
                        await onSubmit(
                          numGamesPlayed,
                          carryOverSuspensions,
                          teamsArray
                        )
                        reset()
                        toggleOpen()
                      } catch (e) {
                        console.error(e)
                      } finally {
                        setSyncModalOpen(false)
                        setLoading(false)
                      }
                    }}
                    css={css`
                      border: 1px solid ${colors.FIERY_RED};
                      padding: 0 16px;
                      background-color: ${colors.FIERY_RED};
                    `}
                    disabled={loading}
                    busy={loading}
                  >
                    {'Confirm'}
                  </FormButton>
                </div>
              </div>
            </Modal>
            <form
              method='GET'
              action='/'
              onSubmit={async (e) => {
                e.preventDefault()
                setSyncModalOpen(true)
              }}
            >
              <p
                css={css`
                  margin-top: -8px !important;
                  margin-bottom: 16px !important;
                  font-size: 16px;
                  line-height: 22px;
                  font-weight: 500;
                  color: ${colors.WHITE};
                  ${font.body}
                `}
              >
                Are you sure you want to synchronize teams from regular season?
              </p>
              {regularSeason && (
                <Flex
                  as='a'
                  href={`${ROUTES.SCHEDULE_ROOT}/${regularSeason.id}/standings`}
                  target='_blank'
                  rel='noreferrer'
                  title='Open regular season in new tab'
                  alignItems='center'
                  justifyContent='space-between'
                  css={css`
                    border: 1px solid ${colors.SOFT_STEEL};
                    background: ${colors.HEADER};
                    border-radius: 8px;
                    padding: 12px 20px;
                    margin-bottom: 16px;
                    &,
                    i {
                      transition: border-color 0.15s ease-in-out,
                        color 0.15s ease-in-out;
                    }
                    &:hover {
                      border-color: ${colors.PRIMARY};
                      i {
                        color: ${colors.PRIMARY};
                      }
                    }
                    .name {
                      ${font.title}
                      font-weight: 500;
                      font-size: 18px;
                    }
                    .date {
                      font-size: 12px;
                      ${font.body}
                      color: ${colors.ATTENDANCE_GRAY};
                      margin-top: 4px;
                    }
                  `}
                >
                  <div>
                    <div className='name'>
                      {regularSeason?.name_full ||
                        regularSeason?.name ||
                        regularSeason?.name}
                    </div>
                    <div className='date'>
                      {dayjs(regularSeason.starts_at).format('MMM D, YYYY')} -{' '}
                      {dayjs(regularSeason.ends_at).format('MMM D, YYYY')}
                    </div>
                  </div>
                  <Icon name='external-link' fontSize={16} />
                </Flex>
              )}
              <p
                css={css`
                  margin-bottom: 16px !important;
                  font-size: 16px;
                  line-height: 22px;
                  font-weight: 500;
                  color: ${colors.WHITE};
                  ${font.body}
                `}
              >
                This action will replace current placeholders with teams and
                players from the associated division.
              </p>
              <div
                css={css`
                  border-bottom: 1px solid ${colors.SOFT_STEEL};
                  width: 100%;
                  margin-bottom: 16px;
                `}
              />
              <FormRow
                css={css`
                  label {
                    font-size: 14px;
                  }
                `}
              >
                <FormInput
                  inputStyles={css`
                    background: ${colors.HEADER};
                    border-radius: 4px;
                  `}
                  input={{
                    type: 'number',
                    name: 'min_games_played',
                    min: 0,
                    step: 1,
                    label: isSoccer ? 'Minimum Player MP' : 'Minimum Player GP',
                    required: true,
                    value: numGamesPlayed,
                    placeholder: 'Enter number of games',
                    disabled: loading,
                  }}
                  onChange={({ target }) => {
                    setNumGamesPlayed(target.value)
                  }}
                />
                <div
                  css={css`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin-left: 16px;
                    margin-top: 8px;
                  `}
                >
                  <FormInput
                    input={{
                      type: 'checkbox',
                      name: 'carry_over_suspensions',
                      label: 'Carry Over Suspensions',
                      value: carryOverSuspensions,
                      disabled: loading,
                    }}
                    onChange={({ target }) => {
                      setCarryOverSuspensions(target.checked)
                    }}
                  />
                </div>
              </FormRow>
              <div
                css={css`
                  border-bottom: 1px solid ${colors.SOFT_STEEL};
                  width: 100%;
                `}
              />
              <Flex
                alignItems='center'
                justifyContent='space-between'
                css={css`
                  margin-top: 32px;

                  .cancel {
                    background: ${colors.HEADER};
                  }
                  .submit {
                    background: ${colors.PRIMARY};
                  }
                `}
              >
                <SuspensionSubmitButton
                  disabled={loading}
                  className='cancel'
                  type='button'
                  onClick={() => {
                    reset()
                    toggleOpen()
                  }}
                >
                  Cancel
                </SuspensionSubmitButton>
                <SuspensionSubmitButton
                  busy={loading}
                  className='submit'
                  disabled={
                    loading ||
                    numGamesPlayed.length === 0 ||
                    hasSelectedTeams.length === 0 ||
                    hasSelectedTeams.length === 1
                  }
                  isSolid
                  css={css`
                    margin-left: 32px;
                  `}
                  type='submit'
                >
                  Sync
                </SuspensionSubmitButton>
              </Flex>
            </form>
            {regularSeason?.division_id && (
              <div
                css={css`
                  position: absolute;
                  top: -96px;
                  left: calc(100% + 64px);
                  max-height: 526px;
                  width: ${STANDINGS_WIDTH}px;
                  background: linear-gradient(#282e38, #181a1d);
                  box-shadow: rgb(0 0 0) 4px 0px 35px 0;
                  border-radius: 8px;
                  padding: 16px 16px 16px 16px;
                  overflow-y: auto;
                  overflow-x: hidden;

                  h2 {
                    font-size: 24px;
                    line-height: 24px;
                    ${font.title}
                    font-weight: 700;
                    margin-left: 8px;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    color: ${colors.WHITE};
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid ${colors.SOFT_STEEL};
                  `}
                >
                  <h2>Team Seed Order</h2>
                  <button
                    onClick={() => {
                      // Set all the checked property to true when reset is clicked
                      const data = originalPlacementOrderData?.map((v) => {
                        return {
                          ...v,
                          checked: true,
                        }
                      })
                      setPlacementOrderData(data)
                    }}
                    type='button'
                    css={css`
                      display: flex;
                      gap: 8px;
                      margin-top: 8px;
                    `}
                  >
                    <label
                      css={css`
                        font-size: 16px;
                        font-weight: 500;
                        color: ${colors.AMERICAN_YELLOW};
                      `}
                    >
                      Reset
                    </label>
                    <Icon
                      name='history'
                      color={colors.AMERICAN_YELLOW}
                      fontSize={15}
                      css={css`
                        margin-right: 11px;
                      `}
                    />
                  </button>
                </div>
                <Styles>
                  <PlacementOrderTable
                    data={tableData.filter((v) => v?.checked)}
                    columns={columns}
                    setData={setPlacementOrderData}
                  />
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: center;
                      margin-top: 16px;
                    `}
                  >
                    <label
                      css={css`
                        font-size: 16px;
                        font-weight: 700;
                        text-transform: uppercase;
                        ${font.title}
                      `}
                    >
                      Unselected Teams
                    </label>
                  </div>
                  <PlacementOrderTable
                    data={tableData.filter((v) => !v?.checked)}
                    columns={unSelectedColumns}
                    setData={setPlacementOrderData}
                  />
                </Styles>
              </div>
            )}
          </div>
        )
      }}
    </TextModal>
  )
}

export default SyncGameSlots
