/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import colors from '@sportninja/common/constants/appColors'
import { connect } from 'react-redux'
import {
  getGameStatusTypesBySportId,
  getAllGameTypes,
} from '@sportninja/common/selectors/types'
import req from '@sportninja/common/api/request'

import { Flex } from '../../Layout'
import { ReactComponent as DropdownArrow } from '../../Suspensions/dropdown.svg'
import { Pager } from '../../Pagination'
import { media, Mobile } from '../../Responsive'
import { font } from '../../css'
import ListStatusView from '../StatusView'
import useGetScheduleFiltering from '../useGetScheduleFiltering'
import GameListRow from './GameListRow'
import GameListFilters from './GameListFilters'
import MobileGameListRow from './MobileGameListRow'
import { useUrlManager } from '../../../hooks/useUrlManager'

/**
 * Checks if all parameters in dateRange and currentParams are empty.
 *
 * @param {Object} dateRange - The date range object.
 * @param {Object} currentParams - The current parameters object.
 * @return {boolean} Returns true if all parameters are empty, otherwise returns false.
 */
const isParamsAllEmpty = (dateRange, currentParams) => {
  for (let prop in dateRange) {
    if (dateRange[prop] !== null && dateRange[prop] !== false) {
      return false
    }
  }

  for (let prop in currentParams) {
    if (currentParams[prop] !== null && currentParams[prop] !== false) {
      return false
    }
  }

  return true
}

const DayOfGames = ({
  date,
  games = [],
  gameStatusTypes,
  gameTypes,
  isMobile,
  isSoccer = false,
  isHockey = false,
  onClickFirstGame = () => {},
  scheduleId = null,
}) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <div
      css={css`
        margin-bottom: 16px;

        ${media.mobile} {
          padding: 0 8px;
        }
      `}
    >
      <Flex
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        alignItems='center'
        justifyContent='space-between'
        className='day-of-games'
        css={css`
          height: 40px;
          border-radius: 8px;
          background-color: ${colors.HEADER};
          padding: 0 16px;
          margin-bottom: 16px;
          cursor: pointer;
          transition: filter 0.2s ease-in-out;
          &:hover {
            filter: brightness(1.2);
          }

          svg {
            transition: transform 0.1s ease-in-out;
            ${!isOpen && 'transform: rotate(180deg);'}
          }
        `}
      >
        <p
          css={css`
            font-weight: 700;
            text-transform: uppercase;
          `}
        >
          {date}
        </p>
        <DropdownArrow />
      </Flex>

      {isOpen &&
        games.map((game, idx) => {
          if (isMobile) {
            return (
              <MobileGameListRow
                key={game.id}
                hasNextSibling={games.length > 1 && idx !== games.length - 1}
                game={game}
                gameStatusTypes={gameStatusTypes}
                gameTypes={gameTypes}
                isSoccer={isSoccer}
                isHockey={isHockey}
                scheduleId={scheduleId}
              />
            )
          }

          return (
            <GameListRow
              key={game.id}
              hasNextSibling={games.length > 1 && idx !== games.length - 1}
              game={game}
              gameStatusTypes={gameStatusTypes}
              gameTypes={gameTypes}
              isSoccer={isSoccer}
              isHockey={isHockey}
              isFirstGame={idx === 0}
              onClickFirstGame={onClickFirstGame}
              scheduleId={scheduleId}
            />
          )
        })}
    </div>
  )
}

const GameList = ({
  team_id,
  schedule_id,
  gameStatusTypes,
  gameTypes,
  slug: _slug = '/games',
  refreshKey,
  setVisibleGameIds,
  areFiltersHidden,
  setFilterForExporting = () => {},
  isSoccer = false,
  isHockey = false,
  onClickFirstGame = () => {},
  // this is only for team filtering
  scheduleId = null,
}) => {
  const {
    currentParams,
    teams,
    team,
    setTeam,
    filters,
    dateRange,
    setDateRange,
    state,
    setState,
    sort,
    setSort,
    loaded,
  } = useGetScheduleFiltering(
    {
      team_id,
      schedule_id,
    },
    {
      autoSelectMostRecentComp: true,
      onScheduleChange: (id) => {
        setSlug(`/schedules/${id}/games`)
      },
    }
  )

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({})
  const [slug, setSlug] = useState(
    currentParams?.sn_division
      ? `/schedules/${currentParams.sn_division}/games`
      : currentParams?.sn_schedule
      ? `/schedules/${currentParams.sn_schedule}/games`
      : _slug
  )
  const { setParam } = useUrlManager()

  const lastDateRange = useRef({
    starts_at: undefined,
    ends_at: undefined,
  })

  const getItems = async (page) => {
    try {
      setLoading(true)
      const query = { page, order: sort, exclude_cancelled_games: 1 }
      if (team_id || team) {
        query.team_id = team_id || team
      }
      if (dateRange.starts_at && dateRange.ends_at) {
        query.starts_after = dayjs(dateRange.starts_at)
          .hour(0)
          .minute(0)
          .second(0)
          .format()

        query.starts_before = dayjs(dateRange.ends_at)
          .hour(0)
          .minute(0)
          .second(0)
          .add(1, 'day')
          .format()
      } else if (!currentParams.sn_sort) {
        query.default = 1
      }

      if (currentParams.sn_sort) {
        query.order = currentParams.sn_sort
      }

      setFilterForExporting(query)
      const response = await req(slug, { query })
      setItems(response.data)
      if (typeof setVisibleGameIds === 'function') {
        setVisibleGameIds(response.data.map((d) => d.id))
      }
      setPagination(response.meta.pagination)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const lastDateRangeWasSet =
      Object.values(lastDateRange.current).filter(
        (v) => typeof v !== 'undefined'
      ).length === 2
    if (dateRange.starts_at && dateRange.ends_at) {
      lastDateRange.current = dateRange
      getItems()
    } else if (
      lastDateRangeWasSet &&
      !dateRange.starts_at &&
      !dateRange.ends_at
    ) {
      getItems()
    }
  }, [dateRange.starts_at, dateRange.ends_at])

  useEffect(() => {
    if (loaded) {
      getItems()
    }
  }, [loaded, sort, team, slug, refreshKey])

  let dates = {}
  items.forEach((item) => {
    const day = dayjs(item.starts_at).format('dddd, MMM D, YYYY')
    dates[day] = dates[day] || []
    dates[day].push(item)
  })

  const today = dayjs()
  const isTodayEnabled =
    dateRange.starts_at &&
    dateRange.ends_at &&
    today.isSame(dateRange.starts_at, 'day') &&
    today.isSame(dateRange.ends_at, 'day')

  return (
    <div
      css={css`
        margin-top: 24px;
        margin-bottom: 84px;
      `}
    >
      <GameListFilters
        filters={filters}
        dateRange={dateRange}
        team_id={team_id}
        onTeamChange={setTeam}
        onScheduleChange={(scheduleId) => {
          if (scheduleId) {
            setSlug(`/schedules/${scheduleId}/games`)
          } else {
            setSlug(_slug)
          }
        }}
        setDateRange={setDateRange}
        state={state}
        setState={setState}
        sort={sort}
        setSort={(value) => {
          if (isTodayEnabled) {
            setDateRange({
              starts_at: undefined,
              ends_at: undefined,
            })
          }
          setSort(value)
        }}
        teams={teams}
        team={team}
        loading={loading}
        useDateRange
        useGameOrder
        isHidden={areFiltersHidden}
      >
        <Flex column noFlex>
          <Flex
            alignItems='flex-end'
            css={css`
              margin-top: 8px;
              padding: 0 16px 16px 0;
            `}
          >
            <Flex
              as='button'
              type='button'
              alignItems='center'
              id='game-list-todays-game'
              className={isTodayEnabled ? 'active' : ''}
              css={css`
                height: 40px;
                transition: background-color 0.2s ease-in-out;
                background-color: ${isTodayEnabled ? '#3F4753' : '#27303e'};
                border: 1px solid #3f4753;
                border-radius: 4px;
                padding: 0 16px;
                color: white;
                ${font.body}
                font-size: 14px;

                &:hover {
                  border-color: white;
                }
              `}
              onClick={() => {
                if (isTodayEnabled) {
                  setParam('sn_today', '')
                  setParam('sn_starts_at', '')
                  setParam('sn_ends_at', '')
                  setDateRange({
                    starts_at: undefined,
                    ends_at: undefined,
                  })
                } else {
                  const today = dayjs().hour(0).minute(0).second(0)
                  const tilTomorrow = today
                  setParam('sn_today', 'true')
                  setParam('sn_starts_at', dayjs(today).format('YYYY-MM-DD'))
                  setParam(
                    'sn_ends_at',
                    dayjs(tilTomorrow).format('YYYY-MM-DD')
                  )
                  setDateRange({
                    starts_at: today,
                    ends_at: tilTomorrow,
                  })
                }
              }}
            >
              Today&apos;s Games
              <Flex
                id='game-list-todays-game-toggle'
                className={isTodayEnabled ? 'active' : ''}
                alignItems='center'
                css={css`
                  position: relative;
                  height: 12px;
                  width: 22px;
                  transition: background-color 0.1s, border 0.1s ease-in-out;
                  ${isTodayEnabled &&
                  `background-color: ${colors.DEFAULT_FLAIR};`}
                  border: 2px solid white;
                  border-radius: 6px;
                  margin-left: 16px;

                  &.active {
                    border-color: ${colors.DEFAULT_FLAIR};
                  }
                `}
              >
                <div
                  id='game-list-todays-game-toggle-pip'
                  className={isTodayEnabled ? 'active' : ''}
                  css={css`
                    background-color: white;
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;

                    transition: transform 0.1s,
                      background-color 0.1s ease-in-out;
                    transform: translateX(${isTodayEnabled ? 11 : 1}px);

                    $.active {
                      background-color: ${colors.DEFAULT_FLAIR};
                    }
                  `}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </GameListFilters>
      {loading || items.length === 0 ? (
        <ListStatusView
          loading={loading}
          zeroItems={items.length === 0}
          noItemsText='games'
          error={error}
        />
      ) : (
        <>
          <Mobile>
            {(isMobile) => {
              return Object.keys(dates).map((date) => {
                const listOfGames = dates[date]
                return (
                  <DayOfGames
                    key={date}
                    date={date}
                    games={listOfGames}
                    gameStatusTypes={gameStatusTypes}
                    gameTypes={gameTypes}
                    isMobile={isMobile}
                    isSoccer={isSoccer}
                    isHockey={isHockey}
                    onClickFirstGame={onClickFirstGame}
                    scheduleId={schedule_id || scheduleId}
                  />
                )
              })
            }}
          </Mobile>
          {pagination && (
            <Pager
              onClick={getItems}
              page={pagination.current_page}
              total={pagination.total_pages}
            />
          )}
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    gameTypes: getAllGameTypes(state),
    gameStatusTypes: getGameStatusTypesBySportId(state, sportId),
  }
}

export default connect(mapStateToProps)(GameList)
