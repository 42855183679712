/** @jsxImportSource @emotion/react */
import { UserOutlined, LogoutOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Alert, App } from 'antd'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { EventCard } from 'src/components/RegistrationEventCard'
import Button from 'src/components/ts-components/Button'
import Text from 'src/components/ts-components/Text'
import { RegistrationContext } from '../context/RegistrationContext'
import { PageTitle, REGISTRATION_STEPS } from './Main'
import WaiversAgreement from '../WaiversAgreement'
import { EventProgress } from 'src/components/RegistrationEventProgress'
import { checkIfUserIsVerified } from './New_ConfirmYourEmail'
import { registerError } from 'src/utils/sentry'
import { getErrorMessage } from '@sportninja/common/utils/utils'

// Styled components
const Container = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  background-color: ${colors.NEUTRAL_800};
  width: 1080px;
  padding: 0 35px 35px 35px;
  border-radius: 16px 16px 0 0;

  @media (max-width: 768px) {
    min-width: 0;
    width: 100%;
  }
`

const EmailContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-top: 16px;
  margin-bottom: -8px;
`

const ButtonContainer = css`
  background-color: ${colors.NEUTRAL_800};
  z-index: 100;
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`

const ButtonGroup = css`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

const ButtonStyle = css`
  width: 200px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Waiver = () => {
  const {
    registration,
    setStep,
    email,
    updateSessionConfirmSubmission,
    updateSessionWaiver,
    teamOfficialType,
    role,
    division,
    couponInfo,
    session,
    updateSessionPayment,
    resetSession,
    isTeamRep,
  } = useContext(RegistrationContext)

  const isOneHundrerPercentCoupon = useMemo(() => {
    // Check if it's a 100% coupon or if the discount makes the cost zero
    const baseCost = isTeamRep ? division?.team_cost : division?.player_cost
    if (couponInfo?.discount === 100 && couponInfo?.type === 'percentage') {
      return true
    }
    if (couponInfo?.discount && baseCost) {
      return couponInfo?.discount >= baseCost
    }
    return false
  }, [
    couponInfo?.discount,
    couponInfo?.type,
    isTeamRep,
    division?.team_cost,
    division?.player_cost,
  ])

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const waivers = registration?.registration?.waivers
  const waiverRequired = registration?.registration?.show_waiver
  const { message } = App.useApp()
  const [allWaiversAgreed, setAllWaiversAgreed] = useState(false)
  const depositToCheck = isTeamRep
    ? division?.deposit
    : division?.player_deposit

  const singlePayment = isTeamRep
    ? division?.single_payment
    : division?.player_single_payment

  useEffect(() => {
    if (session?.signed_waiver) {
      setAllWaiversAgreed(true)
    }
  }, [session])

  const handleBack = useCallback(() => {
    setStep(REGISTRATION_STEPS.TEAM_REGISTER)
  }, [])

  const handleNext = useCallback(async () => {
    try {
      setIsLoading(true)
      await updateSessionWaiver(true)
      await updateSessionPayment()
      if (singlePayment || (depositToCheck && depositToCheck > 0)) {
        setIsLoading(false)
        if (singlePayment && isOneHundrerPercentCoupon) {
          checkIfUserIsVerified()
            .then(() => {
              updateSessionConfirmSubmission()
                .then(() => {
                  setIsLoading(false)
                  setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
                })
                .catch((e) => {
                  setIsLoading(false)
                  message.open({
                    type: 'error',
                    content:
                      'We were unable to confirm your submission. Please Contact SportNinja Support.',
                    duration: 6,
                  })
                  registerError(e, 'Error confirming submission', {
                    session: session,
                    division: division,
                    couponInfo: couponInfo,
                    teamOfficialType: teamOfficialType,
                    role: role,
                  })
                })
            })
            .catch((e) => {
              setTimeout(() => {
                setIsLoading(false)
                setStep(REGISTRATION_STEPS.CONFIRM_YOUR_EMAIL)
              }, 2000)
            })
        } else {
          setStep(REGISTRATION_STEPS.REGISTRATION_PAYMENT)
        }
      } else {
        checkIfUserIsVerified()
          .then(() => {
            updateSessionConfirmSubmission()
              .then(() => {
                setIsLoading(false)
                setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
              })
              .catch((e) => {
                setIsLoading(false)
                message.open({
                  type: 'error',
                  content:
                    'We were unable to confirm your submission. Please Contact SportNinja Support.',
                  duration: 6,
                })
                registerError(e, 'Error confirming submission', {
                  session: session,
                  division: division,
                  couponInfo: couponInfo,
                  teamOfficialType: teamOfficialType,
                  role: role,
                })
              })
          })
          .catch((e) => {
            setTimeout(() => {
              setIsLoading(false)
              setStep(REGISTRATION_STEPS.CONFIRM_YOUR_EMAIL)
            }, 2000)
          })
      }
    } catch (e) {
      setIsLoading(false)
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
      registerError(e, 'Error confirming submission', {
        session: session,
        division: division,
        couponInfo: couponInfo,
        teamOfficialType: teamOfficialType,
        role: role,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    couponInfo,
    depositToCheck,
    division,
    message,
    role,
    session,
    singlePayment,
    teamOfficialType,
  ])

  // Render helpers
  const renderEmailSection = useCallback(() => {
    if (!email) {
      return null
    }

    return (
      <div css={EmailContainer}>
        <a
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: default;
          `}
        >
          <UserOutlined
            css={css`
              color: ${colors.PRIMARY};
            `}
          />
          <Text
            variant='paragraphSmall'
            color={colors.PRIMARY}
            weight='regular'
          >
            {email}
          </Text>
        </a>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
          `}
          onClick={() => {
            resetSession()
            // await 1 second
            setTimeout(() => {
              window.location.reload()
            }, 500)
          }}
        >
          <LogoutOutlined
            css={css`
              color: ${colors.ERROR_200};
            `}
          />
          <Text
            variant='paragraphSmall'
            color={colors.ERROR_200}
            weight='regular'
          >
            Start Over
          </Text>
        </div>
      </div>
    )
  }, [email])

  return (
    <div css={Container}>
      {renderEmailSection()}
      <EventCard eventInfo={registration} />
      <EventProgress
        eventProgress={4}
        isWaiverRequired={waiverRequired}
        isTeamRep={isTeamRep}
      />
      <PageTitle
        title={'Agreement & Waivers'}
        subtitle={
          'To complete your registration, please review and agree to all required waivers. Scroll to the bottom of each waiver, check the box to confirm, and then proceed.'
        }
        showAlert={true}
        showSuccess={false}
      />
      <WaiversAgreement
        waivers={waivers}
        allWaiversAgreed={allWaiversAgreed}
        onAllWaiversAgreed={setAllWaiversAgreed}
      />
      {/* Removing for now, but we already have the component in the codebase */}
      {/* <SignaturePad /> */}
      {error && (
        <Alert
          message={
            error ||
            'We were unable to complete your registration. Please Contact SportNinja Support.'
          }
          type='error'
          showIcon
          css={css`
            margin-bottom: 16px;
          `}
        />
      )}
      <div css={ButtonContainer}>
        <div css={ButtonGroup}>
          <Button
            onClick={handleBack}
            text='BACK'
            variant='secondary'
            style={ButtonStyle}
          />
          <Button
            onClick={handleNext}
            text='ACCEPT'
            variant='primary'
            style={ButtonStyle}
            disabled={!allWaiversAgreed || isLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default Waiver
