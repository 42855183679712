/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { Spin } from 'antd'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import sportninjaLogo from './sportninja_dark.svg'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import Text from 'src/components/ts-components/Text'
import { useMobxStore } from 'src/state'
import AlmostThere from './components/AlmostThere'
import CheckYourEmail from './components/CheckYourEmail'
import ParentStep from './components/ParentStep'
import PlayerStep from './components/PlayerStep'
import UnderAgeError from './components/UnderAgeError'
import WelcomeStep from './components/WelcomeStep'
import WaiverStep from './components/WaiverStep'

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

type ResponseData = {
  id: string
  email: string
  name_first: string
  name_last: string
  name_full: string | null
  message: string | null
  sender: string
  role_type_id: number
  created_at: string
  sent_at: string | null
  accepted_at: string | null
  type: {
    id: string
    name: string
    name_full: string
  }
  destination: {
    id: string
    name: string
    name_full: string
  }
  show_waiver: boolean
  waiver_content: string | null
  birth_date: string
  player_id: string
  show_underage_workflow: boolean
}

interface Props {
  inviteId: string
  login: (values: { email: string; password: string }) => Promise<void>
  logout: () => Promise<void>
}

const validateFormUsingApi = async (data: any) => {
  const result = await req('/signup/signup-validate', {
    method: 'POST',
    body: JSON.stringify(data),
  })
  return result
}

const validateChildFormUsingApi = async (data: any) => {
  const result = await req('/signup/signup-validate', {
    method: 'POST',
    body: JSON.stringify(data),
  })
  return result
}

export const UnderageInvitation = ({ inviteId, login, logout }: Props) => {
  const {
    me: {
      fetchMe,
      setRegisterSuccess,
      state: { registerSuccess },
    },
  } = useMobxStore()

  const [step, setStep] = useState<number | undefined>(undefined)
  const [data, setData] = useState<ResponseData | null>(null)
  const [loading, setLoading] = useState(true)
  const [stepState, setStepState] = useState<number[]>([])
  const [error, setError] = useState<string | null>(null)
  const [createdUserId, setCreatedUserId] = useState<string | null>(null)

  // STEP 0 STATE
  const [radioOption, setRadioOption] = useState<'yes' | 'no'>('no')
  const [relationship, setRelationship] = useState<string>('')

  // STEP 1 STATE
  const [acceptWaiver, setAcceptWaiver] = useState(false)

  // STEP 2 STATE
  const [playerName, setPlayerName] = useState('')
  const [playerLastName, setPlayerLastName] = useState('')
  const [playerDOB, setPlayerDOB] = useState('')
  const [playerHeightFT, setPlayerHeightFT] = useState('')
  const [playerHeightIN, setPlayerHeightIN] = useState('')
  const [playerWeight, setPlayerWeight] = useState('')

  // STEP 3 STATE
  const [userName, setUserName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [userDOB, setUserDOB] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [acceptTerms, setAcceptTerms] = useState(false)

  // STEP 4 STATE
  const [timer, setTimer] = useState(0)

  // STEP 6 STATE
  const history = useHistory()

  // Errors
  const [fieldErrors, setFieldErrors] = useState<string[]>([])
  const [customError, setCustomError] = useState({})

  const setCustomErroByField = (field, lmessage) => {
    setCustomError((prev) => ({ ...prev, [field]: lmessage }))
    setFieldErrors((prevErrors) => [...new Set([...prevErrors, field])])
  }

  const setCustomErrors = useCallback((invalid_fields) => {
    // Parent Fields
    if (invalid_fields.name_first) {
      setCustomErroByField('name_first', invalid_fields.name_first)
    }
    if (invalid_fields.name_last) {
      setCustomErroByField('name_last', invalid_fields.name_last)
    }
    if (invalid_fields.email) {
      setCustomErroByField('email', invalid_fields.email)
    }
    if (invalid_fields.birth_date) {
      setCustomErroByField('birth_date', invalid_fields.birth_date)
    }
    if (invalid_fields.password) {
      setCustomErroByField('password', invalid_fields.password)
    }
    if (invalid_fields.confirm_password) {
      setCustomErroByField('confirm_password', invalid_fields.confirm_password)
    }
    // Child Fields
    if (invalid_fields['player.name_first']) {
      setCustomErroByField(
        'player.name_first',
        invalid_fields['player.name_first']
      )
    }
    if (invalid_fields['player.name_last']) {
      setCustomErroByField(
        'player.name_last',
        invalid_fields['player.name_last']
      )
    }
    if (invalid_fields['player.birth_date']) {
      setCustomErroByField(
        'player.birth_date',
        invalid_fields['player.birth_date']
      )
    }
    if (invalid_fields['player.weight']) {
      setCustomErroByField('player.weight', invalid_fields['player.weight'])
    }
  }, [])

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [timer])

  const loginUser = useCallback(async () => {
    setStep(6)

    try {
      await logout()
      await login({
        email: userEmail,
        password: password,
      })

      setRegisterSuccess(true)
      setStep(6)

      await delay(2000)
      fetchMe()
      await delay(1000)

      history.push(`${ROUTES.INVITE_V2_ROOT}${inviteId}`)
    } catch (e) {
      console.error(e)
      setError(JSON.stringify(e))
    }
  }, [userEmail, password, createdUserId])

  const onResendEmail = async (email: string) => {
    await req('/signup/resend', {
      method: 'POST',
      body: JSON.stringify({ email }),
      requiresAuth: false,
    })
    setTimer(30)
  }

  const onSubmit = useCallback(async () => {
    setCustomError({})
    setFieldErrors([])
    const player = {
      name_first: playerName,
      name_last: playerLastName,
      birth_date: playerDOB
        ? dayjs(playerDOB)?.toISOString()?.split('T')[0]
        : null,
      height: Number(`${playerHeightFT}.${playerHeightIN ?? 0}`) / 3.281,
      weight: playerWeight,
      id: data?.player_id,
    }
    const body = {
      name_first: userName,
      name_last: userLastName,
      birth_date: userDOB ? dayjs(userDOB)?.toISOString()?.split('T')[0] : null,
      email: userEmail,
      phone: userPhone,
      invitation_id: inviteId,
      password,
      confirm_password: confirmPassword,
    }
    try {
      await validateFormUsingApi({
        ...body,
        player: playerName ? { ...player } : null,
      })
      setLoading(true)
      const res = await req('/signup', {
        method: 'POST',
        body: JSON.stringify({
          ...body,
          player: playerName ? { ...player } : null,
        }),
        requiresAuth: false,
      })

      if (res) {
        setCreatedUserId(res.id)
      }

      if (res && data?.email !== userEmail) {
        setStep(4)
      } else if (res && data?.email === userEmail) {
        await loginUser()
      }
    } catch (e: any) {
      if (e?.invalid_fields) {
        setCustomErrors(e.invalid_fields)
        return
      }
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [
    playerName,
    playerLastName,
    playerDOB,
    playerHeightFT,
    playerHeightIN,
    playerWeight,
    data?.player_id,
    data?.email,
    userName,
    userLastName,
    userDOB,
    userEmail,
    userPhone,
    inviteId,
    password,
    confirmPassword,
    loginUser,
    setCustomErrors,
  ])

  const renderStep = useCallback(
    (receivedStep?: number) => {
      switch (receivedStep || step) {
        case 0:
          return (
            <WelcomeStep
              data={data}
              onNext={() => {
                if (radioOption === 'yes') {
                  setStep(5)
                  return
                }
                setStep(2)
                setStepState((prev) => [...prev, 0, 2])
              }}
              customError={customError}
              fieldErrors={fieldErrors}
              radioOption={radioOption}
              relationship={relationship}
              setRadioOption={(value: string) => {
                if (value === 'yes' || value === 'no') {
                  setRadioOption(value)
                }
              }}
              setRelationship={setRelationship}
              key={`welcome-step-${step}`}
            />
          )
        case 1:
          return (
            <WaiverStep
              acceptWaiver={acceptWaiver}
              setAcceptWaiver={setAcceptWaiver}
              stepState={stepState}
              data={data}
              onNext={() => {
                setStep(data?.show_underage_workflow ? 2 : 3)
                setStepState((prev) => [
                  ...prev,
                  data?.show_underage_workflow ? 2 : 3,
                ])
              }}
              onBack={() => {
                setStep(0)
                setStepState(stepState.filter((s) => s !== 0))
              }}
              key={`waiver-step-${step}`}
            />
          )
        case 2:
          return (
            <PlayerStep
              data={data}
              onNext={async () => {
                try {
                  setCustomError({})
                  setFieldErrors([])
                  await validateChildFormUsingApi({
                    player: {
                      name_first: playerName,
                      name_last: playerLastName,
                      birth_date: playerDOB
                        ? dayjs(playerDOB)?.toISOString()?.split('T')[0]
                        : null,
                      height:
                        Number(`${playerHeightFT}.${playerHeightIN ?? 0}`) /
                        3.281,
                      weight: playerWeight,
                      id: data?.id,
                      // jersey_size: playerJerseySize,
                    },
                  })
                  const numberRegex = /^[0-9]+$/
                  if (
                    (playerHeightFT !== '' &&
                      !numberRegex.test(playerHeightFT)) ||
                    (playerHeightIN !== '' &&
                      !numberRegex.test(playerHeightIN)) ||
                    (playerHeightFT !== '' && Number(playerHeightFT) < 0) ||
                    (playerHeightIN !== '' && Number(playerHeightIN) < 0)
                  ) {
                    setCustomErroByField('player.height', 'Height invalid')
                    return
                  }

                  if (
                    (playerWeight !== '' && !numberRegex.test(playerWeight)) ||
                    (playerWeight !== '' && Number(playerWeight) < 0)
                  ) {
                    setCustomErroByField('player.weight', 'Weight invalid')
                    return
                  }

                  setStep(3)
                  setStepState((prev) => [...prev, 3])
                } catch (e: any) {
                  if (e?.invalid_fields) {
                    setCustomErrors(e.invalid_fields)
                    return
                  }
                  const errorMessage = getErrorMessage(e)
                  setError(errorMessage)
                }
              }}
              onBack={() => {
                setStep(0)
                setStepState(stepState.filter((s) => s !== 2))
              }}
              playerDOB={playerDOB}
              playerHeightFT={playerHeightFT}
              playerHeightIN={playerHeightIN}
              playerWeight={playerWeight}
              playerName={playerName}
              playerLastName={playerLastName}
              setPlayerDOB={setPlayerDOB}
              setPlayerHeightFT={setPlayerHeightFT}
              setPlayerHeightIN={setPlayerHeightIN}
              setPlayerWeight={setPlayerWeight}
              setPlayerName={setPlayerName}
              setPlayerLastName={setPlayerLastName}
              validateForm={() => validateChildFormUsingApi({})}
              stepState={stepState}
              customError={customError}
              fieldErrors={fieldErrors}
              key={`player-step-${step}`}
            />
          )
        case 3:
          return (
            <ParentStep
              acceptTerms={acceptTerms}
              setAcceptTerms={setAcceptTerms}
              userName={userName}
              userLastName={userLastName}
              userDOB={userDOB}
              userPhone={userPhone}
              password={password}
              confirmPassword={confirmPassword}
              setUserName={setUserName}
              setUserLastName={setUserLastName}
              setUserDOB={setUserDOB}
              setUserPhone={setUserPhone}
              setPassword={setPassword}
              setConfirmPassword={setConfirmPassword}
              onNext={onSubmit}
              onBack={() => {
                setStep(2)
                setStepState(stepState.filter((s) => s !== 3))
              }}
              stepState={stepState}
              setUserEmail={setUserEmail}
              userEmail={userEmail}
              // @ts-ignore
              fieldErrors={fieldErrors}
              customError={customError}
              key={`parent-step-${step}`}
            />
          )
        case 4:
          return (
            <CheckYourEmail
              userEmail={userEmail}
              timer={timer}
              onResendEmail={onResendEmail}
            />
          )
        case 5:
          return <UnderAgeError />
        case 6:
          return <AlmostThere />
        default:
          return <Spin />
      }
    },
    [
      step,
      data,
      customError,
      fieldErrors,
      radioOption,
      relationship,
      acceptWaiver,
      stepState,
      playerDOB,
      playerHeightFT,
      playerHeightIN,
      playerWeight,
      playerName,
      playerLastName,
      acceptTerms,
      userName,
      userLastName,
      userDOB,
      userPhone,
      password,
      confirmPassword,
      onSubmit,
      userEmail,
      timer,
      setCustomErrors,
    ]
  )

  useLayoutEffect(() => {
    const fetchInfo = async () => {
      try {
        const response: { data: ResponseData } = await req(
          `/invitations/${inviteId}`
        )

        if (registerSuccess) {
          setStep(6)
        } else {
          setStep(0)
          setPlayerName(response.data.name_first ?? '')
          setPlayerLastName(response.data.name_last ?? '')
          setPlayerDOB(
            dayjs(response.data.birth_date).utc().format('YYYY-MM-DD') ?? ''
          )
          setUserEmail(response.data.email ?? '')
        }

        setData(response.data)
      } catch (e) {
        console.error(e)
        setError(JSON.stringify(e))
      } finally {
        setLoading(false)
      }
    }

    fetchInfo()
  }, [registerSuccess])

  if (loading) {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
        `}
      >
        <Spin />
      </div>
    )
  }

  if (!loading && !data) {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
        `}
      >
        <Text variant='displaySmall' weight='bold' color={colors.NEUTRAL_0}>
          Finishing...
        </Text>
      </div>
    )
  }

  if (error) {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
        `}
      >
        <Text
          variant='paragraphMedium'
          weight='semiBold'
          color={colors.ERROR_200}
        >
          {error || 'Something went wrong'}
        </Text>
      </div>
    )
  }

  return (
    <div
      css={css`
        padding: 60px;
        @media (max-width: 768px) {
          padding: 20px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <div>
        <img
          src={sportninjaLogo}
          alt='SportNinja'
          css={css`
            align-self: flex-start;
          `}
        />
        <div
          css={css`
            display: flex;
            margin-top: 20px;
            max-width: 1440px !important;
            min-width: 1200px !important;
            align-self: center;
            gap: 80px;

            @media (max-width: 768px) {
              flex-direction: column;
              max-width: 100% !important;
              min-width: 100% !important;
            }
          `}
        >
          <div
            css={css`
              flex: 1;

              @media (max-width: 768px) {
                border-right: none;
                padding-right: 0;
                margin-right: 0;
                min-height: 0;

                margin-bottom: 32px;
              }
            `}
          >
            {renderStep(step)}
          </div>

          {step !== 4 && step !== 5 && step !== 6 && (
            <div
              css={css`
                flex: 1;
                border-left: 1px solid ${colors.SOFT_STEEL};
                min-height: 60vh;
                @media (max-width: 768px) {
                  text-align: center;
                  border-left: none;
                  padding-left: 0;
                  margin-left: 0;
                  min-height: 0;
                }
              `}
            >
              <p
                css={css`
                  margin-left: 80px;
                `}
              >
                Or if you already have a SportNinja <br /> account{' '}
                <span
                  onClick={() => {
                    history.push(`${ROUTES.INVITE_V2_ROOT}${inviteId}`)
                  }}
                  css={css`
                    cursor: pointer;
                    color: ${colors.DEFAULT_FLAIR};
                    line-height: 150%;

                    &:hover {
                      text-decoration: underline;
                    }
                  `}
                >
                  log in
                </span>{' '}
                to accept the invite.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
