/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useEffect, useRef, useState } from 'react'
import {
  paragraphSmall,
  paragraphXSmall,
  weightRegular,
} from 'src/components/css'

const WAIVER_HEIGHT = 150

const Waiver = ({ hasScrolled, setHasScrolled, waiverData }) => {
  const divRef = useRef(null)
  const [divHeight, setDivHeight] = useState(0)

  const onScroll = (event) => {
    if (hasScrolled) {
      return
    }

    if (
      event.currentTarget.scrollTop + WAIVER_HEIGHT >=
      event.currentTarget.scrollHeight
    ) {
      setHasScrolled(true)
    }
  }

  useEffect(() => {
    if (divRef.current) {
      setDivHeight(divRef.current.offsetHeight)
    }
  }, [waiverData]) // Recalculate height whenever waiverData changes

  useEffect(() => {
    if (divHeight && divHeight > 0) {
      if (divHeight <= WAIVER_HEIGHT) {
        setHasScrolled(true)
      }
    }
  }, [divHeight]) // Recalculate height whenever divHeight changes

  return (
    <>
      <div
        css={css`
          ${paragraphSmall}
          ${weightRegular}
          color: ${colors.WHITE};
          align-self: flex-start;
          margin-bottom: -16px;
        `}
      >
        Waiver:
      </div>
      <div
        onScroll={onScroll}
        css={css`
          line-height: 17px;
          font-size: 14px;
          height: ${WAIVER_HEIGHT}px;
          overflow: auto;
          border: 1px solid ${colors.SOFT_STEEL};
          border-radius: 4px;
          padding: 8px 16px;
          scrollbar-color: white;
          background-color: ${colors.HEADER};

          p {
            ${paragraphXSmall}
            ${weightRegular}
            color: ${colors.WHITE};
            margin: 8px 0;
            font-family: 'Inter', Arial, Helvetica, sans-serif !important;
          }
        `}
      >
        {waiverData ? (
          <div
            ref={divRef}
            dangerouslySetInnerHTML={{
              __html: waiverData?.replace(/\n/g, '<br />'),
            }}
          />
        ) : null}
      </div>
      {/* <div
        css={css`
          ${paragraphMedium}
          ${weightRegular}
          color: ${colors.WHITE};
          align-self: flex-start;
        `}
      >
        Read to the bottom of the waiver before joining.
      </div> */}
    </>
  )
}

export default Waiver
