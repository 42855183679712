/**
 * Custom error class for session-related errors
 */
class SessionError extends Error {
  constructor(message: string, public code: string) {
    super(message)
    this.name = 'SessionError'
  }
}

export default SessionError
