/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useState } from 'react'
import colors from '@sportninja/common/constants/appColors'

import { Flex } from '../../Layout'
import { zIndex } from '../../css'
import { media } from '../../Responsive'
import { Overlay } from '../../Sheet/css'
import OptionButtonIcon from './Icon'

export const MoreOptionListItem = ({
  onClick,
  textColor,
  iconName,
  title,
  iconColor,
  faType,
}) => {
  return (
    <Flex
      className='more-option-list-item'
      alignItems='center'
      onClick={onClick}
      css={css`
        background-color: ${colors.HEADER};
        padding: 12px 20px;

        cursor: pointer;

        p,
        i {
          transition: color 0.15s ease-in-out;
          color: ${textColor};
        }

        transition: background-color 0.15s ease-in-out;

        &:hover {
          background-color: ${colors.SOFT_STEEL};

          p,
          i {
            color: white;
          }
        }
      `}
    >
      <OptionButtonIcon
        name={iconName}
        title={title}
        faType={faType}
        color={iconColor}
        css={css`
          margin-right: 8px;
        `}
      />
      <p
        css={css`
          text-transform: capitalize;
        `}
      >
        {title}
      </p>
    </Flex>
  )
}

const MoreOptions = ({ options: _options = [] }) => {
  const options = _options.filter((opt) => {
    // Option can resolve to undefined (e.g. did not pass permission check)
    // so filter those out here
    if (typeof opt !== 'object') return false
    return Object.values(opt)?.length > 0
  })

  if (options.length === 0) return false

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {isOpen && (
        <Overlay
          className='overlay-enter-done'
          onClick={() => setIsOpen(false)}
          css={css`
            background-color: transparent;
            cursor: initial;
          `}
        />
      )}
      <Flex
        noFlex
        alignItems='center'
        justifyContent='center'
        css={css`
          position: relative;
        `}
        className={isOpen ? 'is-open' : ''}
      >
        <button
          type='button'
          className='option-button-delete-btn'
          css={css`
            width: 20px;

            ${isOpen &&
            `
            i {
              color: white;
            }
          `}

            &:hover {
              i {
                color: white;
              }
            }
          `}
          onClick={() => {
            setIsOpen((o) => !o)
          }}
        >
          <OptionButtonIcon name='ellipsis-v' title='More options' />
        </button>
        <div
          css={css`
            position: absolute;
            top: 30px;
            right: 0;
            width: max-content;
            border: 1px solid ${colors.SOFT_STEEL};
            box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
            overflow: hidden;
            z-index: ${zIndex.overlay + 1};
            transition: opacity 0.2s ease-in-out;
            border-radius: 4px;

            opacity: 0;
            visibility: hidden;

            .is-open & {
              visibility: visible;
              opacity: 1;
            }

            .more-option-list-item {
              &:not(:last-of-type) {
                border-bottom: 1px solid ${colors.SOFT_STEEL};
              }
            }

            ${media.mobile} {
              top: -50px;
            }
          `}
        >
          {options.map((option) => {
            const {
              key,
              title,
              onClick,
              iconName,
              iconColor = 'white',
              textColor = 'white',
              Component,
              componentProps,
            } = option

            if (Component) {
              return (
                <Component
                  key={key}
                  option={option}
                  onToggle={() => setIsOpen(false)}
                  {...componentProps}
                />
              )
            }

            return (
              <MoreOptionListItem
                key={key}
                title={title}
                onClick={() => {
                  setIsOpen(false)
                  onClick()
                }}
                iconName={iconName}
                iconColor={iconColor}
                textColor={textColor}
              />
            )
          })}
        </div>
      </Flex>
    </>
  )
}

export default MoreOptions
