/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { UnorderedListOutlined } from '@ant-design/icons'
import colors from '@sportninja/common/constants/appColors'
import Text from 'src/components/ts-components/Text'
import { SwitchInput } from 'src/components/ts-components/SwitchInput'

interface DraggableItemProps {
  id: string
  label: string
  subtitle: string
  isEnabled: boolean
  onToggle: (id: string, enabled: boolean) => void
}

const DraggableItem = ({
  id,
  label,
  subtitle,
  isEnabled,
  onToggle,
}: DraggableItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        padding: 8px 0;
        cursor: grab;
        &:active {
          cursor: grabbing;
        }
      `}
    >
      <div
        {...attributes}
        {...listeners}
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          color: ${colors.NEUTRAL_50};
          cursor: grab;
          &:active {
            cursor: grabbing;
          }
        `}
      >
        <UnorderedListOutlined
          css={css`
            color: ${colors.NEUTRAL_50};
            font-size: 16px;
          `}
        />
      </div>
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 2px;
        `}
      >
        <Text variant='paragraphSmall' weight='regular' color={colors.WHITE}>
          {label}
        </Text>
        <Text
          variant='paragraphXSmall'
          weight='regular'
          color={colors.NEUTRAL_40}
        >
          {subtitle}
        </Text>
      </div>
      <SwitchInput
        value={isEnabled}
        onChange={(value) => onToggle(id, value)}
        title=''
        subtitle=''
      />
    </div>
  )
}

export default DraggableItem
