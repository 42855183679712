/** @jsxImportSource @emotion/react */
import { DeleteOutlined } from '@ant-design/icons'
import { Alert, Input, Select } from 'antd'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Text from 'src/components/ts-components/Text'
import dayjs from 'dayjs'
import { buttonStyle } from '../steps/Main'
import {
  headingSixStyle,
  paragraphSmallStyle,
  paragraphXSmallStyle,
} from '../steps/Main'
import PhoneInput from 'src/components/ts-components/PhoneInput'
import { TextInput } from 'src/components/ts-components/TextInput'
import { TextAreaInput } from 'src/components/ts-components/TextAreaInput'
import Button from 'src/components/ts-components/Button'

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

const TeamOfficialForm = ({
  teamPhone,
  setTeamPhone,
  teamEmail,
  setTeamEmail,
  teamWebsite,
  setTeamWebsite,
  teamNotes,
  setTeamNotes,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  birthDate,
  setBirthDate,
  country,
  setCountry,
  zipCode,
  setZipCode,
  countries,
  fieldErrors,
  customError,
  isAddingTeamOfficial,
  setIsAddingTeamOfficial,
  additionalTeamOfficials,
  setAdditionalTeamOfficials,
  newTeamOfficial,
  setNewTeamOfficial,
  handleAddTeamOfficial,
  selectedPlayer,
}) => {
  return (
    <>
      {/* Team Rep */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          align-self: stretch;
          margin-top: 16px;
          gap: 16px;
        `}
      >
        {/* <Text
          variant='captionLarge'
          color={colors.WHITE}
          weight='semiBold'
          style={`
            align-self: stretch;
          `}
        >
          Team Info
        </Text> */}
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            /* on mobile, change direction */
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-self: stretch;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8x;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <PhoneInput
              label='Phone'
              name='teamPhone'
              autoComplete='given-name'
              value={teamPhone}
              onChange={(e) => setTeamPhone && setTeamPhone(e)}
              error={
                fieldErrors?.includes('team_phone')
                  ? customError.team_phone
                  : ''
              }
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <TextInput
              label='Email'
              name='teamEmail'
              value={teamEmail}
              setValue={(e) => setTeamEmail && setTeamEmail(e)}
              type='email'
              customError={customError}
              fieldErrors={fieldErrors}
              autoComplete='email'
            />
          </div>
        </div>
        {/* <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            width: 49.5%;
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-self: stretch;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <TextInput
              label='Website'
              name='teamWebsite'
              value={teamWebsite}
              setValue={(e) => setTeamWebsite && setTeamWebsite(e)}
              type='url'
              customError={customError}
              fieldErrors={fieldErrors}
            />
          </div>
        </div> */}
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            width: 100%;
            /* on mobile, change direction */
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-self: stretch;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <TextAreaInput
              label='Notes'
              name='teamNotes'
              value={teamNotes}
              setValue={(e) => setTeamNotes && setTeamNotes(e)}
              customError={customError}
              fieldErrors={fieldErrors}
            />
          </div>
        </div>
        <Text
          variant='captionLarge'
          color={colors.WHITE}
          weight='semiBold'
          style={`
            align-self: stretch;
          `}
        >
          Team Official Info
        </Text>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            /* on mobile, change direction */
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-self: stretch;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              First Name *
            </Text>
            <Input
              name='firstName'
              placeholder='John'
              type='text'
              autoComplete='given-name'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={selectedPlayer?.name_first || firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={true}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              Last Name *
            </Text>
            <Input
              name='lastName'
              placeholder='Doe'
              type='text'
              autoComplete='family-name'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={selectedPlayer?.name_last || lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={true}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-self: stretch;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              Email *
            </Text>
            <Input
              name='email'
              placeholder='john.doe@me.com'
              type='email'
              autoComplete='email'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={true}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              Birthdate *
            </Text>
            <Input
              name='birthdate'
              placeholder='01/01/2000'
              type='text'
              autoComplete='birthdate'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={
                selectedPlayer?.date_of_birth || birthDate
                  ? `${String(
                      new Date(
                        selectedPlayer?.date_of_birth || birthDate
                      ).getUTCDate()
                    ).padStart(2, '0')}-${String(
                      new Date(
                        selectedPlayer?.date_of_birth || birthDate
                      ).getUTCMonth() + 1
                    ).padStart(2, '0')}-${new Date(
                      selectedPlayer?.date_of_birth || birthDate
                    ).getUTCFullYear()}`
                  : '-'
              }
              onChange={(e) => setBirthDate(e.target.value)}
              disabled={true}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-self: stretch;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              Country *
            </Text>
            <Select
              css={css`
                width: 100%;
                height: 36px;
              `}
              status={
                fieldErrors?.includes('country_code') ? 'error' : 'default'
              }
              showSearch
              filterOption={filterOption}
              placeholder='Select a country'
              onChange={(e) => {
                setCountry(e)
              }}
              value={country}
              options={
                countries
                  ? countries.map((el) => {
                      return {
                        label: el?.name,
                        value: el?.iso_3166_2,
                      }
                    })
                  : []
              }
              allowClear={true}
            />
            <div
              tabIndex={-1}
              css={css`
                transition: opacity 0.1s ease-in-out;
                opacity: ${fieldErrors?.includes('country_code') ? 1 : 0};
                font-size: 13px;
                line-height: 16px;
                color: ${colors.ERROR_LIGHT};
                align-self: stretch;
                margin-top: 8px;
              `}
            >
              <span>{customError.country_code}</span>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <Text
              variant='paragraphSmall'
              color={colors.WHITE}
              weight='regular'
            >
              Zip/Postal Code *
            </Text>
            <Input
              status={
                fieldErrors?.includes('postal_code') ? 'error' : 'default'
              }
              name='zipCode'
              placeholder=''
              type='text'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              css={css`
                height: 36px;
              `}
              autoComplete='postal-code'
            />
            <div
              tabIndex={-1}
              css={css`
                transition: opacity 0.1s ease-in-out;
                opacity: ${fieldErrors?.includes('postal_code') ? 1 : 0};
                font-size: 13px;
                line-height: 16px;
                color: ${colors.ERROR_LIGHT};
                align-self: stretch;
                margin-top: 8px;
              `}
            >
              <span>{customError.postal_code}</span>
            </div>
          </div>
        </div>
        {fieldErrors?.includes(
          'team_official_array.0.team_official_type_id'
        ) ? (
          <div
            tabIndex={-1}
            css={css`
              transition: opacity 0.1s ease-in-out;
              opacity: ${fieldErrors?.includes(
                'team_official_array.0.team_official_type_id'
              )
                ? 1
                : 0};
              font-size: 13px;
              line-height: 16px;
              color: ${colors.ERROR_LIGHT};
              align-self: stretch;
            `}
          >
            <span>
              {customError['team_official_array.0.team_official_type_id']}
            </span>
          </div>
        ) : null}
        <div
          css={css`
            width: 100%;
            height: 1px;
            background: ${colors.VERY_DARK_GRAYISH_BLUE};
          `}
        />
      </div>
      {/* Additional Team Officials */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          margin-top: 16px;
          margin-bottom: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            justify-content: space-between;
          `}
        >
          <Text
            variant='paragraphMedium'
            color={colors.WHITE}
            weight='semiBold'
            style={`
                align-self: stretch;
            `}
          >
            Additional Team Officials
          </Text>
          <p
            css={css`
              ${paragraphXSmallStyle}
              color: ${colors.PRIMARY};
              cursor: pointer;
              text-transform: capitalize;
            `}
            onClick={() => {
              setIsAddingTeamOfficial(!isAddingTeamOfficial)
            }}
          >
            {!isAddingTeamOfficial ? 'Add Team Official' : 'Cancel'}
          </p>
        </div>
        {!isAddingTeamOfficial && additionalTeamOfficials?.length === 0 ? (
          <p
            css={css`
              color: ${colors.WHITE};
              ${paragraphSmallStyle}
              width: 100%;
              height: 150px;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            No Additional Team Officials
          </p>
        ) : null}
        {!isAddingTeamOfficial && additionalTeamOfficials?.length > 0 ? (
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            {additionalTeamOfficials.map((official, index) => (
              <div
                key={index}
                css={css`
                  display: flex;
                  padding: 8px 16px;
                  justify-content: space-between;
                  align-items: center;
                  flex: 1 0 0;
                  border-radius: 4px;
                  background: ${colors.VERY_DARK_GRAYISH_BLUE};
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                  `}
                >
                  <Text
                    variant='paragraphSmall'
                    color={colors.WHITE}
                    weight='regular'
                  >
                    {official.firstName} {official.lastName}
                  </Text>
                  <Text
                    variant='paragraphSmall'
                    color={colors.WHITE}
                    weight='regular'
                  >
                    Team Official
                  </Text>
                </div>
                <DeleteOutlined
                  onClick={() => {
                    setAdditionalTeamOfficials(
                      additionalTeamOfficials.filter((el, i) => i !== index)
                    )
                  }}
                  css={css`
                    color: ${colors.ERROR_LIGHT};
                    cursor: pointer;
                  `}
                  size={24}
                />
              </div>
            ))}
          </div>
        ) : null}
        {isAddingTeamOfficial ? (
          <>
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                  @media (max-width: 768px) {
                    flex-direction: column;
                    width: 100%;
                    align-self: stretch;
                  }
                `}
              >
                <Text
                  variant='paragraphSmall'
                  color={colors.WHITE}
                  weight='regular'
                >
                  First Name *
                </Text>
                <Input
                  name='newTeamOfficialFirstName'
                  placeholder='John'
                  type='text'
                  autoComplete='given-name'
                  autoCorrect='off'
                  autoCapitalize='none'
                  required
                  value={newTeamOfficial.firstName}
                  onChange={(e) =>
                    setNewTeamOfficial({
                      ...newTeamOfficial,
                      firstName: e.target.value,
                    })
                  }
                  status={
                    fieldErrors?.includes('additionalTeamOfficialsFirstName')
                      ? 'error'
                      : undefined
                  }
                />
                <div
                  tabIndex={-1}
                  css={css`
                    margin-top: 8px;
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${fieldErrors?.includes(
                      'additionalTeamOfficialsFirstName'
                    )
                      ? 1
                      : 0};
                    font-size: 13px;
                    line-height: 16px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>{customError.additionalTeamOfficialsFirstName}</span>
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                  @media (max-width: 768px) {
                    flex-direction: column;
                    width: 100%;
                    align-self: stretch;
                  }
                `}
              >
                <Text
                  variant='paragraphSmall'
                  color={colors.WHITE}
                  weight='regular'
                >
                  Last Name *
                </Text>
                <Input
                  name='newTeamOfficialLastName'
                  placeholder='Doe'
                  type='text'
                  autoComplete='family-name'
                  autoCorrect='off'
                  autoCapitalize='none'
                  required
                  value={newTeamOfficial.lastName}
                  onChange={(e) =>
                    setNewTeamOfficial({
                      ...newTeamOfficial,
                      lastName: e.target.value,
                    })
                  }
                  status={
                    fieldErrors?.includes('additionalTeamOfficialsLastName')
                      ? 'error'
                      : undefined
                  }
                />
                <div
                  tabIndex={-1}
                  css={css`
                    margin-top: 8px;
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${fieldErrors?.includes(
                      'additionalTeamOfficialsLastName'
                    )
                      ? 1
                      : 0};
                    font-size: 13px;
                    line-height: 16px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>{customError.additionalTeamOfficialsLastName}</span>
                </div>
              </div>
            </div>
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
                /* on mobile, change the direction */
                @media (max-width: 768px) {
                  flex-direction: column;
                  align-items: stretch;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                `}
              >
                <Text
                  variant='paragraphSmall'
                  color={colors.WHITE}
                  weight='regular'
                >
                  Email *
                </Text>
                <Input
                  name='newTeamOfficialEmail'
                  placeholder='john.doe@me.com'
                  type='email'
                  autoComplete='email'
                  autoCorrect='off'
                  autoCapitalize='none'
                  required
                  value={newTeamOfficial.email}
                  onChange={(e) =>
                    setNewTeamOfficial({
                      ...newTeamOfficial,
                      email: e.target.value,
                    })
                  }
                  status={
                    fieldErrors?.includes('additionalTeamOfficialsEmail')
                      ? 'error'
                      : undefined
                  }
                />
                <div
                  tabIndex={-1}
                  css={css`
                    margin-top: 8px;
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${fieldErrors?.includes(
                      'additionalTeamOfficialsEmail'
                    )
                      ? 1
                      : 0};
                    font-size: 13px;
                    line-height: 16px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>{customError.additionalTeamOfficialsEmail}</span>
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                `}
              >
                <Text
                  variant='paragraphSmall'
                  color={colors.WHITE}
                  weight='regular'
                >
                  Birthdate *
                </Text>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    flex: 1;
                    height: 40px;
                    width: 100%;
                    flex-direction: row;
                    @media (max-width: 600px) {
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      align-self: stretch;
                    }
                  `}
                >
                  <Input
                    name='dateOfBirthDay'
                    placeholder='DD'
                    type='number'
                    autoComplete='bday-day'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={newTeamOfficial.birthDateDay}
                    onChange={(e) =>
                      setNewTeamOfficial({
                        ...newTeamOfficial,
                        birthDateDay: e.target.value,
                      })
                    }
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value, 10))
                        .toString()
                        .slice(0, 2)
                    }}
                    status={
                      fieldErrors?.includes('additionalTeamOfficialsBirthDate')
                        ? 'error'
                        : undefined
                    }
                    maxLength={2}
                    min={1}
                    max={31}
                  />
                  <Input
                    name='dateOfBirthMonth'
                    placeholder='MM'
                    type='number'
                    autoComplete='bday-month'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={newTeamOfficial.birthDateMonth}
                    onChange={(e) =>
                      setNewTeamOfficial({
                        ...newTeamOfficial,
                        birthDateMonth: e.target.value,
                      })
                    }
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value, 10))
                        .toString()
                        .slice(0, 2)
                    }}
                    status={
                      fieldErrors?.includes('additionalTeamOfficialsBirthDate')
                        ? 'error'
                        : undefined
                    }
                    maxLength={2}
                    min={1}
                    max={12}
                  />

                  <Input
                    name='dateOfBirthYear'
                    placeholder='YYYY'
                    type='number'
                    autoComplete='bday-year'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={newTeamOfficial.birthDateYear}
                    onChange={(e) =>
                      setNewTeamOfficial({
                        ...newTeamOfficial,
                        birthDateYear: e.target.value,
                      })
                    }
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value, 10))
                        .toString()
                        .slice(0, 4)
                    }}
                    status={
                      fieldErrors?.includes('additionalTeamOfficialsBirthDate')
                        ? 'error'
                        : undefined
                    }
                    maxLength={4}
                    min={1900}
                    max={dayjs().year()}
                  />
                </div>
                <div
                  css={css`
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${fieldErrors?.includes(
                      'additionalTeamOfficialsBirthDate'
                    )
                      ? 1
                      : 0};
                    font-size: 13px;
                    line-height: 16px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>{customError.additionalTeamOfficialsBirthDate}</span>
                </div>
              </div>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 8px;
                width: 25%;
                align-self: flex-end;
                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              <Button
                variant='primary'
                text='Add Team Official'
                onClick={async () => {
                  handleAddTeamOfficial()
                }}
              />
            </div>
            <div
              css={css`
                width: 100%;
                height: 1px;
                background: ${colors.VERY_DARK_GRAYISH_BLUE};
              `}
            />
          </>
        ) : null}
        {fieldErrors?.includes('additionalTeamOfficialsFirstName') ? (
          <div
            css={css`
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              max-width: 500px;
              align-self: center;
              flex: 1;
            `}
          >
            <Alert
              message={customError.additionalTeamOfficialsFirstName}
              type='error'
              showIcon
            />
          </div>
        ) : null}
        {fieldErrors?.includes('additionalTeamOfficialsLastName') ? (
          <div
            css={css`
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              max-width: 500px;
              align-self: center;
              flex: 1;
            `}
          >
            <Alert
              message={customError.additionalTeamOfficialsLastName}
              type='error'
              showIcon
            />
          </div>
        ) : null}
        {fieldErrors?.includes('additionalTeamOfficialsEmail') ? (
          <div
            css={css`
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              align-self: center;
              flex: 1;
            `}
          >
            <Alert
              message={customError.additionalTeamOfficialsEmail}
              type='error'
              showIcon
            />
          </div>
        ) : null}
        {fieldErrors?.includes('additionalTeamOfficialsBirthDate') ? (
          <div
            css={css`
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              max-width: 500px;
              align-self: center;
              flex: 1;
            `}
          >
            <Alert
              message={customError.additionalTeamOfficialsBirthDate}
              type='error'
              showIcon
            />
          </div>
        ) : null}
      </div>
    </>
  )
}

export default TeamOfficialForm
