/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Checkbox } from 'antd'
import React from 'react'
import type { CheckboxProps } from 'antd'
import { paragraphSmall, weightRegular } from '../../../../src/components/css'
import colors from '@sportninja/common/constants/appColors'

type CheckBoxInputProps = {
  title: string
  value: boolean
  onChange: CheckboxProps['onChange']
}

export const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
  title,
  value,
  onChange,
}) => {
  return (
    <>
      <Checkbox onChange={onChange} checked={value} />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;
        `}
      >
        <p
          css={css`
            ${paragraphSmall}
            ${weightRegular}
            color: ${colors.WHITE};
            align-self: stretch;
          `}
        >
          {title}
        </p>
      </div>
    </>
  )
}
