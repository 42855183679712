import { call, put, select } from 'redux-saga/effects'

import actions from '../actions/scoring'
import { generateSagas, wait } from './utils'
import attemptScoringRequest from './attempt-scoring-request'
import scoringRequest from './scoring-request'

const api = {
  assists: {
    create: async (goalId, body) => {
      return await scoringRequest(`/goals/${goalId}/assists`, {
        method: 'POST',
        body,
      })
    },
    update: async (assistId, body) => {
      return await scoringRequest(`/assists/${assistId}`, {
        method: 'PUT',
        body,
      })
    },
    delete: async (assistId) => {
      return await scoringRequest(`/assists/${assistId}`, { method: 'DELETE' })
    },
  },
  goals: {
    create: async (id, periodId, body) => {
      return await scoringRequest(`/games/${id}/periods/${periodId}/goals`, {
        method: 'POST',
        body,
      })
    },
    update: async (id, body) => {
      return await scoringRequest(`/goals/${id}`, { method: 'PUT', body })
    },
    delete: async (id) => {
      return await scoringRequest(`/goals/${id}`, { method: 'DELETE' })
    },
  },
  goalieChanges: {
    create: async (id, periodId, body) => {
      // return await scoringRequest(
      //   `/games/${id}/periods/${periodId}/goaliechanges`,
      //   { method: 'POST', body }
      // )
      return await scoringRequest(
        `/games/${id}/periods/${periodId}/substitutions`,
        { method: 'POST', body }
      )
    },
    update: async (id, body) => {
      // return await scoringRequest(`/goaliechanges/${id}`, {
      //   method: 'PUT',
      //   body,
      // })
      return await scoringRequest(`/substitutions/${id}`, {
        method: 'PUT',
        body,
      })
    },
    // delete: async (id) => {
    //   return await scoringRequest(`/goaliechanges/${id}`, { method: 'DELETE' })
    // },
    delete: async (id) => {
      return await scoringRequest(`/substitutions/${id}`, { method: 'DELETE' })
    },
  },
  periods: {
    create: async (id, body) => {
      return await scoringRequest(`/games/${id}/periods`, {
        method: 'POST',
        body,
      })
    },
    update: async (id, periodId, body) => {
      return await scoringRequest(`/games/${id}/periods/${periodId}`, {
        method: 'PUT',
        body,
      })
    },
    delete: async (id, periodId) => {
      return await scoringRequest(`/games/${id}/periods/${periodId}`, {
        method: 'DELETE',
      })
    },
  },
  penalties: {
    create: async (id, periodId, body) => {
      return await scoringRequest(`/games/${id}/periods/${periodId}/offenses`, {
        method: 'POST',
        body,
      })
    },
    update: async (id, body) => {
      return await scoringRequest(`/offenses/${id}`, { method: 'PUT', body })
    },
    delete: async (id) => {
      return await scoringRequest(`/offenses/${id}`, { method: 'DELETE' })
    },
  },
  shots: {
    create: async (id, periodId, body) => {
      return await scoringRequest(`/games/${id}/periods/${periodId}/shots`, {
        method: 'POST',
        body,
      })
    },
    delete: async (id) => {
      return await scoringRequest(`/shots/${id}`, { method: 'DELETE' })
    },
  },
  signatures: {
    create: async (id, userId, body) => {
      return await scoringRequest(
        `/games/${id}/signatures?official_id=${userId}`,
        {
          method: 'POST',
          body,
        }
      )
    },
  },
}

const scoring = [
  [
    actions.periods.create,
    function* (payload) {
      const { id, form } = payload

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(
            api.periods.create,
            id,
            JSON.stringify(form)
          )
          yield put(actions.periods.create.success({ id, data: response.data }))

          return response
        },
        { type: actions.periods.create.request, payload }
      )
    },
  ],

  [
    actions.periods.update,
    function* (payload) {
      const { id, periodId, form } = payload

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(
            api.periods.update,
            id,
            periodId,
            JSON.stringify(form)
          )
          yield put(
            actions.periods.update.success({
              id,
              periodId,
              data: response.data,
            })
          )

          return response
        },
        { type: actions.periods.update.request, payload }
      )
    },
  ],

  [
    actions.periods.delete,
    function* (payload) {
      const { id, periodId } = payload

      const response = yield call(api.periods.delete, id, periodId)
      yield put(actions.periods.delete.success({ id, periodId }))

      return response
    },
  ],

  [
    actions.assists.create,
    function* (payload) {
      const { id, goalId, form, assistType } = payload
      const body = {
        game_id: id,
        assist_type_id: assistType,
        player_id: form.playerId,
      }

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(
            api.assists.create,
            goalId,
            JSON.stringify(body)
          )
          yield put(
            actions.assists.create.success({ id, goalId, data: response.data })
          )
        },
        { type: actions.assists.create.request, payload }
      )
    },
  ],

  [
    actions.assists.update,
    function* (payload) {
      const { id, goalId, form } = payload
      const body = { player_id: form.playerId }

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(
            api.assists.update,
            form.assistId,
            JSON.stringify(body)
          )

          // WTF? If game is included here, it breaks...
          const { game, ...filteredData } = response.data

          yield put(
            actions.assists.update.success({
              id,
              goalId,
              assistId: form.assistId,
              data: filteredData,
            })
          )

          return response
        },
        { type: actions.assists.update.request, payload }
      )
    },
  ],

  [
    actions.assists.delete,
    function* (payload) {
      const { id, goalId, assistId } = payload

      return yield attemptScoringRequest(
        function* () {
          yield call(api.assists.delete, assistId)
          yield put(actions.assists.delete.success({ id, goalId, assistId }))

          return
        },
        { type: actions.assists.delete.request, payload }
      )
    },
  ],

  [
    actions.goals.create,
    function* (payload) {
      const { id, periodId, form } = payload

      const { first_assist, second_assist, ...other } = form
      const body = { ...other }

      // Check that playerId is set - could have been touched but then cleared, which
      // will still exist in the form object.
      if (first_assist && first_assist.playerId) {
        body.assists = body.assists || []
        body.assists.push({
          uid: first_assist.uid,
          assist_type_id: '1',
          player_id: first_assist.playerId,
        })
      }

      // Check that playerId is set - could have been touched but then cleared, which
      // will still exist in the form object.
      if (second_assist && second_assist.playerId) {
        body.assists = body.assists || []
        body.assists.push({
          uid: second_assist.uid,
          assist_type_id: '2',
          player_id: second_assist.playerId,
        })
      }

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(
            api.goals.create,
            id,
            periodId,
            JSON.stringify(body)
          )
          yield put(
            actions.goals.create.success({
              id,
              teamId: form.team_id,
              data: response.data,
            })
          )

          return response
        },
        { type: actions.goals.create.request, payload }
      )
    },
  ],

  [
    actions.goals.update,
    function* (payload) {
      const { id, goalId, form } = payload

      const { first_assist, second_assist, player_id, is_bench_edit, ...goal } =
        form

      const body = { ...goal }
      if (player_id) {
        body.shot = { player_id }
      }
      if (is_bench_edit) {
        body.noscore = true
      }

      const response = yield attemptScoringRequest(
        function* () {
          const resp = yield call(
            api.goals.update,
            goalId,
            JSON.stringify(body)
          )
          yield put(
            actions.goals.update.success({ id, goalId, data: resp.data })
          )
          return resp
        },
        { type: actions.goals.update.request, payload }
      )

      if (first_assist) {
        // Only delete if we have a playerId to delete - if this is undefined then
        // the field was touched but then cleared.
        if (first_assist.playerId === '') {
          if (first_assist.assistId) {
            yield wait(
              actions.assists.delete.request,
              id,
              goalId,
              first_assist.assistId
            )
          }
        } else if (first_assist.assistId === null) {
          yield wait(
            actions.assists.create.request,
            id,
            goalId,
            first_assist,
            '1'
          )
        } else {
          yield wait(actions.assists.update.request, id, goalId, first_assist)
        }
      }

      if (second_assist) {
        // Only delete if we have a playerId to delete - if this is undefined then
        // the field was touched but then cleared.
        if (second_assist.playerId === '') {
          if (second_assist.assistId) {
            yield wait(
              actions.assists.delete.request,
              id,
              goalId,
              second_assist.assistId
            )
          }
        } else if (second_assist.assistId === null) {
          yield wait(
            actions.assists.create.request,
            id,
            goalId,
            second_assist,
            '2'
          )
        } else {
          yield wait(actions.assists.update.request, id, goalId, second_assist)
        }
      }

      return response
    },
  ],

  [
    actions.goals.delete,
    function* (payload) {
      const { id, goalId } = payload
      const shotId = yield select((state) => {
        const goal = state.game?.[id]?.goals?.find((g) => g.id === goalId)
        return goal?.shot?.id
      })

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(api.goals.delete, goalId)
          yield put(actions.goals.delete.success({ id, goalId }))
          if (shotId) {
            yield put(actions.shots.delete.success({ id, shotId: shotId }))
          }

          return response
        },
        { type: actions.goals.delete.request, payload }
      )
    },
  ],

  [
    actions.goalieChanges.create,
    function* (payload) {
      const { id, periodId, form } = payload

      // Our option value for null is the string 'null', which we need to transform
      // into actual null for the API
      if (form.goalie_id && form.goalie_id === 'null') {
        form.goalie_id = null
      }
      if (form.previous_goalie_id && form.previous_goalie_id === 'null') {
        form.previous_goalie_id = null
      }

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(
            api.goalieChanges.create,
            id,
            periodId,
            JSON.stringify(form)
          )
          yield put(
            actions.goalieChanges.create.success({ id, data: response.data })
          )

          return response
        },
        { type: actions.goalieChanges.create.request, payload }
      )
    },
  ],

  [
    actions.goalieChanges.update,
    function* (payload) {
      const { id, goalieChangeId, form } = payload

      // Our option value for null is the string 'null', which we need to transform
      // into actual null for the API
      if (form.goalie_id && form.goalie_id === 'null') {
        form.goalie_id = null
      }
      if (form.previous_goalie_id && form.previous_goalie_id === 'null') {
        form.previous_goalie_id = null
      }

      const body = JSON.stringify({ ...form })

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(
            api.goalieChanges.update,
            goalieChangeId,
            body
          )
          yield put(
            actions.goalieChanges.update.success({
              id,
              goalieChangeId,
              data: response.data,
            })
          )

          return response
        },
        { type: actions.goalieChanges.update.request, payload }
      )
    },
  ],

  [
    actions.goalieChanges.delete,
    function* (payload) {
      const { id, goalieChangeId } = payload

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(api.goalieChanges.delete, goalieChangeId)
          yield put(
            actions.goalieChanges.delete.success({ id, goalieChangeId })
          )

          return response
        },
        { type: actions.goalieChanges.delete.request, payload }
      )
    },
  ],

  [
    actions.penalties.create,
    function* (payload) {
      const { id, periodId, form } = payload
      const { notes, amount, served_by_player_id, ...offense } = form
      const body = {
        ...offense,
        penalty: {
          amount,
          served_by_player_id,
          penalty_type_id: '1', // time out
          notes: notes || '',
        },
      }

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(
            api.penalties.create,
            id,
            periodId,
            JSON.stringify(body)
          )
          yield put(
            actions.penalties.create.success({
              id,
              teamId: form.team_id,
              data: response.data,
            })
          )

          return response
        },
        { type: actions.penalties.create.request, payload }
      )
    },
  ],

  [
    actions.penalties.update,
    function* (payload) {
      const { id, penaltyId, form } = payload
      const { card_type_id, notes, amount, served_by_player_id, ...offense } =
        form

      const body = { ...offense }

      if (amount || amount === 0 || served_by_player_id) {
        body.penalty = {}
        if (amount || amount === 0) {
          body.penalty.amount = amount
        }
        if (served_by_player_id) {
          body.penalty.served_by_player_id = served_by_player_id
        }
        if (card_type_id === '') {
          body.card_type_id = null
        } else {
          body.card_type_id = card_type_id
        }
        if (notes) {
          body.penalty.notes = notes
        }
      }

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(
            api.penalties.update,
            penaltyId,
            JSON.stringify(body)
          )
          yield put(
            actions.penalties.update.success({
              id,
              penaltyId,
              data: response.data,
            })
          )

          return response
        },
        { type: actions.penalties.update.request, payload }
      )
    },
  ],

  [
    actions.penalties.delete,
    function* (payload) {
      const { id, teamId, penaltyId } = payload

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(api.penalties.delete, penaltyId)
          yield put(actions.penalties.delete.success({ id, penaltyId, teamId }))

          return response
        },
        { type: actions.penalties.delete.request, payload }
      )
    },
  ],

  [
    actions.shots.create,
    function* (payload) {
      const {
        id,
        periodId,
        teamId,
        occurredAt,
        opposingGoalieId,
        uid,
        period_clock_time,
      } = payload
      const body = {
        team_id: teamId,
        occurred_at: occurredAt,
        uid,
      }

      if (period_clock_time) {
        body.period_clock_time = period_clock_time
      }

      if (opposingGoalieId) {
        body.opposing_goalie_id = opposingGoalieId
      }

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(
            api.shots.create,
            id,
            periodId,
            JSON.stringify(body)
          )
          yield put(actions.shots.create.success({ id, data: response.data }))

          return response
        },
        { type: actions.shots.create.request, payload }
      )
    },
  ],

  [
    actions.shots.delete,
    function* (payload) {
      const { id, shotId } = payload

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(api.shots.delete, shotId)
          yield put(actions.shots.delete.success({ id, shotId }))

          return response
        },
        { type: actions.shots.delete.request, payload }
      )
    },
  ],

  [
    actions.signatures.create,
    function* (payload) {
      const { id, userId, data } = payload

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(api.signatures.create, id, userId, data)
          yield put(
            actions.signatures.create.success({
              id,
              userId,
              data: response.data,
            })
          )

          return response
        },
        { type: actions.signatures.create.request, payload }
      )
    },
  ],
]

export default generateSagas([...scoring])
