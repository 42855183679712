import React from 'react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { ROUTES } from '@sportninja/common/constants/app'
import { Link } from 'react-router-dom'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'

import ThumbnailAndName from '../ThumbnailAndName'
import { ReactComponent as EditPencil } from './pencil.svg'
// import { ReactComponent as Attachment } from './attachment.svg'
import AddSuspensionSheet from './AddSuspensionSheet'

dayjs.extend(timezone)

const SuspensionTableRow = ({
  isProfileView,
  onComplete,
  suspension = {},
  canEdit,
  onlyDisplayActive,
  scheduleId = null,
}) => {
  const {
    game,
    is_active,
    player,
    description,
    // comment,
    schedule,
    start_date,
    end_date,
    team,
    id,
    timezone,
    enforcement,
    // length,
    // remaining,
    offense,
  } = suspension

  return (
    <tr className={!suspension.is_active ? 'is-inactive' : ''}>
      {!isProfileView && (
        <td width='234' className='col-1'>
          {player?.id ? (
            <Link to={`${ROUTES.PLAYER_ROOT}/${player.id}`}>
              <ThumbnailAndName
                useInitials
                initialsSize={40}
                square={false}
                size='kxsmall'
                fit={false}
                row={{
                  id: player.id,
                  nameFirst: player.name_first,
                  nameLast: player.name_last,
                  fullName: `${player.name_first} ${player.name_last}`,
                  imageId: getImageThumbnailId(player),
                }}
                textClassName='line-clamp'
              />
            </Link>
          ) : (
            '-'
          )}
        </td>
      )}
      {/* <td className={isProfileView ? 'col-1' : ''}>
        <Link to={`${ROUTES.PLAYER_ROOT}/${player?.id}`}>
          <ThumbnailAndName
            size='kxsmall'
            iconName='user'
            textClassName='line-clamp'
            square={false}
            row={{
              nameFirst: player.name_first,
              nameLast: player.name_last,
              fullName: `${player.name_first} ${player.name_last}`,
              imageId: getImageThumbnailId(player),
            }}
          />
        </Link>
      </td> */}
      <td className={isProfileView ? 'col-1' : ''}>
        <Link
          to={`${ROUTES.TEAM_ROOT}/${team?.id}${
            scheduleId ? `?sn_schedule=${scheduleId}` : ''
          }`}
        >
          <ThumbnailAndName
            size='kxsmall'
            iconName='user-friends'
            textClassName='line-clamp'
            row={{
              id: team.id,
              fullName: team.name_full,
              imageId: getImageThumbnailId(team),
            }}
          />
        </Link>
      </td>
      {isProfileView && (
        <td>
          <Link to={`${ROUTES.SCHEDULE_ROOT}/${schedule?.id}`}>
            {schedule?.name_full}
          </Link>
        </td>
      )}
      {!onlyDisplayActive && (
        <td>{is_active ? 'Active' : 'Completed' || '-'}</td>
      )}
      <td>{start_date ? dayjs.utc(start_date).format('YYYY-MM-DD') : '-'}</td>
      <td>
        {end_date && enforcement?.id !== 3
          ? dayjs.utc(end_date).format('YYYY-MM-DD')
          : '-'}
      </td>
      {/* <td>{length || '-'}</td>
      <td>
        {remaining || '-'} / {length || '-'}
      </td> */}
      {/* <td>{report?.type}</td> */}
      <td>
        {offense?.offense_type?.name_full || offense?.offense_type?.name || '-'}
      </td>
      <td className='comment'>
        <div className='comment-inner'>{description || '-'}</div>
      </td>
      {/* <td>{report?.comment || '-'}</td> */}
      {/* <td
        className={'attachment' + (report?.attachment ? ' has-attachment' : '')}
      >
        {report?.attachment ? (
          <>
            <Attachment className='attachment-svg' />
            <span className='attachment-name'>{report?.attachment?.name}</span>
          </>
        ) : (
          '-'
        )}
      </td> */}
      {!isProfileView && canEdit && (
        <td className='edit'>
          <AddSuspensionSheet
            player={player}
            team={team}
            description={description}
            starts_at={start_date}
            ends_at={end_date}
            isIndefinite={enforcement?.id === 3 || !end_date}
            comp={schedule}
            timezone={timezone}
            game={game}
            offense={offense}
            id={id}
            onComplete={onComplete}
            button={(toggleOpen) => {
              return (
                <EditPencil
                  onClick={toggleOpen}
                  className='suspensions-edit-pencil'
                />
              )
            }}
          />
        </td>
      )}
    </tr>
  )
}

export default SuspensionTableRow
