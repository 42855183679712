import req from '@sportninja/common/api/request'
import { useCallback, useState } from 'react'

type Image = {
  id: string
  filename: string
  full_path: string
  mime_type: string
  width: number
  height: number
  formats?: {
    id: string
    filename: string
    full_path: string
    mime_type: string
    width: number
    height: number
  }[]
}

export type Player = {
  id: string
  name_first: string
  name_middle: string | null
  name_last: string
  full_name?: string | null
  birth_date?: string | null
  height?: number | null
  weight?: number | null
  created_at?: string
  gender?: string | null
  image: Image | null
  invitation_id?: string | null
  player_number: string | null
  play_direction: string | null
  is_affiliate: boolean
  is_injured: boolean
  is_playing: boolean
  is_starting: boolean
  is_proposed_starting: boolean
  is_suspended: boolean
  attendance_status: string | null
  customer_identifier: string
  sf_id: string | null
  suspensions: any[]
  email?: string
  phone_number?: string
  is_accepted?: boolean
  is_invited?: boolean
  is_validated?: boolean
  is_signed_waiver: boolean
  league_registration_number: string | null
  player_type: string | null
}

export const useUserPlayers = () => {
  const [data, setData] = useState<Player[] | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const response = await req('/users/me/players2')
      setData(response.data)
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error('An error occurred while fetching user players')
      )
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    data,
    loading,
    error,
    refetch: fetchData,
  }
}
