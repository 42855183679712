import { useCallback, useState } from 'react'
import req from '../api/request'

export interface Type {
  sport_id: string
  name: string
  name_full: string
  data: any
}

interface UseTypesResult {
  types: Type[] | null
  loading: boolean
  error: any
  fetchTypes: () => Promise<void>
}

export const useTypes = (): UseTypesResult => {
  const [types, setTypes] = useState<Type[] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const fetchTypes = useCallback(async () => {
    setLoading(true)
    try {
      const data = await req('/sports/types')
      setTypes(data)
    } catch (err: any) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [])

  return { types, loading, error, fetchTypes }
}
