/* eslint-disable react-native/no-inline-styles */
/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { App, Modal, Table, Tooltip } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import Text from '../../../components/ts-components/Text'
import { useCallback, useState, useEffect } from 'react'
import {
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import Button from '../../../components/ts-components/Button'
import { CouponForm } from './CouponForm'
import {
  ManageCouponProvider,
  useManageCoupon,
} from './context/ManageCouponContext'
import { useCoupons } from '@sportninja/common/hooks/useCoupons'
import DeleteModal from '../../../components/Modal/DeleteModal'
import { formatLongDate } from '@sportninja/common/ts-utils/date-utils'
import dayjs from 'dayjs'
import { useModalScroll } from 'src/hooks/useModalScroll'

type ManageCouponModalProps = {
  title: string
  isFormOpen: boolean
  setIsFormOpen: (isFormOpen: boolean) => void
  scheduleId: string
}

const styles = css`
  .my-modal-body {
  }
  .my-modal-mask {
    backdrop-filter: blur(1px);
  }
  .my-modal-header {
    border-bottom: 1px dotted ${colors.FIERY_RED};
  }
  .my-modal-footer {
    color: ${colors.FIERY_RED};
  }
  .my-modal-content {
    border: 1px solid #333;
  }
`

const classNames = {
  body: styles['my-modal-body'],
  mask: styles['my-modal-mask'],
  header: styles['my-modal-header'],
  footer: styles['my-modal-footer'],
  content: styles['my-modal-content'],
}

const modalStyles = {
  header: {
    backgroundColor: colors.SECONDARY_300,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  body: {
    borderBottom: `1px solid ${colors.SOFT_STEEL}`,
    borderTop: `1px solid ${colors.SOFT_STEEL}`,
    backgroundColor: colors.SECONDARY_300,
    padding: '0',
    '@media (max-width: 768px)': {
      maxHeight: '80vh',
      overflowY: 'auto',
    },
  },
  content: {
    backgroundColor: colors.SECONDARY_300,
    paddingTop: '8px',
    paddingBottom: '16px',
    paddingLeft: '0',
    paddingRight: '0',
    maxWidth: '95vw',
    width: 'fit-content',
    minWidth: '800px',
    '@media (max-width: 768px)': {
      width: '100%',
      minWidth: 'unset',
      maxWidth: '100%',
      margin: '0',
      borderRadius: '0',
    },
  },
  wrapper: {
    zIndex: 10001,
  },
  mask: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(6, 10, 16, 0.8)',
    zIndex: 10000,
  },
  footer: {
    paddingLeft: '16px',
    paddingRight: '16px',
    '@media (max-width: 768px)': {
      position: 'sticky',
      bottom: 0,
      backgroundColor: colors.SECONDARY_300,
      borderTop: `1px solid ${colors.SOFT_STEEL}`,
    },
  },
}

const DeleteButton = ({ ...props }) => {
  return (
    <Tooltip title='Delete Coupon' placement='top' zIndex={11000}>
      <DeleteOutlined
        css={css`
          font-size: 16px;
          color: ${colors.ERROR_200};
          cursor: pointer;
        `}
        {...props}
      />
    </Tooltip>
  )
}

const ManageCouponModalContent = ({
  title,
  isFormOpen,
  setIsFormOpen,
  scheduleId,
}: ManageCouponModalProps) => {
  const { message } = App.useApp()
  const [isAdding, setIsAdding] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    couponState,
    createCoupon,
    updateCoupon,
    deleteCoupon,
    refreshCoupons,
  } = useCoupons(scheduleId)
  const {
    id,
    setId,
    code,
    discount,
    discountType,
    isActive,
    startsAtMonth,
    startsAtDay,
    startsAtYear,
    endsAtMonth,
    endsAtDay,
    endsAtYear,
    maxUses,
    setFieldErrors,
    setCustomError,
    setCode,
    setDiscount,
    setDiscountType,
    setIsActive,
    setStartsAtMonth,
    setStartsAtDay,
    setStartsAtYear,
    setEndsAtMonth,
    setEndsAtDay,
    setEndsAtYear,
    setMaxUses,
    emailRestrictions,
    setEmailRestrictions,
  } = useManageCoupon()
  const { openModal, closeModal } = useModalScroll()

  useEffect(() => {
    if (isFormOpen) {
      openModal()
    } else {
      closeModal()
    }
  }, [closeModal, isFormOpen, openModal])

  useEffect(() => {
    if (isFormOpen) {
      refreshCoupons()
    }
  }, [isFormOpen, refreshCoupons])

  const clearFormState = useCallback(() => {
    setId('')
    setCode('')
    setDiscount('0')
    setDiscountType('amount')
    setIsActive(true)
    setStartsAtMonth('')
    setStartsAtDay('')
    setStartsAtYear('')
    setEndsAtMonth('')
    setEndsAtDay('')
    setEndsAtYear('')
    setMaxUses(0)
    setEmailRestrictions([])
    setFieldErrors([])
    setCustomError({
      code: '',
      discount: '',
      discount_type: 'amount',
      is_active: true,
      starts_at: '',
      ends_at: '',
    })
  }, [
    setId,
    setCode,
    setDiscount,
    setDiscountType,
    setIsActive,
    setStartsAtMonth,
    setStartsAtDay,
    setStartsAtYear,
    setEndsAtMonth,
    setEndsAtDay,
    setEndsAtYear,
    setMaxUses,
    setFieldErrors,
    setCustomError,
    setEmailRestrictions,
  ])

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      const payload: any = {
        code,
        discount: Number(discount),
        discount_type: discountType,
        is_active: isActive,
      }

      // Only add dates if all components are present
      if (startsAtYear && startsAtMonth && startsAtDay) {
        payload.start_date = `${startsAtYear}-${startsAtMonth}-${startsAtDay}`
      } else {
        payload.start_date = null
      }

      if (endsAtYear && endsAtMonth && endsAtDay) {
        payload.end_date = `${endsAtYear}-${endsAtMonth}-${endsAtDay}`
      } else {
        payload.end_date = null
      }

      // Start date must be before end date
      if (payload.start_date && payload.end_date) {
        const startDate = new Date(payload.start_date)
        const endDate = new Date(payload.end_date)
        if (startDate > endDate) {
          setCustomError((prev) => ({
            ...prev,
            starts_at: 'Start Date must be before End Date',
          }))
          setFieldErrors(['starts_at'])
          return
        }
      }

      // Only add max_uses if greater than 0
      if (maxUses > 0) {
        payload.max_uses = maxUses
      }

      // Email restrictions must be an array
      if (emailRestrictions?.length > 0) {
        payload.email_matches = emailRestrictions.join(',')
      } else {
        payload.email_matches = ''
      }

      if (id) {
        // if max_uses is 0, remove it from the payload
        if (maxUses === 0) {
          payload.max_uses = null
        }
        await updateCoupon(id, payload)
        message.success(`Coupon ${code} has been updated.`)
      } else {
        await createCoupon(payload)
      }
      setIsAdding(false)
      refreshCoupons()
      clearFormState()
    } catch (e: any) {
      if (e?.invalid_fields) {
        const errors: string[] = []
        Object.keys(e.invalid_fields).forEach((field) => {
          setCustomError((prev) => ({
            ...prev,
            [field]: e.invalid_fields[field],
          }))
          errors.push(field)
        })
        setFieldErrors(errors)
      } else {
        message.error(e?.message)
        setTimeout(() => {
          message.destroy()
        }, 3000)
      }
    } finally {
      setIsSubmitting(false)
    }
  }, [
    code,
    discount,
    discountType,
    isActive,
    startsAtYear,
    startsAtMonth,
    startsAtDay,
    endsAtYear,
    endsAtMonth,
    endsAtDay,
    maxUses,
    emailRestrictions,
    id,
    refreshCoupons,
    clearFormState,
    setCustomError,
    setFieldErrors,
    updateCoupon,
    message,
    createCoupon,
  ])

  const columns = [
    {
      title: (
        <Text variant='captionSmall' color={colors.WHITE} weight='semiBold'>
          CODE
        </Text>
      ),
      dataIndex: 'code',
      key: 'code',
      render: (code: string) => (
        <Text variant='paragraphMedium' color={colors.WHITE} weight='regular'>
          {code}
        </Text>
      ),
    },
    {
      title: (
        <Text variant='captionSmall' color={colors.WHITE} weight='semiBold'>
          DISCOUNT
        </Text>
      ),
      dataIndex: 'discount',
      key: 'discount',
      render: (d: number, record: any) => (
        <Text variant='paragraphMedium' color={colors.WHITE} weight='regular'>
          {record.discount_type === 'percentage' ? `${d}%` : `$${d}`}
        </Text>
      ),
    },
    {
      title: (
        <Text variant='captionSmall' color={colors.WHITE} weight='semiBold'>
          TYPE
        </Text>
      ),
      dataIndex: 'discount_type',
      key: 'discount_type',
      render: (type: string) => (
        <Text variant='paragraphMedium' color={colors.WHITE} weight='regular'>
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </Text>
      ),
    },
    {
      title: (
        <Text variant='captionSmall' color={colors.WHITE} weight='semiBold'>
          DATES
        </Text>
      ),
      key: 'validity_period',
      render: (_: any, record: any) => {
        if (!record.start_date && !record.end_date) {
          return (
            <Text
              variant='paragraphMedium'
              color={colors.WHITE}
              weight='regular'
            >
              -
            </Text>
          )
        }

        const startDate = record.start_date
          ? formatLongDate(record.start_date)
          : null
        const endDate = record.end_date ? formatLongDate(record.end_date) : null

        if (startDate && endDate) {
          return (
            <Text
              variant='paragraphMedium'
              color={colors.WHITE}
              weight='regular'
            >
              {`${startDate} - ${endDate}`}
            </Text>
          )
        }

        if (startDate) {
          return (
            <Text
              variant='paragraphMedium'
              color={colors.WHITE}
              weight='regular'
            >
              {`From ${startDate}`}
            </Text>
          )
        }

        return (
          <Text variant='paragraphMedium' color={colors.WHITE} weight='regular'>
            {`Until ${endDate}`}
          </Text>
        )
      },
    },
    {
      title: (
        <Text variant='captionSmall' color={colors.WHITE} weight='semiBold'>
          USAGE
        </Text>
      ),
      dataIndex: 'current_uses',
      key: 'current_uses',
      render: (currentUses: number, record: any) => {
        if (record.max_uses === null || record.max_uses === undefined) {
          return (
            <Text
              variant='paragraphMedium'
              color={colors.WHITE}
              weight='regular'
            >
              -
            </Text>
          )
        }

        const isNearLimit = currentUses >= record.max_uses * 0.75

        return (
          <Text
            variant='paragraphMedium'
            color={isNearLimit ? colors.ERROR_200 : colors.WHITE}
            weight='regular'
          >
            {`${currentUses || 0}/${record.max_uses}`}
          </Text>
        )
      },
    },
    {
      title: (
        <Text variant='captionSmall' color={colors.WHITE} weight='semiBold'>
          STATUS
        </Text>
      ),
      dataIndex: 'is_active',
      key: 'is_active',
      render: (active: boolean) => (
        <div
          css={css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {active ? (
            <Tooltip title='Active' placement='top' zIndex={11000}>
              <CheckCircleOutlined
                style={{ color: colors.SUCCESS_200, fontSize: 16 }}
              />
            </Tooltip>
          ) : (
            <Tooltip title='Inactive' placement='top' zIndex={11000}>
              <CloseCircleOutlined
                style={{ color: colors.ERROR_200, fontSize: 16 }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: (
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            width: 100%;
          `}
        >
          <Text variant='captionSmall' color={colors.WHITE} weight='semiBold'>
            ⋮
          </Text>
        </div>
      ),
      key: 'actions',
      render: (_text: string, record: any) => (
        <div
          css={css`
            display: flex;
            gap: 8px;
            white-space: nowrap;
          `}
        >
          <Tooltip title='Copy Code' placement='top' zIndex={11000}>
            <CopyOutlined
              css={css`
                font-size: 16px;
                color: ${colors.PRIMARY};
                cursor: pointer;
              `}
              onClick={() => {
                navigator.clipboard.writeText(record.code)
                message.info(`Coupon code ${record.code} copied to clipboard`)
              }}
            />
          </Tooltip>
          <Tooltip title='Edit Coupon' placement='top' zIndex={11000}>
            <EditOutlined
              css={css`
                font-size: 16px;
                color: ${colors.PRIMARY};
                cursor: pointer;
              `}
              onClick={() => {
                setId(record.id)
                setCode(record.code)
                setDiscount(record.discount.toString())
                setDiscountType(record.discount_type)
                setIsActive(record.is_active)
                setMaxUses(record.max_uses || 0)

                // Only set start date values if start_date exists
                if (record.start_date) {
                  const startDate = dayjs(record.start_date).utc()
                  setStartsAtMonth(
                    String(startDate.month() + 1).padStart(2, '0')
                  )
                  setStartsAtDay(String(startDate.date()).padStart(2, '0'))
                  setStartsAtYear(startDate.year().toString())
                } else {
                  setStartsAtMonth('')
                  setStartsAtDay('')
                  setStartsAtYear('')
                }

                // Only set end date values if end_date exists
                if (record.end_date) {
                  const endDate = dayjs(record.end_date).utc()
                  setEndsAtMonth(String(endDate.month() + 1).padStart(2, '0'))
                  setEndsAtDay(String(endDate.date()).padStart(2, '0'))
                  setEndsAtYear(endDate.year().toString())
                } else {
                  setEndsAtMonth('')
                  setEndsAtDay('')
                  setEndsAtYear('')
                }

                if (record?.email_matches) {
                  setEmailRestrictions(record.email_matches?.split(','))
                }

                setIsAdding(true)
              }}
            />
          </Tooltip>
          <DeleteModal
            title='Delete Coupon'
            message={`Are you sure you want to delete coupon ${record.code}? This cannot be undone.`}
            forceDeleteText='Delete'
            onDelete={async () => {
              await deleteCoupon(record.id)
              message.success(`Coupon ${record.code} has been deleted.`)
              refreshCoupons()
            }}
            Button={DeleteButton}
            shouldCloseModalAfterDelete={true}
            displayBanner={false}
          />
        </div>
      ),
    },
  ]

  const getFooter = useCallback(() => {
    return (
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          @media (max-width: 768px) {
            flex-direction: column;
            gap: 8px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 8px;
            width: 50%;
            align-self: flex-end;
            @media (max-width: 768px) {
              width: 100%;
              flex-direction: column;
            }
          `}
        >
          <Button
            text={isAdding ? 'BACK' : 'CLOSE'}
            onClick={() => {
              if (isAdding) {
                setIsAdding(false)
                clearFormState()
              } else {
                setIsFormOpen(false)
              }
            }}
            variant='secondary'
            disabled={isSubmitting}
          />
          <Button
            text={
              isAdding
                ? isSubmitting
                  ? 'SAVING...'
                  : id
                  ? 'SAVE CHANGES'
                  : 'SAVE'
                : 'ADD COUPON'
            }
            onClick={() => {
              if (isAdding) {
                handleSubmit()
              } else {
                setIsAdding(true)
              }
            }}
            variant='primary'
            disabled={isSubmitting}
          />
        </div>
      </div>
    )
  }, [isAdding, isSubmitting, id, clearFormState, setIsFormOpen, handleSubmit])

  const closeIcon = useCallback(() => {
    return (
      <CloseOutlined
        css={css`
          color: ${colors.SOLITUDE};
          font-size: 16px;
        `}
      />
    )
  }, [])

  const getHeader = useCallback(() => {
    return (
      <Text variant='heading3' weight='semiBold' color={colors.WHITE}>
        {isAdding && !id
          ? 'New Coupon'
          : isAdding && id
          ? 'Edit Coupon'
          : title}
      </Text>
    )
  }, [isAdding, id, title])

  return (
    <Modal
      css={css`
        .ant-modal-wrap {
          /* z-index: 10001; */
        }
        .ant-modal-content {
          @media (max-width: 768px) {
            width: 100%;
            min-width: unset !important;
          }
        }
        @media (max-width: 768px) {
          width: 100%;
          /* max-width: 100vw !important; */
          margin: 0;
          top: 0;
          padding-bottom: 0;
          .ant-modal {
            top: 0;
            padding-bottom: 0;
            margin: 0;
          }
        }
      `}
      width='auto'
      closeIcon={closeIcon()}
      title={getHeader()}
      footer={getFooter()}
      open={isFormOpen}
      onOk={() => {
        setIsFormOpen(false)
      }}
      onCancel={() => {
        setIsFormOpen(false)
        setIsAdding(false)
        clearFormState()
      }}
      centered
      classNames={classNames}
      styles={modalStyles}
      maskClosable={false}
    >
      <div
        css={css`
          padding: 16px;
          @media (max-width: 768px) {
            padding: 8px;
          }
        `}
      >
        {isAdding ? (
          <CouponForm />
        ) : (
          <Table
            loading={couponState.isLoading}
            dataSource={couponState.coupons}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
            size='small'
            css={css`
              @media (max-width: 768px) {
                .ant-table {
                  font-size: 12px;
                }
                .ant-table-cell {
                  padding: 8px 4px !important;
                }
              }
              .ant-table-row:nth-child(odd) {
                background-color: ${colors.SECONDARY_300};
              }
              .ant-table-row:nth-child(even) {
                background-color: ${colors.SECONDARY_400};
              }
            `}
          />
        )}
      </div>
    </Modal>
  )
}

const ManageCouponModal = (props: ManageCouponModalProps) => {
  return (
    <ManageCouponProvider>
      <ManageCouponModalContent {...props} />
    </ManageCouponProvider>
  )
}

export default ManageCouponModal
