import req from '@sportninja/common/api/request'
import { useCallback, useState } from 'react'

export type Team = {
  uid: string
  name: string
  abbreviation: string | null
  email: string | null
  website: string | null
  phone: string | null
  sport_id: string
  image: string | null
  player_count: number
  max_players_per_team: number
}

export type Role = 'player' | 'team-official' | null

export const useUserRegistrationTeams = (
  registrationId: string,
  role: Role
) => {
  const [data, setData] = useState<Team[] | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = useCallback(async () => {
    if (!registrationId || !role) {
      return
    }
    setLoading(true)
    setError(null)
    try {
      const endpoint =
        role === 'player'
          ? `/registration/${registrationId}/player/teams`
          : `/registration/${registrationId}/team-official/teams`
      const response = await req(endpoint)
      setData(response.data)
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error('An error occurred while fetching registration teams')
      )
    } finally {
      setLoading(false)
    }
  }, [registrationId, role])

  return {
    data,
    loading,
    error,
    refetch: fetchData,
  }
}
