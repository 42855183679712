import React from 'react'

interface VerifiedIconProps {
  width?: number
  height?: number
  color?: string
}

const VerifiedIcon: React.FC<VerifiedIconProps> = ({
  width = 16,
  height = 16,
  color = '#3894FF',
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
    >
      <g clipPath='url(#clip0_8712_5826)'>
        <path
          d='M15.3333 8L13.7066 6.14L13.9333 3.68L11.5266 3.13333L10.2666 1L7.99996 1.97333L5.73329 1L4.47329 3.12667L2.06663 3.66667L2.29329 6.13333L0.666626 8L2.29329 9.86L2.06663 12.3267L4.47329 12.8733L5.73329 15L7.99996 14.02L10.2666 14.9933L11.5266 12.8667L13.9333 12.32L13.7066 9.86L15.3333 8ZM6.72663 11.1467L4.19329 8.60667L5.17996 7.62L6.72663 9.17333L10.6266 5.26L11.6133 6.24667L6.72663 11.1467Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_8712_5826'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default VerifiedIcon
