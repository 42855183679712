/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Fragment } from 'react'

import { Column, Flex } from '../../../components/Layout'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { media } from '../../../components/Responsive'
import { COL_WIDTH, ROUND_LABEL_HEIGHT } from './constants'
import LineColumn from './LineColumn'
import Round from './Round'

const Brackets = ({
  onScroll,
  loadingBracket,
  competitionType,
  innerRef,
  parentRef,
  selectedComp,
  rounds,
  selected,
  setSelected,
  stats,
}) => {
  const hasLowerPoolsOnAnyRounds = rounds.some(
    (round) => round.lowerPools.length > 0
  )

  return (
    <Flex
      column
      key='schedule-bracket'
      id='schedule-bracket'
      css={css`
        position: relative;
        width: 100%;
        .wrap {
          position: relative;
        }
        .inner {
          display: flex;
        }

        .scroller {
          margin: 0;
          &::after {
            background: linear-gradient(
              to left,
              rgba(0, 0, 0, 0.5),
              transparent 55%,
              transparent
            );
            bottom: 0;
            content: '';
            position: absolute;
            right: 0px;
            top: 0;
            width: 42px;
            z-index: 1;
            pointer-events: none;

            transition: opacity 250ms;
            opacity: 0;
          }

          &::before {
            background: linear-gradient(
              to right,
              rgba(0, 0, 0, 0.5),
              transparent 55%,
              transparent
            );
            bottom: 0;
            content: '';
            position: absolute;
            left: 0px;
            top: 0;
            width: 42px;
            z-index: 1;
            pointer-events: none;

            transition: opacity 250ms;
            opacity: 0;
          }

          &.show-right-fade::after,
          &.show-left-fade::before {
            opacity: 1;
          }
        }

        .round {
          min-width: 250px;
          max-width: 250px;
        }

        .spacer {
          width: ${COL_WIDTH / 2}px;
        }

        .spacer-header {
          height: ${ROUND_LABEL_HEIGHT}px;
          background-color: ${colors.HEADER};
        }

        ${media.mobile} {
          & {
            position: relative;
            padding: 0;
          }

          .scroller {
            &::after {
              right: 0;
            }

            &::before {
              left: 0;
            }
          }

          .round {
            min-width: 240px;
            max-width: 240px;
          }
        }
      `}
    >
      {loadingBracket ? (
        <LoadingSpinner
          css={css`
            margin-top: 40px;
          `}
        />
      ) : selectedComp.length === 0 || rounds.length === 0 ? (
        <div
          css={css`
            flex: 1;
            margin-top: 24px;
            text-align: center;
          `}
        >
          {selectedComp.length === 0
            ? `Select a ${competitionType} to view its bracket.`
            : `There is no bracket available for this ${competitionType} yet.`}
        </div>
      ) : (
        <Column
          fullWidth
          ref={parentRef}
          className='scroller'
          onScroll={onScroll}
          css={css`
            overflow-x: auto;
            width: 100%;
          `}
        >
          <div className='inner' ref={innerRef}>
            <div className='spacer'>
              <div className='spacer-header' />
            </div>
            {rounds.map((round, idx) => {
              // SN-4026 As requested by Jeff on 2024-03-01,
              // we should display the score instead
              // of the points for the following tournament types:
              const shouldDisplayScoreInsteadOfPoints =
                round?.tournament_type_id === 1 ||
                round?.tournament_type_id === 2

              const isSingleElimination = round?.tournament_type_id === 1
              const isDoubleElimination = round?.tournament_type_id === 2
              const isSingleOrDoubleElimination =
                isSingleElimination || isDoubleElimination

              const isFinals = idx === rounds.length - 1
              const isSemifinals = idx === rounds.length - 2

              return (
                <Fragment key={round.id}>
                  <Round
                    round={round}
                    roundNumber={idx}
                    selected={selected}
                    setSelected={setSelected}
                    stats={stats}
                    shouldDisplayScoreInsteadOfPoints={
                      shouldDisplayScoreInsteadOfPoints
                    }
                    hasLowerPoolsOnAnyRounds={hasLowerPoolsOnAnyRounds}
                    isSingleOrDoubleElimination={isSingleOrDoubleElimination}
                    isFinals={isFinals}
                    isSemifinals={isSemifinals}
                    isSingleElimination={isSingleElimination}
                    isDoubleElimination={isDoubleElimination}
                  />
                  {idx < rounds.length - 1 &&
                    round?.pools &&
                    rounds[idx + 1]?.pools && (
                      <LineColumn
                        roundNumber={idx}
                        key={`${selectedComp}-${idx}`}
                        column={idx + 1}
                        left={round}
                        right={rounds[idx + 1]}
                        selected={selected}
                        parentRef={parentRef}
                        rounds={rounds}
                      />
                    )}
                </Fragment>
              )
            })}
            <div className='spacer'>
              <div className='spacer-header spacer-header-right' />
            </div>
          </div>
        </Column>
      )}
    </Flex>
  )
}

export default Brackets
