/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react'
import Slider, { Settings as SliderSettings } from 'react-slick'
import styled from 'styled-components'
import GameAttendanceCard from '../GameAttendanceCard'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { UpcomingGame } from '@sportninja/common/types/PlayerAttendance'
import {
  checkTeamAssignment,
  formatGameDate,
} from '@sportninja/common/ts-utils'
import { getDayjsTZDate } from '@sportninja/common/utils/utils'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'

const PrevArrow = (props: any) => {
  const { className, onClick, currentSlide } = props
  return (
    <div
      className={`${className} ${currentSlide === 0 ? 'slick-disabled' : ''}`}
      onClick={onClick}
      style={{ display: currentSlide === 0 ? 'none' : 'block' }}
    />
  )
}

const NextArrow = (props: any) => {
  const { className, onClick, slideCount, currentSlide } = props
  return (
    <div
      className={`${className} ${
        currentSlide === slideCount - 1 ? 'slick-disabled' : ''
      }`}
      onClick={onClick}
      style={{ display: currentSlide === slideCount - 1 ? 'none' : 'block' }}
    />
  )
}

interface GameAttendanceCarouselProps {
  attendance: UpcomingGame[]
  readAttendanceInformation: () => void
  onPressManageAttendance: (
    gameId: string,
    rosterId: string,
    teamId: string,
    teamName: string,
    showWaiver: boolean
  ) => void
  onPressManageRefreshments: (
    gameId: string,
    rosterId: string,
    teamId: string,
    teamName: string,
    showWaiver: boolean
  ) => void
}

const GameAttendanceCarousel: React.FC<GameAttendanceCarouselProps> = ({
  attendance,
  readAttendanceInformation,
  onPressManageAttendance,
  onPressManageRefreshments,
}) => {
  const isEmpty = useMemo(() => {
    return attendance?.length === 0
  }, [attendance])

  if (isEmpty) {
    return null
  }

  const settings: SliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    customPaging: (_i: number) => <CustomDot />,
    className: 'slick-carousel',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    adaptiveHeight: true,
  }

  const updateAttendanceStatus = async ({
    playerId,
    gameId,
    rosterId,
    status,
  }: {
    playerId: string
    gameId: string
    rosterId: string
    status: number
  }) => {
    try {
      await req(
        `/games/${gameId}/rosters/${rosterId}/players/${playerId}/attendance`,
        {
          method: 'PUT',
          body: JSON.stringify({
            attendance_status: status,
          }),
        }
      )
      await readAttendanceInformation()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <CarouselContainer>
      <StyledSlider {...settings}>
        {attendance?.map((game: UpcomingGame, index) => {
          const { isPlayingOnHomeTeam, isPlayingOnAwayTeam } =
            checkTeamAssignment(game)
          const gameDate = getDayjsTZDate(
            game?.starts_at,
            game?.location?.timezone
          )
          const playersAttendance = game?.myPlayers
          const teamRepsAttendance = game?.myTeamOfficials
          const dateTime = formatGameDate(gameDate)
          const displayPlayerAttendance =
            playersAttendance && playersAttendance.length > 0
          const displayTeamRepAttendance =
            teamRepsAttendance && teamRepsAttendance.length > 0
          const homeTeam = {
            name: game?.home_team?.name,
            // logo: getImageThumbnailId(game?.home_team),
            logo: game?.home_team?.image,
          }
          const awayTeam = {
            name: game?.visiting_team?.name,
            // logo: getImageThumbnailId(game?.visiting_team),
            logo: game?.visiting_team?.image,
          }
          const isFirstGame = index === 0

          return (
            <div key={index}>
              <GameAttendanceCard
                onPressManageAttendance={onPressManageAttendance}
                isFirstGame={isFirstGame}
                rowItem={game}
                playersAttendance={playersAttendance}
                teamRepsAttendance={teamRepsAttendance}
                homeTeam={homeTeam}
                awayTeam={awayTeam}
                gameTime={dateTime}
                leagueName={game.schedule}
                arenaName={game.facility}
                facilityNumber={game.location.name}
                isPlayingOnHomeTeam={isPlayingOnHomeTeam}
                isPlayingOnAwayTeam={isPlayingOnAwayTeam}
                displayPlayerAttendance={displayPlayerAttendance}
                displayTeamRepAttendance={displayTeamRepAttendance}
                onAttendanceSelect={updateAttendanceStatus}
                onPressManageRefreshments={onPressManageRefreshments}
              />
            </div>
          )
        })}
      </StyledSlider>
    </CarouselContainer>
  )
}

const CarouselContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 32px;
`

const StyledSlider = styled(Slider)`
  /* this is the default style of the dots */
  /* .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
} */

  .slick-dots {
    /* center the dots */
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: -24px;
    gap: 8px;
    li {
      margin: 0 0;
      width: 8px !important;
      height: 8px !important;
    }
  }
  /* remove the margin of the arrows */
  .slick-arrow {
    margin: 0 16px;
    z-index: 1;
  }

  /* the slides */
  .slick-slide {
    padding: 0 8px;
  }
  .slick-list {
    margin: 0 -8px;
  }
`

const CustomDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #7f8c8d;

  .slick-active & {
    background-color: ${colors.PRIMARY};
  }
`

export default GameAttendanceCarousel
