/** @jsxImportSource @emotion/react */

import React from 'react'
import css from '@emotion/css/macro'
import { Controller } from 'react-hook-form'
import type { Control, RegisterOptions } from 'react-hook-form'
import colors from '@sportninja/common/constants/appColors'
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { Input as AntInput } from 'antd'
import Text from '../ts-components/Text'

const { TextArea } = AntInput

interface Props {
  control?: Control<any>
  fieldName: string
  required?: boolean
  placeholder?: string
  invalid?: boolean
  invalidMessage?: string
  type?: React.HTMLInputTypeAttribute
  icon?: React.ReactNode | JSX.Element | EmotionJSX.Element
  onIconPress?: () => void
  label?: string
  disabled?: boolean
  isMockInput?: boolean
  mockValue?: string
  secureTextEntry?: boolean
  mask?: 'date'
  useReturnKey?: boolean
  onReturnPress?: () => void
  autoComplete?: 'on' | 'off'
  isLightMode?: boolean
  customWidth?: string
  customRules?: Omit<
    RegisterOptions<any, string>,
    'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  tabIndex?: number
  min?: number
}

export const SNTextArea = ({
  control,
  fieldName,
  invalid,
  invalidMessage,
  placeholder,
  required,
  type = 'text',
  label,
  disabled,
  autoComplete = 'on',
  isLightMode = false,
  customWidth,
  customRules = {},
  tabIndex = 1,
}: Props) => {
  return (
    <div
      tabIndex={-1}
      css={css`
        height: 100%;
        max-width: ${customWidth || '100%'};
        display: ${type === 'hidden' ? 'none' : 'flex'};
        flex-flow: column;
        flex: 1;
      `}
    >
      {label && (
        <Text
          variant='paragraphSmall'
          weight='regular'
          color={colors.WHITE}
          style={`
            align-self: stretch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 8px;
          `}
        >
          {label}
          {required ? ' *' : ''}
        </Text>
      )}
      <Controller
        control={control}
        name={fieldName}
        rules={{
          required,
          pattern:
            type === 'email'
              ? {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                }
              : undefined,
          ...customRules,
        }}
        render={({ field }) => (
          <div
            tabIndex={-1}
            css={css`
              display: flex;
              flex: 1;
              flex-direction: column;
              transition: all 0.1s ease-in-out;

              ${isLightMode &&
              `.ant-input { color: ${colors.BLACK}!important;}`}

              .ant-input {
                border-radius: 4px !important;
              }

              &:focus-within {
                border-color: ${disabled
                  ? colors.SOFT_STEEL
                  : isLightMode
                  ? colors.ATTENDANCE_GRAY
                  : colors.WHITE};
              }
            `}
          >
            <TextArea
              // add invalid styles
              status={invalid ? 'error' : ''}
              css={css`
                height: 100%;
                flex: 1;
              `}
              rows={8}
              disabled={disabled}
              tabIndex={tabIndex}
              required={required}
              autoComplete={autoComplete}
              placeholder={placeholder}
              {...field}
            />
          </div>
        )}
      />
      <div
        tabIndex={-1}
        css={css`
          margin-top: 8px;
          transition: opacity 0.1s ease-in-out;
          opacity: ${invalid ? 1 : 0};
          font-size: 13px;
          line-height: 16px;
          color: ${colors.ERROR_LIGHT};
        `}
      >
        <span>{invalidMessage}</span>
      </div>
    </div>
  )
}
