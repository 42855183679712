import React, { useState } from 'react'
import GameAttendanceCarousel from '../../components/GameAttendanceCarousel'
import DivisionStandingsCarousel from '../../components/DivisionStandingsCarousel'
import { useAttendance } from '@sportninja/common/hooks/useAttendance'
import styled from '@emotion/styled/macro'
import ManageTeamAttendanceModal from 'src/components/ManageTeamAttendanceModal'
import ManageRefreshmentsModal from 'src/components/ManageRefreshmentsModal'
import LastGameCard from 'src/components/LastGameCard'
import useHomeGames from '@sportninja/common/hooks/useHomeGames'
// import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'

export const SidebarWrap = styled.div`
  width: 330px;
  min-width: 300px;
  position: relative;
  /* margin-left: 16px; */
`

export const SidebarInner = styled.div`
  width: inherit;
  position: fixed;
  /* padding: 16px; */
  background: transparent;
  border-radius: 8px;
  max-height: 90vh;
  /* overflow-y: auto; */
  /* hide the scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 8px;
  padding-right: 8px;
`

const UserSidebar = () => {
  const { attendanceState, readAttendanceInformation } = useAttendance()
  const { homeGames, loading, error } = useHomeGames()
  const [isManageAttendanceModalOpen, setIsManageAttendanceModalOpen] =
    useState(false)
  const [isManageRefreshmentsModalOpen, setIsManageRefreshmentsModalOpen] =
    useState(false)
  const [selectedManageRefreshments, setSelectedManageRefreshments] = useState<{
    gameId: string
    rosterId: string
    teamId: string
    teamName: string
    showWaiver: boolean
  } | null>(null)

  const [selectedManageAttendance, setSelectedManageAttendance] = useState<{
    gameId: string
    rosterId: string
    teamId: string
    teamName: string
    showWaiver: boolean
  } | null>(null)

  const onPressManageRefreshments = (
    gameId: string,
    rosterId: string,
    teamId: string,
    teamName: string,
    showWaiver: boolean
  ) => {
    setSelectedManageRefreshments({
      gameId,
      rosterId,
      teamId,
      teamName,
      showWaiver,
    })
    setIsManageRefreshmentsModalOpen(true)
  }

  const onPressManageAttendance = (
    gameId: string,
    rosterId: string,
    teamId: string,
    teamName: string,
    showWaiver: boolean
  ) => {
    setSelectedManageAttendance({
      gameId,
      rosterId,
      teamId,
      teamName,
      showWaiver,
    })
    setIsManageAttendanceModalOpen(true)
  }

  return (
    <>
      <ManageTeamAttendanceModal
        isManageAttendanceModalOpen={isManageAttendanceModalOpen}
        setIsManageAttendanceModalOpen={setIsManageAttendanceModalOpen}
        selectedManageAttendance={selectedManageAttendance}
        readAttendanceInformation={readAttendanceInformation}
      />
      <ManageRefreshmentsModal
        isManageRefreshmentsModalOpen={isManageRefreshmentsModalOpen}
        setIsManageRefreshmentsModalOpen={setIsManageRefreshmentsModalOpen}
        selectedManageRefreshments={selectedManageRefreshments}
        readAttendanceInformation={readAttendanceInformation}
      />
      <SidebarWrap>
        <SidebarInner>
          <DivisionStandingsCarousel />
          {!loading &&
          !error &&
          homeGames &&
          homeGames?.previousGames &&
          homeGames?.previousGames?.length > 0 ? (
            <LastGameCard
              game_id={homeGames?.previousGames[0]?.id}
              homeTeam={{
                name: homeGames?.previousGames[0]?.home_team?.name,
                // logo: getImageThumbnailId(
                //   homeGames?.previousGames[0]?.home_team
                // ),
                logo: homeGames?.previousGames[0]?.home_team?.image,
                score: homeGames?.previousGames[0]?.home_team_score,
                team_id: homeGames?.previousGames[0]?.home_team?.id,
              }}
              visitorTeam={{
                name: homeGames?.previousGames[0]?.visiting_team?.name,
                // logo: getImageThumbnailId(
                //   homeGames?.previousGames[0]?.visiting_team
                // ),
                logo: homeGames?.previousGames[0]?.visiting_team?.image,
                score: homeGames?.previousGames[0]?.visiting_team_score,
                team_id: homeGames?.previousGames[0]?.visiting_team?.id,
              }}
            />
          ) : null}
          <GameAttendanceCarousel
            attendance={attendanceState.attendance}
            readAttendanceInformation={readAttendanceInformation}
            onPressManageAttendance={onPressManageAttendance}
            onPressManageRefreshments={onPressManageRefreshments}
          />
        </SidebarInner>
      </SidebarWrap>
    </>
  )
}

export default UserSidebar
