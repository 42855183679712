/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { Input, Alert, App } from 'antd'
import { useContext, useEffect, useRef, useState } from 'react'
import { EventCard } from 'src/components/RegistrationEventCard'
import { EventProgress } from 'src/components/RegistrationEventProgress'
import { RegistrationContext } from '../context/RegistrationContext'
import {
  PageTitle,
  REGISTRATION_STEPS,
  paragraphMediumStyle,
  paragraphSmallStyle,
} from './Main'
import { heading3, weightRegular } from 'src/components/css'
import { UserOutlined, LogoutOutlined } from '@ant-design/icons'

const reSendCode = async (email, sessionId, registrationId) => {
  await req(`/registration/${registrationId}/resend`, {
    method: 'POST',
    body: JSON.stringify({ email, registration_session_id: sessionId }),
  })
}

const validateCode = async (email, code) => {
  try {
    await req('/signup/validate', {
      method: 'POST',
      body: JSON.stringify({ email, token: code }),
    })
    return true
  } catch (e) {
    if (e?.message === 'This email has already been verified') {
      return true
    }
    throw e
  }
}

export const checkIfUserIsVerified = async () => {
  try {
    const { data } = await req('/signup/status/validate')
    if (data?.is_validated) {
      return true
    } else {
      throw new Error('User is not verified')
    }
  } catch (e) {
    if (e?.message === 'This email has already been verified') {
      return true
    }
    throw e
  }
}

const ConfirmYourEmail = () => {
  const {
    setStep,
    registration,
    email,
    updateSessionDivision,
    resetSession,
    session,
    userId,
  } = useContext(RegistrationContext)
  const [error, setError] = useState(null)
  const [invalidCodeError, setInvalidCodeError] = useState(null)
  const [codeValidationSuccess, setCodeValidationSuccess] = useState(false)
  const [timeLeft, setTimeLeft] = useState(30)
  const resendCodeIntervalRef = useRef(null)
  const verifyUserIntervalRef = useRef(null)
  const { message } = App.useApp()
  const waiverRequired = registration?.registration?.show_waiver

  useEffect(() => {
    checkIfUserIsVerified()
      .then(async () => {
        // We do that so we can ensure that the user id is set in the session
        await updateSessionDivision(session?.division_id, userId)
        setStep(REGISTRATION_STEPS.SELECT_YOUR_ROLE)
        // updateSessionConfirmSubmission()
        //   .then(() => {
        //     setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
        //   })
        //   .catch((e) => {
        //     if (e?.status === 410) {
        //       deleteSession()
        //       window.location.reload()
        //       return
        //     }
        //     const errorMessage = getErrorMessage(e)
        //     setError(`${errorMessage}`)
        //   })
      })
      .catch(() => {
        console.log('User is not verified')
      })
  }, [])

  useEffect(() => {
    verifyUserIntervalRef.current = setInterval(() => {
      checkIfUserIsVerified()
        .then(() => {
          setStep(REGISTRATION_STEPS.SELECT_YOUR_ROLE)
          // updateSessionConfirmSubmission()
          //   .then(() => {
          //     clearInterval(verifyUserIntervalRef.current)
          //     setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
          //   })
          //   .catch((e) => {
          //     if (e?.status === 410) {
          //       deleteSession()
          //       window.location.reload()
          //       return
          //     }
          //     const errorMessage = getErrorMessage(e)
          //     setError(`${errorMessage}`)
          //     clearInterval(verifyUserIntervalRef.current)
          //   })
        })
        .catch(() => {
          console.log('User is not verified')
        })
    }, 30000)
    return () => clearInterval(verifyUserIntervalRef.current)
  }, [])

  useEffect(() => {
    if (timeLeft === 30) {
      resendCodeIntervalRef.current = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev === 0) {
            clearInterval(resendCodeIntervalRef.current)
          }
          return prev - 1
        })
      }, 1000)
    }
    return () => clearInterval(resendCodeIntervalRef.current)
  }, [timeLeft])

  useEffect(() => {
    resendCodeIntervalRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev === 0) {
          clearInterval(resendCodeIntervalRef.current)
        }
        return prev - 1
      })
    }, 1000)
    return () => clearInterval(resendCodeIntervalRef.current)
  }, [])

  return (
    <>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
          /* background-color: ${colors.REGISTRATION_SECOND_BACKGROUND}; */
          background-color: ${colors.NEUTRAL_800};
          width: 1080px;
          padding: 0 35px 35px 35px;
          border-radius: 16px 16px 0px 0px;
          @media (max-width: 768px) {
            min-width: 0;
            width: 100%;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            margin-top: 16px;
            margin-bottom: -8px;
          `}
        >
          <a
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: default;
            `}
            // href={`${ROUTES.PROFILE.ROOT}`}
            target='_blank'
            rel='noreferrer'
          >
            <UserOutlined
              css={css`
                color: ${colors.PRIMARY};
              `}
            />
            <p
              css={css`
                ${paragraphSmallStyle}
                color: ${colors.PRIMARY};
              `}
            >
              {email}{' '}
            </p>
          </a>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
            `}
            onClick={() => {
              resetSession()
              setTimeout(() => {
                window.location.reload()
              }, 500)
            }}
          >
            <LogoutOutlined
              css={css`
                color: ${colors.ERROR_200};
              `}
            />
            <p
              css={css`
                ${paragraphSmallStyle}
                color: ${colors.ERROR_200};
              `}
            >
              Start Over
            </p>
          </div>
        </div>
        <EventCard eventInfo={registration} />
        <EventProgress eventProgress={2} isWaiverRequired={waiverRequired} />
        <PageTitle
          title='Verify Your Email'
          subtitle={`We sent a six-digit code to ${email} Please enter it below to verify your email address.`}
        />
        <div
          css={css`
            max-width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            align-self: center;
            margin-bottom: 32px;
            @media (max-width: 768px) {
              width: 100%;
            }
            .ant-otp-input {
              ${heading3}
              ${weightRegular}
            font-size: 32px!important;
              width: 61px !important;
              height: 64px !important;
              padding: 16px;
              border-radius: 4px;
              color: ${colors.WHITE};
              @media (max-width: 768px) {
                width: 44px !important;
                height: 40px !important;
                font-size: 16px !important;
              }
            }
          `}
        >
          <Input.OTP
            length={6}
            status={
              invalidCodeError
                ? 'error'
                : codeValidationSuccess
                ? 'success'
                : null
            }
            onChange={async (value) => {
              try {
                setInvalidCodeError(false)
                setCodeValidationSuccess(false)
                await validateCode(email, value)
                setCodeValidationSuccess(true)
                setStep(REGISTRATION_STEPS.SELECT_YOUR_ROLE)
                // updateSessionConfirmSubmission()
                //   .then(() => {
                //     setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
                //   })
                //   .catch((e) => {
                //     if (e?.status === 410) {
                //       deleteSession()
                //       window.location.reload()
                //       return
                //     }
                //     const errorMessage = getErrorMessage(e)
                //     setError(`${errorMessage}`)
                //   })
              } catch (e) {
                const errorMessage = getErrorMessage(e)
                // This email has already been verified
                if (errorMessage === 'This email has already been verified') {
                  setStep(REGISTRATION_STEPS.SELECT_YOUR_ROLE)
                  // updateSessionConfirmSubmission()
                  //   .then(() => {
                  //     setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
                  //     return
                  //   })
                  //   .catch((new_error) => {
                  //     if (new_error?.status === 410) {
                  //       deleteSession()
                  //       window.location.reload()
                  //       return
                  //     }
                  //     const newErrorMessage = getErrorMessage(new_error)
                  //     setError(`${newErrorMessage}`)
                  //   })
                  return
                }
                if (e?.invalid_fields?.token) {
                  setInvalidCodeError(e?.invalid_fields?.token)
                  return
                }
                setError(`${errorMessage}`)
              }
            }}
          />
        </div>
        <div
          tabIndex={-1}
          css={css`
            transition: opacity 0.1s ease-in-out;
            display: ${invalidCodeError ? 'inline' : 'none'};
            font-size: 13px;
            line-height: 16px;
            color: ${colors.ERROR_LIGHT};
            align-self: center;
            margin-bottom: 16px;
            margin-top: -16px;
          `}
        >
          <span>{invalidCodeError}</span>
        </div>
        {timeLeft <= 0 ? (
          <p
            css={css`
              ${paragraphMediumStyle}
              color: ${colors.WHITE};
              align-self: stretch;
              text-align: center;
              margin-bottom: 16px;
              cursor: pointer;
            `}
            onClick={async () => {
              try {
                message.open({
                  type: 'loading',
                  content: 'Sending new code',
                  duration: 0,
                })
                await reSendCode(email, session?.id, registration?.id)
                message.destroy()
                message.open({
                  type: 'success',
                  content: 'Verification code resent. Check your email.',
                  duration: 5,
                })
              } catch (e) {
                message.destroy()
                const errorMessage = getErrorMessage(e)
                setError(`${errorMessage}`)
              } finally {
                clearInterval(resendCodeIntervalRef.current)
                setTimeLeft(30)
                resendCodeIntervalRef.current = setInterval(() => {
                  setTimeLeft((prev) => {
                    if (prev === 0) {
                      clearInterval(resendCodeIntervalRef.current)
                    }
                    return prev - 1
                  })
                }, 1000)
              }
            }}
          >
            Don&apos;t see your email?{' '}
            <span
              css={css`
                color: ${colors.PRIMARY};
              `}
            >
              Send a new code
            </span>
          </p>
        ) : (
          <p
            css={css`
              ${paragraphMediumStyle}
              color: ${colors.WHITE};
              align-self: stretch;
              text-align: center;
              margin-bottom: 16px;
            `}
          >
            Don’t see it? Send a new code in {timeLeft} seconds
          </p>
        )}
        {error ? (
          <div
            css={css`
              margin-bottom: 16px;
              width: 100%;
              /* on mobile, limit the width even more */
              @media (max-width: 768px) {
                max-width: 300px;
              }
            `}
          >
            <Alert message={error} type='error' showIcon />
          </div>
        ) : null}
      </div>
    </>
  )
}

export default ConfirmYourEmail
