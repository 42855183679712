/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'

import { IS_DEVELOPMENT } from '../../../utils/utils'
import GameSlot from './GameSlot'

const GameSlotTable = ({
  games,
  availableVenues,
  forms,
  handleSetForm,
  id,
  getAvailableFacilities,
  submitLoading,
  advanceLoading,
  onApprove,
  onDebugAdvance,
  tournamentStatus,
  onPressCopy,
  onPressTeamModal = () => {},
  isSingleOrDoubleTournament = false,
  isSingleElimination = false,
  isDoubleElimination = false,
}) => {
  return (
    <table
      css={css`
        width: 100%;
        margin-right: auto;

        transition: opacity 0.2s ease-in-out;
        ${(submitLoading || advanceLoading) &&
        css`
          opacity: 0.6;
        `}

        thead {
          tr {
            height: 46px;
          }

          td {
            vertical-align: middle;
            text-transform: uppercase;
            font-weight: 700;
            padding: 0px 6px 16px;
          }
        }

        tbody {
          tr {
            height: 56px;

            &:nth-of-type(odd) {
              background-color: #292e35;
            }
          }

          td {
            vertical-align: middle;
            padding: 0 4px;
            font-size: 16px;
          }
        }

        tr.is-disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        .team {
          padding: 0 0px !important;
          min-width: 120px;
          width: 120px;

          i {
            transform: translateX(1px);
          }
        }

        .date-picker {
          min-width: 246px;
          width: 246px;

          .picker-label {
            margin-bottom: 0;
          }

          .date-picker-contents {
            width: unset !important;
          }
        }

        .game-number {
          position: relative;
          min-width: 16px;
          width: 16px;
          text-align: right;
        }

        .game-status {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              text-decoration: underline;
            }

            &.is-ended {
              color: ${colors.DEFAULT_FLAIR};

              i {
                color: ${colors.DEFAULT_FLAIR};
              }
            }
          }
        }

        .venue {
          min-width: 188px;
          width: 188px;
        }

        .facility {
          min-width: 150px;
          width: 150px;
        }

        .td-center {
          text-align: center;
        }

        .picker-value-container,
        select {
          height: 40px;
          background: #27303e;
          border: 1px solid #3f4753;
          border-radius: 4px;
        }

        .date-picker.is-edited .picker-value-container,
        select.is-edited {
          border-color: ${colors.DEFAULT_FLAIR};
        }
      `}
    >
      <thead>
        <tr>
          <td className='game-number'>#</td>
          <td>Date & Time</td>
          <td>Venue</td>
          <td>Facility</td>
          <td> </td>
          <td>Teams</td>
          <td className='td-center'>Appr.</td>
          <td className='td-center'>Round</td>
          {!isSingleOrDoubleTournament ? (
            <td className='td-center'>Pool</td>
          ) : null}
          <td className='td-center'>Game Status</td>
          {IS_DEVELOPMENT && <td className='td-center'>Advancement (Debug)</td>}
        </tr>
      </thead>
      <tbody>
        {games.map((game = {}, idx) => {
          const isFinals = idx === games.length - 1
          const isSemifinals = idx === games.length - 2

          return (
            <GameSlot
              key={game.id}
              idx={idx}
              prevGameIsComplete={games[Math.max(0, idx - 1)]?.ended_at}
              prevGameHasBye={games[Math.max(0, idx - 1)]?.has_bye}
              availableVenues={availableVenues}
              form={forms[game.id]}
              game={game}
              handleSetForm={handleSetForm.bind(this, game.id)}
              scheduleId={id}
              getAvailableFacilities={getAvailableFacilities}
              submitLoading={submitLoading}
              advanceLoading={advanceLoading}
              onApprove={onApprove.bind(this, game.id)}
              onDebugAdvance={onDebugAdvance.bind(this, game.id)}
              tournamentStatus={tournamentStatus}
              onPressCopy={onPressCopy}
              onPressTeamModal={onPressTeamModal}
              isSingleOrDoubleTournament={isSingleOrDoubleTournament}
              isSingleElimination={isSingleElimination}
              isDoubleElimination={isDoubleElimination}
              isFinals={isFinals}
              isSemifinals={isSemifinals}
            />
          )
        })}
      </tbody>
    </table>
  )
}

export default GameSlotTable
