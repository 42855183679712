import { call, put } from 'redux-saga/effects'
import req from '@sportninja/common/api/request'

import { t } from '../i18n'
import actions from '../actions/org'
import { createEntity } from './helpers'
import { readFactory, readWithPagesFactory } from './helpers/read'
import { updateEntityFactory } from './helpers/update'
import { deleteEntityFactory } from './helpers/delete'
import entityImageSagas from './helpers/entity-image'
import venuesSagas from './helpers/venues'
import usersSagas from './helpers/users'
import feedSagas from './helpers/feed'
import officialsSagas from './helpers/officials'
import { ENTITY_TYPES, generateSagas, wait } from './utils'
const ENTITY_TYPE = ENTITY_TYPES.org

export const api = {
  create: async (body) => await req('/organizations', { method: 'POST', body }),

  children: {
    read: async (orgId, page = 1, sort, direction) =>
      await req(`/organizations/${orgId}/children`, {
        query: { page, sort, direction },
      }),
  },
  schedules: {
    read: async (orgId, page = 1, sort, direction, is_archive) =>
      await req(`/organizations/${orgId}/schedules`, {
        query: { page, sort, direction, is_archive },
      }),
  },
  teams: {
    read: async (orgId, page = 1, sort, direction) =>
      await req(`/organizations/${orgId}/teams`, {
        query: { page, sort, direction },
      }),
  },

  games: {
    read: async (
      orgId,
      page = 1,
      starts_after = '2000-01-01T00:00:00Z',
      starts_before,
      direction,
      tz
    ) => {
      const query = {
        page,
        organization_id: orgId,
        starts_after,
        starts_before,
        order: direction,
      }
      if (tz) query.tz = tz
      return await req('/games', { query })
    },
  },
}

const org = [
  [
    actions.create,
    function* ({ form }) {
      const { imageData, ...body } = form

      const setup = {
        bodyTransform: {
          parent_id: body.parent_id,
          // The API still requires these to be set, even though they are not used
          can_contain_organizations: true,
          can_contain_teams: true,
          name: body.name_full, // API requires this, just copy the name_full field
        },
      }

      const response = yield call(
        createEntity,
        api.create,
        actions.create.success,
        body,
        setup,
        body.parent_id
      )

      if (typeof imageData !== 'undefined') {
        try {
          yield wait(actions.image.create.request, response.data.id, imageData)
        } catch (e) {
          response.error = `${t('errors:imageUploadProblem')}.`
        }
      }

      return response
    },
  ],
  [actions.read, readFactory(ENTITY_TYPE, actions.read)],
  [actions.update, updateEntityFactory(ENTITY_TYPE, actions)],
  [actions.delete, deleteEntityFactory(ENTITY_TYPE, actions.delete)],

  [
    actions.children.read,
    readWithPagesFactory(api.children.read, actions.children.read),
  ],
  [
    actions.schedules.read,
    readWithPagesFactory(api.schedules.read, actions.schedules.read),
  ],
  [
    actions.teams.read,
    readWithPagesFactory(api.teams.read, actions.teams.read),
  ],
  [
    actions.games.read,
    function* (payload) {
      const { id, page, startDate, endDate, sort, direction, tz } = payload

      const response = yield call(
        api.games.read,
        id,
        page,
        startDate,
        endDate,
        direction,
        tz
      )

      yield put(actions.games.read.success({ id, data: response.data }))
      return response
    },
  ],
]

export default generateSagas([
  ...org,
  ...entityImageSagas(ENTITY_TYPE, actions.image),
  ...venuesSagas(ENTITY_TYPE, actions.venues),
  ...usersSagas(ENTITY_TYPE, actions.users),
  ...feedSagas(ENTITY_TYPE, actions.feed),
  ...officialsSagas(ENTITY_TYPE, actions.officials),
])
