/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useState } from 'react'
import req from '@sportninja/common/api/request'
import Papa from 'papaparse'

import ImportCSVContainer from '../ImportCSVContainer'
import ImportCSVTitle from '../ImportCSVTitle'
import ImportCSVBanner, { BANNER_TYPES } from '../ImportCSVBanner'
import ImportCSVContentUploadForm from '../ImportCSVContentUploadForm'
import ImportOfficialsErrorDuplicates from './ImportOfficialsErrorDuplicates'
import ImportCSVError from '../ImportCSVError'
import colors from '@sportninja/common/constants/appColors'

const ImportOfficialsContent = ({
  loading,
  setLoading,
  importTemplate,
  teamId,
  teamName,
  teamRosterId,
  toggle,
  onSuccess = () => {},
  orgId,
  isMultiTeamRoster = false,
}) => {
  const [success, setSuccess] = useState(false)
  const [partialSuccess, setPartialSuccess] = useState(false)
  const [importError, setImportError] = useState(false)
  const [file, setFile] = useState(null)
  const [error, setError] = useState(false)
  const [parsedCSV, setParsedCSV] = useState([])
  const [shouldCheckForConflicts, setShouldCheckForConflicts] = useState(true)
  const [hasConflicts, setHasConflicts] = useState(false)
  const [errorCSV, setErrorCSV] = useState(false)
  const [clearRosterBeforeImport, setClearRosterBeforeImport] = useState(false)

  const hasImportErrors = Array.isArray(importError) && importError.length > 0
  const submitUrl = `/organizations/${orgId}/officials/import/csv`

  useEffect(() => {
    return () => {
      setLoading(false)

      setPartialSuccess(false)
      setSuccess(false)
      setImportError(false)
      setFile(false)
      setError(false)
      setParsedCSV([])
      setShouldCheckForConflicts(true)
      setHasConflicts(false)
      setErrorCSV(false)
    }
  }, [])

  useEffect(() => {
    const complete = ({ data }) => {
      setParsedCSV(data)
    }

    if (file) {
      Papa.parse(file, {
        complete,
        skipEmptyLines: true,
      })
    }
  }, [file])

  const onUploadComplete = (response) => {
    setImportError(false)
    setHasConflicts(false)
    setSuccess(response)
    setLoading(false)
    onSuccess()
    setTimeout(toggle, 10000)
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!file) {
      return
    }

    setError(false)
    setPartialSuccess(false)
    setErrorCSV(false)
    setLoading(true)

    const body = new FormData()
    body.append('file', file)
    body.append('conflicts', shouldCheckForConflicts ? 'check' : 'ignore')
    body.append('clear_roster', clearRosterBeforeImport)

    try {
      const response = await req(submitUrl, {
        body,
        method: 'POST',
        parseJSON: false,
        skipAutoContentType: true,
      })

      onUploadComplete(response)
    } catch (e) {
      setLoading(false)

      if (e.statusCode === 409) {
        if (
          Object.prototype.hasOwnProperty.call(e, 'failed') &&
          e.failed.count > 0
        ) {
          setErrorCSV(e.file)
          setImportError(e?.failed?.errors)
        } else if (
          Object.prototype.hasOwnProperty.call(e, 'conflicts') &&
          e.conflicts.count > 0
        ) {
          setHasConflicts(true)
          setImportError(e?.conflicts?.conflicts)
        }

        if (e.success.count > 0) {
          setPartialSuccess(e.success.count)
        }
        // We specifically call the success handler here in the case of error, because
        // sometimes the API will report an error but still process an upload? I know.
        onSuccess()
        setFile(null)
      } else {
        console.log('error', e)
        setError(
          `There was a problem importing your file | ${
            e?.message || JSON.stringify(e)
          }`
        )
      }
    }
  }

  const onFileSelect = ({ target }) => {
    if (!target || !target.files || target.files.length === 0) {
      return
    }

    const file = target.files[0]

    if (
      /image\/svg\+xml/.test(file?.type) ||
      (!/csv/.test(file?.type) && !/\.csv$/.test(file?.name))
    ) {
      setError('Please select a CSV file.')
      setTimeout(() => {
        setError(false)
      }, 5000)
      return
    }

    setError(false)
    setImportError(false)
    setPartialSuccess(false)
    setErrorCSV(false)
    setFile(file)
  }

  return (
    <ImportCSVContainer hasImportErrors={hasImportErrors}>
      <ImportCSVTitle
        importTemplate={importTemplate}
        success={success}
        onClose={toggle}
        title={hasConflicts ? 'Duplicate Review' : undefined}
      />
      {typeof partialSuccess === 'number' && (
        <ImportCSVBanner
          type={BANNER_TYPES.success.type}
          message={`${partialSuccess} item${
            partialSuccess > 1 ? 's were' : ' was'
          } imported successfully.`}
        />
      )}
      {error && (
        <ImportCSVBanner type={BANNER_TYPES.error.type} message={error} />
      )}
      {errorCSV && (
        <div
          css={css`
            display: flex;
            margin-bottom: 16px;
          `}
        >
          <a
            css={css`
              border-radius: 4px;
              border: 2px solid ${colors.DEFAULT_FLAIR};
              padding: 8px 16px;
            `}
            href={`data:text/csv;charset=utf-8,${errorCSV.rows}`}
            download={errorCSV.name}
          >
            Download CSV with failed rows
          </a>
        </div>
      )}
      {hasImportErrors ? (
        hasConflicts ? (
          <ImportOfficialsErrorDuplicates
            conflicts={importError}
            teamName={teamName}
            parsedCSV={parsedCSV}
            file={file}
            resetFile={() => {
              setError(false)
              setImportError(false)
              setFile(null)
              setHasConflicts(false)
              setShouldCheckForConflicts(true)
              setParsedCSV([])
            }}
            submitUrl={submitUrl}
            onUploadComplete={onUploadComplete}
            orgId={orgId}
            isMultiTeamRoster={isMultiTeamRoster}
          />
        ) : (
          <>
            <ImportCSVBanner
              type={BANNER_TYPES.error.type}
              message={BANNER_TYPES.error.message}
            />
            <ImportCSVError errors={importError} />
          </>
        )
      ) : (
        false
      )}
      {success ? (
        <>
          <ImportCSVBanner
            type={BANNER_TYPES.success.type}
            message={
              'Your officials were imported successfully. This dialog will close in 10 seconds.'
            }
          />
          <div
            css={css`
              border: 1px solid #3f4753;
              border-radius: 4px;
              padding: 10px;
            `}
          >
            {success?.success?.count} officials(s) were imported.
          </div>
        </>
      ) : (
        !hasConflicts && (
          <ImportCSVContentUploadForm
            onSubmit={onSubmit}
            loading={loading}
            onFileSelect={onFileSelect}
            resetFile={() => setFile(null)}
            file={file}
            importTemplate={importTemplate}
            error={error}
            clearRosterBeforeImport={clearRosterBeforeImport}
            setClearRosterBeforeImport={setClearRosterBeforeImport}
          />
        )
      )}
    </ImportCSVContainer>
  )
}

export default ImportOfficialsContent
