/** @jsxImportSource @emotion/react */
import {
  DollarOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  LinkOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { RegistrationData } from '@sportninja/common/hooks/useRegistrationData'
import { AnalyticsData } from '@sportninja/common/types/Registration'
import { formatCurrency } from '@sportninja/common/utils/utils'
import React from 'react'
import IndicatorCard from 'src/components/ts-components/IndicatorCard'
import { computeTrendPercentage } from '../helpers'
import Text from 'src/components/ts-components/Text'

interface IndicatorCardsProps {
  data: RegistrationData
  analyticsData: AnalyticsData
  onCopyRegistrationLink: () => void
}

const styles = {
  cardContainer: css`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0 16px;
    }
  `,
  icon: css`
    font-size: 32px;
    color: ${colors.NEUTRAL_100};
  `,
  copyContainer: css`
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  `,
  copyIcon: css`
    color: ${colors.PRIMARY};
    font-size: 12px;
  `,
}

const IndicatorCards: React.FC<IndicatorCardsProps> = ({
  data,
  analyticsData,
  onCopyRegistrationLink,
}) => {
  const isTeamRegistration = data?.detailed?.registration?.is_team_registration
  const isPlayerRegistration =
    data?.detailed?.registration?.is_player_registration

  const CustomCopyLink = React.useMemo(
    () => (
      <div css={styles.copyContainer} onClick={onCopyRegistrationLink}>
        <CopyOutlined css={styles.copyIcon} />
        <Text variant='paragraphXSmall' weight='regular' color={colors.PRIMARY}>
          Copy
        </Text>
      </div>
    ),
    [onCopyRegistrationLink]
  )

  return (
    <div css={styles.cardContainer}>
      <IndicatorCard
        title='Total Registrations'
        subtitle={`${computeTrendPercentage(
          analyticsData?.registrations?.last_7_days_trend_percentage || 0
        )}% since last 7 days`}
        value={String(analyticsData?.registrations?.total || 0)}
        hideSmallIndicator={false}
        icon={<UserOutlined css={styles.icon} />}
      />

      <IndicatorCard
        title='Team Registrations'
        subtitle={`${computeTrendPercentage(
          analyticsData?.team_registrations?.last_7_days_trend_percentage || 0
        )}% since last 7 days`}
        value={String(analyticsData?.team_registrations?.total || 0)}
        icon={<UsergroupAddOutlined css={styles.icon} />}
      />

      <IndicatorCard
        title='Player Registrations'
        subtitle={`${computeTrendPercentage(
          analyticsData?.player_registrations?.last_7_days_trend_percentage || 0
        )}% since last 7 days`}
        value={String(analyticsData?.player_registrations?.total || 0)}
        icon={<UserOutlined css={styles.icon} />}
      />

      <IndicatorCard
        title='Revenue'
        subtitle={`${
          isTeamRegistration
            ? `Team: ${formatCurrency(
                analyticsData?.team_revenue?.total || 0,
                data?.detailed?.registration?.currency?.name || ''
              )}`
            : ''
        } ${isTeamRegistration && isPlayerRegistration ? '|' : ''} ${
          isPlayerRegistration
            ? `Player: ${formatCurrency(
                analyticsData?.player_revenue?.total || 0,
                data?.detailed?.registration?.currency?.name || ''
              )}`
            : ''
        }`}
        value={formatCurrency(
          analyticsData?.revenue?.total || 0,
          data?.detailed?.registration?.currency?.name || ''
        )}
        hideSmallIndicator={true}
        icon={<DollarOutlined css={styles.icon} />}
      />
      <IndicatorCard
        title='Registration Link'
        subtitle='Copy'
        value={
          data?.detailed?.registration?.custom_link ||
          'https://app.sportninja.com/registration...'
        }
        icon={<LinkOutlined css={styles.icon} />}
        hideSubtitle={true}
        customSubtitle={CustomCopyLink}
        hideSmallIndicator={true}
        smallerFontForValue={true}
      />
    </div>
  )
}

// Optional: Create a loading skeleton version
export const IndicatorCardsSkeleton: React.FC = () => {
  return (
    <div css={styles.cardContainer}>
      {Array(4)
        .fill(null)
        .map((_, index) => (
          <div
            key={index}
            css={css`
              background: ${colors.NEUTRAL_10};
              height: 120px;
              flex: 1;
              border-radius: 8px;
              animation: pulse 1.5s infinite;

              @keyframes pulse {
                0% {
                  opacity: 0.6;
                }
                50% {
                  opacity: 1;
                }
                100% {
                  opacity: 0.6;
                }
              }
            `}
          />
        ))}
    </div>
  )
}

export default IndicatorCards

// Usage example:
/*
<IndicatorCards
  data={data}
  registrationLast7DaysTrendPercentage={registrationLast7DaysTrendPercentage}
  registrationsValue={registrationsValue}
  revenueLast7DaysTrendPercentage={revenueLast7DaysTrendPercentage}
  revenueValue={revenueValue}
  onCopyRegistrationLink={onCopyRegistrationLink}
/>

// For loading state
<IndicatorCardsSkeleton />
*/
