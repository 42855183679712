import { useState, useEffect, useCallback } from 'react'
import req from '../api/request'
import { PlayerRoster } from '../types/PlayerRoster'
import { TeamOfficialRoster } from '../types/TeamOfficialRoster'
// Define the possible states for our hook
type FetchState = 'idle' | 'loading' | 'success' | 'error'

// Define the return type of our hook
interface UseGamePlayerRosterResult {
  roster: PlayerRoster | null
  officials: TeamOfficialRoster | null
  fetchState: FetchState
  error: string | null
  refetch: () => void
}

const useGamePlayerRoster = (
  gameId: string | null | undefined,
  rosterId: string | null | undefined
): UseGamePlayerRosterResult => {
  const [roster, setRoster] = useState<PlayerRoster | null>(null)
  const [officials, setOfficials] = useState<TeamOfficialRoster | null>(null)
  const [fetchState, setFetchState] = useState<FetchState>('idle')
  const [error, setError] = useState<string | null>(null)

  const fetchPlayers = useCallback(async () => {
    setFetchState('loading')
    setError(null)
    try {
      const { data } = await req(`/games/${gameId}/rosters/${rosterId}/players`)

      setRoster(data)
      setFetchState('success')
    } catch (err) {
      setFetchState('error')
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }, [gameId, rosterId])

  const fetchTeamOfficials = useCallback(async () => {
    setFetchState('loading')
    setError(null)
    try {
      const { data } = await req(
        `/games/${gameId}/rosters/${rosterId}/team-officials`
      )
      setOfficials(data)
      setFetchState('success')
    } catch (err) {
      setFetchState('error')
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }
  }, [gameId, rosterId])

  useEffect(() => {
    if (gameId && rosterId) {
      fetchPlayers()
      fetchTeamOfficials()
    }
  }, [fetchPlayers, fetchTeamOfficials, gameId, rosterId])

  const refetch = () => {
    if (gameId && rosterId) {
      fetchPlayers()
      fetchTeamOfficials()
    }
  }

  return { roster, officials, fetchState, error, refetch }
}

export default useGamePlayerRoster
