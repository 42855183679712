import { call, put, select } from 'redux-saga/effects'
// TODO: Re-enable this for web platforms
// import { push } from 'connected-react-router'
// import { ROUTES } from '../constants/app'

import {
  clearSessionData,
  getSessionToken,
  getIframeSessionToken,
  sendLogoutSignal,
  setSessionToken,
  setIframeSessionToken,
} from '../api/account'
import req from '../api/request'
import actions from '../actions/auth'
import { generateSagas } from './utils'
import { tutorialKeys } from '../constants/userGuiding'
import { setTutorial } from '../utils/TutorialHelper'

export const api = {
  login: async (body) =>
    await req('/auth/login', { method: 'POST', body, requiresAuth: false }),
  loginRegistration: async (body) =>
    await req('/auth/login?registration=1', {
      method: 'POST',
      body,
      requiresAuth: false,
    }),
  register: async (body) =>
    await req('/signup', { method: 'POST', body, requiresAuth: false }),
  new_register: async (body) =>
    await req('/signup', { method: 'POST', body, requiresAuth: false }),
  refresh: async (useIframeToken) =>
    await req('/auth/refresh', {
      method: 'POST',
      useIframeToken,
      timeout: 10000,
    }),
  verify: async (body) =>
    await req('/signup/validate', {
      method: 'POST',
      body,
      requiresAuth: false,
    }),
  resend: async (body) =>
    await req('/signup/resend', { method: 'POST', body, requiresAuth: false }),

  changePassword: async (body) =>
    await req('/auth/password', { method: 'PUT', body }),
  forgotPassword: async (body) =>
    await req('/auth/password/reset', {
      method: 'POST',
      body,
      requiresAuth: false,
    }),
  forgotPasswordRegistration: async (body, scheduleId) =>
    await req(`/registration/${scheduleId}/password/reset`, {
      method: 'POST',
      body,
      requiresAuth: false,
    }),
  resetPassword: async (body) =>
    await req('/auth/password', { method: 'POST', body, requiresAuth: false }),

  impersonate: async (body) =>
    await req('/auth/impersonate', { method: 'POST', body }),
  setTutorialUserBusiness: async (isBusiness) =>
    await setTutorial(tutorialKeys.NEW_ACCOUNT_USER_BUSINESS, isBusiness),
}

function* setCredentials(response) {
  const inFrame = yield select((state) => state.auth.inFrame)

  // store token in local storage
  inFrame
    ? yield setIframeSessionToken(response.access_token)
    : yield setSessionToken(response.access_token)
  yield put(actions.login.success(response.user))
  return response.user
}

const auth = [
  [
    actions.login,
    function* ({ form }) {
      const body = JSON.stringify({ ...form })

      // log you out, basically
      yield call(clearSessionData)

      const response = yield call(api.login, body)

      return yield call(setCredentials, response)
    },
  ],
  [
    actions.loginRegistration,
    function* ({ form }) {
      const body = JSON.stringify({ ...form })

      // log you out, basically
      yield call(clearSessionData)

      const response = yield call(api.loginRegistration, body)

      yield call(setCredentials, response)

      return response
    },
  ],
  [
    actions.impersonate,
    function* ({ form }) {
      const body = JSON.stringify(form)
      const response = yield call(api.impersonate, body)

      // log you out, basically
      yield call(clearSessionData)

      return yield call(setCredentials, response)
    },
  ],

  // DEPRECATED
  [
    actions.register,
    function* ({ form }) {
      const body = JSON.stringify({ ...form })
      return yield call(api.register, body)
    },
  ],

  [
    actions.new_register,
    function* ({ form }) {
      const body = JSON.stringify({ ...form })
      return yield call(api.new_register, body)
    },
  ],

  [
    actions.logout,
    function* (payload) {
      yield call(clearSessionData)
      yield call(sendLogoutSignal)
      yield put(actions.logout.success())

      // Adding a history entry to the homepage will prevent user from being redirected
      // to a previously viewed organization tab upon re-login (which they may or
      // may not have permission to view!)
      //
      // However, this saga is called via auto-logout due to a failed login or a newly
      // created account - and we don't want to do any navigation in those scenarios.
      // if (payload && payload.goToHome) yield put(push(ROUTES.HOME))
    },
  ],

  [
    actions.refresh,
    function* () {
      const inFrame = yield select((state) => state.auth.inFrame)
      const token = inFrame
        ? yield getIframeSessionToken()
        : yield getSessionToken()

      if (token === null) {
        throw new Error('No session token to refresh.')
      }

      const response = yield call(api.refresh, inFrame)
      return yield call(setCredentials, response)
    },
  ],

  [
    actions.verify,
    function* ({ token }) {
      const body = JSON.stringify({ token })
      const response = yield call(api.verify, body)
      const setCredentialsResponse = yield call(setCredentials, response)
      // Account type 2 is business
      if (response?.user?.account_type && response?.user?.account_type === 2) {
        yield call(api.setTutorialUserBusiness, true)
      } else {
        yield call(api.setTutorialUserBusiness, false)
      }
      return setCredentialsResponse
    },
  ],

  [
    actions.resend,
    function* ({ email }) {
      const body = JSON.stringify({ email })
      return yield call(api.resend, body)
    },
  ],

  [
    actions.forgot,
    function* ({ email }) {
      const body = JSON.stringify({ ...email })
      return yield call(api.forgotPassword, body)
    },
  ],

  [
    actions.forgot_registration,
    function* ({ email, scheduleId }) {
      const body = JSON.stringify({ ...email })
      return yield call(api.forgotPasswordRegistration, body, scheduleId)
    },
  ],

  [
    actions.reset,
    function* ({ password, token }) {
      const body = JSON.stringify({ password, token })
      const response = yield call(api.resetPassword, body)

      return yield call(setCredentials, response)
    },
  ],

  [
    actions.change,
    function* ({ form }) {
      const body = JSON.stringify({
        password_old: form.password_old,
        password: form.password,
      })
      return yield call(api.changePassword, body)
    },
  ],
  [
    actions.setCredentials,
    function* ({ token, user }) {
      const inFrame = yield select((state) => state.auth.inFrame)
      inFrame
        ? yield setIframeSessionToken(token)
        : yield setSessionToken(token)
      yield put(actions.login.success(user))
      return user
    },
  ],
]

export default generateSagas([...auth])
