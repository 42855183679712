import { call, put } from 'redux-saga/effects'

import actions from '../actions/invite'

import { readFactory } from './helpers/read'

import req from '@sportninja/common/api/request'
import { ENTITY_TYPES, generateSagas } from './utils'
const ENTITY_TYPE = ENTITY_TYPES.invite

const api = {
  accept: async (id, body) =>
    await req(`/invitations/${id}/accept`, { method: 'POST', body }),
}

const invite = [
  [actions.read, readFactory(ENTITY_TYPE, actions.read)],
  [
    actions.accept,
    function* (payload) {
      const {
        id,
        user_id,
        accepted_format,
        selected_position_id,
        selected_position_type,
        ask_position,
      } = payload
      const json_payload = { user_id, accepted_format }
      if (ask_position && selected_position_id && selected_position_type) {
        if (selected_position_type === 'PLAYER') {
          json_payload.player_type_id = selected_position_id
        }
        if (selected_position_type === 'TEAM_OFFICIAL') {
          json_payload.team_official_type_id = selected_position_id
        }
      }
      const body = JSON.stringify(json_payload)
      const response = yield call(api.accept, id, body)
      yield put(actions.accept.success({ id }))
      return response
    },
  ],
]

export default generateSagas([...invite])
