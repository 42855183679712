/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React, { useEffect, useState } from 'react'
import { Table, Button, Tooltip } from 'antd'
import {
  FileTextOutlined,
  DollarOutlined,
  EllipsisOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons'
import colors from '@sportninja/common/constants/appColors'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import CurrencyInput from 'src/components/ts-components/CurrencyInput'
import {
  paragraphXSmall,
  weightRegular,
  paragraphMedium,
} from 'src/components/css'
import type { TableProps } from 'antd'
import { SearchControls } from './SearchControls'

type PlayerStatus =
  | 'Approved'
  | 'Pending'
  | 'Not Approved'
  | 'Accepted'
  | 'Open'

interface Player {
  id: string
  name: string
  avatar: string
  allocated: number
  paid: number
  totalOwed: number
  status: PlayerStatus
  division?: string
}

interface PlayerRegistrationBalanceTableProps {
  players: Player[]
}

const tableStyles = css`
  /* margin-top: 16px; */
  .ant-table {
    background: transparent;
  }
  .ant-table-thead > tr > th {
    ${paragraphXSmall}
    ${weightRegular}
    background-color: ${colors.SECONDARY_400};
    color: ${colors.NEUTRAL_50};
    text-transform: uppercase;
    white-space: nowrap;
    border-bottom: none;
    height: 30px !important;
    padding: 8px !important;
  }
  .ant-table-thead > tr > td {
    ${paragraphMedium}
    ${weightRegular}
    color: ${colors.WHITE};
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 30px !important;
    padding: 8px 16px !important;
  }
  .ant-table-tbody > tr:nth-child(even) {
    background-color: ${colors.SECONDARY_300};
  }
  .ant-table-tbody > tr:nth-child(odd) {
    background-color: ${colors.SECONDARY_400};
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
    padding: 12px 16px;
    color: ${colors.WHITE};
  }
  .ant-table-cell {
    vertical-align: middle !important;
    padding: 4px 8px !important;
  }
  th.ant-table-cell::before {
    display: none !important;
  }
  .ant-table-container {
    overflow-x: auto;
  }
`

const avatarStyles = css`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 12px;
`

const nameColumnStyles = css`
  display: flex;
  align-items: center;
`

const optionsStyles = css`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  .ant-btn {
    width: 24px;
    height: 24px;
    color: ${colors.PRIMARY};
    &:hover {
      color: ${colors.PRIMARY_LIGHT};
      background: transparent;
    }
  }
`

const currencyContainerStyles = css`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 160px;
  justify-content: space-between;
`

const currencyInputStyles = css`
  .ant-input-number {
    width: 120px !important;
  }
`

const lockIconStyles = css`
  color: ${colors.PRIMARY};
  font-size: 16px;
  cursor: pointer;
  &:hover {
    color: ${colors.PRIMARY_LIGHT};
  }
`

const PlayerRegistrationBalanceTable: React.FC<
  PlayerRegistrationBalanceTableProps
> = ({ players }) => {
  const [lockedPlayers, setLockedPlayers] = useState<{
    [key: string]: boolean
  }>({})
  const [searchText, setSearchText] = useState<string>('')
  const [selectedDivision, setSelectedDivision] = useState<string>('all')
  const [showConsole, setShowConsole] = useState<boolean>(false)

  useEffect(() => {
    setSearchText('')
    setSelectedDivision('all')
  }, [players])

  const toggleLock = (playerId: string) => {
    setLockedPlayers((prev) => ({
      ...prev,
      [playerId]: !prev[playerId],
    }))
  }

  const statusOptions = [
    { label: 'Approved', value: 'Approved' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Not Approved', value: 'Not Approved' },
    { label: 'Accepted', value: 'Accepted' },
    { label: 'Open', value: 'Open' },
  ]

  // Mock divisions for now - replace with actual divisions from props or API
  const divisions = [
    { id: 'div1', name: 'Division 1' },
    { id: 'div2', name: 'Division 2' },
    { id: 'div3', name: 'Division 3' },
  ]

  const filteredPlayers = players.filter((player) => {
    const matchesSearch = player.name
      .toLowerCase()
      .includes(searchText.toLowerCase())
    const matchesDivision =
      selectedDivision === 'all' || player.division === selectedDivision
    return matchesSearch && matchesDivision
  })

  const columns: TableProps<Player>['columns'] = [
    {
      title: 'PLAYER',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text: string, _record: Player) => (
        <div css={nameColumnStyles}>
          <img
            src={'/images/generic_placeholders/placeholder_player.png'}
            alt={text}
            css={avatarStyles}
            onError={(e) => {
              e.currentTarget.src =
                '/images/generic_placeholders/placeholder_player.png'
            }}
          />
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: 'ALLOCATED',
      dataIndex: 'allocated',
      key: 'allocated',
      width: 100,
      sorter: (a, b) => (a.allocated || 0) - (b.allocated || 0),
      render: (value: number, record: Player) => (
        <div css={currencyContainerStyles}>
          <div css={currencyInputStyles}>
            <CurrencyInput
              label=''
              value={value || 0}
              onChange={(newValue) =>
                console.log('Allocated changed:', newValue)
              }
              name={`allocated-${record.id}`}
              fieldErrors={[]}
              customError=''
              disabled={lockedPlayers[record.id]}
              selectStyle={css`
                /* height: 30px !important; */
              `}
            />
          </div>
          <Tooltip
            title={
              lockedPlayers[record.id] ? 'Unlock allocation' : 'Lock allocation'
            }
            placement='top'
          >
            <div
              css={lockIconStyles}
              onClick={() => toggleLock(record.id)}
              role='button'
              tabIndex={0}
            >
              {lockedPlayers[record.id] ? <LockOutlined /> : <UnlockOutlined />}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'PAID',
      dataIndex: 'paid',
      key: 'paid',
      width: 120,
      sorter: (a, b) => (a.paid || 0) - (b.paid || 0),
      render: (value: number) => `$${value?.toFixed(2) || 0}`,
    },
    {
      title: 'TOTAL OWED',
      dataIndex: 'totalOwed',
      key: 'totalOwed',
      width: 120,
      sorter: (a, b) => a.totalOwed - b.totalOwed,
      render: (value: number) => `$${value.toFixed(2)}`,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: 160,
      filters: statusOptions.map((option) => ({
        text: option.label,
        value: option.value,
      })),
      onFilter: (value, record: Player) =>
        record.status === (value as PlayerStatus),
      render: (status: PlayerStatus, record: Player) => (
        <SelectInput
          label=''
          disabled={false}
          options={statusOptions}
          name={`status-${record.id}`}
          value={status}
          onChange={(value) => console.log('Status changed:', value)}
          fieldErrors={[]}
          customError=''
          style={'height: 36px !important;'}
        />
      ),
    },
    {
      title: 'OPTIONS',
      key: 'options',
      width: 120,
      align: 'right',
      render: () => (
        <div css={optionsStyles}>
          <Tooltip title='View details' placement='top'>
            <Button
              icon={<FileTextOutlined style={{ fontSize: 20 }} />}
              type='text'
            />
          </Tooltip>
          <Tooltip title='Payment history' placement='top'>
            <Button
              icon={<DollarOutlined style={{ fontSize: 20 }} />}
              type='text'
            />
          </Tooltip>
          <Tooltip title='More options' placement='top'>
            <Button
              icon={<EllipsisOutlined style={{ fontSize: 20 }} />}
              type='text'
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  return (
    <>
      <SearchControls
        searchText={searchText}
        setSearchText={setSearchText}
        selectedDivision={selectedDivision}
        setSelectedDivision={setSelectedDivision}
        divisions={divisions}
        showConsole={showConsole}
        setShowConsole={setShowConsole}
      />
      <Table
        css={tableStyles}
        columns={columns}
        dataSource={filteredPlayers}
        pagination={false}
        rowKey='id'
        bordered={false}
        scroll={{ x: 'max-content' }}
        size='small'
      />
    </>
  )
}

export default PlayerRegistrationBalanceTable
