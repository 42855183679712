/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React, { useEffect, useState } from 'react'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import req from '@sportninja/common/api/request'

import Text from '../ts-components/Text'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const { Option, OptGroup } = Select

interface Entity {
  id: string
  name_full: string
}

interface FeedItemParentPickerProps {
  isOpen?: boolean
  hasError?: boolean
  defaultValue?: string
  onChange: (value: { parent_id: string; entityType: string }) => void
  orgs: Entity[]
  teams: Entity[]
  scheds: Entity[]
  disabled?: boolean
  selected: string | undefined
  setSelected: (value: any) => void
  selectedType: string | null
  setSelectedType: (value: string | null) => void
  targetAudience: number | null
  setTargetAudience: (value: number | null) => void
  setParentId: (value: string) => void
  setParentEntityType: (
    value: 'team' | 'schedule' | 'organization' | undefined
  ) => void
}

const FeedParentPicker: React.FC<FeedItemParentPickerProps> = ({
  hasError,
  onChange,
  orgs = [],
  teams = [],
  scheds = [],
  disabled = false,
  selected,
  setSelected,
  selectedType,
  setSelectedType,
  targetAudience,
  setTargetAudience,
  setParentId,
  setParentEntityType,
  isOpen = false,
  defaultValue,
}) => {
  const [loadingTargetAudience, setLoadingTargetAudience] = useState(false)

  // If there is only one option, select it automatically
  useEffect(() => {
    const orgEmpty = orgs.length === 0
    const teamEmpty = teams.length === 0
    const schedEmpty = scheds.length === 0
    if (!orgEmpty && !teamEmpty && !schedEmpty) {
      return
    }
    if (orgEmpty && !teamEmpty && schedEmpty) {
      setSelected(teams[0].id)
      setSelectedType('team')
      setParentId(teams[0].id)
      setParentEntityType('team')
    }
    if (orgEmpty && teamEmpty && !schedEmpty) {
      setSelected(scheds[0].id)
      setSelectedType('sched')
      setParentId(scheds[0].id)
      setParentEntityType('schedule')
    }
    if (!orgEmpty && teamEmpty && schedEmpty) {
      setSelected(orgs[0].id)
      setSelectedType('org')
      setParentId(orgs[0].id)
      setParentEntityType('organization')
    }
  }, [orgs, teams, scheds, isOpen])

  useEffect(() => {
    const getTargetAudience = async () => {
      if (!selected || !selectedType) {
        return
      }

      try {
        setLoadingTargetAudience(true)
        let type = ''
        if (selectedType === 'org') {
          type = 'organization'
        } else if (selectedType === 'team') {
          type = 'team'
        } else if (selectedType === 'sched') {
          type = 'competition'
        }

        const { data } = await req(`/social-feed/${selected}/${type}`)
        if (data?.total_users > 0) {
          setTargetAudience(data.total_users)
        }
      } catch (e) {
        console.error(e)
        setTargetAudience(null)
      } finally {
        setLoadingTargetAudience(false)
      }
    }

    getTargetAudience()
  }, [selected, selectedType])

  const handleChange: SelectProps['onChange'] = (value, _option) => {
    const selectedEntity = [...orgs, ...teams, ...scheds].find(
      (entity) => entity.id === value
    )
    const isOrg = orgs.some((org) => org.id === value)
    const isTeam = teams.some((team) => team.id === value)
    const isSched = scheds.some((sched) => sched.id === value)
    const entityType: 'org' | 'team' | 'sched' | null = isOrg
      ? 'org'
      : isTeam
      ? 'team'
      : isSched
      ? 'sched'
      : null
    setSelected(selectedEntity?.id || '')
    setSelectedType(entityType)
    onChange({
      parent_id: selectedEntity?.id || '',
      entityType: entityType as 'org' | 'team' | 'sched',
    })
  }

  const renderOption = (entity: Entity, type: string) => {
    return (
      <Option key={entity.id} value={entity.id}>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <img
            src={
              getImageThumbnailId(entity) || type === 'org'
                ? '/images/generic_placeholders/placeholder_organization.png'
                : type === 'team'
                ? '/images/generic_placeholders/placeholder_team.png'
                : '/images/generic_placeholders/placeholder_competition.png'
            }
            alt={entity.name_full}
            width={24}
            height={24}
            css={css`
              border-radius: 4px;
              border: 1px solid ${colors.WHITE};
            `}
          />
          <Text variant='paragraphSmall' color={colors.WHITE}>
            {entity.name_full}
          </Text>
        </div>
      </Option>
    )
  }

  return (
    <div>
      <style>
        {`
          .ant-select-dropdown {
            z-index: 999999 !important;
          }
          .ant-select-selector {
            background: ${colors.SECONDARY_200} !important;
            border-radius: 8px !important;
          }
          .ant-select-selection-item {
            font-size: 16px !important;
            font-weight: 400 !important;
            color: ${colors.WHITE} !important;
          }
        `}
      </style>
      <Text
        variant='paragraphSmall'
        color={colors.WHITE}
        style={'margin-bottom: 8px;'}
      >
        Post To:
      </Text>
      <Select
        disabled={disabled}
        placeholder={
          isCanlan
            ? 'Search leagues, seasons, or teams'
            : 'Search organizations, competitions, or teams'
        }
        onChange={handleChange}
        value={selected || defaultValue}
        showSearch={false}
        filterOption={(input, option) => {
          const optionText = option?.label?.toString().toLowerCase() || ''
          return optionText.indexOf(input.toLowerCase()) >= 0
        }}
        status={hasError ? 'error' : undefined}
        dropdownStyle={{ backgroundColor: colors.SECONDARY_300 }}
        css={css`
          width: 100%;
          border-radius: 8px;
          border: 1px solid ${colors.SECONDARY_100};
          background: ${colors.SECONDARY_200};
          ${disabled &&
          css`
            color: ${colors.NEUTRAL_100};
          `}
          ${!disabled &&
          css`
            color: ${colors.WHITE};
          `}
        `}
      >
        {orgs?.length > 0 ? (
          <OptGroup label={isCanlan ? 'Leagues' : 'Organizations'}>
            {orgs.map((org) => renderOption(org, 'org'))}
          </OptGroup>
        ) : null}
        {scheds?.length > 0 ? (
          <OptGroup label={isCanlan ? 'Seasons' : 'Competitions'}>
            {scheds.map((sched) => renderOption(sched, 'sched'))}
          </OptGroup>
        ) : null}
        {teams?.length > 0 ? (
          <OptGroup label='Teams'>
            {teams.map((team) => renderOption(team, 'team'))}
          </OptGroup>
        ) : null}
      </Select>
      {selected && targetAudience && !loadingTargetAudience && (
        <Text
          variant='paragraphXSmall'
          color={colors.NEUTRAL_100}
          style={'margin-top: 8px;'}
        >
          Audience: {targetAudience}
        </Text>
      )}
    </div>
  )
}

export default FeedParentPicker
