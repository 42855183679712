/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { RegistrationTableDivision } from 'src/pages/Schedule/PlayerRegistration/helpers'
import { RegisteredPlayer, Team } from '@sportninja/common/types/Registration'

// Add useDebounce hook
const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

interface UseSearchAndFilterProps {
  divisions: RegistrationTableDivision[]
  setExpandedRows: (rows: string[]) => void
}

interface UseSearchAndFilterReturn {
  searchText: string
  setSearchText: (text: string) => void
  selectedDivision: string
  setSelectedDivision: (division: string) => void
  getFilteredTeams: (teams: Team[]) => Team[]
  getFilteredDivisions: () => RegistrationTableDivision[]
  openDivisions: { [key: number]: boolean }
  setOpenDivisions: React.Dispatch<
    React.SetStateAction<{ [key: number]: boolean }>
  >
  allExpanded: boolean
  setAllExpanded: React.Dispatch<React.SetStateAction<boolean>>
  getFilteredPlayers: (players: RegisteredPlayer[]) => RegisteredPlayer[]
  onlyBuckets: boolean
  setOnlyBuckets: React.Dispatch<React.SetStateAction<boolean>>
  selectedStatuses: number[]
  setSelectedStatuses: React.Dispatch<React.SetStateAction<number[]>>
}

// Default statuses (all except Cancelled)
const defaultStatuses = [1, 2, 3, 4] // Submitted, Pending, Approved, Approved w/ Conditions

export const useSearchAndFilter = ({
  divisions,
  setExpandedRows,
}: UseSearchAndFilterProps): UseSearchAndFilterReturn => {
  const [searchText, setSearchText] = useState('')
  const [selectedDivision, setSelectedDivision] = useState<string>('all')
  const [openDivisions, setOpenDivisions] = useState<{
    [key: number]: boolean
  }>({})
  const [allExpanded, setAllExpanded] = useState(false)
  const [onlyBuckets, setOnlyBuckets] = useState(false)
  const [selectedStatuses, setSelectedStatuses] =
    useState<number[]>(defaultStatuses)
  const prevSelectedStatuses = useRef<number[]>([...selectedStatuses])
  const isFirstRender = useRef(true)
  const isFirstStatusChange = useRef(true)

  // Use debounced search text
  const debouncedSearchText = useDebounce(searchText, 500)

  // Memoize the lowercase search text to avoid repeated conversions
  const searchLower = useMemo(
    () => debouncedSearchText.toLowerCase(),
    [debouncedSearchText]
  )

  // Memoize the status filter function
  const filterByStatus = useCallback(
    (item: { registration_status: number }) =>
      selectedStatuses.length === 0 ||
      selectedStatuses.includes(item.registration_status),
    [selectedStatuses]
  )

  // Memoize the search text filter function
  const filterBySearchText = useCallback(
    (item: {
      name_first?: string
      name_last?: string
      name_full?: string
      name?: string
    }) => {
      if (!searchLower) {
        return true
      }
      return (
        item.name?.toLowerCase().includes(searchLower) || // For division names
        item.name_first?.toLowerCase().includes(searchLower) ||
        item.name_last?.toLowerCase().includes(searchLower) ||
        item.name_full?.toLowerCase().includes(searchLower)
      )
    },
    [searchLower]
  )

  useEffect(() => {
    if (isFirstRender.current) {
      setOpenDivisions(
        divisions.reduce((acc, _, index) => {
          acc[index] = false
          return acc
        }, {})
      )
      setAllExpanded(false)
      isFirstRender.current = false
    }
  }, [divisions])

  // Set all divisions that have buckets to open if onlyBuckets is true
  useEffect(() => {
    if (onlyBuckets) {
      const newOpenDivisions = divisions.reduce((acc, division, index) => {
        if (division.teams?.some((team) => team.is_bucket)) {
          acc[index] = true
        }
        return acc
      }, {})
      setOpenDivisions((prev) => ({ ...prev, ...newOpenDivisions }))
    }
  }, [divisions, onlyBuckets])

  // If one division is selected, set the first division to open
  useEffect(() => {
    if (selectedDivision !== 'all') {
      setOpenDivisions({ [0]: true })
    }
    // else {
    //   console.log('Chamou aqui2', selectedDivision)
    //   setOpenDivisions(
    //     divisions.reduce((acc, _, index) => {
    //       acc[index] = false
    //       return acc
    //     }, {})
    //   )
    //   setAllExpanded(false)
    // }
  }, [selectedDivision, divisions])

  // If there is a search text, and there are filtered players, we should expand the team rows that have players
  useEffect(() => {
    if (debouncedSearchText && debouncedSearchText.length > 0) {
      const filteredPlayers = getFilteredPlayers(
        divisions.flatMap((division) =>
          division.teams?.flatMap((team) => team.registered_players || [])
        )
      )
      if (filteredPlayers.length > 0) {
        const teamIds = filteredPlayers.map((player) => player.team_roster_id)
        setExpandedRows(teamIds.filter((id) => id !== undefined) as string[])
      }
    }
  }, [debouncedSearchText, divisions])

  // If the status filter is changed, check the diviions to see if they have teams or players that match the status
  useEffect(() => {
    if (isFirstStatusChange.current && divisions?.length > 0) {
      isFirstStatusChange.current = false
      return
    }
    if (selectedStatuses.length > 0 && divisions?.length > 0) {
      // If the selected statuses are the same as the previous selected statuses, don't filter the divisions
      if (prevSelectedStatuses?.current?.length === selectedStatuses?.length) {
        return
      }
      prevSelectedStatuses.current = selectedStatuses
      const filteredDivisions = divisions.filter((division) =>
        division.teams?.some(
          (team) =>
            selectedStatuses.includes(team.registration_status) ||
            team.registered_players?.some((player) =>
              selectedStatuses.includes(player.registration_status)
            )
        )
      )
      setOpenDivisions(
        filteredDivisions.reduce((acc, _, index) => {
          acc[index] = true
          return acc
        }, {})
      )
      // Filter the divisions to only include those that have players with the selected statuses. If so, open the team rows
      const hasPlayersWithSelectedStatuses = filteredDivisions?.filter(
        (division) =>
          division.teams?.some((team) =>
            team.registered_players?.some((player) =>
              selectedStatuses.includes(player.registration_status)
            )
          )
      )
      if (hasPlayersWithSelectedStatuses?.length > 0) {
        const teamIds = hasPlayersWithSelectedStatuses.flatMap((division) =>
          division.teams?.map((team) => team.team_roster_id)
        )
        setExpandedRows(teamIds.filter((id) => id !== undefined) as string[])
      }
      setAllExpanded(true)
    }
  }, [divisions, selectedStatuses])

  // Optimize getFilteredTeams with memoized filters
  const getFilteredTeams = useCallback(
    (teams: Team[]) => {
      if (!teams) {
        return []
      }

      return teams
        .filter((team) => {
          // Apply status filter
          const teamStatusMatches = filterByStatus(team)
          const hasMatchingPlayers =
            team.registered_players?.some(filterByStatus)
          return teamStatusMatches || hasMatchingPlayers
        })
        .filter((team) => {
          // Apply bucket filter
          if (onlyBuckets) {
            return team.is_bucket
          }
          return true
        })
        .filter((team) => {
          // Apply search text filter
          if (!searchLower) {
            return true
          }
          const teamNameMatch = team.name_full
            .toLowerCase()
            .includes(searchLower)
          const hasMatchingPlayer =
            team.registered_players?.some(filterBySearchText)
          return teamNameMatch || hasMatchingPlayer
        })
        .map((team) => ({
          ...team,
          key: `${team.team_roster_id}`,
        }))
    },
    [filterByStatus, filterBySearchText, onlyBuckets, searchLower]
  )

  // Optimize getFilteredPlayers with memoized filters
  const getFilteredPlayers = useCallback(
    (players: RegisteredPlayer[]) => {
      if (!players) {
        return []
      }

      return players
        .filter(filterByStatus)
        .filter((player) => !searchLower || filterBySearchText(player))
        .map((player) => ({
          ...player,
          key: player.uid,
        }))
    },
    [filterByStatus, filterBySearchText, searchLower]
  )

  // Optimize getFilteredDivisions with memoized filters
  const getFilteredDivisions = useCallback(() => {
    const filterDivision = (division: RegistrationTableDivision) => {
      // If there's no search text, always show the division
      if (!searchLower) {
        return true
      }

      // Check if division name matches the search text
      if (division.name?.toLowerCase().includes(searchLower)) {
        return true
      }

      // If there's a search text, only show divisions with matching results
      const filteredTeams = getFilteredTeams(division.teams || [])
      const allPlayers =
        division.teams?.flatMap((team) => team.registered_players || []) || []
      const filteredPlayers = getFilteredPlayers(allPlayers)
      return filteredTeams.length > 0 || filteredPlayers.length > 0
    }

    return selectedDivision === 'all'
      ? divisions.filter(filterDivision)
      : divisions.filter(
          (division) =>
            division.id === selectedDivision && filterDivision(division)
        )
  }, [
    divisions,
    selectedDivision,
    getFilteredTeams,
    getFilteredPlayers,
    searchLower,
  ])

  return {
    searchText,
    setSearchText,
    selectedDivision,
    setSelectedDivision,
    getFilteredTeams,
    getFilteredDivisions,
    openDivisions,
    setOpenDivisions,
    allExpanded,
    setAllExpanded,
    getFilteredPlayers,
    onlyBuckets,
    setOnlyBuckets,
    selectedStatuses,
    setSelectedStatuses,
  }
}
