// @ts-ignore
/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled/macro'
import colors from '@sportninja/common/constants/appColors'
import { paragraphXSmall, weightBold, weightMedium } from 'src/components/css'

export const ScheduleLink = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  ${paragraphXSmall};
  ${weightMedium}
  color: ${colors.PRIMARY};
  text-transform: uppercase;
`

export const MatchDateTime = styled.div`
  ${paragraphXSmall}
  ${weightBold}
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${colors.ATTENDANCE_GRAY};
  ${(props) =>
    // @ts-ignore
    props.isDesktop
      ? css`
          padding-left: 2px;
          font-weight: bold;
          text-transform: uppercase;
        `
      : css`
          text-align: center;
        `}
`

export const ArrowRight = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <g clip-path='url(#clip0_6717_844)'>
      <path
        d='M5.72656 11.06L8.7799 8L5.72656 4.94L6.66656 4L10.6666 8L6.66656 12L5.72656 11.06Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_6717_844'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export const AutoPlayButton = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='192'
    height='192'
    viewBox='0 0 192 192'
    fill='none'
  >
    <g opacity='0.6'>
      <rect
        x='4'
        y='4'
        width='184'
        height='184'
        rx='92'
        stroke='white'
        stroke-width='8'
      />
      <path
        d='M64 54.5601V137.44C64 143.76 70.96 147.6 76.32 144.16L141.44 102.72C146.4 99.6001 146.4 92.4001 141.44 89.2001L76.32 47.8401C70.96 44.4001 64 48.2401 64 54.5601Z'
        fill='white'
      />
    </g>
  </svg>
)
