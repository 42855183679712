import req from '@sportninja/common/api/request'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import queryString from 'query-string'
import { useUrlManager } from 'src/hooks/useUrlManager'

const teamSort = (a, b) => {
  const aName = a?.name?.toLowerCase()
  const bName = b?.name?.toLowerCase()
  if (aName < bName) {
    return -1
  }
  if (aName > bName) {
    return 1
  }
  return 0
}

const useGetScheduleFiltering = ({ team_id, schedule_id }, options = {}) => {
  const { currentQueryParams, setParam } = useUrlManager()

  const currentParams = useMemo(() => {
    return {
      sn_schedule: currentQueryParams.get('sn_schedule'),
      sn_division: currentQueryParams.get('sn_division'),
      sn_team: currentQueryParams.get('sn_team'),
      sn_team_name: currentQueryParams.get('sn_team_name'),
      sn_starts_at: currentQueryParams.get('sn_starts_at'),
      sn_ends_at: currentQueryParams.get('sn_ends_at'),
      sn_sort: currentQueryParams.get('sn_sort') || 'asc',
      sn_today: Boolean(currentQueryParams.get('sn_today')),
      sn_sort_by: currentQueryParams.get('sn_sort_by'),
      sn_schedule_presentation: currentQueryParams.get(
        'sn_schedule_presentation'
      ),
    }
  }, [currentQueryParams])

  const {
    sn_schedule,
    sn_division,
    sn_team,
    sn_starts_at,
    sn_ends_at,
    sn_sort,
    sn_today,
    sn_team_name,
  } = currentParams

  const {
    autoSelectMostRecentComp = false,
    defaultSort = 'asc',
    onScheduleChange,
    onTeamChange,
    onIsGoalie,
  } = options

  const [teams, setTeams] = useState([])
  const [team, setTeam] = useState(sn_team || '')
  const [filters, setFilters] = useState([])
  const [dateRange, setDateRange] = useState({
    starts_at: sn_starts_at,
    ends_at: sn_ends_at,
  })
  const [state, setState] = useState({})
  const [sort, setSort] = useState(sn_sort || defaultSort)
  const [loaded, setLoaded] = useState(false)
  const [currentQueryString] = useState(window.location.search)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm)

  useEffect(() => {
    let timeout

    const debounce = () => {
      timeout = setTimeout(() => {
        setDebouncedSearchTerm(searchTerm)
      }, 600)
    }

    clearTimeout(timeout)
    debounce()

    return () => {
      clearTimeout(timeout)
    }
  }, [searchTerm])

  useEffect(() => {
    const getTeamFilters = async () => {
      const response = await req(`/teams/${team_id}/schedules`)
      const scheduleTypes = []

      // The data structure from this endpoint is "backwards" in that it shows
      // the schedules the team belongs to, and each schedule includes its
      // own parent. Instead, we need the root schedules, with child schedules
      // contained within.
      const populateSchedules = (schedule) => {
        const existingScheduleType = scheduleTypes.find(
          (type) => type?.name === schedule?.type
        )
        if (!existingScheduleType) {
          scheduleTypes.push({
            name: schedule?.type,
            schedules: [schedule],
          })
        } else if (
          !existingScheduleType.schedules.find((s) => s.id === schedule.id)
        ) {
          existingScheduleType.schedules.push(schedule)
        }

        if (
          Object.prototype.hasOwnProperty.call(schedule, 'parent') &&
          schedule?.parent !== null
        ) {
          populateSchedules(schedule.parent)
        }
      }

      response.data.map(populateSchedules)

      if (scheduleTypes.length === 0) {
        setLoaded(true)
        return
      }

      scheduleTypes.reverse()

      // Hacky shit because sometimes competitions aren't always season > conference > division
      // They can be season only, or season > conference, or season > division
      // But, we always want Season (i.e. the root competition) to come first
      if (scheduleTypes[0]?.name !== 'Season') {
        const seasonIdx = scheduleTypes.findIndex((s) => s?.name === 'Season')
        if (seasonIdx !== -1) {
          const season = scheduleTypes[seasonIdx]
          scheduleTypes.splice(seasonIdx, 1)
          scheduleTypes.unshift(season)
        }
      }

      let scheduleFromQueryString = ''
      if (Array.isArray(scheduleTypes[0].schedules)) {
        scheduleTypes[0].schedules.sort((a, b) => {
          const aStart = dayjs(a.starts_at)
          const bStart = dayjs(b.starts_at)

          if (aStart.isBefore(bStart)) {
            return 1
          } else if (aStart.isAfter(bStart)) {
            return -1
          }

          return 0
        })

        const query = queryString.parse(currentQueryString)
        if (query.schedule_id && query.schedule_id.length > 0) {
          scheduleFromQueryString = query.schedule_id
        }

        if (typeof onScheduleChange === 'function') {
          if (scheduleFromQueryString) {
            onScheduleChange(scheduleFromQueryString)
          } else if (autoSelectMostRecentComp && !sn_schedule && !sn_division) {
            // Select the first schedule in the first schedule type dropdown, which
            // since it's sorted, will be the most recent
            setParam('sn_schedule', scheduleTypes[0]?.schedules?.[0]?.id)
            onScheduleChange(scheduleTypes[0]?.schedules?.[0]?.id)
          }
        }

        if (
          typeof onIsGoalie === 'function' &&
          query.is_goalie &&
          query.is_goalie === '1'
        ) {
          onIsGoalie(true)
        }
      }

      const keyNames = scheduleTypes.reduce((collector, type, idx) => {
        if (type.name === 'Conference') {
          return collector
        }
        const schedules = type.schedules
        // Here, we need to ensure we update the dropdown itself
        const preSelectedSchedule = scheduleFromQueryString
          ? scheduleFromQueryString
          : autoSelectMostRecentComp
          ? schedules[0].id
          : ''
        return {
          ...collector,
          [type.name]:
            idx === 0 && !sn_schedule && !sn_division
              ? preSelectedSchedule
              : type.name === 'Season'
              ? sn_schedule
              : type.name === 'Division'
              ? sn_division
              : preSelectedSchedule,
        }
      }, {})

      setState(keyNames)
      setFilters(scheduleTypes)

      setLoaded(true)
    }

    const getScheduleFilters = async () => {
      let teams = []
      const response = await req(`/schedules/${schedule_id}/children/dropdown`)

      const _data = response.data

      if (_data.length === 0) {
        return
      }

      const data = _data.length > 1 ? _data.slice(1) : _data
      const keyNames = data.reduce((collector, scheduleType) => {
        // Sometimes the API will incorrectly report a sub competition type
        // with no schedules, and this will break standings
        if (!scheduleType?.schedules || scheduleType?.schedules.length === 0) {
          return collector
        }

        // scheduleType like Conference, Division, etc.
        scheduleType?.schedules?.forEach((schedule) => {
          schedule?.teams?.forEach((team) => {
            teams.push({ ...team, schedule_id: schedule.id })
          })
        })

        return {
          ...collector,
          [scheduleType.name]:
            scheduleType.name === 'Division'
              ? sn_division || ''
              : scheduleType.name === 'Season'
              ? sn_schedule || ''
              : '',
        }
      }, {})

      setTeams(teams.sort(teamSort))
      setState(keyNames)
      setFilters(data)

      const query = queryString.parse(currentQueryString)

      if (
        typeof onScheduleChange === 'function' &&
        query.scheduleType &&
        query.scheduleType.length > 0 &&
        query.schedule_id &&
        query.schedule_id.length > 0
      ) {
        onScheduleChange(query.schedule_id)
        setState((s) => {
          const newState = { ...s }
          newState[query.scheduleType] = query.schedule_id
          return newState
        })
      }

      if (
        typeof onTeamChange === 'function' &&
        query.team_id &&
        query.team_id.length > 0
      ) {
        onTeamChange(query.team_id)
      }

      if (
        typeof onIsGoalie === 'function' &&
        query.is_goalie &&
        query.is_goalie === '1'
      ) {
        onIsGoalie(true)
      }

      setLoaded(true)
    }

    if (team_id) {
      getTeamFilters()
    } else if (schedule_id) {
      getScheduleFilters()
    }
  }, [team_id, schedule_id, currentQueryString])

  return {
    teams,
    team,
    setTeam,
    filters,
    dateRange,
    setDateRange,
    state,
    setState,
    sort,
    setSort,
    loaded,
    debouncedSearchTerm,
    searchTerm,
    setSearchTerm,
    currentParams,
  }
}

export default useGetScheduleFiltering
