/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { connect } from 'react-redux'
import { getSelfUser } from '@sportninja/common/selectors/users'
import { useEffect, useMemo, useRef, useState } from 'react'
import { t } from '@sportninja/common/i18n'
import { Flex } from '../../components/Layout'
import { media, Mobile } from '../../components/Responsive'
import HomeSidebar from './HomeSidebar'
import UserSidebar from './UserSidebar'
import Helmet from '../../components/Helmet'
import { HomePaymentBanner } from '../RegistrationPayment/HomePaymentBanner'
import { HomeBannerParticipantsLimit } from '../../components/HomeBannerParticipantsLimit'
import PostInput from 'src/components/PostInput'
import FeedPage from 'src/components/FeedPage'
import FeedPostCreateModal from 'src/components/FeedPostCreateModal'

const Home = ({
  user = {},
  entity = {},
  isEntityFeed,
  entityType,
  entityId,
  refreshKey,
  hideSidebar = false,
  isInFrame,
  isLoggedIn,
  canPost = false,
}) => {
  const [permission, setPermission] = useState({})
  const [feedOrgs, setFeedOrgs] = useState([])
  const [feedTeams, setFeedTeams] = useState([])
  const [feedScheds, setFeedScheds] = useState([])
  const refreshNewFeed = useRef(() => {})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [postToEdit, setPostToEdit] = useState(null)
  const shouldDisplayPostCreate = useMemo(() => {
    if (isEntityFeed) {
      return (
        permission?.[entityId]?.update ||
        permission?.[entityId]?.post ||
        canPost
      )
    }
    return (
      feedOrgs?.length > 0 || feedTeams?.length > 0 || feedScheds?.length > 0
    )
  }, [
    canPost,
    entityId,
    feedOrgs?.length,
    feedScheds?.length,
    feedTeams?.length,
    isEntityFeed,
    permission,
  ])
  const [isLoadingFeed, setIsLoadingFeed] = useState(false)

  useEffect(() => {
    const feedPage = document.getElementById('feed-page')
    if (isModalOpen) {
      feedPage.style.display = 'none'
    } else {
      feedPage.style.display = 'block'
    }
  }, [isModalOpen])

  useEffect(() => {
    localStorage.removeItem('isLightMode')
  }, [])

  return (
    <Flex
      justifyContent='center'
      css={css`
        width: 100%;
        padding-top: 32px;

        ${media.mobile} {
          padding-top: 16px;
        }
      `}
    >
      {!isEntityFeed && (
        <Helmet
          title={t('util:capitalize', {
            text: t('common:home'),
          })}
        />
      )}
      <Mobile>
        {(isMobile) => {
          const width = isMobile ? 'calc(100% - 32px)' : 'inherit'
          return (
            <>
              <FeedPostCreateModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                orgs={feedOrgs}
                teams={feedTeams}
                scheds={feedScheds}
                entityType={entityType}
                entityId={entityId}
                refreshNewFeed={refreshNewFeed.current}
                postToEdit={postToEdit}
                setPostToEdit={setPostToEdit}
              />
              {!hideSidebar && !isMobile ? (
                isEntityFeed ? null : (
                  <HomeSidebar
                    user={user}
                    isLoggedIn={isLoggedIn}
                    setFeedOrgs={setFeedOrgs}
                    setFeedTeams={setFeedTeams}
                    setFeedScheds={setFeedScheds}
                  />
                )
              ) : (
                false
              )}
              <div
                css={css`
                  margin: 0 16px 64px;
                  width: ${width};
                  max-width: 650px;

                  ${media.mobile} {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                    width: calc(100% - 32px);
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                  `}
                >
                  {!isEntityFeed ? <HomeBannerParticipantsLimit /> : null}
                  {shouldDisplayPostCreate && !isLoadingFeed ? (
                    <PostInput
                      onInputClick={() => setIsModalOpen(true)}
                      avatarUrl={user?.imageId}
                    />
                  ) : null}
                  <HomePaymentBanner isInFrame={isInFrame} />
                  <FeedPage
                    entityId={entityId}
                    entityType={entityType}
                    canEdit={permission?.[entityId]?.update}
                    canDelete={permission?.[entityId]?.update}
                    avatarUrl={user?.imageId}
                    refreshNewFeed={refreshNewFeed}
                    setPermission={setPermission}
                    user={user}
                    isEntityFeed={isEntityFeed}
                    setPostToEdit={setPostToEdit}
                    setModalOpen={setIsModalOpen}
                    permission={permission}
                    setIsLoadingFeed={setIsLoadingFeed}
                  />
                </div>
              </div>
              {!isMobile && !isEntityFeed ? <UserSidebar /> : null}
            </>
          )
        }}
      </Mobile>
    </Flex>
  )
}

const mapStateToProps = (state) => {
  return {
    user: getSelfUser(state),
    isInFrame: state.auth.inFrame,
    isLoggedIn: state.auth.login.success,
  }
}

export default connect(mapStateToProps)(Home)
