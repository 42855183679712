import { useCallback, useState } from 'react'
import req from '../api/request'
import { SportSettings } from '../types'

/**
 * Interface for schedule settings data
 */

/**
 * Hook result interface for useScheduleSettings
 */
interface UseScheduleSettingsResult {
  /**
   * The schedule settings data
   */
  scheduleSettings: SportSettings | null
  /**
   * Loading state for the request
   */
  loading: boolean
  /**
   * Error state if request fails
   */
  error: Error | null
  /**
   * Function to manually fetch schedule settings
   */
  fetchScheduleSettings: (scheduleId: string | number) => Promise<void>
}

/**
 * Hook for fetching and managing schedule settings
 * @param initialScheduleId - Optional initial schedule ID to fetch settings for
 * @returns Object containing schedule settings data, loading state, error state, and fetch function
 */
export const useScheduleSettings = (): UseScheduleSettingsResult => {
  const [scheduleSettings, setScheduleSettings] =
    useState<SportSettings | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  /**
   * Fetches schedule settings for a given schedule ID
   * @param scheduleId - The ID of the schedule to fetch settings for
   */
  const fetchScheduleSettings = useCallback(
    async (scheduleId: string | number) => {
      if (!scheduleId) {
        return
      }
      setLoading(true)
      try {
        const { data } = await req(`/schedules/${scheduleId}/settings`)
        setScheduleSettings(data)
        setError(null)
      } catch (err: any) {
        setError(err)
        setScheduleSettings(null)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return { scheduleSettings, loading, error, fetchScheduleSettings }
}
