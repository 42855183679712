/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useMemo, useState } from 'react'
import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'
import parsers from '@sportninja/common/reducers/parsers'
import { connect } from 'react-redux'
import { getPlayerTypesBySportId } from '@sportninja/common/selectors/types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import colors from '@sportninja/common/constants/appColors'
import { ROUTES } from '@sportninja/common/constants/app'
import { Link, useLocation } from 'react-router-dom'

import { playerForm } from '../Form/form-configs'
import { InviteAction } from '../ActionPanel/ActionPanelActions'
import OptionButtonEdit from './OptionButtons/Edit'
import OptionButtonDelete from './OptionButtons/Delete'
import List from '.'
import PersonCell from './PersonCell'
import TeamRosterBasedList from './TeamRosterBasedList'
import MoreOptions from './OptionButtons/More'
import EditEntitySubTitle from '../../pages/EditEntitySubTitle'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { isCanlan } from '@sportninja/common/utils/customer-name'

dayjs.extend(utc)

export const playerSort = (sort, direction) => (a, b) => {
  if (sort === 'name_last') {
    if (a?.name_last < b?.name_last) {
      return direction === 'asc' ? -1 : 1
    } else if (a?.name_last > b?.name_last) {
      return direction === 'asc' ? 1 : -1
    }
    if (a?.name_first < b?.name_first) {
      return direction === 'asc' ? -1 : 1
    } else if (a?.name_first > b?.name_first) {
      return direction === 'asc' ? 1 : -1
    }

    return 0
  }

  if (sort === 'name_first') {
    if (a?.name_first < b?.name_first) {
      return direction === 'asc' ? -1 : 1
    } else if (a?.name_first > b?.name_first) {
      return direction === 'asc' ? 1 : -1
    }
    if (a?.name_last < b?.name_last) {
      return direction === 'asc' ? -1 : 1
    } else if (a?.name_last > b?.name_last) {
      return direction === 'asc' ? 1 : -1
    }

    return 0
  }

  if (sort === 'player_number') {
    if (a?.player_number < b?.player_number) {
      return direction === 'asc' ? -1 : 1
    } else if (a?.player_number > b?.player_number) {
      return direction === 'asc' ? 1 : -1
    }
  }

  if (sort === 'player_type.id') {
    if (a?.player_type?.id < b?.player_type?.id) {
      return direction === 'asc' ? -1 : 1
    } else if (a?.player_type?.id > b?.player_type?.id) {
      return direction === 'asc' ? 1 : -1
    }
  }

  return 0
}

const PlayerList = ({
  teamId,
  teamName,
  permission = {},
  playerTypes,
  refreshKey,
  onComplete,
  isInFrame,
  shouldShowWaiverStatus = false,
  isSoccer = false,
  sportName = 'hockey',
  sportId,
  lockRosterDate,
}) => {
  const [sort, setSort] = useState('name_first')
  const [direction, setDirection] = useState('asc')
  const location = useLocation()
  const existingRosterId = useMemo(
    () => location?.state?.rosterId,
    [location?.state]
  )

  const headers = [
    {
      text: t('common:name'),
      sizePercent: 34,
      sort: 'name_last',
      Cell: ({ item }) => {
        let to
        if (item?.id) {
          to = `${ROUTES.PLAYER_ROOT}/${item.id}`
        }
        return (
          <PersonCell
            className='player-list-player'
            item={item}
            subTitle={item?.customer_identifier}
            as={to ? Link : undefined}
            to={to}
          />
        )
      },
    },
    {
      text: '#',
      sizePercent: 6,
      sort: 'player_number',
      accessor: 'player_number',
      minWidth: 50,
    },
    {
      text: t('common:position'),
      sizePercent: 16,
      sort: 'player_type.id',
      minWidth: 90,
      accessor: (item) => {
        return (
          playerTypes?.find((t) => t.id === parseInt(item?.player_type?.id, 10))
            ?.name_full || '-'
        )
      },
    },
    {
      text: 'DOB',
      sizePercent: 17,
      minWidth: 100,
      accessor: (item) => {
        const dateAsString = item?.birth_date
          ? dayjs.utc(item.birth_date).format('YYYY-MM-DD')
          : ''
        if (dateAsString === 'Invalid Date') {
          return `${dateAsString} | ${item?.birth_date}`
        } else {
          return dateAsString
        }
      },
    },
    {
      text: 'Status',
      sizePercent: 17,
      minWidth: 120,
      accessor: (item) => {
        let content = false

        const activeSuspensions =
          Array.isArray(item?.suspensions) &&
          item.suspensions.filter((s) => s.is_active) &&
          item.suspensions.length > 0

        if (activeSuspensions) {
          // Sort suspensions by first added
          item.suspensions.sort((a, b) => {
            const aStartDate = dayjs.utc(a.start_date)
            const bStartDate = dayjs.utc(b.start_date)
            if (aStartDate.isBefore(bStartDate)) {
              return -1
            } else if (aStartDate.isAfter(bStartDate)) {
              return 1
            }

            return 0
          })

          const suspension = item.suspensions[0]

          const startDate = suspension?.start_date
            ? dayjs.utc(suspension.start_date).format('MMM D')
            : ''
          const endDate = suspension?.end_date
            ? dayjs.utc(suspension.end_date).format('MMM D')
            : ''

          content = (
            <div>
              <div
                css={css`
                  text-transform: uppercase;
                  color: ${colors.FIERY_RED};
                  font-size: 14px;
                `}
              >
                Suspended
              </div>
              <div
                css={css`
                  margin-top: 2px;
                  font-size: 13px;
                `}
              >
                {startDate} -{' '}
                {suspension?.enforcement?.id !== 3 && endDate !== ''
                  ? endDate
                  : 'Indefinitely'}
              </div>
            </div>
          )
        } else if (item?.is_injured) {
          content = 'Injured'
        }

        return content
      },
    },
    !isInFrame &&
      shouldShowWaiverStatus && {
        text: 'Signed Waiver',
        sizePercent: 12,
        minWidth: 80,
        accessor: (item) => {
          return item?.is_signed_waiver ? 'Yes' : 'No'
        },
      },
    !isInFrame && {
      text: 'Invite',
      sizePercent: 12,
      minWidth: 80,
      accessor: (item) => {
        let status = ''
        if (item?.is_invited) {
          status = 'Invited'
        }
        if (item?.is_accepted) {
          status = 'Accepted'
        }

        return status
      },
    },
  ].filter((o) => typeof o !== 'undefined' && typeof o !== 'boolean')

  const nonUpdatePermissonHeaders = [
    {
      text: t('common:name'),
      sizePercent: 34,
      sort: 'name_last',
      Cell: ({ item }) => {
        let to
        if (item?.id) {
          to = `${ROUTES.PLAYER_ROOT}/${item.id}`
        }
        return (
          <PersonCell
            className='player-list-player'
            item={item}
            subTitle={item?.customer_identifier}
            as={to ? Link : undefined}
            to={to}
          />
        )
      },
    },
    {
      text: '#',
      sizePercent: 6,
      sort: 'player_number',
      accessor: 'player_number',
      minWidth: 50,
    },
    {
      text: t('common:position'),
      sizePercent: 16,
      sort: 'player_type.id',
      minWidth: 90,
      accessor: (item) => {
        return (
          playerTypes?.find((t) => t.id === parseInt(item?.player_type?.id, 10))
            ?.name_full || '-'
        )
      },
    },
    {
      text: 'DOB',
      sizePercent: 17,
      minWidth: 100,
      accessor: (item) => {
        const dateAsString = item?.birth_date
          ? dayjs.utc(item.birth_date).format('YYYY-MM-DD')
          : ''
        if (dateAsString === 'Invalid Date') {
          return `${dateAsString} | ${item?.birth_date}`
        } else {
          return dateAsString
        }
      },
    },
    {
      text: 'Status',
      sizePercent: 17,
      minWidth: 120,
      accessor: (item) => {
        let content = false

        const activeSuspensions =
          Array.isArray(item?.suspensions) &&
          item.suspensions.filter((s) => s.is_active) &&
          item.suspensions.length > 0

        if (activeSuspensions) {
          // Sort suspensions by first added
          item.suspensions.sort((a, b) => {
            const aStartDate = dayjs.utc(a.start_date)
            const bStartDate = dayjs.utc(b.start_date)
            if (aStartDate.isBefore(bStartDate)) {
              return -1
            } else if (aStartDate.isAfter(bStartDate)) {
              return 1
            }

            return 0
          })

          const suspension = item.suspensions[0]

          const startDate = suspension?.start_date
            ? dayjs.utc(suspension.start_date).format('MMM D')
            : ''
          const endDate = suspension?.end_date
            ? dayjs.utc(suspension.end_date).format('MMM D')
            : ''

          content = (
            <div>
              <div
                css={css`
                  text-transform: uppercase;
                  color: ${colors.FIERY_RED};
                  font-size: 14px;
                `}
              >
                Suspended
              </div>
              <div
                css={css`
                  margin-top: 2px;
                  font-size: 13px;
                `}
              >
                {startDate} -{' '}
                {suspension?.enforcement?.id !== 3 && endDate !== ''
                  ? endDate
                  : 'Indefinitely'}
              </div>
            </div>
          )
        } else if (item?.is_injured) {
          content = 'Injured'
        }

        return content
      },
    },
  ].filter((o) => typeof o !== 'undefined' && typeof o !== 'boolean')

  return (
    <TeamRosterBasedList
      teamId={teamId}
      teamName={teamName}
      permission={permission}
      slug='players'
      refreshKey={refreshKey}
      onComplete={onComplete}
      existingRosterIdFromLocationState={existingRosterId}
      lockRosterDate={lockRosterDate}
    >
      {(roster, refresh) => {
        const options = (item) => {
          if (!item) {
            return []
          }

          const slug = `/teams/${teamId}/rosters/${roster.id}/players/${item.id}`

          const onEdit = async (values) => {
            const { imageData, ...form } = values

            // https://sportninja.atlassian.net/browse/SN-4313
            if (form?.phone_number) {
              if (
                form.phone_number === '+' ||
                form.phone_number === '' ||
                form.phone_number.length < 9
              ) {
                form.phone_number = null
              }
            }

            const body = JSON.stringify(form)

            const response = await req(slug, {
              method: 'PUT',
              body,
            })

            if (imageData) {
              await req(`/teams/${teamId}/players/${item.id}/image`, {
                method: 'POST',
                body: imageData,
              })
            }

            await refresh()
            return response
          }

          const opts = [
            permission.update && (
              <OptionButtonEdit
                key='edit'
                form={playerForm(
                  parsers.individualPlayer(item),
                  false,
                  false,
                  true,
                  sportName,
                  false,
                  0,
                  sportId
                )}
                onSubmit={onEdit}
                title={t('GameSetup:editPlayer')}
                subTitle={
                  <>
                    <EditEntitySubTitle
                      id={item?.id || ''}
                      entityType={ENTITY_TYPES.player}
                    />
                    {isCanlan && item?.sf_id ? (
                      <EditEntitySubTitle
                        id={item?.sf_id || ''}
                        entityType={ENTITY_TYPES.salesforce}
                      />
                    ) : null}
                  </>
                }
              />
            ),
            permission.update && (
              <InviteAction
                key='invite'
                invite={{
                  is_invited: item.is_invited,
                  is_accepted: item.is_accepted,
                  invitation_id: item.invitation_id,
                }}
                user={{
                  id: item.id,
                  name_first: item.name_first,
                  name_last: item.name_last,
                  player_type_id: item.player_type.id,
                  email: item?.email,
                }}
                slug={slug}
                onComplete={async () => await refresh()}
              />
            ),
            // Team officials should be able to remove players from roster
            <MoreOptions
              key='more'
              options={[
                permission.update && {
                  key: 'remove',
                  Component: OptionButtonDelete,
                  componentProps: {
                    buttonText: 'remove',
                    slug,
                    itemId: item.id,
                    iconName: 'user-minus',
                    title: `Remove ${item.name_first} ${item.name_last}`,
                    type: t('common:player'),
                    onComplete: refresh,
                  },
                },
              ]}
            />,
          ]

          return opts.filter(
            (o) => typeof o !== 'undefined' && typeof o !== 'boolean'
          )
        }

        const sorter = playerSort(sort, direction)

        const players = [],
          affiliates = []

        roster?.players?.forEach((p) => {
          const player = {
            ...p,
            isFromTeamRoster: true,
            isEmailRequired: roster?.require_email,
          }

          if (player.is_affiliate) {
            affiliates.push(player)
          } else {
            players.push(player)
          }
        })

        return (
          <>
            <List
              key='players'
              headers={
                permission?.update || permission?.admin
                  ? headers
                  : nonUpdatePermissonHeaders
              }
              options={permission?.update || permission?.admin ? options : null}
              listProps={{
                items: players.sort(sorter),
                sort,
                direction,
                setSort: (s) => {
                  setSort(s)
                },
                setDirection: (d) => {
                  setDirection(d)
                },
              }}
              noItemsText={t('common:player', { count: 2 })}
            />
            {affiliates.length > 0 && (
              <>
                <p
                  className='player-list-affiliates-title'
                  css={css`
                    margin-top: 24px;
                    font-weight: 700;
                    color: ${colors.DEFAULT_FLAIR};
                    text-transform: uppercase;
                  `}
                >
                  Affiliates
                </p>
                <List
                  key='affiliates'
                  hideHeaders
                  headers={headers}
                  options={options}
                  listProps={{
                    items: affiliates.sort(sorter),
                    sort,
                    direction,
                  }}
                  noItemsText={t('common:affiliate', { count: 2 })}
                />
              </>
            )}
          </>
        )
      }}
    </TeamRosterBasedList>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    playerTypes: getPlayerTypesBySportId(state, sportId),
    isInFrame: state.auth.inFrame,
  }
}

export default connect(mapStateToProps)(PlayerList)
