/** @jsxImportSource @emotion/react */
import { CalendarOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { DatePicker as AntdDatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { paragraphMedium, weightRegular } from 'src/components/css'
import Text from '../Text'
import { ErrorLabel } from '../ErrorLabel'

type RangePickerProps = {
  label: string
  onChange: (e: any) => void
  starts_at: Dayjs | null | undefined | string
  ends_at: Dayjs | null | undefined | string
  fieldErrors: string[]
  name: string
  customError: any
}

const getDate = (
  start_at: Dayjs | null | undefined | string,
  end_at: Dayjs | null | undefined | string
): [Dayjs | null | undefined, Dayjs | null | undefined] => {
  if (!start_at && !end_at) {
    return [undefined, undefined]
  }
  if (start_at && !end_at) {
    return [dayjs(start_at), undefined]
  }
  if (!start_at && end_at) {
    return [undefined, dayjs(end_at)]
  }
  return [dayjs(start_at), dayjs(end_at)]
}

const RangePicker = ({
  label,
  onChange,
  starts_at,
  ends_at,
  fieldErrors,
  name,
  customError,
}: RangePickerProps) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;
          align-self: stretch;
        `}
      >
        <Text variant='paragraphSmall' weight='regular'>
          {label}
        </Text>
        <AntdDatePicker.RangePicker
          popupStyle={{ position: 'fixed' }}
          status={
            fieldErrors[name] || fieldErrors?.includes(name) ? 'error' : ''
          }
          css={css`
            ${paragraphMedium}
            ${weightRegular}
            width: 100%;
            background: ${colors.SECONDARY_200};
            border: 1px solid ${colors.SECONDARY_100};
            border-radius: 8px 8px 8px 8px;
            height: 40px;
          `}
          format={{
            format: 'DD/MM/YYYY',
            type: 'mask',
          }}
          size='large'
          allowEmpty={[false, false]}
          allowClear={false}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode as HTMLElement
          }}
          onChange={(e) => {
            if (e) {
              //   const [starts_at, ends_at] = e
              onChange(e)
            }
          }}
          suffixIcon={
            <CalendarOutlined
              css={css`
                color: ${colors.WHITE};
                font-size: 24px;
              `}
            />
          }
          value={getDate(starts_at, ends_at)}
        />
        <ErrorLabel
          fieldErrors={fieldErrors || []}
          customError={customError || ''}
          name={name}
        />
      </div>
    </div>
  )
}

export default RangePicker
