/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React from 'react'
import PropTypes from 'prop-types'
import { t } from '@sportninja/common/i18n'

import MapCard from '../../components/MapCard'
import { Container, Column } from '../../components/Layout'
import Card from '../../components/Card'
import GameDetailsScoreBox from './GameScoreBox'
import GameDetailsStats from './GameDetailsStats'
import { Mobile } from '../../components/Responsive'
import GameShootout from './GameShootout'
import ReactPlayer from 'react-player'

const Details = ({ game, isSoccer = false, isGenericGame = false }) => {
  if (!game) {
    return false
  }
  const { homeTeam, visitingTeam, referees, scorekeepers, location, facility } =
    game
  const teams = [homeTeam, visitingTeam]
  const officials = game?.officials || [...referees, ...scorekeepers]
  const resourceUri = game?.broadcast_video_url
  const hasMoreThanOnePeriod = game?.periods?.length > 1
  // Looks weird, but avoid me to make a big mistake on the quick win mode
  let shouldDisplayPeriodStuffs = true
  if (isGenericGame) {
    if (!hasMoreThanOnePeriod) {
      shouldDisplayPeriodStuffs = false
    } else {
      shouldDisplayPeriodStuffs = true
    }
  } else {
    shouldDisplayPeriodStuffs = true
  }

  return (
    <>
      <Mobile>
        {(isMobile) => {
          return isMobile ? (
            <>
              {shouldDisplayPeriodStuffs ? (
                <GameDetailsScoreBox
                  game={game}
                  isMobile
                  isSoccer={isSoccer}
                  isGenericGame={isGenericGame}
                />
              ) : null}

              <GameShootout game={game} isMobile />
              <GameDetailsStats
                game={game}
                isMobile
                isSoccer={isSoccer}
                isGenericGame={isGenericGame}
              />
            </>
          ) : (
            <Container>
              <Column fullWidth>
                {shouldDisplayPeriodStuffs ? (
                  <GameDetailsScoreBox
                    game={game}
                    isSoccer={isSoccer}
                    isGenericGame={isGenericGame}
                  />
                ) : null}
                <GameShootout game={game} />
                <GameDetailsStats
                  game={game}
                  isSoccer={isSoccer}
                  isGenericGame={isGenericGame}
                />

                {/* {resourceUri ? (
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-left: 24px;
                    `}
                  >
                    <ReactPlayer
                      url={resourceUri}
                      pip={false}
                      playing={false}
                      controls={true}
                      light={false}
                      volume={0.8}
                      muted={false}
                      played={0}
                      loaded={0}
                      duration={0}
                      playbackRate={1.0}
                      loop={false}
                      onError={(e) => {
                        console.log('error', e)
                        // setError('An error occurred')
                      }}
                      width={320}
                      height={180}
                    />
                  </div>
                ) : null} */}
              </Column>
            </Container>
          )
        }}
      </Mobile>
      <Container as='main'>
        <MapCard
          venue={location}
          title={t('common:location')}
          facility={facility}
        />

        {teams.map((team, idx) => {
          const sectionTitle = idx === 0 && t('common:team', { count: 2 })
          return (
            <Card
              key={team.fullName}
              iconName='user-friends'
              imageId={team.imageId}
              isEntity
              sectionTitle={sectionTitle}
              subTitle={team.parentName}
              title={team.fullName}
              to={
                team.isPlaceholder
                  ? undefined
                  : `${team.to}?${game.parentScheduleType}=${game.parentId}`
              }
            />
          )
        })}

        {officials.map((user = {}, idx) => {
          const sectionTitle =
            idx === 0 && t('common:gameOfficial', { count: 2 })

          const firstInitial = user?.nameFirst ? user.nameFirst?.charAt(0) : ''
          const lastInitial = user?.nameLast ? user.nameLast?.charAt(0) : ''

          return (
            <Card
              key={`${user.fullName}-${user.description}-${idx}`}
              imageId={user.imageId}
              sectionTitle={sectionTitle}
              subTitle={user.description}
              initials={`${firstInitial}${lastInitial}`}
              // TODO: Add back when we will allow users to navigate to user profiles
              // to={user.to}
              title={user.fullName ? user.fullName : 'No Name'}
            />
          )
        })}
      </Container>
    </>
  )
}

Details.propTypes = {
  game: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
}

export default Details
