/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { Link } from 'react-router-dom'

import { font } from '../../css'
import { Flex } from '../../Layout'
import Picture from '../../Picture'
import {
  getCurrentPeriod,
  getDateAndGameTime,
  getGameStatusLabel,
} from './GameListRowTime'

const Team = ({ team, isHome, teamSlot = {}, scheduleId = null }) => {
  const to = team?.id
    ? `${ROUTES.TEAM_ROOT}/${team.id}${
        scheduleId ? `?sn_schedule=${scheduleId}` : ''
      }`
    : undefined

  return (
    <Flex
      as={Link}
      to={to}
      noFlex
      css={css`
        ${isHome && 'margin-bottom: 20px;'}
      `}
    >
      <Picture
        square
        fit
        imageId={getImageThumbnailId(team)}
        size='kxsmall'
        iconName='user-friends'
      />
      <Flex
        column
        css={css`
          margin-left: 16px;
        `}
      >
        <div
          css={css`
            ${font.title}
            font-weight: 700;
            font-size: 24px;
            line-height: 100%;
            text-transform: uppercase;
            padding-right: 8px;
          `}
        >
          {team
            ? team?.name_full
            : teamSlot
            ? teamSlot?.name_full
            : isHome
            ? 'Home Team'
            : 'Visiting Team'}
        </div>
        <div
          className='mobile-game-list-team-home-away'
          css={css`
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            color: ${colors.LINK_WATER};
            margin-top: 2px;
          `}
        >
          {isHome ? 'Home' : 'Visitor'}
        </div>
      </Flex>
    </Flex>
  )
}

const Score = ({ children, isWinner }) => {
  return (
    <Flex
      noFlex
      column
      alignItems='center'
      css={css`
        flex-basis: 10%;
        ${font.title}
        font-weight: 700;
        font-size: 40px;
        text-align: center;
      `}
    >
      <span>
        {children}
        {isWinner && (
          <div
            className='score-indicator'
            css={css`
              height: 4px;
              background-color: ${colors.DEFAULT_FLAIR};
            `}
          />
        )}
      </span>
    </Flex>
  )
}

const MobileGameListRow = ({
  game,
  gameTypes,
  gameStatusTypes,
  hasNextSibling,
  scheduleId = null,
}) => {
  const { localDate, date, displayGameTime } = getDateAndGameTime(game)
  const currentPeriod = getCurrentPeriod(game)

  const fullGameType = gameTypes?.find((gameType) => {
    return parseInt(gameType?.id, 10) === game?.game_type_id
  })

  const homeTeamWon =
    game.home_team_score > game.visiting_team_score || game.game_status_id === 3
  const visitingTeamWon =
    game.home_team_score < game.visiting_team_score || game.game_status_id === 2

  return (
    <div
      css={css`
        overflow: hidden;
        border-radius: 8px;
        ${hasNextSibling && 'margin-bottom: 16px;'}
      `}
    >
      <Flex
        as={Link}
        to={`${ROUTES.GAME_ROOT}/${game.id}`}
        className='mobile-game-header'
        justifyContent='space-between'
        css={css`
          height: 60px;
          padding: 14px 16px;
          background-color: #303f56;
        `}
      >
        <Flex column justifyContent='space-between'>
          {game.ended_at ? (
            <div
              className='mobile-game-row-final'
              css={css`
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
                color: ${colors.DEFAULT_FLAIR};
                text-transform: uppercase;
              `}
            >
              {game.game_status_id > 1
                ? ` - ${getGameStatusLabel(
                    game.game_status_id,
                    gameStatusTypes
                  )}`
                : game.shootout
                ? 'Shootout'
                : currentPeriod.isOvertime
                ? 'Overtime'
                : t('util:capitalize', { text: t('common:final') })}
            </div>
          ) : game.started_at ? (
            <div
              className='mobile-game-row-in-progress'
              css={css`
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
              `}
            >
              {currentPeriod.clockTime
                ? currentPeriod.fullName
                : currentPeriod.name}{' '}
              {currentPeriod.clockTime
                ? ` - ${currentPeriod.clockTime}`
                : t('util:capitalize', {
                    text: t('GameScreen:intermission'),
                  })}
            </div>
          ) : (
            <div
              className='mobile-game-row-time'
              css={css`
                font-weight: 500;
                font-size: 14px;
              `}
            >
              {localDate.format('h:mm a (z)')}
              {displayGameTime &&
                date.format(
                  localDate.day() !== date.day()
                    ? '- MMM D - h:mm a z'
                    : '- h:mm a z'
                )}
            </div>
          )}
          <div
            className='mobile-game-number'
            css={css`
              font-weight: 500;
              font-size: 12px;
              line-height: 12px;
            `}
          >
            Game #{game.league_game_number} - {fullGameType?.name}
          </div>
        </Flex>
        <Flex
          noFlex
          column
          justifyContent='center'
          css={css`
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            text-align: right;
          `}
        >
          <div>{game.venue.name}</div>
          {game.facility && (
            <div
              css={css`
                margin-top: 4px;
              `}
            >
              {game.facility.name}
            </div>
          )}
        </Flex>
      </Flex>
      <Flex
        column
        className='mobile-game-body'
        justifyContent='space-between'
        css={css`
          background-color: ${colors.HEADER};
          padding: 14px 16px;
        `}
      >
        <Flex row justifyContent='space-between'>
          <Team
            team={game.homeTeam}
            teamSlot={game.homeTeamSlot}
            isHome
            scheduleId={scheduleId}
          />
          <Score isWinner={homeTeamWon}>{game.home_team_score}</Score>
        </Flex>
        <Flex row justifyContent='space-between'>
          <Team
            team={game.visitingTeam}
            teamSlot={game.visitingTeamSlot}
            scheduleId={scheduleId}
          />
          <Score isWinner={visitingTeamWon}>{game.visiting_team_score}</Score>
        </Flex>
      </Flex>
    </div>
  )
}

export default MobileGameListRow
