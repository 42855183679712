/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React, { useState } from 'react'

interface PhoneInputProps {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  error?: string
  label: string
}

const containerStyles = css`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 8px;
`

const labelStyles = css`
  color: var(--Neutrals-neutrals-0, #fff);
  font-feature-settings: 'cv03' on, 'cv04' on;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
`

const inputStyles = css`
  display: flex;
  padding: 8px 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Foundation-Secondary-secondary-100, #4e5561);
  background: var(--Foundation-Secondary-secondary-200, #323a48);
  height: 40px;
  transition: border-color 0.3s, background-color 0.3s;
`

const focusedInputStyles = css`
  border-color: #f2b400;
  background-color: #27303e;
`

const textStyles = css`
  color: var(--Neutrals-neutrals-0, #fff);
  font-feature-settings: 'cv01' on, 'cv03' on, 'cv04' on;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  height: 36px !important;

  &::placeholder {
    color: #c1c2c3;
  }
`

const errorStyles = css`
  color: #e55c5c;
  font-size: 12px;
  margin-top: 4px;
`

const disabledStyles = css`
  opacity: 0.5;
  pointer-events: none;
`

const PhoneInput: React.FC<PhoneInputProps> = ({
  value,
  onChange,
  disabled = false,
  error,
  label,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const formatPhoneNumber = (input: string) => {
    // Remove all non-digit characters
    const digitsOnly = input.replace(/\D/g, '')

    // Format the number with correct grouping
    let formatted = ''
    if (digitsOnly.length > 0) {
      formatted = `+${digitsOnly.slice(0, 1)}`
      if (digitsOnly.length > 1) {
        formatted += ` ${digitsOnly.slice(1, 4)}`
      }
      if (digitsOnly.length > 4) {
        formatted += ` ${digitsOnly.slice(4, 7)}`
      }
      if (digitsOnly.length > 7) {
        formatted += ` ${digitsOnly.slice(7)}`
      }
    }
    return formatted
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value)
    onChange(formatted)
  }

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  return (
    <div css={containerStyles}>
      <label css={labelStyles}>{label}</label>
      <div
        css={[
          inputStyles,
          isFocused && focusedInputStyles,
          disabled && disabledStyles,
          error &&
            css`
              border-color: #e55c5c;
            `,
        ]}
      >
        <input
          css={textStyles}
          type='tel'
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          placeholder='+1 234 567 8901'
          autoComplete='tel'
        />
      </div>
      {error && <div css={errorStyles}>{error}</div>}
    </div>
  )
}

export default PhoneInput
