/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import Text from 'src/components/ts-components/Text'
import { useOrganizationModalContext } from '../../context'
import { TextInput } from 'src/components/ts-components/TextInput'
import colors from '@sportninja/common/constants/appColors'
import { CheckBoxInput } from 'src/components/ts-components/CheckBoxInput'
import Button from 'src/components/ts-components/Button'

const Integrations = () => {
  const {
    state,
    setState,
    onSubmitDaysmartCredentials,
    onClearCredentials,
    onPressTest,
  } = useOrganizationModalContext()

  return (
    <div
      css={css`
        display: flex;
        padding: 16px;
        gap: 16px;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text variant='captionLarge' weight='semiBold'>
          Integrations
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text variant='paragraphSmall' weight='regular'>
          Please Enter Your Credentials Before Proceeding:
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          align-self: stretch;
          flex-direction: row;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <TextInput
            label='Daysmart API Key *'
            value={state.daysmart_api_key}
            setValue={(value) => {
              setState({ ...state, daysmart_api_key: value })
            }}
            onBlur={() => {
              if (state.name.length < 3) {
                setState({
                  ...state,
                  fieldErrors: [...state.fieldErrors, 'name'],
                  customError: {
                    ...state.customError,
                    name: 'Name must be at least 3 characters',
                  },
                })
                return
              }
              if (state.name.length > 50) {
                setState({
                  ...state,
                  fieldErrors: [...state.fieldErrors, 'name'],
                  customError: {
                    ...state.customError,
                    name: 'Name must be less than 50 characters',
                  },
                })
                return
              }
              if (!state.name.match(/^[a-zA-Z0-9\s]+$/)) {
                setState({
                  ...state,
                  fieldErrors: [...state.fieldErrors, 'name'],
                  customError: {
                    ...state.customError,
                    name: 'Name must be alphanumeric',
                  },
                })
                return
              }
              if (state.name.length === 0) {
                setState({
                  ...state,
                  fieldErrors: state.fieldErrors.filter(
                    (field) => field !== 'name'
                  ),
                  customError: {
                    ...state.customError,
                    name: '',
                  },
                })
                return
              }
              setState({
                ...state,
                fieldErrors: state.fieldErrors.filter(
                  (field) => field !== 'name'
                ),
                customError: {
                  ...state.customError,
                  name: '',
                },
              })
            }}
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            disabled={false}
            name='daysmart_api_key'
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          align-self: stretch;
          flex-direction: row;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <TextInput
            label='Daysmart API Secret *'
            value={state.daysmart_api_secret}
            setValue={(value) => {
              setState({ ...state, daysmart_api_secret: value })
            }}
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            disabled={false}
            name='daysmart_api_secret'
            type='password'
          />
        </div>
      </div>
      <a
        css={css`
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          align-self: stretch;
        `}
        href='https://support.sportninja.com/daysmart-integration-creating-an-api-key'
        target='_blank'
        rel='noreferrer'
      >
        <Text variant='paragraphSmall' weight='regular' color={colors.PRIMARY}>
          Where To Find This In Daysmart?
        </Text>
      </a>
      <div
        css={css`
          border-bottom: 1px solid ${colors.SECONDARY_200};
          width: 100%;
        `}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            gap: 8px;
            width: 100%;
          `}
        >
          <CheckBoxInput
            label={
              <>
                By Proceeding With This Process, You Agree To The{' '}
                <a
                  href='https://www.sportninja.com/integration-terms'
                  target='_blank'
                  rel='noreferrer'
                  css={css`
                    color: ${colors.PRIMARY};
                  `}
                >
                  Terms and Conditions
                </a>{' '}
                Of The Daysmart Integration.
              </>
            }
            hint=''
            name='accept_terms'
            value={state.accepted_terms}
            onChange={() => {
              setState({ ...state, accepted_terms: !state.accepted_terms })
            }}
            fieldErrors={state.fieldErrors}
            customError={state.customError}
            disabled={false}
            checkBoxStyle={css`
              width: 24px;
              height: 24px;
              align-self: center;
            `}
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          gap: 16px;
          width: 100%;
          margin-left: auto;
        `}
      >
        {state.existing_daysmart_connection ? (
          <Button
            text='Clear Cred.'
            onClick={onClearCredentials}
            variant='danger'
            disabled={state.daysmart_test_loading || state.daysmart_loading}
            isLoading={state.daysmart_test_loading || state.daysmart_loading}
          />
        ) : null}
        <Button
          text='Test'
          onClick={onPressTest}
          variant='secondary'
          disabled={
            state.daysmart_test_loading ||
            state.daysmart_loading ||
            !state.accepted_terms
          }
          isLoading={state.daysmart_test_loading || state.daysmart_loading}
        />
        <Button
          text='Connect'
          onClick={onSubmitDaysmartCredentials}
          variant='primary'
          disabled={
            state.daysmart_test_loading ||
            state.daysmart_loading ||
            !state.daysmart_test_success
          }
          isLoading={state.daysmart_test_loading || state.daysmart_loading}
        />
      </div>
    </div>
  )
}

export default Integrations
