/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { convertToImperial } from '@sportninja/common/reducers/helpers'
import { getBirthDateString } from '@sportninja/common/reducers/helpers'
import colors from '@sportninja/common/constants/appColors'

import { Flex } from '../Layout'
import { media } from '../Responsive'

import Text from '../ts-components/Text'

const Bio = ({ birthDate, height, weight }) => {
  const [ft, inches] = convertToImperial(height)
  const age = dayjs().diff(birthDate, 'year')

  const strings = [
    birthDate && `${getBirthDateString(birthDate)} (${age} yo)`,
    weight && `${weight} lbs`,
    !isNaN(ft) &&
      !isNaN(inches) &&
      ft !== '' &&
      inches !== '' &&
      `${ft}' ${inches}"`,
  ]

  return (
    <Flex
      noFlex
      css={css`
        font-weight: 500;
        font-size: 16px;
        color: ${colors.ATTENDANCE_GRAY};

        ${media.mobile} {
          flex-wrap: wrap;
        }
      `}
    >
      {strings
        .filter((s) => typeof s === 'string')
        .map((string, idx) => {
          return (
            <Text
              key={idx}
              variant='paragraphMedium'
              weight='regular'
              color={colors.WHITE}
            >
              {idx !== 0 && (
                <span
                  css={css`
                    padding: 0 10px;
                  `}
                >
                  |
                </span>
              )}
              {string}
            </Text>
          )
        })}
    </Flex>
  )
}

Bio.propTypes = {
  birthDate: PropTypes.string,
  height: PropTypes.any,
  weight: PropTypes.any,
}

export default Bio
