/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Flex } from '../../../components/Layout'
import { border } from '../../../components/css'
import { media } from '../../../components/Responsive'
import { Select } from 'antd'
import { TOURNAMENT_STATUS, TOURNAMENT_STATUS_TO_STRING } from './constants'
import colors from '@sportninja/common/constants/appColors'

const BracketFilter = ({
  comps = [],
  setSelectedComp,
  selectedComp,
  competitionType,
  children,
}) => {
  if (!competitionType) {
    console.log('[error] no competitionType provided')
    return false
  }

  const getSubTitle = (option) => {
    let subTitle = `${option.count} Teams - ${
      TOURNAMENT_STATUS_TO_STRING[option.tournament_status]
    }`
    return subTitle
  }

  /**
   * Returns the tournament type label based on the competition's tournament type
   *
   * @param {Object} comp - The competition object
   * @param {boolean} isSingleElimination - Whether single elimination tournament exists
   * @param {boolean} isDoubleElimination - Whether double elimination tournament exists
   * @returns {string|null} - The tournament type label or null if conditions aren't met
   */
  const getTournamentTypeLabel = (comp) => {
    if (!comp?.tournament_type_id) {
      return ''
    }

    const isSingleElimination = comp?.tournament_type_id === 1
    const isDoubleElimination = comp?.tournament_type_id === 2

    // Check if it's single or double elimination and properly configured
    if (isSingleElimination || isDoubleElimination) {
      // Return the appropriate label based on tournament type
      if (comp.tournament_type_id === 1) {
        return '(Single Elimination)'
      } else if (comp.tournament_type_id === 2) {
        return '(Double Elimination)'
      }
    }

    // Return empty string if conditions aren't met
    return ''
  }

  return (
    <Flex
      className='schedule-filters'
      alignItems='flex-end'
      css={css`
        padding-bottom: 20px;
        margin: 20px 0;
        border-bottom: ${border};
        width: 100%;
        flex: 1;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
          ${media.mobile} {
            flex-direction: column;
          }
        `}
      >
        <p
          css={css`
            height: 12px;
            font-size: 12px;
            line-height: 12px;
            margin-bottom: -8px;
            text-transform: capitalize;
          `}
        >
          {competitionType}
        </p>
        <Select
          value={selectedComp}
          onChange={(comp) => {
            setSelectedComp(comps.find((c) => c?.id === comp))
          }}
          style={{ minWidth: 300, height: 46 }}
        >
          {comps.map((comp) => {
            const subTitleAlternativeStyleForGameSlotsDropDown =
              comp?.tournament_status &&
              comp?.tournament_status !== TOURNAMENT_STATUS.NOT_CONFIGURED
                ? css`
                    text-decoration: underline;
                    font-weight: 700;
                  `
                : css``

            return (
              <Select.Option key={comp.id} value={comp.id}>
                <p
                  css={css`
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin-block-start: 0;
                    margin-block-end: 0;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                    padding: 0;
                    margin: 0;
                    line-height: 16px;
                    margin-bottom: 2px;
                  `}
                >
                  {comp.name_full || comp.name}{' '}
                  <span
                    css={css`
                      font-size: 14px;
                      line-height: 16px;
                      margin-bottom: 2px;
                      color: ${colors.NEUTRAL_50};
                    `}
                  >
                    {getTournamentTypeLabel(comp)}
                  </span>
                </p>
                <p
                  css={[
                    css`
                      opacity: 0.6;
                      margin-top: 2px;
                      font-size: 12px;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      margin-block-start: 0;
                      margin-block-end: 0;
                      margin-inline-start: 0px;
                      margin-inline-end: 0px;
                      padding: 0;
                      margin: 0;
                      line-height: 16px;
                    `,
                    subTitleAlternativeStyleForGameSlotsDropDown,
                  ]}
                >
                  {getSubTitle(comp)}
                </p>
              </Select.Option>
            )
          })}
        </Select>
        {/* <NewSelect
          label={competitionType}
          options={comps}
          selectedOptionId={selectedComp}
          onClick={(comp) => {
            setSelectedComp(comp)
          }}
          getTitle={(c) => c?.name_full || c?.name}
          getSubTitle={getSubTitle}
        /> */}
      </div>
      {children}
    </Flex>
  )
}

export default BracketFilter
