import { createSelector } from 'reselect'

export const getCountries = (state) => state.types.countries
export const getSports = (state) => state.types.sports

export const getSportById = (state, id) => {
  const sports = getSports(state)
  if (!sports || !id) {
    return false
  }
  return sports[id] || false
}

export const getSportByName = (state, name) => {
  const sports = getSports(state)
  if (!sports || !name) {
    return false
  }

  for (const sportId in sports) {
    if (sports[sportId].name === name) {
      return sports[sportId]
    }
  }

  return false
}

export const makeGetSportById = () => {
  return createSelector(getSportById, (sport) => sport)
}

export const getPlayerTypesBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.players) {
    return []
  }
  return sport.players
}

export const getRoleTypes = (state) => state.types.roles

// Hockey
export const getHockeySport = (state) => getSportByName(state, 'hockey')

export const getSoccerSport = (state) => getSportByName(state, 'soccer')

export const getGenericSport = (state) => getSportByName(state, 'generic')

export const getPlayerTypesForHockey = (state) => {
  const sports = state.types && state.types.sports
  if (!sports) {
    return []
  }

  const sportList = Object.keys(sports)
  const sportId = sportList.find((id) => sports[id].name === 'hockey')

  if (!sportId) {
    return []
  }
  return sports[sportId].players
}

export const getPlayerTypesForSoccer = (state) => {
  const sports = state.types && state.types.sports
  if (!sports) {
    return []
  }

  const sportList = Object.keys(sports)
  const sportId = sportList.find((id) => sports[id].name === 'soccer')

  if (!sportId) {
    return []
  }
  return sports[sportId].players
}

export const getGoalieTypeIdForSport = (state, sportId) => {
  const playerTypes = getPlayerTypesBySportId(state, sportId)
  const goalieType = playerTypes.find(
    (playerType) => playerType.name === 'GOAL'
  )
  return goalieType && goalieType.id
}

export const getGoalieTypeIdForHockey = createSelector(
  getPlayerTypesForHockey,
  (hockey) => {
    const goalieType =
      hockey && hockey.find((playerType) => playerType.name === 'GOAL')
    return goalieType && goalieType.id
  }
)

export const getGoalieTypeIdForSoccer = createSelector(
  getPlayerTypesForSoccer,
  (hockey) => {
    const goalieType =
      hockey && hockey.find((playerType) => playerType.name === 'GOAL')
    return goalieType && goalieType.id
  }
)

export const getPeriodTypesForHockey = createSelector(
  getHockeySport,
  (hockey) => {
    if (!hockey || !hockey.periods) {
      return []
    }
    return hockey.periods
  }
)

export const getPeriodTypesForSoccer = createSelector(
  getSoccerSport,
  (hockey) => {
    if (!hockey || !hockey.periods) {
      return []
    }
    return hockey.periods
  }
)

export const getAssistTypesForHockey = createSelector(
  getHockeySport,
  (hockey) => {
    if (!hockey || !hockey.assists) {
      return []
    }
    return hockey.assists
  }
)

export const getAssistTypesForSoccer = createSelector(
  getSoccerSport,
  (hockey) => {
    if (!hockey || !hockey.assists) {
      return []
    }
    return hockey.assists
  }
)

export const getOffenseTypesForHockey = createSelector(
  getHockeySport,
  (hockey) => {
    if (!hockey || !hockey.offenses) {
      return []
    }
    return hockey.offenses
  }
)

export const getOffenseSeverityTypesForHockey = createSelector(
  getHockeySport,
  (hockey) => {
    if (!hockey || !hockey.offenseSeverityTypes) {
      return []
    }
    return hockey.offenseSeverityTypes
  }
)

export const getOffenseTypesForSoccer = createSelector(
  getSoccerSport,
  (hockey) => {
    if (!hockey || !hockey.offenses) {
      return []
    }
    return hockey.offenses
  }
)

export const getOffenseSeverityTypesForSoccer = createSelector(
  getSoccerSport,
  (soccer) => {
    if (!soccer || !soccer.offenseSeverityTypes) {
      return []
    }
    return soccer.offenseSeverityTypes
  }
)
export const getOfficialTypesForHockey = createSelector(
  getHockeySport,
  (hockey) => {
    if (!hockey || !hockey.officials) {
      return []
    }
    return hockey.officials
  }
)

export const getOfficialTypesForSoccer = createSelector(
  getSoccerSport,
  (hockey) => {
    if (!hockey || !hockey.officials) {
      return []
    }
    return hockey.officials
  }
)

export const getOfficialTypesForGeneric = createSelector(
  getGenericSport,
  (generic) => {
    if (!generic || !generic.officials) {
      return []
    }
    return generic.officials
  }
)

export const getStatTypesForHockey = createSelector(
  getHockeySport,
  (hockey) => {
    if (!hockey || !hockey.statTypes) {
      return []
    }
    return hockey.statTypes
  }
)

export const getTeamOfficialTypesForHockey = createSelector(
  getHockeySport,
  (hockey) => {
    if (!hockey || !hockey.teamOfficials) {
      return []
    }
    return hockey.teamOfficials
  }
)

export const getStatTypesForSoccer = createSelector(
  getSoccerSport,
  (soccer) => {
    if (!soccer || !soccer.statTypes) {
      return []
    }
    return soccer.statTypes
  }
)

export const getTeamOfficialTypesForSoccer = createSelector(
  getSoccerSport,
  (hockey) => {
    if (!hockey || !hockey.teamOfficials) {
      return []
    }
    return hockey.teamOfficials
  }
)

export const getGameTypesForHockey = createSelector(
  getHockeySport,
  (hockey) => {
    if (!hockey || !hockey.gameTypes) {
      return []
    }
    return hockey.gameTypes
  }
)

export const getGameTypesForSoccer = createSelector(
  getSoccerSport,
  (hockey) => {
    if (!hockey || !hockey.gameTypes) {
      return []
    }
    return hockey.gameTypes
  }
)

export const getGameStatusTypesForHockey = createSelector(
  getHockeySport,
  (hockey) => {
    if (!hockey || !hockey.gameStatus) {
      return []
    }
    return hockey.gameStatus
  }
)

export const getGameStatusTypesForSoccer = createSelector(
  getSoccerSport,
  (hockey) => {
    if (!hockey || !hockey.gameStatus) {
      return []
    }
    return hockey.gameStatus
  }
)

export const getGoalTypesForHockey = createSelector(
  getHockeySport,
  (hockey) => {
    if (!hockey || !hockey.goalTypes) {
      return []
    }
    return hockey.goalTypes.map((type) => ({
      ...type,
      id: parseInt(type?.id, 10),
    }))
  }
)

export const getGoalTypesForSoccer = createSelector(
  getSoccerSport,
  (hockey) => {
    if (!hockey || !hockey.goalTypes) {
      return []
    }
    return hockey.goalTypes.map((type) => ({
      ...type,
      id: parseInt(type?.id, 10),
    }))
  }
)

export const getCardsForHockey = createSelector(getHockeySport, (hockey) => {
  if (!hockey || !hockey.cardTypes) {
    return []
  }
  return hockey.cardTypes
})

export const getCardsForSoccer = createSelector(getSoccerSport, (hockey) => {
  if (!hockey || !hockey.cardTypes) {
    return []
  }
  return hockey.cardTypes
})

export const getPeriodTypesBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.periods) {
    return []
  }
  return sport.periods
}

export const getAssistTypesBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.assists) {
    return []
  }
  return sport.assists
}

export const getOffenseTypesBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.offenses) {
    return []
  }
  return sport.offenses
}

export const getOffenseSeverityTypesBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.offenseSeverityTypes) {
    return []
  }
  return sport.offenseSeverityTypes
}

/**
 * @deprecated This function has been deprecated. Always query for all official types.
 */
export const getOfficialTypesBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.officials) {
    return []
  }
  return sport.officials
}

export const getStatTypesBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.statTypes) {
    return []
  }
  return sport.statTypes
}

export const getTeamOfficialTypesBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.teamOfficials) {
    return []
  }
  return sport.teamOfficials
}

export const getGameTypesBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.gameTypes) {
    return []
  }
  return sport.gameTypes
}

export const getGameStatusTypesBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.gameStatus) {
    return []
  }
  return sport.gameStatus
}

export const getGoalTypesBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.goalTypes) {
    return []
  }
  return sport.goalTypes.map((type) => ({
    ...type,
    id: parseInt(type?.id, 10),
  }))
}

export const getCardsBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.cardTypes) {
    return []
  }
  return sport.cardTypes
}

export const getAllPlayerTypes = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap((sport) => sport.players || [])
}

export const getAllPeriodTypes = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap((sport) => sport.periods || [])
}

export const getAllAssistTypes = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap((sport) => sport.assists || [])
}

export const getAllOffenseTypes = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap((sport) => sport.offenses || [])
}

export const getAllOffenseSeverityTypes = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap(
    (sport) => sport.offenseSeverityTypes || []
  )
}

export const getAllOfficialTypes = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap((sport) => sport.officials || [])
}

/**
 * Retrieves all stat types from all sports and adds the sport_id to each stat
 * @param {Object} state - The Redux state
 * @returns {Array} Array of stat types with their respective sport_id
 */
export const getAllStatTypes = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap((sport) =>
    (sport.statTypes || []).map((statType) => ({
      ...statType,
      sport_id: sport.id,
    }))
  )
}

export const getAllTeamOfficialTypes = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap((sport) => sport.teamOfficials || [])
}

export const getAllGameTypes = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap((sport) => sport.gameTypes || [])
}

export const getAllGameStatusTypes = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap((sport) => sport.gameStatus || [])
}

export const getAllGoalTypes = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap((sport) => sport.goalTypes || [])
}

export const getAllCards = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap((sport) => sport.cardTypes || [])
}

export const getTimezones = (state) => state.types.timezones

export const getGenericSportPropertiesBySportId = (state, sportId) => {
  const sport = getSportById(state, sportId)
  return sport && sport.genericSportProperties
}

export const getAllGenericSportProperties = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap(
    (sport) => sport.genericSportProperties || []
  )
}

export const getDefaultScheduleSettingsBySportId = (state, id) => {
  const sport = getSportById(state, id)
  if (!sport || !sport.defaultScheduleSettings) {
    return []
  }
  return sport.defaultScheduleSettings
}

export const getAllDefaultScheduleSettingsTypes = (state) => {
  const sports = getSports(state)
  return Object.values(sports).flatMap(
    (sport) => sport.defaultScheduleSettings || []
  )
}
