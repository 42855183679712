/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from 'styled-components'
import Slider, { Settings as SliderSettings } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { RightOutlined } from '@ant-design/icons'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import useHomeDivisionStandings from '@sportninja/common/hooks/useHomeDivisionStandings'
import {
  getOrdinalSuffix,
  processStandingsData,
} from '@sportninja/common/ts-utils'

const PrevArrow = (props: any) => {
  const { className, onClick, currentSlide } = props
  return (
    <div
      className={`${className} ${currentSlide === 0 ? 'slick-disabled' : ''}`}
      onClick={onClick}
      style={{ display: currentSlide === 0 ? 'none' : 'block' }}
    />
  )
}

const NextArrow = (props: any) => {
  const { className, onClick, slideCount, currentSlide } = props
  return (
    <div
      className={`${className} ${
        currentSlide === slideCount - 1 ? 'slick-disabled' : ''
      }`}
      onClick={onClick}
      style={{ display: currentSlide === slideCount - 1 ? 'none' : 'block' }}
    />
  )
}

const CardWrapper = styled.div`
  background-color: ${colors.SECONDARY_300};
  border-radius: 8px;
  border: 1px solid ${colors.SECONDARY_200};
  padding: 16px;
  width: 300px;
  color: white;
  width: 100%;
  margin-bottom: 32px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${colors.SOFT_STEEL};
`

const Title = styled.h2`
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  color: ${colors.WHITE};
`

const SubTitle = styled.h3`
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  color: ${colors.NEUTRAL_50};
`

const ViewMore = styled.a`
  color: ${colors.PRIMARY};
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
`

const TeamInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
`

// ant design image component
const TeamLogo = styled.img`
  width: 32px !important;
  height: 32px !important;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  object-fit: contain;
`

const TeamName = styled.h3`
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: ${colors.WHITE};
  margin: 0;
`

const TeamPosition = styled.p`
  color: ${colors.NEUTRAL_50};
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  margin: 0;
`

const StatsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const StatsHeader = styled.th`
  width: 32px;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.NEUTRAL_50};
  font-weight: 500;
  padding-bottom: 8px;
  text-align: center;
`

const StatsData = styled.td`
  color: ${colors.WHITE};
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  width: 32px;
`

const CustomDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #7f8c8d;

  .slick-active & {
    background-color: ${colors.PRIMARY};
  }
`

const StyledSlider = styled(Slider)`
  .slick-dots {
    /* center the dots */
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: -36px;
    gap: 8px;
    li {
      margin: 0 0;
      width: 8px !important;
      height: 8px !important;
    }
  }
  /* remove the margin of the arrows */
  .slick-arrow {
    margin: 0 0;
    z-index: 1;
  }

  /* the slides */
  .slick-slide {
    padding: 0 8px;
  }
  .slick-list {
    margin: 0 -8px;
  }
  .slick-prev,
  .slick-next {
    top: 50% !important;
    /* transform: translate(0, -30%) !important; */
  }
`

const CarouselContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
`

const settings: SliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  customPaging: (_i: number) => <CustomDot />,
  className: 'slick-carousel',
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
}

const ViewMoreText = styled.p`
  color: ${colors.PRIMARY};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.4px;
  width: 100%;
`

const DivisionStandingsCarousel: React.FC = () => {
  const { standingsData, standingsLoading } = useHomeDivisionStandings()

  if (standingsLoading || !standingsData || standingsData?.length === 0) {
    return null
  }

  const teams = processStandingsData(standingsData)

  if (teams?.length === 0) {
    return null
  }

  return (
    <CardWrapper>
      <CarouselContainer>
        <StyledSlider {...settings}>
          {teams.map((team, index) => (
            <div key={index}>
              <Header>
                <div style={{ maxWidth: '180px' }}>
                  <Title>{team.schedule_name}</Title>
                  <SubTitle>{team.schedule_root_name}</SubTitle>
                </div>
                <span
                  onClick={() => {
                    window.location.href = `/schedule/${team.schedule_root_id}/standings`
                  }}
                  css={css`
                    cursor: pointer;
                  `}
                >
                  <ViewMore>
                    <ViewMoreText>View more</ViewMoreText>
                    <RightOutlined style={{ fontSize: '12px' }} />
                  </ViewMore>
                </span>
              </Header>
              <span
                onClick={() => {
                  window.location.href = `/team/${team.team_id}`
                }}
                css={css`
                  cursor: pointer;
                `}
              >
                <TeamInfo>
                  <TeamLogo
                    src={
                      team.team_image ||
                      '/images/generic_placeholders/placeholder_team.png'
                    }
                    alt={`${team.team_name} logo`}
                    onError={(e) => {
                      e.currentTarget.src =
                        '/images/generic_placeholders/placeholder_team.png'
                    }}
                  />
                  <div>
                    <TeamName>{team.team_name}</TeamName>
                    <TeamPosition>
                      Position {team.rank}
                      {getOrdinalSuffix(team.rank)}
                    </TeamPosition>
                  </div>
                </TeamInfo>
              </span>
              <StatsTable>
                <thead>
                  <tr
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      width: '100%',
                    }}
                  >
                    <StatsHeader>GP</StatsHeader>
                    <StatsHeader>W</StatsHeader>
                    <StatsHeader>L</StatsHeader>
                    <StatsHeader>T</StatsHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      width: '100%',
                    }}
                  >
                    <StatsData>{team.GP}</StatsData>
                    <StatsData>{team.W}</StatsData>
                    <StatsData>{team.L}</StatsData>
                    <StatsData>{team.T}</StatsData>
                  </tr>
                </tbody>
              </StatsTable>
            </div>
          ))}
        </StyledSlider>
      </CarouselContainer>
    </CardWrapper>
  )
}

export default DivisionStandingsCarousel
