/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons'
import { Image, Radio } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import { Player } from '@sportninja/common/types/Player'
import { status } from '@sportninja/common/constants/attendanceStatus'
import req from '@sportninja/common/api/request'

interface ManageTeamAttendanceModalContentProps {
  players?: Player[]
  gameId?: string
  rosterId?: string
  refetch?: () => void
  readAttendanceInformation: () => void
  requireUniqueJerseyNumbers?: boolean
  // playerTypesBySport?: PlayerType[]
  isGenericSport?: boolean
  isSoccer?: boolean
}

const Container = styled.div`
  background-color: ${colors.SECONDARY_300};
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  max-height: 500px;
  min-height: 500px;
  overflow-y: auto;
`

const Alert = styled.div`
  font-size: 14px;
  line-height: 20px;
  background-color: #360000;
  border: 1px solid ${colors.ERROR_200};
  color: ${colors.ERROR_100};
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

const Table = styled.table`
  width: 100%;
`

const TableHead = styled.thead`
  text-align: left;
  background-color: ${colors.SECONDARY_200};
  color: ${colors.NEUTRAL_50};
  font-size: 14px;
  line-height: 20px;
  & th {
    padding: 8px;
    cursor: pointer;
    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
    }
  }
`

const TableRow = styled.tr<{ index: number }>`
  background-color: ${(props) =>
    props.index % 2 === 0 ? 'transparent' : colors.SECONDARY_200};
`

const TableCell = styled.td<{
  isDuplicate?: boolean
  clicable?: boolean
}>`
  min-width: 60px;
  padding: 8px;
  overflow: hidden;
  color: ${(props) =>
    props.isDuplicate
      ? colors.ERROR_200
      : props.clicable
      ? colors.PRIMARY
      : colors.NEUTRAL_0};
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.2px; /* 145% */
  text-align: center;
  vertical-align: middle;
  cursor: ${(props) => (props.clicable ? 'pointer' : 'default')};
`

const PlayerImageWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  overflow: hidden;
  border-radius: 50%;
  background-color: ${colors.NEUTRAL_0};
`

const StatusContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`

const StyledIcon = styled.span`
  font-size: 20px;
  cursor: pointer;
`

const StyledInput = styled.input`
  width: 60px;
  text-align: center;
  display: flex;
  padding: 8px;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${colors.SECONDARY_100};
  background: ${colors.SECONDARY_200};
  color: ${colors.NEUTRAL_0};
  font-size: 16px;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
  -moz-appearance: textfield; /* Firefox */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const AffiliateTag = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 145%; /* 17.4px */
  color: ${colors.NEUTRAL_50};
`

const SuspendedTag = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 145%; /* 17.4px */
  color: ${colors.ERROR_200};
`

const Separator = styled.tr`
  background-color: ${colors.SECONDARY_200};
  color: ${colors.NEUTRAL_50};
  font-size: 14px;
  font-weight: 600;
  td {
    padding: 8px;
    text-align: left;
  }
  border-top: 1px solid ${colors.NEUTRAL_100};
  border-bottom: 1px solid ${colors.NEUTRAL_100};
  padding: 4px;
`

const numberColumnStyles = css`
  text-align: center;
  width: 60px;
`

const positionColumnStyles = css`
  text-align: center;
  padding-left: 8px;
`

const startingGoalieColumnStyles = css`
  text-align: center;
  padding-left: 8px;
`

const exclamationIconStyles = css`
  margin-right: 0.5rem;
  font-size: 16px;
  color: ${colors.ERROR_100};
`

const playerImageStyles = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const playerNameContainerStyles = css`
  display: flex;
  align-items: center;
`

const playerNameTextStyles = css`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: flex-start;
`

const numberCellStyles = (isDuplicate: boolean, isSuspended: boolean) => css`
  cursor: ${isSuspended ? 'not-allowed' : 'pointer'};
  width: 60px;
  opacity: ${isSuspended ? 0.5 : 1};
  color: ${isDuplicate ? colors.ERROR_200 : colors.NEUTRAL_0};
`

const statusIconStyles = (
  isActive: boolean,
  color: string,
  isSuspended: boolean
) => css`
  color: ${isActive ? color : colors.NEUTRAL_50};
  cursor: ${isSuspended ? 'not-allowed' : 'pointer'};
  opacity: ${isSuspended ? 0.5 : 1};
`

const ManageTeamAttendanceModalContent: React.FC<
  ManageTeamAttendanceModalContentProps
> = ({
  players = [],
  gameId,
  rosterId,
  refetch,
  readAttendanceInformation,
  requireUniqueJerseyNumbers = true,
  // playerTypesBySport = [],
  isGenericSport = false,
  isSoccer = false,
}) => {
  // const goaliePlayerType = useMemo(
  //   () => playerTypesBySport.find((type) => type.is_goalie),
  //   [playerTypesBySport]
  // )

  const getDuplicateJerseyNumbers = () => {
    if (!requireUniqueJerseyNumbers) {
      return []
    }
    const jerseyNumbers = players.map((player) => player?.player_number)
    return jerseyNumbers.filter(
      (number, index) => jerseyNumbers.indexOf(number) !== index
    )
  }

  const duplicateJerseyNumbers = getDuplicateJerseyNumbers()
  const [isEditing, setIsEditing] = useState<string | null>(null)
  const [editedNumber, setEditedNumber] = useState<string>('')
  const [sortField, setSortField] = useState<any>('name_last')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [localPlayers, setLocalPlayers] = useState(players)

  const SortIcon = useCallback(
    ({ field }: { field: 'name' | 'number' }) => {
      if (sortField !== field) {
        return null
      }
      return sortDirection === 'asc' ? (
        <CaretUpOutlined />
      ) : (
        <CaretDownOutlined />
      )
    },
    [sortField, sortDirection]
  )

  useEffect(() => {
    setLocalPlayers(players)
  }, [players])

  const updatePlayerAttendance = useCallback(
    async (
      playerId: string,
      playerNumber: number,
      attendanceStatus: number
    ) => {
      try {
        await req(
          `/games/${gameId}/rosters/${rosterId}/players/${playerId}/attendance-data`,
          {
            method: 'PUT',
            body: JSON.stringify({
              player_number: playerNumber,
              attendance_status: attendanceStatus,
            }),
          }
        )
        refetch?.()
        readAttendanceInformation?.()
      } catch (e) {
        console.error(e)
      }
    },
    [gameId, rosterId, refetch, readAttendanceInformation]
  )

  const updateAttendanceStatus = useCallback(
    async (playerId: string, attendanceStatus: number | null) => {
      try {
        await req(
          `/games/${gameId}/rosters/${rosterId}/players/${playerId}/attendance`,
          {
            method: 'PUT',
            body: JSON.stringify({ attendance_status: attendanceStatus }),
          }
        )
        refetch?.()
        readAttendanceInformation?.()
      } catch (e) {
        console.error(e)
      }
    },
    [gameId, rosterId, refetch, readAttendanceInformation]
  )

  const handleStartingGoalieChange = useCallback(
    async (playerId: string, checked: boolean) => {
      console.log()
      try {
        // Update local state first
        setLocalPlayers((prevPlayers) => {
          const updatedPlayers = prevPlayers.map((p) => {
            if (p.id === playerId) {
              return {
                ...p,
                is_proposed_starting: checked,
              }
            }
            if (checked && p.id !== playerId) {
              return {
                ...p,
                is_proposed_starting: false,
              }
            }
            return p
          })
          return updatedPlayers
        })
        if (checked) {
          const otherGoalies = players.filter(
            (p) => p.id !== playerId && p.is_proposed_starting === true
          )
          await Promise.all(
            otherGoalies.map((goalie) =>
              req(
                `/games/${gameId}/rosters/${rosterId}/players/${goalie.id}/attendance-data`,
                {
                  method: 'PUT',
                  body: JSON.stringify({
                    is_proposed_starting: false,
                  }),
                }
              )
            )
          )
        }

        // Update selected goalie
        await req(
          `/games/${gameId}/rosters/${rosterId}/players/${playerId}/attendance-data`,
          {
            method: 'PUT',
            body: JSON.stringify({
              is_proposed_starting: checked,
              // player_type_id: goaliePlayerType?.id,
            }),
          }
        )

        // If setting a goalie as starting, unset all other goalies

        refetch?.()
        readAttendanceInformation?.()
      } catch (e) {
        console.error(e)
      }
    },
    [gameId, rosterId, refetch, readAttendanceInformation, players]
  )

  if (players.length === 0) {
    return <Container>No players available.</Container>
  }

  const handleSort = (field: 'name' | 'number' | 'name_last') => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortDirection('asc')
    }
  }

  const sortPlayers = (playerList: Player[]) => {
    return [...playerList].sort((a, b) => {
      // Then sort by selected field
      if (sortField === 'name_last' || sortField === 'name') {
        const aName = `${a.name_last}`.toLowerCase()
        const bName = `${b.name_last}`.toLowerCase()
        return sortDirection === 'asc'
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName)
      } else {
        const aNum = a.player_number || 0
        const bNum = b.player_number || 0
        return sortDirection === 'asc' ? aNum - bNum : bNum - aNum
      }
    })
  }

  const regularPlayers = sortPlayers(
    localPlayers.filter((p) => !p.is_affiliate)
  )
  const affiliatePlayers = sortPlayers(
    localPlayers.filter((p) => p.is_affiliate)
  )

  return (
    <Container>
      {duplicateJerseyNumbers.length > 0 && (
        <Alert>
          <ExclamationCircleOutlined css={exclamationIconStyles} />
          You Have {duplicateJerseyNumbers.length} Players With The Same Jersey
          Number
        </Alert>
      )}

      <Table>
        <TableHead>
          <tr>
            <th css={numberColumnStyles} onClick={() => handleSort('number')}>
              # <SortIcon field='number' />
            </th>
            <th onClick={() => handleSort('name')}>
              Player Name <SortIcon field='name' />
            </th>
            <td css={positionColumnStyles}>Position</td>
            {!isGenericSport && (
              <th css={startingGoalieColumnStyles}>
                Starting {isSoccer ? 'Keeper' : 'Goalie'}
              </th>
            )}
            <th css={positionColumnStyles}>Status</th>
          </tr>
        </TableHead>
        <tbody>
          {regularPlayers.map((player, index) => {
            const isDuplicate = duplicateJerseyNumbers.includes(
              player?.player_number
            )

            return (
              <TableRow key={player.id} index={index}>
                <TableCell>
                  {isEditing === player.id && !player.is_suspended ? (
                    <StyledInput
                      onInput={(e) => {
                        if (e.currentTarget.value.length > 3) {
                          e.currentTarget.value = e.currentTarget.value.slice(
                            0,
                            3
                          )
                        }
                      }}
                      maxLength={3}
                      type='number'
                      value={editedNumber}
                      onChange={(e) => setEditedNumber(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          updatePlayerAttendance(
                            player.id,
                            Number(editedNumber),
                            player.attendance_status
                          )
                          setIsEditing(null)
                          setEditedNumber('')
                        }
                      }}
                      onBlur={() => {
                        if (
                          editedNumber !== player.player_number?.toString() &&
                          editedNumber !== ''
                        ) {
                          updatePlayerAttendance(
                            player.id,
                            Number(editedNumber),
                            player.attendance_status
                          )
                        }
                        setIsEditing(null)
                        setEditedNumber('')
                      }}
                      autoFocus
                    />
                  ) : (
                    <div
                      css={numberCellStyles(isDuplicate, player.is_suspended)}
                      onClick={() => {
                        if (!player.is_suspended) {
                          setIsEditing(player.id)
                          setEditedNumber(
                            player.player_number?.toString() || ''
                          )
                        }
                      }}
                    >
                      {player?.player_number}
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  <div css={playerNameContainerStyles}>
                    <PlayerImageWrapper>
                      <Image
                        src={player.image}
                        alt={player?.full_name}
                        preview={false}
                        css={playerImageStyles}
                        fallback={
                          '/images/generic_placeholders/placeholder_player.png'
                        }
                      />
                    </PlayerImageWrapper>
                    <div css={playerNameTextStyles}>
                      {player?.name_first} {player?.name_last}
                      {player?.is_suspended && (
                        <SuspendedTag>Suspended</SuspendedTag>
                      )}
                    </div>
                  </div>
                </TableCell>
                <TableCell>{player?.player_type?.abbreviation}</TableCell>
                {!isGenericSport && (
                  <TableCell>
                    <Radio
                      checked={player.is_proposed_starting}
                      onChange={(e) =>
                        handleStartingGoalieChange(player.id, e.target.checked)
                      }
                      disabled={player.is_suspended}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <StatusContainer>
                    <StyledIcon
                      as={CheckCircleOutlined}
                      css={statusIconStyles(
                        player?.attendance_status === status.ATTENDING,
                        colors.SUCCESS_200,
                        player.is_suspended
                      )}
                      onClick={async () => {
                        if (!player.is_suspended) {
                          if (player?.attendance_status === status.ATTENDING) {
                            await updateAttendanceStatus(player.id, null)
                          } else {
                            await updateAttendanceStatus(
                              player.id,
                              status.ATTENDING
                            )
                          }
                        }
                      }}
                    />
                    <StyledIcon
                      as={QuestionCircleOutlined}
                      css={statusIconStyles(
                        player?.attendance_status === status.MAYBE,
                        colors.WARNING_200,
                        player.is_suspended
                      )}
                      onClick={async () => {
                        if (!player.is_suspended) {
                          if (player?.attendance_status === status.MAYBE) {
                            await updateAttendanceStatus(player.id, null)
                          } else {
                            await updateAttendanceStatus(
                              player.id,
                              status.MAYBE
                            )
                          }
                        }
                      }}
                    />
                    <StyledIcon
                      as={CloseCircleOutlined}
                      css={statusIconStyles(
                        player?.attendance_status === status.NOT_ATTENDING,
                        colors.ERROR_200,
                        player.is_suspended
                      )}
                      onClick={async () => {
                        if (!player.is_suspended) {
                          if (
                            player?.attendance_status === status.NOT_ATTENDING
                          ) {
                            await updateAttendanceStatus(player.id, null)
                          } else {
                            await updateAttendanceStatus(
                              player.id,
                              status.NOT_ATTENDING
                            )
                          }
                        }
                      }}
                    />
                  </StatusContainer>
                </TableCell>
              </TableRow>
            )
          })}

          {affiliatePlayers.length > 0 && (
            <Separator>
              <td colSpan={!isGenericSport ? 5 : 4}>Affiliates</td>
            </Separator>
          )}

          {affiliatePlayers.map((player, index) => {
            const isDuplicate = duplicateJerseyNumbers.includes(
              player?.player_number
            )

            return (
              <TableRow key={player.id} index={index}>
                <TableCell>
                  {isEditing === player.id && !player.is_suspended ? (
                    <StyledInput
                      onInput={(e) => {
                        if (e.currentTarget.value.length > 3) {
                          e.currentTarget.value = e.currentTarget.value.slice(
                            0,
                            3
                          )
                        }
                      }}
                      maxLength={3}
                      type='number'
                      value={editedNumber}
                      onChange={(e) => setEditedNumber(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          updatePlayerAttendance(
                            player.id,
                            Number(editedNumber),
                            player.attendance_status
                          )
                          setIsEditing(null)
                          setEditedNumber('')
                        }
                      }}
                      onBlur={() => {
                        if (
                          editedNumber !== player.player_number?.toString() &&
                          editedNumber !== ''
                        ) {
                          updatePlayerAttendance(
                            player.id,
                            Number(editedNumber),
                            player.attendance_status
                          )
                        }
                        setIsEditing(null)
                        setEditedNumber('')
                      }}
                      autoFocus
                    />
                  ) : (
                    <div
                      css={numberCellStyles(isDuplicate, player.is_suspended)}
                      onClick={() => {
                        if (!player.is_suspended) {
                          setIsEditing(player.id)
                          setEditedNumber(
                            player.player_number?.toString() || ''
                          )
                        }
                      }}
                    >
                      {player?.player_number}
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  <div css={playerNameContainerStyles}>
                    <PlayerImageWrapper>
                      <Image
                        src={player.image}
                        alt={player?.full_name}
                        preview={false}
                        css={playerImageStyles}
                        fallback={
                          '/images/generic_placeholders/placeholder_player.png'
                        }
                      />
                    </PlayerImageWrapper>
                    <div css={playerNameTextStyles}>
                      {player?.name_first} {player?.name_last}
                      {player?.is_affiliate && (
                        <AffiliateTag>Affiliate</AffiliateTag>
                      )}
                      {player?.is_suspended && (
                        <SuspendedTag>Suspended</SuspendedTag>
                      )}
                    </div>
                  </div>
                </TableCell>
                <TableCell>{player?.player_type?.abbreviation}</TableCell>
                {!isGenericSport && (
                  <TableCell>
                    <Radio
                      checked={player.is_proposed_starting}
                      onChange={(e) =>
                        handleStartingGoalieChange(player.id, e.target.checked)
                      }
                      disabled={player.is_suspended}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <StatusContainer>
                    <StyledIcon
                      as={CheckCircleOutlined}
                      css={statusIconStyles(
                        player?.attendance_status === status.ATTENDING,
                        colors.SUCCESS_200,
                        player.is_suspended
                      )}
                      onClick={async () => {
                        if (!player.is_suspended) {
                          if (player?.attendance_status === status.ATTENDING) {
                            await updateAttendanceStatus(player.id, null)
                          } else {
                            await updateAttendanceStatus(
                              player.id,
                              status.ATTENDING
                            )
                          }
                        }
                      }}
                    />
                    <StyledIcon
                      as={QuestionCircleOutlined}
                      css={statusIconStyles(
                        player?.attendance_status === status.MAYBE,
                        colors.WARNING_200,
                        player.is_suspended
                      )}
                      onClick={async () => {
                        if (!player.is_suspended) {
                          if (player?.attendance_status === status.MAYBE) {
                            await updateAttendanceStatus(player.id, null)
                          } else {
                            await updateAttendanceStatus(
                              player.id,
                              status.MAYBE
                            )
                          }
                        }
                      }}
                    />
                    <StyledIcon
                      as={CloseCircleOutlined}
                      css={statusIconStyles(
                        player?.attendance_status === status.NOT_ATTENDING,
                        colors.ERROR_200,
                        player.is_suspended
                      )}
                      onClick={async () => {
                        if (!player.is_suspended) {
                          if (
                            player?.attendance_status === status.NOT_ATTENDING
                          ) {
                            await updateAttendanceStatus(player.id, null)
                          } else {
                            await updateAttendanceStatus(
                              player.id,
                              status.NOT_ATTENDING
                            )
                          }
                        }
                      }}
                    />
                  </StatusContainer>
                </TableCell>
              </TableRow>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

export default ManageTeamAttendanceModalContent
