/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import colors from '@sportninja/common/constants/appColors'
import Text from '../ts-components/Text'
import { CameraOutlined } from '@ant-design/icons'

interface PostInputProps {
  onInputClick: () => void
  avatarUrl?: string
}

const PostInput: React.FC<PostInputProps> = ({ onInputClick, avatarUrl }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 16px;
        border: 1px solid ${colors.SECONDARY_200};
        background: ${colors.SECONDARY_300};
        border-radius: 8px;
        cursor: pointer;
        gap: 8px;
      `}
      onClick={onInputClick}
    >
      <img
        src={avatarUrl || '/images/generic_placeholders/placeholder_player.png'}
        alt='User avatar'
        css={css`
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: contain;
          border: 1px solid ${colors.NEUTRAL_100};
        `}
      />
      <div
        css={css`
          border-radius: 8px;
          border: 1px solid ${colors.SECONDARY_100};
          background: ${colors.SECONDARY_200};
          display: flex;
          align-items: center;
          gap: 16px;
          padding: 8px 16px;
          width: 100%;
          min-height: 40px;
        `}
      >
        <Text
          variant='paragraphMedium'
          weight='regular'
          color={colors.NEUTRAL_100}
          style={`
          flex-grow: 1;
        `}
        >
          Start writing a post or announcement...
        </Text>

        <CameraOutlined
          css={css`
            font-size: 24px;
            color: ${colors.PRIMARY};
          `}
        />
      </div>
    </div>
  )
}

export default PostInput
