/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Text from 'src/components/ts-components/Text'
import Button from 'src/components/ts-components/Button'
import { TextInput } from 'src/components/ts-components/TextInput'
import { DateOfBirthInput } from 'src/components/ts-components/DateOfBirthInput'
import { Checkbox } from 'antd'
// @ts-ignore
import PhoneInputComponent from 'src/components/PhoneFormComponent'

/**
 * Props for the ParentStep component
 * @typedef {Object} ParentStepProps
 * @property {Object} data - The invitation data
 * @property {Function} onNext - Function to call when proceeding to next step
 * @property {Array<string>} fieldErrors - Array of field names with errors
 * @property {Object} customError - Custom error messages by field name
 * @property {string} userName - Parent's first name
 * @property {string} userLastName - Parent's last name
 * @property {string} userDOB - Parent's date of birth
 * @property {string} userEmail - Parent's email
 * @property {string} userPhone - Parent's phone number
 * @property {string} password - Parent's password
 * @property {string} confirmPassword - Parent's password confirmation
 * @property {boolean} acceptTerms - Whether parent has accepted terms
 * @property {Function} setUserName - Function to set parent's first name
 * @property {Function} setUserLastName - Function to set parent's last name
 * @property {Function} setUserDOB - Function to set parent's date of birth
 * @property {Function} setUserEmail - Function to set parent's email
 * @property {Function} setUserPhone - Function to set parent's phone number
 * @property {Function} setPassword - Function to set parent's password
 * @property {Function} setConfirmPassword - Function to set parent's password confirmation
 * @property {Function} setAcceptTerms - Function to set whether parent has accepted terms
 * @property {Function} onBack - Function to go back to previous step
 * @property {Array<number>} stepState - Array tracking the step history
 */
type ParentStepProps = {
  data: any
  onNext: () => void
  fieldErrors?: Record<string, string>
  customError?: Record<string, string>
  userName: string
  userLastName: string
  userDOB: string
  userEmail: string
  userPhone: string
  password: string
  confirmPassword: string
  acceptTerms: boolean
  setUserName: (value: string) => void
  setUserLastName: (value: string) => void
  setUserDOB: (value: string) => void
  setUserEmail: (value: string) => void
  setUserPhone: (value: string) => void
  setPassword: (value: string) => void
  setConfirmPassword: (value: string) => void
  setAcceptTerms: (value: boolean) => void
  onBack: () => void
  stepState: number[]
}

/**
 * Default container style for phone input component
 * @type {React.CSSProperties}
 */
const defaultContainerStyle = {
  width: '100%',
  height: 40,
}

/**
 * Default input style for phone input component
 * @type {React.CSSProperties}
 */
const defaultInputStyle = {
  borderWidth: 1,
  borderColor: '#3F4753',
  height: 40,
  borderRadius: 8,
  display: 'flex',
  backgroundColor: colors.SECONDARY_200,
  color: 'rgba(255, 255, 255, 0.85)',
  fontSize: 16,
  width: '100%',
}

/**
 * ParentStep component for the underage invitation flow
 * Allows parent to create their profile
 *
 * @param {ParentStepProps} props - Component props
 * @returns {JSX.Element} The rendered component
 */
const ParentStep = ({
  onNext,
  fieldErrors = {},
  customError = {},
  userName,
  userLastName,
  userDOB,
  password,
  confirmPassword,
  acceptTerms,
  setUserName,
  setUserLastName,
  setUserDOB,
  setUserPhone,
  setPassword,
  setConfirmPassword,
  setAcceptTerms,
  onBack,
  stepState,
}: ParentStepProps) => {
  console.log('fieldErrors', fieldErrors)
  console.log('customError', customError)
  return (
    <div
      css={css`
        padding-top: 32px;
      `}
    >
      <Text variant='displaySmall' weight='bold' color={colors.NEUTRAL_0}>
        WELCOME TO SPORTNINJA
      </Text>
      <p
        css={css`
          font-size: 18px;
          margin-top: 16px;
        `}
      >
        {"Ok, now let's create your profile:"}
      </p>
      <form
        css={css`
          margin-top: 32px;

          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 8px;
            margin-top: 16px;

            @media (max-width: 768px) {
              flex-direction: column;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
              width: 50%;
              @media (max-width: 768px) {
                width: 100%;
              }
            `}
          >
            <TextInput
              name='name_first'
              customError={customError}
              fieldErrors={fieldErrors}
              label='Your First Name *'
              setValue={(value) => setUserName(value)}
              value={userName}
              placeholder='Your First Name'
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
              width: 50%;
              @media (max-width: 768px) {
                width: 100%;
              }
            `}
          >
            <TextInput
              name='name_last'
              customError={customError}
              fieldErrors={fieldErrors}
              label='Your Last Name *'
              setValue={(value) => setUserLastName(value)}
              value={userLastName}
              placeholder='Your Last Name'
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            gap: 8px;
            margin-top: 16px;

            @media (max-width: 768px) {
              flex-direction: column;
            }
          `}
        >
          <div
            css={css`
              flex: 1;
            `}
          >
            <DateOfBirthInput
              name='birth_date'
              value={userDOB}
              setValue={(value) => setUserDOB(value)}
              label='Your Birth Date *'
              fieldErrors={fieldErrors}
              customError={customError}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: 49.3%;
              gap: 8px;
              @media (max-width: 768px) {
                width: 100%;
              }
            `}
          >
            <style>
              {`
                .flag-dropdown {
                  height: 40px!important;
                }
              `}
            </style>
            <p
              tabIndex={-1}
              css={css`
                font-weight: 500;
                font-size: 14px;
                line-height: 145%;
                color: ${colors.WHITE};
              `}
            >
              Phone Number
            </p>
            {/* @ts-ignore */}
            <PhoneInputComponent
              // @ts-ignore
              tabIndex={1}
              namePhone={'phone'}
              onChangePhone={(e) => setUserPhone(e.target.value)}
              setPhoneValue={setUserPhone}
              containerStyle={defaultContainerStyle}
              inputStyle={defaultInputStyle}
              disableDropdown={true}
              invalid={fieldErrors?.phone !== undefined}
              invalidMessage={fieldErrors?.phone}
            />
            {fieldErrors?.phone ? (
              <div
                css={css`
                  transition: opacity 0.1s ease-in-out;
                  display: ${fieldErrors?.phone !== undefined
                    ? 'block'
                    : 'none'};
                  font-size: 13px;
                  line-height: 16px;
                  color: ${colors.ERROR_200};
                `}
              >
                <span>{fieldErrors?.phone}</span>
              </div>
            ) : null}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            gap: 8px;
            margin-top: 16px;
            width: 100%;
            @media (max-width: 768px) {
              flex-direction: column;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
              width: 50%;
              @media (max-width: 768px) {
                width: 100%;
              }
            `}
          >
            <TextInput
              name='password'
              label='Password *'
              value={password}
              setValue={(value) => setPassword(value)}
              fieldErrors={fieldErrors}
              customError={customError}
              type='password'
              placeholder='Password'
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
              width: 50%;
              @media (max-width: 768px) {
                width: 100%;
              }
            `}
          >
            <TextInput
              name='confirm_password'
              label='Confirm Password *'
              value={confirmPassword}
              setValue={(value) => setConfirmPassword(value)}
              fieldErrors={fieldErrors}
              customError={customError}
              type='password'
              placeholder='Confirm Password'
            />
          </div>
        </div>
        <div
          css={css`
            margin-top: 16px;
            display: flex;
            gap: 8px;
            align-items: center;
          `}
        >
          <Checkbox
            checked={acceptTerms}
            onChange={(e) => setAcceptTerms(e.target.checked)}
          />
          <Text variant='paragraphSmall' weight='regular' color={colors.WHITE}>
            I agree to the{' '}
            <a
              href='/docs/eula.pdf'
              target='_blank'
              css={css`
                cursor: pointer;
                color: ${colors.DEFAULT_FLAIR};
              `}
            >
              Terms & Conditions
            </a>{' '}
            and the{' '}
            <a
              href='/docs/privacy.pdf'
              target='_blank'
              css={css`
                cursor: pointer;
                color: ${colors.DEFAULT_FLAIR};
              `}
            >
              Privacy Policy
            </a>
            .
          </Text>
        </div>
      </form>
      <div
        css={css`
          display: flex;
          gap: 16px;
          margin-top: 16px;
          width: 100%;
          justify-content: flex-end;
          @media (max-width: 768px) {
            width: 100%;
            flex-direction: column-reverse;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            width: 50%;
            align-self: flex-end;
            @media (max-width: 768px) {
              width: 100%;
            }
          `}
        >
          {stepState.length > 0 && (
            <Button text='Back' variant='secondary' onClick={onBack} />
          )}
        </div>
        <div
          css={css`
            display: flex;
            width: 50%;
            align-self: flex-end;
            @media (max-width: 768px) {
              width: 100%;
            }
          `}
        >
          <Button
            variant='primary'
            text='Create Your User'
            onClick={onNext}
            disabled={!acceptTerms}
          />
        </div>
      </div>
    </div>
  )
}

export default ParentStep
