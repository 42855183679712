/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { CloseOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import Button from 'src/components/ts-components/Button'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import Text from '../ts-components/Text'
import useWindowSize from 'src/hooks/useWindowSize'
import { useModalScroll } from 'src/hooks/useModalScroll'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const styles = css`
  .my-modal-body {
  }
  .my-modal-mask {
    backdrop-filter: blur(1px);
  }
  .my-modal-header {
    border-bottom: 1px dotted ${colors.FIERY_RED};
  }
  .my-modal-footer {
    color: ${colors.FIERY_RED};
  }
  .my-modal-content {
    border: 1px solid #333;
  }
`

const classNames = {
  body: styles['my-modal-body'],
  mask: styles['my-modal-mask'],
  header: styles['my-modal-header'],
  footer: styles['my-modal-footer'],
  content: styles['my-modal-content'],
}

const modalStyles = {
  header: {
    backgroundColor: colors.SECONDARY_300,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  body: {
    borderBottom: `1px solid ${colors.SOFT_STEEL}`,
    borderTop: `1px solid ${colors.SOFT_STEEL}`,
    backgroundColor: colors.SECONDARY_300,
  },
  content: {
    backgroundColor: colors.SECONDARY_300,
    paddingTop: '8px',
    paddingBottom: '16px',
    paddingLeft: '0',
    paddingRight: '0',
  },
  wrapper: {
    zIndex: 10001,
  },
  mask: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(6, 10, 16, 0.8)',
    zIndex: 10000,
  },
  footer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

type CompetitionSetupModalProps = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  onContinue: (structureType: 'division' | 'conference') => void
  onCancel: () => void
}

const CompetitionSetupModal = ({
  isOpen,
  setIsOpen,
  onContinue,
  onCancel,
}: CompetitionSetupModalProps) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width <= 768, [width])
  const { openModal, closeModal } = useModalScroll()

  useEffect(() => {
    if (isOpen) {
      openModal()
    } else {
      closeModal()
    }
  }, [closeModal, isOpen, openModal])

  const closeIcon = useCallback(() => {
    return (
      <CloseOutlined
        css={css`
          color: ${colors.SOLITUDE};
          font-size: 16px;
        `}
      />
    )
  }, [])

  return (
    <Modal
      css={css`
        display: flex;
        flex: 1;
        height: ${isMobile ? '100vh' : '60vh'} !important;
        margin: ${isMobile ? '0' : '24px'};
        padding: 0;
        max-width: ${isMobile ? '100%' : '1080px'};

        top: ${isMobile ? '0' : '50%'};
        .ant-modal-content {
          min-height: ${isMobile ? '100vh' : 'inherit'} !important;
          border-radius: ${isMobile ? '0' : '8px'};
          display: flex;
          flex-direction: column;
          padding: ${isMobile
            ? '16px 12px 12px 12px'
            : '32px 24px 24px 24px'} !important;
        }
        .ant-modal-body {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          border-top: 0 !important;
          border-bottom: 0 !important;
        }
      `}
      width={isMobile ? '100%' : 780}
      closeIcon={closeIcon()}
      open={isOpen}
      onCancel={() => {
        setIsOpen(false)
        onCancel()
      }}
      footer={
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          `}
        >
          <div
            css={css`
              width: ${isMobile ? '100%' : '60%'};
            `}
          >
            <Button
              text='CONTINUE TO SETUP'
              onClick={() => onContinue('division')}
              variant='primary'
              style={`
                width: 100%;
              `}
            />
          </div>
        </div>
      }
      centered
      classNames={classNames}
      styles={modalStyles}
      maskClosable={false}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: center;
          `}
        >
          <Text
            variant='heading4'
            weight='semiBold'
            color={colors.NEUTRAL_0}
            style={'align-self: center;'}
          >
            Next: {isCanlan ? 'Season' : 'Competition'} Structure Setup
          </Text>
          <Text
            variant='paragraphSmall'
            color={colors.NEUTRAL_50}
            style={`
              text-align: center;
            `}
          >
            Learn about the two {isCanlan ? 'season' : 'competition'} structures
            you can configure in the setup
          </Text>
        </div>

        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 32px;
            ${isMobile
              ? `
                grid-template-columns: 1fr;
              `
              : ''}
          `}
        >
          {/* Division Based Column */}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              flex: 1 0 0;
              align-self: stretch;
              border-radius: 16px;
              border: 1px solid ${colors.SECONDARY_100};
            `}
          >
            <img
              src={'/images/competition_type_one.png'}
              alt='Division Based'
              css={css`
                width: 100%;
                height: 160px;
                border-radius: 16px 16px 0 0;
              `}
            />
            <div
              css={css`
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                height: 100%;
                gap: 8px;
                align-self: stretch;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    align-self: stretch;
                  `}
                >
                  <Text
                    variant='heading6'
                    color={colors.WHITE}
                    weight='semiBold'
                  >
                    Division Based
                  </Text>
                  <div
                    css={css`
                      display: flex;
                      padding: 2px 4px;
                      justify-content: center;
                      align-items: center;
                      gap: 8px;
                      border-radius: 2px;
                      background: rgba(56, 148, 255, 0.1);
                    `}
                  >
                    <Text
                      variant='paragraphXSmall'
                      color={colors.INFO_300}
                      weight='regular'
                    >
                      Most Common
                    </Text>
                  </div>
                </div>
              </div>
              <Text variant='paragraphSmall' color={colors.NEUTRAL_50}>
                • Teams compete within divisions.
                <br />
                • Best for smaller leagues without geographic or skill-based
                separation.
                <br />• Examples: Local leagues, small tournaments.
              </Text>
            </div>
          </div>

          {/* Conference Based Column */}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              flex: 1 0 0;
              align-self: stretch;
              border-radius: 16px;
              border: 1px solid ${colors.SECONDARY_100};
            `}
          >
            <img
              src={'/images/competition_type_two.png'}
              alt='Conference Based'
              css={css`
                width: 100%;
                height: 160px;
                border-radius: 16px 16px 0 0;
              `}
            />
            <div
              css={css`
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                align-self: stretch;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  flex: 1 0 0;
                  align-self: stretch;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    align-self: stretch;
                  `}
                >
                  <Text
                    variant='heading6'
                    color={colors.WHITE}
                    weight='semiBold'
                  >
                    Conference {'>'} Division Based
                  </Text>
                </div>
              </div>
              <Text
                variant='paragraphSmall'
                color={colors.NEUTRAL_50}
                style={`
                  text-align: left;
                `}
              >
                • Teams are grouped into conferences (e.g., East/West), then
                divided into divisions.
                <br />
                • Ideal for larger leagues with geographic or skill-based
                separation.
                <br />• Examples: NHL, NBA, NFL.
              </Text>
            </div>
          </div>
        </div>

        <Text
          variant='paragraphXSmall'
          color={colors.NEUTRAL_50}
          style={`
            text-align: center;
          `}
        >
          Note: Teams are added to the Division-level only.
        </Text>
      </div>
    </Modal>
  )
}

export default CompetitionSetupModal
