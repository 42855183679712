/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React, { useState, useEffect } from 'react'
import { Input } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import Text from '../Text'
import { paragraphSmall, weightRegular } from 'src/components/css'
import { CloseOutlined } from '@ant-design/icons'
import { ErrorLabel } from '../ErrorLabel'
interface MultipleOptionsInputProps {
  label: string
  tip?: string
  placeholder?: string
  value: string[]
  type?: 'email' | 'text'
  onChange: (values: string[]) => void
  validator?: RegExp
  fieldErrors: string[]
  customError: any
  name: string
}

const MultipleOptionsInput: React.FC<MultipleOptionsInputProps> = ({
  label,
  tip,
  placeholder = 'Enter value',
  value,
  type = 'text',
  onChange,
  validator,
  fieldErrors,
  customError,
  name,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [isError, setIsError] = useState(false)

  /**
   * Validates the input against the provided validator regex
   * @param input - The string to validate
   * @returns boolean indicating if the input is valid
   */
  const validateInput = (input: string) => {
    if (!validator || !input.trim()) {
      return true
    }
    return validator.test(input)
  }

  /**
   * Processes the input value and adds valid entries to the list
   * @param inputToProcess - The input string to process
   * @returns boolean indicating if processing was successful
   */
  const processInput = (inputToProcess: string): boolean => {
    if (!inputToProcess.trim()) return false

    const newValues = inputToProcess
      .split(',')
      .map((v) => v.trim())
      .filter((v) => v)
    const validValues = newValues.filter(validateInput)

    if (validValues.length === newValues.length) {
      const updatedValues = [...new Set([...value, ...validValues])]
      onChange(updatedValues)
      setInputValue('')
      setIsError(false)
      return true
    } else {
      setIsError(true)
      return false
    }
  }

  /**
   * Handles input changes and automatically adds values when comma or space is detected
   * @param e - The input change event
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setIsError(false)

    // Check if the input ends with a comma or space
    if (newValue.endsWith(',') || newValue.endsWith(' ')) {
      // Remove the comma or space and process the input
      const valueToProcess = newValue.slice(0, -1).trim()

      if (valueToProcess && validateInput(valueToProcess)) {
        processInput(valueToProcess)
      } else {
        // Keep the input value without the comma/space if it's not valid
        setInputValue(newValue)
      }
    } else {
      setInputValue(newValue)
    }
  }

  /**
   * Handles key down events, particularly for the Enter key
   * @param e - The keyboard event
   */
  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      processInput(inputValue)
    }
  }

  /**
   * Handles removing a value from the list
   * @param valueToRemove - The value to remove
   */
  const handleRemoveValue = (valueToRemove: string) => {
    onChange(value.filter((v) => v !== valueToRemove))
  }

  // Reset error state when input changes
  useEffect(() => {
    setIsError(false)
  }, [inputValue])

  // Handle input blur to automatically add valid email
  const handleBlur = () => {
    if (inputValue.trim() && validateInput(inputValue.trim())) {
      processInput(inputValue)
    }
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 8px;
        margin-bottom: 16px;

        .ant-input {
          background-color: ${colors.HEADER};
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
        `}
      >
        <Text variant='paragraphSmall' weight='regular' color={colors.WHITE}>
          {label}
        </Text>
        {tip && (
          <Text
            variant='paragraphXSmall'
            weight='regular'
            color={colors.NEUTRAL_100}
          >
            {tip}
          </Text>
        )}
      </div>
      <Input
        css={css`
          display: flex;
          padding: 8px 16px 8px 8px !important;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid
            ${isError ? colors.ERROR_LIGHT : colors.SECONDARY_100}!important;
          background-color: ${colors.SECONDARY_200}!important;
        `}
        type={type}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onBlur={handleBlur}
        placeholder={placeholder}
        status={
          fieldErrors[label] || fieldErrors?.includes(label) ? 'error' : ''
        }
      />
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
        `}
      >
        {value.map((item) => (
          <div
            key={item}
            css={css`
              ${paragraphSmall}
              ${weightRegular}
              display: flex;
              padding: 4px 8px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 4px;
              background: ${colors.PRIMARY};
              color: ${colors.SECONDARY_300};
            `}
          >
            {item}
            <CloseOutlined
              css={css`
                cursor: pointer;
                color: ${colors.SECONDARY_300};
                font-size: 10px;
              `}
              onClick={() => handleRemoveValue(item)}
            />
          </div>
        ))}
        <ErrorLabel
          fieldErrors={fieldErrors || []}
          customError={customError || ''}
          name={name}
        />
      </div>
    </div>
  )
}

export default MultipleOptionsInput
