/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Select, Checkbox } from 'antd'
import React from 'react'
import { paragraphMedium, weightRegular } from 'src/components/css'
import { ErrorLabel } from '../ErrorLabel'
import Text from '../Text'

type SelectInputProps = {
  label: string
  disabled: boolean
  options: any[]
  name: string
  value: string
  onChange: (value: string) => void
  fieldErrors: any
  customError: any
  showSearch?: boolean
  filterOption?: (input: string, option: any) => boolean
  style?: any
  extraCheckBox?: boolean
  extraCheckBoxLabel?: string
  extraCheckBoxOnChange?: (value: boolean) => void
  onSearch?: (value: string) => void
  allowClear?: boolean
  inputRef?: React.RefObject<any>
  onSelect?: (value: string) => void
  placeholder?: string
}

export const SelectInput: React.FC<SelectInputProps> = ({
  label,
  disabled,
  options,
  name,
  value,
  onChange,
  fieldErrors,
  customError,
  showSearch = false,
  filterOption,
  style,
  extraCheckBox,
  extraCheckBoxLabel,
  extraCheckBoxOnChange,
  onSearch,
  allowClear = false,
  inputRef,
  onSelect,
  placeholder,
}) => {
  return (
    <>
      <style>
        {`
          .ant-select-dropdown {
            z-index: 999999 !important;
          }
          .ant-select-selector {
            background: ${colors.SECONDARY_200} !important;
            border-radius: 8px !important;
          }
          .ant-select-selection-item {
            font-size: 14px !important;
            font-weight: 400 !important;
            color: ${colors.WHITE} !important;
          }
          .ant-select-dropdown .ant-select-item-option-content {
            font-size: 14px !important;
            font-weight: 400 !important;
            color: ${colors.WHITE} !important;
          }
        `}
      </style>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text
          variant='paragraphSmall'
          weight='regular'
          color={colors.WHITE}
          style={`
            align-self: stretch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {label}
        </Text>
        {extraCheckBox && extraCheckBoxLabel && extraCheckBoxOnChange && (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: flex-end;
            `}
          >
            <Checkbox
              onChange={(e) => extraCheckBoxOnChange(e.target.checked)}
              css={css`
                .ant-checkbox-inner {
                  background-color: ${colors.SECONDARY_200};
                  border-color: ${colors.WHITE};
                }
                .ant-checkbox-wrapper {
                  color: ${colors.WHITE};
                }
              `}
            >
              {extraCheckBoxLabel}
            </Checkbox>
          </div>
        )}
      </div>
      <Select
        placeholder={placeholder}
        ref={inputRef}
        allowClear={allowClear}
        disabled={disabled}
        showSearch={showSearch}
        onChange={(e) => onChange(e)}
        onSearch={(e) => onSearch && onSearch(e)}
        onSelect={onSelect}
        options={options}
        value={value || undefined}
        css={css`
          ${paragraphMedium}
          ${weightRegular}
          font-size: 16px !important;
          color: ${colors.WHITE};
          width: 100%;
          background: ${colors.SECONDARY_200} !important;
          border-radius: 8px !important;
          height: 40px !important;
          ${disabled && 'opacity: 0.5 !important'}
          ${style}
        `}
        filterOption={filterOption}
        status={fieldErrors[name] || fieldErrors?.includes(name) ? 'error' : ''}
      />
      <ErrorLabel
        fieldErrors={fieldErrors || []}
        customError={customError || ''}
        name={name}
      />
    </>
  )
}
