import React, { useCallback, useState } from 'react'
import { t } from '@sportninja/common/i18n'

import ImagePicker from '../ImagePicker'
import FileInput from '../FileInput'
import Icon from '../Icon'
import { Flex } from '../Layout'
import { InputWrapper } from '../Form/css'
import { Tooltip } from 'antd'

const BackgroundPicturePicker = ({
  disabled,
  imageId,
  isBackground = false,
  noFlex,
  onChange,
  placeholder = t('common:logo'),
}) => {
  const [imageData, setImageData] = useState(false)
  const [fileName, setFileName] = useState(false)
  const [shouldDelete, setShouldDelete] = useState(false)

  const onComplete = useCallback((data, name) => {
    setImageData(data)
    setFileName(name)
    onChange({ target: { name: 'backgroundImageData', value: data } })
  }, [])

  const buttonTextPrefix =
    !shouldDelete && imageId
      ? `${t('Web:change')}`
      : isBackground
      ? 'Upload Background'
      : `${t('Web:upload')}`

  return (
    <Tooltip
      title={isBackground ? 'Recommended: 1440x300' : null}
      placement='bottom'
    >
      <InputWrapper
        alignItems='center'
        style={{ flexDirection: 'row' }}
        noFlex={noFlex}
        disabled={disabled}
      >
        {!shouldDelete && imageId && !imageData && (
          <Flex
            noFlex
            alignItems='center'
            style={{ alignSelf: 'flex-end', height: 52, marginRight: 8 }}
          >
            <Icon
              name='trash'
              style={{ cursor: 'pointer' }}
              fontSize={16}
              onClick={() => {
                setShouldDelete(true)
                onChange({
                  target: { name: 'delete_image_background', value: true },
                })
              }}
            />
          </Flex>
        )}
        <FileInput
          backgroundImage={imageData || (!shouldDelete && imageId)}
          disabled={disabled}
          fileName={fileName}
          htmlFor='background-image-picker'
          labelText={`${buttonTextPrefix} ${
            isBackground ? 'Image' : placeholder
          }`}
          noFlex={noFlex}
        >
          <ImagePicker
            disabled={disabled}
            onComplete={onComplete}
            customId={'background-image-picker'}
          />
        </FileInput>
        {shouldDelete && !imageData && (
          <div
            style={{
              position: 'absolute',
              bottom: -29,
              left: 8,
              width: 260,
              fontSize: 12,
              color: 'red',
            }}
          >
            Image will be removed. Press &apos;Submit&apos; to confirm.
          </div>
        )}
      </InputWrapper>
    </Tooltip>
  )
}

export default BackgroundPicturePicker
