import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import ActionButton from '../ActionButton'
import Modal from '../Modal'
import getImportConfig from './get-import-config'
import ImportCSVContent from './ImportCSVContent'

const ImportCSV = ({
  button,
  entityType,
  importType,
  teamName,
  teamRosterId,
  useActionButton,
  customOnSuccessMessage = null,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const importTemplate = getImportConfig(teamRosterId, importType, entityType)

  const handleEsc = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false)
      }
    },
    [setIsOpen]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [handleEsc])

  const onToggle = () => {
    setIsOpen(!isOpen)
  }

  const link = useActionButton ? (
    <ActionButton
      busy={loading}
      icon='upload'
      onClick={onToggle}
      label={importTemplate?.label}
      title={importTemplate?.label}
    />
  ) : button ? (
    button(importTemplate?.label, onToggle)
  ) : (
    <button
      type='button'
      className='is--clickable'
      style={{ marginTop: 'auto' }}
      onClick={onToggle}
    >
      <div className='signOutText'>
        <Icon name='upload' style={{ marginRight: 8 }} />
        {importTemplate?.label}
      </div>
    </button>
  )

  return (
    <>
      {link}
      <Modal isOpen={isOpen} onClose={onToggle}>
        {isOpen && (
          <ImportCSVContent
            setLoading={setLoading}
            loading={loading}
            toggle={onToggle}
            importTemplate={importTemplate}
            entityType={entityType}
            importType={importType}
            teamName={teamName}
            teamRosterId={teamRosterId}
            customOnSuccessMessage={customOnSuccessMessage}
            {...props}
          />
        )}
      </Modal>
    </>
  )
}

ImportCSV.propTypes = {
  id: PropTypes.string.isRequired,
  importType: PropTypes.string,
  entityType: PropTypes.string,
  supportsXML: PropTypes.bool,
  teamName: PropTypes.string,
  teamRosterId: PropTypes.string,
  useActionButton: PropTypes.bool,
}

export default ImportCSV
