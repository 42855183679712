import req from '@sportninja/common/api/request'
import { useCallback, useState } from 'react'

export type Country = {
  id: number
  name: string
  name_full: string
  iso_3166_2: string
  iso_3166_3: string
  capital: string
  citizenship: string
  currency_symbol: string
  is_visible: boolean
}

export const useCountries = () => {
  const [countries, setCountries] = useState<Country[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchCountries = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const response = await req('/countries')

      // Sort to ensure US and Canada are at the top
      const sortedCountries = [...response].sort((a, b) => {
        if (a.iso_3166_2 === 'US') {
          return -1
        }
        if (b.iso_3166_2 === 'US') {
          return 1
        }
        if (a.iso_3166_2 === 'CA') {
          return -1
        }
        if (b.iso_3166_2 === 'CA') {
          return 1
        }
        return 0
      })
      setCountries(sortedCountries)
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error('An error occurred while fetching countries')
      )
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    countries,
    loading,
    error,
    refetch: fetchCountries,
  }
}
