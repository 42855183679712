/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { useEffect, useState } from 'react'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { zIndex } from '../css'
import { Flex } from '../Layout'
import LoadingSpinner from '../LoadingSpinner'
import { media } from '../Responsive'
import Text from '../ts-components/Text'

dayjs.extend(isToday)

const ChatListItem = ({
  id,
  onClick,
  loggedInUserId,
  unreadMessages,
  data,
}) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (data) {
      setLoading(false)
    }
  }, [data])

  let date = ''
  if (data?.last_message?.created_at) {
    const dayjsDate = dayjs.utc(data?.last_message?.created_at).local()
    if (dayjsDate.isToday()) {
      date = dayjsDate.format('h:mm a')
    } else if (dayjsDate.year() === dayjs().year()) {
      date = dayjsDate.format('MMM D')
    } else {
      date = dayjsDate.format('MM/YYYY')
    }
  }

  if (loading) {
    return false
  }

  return (
    <Flex
      alignItems='center'
      onClick={onClick}
      css={css`
        border-bottom: 1px solid ${colors.NEUTRAL_100};
        cursor: pointer;
        padding: 8px 8px;
        margin-bottom: 4px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.05);
        }

        .team-name-container {
          margin-left: 8px;
        }
      `}
    >
      <Flex alignItems='center'>
        <img
          src={
            getImageThumbnailId(data?.team) ||
            '/images/generic_placeholders/placeholder_team.png'
          }
          alt={data?.team?.team_name}
          css={css`
            width: 32px;
            height: 32px;
            border-radius: 8px;
            border: 1px solid ${colors.NEUTRAL_0};
            object-fit: contain;
          `}
          onError={(e) => {
            e.target.src = '/images/generic_placeholders/placeholder_team.png'
          }}
        />
        <div className='team-name-container'>
          <Text
            variant='paragraphSmall'
            color={colors.NEUTRAL_0}
            weight='semiBold'
          >
            {data?.team?.team_name}
          </Text>
          {data?.last_message?.text && (
            <Text
              variant='paragraphXSmall'
              color={colors.NEUTRAL_50}
              weight='regular'
              style={`
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -moz-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              `}
            >
              {loggedInUserId === data?.last_message?.user_id
                ? 'You'
                : data?.last_message?.user}
              : {data?.last_message?.text}
            </Text>
          )}
        </div>
      </Flex>
      {date && (
        <div
          css={css`
            padding-left: 8px;
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          <Text variant='paragraphXSmall' color={colors.NEUTRAL_40}>
            {date}
          </Text>
          {unreadMessages > 0 && (
            <div
              css={css`
                display: flex;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: ${colors.PRIMARY};
              `}
            />
          )}
        </div>
      )}
    </Flex>
  )
}

const ChatList = ({
  error,
  isReady,
  isTeamOrSchedulePage,
  setIsOpen,
  onTeamClick,
  loggedInUserId,
  loadedTeams,
}) => {
  const [loading, setLoading] = useState(true)
  const [data, setChatData] = useState([])

  useEffect(() => {
    req('/chat/teams')
      .then(({ data }) => {
        if (data.length > 0) {
          setChatData(data)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div
      css={css`
        position: fixed;
        top: ${isTeamOrSchedulePage ? 63 : 49}px;
        right: 88px;
        border-top-width: 0;
        border-radius: 8px;
        border: 1px solid ${colors.SOFT_STEEL};
        background-color: ${colors.SECONDARY_300};
        width: 328px;
        min-height: 200px;
        z-index: ${zIndex.header + 1};
        margin-top: 10px;
        max-height: 60vh;
        /* Bringing this back due to issues with a long list of chats */
        overflow-y: scroll;
        &::-webkit-scrollbar-thumb {
          background-color: ${colors.SOFT_STEEL};
        }
        ${media.mobile} {
          top: 47px;
          right: 0px;
        }
      `}
    >
      <Flex
        column
        css={css`
          padding: 16px 8px 8px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        `}
      >
        <Text
          variant='heading5'
          color={colors.NEUTRAL_0}
          weight='bold'
          style={`margin-left: 8px; margin-bottom: 16px`}
        >
          Chat
        </Text>
        {error ? (
          <div
            css={css`
              padding: 20px 40px;
              text-align: center;
              color: red;
            `}
          >
            There was a problem connecting to chat
          </div>
        ) : loading || !isReady ? (
          <LoadingSpinner
            size={2}
            borderSize={2}
            css={css`
              margin-bottom: 16px;
            `}
          />
        ) : data.length === 0 ? (
          <div
            css={css`
              padding: 20px 40px;
              text-align: center;
            `}
          >
            No team chats available
          </div>
        ) : (
          data.map((messageCtx) => {
            return (
              <ChatListItem
                key={messageCtx?.team?.id}
                id={messageCtx?.team?.id}
                onClick={() => {
                  setIsOpen(false)
                  onTeamClick(messageCtx)
                }}
                data={messageCtx}
                loggedInUserId={loggedInUserId}
                unreadMessages={messageCtx?.unread_messages}
              />
            )
          })
        )}
      </Flex>
    </div>
  )
}

export default ChatList
