/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import SortableTree, {
  changeNodeAtPath,
  removeNodeAtPath,
  toggleExpandedForAll,
} from '@nosferatu500/react-sortable-tree'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import { isValidUrl } from '@sportninja/common/utils/utils'
import deepEqual from 'fast-deep-equal'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import styled from '@emotion/styled/macro'
import Banner from 'src/components/Banner'
import BannerController from 'src/components/Banner/BannerController'
import Icon from '../../../components/Icon'
import { Flex } from '../../../components/Layout'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Picture from '../../../components/Picture'
import { Mobile } from '../../../components/Responsive'
import { font } from '../../../components/css'
import { AddChildButton, AddTeamButton, DeleteButton } from './Buttons'
import EditPanel from './EditPanel'
import TeamInfoPanel from './TeamInfoPanel'
import styles from './css'
import utils from './utils'
import { Alert, Space } from 'antd'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const BaseButton = styled.button`
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  transition: opacity 0.2s ease-in-out;

  color: white;
  font-size: 18px;

  i {
    transition: color 0.15s ease-in-out;
  }

  &:not([disabled]):hover {
    i {
      color: white;
    }
  }
`

const initialTreeDataState = [
  {
    id: 'New',
    initialState: true,
    title: '',
    subtitle: '',
    expanded: true,
  },
]

const ScheduleTree = ({
  isSoccer = false,
  id,
  orgId,
  schedule: parentSchedule,
  showSalesforceSettings,
  is_registration_on = false,
  sportId = null,
  genericSportId = null,
}) => {
  const editPanelRef = useRef(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchFocusIndex, setSearchFocusIndex] = useState(undefined)
  const [cursor, setCursor] = useState(null)
  const [loading, setLoading] = useState(true)
  const [treeData, setTreeData] = useState(initialTreeDataState)
  const [treeCopy, setTreeCopy] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [searchAllTeams, setSearchAllTeams] = useState(false)
  const [sourceRosterForCopy, setSourceRosterForCopy] = useState(false)
  const [isSubmissionPublic, setIsSubmissionPublic] = useState(true)
  const [selectedTeamData, setSelectedTeamData] = useState(null)
  const [finishedSubmitting, setFinishedSubmitting] = useState(false)

  const currentNode = useMemo(
    () => utils.getCurrentNode(treeData, cursor),
    [treeData, cursor]
  )

  const onDismissEditPanel = useCallback(() => {
    // In the case of a "new" node, i.e. one which does not exist
    // in the database yet, clicking "Cancel" in the edit panel
    // should remove the node from the local tree.
    if (currentNode && currentNode.id === 'New') {
      setTreeData((state) => {
        return removeNodeAtPath({
          treeData: state,
          path: cursor,
          getNodeKey: utils.getNodeKey,
        })
      })
    }
    if (currentNode && !currentNode.initialState) {
      setTimeout(() => {
        setCursor(null)
        setSearchQuery('')
      }, 0)
    }
  }, [cursor, currentNode])

  // Handler for the "zero state" of the tree, i.e. when no user-created nodes
  // exist, we'll show a placeholder starter root node.
  useEffect(() => {
    if (!loading) {
      if (treeData.length === 1 && treeData[0].id === 'New') {
        setTimeout(() => {
          setCursor(['New'])
          setSearchQuery('New')
        }, 0)
      } else if (treeData.length === 0) {
        setTreeData(initialTreeDataState)
      }
    }
  }, [loading, treeData])

  // Initial load handler. Make a request for the schedule and its children/teams,
  // then update the tree data.
  useEffect(() => {
    req(`/schedules/${id}`, { query: { children: true } }).then(({ data }) => {
      // Ensure that team and sub-competitions created in setup follow the privacy
      // settings of the parent organization of this competition
      if (data?.organization) {
        setIsSubmissionPublic(data.organization?.is_public)
      }

      const rootSchedule = { ...data }

      if (data?.children?.data?.length === 0 && data?.teams?.data?.length > 0) {
        rootSchedule.teams = data.teams.data
      }

      const result = utils.transformTree([rootSchedule], true)
      setTreeData(result)
      setLoading(false)
    })
  }, [setLoading])

  // Monolithic form handler for the edit panel - handles all the various cases:
  // add/edit/delete schedule, adding/delete team
  const onSubmit = async (formValues) => {
    const parent = utils.getCurrentNode(treeData, cursor.slice(0, -1))
    const parentId = parent ? parent.id : id
    // https://sportninja.atlassian.net/browse/SN-3118
    // We decided that if the user already has teams added,
    // then we should not allow them to add a team without
    // first adding a division. We wil block the button
    // from showing up in the UI to prevent new tournaments
    // from being created without a division.
    // const isTryingToAddTeamWithoutDivision =
    //   treeData[0]?.children[0]?.subtitle === 'Team'

    // if (isTryingToAddTeamWithoutDivision) {
    //   BannerController.add(({ ...props }) => (
    //     <Banner
    //       duration={undefined}
    //       onClose={undefined}
    //       onClick={undefined}
    //       subChildren={undefined}
    //       {...props}
    //       type='failed'
    //     >
    //       {'Must add a division before adding a team'}
    //     </Banner>
    //   ))
    //   setCursor(null)
    //   setSearchQuery('')
    //   setIsBusy(false)
    //   onDismissEditPanel()
    //   return
    // }

    let payload = {
      type: formValues.subtitle,
      name: formValues.title,
      name_full: formValues.title,
      starts_at: parentSchedule.starts_at,
      ends_at: parentSchedule.ends_at,
      sort_order: formValues?.sort_order,
      lock_roster_date:
        formValues.subtitle === 'Season'
          ? formValues?.lock_roster_date?.split('T')[0] || null
          : formValues?.lock_roster_date?.split('T')[0] ||
            // formValues?.starts_at ||
            null,
    }

    // If the lock_roster_date property doest exist on formValues, then we need to set it as the starts_at date
    // check if the property itself existis in the object (formValues)
    if (!utils.hasKey(formValues, 'lock_roster_date')) {
      payload.lock_roster_date = formValues?.starts_at
    }

    // here
    if (formValues.subtitle === 'Division' && showSalesforceSettings) {
      payload = {
        ...payload,
        day_of_week: formValues.day_of_week,
        deposit: formValues.deposit,
        division_age: formValues.division_age,
        division_gender: formValues.division_gender,
        division_group: formValues.division_group,
        team_cost: formValues.team_cost,
        is_active: formValues.is_active,
        season_type: formValues?.season_type,
        online_registration_open: formValues?.online_registration_open,
        season_currency: formValues?.season_currency,
        tax_rate: formValues?.tax_rate,
      }
    }

    if (utils.hasKey(formValues, 'game_cutoff')) {
      payload.settings = payload.settings || {}
      payload.settings.game_cutoff = parseInt(formValues.game_cutoff, 10)
    }

    const isTeamSubmission =
      utils.hasKey(formValues, 'selected_teams') ||
      utils.hasKey(formValues, 'teamName')
    let response
    let responses = []
    let teamId = formValues?.team_id

    // create a brand new team under the parent org of the current competition
    if (utils.hasKey(formValues, 'teamName')) {
      response = await req(`/schedules/${parentId}/teams`, {
        method: 'POST',
        body: JSON.stringify({
          name: formValues.teamName,
        }),
      })
    } else if (utils.hasKey(formValues, 'selected_teams')) {
      // Add one, or a series of teams that already exist elsewhere
      for (let teamId of formValues.selected_teams) {
        const body = {
          team_id: teamId,
        }
        if (sourceRosterForCopy && sourceRosterForCopy !== 'NEW') {
          body.duplicate_roster = true
          body.source_team_player_roster_id = sourceRosterForCopy
        }

        response = await req(`/schedules/${parentId}/teams`, {
          method: 'POST',
          body: JSON.stringify(body),
        })
        responses.push(response)
      }
    } else {
      // Edit a sub-competition
      if (currentNode.id !== 'New') {
        response = await req(`/schedules/${currentNode.id}`, {
          method: 'PUT',
          body: JSON.stringify(payload),
        })
      } else {
        // Create a new sub-competition
        response = await req(`/organizations/${orgId}/schedules`, {
          method: 'POST',
          body: JSON.stringify({
            ...payload,
            parent_id: parentId,
            is_public: isSubmissionPublic,
          }),
        })
      }
    }
    // if (false) {
    //   window.location.reload(false)
    // } else {
    const dataId = response.data.id
    const title = response.data.name_full
    const type = response.data.type
    const settings = response.data.settings
    const image = response.data.image
    setTreeData(
      changeNodeAtPath({
        treeData,
        path: cursor,
        newNode: ({ node }) => {
          const isTeamType = utils.isTeamNode(node) || isTeamSubmission
          const details = { ...node, id: dataId, title }

          if (type) {
            details.lock_roster_date = response?.data?.lock_roster_date
            details.sort_order = response?.data?.sort_order
            details.subtitle = type
            if (type === 'Division' && showSalesforceSettings) {
              details.day_of_week = response.data.day_of_week
              details.deposit = response.data.deposit
              details.division_age = response.data.division_age
              details.division_gender = response.data.division_gender
              details.division_group = response.data.division_group
              details.team_cost = response.data.team_cost
              details.is_active = response.data.is_active
              details.season_type = response?.data?.season_type
              details.online_registration_open =
                response?.data?.online_registration_open
              details.season_currency = response?.data?.season_currency
              details.tax_rate = response?.data?.tax_rate
            }
          }
          if (settings) {
            details.settings = settings
          }
          if (image) {
            details.images = image
          }
          if (isTeamType) {
            details.teamId = dataId
            details.scheduleId = parentId
            details.nodeType = 'Team'
            details.subtitle = 'Team'
          }
          delete details.initialState
          return details
        },
        getNodeKey: utils.getNodeKey,
      })
    )

    // https://sportninja.atlassian.net/browse/SN-3902
    // Request the new tree data from the server, so that we can get the
    // correct tree sort_order for the new node.
    if (type === 'Division' || type === 'Conference') {
      req(`/schedules/${id}`, { query: { children: true } })
        .then(({ data }) => {
          // Ensure that team and sub-competitions created in setup follow the privacy
          // settings of the parent organization of this competition
          if (data?.organization) {
            setIsSubmissionPublic(data.organization?.is_public)
          }

          const rootSchedule = { ...data }

          if (
            data?.children?.data?.length === 0 &&
            data?.teams?.data?.length > 0
          ) {
            rootSchedule.teams = data.teams.data
          }

          const result = utils.transformTree([rootSchedule], true)
          setTreeData(result)
          setLoading(false)
        })
        .finally(() => {
          setSearchQuery('')
        })
    } else {
      setSearchQuery('')
    }
    if (currentNode.id === 'New') {
      setCursor(null)
    }
    setFinishedSubmitting(true)
    BannerController.add(({ ...props }) => (
      <Banner
        duration={undefined}
        onClose={undefined}
        onClick={undefined}
        subChildren={undefined}
        {...props}
        type='success'
      >
        {currentNode.id !== 'New'
          ? `Updated ${type} successfully`
          : 'Added successfully'}
      </Banner>
    ))
  }

  const { lastTreeIndex, teams, compDepthCount, depthMetadata } =
    utils.getTreeInfo(treeData)

  const isEditing =
    currentNode &&
    Object.keys(currentNode).length > 0 &&
    (currentNode.id === 'New' || currentNode.nodeType !== 'Team')
  const isViewingTeam =
    currentNode &&
    Object.keys(currentNode).length > 0 &&
    currentNode.id !== 'New' &&
    currentNode.nodeType === 'Team'

  const [isExpanded, setIsExpanded] = useState(true)

  useEffect(() => {
    const fetchTeamInformation = async () => {
      try {
        const { data } = await req(`/teams/${currentNode?.teamId}`)
        setSelectedTeamData(data)
      } catch (err) {
        setSelectedTeamData(null)
      }
    }
    if (
      currentNode &&
      currentNode?.nodeType === 'Team' &&
      currentNode?.teamId
    ) {
      fetchTeamInformation()
    }
  }, [currentNode])

  return (
    <Mobile>
      {(isMobile) => {
        if (isMobile) {
          return (
            <div
              css={css`
                margin-top: 40px;
                text-align: center;
              `}
            >
              Sorry, but {t('common:competition')} setup is not available on
              mobile.
            </div>
          )
        }
        return (
          <div css={styles} className={isDragging ? 'is-dragging' : ''}>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                {is_registration_on && (
                  <Space
                    direction='vertical'
                    style={{ width: '100%', marginBottom: 16 }}
                  >
                    <Alert
                      description={`To add teams to your ${
                        isCanlan ? 'season' : 'competition'
                      } when registration is turned
                    ON, please use the registration tab.`}
                      type='warning'
                      showIcon
                    />
                  </Space>
                )}
                <Flex
                  css={css`
                    position: relative;
                    height: 100%;
                    max-height: 1080px !important;
                    pointer-events: ${isBusy ? 'none' : 'auto'};
                    transition: opacity 0.2s ease-in-out;
                    opacity: ${isBusy ? '0.5' : '1'};
                  `}
                >
                  <button
                    type='button'
                    onClick={() => {
                      const result = toggleExpandedForAll({
                        treeData,
                        expanded: !isExpanded,
                      })
                      setTreeData(result)
                      setIsExpanded(!isExpanded)
                    }}
                    css={css`
                      position: absolute;
                      top: 16px;
                      right: calc(50% + 16px);
                      ${font.body}
                      font-size: 12px;
                      color: white;
                      border: 1px solid ${colors.DEFAULT_FLAIR};
                      border-radius: 4px;
                      height: 32px;
                      padding: 0px 10px;
                      z-index: 1;
                      transition: border-color 0.15s ease-in-out;

                      &:hover {
                        border-color: white;
                      }
                    `}
                  >
                    <Icon
                      name={isExpanded ? 'compress-alt' : 'expand-alt'}
                      css={css`
                        margin-right: 6px;
                      `}
                    />
                    {isExpanded ? 'Collapse' : 'Expand'} All
                  </button>
                  <SortableTree
                    id='tree'
                    css={css`
                      .rst__rowTitle {
                        font-size: 12px;
                      }
                    `}
                    canDrag={({ node }) => {
                      return !isEditing && !loading && utils.isTeamNode(node)
                    }}
                    canDrop={({ prevParent, nextParent }) => {
                      return (
                        !isEditing &&
                        !loading &&
                        (nextParent && nextParent.subtitle) ===
                          (prevParent && prevParent.subtitle)
                      )
                    }}
                    canNodeHaveChildren={utils.canNodeHaveChildren}
                    searchFocusOffset={searchFocusIndex}
                    // searchFinishCallback={(matches) => {
                    //   console.log('search finished')
                    //   setTimeout(() => {
                    //     if (Array.isArray(cursor) && cursor.length > 0 && cursor[cursor.length - 1] === 'New') {
                    //       setSearchFocusIndex(matches.length > 0 ? searchFocusIndex % matches.length : 0)
                    //     }
                    //   }, 0)
                    // }}
                    searchQuery={searchQuery}
                    searchMethod={({ node, searchQuery }) => {
                      return node.id === searchQuery
                    }}
                    style={{ height: 'inherit', width: '50%' }}
                    treeData={treeData}
                    onChange={(treeData) => setTreeData(treeData)}
                    onDragStateChanged={({ isDragging }) => {
                      if (isDragging) {
                        setTreeCopy(treeData)
                      }
                      setIsDragging(isDragging)
                    }}
                    onMoveNode={async ({
                      treeData,
                      nextParentNode,
                      node,
                      prevPath,
                      nextPath,
                      prevTreeIndex,
                      nextTreeIndex,
                    }) => {
                      // If they have the same tree index, then user dropped it back in the
                      // same spot, so don't do anything
                      if (prevTreeIndex === nextTreeIndex) {
                        return
                      }

                      setIsBusy(true)
                      try {
                        const values = {
                          destination_schedule_id: nextParentNode.id,
                          // we need to have this defined even if there is no dest. team
                          seed_action: 'after',
                        }

                        const siblings = nextParentNode.children

                        if (siblings.length > 1) {
                          const nodeIndex = siblings.findIndex(
                            (s) => s.id === node.id
                          )
                          if (nodeIndex === 0) {
                            values.seed_action = 'before'
                            values.destination_team_id = siblings[1].teamId
                          } else if (nodeIndex === siblings.length - 1) {
                            values.seed_action = 'after'
                            values.destination_team_id =
                              siblings[siblings.length - 2].teamId
                          } else {
                            values.seed_action = 'before'
                            values.destination_team_id =
                              siblings[nodeIndex + 1].teamId
                          }
                        }

                        const response = await req(
                          `/schedules/${node.scheduleId}/teams/${
                            node.teamId || node.id
                          }/move`,
                          {
                            method: 'PUT',
                            body: JSON.stringify(values),
                          }
                        )

                        if (Array.isArray(response)) {
                          const sourceScheduleObject = response.find((item) => {
                            return item.find((i) => {
                              return i === 'source_teams'
                            })
                          })
                          const destScheduleObject = response.find((item) => {
                            return item.find((i) => {
                              return i === 'destination_teams'
                            })
                          })

                          const sourceTeams = sourceScheduleObject.find((i) => {
                            return Object.prototype.hasOwnProperty.call(
                              i,
                              'data'
                            )
                          })
                          const destTeams = destScheduleObject.find((i) => {
                            return Object.prototype.hasOwnProperty.call(
                              i,
                              'data'
                            )
                          })

                          let treeDataToUse = treeData
                          if (!deepEqual(prevPath, nextPath)) {
                            const sourceChanged = changeNodeAtPath({
                              treeData: treeDataToUse,
                              path: prevPath.slice(0, -1),
                              newNode: ({ node }) => {
                                const children = sourceTeams.data.map(
                                  (team) => {
                                    return utils.parseTeam(team, node.id)
                                  }
                                )
                                return { ...node, children }
                              },
                              getNodeKey: utils.getNodeKey,
                            })
                            treeDataToUse = sourceChanged
                          }
                          const destChanged = changeNodeAtPath({
                            treeData: treeDataToUse,
                            path: nextPath.slice(0, -1),
                            newNode: ({ node }) => {
                              const children = destTeams.data.map((team) => {
                                return utils.parseTeam(team, node.id)
                              })
                              return { ...node, children }
                            },
                            getNodeKey: utils.getNodeKey,
                          })
                          setTreeData(destChanged)
                        }
                      } catch (e) {
                        setTreeData(treeCopy)
                        // alert('There was a problem. Please try again.')
                        return BannerController.add(({ ...props }) => (
                          <Banner
                            duration={undefined}
                            onClose={undefined}
                            onClick={undefined}
                            subChildren={undefined}
                            {...props}
                            type='failed'
                          >
                            {e?.message
                              ? e.message
                              : 'An error happened (ERR: 1)'}
                          </Banner>
                        ))
                      } finally {
                        setIsBusy(false)
                        setTreeCopy([])
                      }
                    }}
                    getNodeKey={utils.getNodeKey}
                    generateNodeProps={({ node, path }) => {
                      const buttons = []
                      const isTeamNode = utils.isTeamNode(node)
                      const isNew = node.id === 'New'

                      const sharedProps = {
                        clear: onDismissEditPanel,
                        disabled: isNew,
                        depthMetadata,
                        node,
                        lastTreeIndex,
                        path,
                        scheduleId: id,
                        setCursor,
                        setSearchQuery,
                        setTreeData,
                      }

                      const hasChildren =
                        node.children && node.children.length > 0

                      if (!isDragging && !isNew) {
                        const hasTeams =
                          hasChildren && node.children[0].nodeType === 'Team'
                        const hasSubComps =
                          hasChildren && node.children[0].nodeType !== 'Team'
                        const currDepth = path.length
                        const isDivision = node.subtitle === 'Division'
                        const isConference = node.subtitle === 'Conference'

                        // If teams are at root, then show button on the last team
                        // node in the tree
                        if (
                          compDepthCount === 0 &&
                          isTeamNode &&
                          teams.findIndex((t) => t === node.id) ===
                            teams.length - 1
                        ) {
                          buttons.push(
                            <AddTeamButton
                              key='team-sibling'
                              isSibling
                              {...sharedProps}
                            />
                          )
                        }

                        // Add descendant competition button
                        if (
                          !isDivision &&
                          !hasTeams &&
                          !isTeamNode &&
                          (teams.length === 0 ||
                            (teams.length > 0 && currDepth < compDepthCount))
                        ) {
                          buttons.push(
                            <AddChildButton key='child' {...sharedProps} />
                          )
                        }

                        // https://sportninja.atlassian.net/browse/SN-3118
                        const isTryingToAddTeamWithoutDivision =
                          treeData[0]?.children?.length === 0

                        // Add team button
                        if (
                          !isConference &&
                          !hasSubComps &&
                          !isTeamNode &&
                          currDepth === compDepthCount &&
                          !isTryingToAddTeamWithoutDivision &&
                          !is_registration_on
                        ) {
                          buttons.push(
                            <AddTeamButton key='team' {...sharedProps} />
                          )
                        }

                        if (
                          node.subtitle !== 'Season' &&
                          !(isTeamNode && is_registration_on)
                        ) {
                          buttons.push(
                            <DeleteButton
                              key='delete'
                              setIsBusy={setIsBusy}
                              {...sharedProps}
                            />
                          )
                        }
                      }

                      if (isTeamNode) {
                        if (node.image) {
                          const isUrl = isValidUrl(node?.image)
                          buttons.push(
                            <Picture
                              fill={false}
                              square
                              fit
                              key='image'
                              imageId={
                                isUrl ? getImageThumbnailId(node) : node?.image
                              }
                              height='100%'
                              width='100%'
                              size='xxsmall'
                              iconName='user-friends'
                            />
                          )
                        }

                        if (parentSchedule?.is_tournament && node?.seed > 0) {
                          buttons.push(
                            <div
                              className='seed'
                              style={node.image && { marginLeft: 8 }}
                            >
                              <div className='title'>Seed</div>
                              <div className='value'>{node.seed}</div>
                            </div>
                          )
                        }
                      }

                      return {
                        className: isTeamNode ? 'is-team' : '',
                        onClick: (e) => {
                          // This is the handler for when you click on a node - we
                          // want to avoid handling clicks from the expand/collapse
                          // buttons, team nodes, and new nodes
                          if (
                            e.target instanceof HTMLButtonElement ||
                            // isTeamNode ||
                            node.id === 'New'
                          ) {
                            return
                          }
                          e.stopPropagation()

                          // If the last state of the cursor was a "new node" - then
                          // make sure it's deleted.
                          if (
                            Array.isArray(cursor) &&
                            cursor[cursor.length - 1] === 'New'
                          ) {
                            setTreeData((state) => {
                              return removeNodeAtPath({
                                treeData: state,
                                path: cursor,
                                getNodeKey: utils.getNodeKey,
                              })
                            })
                          }

                          setTimeout(() => {
                            setCursor(path)
                            setSearchQuery(node.id)
                          })
                        },
                        buttons,
                      }
                    }}
                  />
                  <div
                    ref={editPanelRef}
                    css={css`
                      width: 50%;
                      height: 100vh;
                      align-self: flex-start;
                      max-height: 1080px !important;
                    `}
                  >
                    {isViewingTeam && currentNode?.teamId && (
                      <TeamInfoPanel
                        teamId={currentNode?.teamId}
                        title={currentNode?.title}
                        seed={currentNode?.seed}
                        selectedTeamData={selectedTeamData}
                        scheduleId={id}
                      />
                    )}
                    {isEditing && (
                      <EditPanel
                        cursor={cursor}
                        depthMetadata={depthMetadata}
                        disableEditing={currentNode.id === id}
                        node={currentNode}
                        onCancel={onDismissEditPanel}
                        onSubmit={onSubmit}
                        parentOrgId={parentSchedule.organization.id}
                        showMinGameCutoff={!parentSchedule.is_tournament}
                        setTreeData={setTreeData}
                        scheduleId={parentSchedule.id}
                        showSalesforceSettings={showSalesforceSettings}
                        teams={teams}
                        searchAllTeams={searchAllTeams}
                        setSearchAllTeams={setSearchAllTeams}
                        sourceRosterForCopy={sourceRosterForCopy}
                        setSourceRosterForCopy={setSourceRosterForCopy}
                        treeData={treeData}
                        isSoccer={isSoccer}
                        sportId={sportId}
                        genericSportId={genericSportId}
                        finishedSubmitting={finishedSubmitting}
                        setFinishedSubmitting={setFinishedSubmitting}
                      />
                    )}
                  </div>
                </Flex>
              </>
            )}
          </div>
        )
      }}
    </Mobile>
  )
}

export default ScheduleTree
