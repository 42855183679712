/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { observer } from 'mobx-react-lite'
import { useMobxStore } from 'src/state'
import bannerHead from './banner_head.png'
import colors from '@sportninja/common/constants/appColors'
import { Button } from '../Button'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import Icon from '../Icon'
import { BOTTOM_TAB_BAR_HEIGHT } from '../BottomTabBar'
import useWindowSize from 'src/hooks/useWindowSize'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

export const DownloadApp = observer(
  ({ isLoggedIn }: { isLoggedIn: boolean }) => {
    const { width } = useWindowSize()
    const isMobile = useMemo(() => {
      return width <= 768
    }, [width])
    const [isOnRegistrationsPage, setIsOnRegistrationsPage] = useState(false)
    const history = useHistory()

    useEffect(() => {
      if (!history) {
        return
      }
      // Handles the initial path
      if (history.location.pathname.includes('registrations')) {
        setIsOnRegistrationsPage(true)
      }
      // Handles the path changes
      history.listen((location) => {
        setIsOnRegistrationsPage(location.pathname.includes('registrations'))
      })
    }, [history])

    const {
      appStore: {
        state: { showDownloadBanner },
        setDownloadBannerSize,
        setDownloadBannerVisibility,
      },
    } = useMobxStore()

    const handleOpenBanner = () => {
      const userAgent = navigator.userAgent
      if (/iPhone|iPad|iPod/.test(userAgent)) {
        window.location.href =
          'https://apps.apple.com/br/app/sportninja/id1436064312'
      } else if (/Android/.test(userAgent)) {
        window.location.href =
          'https://play.google.com/store/apps/details?id=com.sportninjainc.sportninja'
      }
    }

    if (
      isCanlan ||
      !showDownloadBanner.isBannerVisible ||
      !isMobile ||
      isOnRegistrationsPage
    ) {
      return <div />
    }

    if (showDownloadBanner.isBig) {
      return (
        <div
          css={css`
            position: fixed;
            bottom: ${isLoggedIn ? BOTTOM_TAB_BAR_HEIGHT : '0px'};
            width: 100vw;
            height: 100vh;
            z-index: 1100;
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              flex: 1;
              width: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              backdrop-filter: blur(5px);
            `}
          />
          <div
            css={css`
              flex: 1;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              position: relative;
            `}
          >
            <button
              css={css`
                position: absolute;
                top: 16px;
                right: 16px;
                z-index: 1002;
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
              `}
              onClick={(e) => {
                e.preventDefault()
                setDownloadBannerSize('small')
              }}
            >
              <Icon name='times' color='white' fontSize={18} />
            </button>
            <div
              css={css`
                display: flex;
                flex: 1.2;
                overflow: hidden;
              `}
            >
              <img
                src={bannerHead}
                css={css`
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                `}
              />
            </div>
            <div
              css={css`
                flex: 1;
                background-color: ${colors.SOFT_STEEL};
                text-align: center;
                padding: 16px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                // gap: 16px;
              `}
            >
              <p
                css={css`
                  font-family: Rift, Arial, Helvetica, sans-serif;
                  color: white;
                  font-size: 28px;
                  font-weight: 600;
                `}
              >
                this looks better in the app
              </p>
              <p
                css={css`
                  font-size: 18px;
                  color: white;
                `}
              >
                Set attendance, customize your profile, check stats, standings,
                results and more.
              </p>
              <Button label='GET APP' onClick={handleOpenBanner} />
            </div>
          </div>
        </div>
      )
    }

    if (showDownloadBanner.isSmall) {
      return (
        <div
          css={css`
            position: fixed;
            bottom: ${isLoggedIn ? BOTTOM_TAB_BAR_HEIGHT : '0px'};
            width: 100vw;
            z-index: 1002;
          `}
        >
          <div
            css={css`
              background-color: ${colors.SOFT_STEEL};
              padding: 16px;
              display: flex;
              width: 100%;
              gap: 8px;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <div
              css={css`
                flex: 1.5;
              `}
            >
              <p
                css={css`
                  font-size: 16px;
                  color: white;
                  text-align: center;
                `}
              >
                This looks better in the app.
              </p>
            </div>
            <div
              css={css`
                flex: 1;
              `}
            >
              <Button label='GET APP' onClick={handleOpenBanner} />
            </div>
            <div
              css={css`
                flex: 0.3;
              `}
            >
              <button
                css={css`
                  background-color: transparent;
                  border: none;
                  outline: none;
                  cursor: pointer;
                  width: 100%;
                `}
                onClick={(e) => {
                  e.preventDefault()
                  setDownloadBannerSize('small')
                  setDownloadBannerVisibility(false)
                }}
              >
                <Icon name='times' color='white' fontSize={18} />
              </button>
            </div>
          </div>
        </div>
      )
    }
  }
)
