import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import TextModal from '../Modal/TextModal'

const ImagePicker = ({ disabled, onComplete, customId = null }) => {
  const [error, setError] = useState(false)

  const onFileSelect = ({ target }) => {
    if (!target || !target.files || target.files.length === 0) {
      return
    }

    const file = target.files[0]

    if (file.size > 5000000) {
      return setError(true)
    }

    let reader = new FileReader()

    reader.onloadend = ({ target }) => {
      const imageData = target.result
      if (!imageData) {
        return
      }
      onComplete(imageData, file.name)
    }

    reader.readAsDataURL(file)
  }

  return (
    <>
      <TextModal
        isOpen={error}
        title='Image is too large'
        toggle={() => setError(false)}
      >
        That image is too large. Try uploading an image under 5 MB.
      </TextModal>
      <label
        className={cn('flex', { 'is--clickable': !disabled })}
        htmlFor={customId ? customId : 'add-image'}
      >
        <i className='fas fa-camera' />
      </label>
      <input
        accept='image/*'
        id={customId ? customId : 'add-image'}
        name='file'
        onChange={onFileSelect}
        type='file'
        disabled={disabled}
      />
    </>
  )
}

ImagePicker.propTypes = {
  disabled: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
}

export default ImagePicker
