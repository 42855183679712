/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import Text from '../Text'

type ImageUploaderProps = {
  label: string
  tip: string
  imageUrl: string
  setImageUrl: (url: string) => void
  messageApi?: any
  setImageChanged?: (changed: boolean) => void
}

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  if (img) {
    reader.readAsDataURL(img)
  }
}

const ImageUploader = ({
  label,
  tip,
  imageUrl,
  setImageUrl,
  messageApi,
  setImageChanged = () => {},
}: ImageUploaderProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      messageApi?.open({
        type: 'error',
        content: 'You can only upload JPG/PNG file!',
        duration: 5,
      })
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      messageApi?.open({
        type: 'error',
        content: 'Image must smaller than 2MB!',
        duration: 5,
      })
    }
    return isJpgOrPng && isLt2M
  }

  const handleChange = (info) => {
    try {
      setIsLoading(true)
      getBase64(info.file.originFileObj, (url) => {
        setIsLoading(false)
        setImageUrl(url)
        setImageChanged(true)
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 4px;
        `}
      >
        <Text variant='paragraphSmall' weight='regular' color={colors.WHITE}>
          {label}
        </Text>
        <Text
          variant='paragraphXSmall'
          weight='regular'
          color={colors.NEUTRAL_100}
        >
          {tip}
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          width: 100%;
          height: 40px;
        `}
      >
        <Upload
          onDrop={handleChange}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          showUploadList={false}
          action={undefined}
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            width: 100%;
            ${isLoading &&
            css`
              cursor: not-allowed;
              opacity: 0.7;
            `}
            cursor: pointer;
            .ant-upload {
              width: 100%;
              align-self: stretch;
            }
            .ant-upload-wrapper {
              gap: 0;
            }
          `}
          // {...props}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <div
              css={css`
                display: flex;
                padding: 8px 16px;
                justify-content: space-between;
                align-items: center;
                flex: 1 0 0;
                border-radius: 8px;
                border: 1px solid ${colors.SECONDARY_100};
                background: ${colors.SECONDARY_200};
                width: 100%;
                height: 40px;
                align-self: stretch;
              `}
            >
              <Text
                variant='paragraphSmall'
                weight='regular'
                color={colors.WHITE}
              >
                {imageUrl ? 'Change Image' : 'Select an Image'}
              </Text>
              <UploadOutlined
                css={css`
                  font-size: 24px;
                  color: ${colors.PRIMARY};
                `}
              />
            </div>
          </div>
        </Upload>
        {imageUrl && (
          <DeleteOutlined
            css={css`
              font-size: 24px;
              color: ${colors.ERROR_LIGHT};
              cursor: pointer;
            `}
            onClick={() => {
              setImageUrl('')
              setImageChanged(true)
            }}
          />
        )}
      </div>
      <img
        src={imageUrl}
        alt='Uploaded image'
        css={css`
          display: ${imageUrl !== '' ? 'block' : 'none'};
          margin-top: 16px;
          background: ${colors.NEUTRAL_0};
          object-fit: contain;
          width: 100%;
          max-height: 200px;
        `}
      />
    </div>
  )
}

export default ImageUploader
