/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import Text from '../Text'
import colors from '@sportninja/common/constants/appColors'
import { Select } from 'antd'
import { paragraphSmall } from 'src/components/css'
import { ErrorLabel } from '../ErrorLabel'

type SelectMultiInputProps = {
  label: string
  options: { label: string; value: string }[]
  value: string[]
  onChange: (value: string[]) => void
  fieldErrors: string[]
  customError: any
  name: string
}

const SelectMultiInput = ({
  label,
  options,
  value,
  onChange,
  fieldErrors,
  customError,
  name,
}: SelectMultiInputProps) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
      `}
    >
      <Text variant='paragraphSmall' weight='regular' color={colors.WHITE}>
        {label}
      </Text>
      <Select
        mode='multiple'
        options={options}
        value={value}
        onChange={onChange}
        css={css`
          width: 100%;
          .ant-select-selector {
            /* padding: 8px 8px 8px 8px !important; */
          }
          .ant-select-selection-item-content {
            ${paragraphSmall}
            align-self: center;
            color: ${colors.SECONDARY_300};
          }
          .ant-select-selection-item {
            background-color: ${colors.PRIMARY}!important;
            padding: 4px 8px !important;
          }
          .ant-select-selection-item-remove {
            color: ${colors.SECONDARY_300}!important;
          }
        `}
        status={fieldErrors[name] || fieldErrors?.includes(name) ? 'error' : ''}
      />
      <ErrorLabel
        fieldErrors={fieldErrors || []}
        customError={customError || ''}
        name={name}
      />
    </div>
  )
}

export default SelectMultiInput
