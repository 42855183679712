/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Text from 'src/components/ts-components/Text'
import Button from 'src/components/ts-components/Button'
import { Checkbox } from 'antd'

/**
 * Props for the WaiverStep component
 * @typedef {Object} WaiverStepProps
 * @property {Object} data - The invitation data containing waiver content
 * @property {Function} onNext - Function to call when proceeding to next step
 * @property {Function} onBack - Function to go back to previous step
 * @property {boolean} acceptWaiver - Whether the user has accepted the waiver
 * @property {Function} setAcceptWaiver - Function to set waiver acceptance state
 * @property {Array<number>} stepState - Array tracking the step history
 */
type WaiverStepProps = {
  data: any
  onNext: () => void
  onBack?: () => void
  acceptWaiver: boolean
  setAcceptWaiver: (value: boolean) => void
  stepState: number[]
}

/**
 * WaiverStep component for the underage invitation flow
 * Displays the waiver content and requires user acceptance before proceeding
 *
 * @param {WaiverStepProps} props - Component props
 * @returns {JSX.Element} The rendered component
 */
const WaiverStep = ({
  data,
  onNext,
  onBack,
  acceptWaiver,
  setAcceptWaiver,
  stepState,
}: WaiverStepProps) => {
  return (
    <div
      css={css`
        padding-top: 32px;
      `}
    >
      <Text variant='displaySmall' weight='bold' color={colors.NEUTRAL_0}>
        Participant Agreement
      </Text>
      <Text
        variant='paragraphMedium'
        weight='regular'
        color={colors.NEUTRAL_0}
        style={`
          margin-top: 16px;
        `}
      >
        Before joining, please read and review the following waiver:
      </Text>
      <div
        css={css`
          margin-top: 16px;
          height: 50vh;
          background-color: #27303e;
          border-radius: 8px;
          border-color: ${colors.SOFT_STEEL};
          overflow-y: auto;
          padding: 20px;

          font-size: 14px;
          letter-spacing: 1.6px;

          &::-webkit-scrollbar {
            width: 0.8em;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          }

          &::-webkit-scrollbar-thumb {
            background-color: ${colors.SOFT_STEEL};
          }

          p {
            margin: 8px 0;
          }

          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        {data?.waiver_content ? (
          <div
            dangerouslySetInnerHTML={{
              __html: data?.waiver_content?.replace(/\n/g, '<br />'),
            }}
          />
        ) : null}
      </div>
      <div
        css={css`
          margin-top: 16px;
        `}
      >
        <Checkbox
          checked={acceptWaiver}
          onChange={(e) => setAcceptWaiver(e.target.checked)}
        >
          I have read and agree to the terms and conditions outlined in the
          waiver.
        </Checkbox>
      </div>
      <div
        css={css`
          margin-top: 16px;
          display: flex;
          gap: 16px;

          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        {stepState.length > 0 && onBack && (
          <Button text='Back' variant='secondary' onClick={onBack} />
        )}
        <Button
          text='Next'
          variant='primary'
          onClick={onNext}
          disabled={!acceptWaiver}
        />
      </div>
    </div>
  )
}

export default WaiverStep
