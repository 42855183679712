/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { formatCurrency } from '@sportninja/common/utils/utils'
import type { TableColumnsType } from 'antd'
import { Tag, Dropdown, Badge, Tooltip } from 'antd'
import { memo, useCallback, useMemo, useState } from 'react'
import { SelectInput } from '../../SelectInput'
import dayjs from 'dayjs'
import {
  InfoCircleOutlined,
  MoreOutlined,
  PlusCircleFilled,
  TagOutlined,
} from '@ant-design/icons'
import { RegistrationTableDivision } from 'src/pages/Schedule/PlayerRegistration/helpers'
import { formatShortDate } from '@sportninja/common/ts-utils/date-utils'
import useWindowSize from 'src/hooks/useWindowSize'
import { Link } from 'react-router-dom'

const registrationOptions = [
  {
    label: 'Submitted',
    value: 1,
  },
  {
    label: 'Pending',
    value: 2,
  },
  {
    label: 'Approved',
    value: 3,
  },
  {
    label: 'Approved w/ Conditions',
    value: 4,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsSubmitted = [
  {
    label: 'Submitted',
    value: 1,
  },
  {
    label: 'Approved',
    value: 3,
  },
  {
    label: 'Approved w/ Conditions',
    value: 4,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsPending = [
  {
    label: 'Pending',
    value: 2,
  },
  {
    label: 'Approved',
    value: 3,
  },
  {
    label: 'Approved w/ Conditions',
    value: 4,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsApproved = [
  {
    label: 'Approved',
    value: 3,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsApprovedWithConditions = [
  {
    label: 'Approved w/ Conditions',
    value: 4,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsCancelled = [
  {
    label: 'Cancelled',
    value: 5,
  },
]

/** Team data interface */
interface Team {
  id: string
  team_roster_id?: string
  uid?: string
  team_id: string
  name_full: string
  players_count: number
  division_id: string
  is_comp: boolean
  is_bucket: boolean
  team_registration_id: string
  registration_status: number
  billing_schedule: BillingScheduleItem[]
  schedules: Schedule[]
  is_coupon: boolean
  coupon?: {
    code: string
    discount_type: string
    discount: number
    discount_amount: number
  }
  total_paid: number
  total_due: number
  registered_at: string
}

/** Billing schedule item interface */
interface BillingScheduleItem {
  uid: string
  user_type: 'player' | 'teamRep'
  user_id: string
  team_official_id: string
  payment_type: 'deposit' | 'installment'
  due_date: string
  subtotal: number
  taxtotal: number
  total: number
  paid: boolean
  currency: number
  charge?: {
    id: number
    stripe_id: string
    description: string
    paid_at: string
    amount: string
    paid: number
    refunded: number
  }
  single_payment: boolean
  display_subtotal: number
  refunded: boolean
}

/** Schedule interface */
interface Schedule {
  name: string
}

/** Props for the table component */
interface TableProps {
  setDivision: (division: RegistrationTableDivision) => void
  setDivisionId: (id: string) => void
  setParentTeamRegistrationId: (id: string) => void
  setTeamId: (id: string) => void
  setTeamName: (name: string) => void
  divisions: RegistrationTableDivision[]
  scheduleId: string
  handleStatusChange: (status: number, id: string, record: Team) => void
  loadingTeamIds: string[]
  setIsRegistrationDetailsModalOpen: (isOpen: boolean) => void
  setRegistrationDetailId: (id: string) => void
  setBucketToEdit: (bucket: any) => void
  setBucketToDelete: (bucket: any) => void
  setIsBucketModalOpen: (isOpen: boolean) => void
  setBucketScheduleRegistrationId: (id: string) => void
  setIsPlayerAdminModalOpen: (isOpen: boolean) => void
  setScheduleRegistrationId: (id: string) => void
}

/**
 * Memoized team status selector component
 */
const TeamStatusSelector = memo(
  ({
    status,
    teamId,
    onChange,
    disabled,
    options,
    isBucket = false,
  }: {
    status: number
    teamId: string
    onChange: (status: number, id: string) => void
    disabled: boolean
    options: { label: string; value: number }[]
    isBucket?: boolean
  }) => {
    // Get color based on status
    const getStatusColor = (s: number) => {
      switch (s) {
        case 1: // Submitted
          return colors.INFO_200
        case 2: // Pending
          return colors.WARNING_200
        case 3: // Approved
          return colors.SUCCESS_200
        case 4: // Approved w/ Conditions
          return colors.SUCCESS_200
        case 5: // Cancelled
          return colors.ERROR_200
        default:
          return colors.NEUTRAL_100
      }
    }

    const optionsWithBadge = options.map((opt) => ({
      ...opt,
      label: (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <Badge
            color={getStatusColor(opt.value)}
            css={css`
              .ant-badge-status-dot {
                width: 8px;
                height: 8px;
              }
            `}
          />
          {opt.label}
        </div>
      ),
      value: opt.value, // Ensure value is passed through
    }))

    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
        `}
      >
        <SelectInput
          options={optionsWithBadge}
          // @ts-ignore
          value={status}
          onChange={(value) => onChange(Number(value), teamId)}
          label=''
          disabled={disabled}
          name='teamStatus'
          fieldErrors={[]}
          customError={{}}
          style={`
            width: 200px;
            @media (max-width: 768px) {
              width: 100%;
            }
            height: 32px !important;
            // if is bucket, set the opacity to 0 and hide the dropdown
            // remove the cursor and pointer events
            ${isBucket && 'opacity: 0; cursor: default; pointer-events: none;'}
          `}
        />
      </div>
    )
  }
)

TeamStatusSelector.displayName = 'TeamStatusSelector'

/**
 * Memoized payment status component
 */
const PaymentStatus = memo(
  ({
    totalPaid,
    hasPaymentDelayed,
    currency,
    hasCoupon,
    code,
    discountText,
    discount_type,
  }: {
    totalPaid: number
    hasPaymentDelayed: boolean
    currency: string
    hasCoupon: boolean
    code: string
    discountText: string
    discount_type: string
  }) => (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
      `}
    >
      {hasCoupon ? (
        <Tooltip
          title={`Coupon: ${code} - ${discountText} ${
            discount_type === 'percentage' ? 'off' : 'discount'
          }`}
        >
          <TagOutlined
            style={{ color: colors.PRIMARY, fontSize: 20 }}
            color={colors.PRIMARY}
          />
        </Tooltip>
      ) : null}
      <span
        css={css`
          color: ${totalPaid === 0
            ? colors.NEUTRAL_100
            : hasPaymentDelayed
            ? colors.ERROR_LIGHT
            : colors.SUCCESS_GREEN};
        `}
      >
        {formatCurrency(totalPaid, currency)}
      </span>
    </div>
  )
)

PaymentStatus.displayName = 'PaymentStatus'

/**
 * Error boundary component for image loading
 */
const TeamImage = ({
  src,
  alt,
  className,
  isBucket = false,
}: {
  src: string
  alt: string
  className?: string
  isBucket?: boolean
}) => {
  const [error, setError] = useState(false)

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: center;
      `}
    >
      <img
        css={css`
          width: 32px;
          height: 32px;
          background: ${colors.WHITE};
          border-radius: 8px;
          padding: ${!isBucket ? '2px' : '0'};
          object-fit: contain;
        `}
        src={
          error || !src
            ? '/images/generic_placeholders/placeholder_team.png'
            : src
        }
        alt={alt}
        className={className}
        onError={(e) => {
          // Cast event target to HTMLImageElement
          const img = e.target as HTMLImageElement
          // Clear the error handler to prevent infinite loops
          img.onerror = null
          img.src = '/images/generic_placeholders/placeholder_team.png'
          setError(true)
        }}
      />
    </div>
  )
}

/**
 * Enhanced billing schedule renderer with error handling
 */
const SafeBillingRenderer = ({
  billingSchedule,
  division,
  isComplimentary,
  isBucket = false,
}: {
  billingSchedule?: BillingScheduleItem[]
  division?: RegistrationTableDivision
  isComplimentary: boolean
  isBucket?: boolean
}) => {
  if (!division?.currency?.name) {
    return <span>Currency not available</span>
  }
  if (!billingSchedule?.length) {
    return (
      <span
        css={css`
          opacity: 0;
        `}
      >
        Feb 14, 2025
      </span>
    )
  }

  if (isBucket) {
    return (
      <p
        css={css`
          opacity: 0;
        `}
      >
        Unassigned Group
      </p>
    )
  }

  return (
    <BillingRenderer
      billingSchedule={billingSchedule}
      division={division}
      isComplimentary={isComplimentary}
    />
  )
}

/**
 * Renders the team column with logo and name
 */
const TeamColumnRenderer = ({
  record,
  text,
  isBucket,
  scheduleId,
}: {
  record: Team
  text: string
  isBucket: boolean
  scheduleId: string
}) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: center;
      `}
    >
      <TeamImage
        src={
          isBucket ? '/images/bucket_image.png' : getImageThumbnailId(record)
        }
        alt={`${text} team logo`}
        className='team-logo'
        isBucket={isBucket}
      />
      {isBucket ? (
        <span>{text}</span>
      ) : (
        <Link
          to={`/team/${record?.team_id}/roster?sn_schedule=${scheduleId}`}
          css={css`
            &:hover {
              text-decoration: underline;
            }
          `}
        >
          {text}
        </Link>
      )}
    </div>
  )
}

/**
 * Renders the billing information with currency formatting
 */
const BillingRenderer = ({
  billingSchedule,
  isComplimentary,
}: {
  billingSchedule: BillingScheduleItem[]
  division?: RegistrationTableDivision
  isComplimentary: boolean
}) => {
  if (isComplimentary) {
    return <span>{'Complimentary'}</span>
  }
  const nextInstallmentToPay = billingSchedule?.find((item) => {
    return item.paid === false
  })
  const due = nextInstallmentToPay?.due_date
  const day = dayjs(due, 'YYYY-MM-DD')
  const isAfter = dayjs().isAfter(day)

  if (!nextInstallmentToPay) {
    return <span>{'-'}</span>
  }

  return (
    <span
      css={css`
        ${isAfter && `color: ${colors.ERROR_200};`}
      `}
    >
      {formatShortDate(day.format('YYYY-MM-DD'))}{' '}
    </span>
  )
}

/**
 * Creates the table columns configuration
 */
export const useTableColumns = ({
  divisions,
  scheduleId,
  handleStatusChange,
  loadingTeamIds,
  setIsRegistrationDetailsModalOpen,
  setRegistrationDetailId,
  setBucketToEdit,
  setBucketToDelete,
  setIsBucketModalOpen,
  setBucketScheduleRegistrationId,
  setIsPlayerAdminModalOpen,
  setParentTeamRegistrationId,
  setTeamId,
  setDivisionId,
  setScheduleRegistrationId,
  setTeamName,
  setDivision,
}: TableProps): TableColumnsType<Team> => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < 768, [width])

  const getRegistrationOptions = useCallback((status) => {
    switch (status) {
      case 1:
        return registrationOptionsSubmitted
      case 2:
        return registrationOptionsPending
      case 3:
        return registrationOptionsApproved
      case 4:
        return registrationOptionsApprovedWithConditions
      case 5:
        return registrationOptionsCancelled
      default:
        return registrationOptions
    }
  }, [])

  // @ts-ignore
  return useMemo(
    () => [
      {
        title: 'Team',
        dataIndex: 'name_full',
        key: 'name_full',
        fixed: true,
        render: (text: string, record: Team) => (
          <TeamColumnRenderer
            record={record}
            text={text}
            isBucket={record.is_bucket}
            scheduleId={scheduleId}
          />
        ),
        filterMode: 'menu',
        filters: [],
        onFilter: (value, record: Team) =>
          String(record.name_full) === String(value),
        filterSearch: true,
        sorter: (a: Team, b: Team) => a.name_full.localeCompare(b.name_full),
      },
      {
        title: 'Players',
        dataIndex: 'players_count',
        key: 'players_count',
        render: (_: unknown, record: Team) => {
          const division = divisions?.find((d) => d.id === record.division_id)
          if (record?.is_bucket) {
            if (division?.registration?.max_players_per_bucket) {
              return (
                <p>
                  {record?.players_count || 0}{' '}
                  {division?.registration?.max_players_per_bucket
                    ? ` / ${division?.registration?.max_players_per_bucket}`
                    : ''}
                </p>
              )
            }
            return <p>{record?.players_count || 0}</p>
          }
          return (
            <p>
              {record?.players_count || 0}
              {division?.maxPlayers ? ` / ${division.maxPlayers}` : ''}
            </p>
          )
        },
        filters: [
          { text: '0-5 players', value: '0-5' },
          { text: '6-10 players', value: '6-10' },
          { text: '11-15 players', value: '11-15' },
          { text: '16+ players', value: '16+' },
        ],
        onFilter: (value, record: Team) => {
          const count = record.players_count || 0
          const [min, max] = String(value).split('-').map(Number)
          if (max) {
            return count >= min && count <= max
          }
          return count >= min
        },
      },
      // {
      //   title: 'Curr. Div.',
      //   dataIndex: 'schedules',
      //   key: 'schedules',
      //   render: (_schedules: any, record: Team) => {
      //     const division = divisions?.find((s) => s.id === record?.division_id)
      //     if (record.is_bucket) {
      //       return (
      //         <p
      //           css={css`
      //             opacity: 0;
      //           `}
      //         >
      //           Division 1
      //         </p>
      //       )
      //     }
      //     return <p>{division?.name || '-'}</p>
      //   },
      // },
      {
        title: 'Registered At',
        dataIndex: 'registered_at',
        key: 'registered_at',
        sorter: (a: Team, b: Team) => {
          const dateA = a.registered_at
            ? new Date(a.registered_at).getTime()
            : 0
          const dateB = b.registered_at
            ? new Date(b.registered_at).getTime()
            : 0
          return dateA - dateB
        },
        render: (registeredAt: string, record: Team) => {
          if (record.is_bucket) {
            return (
              <p
                css={css`
                  opacity: 0;
                `}
              >
                -
              </p>
            )
          }

          if (!registeredAt) {
            return <p>-</p>
          }

          return <p>{formatShortDate(registeredAt)}</p>
        },
      },
      {
        title: 'Due',
        dataIndex: 'billing_schedule',
        key: 'billing_schedule',
        render: (billingSchedule: BillingScheduleItem[], record: Team) => {
          return (
            <SafeBillingRenderer
              billingSchedule={billingSchedule}
              isComplimentary={record.is_comp}
              division={divisions.find((d) => d.id === record.division_id)}
              isBucket={record.is_bucket}
            />
          )
        },
      },
      // {
      //   title: 'Coupon',
      //   dataIndex: 'is_coupon',
      //   key: 'is_coupon',
      //   render: (_: unknown, record: Team) => {
      //     if (!record.is_coupon) {
      //       return <Tag color='red'>No</Tag>
      //     }

      //     const { code, discount_type, discount, discount_amount } =
      //       record.coupon || {}
      //     const discountText =
      //       discount_type === 'percentage'
      //         ? `${discount}%`
      //         : formatCurrency(discount_amount || 0, 'USD')

      //     return (
      //       <Tooltip
      //         title={
      //           record.coupon
      //             ? `Code: ${code} - ${discountText} ${
      //                 discount_type === 'percentage' ? 'off' : 'discount'
      //               }`
      //             : ''
      //         }
      //       >
      //         <Tag color={record.is_coupon ? 'green' : 'red'}>
      //           {record.is_coupon ? 'Yes' : 'No'}
      //         </Tag>
      //       </Tooltip>
      //     )
      //   },
      //   filters: [
      //     { text: 'Has Coupon', value: true },
      //     { text: 'No Coupon', value: false },
      //   ],
      //   onFilter: (value, record: Team) => record.is_coupon === value,
      // },
      {
        title: 'Dep. Paid',
        dataIndex: 'billing_schedule',
        key: 'deposit_paid',
        render: (billingSchedule: BillingScheduleItem[], record: Team) => {
          if (record.is_comp || billingSchedule?.length === 0) {
            return (
              <Tag
                color={'green'}
                css={css`
                  opacity: 0;
                `}
              >
                Yes
              </Tag>
            )
          }

          const deposit = billingSchedule
            ?.filter((item) => item.user_type === 'teamRep')
            .find((item) => item.payment_type === 'deposit')

          if (!deposit) {
            return null
          }

          return (
            <Tag color={deposit?.paid ? 'green' : 'red'}>
              {deposit?.paid ? 'Yes' : 'No'}
            </Tag>
          )
        },
        filters: [
          { text: 'Paid', value: 'true' },
          { text: 'Not Paid', value: 'false' },
        ],
        onFilter: (value, record: Team) => {
          if (record.is_comp || !record.billing_schedule?.length) {
            return false
          }
          const deposit = record.billing_schedule
            ?.filter((item) => item.user_type === 'teamRep')
            .find((item) => item.payment_type === 'deposit')
          return String(deposit?.paid) === value
        },
      },
      {
        title: 'Outstanding',
        dataIndex: 'billing_schedule',
        key: 'outstanding',
        align: 'right',
        render: (billingSchedule: BillingScheduleItem[], record: Team) => {
          const division = divisions.find((d) => d.id === record?.division_id)
          if (record.is_bucket) {
            return (
              <span
                css={css`
                  opacity: 0;
                `}
              >
                $775.00 USD
              </span>
            )
          }
          if (record.is_comp) {
            return <span>{formatCurrency(0, division?.currency?.name)}</span>
          }
          const total =
            billingSchedule
              ?.filter((item) => item.user_type === 'teamRep')
              .reduce((acc, curr) => acc + curr.subtotal, 0) || 0
          const paid =
            billingSchedule
              ?.filter((item) => item.user_type === 'teamRep')
              .filter((item) => item.paid && !item.refunded)
              .reduce((acc, curr) => acc + curr.subtotal, 0) || 0

          return (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 8px;
              `}
            >
              <span>
                {formatCurrency(total - paid, division?.currency?.name)}
              </span>
            </div>
          )
        },
        sorter: (a: Team, b: Team) => {
          const getOutstanding = (team: Team) => {
            if (team.is_bucket || team.is_comp) {
              return 0
            }
            const total =
              team.billing_schedule
                ?.filter((item) => item.user_type === 'teamRep')
                .reduce((acc, curr) => acc + curr.subtotal, 0) || 0
            const paid =
              team.billing_schedule
                ?.filter((item) => item.user_type === 'teamRep')
                .filter((item) => item.paid)
                .reduce((acc, curr) => acc + curr.subtotal, 0) || 0
            return total - paid
          }
          return getOutstanding(a) - getOutstanding(b)
        },
      },
      {
        title: 'Paid',
        dataIndex: 'billing_schedule',
        key: 'paid',
        align: 'right',
        render: (billingSchedule: BillingScheduleItem[], record: Team) => {
          const hasCoupon = record?.is_coupon === true
          const { code, discount_type, discount, discount_amount } =
            record?.coupon || {}
          const discountText =
            discount_type === 'percentage'
              ? `${discount}%`
              : formatCurrency(discount_amount || 0, 'USD')

          if (record.is_bucket) {
            return (
              <span
                css={css`
                  opacity: 0;
                `}
              >
                $325.00 USD
              </span>
            )
          }
          const division = divisions.find((d) => d.id === record?.division_id)

          if (record.is_comp) {
            return <span>--</span>
          }

          let totalPaid = 0
          let hasPaymentDelayed = false

          billingSchedule
            ?.filter((item) => item.user_type === 'teamRep')
            .forEach((payment) => {
              if (payment.paid) {
                totalPaid += payment.display_subtotal
              } else if (dayjs(payment.due_date).isBefore(dayjs())) {
                hasPaymentDelayed = true
              }
            })

          return (
            <PaymentStatus
              totalPaid={totalPaid}
              hasPaymentDelayed={hasPaymentDelayed}
              currency={division?.currency?.name || ''}
              hasCoupon={hasCoupon}
              code={code || ''}
              discountText={discountText || ''}
              discount_type={discount_type || ''}
            />
          )
        },
      },
      {
        title: 'Total Outstanding',
        dataIndex: 'total_due',
        key: 'total_due',
        align: 'right',
        render: (_value, record: Team) => {
          if (record.is_bucket) {
            return (
              <span
                css={css`
                  opacity: 0;
                `}
              >
                $0.00 USD
              </span>
            )
          }
          const division = divisions.find((d) => d.id === record?.division_id)

          // if (record.is_comp) {
          //   return <span>--</span>
          // }
          return (
            <span>
              {formatCurrency(record?.total_due || 0, division?.currency?.name)}
            </span>
          )
        },
      },
      {
        title: 'Total Paid',
        dataIndex: 'total_paid',
        key: 'total_paid',
        align: 'right',
        render: (_value, record: Team) => {
          if (record.is_bucket) {
            return (
              <span
                css={css`
                  opacity: 0;
                `}
              >
                $0.00 USD
              </span>
            )
          }
          const division = divisions.find((d) => d.id === record?.division_id)
          // if (record.is_comp) {
          //   return <span>--</span>
          // }
          return (
            <span>
              {formatCurrency(
                record?.total_paid || 0,
                division?.currency?.name
              )}
            </span>
          )
        },
      },
      {
        title: 'Status',
        key: 'status',
        width: 150,
        render: (_: unknown, record: Team) => {
          return (
            <TeamStatusSelector
              status={record.registration_status}
              teamId={record.id}
              onChange={(status, id) => handleStatusChange(status, id, record)}
              disabled={loadingTeamIds.includes(record.id)}
              options={getRegistrationOptions(record.registration_status)}
              isBucket={record.is_bucket}
            />
          )
        },
        filters: registrationOptions.map((option) => ({
          text: option.label,
          value: option.value,
        })),
        onFilter: (value: number, record: Team) =>
          record.registration_status === value,
      },
      {
        title: '',
        key: 'action',
        render: (_: unknown, record: Team) => {
          if (!record.is_bucket) {
            return (
              <div
                css={css`
                  display: flex;
                  align-items: flex-end;
                  gap: 8px;
                  justify-content: center;
                `}
              >
                <Tooltip title='Add Player'>
                  <PlusCircleFilled
                    onClick={() => {
                      const division = divisions.find(
                        (d) => d.id === record.division_id
                      )
                      const team = division?.teams?.find(
                        (t) => t.team_roster_id === record.team_roster_id
                      )
                      setParentTeamRegistrationId(team?.id || '')
                      setTeamId(record?.team_id || '')
                      setTeamName(record?.name_full || '')
                      setDivisionId(division?.registration?.schedule_id || '')
                      setDivision(division || ({} as RegistrationTableDivision))
                      setScheduleRegistrationId(
                        division?.registration?.id || ''
                      )
                      setIsPlayerAdminModalOpen(true)
                    }}
                    css={css`
                      color: ${colors.PRIMARY};
                      font-size: 20px;
                      cursor: pointer;
                      opacity: 1;
                    `}
                  />
                </Tooltip>
                <InfoCircleOutlined
                  onClick={() => {
                    setRegistrationDetailId(record.id)
                    setIsRegistrationDetailsModalOpen(true)
                  }}
                  css={css`
                    color: ${colors.PRIMARY};
                    font-size: 20px;
                    cursor: pointer;
                  `}
                />
              </div>
            )
          }

          const items = [
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => {
                setBucketScheduleRegistrationId(record?.id || '')
                setBucketToEdit(record)
                setIsBucketModalOpen(true)
              },
            },
            {
              key: 'delete',
              label: 'Delete',
              onClick: () => {
                setBucketToDelete(record)
                document.getElementById('delete-bucket-button')?.click()
              },
            },
          ]

          return (
            <div
              css={css`
                display: flex;
                gap: 8px;
                position: absolute;
                right: ${isMobile ? '32px' : '20px'};
                top: 16px;
              `}
            >
              <Tooltip title='Add Player'>
                <PlusCircleFilled
                  onClick={() => {
                    const division = divisions.find(
                      (d) => d.id === record.division_id
                    )
                    const team = division?.teams?.find(
                      (t) => t.team_roster_id === record.team_roster_id
                    )
                    setParentTeamRegistrationId(team?.id || '')
                    setTeamId(record?.team_id || '')
                    setTeamName(record?.name_full || '')
                    setDivisionId(division?.registration?.schedule_id || '')
                    setDivision(division || ({} as RegistrationTableDivision))
                    setScheduleRegistrationId(division?.registration?.id || '')
                    setIsPlayerAdminModalOpen(true)
                  }}
                  css={css`
                    color: ${colors.PRIMARY};
                    font-size: 20px;
                    cursor: pointer;
                    opacity: 1;
                  `}
                />
              </Tooltip>
              <Dropdown
                menu={{ items }}
                trigger={['click']}
                placement='bottomRight'
              >
                <MoreOutlined
                  css={css`
                    color: ${colors.PRIMARY};
                    font-size: 20px;
                    cursor: pointer;
                    opacity: 1;
                  `}
                />
              </Dropdown>
            </div>
          )
        },
      },
    ],
    [
      divisions,
      getRegistrationOptions,
      handleStatusChange,
      loadingTeamIds,
      scheduleId,
      isMobile,
    ]
  )
}
