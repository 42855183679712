import { useState, useCallback } from 'react'
import req from '../api/request'

export interface Coupon {
  id: string
  code: string
  discount: number
  discount_type: string
  is_active: boolean
  start_date: string
  end_date: string
  remaining: number
  email_matches: string[]
}

interface CouponState {
  coupons: Coupon[]
  isLoading: boolean
  error: string | null
}

interface UseCouponsResult {
  couponState: CouponState
  createCoupon: (couponData: Omit<Coupon, 'id' | 'remaining'>) => Promise<void>
  updateCoupon: (couponId: string, couponData: Partial<Coupon>) => Promise<void>
  deleteCoupon: (couponId: string) => Promise<void>
  refreshCoupons: () => Promise<void>
}

export const useCoupons = (scheduleId?: string): UseCouponsResult => {
  if (!scheduleId) {
    throw new Error('Schedule ID is required')
  }

  const [couponState, setCouponState] = useState<CouponState>({
    coupons: [],
    isLoading: false,
    error: null,
  })

  const refreshCoupons = useCallback(async () => {
    setCouponState((prev) => ({ ...prev, isLoading: true, error: null }))

    try {
      const { data } = await req(`/registration/${scheduleId}/admin/coupon`)
      setCouponState({
        coupons: data,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      setCouponState((prev) => ({
        ...prev,
        isLoading: false,
        error: error?.message || 'An error occurred',
      }))
    }
  }, [scheduleId])

  const createCoupon = useCallback(
    async (couponData: Omit<Coupon, 'id'>) => {
      setCouponState((prev) => ({ ...prev, isLoading: true, error: null }))

      try {
        await req(`/registration/${scheduleId}/admin/coupon`, {
          method: 'POST',
          body: JSON.stringify(couponData),
        })
        await refreshCoupons()
      } catch (error) {
        setCouponState((prev) => ({
          ...prev,
          isLoading: false,
          error: error instanceof Error ? error.message : 'An error occurred',
        }))
        throw error
      }
    },
    [scheduleId, refreshCoupons]
  )

  const updateCoupon = useCallback(
    async (couponId: string, couponData: Partial<Coupon>) => {
      setCouponState((prev) => ({ ...prev, isLoading: true, error: null }))

      try {
        await req(`/registration/${scheduleId}/admin/coupon/${couponId}`, {
          method: 'PUT',
          body: JSON.stringify(couponData),
        })
        await refreshCoupons()
      } catch (error) {
        setCouponState((prev) => ({
          ...prev,
          isLoading: false,
          error: error instanceof Error ? error.message : 'An error occurred',
        }))
        throw error
      }
    },
    [scheduleId, refreshCoupons]
  )

  const deleteCoupon = useCallback(
    async (couponId: string) => {
      setCouponState((prev) => ({ ...prev, isLoading: true, error: null }))

      try {
        await req(`/registration/${scheduleId}/admin/coupon/${couponId}`, {
          method: 'DELETE',
        })
        await refreshCoupons()
      } catch (error) {
        setCouponState((prev) => ({
          ...prev,
          isLoading: false,
          error: error instanceof Error ? error.message : 'An error occurred',
        }))
        throw error
      }
    },
    [scheduleId, refreshCoupons]
  )

  return {
    couponState,
    createCoupon,
    updateCoupon,
    deleteCoupon,
    refreshCoupons,
  }
}
