/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { font, zIndex } from '../css'
import Icon from '../Icon'
import { Flex } from '../Layout'
import Picture from '../Picture'
import BaseActionButton from './BaseActionButton'

const MobilePageHeader = ({
  baseCrumb,
  title,
  breadcrumbs = [],
  loading,
  iconName,
  imageId,
  isTeamOrSchedule,
  actions,
  isInFrame,
  titleActions,
  displayMobileActions = true,
}) => {
  const [areOptionsOpen, setAreOptionsOpen] = useState(false)

  const toggleDisplayActions = () => {
    setAreOptionsOpen(!areOptionsOpen)
  }

  let shouldDisplayBackButton = breadcrumbs?.length > 0
  if (isInFrame) {
    shouldDisplayBackButton = breadcrumbs?.length > 1
  }

  return (
    <div
      css={css`
        margin: 0 16px 8px 16px;
      `}
    >
      <Flex row alignItems='center'>
        {shouldDisplayBackButton && (
          <Link
            to={
              isTeamOrSchedule || breadcrumbs.length === 1
                ? baseCrumb
                : breadcrumbs[breadcrumbs.length - 2]?.to
            }
            css={css`
              margin-right: 16px;
            `}
          >
            <Icon
              fontSize={18}
              className='mobile-page-header-back'
              name='angle-left'
              color={colors.DEFAULT_FLAIR}
            />
          </Link>
        )}
        {isTeamOrSchedule && (
          <Picture
            square
            fit
            iconName={iconName}
            imageId={imageId}
            size='kxxsmall'
            css={css`
              margin-right: 8px;
            `}
          />
        )}
        <h1
          css={css`
            ${font.title}
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;

            letter-spacing: 0.015em;
            text-transform: uppercase;

            pointer-events: none;

            @keyframes fade {
              0% {
                opacity: 1;
              }
              100% {
                opacity: 0;
              }
            }
            ${loading &&
            css`
              ${isTeamOrSchedule && 'min-height: 24px; margin-top: 7px;'}
              background-color: rgba(255, 255, 255, 0.1);
              width: 200px;
              border-radius: 8px;
              animation-name: fade;
              animation-duration: 1s;
              animation-timing-function: ease-in-out;
              animation-iteration-count: infinite;
              animation-direction: alternate;
            `}
          `}
        >
          {title}
        </h1>
        {actions && !isInFrame && (
          <div
            css={css`
              position: relative;
              margin-left: auto;
            `}
          >
            {displayMobileActions ? (
              <BaseActionButton
                name='ellipsis-v'
                color='white'
                title='Action Menu'
                onClick={toggleDisplayActions}
              />
            ) : null}
            {areOptionsOpen && (
              <div
                css={css`
                  position: absolute;
                  z-index: ${zIndex.modal};

                  button {
                    background-color: ${colors.HEADER};
                    margin-top: 8px;
                  }
                `}
              >
                {titleActions}
                {actions}
              </div>
            )}
          </div>
        )}
      </Flex>
    </div>
  )
}

export default MobilePageHeader
