import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { DateUtils } from 'react-day-picker'
import i18n, { t } from '@sportninja/common/i18n'
import { useTimezoneManager } from '@sportninja/common/hooks/useTimezoneManager'
import { toISOString } from '../../utils/utils'
import Picker from '../Picker'
import YearMonthPicker from './YearMonthPicker'
import TimePicker from './TimePicker'
import { StyledDayPicker, PickerContents } from './css'
import translations from './translations.json'
import Icon from '../Icon'
dayjs.extend(utc)
const isUndefined = (o) => typeof o === 'undefined'
export const datePickerRequired = (values) => {
  const s = isUndefined(values.starts_at),
    e = isUndefined(values.ends_at)
  let message = false
  if (s && e) {
    message = t('errors:startAndEndDate')
  } else if (!s && e) {
    message = t('errors:endDate')
  }
  return message && { starts_at: message }
}
const isSelectingFirstDay = (from, to, day) => {
  const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
  const isRangeSelected = from && to
  return !from || isBeforeFirstDay || isRangeSelected
}
/**
 * DatePicker component for selecting dates and times
 *
 * @param {Object} props - Component props
 * @param {boolean} [props.changeOnReset=false] - Whether to trigger onChange when resetting
 * @param {string} [props.datePlaceholder] - Placeholder text for the date input
 * @param {(Object|string)} [props.defaultValue] - Default date value
 * @param {Object} [props.defaultValues={}] - Default values for range selection
 * @param {boolean} [props.disabled] - Whether the picker is disabled
 * @param {(Object|boolean)} [props.errors={}] - Error messages
 * @param {boolean} [props.hasError] - Whether the picker has an error
 * @param {boolean} [props.hideClear=false] - Whether to hide the clear button
 * @param {boolean} [props.hideTime=false] - Whether to hide the time picker
 * @param {Date} [props.displayMonth] - Month to display initially
 * @param {string} [props.name] - Name of the input
 * @param {boolean} [props.noFlex] - Whether to disable flex styling
 * @param {Function} props.onChange - Change handler function
 * @param {string} [props.label] - Label for the input
 * @param {boolean} [props.required] - Whether the field is required
 * @param {boolean} [props.useRange=false] - Whether to use range selection
 * @param {boolean} [props.useISOString=true] - Whether to use ISO string format
 * @param {Object} [props.overrideStyles={}] - Override styles
 * @param {string} [props.id] - ID for the input
 * @param {string} [props.timezone] - Timezone for the date
 * @param {('top'|'bottom')} [props.position='bottom'] - Position of the date picker dropdown
 * @returns {React.Component} - The DatePicker component
 *
 * @example
 * // Render the date picker above the input field
 * <DatePicker
 *   name="event_date"
 *   label="Event Date"
 *   position="top"
 *   onChange={handleDateChange}
 * />
 *
 * // Render the date picker below the input field (default)
 * <DatePicker
 *   name="event_date"
 *   label="Event Date"
 *   onChange={handleDateChange}
 * />
 */
const DatePicker = ({
  changeOnReset = false,
  datePlaceholder,
  defaultValue,
  defaultValues = {},
  disabled,
  errors = {},
  hasError,
  hideClear = false,
  hideTime = false,
  displayMonth,
  name,
  noFlex,
  onChange,
  label,
  required,
  useRange = false,
  useISOString = true,
  overrideStyles = {},
  id,
  timezone,
  // Jeff asked for this change on a quick-win on 2025-03-18
  position = 'bottom',
}) => {
  const today = new Date()
  const initialMonth =
    displayMonth instanceof Date
      ? displayMonth
      : defaultValue
      ? defaultValue
      : new Date(today.getFullYear(), today.getMonth())
  const pickerRef = useRef(null)
  // Probably an elegant way to fix this - but we don't want to display the current
  // date/time if there's no default value - so we pass in undefined.
  const [day, setDay] = useState(
    defaultValue ? dayjs(defaultValue).toDate() : undefined
  )
  const [month, setMonth] = useState(initialMonth)
  const a =
    defaultValues.starts_at &&
    defaultValues.starts_at.value &&
    dayjs(defaultValues.starts_at.value).utc()
  const b =
    defaultValues.ends_at &&
    defaultValues.ends_at.value &&
    dayjs(defaultValues.ends_at.value).utc()
  // The DayPicker component wants native Javascript date objects - but we also
  // want to avoid using a local date. So, explicitly pass the UTC year, month,
  // and date to JS.
  const startsAtDefaultValue = a
    ? new Date(a.year(), a.month(), a.date())
    : undefined
  const endsAtDefaultValue = b
    ? new Date(b.year(), b.month(), b.date())
    : undefined
  const [from, setFrom] = useState(startsAtDefaultValue)
  const [to, setTo] = useState(endsAtDefaultValue)
  const [enteredTo, setEnteredTo] = useState(endsAtDefaultValue)
  const [time, setTime] = useState([
    day instanceof Date
      ? timezone
        ? dayjs(day).tz(timezone).hour()
        : dayjs(day).hour()
      : dayjs().hour(),
    day instanceof Date ? dayjs(day).minute() : dayjs().minute(),
  ])
  const { getDateISOString } = useTimezoneManager({
    date: dayjs(defaultValue).format(),
    timezone,
  })
  useEffect(() => {
    if (typeof defaultValue === 'string') {
      const defaultDate = dayjs(defaultValue).toDate()
      setDay(defaultDate)
      setMonth(new Date(defaultDate.getFullYear(), defaultDate.getMonth()))
    }
  }, [defaultValue])
  useEffect(() => {
    if (Object.keys(defaultValues).length > 0 || useRange) {
      const a =
        defaultValues.starts_at &&
        defaultValues.starts_at.value &&
        dayjs(defaultValues.starts_at.value).utc()
      const b =
        defaultValues.ends_at &&
        defaultValues.ends_at.value &&
        dayjs(defaultValues.ends_at.value).utc()
      const startsAtDefaultValue = a
        ? new Date(a.year(), a.month(), a.date())
        : undefined
      const endsAtDefaultValue = b
        ? new Date(b.year(), b.month(), b.date())
        : undefined
      setFrom(startsAtDefaultValue)
      setTo(endsAtDefaultValue)
      setEnteredTo(endsAtDefaultValue)
      setDay(startsAtDefaultValue)
      if (startsAtDefaultValue instanceof Date) {
        setMonth(
          new Date(
            startsAtDefaultValue.getFullYear(),
            startsAtDefaultValue.getMonth()
          )
        )
      } else {
        setMonth(displayMonth)
      }
    }
  }, [defaultValues, useRange])
  const resetDates = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
    }
    if (useRange) {
      setFrom(null)
      setTo(null)
      setEnteredTo(null)
      onChange({ target: { name: 'starts_at', value: undefined } })
      onChange({ target: { name: 'ends_at', value: undefined } })
    } else {
      setDay(undefined)
      if (changeOnReset) {
        onChange({ target: { name, value: undefined } })
      }
    }
  }
  const handleChange = (day) => {
    if (useRange) {
      if (isSelectingFirstDay(from, to, day) || (from && to)) {
        setFrom(day)
        setTo(null)
        setEnteredTo(null)
        onChange({
          target: {
            name: 'starts_at',
            value: day ? getDateISOString(dayjs(day).format()) : undefined,
          },
        })
        onChange({ target: { name: 'ends_at', value: undefined } })
        setDay(day)
        setMonth(new Date(day.getFullYear(), day.getMonth()))
      } else {
        setTo(day)
        setEnteredTo(day)
        onChange({
          target: {
            name: 'ends_at',
            value: day ? getDateISOString(dayjs(day).format()) : undefined,
          },
        })
      }
    } else {
      const newDay = timezone
        ? dayjs(day).tz(timezone).set('hour', time[0]).set('minute', time[1])
        : dayjs(day).set('hour', time[0]).set('minute', time[1])
      const newDate = newDay.utc()
      setDay(new Date(newDate.format()))
      onChange({
        target: {
          name,
          value: newDay
            ? useISOString
              ? getDateISOString(newDate.format())
              : newDate
            : undefined,
        },
      })
    }
  }
  const handleTimeChange = (hour, minute) => {
    setTime([hour, minute])
    const newDay = day
      ? timezone
        ? dayjs(day).tz(timezone).set('hour', hour).set('minute', minute)
        : dayjs(day).set('hour', hour).set('minute', minute)
      : dayjs().set('hour', hour).set('minute', minute)
    const newDate = newDay.utc()
    setDay(new Date(newDate.format()))
    onChange({ target: { name, value: getDateISOString(newDate.format()) } })
  }
  let additionalProps = {}
  if (useRange) {
    const modifiers = { start: from, end: enteredTo }
    // const disabledDays = { before: from }
    const selectedDays = [from, { from, to: enteredTo }]
    additionalProps = {
      selectedDays: selectedDays,
      // disabledDays: disabledDays,
      modifiers: modifiers,
    }
  }
  const sharedInputProps = {
    disabled,
    hasError: hasError || !!errors.starts_at,
    noFlex,
    required,
  }
  let localeProps = {}
  if (
    i18n.locale !== 'en' &&
    Object.prototype.hasOwnProperty.call(translations, i18n.locale)
  ) {
    localeProps = translations[i18n.locale]
  }

  const selectedDayMemoized = useMemo(() => {
    const d = timezone ? dayjs(day).tz(timezone) : dayjs(day)
    const df = d.format('YYYY-MM-DDT00:00:00')

    return new Date(df)
  }, [day, timezone, time])

  return (
    <Picker
      id={id}
      disabled={disabled}
      hideClear={!!hideClear || !!timezone}
      label={label}
      onReset={resetDates}
      placeholder={datePlaceholder}
      value={
        useRange
          ? from &&
            `${from.toLocaleDateString()} - ${
              to ? to.toLocaleDateString() : ''
            }`
          : day &&
            dayjs(day)
              .tz(timezone || dayjs.tz.guess())
              .format(`MMM D, YYYY${!hideTime ? ' - h:mm a' : ''}`)
      }
      icon={
        <Icon
          faType='far'
          name='calendar'
          className='date-picker-icon'
          color='white'
        />
      }
      {...sharedInputProps}
    >
      <PickerContents
        ref={pickerRef}
        className='date-picker-contents'
        style={overrideStyles}
        position={position}
      >
        <StyledDayPicker
          {...localeProps}
          locale={i18n.locale}
          className={useRange ? 'range' : undefined}
          // enableOutsideDaysClick
          // showOutsideDays
          selectedDays={selectedDayMemoized}
          // This is really a Date object representing the month of a given year
          month={month}
          onDayClick={handleChange}
          captionElement={({ date, localeUtils }) => (
            <YearMonthPicker
              date={date}
              localeUtils={localeUtils}
              onChange={setMonth}
            />
          )}
          {...additionalProps}
        />
        {!useRange && !hideTime && (
          <TimePicker
            defaultValue={day}
            timezone={timezone}
            onChange={handleTimeChange}
          />
        )}
      </PickerContents>
    </Picker>
  )
}
DatePicker.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  hasError: PropTypes.bool,
  hideClear: PropTypes.bool,
  name: PropTypes.string,
  noFlex: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  useRange: PropTypes.bool,
  position: PropTypes.oneOf(['top', 'bottom']),
}
export default DatePicker
