/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ENABLE_REGISTRATION, ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useCallback, useEffect, useMemo, useState } from 'react'
import colors from '@sportninja/common/constants/appColors'
import { useSports } from '@sportninja/common/hooks/useSports'
import { getAllGenericSportProperties } from '@sportninja/common/selectors/types'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { connect } from 'react-redux'
import OptionButtonIcon from 'src/components/List/OptionButtons/Icon'
import Icon from '../../components/Icon'
import { Flex } from '../../components/Layout'
import List from '../../components/List'
import OptionButtonDelete from '../../components/List/OptionButtons/Delete'
import OptionButtonFavourite from '../../components/List/OptionButtons/Favourite'
import MoreOptions, {
  MoreOptionListItem,
} from '../../components/List/OptionButtons/More'
import useGetListItems from '../../components/List/useGetListItems'
import Picture from '../../components/Picture'
import CloneSchedule from '../Schedule/CloneSchedule'
import { Switch } from 'antd'
import DeleteModal from '../../components/Modal/DeleteModal'
import req from '@sportninja/common/api/request'

dayjs.extend(utc)

/**
 * Component for handling archive/unarchive functionality for schedules
 * @param {Object} props - Component props
 * @param {Function} props.Button - Optional custom button component
 * @param {string} props.entityType - Type of entity (schedule)
 * @param {string|number} props.itemId - ID of the schedule
 * @param {boolean} props.isArchived - Whether the schedule is currently archived
 * @param {Function} props.onComplete - Callback function after operation completes
 * @param {Function} props.onToggle - Function to toggle the more options menu
 * @returns {JSX.Element} Archive/Unarchive button component
 */
const OptionButtonArchive = ({
  Button,
  entityType,
  itemId,
  isArchived,
  onComplete,
  onToggle,
  name,
}) => {
  const actionType = isArchived ? 'unarchive' : 'archive'
  const iconName = isArchived ? 'box-open' : 'archive'
  const title = isArchived ? `Unarchive ${name}` : `Archive ${name}`
  const buttonText = isArchived
    ? 'Unarchive Competition'
    : 'Archive Competition'

  const onClick = async () => {
    await req(`/${entityType}/${itemId}`, {
      method: 'PUT',
      body: JSON.stringify({
        is_archive: !isArchived,
      }),
    })
    await onComplete()
  }

  return (
    <DeleteModal
      buttonText={isArchived ? 'Unarchive' : 'Archive'}
      title={title}
      message={`Are You Sure You Want To ${actionType} This Competition?`}
      secondaryMessage={
        isArchived
          ? 'This Will Make The Competition Visible In The Main List Again.'
          : 'This Will Hide The Competition From The Main List.'
      }
      onDelete={onClick}
      shouldCloseModalAfterDelete={true}
      Button={
        Button
          ? Button
          : ({ onClick }) => (
              <MoreOptionListItem
                onClick={() => {
                  onClick()
                  onToggle()
                }}
                iconName={iconName}
                title={buttonText}
              />
            )
      }
      displayBanner={true}
      // sucess
      bannerText={`${name} ${actionType}d successfully`}
    />
  )
}

const ScheduleCell = ({ item, onClick }) => (
  <Flex
    alignItems='center'
    id='sandbox-schedule-name'
    onClick={async () => {
      await onClick()
    }}
  >
    <Picture
      size='kxsmall'
      square
      fit
      imageId={getImageThumbnailId(item)}
      iconName={item.is_tournament ? 'trophy' : 'calendar-alt'}
      css={css`
        margin-right: 8px;
      `}
    />
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      `}
    >
      <span>{item.name_full}</span>
      {item?.is_archive && (
        <span
          css={css`
            font-size: 14px;
            line-height: 1.5;
            color: ${colors.PRIMARY};
            margin-top: 4px;
          `}
        >
          Archived
        </span>
      )}
    </div>
  </Flex>
)

const ScheduleList = ({
  refreshKey,
  setCount = () => {},
  showParentColumn,
  parentId,
  accountTypeId,
  slug,
  defaultSort,
  defaultDirection,
  syncSalesforce,
  orgFeatures,
  onClickSandboxTour = () => {},
  onEditSchedule = () => {},
  setInitialState = () => {},
  setOnTriggerRefresh = () => {},
  genericSports,
  onClickWebPluginOnScheduleList = () => {},
  isParentOrgProfessionalOrUltimateAccount = false,
  setSyncSalesforce = () => {},
  setScheduleInitialStateId = () => {},
  onClickRegistrationSetup = () => {},
  setRegistrationScheduleId = () => {},
  alwaysListArchived = false,
}) => {
  const { sports, fetchSports } = useSports()

  useEffect(() => {
    if (!sports || sports?.length === 0) {
      fetchSports()
    }
  }, [fetchSports, sports])

  const [filter, setFilter] = useState('')
  const [showArchived, setShowArchived] = useState(false)

  useEffect(() => {
    if (alwaysListArchived) {
      setShowArchived(true)
    }
  }, [alwaysListArchived])

  const listProps = useGetListItems(slug, {
    sortDefault: defaultSort,
    directionDefault: defaultDirection,
    onComplete: (response) => setCount(response.meta.pagination.count),
  })
  const sportFilterList = useMemo(() => {
    const sportOptions =
      sports
        ?.map((sport) => ({
          text: sport.name_full,
          value: sport.id,
          is_generic: false,
        }))
        .filter((value) => value?.text !== 'Generic') || []
    const genericSportId = sports?.find((s) => s.name_full === 'Generic')?.id
    genericSports.forEach((sport) => {
      if (!sportOptions.find((s) => s.value === sport.id)) {
        sportOptions.push({
          text: `${sport.name} (Basic)`,
          value: sport.id,
          is_generic: true,
          generic_sport_id: genericSportId,
        })
      }
    })
    return [...sportOptions]
  }, [sports, genericSports])

  const { sort, direction, getItems, permissions, debouncedSearchTerm, items } =
    listProps

  const hasAtLeastOneRegistrationEnabled = useMemo(() => {
    return items?.some((item) => item.is_registration_on) || false
  }, [items])

  const shouldDisplayRegistrationStatus = useMemo(() => {
    return hasAtLeastOneRegistrationEnabled
  }, [hasAtLeastOneRegistrationEnabled])

  // Check if user has update permission for any item
  const hasUpdatePermission = useMemo(() => {
    return Object.values(permissions).some((permission) => permission?.update)
  }, [permissions])

  useEffect(() => {
    setOnTriggerRefresh(() => getItems)
  }, [])

  useEffect(() => {
    getItems(1, { is_archive: showArchived })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey, sort, direction, debouncedSearchTerm, showArchived])

  useEffect(() => {
    if (filter === '') {
      getItems(1, { is_archive: showArchived })
    } else {
      // First, lets find the item on the sportFilterList
      const sport = sportFilterList.find((s) => s.value === filter)
      if (!sport?.is_generic) {
        getItems(1, { sport_id: filter, is_archive: showArchived })
      } else {
        getItems(1, {
          sport_id: sport?.generic_sport_id,
          generic_sport_id: filter,
          is_archive: showArchived,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, showArchived])

  const renderNameCell = ({ item }) => {
    return <ScheduleCell item={item} onClick={onClickSandboxTour} />
  }

  const renderRegistrationAcessor = (item) => {
    if (!item?.is_registration_on) {
      return null
    }
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          background-color: ${item?.is_registration_on
            ? colors.SUCCESS_DARK
            : colors.SOFT_STEEL};
          border-radius: 16px;
          padding: 8px 16px;
          gap: 8px;
        `}
      >
        <Icon
          name={item?.is_registration_on ? 'check-circle' : 'minus-circle'}
          fontSize={13}
          faType='far'
        />
        <span
          css={css`
            color: ${colors.WHITE};
            font-size: 16x;
          `}
        >
          {item?.is_registration_on ? 'Open' : 'Non-set'}
        </span>
      </div>
    )
  }

  const renderCloneCompetitionButton = ({ onClick, onToggle }) => {
    return (
      <MoreOptionListItem
        onClick={() => {
          onClick()
          onToggle()
        }}
        iconName='clone'
        faType='far'
        title={`Clone ${t('common:competition')}`}
      />
    )
  }

  const renderGeneratePluginTag = ({ onClick }) => {
    return (
      <MoreOptionListItem
        onClick={() => {
          onClick()
        }}
        iconName='plug'
        faType='far'
        title={'Generate JS Plugin Tag'}
      />
    )
  }

  const headers = [
    {
      text: t('common:name'),
      sizePercent: 30,
      minWidth: 176,
      sort: 'name_full',
      Cell: renderNameCell,
      to: (item) => ({
        pathname: `${ROUTES.SCHEDULE_ROOT}/${item.id}`,
        state: { fromParent: parentId },
      }),
    },
    showParentColumn && {
      text: t('common:parentOrg'),
      sizePercent: 30,
      sort: 'organization.name_full',
      accessor: 'organization.name_full',
      to: (item) => `${ROUTES.ORG_ROOT}/${item.organization.id}`,
    },
    // add a new column for the archived status - only show if user has update permission and showArchived is true
    // hasUpdatePermission &&
    //   showArchived && {
    //     text: 'Status',
    //     sizePercent: 15,
    //     minWidth: 60,
    //     sort: 'is_archive',
    //     accessor: (item) => (item?.is_archive ? 'Archived' : 'Unarchived'),
    //   },
    {
      text: 'Sport',
      sizePercent: 15,
      minWidth: 60,
      sort: 'sport_id',
      accessor: (item) =>
        item?.generic_sport_name
          ? `${item?.generic_sport_name} (Basic)`
          : sports?.find((s) => s.id === item?.sport_id)?.name_full,
    },
    {
      text: t('common:game', { count: 2 }),
      sizePercent: 5,
      minWidth: 60,
      sort: 'games_count',
      accessor: 'games_count',
    },
    {
      text: t('common:start'),
      sizePercent: 14,
      minWidth: 100,
      sort: 'starts_at',
      accessor: (item) => dayjs.utc(item.starts_at).format("MMM DD, 'YY"),
    },
    {
      text: t('common:end'),
      sizePercent: 14,
      minWidth: 100,
      sort: 'ends_at',
      accessor: (item) => dayjs.utc(item.ends_at).format("MMM DD, 'YY"),
    },
    shouldDisplayRegistrationStatus && {
      text: 'REGIST. STATUS',
      sizePercent: 14,
      minWidth: 100,
      sort: 'reg_status',
      accessor: renderRegistrationAcessor,
    },
  ]

  const options = (item) => {
    const permission = permissions[item.id]
    let isProfessionalOrUltimateAccount = true
    // let isProfessionalOrUltimateAccount =
    //   item?.organization?.account_subscription_type_id ===
    //     SubscriptionType.PROFESSIONAL ||
    //   item?.organization?.account_subscription_type_id ===
    //     SubscriptionType.ULTIMATE

    // if (isParentOrgProfessionalOrUltimateAccount) {
    //   isProfessionalOrUltimateAccount = true
    // }

    const opts = [
      item?.is_registration_on &&
        orgFeatures?.registration &&
        ENABLE_REGISTRATION &&
        permission.update &&
        accountTypeId >= 2 && (
          <OptionButtonIcon
            name='cog'
            onClick={() => {
              setRegistrationScheduleId(item.id)
              onClickRegistrationSetup()
            }}
          />
        ),
      permission.update && (
        <OptionButtonIcon
          name='pencil'
          onClick={() => {
            setSyncSalesforce(item?.organization?.sync_salesforce || false)
            setInitialState(item)
            setScheduleInitialStateId(item.id)
            onEditSchedule()
          }}
        />
      ),
      <OptionButtonFavourite
        key='fav'
        entityType={ENTITY_TYPES.schedule}
        item={item}
        isFavourite={item.is_favorite}
      />,
      <MoreOptions
        key='more'
        options={[
          isProfessionalOrUltimateAccount &&
            permission.update && {
              key: 'plugin',
              Component: renderGeneratePluginTag,
              componentProps: {
                Button: renderGeneratePluginTag,
                schedule: item,
                scheduleId: item.id,
                onClick: () => {
                  onClickWebPluginOnScheduleList(item)
                },
              },
            },
          permission.update && {
            key: 'clone',
            Component: CloneSchedule,
            componentProps: {
              shouldEnablePlayoffs: isCanlan || orgFeatures?.tournaments,
              Button: renderCloneCompetitionButton,
              schedule: item,
              scheduleId: item.id,
            },
          },
          permission.update && {
            key: 'archive',
            Component: OptionButtonArchive,
            componentProps: {
              name: item.name_full,
              entityType: ENTITY_TYPES.schedule,
              itemId: item.id,
              isArchived: item.is_archive,
              onComplete: () => {
                getItems(1, { is_archive: showArchived })
              },
            },
          },
          permission.delete && {
            key: 'delete',
            Component: OptionButtonDelete,
            componentProps: {
              useForceDelete: true,
              unmountOnClose: true,
              entityType: ENTITY_TYPES.schedule,
              itemId: item.id,
              title: `Delete Competition`,
              type: t('common:competition'),
              iconName: 'trash',
              onComplete: () => {
                window.location.reload()
              },
            },
          },
        ]}
      />,
    ]

    return opts.filter((o) => o !== undefined)
  }

  // Check if user has admin permission
  const hasAdminPermission = useMemo(() => {
    return Object.values(permissions).some((permission) => permission?.admin)
  }, [permissions])

  // Create the Show Archived toggle component
  /**
   * Renders the toggle button for showing archived schedules
   * Uses useCallback to memoize the function to prevent unnecessary re-renders
   * @returns {JSX.Element} The toggle button component
   */
  const renderShowArchivedToggle = useCallback(() => {
    if (!hasAdminPermission) {
      return null
    }
    if (alwaysListArchived) {
      return null
    }
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px;
          border-radius: 8px;
          border: 1px solid ${colors.SECONDARY_200};
          background: ${colors.SECONDARY_300};
          height: 36px !important;
          cursor: pointer;
        `}
        onClick={() => setShowArchived(!showArchived)}
      >
        <span
          css={css`
            color: ${colors.WHITE};
            font-size: 14px;
            font-weight: 400;
          `}
        >
          Show Archived
        </span>
        <Switch checked={showArchived} size='small' />
      </div>
    )
  }, [showArchived, hasAdminPermission, alwaysListArchived])

  return (
    <List
      headers={headers.filter((o) => o !== undefined)}
      options={options}
      listProps={listProps}
      noItemsText={t('common:competition', { count: 2 })}
      showSearch
      sportFilterOptions={sportFilterList}
      sportFilter={filter}
      setSportFilter={setFilter}
      showSportFilter={true}
      enabledAllItemsOption={true}
      displayPlaceholder={false}
      additionalFilters={renderShowArchivedToggle}
    />
  )
}

const mapStateToProps = (state) => {
  const genericSports = getAllGenericSportProperties(state)
  return {
    genericSports,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleList)
