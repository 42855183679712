/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Menu } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import { paragraphSmall, weightMedium, weightRegular } from 'src/components/css'
import React, { useMemo } from 'react'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'

const getImage = (item: any, fallback: string) => {
  if (item?.image?.formats && item?.image?.formats?.length > 0) {
    return getImageThumbnailId(item)
  }
  return getImageThumbnailId(item) || item?.imageId || fallback
}

const SiderBarItem = ({
  item,
  baseRoute,
  displayGetStarted,
  tutorialId,
  fallback,
  firstLine,
  secondLine,
}) => {
  return (
    <a
      id={item?.id}
      className={tutorialId}
      href={`${baseRoute}/${item?.id}${
        displayGetStarted ? '?getting_started=1' : ''
      }`}
      // https://sportninja.atlassian.net/browse/SN-4623
      // target='_blank'
      // rel='noreferrer'
      css={css`
        display: flex;
        padding: 8px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        width: 100%;
        &:hover {
          background: ${colors.SECONDARY_400};
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: white;
          border-radius: 4px;
          border: 1px solid ${colors.NEUTRAL_0};
          ${colors.NEUTRAL_0};
          flex-shrink: 0;
        `}
      >
        <img
          loading='lazy'
          src={getImage(item, fallback)}
          alt={item?.name || item?.name_full}
          width={32}
          height={32}
          css={css`
            border-radius: 4px;
            border: 1px solid ${colors.NEUTRAL_0};
            flex-shrink: 0;
            object-fit: contain;
          `}
          onError={(e: any) => {
            e.target.onerror = null
            e.target.src = fallback
          }}
        />
      </div>
      <p
        css={css`
          ${paragraphSmall}
          ${weightMedium}
        color: ${colors.NEUTRAL_0};
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        `}
      >
        {firstLine}
        {secondLine && <br />}
        {secondLine}
      </p>
    </a>
  )
}

type HomeSidebarListProps = {
  title: string
  data: []
  icon: React.ReactNode
  baseRoute: string
  fallback: string
  displayCount?: boolean
  isOpen?: boolean
  onToggle?: () => void
  tutorialId?: string
  displayGetStarted?: boolean
}

const HomeSidebarList = ({
  title,
  data = [],
  icon,
  baseRoute,
  fallback,
  displayCount = true,
  isOpen = false,
  onToggle = () => {},
  tutorialId = '',
  displayGetStarted = false,
}: HomeSidebarListProps) => {
  const defaultOpenKeys = useMemo(() => (isOpen ? ['sub1'] : []), [isOpen])

  const children = useMemo(
    () =>
      data?.map((item: any, _index: number) => {
        const name = item?.name || item?.name_full || item?.fullName
        const nameLength = name.length
        let firstLine = name
        let secondLine = ''
        if (nameLength > 25) {
          const words = name.split(' ')
          let currentLine = ''
          for (const word of words) {
            if ((currentLine + ' ' + word).length <= 25) {
              currentLine += (currentLine ? ' ' : '') + word
            } else {
              break
            }
          }
          firstLine = currentLine
          secondLine = name.slice(currentLine.length)
        }

        return {
          key: item?.id,
          label: (
            <SiderBarItem
              item={item}
              baseRoute={baseRoute}
              displayGetStarted={displayGetStarted}
              tutorialId={tutorialId}
              fallback={fallback}
              firstLine={firstLine}
              secondLine={secondLine}
            />
          ),
          type: 'item',
        }
      }),
    [data, baseRoute, displayGetStarted, fallback, tutorialId]
  )

  if (!data) {
    return null
  }

  // const onClick: MenuProps['onClick'] = (e) => {
  //   console.log(e)
  //   if (e.key === 'sub1') {
  //     onToggle()
  //   }
  // }

  const items: any = [
    {
      key: 'sub1',
      label: (
        <p
          css={css`
            ${paragraphSmall}
            ${weightRegular}
            color: ${colors.NEUTRAL_0};
          `}
        >
          {title}{' '}
          <span
            css={css`
              color: ${colors.NEUTRAL_50};
            `}
          >
            {displayCount && `(${data.length})`}
          </span>
        </p>
      ),
      icon: icon,
      children: children,
    },
  ]

  return (
    <>
      <style>
        {`
          .ant-menu-submenu-title {
            padding-left: 0 !important;
            padding-inline: 0 !important;
            height: 48px !important;
            width: 100% !important;
            margin-inline: 0px !important;
          }
           .ant-menu .ant-menu-submenu-arrow {
            width: 0 !important;
          }
          .ant-menu-submenu-arrow {
              color: ${colors.NEUTRAL_100} !important;
          }
          .ant-menu-item {
            padding-left: 0 !important;
            padding-inline: 0 !important;
            height: 48px !important;
            width: 100% !important;
            margin-inline: 0px !important;
          }
          .ant-menu-submenu-title {
            width: 100% !important;
            margin-inline: 0px !important;
          }
          .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
            background: none !important;
            width: 100% !important;
            margin-inline: 0px !important;
          }
          .ant-menu-light .ant-menu-item-selected {
            background: none !important;
          }
          .ant-menu-inline.ant-menu-root .ant-menu-item {
            padding-left: 0 !important;
            width: 100% !important;
            margin-inline: 0px !important;
          }
          .ant-image {
            width: 32px !important;
            height: 32px !important;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .ant-menu-item-active {
            background: none !important;
          }
        `}
      </style>
      <Menu
        forceSubMenuRender={true}
        onOpenChange={(_openKeys) => {
          // [] or ['sub1']
          onToggle()
        }}
        css={css`
          width: 100%;
          .ant-menu-submenu-title {
            padding-left: 8px !important;
          }
          .ant-menu .ant-menu-submenu-arrow {
            width: 0 !important;
          }
        `}
        openKeys={defaultOpenKeys}
        mode='inline'
        items={items}
      />
    </>
  )
}

export default HomeSidebarList
