import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import colors from '@sportninja/common/constants/appColors'

import Icon from '../Icon'
import { textColor } from '../css'
import { media } from '../Responsive'

const MenuIconComponent = styled.button`
  position: relative;
  font-size: 19px !important;

  :not(:last-of-type) {
    margin-right: 28px;

    ${media.mobile} {
      margin-right: 16px;
    }
  }

  :hover {
    i {
      color: ${colors.WHITE};
    }
  }
`

const MenuIcon = ({
  onClick,
  children,
  className,
  color = textColor.grey,
  customIcon = false,
  ...props
}) => (
  <MenuIconComponent className={className} onClick={onClick}>
    {customIcon ? customIcon : <Icon color={color} {...props} />}
    {children}
  </MenuIconComponent>
)

MenuIcon.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  showPip: PropTypes.bool,
}

export default MenuIcon
