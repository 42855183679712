/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { Spin, Table, Popconfirm } from 'antd'
import { observer } from 'mobx-react-lite'
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Icon from 'src/components/Icon'
import { NewModal } from 'src/components/NewModal'
import { Input } from 'src/components/Input'
import { Button } from 'src/components/Button'
import { useMobxStore } from 'src/state'
import { ModalHeader } from './UpgradeModal'
import { useForm } from 'react-hook-form'
import Text from 'src/components/ts-components/Text'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Tax Rate',
    dataIndex: 'tax_rate',
    key: 'tax_rate',
  },
  {
    title: 'Options',
    dataIndex: 'options',
    key: 'options',
  },
]

type FormType = {
  taxName: string
  taxDescription: string
  taxRate: string | number
}

export const Taxes = observer(() => {
  const {
    me: {
      state: { account, loading },
      fetchMe,
    },
  } = useMobxStore()
  const { control, handleSubmit, setValue } = useForm<FormType>({
    defaultValues: {
      taxDescription: '',
      taxName: '',
      taxRate: '',
    },
  })
  const taxModalRef = useRef<NewModal>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [selectedTaxId, setSelectedTaxId] = useState('')
  const [taxError, setTaxError] = useState(false)

  const handleEsc = useCallback((event) => {
    if (event.key === 'Escape') {
      taxModalRef.current?.closeModal()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [handleEsc])

  const dataSource = useMemo(() => {
    return account?.taxes.map((tax) => ({
      key: tax?.id,
      name: tax?.name,
      description: tax.description,
      tax_rate: `${tax?.tax_rate}%`,
      options: (
        <div
          css={css`
            display: flex;
            gap: 16px;
          `}
        >
          <button
            onClick={() => {
              setValue('taxName', tax?.name)
              setValue('taxDescription', tax?.description || '')
              setValue('taxRate', tax?.tax_rate)
              setIsUpdate(true)
              setSelectedTaxId(tax?.id)

              taxModalRef.current?.openModal()
            }}
          >
            <Icon name='pen' color={colors.DEFAULT_FLAIR} fontSize={16} />
          </button>
          <Popconfirm
            title='Delete this tax?'
            description='Are you sure you want to delete this tax?'
            onConfirm={async () => {
              await req(`/account/${account?.uid}/tax/${tax?.id}/`, {
                method: 'DELETE',
              })
              fetchMe()
            }}
            okText='Yes'
            cancelText='No'
          >
            <button>
              <Icon name='trash' color={colors.ERROR} fontSize={16} />
            </button>
          </Popconfirm>
        </div>
      ),
    }))
  }, [account])

  const onSubmit = useCallback(
    async (data: FormType) => {
      setTaxError(false)
      setIsLoading(true)

      if (Number(data.taxRate) <= 0) {
        setTaxError(true)
        setIsLoading(false)
        return
      }

      await req(
        isUpdate
          ? `/account/${account?.uid}/tax/${selectedTaxId}`
          : `/account/${account?.uid}/tax`,
        {
          method: isUpdate ? 'PUT' : 'POST',
          body: JSON.stringify({
            name: data.taxName,
            description: data.taxDescription,
            tax_rate: data.taxRate,
            currency_id: 1,
          }),
        }
      )

      setIsLoading(false)
      fetchMe()
      taxModalRef.current?.closeModal()
    },
    [account, isLoading, isUpdate, selectedTaxId]
  )

  return (
    <>
      <div
        css={css`
          background-color: ${colors.HEADER};
          padding: 16px;
          border-radius: 8px;
          border: 1px solid ${colors.SOFT_STEEL};
          max-width: 820px;
          margin: 0 auto;
          margin-top: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div>
            <Text variant='heading5' weight='regular' color={colors.WHITE}>
              Taxes
            </Text>
            <p
              css={css`
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 12px */
                margin-top: 8px;
                color: ${colors.ATTENDANCE_GRAY};
              `}
            >
              Set Up Tax Rates To Apply Charges During The Registration Process.
            </p>
          </div>
          <button
            onClick={() => {
              setValue('taxName', '')
              setValue('taxDescription', '')
              setValue('taxRate', '')
              setIsUpdate(false)

              taxModalRef.current?.openModal()
            }}
            css={css`
              display: flex;
              gap: 8px;
              align-items: center;
              color: ${colors.DEFAULT_FLAIR};
            `}
          >
            <p>Add</p>
            <Icon
              name='plus-square'
              color={colors.DEFAULT_FLAIR}
              fontSize={16}
            />
          </button>
        </div>

        <Suspense fallback={<Spin />}>
          <div
            css={css`
              margin-top: 16px;
              padding-top: 16px;
              border-top: 1px solid ${colors.SOFT_STEEL};

              * {
                border-color: ${colors.SOFT_STEEL} !important;
              }
            `}
          >
            <Table
              loading={loading}
              bordered
              pagination={false}
              columns={columns}
              dataSource={dataSource}
              locale={{
                emptyText: 'No Taxes Registered',
              }}
            />
          </div>
        </Suspense>
      </div>
      <NewModal ref={taxModalRef} shouldCloseOnOverlayClick={false}>
        <div
          css={css`
            padding: 16px;
            background: no-repeat fixed linear-gradient(#282e38, #181a1d);
            display: flex;
            flex-direction: column;
            max-height: 90vh;
            overflow-y: auto;
            width: 600px;
            border-radius: 8px;
          `}
        >
          <ModalHeader
            title={isUpdate ? 'Update Tax' : 'Add Tax'}
            onCloseClick={() => {
              setValue('taxName', '')
              setValue('taxDescription', '')
              setValue('taxRate', '')
              setIsUpdate(false)
              setTaxError(false)
              setIsLoading(false)

              taxModalRef.current?.closeModal()
            }}
            hideSupportButton
          />
          <div
            css={css`
              margin-top: 16px;
            `}
          >
            <div
              css={css`
                .ant-input {
                  background-color: ${colors.HEADER};
                }
              `}
            >
              <Input control={control} fieldName='taxName' label='Tax Name' />
              <Input
                control={control}
                fieldName='taxDescription'
                label='Tax Description'
              />
              <div
                css={css`
                  display: flex;
                  gap: 16px;
                  align-items: center;
                  margin-top: 8px;
                `}
              >
                <Input
                  control={control}
                  min={0}
                  fieldName='taxRate'
                  label='Tax Rate (%)'
                  type='number'
                  invalid={taxError}
                  invalidMessage='Tax rate must be greater than 0'
                />
              </div>
            </div>

            <div
              css={css`
                display: flex;
                gap: 16px;
                align-items: center;
                margin-top: 16px;
              `}
            >
              <Button
                label='Cancel'
                disabled={isLoading}
                variant='secondary'
                onClick={() => {
                  setTaxError(false)
                  taxModalRef.current?.closeModal()
                }}
              />

              <Button
                label={isUpdate ? 'Update' : 'Confirm'}
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </NewModal>
    </>
  )
})
