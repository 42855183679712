/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useMemo, useState } from 'react'
import { saveAs } from 'file-saver'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'

import LoadingSpinner from '../LoadingSpinner'
import { FormButton } from '../Form/css'
import FormInput from '../Form/FormInput'
import Icon from '../Icon'
import { font } from '../css'
import SuspensionTableComponent from './SuspensionTableComponent'
import SuspensionSelect from './SuspensionSelect'
import SuspensionTableRow from './SuspensionTableRow'
import { Flex } from '../Layout'
import { media } from '../Responsive'
import { Spin } from 'antd'

const ClearButton = ({ onClick }) => (
  <Icon
    name='times'
    fontSize={20}
    onClick={onClick}
    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
  />
)

const Suspensions = ({
  isProfileView = false,
  loading = true,
  suspensions = [],
  refresh,
  exportId,
  exportFileName,
  hasPermission,
  onlyDisplayActive = false,
  scheduleId = null,
}) => {
  const [activeFilter, setActiveFilter] = useState(false)
  const [playerFilter, setPlayerFilter] = useState('')

  const receivedSuspensions = useMemo(() => suspensions, [suspensions])

  const filteredSuspensions = receivedSuspensions.filter((s) => {
    if (onlyDisplayActive) {
      return s.is_active
    }

    let shouldInclude = true
    if (activeFilter === 'active') {
      shouldInclude = s.is_active
    } else if (activeFilter === 'completed') {
      shouldInclude = !s.is_active
    }

    if (playerFilter) {
      const name = s?.player?.name_first
        ? `${s?.player?.name_first} ${s?.player?.name_last}`
        : '-'
      return (
        shouldInclude && name.toLowerCase().includes(playerFilter.toLowerCase())
      )
    }

    return shouldInclude
  })

  if (loading) {
    return (
      <div
        className='stats-by-season'
        css={css`
          display: flex;
          flex-direction: column;
          min-height: 98px;
          margin-top: 24px;
          padding: 20px 16px;
          justify-content: center;
          align-items: center;
          background-color: #27303e;
          border: 1px solid #3f4753;
          border-radius: 8px;

          ${media.mobile} {
            padding: 16px 0;
          }
        `}
      >
        <Spin />
      </div>
    )
  }

  if (receivedSuspensions.length === 0) {
    return (
      <div
        className='stats-by-season'
        css={css`
          display: flex;
          flex-direction: column;
          min-height: 98px;
          margin-top: 24px;
          padding: 20px 16px;
          background-color: #27303e;
          border: 1px solid #3f4753;
          border-radius: 8px;

          ${media.mobile} {
            padding: 16px 0;
          }
        `}
      >
        <Flex alignItems='center' justifyContent='center'>
          No Suspensions
        </Flex>
      </div>
    )
  }

  return (
    <>
      <div
        css={css`
          margin: 24px auto;
          display: flex;
          flex-wrap: wrap;
        `}
      >
        {/* <SuspensionSelect value='' label='Organization' /> */}
        {/* <SuspensionSelect value='' label='Conference' />
            <SuspensionSelect value='' label='Division' /> */}
        {/* <SuspensionSelect value='' label='Team' /> */}
        {/* <SuspensionSelect value='' label='Suspension Type' /> */}
        {onlyDisplayActive && (
          <h1
            css={css`
              ${font.title}
              font-weight: 700;
              font-size: 15px;
              letter-spacing: 1.2px;
              color: ${colors.PRIMARY};
            `}
          >
            Active Suspensions
          </h1>
        )}
        {hasPermission && (
          <>
            <SuspensionSelect
              css={css`
                padding-bottom: 0;
              `}
              value={activeFilter}
              label='Status'
              onChange={(value) => {
                setActiveFilter(value)
              }}
              enabledAllItemsOption
              options={[
                { text: 'Active', value: 'active' },
                { text: 'Completed', value: 'completed' },
              ]}
            />
            <div
              css={css`
                max-width: 400px;
                padding: 0 16px;
              `}
            >
              <p
                css={css`
                  height: 12px;
                  font-size: 12px;
                  line-height: 12px;
                  margin-bottom: 8px;
                  text-transform: capitalize;
                  @media only screen and (min-width: 864px) {
                    padding-left: 8px;
                  }
                `}
              >
                Search Player
              </p>
              <FormInput
                wrapperClass={css`
                  min-width: 240px;
                `}
                fixBorder
                onChange={(e) => {
                  setPlayerFilter(e.target.value)
                }}
                input={{
                  right: (
                    <ClearButton
                      onClick={(e) => {
                        e.preventDefault()
                        setPlayerFilter('')
                      }}
                    />
                  ),
                  autoComplete: 'chrome-off',
                  name: 'name',
                  placeholder: 'Enter a player name',
                  value: playerFilter,
                }}
              />
            </div>
          </>
        )}
        {exportId && hasPermission && (
          <div
            css={css`
              display: flex;
              align-items: flex-end;
            `}
          >
            <FormButton
              css={css`
                height: 40px;
                padding: 0 16px;
                display: flex;
              `}
              onClick={async () => {
                const response = await req(
                  `/suspensions/schedule/${exportId}/export/csv`,
                  {
                    method: 'GET',
                    parseJSON: false,
                    skipAutoContentType: true,
                    headers: {
                      Accept: 'text/csv',
                    },
                  }
                )
                const blob = await response.blob()
                saveAs(blob, `${exportFileName}.csv`)
              }}
            >
              Export CSV
              <Icon
                name='download'
                fontSize={14}
                css={css`
                  margin-left: 8px;
                `}
              />
            </FormButton>
          </div>
        )}
      </div>
      {/* <Flex
            noFlex
            alignItems='flex-end'
            css={css`
              height: 60px;
            `}
          >
            <SuspensionSubmitButton />
          </Flex> */}

      <div
        css={css`
          overflow: auto;
        `}
      >
        <SuspensionTableComponent>
          <thead>
            <tr>
              {!isProfileView && (
                <th width='234' className='col-1'>
                  Player
                </th>
              )}
              <th className={isProfileView ? 'col-1' : ''}>Team</th>
              {isProfileView && <th>Competition</th>}
              {!onlyDisplayActive && <th>Status</th>}
              <th>Date</th>
              <th>Return Date</th>
              {/* <th>Length</th> */}
              {/* <th>Remaining</th> */}
              <th>Penalty</th>
              <th>Description</th>
              {/* <th>Comment</th> */}
              {/* <th>Report</th> */}
              {!isProfileView && hasPermission && (
                <th className='edit'>Edit</th>
              )}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={7} className='no-results loading'>
                  <LoadingSpinner size={3} borderSize={2} />
                </td>
              </tr>
            ) : !Array.isArray(filteredSuspensions) ||
              filteredSuspensions.length === 0 ? (
              <tr>
                <td colSpan={7} className='no-results'>
                  {isProfileView
                    ? 'You have no suspensions.'
                    : 'There are no suspensions for this competition.'}
                </td>
              </tr>
            ) : (
              filteredSuspensions.map((suspension) => {
                return (
                  <SuspensionTableRow
                    isProfileView={isProfileView}
                    onComplete={refresh}
                    key={suspension.id}
                    suspension={suspension}
                    canEdit={hasPermission}
                    onlyDisplayActive={onlyDisplayActive}
                    scheduleId={scheduleId}
                  />
                )
              })
            )}
          </tbody>
        </SuspensionTableComponent>
      </div>
    </>
  )
}

export default Suspensions
