/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useSports } from '@sportninja/common/hooks/useSports'
import { useContext, useEffect, useMemo, useRef } from 'react'
import Helmet from 'src/components/Helmet'
import {
  heading3,
  heading5,
  heading6,
  paragraphMedium,
  paragraphSmall,
  paragraphXSmall,
  weightBold,
  weightRegular,
  weightSemiBold,
} from 'src/components/css'
import { RegistrationContext } from '../context/RegistrationContext'
import ConfirmYourEmail from './New_ConfirmYourEmail'
import Login from './New_Login'
import RegisterReview from './New_RegisterReview'
import RegistrationPayment from './New_RegistrationPayment'
import SelectYourDivision from './New_SelectYourDivision'
import { SignUp } from './New_SignUp'
import TeamRegister from './New_TeamRegister'
import hockeyDefaultBg from './hockey_default_bg.png'
import { Alert } from 'antd'
import SelectYourRole from './New_SelectYourRole'
import Text from 'src/components/ts-components/Text'
import useWindowSize from 'src/hooks/useWindowSize'
import Waiver from './New_Waiver'
export const alternateButtonStyle = css`
  ${heading6}
  ${weightSemiBold}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  flex: 1;
  height: 40px;
  border: 2px solid ${colors.PRIMARY};
  color: ${colors.WHITE};
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'BarlowCondensed', sans-serif;
  min-width: 240px;
  max-width: 240px;
  width: 240px;
  @media (max-width: 600px) {
    width: 100%;
    min-width: 0px;
    max-width: 100%;
  }
`

export const buttonStyle = css`
  ${heading6}
  ${weightSemiBold}
  background-color: ${colors.PRIMARY};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  flex: 1;
  height: 40px;
  border: 2px solid ${colors.SOFT_STEEL};
  color: ${'white'};
  border-radius: 8px;
  cursor: ${'pointer'};
  text-transform: uppercase;
  font-family: 'BarlowCondensed', sans-serif;
  min-width: 240px;
  max-width: 240px;
  @media (max-width: 600px) {
    width: 100%;
    min-width: 0px;
    max-width: 100%;
  }
`

export const disabledButtonStyle = css`
  ${buttonStyle}
  background-color: ${colors.SOFT_STEEL};
  cursor: not-allowed;
`

export const loadingButtonStyle = css`
  ${buttonStyle}
  background-color: ${colors.SOFT_STEEL};
  cursor: wait;
`

export const headingStyle = css`
  ${heading5}
  ${weightSemiBold}
`

export const headingThreeStyle = css`
  ${heading3}
  ${weightSemiBold}
`

export const headingSixStyle = css`
  ${heading6}
  ${weightSemiBold}
`

export const headingButtonStyle = css`
  ${heading5}
  ${weightSemiBold}
`

export const paragraphSmallStyle = css`
  ${paragraphSmall}
  ${weightRegular}
`

export const paragraphMediumStyle = css`
  ${paragraphMedium}
  ${weightRegular}
`

export const paragraphSmallBoldStyle = css`
  ${paragraphSmall}
  ${weightBold}
`

export const paragraphMediumBoldStyle = css`
  ${paragraphMedium}
  ${weightBold}
`

export const paragraphXSmallStyle = css`
  ${paragraphXSmall}
  ${weightRegular}
`

export const PageTitle = ({
  title,
  subtitle,
  showAlert = false,
  showSuccess = false,
}) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        margin-top: 24px;
        margin-bottom: 24px;
      `}
    >
      {showAlert ? (
        <Alert
          description='Do Not Close This Page Until You Have Completed The Registration Process.'
          type='warning'
          showIcon
          css={css`
            width: 100%;
            padding: 16px 16px;
          `}
        />
      ) : null}
      {showSuccess ? (
        <Alert
          style={{
            padding: '16px 16px',
          }}
          description='Your Registration Has Been Successfully Submitted.'
          type='success'
          showIcon
          css={css`
            width: 100%;
          `}
        />
      ) : null}
      <Text variant='heading3' weight='semiBold' color={colors.NEUTRAL_0}>
        {title}
      </Text>
      {subtitle ? (
        <Text
          variant='paragraphMedium'
          weight='regular'
          color={colors.NEUTRAL_50}
        >
          {subtitle}
        </Text>
      ) : null}
      <div
        css={css`
          width: 100%;
          height: 1px;
          background-color: ${colors.VERY_DARK_GRAYISH_BLUE};
        `}
      />
    </div>
  )
}

export const REGISTRATION_STEPS = {
  SELECT_YOUR_DIVISION: 0,
  SIGN_UP: 1,
  LOGIN: 2,
  TEAM_REGISTER: 3,
  REGISTRATION_PAYMENT: 4,
  CONFIRM_YOUR_EMAIL: 5,
  REGISTER_REVIEW: 6,
  SELECT_YOUR_ROLE: 7,
  WAIVER: 8,
}

const steps = {
  0: SelectYourDivision,
  1: SignUp,
  2: Login,
  3: TeamRegister,
  4: RegistrationPayment,
  5: ConfirmYourEmail,
  6: RegisterReview,
  7: SelectYourRole,
  8: Waiver,
}

const getBackgroundImage = (
  genericSportForGame,
  isSoccer,
  imageName,
  isDesktop,
  sport = null
) => {
  if (!genericSportForGame) {
    if (isSoccer) {
      return '/images/soccer_game_background'
    } else {
      if (sport && sport?.name) {
        if (sport?.name === 'lacrosse') {
          return '/images/grass'
        }
        if (sport?.name === 'fieldhockey') {
          return '/images/grass'
        }
        if (sport?.name === 'floorball') {
          return '/images/floorball'
        }
      }
      return '/images/game_hockey'
    }
  } else {
    return isDesktop
      ? genericSportForGame?.background_web_image?.replace('.png', '')
      : genericSportForGame?.background_mobile_image?.replace('.png', '')
  }
}

const Main = () => {
  const { step, registration, genericSportProperties } =
    useContext(RegistrationContext)
  const { width } = useWindowSize()
  const isDesktop = width > 768
  const { sports, fetchSports } = useSports()

  useEffect(() => {
    if (!sports || sports?.length === 0) {
      fetchSports()
    }
  }, [fetchSports, sports])

  const sport = sports?.find((s) => s?.id === registration?.sport_id)
  const isSoccer = sport?.name === 'soccer'
  const isGenericSport =
    registration?.registration?.root_schedule?.generic_sport_id !== null
  const genericSport = isGenericSport
    ? genericSportProperties?.find(
        (g) =>
          g?.id === registration?.registration?.root_schedule?.generic_sport_id
      )
    : null

  const backgroundImage = getBackgroundImage(
    genericSport,
    isSoccer,
    'game_hockey',
    isDesktop,
    sport
  )

  const Step = useMemo(() => steps[step], [step])
  const divRef = useRef(null)

  const apiBackgroundImage = useMemo(() => {
    return (
      registration?.registration?.image?.full_path || `${backgroundImage}.png`
    )
  }, [registration?.registration?.image?.full_path, backgroundImage])

  useEffect(() => {
    document.body.classList.add('keyboard')
    document.body.addEventListener(
      'focus',
      (event) => {
        const target = event.target
        switch (target.tagName) {
          case 'INPUT':
          case 'TEXTAREA':
          case 'SELECT':
            document.body.classList.add('keyboard')
        }
      },
      true
    )
    document.body.addEventListener(
      'blur',
      () => {
        document.body.classList.remove('keyboard')
      },
      true
    )
    return () => {
      document.body.removeEventListener('focus', () => {
        document.body.classList.remove('keyboard')
      })
      document.body.removeEventListener('blur', () => {
        document.body.classList.remove('keyboard')
      })
    }
  }, [])

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* Adjustment for the Background issue */
        /* max-height: 100vh; */
        background-color: ${colors.NEUTRAL_800};
        z-index: -1;
      `}
    >
      <style>
        {`
            body.keyboard {
              height: calc(100% + 500px); /* add padding for keyboard */
            }
            input {
              font-size: 16px !important;
            }
          `}
      </style>
      <img
        src={'/images/powered_by_sportninja.png'}
        css={css`
          height: 30px;
          max-width: 180px;
          position: absolute;
          bottom: ${step === REGISTRATION_STEPS.TEAM_REGISTER
            ? '-700px'
            : '-150px'};
          left: 16px;
          z-index: 3;
          display: none;
          /* hide on mobile */
          @media (max-width: 768px) {
            display: none;
          }
        `}
      />
      <Helmet title='Registration' />
      <div
        ref={divRef}
        css={css`
          background-image: url(${apiBackgroundImage});
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          /* Adjustment for the Background issue */
          height: 40vh;
          z-index: 1;
        `}
      >
        <img
          src={apiBackgroundImage}
          onError={() => {
            if (divRef.current) {
              divRef.current.style.backgroundImage = `url(${hockeyDefaultBg})`
            }
          }}
          css={css`
            display: none;
          `}
        />
      </div>
      <div
        css={css`
          /* overflow-y: auto; */
          margin-top: 100px;
          /* margin-top: 160px; */
          background-color: ${colors.NEUTRAL_800};
          border-radius: 16px 16px 0px 0px;
          display: flex;
          flex-direction: column;
          display: flex;
          min-width: 1080px;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          flex-shrink: 0;
          /* height: 100%; */
          position: absolute;
          bottom: 0;
          top: 0;
          /* if the step is TeamRegister, we should make this 150% */
          height: ${'100%'};
          @media (max-width: 768px) {
            width: 100%;
            min-width: 0;
          }
          > * {
            color: #000;
            background-color: ${colors.NEUTRAL_800};
          }
          /* Adjustment for the Background issue */
          z-index: 2;
        `}
      >
        <div
          css={css`
            width: 100%;
            height: 100%;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
          `}
        >
          <Step isDarkMode={false} />
        </div>
      </div>
    </div>
  )
}

export default Main
