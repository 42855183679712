/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useState } from 'react'
import colors from '@sportninja/common/constants/appColors'
import { useContext } from 'react'

import Icon from '../Icon'
import Picture from '../Picture'
import MenuIcon from '../MainNav/MenuIcon'
import { font } from '../css'
import { media, Mobile } from '../Responsive'
import { PusherContext } from '../../pages/PusherContext'
import ChatList from './ChatList'
import ChatWindow from './ChatWindow'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { CommentOutlined } from '@ant-design/icons'

const Chat = ({ isTeamOrSchedulePage, loggedInUserId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loadedTeams, setLoadedTeams] = useState({})
  const { client, isReady, error } = useContext(PusherContext)

  const onTeamClick = (t) => {
    setLoadedTeams((teams) => {
      if (t?.team?.id) {
        return {
          ...teams,
          [t.team.id]: { ...t.team, notificationCount: 0, isMinimized: false },
        }
      }
      return teams
    })
  }

  const onChatWindowClose = (teamId) => {
    console.log('onChatWindowClose', teamId)
    setLoadedTeams((teams) => {
      const copy = { ...teams }
      delete copy[teamId]
      return copy
    })
  }

  const onMinimize = (teamId, isMinimized) => {
    setLoadedTeams((teams) => {
      const copy = { ...teams }
      copy[teamId].isMinimized = isMinimized
      if (!isMinimized) {
        copy[teamId].notificationCount = 0
      }
      return copy
    })
  }

  const onSetHasNotification = (teamId) => {
    setLoadedTeams((teams) => {
      const copy = { ...teams }
      if (copy[teamId].isMinimized) {
        copy[teamId].notificationCount++
      }
      return copy
    })
  }

  const totalNotificationCount = Object.values(loadedTeams).reduce(
    (collector, team) => {
      return collector + team.notificationCount
    },
    0
  )

  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault()

          setIsOpen(false)
        }}
        css={css`
          position: fixed;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          z-index: ${isOpen ? '1000' : '100'};
          pointer-events: ${isOpen ? 'all' : 'none'};
          transition: all 0.2s ease-in-out;
          display: ${isOpen ? 'flex' : 'none'};
        `}
      />
      <div
        css={css`
          z-index: ${isOpen ? '1001' : '101'};
          margin-right: 28px;
        `}
      >
        {/* The actual main navigation icon for chat */}
        <MenuIcon
          name='comments-alt'
          css={css`
            margin-top: 4px;
          `}
          onClick={() => {
            setIsOpen((i) => !i)
          }}
          color={isOpen ? colors.PRIMARY : undefined}
          customIcon={
            <CommentOutlined
              css={css`
                font-size: 24px;
                color: ${isOpen ? colors.DEFAULT_FLAIR : colors.NEUTRAL_50};
              `}
            />
          }
        >
          {totalNotificationCount > 0 && (
            <div
              css={css`
                position: absolute;
                top: -10px;
                right: -10px;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                background-color: ${colors.RED};
                padding-bottom: 1px;
              `}
            >
              <div
                css={css`
                  color: ${colors.WHITE};
                  ${font.title};
                  font-size: 13px;
                  font-weight: 700;
                  text-align: center;
                  letter-spacing: 1.2px;
                `}
              >
                {totalNotificationCount}
              </div>
            </div>
          )}
        </MenuIcon>
        {/* Fly-out in top-right that lists available team chats */}
        {isOpen && (
          <ChatList
            isReady={isReady}
            error={error}
            isTeamOrSchedulePage={isTeamOrSchedulePage}
            setIsOpen={setIsOpen}
            onTeamClick={onTeamClick}
            loggedInUserId={loggedInUserId}
            loadedTeams={loadedTeams}
          />
        )}
        {/* Open chat windows */}
        {isReady && (
          <div
            css={css`
              display: flex;
              position: fixed;
              bottom: 0px;
              right: 88px;

              ${media.mobile} {
                right: 48px;
              }
            `}
          >
            {Object.keys(loadedTeams).map((openTeamId) => {
              const loadedTeam = loadedTeams[openTeamId]
              return (
                <ChatWindow
                  key={loadedTeam.id}
                  team={loadedTeam}
                  client={client}
                  loggedInUserId={loggedInUserId}
                  isMinimized={loadedTeam.isMinimized || false}
                  onMinimize={onMinimize.bind(this, openTeamId, true)}
                  onClose={onChatWindowClose.bind(this, openTeamId)}
                  onNewNotification={onSetHasNotification.bind(
                    this,
                    openTeamId
                  )}
                />
              )
            })}
          </div>
        )}
        {/* Container for minimized chat icons */}
        {isReady && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;

              position: fixed;
              bottom: 58px;
              right: 20px;

              ${media.mobile} {
                right: 4px;
              }
            `}
          >
            {/* {Object.values(loadedTeams).filter((t) => t.isMinimized).length >
            0 && (
            <button
              type='button'
              css={css`
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background-color: ${colors.SOFT_STEEL};
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 8px;
              `}
              onClick={() => {}}
            >
              <Icon name='ellipsis-h' color='white' fontSize={18} />
            </button>
          )} */}
            {Object.keys(loadedTeams).map((teamId) => {
              const loadedTeam = loadedTeams[teamId]
              if (!loadedTeam.isMinimized) {
                return false
              }

              const onTeamBtnClick = (e) => {
                e.stopPropagation()
                onTeamClick(loadedTeam)
                onMinimize(teamId, false)
              }

              return (
                <div
                  key={teamId}
                  css={css`
                    position: relative;
                    margin-top: 8px;

                    &:hover {
                      .team-name-tooltip {
                        opacity: 1;
                      }

                      button.minimized-close-btn {
                        scale: 1;
                        opacity: 1;
                      }
                    }
                  `}
                >
                  <div
                    className='team-name-tooltip'
                    css={css`
                      display: flex;
                      align-items: center;
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      right: 56px;
                      width: max-content;
                      opacity: 0;
                      pointer-events: none;

                      transition: opacity 0.2s ease-in-out;
                    `}
                  >
                    <div
                      css={css`
                        background-color: ${colors.SOFT_STEEL};
                        border-radius: 8px;
                        padding: 8px 12px;

                        ${font.body}
                        font-size: 14px;
                        letter-spacing: 1px;
                        color: white;
                        text-align: right;
                        box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2),
                          0 2px 4px 0 rgba(0, 0, 0, 0.1);
                      `}
                    >
                      {loadedTeam.team_name}
                    </div>
                  </div>
                  {/* This is the main icon for the team chat icon */}
                  <button type='button' onClick={onTeamBtnClick}>
                    <Mobile>
                      {(isMobile) => {
                        return (
                          <Picture
                            size={isMobile ? 'kxsmall' : 'ksmall'}
                            square
                            fit
                            iconName='user-friends'
                            imageId={getImageThumbnailId(loadedTeam)}
                            css={css`
                              border-radius: 50%;
                              overflow: hidden;
                            `}
                            alt={loadedTeam.team_name}
                          />
                        )
                      }}
                    </Mobile>
                  </button>
                  {/* This is the bottom-right "online" pip / notification count */}
                  <div
                    css={css`
                      position: absolute;
                      right: -4px;
                      bottom: 2px;
                      text-align: center;
                      padding: 2px;
                      border-radius: ${loadedTeam.notificationCount > 0
                        ? '18px'
                        : '50%'};
                      background-color: #181a1d;
                      ${font.title}
                      font-size: 13px;
                      font-weight: 700;
                    `}
                  >
                    {loadedTeam.notificationCount > 0 ? (
                      <div
                        css={css`
                          height: 18px;
                          min-width: 18px;
                          padding: 0 4px;
                          border-radius: 18px;
                          background-color: red;
                          text-align: center;
                          margin: auto;
                          line-height: 17px;
                        `}
                      >
                        {loadedTeam.notificationCount}
                      </div>
                    ) : (
                      <div
                        css={css`
                          height: 14px;
                          width: 14px;
                          border-radius: 50%;
                          background-color: #31a24c;
                        `}
                      />
                    )}
                  </div>
                  {/* This is the top-right close icon/button */}
                  <button
                    className='minimized-close-btn'
                    type='button'
                    css={css`
                      position: absolute;
                      right: -6px;
                      top: -5px;
                      border-radius: 50%;
                      border: 1px solid ${colors.ATTENDANCE_GRAY};
                      background-color: black;
                      height: 24px;
                      width: 24px;

                      opacity: 0;
                      scale: 0.9;

                      transition: scale 0.1s ease-in-out,
                        opacity 0.1s ease-in-out;

                      &:hover {
                        background-color: ${colors.ATTENDANCE_GRAY};
                        i {
                          color: black;
                        }
                      }

                      ${media.mobile} {
                        width: 22px;

                        height: 22px;
                        right: -4px;

                        i {
                          font-size: 12px;
                        }
                      }
                    `}
                    onClick={(e) => {
                      e.stopPropagation()
                      onChatWindowClose(teamId)
                    }}
                  >
                    <Icon name='times' fontSize={14} />
                  </button>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}

export default Chat
