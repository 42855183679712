// create a context for the registration setup

import { Registration } from '@sportninja/common/types'
import { RegistrationTypes, Tax } from '@sportninja/common/types/Registration'
import dayjs from 'dayjs'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRegistrationData } from '@sportninja/common/hooks/useRegistrationData'
import { useWaivers, Waiver } from '@sportninja/common/hooks/useWaivers'

type RegistrationSetupState = {
  loading: boolean
  competitionDateRange: {
    starts_at: string | null
    ends_at: string | null
  }
  goLive: boolean
  registrationType: 'player' | 'team'
  paidBy: 'player' | 'team'
  bccEmails: string[]
  currency: string
  taxes: string[]
  customBackgroundImage: string
  customBackgroundImageChanged: boolean
  darkMode: boolean
  waivers: string[]
  registrationForm: {
    [key: string]: boolean
  }
  fieldErrors: string[]
  customError: any
  payment_type: string
  autoApproveRegistrations: boolean
  enableTeamRegistration: boolean
  enablePlayerRegistration: boolean
  payment_provider: string
  show_waiver: boolean
  addPlayersOutsideOfRegistration: boolean
}

type RegistrationSetupContextType = {
  state: RegistrationSetupState
  setState: React.Dispatch<React.SetStateAction<RegistrationSetupState>>
  registrationTypes: RegistrationTypes
  availableTaxes: Tax[]
  submitRegistration: () => Promise<void>
  isUpdated: boolean
  apiWaivers: Waiver[]
  refetchWaivers: () => void
}

const RegistrationSetupContext = createContext<
  RegistrationSetupContextType | undefined
>(undefined)

export const useRegistrationSetupContext = () => {
  const context = useContext(RegistrationSetupContext)
  if (!context) {
    throw new Error(
      'useRegistrationSetupContext must be used within a RegistrationSetupContextWrapper'
    )
  }
  return context
}

type RegistrationSetupProps = {
  registration: Registration | null
  registrationTypes: RegistrationTypes
  availableTaxes: Tax[]
  scheduleId: string
  accountId: string
}

type RegistrationSetupContextWrapperProps = RegistrationSetupProps & {
  children: React.ReactNode
}

const RegistrationSetupContextWrapper = ({
  children,
  registration,
  registrationTypes,
  availableTaxes,
  scheduleId,
  accountId,
}: RegistrationSetupContextWrapperProps) => {
  const [state, setState] = useState<RegistrationSetupState>({
    show_waiver: false,
    competitionDateRange: {
      starts_at: dayjs().utc().format('YYYY-MM-DD'),
      ends_at: dayjs().utc().format('YYYY-MM-DD'),
    },
    goLive: false,
    registrationType: 'team',
    paidBy: 'player',
    bccEmails: [],
    currency: '2',
    taxes: [],
    customBackgroundImage: '',
    customBackgroundImageChanged: false,
    darkMode: false,
    waivers: [],
    registrationForm: {},
    fieldErrors: [],
    customError: '',
    loading: false,
    payment_type: '1',
    autoApproveRegistrations: false,
    enableTeamRegistration: false,
    enablePlayerRegistration: false,
    addPlayersOutsideOfRegistration: false,
    payment_provider: '2',
  })

  const { data: apiWaivers, refetch: refetchWaivers } = useWaivers(accountId)

  const isUpdated = useMemo(
    () => Boolean(registration && registration.id),
    [registration]
  )

  const {
    createRegistration,
    updateRegistration,
    uploadRegistrationImage,
    deleteRegistrationImage,
  } = useRegistrationData('')

  const setCustomErroByField = (field: string, message: string) => {
    setState((prev) => ({ ...prev, fieldErrors: [...prev.fieldErrors, field] }))
    setState((prev) => ({
      ...prev,
      customError: { ...prev.customError, [field]: message },
    }))
  }

  const setCustomErrors = (invalid_fields: any) => {
    if (invalid_fields.waivers) {
      setCustomErroByField('waivers', invalid_fields.waivers)
    }
    if (invalid_fields.name || invalid_fields.name_full) {
      setCustomErroByField('name', invalid_fields.name)
    }
    if (invalid_fields.currency) {
      setCustomErroByField('currency', invalid_fields.currency)
    }
    if (invalid_fields.payment_currency) {
      setCustomErroByField('payment_currency', invalid_fields.payment_currency)
    }
    if (invalid_fields.payment_type) {
      setCustomErroByField('payment_type', invalid_fields.payment_type)
    }
  }

  const submitRegistration = async () => {
    try {
      setState((prev) => ({
        ...prev,
        fieldErrors: [],
        customError: '',
        loading: true,
      }))

      if (state.show_waiver && state.waivers.length === 0) {
        const error = new Error(
          'Please select at least one waiver'
        ) as Error & {
          invalid_fields?: Record<string, string>
        }
        error.invalid_fields = { waivers: 'Please select at least one waiver' }
        throw error
      }

      // Handle registration data
      if (isUpdated) {
        await updateRegistration({
          scheduleId: scheduleId,
          allowPlayerRegistration: state.enablePlayerRegistration,
          registrationType:
            state.enableTeamRegistration && !state.enablePlayerRegistration
              ? 'team'
              : 'player',
          enableRegistration: true,
          eventNumber: '',
          currency: Number(state.currency),
          costPerRegistration: 0,
          paymentCurrency: Number(state.currency),
          paymentMethod: Number(state.payment_type),
          goLive: state.goLive,
          dateRange: {
            starts_at: state.competitionDateRange.starts_at || '',
            ends_at: state.competitionDateRange.ends_at || '',
          },
          useDarkTheme: state.darkMode,
          maxTeamSlots: 0,
          defaultTaxes: state.taxes,
          bccEmails: state.bccEmails,
          paymentProvider: Number(state.payment_provider),
          autoApproveRegistrations: state.autoApproveRegistrations,
          enableTeamRegistration: state.enableTeamRegistration,
          enablePlayerRegistration: state.enablePlayerRegistration,
          addPlayersOutsideOfRegistration:
            state.addPlayersOutsideOfRegistration,
          show_waiver: state?.show_waiver === true ? true : false,
          waivers: state?.waivers || [],
        })
      } else {
        await createRegistration({
          scheduleId: scheduleId,
          allowPlayerRegistration: state.registrationType === 'player',
          registrationType: state.registrationType,
          enableRegistration: true,
          eventNumber: '',
          currency: Number(state.currency),
          costPerRegistration: 0,
          paymentCurrency: Number(state.currency),
          paymentMethod: Number(state.payment_type),
          goLive: state.goLive,
          dateRange: {
            starts_at: state.competitionDateRange.starts_at || '',
            ends_at: state.competitionDateRange.ends_at || '',
          },
          useDarkTheme: state.darkMode,
          maxTeamSlots: 0,
          defaultTaxes: state.taxes,
          bccEmails: state.bccEmails,
          paymentProvider: Number(state.payment_provider),
          autoApproveRegistrations: state.autoApproveRegistrations,
          enableTeamRegistration: state.enableTeamRegistration,
          enablePlayerRegistration: state.enablePlayerRegistration,
          addPlayersOutsideOfRegistration:
            state.addPlayersOutsideOfRegistration,
          show_waiver: state?.show_waiver === true ? true : false,
          waivers: state?.waivers || [],
        })
      }

      // Handle image upload/deletion
      if (state.customBackgroundImageChanged) {
        if (state.customBackgroundImage !== '') {
          await uploadRegistrationImage(scheduleId, state.customBackgroundImage)
        } else {
          await deleteRegistrationImage(scheduleId)
        }
      }
    } catch (error: any) {
      if (error?.invalid_fields) {
        setCustomErrors(error?.invalid_fields)
        throw error
      }
      throw error instanceof Error
        ? error
        : new Error('Failed to create registration')
    } finally {
      setState((prev) => ({ ...prev, loading: false }))
    }
  }

  const value: RegistrationSetupContextType = {
    state,
    setState,
    registrationTypes,
    availableTaxes,
    isUpdated,
    submitRegistration,
    apiWaivers,
    refetchWaivers,
  }

  useEffect(() => {
    if (registration) {
      setState((prevState) => ({
        ...prevState,
        competitionDateRange: {
          starts_at:
            registration?.starts_at || dayjs().utc().format('YYYY-MM-DD'),
          ends_at: registration?.ends_at || dayjs().utc().format('YYYY-MM-DD'),
        },
        currency: registration?.currency?.id || '2',
        taxes: registration?.taxes?.map((tax) => tax.id) || [],
        bccEmails: registration?.bcc_registrations_to?.split(',') || [],
        goLive: registration?.is_live,
        registrationType:
          registration?.registration_type_id === 1 ? 'team' : 'player',
        paidBy: 'team',
        darkMode: registration?.dark_theme,
        customBackgroundImage: registration?.image?.full_path || '',
        customBackgroundImageChanged: false,
        enableTeamRegistration: registration?.is_team_registration,
        enablePlayerRegistration: registration?.is_player_registration,
        autoApproveRegistrations: registration?.auto_approve,
        show_waiver: registration?.show_waiver,
        waivers: registration?.waivers?.map((waiver) => waiver.id) || [],
        addPlayersOutsideOfRegistration:
          registration?.can_add_players_from_roster,
      }))
    }
  }, [registration])

  return (
    <RegistrationSetupContext.Provider value={value}>
      {children}
    </RegistrationSetupContext.Provider>
  )
}

export { RegistrationSetupContextWrapper }
