/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { CloseOutlined } from '@ant-design/icons'
import { App, Modal, Tabs } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import { paragraphMedium, weightRegular } from 'src/components/css'
import Button from 'src/components/ts-components/Button'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  RegistrationSetupContextWrapper,
  useRegistrationSetupContext,
} from './context'
import GeneralSettings from './forms/GeneralSettings'
import Payments from './forms/Payments'
import Customization from './forms/Customization'
import Waiver from './forms/Waiver'
// import RegistrationForm from './forms/RegistrationForm'
import { Registration } from '@sportninja/common/types'
import { RegistrationTypes, Tax } from '@sportninja/common/types/Registration'
import { useModalScroll } from 'src/hooks/useModalScroll'
import Text from 'src/components/ts-components/Text'
import useWindowSize from 'src/hooks/useWindowSize'

const labelById = {
  0: 'General Settings',
  1: 'Payments',
  2: 'Customization',
  3: 'Player Waivers',
  // 4: 'Registration Form',
}
const styles = css`
  .my-modal-body {
  }
  .my-modal-mask {
    backdrop-filter: blur(1px);
  }
  .my-modal-header {
    border-bottom: 1px dotted ${colors.FIERY_RED};
  }
  .my-modal-footer {
    color: ${colors.FIERY_RED};
  }
  .my-modal-content {
    border: 1px solid #333;
  }
`
const classNames = {
  body: styles['my-modal-body'],
  mask: styles['my-modal-mask'],
  header: styles['my-modal-header'],
  footer: styles['my-modal-footer'],
  content: styles['my-modal-content'],
}
const modalStyles = {
  header: {
    backgroundColor: colors.SECONDARY_300,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  body: {
    borderBottom: `1px solid ${colors.SOFT_STEEL}`,
    borderTop: `1px solid ${colors.SOFT_STEEL}`,
    backgroundColor: colors.SECONDARY_300,
  },
  content: {
    backgroundColor: colors.SECONDARY_300,
    paddingTop: '8px',
    paddingBottom: '16px',
    paddingLeft: '0',
    paddingRight: '0',
  },
  wrapper: {
    zIndex: 10001,
  },
  mask: {
    // backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(6, 10, 16, 0.8)',
    zIndex: 10000,
  },
  footer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

type RegistrationSetupProps = {
  isRegistrationFormOpen: boolean
  setIsRegistrationFormOpen: (isRegistrationFormOpen: boolean) => void
  registration: Registration | null
  registrationTypes: RegistrationTypes
  availableTaxes: Tax[]
  scheduleId: string
  refetchRegistrationFn: React.MutableRefObject<() => void>
  accountId: string
}

const RegistrationSetupContent = ({
  setIsRegistrationFormOpen,
  refetchRegistrationFn,
  isRegistrationFormOpen,
}: {
  setIsRegistrationFormOpen: (isRegistrationFormOpen: boolean) => void
  refetchRegistrationFn: React.MutableRefObject<
    (displayLoading?: boolean) => void
  >
  isRegistrationFormOpen: boolean
}) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width <= 768, [width])
  const [activeTab, setActiveTab] = useState('0')
  const { submitRegistration, state, isUpdated, refetchWaivers } =
    useRegistrationSetupContext()
  const { message } = App.useApp()

  useEffect(() => {
    if (isRegistrationFormOpen) {
      refetchWaivers()
    }
  }, [isRegistrationFormOpen, refetchWaivers])

  const getFormById = (id: string) => {
    switch (id) {
      case '0':
        return <GeneralSettings />
      case '1':
        return <Payments />
      case '2':
        return <Customization />
      case '3':
        return <Waiver />
      // case '4':
      //   return <RegistrationForm />
      default:
        return null
    }
  }

  const getFooter = useCallback(() => {
    return (
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          gap: 16px;
          width: 50%;
          margin-left: auto;
        `}
      >
        <Button
          text='Cancel'
          onClick={() => setIsRegistrationFormOpen(false)}
          variant='secondary'
          disabled={state.loading}
        />
        <Button
          text='Save'
          onClick={async () => {
            try {
              await submitRegistration()
              message.success(
                isUpdated
                  ? 'Registration Updated Successfully'
                  : 'Registration Created Successfully'
              )
              await refetchRegistrationFn.current?.(isUpdated ? false : true)
              setIsRegistrationFormOpen(false)
            } catch (error: any) {
              if (error?.invalid_fields) {
                if (error.invalid_fields.currency) {
                  setActiveTab('1')
                }
                if (error.invalid_fields.payment_currency) {
                  setActiveTab('1')
                }
                if (error.invalid_fields.payment_type) {
                  setActiveTab('1')
                }
                return
              }
              message.error(error.message || 'Failed to Submit Registration')
            }
          }}
          variant='primary'
          disabled={state.loading}
        />
      </div>
    )
  }, [
    isUpdated,
    message,
    refetchRegistrationFn,
    setIsRegistrationFormOpen,
    state.loading,
    submitRegistration,
  ])

  const getHeader = useCallback(() => {
    return (
      <Text variant='heading3' weight='semiBold' color={colors.WHITE}>
        Registration Settings
      </Text>
    )
  }, [])

  const closeIcon = useCallback(() => {
    return (
      <CloseOutlined
        css={css`
          color: ${colors.SOLITUDE};
          font-size: 16px;
          cursor: ${state.loading ? 'not-allowed' : 'pointer'};
          opacity: ${state.loading ? 0.5 : 1};
        `}
      />
    )
  }, [state.loading])

  return (
    <Modal
      css={css`
        display: flex;
        flex: 1;
        background: ${colors.SECONDARY_400} !important;
        height: ${isMobile ? '100vh' : 'auto'} !important;
        margin: ${isMobile ? '0' : '24px'};
        padding: 0;
        max-width: ${isMobile ? '100%' : '770px'};
        top: ${isMobile ? '0' : '50%'};
        .ant-modal-content {
          height: ${isMobile ? '100vh' : 'auto'} !important;
          border-radius: ${isMobile ? '0' : '8px'};
          display: flex;
          flex-direction: column;
        }
        .ant-modal-body {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
        }
        .ant-tabs-tabpane {
          background: ${colors.SECONDARY_300} !important;
        }
        .ant-tabs-nav {
          min-height: ${isMobile ? 'auto' : '600px'} !important;
        }
        .ant-tabs-tab {
          height: 40px !important;
        }
        /* if the tab is active, change the background color */
        .ant-tabs-tab-active {
          background-color: #26303e;
        }
        .ant-tabs-tab-btn {
          ${paragraphMedium}
          ${weightRegular}
        }
        .ant-tabs-tab-btn[aria-selected='true'] {
          color: ${colors.PRIMARY}!important;
        }
        .ant-tabs-tab-btn[aria-selected='false'] {
          color: ${colors.NEUTRAL_30}!important;
        }
        .ant-tabs-tab.ant-tabs-tab-disabled {
          color: ${colors.NEUTRAL_30}!important;
          cursor: not-allowed !important;
          opacity: 0.5 !important;
        }
      `}
      width={isMobile ? '100%' : 770}
      closeIcon={closeIcon()}
      title={getHeader()}
      footer={getFooter()}
      open={true}
      onOk={() => setIsRegistrationFormOpen(false)}
      onCancel={() => setIsRegistrationFormOpen(false)}
      centered
      classNames={classNames}
      styles={modalStyles}
      maskClosable={false}
    >
      <Tabs
        activeKey={activeTab}
        defaultActiveKey='0'
        onChange={(key) => setActiveTab(key)}
        tabPosition={isMobile ? 'top' : 'left'}
        css={css`
          display: flex;
          flex: 1;
          height: 100%;
          overflow: hidden;
          .ant-tabs-nav {
            @media (max-width: 768px) {
              width: 100%;
              min-height: auto !important;
              border-bottom: 1px solid ${colors.SOFT_STEEL};
              .ant-tabs-nav-list {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                scrollbar-width: none;
                -ms-overflow-style: none;
                &::-webkit-scrollbar {
                  display: none;
                }
              }

              .ant-tabs-nav-operations {
                display: none !important;
              }
            }
          }

          .ant-tabs-content {
            height: 100%;
          }

          .ant-tabs-content-holder {
            flex: 1;
            overflow: hidden;
          }

          .ant-tabs-tab {
            height: 40px !important;
            @media (max-width: 768px) {
              margin: 0 !important;
              padding: 8px 16px !important;
              flex: 0 0 auto;
              justify-content: center;
              min-width: 120px;
            }
          }

          .ant-tabs-tab-active {
            background-color: #26303e;
            @media (max-width: 768px) {
              border-bottom: 2px solid ${colors.PRIMARY} !important;
            }
          }

          .ant-tabs-tab-btn {
            ${paragraphMedium}
            ${weightRegular}
            @media (max-width: 768px) {
              text-align: center;
              width: 100%;
              white-space: nowrap;
            }
          }

          .ant-tabs-tab-btn[aria-selected='true'] {
            color: ${colors.PRIMARY}!important;
          }

          .ant-tabs-tab-btn[aria-selected='false'] {
            color: ${colors.NEUTRAL_30}!important;
          }

          .ant-tabs-content-holder {
            @media (max-width: 768px) {
              padding: 0 16px;
              max-height: 100%;
              overflow-y: auto;
              height: 100%;
            }
          }

          .ant-tabs-ink-bar {
            @media (max-width: 768px) {
              display: none;
            }
          }
        `}
        items={new Array(4).fill(null).map((_, i) => {
          const id = String(i)
          return {
            label: labelById[id],
            key: id,
            children: getFormById(id),
            disabled: false,
          }
        })}
        className='tab-options'
      />
    </Modal>
  )
}

const RegistrationSetup = ({
  isRegistrationFormOpen,
  setIsRegistrationFormOpen,
  registration,
  registrationTypes,
  availableTaxes,
  scheduleId,
  refetchRegistrationFn,
  accountId,
}: RegistrationSetupProps) => {
  const { openModal, closeModal } = useModalScroll()

  useEffect(() => {
    if (isRegistrationFormOpen) {
      openModal()
    } else {
      closeModal()
    }
  }, [closeModal, isRegistrationFormOpen, openModal])

  if (!isRegistrationFormOpen) {
    return null
  }

  return (
    <RegistrationSetupContextWrapper
      registration={registration}
      registrationTypes={registrationTypes}
      availableTaxes={availableTaxes}
      scheduleId={scheduleId}
      accountId={accountId}
    >
      <RegistrationSetupContent
        setIsRegistrationFormOpen={setIsRegistrationFormOpen}
        refetchRegistrationFn={refetchRegistrationFn}
        isRegistrationFormOpen={isRegistrationFormOpen}
      />
    </RegistrationSetupContextWrapper>
  )
}

export default RegistrationSetup
