/** @jsxImportSource @emotion/react */
import { DeleteOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import React, { useState } from 'react'
import { paragraphMedium, weightSemiBold } from 'src/components/css'
import DeleteModal from 'src/components/Modal/DeleteModal'
import { SwitchInput } from 'src/components/ts-components/SwitchInput'
import Text from 'src/components/ts-components/Text'
import { TextInput } from 'src/components/ts-components/TextInput'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import { Separator } from '../GeneralForm'
import { useScheduleContext } from '../ScheduleContext'
import TiebreakerModal from './TiebreakerModal'
import '../styles.scss'

const ReprocessHiddenButton = ({ ...props }) => {
  return (
    <button
      id='reprocess-hidden-button'
      css={css`
        display: flex;
        padding: 8px 16px 8px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid ${colors.ERROR_200};
        box-shadow: 0px 2px 0px 0px rgba(5, 145, 255, 0.1);
        /* hide the button */
        display: none;
      `}
      {...props}
    >
      <DeleteOutlined
        css={css`
          color: ${colors.ERROR_200};
        `}
        width={24}
        height={24}
      />
      <p
        css={css`
          ${paragraphMedium}
          ${weightSemiBold}
          font-family: ${isCanlan
            ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
            : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
          color: ${colors.WHITE};
        `}
      >
        DELETE {isCanlan ? 'SEASON' : 'COMPETITION'}
      </p>
    </button>
  )
}

type SettingsFormProps = {
  onCancelPressed: () => void
  triggerRefresh: () => void
  addPending: (id: string | undefined) => void
  isMobile: boolean
}

const SettingsForm: React.FC<SettingsFormProps> = ({
  onCancelPressed,
  triggerRefresh,
  addPending,
  isMobile,
}) => {
  const [isTiebreakerModalOpen, setIsTiebreakerModalOpen] = useState(false)

  const {
    id,
    fieldErrors,
    customError,
    skipTeamOfficialsSignatures,
    setSkipTeamOfficialsSignatures,
    skipOfficialsSignatures,
    setSkipOfficialsSignatures,
    timingPeriodLength,
    setTimingPeriodLength,
    timingExtraPeriodLength,
    setTimingExtraPeriodLength,
    requireGameOfficials,
    setRequireGameOfficials,
    goalsPerForfeit,
    wins,
    ties,
    losses,
    overtimerWin,
    overtimeLoss,
    shootoutLoss,
    setGoalsPerForfeit,
    setWins,
    setTies,
    setLosses,
    setOvertimeWin,
    setOvertimeLoss,
    setShootoutLoss,
    onSubmit,
    onCancel,
    requireUniqueJerseyNumbers,
    setRequireUniqueJerseyNumbers,
    requireWaiverSignature,
    setRequireWaiverSignature,
    requireTimeGameEvent,
    setRequireTimeGameEvent,
    requireGoalType,
    setRequireGoalType,
    requireGoalConfirmation,
    setRequireGoalConfirmation,
    defaultSortBy,
    setDefaultSortBy,
    tiebreakerStats,
    setTiebreakerStats,
    statTypes,
    // defaultStandingsSettingsForSport,
  } = useScheduleContext()

  // const defaultTiebreakerStats =
  //   defaultStandingsSettingsForSport?.sort_order?.tie_breaker_stats

  // Handle edit tiebreaker click
  const handleEditTiebreaker = () => {
    if (statTypes?.length === 0) {
      return
    }
    setIsTiebreakerModalOpen(true)
  }

  const handleTiebreakerModalClose = () => {
    setIsTiebreakerModalOpen(false)
  }

  const handleTiebreakerModalSave = (selectedStats: number[]) => {
    setTiebreakerStats(selectedStats)
    setIsTiebreakerModalOpen(false)
  }

  // Function to get the current tiebreaker mode
  // const getTiebreakerMode = () => {
  //   return tiebreakerStats.length === 0 ? 'default' : 'custom'
  // }

  // Function to handle tiebreaker mode change
  // const handleTiebreakerModeChange = (value: string) => {
  //   if (value === 'default') {
  //     setTiebreakerStats([])
  //   } else {
  //     // Set some default tiebreaker stats if switching to custom
  //     setTiebreakerStats(defaultTiebreakerStats || [])
  //   }
  // }

  // Function to get stat label by value
  const getStatLabel = (value: number) => {
    const name = statTypes.find((option) => option.id === value)?.name
    return name || ''
  }

  // Function to get formatted tiebreaker stats text
  /**
   * Formats the tiebreaker stats text with order indices
   * @returns {string} Formatted string of tiebreaker stats with their order indices
   */
  const getTiebreakerStatsText = () => {
    if (tiebreakerStats.length === 0) {
      return ''
    }
    return tiebreakerStats
      .map((stat, index) => `${getStatLabel(stat)} (${index + 1})`)
      .join(', ')
  }

  return (
    <div
      css={css`
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
        gap: 16px;
        border-bottom: 1px solid #3f4753;
        ${isMobile &&
        css`
          padding-left: 8px;
          padding-right: 8px;
          width: 100%;
        `}
      `}
    >
      <DeleteModal
        title={'CONFIRM AND REPROCESS COMPETITION STATISTICS'}
        message={
          'Reprocess statistics when you have made edits to the Points System, and want to update the statistics/standings for the Competition.'
        }
        secondaryMessage={
          'NOTE: Depending on the number of games, this can take several minutes to complete.'
        }
        onDelete={async (_shouldForce = false) => {
          await onSubmit()
          await req(`/schedules/${id}/reprocess`)
          addPending(id)
          triggerRefresh()
          onCancel()
          onCancelPressed()
        }}
        Button={ReprocessHiddenButton}
        useForceDelete={true}
        forceDeleteText={undefined}
        buttonText='Reprocess'
        bannerText='Reprocessing Stats'
        displayBanner={false}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 16px;
        `}
      >
        {/* Game Setup Settings */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <Text
              variant='captionLarge'
              weight='semiBold'
              color={colors.NEUTRAL_0}
            >
              Game Setup Settings
            </Text>
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Skip Team Official Setup'
              subtitle='This Option Allows You To Skip The Setup Of Team Officials.'
              value={skipTeamOfficialsSignatures}
              onChange={setSkipTeamOfficialsSignatures}
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Require Game Officials'
              subtitle='This Option Makes At Least One Game Official Required For All Games.'
              value={requireGameOfficials}
              onChange={setRequireGameOfficials}
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Skip Game Officials Signatures'
              subtitle='This Option Allows You To Skip The Signatures Of Officials.'
              value={skipOfficialsSignatures}
              onChange={setSkipOfficialsSignatures}
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Require Unique Jersey Numbers'
              subtitle='This Option Will Enforce All Players Have A Unique Jersey # When Setting Up A Game.'
              value={requireUniqueJerseyNumbers}
              onChange={setRequireUniqueJerseyNumbers}
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Require Waiver Signature Before Player Can Play'
              subtitle='This Option Will Requires Players To Sign The Waiver Before They Can Be Added To A Game'
              value={requireWaiverSignature}
              onChange={setRequireWaiverSignature}
            />
          </div>
          {/* For now, this is being disabled */}
          {/* <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Skip Starting Line-up Selection'
              subtitle='This option allows you to skip the starting line-up.'
              value={skipStartingLineupSelection}
              onChange={setSkipStartingLineupSelection}
            />
          </div> */}
        </div>
        <Separator />
        {/* Standings Settings */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <Text
              variant='captionLarge'
              weight='semiBold'
              color={colors.NEUTRAL_0}
            >
              Standings Settings
            </Text>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
              ${isMobile &&
              css`
                width: 100%;
                flex-direction: column;
              `}
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <SelectInput
                style={css`
                  height: 50px !important;
                  ${statTypes?.length === 0 &&
                  css`
                    opacity: 0.5;
                  `}
                `}
                name='defaultSortBy'
                value={defaultSortBy ? String(defaultSortBy) : ''}
                onChange={(value) => setDefaultSortBy(parseInt(value, 10))}
                label='Default Sort By'
                options={statTypes
                  .map((stat) => ({
                    ...stat,
                    // Replace IN-DIV with DIV for sorting purposes
                    sortKey:
                      stat.abbreviation === 'IN-DIV'
                        ? 'DIV'
                        : stat.abbreviation,
                  }))
                  .sort((a, b) => a.sortKey.localeCompare(b.sortKey))
                  .map((stat) => ({
                    label: (
                      <div
                        css={css`
                          display: flex;
                          flex-direction: column;
                        `}
                      >
                        <Text
                          variant='paragraphSmall'
                          color={colors.WHITE}
                          weight='regular'
                        >
                          {stat.abbreviation === 'IN-DIV'
                            ? 'DIV'
                            : stat.abbreviation}{' '}
                          ({stat.name})
                        </Text>
                        <Text
                          variant='paragraphXSmall'
                          color={colors.NEUTRAL_50}
                          weight='regular'
                        >
                          {stat.description}
                        </Text>
                      </div>
                    ),
                    value: String(stat.id),
                  }))}
                fieldErrors={fieldErrors}
                customError={customError}
                filterOption={(input, option) => {
                  const statName =
                    option.label.props.children[0]?.props?.children[0]
                  const abbreviation =
                    option.label.props.children[0]?.props?.children[2]
                  if (!input || !statName || !abbreviation) {
                    return false
                  }
                  return (
                    statName
                      .toString()
                      .toLowerCase()
                      .includes(input?.toLowerCase() || '') ||
                    abbreviation
                      .toString()
                      .toLowerCase()
                      .includes(input?.toLowerCase() || '')
                  )
                }}
                showSearch={true}
                disabled={statTypes?.length === 0}
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: space-between;
                  gap: 8px;
                  width: 100%;
                `}
              >
                <Text
                  variant='paragraphXSmall'
                  weight='regular'
                  color={colors.NEUTRAL_50}
                >
                  Secondary Sorting Order: {getTiebreakerStatsText()}
                </Text>
                <button
                  onClick={handleEditTiebreaker}
                  disabled={statTypes?.length === 0}
                  css={css`
                    cursor: pointer;
                    ${statTypes?.length === 0 &&
                    css`
                      opacity: 0.5;
                    `}
                    ${statTypes?.length === 0 &&
                    css`
                      cursor: not-allowed;
                    `}
                  `}
                >
                  <Text
                    variant='paragraphXSmall'
                    weight='regular'
                    color={colors.PRIMARY}
                  >
                    Customize Secondary Sorting Rules
                  </Text>
                </button>
              </div>
            </div>
            {/* <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 8px;
                  width: 100%;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;
                    flex: 1 0 0;
                    width: 100%;
                  `}
                >
                  <SelectInput
                    name='tiebreakerMode'
                    value={getTiebreakerMode()}
                    onChange={handleTiebreakerModeChange}
                    label='Tiebreaker Logic'
                    options={[
                      { label: 'Default', value: 'default' },
                      { label: 'Custom', value: 'custom' },
                    ]}
                    fieldErrors={fieldErrors}
                    customError={customError}
                    disabled={false}
                  />
                  <Text
                    variant='paragraphXSmall'
                    weight='regular'
                    color={colors.NEUTRAL_50}
                  >
                    {getTiebreakerStatsText()}
                  </Text>
                </div>
                {getTiebreakerMode() === 'custom' && (
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 4px;
                      cursor: pointer;
                      border: 1px solid ${colors.PRIMARY};
                      border-radius: 8px;
                      padding: 4px 8px;
                      align-self: center;
                    `}
                    onClick={handleEditTiebreaker}
                  >
                    <Text
                      variant='paragraphMedium'
                      weight='semiBold'
                      color={colors.PRIMARY}
                    >
                      Edit
                    </Text>
                    <EditOutlined
                      css={css`
                        color: ${colors.PRIMARY};
                        font-size: 20px;
                      `}
                    />
                  </div>
                )}
              </div>
            </div> */}
          </div>
        </div>
        <Separator />
        {/* Scoring Settings */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <Text
              variant='captionLarge'
              weight='semiBold'
              color={colors.NEUTRAL_0}
            >
              Scoring Settings
            </Text>
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Require Time of Events'
              subtitle='Require the time of events to be set for all games.'
              value={requireTimeGameEvent}
              onChange={setRequireTimeGameEvent}
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Require Goal Type'
              subtitle='Require the goal type to be set for all goals.'
              value={requireGoalType}
              onChange={setRequireGoalType}
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Require Goal Confirmation Screen'
              subtitle='Display the confirmation screen after a event is recorded.'
              value={requireGoalConfirmation}
              onChange={setRequireGoalConfirmation}
            />
          </div>
        </div>
        <Separator />
        {/* Timing Settings */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <Text
              variant='captionLarge'
              weight='semiBold'
              color={colors.NEUTRAL_0}
            >
              Timing Settings
            </Text>
          </div>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
              ${isMobile &&
              css`
                width: 100%;
                flex-direction: column;
              `}
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'timingPeriodLength'}
                value={
                  timingPeriodLength !== null
                    ? timingPeriodLength.toString()
                    : ''
                }
                setValue={(value) =>
                  setTimingPeriodLength(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Default Period Length'
                type='number'
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'timingExtraPeriodLength'}
                value={
                  timingExtraPeriodLength !== null
                    ? timingExtraPeriodLength.toString()
                    : ''
                }
                setValue={(value) =>
                  setTimingExtraPeriodLength(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Default Overtime Length'
                type='number'
              />
            </div>
          </div>
        </div>
        <Separator />
        {/* Point System */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <Text
              variant='captionLarge'
              weight='semiBold'
              color={colors.NEUTRAL_0}
            >
              Point System
            </Text>
          </div>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
              ${isMobile &&
              css`
                width: 100%;
                flex-direction: column;
              `}
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'wins'}
                value={wins !== null ? wins.toString() : ''}
                setValue={(value) => setWins(value ? parseFloat(value) : null)}
                fieldErrors={fieldErrors}
                customError={customError}
                label='Wins'
                type='number'
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'ties'}
                value={ties !== null ? ties.toString() : ''}
                setValue={(value) => setTies(value ? parseFloat(value) : null)}
                fieldErrors={fieldErrors}
                customError={customError}
                label='Ties'
                type='number'
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'losses'}
                value={losses !== null ? losses.toString() : ''}
                setValue={(value) =>
                  setLosses(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Losses'
                type='number'
              />
            </div>
          </div>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
              ${isMobile &&
              css`
                width: 100%;
                flex-direction: column;
              `}
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'overtimerWin'}
                value={overtimerWin !== null ? overtimerWin.toString() : ''}
                setValue={(value) =>
                  setOvertimeWin(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Overtime Win'
                type='number'
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'overtimeLoss'}
                value={overtimeLoss !== null ? overtimeLoss.toString() : ''}
                setValue={(value) =>
                  setOvertimeLoss(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Overtime Losses'
                type='number'
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'shootoutLoss'}
                value={shootoutLoss !== null ? shootoutLoss.toString() : ''}
                setValue={(value) =>
                  setShootoutLoss(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Shootout Losses'
                type='number'
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'goalsPerForfeit'}
                value={
                  goalsPerForfeit !== null ? goalsPerForfeit.toString() : ''
                }
                setValue={(value) =>
                  setGoalsPerForfeit(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Goals Per Forfeit'
                type='number'
              />
            </div>
          </div>
        </div>
      </div>
      <TiebreakerModal
        isOpen={isTiebreakerModalOpen}
        onClose={handleTiebreakerModalClose}
        onSave={handleTiebreakerModalSave}
        currentStats={tiebreakerStats}
        statTypes={statTypes}
        defaultSortBy={defaultSortBy}
      />
    </div>
  )
}

export default SettingsForm
