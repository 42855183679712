/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'

import Map from '../MapCard/Map'
import { Suspense } from 'react'
import LoadingSpinner from '../LoadingSpinner'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import Icon from '../Icon'

interface Props {
  latitude: number
  longitude: number
  name: string
  description: string
  updatePermission: boolean

  onUpdateClick?: () => void
  onDeleteClick?: () => void
}

export const NewMapCard = ({
  description,
  latitude,
  longitude,
  name,
  onUpdateClick,
  onDeleteClick,
  updatePermission = false,
}: Props) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: calc(100% / 4 - 20px);
        min-height: 300px;
        background-color: ${colors.HEADER};
        overflow: hidden;
        border-radius: 16px;
      `}
    >
      <div
        css={css`
          flex: 1;
          display: flex;
          min-height: 200px;
        `}
      >
        {latitude && longitude ? (
          <Suspense fallback={<LoadingSpinner size={18} />}>
            <Map
              css={css`
                & {
                  flex: 1;
                  border-radius: 4px 4px 0 0; /* TODO: Fix this in Safari/iOS */
                }

                .leaflet-pane,
                .leaflet-top,
                .leaflet-control {
                  z-index: unset;
                }

                .leaflet-marker-icon {
                  font-size: 35px;
                  color: ${colors.HEADER_BG_2};
                }

                .leaflet-bottom {
                  display: none;
                }

                .leaflet-touch .leaflet-bar a {
                  width: 25px;
                  height: 25px;
                  line-height: 20px;
                }
              `}
              position={[latitude, longitude]}
              zoom={15}
              scrollWheelZoom={false}
            />
          </Suspense>
        ) : (
          <div
            css={css`
              flex: 1;
              justify-content: center;
              align-items: center;
              display: flex;
              background-color: ${colors.HEADER_BG_2};
            `}
          >
            <Icon name='map-marked-alt' fontSize={50} color={colors.WHITE} />
          </div>
        )}
      </div>
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 16px;
        `}
      >
        <p
          css={css`
            overflow: hidden;
            color: ${colors.WHITE};
            text-overflow: ellipsis;
            whitespace: nowrap;
            font-family: ${isCanlan
              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
              : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            margin-bottom: 8px;
          `}
        >
          {name}
        </p>
        <p
          css={css`
            overflow: hidden;
            color: ${colors.ATTENDANCE_GRAY};
            text-overflow: ellipsis;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: 0.8px;
          `}
        >
          {description}
        </p>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          flex: 0.1;
          justify-content: space-between;
          padding: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 12px;
          `}
        >
          {updatePermission ? (
            <button onClick={onUpdateClick}>
              <Icon name='pen' color={colors.DEFAULT_FLAIR} fontSize={16} />
            </button>
          ) : null}
          {latitude && longitude && (
            <a
              href={`https://www.openstreetmap.org/#map=15/${latitude}/${longitude}`}
              target='_blank'
              rel='noreferrer'
            >
              <Icon
                name='external-link-alt'
                color={colors.DEFAULT_FLAIR}
                fontSize={16}
              />
            </a>
          )}
        </div>
        {updatePermission ? (
          <button onClick={onDeleteClick}>
            <Icon name='trash' color={colors.ERROR} fontSize={16} />
          </button>
        ) : null}
      </div>
    </div>
  )
}
