/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useMemo, useState } from 'react'
import req from '@sportninja/common/api/request'
import { ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import colors from '@sportninja/common/constants/appColors'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Flex } from '../../components/Layout'
import Bio from '../../components/PageTitle/Bio'
import Picture from '../../components/Picture'
import Highlights from '../../components/Highlights'
import Icon from '../../components/Icon'
import { font } from '../../components/css'
import {
  ProfileMenu,
  ProfileName,
  ProfileTop,
  ProfileWrap,
} from '../Profile/ProfileComponents'
// import StatsBySeason from '../Profile/StatsBySeason'
import StatsBySeason from '../Profile/v2/StatsBySeason'
import dayjs from 'dayjs'

const Player = ({
  match: {
    params: { id },
  },
  isInFrame,
}) => {
  const history = useHistory()
  const [item, setItem] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [showStats, setShowStats] = useState(false)

  const readItem = async () => {
    req(`/players/${id}`).then((response) => {
      setItem(response.data)
      if (response?.data?.is_skater || response?.data?.is_goalie) {
        setShowStats(true)
      }
      setLoaded(true)
    })
  }

  useEffect(() => {
    readItem()
  }, [id])

  const BASE_ROUTE = `${ROUTES.PLAYER_ROOT}/${id}`

  const pages = useMemo(
    () =>
      [
        showStats && {
          text: t('Web:statistics'),
          to: BASE_ROUTE,
          component: (
            <StatsBySeason playerIds={[id]} setShowStats={setShowStats} />
          ),
        },
        // {
        //   text: 'Game Logs',
        //   to: `${BASE_ROUTE}/gamelogs`,
        //   component: <StatsBySeason playerIds={[id]} />,
        // },
        // !isInFrame && {
        //   text: 'Highlights',
        //   to: `${BASE_ROUTE}/highlights`,
        //   component: <Highlights requestIds={[id]} />,
        // },
      ].filter((p) => typeof p !== 'undefined' && typeof p !== 'boolean'),
    [showStats, id, isInFrame]
  )

  return (
    <ProfileWrap baseRoute={BASE_ROUTE} pages={pages} loaded={loaded}>
      {isInFrame && (
        <Flex
          noFlex
          css={css`
            margin: 8px 0;
          `}
        >
          <button
            type='button'
            css={css`
              padding: 8px;
              border-radius: 8px;
              border: 1px solid black;
              color: black;
              ${font.title}
              font-weight: 500;
              font-size: 14px;
            `}
            onClick={history.goBack}
          >
            <Icon
              name='caret-left'
              css={css`
                margin-right: 8px;
              `}
            />
            Back
          </button>
        </Flex>
      )}
      <ProfileTop>
        <Flex
          css={css`
            position: relative;
          `}
        >
          <Picture size='xlarge' imageId={getImageThumbnailId(item)} />
          <Flex
            column
            justifyContent='center'
            css={css`
              margin-left: 16px;
            `}
          >
            <ProfileName item={item} />
            <Bio
              birthDate={item.birth_date}
              height={item.height}
              weight={item.weight}
            />

            {item.is_suspended && (
              <Flex
                noFlex
                alignItems='center'
                css={css`
                  background-color: ${colors.FIERY_RED};
                  height: 32px;
                  border-radius: 8px;
                  padding: 0 12px;
                  margin-top: 16px;
                `}
              >
                <Icon
                  name='exclamation-circle'
                  fontSize={18}
                  css={css`
                    margin-right: 8px;
                  `}
                />
                {item.suspensions?.[0].enforcement?.name === 'indefinite' ||
                !item.suspensions?.[0].end_date
                  ? `${item.suspensions?.[0]?.schedule?.name_full} - Suspended Indefinitely`
                  : `${item.suspensions?.[0]?.schedule?.name_full} - Suspended Until ` +
                    dayjs(item.suspensions?.[0].end_date)
                      .utc()
                      .format('MMM D, YYYY')}
              </Flex>
            )}
          </Flex>
        </Flex>
      </ProfileTop>
      <ProfileMenu baseRoute={BASE_ROUTE} pages={pages} isInFrame={isInFrame} />
    </ProfileWrap>
  )
}

const mapStateToProps = (state) => {
  return {
    isInFrame: state.auth.inFrame,
  }
}
export default connect(mapStateToProps)(Player)
