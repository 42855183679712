import './polyfills' // This import needs to come first
import 'driver.js/dist/driver.css'
import 'react-phone-input-2/lib/style.css'
import React from 'react'
import { I18nContext, I18nManager } from '@shopify/react-i18n'
import { createRoot } from 'react-dom/client'
import '@nosferatu500/react-sortable-tree/style.css'
import rootReducer from '@sportninja/common/reducers'
import rootSagaCreator from '@sportninja/common/sagas'
import configureStore from '@sportninja/common/store'
import { Provider } from 'react-redux'
import { ThemeProvider } from './configs'

import history from './history'
import App from './pages/App'

// This must come before App component import. These are the base imports that are
// overridden by component-specific styles that get imported via <App />
import './index.scss'
import './utility.scss'

// Smart banner app
import SmartBanner from 'react-smartbanner'
import '../../node_modules/react-smartbanner/dist/main.css'
import { init } from './utils/sentry'
import { App as AntApp } from 'antd'

// Suppress console logs in production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const locale = 'en'
const i18nManager = new I18nManager({
  locale,
  onError(error) {
    console.log(error)
  },
})

init()

const store = configureStore(rootReducer, rootSagaCreator(), history)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <ThemeProvider>
      <AntApp
        message={{
          duration: 5,
          className: 'no-transition',
        }}
        style={null}
      >
        <I18nContext.Provider value={i18nManager}>
          <App history={history} />
        </I18nContext.Provider>
        <SmartBanner
          title={'SportNinja'}
          position={'bottom'}
          daysHidden={1}
          daysReminder={1}
        />
      </AntApp>
    </ThemeProvider>
  </Provider>
)
