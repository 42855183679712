import React, { useEffect, useState } from 'react'
import { useContext } from 'react'

import { PusherContext } from '../pages/PusherContext'

export const ScheduleReprocessingContext = React.createContext(undefined)

const ScheduleReprocessingContextProvider = ({ children, user }) => {
  const [listeners, setListeners] = useState({})
  const [isListening, setIsListening] = useState(false)
  const { client, isReady } = useContext(PusherContext)

  const addPending = (scheduleId) => {
    if (isListening) {
      setListeners((p) => ({ ...p, [scheduleId]: { progress: 0 } }))
    }
  }

  useEffect(() => {
    let channel

    if (isReady && client && user?.id) {
      channel = client.subscribe(`notification.${user.id}`)

      channel.bind('notification', (data = {}) => {
        if (
          data?.message?.type === 'reprocessStats' &&
          data?.message?.data?.schedule_id
        ) {
          setListeners((p) => {
            const copy = { ...p }

            copy[data.message.data.schedule_id] = {
              progress: data.message.data.progress_in_percent,
              count: data.message.data.count,
            }

            return copy
          })

          if (data.message.data.progress_in_percent === 100) {
            setTimeout(() => {
              setListeners((p) => {
                const copy = { ...p }
                delete copy[data.message.data.schedule_id]
                return copy
              })
            }, 1000)
          }
        }
        if (
          data?.message?.type === 'daysmartProcessing' &&
          data?.message?.data?.schedule_id
        ) {
          setListeners((p) => {
            const copy = { ...p }

            copy[data.message.data.schedule_id] = {
              progress: data.message.data.progress_in_percent,
              count: data.message.data.count,
            }

            return copy
          })

          if (data.message.data.progress_in_percent === 100) {
            setTimeout(() => {
              setListeners((p) => {
                const copy = { ...p }
                delete copy[data.message.data.schedule_id]
                return copy
              })
            }, 1000)
          }
        }
      })

      channel.bind('pusher:subscription_error', (err) => {
        console.error('subscription error', err)
        setIsListening(false)
      })

      channel.bind('pusher:subscription_succeeded', (message) => {
        if (__DEV__) {
          console.log(
            `[ws] subscribed to channel: notification.${user.id}`,
            message
          )
        }
        setIsListening(true)
      })
    }

    return () => {
      channel?.unbind('pusher:subscription_error')
      channel?.unbind('pusher:subscription_succeeded')
      channel?.unbind('notification')

      if (client?.connection?.state === 'connected') {
        client?.unsubscribe(`notification.${user?.id}`)

        if (__DEV__) {
          console.log(
            '[ws] disconnected from channel',
            `notification.${user?.id}`
          )
        }
      }
    }
  }, [client, isReady, user?.id])

  const value = {
    listeners,
    addPending,
  }

  return (
    <ScheduleReprocessingContext.Provider value={value}>
      {children}
    </ScheduleReprocessingContext.Provider>
  )
}

export default ScheduleReprocessingContextProvider
