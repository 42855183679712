import { useState, useCallback, useEffect } from 'react'
import req from '../api/request'
import { UpcomingGame } from '../types/PlayerAttendance'

interface AttendanceState {
  attendance: UpcomingGame[]
  isLoading: boolean
  error: string | null
}

interface UseAttendanceResult {
  attendanceState: AttendanceState
  readAttendanceInformation: (refresh?: boolean) => Promise<void>
}

export const useAttendance = (): UseAttendanceResult => {
  const [attendanceState, setAttendanceState] = useState<AttendanceState>({
    attendance: [],
    isLoading: false,
    error: null,
  })

  const readAttendanceInformation = useCallback(async (refresh = true) => {
    if (refresh) {
      setAttendanceState((prevState) => ({
        ...prevState,
        isLoading: true,
        error: null,
      }))
    }

    try {
      const attendanceData: UpcomingGame[] = []

      const { data: playerAttendanceData } = await req(
        '/home/player-attendance'
      )
      if (playerAttendanceData?.upcomingGames) {
        attendanceData.push(...playerAttendanceData.upcomingGames)
      }

      // Fetch team official attendance data
      const { data: teamOfficialAttendanceData } = await req(
        '/home/team-official-attendance'
      )
      if (teamOfficialAttendanceData?.upcomingGames) {
        teamOfficialAttendanceData.upcomingGames.forEach(
          (teamOfficialAttendance: UpcomingGame) => {
            const index = attendanceData.findIndex(
              (attendance) => attendance.id === teamOfficialAttendance.id
            )
            if (index !== -1) {
              attendanceData[index].myTeamOfficials =
                teamOfficialAttendance.myTeamOfficials
              attendanceData[index].show_waiver =
                teamOfficialAttendance.show_waiver
            } else {
              attendanceData.push(teamOfficialAttendance)
            }
          }
        )
      }

      // Sort and filter games
      const sortedGames = attendanceData
        .sort(
          (a, b) =>
            new Date(a.starts_at).getTime() - new Date(b.starts_at).getTime()
        )
        .filter(
          (game) =>
            (game.myPlayers?.length ?? 0) > 0 ||
            (game.myTeamOfficials?.length ?? 0) > 0
        )

      setAttendanceState((prevState) => ({
        ...prevState,
        attendance: sortedGames,
        isLoading: false,
        error: null,
      }))
    } catch (e) {
      setAttendanceState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: 'An error occurred when trying to read attendance',
      }))
    }
  }, [])

  useEffect(() => {
    readAttendanceInformation()
  }, [readAttendanceInformation])

  return { attendanceState, readAttendanceInformation }
}
