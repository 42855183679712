/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Text from 'src/components/ts-components/Text'
import emailIcon from './email_icon.svg'

/**
 * Props for the CheckYourEmail component
 * @typedef {Object} CheckYourEmailProps
 * @property {string} userEmail - The email address of the user
 * @property {number} timer - Timer for resend email functionality
 * @property {Function} onResendEmail - Function to handle resending email
 */
type CheckYourEmailProps = {
  userEmail: string
  timer: number
  onResendEmail: (email: string) => void
}

/**
 * CheckYourEmail component for the underage invitation flow
 * Displays a message to check email and provides option to resend verification email
 *
 * @param {CheckYourEmailProps} props - Component props
 * @returns {JSX.Element} The rendered component
 */
const CheckYourEmail = ({
  userEmail,
  timer,
  onResendEmail,
}: CheckYourEmailProps) => {
  return (
    <div
      css={css`
        padding-top: 32px;
        width: 100%;
        @media (max-width: 768px) {
          width: 100%;
        }
      `}
    >
      <img alt='Email Icon' src={emailIcon} />
      <Text
        variant='displaySmall'
        weight='bold'
        color={colors.NEUTRAL_0}
        style={`
          margin-top: 32px;
        `}
      >
        check your email
      </Text>
      <Text
        variant='paragraphMedium'
        weight='regular'
        color={colors.NEUTRAL_0}
        style={`
          margin-top: 32px;
          line-height: 120%;
        `}
      >
        Great news! We&apos;ve sent a confirmation email to your inbox. <br />
        All you need to do is click the link inside to activate your account.
      </Text>
      <span onClick={() => (timer > 0 ? {} : onResendEmail(userEmail))}>
        <Text
          variant='paragraphMedium'
          weight='regular'
          style={`
            cursor: ${timer > 0 ? 'not-allowed' : 'pointer'};
            margin-top: 16px;
            line-height: 120%;
            color: ${timer > 0 ? colors.ATTENDANCE_GRAY : colors.DEFAULT_FLAIR};
          `}
        >
          Resend email {timer > 0 && ` (${timer})`}
        </Text>
      </span>
    </div>
  )
}

export default CheckYourEmail
