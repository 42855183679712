/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { paragraphSmall, weightBold, weightRegular } from '../css'

type EventProgressProps = {
  eventProgress: 1 | 2 | 3 | 4 | 5 | 6
  isWaiverRequired?: boolean
  isTeamRep?: boolean
}

export const EventProgress = ({
  eventProgress = 1,
  isWaiverRequired = false,
  isTeamRep = false,
}: EventProgressProps) => {
  // Helper function to get the step number based on conditions
  const getStepNumber = (baseNumber: number) => {
    if (isTeamRep) {
      return baseNumber - 1 // Team rep starts at 1 and skips waiver
    }
    if (!isWaiverRequired && baseNumber > 3) {
      return baseNumber - 1 // Skip waiver number in sequence
    }
    return baseNumber
  }

  return (
    <div
      css={css`
        margin-top: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        @media (max-width: 768px) {
          flex-direction: column;
          gap: 16px;
          width: 100%;
        }
        padding: 16px;
        border-radius: 8px;
        background: rgba(51, 54, 59, 0.2);
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <span
          css={css`
            ${eventProgress === 1
              ? css`
                  ${paragraphSmall}
                  ${weightBold}
                `
              : css`
                  ${paragraphSmall}
                  ${weightRegular}
                `}
            color: ${eventProgress === 1 ? colors.PRIMARY : colors.WHITE};
          `}
        >
          1. Credentials
        </span>
        <div
          css={css`
            height: 4px;
            align-self: stretch;
            border-radius: 8px;
            background: ${colors.PRIMARY};
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <span
          css={css`
            ${eventProgress === 2
              ? css`
                  ${paragraphSmall}
                  ${weightBold}
                `
              : css`
                  ${paragraphSmall}
                  ${weightRegular}
                `}
            color: ${eventProgress === 2
              ? colors.PRIMARY
              : eventProgress >= 2
              ? colors.WHITE
              : colors.STORM_GRAY};
          `}
        >
          2. Validation
        </span>
        <div
          css={css`
            height: 4px;
            align-self: stretch;
            border-radius: 8px;
            background: ${eventProgress === 2
              ? colors.PRIMARY
              : eventProgress >= 2
              ? colors.PRIMARY
              : colors.CHARADE};
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <span
          css={css`
            ${eventProgress === 3
              ? css`
                  ${paragraphSmall}
                  ${weightBold}
                `
              : css`
                  ${paragraphSmall}
                  ${weightRegular}
                `}
            color: ${eventProgress === 3
              ? colors.PRIMARY
              : eventProgress >= 3
              ? colors.WHITE
              : colors.STORM_GRAY};
          `}
        >
          3. Register
        </span>
        <div
          css={css`
            height: 4px;
            align-self: stretch;
            border-radius: 8px;
            background: ${eventProgress >= 3 ? colors.PRIMARY : colors.CHARADE};
          `}
        />
      </div>
      {!isTeamRep && isWaiverRequired && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1 0 0;
            @media (max-width: 768px) {
              width: 100%;
            }
          `}
        >
          <span
            css={css`
              ${eventProgress === 4
                ? css`
                    ${paragraphSmall}
                    ${weightBold}
                  `
                : css`
                    ${paragraphSmall}
                    ${weightRegular}
                  `}
              color: ${eventProgress === 4
                ? colors.PRIMARY
                : eventProgress >= 4
                ? colors.WHITE
                : colors.STORM_GRAY};
            `}
          >
            4. Waiver
          </span>
          <div
            css={css`
              height: 4px;
              align-self: stretch;
              border-radius: 8px;
              background: ${eventProgress >= 4
                ? colors.PRIMARY
                : colors.CHARADE};
            `}
          />
        </div>
      )}

      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <span
          css={css`
            ${eventProgress === 5
              ? css`
                  ${paragraphSmall}
                  ${weightBold}
                `
              : css`
                  ${paragraphSmall}
                  ${weightRegular}
                `}
            color: ${eventProgress === 5
              ? colors.PRIMARY
              : eventProgress >= 5
              ? colors.WHITE
              : colors.STORM_GRAY};
          `}
        >
          {getStepNumber(5)}. Payment
        </span>
        <div
          css={css`
            height: 4px;
            align-self: stretch;
            border-radius: 8px;
            background: ${eventProgress >= 5 ? colors.PRIMARY : colors.CHARADE};
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <span
          css={css`
            ${eventProgress === 6
              ? css`
                  ${paragraphSmall}
                  ${weightBold}
                `
              : css`
                  ${paragraphSmall}
                  ${weightRegular}
                `}
            color: ${eventProgress === 6
              ? colors.PRIMARY
              : eventProgress >= 6
              ? colors.WHITE
              : colors.STORM_GRAY};
          `}
        >
          {getStepNumber(6)}. Confirmation
        </span>
        <div
          css={css`
            height: 4px;
            align-self: stretch;
            border-radius: 8px;
            background: ${eventProgress >= 6 ? colors.PRIMARY : colors.CHARADE};
          `}
        />
      </div>
    </div>
  )
}
