import React, { useCallback, useEffect, useState } from 'react'
import { getConfig } from '../get-import-config'
import BaseActionButton from '../../Page/BaseActionButton'
import Modal from '../../Modal'
import ImportOfficialsContent from './ImportOfficialsContent'

const ImportOfficials = ({
  teamId,
  teamName,
  teamRosterId,
  onSuccess,
  orgId,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const importTemplate = getConfig().officials
  const onToggle = () => {
    setIsOpen(!isOpen)
  }
  const isMultiTeamRoster = orgId ? true : false

  const handleEsc = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false)
      }
    },
    [setIsOpen]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [handleEsc])

  const link = (
    <BaseActionButton
      name={'upload'}
      title={importTemplate?.label}
      onClick={onToggle}
      disabled={loading}
      busy={loading}
    />
  )

  return (
    <>
      {link}
      <Modal
        isOpen={isOpen}
        onClose={onToggle}
        shouldCloseOnOverlayClick={false}
      >
        {isOpen && (
          <ImportOfficialsContent
            setLoading={setLoading}
            loading={loading}
            toggle={onToggle}
            importTemplate={importTemplate}
            teamName={teamName}
            teamRosterId={teamRosterId}
            teamId={teamId}
            onSuccess={onSuccess}
            orgId={orgId}
            isMultiTeamRoster={isMultiTeamRoster}
          />
        )}
      </Modal>
    </>
  )
}

export default ImportOfficials
