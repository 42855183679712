/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useMemo } from 'react'
import Text from 'src/components/ts-components/Text'
import colors from '@sportninja/common/constants/appColors'
import { Modal } from 'antd'
import useWindowSize from 'src/hooks/useWindowSize'
import { CloseOutlined } from '@ant-design/icons'
import { StatType } from '@sportninja/common/types'

interface TieBreakingProcedureModalProps {
  isOpen: boolean
  onClose: () => void
  tieBreakingStats: number[]
  statTypes: StatType[]
  defaultSortBy: number | null
}

const styles = css`
  .my-modal-body {
  }
  .my-modal-mask {
    backdrop-filter: blur(1px);
  }
  .my-modal-header {
    border-bottom: 1px dotted ${colors.FIERY_RED};
  }
  .my-modal-footer {
    color: ${colors.FIERY_RED};
  }
  .my-modal-content {
    border: 1px solid #333;
  }
`

const classNames = {
  body: styles['my-modal-body'],
  mask: styles['my-modal-mask'],
  header: styles['my-modal-header'],
  footer: styles['my-modal-footer'],
  content: styles['my-modal-content'],
}

const modalStyles = {
  header: {
    backgroundColor: colors.SECONDARY_300,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  body: {
    borderBottom: `1px solid ${colors.SOFT_STEEL}`,
    borderTop: `1px solid ${colors.SOFT_STEEL}`,
    backgroundColor: colors.SECONDARY_300,
  },
  content: {
    backgroundColor: colors.SECONDARY_300,
    paddingTop: '8px',
    paddingBottom: '16px',
    paddingLeft: '0',
    paddingRight: '0',
  },
  wrapper: {
    zIndex: 10002,
  },
  mask: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(6, 10, 16, 0.8)',
    zIndex: 10001,
  },
  footer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

const TieBreakingProcedureModal: React.FC<TieBreakingProcedureModalProps> = ({
  isOpen,
  onClose,
  tieBreakingStats,
  statTypes,
  defaultSortBy,
}) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width <= 768, [width])

  // Get the primary sort stat
  const primarySortStat = useMemo(() => {
    return statTypes?.find((stat) => stat.id === defaultSortBy)
  }, [defaultSortBy, statTypes])

  // Get the tie-breaking stats in order
  const orderedTieBreakingStats = useMemo(() => {
    return tieBreakingStats
      ?.map((statId) => statTypes?.find((stat) => stat.id === statId))
      .filter(Boolean)
  }, [tieBreakingStats, statTypes])

  const getHeader = () => {
    return (
      <Text variant='heading3' weight='semiBold' color={colors.WHITE}>
        Tie-Breaking Procedure
      </Text>
    )
  }

  const closeIcon = () => {
    return (
      <CloseOutlined
        css={css`
          color: ${colors.SOLITUDE};
          font-size: 18px;
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            color: ${colors.WHITE};
          }
        `}
      />
    )
  }

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      classNames={classNames}
      styles={modalStyles}
      css={css`
        display: flex;
        flex: 1;
        background: ${colors.SECONDARY_400} !important;
        height: ${isMobile ? '100vh' : '60vh'} !important;
        max-height: ${isMobile ? '100vh' : '1080px'} !important;
        margin: ${isMobile ? '0' : '24px'};
        padding: 0;
        max-width: ${isMobile ? '100%' : '40vw'};
        top: ${isMobile ? '0' : '50%'};
        .ant-modal-content {
          height: ${isMobile ? '100vh' : '60vh'} !important;
          max-height: ${isMobile ? '100vh' : '1080px'} !important;
          border-radius: ${isMobile ? '0' : '8px'};
          display: flex;
          flex-direction: column;
        }
        .ant-modal-body {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          padding: 24px;
          background: ${colors.SECONDARY_300};
        }
      `}
      width={isMobile ? '100%' : '40vw'}
      centered={!isMobile}
      maskClosable={false}
      closeIcon={closeIcon()}
      title={getHeader()}
      footer={null}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 0 8px;
          height: 100%;
          overflow-y: auto;
        `}
      >
        <Text
          variant='paragraphMedium'
          color={colors.NEUTRAL_0}
          weight='regular'
        >
          If two or more teams are tied in during the regular season, the
          standing of the teams is determined in the following order:
        </Text>
        <div
          css={css`
            width: 100%;
            border-bottom: 1px solid ${colors.SECONDARY_200};
          `}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 24px;
          `}
        >
          {/* Primary Sort Stat */}
          {primarySortStat && (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 4px;
              `}
            >
              <Text
                variant='paragraphSmall'
                color={colors.WHITE}
                weight='regular'
              >
                1. {primarySortStat.name} ({primarySortStat.abbreviation})
              </Text>
              <Text
                variant='paragraphXSmall'
                color={colors.NEUTRAL_40}
                weight='regular'
              >
                {primarySortStat.description}
              </Text>
            </div>
          )}

          {/* Tie-Breaking Stats */}
          {orderedTieBreakingStats?.map((stat, index) => (
            <div
              key={stat?.id}
              css={css`
                display: flex;
                flex-direction: column;
                gap: 4px;
              `}
            >
              <Text
                variant='paragraphSmall'
                color={colors.WHITE}
                weight='regular'
              >
                {index + 2}. {stat?.name} ({stat?.abbreviation})
              </Text>
              <Text
                variant='paragraphXSmall'
                color={colors.NEUTRAL_40}
                weight='regular'
              >
                {stat?.description}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default TieBreakingProcedureModal
