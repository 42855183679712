import req from '@sportninja/common/api/request'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  AnalyticsData,
  DetailedRegistrationData,
  RegistrationTypes,
} from '../types/Registration'

interface TypeObject {
  [key: string]: []
}

export function extractValues(data: TypeObject[]): RegistrationTypes {
  const extracted: any = {}
  data.forEach((item) => {
    const key = Object.keys(item)[0]
    const mappedKey = key.replace('_type', '')
    extracted[mappedKey] = item[key]
  })
  return extracted as RegistrationTypes
}

export type RegistrationData = {
  detailed: DetailedRegistrationData
  analytics: AnalyticsData
}

type CreateRegistrationProps = {
  scheduleId: string
  allowPlayerRegistration: boolean
  registrationType: string
  enableRegistration: boolean
  eventNumber: string
  currency: number
  costPerRegistration: number
  paymentCurrency: number
  paymentMethod: number
  goLive: boolean
  dateRange: {
    starts_at: string
    ends_at: string
  }
  useDarkTheme: boolean
  maxTeamSlots: number
  defaultTaxes: string[]
  bccEmails: string[]
  paymentProvider: number
  autoApproveRegistrations: boolean
  enableTeamRegistration: boolean
  enablePlayerRegistration: boolean
  show_waiver: boolean
  waivers: string[]
  addPlayersOutsideOfRegistration: boolean
}

type DivisionRegistrationProps = {
  scheduleId: string
  currency: number
  dayOfWeek: number
  divisionGender: number
  maxTeams: number | null
  deposit: number | null
  teamCost: number | null
  divisionAge: number
  maxPlayersPerTeam: number | null
  singlePayment: boolean
  maxTeamRepPerTeam: number | null
  installments: number | null
  installmentStartDate: string | null
  installmentFrequency: string | null
  depositRequired: boolean
  playerInstallments: number | null
  playerInstallmentFrequency: string | null
  playerInstallmentStartDate: string | null
  playerDepositRequired: boolean
  playerCostPerRegistration: number | null
  playerDeposit: number | null
  playerSinglePayment: boolean
  defaultTaxes: string[]
  maxPlayersPerBucket: number | null
  showExistingRegistrationsTeams: boolean
  showExistingRegistrationsPlayers: boolean
}

type UpdateTeamRegistrationStatusProps = {
  scheduleId: string
  status: number
  conditions?: string | null
  conditionType?: number | null
  divisionId?: string
  teamRegistrationId: string
  team_registration_id?: string
}

type UpdatePlayerRegistrationStatusProps = {
  scheduleId: string
  status: number
  conditions?: string | null
  conditionType?: number | null
  divisionId?: string
  teamRegistrationId: string
}

export const useRegistrationData = (scheduleId?: string) => {
  const [data, setData] = useState<RegistrationData | null>(null)
  const [types, setTypes] = useState<RegistrationTypes | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)
  const [analytics, setAnalytics] = useState<AnalyticsData | null>(null)

  const isRegistrationEmpty = useMemo(() => {
    return (
      !data?.detailed?.registration?.starts_at ||
      data?.detailed?.divisions?.length === 0
    )
  }, [data])

  const fetchTypes = useCallback(async () => {
    try {
      const response = await req('/types/registration')
      setTypes(extractValues(response))
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error('An error occurred while fetching types')
      )
    }
  }, [])

  const uploadRegistrationImage = useCallback(
    async (schedule_id: string, imageData: any) => {
      try {
        await req(`/registration/${schedule_id}/admin/image`, {
          method: 'POST',
          body: imageData,
        })
      } catch (err) {
        throw err instanceof Error
          ? err
          : new Error('An error occurred while uploading registration image')
      }
    },
    []
  )

  const deleteRegistrationImage = useCallback(async (schedule_id: string) => {
    try {
      await req(`/registration/${schedule_id}/admin/image`, {
        method: 'DELETE',
      })
    } catch (err) {
      throw err instanceof Error
        ? err
        : new Error('An error occurred while deleting registration image')
    }
  }, [])

  const createDivisionRegistration = useCallback(
    async (props: DivisionRegistrationProps) => {
      setLoading(true)
      setError(null)
      try {
        const response = await req(
          `/registration/${props.scheduleId}/admin/division`,
          {
            method: 'POST',
            body: JSON.stringify({
              currency: props.currency,
              day_of_week: props.dayOfWeek,
              division_gender: props.divisionGender,
              max_teams: props.maxTeams,
              max_players_per_bucket: props.maxPlayersPerBucket,
              deposit: props.deposit,
              team_cost: props.teamCost,
              division_age: props?.divisionAge,
              max_players_per_team: props.maxPlayersPerTeam,
              single_payment: props.singlePayment,
              max_team_rep_per_team: props.maxTeamRepPerTeam,
              installments: props.installments,
              installment_start_date: props.installmentStartDate,
              installment_frequency: props.installmentFrequency,
              deposit_required: props.depositRequired,
              player_installments: props.playerInstallments,
              player_installment_start_date: props.playerInstallmentStartDate,
              player_installment_frequency: props.playerInstallmentFrequency,
              player_deposit_required: props.playerDepositRequired,
              player_cost: props.playerCostPerRegistration,
              player_deposit: props.playerDeposit,
              player_single_payment: props.playerSinglePayment,
              taxes: props.defaultTaxes?.map((el) => ({ id: el })) || [],
              show_existing_registrations_teams:
                props?.showExistingRegistrationsTeams || false,
              show_existing_registrations_players:
                props?.showExistingRegistrationsPlayers || false,
            }),
          }
        )
        return response.data
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error(
                'An error occurred while creating division registration'
              )
        )
        throw err
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const updateDivisionRegistration = useCallback(
    async (props: DivisionRegistrationProps) => {
      setLoading(true)
      setError(null)
      try {
        const response = await req(
          `/registration/${props.scheduleId}/admin/division`,
          {
            method: 'PUT',
            body: JSON.stringify({
              currency: props.currency,
              day_of_week: props.dayOfWeek,
              division_gender: props.divisionGender,
              max_teams: props.maxTeams,
              max_players_per_bucket: props.maxPlayersPerBucket,
              deposit: props.deposit,
              team_cost: props.teamCost,
              division_age: props.divisionAge,
              max_players_per_team: props.maxPlayersPerTeam,
              single_payment: props.singlePayment,
              max_team_rep_per_team: props.maxTeamRepPerTeam,
              installments: props.installments,
              installment_start_date: props.installmentStartDate,
              installment_frequency: props.installmentFrequency,
              deposit_required: props.depositRequired,
              player_installments: props.playerInstallments,
              player_installment_start_date: props.playerInstallmentStartDate,
              player_installment_frequency: props.playerInstallmentFrequency,
              player_deposit_required: props.playerDepositRequired,
              player_cost: props.playerCostPerRegistration,
              player_deposit: props.playerDeposit,
              player_single_payment: props.playerSinglePayment,
              taxes: props.defaultTaxes?.map((el) => ({ id: el })) || [],
              show_existing_registrations_teams:
                props?.showExistingRegistrationsTeams || false,
              show_existing_registrations_players:
                props?.showExistingRegistrationsPlayers || false,
            }),
          }
        )
        return response.data
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error(
                'An error occurred while updating division registration'
              )
        )
        throw err
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const createRegistration = useCallback(
    async (props: CreateRegistrationProps) => {
      setLoading(true)
      setError(null)
      try {
        const response = await req(`/registration/${props.scheduleId}/admin`, {
          method: 'POST',
          body: JSON.stringify({
            auto_approve: props?.autoApproveRegistrations || false,
            is_player_registration: props?.enablePlayerRegistration || false,
            is_team_registration: props?.enableTeamRegistration || false,
            payment_provider: props?.paymentProvider || 0,
            player_registration_allowed:
              props?.allowPlayerRegistration || false,
            registration_type_id: props?.registrationType === 'team' ? 1 : 2,
            is_registration_on: props?.enableRegistration || false,
            official_governing_body_event_number: props.eventNumber,
            currency: props.currency,
            payment_currency: props.paymentCurrency,
            payment_type: props.paymentMethod,
            is_live: props.goLive,
            starts_at: props.dateRange.starts_at,
            ends_at: props.dateRange.ends_at,
            custom_link: 'SportNinja',
            dark_theme: props.useDarkTheme,
            push_notification: false,
            max_teams: props.maxTeamSlots || 0,
            taxes: props.defaultTaxes?.map((el) => ({ id: el })) || [],
            bcc_registrations_to: props.bccEmails
              ? props.bccEmails?.join(',')
              : '',
            show_waiver: props.show_waiver,
            waivers: props.show_waiver
              ? props.waivers?.map((el) => ({ id: el })) || []
              : [],
            can_add_players_from_roster:
              props?.addPlayersOutsideOfRegistration || false,
            show_existing_registrations_teams: true,
            show_existing_registrations_players: true,
          }),
        })
        return response.data
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error('An error occurred while creating registration')
        )
        throw err
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const updateRegistration = useCallback(
    async (props: CreateRegistrationProps) => {
      setLoading(true)
      setError(null)
      try {
        const response = await req(`/registration/${props.scheduleId}/admin`, {
          method: 'PUT',
          body: JSON.stringify({
            auto_approve: props?.autoApproveRegistrations,
            is_player_registration: props?.enablePlayerRegistration,
            is_team_registration: props?.enableTeamRegistration,
            payment_provider: props?.paymentProvider,
            player_registration_allowed: props?.allowPlayerRegistration,
            registration_type_id: props?.registrationType === 'team' ? 1 : 2,
            is_registration_on: props?.enableRegistration,
            official_governing_body_event_number: props.eventNumber,
            currency: props.currency,
            payment_currency: props.paymentCurrency,
            payment_type: props.paymentMethod,
            is_live: props.goLive,
            starts_at: props.dateRange.starts_at,
            ends_at: props.dateRange.ends_at,
            custom_link: 'SportNinja',
            dark_theme: props.useDarkTheme,
            push_notification: false,
            max_teams: props.maxTeamSlots || 0,
            taxes: props.defaultTaxes?.map((el) => ({ id: el })) || [],
            bcc_registrations_to: props.bccEmails
              ? props.bccEmails?.join(',')
              : '',
            show_waiver: props.show_waiver,
            waivers: props.show_waiver
              ? props.waivers?.map((el) => ({ id: el })) || []
              : [],
            can_add_players_from_roster:
              props?.addPlayersOutsideOfRegistration || false,
            show_existing_registrations_teams: true,
            show_existing_registrations_players: true,
          }),
        })
        return response.data
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error('An error occurred while updating registration')
        )
        throw err
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const updateTeamRegistrationStatus = useCallback(
    async (props: UpdateTeamRegistrationStatusProps) => {
      setError(null)
      try {
        const body = {
          team_registration_id:
            props.teamRegistrationId || props.team_registration_id,
          conditions: props.conditions,
          condition_type_id: props.conditionType,
          status: props.status,
        }
        if (props.divisionId) {
          ;(body as any).division_id = props.divisionId
        }

        const response = await req(
          `/registration/${props.scheduleId}/admin/team-status`,
          {
            method: 'PUT',
            body: JSON.stringify(body),
          }
        )

        return response.data
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error(
                'An error occurred while updating team registration status'
              )
        )
        throw err
      }
    },
    []
  )

  const updatePlayerRegistrationStatus = useCallback(
    async (props: UpdatePlayerRegistrationStatusProps) => {
      setError(null)
      try {
        const body = {
          team_registration_id: props.teamRegistrationId,
          status: props.status,
          conditions: props.conditions,
          condition_type_id: props.conditionType,
        }
        if (props.divisionId) {
          ;(body as any).division_id = props.divisionId
        }

        const response = await req(
          `/registration/${props.scheduleId}/admin/player-status`,
          {
            method: 'PUT',
            body: JSON.stringify(body),
          }
        )

        return response.data
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error(
                'An error occurred while updating player registration status'
              )
        )
        throw err
      }
    },
    []
  )

  const fetchData = useCallback(
    async (displayLoading = true) => {
      if (!scheduleId) {
        return
      }

      if (displayLoading) {
        setLoading(true)
      }
      setError(null)
      try {
        const detailedResponse = await req(
          `/registration/${scheduleId}/admin/detailed-new`
        )
        const infoResponse = await req(`/registration/${scheduleId}/admin/info`)
        let analyticsResponse: any
        // We are doing this, since this should not fail if analytics API call fails
        try {
          analyticsResponse = await req(
            `/registration/${scheduleId}/admin/analytics`
          )
        } catch (err: any) {
          console.log(err)
        }
        detailedResponse.data.registration = infoResponse.data
        detailedResponse.data.available_taxes =
          infoResponse.data.available_taxes
        setAnalytics(analyticsResponse?.data || {})
        setData({
          detailed: detailedResponse.data,
          analytics: analyticsResponse?.data || {},
        })
      } catch (err: any) {
        setError(err)
      } finally {
        if (displayLoading) {
          setLoading(false)
        }
      }
    },
    [scheduleId]
  )

  const fetchAnalytics = useCallback(async () => {
    if (!scheduleId) {
      return
    }
    try {
      const analyticsResponse = await req(
        `/registration/${scheduleId}/admin/analytics`
      )
      setAnalytics(analyticsResponse.data || {})
    } catch (err: any) {
      // This is bad, but we need to handle it
      console.log(err)
    }
  }, [scheduleId])

  useEffect(() => {
    if (scheduleId) {
      fetchData()
    }
  }, [fetchData, scheduleId])

  return {
    data,
    loading,
    error,
    refetch: fetchData,
    isRegistrationEmpty,
    types,
    availableTaxes: data?.detailed?.available_taxes || [],
    createRegistration,
    updateRegistration,
    uploadRegistrationImage,
    deleteRegistrationImage,
    createDivisionRegistration,
    updateDivisionRegistration,
    updateTeamRegistrationStatus,
    updatePlayerRegistrationStatus,
    fetchTypes,
    fetchAnalytics,
    analytics,
  }
}
