/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Flex } from '../Layout'
import colors from '@sportninja/common/constants/appColors'
import GenericTable from '../GenericTable'
import Icon from '../Icon'
import req from '@sportninja/common/api/request'
import LoadingSpinner from '../LoadingSpinner'
import dayjs from 'dayjs'
import { NewSelect } from './RosterSelect'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import RegistrationIndicatorCard from '../../pages/Schedule/Registration/RegistrationIndicatorCard'
import { NewModal, modalRef } from '../NewModal'
import BannerController from '../Banner/BannerController'
import Banner from '../Banner'
import { useI18n } from '@shopify/react-i18n'
import { Link, useHistory } from 'react-router-dom'
import { ROUTES } from '@sportninja/common/constants/app'
import { useMobxStore } from 'src/state'
import { Tooltip } from 'antd'
import { useUrlManager } from 'src/hooks/useUrlManager'

const defaultCurrencies = {
  1: 'CAD',
  2: 'USD',
}

export const StatusBadge = ({
  status,
  options,
  outline,
  optionsValues = undefined,
}) => {
  switch (status) {
    case options.yellow:
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            padding: 6px 20px;
            border-radius: 20px;
            background-color: ${outline ? 'transparent' : colors.CAUTION_DARK};
            border: 1px solid ${outline ? colors.CAUTION_DARK : 'transparent'};
          `}
        >
          {!outline && (
            <Icon
              name='adjust'
              color={colors.WHITE}
              css={css`
                margin-right: 9px;
              `}
            />
          )}
          <span
            css={css`
              color: ${colors.WHITE};
            `}
          >
            {optionsValues.yellow || 'Error'}
          </span>
        </div>
      )
    case options.red:
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            padding: 6px 20px;
            border-radius: 20px;
            background-color: ${colors.ERROR};
            background-color: ${outline ? 'transparent' : colors.ERROR};
            border: 1px solid ${outline ? colors.ERROR : 'transparent'};
          `}
        >
          {!outline && (
            <Icon
              name='times-circle'
              color={colors.WHITE}
              css={css`
                margin-right: 9px;
              `}
            />
          )}
          <span
            css={css`
              color: ${colors.WHITE};
            `}
          >
            {optionsValues.red || 'Pending'}
          </span>
        </div>
      )
    case options.blue:
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            padding: 6px 20px;
            border-radius: 20px;
            background-color: ${colors.INFO_200};
            background-color: ${outline ? 'transparent' : colors.INFO_200};
            border: 1px solid ${outline ? colors.INFO_200 : 'transparent'};
          `}
        >
          {!outline && (
            <Icon
              name='info-circle'
              color={colors.WHITE}
              css={css`
                margin-right: 9px;
              `}
            />
          )}
          <span
            css={css`
              color: ${colors.WHITE};
            `}
          >
            {optionsValues.blue || 'Refunded'}
          </span>
        </div>
      )
    case options.green:
    default:
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            padding: 6px 20px;
            border-radius: 20px;
            background-color: ${outline ? 'transparent' : colors.DARK_GREEN};
            border: 1px solid ${outline ? colors.DARK_GREEN : 'transparent'};
          `}
        >
          {!outline && (
            <Icon
              name='check'
              color={colors.WHITE}
              css={css`
                margin-right: 9px;
              `}
            />
          )}
          <span
            css={css`
              color: ${colors.WHITE};
            `}
          >
            {optionsValues.green || 'Paid'}
          </span>
        </div>
      )
  }
}

async function fetchTeamRosters(teamId) {
  const response = await req(`/teams/${teamId}/rosters`)

  return response
}

async function fetchTeamRosterBilling(teamId, rosterId) {
  const response = await req(`/teams/${teamId}/rosters/${rosterId}/billing`)

  return response.data
}

export async function fetchBillingInfo(billingId) {
  const response = await req(`/billing/schedule/${billingId}/receipt`)
  return response.data
}

async function fetchPaymentInfo() {
  const response = await req('/payment/method')
  return response
}

export function calculatePastDues(billingDetails) {
  let total = 0

  billingDetails.forEach((el) => {
    if (el.paid) {
      return
    }

    const day = dayjs(el?.due_date, 'YYYY-MM-DD')
    const isAfter = dayjs().isAfter(day)

    if (isAfter) {
      total += el.subtotal
    }
  })

  return total
}

const FinancialList = observer(({ teamId, canEdit }) => {
  const { setParam, currentQueryParams } = useUrlManager()
  const currentParams = useMemo(() => {
    return {
      sn_schedule: currentQueryParams.get('sn_schedule'),
    }
  }, [currentQueryParams])
  const { sn_schedule } = currentParams
  const {
    me: {
      state: { me },
    },
  } = useMobxStore()
  const [rosters, setRosters] = useState([])
  const [loading, setLoading] = useState(true)
  const [rosterLoading, setRosterLoading] = useState(true)
  const [loadingBillingDetails, setLoadingBillingDetails] = useState(false)
  const [billingDetails, setBillingDetails] = useState(null)
  const [error, setError] = useState(false)
  const [selectedReceipt, setSelectedReceipt] = useState(null)
  const [receiptIsLoading, setReceiptIsLoading] = useState(false)
  const [selectedRosterId, setSelectedRosterId] = useState(undefined)
  const [selectedRosterUID, setSelectedRosterUID] = useState(undefined)
  const [billingId, setBillingId] = useState(undefined)
  const [pdfIsLoading, setPdfIsLoading] = useState(false)
  const shouldDisplayBillingPayment = useMemo(() => {
    if (
      billingDetails &&
      billingDetails?.length > 0 &&
      me?.id === billingDetails?.[0]?.user_id
    ) {
      return true
    }
    if (canEdit) {
      return true
    }
    return false
  }, [me, billingDetails, canEdit])

  const downloadRef = useRef(null)
  const history = useHistory()

  const [i18n] = useI18n()

  useEffect(() => {
    if (sn_schedule && rosters && rosters.length > 0) {
      const roster = rosters.find((r) => r.schedule_uid === sn_schedule)
      if (roster) {
        setSelectedRosterId(roster.id)
        setSelectedRosterUID(roster.uid)
      }
    }
  }, [sn_schedule, rosters])

  const onView = useCallback(async () => {
    setPdfIsLoading(true)
    try {
      const pdf = await req(`/billing/schedule/${billingId}/receipt/pdf`, {
        parseJSON: false,
      })

      const normalizePdf = await pdf.blob()

      const link = document.createElement('a')
      link.href = URL.createObjectURL(normalizePdf)
      link.download = `${selectedReceipt?.item?.charge?.stripe_id}.pdf`
      link.click()
      setPdfIsLoading(false)
    } catch (e) {
      console.error(e)
      alert(
        `There was an error generating the report. ${
          e?.message || ''
        } Please try again.`
      )
    }
  }, [selectedReceipt, billingId])

  const totalPaid = useMemo(() => {
    if (!billingDetails) {
      return 0
    }

    let total = 0

    billingDetails
      .filter((el) => el.paid)
      .forEach((el) => {
        total += el.subtotal
      })

    return total
  }, [billingDetails])

  const currency = useMemo(() => {
    if (!billingDetails) {
      return 'USD'
    }

    return defaultCurrencies[billingDetails[0]?.currency]
  }, [billingDetails])

  const totalOutstanding = useMemo(() => {
    if (!billingDetails) {
      return 0
    }

    let total = 0

    billingDetails
      .filter((el) => !el.paid)
      .forEach((el) => {
        total += el.subtotal
      })

    return total
  }, [billingDetails])

  const loadReceipt = useCallback((billingId, index) => {
    setReceiptIsLoading(true)
    modalRef.current.openModal()

    fetchBillingInfo(billingId)
      .then((response) => {
        setSelectedReceipt({ item: response, index })
        setReceiptIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setReceiptIsLoading(false)
        BannerController.add(({ ...props }) => (
          <Banner {...props} type='failed'>
            {error?.message ?? 'An error happened'}
          </Banner>
        ))
      })
  }, [])

  useEffect(() => {
    fetchTeamRosters(teamId)
      .then((res) => {
        setRosters(res)
        setRosterLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!rosterLoading) {
      fetchTeamRosterBilling(teamId, rosters[0]?.uid)
        .then((res) => {
          setBillingDetails(res)
          setLoading(false)
        })
        .catch((err) => {
          console.error('FALL', err)
          setError(true)
          setLoading(false)
        })
    }
  }, [rosterLoading, rosters])

  const headerLength = useMemo(() => {
    if (shouldDisplayBillingPayment) {
      return [1.5, 1, 1, 1, 1.3, 1]
    }
    return [1.5, 1, 1, 1, 1.3]
  }, [shouldDisplayBillingPayment])

  if (loading) {
    return (
      <div
        css={css`
          margin-top: 40px;
        `}
      >
        <LoadingSpinner />
      </div>
    )
  }

  if (error) {
    return (
      <div
        css={css`
          margin-top: 40px;
        `}
      >
        {rosters?.length === 0 ? (
          <p>The team has no related charges.</p>
        ) : (
          <p>
            We cannot show the financial details right now, try again later.
          </p>
        )}
      </div>
    )
  }

  if (!me) {
    return (
      <div
        css={css`
          margin-top: 40px;
        `}
      >
        <p>We cannot show the financial details right now, try again later.</p>
      </div>
    )
  }

  return (
    <section
      css={css`
        height: 80vh;
      `}
    >
      <Flex>
        <div
          css={css`
            display: flex;
            align-items: flex-end;
            padding: 24px 0;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: flex-end;
              gap: 24px;
            `}
          >
            <div
              css={css`
                width: 400px;
              `}
            >
              <NewSelect
                fixedWidth={400}
                label='Competition'
                options={rosters}
                onClick={(roster) => {
                  if (selectedRosterId !== roster.id) {
                    setLoadingBillingDetails(true)
                    setParam('sn_schedule', roster.schedule_uid)
                    setSelectedRosterId(roster.id)
                    setSelectedRosterUID(roster.uid)
                    fetchTeamRosterBilling(teamId, roster.uid)
                      .then((res) => {
                        setBillingDetails(res)
                        setLoadingBillingDetails(false)
                      })
                      .catch((err) => {
                        console.error(err)
                        setError(true)
                        setLoadingBillingDetails(false)
                      })
                  }
                }}
                selectedOptionId={selectedRosterId || rosters[0]?.id}
                getSubTitle={(roster) => {
                  let subTitle = 'Unlinked Roster'

                  if (roster.starts_at) {
                    const starts = dayjs(
                      roster.starts_at,
                      'YYYY-MM-DD hh:mm:ss'
                    )
                    const ends = dayjs(roster.ends_at, 'YYYY-MM-DD hh:mm:ss')
                    subTitle = `${starts.format('MMM D, YYYY')} - ${ends.format(
                      'MMM D, YYYY'
                    )}`
                  }
                  return subTitle
                }}
                getTitle={(r) =>
                  r?.schedule_name ? r?.schedule_name : r?.name
                }
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 40px;
                gap: 8px;
                margin-bottom: 16px;
              `}
            >
              <p
                css={css`
                  font-size: 24px;
                  font-weight: 700;
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                  line-height: 32px;
                `}
              >
                {rosters.find((r) => r.id === selectedRosterId)
                  ?.schedule_name || rosters[0]?.schedule_name}
              </p>
              <p
                css={css`
                  color: ${colors.NEUTRAL_50};
                `}
              >
                {rosters.find((r) => r.id === selectedRosterId)
                  ?.division_name || rosters[0]?.division_name}
              </p>
            </div>
          </div>
        </div>
      </Flex>
      {loadingBillingDetails ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              flex: 10;
              height: 50px;
              padding-top: 24px;
              border-top: 1px solid ${colors.SOFT_STEEL};
            `}
          >
            {billingDetails?.length > 0 ? (
              <GenericTable
                data={billingDetails}
                headers={[
                  'Transaction',
                  'Due',
                  'subtotal',
                  'Installment',
                  'Payment Status',
                  shouldDisplayBillingPayment && 'Options',
                ]}
                headersFlexSize={headerLength}
                content={() =>
                  billingDetails.map((item, index) => {
                    const isRefunded = item?.charge?.refunded === 1

                    console.log(item)
                    console.log('charge', item?.charge)
                    console.log('isRefunded', isRefunded)
                    return (
                      <div
                        key={String(index)}
                        css={css`
                          flex: 1;
                          background-color: ${index % 2 === 0
                            ? colors.HEADER
                            : 'transparent'};
                          display: flex;
                          align-items: center;
                          flex-direction: row;
                          height: 50px;
                          padding: 8px;
                        `}
                      >
                        <div
                          css={css`
                            display: flex;
                            flex: 1.5;
                            align-items: center;
                            flex-direction: row;
                          `}
                        >
                          <span>
                            {item?.single_payment
                              ? 'One Time Payment'
                              : item?.payment_type === 'deposit'
                              ? 'Deposit'
                              : 'Installment'}
                          </span>
                        </div>
                        <div
                          css={css`
                            flex: 1;
                          `}
                        >
                          <span
                            css={css`
                              color: ${dayjs(item.due_date).isBefore(dayjs()) &&
                              !item.paid
                                ? colors.ERROR_LIGHT
                                : colors.WHITE};
                            `}
                          >
                            {dayjs(item.due_date).format('MMMM DD, YYYY')}
                          </span>
                        </div>
                        <div
                          css={css`
                            flex: 1;
                          `}
                        >
                          <span>
                            {currency &&
                              i18n.formatCurrency(item.subtotal, {
                                currency: currency,
                                form: 'short',
                              })}
                          </span>
                        </div>
                        <div
                          css={css`
                            flex: 1;
                            display: flex;
                          `}
                        >
                          {index > 0
                            ? index + '/' + (billingDetails.length - 1)
                            : '--'}
                        </div>
                        <div
                          css={css`
                            flex: 1.3;
                            display: flex;
                          `}
                        >
                          <StatusBadge
                            outline
                            status={
                              isRefunded
                                ? 'blue'
                                : item.paid
                                ? 'green'
                                : dayjs(item.due_date).isBefore(dayjs()) &&
                                  !item.paid
                                ? 'red'
                                : 'yellow'
                            }
                            options={{
                              blue: 'blue',
                              green: 'green',
                              red: 'red',
                              yellow: 'yellow',
                            }}
                            optionsValues={{
                              blue: 'Refunded',
                              green: 'Paid',
                              red: 'Past Due',
                              yellow: 'Pending',
                            }}
                          />
                        </div>
                        {shouldDisplayBillingPayment && (
                          <div
                            css={css`
                              flex: 1;
                              display: flex;
                            `}
                          >
                            <Tooltip title='View Receipt'>
                              <button
                                disabled={!item.paid}
                                css={css`
                                  cursor: ${!item.paid
                                    ? 'not-allowed'
                                    : 'pointer'};
                                  opacity: ${!item.paid ? 0.3 : 1};
                                `}
                                onClick={() => {
                                  loadReceipt(item?.uid, index)
                                  setBillingId(item?.uid)
                                }}
                              >
                                <Icon
                                  name='receipt'
                                  color={
                                    item.paid
                                      ? colors.DEFAULT_FLAIR
                                      : colors.ATTENDANCE_GRAY
                                  }
                                  fontSize={18}
                                />
                              </button>
                            </Tooltip>
                            <Tooltip title='Make Payment'>
                              <button
                                disabled={item.paid}
                                css={css`
                                  cursor: ${item.paid
                                    ? 'not-allowed'
                                    : 'pointer'};
                                  opacity: ${item.paid ? 0.3 : 1};
                                  margin-left: 15px;
                                `}
                                onClick={() =>
                                  history.push(
                                    `${
                                      ROUTES.REGISTRATION_PAYMENT
                                    }?teamId=${teamId}&rosterId=${
                                      selectedRosterUID || rosters[0]?.uid
                                    }&billingId=${item.uid}`
                                  )
                                }
                              >
                                <Icon
                                  name='money-bill'
                                  color={
                                    !item.paid
                                      ? colors.DEFAULT_FLAIR
                                      : colors.ATTENDANCE_GRAY
                                  }
                                  fontSize={18}
                                />
                              </button>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    )
                  })
                }
              />
            ) : (
              <p>No billing details</p>
            )}
          </div>
          {billingDetails?.length > 0 && (
            <div
              css={css`
                flex: 2.3;
                height: 50px;
                padding-left: 16px;
              `}
            >
              <RegistrationIndicatorCard
                iconName='wallet'
                title='Total Paid'
                customHeight='220px'
                renderContent={() => (
                  <div
                    css={css`
                      margin-top: 2px;
                    `}
                  >
                    <span
                      css={css`
                        font-weight: 700;
                        font-size: 24px;
                        letter-spacing: 0.05em;
                        font-family: ${isCanlan
                          ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                          : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                      `}
                    >
                      {currency &&
                        i18n.formatCurrency(totalPaid, {
                          currency: currency,
                          form: 'explicit',
                        }) +
                          ' / ' +
                          i18n.formatCurrency(totalOutstanding + totalPaid, {
                            currency: currency,
                            form: 'explicit',
                          })}
                    </span>
                    <div
                      css={css`
                        background-color: ${colors.INFO_DARK};
                        width: 100%;
                        position: relative;
                        margin-top: 8px;
                        overflow: hidden;
                        border-radius: 2px;
                        height: 11px;
                      `}
                    >
                      <div
                        css={css`
                          background-color: ${colors.INFO_LIGHT};
                          position: absolute;
                          height: 11px;
                          width: ${(totalPaid / totalOutstanding) * 100}%;
                        `}
                      />
                    </div>
                    <p
                      css={css`
                        color: ${colors.ATTENDANCE_GRAY};
                        font-size: 16px;
                        font-size: 12px;
                        margin-top: 5px;
                        line-height: 15px;
                      `}
                    >
                      Total Outstanding:{' '}
                      {currency &&
                        i18n.formatCurrency(totalOutstanding, {
                          currency: currency,
                          form: 'explicit',
                        })}
                    </p>
                  </div>
                )}
              />
              {!!billingDetails.find((el) => !el.paid)?.subtotal && (
                <RegistrationIndicatorCard
                  iconName='wallet'
                  title='TEAM NEXT PAYMENT'
                  renderContent={() => (
                    <div
                      css={css`
                        margin-top: 2px;
                      `}
                    >
                      <span
                        css={css`
                          font-weight: 700;
                          font-size: 24px;
                          letter-spacing: 0.05em;
                          font-family: ${isCanlan
                            ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                            : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                        `}
                      >
                        {currency &&
                          i18n.formatCurrency(
                            billingDetails.find((el) => !el.paid)?.subtotal,
                            {
                              currency: currency,
                              form: 'explicit',
                            }
                          )}
                      </span>
                      <p
                        css={css`
                          color: ${colors.ATTENDANCE_GRAY};
                          font-size: 16px;
                          font-size: 12px;
                          margin-top: 5px;
                        `}
                      >
                        {dayjs(
                          billingDetails.find((el) => !el.paid)?.due_date
                        ).format('MMMM DD, YYYY')}
                      </p>
                    </div>
                  )}
                />
              )}
              {/* <RegistrationIndicatorCard
                iconName='cc-visa'
                iconType='fab'
                title='BILLING INFO'
                onEditPress={() => {}}
                renderContent={() => (
                  <div
                    css={css`
                      margin-top: 2px;
                      display: block;
                    `}
                  >
                    <span
                      css={css`
                        font-weight: 700;
                        font-size: 24px;
                        letter-spacing: 0.05em;
                        font-family: ${isCanlan
                          ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                          : 'Rift, Arial, Helvetica, sans-serif'};
                      `}
                    >
                      **** **** **** 1234
                    </span>
                  </div>
                )}
              /> */}
              <RegistrationIndicatorCard
                iconName='wallet'
                title='Total Past Due'
                renderContent={() => (
                  <div
                    css={css`
                      margin-top: 2px;
                      display: block;
                    `}
                  >
                    <span
                      css={css`
                        font-weight: 700;
                        font-size: 24px;
                        letter-spacing: 0.05em;
                        font-family: ${isCanlan
                          ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                          : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                      `}
                    >
                      {currency &&
                        i18n.formatCurrency(calculatePastDues(billingDetails), {
                          currency: currency,
                          form: 'explicit',
                        })}
                    </span>
                  </div>
                )}
              />
            </div>
          )}
        </div>
      )}
      <NewModal ref={modalRef} shouldCloseOnOverlayClick={false}>
        <div
          css={css`
            background-color: ${colors.HEADER};
            padding: 40px 32px 40px 32px;
            transition: all 0.3s ease;
          `}
        >
          {receiptIsLoading ? (
            <div
              css={css`
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 200px;
              `}
            >
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <p
                css={css`
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                  color: white;
                  font-size: 48px;
                  font-weight: 700;
                  margin-bottom: 40px;
                `}
              >
                RECEIPT
              </p>
              <div
                css={css`
                  display: flex;
                  margin-bottom: 24px;
                `}
              >
                <div
                  css={css`
                    min-width: 150px;
                    * {
                      color: ${colors.ATTENDANCE_GRAY};
                      font-size: 16px;
                    }
                  `}
                >
                  <span>Transaction ID</span>
                </div>
                <span
                  css={css`
                    font-size: 16px;
                    color: ${colors.WHITE};
                  `}
                >
                  {selectedReceipt?.item?.charge?.stripe_id}
                </span>
              </div>
              <div
                css={css`
                  display: flex;
                  margin-bottom: 24px;
                `}
              >
                <div
                  css={css`
                    min-width: 150px;
                    * {
                      color: ${colors.ATTENDANCE_GRAY};
                      font-size: 16px;
                    }
                  `}
                >
                  <span>Subtotal Sent</span>
                </div>
                <span
                  css={css`
                    font-size: 16px;
                    color: ${colors.WHITE};
                  `}
                >
                  {selectedReceipt?.item?.paid
                    ? currency &&
                      i18n.formatCurrency(selectedReceipt?.item?.subtotal, {
                        currency: currency,
                        form: 'explicit',
                      })
                    : currency &&
                      i18n.formatCurrency(0, {
                        currency: currency,
                        form: 'short',
                      })}
                </span>
              </div>
              <div
                css={css`
                  display: flex;
                  margin-bottom: 24px;
                `}
              >
                <div
                  css={css`
                    min-width: 150px;
                    * {
                      color: ${colors.ATTENDANCE_GRAY};
                      font-size: 16px;
                    }
                  `}
                >
                  <span>Date Paid</span>
                </div>
                <span
                  css={css`
                    font-size: 16px;
                    color: ${colors.WHITE};
                  `}
                >
                  {dayjs(selectedReceipt?.item?.charge?.created_at).format(
                    'MMMM DD, YYYY'
                  )}
                </span>
              </div>
              <div
                css={css`
                  display: flex;
                  margin-bottom: 24px;
                `}
              >
                <div
                  css={css`
                    min-width: 150px;
                    * {
                      color: ${colors.ATTENDANCE_GRAY};
                      font-size: 16px;
                    }
                  `}
                >
                  <span>Installment</span>
                </div>
                <span
                  css={css`
                    font-size: 16px;
                    color: ${colors.WHITE};
                  `}
                >
                  {selectedReceipt?.index > 0
                    ? selectedReceipt?.index + '/' + (billingDetails.length - 1)
                    : '--'}
                </span>
              </div>
              <div
                css={css`
                  display: flex;
                  margin-bottom: 24px;
                `}
              >
                <div
                  css={css`
                    min-width: 150px;
                    * {
                      color: ${colors.ATTENDANCE_GRAY};
                      font-size: 16px;
                    }
                  `}
                >
                  <span>Description</span>
                </div>
                <span
                  css={css`
                    font-size: 16px;
                    color: ${colors.WHITE};
                  `}
                >
                  {selectedReceipt?.item?.single_payment
                    ? 'One Time Payment'
                    : selectedReceipt?.item?.payment_type === 'deposit'
                    ? 'Deposit'
                    : 'Installment'}
                </span>
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: 40px;
                `}
              >
                <button
                  onClick={() => {
                    modalRef.current.closeModal()
                    setBillingId(null)
                  }}
                  css={css`
                    border: 1px solid ${colors.WHITE};
                    border-radius: 4px;
                    padding: 8px 25px;
                    font-size: 18px;
                    color: ${colors.WHITE};
                    font-family: ${isCanlan
                      ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                      : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                  `}
                >
                  CLOSE
                </button>
                <button
                  ref={downloadRef}
                  disabled={pdfIsLoading}
                  onClick={onView}
                  css={css`
                    border: 1px solid ${colors.DEFAULT_FLAIR};
                    border-radius: 4px;
                    padding: 8px 0px;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    display: flex;
                    margin-left: 20px;
                  `}
                >
                  {pdfIsLoading ? (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <div
                        css={css`
                          width: 10px;
                          margin-right: 12px;
                        `}
                      >
                        <LoadingSpinner size={1.5} />
                      </div>
                      <span
                        css={css`
                          color: ${colors.WHITE};
                          font-size: 18px;
                          font-family: ${isCanlan
                            ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                            : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                        `}
                      >
                        PROCESSING...
                      </span>
                    </div>
                  ) : (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <Icon
                        color={colors.WHITE}
                        fontSize={18}
                        name='file-pdf'
                      />
                      <span
                        css={css`
                          color: ${colors.WHITE};
                          font-size: 18px;
                          margin-left: 10px;
                          font-family: ${isCanlan
                            ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                            : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                        `}
                      >
                        DOWNLOAD PDF
                      </span>
                    </div>
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </NewModal>
    </section>
  )
})

export default FinancialList
