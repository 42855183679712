/** @jsxImportSource @emotion/react */
import css, { SerializedStyles } from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Input as AntInput } from 'antd'
import React, { useState } from 'react'
import { ErrorLabel } from '../ErrorLabel'
import Text from '../Text'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

/**
 * TextInput component for form fields with various customization options
 * @component
 */
type TextInputProps = {
  /** Field name for error handling and identification */
  name: string
  /** Current input value */
  value: string
  /** Function to update the input value */
  setValue: (value: string) => void
  /** Object containing field validation errors */
  fieldErrors: any
  /** Custom error message */
  customError: any
  /** Label text displayed above the input */
  label: string
  /** Input type (text, password, number, etc.) */
  type?: string
  /** Maximum character length */
  maxLength?: number
  /** Whether the input is disabled */
  disabled?: boolean
  /** HTML autocomplete attribute */
  autoComplete?:
    | 'on'
    | 'off'
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'one-time-code'
    | 'organization-title'
    | 'organization'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'cc-name'
    | 'cc-given-name'
    | 'cc-additional-name'
    | 'cc-family-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
    | 'cc-type'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'url'
    | 'photo'
  /** Function called when input loses focus */
  onBlur?: () => void
  /** Minimum value for number inputs */
  min?: number
  /** Placeholder text */
  placeholder?: string
  /** Content to display after the input */
  addonAfter?: string
  /** Custom style for the input */
  /** Custom CSS styles using emotion css */
  style?: SerializedStyles
}

export const TextInput: React.FC<TextInputProps> = ({
  name,
  value,
  setValue,
  fieldErrors,
  customError,
  label,
  type = 'text',
  maxLength = 255,
  disabled = false,
  autoComplete = 'off',
  onBlur,
  min,
  placeholder,
  addonAfter,
  style,
}) => {
  // State to toggle password visibility
  const [passwordVisible, setPasswordVisible] = useState(false)

  // Determine the actual input type based on password visibility
  const inputType = type === 'password' && passwordVisible ? 'text' : type

  // Password visibility toggle icon
  const passwordIcon =
    type === 'password' ? (
      <span
        onClick={() => setPasswordVisible(!passwordVisible)}
        css={css`
          cursor: pointer;
          color: ${colors.WHITE};
        `}
      >
        {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      </span>
    ) : null

  return (
    <>
      <Text
        variant='paragraphSmall'
        weight='regular'
        color={colors.WHITE}
        style={`
          align-self: stretch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
      >
        {label}
      </Text>
      <AntInput
        addonAfter={addonAfter}
        placeholder={placeholder}
        min={min}
        status={fieldErrors[name] || fieldErrors?.includes(name) ? 'error' : ''}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        type={inputType}
        maxLength={maxLength}
        disabled={disabled}
        autoComplete={autoComplete}
        onBlur={onBlur}
        suffix={passwordIcon}
        css={css`
          font-size: 14px !important;
          height: 40px !important;
          border-radius: 8px;
          border: 1px solid ${colors.SECONDARY_100};
          background: ${colors.SECONDARY_200};
          ${disabled &&
          css`
            color: ${colors.NEUTRAL_100};
          `}
          ${!disabled &&
          css`
            color: ${colors.WHITE};
          `}
          ${style}
        `}
      />
      <ErrorLabel
        fieldErrors={fieldErrors}
        customError={customError}
        name={name}
      />
    </>
  )
}
