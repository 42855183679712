/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'
import colors from '@sportninja/common/constants/appColors'
import { useEffect, useRef, useState } from 'react'
import Icon from '../Icon'
import EnvHandler from '@sportninja/common/utils/env-handler-setup'

interface ShareComponentProps {
  registrationId: string
  scheduleId: string
  customText?: string
  alternativeBackground?: boolean
}

const ShareComponent = ({
  registrationId,
  scheduleId,
  customText = 'Share',
  alternativeBackground = false,
}: ShareComponentProps) => {
  const [hasCopied, setHasCopied] = useState(false)
  const baseUrl = useRef(null)
  const shareUrl = `${baseUrl.current}/schedule/${scheduleId}/registration?registration_id=${registrationId}`

  useEffect(() => {
    EnvHandler.get().then((value) => {
      // Since we are getting the base URL, to share it we need
      // to remove the /v1 ( which is used only for API calls )
      // and replace the port 8080 with 3000 ( which is the port for the web app )
      let clearValue = value?.replace('/v1', '')
      let clearValueWithPort = clearValue?.replace('8080', '3000')
      baseUrl.current = clearValueWithPort
    })
    return () => {
      // baseUrl.current = null
    }
  }, [])

  useEffect(() => {
    // Create a URL object from the current location
    const currentUrl = new URL(window.location.href)
    // Set or update the registration_id parameter
    currentUrl.searchParams.set('registration_id', registrationId)
    // Update the browser URL with the new search params
    window.history.pushState({}, '', currentUrl.toString())

    return () => {
      // On cleanup, remove the registration_id parameter
      const cleanupUrl = new URL(window.location.href)
      cleanupUrl.searchParams.delete('registration_id')
      window.history.pushState({}, '', cleanupUrl.toString())
    }
  }, [registrationId])

  useEffect(() => {
    if (hasCopied) {
      setTimeout(() => {
        setHasCopied(false)
      }, 2000)
    }
  }, [hasCopied])

  return (
    <div
      css={css`
        display: flex;
        display: none;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background-color: ${!alternativeBackground
              ? '#1B222B'
              : colors.SECONDARY_300};
            margin-left: 8px;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 4px;
            padding-bottom: 4px;
          `}
        >
          <span
            css={css`
              color: #6a6c70;
              font-size: 14px;
              line-height: 1.5;
            `}
          >
            {customText}
          </span>
          <div
            css={css`
              padding: 4px 8px 0;
              font-size: 16px;
              font-family: 'Courier New', monospace;
              font-weight: normal;
              color: ${colors.WHITE};
              min-width: 185px;
            `}
          >
            {hasCopied ? 'Copied' : 'Share Registration'}
          </div>
          <button
            type='button'
            title='Copy share link to clipboard'
            onClick={() => {
              navigator.clipboard.writeText(shareUrl)
              setHasCopied(true)
            }}
            css={css`
              height: 100%;
              background: none;
              border: none;
              cursor: pointer;
              padding: 0 4px;
              &:hover {
                opacity: 0.8;
              }
            `}
          >
            <Icon
              faType='far'
              name={hasCopied ? 'clipboard-check' : 'share'}
              color={hasCopied ? colors.DEFAULT_FLAIR : undefined}
              fontSize={16}
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ShareComponent
