/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import dayjs from 'dayjs'
import {
  getHighQualityImageThumbnailId,
  getImageThumbnailId,
} from '@sportninja/common/reducers/helpers'
import { ROUTES } from '@sportninja/common/constants/app'
import { Link } from 'react-router-dom'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import Linkify from 'react-linkify'

import { Flex } from '../Layout'
import Picture from '../Picture'
import { InitialsThumbnail } from '../ThumbnailAndName'
import Text from '../ts-components/Text'

const getInitials = (name = '') => {
  const [first = '', last = ''] = name.split(' ')
  return [first.charAt(0), last.charAt(0)]
}

const ChatMessage = ({
  message,
  loggedInUserId,
  previousUserId,
  nextUserId,
}) => {
  const isSelf = loggedInUserId === message.user_id
  const isSameUserAsBefore = previousUserId === message.user_id
  const isSameUserAsNext = nextUserId === message.user_id
  const isPlayer =
    typeof message?.player_id === 'string' && message.player_id.length > 0
  const [firstInitial, lastInitial] = getInitials(message?.user?.name)

  const imageThumbnailId =
    getHighQualityImageThumbnailId(message) || message?.image_url

  return (
    <Flex
      noFlex
      flexDirection={isSelf ? 'row-reverse' : 'row'}
      css={css`
        margin-top: ${isSameUserAsBefore ? 3 : 10}px;
      `}
    >
      <div>
        {!isSameUserAsBefore && !isSelf && (
          <Text
            variant='paragraphXSmall'
            weight='semibold'
            color={colors.NEUTRAL_50}
            style={css`
              margin-bottom: 4px;
              margin-left: 8px;
            `}
          >
            {message?.user?.name}
          </Text>
        )}
        <Flex alignItems='flex-end'>
          {/* {!isSelf && !isSameUserAsNext && (
            <Flex
              as={isPlayer ? Link : undefined}
              to={
                isPlayer
                  ? `${ROUTES.PLAYER_ROOT}/${message.player_id}`
                  : undefined
              }
              css={
                isPlayer &&
                css`
                  &:hover .initials-thumbnail {
                    border-color: ${colors.PRIMARY};
                    color: ${colors.PRIMARY};
                  }
                `
              }
            >
              {message?.user?.image?.filename ? (
                <Picture
                  size='kxxsmall'
                  imageId={getImageThumbnailId(message?.user)}
                />
              ) : (
                <InitialsThumbnail size={30}>
                  {firstInitial}
                  {lastInitial}
                </InitialsThumbnail>
              )}
            </Flex>
          )} */}
          <div
            css={css`
              /* min-width: 108px; */
              max-width: 250px;
              padding: 7px 8px 4px 8px;
              border-radius: 12px;
              margin-left: 8px;
              ${isSameUserAsBefore &&
              (isSelf
                ? 'border-top-right-radius: 4px;'
                : 'border-top-left-radius: 4px;')}

              ${isSameUserAsNext &&
              (isSelf
                ? 'border-bottom-right-radius: 4px;'
                : 'border-bottom-left-radius: 4px;')}

              background-color: ${isSelf
                ? isCanlan
                  ? colors.INFO_500
                  : colors.INFO_500
                : colors.SECONDARY_100};

              /* margin-left: ${!isSelf && !isSameUserAsNext ? 6 : 36}px; */
            `}
          >
            <div
              css={css`
                color: ${!isSelf || isCanlan
                  ? colors.NEUTRAL_0
                  : colors.NEUTRAL_0};
                text-align: ${isSelf ? 'right' : 'left'};
                word-break: break-word;
                a {
                  text-decoration: underline;
                  color: ${!isSelf || isCanlan
                    ? colors.NEUTRAL_0
                    : colors.SECONDARY_300};
                }
              `}
            >
              {imageThumbnailId ? (
                <img
                  src={imageThumbnailId}
                  alt='chat-image'
                  css={css`
                    max-width: 100%;
                    max-height: 200px;
                  `}
                />
              ) : (
                <Linkify
                  properties={{
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  }}
                >
                  <Text
                    variant='paragraphSmall'
                    weight='regular'
                    style={css`
                      letter-spacing: 0px;
                      line-height: 100% !important;
                    `}
                  >
                    {message?.message}
                  </Text>
                </Linkify>
              )}
            </div>
            <div
              css={css`
                font-size: 10px;
                color: ${isSelf
                  ? isCanlan
                    ? '#38d1ff'
                    : colors.NEUTRAL_50
                  : colors.ATTENDANCE_GRAY};
                text-align: ${isSelf ? 'left' : 'right'};
                margin-top: 4px;
              `}
            >
              {dayjs.utc(message?.created_at).local().format('h:mm a')}
            </div>
          </div>
        </Flex>
      </div>
    </Flex>
  )
}

export default ChatMessage
