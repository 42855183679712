// PhoneInput.jsx
import React, { useEffect, useRef, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import colors from '@sportninja/common/constants/appColors'

/**
 * PhoneInputComponent is a custom input component for phone numbers.
 *
 * @component
 * @param {object} props - Component props
 * @param {boolean} props.disabled - Determines whether the input is disabled
 * @param {string} props.phoneValue - The current value of the phone input
 * @param {Function} props.setPhoneValue - Function to update the phone value
 * @param {Function} props.onChangePhone - Function to handle phone number changes
 * @param {string} props.namePhone - The name attribute for the phone input
 * @returns {React.Component} The PhoneInput component
 */
const PhoneInputComponent = ({
  disabled,
  phoneValue,
  setPhoneValue,
  onChangePhone,
  namePhone,
  ...props
}) => {
  const inputRef = useRef(null)
  const [isPhoneFocused, setIsPhoneFocused] = useState(false)
  const inputStyleFocused = {
    height: 46,
    border: `1px solid ${colors.PRIMARY}`,
    borderRadius: 8,
    display: 'flex',
    backgroundColor: 'transparent',
    color: colors.WHITE,
    fontSize: 16,
    letterSpacing: 0.74,
  }
  const inputStyleUnfocused = {
    height: 46,
    border: `1px solid ${colors.SECONDARY_100}`,
    borderRadius: 2,
    display: 'flex',
    backgroundColor: 'transparent',
    color: colors.WHITE,
    fontSize: 16,
    letterSpacing: 0.74,
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.tabIndex = 1 // Set the tabIndex here
    }
  }, [])

  return (
    <PhoneInput
      inputProps={{
        ref: inputRef,
      }}
      onFocus={() => setIsPhoneFocused(true)}
      onBlur={() => setIsPhoneFocused(false)}
      disabled={disabled}
      containerStyle={{
        position: 'relative',
        flexDirection: 'column',
        width: '50%',
      }}
      inputStyle={isPhoneFocused ? inputStyleFocused : inputStyleUnfocused}
      buttonStyle={{
        height: 46,
        backgroundColor: 'transparent',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: 8,
      }}
      dropdownStyle={{
        backgroundColor: '#1e2127',
        fontSize: 16,
        color: colors.WHITE,
      }}
      dropdownClass={'country-list'}
      country={'us'}
      preferredCountries={['us', 'ca', 'br']}
      value={phoneValue}
      onChange={(phone) => {
        // Before submitting the phone value, we should always add a + to the beginning
        let mphone = `+${phone}`
        setPhoneValue(mphone)
        onChangePhone({ target: { name: namePhone, value: mphone } })
      }}
      {...props}
    />
  )
}

export default PhoneInputComponent
