import { useState, useEffect } from 'react'
import req from '../api/request'

interface Team {
  id: string
  name: string
  image: string
}

interface Location {
  name: string
  address: string
  timezone: string
}

interface Game {
  id: string
  home_team: Team
  visiting_team: Team
  type: string
  starts_at: string
  visiting_team_score: number
  home_team_score: number
  status: string
  root_schedule: string
  schedule: string
  current_period_time: string | null
  current_period_name: string | null
  facility: string
  location: Location
}

interface MyTeam {
  team_id: string
  team_name: string
}

interface HomeGamesData {
  previousGames: Game[]
  upcomingGames: Game[]
  myTeams: MyTeam[]
}

interface ApiResponse {
  data: HomeGamesData
}

const useHomeGames = () => {
  const [homeGames, setHomeGames] = useState<HomeGamesData | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const fetchHomeGames = async () => {
      try {
        setLoading(true)
        const response: ApiResponse = await req('/home/games')
        setHomeGames(response.data)
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error('An unknown error occurred')
        )
      } finally {
        setLoading(false)
      }
    }

    fetchHomeGames()
  }, [])

  return { homeGames, loading, error }
}

export default useHomeGames
