import { call, put, select } from 'redux-saga/effects'
import req from '@sportninja/common/api/request'

import { getUserById } from '../../selectors/users'
import { readWithPagesFactory } from './read'
import { getInviteStatus } from './utils'

const api = (entityType, isInviteType = false) => {
  return {
    read: async (id, page = 1, sort, direction) =>
      await req(`/${entityType}/${id}/permissions`, {
        query: {
          page,
          sort,
          direction,
        },
      }),
    create: async (id, body) =>
      await req(`/${entityType}/${id}/permissions`, { method: 'POST', body }),
    update: async (id, userId, body) => {
      let url = `/${entityType}/${id}/permissions/${userId}`
      if (isInviteType) {
        url = `/invitations/${userId}`
      }

      return await req(url, { method: 'PUT', body })
    },
    delete: async (id, userId) => {
      let url = `/${entityType}/${id}/permissions/${userId}`
      if (isInviteType) {
        url = `/invitations/${userId}`
      }

      return await req(url, { method: 'DELETE' })
    },
  }
}

const transformRoleTypeIdString = (form) => {
  if (typeof form.role_type_id === 'string' && form.role_type_id.length > 0) {
    form.role_type_id = parseInt(form.role_type_id, 10)
  }
}

export default (entityType, actions) => {
  return [
    [actions.read, readWithPagesFactory(api(entityType).read, actions.read)],

    [
      actions.create,
      function* (payload) {
        const { id, form: _form } = payload
        const { message_text, ...form } = _form

        // Convert role type id string to int for API
        transformRoleTypeIdString(form)

        // If we name this `message` in the form, then normal 500 errors from the API
        // will highlight the form field, since that's the key the API uses for the error text.
        if (typeof message_text === 'string') {
          form.message = message_text
        }

        const body = JSON.stringify(form)

        const response = yield call(api(entityType).create, id, body)
        yield put(actions.create.success({ id, data: response.data }))
        return response
      },
    ],

    [
      actions.update,
      function* (payload) {
        const { id, userId, form: _form } = payload
        const { email, message_text, ...form } = _form

        // Convert role type id string to int for API
        transformRoleTypeIdString(form)

        // If we name this `message` in the form, then normal 500 errors from the API
        // will highlight the form field, since that's the key the API uses for the error text.
        if (typeof message_text === 'string') {
          form.message = message_text
        }

        if (email) {
          form.email = email
          const existingUser = yield select((state) => {
            return getUserById(state, userId)
          })
          // https://sportninja.atlassian.net/browse/SN-4754
          // #### Update on 2024-10-04 ####
          // Due to the issue reported on the ticket above
          // we are now always including the email in the update request
          // if the email is present in the form.
          // Old comment from mq below
          // If email present, compare to existing email. If different, then include in body
          // if (existingUser?.email?.toLowerCase() !== email.toLowerCase()) {

          // }
        }

        const inviteStatus = yield getInviteStatus(entityType, id, userId)

        const body = JSON.stringify(form)
        const response = yield call(
          api(entityType, !inviteStatus.accepted).update,
          id,
          userId,
          body
        )

        yield put(actions.update.success({ id, userId, data: response.data }))
        return response
      },
    ],

    [
      actions.delete,
      function* (payload) {
        const { id, userId } = payload

        const inviteStatus = yield getInviteStatus(entityType, id, userId)
        const isInviteType = inviteStatus ? !inviteStatus.accepted : false

        const response = yield call(
          api(entityType, isInviteType).delete,
          id,
          isInviteType ? inviteStatus?.invitationId : userId
        )
        yield put(actions.delete.success({ id, userId }))
        return response
      },
    ],
  ]
}
