/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import Text from 'src/components/ts-components/Text'
import { useOrganizationModalContext } from '../../context'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import { SwitchInput } from 'src/components/ts-components/SwitchInput'
import colors from '@sportninja/common/constants/appColors'

const Settings = () => {
  const { state, setState } = useOrganizationModalContext()

  return (
    <div
      css={css`
        display: flex;
        padding: 16px;
        gap: 16px;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text variant='captionLarge' weight='semiBold'>
          Settings
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 24px;
          align-self: stretch;
        `}
      >
        <SwitchInput
          title='Request Waiver'
          subtitle={
            'Players Will Receive And Be Required To Sign The Waiver(S) With Their Invite, In Order To Accept And Join Each Team.'
          }
          value={state.show_waiver}
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              show_waiver: value,
              waiver_id: value ? prevState.waiver_id : '',
              waivers: value ? prevState.waivers : [],
            }))
          }}
        />
      </div>
      <a
        css={css`
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          align-self: stretch;
        `}
        href='https://support.sportninja.com/'
        target='_blank'
        rel='noreferrer'
      >
        <Text variant='paragraphXSmall' weight='regular' color={colors.PRIMARY}>
          Learn More
        </Text>
      </a>
      {state?.show_waiver ? (
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <SelectInput
              label='Waiver'
              options={state.waivers}
              value={state.waiver_id}
              onChange={(value) => {
                setState({
                  ...state,
                  waiver_id: value,
                })
              }}
              fieldErrors={state.fieldErrors}
              customError={state.customError}
              disabled={false}
              name='waiver_id'
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Settings
