import { getIframeSessionToken, getSessionToken } from './account'
import { store } from '../store'
import { bindActionToPromise } from '../actions/utils'
import authActions from '../actions/auth'
import appErrorActions from '../actions/appError'
import EnvHandler from '../utils/env-handler'

export const getHeaders = async (
  requiresAuth,
  headers = {},
  skipAutoContentType = false,
  useIframeToken = false
) => {
  const config = { Accept: 'application/json', ...headers }

  // The browser takes care of setting this header in some cases automatically,
  // like when submitting a form
  if (!skipAutoContentType) {
    config['Content-Type'] = 'application/json'
  }

  if (requiresAuth) {
    config.Authorization = `Bearer ${
      useIframeToken ? await getIframeSessionToken() : await getSessionToken()
    }`
  }

  for (const header in headers) {
    if (
      Object.prototype.hasOwnProperty.call(config, header) &&
      config[header] === false
    ) {
      // Remove header values that have been set explicitly to false
      delete config[header]
    }
  }

  return new Headers(config)
}

const buildQueryString = (params = {}) => {
  const keyNames = params ? Object.keys(params) : []
  if (keyNames.length === 0) {
    return ''
  }

  const keys = []
  for (let i = 0; i < keyNames.length; i++) {
    const keyName = keyNames[i]
    const value = params[keyName]
    if (typeof value === 'undefined') {
      continue
    }
    keys.push(`${encodeURIComponent(keyName)}=${encodeURIComponent(value)}`)
  }

  if (keys.length === 0) {
    return ''
  }

  return `?${keys.join('&')}`
}

let cachedInFrame = false
let cachedEnv = null
const request = async (url, settings = {}, retry = 3) => {
  let env

  if (cachedEnv) {
    env = cachedEnv
  } else {
    const environment = await EnvHandler.get()
    env = environment
    cachedEnv = env
  }

  const {
    requiresAuth = true,
    method = 'GET',
    body = false,
    headers,
    query,
    skipAutoContentType,
    useIframeToken,
    timeout,
    ...rest
  } = settings

  if (typeof useIframeToken === 'boolean') {
    cachedInFrame = useIframeToken
  }

  let abortController, signal
  if (timeout) {
    abortController = new AbortController()
    signal = abortController.signal
  }

  const options = {
    headers: await getHeaders(
      requiresAuth,
      headers,
      skipAutoContentType,
      cachedInFrame
    ),
    method,
    signal,
    ...rest,
  }

  if (body) {
    options.body = body
  }

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  for (let attempt = 0; attempt < retry; attempt++) {
    try {
      const queryString = buildQueryString(query)
      let to
      if (timeout) {
        to = setTimeout(() => abortController.abort(), timeout)
      }
      const response = await fetch(env + url + queryString, options)
      if (to) {
        clearTimeout(to)
      }
      if (response?.status === 504) {
        let message = `Gateway Timeout Error (504) URL:${env + url} (${method})`
        const error = new Error(message)
        bindActionToPromise(
          store.dispatch,
          appErrorActions.setError.request
        )(error)
        throw error
      }
      return response
    } catch (e) {
      if (
        attempt === retry - 1 ||
        e?.message?.includes('Gateway Timeout Error (504)')
      ) {
        let message = `Error: ${e.message} URL:${env + url} (${method})`
        if (e?.message === 'Failed to fetch') {
          console.error('Failed to fetch', e)
          message =
            "We're having trouble connecting to SportNinja. Please check your network and try again. If the issue continues, contact our support team."
          bindActionToPromise(
            store.dispatch,
            appErrorActions.setError.request
          )(e)
        }
        e.message = message

        throw e
      }
      await sleep(1000) // Wait for 1 second before retrying
    }
  }
}

const attemptRefresh = async (response, ...requestProps) => {
  if (!store || !store.dispatch) {
    return
  }

  // Attempt to refresh the session, but only if this isn't already an
  // attempt to refresh the session :P
  // In that case, just log the user out, there's nothing more we can do here
  if (/\/auth\/refresh$/i.test(response.url)) {
    store.dispatch(authActions.logout.request())
    return
  }

  // Attempt to refresh the token; if it fails for whatever reason (i.e. there is
  // no token) then just log the user out
  try {
    await bindActionToPromise(store.dispatch, authActions.refresh.request)()
  } catch (e) {
    store.dispatch(authActions.logout.request())
    return
  }

  return await request(...requestProps)
}

// Retry a request - if it throws an error, it'll throw just like `throwOnHTTPError`
// but otherwise if we get a 503 again, throw a network unavailable error.
// const retry = async (...requestProps) => {
//   const response = await request(...requestProps)
//   if (response.status === 503) throw new Error('Network unavailable (503)')
//   return response
// }

const throwOnHTTPError = async (origResponse, ...requestProps) => {
  const status = origResponse.status
  if (status < 400) {
    return origResponse
  }

  // Removing this for now because it's causing some weird issues
  // if (!/\/auth\/login$/i.test(origResponse.url) && status === 401) {
  //   // const response = await attemptRefresh(origResponse, ...requestProps)
  //   // if (response) return response
  //   store.dispatch(authActions.logout.request())
  //   const invalidCredentialsErrors = new Error('Invalid credentials')
  //   throw invalidCredentialsErrors
  // }

  // Duck out early if the network is unavailable - avoids a JSON parsing error below
  if ([502, 503].includes(status)) {
    // return await retry(...requestProps)
    const networkError = new Error(
      `Network unavailable (${status}) ${origResponse?.url || ''}`
    )
    throw networkError
  }

  let error = new Error()
  try {
    error = await origResponse.json()
    if (typeof error === 'string') {
      error = new Error(error)
    }
    error.statusCode = status
    if (status === 500) {
      error.message = `There was a problem (500): ${error?.message || error} ${
        origResponse?.url || ''
      }`
    }
  } catch (e) {
    if (typeof e === 'string') {
      error = new Error(e)
    }
    // If for some reason the API returns malformed JSON, show that I guess?
    error.message = `There was a problem: ${e?.message || e} ${
      origResponse?.url || ''
    } (${origResponse?.status || ''})`
  }

  if (origResponse && origResponse.url && origResponse.status) {
    error.url = origResponse.url
    error.status = origResponse.status
  }
  error.isApiError = true
  throw error
}

export const req = async (url, settings = {}) => {
  const { parseJSON = true, noThrow = false, ...rest } = settings
  const orig = await request(url, rest)

  // HTTP 204 - No content: just return early
  if (orig.status === 204) {
    return {}
  }

  // HTTP Error codes
  if (noThrow) {
    if (parseJSON) {
      return await orig.json()
    } else {
      return orig
    }
  }

  const response = await throwOnHTTPError(orig, url, rest)

  if (parseJSON) {
    return await response.json()
  } else {
    return response
  }
}

export default req
