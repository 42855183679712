/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import {
  ManageRefreshmentsModalContextType,
  ManageRefreshmentsModalProvider,
  ManageRefreshmentsModalContext,
} from './context'
import { Modal } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import { useCallback, useMemo } from 'react'
import Button from '../ts-components/Button'
import Text from '../ts-components/Text'
import { CloseOutlined } from '@ant-design/icons'
import ManageRefreshmentsModalContent from './ManageRefreshmentsModalContent'
import useGamePlayerRoster from '@sportninja/common/hooks/useGamePlayerRoster'

const styles = css`
  .my-modal-body {
  }
  .my-modal-mask {
    backdrop-filter: blur(1px);
  }
  .my-modal-header {
    border-bottom: 1px dotted ${colors.FIERY_RED};
  }
  .my-modal-footer {
    color: ${colors.FIERY_RED};
  }
  .my-modal-content {
    border: 1px solid #333;
  }
`
const classNames = {
  body: styles['my-modal-body'],
  mask: styles['my-modal-mask'],
  header: styles['my-modal-header'],
  footer: styles['my-modal-footer'],
  content: styles['my-modal-content'],
}
const modalStyles = {
  header: {
    backgroundColor: colors.SECONDARY_300,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  body: {
    borderBottom: `1px solid ${colors.SOFT_STEEL}`,
    borderTop: `1px solid ${colors.SOFT_STEEL}`,
    backgroundColor: colors.SECONDARY_300,
  },
  content: {
    backgroundColor: colors.SECONDARY_300,
    paddingTop: '8px',
    paddingBottom: '16px',
    paddingLeft: '0',
    paddingRight: '0',
  },
  wrapper: {
    zIndex: 10001,
  },
  mask: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(6, 10, 16, 0.8)',
    zIndex: 10000,
  },
  footer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

const ManageRefreshmentsModal = ({
  isManageRefreshmentsModalOpen,
  setIsManageRefreshmentsModalOpen,
  selectedManageRefreshments,
  readAttendanceInformation = () => {},
}: {
  isManageRefreshmentsModalOpen: boolean
  setIsManageRefreshmentsModalOpen: (isOpen: boolean) => void
  selectedManageRefreshments: {
    gameId: string
    rosterId: string
    teamId: string
    teamName: string
    showWaiver: boolean
  } | null
  readAttendanceInformation: () => void
}) => {
  const { roster, officials, refetch } = useGamePlayerRoster(
    selectedManageRefreshments?.gameId,
    selectedManageRefreshments?.rosterId
  )

  // roster?.players
  // officials?.team_officials
  // just merge the two in a single array
  const mergedRoster = useMemo(() => {
    if (!roster || !officials) {
      return []
    }
    return [...roster.players, ...officials.team_officials]
  }, [roster, officials])

  const getFooter = useCallback(
    (_context: ManageRefreshmentsModalContextType) => {
      return (
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            gap: 16px;
            width: 50%;
            margin-left: auto;
          `}
        >
          <Button
            text='Close'
            onClick={() => {
              setIsManageRefreshmentsModalOpen(false)
            }}
            variant='secondary'
          />
        </div>
      )
    },
    [setIsManageRefreshmentsModalOpen]
  )
  const getHeader = useCallback(() => {
    return (
      <Text
        variant='heading3'
        weight='semiBold'
        color={colors.WHITE}
        textTransform='capitalize'
      >
        {/* {`Manage Refreshments for ${selectedManageRefreshments?.teamName}`} */}
        Choose Player/Team Official
      </Text>
    )
  }, [])
  const closeIcon = useCallback(() => {
    return (
      <CloseOutlined
        css={css`
          color: ${colors.SOLITUDE};
          font-size: 16px;
        `}
      />
    )
  }, [])

  return (
    <ManageRefreshmentsModalProvider>
      {/* consumer */}
      <ManageRefreshmentsModalContext.Consumer>
        {(context) => {
          if (!context) {
            return null
          }
          return (
            <Modal
              open={isManageRefreshmentsModalOpen}
              onOk={() => setIsManageRefreshmentsModalOpen(false)}
              onCancel={() => setIsManageRefreshmentsModalOpen(false)}
              css={css`
                .ant-modal-wrap {
                  z-index: 10001;
                }
              `}
              width={600}
              centered
              classNames={classNames}
              styles={modalStyles}
              maskClosable={false}
              footer={getFooter(context)}
              title={getHeader()}
              closeIcon={closeIcon()}
            >
              <ManageRefreshmentsModalContent
                players={mergedRoster || []}
                gameId={roster?.game_id}
                rosterId={roster?.id}
                refetch={refetch}
                setIsManageRefreshmentsModalOpen={
                  setIsManageRefreshmentsModalOpen
                }
                readAttendanceInformation={readAttendanceInformation}
              />
            </Modal>
          )
        }}
      </ManageRefreshmentsModalContext.Consumer>
    </ManageRefreshmentsModalProvider>
  )
}

export default ManageRefreshmentsModal
