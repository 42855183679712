/** @jsxImportSource @emotion/react */
import { Fragment, useCallback, useState } from 'react'
import css from '@emotion/css/macro'
import { t } from '@sportninja/common/i18n'
import { ROUTES } from '@sportninja/common/constants/app'
import AuthWrap from '../../components/AuthWrap'
import { H1, BodyText } from '../../components/AuthWrap/css'
import Form from '../../components/Form'
import AuthSecondaryLink from '../../components/AuthWrap/AuthSecondaryLink'

const onError = (_, error) => {
  if (error.status === 404) {
    return { email: t('errors:couldNotFindAccount') }
  } else {
    return { message: error.message }
  }
}

const ForgotPassword = ({ forgot, forgotRegistration, history }) => {
  const form = [
    [
      {
        name: 'email',
        label: t('common:email'),
        autoComplete: 'email',
        type: 'email',
        autoCorrect: 'off',
        autoCapitalize: 'none',
        required: true,
      },
    ],
  ]

  const isRegistration = new URLSearchParams(window.location.search).get(
    'is_registration'
  )

  const registrationSessionId = new URLSearchParams(window.location.search).get(
    'registration_session_id'
  )

  const scheduleId = new URLSearchParams(window.location.search).get(
    'schedule_id'
  )

  const [step, setStep] = useState(0)
  // We'll track the email when the form is submitted, so we can display it on
  // the success screen
  const [successEmail, setEmail] = useState('')

  const goToLogin = useCallback(() => history.push(ROUTES.LOGIN))
  const onSubmit = useCallback(async (form) => {
    let formToSubmit = form
    if (isRegistration && registrationSessionId) {
      formToSubmit = {
        ...form,
        registration_session_id: registrationSessionId,
      }
    }
    if (isRegistration && registrationSessionId) {
      await forgotRegistration(formToSubmit, scheduleId)
    } else {
      await forgot(formToSubmit)
    }
    setEmail(form.email)
    setStep(1)
  }, [])

  const title = t('Auth:forgotPassword')

  return (
    <AuthWrap title={title} hideCancel>
      <H1>{[title, `${t('Auth:almostReady')}!`][step]}</H1>
      {
        [
          <Fragment key='form'>
            <BodyText>
              <p>{t('Auth:pleaseEnterEmail')}</p>
            </BodyText>
            <Form
              css={css`
                flex: initial;
              `} // We don't want the form to flex in height
              form={form}
              hideCancel
              onError={onError}
              onSubmit={onSubmit}
              submitText={t('common:submit')}
            />
          </Fragment>,
          <BodyText key='success'>
            <p>{t('Auth:anEmailHasBeenSent', { x: successEmail })}</p>
            <p>
              {t('Auth:ifYouNeedALittleHelp')},{' '}
              <a
                href='mailto:support@sportninja.com?subject=%5BSupport%5D%20I%20forgot%20my%20password'
                css={css`
                  text-decoration: underline;
                `}
              >
                {t('Auth:pleaseClickHere')}.
              </a>
            </p>
          </BodyText>,
        ][step]
      }
      <AuthSecondaryLink
        buttonText={t('common:login')}
        onClick={goToLogin}
        text={`${t('Auth:orIfYouRememberYourPassword')}:`}
      />
    </AuthWrap>
  )
}

export default ForgotPassword
