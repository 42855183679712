/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import cn from 'classnames'
import * as csv from 'csvtojson'
import dayjs from 'dayjs'
import React from 'react'
import SuspensionSelect from '../../components/Suspensions/SuspensionSelect'
import DatePicker from '../DatePicker'
import { FormButton } from '../Form/css'
import Icon from '../Icon'
import { Table, Alert } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import { SearchOutlined } from '@ant-design/icons'

// Generic function to transform CSV data to a consistent format
const transformData = (dataArray, reportType) => {
  if (!dataArray || !dataArray.length) {
    return []
  }
  return dataArray.map((item) => {
    const transformedItem = {}

    // Process each field in the CSV row
    Object.keys(item).forEach((key) => {
      // Preserve the original header title as the key
      transformedItem[key] = item[key]

      // Special case handling based on report type
      if (reportType === 'teamEngagement' && key === 'Has Logo') {
        // Convert '1' to true for 'Has Logo' field
        transformedItem[key] = item[key] === '1'
      }
    })

    return transformedItem
  })
}

const dropDownOptions = [
  {
    text: 'Penalty Report',
    value: 'penaltiesByTeam',
  },
  {
    text: 'Games Played',
    value: 'gamesPlayed',
  },
  {
    text: 'Team Engagement',
    value: 'teamEngagement',
  },
  {
    text: 'Registration Participant Info',
    value: 'registrations',
  },
  {
    text: 'Registration Revenue Summary',
    value: 'registrationRevenue',
  },
  {
    text: 'Registration Transactions',
    value: 'registrationTransaction',
  },
]

const reportEndpoints = {
  penaltiesByTeam: '/reports/:entityId/team_penalty_minutes',
  registrations: '/reports/:entityId/registration',
  gamesPlayed: '/reports/:entityId/games_played',
  teamEngagement: '/reports/:entityId/team_engagement',
  registrationRevenue: '/reports/:entityId/registration',
  registrationTransaction: '/reports/:entityId/registration',
}

const reportOrgEndpoints = {
  penaltiesByTeam: '/reports/org/:entityId/team_penalty_minutes',
  registrations: '/reports/org/:entityId/registration',
  gamesPlayed: '/reports/org/:entityId/games_played',
  teamEngagement: '/reports/org/:entityId/team_engagement',
  registrationRevenue: '/reports/org/:entityId/registration',
  registrationTransaction: '/reports/org/:entityId/registration',
}

// Function to generate dynamic columns based on data
const generateDynamicColumns = (data, reportType) => {
  if (!data || !data.length) {
    return []
  }

  // Get all unique keys from the data
  const keys = Object.keys(data[0])

  return keys.map((key) => {
    // Special handling for URL field
    if (key === 'URL' || key === 'url') {
      return {
        title: 'URL',
        dataIndex: key,
        key: key,
        render: (item) => {
          if (!item) {
            return null
          }
          return (
            <a
              css={css`
                color: #ffffff;
                white-space: nowrap;
                width: 100%;
              `}
              href={item}
              target='_blank'
              rel='noreferrer'
            >
              <SearchOutlined />
            </a>
          )
        },
      }
    }

    // Special handling for date fields
    if (
      key === 'date' ||
      key === 'Date' ||
      key.includes('date') ||
      key.includes('Date')
    ) {
      return {
        // title: formatColumnTitle(key),
        title: key,
        dataIndex: key,
        key: key,
        render: (item) => {
          if (!item) {
            return null
          }
          return (
            <span
              css={css`
                color: #ffffff;
                white-space: nowrap;
                width: 100%;
              `}
            >
              {dayjs(item).isValid()
                ? dayjs(item).format('YYYY-MM-DD hh:mm A')
                : item}
            </span>
          )
        },
      }
    }

    // Special handling for boolean fields (like hasLogo in teamEngagement)
    if (reportType === 'teamEngagement' && key === 'hasLogo') {
      return {
        title: 'Has Logo',
        dataIndex: key,
        key: key,
        render: (item) => {
          return (
            <span
              css={css`
                color: #ffffff;
                white-space: nowrap;
                width: 100%;
              `}
            >
              {item ? 'Yes' : 'No'}
            </span>
          )
        },
      }
    }

    // Default rendering for all other fields
    return {
      // title: formatColumnTitle(key),
      title: key,
      dataIndex: key,
      key: key,
      render: (item) => {
        return (
          <span
            css={css`
              color: #ffffff;
              white-space: nowrap;
              width: 100%;
            `}
          >
            {item !== undefined && item !== null ? item : '-'}
          </span>
        )
      },
    }
  })
}

const generateHref = (string, reportName, ref) => {
  // Create formatted timestamp for filename
  const timestamp = dayjs().format('YYYYMMDDHHMMSS')
  // Format the filename: REPORTNAME_TIMESTAMP (all uppercase)
  const formattedFilename = `${reportName.toUpperCase()}_${timestamp}.csv`

  var blob = new Blob([string], { type: 'text/csv;charset=utf-8;' })

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, formattedFilename)
  } else {
    const link = ref.current
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', formattedFilename)
    }
  }
}

const NewReports = ({ entityId, entityType }) => {
  const [schedules, setSchedules] = React.useState([])
  const [schedule, setSchedule] = React.useState('')
  const [report, setReport] = React.useState('penaltiesByTeam')
  const [loading, setLoading] = React.useState(false)
  const [csvString, setCSVString] = React.useState(false)
  const [columns, setColumns] = React.useState([])
  const [dateRange, setDateRange] = React.useState({
    starts_at: undefined,
    ends_at: undefined,
  })
  const [error, setError] = React.useState(null)
  const downloadRef = React.useRef(null)

  const [dataAsJson, setDataAsJson] = React.useState([])

  React.useEffect(() => {
    const getSchedules = async () => {
      try {
        setLoading(true)
        const { data } = await req(
          `/organizations/${entityId}/schedules?sort=starts_at&direction=desc`
        )
        setSchedules(data.map((s) => ({ text: s.name, value: s.id })))
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    if (entityType === ENTITY_TYPES.org) {
      getSchedules()
    }
  }, [entityId, entityType])

  const onView = async () => {
    try {
      setError(null)

      const query = {}
      if (dateRange.starts_at && dateRange.ends_at) {
        query.starts_after = dayjs(dateRange.starts_at)
          .hour(0)
          .minute(0)
          .second(0)
          .format()
        query.starts_before = dayjs(dateRange.ends_at)
          .hour(0)
          .minute(0)
          .second(0)
          .add(1, 'day')
          .format()
      }
      query.format = 'csv'
      // Add the report type to the query
      if (report === 'registrationRevenue') {
        query.report_type = 'revenue'
      } else if (report === 'registrationTransaction') {
        query.report_type = 'transaction'
      } else if (report === 'registrations') {
        query.report_type = 'info'
      }
      let url = ''
      if (entityType === ENTITY_TYPES.org) {
        if (schedule === '') {
          url = reportOrgEndpoints[report].replace(':entityId', entityId)
        } else {
          url = reportEndpoints[report].replace(':entityId', schedule)
        }
      } else {
        url = reportEndpoints[report].replace(':entityId', entityId)
      }
      const response = await req(url, { parseJSON: false, query })
      const text = await response.text()
      setCSVString(text)
      generateHref(text, report, downloadRef)
      csv({ output: 'json' })
        .fromString(text)
        .then((result) => {
          console.log('result', result)
          // Use the generic transform function for all report types
          const transformedData = transformData(result, report)
          setDataAsJson(transformedData)

          // Generate dynamic columns based on the transformed data
          if (transformedData && transformedData.length > 0) {
            const dynamicColumns = generateDynamicColumns(
              transformedData,
              report
            )
            setColumns(dynamicColumns)
          }
        })
    } catch (e) {
      console.log('e', e)
      console.error(e)
      setError(
        `There was an error generating the report. ${
          e?.message || ''
        } Please try again.`
      )
    }
  }

  return (
    <div
      css={css`
        margin: 24px auto;
        display: flex;
        flex-direction: column;
        /* overflow-x: auto; */
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          align-self: flex-start;
        `}
      >
        <SuspensionSelect
          className={cn('game-list-filters-team', {
            'is-selected': !!report,
          })}
          disabled={loading}
          enabledAllItemsOption={false}
          label={t('util:capitalize', { text: 'Report Type' })}
          value={report}
          onChange={(selectedReport) => {
            setDataAsJson([])
            setColumns([])
            setError(null)
            setReport(selectedReport)
          }}
          options={dropDownOptions}
        />
        {entityType === ENTITY_TYPES.org ? (
          <SuspensionSelect
            className={cn('game-list-filters-team', {
              'is-selected': !!schedule,
            })}
            disabled={loading}
            enabledAllItemsOption={true}
            label={t('util:capitalize', {
              text: isCanlan ? 'Season' : 'Competition',
            })}
            value={schedule}
            onChange={(selectedReport) => {
              setDataAsJson([])
              setColumns([])
              setError(null)
              setSchedule(selectedReport)
            }}
            options={schedules}
          />
        ) : null}
        <div
          css={css`
            margin: 0 16px 16px 0;
            width: 260px;
            min-width: 260px;

            .picker-label {
              height: 12px;
              font-size: 12px;
              line-height: 12px;
              margin-bottom: 8px;
              letter-spacing: 1.04px;
            }

            .picker-value-container {
              background: #27303e;
              border: ${isCanlan ? 2 : 1}px solid #3f4753;
              border-radius: 4px;
              height: 40px;
            }
          `}
        >
          <DatePicker
            datePlaceholder='Select A Date Range'
            defaultValues={(() => {
              const values = {}
              if (dateRange.starts_at) {
                values.starts_at = { value: dateRange.starts_at }
              }
              if (dateRange.ends_at) {
                values.ends_at = { value: dateRange.ends_at }
              }
              return values
            })()}
            disabled={loading}
            hideTime
            label='Date Range'
            onChange={({ target }) => {
              try {
                const { name, value } = target
                setDateRange((prevDateRange) => {
                  return {
                    ...prevDateRange,
                    [name]: value,
                  }
                })
              } catch (e) {
                console.error(e)
              }
            }}
            useRange
          />
        </div>
        <FormButton
          isSubmit
          css={css`
            height: 46px;
            width: 100px;
          `}
          onClick={async () => {
            try {
              setLoading(true)
              await onView()
            } finally {
              setLoading(false)
            }
          }}
        >
          <Icon
            name='search'
            css={css`
              margin-right: 6px;
              transform: translateY(-1px);
            `}
            fontSize={14}
          />
          View
        </FormButton>
        <FormButton
          as='a'
          disabled={!csvString || dataAsJson.length === 0}
          isSubmit
          css={css`
            width: 200px;
            height: 46px;
            text-align: center;
            padding-top: 12px;
            margin-left: 16px;
          `}
          ref={downloadRef}
        >
          <Icon
            name='download'
            css={css`
              margin-right: 6px;
            `}
            fontSize={14}
          />
          Download CSV
        </FormButton>
      </div>
      {error && (
        <Alert
          showIcon
          message={error}
          type='error'
          css={css`
            margin-bottom: 16px;
            width: 100%;
          `}
          onClose={() => setError(null)}
        />
      )}
      <Table
        css={css`
          overflow-x: auto;
          width: 100%;
          background: #27303e;
          border-radius: 8px;
          * {
            border-color: ${colors.SOFT_STEEL} !important;
          }
          .ant-table-column-sorter {
            display: none;
          }

          /* Ensure table headers display full content */
          .ant-table-thead > tr > th {
            white-space: nowrap;
            overflow: visible;
            text-overflow: unset;
            /* Removed max-width constraint to allow headers to take necessary width */
          }

          /* Ensure table cells maintain the same styling */
          .ant-table-tbody > tr > td {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        `}
        columns={
          columns.length > 0
            ? columns.map((column) => ({
                ...column,
                // Add ellipsis property to handle text overflow in column headers
                ellipsis: false, // Changed to false to prevent truncation
                // Add a custom title render function to ensure single line
                title: (
                  <div
                    css={css`
                      white-space: nowrap;
                      overflow: visible;
                      text-overflow: unset;
                    `}
                  >
                    {column.title}
                  </div>
                ),
              }))
            : []
        }
        dataSource={dataAsJson}
        loading={loading}
        bordered
        size='small'
        pagination={{
          pageSize: 50,
          defaultPageSize: 50,
          showSizeChanger: false,
        }}
      />
    </div>
  )
}

export default NewReports
