/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Table, Tooltip, Input, Alert } from 'antd'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MenuOutlined,
  // DollarOutlined,
  // EllipsisOutlined,
  SearchOutlined,
  HourglassOutlined,
  // EyeOutlined,
  ExportOutlined,
} from '@ant-design/icons'
import Text from 'src/components/ts-components/Text'
import {
  UserRegistration,
  useUserRegistrationData,
} from '@sportninja/common/hooks/useUserRegistrationData'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { formatShortDate } from '@sportninja/common/ts-utils/date-utils'
import { useRegistrationData } from '@sportninja/common/hooks/useRegistrationData'
import { formatCurrency, getErrorMessage } from '@sportninja/common/utils/utils'
import { useState, useMemo, useEffect } from 'react'
// import RegistrationBilling from '../RegistrationBilling'
import useWindowSize from 'src/hooks/useWindowSize'
import dayjs from 'dayjs'
import req from '@sportninja/common/api/request'

const RegistrationList = () => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < 1024, [width])
  const { types, fetchTypes } = useRegistrationData()
  const { registrationState } = useUserRegistrationData()
  const { registrations, loading, error } = registrationState
  // const [selectedRegistration, setSelectedRegistration] =
  //   useState<UserRegistration | null>(null)
  // const [isBillingModalOpen, setIsBillingModalOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [openSessionError, setOpenSessionError] = useState<string | null>(null)

  useEffect(() => {
    if (!types || types === null) {
      fetchTypes()
    }
  }, [fetchTypes, types])

  const filteredRegistrations = useMemo(() => {
    if (!searchText) {
      return registrations
    }

    const searchLower = searchText.toLowerCase().trim()
    return registrations.filter((registration) => {
      const teamName = registration?.team?.name?.toLowerCase() || ''
      const scheduleName = registration?.schedule?.name?.toLowerCase() || ''

      return (
        teamName.includes(searchLower) || scheduleName.includes(searchLower)
      )
    })
  }, [registrations, searchText])

  const columns = [
    {
      title: (
        <Text
          variant='captionSmall'
          weight='semiBold'
          color={colors.NEUTRAL_0}
          style={`
            text-transform: uppercase;
            text-align: left;
            margin-left: 8px;
          `}
        >
          Name
        </Text>
      ),
      dataIndex: ['team', 'name'],
      key: 'team_name',
      width: 180,
      align: 'left',
      sorter: (a, b) => {
        if (!a.team?.name) {
          return 1
        }
        if (!b.team?.name) {
          return -1
        }
        return a?.team?.name?.localeCompare(b?.team?.name)
      },
      render: (text, record: UserRegistration) => {
        if (!text) {
          return (
            <Text
              variant='paragraphMedium'
              weight='regular'
              color={colors.WHITE}
              style={`
                margin-left: 8px;
              `}
            >
              -
            </Text>
          )
        }

        const isBucket = record?.team?.is_bucket
        const playerName = record?.player
          ? `${record.player.name_first} ${record.player.name_last}`
          : null

        const handleClick = async () => {
          if (
            record.status_id === 6 ||
            // @ts-ignore
            record?.type === 'registration_session'
          ) {
            try {
              setOpenSessionError(null)
              await req(`/registration/session/${record.uid}/resume`, {
                method: 'PUT',
              })
              window.open(
                `/registration/${record.schedule.uid}?registration_session_uid=${record.uid}`,
                '_blank'
              )
            } catch (e) {
              const erroMessage = getErrorMessage(e)
              setOpenSessionError(erroMessage)
            }
          } else if (record?.billing?.length > 0) {
            window.open(
              `/registration-details/${record.schedule.uid}/info?registration_id=${record.uid}`,
              '_blank'
            )
          }
        }

        const playerImage = getImageThumbnailId(record.player)
        const teamImage = getImageThumbnailId(record.team)

        return (
          <div
            onClick={handleClick}
            css={css`
              &:hover {
                text-decoration: underline;
              }
              display: flex;
              align-items: ${playerName ? 'flex-start' : 'center'};
              gap: 8px;
              margin-left: 8px;
              cursor: ${record.status_id === 6 ||
              // @ts-ignore
              record?.type === 'registration_session' ||
              record?.billing?.length > 0
                ? 'pointer'
                : 'default'};
              width: 150px;
              /* on mobile, width is 100% */
              @media (max-width: 768px) {
                width: 100%;
              }
            `}
          >
            <img
              src={
                isBucket
                  ? '/images/bucket_image.png'
                  : playerName
                  ? playerImage ||
                    '/images/generic_placeholders/placeholder_player.png'
                  : teamImage ||
                    '/images/generic_placeholders/placeholder_team.png'
              }
              alt='team logo'
              css={css`
                width: 32px;
                height: 32px;
                background: ${colors.WHITE};
                border-radius: ${isBucket ? '8px' : '4px'};
                padding: ${isBucket ? '0px' : '2px'};
                object-fit: contain;
                margin-top: ${playerName ? '4px' : '0'};
              `}
              onError={(e) => {
                const img = e.target as HTMLImageElement
                img.onerror = null
                if (isBucket) {
                  img.src = '/images/bucket_image.png'
                } else if (playerName) {
                  img.src =
                    '/images/generic_placeholders/placeholder_player.png'
                } else {
                  img.src = '/images/generic_placeholders/placeholder_team.png'
                }
              }}
            />
            <div>
              {playerName ? (
                <>
                  <Text
                    variant='paragraphMedium'
                    weight='medium'
                    color={colors.WHITE}
                  >
                    {playerName}
                  </Text>
                  <Text
                    variant='paragraphXSmall'
                    weight='regular'
                    color={colors.WHITE}
                  >
                    {text}
                  </Text>
                </>
              ) : (
                <Text
                  variant='paragraphMedium'
                  weight='regular'
                  color={colors.WHITE}
                >
                  {text}
                </Text>
              )}
            </div>
          </div>
        )
      },
    },
    {
      title: (
        <Text
          variant='captionSmall'
          weight='semiBold'
          color={colors.NEUTRAL_0}
          style={`
            text-transform: capitalize;
            text-align: left;
          `}
        >
          Type
        </Text>
      ),
      dataIndex: 'registration_type',
      key: 'registration_type',
      width: 80,
      align: 'left',
      sorter: (a, b) => a.registration_type.localeCompare(b.registration_type),
      render: (text) => (
        <Text variant='paragraphMedium' weight='regular' color={colors.WHITE}>
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text
          variant='captionSmall'
          weight='semiBold'
          color={colors.NEUTRAL_0}
          style={`
            text-transform: capitalize;
            text-align: left;
          `}
        >
          Competition
        </Text>
      ),
      dataIndex: ['schedule', 'name'],
      key: 'schedule.name',
      width: 180,
      align: 'left',
      filters: registrations
        .map((reg) => ({
          text: reg?.schedule?.name,
          value: reg?.schedule?.name,
        }))
        .filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i),
      onFilter: (value, record) => record?.schedule?.name === value,
      render: (text, record) => (
        <div
          css={css`
            width: 230px;
            /* on mobile, width is 100% */
            @media (max-width: 768px) {
              width: 100%;
            }
          `}
        >
          <Text
            variant='paragraphMedium'
            weight='regular'
            color={colors.WHITE}
            style={`
              text-transform: capitalize;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            <a
              href={`/schedule/${record.schedule.uid}${
                record.team && record.team.uid
                  ? `?sn_team=${record.team.uid}`
                  : ''
              }`}
              css={css`
                &:hover {
                  text-decoration: underline;
                }
              `}
            >
              {text}
            </a>
          </Text>
          <Text
            variant='paragraphXSmall'
            weight='regular'
            color={colors.NEUTRAL_50}
          >
            {formatShortDate(record.schedule.start_date)} -{' '}
            {formatShortDate(record.schedule.end_date)}
          </Text>
        </div>
      ),
    },
    {
      title: (
        <Text
          variant='captionSmall'
          weight='semiBold'
          color={colors.NEUTRAL_0}
          style={`
            text-transform: capitalize;
            text-align: left;
          `}
        >
          Reg. Date
        </Text>
      ),
      key: 'created_at',
      width: 100,
      align: 'left',
      sorter: (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (_, record) => (
        <Text variant='paragraphMedium' weight='regular' color={colors.WHITE}>
          {formatShortDate(record.created_at, false)}
        </Text>
      ),
    },
    {
      title: (
        <Text
          variant='captionSmall'
          weight='semiBold'
          color={colors.NEUTRAL_0}
          style={`
            text-transform: capitalize;
            text-align: left;
          `}
        >
          Outstanding
        </Text>
      ),
      key: 'outstanding',
      width: 120,
      align: 'left',
      render: (_, record) => {
        const billingCurrency = record?.billing?.[0]?.currency
        const currency = types?.currency?.find(
          (c) => Number(c.id) === billingCurrency
        )?.name
        if (record.is_comp) {
          return <span>{formatCurrency(0, currency)}</span>
        }
        const isPlayerRegistration = record?.registration_type !== 'Team'
        const total =
          record?.billing
            ?.filter((item) =>
              isPlayerRegistration
                ? item.user_type === 'player'
                : item.user_type === 'teamRep'
            )
            .reduce((acc, curr) => acc + curr.subtotal, 0) || 0
        const paid =
          record?.billing
            ?.filter((item) =>
              isPlayerRegistration
                ? item.user_type === 'player'
                : item.user_type === 'teamRep'
            )
            .filter((item) => item.paid)
            .reduce((acc, curr) => acc + curr.subtotal, 0) || 0
        return (
          <Text variant='paragraphMedium' weight='regular' color={colors.WHITE}>
            {formatCurrency(total - paid, currency)}
          </Text>
        )
      },
    },
    {
      title: (
        <Text
          variant='captionSmall'
          weight='semiBold'
          color={colors.NEUTRAL_0}
          style={`
            text-transform: capitalize;
            text-align: left;
          `}
        >
          Paid
        </Text>
      ),
      key: 'paid',
      width: 120,
      align: 'left',
      render: (_, record) => {
        // const billingCurrency = record?.billing?.[0]?.currency
        // const currency = types?.currency?.find(
        //   (c) => Number(c.id) === billingCurrency
        // )?.name
        // const totalPaid = record?.billing?.reduce((acc, curr) => {
        //   if (curr.paid) {
        //     return acc + curr.subtotal
        //   }
        //   return acc
        // }, 0)

        const billingCurrency = record?.billing?.[0]?.currency
        const currency = types?.currency?.find(
          (c) => Number(c.id) === billingCurrency
        )?.name
        const isPlayerRegistration = record?.registration_type !== 'Team'
        if (record.is_comp) {
          return <span>--</span>
        }
        let totalPaid = 0
        record?.billing
          ?.filter((item) =>
            isPlayerRegistration
              ? item.user_type === 'player'
              : item.user_type === 'teamRep'
          )
          .forEach((payment) => {
            if (payment.paid) {
              totalPaid += payment.subtotal
            } else if (dayjs(payment.due_date).isBefore(dayjs())) {
              // hasPaymentDelayed = true
            }
          })
        return (
          <Text variant='paragraphMedium' weight='regular' color={colors.WHITE}>
            {formatCurrency(totalPaid || 0, currency)}
          </Text>
        )
      },
    },
    {
      title: (
        <Text
          variant='captionSmall'
          weight='semiBold'
          color={colors.NEUTRAL_0}
          style={`
            text-transform: capitalize;
            text-align: left;
          `}
        >
          Status
        </Text>
      ),
      key: 'status_id',
      dataIndex: 'status_id',
      width: 50,
      align: 'left',
      filters: [
        { text: 'Submitted', value: 1 },
        { text: 'Pending', value: 2 },
        { text: 'Approved', value: 3 },
        { text: 'Approved w/ Conditions', value: 4 },
        { text: 'Cancelled', value: 5 },
        { text: 'In Progress', value: 6 },
      ],
      onFilter: (value, record) => record.status_id === value,
      render: (status_id) => {
        const statusMap = {
          1: {
            icon: <HourglassOutlined />,
            tooltip: 'Submitted',
            color: colors.INFO_200,
          },
          2: {
            icon: <ExclamationCircleOutlined />,
            tooltip: 'Pending',
            color: colors.WARNING_200,
          },
          3: {
            icon: <CheckCircleOutlined />,
            tooltip: 'Approved',
            color: colors.SUCCESS_200,
          },
          4: {
            icon: <CheckCircleOutlined />,
            tooltip: 'Appd. w/ Cond.',
            color: colors.WARNING_200,
          },
          5: {
            icon: <CloseCircleOutlined />,
            tooltip: 'Cancelled',
            color: colors.ERROR_200,
          },
          6: {
            icon: <HourglassOutlined />,
            tooltip: 'In Progress',
            color: colors.INFO_200,
          },
        }

        const status = statusMap[status_id]

        return (
          <div
            css={css`
              display: inline-flex;
              align-items: center;
              gap: 8px;
              padding: 8px 4px;
              border-radius: 4px;
              color: ${status?.color};
              background: transparent;
              justify-content: flex-start;
              white-space: nowrap;
              width: 130px;
              /* on mobile, width is 100% */
              @media (max-width: 768px) {
                width: 100%;
                justify-content: flex-start;
              }
            `}
          >
            <span
              css={css`
                font-size: 16px;
                display: flex;
                align-items: center;
                flex-shrink: 0;
              `}
            >
              {status?.icon}
            </span>
            <Text
              variant='paragraphSmall'
              weight='regular'
              color={colors.WHITE}
              style={`
                max-width: 100px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
            >
              {status?.tooltip}
            </Text>
          </div>
        )
      },
    },
    {
      title: (
        <Text
          variant='captionSmall'
          weight='semiBold'
          color={colors.NEUTRAL_0}
          style={`
            text-transform: capitalize;
            text-align: center;
          `}
        >
          <Tooltip title='Options' placement='top' zIndex={11000}>
            <MenuOutlined
              css={css`
                font-size: 16px;
                color: ${colors.NEUTRAL_100};
              `}
            />
          </Tooltip>
        </Text>
      ),
      key: 'actions',
      width: 50,
      align: 'center',
      render: (_, record) => {
        return (
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <Tooltip
              title={
                record.status_id === 6 || record.type === 'registration_session'
                  ? 'Open Registration Session'
                  : 'View Details'
              }
            >
              {record.status_id === 6 ||
              record.type === 'registration_session' ? (
                <ExportOutlined
                  css={css`
                    font-size: 20px;
                    color: ${colors.PRIMARY};
                    cursor: pointer;
                  `}
                  onClick={async () => {
                    try {
                      setOpenSessionError(null)
                      await req(`/registration/session/${record.uid}/resume`, {
                        method: 'PUT',
                      })
                      window.open(
                        `/registration/${record.schedule.uid}?registration_session_uid=${record.uid}`,
                        '_blank'
                      )
                    } catch (e) {
                      const erroMessage = getErrorMessage(e)
                      setOpenSessionError(erroMessage)
                    }
                  }}
                />
              ) : (
                <a
                  href={`/registration-details/${record.schedule.uid}/info?registration_id=${record.uid}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <ExportOutlined
                    css={css`
                      font-size: 20px;
                      color: ${colors.PRIMARY};
                      cursor: pointer;
                    `}
                  />
                </a>
              )}
            </Tooltip>
          </div>
        )
      },
    },
  ]

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        flex: 1;
        width: 100%;
        ${isMobile &&
        css`
          padding-bottom: 48px;
        `}
      `}
    >
      {error ? (
        <Alert
          message={error}
          type='error'
          showIcon
          css={css`
            margin-bottom: 16px;
          `}
        />
      ) : (
        <>
          <div
            css={css`
              display: flex;
              justify-content: flex-start;
              margin-bottom: 16px;
              /* on mobile, width is 100% */
              @media (max-width: 768px) {
                width: 100%;
                padding: 0 16px;
              }
            `}
          >
            <Input
              placeholder='Search by Team or Competition'
              prefix={
                <SearchOutlined
                  css={css`
                    font-size: 16px;
                    color: ${colors.NEUTRAL_50};
                  `}
                />
              }
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              css={css`
                width: 300px;
                background-color: ${colors.SECONDARY_500};
                border: 1px solid ${colors.SECONDARY_200};
                border-radius: 8px;
                color: ${colors.WHITE};
                font-size: 14px;
                font-weight: 400;
                line-height: 20.3px;
                @media (max-width: 768px) {
                  width: 100%;
                }
                .ant-input {
                  background-color: ${colors.SECONDARY_500};
                  color: ${colors.WHITE};
                }
                .ant-input-prefix {
                  color: ${colors.NEUTRAL_100};
                }
                &::placeholder {
                  color: ${colors.NEUTRAL_100};
                }
              `}
            />
          </div>
          {openSessionError && (
            <Alert
              message={openSessionError}
              type='error'
              showIcon
              css={css`
                margin-bottom: 16px;
              `}
            />
          )}
          <Table
            // @ts-ignore
            columns={columns}
            dataSource={filteredRegistrations}
            loading={loading}
            size='small'
            scroll={{ x: isMobile || width < 1200 ? 1500 : undefined }}
            pagination={{
              defaultPageSize: 20,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
            }}
            bordered
            css={css`
              .ant-table-thead > tr > th {
                padding: 16px 8px !important;
                background-color: ${colors.SECONDARY_300} !important;
              }
              .ant-table-tbody > tr:nth-child(even) {
                background-color: ${colors.SECONDARY_300};
              }
              .ant-table-tbody > tr:nth-child(odd) {
                background-color: ${colors.SECONDARY_400};
              }
              .ant-table-tbody > tr > td {
                padding: 8px 8px !important;
                vertical-align: middle;
              }
              @media (max-width: 768px) {
                padding: 0 16px !important;
              }
            `}
          />
        </>
      )}
      {/* <RegistrationBilling
        isOpen={isBillingModalOpen}
        onClose={() => setIsBillingModalOpen(false)}
        selectedRegistration={selectedRegistration}
        currencies={types?.currency || []}
      /> */}
    </div>
  )
}

export default RegistrationList
