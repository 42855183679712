import req from '@sportninja/common/api/request'
import { useCallback, useEffect, useState } from 'react'
import { Team } from '@sportninja/common/types/Team'
import { Registration } from '@sportninja/common/types/Registration'
interface Division {
  id: string
  name: string
  schedule_id: string
  is_registration_on: number
  organization_id: string
  organization_name: string
  official_governing_body_event_number: string | null
  currency: {
    id: string
    name: string
    name_full: string | null
    abbreviation: string | null
  }
  day_of_week: {
    id: string
    name: string
    name_full: string | null
    abbreviation: string | null
  }
  division_gender: {
    id: string
    name: string
    name_full: string | null
    abbreviation: string | null
  }
  max_teams: number
  team_count_enrolled: number
  team_count: number
  teams: Team[] | null
  deposit: number
  deposit_required: boolean
  single_payment: boolean
  team_cost: number
  division_age: {
    id: string
    name: string
    name_full: string | null
    abbreviation: string | null
  }
  max_players_per_team: number
  max_team_rep_per_team: number
  installments: number
  installment_start_date: string | null
  installment_frequency: string | null
  player_cost: number
}

export interface RegistrationData {
  id: string
  name: string
  abbreviation: string
  image: string | null
  type: string
  sport_id: string
  registration: Registration
  divisions: Division[]
}

export const useRegistration = (registrationId?: string) => {
  const [data, setData] = useState<RegistrationData | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = useCallback(async () => {
    if (!registrationId) {
      return
    }
    setLoading(true)
    setError(null)
    try {
      const response = await req(`/registration/${registrationId}`)
      setData(response.data)
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error('An error occurred while fetching registration data')
      )
    } finally {
      setLoading(false)
    }
  }, [registrationId])

  useEffect(() => {
    if (registrationId) {
      fetchData()
    }
  }, [fetchData, registrationId])

  return {
    data,
    loading,
    error,
    refetch: fetchData,
  }
}
