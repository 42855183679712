/* eslint-disable no-prototype-builtins */
/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'

import {
  playoffsIds,
  playoffsStrings,
} from '@sportninja/common/constants/playoffTypes'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import Icon from '../../../components/Icon'
import { Flex } from '../../../components/Layout'
import { NewSelect } from '../../../components/List/RosterSelect'
import LoadingSpinner from '../../../components/LoadingSpinner'
import SuspensionSubmitButton from '../../../components/Suspensions/SuspensionSubmitButton'
import BracketFilter from '../ScheduleBracket/BracketFilter'
import { TOURNAMENT_STATUS } from '../ScheduleBracket/constants'
import { useQueryState } from '../utils'
import GameSlotTable from './GameSlotTable'
import RebuildGameSlots from './RebuildGameSlots'
import SyncGameSlots from './SyncGameSlots'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import Modal from 'src/components/Modal'
import colors from '@sportninja/common/constants/appColors'
import { FormButton } from 'src/components/Form/css'
import { font } from 'src/components/css'
import FormInput from 'src/components/Form/FormInput'
import { NewModal } from 'src/components/NewModal'
import Picture from 'src/components/Picture'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { Select, Alert, Space } from 'antd'
import { Button } from 'src/components/Button'
import { getErrorMessage } from '@sportninja/common/utils/utils'

const GameSlotsInner = ({
  availableVenues,
  id,
  selectedComp,
  competitionType,
  forms,
  setForms,
  setAdvanceLoading,
  fetchDropdown,
  fetchGameSlots,
  gamesLoading,
  games,
  submitLoading,
  advanceLoading,
  tournamentStatus = TOURNAMENT_STATUS.NOT_STARTED,
  onPressTeamModal = () => {},
  isSingleOrDoubleTournament = false,
  setError,
  isSingleElimination = false,
  isDoubleElimination = false,
}) => {
  const handleSetForm = (id, key, value) => {
    setForms((state) => {
      const newState = {
        ...state,
        [id]: {
          ...state[id],
          [key]: value,
        },
      }

      if (typeof value === 'undefined') {
        delete newState[id][key]
        if (Object.keys(newState[id]).length === 0) {
          delete newState[id]
        }
      }

      return newState
    })
  }

  const onApprove = async (slotId) => {
    setAdvanceLoading(slotId)
    try {
      setError(null)
      await req(`/schedules/${selectedComp}/gameslots/${slotId}/approve`)

      // Request dropdowns, because we need to update the display in the select
      // box, especially if this approval means the tournament status changed
      await fetchDropdown()
      await fetchGameSlots()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
      if (__DEV__) {
        console.error(e)
      }
    } finally {
      setAdvanceLoading(false)
    }
  }

  const onDebugAdvance = async (slotId, teamId) => {
    try {
      setError(null)
      await req(`/schedules/${selectedComp}/gameslots/${slotId}/advance`, {
        method: 'PUT',
        body: JSON.stringify({
          team_id: teamId,
        }),
      })
      // Request dropdowns, because we need to update the display in the select
      // box, especially if this advancement means the tournament status changed
      await fetchDropdown()
      await fetchGameSlots()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
      if (__DEV__) {
        console.error(e)
      }
    }
  }

  const onPressCopy = (selectedVenue, selectedFacility) => {
    games?.forEach((game) => {
      if (game?.status !== 2) {
        handleSetForm(game.id, 'venue_id', selectedVenue)
        handleSetForm(game.id, 'facility_id', selectedFacility)
      }
    })
  }

  return (
    <div
      css={css`
        overflow-x: auto;
        white-space: nowrap;
        padding-bottom: 24px;
        min-height: 800px;
      `}
    >
      <Flex column>
        {(selectedComp.length === 0 || games.length === 0) && (
          <div
            css={css`
              flex: 1;
              margin-top: 24px;
              text-align: center;
            `}
          >
            {selectedComp.length === 0
              ? `Select a ${competitionType} to view its pending game slots.`
              : gamesLoading
              ? false
              : `There are no game slots available for this ${competitionType} yet.`}
          </div>
        )}
        {gamesLoading ? (
          <LoadingSpinner
            css={css`
              margin-top: 40px;
            `}
          />
        ) : (
          selectedComp &&
          games.length > 0 && (
            <GameSlotTable
              games={games}
              availableVenues={availableVenues}
              forms={forms}
              handleSetForm={handleSetForm}
              id={id}
              submitLoading={submitLoading}
              advanceLoading={advanceLoading}
              onApprove={onApprove}
              onDebugAdvance={onDebugAdvance}
              tournamentStatus={tournamentStatus}
              onPressCopy={onPressCopy}
              onPressTeamModal={onPressTeamModal}
              isSingleOrDoubleTournament={isSingleOrDoubleTournament}
              isSingleElimination={isSingleElimination}
              isDoubleElimination={isDoubleElimination}
            />
          )
        )}
      </Flex>
    </div>
  )
}

const GameSlots = ({
  id,
  canSyncGameSlots = false,
  bracketsCreated = false,
  readItem = () => {},
  setSelectedGameSlotDivision = () => {},
  setSelectedGameSlotDivisionName = () => {},
  setGamesCreated = () => {},
  setHasTournamentStarted = () => {},
  setOnFinishUploadGameslots = () => {},
  sportId = null,
}) => {
  const [error, setError] = useState('An unknown error occurred')
  const [competitionType, setCompetitionType] = useState(
    t('common:competition')
  )
  const [comps, setComps] = useState([])
  const [selectedComp, setSelectedComp] = useQueryState('sn_division', '')
  const [availableVenues, setAvailableVenues] = useState([])
  const [games, setGames] = useState([])
  const [regularSeason, setRegularSeason] = useState({})
  const [gamesLoading, setGamesLoading] = useState(true)
  const selectedCompObject = comps?.find((c) => c.id === selectedComp)

  const [forms, setForms] = useState({})
  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [advanceLoading, setAdvanceLoading] = useState(false)
  const [tournamentType, setTournamentType] = useState(null)
  const [tournamentTypeLoading, setTournamentTypeLoading] = useState(false)
  const [tournamentTypeSuccess, setTournamentTypeSuccess] = useState(false)
  const [allGamesFilled, setAllGamesFilled] = useState(false)
  const [selectedCompSportId, setSelectedCompSportId] = useState(null)

  const [startTournamentLoading, setStartTournamentLoading] = useState(false)
  const [resetTournamentModal, setResetTournamentModal] = useState(false)
  const [shouldForce, setShouldForce] = useState(false)
  const [forceSaveAfterUpdate, setForceSaveAfterUpdate] = useState(false)
  const selectTeamModalRef = useRef(null)
  const [gameSlotTeams, setGameSlotTeams] = useState([])
  const [selectedGameSlotTeam, setSelectedGameSlotTeam] = useState(null)
  const [selectedGameSlotTeamToReplace, setSelectedGameSlotTeamToReplace] =
    useState(null)
  const [teamReplaceError, setTeamReplaceError] = useState(null)
  const isSingleElimination =
    tournamentType === playoffsIds[playoffsStrings.SINGLE_ELIMINATION]
  const isDoubleElimination =
    tournamentType === playoffsIds[playoffsStrings.DOUBLE_ELIMINATION]
  const isSingleOrDoubleTournament = isSingleElimination || isDoubleElimination

  const hasAutoSelected = useRef(selectedComp)

  const selectedCompetitionObject = comps?.find((c) => c.id === selectedComp)

  const isDirty = Object.keys(forms).length > 0
  const tournamentStatus = games?.[0]?.tournament_status

  const isFilled = (game) => {
    return game.venue_id && game.facility_id && game.starts_at
  }

  const isFormsFilled = (game) => {
    const form = forms[game.id]
    return form && (form.venue_id || form.facility_id || form.starts_at)
  }

  useEffect(() => {
    const fetchGameSlotTeams = async () => {
      try {
        setError(null)
        const { data } = await req(`/schedules/${selectedComp}/gameslots/teams`)
        if (data) {
          setGameSlotTeams(data)
        }
      } catch (e) {
        const errorMessage = getErrorMessage(e)
        setError(errorMessage)
        if (__DEV__) {
          console.error(e)
        }
      }
    }
    if (selectedComp) {
      fetchGameSlotTeams()
    }
  }, [selectedComp, games])

  useEffect(() => {
    if (tournamentStatus === TOURNAMENT_STATUS.IN_PROGRESS) {
      setHasTournamentStarted(true)
    } else {
      setHasTournamentStarted(false)
    }
  }, [tournamentStatus])

  useEffect(() => {
    if (games && games?.length > 0) {
      setGamesCreated(true)
    } else {
      setGamesCreated(false)
    }
  }, [games])

  useEffect(() => {
    const fetchSportIdForComp = async () => {
      try {
        setError(null)
        const { data } = await req(`/schedules/${selectedComp}`)
        if (data) {
          setSelectedCompSportId(data?.sport_id)
        }
      } catch (e) {
        const errorMessage = getErrorMessage(e)
        setError(errorMessage)
        if (__DEV__) {
          console.error(e)
        }
      }
    }
    if (selectedComp) {
      fetchSportIdForComp()
    }
  }, [selectedComp])

  useEffect(() => {
    if (selectedComp) {
      const compName = comps.find((c) => c.id === selectedComp)?.name
      setSelectedGameSlotDivisionName(
        compName ? compName?.replace(/\s+/g, '_') : 'gameslots'
      )
      setSelectedGameSlotDivision(selectedComp)
    }
  }, [selectedComp])

  useEffect(() => {
    if (selectedComp && comps) {
      const c = comps.find((c) => c.id === selectedComp)
      if (c?.tournament_type_id) {
        setTournamentType(c?.tournament_type_id)
      }
    }
  }, [selectedComp, comps])

  const fetchDropdown = useCallback(async () => {
    const { data: compStructure } = await req(
      `/schedules/${id}/children/dropdown`
    )
    const levelContainingTeams = compStructure[compStructure.length - 1]

    if (levelContainingTeams) {
      setCompetitionType(levelContainingTeams.name)
      setComps(levelContainingTeams.schedules || [])
    }
  }, [id])

  useEffect(() => {
    fetchDropdown()
    // Venue endpoint includes facility information
    // Just switch to the new endpoint once Jeff's figure out
    // if this works properly
    // req(`/schedules/${id}/venues`).then(({ data }) => {
    req('/venues', { query: { schedule_id: id } }).then(({ data }) => {
      setAvailableVenues(data)
    })
  }, [fetchDropdown, id])

  useEffect(() => {
    if (comps?.length > 0 && !hasAutoSelected.current) {
      setSelectedComp(comps[0]?.id)
      hasAutoSelected.current = true
    }
  }, [comps])

  const fetchGameSlots = useCallback(
    async (showLoadingIndicator = false) => {
      if (selectedComp.length > 0) {
        if (showLoadingIndicator) {
          setGamesLoading(true)
        }
        try {
          const {
            data,
            meta: { tournament_regular_season_schedule },
          } = await req(`/schedules/${selectedComp}/gameslots`)
          setGames(data)
          setRegularSeason(tournament_regular_season_schedule)
        } finally {
          setGamesLoading(false)
        }
      }
    },
    [selectedComp]
  )

  useEffect(() => {
    fetchGameSlots(true)
  }, [fetchGameSlots])

  useEffect(() => {
    const prefillVenuesAndFacilities = async () => {
      if (games.length > 0 && availableVenues.length === 1) {
        const theOneVenue = availableVenues[0]
        setForms(
          games
            .filter((gameSlot) => {
              return typeof gameSlot?.venue_id !== 'string'
            })
            .reduce((collector, gameSlot) => {
              const toSet = {
                venue_id: theOneVenue.id,
              }

              if (theOneVenue.facilities.length === 1) {
                toSet.facility_id = theOneVenue.facilities[0].id
              }
              return {
                ...collector,
                [gameSlot.id]: toSet,
              }
            }, {})
        )
      }
    }

    prefillVenuesAndFacilities()
  }, [games, availableVenues])

  useEffect(() => {
    const checkAllFilled = async () => {
      if (
        games.length > 0 &&
        [
          TOURNAMENT_STATUS.FULL_PLACEMENT,
          TOURNAMENT_STATUS.PRE_PLACEMENT,
          TOURNAMENT_STATUS.IN_PROGRESS,
        ].includes(tournamentStatus)
      ) {
        // const isGamesFilled = games?.every(game => isFilled(game) || isFormsFilled(game))
        // setAllGamesFilled(isGamesFilled)
        setAllGamesFilled(true)
      } else {
        const allFilled = forms
          ? Object.values(forms).every((obj) => {
              return (
                obj.hasOwnProperty('venue_id') &&
                obj.hasOwnProperty('facility_id') &&
                obj.hasOwnProperty('starts_at') &&
                obj.venue_id !== null &&
                obj.venue_id !== '' &&
                obj.facility_id !== null &&
                obj.facility_id !== '' &&
                obj.starts_at !== null &&
                obj.starts_at !== ''
              )
            }) && Object.values(forms)?.length === games?.length
          : false
        setAllGamesFilled(allFilled)
      }
    }
    checkAllFilled()
  }, [games, tournamentStatus, forms])

  const onResetTournament = async () => {
    setSubmitLoading(true)
    try {
      setError(null)
      await req(`/schedules/${selectedComp}/tournaments/reset`, {
        method: 'PUT',
      })
      await fetchDropdown()
      await fetchGameSlots()
      setSubmitLoading(false)
      window.location.reload()
    } catch (e) {
      setSubmitLoading(false)
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
      if (__DEV__) {
        console.error(e)
      }
    }
  }

  const onSubmit = async () => {
    setSubmitLoading(true)
    try {
      const finalForm = Object.keys(forms).reduce((collector, slotId) => {
        const form = forms[slotId]

        collector.push({ game_slot_id: slotId, ...form })
        return collector
      }, [])

      await req(`/schedules/${selectedComp}/gameslots`, {
        method: 'PUT',
        body: JSON.stringify({ game_slots: finalForm }),
      })
      await fetchDropdown()
      await fetchGameSlots()

      setSubmitSuccess(true)
      setForms({})
      setTimeout(() => {
        setSubmitSuccess(false)
      }, 1500)
    } finally {
      setSubmitLoading(false)
      setForceSaveAfterUpdate(false)
    }
  }

  const onSetTournamentType = async () => {
    setTournamentTypeLoading(true)
    try {
      setError(null)
      const body = {
        bracket_template_id: tournamentType,
        regular_season_schedule_id: regularSeason?.division_id,
      }
      await req(`/schedules/${selectedComp}/tournaments/configure`, {
        method: 'POST',
        body: JSON.stringify(body),
      })
      await fetchDropdown()
      await fetchGameSlots()
      await readItem(ENTITY_TYPES.schedule, id)
      setTournamentTypeSuccess(true)
      setTimeout(() => {
        setTournamentTypeSuccess(false)
      }, 1500)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
      if (__DEV__) {
        console.error(e)
      }
    } finally {
      setTournamentTypeLoading(false)
    }
  }

  const onStartTournament = async () => {
    setStartTournamentLoading(true)
    try {
      setError(null)
      if (!selectedComp) {
        return
      }
      await req(`/schedules/${selectedComp}/tournaments/initialize`, {
        method: 'POST',
      })
      await fetchDropdown()
      await fetchGameSlots()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
      if (__DEV__) {
        console.error(e)
      }
    } finally {
      setStartTournamentLoading(false)
    }
  }

  const onRefresh = async () => {
    setSubmitLoading(true)

    await req(`/schedules/${selectedComp}/gameslots/refresh`)
    await fetchDropdown()
    await fetchGameSlots(true)

    setSubmitLoading(false)
  }

  useEffect(() => {
    setOnFinishUploadGameslots(() => {
      return async () => {
        setForceSaveAfterUpdate(true)
        await fetchGameSlots(true)
      }
    })
  }, [])

  const onSync = async (
    min_games_played,
    carry_over_suspensions = false,
    ordered_teams = []
  ) => {
    try {
      setError(null)
      await req(`/schedules/${selectedComp}/tournaments/sync_team`, {
        method: 'PUT',
        body: JSON.stringify({
          min_games_played,
          carry_over_suspensions,
          ordered_teams,
        }),
      })
      await fetchDropdown()
      await fetchGameSlots(true)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
      if (__DEV__) {
        console.error(e)
      }
    }
  }

  const isSaveChangesDisabled = () => {
    if (forceSaveAfterUpdate) {
      if (allGamesFilled) {
        return false
      } else {
        return true
      }
    } else {
      return !isDirty || submitLoading || advanceLoading || !allGamesFilled
    }
  }

  const tournamentTypes = isCanlan
    ? [
        {
          id: playoffsIds[playoffsStrings.SINGLE_ELIMINATION],
          title: playoffsStrings.SINGLE_ELIMINATION,
        },
        {
          id: playoffsIds[playoffsStrings.DOUBLE_ELIMINATION],
          title: playoffsStrings.DOUBLE_ELIMINATION,
        },
        {
          id: playoffsIds[playoffsStrings.CANLAN],
          title: playoffsStrings.CANLAN,
        },
      ]
    : [
        {
          id: playoffsIds[playoffsStrings.SINGLE_ELIMINATION],
          title: playoffsStrings.SINGLE_ELIMINATION,
        },
        {
          id: playoffsIds[playoffsStrings.DOUBLE_ELIMINATION],
          title: playoffsStrings.DOUBLE_ELIMINATION,
        },
      ]

  const onPressTeamModal = (slotData) => {
    setTeamReplaceError(null)
    setSelectedGameSlotTeam(null)
    setSelectedGameSlotTeamToReplace(slotData)
    selectTeamModalRef?.current?.openModal()
  }

  const onPressSaveTeamReplace = async () => {
    try {
      setError(null)
      setTeamReplaceError(null)
      const side = selectedGameSlotTeamToReplace?.isHome ? 'home' : 'visiting'
      // {{url}}/schedules/:schedule_id/gameslots/:game_slot_id/replace-team/:team_id
      await req(
        `/schedules/${selectedComp}/gameslots/${selectedGameSlotTeamToReplace?.game?.id}/replace-team/${selectedGameSlotTeamToReplace?.team_id}?side=${side}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            new_team_id: selectedGameSlotTeam,
          }),
        }
      )
      fetchGameSlots(true)
      selectTeamModalRef?.current?.closeModal()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setTeamReplaceError(errorMessage)
      if (__DEV__) {
        console.error(e)
      }
    }
  }

  return (
    <Fragment>
      <Flex justifyContent='center' alignItems='flex-start' column>
        <Modal
          isOpen={resetTournamentModal}
          onClose={() => {
            setResetTournamentModal(false)
          }}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-evenly;
              width: 350px;
              height: 250px;
              border-radius: 4px;
              border: 2px solid red;
              background-color: ${colors.HEADER};
              padding: 16px;
            `}
          >
            <label
              css={css`
                align-self: center;
                text-align: left;
                font-size: 28px;
                font-weight: bold;
                ${font.title}
                margin-bottom: 16px;
              `}
            >
              Warning!
            </label>
            <label
              css={css`
                text-align: center;
                margin-bottom: 16px;
              `}
            >
              Are you sure you want to reset the division? This action cannot be
              undone!
            </label>
            <FormInput
              wrapperClass={css``}
              onChange={() => setShouldForce(!shouldForce)}
              disabled={submitLoading}
              input={{
                name: 'force_remove',
                label: 'Ignore This Warning And Reset Anyway',
                type: 'checkbox',
                checked: shouldForce,
              }}
            />
            <div
              css={css`
                display: flex;
                flex-direction: row;
              `}
            >
              <FormButton
                onClick={() => {
                  setResetTournamentModal(false)
                }}
                css={css`
                  border: 1px solid ${colors.ATTENDANCE_GRAY};
                  padding: 0 16px;
                `}
                disabled={submitLoading}
                busy={submitLoading}
              >
                {'Cancel'}
              </FormButton>
              <FormButton
                onClick={async () => {
                  try {
                    setError(null)
                    await onResetTournament()
                  } catch (e) {
                    const errorMessage = getErrorMessage(e)
                    setError(errorMessage)
                    if (__DEV__) {
                      console.error(e)
                    }
                  } finally {
                    setResetTournamentModal(false)
                  }
                }}
                css={css`
                  border: 1px solid ${colors.FIERY_RED};
                  padding: 0 16px;
                  background-color: ${colors.FIERY_RED};
                `}
                disabled={submitLoading || !shouldForce}
                busy={submitLoading}
              >
                {'Confirm'}
              </FormButton>
            </div>
          </div>
        </Modal>
        <BracketFilter
          comps={comps}
          selectedComp={selectedComp}
          setSelectedComp={(c) => {
            setSelectedComp(c.id)
            setForms({})
          }}
          competitionType={competitionType}
          setCompetitionType={setCompetitionType}
        >
          <Flex alignItems='flex-end' justifyContent='flex-end'>
            {[
              TOURNAMENT_STATUS.PRE_PLACEMENT,
              // TOURNAMENT_STATUS.FULL_PLACEMENT,
            ].includes(tournamentStatus)
              ? canSyncGameSlots &&
                allGamesFilled && (
                  <SyncGameSlots
                    onSubmit={onSync}
                    regularSeason={regularSeason}
                    selectedComp={selectedComp}
                    comps={comps}
                    isDirty={isDirty}
                    sportId={sportId}
                    selectedCompObject={selectedCompObject}
                  />
                )
              : null}
            {[
              TOURNAMENT_STATUS.NOT_CONFIGURED,
              TOURNAMENT_STATUS.PRE_PLACEMENT,
              TOURNAMENT_STATUS.FULL_PLACEMENT,
              TOURNAMENT_STATUS.IN_PROGRESS,
            ].includes(tournamentStatus) ? (
              <SuspensionSubmitButton
                width='160px'
                height='42px'
                disabled={submitLoading || advanceLoading}
                onClick={() => {
                  setResetTournamentModal(true)
                }}
                type='button'
                css={css`
                  margin-top: 20px;
                  margin-left: 16px;
                `}
              >
                Reset
              </SuspensionSubmitButton>
            ) : null}
            {/* Removing the rebuild game slots button as requested */}
            {/* {[TOURNAMENT_STATUS.FULL_PLACEMENT].includes(tournamentStatus) ? (
              <RebuildGameSlots onSubmit={onRefresh} />
            ) : null} */}
            <Flex
              justifyContent='flex-end'
              css={css`
                margin-left: auto;
              `}
            >
              {!gamesLoading && (
                <SuspensionSubmitButton
                  width='160px'
                  height='42px'
                  disabled={!isDirty || submitLoading || advanceLoading}
                  onClick={() => {
                    window.location.reload()
                  }}
                  type='button'
                  css={css`
                    margin-top: 20px;
                  `}
                >
                  Discard Changes
                </SuspensionSubmitButton>
              )}
              {!gamesLoading && (
                <SuspensionSubmitButton
                  width='160px'
                  height='42px'
                  isSolid
                  busy={submitLoading}
                  disabled={isSaveChangesDisabled()}
                  onClick={onSubmit}
                  type='button'
                  css={css`
                    margin-top: 20px;
                    margin-left: 16px;
                  `}
                >
                  {submitSuccess ? (
                    <div>
                      <Icon name='check' color='white' />
                    </div>
                  ) : (
                    'Save Changes'
                  )}
                </SuspensionSubmitButton>
              )}
              {[TOURNAMENT_STATUS.FULL_PLACEMENT].includes(tournamentStatus) ? (
                <SuspensionSubmitButton
                  width='160px'
                  height='42px'
                  isSolid
                  busy={startTournamentLoading}
                  disabled={startTournamentLoading}
                  onClick={onStartTournament}
                  type='button'
                  css={css`
                    margin-top: 20px;
                    margin-left: 16px;
                  `}
                >
                  Start Tournament
                </SuspensionSubmitButton>
              ) : null}
            </Flex>
          </Flex>
        </BracketFilter>
        {error ? (
          <Alert
            description={error}
            type='error'
            showIcon
            css={css`
              margin-bottom: 16px;
              width: 100%;
            `}
          />
        ) : null}
        {!bracketsCreated ? (
          <Flex
            alignItems='center'
            css={css`
              z-index: 1;
              margin-bottom: 16px;
            `}
          >
            <NewSelect
              label={'Tournament Type'}
              options={tournamentTypes}
              selectedOptionId={tournamentType}
              onClick={(comp) => {
                setTournamentType(comp?.id)
              }}
              getTitle={(c) => c?.title}
              disabled={
                selectedCompetitionObject?.tournament_type_id !== null ||
                [
                  TOURNAMENT_STATUS.PRE_PLACEMENT,
                  TOURNAMENT_STATUS.FULL_PLACEMENT,
                  TOURNAMENT_STATUS.IN_PROGRESS,
                ].includes(tournamentStatus)
              }
            />
            <SuspensionSubmitButton
              width='160px'
              height='42px'
              isSolid
              busy={tournamentTypeLoading}
              disabled={
                selectedCompetitionObject?.tournament_type_id !== null ||
                tournamentType === null ||
                [
                  TOURNAMENT_STATUS.PRE_PLACEMENT,
                  TOURNAMENT_STATUS.FULL_PLACEMENT,
                  TOURNAMENT_STATUS.IN_PROGRESS,
                ].includes(tournamentStatus)
              }
              onClick={onSetTournamentType}
              type='button'
              css={css`
                margin-top: 20px;
                margin-left: 16px;
              `}
            >
              {tournamentTypeSuccess ? (
                <div>
                  <Icon name='check' color='white' />
                </div>
              ) : (
                'Update Type'
              )}
            </SuspensionSubmitButton>
          </Flex>
        ) : null}
      </Flex>

      <GameSlotsInner
        availableVenues={availableVenues}
        id={id}
        selectedComp={selectedComp}
        competitionType={competitionType}
        forms={forms}
        setForms={setForms}
        setAdvanceLoading={setAdvanceLoading}
        fetchDropdown={fetchDropdown}
        fetchGameSlots={fetchGameSlots}
        gamesLoading={gamesLoading}
        games={games}
        submitLoading={submitLoading}
        advanceLoading={advanceLoading}
        tournamentStatus={tournamentStatus}
        onPressTeamModal={onPressTeamModal}
        isSingleOrDoubleTournament={isSingleOrDoubleTournament}
        isSingleElimination={isSingleElimination}
        isDoubleElimination={isDoubleElimination}
        setError={setError}
      />
      <NewModal ref={selectTeamModalRef} shouldCloseOnOverlayClick={true}>
        <div
          css={css`
            width: 40vw;
            background: #26303e;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            transition: all 0.1s ease-in-out;
            border-radius: 8px;
            padding: 16px;
            gap: 16px;
          `}
        >
          {/* Selected Team */}
          <p
            css={css`
              color: var(--Neutrals-White, #fff);
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%; /* 28.8px */
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
              font-weight: 700;
            `}
          >
            {selectedGameSlotTeamToReplace?.isHome
              ? 'Current Home Team:'
              : 'Current Visiting Team:'}
          </p>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;
            `}
          >
            <Picture
              square
              // size='xxxsmall'
              imageId={getImageThumbnailId(selectedGameSlotTeamToReplace)}
              css={css`
                margin: 6px;
              `}
              iconName='user-friends'
            />
            <div>
              <div
                css={css`
                  font-size: 16px;
                  font-weight: bold;
                  color: white;
                `}
              >
                {selectedGameSlotTeamToReplace?.name}
              </div>
            </div>
          </div>
          <p
            css={css`
              color: var(--Neutrals-White, #fff);
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%; /* 28.8px */
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
              font-weight: 700;
            `}
          >
            Replace Team With:
          </p>
          <div
            css={css`
              display: flex;
              flex-direction: row;
            `}
          >
            <div
              css={css`
                .ant-select {
                  background-color: ${colors.HEADER};
                  width: 100%;
                  min-width: 200px;
                }
              `}
            >
              <Select
                showSearch={false}
                onChange={(e) => {
                  setSelectedGameSlotTeam(e)
                }}
                options={gameSlotTeams
                  .filter(
                    (gst) => gst?.id !== selectedGameSlotTeamToReplace?.team_id
                  )
                  .map((team) => ({
                    label: team?.name,
                    value: team?.id,
                  }))}
                value={selectedGameSlotTeam}
              />
            </div>
          </div>
          {teamReplaceError ? (
            <Space direction='vertical' style={{ width: '100%' }}>
              <Alert description={teamReplaceError} type='error' showIcon />
            </Space>
          ) : null}
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
            `}
          >
            <Button
              onClick={(e) => {
                e.preventDefault()
                selectTeamModalRef?.current?.closeModal()
              }}
              label={'Cancel'}
              variant='secondary'
              disabled={submitLoading}
            />
            <Button
              onClick={async () => await onPressSaveTeamReplace()}
              type='submit'
              label='Submit'
              isLoading={submitLoading}
              disabled={submitLoading || !selectedGameSlotTeam}
            />
          </div>
        </div>
      </NewModal>
    </Fragment>
  )
}

export default GameSlots
