import styled from '@emotion/styled/macro'
import { media } from '../../components/Responsive'
import { font } from '../../components/css'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import colors from '@sportninja/common/constants/appColors'

export const H1 = styled.h1`
  ${font.title}
  font-size: 56px;
  line-height: 100%;
  letter-spacing: 0px;
  margin-bottom: 16px;
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-weight: 600;
  ${media.mobile} {
    font-size: 44px;
    margin-bottom: 42px;
  }
`

export const ForgotText = styled.div`
  display: block;
  ${font.body}
  font-size: 12px;
  margin-bottom: 36px;
`

export const ForgotTextLightMode = styled.div`
  display: block;
  ${font.body}
  font-size: 12px;
  color: #000;
  margin-bottom: 36px;
`

export const BodyText = styled.div`
  ${font.body}
  color: ${colors.NEUTRAL_50};
  line-height: 1.3;
  p,
  .body-text-step {
    margin-bottom: 16px;
  }
  .small {
    font-size: 12px;
  }
  .double-margin {
    margin-top: 32px;
  }
  .bold {
    font-weight: bold;
  }
  .error {
    color: red;
  }
`
