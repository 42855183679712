/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Radio, Space } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import { getAnOrA } from '../../../InviteV2'
import Text from 'src/components/ts-components/Text'
import Button from 'src/components/ts-components/Button'
import { SelectInput } from 'src/components/ts-components/SelectInput'

/**
 * Props for the WelcomeStep component
 * @typedef {Object} WelcomeStepProps
 * @property {Object} data - The invitation data
 * @property {Function} onNext - Function to call when proceeding to next step
 * @property {Array<string>} fieldErrors - Array of field names with errors
 * @property {Object} customError - Custom error messages by field name
 */
type WelcomeStepProps = {
  data: any
  onNext: (isUnderage: boolean, relationship?: string) => void
  fieldErrors?: string[]
  customError?: Record<string, string>
  radioOption?: string
  relationship?: string
  setRadioOption?: (value: string) => void
  setRelationship?: (value: string) => void
}

/**
 * Welcome step component for the underage invitation flow
 * Allows user to identify if they are the invitee or a parent/guardian
 *
 * @param {WelcomeStepProps} props - Component props
 * @returns {JSX.Element} The rendered component
 */
const WelcomeStep = ({
  data,
  onNext,
  fieldErrors = [],
  customError = {},
  radioOption = 'yes',
  relationship = '',
  setRadioOption = () => {},
  setRelationship = () => {},
}: WelcomeStepProps) => {
  return (
    <div
      css={css`
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 720px;
      `}
    >
      <Text variant='displaySmall' weight='bold' color={colors.NEUTRAL_0}>
        WELCOME TO SPORTNINJA
      </Text>
      <Text variant='paragraphMedium' weight='regular' color={colors.NEUTRAL_0}>
        <span
          css={css`
            font-weight: 700;
            color: ${colors.PRIMARY};
          `}
        >
          {data?.name_first} {data?.name_last}
        </span>{' '}
        is invited to join{' '}
        <span
          css={css`
            font-weight: 700;
            color: ${colors.PRIMARY};
          `}
        >
          {data?.destination?.name_full}
        </span>{' '}
        as {getAnOrA(getType(data?.type?.id).type)}{' '}
        <span
          css={css`
            font-weight: 700;
            color: ${colors.PRIMARY};
          `}
        >
          {getType(data?.type?.id).type}
        </span>
        .
      </Text>

      <div>
        <Text
          variant='paragraphMedium'
          weight='regular'
          color={colors.NEUTRAL_0}
        >
          Are you{' '}
          <span
            css={css`
              font-weight: 700;
            `}
          >
            {data?.name_first}
          </span>
          ?
        </Text>
        <div
          css={css`
            margin-top: 16px;
          `}
        >
          <Radio.Group
            defaultValue={radioOption}
            onChange={(e) => {
              setRadioOption(e.target.value)
            }}
          >
            <Space direction='vertical'>
              <Radio value='yes'>{`YES, I'm ${data?.name_first}`}</Radio>
              <Radio value='no'>{"NO, I'm a Parent/Guardian"}</Radio>
            </Space>
          </Radio.Group>
        </div>
      </div>
      {radioOption === 'no' && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <SelectInput
            label={`Please Select Your Relationship To ${data?.name_first}:`}
            disabled={false}
            name='relationship'
            style={css`
              width: 100%;
              margin-top: 8px;
              background-color: #27303e;
              @media (max-width: 768px) {
                width: 100%;
              }
            `}
            placeholder='Relationship'
            onChange={(value) => {
              setRelationship(value)
            }}
            value={relationship}
            options={[
              { label: 'Parent', value: 'Parent' },
              { label: 'Grandparent', value: 'Grandparent' },
              { label: 'Sibling', value: 'Sibling' },
              { label: 'Guardian', value: 'Guardian' },
              { label: 'Other', value: 'Other' },
            ]}
            fieldErrors={fieldErrors}
            customError={customError}
          />
        </div>
      )}
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          width: 100%;
          @media (max-width: 768px) {
            width: 100%;
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            width: 50%;
            align-self: flex-end;
            @media (max-width: 768px) {
              width: 100%;
            }
          `}
        >
          <Button
            text='Next'
            variant='primary'
            onClick={() => {
              onNext(radioOption === 'yes', relationship)
            }}
            disabled={radioOption === 'no' && relationship === ''}
          />
        </div>
      </div>
    </div>
  )
}

/**
 * Helper function to determine the type of invitation
 * @param {string} type - The type ID from the invitation data
 * @returns {Object} Object containing the type information
 */
function getType(type?: string) {
  switch (type) {
    // Player
    case '1':
      return {
        type: 'Player',
      }

    // Official/Staff
    case '2':
    case '4':
    case '6':
      return {
        type: 'User',
      }

    case '7':
    case '8':
    case '9':
      return {
        type: 'Official',
      }

    // Team invitation to competition - not used yet
    case '3':
    case '5':
    default:
      return { type: '' }
  }
}

export default WelcomeStep
