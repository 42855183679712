/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react'
import PlayerForm from './PlayerForm'
import TeamOfficialForm from './TeamOfficialForm'
import { Country } from '@sportninja/common/hooks/useCountries'
import { RegistrationDetails } from '@sportninja/common/hooks/useRegistrationDetails'
import { useProvinces } from '@sportninja/common/hooks/useProvinces'
import { useTypes } from '@sportninja/common/hooks/useTypes'

const RegistrationDetailsInfo = ({
  registrationDetails,
  countries,
  refetch = () => {},
}: {
  registrationDetails?: RegistrationDetails
  countries: Country[]
  refetch?: () => void
}) => {
  const [formData, setFormData] = useState({
    // Player Info
    email: '',
    firstName: '',
    lastName: '',
    birthDate: '',

    // Address
    address: '',
    zipCode: '',
    stateProvince: '',
    country: '',
    city: '',

    // Parents/Guardian Info
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentPhone: '',

    // Emergency Contact Info
    emergencyFirstName: '',
    emergencyLastName: '',
    emergencyPhone: '',
    parentRelationship: '',
    doctorName: '',
    doctorPhone: '',
    allergies: '',
    medicalConditions: '',
    emergencyEmail: '',
    // Preferences
    preferredPosition: '',
    jerseyNumber: '',
    dominantSide: '',
    jerseySize: '',
    tshirtSize: '',
    shortSize: '',
    hockeyUSANumber: '',
    hockeyCanadaNumber: '',
    notes: '',
    otherMembershipNumber: '',
    // Team Official Info
    additionalTeamOfficials: [],

    // Field Errors
    fieldErrors: [],
    customError: {},
  })

  const selectedCountryId = useMemo(() => {
    return countries.find((c) => c.iso_3166_2 === formData.country)?.id
  }, [formData.country, countries])
  const { provinces, refetch: refetchProvinces } =
    useProvinces(selectedCountryId)

  const { types: typesData, fetchTypes } = useTypes()

  const positions = useMemo(() => {
    // First, lets find the type for the sport id
    const sportType = typesData?.find(
      (type) => type.sport_id === registrationDetails?.schedule?.sport_id
    )
    // Return empty if no sport type is found
    if (!sportType) {
      return []
    }
    const playerTypes = sportType?.data?.find(
      (type) => type.player_types !== undefined
    )
    return playerTypes?.player_types
  }, [typesData, registrationDetails?.schedule?.sport_id])

  useEffect(() => {
    if (!typesData || typesData?.length === 0) {
      fetchTypes()
    }
  }, [fetchTypes, typesData])

  // Only fetch provinces for Canada and the USA
  useEffect(() => {
    if (
      provinces.length === 0 &&
      selectedCountryId &&
      (selectedCountryId === 36 || selectedCountryId === 226)
    ) {
      refetchProvinces()
    }
  }, [provinces, refetchProvinces, selectedCountryId])

  // load the province in a separate useEffect
  useEffect(() => {
    if (
      registrationDetails &&
      registrationDetails?.registration_info &&
      provinces
    ) {
      const province = provinces.find(
        (p) => p.id === Number(registrationDetails?.registration_info?.province)
      )
      setFormData((prev) => ({
        ...prev,
        stateProvince: province?.name || '',
      }))
    }
  }, [provinces, registrationDetails])
  useEffect(() => {
    if (registrationDetails && registrationDetails?.registration_info) {
      const preferredPosition = registrationDetails.registration_info
        .player_type_id
        ? String(registrationDetails.registration_info.player_type_id)
        : registrationDetails?.player?.player_type?.id
        ? String(registrationDetails?.player?.player_type?.id)
        : ''
      setFormData((prev) => ({
        ...prev,
        email: registrationDetails.registration_info.email || '',
        firstName: registrationDetails.registration_info.name_first || '',
        lastName: registrationDetails.registration_info.name_last || '',
        birthDate: registrationDetails.registration_info.birth_date || '',
        address: registrationDetails.registration_info.address || '',
        country: registrationDetails.registration_info.country || '',
        parentFirstName:
          registrationDetails.registration_info.parent_first_name || '',
        parentLastName:
          registrationDetails.registration_info.parent_last_name || '',
        parentEmail: registrationDetails.registration_info.parent_email || '',
        parentPhone:
          registrationDetails.registration_info.parent_phone_number || '',
        emergencyFirstName:
          registrationDetails.registration_info.emergency_first_name || '',
        emergencyLastName:
          registrationDetails.registration_info.emergency_last_name || '',
        emergencyPhone:
          registrationDetails.registration_info.emergency_phone_number || '',
        parentRelationship:
          registrationDetails.registration_info.emergency_relationship || '',
        doctorName: registrationDetails.registration_info.doctor_name || '',
        doctorPhone:
          registrationDetails.registration_info.doctor_phone_number || '',
        allergies: registrationDetails.registration_info.known_allergies || '',
        medicalConditions:
          registrationDetails.registration_info.medical_conditions || '',
        preferredPosition: preferredPosition,
        jerseyNumber:
          registrationDetails.registration_info.preferred_jersey_number || '',
        dominantSide:
          registrationDetails.registration_info.preferred_dominant_side || '',
        jerseySize:
          registrationDetails.registration_info.preferred_jersey_size || '',
        tshirtSize:
          registrationDetails.registration_info.preferred_tshirt_size || '',
        shortSize:
          registrationDetails.registration_info.preferred_short_size || '',
        hockeyUSANumber:
          registrationDetails.registration_info
            .preferred_governing_body_number_1 || '',
        hockeyCanadaNumber:
          registrationDetails.registration_info
            .preferred_governing_body_number_2 || '',
        notes: registrationDetails.registration_info.notes || '',
        emergencyEmail:
          registrationDetails.registration_info.emergency_email || '',
        zipCode: registrationDetails.registration_info.postal_code || '',
        city: registrationDetails.registration_info.city || '',
        postalCode: registrationDetails.registration_info.postal_code || '',
        otherMembershipNumber:
          registrationDetails?.registration_info
            ?.preferred_governing_body_number_1 || '',
      }))
    }
  }, [registrationDetails])

  useEffect(() => {
    if (registrationDetails && registrationDetails?.team_official) {
      setFormData((prev) => ({
        ...prev,
        firstName: registrationDetails?.team_official?.name_first || '',
        lastName: registrationDetails?.team_official?.name_last || '',
      }))
    }
  }, [registrationDetails])

  const updateFormData = (field: string, value: string | boolean) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  if (registrationDetails?.registration_type_id === 1) {
    return (
      <TeamOfficialForm
        formData={formData}
        updateFormData={updateFormData}
        countries={countries}
        isRegistrationDetails={true}
        teamInfo={registrationDetails?.team}
        registrationId={registrationDetails?.uid}
      />
    )
  }

  return (
    <PlayerForm
      formData={formData}
      updateFormData={updateFormData}
      countries={countries}
      provinces={provinces}
      disableFields={true}
      playerId={registrationDetails?.player?.id}
      registrationId={registrationDetails?.uid}
      refetch={refetch}
      positions={positions}
    />
  )
}

export default RegistrationDetailsInfo
