/** @jsxImportSource @emotion/react */
import { LoadingOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import Text from '../Text'
import { isCanlan } from '@sportninja/common/utils/customer-name'

type ButtonProps = {
  text: string
  onClick: () => void
  disabled?: boolean
  isLoading?: boolean
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'danger'
  style?: any
  uppercase?: boolean
}

const Button = ({
  text,
  onClick,
  disabled,
  isLoading,
  variant,
  style,
  uppercase = false,
}: ButtonProps) => {
  return (
    <button
      type='button'
      onClick={onClick}
      css={css`
        height: 40px;
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        border-radius: 8px;
        cursor: pointer;
        ${isLoading ||
        (disabled &&
          css`
            cursor: not-allowed;
            opacity: 0.7;
          `)}
        ${variant === 'primary' &&
        css`
          background-color: ${colors.PRIMARY};
          color: ${colors.WHITE};
          text-transform: uppercase;
        `}
        ${variant === 'secondary' &&
        css`
          background-color: transparent;
          border: 1px solid ${colors.WHITE};
          color: ${colors.WHITE};
          text-transform: uppercase;
        `}
        ${variant === 'tertiary' &&
        css`
          background-color: ${colors.WHITE};
          border: 1px solid ${colors.PRIMARY};
          color: ${colors.PRIMARY};
          text-transform: uppercase;
        `}
        ${variant === 'quaternary' &&
        css`
          background-color: ${colors.PRIMARY};
          border: 1px solid ${colors.PRIMARY};
          color: ${colors.SECONDARY_300};
          text-transform: uppercase;
        `}
        ${variant === 'danger' &&
        css`
          background-color: ${colors.ERROR_200};
          color: ${colors.WHITE};
          text-transform: uppercase;
        `}
        ${disabled &&
        css`
          background-color: ${colors.SECONDARY_200};
        `}
        ${uppercase &&
        css`
          text-transform: uppercase;
        `}
        ${style}
      `}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <LoadingOutlined
          css={css`
            color: ${colors.WHITE};
            font-size: 16px;
          `}
        />
      ) : (
        <Text
          variant='heading6'
          weight='semiBold'
          color={
            disabled
              ? colors.NEUTRAL_50
              : variant === 'quaternary'
              ? isCanlan
                ? colors.WHITE
                : colors.SECONDARY_300
              : variant === 'tertiary'
              ? colors.PRIMARY
              : colors.WHITE
          }
        >
          {text}
        </Text>
      )}
    </button>
  )
}

export default Button
