/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router-dom'
import { FrameContext } from '../../pages/App'
import Helmet from '../Helmet'
import Icon from '../Icon'
import ListStatusView from '../List/StatusView'
import { Desktop, media, Mobile } from '../Responsive'
import ContactInfoModal from './ContactInfoModal'
import PageMenu from './Menu'
import PageHeaderWithBackground from './PageHeader'
import MobilePageHeader from './MobileHeader'
import PageHeader from './Header'

const listFilter = (p) => typeof p !== 'undefined' && typeof p !== 'boolean'

const Page = ({
  loading,
  error,
  baseActions: unfilteredBaseActions = [],
  baseCrumb,
  baseRoute,
  baseTitle,
  breadcrumbs,
  pages: unfilteredPages = [],
  title: _title,
  isPrivate,
  refreshKey,
  iconName,
  imageId,
  isInFrame,
  titleActions: unfilteredTitleActions = [],
  preserveQueryFor,
  backgroundImageUrl = null,
  subTitle = null,
  contactInfo = null,
  organizationId = null,
  isTeamOrSchedule,
  isSchedule,
  isOrganization = false,
  isSoccer,
  sport,
  genericSport,
  isOrgsPage = false,
  hideBreadcrumbs = false,
  displayMobileActions = true,
  competitionStartDate = null,
  competitionEndDate = null,
}) => {
  const [isContactInfoModalOpen, setIsContactInfoModalOpen] = useState(false)
  const frameConfig = useContext(FrameContext)

  const pages = unfilteredPages.filter(listFilter)
  const baseActions = unfilteredBaseActions.filter(listFilter)
  const titleActions = unfilteredTitleActions.filter(listFilter)
  const history = useHistory()

  const actions = isInFrame ? (
    []
  ) : (
    <>
      {baseActions}
      {!error && !loading && (
        <Switch>
          {pages?.length > 0 && pages[0].actions && (
            <Route exact path={baseRoute}>
              {pages[0].actions}
            </Route>
          )}
          {pages?.length > 1 &&
            pages.map((page) => {
              if (!page.actions) {
                return null
              }
              return (
                <Route key={page.to} exact path={page.to}>
                  {page.actions}
                </Route>
              )
            })}
        </Switch>
      )}
    </>
  )

  const privateEntityError =
    (error && error.status === 403) ||
    (error && error.status !== 404 && isPrivate)

  let title = _title

  if (privateEntityError) {
    title = 'Private'
  } else if (error && error.status === 404) {
    title = 'Not Found'
  }

  const shouldHidePageMenu =
    isInFrame && frameConfig.hideHeader && frameConfig?.pages?.length === 1

  return (
    <>
      {loading ? null : (
        <>
          {!isOrgsPage ? (
            <PageHeaderWithBackground
              backgroundImageUrl={backgroundImageUrl}
              title={title}
              subTitle={subTitle}
              iconName={iconName}
              imageId={imageId}
              organizationId={organizationId}
              isOrganization={isOrganization}
              sport={sport}
              genericSport={genericSport}
              isSoccer={isSoccer}
              isInFrame={isInFrame}
              titleActions={titleActions}
              baseActions={baseActions}
              pages={pages}
              isSchedule={isSchedule}
              isPrivate={isPrivate}
              error={error}
              loading={loading}
              refreshKey={refreshKey}
              baseCrumb={baseCrumb}
              baseRoute={baseRoute}
              breadcrumbs={breadcrumbs}
              history={history}
              setIsContactInfoModalOpen={setIsContactInfoModalOpen}
              isTeam={isTeamOrSchedule}
              contactInfo={contactInfo}
              competitionStartDate={competitionStartDate}
              competitionEndDate={competitionEndDate}
            />
          ) : null}
          <ContactInfoModal
            title={'Contact Info'}
            isFormOpen={isContactInfoModalOpen}
            setIsFormOpen={setIsContactInfoModalOpen}
            contactInfo={{
              email: contactInfo?.email,
              phone: contactInfo?.phone,
              website: contactInfo?.website,
              support_email: contactInfo?.support_email,
              support_phone: contactInfo?.support_phone,
              support_website: contactInfo?.support_website,
            }}
          />
        </>
      )}
      <Desktop>
        {(isTeamOrSchedule || isOrganization) && !isInFrame && (
          <div
            css={css`
              position: absolute;
              border-bottom: 1px solid ${colors.SOFT_STEEL};
              background-color: #1b1f25;
              height: 48px;
              width: 150vw;
              left: -50%;
            `}
          />
        )}
      </Desktop>
      <div
        css={[
          css`
            margin: ${isTeamOrSchedule || isOrganization ? 0 : 40}px auto;
            padding: 0px 88px;
            max-width: 1600px;
            position: relative;
            ${media.mobile} {
              margin: 12px auto;
              padding: 0px;
            }
          `,
          (isTeamOrSchedule || isOrganization) && isInFrame
            ? css`
                min-height: 1600px;
              `
            : false,
        ]}
      >
        <Helmet title={title ? title : ''} />
        {!frameConfig.hideHeader && isOrgsPage && !loading && (
          <Mobile>
            {(isMobile) =>
              isMobile ? (
                <MobilePageHeader
                  isTeamOrSchedule={isTeamOrSchedule || isOrganization}
                  title={title}
                  iconName={iconName}
                  imageId={imageId}
                  actions={actions}
                  baseCrumb={baseCrumb || baseRoute}
                  baseTitle={baseTitle}
                  breadcrumbs={breadcrumbs}
                  isInFrame={isInFrame}
                  titleActions={titleActions}
                  displayMobileActions={displayMobileActions}
                >
                  {actions}
                </MobilePageHeader>
              ) : (
                <PageHeader
                  isTeamOrSchedule={isTeamOrSchedule || isOrganization}
                  baseCrumb={baseCrumb || baseRoute}
                  baseTitle={baseTitle}
                  title={title}
                  breadcrumbs={breadcrumbs}
                  loading={loading}
                  iconName={iconName}
                  imageId={imageId}
                  isInFrame={isInFrame}
                  titleActions={titleActions}
                  hideBreadcrumbs={hideBreadcrumbs}
                >
                  {isTeamOrSchedule ? false : actions}
                </PageHeader>
              )
            }
          </Mobile>
        )}
        {privateEntityError ? (
          <div
            css={css`
              text-align: center;
              ${isTeamOrSchedule || (isOrganization && 'margin-top: 64px;')}
            `}
          >
            <Icon
              name='lock'
              color='rgba(255, 255, 255, 0.14)'
              fontSize={100}
            />
            <p
              css={css`
                font-size: 16px;
                margin-top: 16px;
              `}
            >
              {t('Web:thisPageIsPrivate')}
            </p>
          </div>
        ) : error && error.status === 404 ? (
          <ListStatusView error={error.message} />
        ) : null}
        {!error && !shouldHidePageMenu && (
          <PageMenu
            isTeamOrSchedule={isTeamOrSchedule || isOrganization}
            isSchedule={isSchedule}
            baseRoute={baseRoute}
            pages={pages}
            loading={loading}
            actions={isInFrame ? [] : actions}
            isInFrame={isInFrame}
            preserveQueryFor={preserveQueryFor}
          />
        )}
        {!error && !loading && (
          <Switch>
            {pages?.length > 0 && (
              <Route exact path={baseRoute} key={`${baseRoute}-${refreshKey}`}>
                {pages[0].component}
              </Route>
            )}
            {pages?.length > 1 &&
              pages.map((page) => (
                <Route exact path={page.to} key={`${page.to}-${refreshKey}`}>
                  {page.component}
                </Route>
              ))}
          </Switch>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isInFrame: state.auth.inFrame,
  }
}

export default connect(mapStateToProps)(Page)
