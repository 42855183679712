/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'

import { zIndex } from '../../components/css'
import { Flex } from '../../components/Layout'
import { media } from '../../components/Responsive'

const SearchContainer = ({
  children,
  displayContents,
  isTeamOrSchedulePage,
}) => {
  return (
    <Flex
      column
      className={displayContents ? 'active' : ''}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        left: 0px;
        top: ${isTeamOrSchedulePage ? 64 : 50}px;
        right: 0px;
        height: calc(100vh - ${isTeamOrSchedulePage ? 64 : 50}px);
        z-index: ${zIndex.base + 1};
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        &::before {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(to bottom, #1b222b, #1b222b);
          z-index: -1;
          transition: opacity 0.2s ease-in-out;
          opacity: 0;
        }

        &.active {
          &,
          &::before {
            opacity: 1;
          }
        }

        padding: 40px 90px 0;

        ${media.mobile} {
          top: 48px;
          padding: 20px 8px 0;

          .search-top-mag-glass {
            display: none;
          }
        }

        pointer-events: ${displayContents ? 'auto' : 'none'};
      `}
    >
      {children}
    </Flex>
  )
}

export default SearchContainer
