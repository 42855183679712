/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import React from 'react'
import { connect } from 'react-redux'
import { Popover, theme } from 'antd'
import { Link } from 'react-router-dom'
import Icon from '../../../components/Icon'

import { Flex } from '../../../components/Layout'
import Picture from '../../../components/Picture'

const POPOVER_WIDTH = 214

// eslint-disable-next-line react/display-name
const GameInfoPopout = React.forwardRef(
  (
    { isOpen, slots = [], isInFrame, children, isSingleOrDoubleElimination },
    ref
  ) => {
    const { token } = theme.useToken()
    return (
      <Popover
        trigger='hover'
        // open={isOpen}
        placement='rightTop'
        content={
          isSingleOrDoubleElimination ? null : (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 12px;
                min-width: ${POPOVER_WIDTH}px;
              `}
            >
              {slots.map((slot, index) => {
                const {
                  id: slotId,
                  game_id: SNGameUID,
                  ended_at,
                  game_number,
                  homeTeamSlot,
                  visitingTeamSlot,
                  home_team_score,
                  visiting_team_score,
                } = slot

                const winningTeam =
                  home_team_score > visiting_team_score
                    ? homeTeamSlot
                    : visitingTeamSlot

                return (
                  <div
                    key={slotId}
                    css={
                      isSingleOrDoubleElimination
                        ? css`
                            display: none;
                          `
                        : ''
                    }
                  >
                    <Flex
                      as={SNGameUID ? (isInFrame ? Link : 'a') : undefined}
                      href={
                        SNGameUID && !isInFrame
                          ? `${ROUTES.GAME_ROOT}/${SNGameUID}`
                          : undefined
                      }
                      to={
                        SNGameUID && isInFrame
                          ? `${ROUTES.GAME_ROOT}/${SNGameUID}`
                          : undefined
                      }
                      rel={isInFrame ? undefined : 'noreferrer'}
                      target={isInFrame ? undefined : '_blank'}
                      // className='slot'
                      key={slotId}
                      css={css`
                        display: flex;
                        position: relative;
                        align-items: center;
                        ${SNGameUID &&
                        css`
                          cursor: pointer;
                        `}

                        &:hover .link-icon {
                          color: ${token.colorPrimary ??
                          colors.ATTENDANCE_GRAY};
                        }
                      `}
                    >
                      {(ended_at ||
                        homeTeamSlot?.team_id ||
                        visitingTeamSlot?.team_id) && (
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                            margin-right: 8px;

                            line-height: 0;

                            .team-image:nth-of-type(2) {
                              margin-top: 6px;
                            }

                            i {
                              transform: translateX(1px);
                            }
                          `}
                        >
                          {ended_at ? (
                            <Picture
                              className='team-image'
                              imageId={getImageThumbnailId(winningTeam)}
                              iconName='user-friends'
                              size='small'
                              square
                              fit
                            />
                          ) : (
                            <div>
                              {homeTeamSlot.team_id && (
                                <Picture
                                  className='team-image'
                                  imageId={getImageThumbnailId(homeTeamSlot)}
                                  iconName='user-friends'
                                  size='xxxsmall'
                                  square
                                  fit
                                />
                              )}
                              {visitingTeamSlot.team_id && (
                                <Picture
                                  className='team-image'
                                  imageId={getImageThumbnailId(
                                    visitingTeamSlot
                                  )}
                                  iconName='user-friends'
                                  size='xxxsmall'
                                  square
                                  fit
                                />
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      <div
                        css={css`
                          line-height: 1.3;
                          display: flex;
                          flex-direction: column;
                          justify-content: space-between;
                        `}
                      >
                        <div
                          css={css`
                            font-size: 16px;
                            font-weight: 700;
                            color: ${token.colorPrimary ??
                            colors.ATTENDANCE_GRAY};
                          `}
                        >
                          Game {game_number}
                        </div>
                        {ended_at ? (
                          <div>
                            <div
                              css={css`
                                font-size: 12px;
                                font-weight: 500;
                                color: ${colors.ATTENDANCE_GRAY};
                              `}
                            >
                              {home_team_score > visiting_team_score
                                ? `${home_team_score} - ${visiting_team_score}`
                                : `${visiting_team_score} - ${home_team_score}`}
                            </div>
                            <div
                              css={css`
                                font-size: 12px;
                                font-weight: 500;
                                color: ${colors.ATTENDANCE_GRAY};
                              `}
                            >
                              Winner: {winningTeam?.name_full}
                            </div>
                          </div>
                        ) : (
                          <div
                            css={css`
                              font-size: 12px;
                              font-weight: 500;
                              color: ${colors.ATTENDANCE_GRAY};
                            `}
                          >
                            {homeTeamSlot?.name_full} vs.{' '}
                            {visitingTeamSlot?.name_full}
                          </div>
                        )}
                      </div>
                      {SNGameUID && (
                        <Icon
                          className='link-icon'
                          name='external-link-alt'
                          faType='far'
                          fontSize={14}
                          color={colors.ATTENDANCE_GRAY}
                          css={css`
                            transition: color 0.2s;
                            position: absolute;
                            right: 0;
                            top: ${ended_at ? '4px' : '0'};
                          `}
                        />
                      )}
                    </Flex>
                  </div>
                )
              })}
            </div>
          )
        }
      >
        {children}
      </Popover>
    )
  }
)

const mapStateToProps = (state) => {
  return {
    isInFrame: state.auth.inFrame,
  }
}

export default connect(mapStateToProps)(GameInfoPopout)
