/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Modal, Table, Checkbox, App, Alert } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import colors from '@sportninja/common/constants/appColors'
import { Team } from '@sportninja/common/types/Registration'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Text from 'src/components/ts-components/Text'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import Button from 'src/components/ts-components/Button'
import {
  getErrorMessage,
  getImageThumbnailId,
} from '@sportninja/common/utils/utils'
import useWindowSize from 'src/hooks/useWindowSize'
import { useModalScroll } from 'src/hooks/useModalScroll'
import req from '@sportninja/common/api/request'
import { TextAreaInput } from 'src/components/ts-components/TextAreaInput'

interface ManageTeamsModalProps {
  isOpen: boolean
  onClose: () => void
  selectedTeams: Team[]
  onClearSelection: () => void
  allDivisions: any[]
  onSuccess?: () => void
  originDivisionId: string
}

const styles = css`
  .my-modal-body {
    max-height: calc(90vh - 110px);
    overflow-y: auto;
  }
  .my-modal-mask {
    backdrop-filter: blur(1px);
  }
  .my-modal-header {
    border-bottom: 1px dotted ${colors.FIERY_RED};
  }
  .my-modal-footer {
    color: ${colors.FIERY_RED};
    position: sticky;
    bottom: 0;
    background: ${colors.SECONDARY_300};
    padding: 16px;
    border-top: 1px solid ${colors.SOFT_STEEL};
  }
  .my-modal-content {
    border: 1px solid #333;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
  }
`

const classNames = {
  body: styles['my-modal-body'],
  mask: styles['my-modal-mask'],
  header: styles['my-modal-header'],
  footer: styles['my-modal-footer'],
  content: styles['my-modal-content'],
}

const modalStyles = {
  header: {
    backgroundColor: colors.SECONDARY_300,
    paddingLeft: '16px',
    paddingRight: '16px',
    position: 'sticky' as const,
    top: 0,
    zIndex: 1,
  },
  body: {
    borderBottom: `1px solid ${colors.SOFT_STEEL}`,
    borderTop: `1px solid ${colors.SOFT_STEEL}`,
    backgroundColor: colors.SECONDARY_300,
    flex: 1,
    overflow: 'auto',
  },
  content: {
    backgroundColor: colors.SECONDARY_300,
    paddingTop: '8px',
    paddingBottom: '0',
    paddingLeft: '0',
    paddingRight: '0',
    display: 'flex',
    flexDirection: 'column' as const,
    maxHeight: '90vh',
  },
  wrapper: {
    zIndex: 10001,
  },
  mask: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(6, 10, 16, 0.8)',
    zIndex: 10000,
  },
  footer: {
    paddingLeft: '16px',
    paddingRight: '16px',
    backgroundColor: colors.SECONDARY_300,
  },
}

const renderDivisionSelectOptions = (
  allDivisions: any[],
  originDivisionId: string
) => {
  return allDivisions
    .filter(
      (division) =>
        division.registration !== null && division.id !== originDivisionId
    )
    .sort((a, b) => a?.name?.localeCompare(b?.name || ''))
    .map((division) => ({
      label: (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <Text variant='paragraphSmall' weight='regular'>
            {division.name}
          </Text>
        </div>
      ),
      value: division.id,
    }))
}

const moveTeams = async ({
  scheduleId,
  teams,
  destinationDivisionId,
}: // notifyTeam,
// notificationMessage,
{
  scheduleId: string
  teams: Team[]
  destinationDivisionId: string
  notifyTeam?: boolean
  notificationMessage?: string
}) => {
  if (!scheduleId) {
    throw new Error('Invalid schedule ID')
  }
  if (!destinationDivisionId) {
    throw new Error('Invalid destination division ID')
  }
  if (!teams?.length) {
    throw new Error('Invalid teams')
  }

  // PUT /v1/registration/{schedule}/move-team/{team_registration}
  const response = await req(`/registration/${scheduleId}/admin/move-teams`, {
    method: 'PUT',
    body: JSON.stringify({
      team_registrations: teams.map((team) => ({
        id: team.id,
      })),
      destination_schedule_id: destinationDivisionId,
      send_notification: false,
      notification_message: '',
      // send_notification: notifyTeam,
      // notification_message: notificationMessage,
    }),
  })
  return response
}

const ManageTeamsModal: React.FC<ManageTeamsModalProps> = ({
  isOpen,
  onClose,
  selectedTeams,
  onClearSelection,
  allDivisions,
  onSuccess,
  originDivisionId,
}) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < 768, [width])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [selectedDivision, setSelectedDivision] = useState('')
  const [notifyTeam, setNotifyTeam] = useState(true)
  const [notificationMessage, setNotificationMessage] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const { openModal, closeModal } = useModalScroll()
  const { message } = App.useApp()

  useEffect(() => {
    if (isOpen) {
      openModal()
      // Initialize selected rows with current selected teams
      setSelectedRowKeys(selectedTeams.map((team) => team?.id))
    } else {
      closeModal()
    }
  }, [closeModal, isOpen, openModal, selectedTeams])

  const closeIcon = useCallback(() => {
    return (
      <CloseOutlined
        css={css`
          color: ${colors.SOLITUDE};
          font-size: 16px;
        `}
      />
    )
  }, [])

  const handleCancel = () => {
    setError('')
    setSelectedDivision('')
    setNotifyTeam(true)
    onClearSelection()
    onClose()
  }

  const handleMove = async () => {
    try {
      setLoading(true)
      setError('')
      // Filter selectedTeams based on selectedRowKeys
      const teamsToMove = selectedTeams.filter((team) =>
        selectedRowKeys.includes(team.id)
      )
      await moveTeams({
        scheduleId: originDivisionId,
        teams: teamsToMove,
        destinationDivisionId: selectedDivision,
        notifyTeam,
        notificationMessage,
      })
      message.success(
        `${
          teamsToMove?.length > 1
            ? `${teamsToMove.length} Teams`
            : `${teamsToMove[0].name}`
        } Moved Successfully`
      )
      onClose()
      onSuccess?.()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const getFooter = useCallback(() => {
    return (
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          gap: 16px;
          width: 50%;
          margin-left: auto;
        `}
      >
        <Button
          text='Cancel'
          onClick={handleCancel}
          variant='secondary'
          disabled={loading}
        />
        <Button
          text='Move'
          onClick={handleMove}
          variant='primary'
          disabled={
            loading || !selectedDivision || selectedRowKeys?.length === 0
          }
        />
      </div>
    )
  }, [loading, selectedDivision, selectedRowKeys])

  const selectedTeamsColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'selection',
      width: 48,
      fixed: 'left' as const,
      render: (_: any, record: Team) => (
        <Checkbox
          checked={selectedRowKeys.includes(record.id)}
          onChange={(e) => {
            const newSelectedKeys = e.target.checked
              ? [...selectedRowKeys, record.id]
              : selectedRowKeys.filter((key) => key !== record.id)
            setSelectedRowKeys(newSelectedKeys)
          }}
          css={css`
            .ant-checkbox-inner {
              background-color: ${colors.SECONDARY_200};
              border-color: ${colors.WHITE};
            }
            &:hover .ant-checkbox-inner {
              border-color: ${colors.PRIMARY};
            }
            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: ${colors.PRIMARY};
              border-color: ${colors.PRIMARY};
            }
          `}
        />
      ),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (name: string, record: Team) => (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <img
            alt={name}
            css={css`
              width: 24px;
              height: 24px;
              border-radius: 4px;
              object-fit: contain;
            `}
            onError={(e) => {
              const img = e.target as HTMLImageElement
              img.onerror = null
              img.src = '/images/generic_placeholders/placeholder_team.png'
            }}
            src={
              record.is_bucket
                ? '/images/bucket_image.png'
                : !getImageThumbnailId(record.image)
                ? '/images/generic_placeholders/placeholder_team.png'
                : getImageThumbnailId(record.image)
            }
          />
          <Text variant='paragraphSmall' weight='regular'>
            {name}
          </Text>
        </div>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'registration_status',
      key: 'registration_status',
      width: 200,
      render: (status: number) => {
        let statusText = ''
        let statusColor = colors.NEUTRAL_0

        switch (status) {
          case 1:
            statusText = 'Submitted'
            statusColor = colors.INFO_200
            break
          case 2:
            statusText = 'Pending'
            statusColor = colors.WARNING_200
            break
          case 3:
            statusText = 'Approved'
            statusColor = colors.SUCCESS_200
            break
          case 4:
            statusText = 'Approved w/ Conditions'
            statusColor = colors.SUCCESS_200
            break
          case 5:
            statusText = 'Cancelled'
            statusColor = colors.ERROR_200
            break
          default:
            statusText = 'Unknown'
            statusColor = colors.NEUTRAL_0
        }

        return (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            <div
              css={css`
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: ${statusColor};
              `}
            />
            <Text variant='paragraphSmall' weight='regular'>
              {statusText}
            </Text>
          </div>
        )
      },
    },
  ]

  if (!isOpen) {
    return null
  }

  return (
    <Modal
      width={1080}
      closeIcon={closeIcon()}
      title={
        <Text variant='heading3' weight='semiBold' color={colors.WHITE}>
          Move Teams
        </Text>
      }
      open={isOpen}
      onCancel={handleCancel}
      footer={getFooter()}
      centered
      classNames={classNames}
      styles={modalStyles}
      maskClosable={false}
      css={css`
        .ant-modal-content {
          overflow: hidden;
          height: ${isMobile ? '100%' : '80vh'} !important;
          display: flex;
          flex-direction: column;
        }
        .ant-modal-body {
          flex: 1;
          border-bottom: none !important;
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }
        .ant-modal-footer {
          position: sticky;
          bottom: 0;
          background: ${colors.SECONDARY_300};
          padding: 16px;
          border-top: 1px solid ${colors.SOFT_STEEL};
        }
        height: ${isMobile ? '100%' : '80vh'} !important;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 32px;
          padding: 24px;
          flex: 1;
          overflow: auto;
          ${isMobile && 'flex-direction: column;'}
        `}
      >
        {/* Selected Teams Section */}
        <div
          css={css`
            flex: 1;
          `}
        >
          <Text
            variant='captionLarge'
            weight='semiBold'
            style={`
              margin-bottom: 24px;
              display: block;
              padding-bottom: 8px;
              border-bottom: 1px solid ${colors.SOFT_STEEL};
            `}
          >
            Selected Teams
          </Text>
          <Table
            dataSource={selectedTeams}
            columns={selectedTeamsColumns}
            pagination={false}
            size='small'
            bordered
            scroll={{ x: 500 }}
            css={css`
              .ant-table {
                border: 1px solid ${colors.SECONDARY_400} !important;
                table-layout: fixed;
                width: 100%;
              }
              .ant-table-cell {
                border-color: ${colors.SECONDARY_400} !important;
              }
              .ant-table-thead > tr > th {
                padding: 4px !important;
                background-color: ${colors.SECONDARY_400} !important;
                text-transform: uppercase;
                font-weight: 600;
              }
              .ant-table-tbody > tr:nth-child(even) {
                background-color: ${colors.SECONDARY_300};
              }
              .ant-table-tbody > tr:nth-child(odd) {
                background-color: ${colors.SECONDARY_400};
              }
              .ant-table-tbody > tr > td {
                vertical-align: middle;
              }
              @media (max-width: 768px) {
                /* padding: 0 16px !important; */
              }
            `}
          />
        </div>

        {/* Separator */}
        <div
          css={css`
            width: 1px;
            background-color: ${colors.SOFT_STEEL};
            margin: 0 8px;
          `}
        />

        {/* Select Division Section */}
        <div
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
        >
          <Text
            variant='captionLarge'
            weight='semiBold'
            style={`
              display: block;
              padding-bottom: 8px;
              border-bottom: 1px solid ${colors.SOFT_STEEL};
            `}
          >
            Select Destination Division
          </Text>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 16px;
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;
              `}
            >
              <SelectInput
                name='division'
                label='Division *'
                options={renderDivisionSelectOptions(
                  allDivisions,
                  originDivisionId
                )}
                onChange={(value) => setSelectedDivision(value)}
                value={selectedDivision}
                fieldErrors={[]}
                customError={{}}
                disabled={loading}
                placeholder='Select a Division'
                showSearch
                filterOption={(input, option) => {
                  return (option?.label?.props?.children?.props?.children || '')
                    ?.toLowerCase()
                    ?.includes(input.toLowerCase())
                }}
              />
            </div>
            <Checkbox
              checked={notifyTeam}
              onChange={(e) => setNotifyTeam(e.target.checked)}
              css={css`
                .ant-checkbox-inner {
                  background-color: ${colors.SECONDARY_200};
                  border-color: ${colors.WHITE};
                }
                .ant-checkbox-wrapper {
                  color: ${colors.WHITE};
                }
                &:hover .ant-checkbox-inner {
                  border-color: ${colors.PRIMARY};
                }
                .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: ${colors.PRIMARY};
                  border-color: ${colors.PRIMARY};
                }
                /* Hide due to https://sportninja.atlassian.net/browse/SN-5675 */
                display: none;
              `}
            >
              <Text
                variant='paragraphSmall'
                weight='regular'
                color={colors.WHITE}
              >
                Notify team officials about the move
              </Text>
            </Checkbox>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;
                /* Hide due to https://sportninja.atlassian.net/browse/SN-5675 */
                display: none;
              `}
            >
              <TextAreaInput
                name='notification_message'
                label='Notification Message'
                value={notificationMessage}
                setValue={(value) => setNotificationMessage(value)}
                fieldErrors={[]}
                customError={{}}
                disabled={loading}
                placeholder='Enter a message to notify the team officials about the division change'
              />
            </div>
          </div>
        </div>
      </div>
      {error && (
        <Alert
          message={error}
          type='error'
          css={css`
            margin: 0 16px 16px;
          `}
          showIcon
        />
      )}
    </Modal>
  )
}

export default ManageTeamsModal
