/* eslint-disable no-dupe-keys */
/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { ROUTES } from '@sportninja/common/constants/app'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Table,
  Avatar,
  Typography,
  Spin,
  TableProps,
  Input,
} from 'antd'
import ReprocessingOverlay from '../../../pages/Schedule/ReprocessingOverlay'
import { Flex } from '../../Layout'
import GameListFilters, {
  SortOrderButton,
} from '../../List/GameList/GameListFilters'
import useGetScheduleFiltering from '../../List/useGetScheduleFiltering'
import Icon from '../../Icon'
import colors from '@sportninja/common/constants/appColors'
import cn from 'classnames'
import ReprocessingSettingsOverlay from 'src/pages/Schedule/ReprocessingSettingsOverlay'

const Statistics = ({
  autoSelectMostRecentComp,
  areFiltersHidden,
  entityType,
  entityId,
  defaultSort,
  refreshKey,
  scheduleReprocessingListener,
  isSoccer = false,
  orgId,
  displaySearch = true,
}) => {
  const BASE_SLUG = `/${entityType}/${entityId}/stats/players`

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [sortBy, setSortBy] = useState(defaultSort)
  const [direction, setDirection] = useState('desc')
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({})
  const [slug, setSlug] = useState(BASE_SLUG)
  const [isGlobalFilterEnabled, setIsGlobalFilterEnabled] = useState(true)

  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchValue)

  const isSchedule = entityType === ENTITY_TYPES.schedule
  const isTeam = entityType === ENTITY_TYPES.team
  const args = {}

  if (isSchedule) {
    args.schedule_id = entityId
  } else if (isTeam) {
    args.team_id = entityId
  }

  const onTeamChange = (teamId) => {
    setTeam(teamId)
    if (teamId) {
      setSlug(`/schedules/${entityId}/stats/players/team/${teamId}`)
    } else {
      setSlug(BASE_SLUG)
    }
  }

  const onScheduleChange = (scheduleId) => {
    if (scheduleId) {
      setScheduleForSearch(scheduleId)
      setSlug(
        isSchedule
          ? `/schedules/${scheduleId}/stats/players`
          : `/schedules/${scheduleId}/stats/players/team/${entityId}`
      )
    } else {
      setSlug(BASE_SLUG)
    }
  }

  const onIsGoalie = (isGoalie) => {
    setIsGoalieStats(isGoalie)
  }

  const {
    teams,
    team,
    setTeam,
    filters,
    state,
    setState,
    loaded,
    currentParams,
  } = useGetScheduleFiltering(args, {
    defaultSort: 'desc',
    autoSelectMostRecentComp,
    onTeamChange,
    onScheduleChange,
    onIsGoalie,
  })

  useEffect(() => {
    if (currentParams.sn_division) {
      setSlug(
        `/schedules/${currentParams.sn_division}/stats/players/team/${entityId}`
      )
    } else if (currentParams.sn_schedule) {
      setSlug(
        `/schedules/${currentParams.sn_schedule}/stats/players/team/${entityId}`
      )
    }
  }, [currentParams, entityId, setTeam])

  useEffect(() => {
    if (currentParams.sn_team) {
      setTeam(currentParams.sn_team)
    }
  }, [currentParams, setTeam])

  useEffect(() => {
    setTeam(entityId)
  }, [entityId, setTeam])

  const [scheduleForSearch, setScheduleForSearch] = useState(null)
  const [isGoalieStats, setIsGoalieStats] = useState(false)

  useEffect(() => {
    let timeout

    const debounce = () => {
      timeout = setTimeout(() => {
        setDebouncedSearchTerm(searchValue)
      }, 400)
    }

    clearTimeout(timeout)
    debounce()

    return () => {
      clearTimeout(timeout)
    }
  }, [searchValue])

  const getItems = useCallback(
    async (page) => {
      if (!page && !!pagination?.current_page) {
        return
      }

      setLoading(true)
      try {
        const response = await req(slug, {
          query: {
            page,
            sortBy,
            sort: direction,
            goalie: isGoalieStats ? 1 : 0,
            ...(scheduleForSearch && { division_id: scheduleForSearch }),
            ...(isGlobalFilterEnabled && { global: 1 }),
            ...(debouncedSearchTerm && { player_name: debouncedSearchTerm }),
          },
        })
        setItems(response.data)
        setPagination(response.meta?.pagination)
      } catch (e) {
        setError(e.message)
      } finally {
        setLoading(false)
      }
    },
    [
      pagination,
      isGoalieStats,
      sortBy,
      direction,
      slug,
      scheduleForSearch,
      isGlobalFilterEnabled,
      debouncedSearchTerm,
    ]
  )

  useEffect(() => {
    if (loaded) {
      getItems(1)
    }
  }, [
    loaded,
    direction,
    slug,
    entityId,
    sortBy,
    isGoalieStats,
    refreshKey,
    scheduleForSearch,
    isGlobalFilterEnabled,
    debouncedSearchTerm,
  ])

  const commonColumns = [
    {
      title: '#',
      key: 'PN',
      dataIndex: 'PN',
      fixed: 'left',
      width: 50,
      sorter: (a, b, sortOrder) => {
        setSortBy('player.player_number')
        const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
        setDirection(direction)
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: {
        title: 'Player Number',
      },
    },
    {
      title: 'Player',
      key: 'player',
      dataIndex: 'player',
      sorter: (a, b, sortOrder) => {
        setSortBy('player.full_name')
        const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
        setDirection(direction)
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (item) => {
        return (
          <Link
            to={`${ROUTES.PLAYER_ROOT}/${item?.player?.id}`}
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            <Avatar
              src={
                getImageThumbnailId(item?.player) ||
                '/images/placeholders/hockey/player.png'
              }
              icon={<Icon name='user' fontSize={14} />}
              css={css`
                border: 1px solid #dbdbdb;
                background-color: white;
                margin: 0;
              `}
            />
            <Typography.Text strong>
              {item?.player?.name_first} {item?.player?.name_last}
            </Typography.Text>
          </Link>
        )
      },
      fixed: 'left',
      width: 270,
    },
  ]

  const skaterColumns = [
    {
      title: 'GP',
      key: 'GP',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'GP',
      showSorterTooltip: {
        title: 'Games Played',
      },
    },
    {
      title: 'G',
      key: 'G',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'G',
      showSorterTooltip: {
        title: 'Goals',
      },
    },
    {
      title: 'A',
      key: 'A',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'A',
      showSorterTooltip: {
        title: 'Assists',
      },
    },
    {
      title: 'P',
      key: 'P',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'P',
      defaultSortOrder: 'descend',
      showSorterTooltip: {
        title: 'Points',
      },
    },
    {
      title: 'PIM',
      key: 'PiM',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'PiM',
      showSorterTooltip: {
        title: 'Penalty Minutes',
      },
    },
    {
      title: 'PPG',
      key: 'PPG',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'PPG',
      showSorterTooltip: {
        title: 'Power Play Goals',
      },
    },
    {
      title: 'PTS/G',
      key: 'PTS/G',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'PTS/G',
      showSorterTooltip: {
        title: 'Average Points Per Game',
      },
    },
  ]

  const goalieColumns = [
    {
      title: 'GP',
      key: 'GP',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'GP',
      showSorterTooltip: {
        title: 'Games Played',
      },
    },
    {
      title: 'W',
      key: 'W',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'W',
      defaultSortOrder: 'descend',
      showSorterTooltip: {
        title: 'Wins',
      },
    },
    {
      title: 'L',
      key: 'L',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'L',
      showSorterTooltip: {
        title: 'Losses',
      },
    },
    {
      title: 'T',
      key: 'T',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'T',
      showSorterTooltip: {
        title: 'Ties',
      },
    },
    {
      title: 'OTL',
      key: 'OTL',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'OTL',
      showSorterTooltip: {
        title: 'Overtime Losses',
      },
    },
    {
      title: 'GAA',
      key: 'GAA',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'GAA',
      width: 70,
      showSorterTooltip: {
        title: 'Goals Against Average',
      },
    },
    {
      title: 'SV%',
      key: 'SV%',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'SV%',
      width: 70,
      showSorterTooltip: {
        title: 'Save Percentage',
      },
    },
    {
      title: 'SO',
      key: 'SO',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'SO',
      showSorterTooltip: {
        title: 'Shutouts',
      },
    },
    {
      title: 'MIN',
      key: 'MP',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'MP',
      width: 70,
      showSorterTooltip: {
        title: 'Minutes Played',
      },
    },
    {
      title: 'PIM',
      key: 'PiM',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'PiM',
      showSorterTooltip: {
        title: 'Penalty Minutes',
      },
    },
    {
      title: 'PPG',
      key: 'PPG',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'PPG',
      showSorterTooltip: {
        title: 'Power Play Goals',
      },
    },
    {
      title: 'SA',
      key: 'SA',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'SA',
      showSorterTooltip: {
        title: 'Shots Against',
      },
    },
    {
      title: 'SV',
      key: 'SV',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'SV',
      showSorterTooltip: {
        title: 'Saves',
      },
    },
    {
      title: 'GA',
      key: 'GA',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'GA',
      showSorterTooltip: {
        title: 'Goals Against',
      },
    },
  ]

  const playerColumns = [
    {
      title: 'MP',
      key: 'MP',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'MP',
      showSorterTooltip: {
        title: 'Matches Played',
      },
    },
    // {
    //   title: 'Mins',
    //   key: 'Mins',
    //   sorter: (a, b, sortOrder) => {},
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   dataIndex: 'Mins',
    //   showSorterTooltip: {
    //     title: 'Minutes Played',
    //   },
    // },
    {
      title: 'G',
      key: 'G',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      dataIndex: 'G',
      showSorterTooltip: {
        title: 'Goals',
      },
    },
    {
      title: 'A',
      key: 'A',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'A',
      showSorterTooltip: {
        title: 'Assists',
      },
    },
    // {
    //   title: 'GF',
    //   key: 'GF',
    //   sorter: (a, b, sortOrder) => {},
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   dataIndex: 'GF',
    //   showSorterTooltip: {
    //     title: 'Goals For',
    //   },
    // },
    {
      title: 'GFA',
      key: 'GFA',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'GFA',
      showSorterTooltip: {
        title: 'Goals For Average',
      },
    },
    {
      title: 'FC',
      key: 'FC',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'FC',
      showSorterTooltip: {
        title: 'Fouls Committed',
      },
    },
    {
      title: 'YC',
      key: 'YC',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'YC',
      showSorterTooltip: {
        title: 'Yellow Cards',
      },
    },
    {
      title: 'RC',
      key: 'RC',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'RC',
      showSorterTooltip: {
        title: 'Red Cards',
      },
    },
    {
      title: 'BC',
      key: 'BC',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'BC',
      showSorterTooltip: {
        title: 'Blue Cards',
      },
    },
  ]

  const keeperColumns = [
    {
      title: 'MP',
      key: 'MP',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'MP',
      showSorterTooltip: {
        title: 'Matches Played',
      },
    },
    // {
    //   title: 'Mins',
    //   key: 'Mins',
    //   sorter: (a, b, sortOrder) => {},
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   dataIndex: 'Mins',
    //   showSorterTooltip: {
    //     title: 'Minutes Played',
    //   },
    // },
    {
      title: 'GA',
      key: 'GA',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'GA',
      showSorterTooltip: {
        title: 'Goals Against',
      },
    },
    {
      title: 'GAA',
      key: 'GAA',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'GAA',
      showSorterTooltip: {
        title: 'Goals Against Average',
      },
    },
    {
      title: 'SV',
      key: 'SV',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'SV',
      showSorterTooltip: {
        title: 'Saves',
      },
    },
    {
      title: 'CS',
      key: 'CS',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'CS',
      showSorterTooltip: {
        title: 'Clean Sheets',
      },
    },
    {
      title: 'W',
      key: 'W',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'W',
      showSorterTooltip: {
        title: 'Wins',
      },
    },
    {
      title: 'L',
      key: 'L',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'L',
      showSorterTooltip: {
        title: 'Loss',
      },
    },
    {
      title: 'D',
      key: 'D',
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'D',
      showSorterTooltip: {
        title: 'Draws',
      },
    },
  ]

  const data = useMemo(
    () => [
      ...items.map((item, index) => ({
        key: index,
        player: item,
        team: item,
        PN: item?.player?.player_number ?? '#',
        // SKATER DATA
        GP: item?.stats?.find((s) => s.abbr === 'GP')?.value ?? 0,
        G: item?.stats?.find((s) => s.abbr === 'G')?.value ?? 0,
        A: item?.stats?.find((s) => s.abbr === 'A')?.value ?? 0,
        P: item?.stats?.find((s) => s.abbr === 'P')?.value ?? 0,
        PiM: item?.stats?.find((s) => s.abbr === 'PiM')?.value ?? 0,
        PPG: item?.stats?.find((s) => s.abbr === 'PPG')?.value ?? 0,
        'PTS/G': item?.stats?.find((s) => s.abbr === 'PTS/G')?.value ?? 0,
        // GOALIE DATA
        W: item?.stats?.find((s) => s.abbr === 'W')?.value ?? 0,
        L: item?.stats?.find((s) => s.abbr === 'L')?.value ?? 0,
        T: item?.stats?.find((s) => s.abbr === 'T')?.value ?? 0,
        OTL: item?.stats?.find((s) => s.abbr === 'OTL')?.value ?? 0,
        GAA: item?.stats?.find((s) => s.abbr === 'GAA')?.value ?? 0,
        'SV%': item?.stats?.find((s) => s.abbr === 'SV%')?.value ?? 0,
        SO: item?.stats?.find((s) => s.abbr === 'SO')?.value ?? 0,
        MP: item?.stats?.find((s) => s.abbr === 'MP')?.value ?? 0,
        SA: item?.stats?.find((s) => s.abbr === 'SA')?.value ?? 0, // Saves Average
        SV: item?.stats?.find((s) => s.abbr === 'SV')?.value ?? 0,
        GA: item?.stats?.find((s) => s.abbr === 'GA')?.value ?? 0,
        // SPECIFIC SOCCER DATA
        'G/M': item?.stats?.find((s) => s.abbr === 'G/M')?.value ?? 0,
        GCA: item?.stats?.find((s) => s.abbr === 'GCA')?.value ?? 0,
        SA: item?.stats?.find((s) => s.abbr === 'SA')?.value ?? 0, // Shots Against
        SV: item?.stats?.find((s) => s.abbr === 'SV')?.value ?? 0,
        SO: item?.stats?.find((s) => s.abbr === 'SO')?.value ?? 0,
        YC: item?.stats?.find((s) => s.abbr === 'YC')?.value ?? 0,
        FC: item?.stats?.find((s) => s.abbr === 'FC')?.value ?? 0,
        GC: item?.stats?.find((s) => s.abbr === 'GC')?.value ?? 0,
        FG: item?.stats?.find((s) => s.abbr === 'FG')?.value ?? 0,
        RC: item?.stats?.find((s) => s.abbr === 'RC')?.value ?? 0,
        BC: item?.stats?.find((s) => s.abbr === 'BC')?.value ?? 0,
        TP: item?.stats?.find((s) => s.abbr === 'TP')?.value ?? 0,
        CS: item?.stats?.find((s) => s.abbr === 'CS')?.value ?? 0,
        D: item?.stats?.find((s) => s.abbr === 'D')?.value ?? 0,
        Mins: item?.stats?.find((s) => s.abbr === 'Mins')?.value ?? 0,
        GF: item?.stats?.find((s) => s.abbr === 'GF')?.value ?? 0,
        GFA: item?.stats?.find((s) => s.abbr === 'GFA')?.value ?? 0,
      })),
    ],
    [items, isGoalieStats]
  )

  const columns = useMemo(() => {
    if (isGoalieStats) {
      return commonColumns.concat(isSoccer ? keeperColumns : goalieColumns)
    }
    return commonColumns.concat(isSoccer ? playerColumns : skaterColumns)
  }, [isGoalieStats, isSoccer])

  const renderTable = useCallback(() => {
    return (
      <Table
        columns={columns}
        bordered
        loading={loading}
        size='small'
        scroll={{ x: 1300 }}
        pagination={{
          defaultCurrent: pagination?.current_page,
          total: pagination?.count,
          showSizeChanger: false,
          pageSize: pagination?.per_page,
          onChange: (page) => getItems(page),
          position: ['bottomCenter'],
        }}
        dataSource={data}
        onChange={(pagination, filters, sorter) => {
          setSortBy(sorter?.columnKey)
          setDirection(sorter?.order === 'descend' ? 'desc' : 'asc')
        }}
      />
    )
  }, [data, loading, pagination, columns])

  return (
    <>
      {/* <ReprocessingOverlay
        scheduleReprocessingListener={scheduleReprocessingListener}
      /> */}
      <ReprocessingSettingsOverlay
        scheduleReprocessingListener={scheduleReprocessingListener}
      />
      <div
        css={css`
          margin-top: 24px;

          ${scheduleReprocessingListener &&
          css`
            opacity: 0.7;
            filter: blur(2px);
            pointer-events: none;
          `}
        `}
      >
        <GameListFilters
          filters={filters}
          team_id={entityId}
          setSlug={setSlug}
          state={state}
          setState={setState}
          loading={loading}
          teams={teams}
          team={team}
          orgId={orgId}
          scheduleId={entityId}
          onTeamChange={onTeamChange}
          onScheduleChange={onScheduleChange}
          isHidden={areFiltersHidden}
        >
          <Flex
            column
            noFlex
            css={css`
              margin-right: 16px;
              .ant-btn-default {
                color: rgba(0, 0, 0, 0.88) !important;
                background-color: transparent !important;
              }

              button {
                text-transform: capitalize !important;
              }
            `}
          >
            <label
              htmlFor={isGoalieStats ? 'skater-stats' : 'goalie-stats'}
              css={css`
                height: 12px;
                font-size: 12px;
                line-height: 12px;
              `}
            >
              Player Type
            </label>
            <Flex
              css={css`
                display: flex;
                margin-top: 8px;
                gap: 8px;
              `}
            >
              <SortOrderButton
                disabled={loading}
                type={!isGoalieStats ? 'primary' : 'default'}
                className={cn({ sorted: !isGoalieStats })}
                onClick={() => {
                  setIsGoalieStats(false)
                  setDirection('desc')
                  setSortBy('P')
                }}
              >
                {isSoccer ? 'Player' : 'Skater'}
              </SortOrderButton>
              <SortOrderButton
                disabled={loading}
                type={isGoalieStats ? 'primary' : 'default'}
                className={cn({ sorted: isGoalieStats })}
                onClick={() => {
                  setIsGoalieStats(true)
                  setDirection('asc')
                  setSortBy('GAA')
                }}
              >
                {isSoccer ? 'Keeper' : 'Goalie'}
              </SortOrderButton>
            </Flex>
          </Flex>
          {isSchedule && scheduleForSearch ? (
            <Flex column noFlex>
              <label
                htmlFor={isGoalieStats ? 'skater-stats' : 'goalie-stats'}
                css={css`
                  height: 12px;
                  font-size: 12px;
                  line-height: 12px;
                `}
              >
                Stats Filter
              </label>
              <Flex
                css={css`
                  margin-top: 8px;
                  padding: 0 16px 16px 0;
                `}
              >
                <SortOrderButton
                  disabled={loading}
                  type={isGlobalFilterEnabled ? 'primary' : 'default'}
                  onClick={() => {
                    setIsGlobalFilterEnabled(true)
                    setSlug(BASE_SLUG)
                  }}
                  className={cn({ sorted: isGlobalFilterEnabled })}
                >
                  {'Season'}
                </SortOrderButton>
                <SortOrderButton
                  disabled={loading}
                  type={!isGlobalFilterEnabled ? 'primary' : 'default'}
                  onClick={() => {
                    setIsGlobalFilterEnabled(false)
                    if (scheduleForSearch && isSchedule) {
                      setSlug(`/schedules/${scheduleForSearch}/stats/players`)
                    }
                  }}
                  css={css`
                    margin-left: 8px;
                  `}
                  className={cn({ sorted: !isGlobalFilterEnabled })}
                >
                  {'Division'}
                </SortOrderButton>
              </Flex>
            </Flex>
          ) : null}
        </GameListFilters>
        {error ? (
          <div
            css={css`
              margin-top: 100px;
              text-align: center;
            `}
          >
            {error}
          </div>
        ) : (
          <div>
            {displaySearch ? (
              <div
                css={css`
                  margin-bottom: 16px;
                  display: flex;
                  align-items: center;
                  gap: 8px;
                `}
              >
                <Typography.Text strong>Search Player:</Typography.Text>
                <div>
                  <Input.Search
                    value={searchValue}
                    placeholder='Enter player name'
                    onChange={(e) => {
                      setSearchValue(e.target.value)
                    }}
                    suffix={
                      <button
                        disabled={searchValue === ''}
                        onClick={(e) => {
                          e.preventDefault()
                          setSearchValue('')
                          getItems(1)
                        }}
                        css={css`
                          background: none;
                          border: none;
                          cursor: pointer;
                          opacity: ${searchValue === '' ? 0 : 1};
                        `}
                      >
                        <Icon name='times' />
                      </button>
                    }
                    onSearch={() => getItems(1)}
                    loading={loading}
                    disabled={loading}
                  />
                </div>
              </div>
            ) : null}
            {renderTable()}
          </div>
        )}
      </div>
    </>
  )
}

export default Statistics
