/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { TextInput } from 'src/components/ts-components/TextInput'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import { TextAreaInput } from 'src/components/ts-components/TextAreaInput'
import Text from 'src/components/ts-components/Text'
import PhoneInput from 'src/components/ts-components/PhoneInput'
import { Country } from '@sportninja/common/hooks/useCountries'
import { Province } from '@sportninja/common/hooks/useProvinces'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import CopyComponent from 'src/components/CopyComponent'
import ShareComponent from 'src/components/ShareComponent'
import { DateOfBirthInput } from 'src/components/ts-components/DateOfBirthInput'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Alert, Image, Upload } from 'antd'
import { useState } from 'react'
import req from '@sportninja/common/api/request'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'

async function updatePlayerImage(
  teamId: string,
  playerId: string,
  base64: File
) {
  const res = await req(`/teams/${teamId}/players/${playerId}/image`, {
    method: 'POST',
    body: base64,
  })

  return res
}

const jerseySizes = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
  { label: 'X-Large', value: 'x-large' },
  { label: 'XX-Large', value: 'xx-large' },
]

const tshirtSizes = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
  { label: 'X-Large', value: 'x-large' },
  { label: 'XX-Large', value: 'xx-large' },
]

const shortSizes = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
  { label: 'X-Large', value: 'x-large' },
  { label: 'XX-Large', value: 'xx-large' },
]

const parentRelationships = [
  { label: 'Mother', value: 'mother' },
  { label: 'Father', value: 'father' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Spouse', value: 'spouse' },
  { label: 'Partner', value: 'partner' },
  { label: 'Other', value: 'other' },
]

interface PlayerFormProps {
  formData: {
    email: string
    firstName: string
    lastName: string
    birthDate: string
    address: string
    zipCode: string
    stateProvince: string | number
    country: string
    parentFirstName: string
    parentLastName: string
    parentEmail: string
    parentPhone: string
    emergencyFirstName: string
    emergencyLastName: string
    emergencyPhone: string
    parentRelationship: string
    doctorName: string
    doctorPhone: string
    allergies: string
    medicalConditions: string
    preferredPosition: string
    jerseyNumber: string
    dominantSide: string
    jerseySize: string
    tshirtSize: string
    shortSize: string
    hockeyUSANumber: string
    hockeyCanadaNumber: string
    notes: string
    city: string
    fieldErrors: any[]
    customError: Record<string, any>
    emergencyEmail: string
    otherMembershipNumber: string
    image?: any
  }
  updateFormData: (field: string, value: string) => void
  countries: Country[]
  provinces: Province[]
  disableFields?: boolean
  playerId?: string
  registrationId?: string
  teamId?: string
  refetch: () => void
  positions: any[]
  scheduleId?: string
}

const PlayerForm = ({
  formData,
  updateFormData,
  countries,
  provinces,
  disableFields = false,
  playerId,
  registrationId,
  teamId,
  refetch = () => {},
  positions,
  scheduleId,
}: PlayerFormProps) => {
  const [newPlayerImage, setNewPlayerImage] = useState<File | null>(null)
  const [newPlayerLoading, setNewPlayerLoading] = useState(false)
  const [newPlayerError, setNewPlayerError] = useState<string | null>(null)
  const [newPlayerSuccess, setNewPlayerSuccess] = useState<string | null>(null)

  const beforeUpload = (file: File) => {
    const isImage = file.type.startsWith('image/')
    if (!isImage) {
      return false
    }
    return false // Return false to prevent auto upload
  }

  const handleChange = (info: any) => {
    if (info.file) {
      setNewPlayerImage(info.file)
    }
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        margin-top: 20px;
        padding: 0 16px;
        padding-bottom: 16px;
        overflow-y: auto;
      `}
    >
      {/* Player Info Section */}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          align-self: stretch;
          gap: 16px;
          width: 100%;
        `}
      >
        <Text
          variant='captionLarge'
          weight='semiBold'
          color={colors.NEUTRAL_0}
          style={`
            align-self: center;
          `}
        >
          Player Info
        </Text>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            gap: 8px;
          `}
        >
          {playerId ? (
            <CopyComponent
              customText='Player ID'
              id={playerId || ''}
              alternativeBackground={disableFields}
            />
          ) : null}
          {registrationId ? (
            <>
              <CopyComponent
                customText='Registration ID'
                id={registrationId || ''}
                alternativeBackground={disableFields}
              />
              {!disableFields && (
                <ShareComponent
                  registrationId={registrationId}
                  scheduleId={scheduleId || ''}
                  alternativeBackground={disableFields}
                />
              )}
            </>
          ) : null}
        </div>
      </div>

      {/* Player Photo Section */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            width: 25%;
          }
          ${disableFields && 'display: none;'}
        `}
      >
        {formData?.image ? (
          <div
            css={css`
              display: flex;
              padding: 8px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 8px;
              background-color: ${colors.SECONDARY_200};
              border: 1px solid ${colors.SECONDARY_100};
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                flex: 1 0 0;
              `}
            >
              <Image
                src={
                  getImageThumbnailId(formData) ||
                  '/images/generic_placeholders/placeholder_player.png'
                }
                alt='player photo'
                width={56}
                height={56}
                css={css`
                  border-radius: 4px;
                  object-fit: cover;
                `}
                preview={false}
              />
              <DeleteOutlined
                css={css`
                  color: ${colors.ERROR_200};
                  font-size: 24px;
                  cursor: pointer;
                `}
                onClick={async () => {
                  try {
                    await req(`/teams/${teamId}/players/${playerId}/image`, {
                      method: 'DELETE',
                    })
                    setNewPlayerImage(null)
                    updateFormData('imageUrl', '')
                    refetch()
                  } catch (e: any) {
                    setNewPlayerError(
                      e?.message ||
                        'An error occurred while deleting the player photo'
                    )
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <Upload
            beforeUpload={beforeUpload}
            onChange={handleChange}
            showUploadList={false}
            action={undefined}
            css={css`
              .ant-upload-wrapper .ant-upload {
                width: 100% !important;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                padding: 8px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                border-radius: 8px;
                border: 1px dashed ${colors.SECONDARY_100};
                cursor: pointer;
                width: 100%;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  flex: 1 0 0;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    width: 48px;
                    height: 48px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: ${colors.PRIMARY};
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      width: 24px;
                      height: 24px;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      border-radius: 4px;
                      background: ${colors.WHITE};
                    `}
                  >
                    <PlusCircleOutlined
                      css={css`
                        font-size: 24px;
                        color: ${colors.SECONDARY_300};
                        background-color: ${colors.PRIMARY};
                      `}
                    />
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 4px;
                    `}
                  >
                    <Text
                      variant='paragraphSmall'
                      weight='regular'
                      color={colors.NEUTRAL_0}
                    >
                      Player Photo
                    </Text>
                  </div>
                  <Text
                    variant='paragraphSmall'
                    weight='medium'
                    color={colors.PRIMARY}
                  >
                    Click to Upload
                  </Text>
                </div>
              </div>
            </div>
          </Upload>
        )}

        {newPlayerImage && !newPlayerSuccess ? (
          <button
            css={css`
              display: inline-flex;
              padding: 8px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 8px;
              background: ${colors.PRIMARY};
              color: ${colors.WHITE};
              border: none;
              cursor: pointer;
              font-weight: 600;
              &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            `}
            onClick={async () => {
              if (!teamId || !playerId) {
                setNewPlayerError('Team ID or Player ID is required')
                return
              }
              setNewPlayerLoading(true)
              setNewPlayerError(null)
              setNewPlayerSuccess(null)
              try {
                await updatePlayerImage(teamId, playerId, newPlayerImage)
                setNewPlayerSuccess('Player photo updated successfully')
                // Update the form data with new image
                const reader = new FileReader()
                reader.onloadend = () => {
                  updateFormData('imageUrl', reader.result as string)
                }
                reader.readAsDataURL(newPlayerImage)
                refetch()
              } catch (e: any) {
                setNewPlayerError(
                  e?.message ||
                    'An error occurred while updating the player photo'
                )
              } finally {
                setNewPlayerLoading(false)
              }
            }}
            disabled={newPlayerLoading}
          >
            Upload photo
          </button>
        ) : null}

        {newPlayerSuccess ? (
          <Alert
            message={'Photo Uploaded Successfully'}
            type='success'
            showIcon
            css={css`
              width: 100%;
            `}
          />
        ) : null}

        {newPlayerError ? (
          <Alert
            message={newPlayerError || 'An error occurred'}
            type='error'
            showIcon
            css={css`
              width: 100%;
            `}
          />
        ) : null}
      </div>

      {!disableFields && (
        <Text
          variant='paragraphSmall'
          color={colors.NEUTRAL_100}
          style={'margin-top: -8px;'}
        >
          Please be sure to enter the{' '}
          <span style={{ fontWeight: 'bold' }}>player&apos;s name</span> below.
        </Text>
      )}

      {/* Name Fields */}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='firstName'
              value={formData.firstName}
              setValue={(value) => updateFormData('firstName', value)}
              label='Player First Name'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              autoComplete='given-name'
              disabled={disableFields}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='lastName'
              value={formData.lastName}
              setValue={(value) => updateFormData('lastName', value)}
              label='Player Last Name'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              autoComplete='family-name'
              disabled={disableFields}
            />
          </div>
        </div>
      </div>

      {/* Email and Birth Date Fields */}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='email'
              value={formData.email}
              setValue={(value) => updateFormData('email', value)}
              label='Email'
              type='email'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <DateOfBirthInput
              name='birthDate'
              value={formData.birthDate}
              setValue={(value) => updateFormData('birthDate', value)}
              label='Player Birth Date'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
        </div>
      </div>

      {/* Position Field */}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <SelectInput
              name='preferredPosition'
              value={formData.preferredPosition}
              onChange={(value) => updateFormData('preferredPosition', value)}
              label='Position'
              options={positions.map((position) => ({
                label: position.name_full,
                value: String(position.id),
              }))}
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          />
        </div>
      </div>

      {/* Address Section */}
      <Text
        variant='captionLarge'
        weight='semiBold'
        color={colors.NEUTRAL_0}
        style={'align-self: flex-start;'}
      >
        Address
      </Text>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='address'
              value={formData.address}
              setValue={(value) => updateFormData('address', value)}
              label='Address *'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='city'
              value={formData.city}
              setValue={(value) => updateFormData('city', value)}
              label='City *'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <SelectInput
              name='country'
              value={formData.country}
              onChange={(value) => updateFormData('country', value)}
              label='Country *'
              options={
                countries
                  ? countries.map((el) => {
                      return {
                        label: el?.name,
                        value: el?.iso_3166_2,
                      }
                    })
                  : []
              }
              disabled={disableFields}
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <SelectInput
              name='stateProvince'
              value={String(formData.stateProvince)}
              onChange={(value) => updateFormData('stateProvince', value)}
              label='State/Province *'
              options={provinces.map((province) => ({
                label: province.name,
                value: String(province.id),
              }))}
              disabled={disableFields}
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            flex: 1;
          `}
        >
          <TextInput
            name='zipCode'
            value={formData.zipCode}
            setValue={(value) => updateFormData('zipCode', value)}
            label='Zip/Postal Code *'
            fieldErrors={formData.fieldErrors}
            customError={formData.customError}
            disabled={disableFields}
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            flex: 1;
          `}
        />
      </div>

      {/* Parents/Guardian Info Section */}
      <Text
        variant='captionLarge'
        weight='semiBold'
        color={colors.NEUTRAL_0}
        style={'align-self: flex-start;'}
      >
        Parent/Guardian Info
      </Text>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='parentFirstName'
              value={formData.parentFirstName}
              setValue={(value) => updateFormData('parentFirstName', value)}
              label='Parent/Guardian First Name'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='parentLastName'
              value={formData.parentLastName}
              setValue={(value) => updateFormData('parentLastName', value)}
              label='Parent/Guardian Last Name'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='parentEmail'
              value={formData.parentEmail}
              setValue={(value) => updateFormData('parentEmail', value)}
              label='Parent/Guardian Email Address'
              type='email'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <PhoneInput
              value={formData.parentPhone}
              onChange={(value) => updateFormData('parentPhone', value)}
              label='Parent/Guardian Phone Number'
              error={formData.customError?.parentPhone}
              disabled={disableFields}
            />
          </div>
        </div>
      </div>

      {/* Emergency Contact Info Section */}
      <Text
        variant='captionLarge'
        weight='semiBold'
        color={colors.NEUTRAL_0}
        style={'align-self: flex-start;'}
      >
        Emergency Contact Info
      </Text>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='emergencyFirstName'
              value={formData.emergencyFirstName}
              setValue={(value) => updateFormData('emergencyFirstName', value)}
              label='Emergency Contact First Name *'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='emergencyLastName'
              value={formData.emergencyLastName}
              setValue={(value) => updateFormData('emergencyLastName', value)}
              label='Emergency Contact Last Name *'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <PhoneInput
              value={formData.emergencyPhone}
              onChange={(value) => updateFormData('emergencyPhone', value)}
              label='Emergency Contact Phone Number *'
              error={formData.customError?.emergencyPhone}
              disabled={disableFields}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <SelectInput
              name='parentRelationship'
              value={formData.parentRelationship}
              onChange={(value) => updateFormData('parentRelationship', value)}
              label='Emergency Contact Relationship *'
              options={parentRelationships}
              disabled={disableFields}
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='emergencyEmail'
              value={formData.emergencyEmail}
              setValue={(value) => updateFormData('emergencyEmail', value)}
              label='Emergency Contact Email *'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='allergies'
              value={formData.allergies}
              setValue={(value) => updateFormData('allergies', value)}
              label='Known Allergies'
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='doctorName'
              value={formData.doctorName}
              setValue={(value) => updateFormData('doctorName', value)}
              label="Doctor's Name"
              fieldErrors={formData.fieldErrors}
              customError={formData.customError}
              disabled={disableFields}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <PhoneInput
              value={formData.doctorPhone}
              onChange={(value) => updateFormData('doctorPhone', value)}
              label="Doctor's Phone Number"
              error={formData.customError?.doctorPhone}
              disabled={disableFields}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      />

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            flex: 1;
          `}
        >
          <TextAreaInput
            name='medicalConditions'
            value={formData.medicalConditions}
            setValue={(value) => updateFormData('medicalConditions', value)}
            label='Medical Conditions'
            fieldErrors={formData.fieldErrors}
            customError={formData.customError}
            rows={2}
            disabled={disableFields}
          />
        </div>
      </div>

      {/* Preferences Section */}
      <Text
        variant='captionLarge'
        weight='semiBold'
        color={colors.NEUTRAL_0}
        style={'align-self: flex-start;'}
      >
        Preferences
      </Text>

      {isCanlan ? (
        <>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              width: 100%;
              @media (min-width: 768px) {
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                flex: 2;
                @media (min-width: 768px) {
                  flex-direction: row;
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <TextAreaInput
                  name='notes'
                  value={formData.notes}
                  setValue={(value) => updateFormData('notes', value)}
                  label='Notes'
                  fieldErrors={formData.fieldErrors}
                  customError={formData.customError}
                  disabled={disableFields}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              width: 100%;
              @media (min-width: 768px) {
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                flex: 2;
                @media (min-width: 768px) {
                  flex-direction: row;
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <TextInput
                  name='jerseyNumber'
                  value={formData.jerseyNumber}
                  setValue={(value) => updateFormData('jerseyNumber', value)}
                  label='Preferred Jersey Number'
                  type='number'
                  fieldErrors={formData.fieldErrors}
                  customError={formData.customError}
                  disabled={disableFields}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <SelectInput
                  name='dominantSide'
                  value={formData.dominantSide}
                  onChange={(value) => updateFormData('dominantSide', value)}
                  label='Dominant Side'
                  options={[
                    { value: 'left', label: 'Left' },
                    { value: 'right', label: 'Right' },
                  ]}
                  disabled={disableFields}
                  fieldErrors={formData.fieldErrors}
                  customError={formData.customError}
                />
              </div>
            </div>
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              width: 100%;
              @media (min-width: 768px) {
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                flex: 2;
                @media (min-width: 768px) {
                  flex-direction: row;
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <SelectInput
                  name='jerseySize'
                  value={formData.jerseySize}
                  onChange={(value) => updateFormData('jerseySize', value)}
                  label='Jersey Size'
                  options={jerseySizes}
                  disabled={disableFields || isCanlan}
                  fieldErrors={formData.fieldErrors}
                  customError={formData.customError}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <SelectInput
                  name='tshirtSize'
                  value={formData.tshirtSize}
                  onChange={(value) => updateFormData('tshirtSize', value)}
                  label='T-Shirt Size'
                  options={tshirtSizes}
                  disabled={disableFields || isCanlan}
                  fieldErrors={formData.fieldErrors}
                  customError={formData.customError}
                />
              </div>
            </div>
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              width: 100%;
              @media (min-width: 768px) {
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                flex: 2;
                @media (min-width: 768px) {
                  flex-direction: row;
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <SelectInput
                  name='shortSize'
                  value={formData.shortSize}
                  onChange={(value) => updateFormData('shortSize', value)}
                  label='Short Size'
                  options={shortSizes}
                  disabled={disableFields || isCanlan}
                  fieldErrors={formData.fieldErrors}
                  customError={formData.customError}
                />
              </div>
            </div>
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              width: 100%;
              @media (min-width: 768px) {
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                flex: 2;
                @media (min-width: 768px) {
                  flex-direction: row;
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <TextInput
                  name='otherMembershipNumber'
                  value={formData.otherMembershipNumber}
                  setValue={(value) =>
                    updateFormData('otherMembershipNumber', value)
                  }
                  label='Governing Body Member ID'
                  placeholder='eg: Hockey USA, Soccer USA, or USA Waterpolo'
                  fieldErrors={formData.fieldErrors}
                  customError={formData.customError}
                  disabled={disableFields}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              />
            </div>
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              width: 100%;
              @media (min-width: 768px) {
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
                flex: 1;
              `}
            >
              <TextAreaInput
                name='notes'
                value={formData.notes}
                setValue={(value) => updateFormData('notes', value)}
                label='Notes'
                fieldErrors={formData.fieldErrors}
                customError={formData.customError}
                disabled={disableFields}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default PlayerForm
