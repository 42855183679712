/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Table, App } from 'antd'
import { useCallback, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import colors from '@sportninja/common/constants/appColors'
import {
  BillingScheduleItem,
  CurrencyType,
} from '@sportninja/common/types/Registration'
import { formatCurrency, getErrorMessage } from '@sportninja/common/utils/utils'
import IndicatorCard from 'src/components/ts-components/IndicatorCard'
import {
  WalletOutlined,
  DollarOutlined,
  FilePdfOutlined,
} from '@ant-design/icons'
import { formatLongDate } from '@sportninja/common/ts-utils/date-utils'
import { ROUTES } from '@sportninja/common/constants/app'
import LoadingSpinner from 'src/components/LoadingSpinner'
import req from '@sportninja/common/api/request'
import useWindowSize from 'src/hooks/useWindowSize'
import { RegistrationDetails } from '@sportninja/common/hooks/useRegistrationDetails'

type RegistrationDetailsBillingProps = {
  selectedRegistration: RegistrationDetails | null
  currencies: CurrencyType[]
}

async function fetchBillingInfo(billingId) {
  const response = await req(`/billing/schedule/${billingId}/receipt`)
  return response.data
}

const RegistrationDetailsBilling = ({
  selectedRegistration,
  currencies,
}: RegistrationDetailsBillingProps) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < 768, [width])
  const { message } = App.useApp()
  const downloadRef = useRef(null)
  const [loadingPdfIds, setLoadingPdfIds] = useState<string[]>([])
  const isPlayerRegistration = useMemo(
    () => selectedRegistration?.registration_type !== 'Team',
    [selectedRegistration?.registration_type]
  )

  const currency = useMemo(() => {
    if (!selectedRegistration?.billing?.[0]?.currency) {
      return null
    }
    return currencies.find(
      (c) => Number(c.id) === selectedRegistration.billing[0].currency
    )?.name
  }, [selectedRegistration, currencies])

  const totalOutstanding = useMemo(() => {
    if (!selectedRegistration?.billing) {
      return 0
    }

    let total = 0
    selectedRegistration.billing
      ?.filter((el) =>
        isPlayerRegistration
          ? el.user_type === 'player'
          : el.user_type === 'teamRep'
      )
      .filter((el) => !el.paid)
      .forEach((el) => {
        total += el.subtotal
      })

    return total
  }, [isPlayerRegistration, selectedRegistration?.billing])

  const totalPaid = useMemo(() => {
    if (!selectedRegistration?.billing) {
      return 0
    }

    let total = 0
    selectedRegistration.billing
      ?.filter((el) =>
        isPlayerRegistration
          ? el.user_type === 'player'
          : el.user_type === 'teamRep'
      )
      .filter((el) => el.paid)
      .forEach((el) => {
        total += el.subtotal
      })

    return total
  }, [isPlayerRegistration, selectedRegistration?.billing])

  const calculatePastDues = (billingDetails: BillingScheduleItem[]) => {
    let total = 0

    billingDetails
      ?.filter((el) =>
        isPlayerRegistration
          ? el.user_type === 'player'
          : el.user_type === 'teamRep'
      )
      .forEach((el) => {
        if (el.paid) {
          return
        }

        const day = dayjs(el?.due_date, 'YYYY-MM-DD')
        const isAfter = dayjs().isAfter(day)

        if (isAfter) {
          total += el.subtotal
        }
      })

    return total
  }

  const onView = useCallback(
    async (billingId: string) => {
      setLoadingPdfIds((prev) => [...prev, billingId])
      try {
        const response = await fetchBillingInfo(billingId)
        const pdf = await req(`/billing/schedule/${billingId}/receipt/pdf`, {
          parseJSON: false,
        })
        const normalizePdf = await pdf.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(normalizePdf)
        link.download = `${response?.charge?.stripe_id}.pdf`
        link.click()
      } catch (err) {
        console.error(err)
        message.open({
          type: 'error',
          content: getErrorMessage(err),
        })
      } finally {
        setLoadingPdfIds((prev) => prev.filter((id) => id !== billingId))
      }
    },
    [setLoadingPdfIds, message]
  )

  const columns = useMemo(
    () =>
      [
        {
          title: 'Payment Type',
          dataIndex: 'payment_type',
          key: 'payment_type',
          render: (_text: string, record: BillingScheduleItem) => (
            <span>
              {record.single_payment
                ? 'One Time Payment'
                : record.payment_type === 'deposit'
                ? 'Deposit'
                : 'Installment'}
            </span>
          ),
        },
        {
          title: 'Due Date',
          dataIndex: 'due_date',
          key: 'due_date',
          render: (date: string, record: BillingScheduleItem) => (
            <span
              css={css`
                color: ${dayjs(date).isBefore(dayjs()) && !record.paid
                  ? colors.ERROR_200
                  : colors.WHITE};
              `}
            >
              {formatLongDate(date)}
            </span>
          ),
        },
        {
          title: 'Amount',
          dataIndex: 'subtotal',
          key: 'subtotal',
          render: (amount: number) => (
            <span>{currency && formatCurrency(amount, currency)}</span>
          ),
        },
        !isMobile && {
          title: 'Installment',
          key: 'installment',
          render: (_: any, _record: any, index: number) => (
            <span>
              {index > 0
                ? `${index}/${
                    selectedRegistration?.billing?.length
                      ? selectedRegistration?.billing?.length - 1
                      : 0
                  }`
                : '--'}
            </span>
          ),
        },
        {
          title: 'Status',
          key: 'status',
          align: 'center',
          width: isMobile ? 80 : 100,
          render: (record: BillingScheduleItem) => {
            const isPaid = record.paid
            const isPastDue =
              dayjs(record.due_date).isBefore(dayjs()) && !record.paid
            const isRefunded = record?.charge?.refunded
            if (isRefunded) {
              return (
                <span
                  css={css`
                    padding: 4px;
                    border-radius: 4px;
                    border: 1px solid ${colors.INFO_200};
                    color: ${colors.INFO_200};
                    font-size: ${isMobile ? '12px' : '14px'};
                    white-space: nowrap;
                    display: inline-block;
                    text-align: center;
                    min-width: ${isMobile ? '70px' : '90px'};
                  `}
                >
                  Refunded
                </span>
              )
            }

            return (
              <span
                css={css`
                  padding: 4px;
                  border-radius: 4px;
                  border: 1px solid
                    ${isPaid
                      ? colors.SUCCESS_200
                      : isPastDue
                      ? colors.ERROR_200
                      : colors.WARNING_200};
                  color: ${isPaid
                    ? colors.SUCCESS_200
                    : isPastDue
                    ? colors.ERROR_200
                    : colors.WARNING_200};
                  font-size: ${isMobile ? '12px' : '14px'};
                  white-space: nowrap;
                  display: inline-block;
                  text-align: center;
                  min-width: ${isMobile ? '70px' : '90px'};
                `}
              >
                {isPaid ? 'Paid' : isPastDue ? 'Past Due' : 'Pending'}
              </span>
            )
          },
        },
        {
          title: 'Action',
          key: 'action',
          align: 'center',
          width: isMobile ? 80 : 120,
          render: (record: BillingScheduleItem) => (
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
              `}
            >
              {!record.paid && (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    cursor: pointer;
                    border: 1px solid ${colors.PRIMARY};
                    padding: 4px 8px;
                    border-radius: 8px;
                    background-color: ${colors.PRIMARY};
                  `}
                  onClick={() => {
                    window.open(
                      `${ROUTES.REGISTRATION_PAYMENT}?teamId=${selectedRegistration?.team?.uid}&rosterId=${selectedRegistration?.team?.team_player_roster_id}&billingId=${record.uid}`,
                      '_blank'
                    )
                  }}
                >
                  <DollarOutlined
                    css={css`
                      font-size: ${isMobile ? '16px' : '20px'};
                      color: ${colors.SECONDARY_300};
                    `}
                  />
                  {!isMobile && (
                    <span
                      css={css`
                        color: ${colors.SECONDARY_300};
                        font-size: 14px;
                      `}
                    >
                      Pay
                    </span>
                  )}
                </div>
              )}
              {record.paid && (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;
                    border: 1px solid ${colors.PRIMARY};
                    padding: 8px;
                    border-radius: 8px;
                    background-color: transparent;
                    min-width: ${isMobile ? '40px' : '100px'};
                  `}
                  ref={downloadRef}
                  onClick={() => onView(record?.uid)}
                >
                  {loadingPdfIds.includes(record.uid) ? (
                    <LoadingSpinner size={1.5} />
                  ) : (
                    <>
                      <FilePdfOutlined
                        css={css`
                          font-size: ${isMobile ? '16px' : '20px'};
                          color: ${colors.PRIMARY};
                        `}
                      />
                      {!isMobile && (
                        <span
                          css={css`
                            color: ${colors.PRIMARY};
                            font-size: 14px;
                          `}
                        >
                          Receipt
                        </span>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          ),
        },
      ].filter(Boolean),
    [
      isMobile,
      currency,
      selectedRegistration?.billing?.length,
      selectedRegistration?.team?.uid,
      selectedRegistration?.team?.team_player_roster_id,
      loadingPdfIds,
      onView,
    ]
  )

  return (
    <div
      css={css`
        padding: ${isMobile ? '8px' : '0'};
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        margin-top: 16px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: ${isMobile ? 'column' : 'row'};
          gap: 16px;
          margin-bottom: 16px;
          width: 100%;
          overflow: visible;
        `}
      >
        <IndicatorCard
          icon={<WalletOutlined />}
          title='Total Paid'
          value={
            currency
              ? `${formatCurrency(totalPaid, currency)} / ${formatCurrency(
                  totalOutstanding + totalPaid,
                  currency
                )}`
              : ''
          }
          subtitle={`Total Outstanding: ${
            currency ? formatCurrency(totalOutstanding, currency) : ''
          }`}
          hideSmallIndicator={true}
          dynamicHeight={true}
        />

        {!!selectedRegistration?.billing?.find((el) => !el.paid)?.subtotal && (
          <IndicatorCard
            icon={<WalletOutlined />}
            title='Next Payment'
            value={
              currency
                ? formatCurrency(
                    selectedRegistration?.billing?.find((el) => !el.paid)
                      ?.subtotal || 0,
                    currency
                  )
                : ''
            }
            subtitle={formatLongDate(
              selectedRegistration?.billing?.find((el) => !el.paid)?.due_date ||
                ''
            )}
            hideSmallIndicator={true}
            dynamicHeight={true}
          />
        )}

        <IndicatorCard
          icon={<WalletOutlined />}
          title='Total Past Due'
          value={
            currency
              ? formatCurrency(
                  calculatePastDues(selectedRegistration?.billing || []),
                  currency
                )
              : ''
          }
          subtitle=''
          hideSmallIndicator={true}
          dynamicHeight={true}
        />
      </div>

      <Table
        dataSource={selectedRegistration?.billing}
        // @ts-ignore
        columns={columns}
        pagination={false}
        rowKey={(record) => record.uid}
        scroll={{ x: isMobile ? 500 : 'max-content' }}
        size='small'
        css={css`
          .ant-table-thead > tr > th {
            padding: 16px 8px !important;
            background-color: ${colors.SECONDARY_400} !important;
          }
          .ant-table-tbody > tr:nth-child(even) {
            background-color: ${colors.SECONDARY_300};
          }
          .ant-table-tbody > tr:nth-child(odd) {
            background-color: ${colors.SECONDARY_400};
          }
          .ant-table-tbody > tr > td {
            vertical-align: middle;
          }
          @media (max-width: 768px) {
            /* padding: 0 16px !important; */
          }
        `}
      />
    </div>
  )
}

export default RegistrationDetailsBilling
