/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import React, { useContext, memo } from 'react'
import { TextInput } from 'src/components/ts-components/TextInput'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import { TextAreaInput } from 'src/components/ts-components/TextAreaInput'
import Text from 'src/components/ts-components/Text'
import { RegistrationContext } from '../context/RegistrationContext'
import PhoneInput from 'src/components/ts-components/PhoneInput'
import { DateOfBirthInput } from 'src/components/ts-components/DateOfBirthInput'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const jerseySizes = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
  { label: 'X-Large', value: 'x-large' },
  { label: 'XX-Large', value: 'xx-large' },
]

const tshirtSizes = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
  { label: 'X-Large', value: 'x-large' },
  { label: 'XX-Large', value: 'xx-large' },
]

const shortSizes = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
  { label: 'X-Large', value: 'x-large' },
  { label: 'XX-Large', value: 'xx-large' },
]

const parentRelationships = [
  { label: 'Mother', value: 'mother' },
  { label: 'Father', value: 'father' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Spouse', value: 'spouse' },
  { label: 'Partner', value: 'partner' },
  { label: 'Sibling', value: 'sibling' },
  { label: 'Other', value: 'other' },
]

const PlayerInfoSection = ({
  selectedPlayer,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  birthDate,
  setBirthDate,
  fieldErrors,
  customError,
  positions,
  preferredPosition,
  setPreferredPosition,
}) => {
  return (
    <>
      <Text
        variant='captionLarge'
        weight='semiBold'
        color={colors.NEUTRAL_0}
        style={'align-self: flex-start;'}
      >
        Player Info
      </Text>

      <Text
        variant='paragraphMedium'
        weight='regular'
        color={colors.NEUTRAL_50}
        style={'margin-top: -8px;'}
      >
        Please be sure to enter the{' '}
        <span style={{ fontWeight: 'bold' }}>player&apos;s name</span> below.
      </Text>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='firstName'
              value={
                selectedPlayer && selectedPlayer?.id !== 'new'
                  ? selectedPlayer?.name_first
                  : firstName
              }
              setValue={setFirstName}
              label='Player First Name *'
              fieldErrors={fieldErrors}
              customError={customError}
              css={css`
                flex: 1;
              `}
              disabled={selectedPlayer?.id !== 'new'}
              autoComplete='given-name'
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='lastName'
              value={
                selectedPlayer && selectedPlayer?.id !== 'new'
                  ? selectedPlayer?.name_last
                  : lastName
              }
              setValue={setLastName}
              label='Player Last Name *'
              fieldErrors={fieldErrors}
              customError={customError}
              css={css`
                flex: 1;
              `}
              disabled={selectedPlayer?.id !== 'new'}
              autoComplete='family-name'
            />
          </div>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <SelectInput
              name='preferredPosition'
              value={preferredPosition}
              onChange={setPreferredPosition}
              label='Player Position *'
              options={positions.map((position) => ({
                label: position.name_full,
                value: position.id,
              }))}
              disabled={false}
              fieldErrors={fieldErrors}
              customError={customError}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <DateOfBirthInput
              name='birthDate'
              value={birthDate}
              setValue={setBirthDate}
              label='Player Birth Date *'
              fieldErrors={fieldErrors}
              customError={customError}
              // disabled={true}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='email'
              value={email}
              setValue={setEmail}
              label='Email *'
              type='email'
              fieldErrors={fieldErrors}
              customError={customError}
              css={css`
                flex: 1;
              `}
              disabled={true}
            />
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          />
        </div>
      </div>
    </>
  )
}

const Divider = () => {
  return (
    <div
      css={css`
        margin-top: 4px;
        margin-bottom: 4px;
      `}
    />
  )
}

const PlayerForm = ({ fieldErrors, customError, positions }) => {
  const {
    // Player Info
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    birthDate,
    setBirthDate,
    preferredPosition,
    setPreferredPosition,

    // Address
    address,
    setAddress,
    zipCode,
    setZipCode,
    stateProvince,
    setStateProvince,
    country,
    setCountry,
    countries,
    city,
    setCity,

    // Parents/Guardian Info
    parentFirstName,
    setParentFirstName,
    parentLastName,
    setParentLastName,
    parentEmail,
    setParentEmail,
    parentPhone,
    setParentPhone,

    // Emergency Contact Info
    emergencyEmail,
    setEmergencyEmail,
    emergencyFirstName,
    setEmergencyFirstName,
    emergencyLastName,
    setEmergencyLastName,
    emergencyPhone,
    setEmergencyPhone,
    parentRelationship,
    setParentRelationship,
    doctorName,
    setDoctorName,
    doctorPhone,
    setDoctorPhone,
    allergies,
    setAllergies,
    medicalConditions,
    setMedicalConditions,

    // Preferences
    jerseyNumber,
    setJerseyNumber,
    dominantSide,
    setDominantSide,
    jerseySize,
    setJerseySize,
    tshirtSize,
    setTshirtSize,
    shortSize,
    setShortSize,
    // hockeyUSANumber,
    // setHockeyUSANumber,
    // hockeyCanadaNumber,
    // setHockeyCanadaNumber,
    notes,
    setNotes,
    // otherMembershipName,
    // setOtherMembershipName,
    otherMembershipNumber,
    setOtherMembershipNumber,

    provinces,

    selectedPlayer,
  } = useContext(RegistrationContext)

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
      `}
    >
      {/* Player Info Section */}
      <PlayerInfoSection
        selectedPlayer={selectedPlayer}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
        birthDate={birthDate}
        setBirthDate={setBirthDate}
        fieldErrors={fieldErrors}
        customError={customError}
        positions={positions}
        preferredPosition={preferredPosition}
        setPreferredPosition={setPreferredPosition}
      />

      <Divider />

      {/* Address Section */}
      <Text
        variant='captionLarge'
        weight='semiBold'
        color={colors.NEUTRAL_0}
        style={'align-self: flex-start;'}
      >
        Address
      </Text>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='address'
              value={address}
              setValue={setAddress}
              label='Address *'
              fieldErrors={fieldErrors}
              customError={customError}
              autoComplete='street-address'
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='city'
              value={city}
              setValue={setCity}
              label='City *'
              fieldErrors={fieldErrors}
              customError={customError}
              autoComplete='address-level2'
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <SelectInput
              name='country'
              value={country}
              onChange={(value) => {
                setCountry(value)
                setStateProvince('')
              }}
              label='Country *'
              options={
                countries
                  ? countries.map((el) => {
                      return {
                        label: el?.name,
                        value: el?.iso_3166_2,
                      }
                    })
                  : []
              }
              disabled={false}
              fieldErrors={fieldErrors}
              customError={customError}
              autoComplete='country'
              showSearch={true}
              filterOption={(input, option) =>
                option?.label?.toLowerCase().includes(input?.toLowerCase())
              }
              allowClear={true}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <SelectInput
              name='stateProvince'
              value={stateProvince}
              onChange={setStateProvince}
              label='State/Province *'
              options={provinces.map((province) => ({
                label: province.name,
                value: province.id,
              }))}
              disabled={false}
              fieldErrors={fieldErrors}
              customError={customError}
              autoComplete='address-level1'
              showSearch={true}
              filterOption={(input, option) =>
                option?.label?.toLowerCase().includes(input?.toLowerCase())
              }
              allowClear={true}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='zipCode'
              value={zipCode}
              setValue={setZipCode}
              label='Zip/Postal Code *'
              fieldErrors={fieldErrors}
              customError={customError}
              autoComplete='postal-code'
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          />
        </div>
      </div>

      <Divider />

      {/* Parents/Guardian Info Section */}
      <Text
        variant='captionLarge'
        weight='semiBold'
        color={colors.NEUTRAL_0}
        style={'align-self: flex-start;'}
      >
        Parent/Guardian Info
      </Text>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='parentFirstName'
              value={parentFirstName}
              setValue={setParentFirstName}
              label='Parent/Guardian First Name'
              fieldErrors={fieldErrors}
              customError={customError}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='parentLastName'
              value={parentLastName}
              setValue={setParentLastName}
              label='Parent/Guardian Last Name'
              fieldErrors={fieldErrors}
              customError={customError}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='parentEmail'
              value={parentEmail}
              setValue={setParentEmail}
              label='Parent/Guardian Email Address'
              type='email'
              fieldErrors={fieldErrors}
              customError={customError}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <PhoneInput
              name='parentPhone'
              value={parentPhone}
              onChange={setParentPhone}
              label='Parent/Guardian Phone Number'
              type='tel'
              fieldErrors={fieldErrors}
              customError={customError}
              error={customError?.parentPhone}
            />
          </div>
        </div>
      </div>

      <Divider />

      {/* Emergency Contact Info Section */}
      <Text
        variant='captionLarge'
        weight='semiBold'
        color={colors.NEUTRAL_0}
        style={'align-self: flex-start;'}
      >
        Emergency Contact Info
      </Text>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='emergencyFirstName'
              value={emergencyFirstName}
              setValue={setEmergencyFirstName}
              label='Emergency Contact First Name *'
              fieldErrors={fieldErrors}
              customError={customError}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='emergencyLastName'
              value={emergencyLastName}
              setValue={setEmergencyLastName}
              label='Emergency Contact Last Name *'
              fieldErrors={fieldErrors}
              customError={customError}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <PhoneInput
              name='emergencyPhone'
              value={emergencyPhone}
              onChange={setEmergencyPhone}
              label='Emergency Contact Phone Number *'
              type='tel'
              fieldErrors={fieldErrors}
              customError={customError}
              error={customError?.emergencyPhone}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <SelectInput
              name='parentRelationship'
              value={parentRelationship}
              onChange={setParentRelationship}
              label='Emergency Contact Relationship *'
              options={parentRelationships} // Add relationship options
              disabled={false}
              fieldErrors={fieldErrors}
              customError={customError}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='emergencyEmail'
              value={emergencyEmail}
              setValue={setEmergencyEmail}
              label='Emergency Contact Email *'
              fieldErrors={fieldErrors}
              customError={customError}
              type='email'
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='allergies'
              value={allergies}
              setValue={setAllergies}
              label='Known Allergies'
              fieldErrors={fieldErrors}
              customError={customError}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 2;
            @media (min-width: 768px) {
              flex-direction: row;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <TextInput
              name='doctorName'
              value={doctorName}
              setValue={setDoctorName}
              label="Doctor's Name"
              fieldErrors={fieldErrors}
              customError={customError}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              flex: 1;
            `}
          >
            <PhoneInput
              name='doctorPhone'
              value={doctorPhone}
              onChange={setDoctorPhone}
              label="Doctor's Phone Number"
              type='tel'
              fieldErrors={fieldErrors}
              customError={customError}
              error={customError?.doctorPhone}
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            flex: 1;
          `}
        >
          <TextAreaInput
            name='medicalConditions'
            value={medicalConditions}
            setValue={setMedicalConditions}
            label='Medical Conditions'
            fieldErrors={fieldErrors}
            customError={customError}
            rows={2}
          />
        </div>
      </div>

      <Divider />

      {/* Preferences Section */}
      <Text
        variant='captionLarge'
        weight='semiBold'
        color={colors.NEUTRAL_0}
        style={'align-self: flex-start;'}
      >
        Preferences
      </Text>

      {isCanlan ? (
        <>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              width: 100%;
              @media (min-width: 768px) {
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                flex: 2;
                @media (min-width: 768px) {
                  flex-direction: row;
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <TextAreaInput
                  name='notes'
                  value={notes}
                  setValue={setNotes}
                  label='Notes'
                  fieldErrors={fieldErrors}
                  customError={customError}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              width: 100%;
              @media (min-width: 768px) {
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                flex: 2;
                @media (min-width: 768px) {
                  flex-direction: row;
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <TextInput
                  name='jerseyNumber'
                  value={jerseyNumber}
                  setValue={setJerseyNumber}
                  label='Preferred Jersey Number'
                  type='number'
                  fieldErrors={fieldErrors}
                  customError={customError}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <SelectInput
                  name='dominantSide'
                  value={dominantSide}
                  onChange={setDominantSide}
                  label='Dominant Side'
                  options={[
                    { value: 'left', label: 'Left' },
                    { value: 'right', label: 'Right' },
                  ]}
                  disabled={false}
                  fieldErrors={fieldErrors}
                  customError={customError}
                />
              </div>
            </div>
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              width: 100%;
              @media (min-width: 768px) {
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                flex: 2;
                @media (min-width: 768px) {
                  flex-direction: row;
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <SelectInput
                  name='tshirtSize'
                  value={tshirtSize}
                  onChange={setTshirtSize}
                  label='T-Shirt Size'
                  options={tshirtSizes}
                  disabled={isCanlan}
                  fieldErrors={fieldErrors}
                  customError={customError}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <SelectInput
                  name='shortSize'
                  value={shortSize}
                  onChange={setShortSize}
                  label='Short Size'
                  options={shortSizes}
                  disabled={isCanlan}
                  fieldErrors={fieldErrors}
                  customError={customError}
                />
              </div>
            </div>
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              width: 100%;
              @media (min-width: 768px) {
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                flex: 2;
                @media (min-width: 768px) {
                  flex-direction: row;
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <TextInput
                  name='otherMembershipNumber'
                  value={otherMembershipNumber}
                  setValue={setOtherMembershipNumber}
                  label='Governing Body Member ID'
                  placeholder='eg: Hockey USA, Soccer USA, or USA Waterpolo'
                  fieldErrors={fieldErrors}
                  customError={customError}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              />
            </div>
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              width: 100%;
              @media (min-width: 768px) {
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                flex: 2;
                @media (min-width: 768px) {
                  flex-direction: row;
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex: 1;
                `}
              >
                <TextAreaInput
                  name='notes'
                  value={notes}
                  setValue={setNotes}
                  label='Notes'
                  fieldErrors={fieldErrors}
                  customError={customError}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default memo(PlayerForm)
