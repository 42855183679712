import { computed, makeObservable, observable, action, runInAction } from 'mobx'
import { RootStore } from '..'

import req from '@sportninja/common/api/request'
import { MeService } from './service'
import BannerController from 'src/components/Banner/BannerController'
import Banner from 'src/components/Banner'
import type { Plan } from '../subscription'

const SESSION_TOKEN = 'session_token'

type SubscriptionUsage = {
  total_usage: number
}

type SubscriptionStripe = {
  currency: string
  amount: number
  interval: string
  interval_count: number
  trial_period_days: null | number | string
  usage_type: string
  current_period_end: string
  current_period_start: string
  status: string
  plan_type: string
}

type PaymentMethod = {
  id: string
  card_type: string
  last_four: string
  exp_month: number
  exp_year: number
  type: string
  default: null | any
}

type Coupon = {
  id: string
  name: string
  percent_off: number
  duration: string
  duration_in_months: number
  max_redemptions: number | null
  times_redeemed: number
  valid: boolean
}

type SubscriptionUpcomingInvoice = {
  amount_due: number
  currency: string
  period_start: string
  period_end: string
  subtotal: number
  total: number
  total_discount_amounts: number
  coupon: Coupon | null
  plan_type: string
}

type Tax = {
  id: string
  name: string
  description: string | null
  tax_rate: string
  currency: {
    id: string
    name: string
    name_full: string | null
    abbreviation: string | null
  }
}

export type Account = {
  uid: string
  full_name: string
  title: string
  email: string
  company_name: string
  phone_number: string
  address_street: string
  address_country: string
  address_state_province: string
  address_city: string
  address_postal_code: string
  subscription_type: Plan
  subscription_usage: SubscriptionUsage | null
  subscription: SubscriptionStripe | null
  payment_method: PaymentMethod
  subscription_upcoming_invoice: SubscriptionUpcomingInvoice
  participants: number
  organization?: {
    image: string
    name: string
    url: string
  }
  taxes: Tax[]
  is_daysmart_connected: boolean
}

export type AccountType = 0 | 1 | 2

export type Me = {
  id: string
  email: string
  created_at: string
  updated_at: string
  validated_at: string | null
  name_first: string
  name_middle: string | null
  name_last: string
  is_public: boolean
  is_visible: boolean
  full_name: string | null
  birth_date: string | null
  height: string | null
  weight: string | null
  gender: string | null
  website: string | null
  phone: string | null
  invitation_id: string | null
  is_validated: boolean
  role_type_id: boolean
  is_accepted: boolean
  is_invited: boolean
  description: string
  account_id: string
  account_type: number
  image?: {
    id: string
    filename: string
    full_path: string
    mime_type: string
    width: number
    height: number
  }
}

export class MeStore {
  rootStore: RootStore
  me: Me | null = null
  account: Account | null = null
  connectedAccount: boolean = false
  registerSuccess: boolean = false

  loading = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore

    const existingToken = localStorage.getItem(SESSION_TOKEN)

    if (existingToken) {
      this.fetchUser()
    }

    makeObservable(this, {
      me: observable,
      account: observable,
      loading: observable,
      connectedAccount: observable,
      registerSuccess: observable,
      updateAccount: action,
      clearUser: action,
      fetchAccount: action,
      setConnectedAccount: action,
      fetchMe: action,
      state: computed,
    })
  }

  private fetchUser() {
    req('/users/me')
      .then((response: { data: Me }) => {
        runInAction(() => {
          this.me = response.data
        })

        this.fetchAccount(response.data?.account_id)
        this.rootStore.payment.setAccountId(response.data?.account_id)
      })
      .catch((error: unknown) => {
        console.error('Error on Fetching User', error)
        runInAction(() => {
          this.me = null
        })
      })
      .finally(() => {
        runInAction(() => {
          if (this.rootStore.subscription.plans === null) {
            this.rootStore.subscription.getPlans()
          }
        })
      })
  }

  clearUser = () => {
    runInAction(() => {
      this.me = null
      this.account = null
    })
  }

  fetchMe = (silent?: boolean) => {
    runInAction(() => {
      this.loading = silent ?? true
      this.fetchUser()
    })
  }

  setRegisterSuccess = (success: boolean) => {
    runInAction(() => {
      this.registerSuccess = success
    })
  }

  fetchAccount = (id?: string) => {
    if (!id) {
      return
    }

    req(`/account/${id}`)
      .then((response: { data: Account }) => {
        runInAction(() => {
          this.account = response.data
        })
      })
      .catch((error: unknown) => {
        console.error('Error on Fetching Account', error)
        runInAction(() => {
          this.account = null
        })
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false
        })
      })
  }

  setConnectedAccount = (connected: boolean) => {
    runInAction(() => {
      this.connectedAccount = connected
    })
  }

  updateAccount = (
    data: Omit<
      Account,
      | 'uid'
      | 'subscription_type'
      | 'subscription'
      | 'subscription_usage'
      | 'payment_method'
      | 'subscription_upcoming_invoice'
      | 'participants'
      | 'taxes'
    >
  ) => {
    runInAction(() => {
      this.loading = true
    })

    if (this.me === null) {
      this.fetchMe()

      return BannerController.add(({ ...props }) => (
        <Banner
          duration={undefined}
          onClose={undefined}
          onClick={undefined}
          subChildren={undefined}
          {...props}
          type='failed'
        >
          {'An error happened (ERR: 1)'}
        </Banner>
      ))
    }

    if (this?.account === null) {
      this.fetchAccount(this.me?.account_id)

      return BannerController.add(({ ...props }) => (
        <Banner
          duration={undefined}
          onClose={undefined}
          onClick={undefined}
          subChildren={undefined}
          {...props}
          type='failed'
        >
          {'An error happened (ERR: 2)'}
        </Banner>
      ))
    }

    MeService.updateAccount(this.account.uid, data)
      .then((response) => {
        runInAction(() => {
          this.account = response
        })
      })
      .catch((e) => {
        BannerController.add(({ ...props }) => (
          <Banner
            duration={undefined}
            onClose={undefined}
            onClick={undefined}
            subChildren={undefined}
            {...props}
            type='failed'
          >
            {e.message || 'An error happened'}
          </Banner>
        ))
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false
        })
      })
  }

  get state() {
    return {
      me: this.me,
      account: this.account,
      loading: this.loading,
      connectedAccount: this.connectedAccount,
      registerSuccess: this.registerSuccess,
    }
  }
}
