/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import colors from '@sportninja/common/constants/appColors'
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import React, { useEffect, useRef, useState } from 'react'
import Text from 'src/components/ts-components/Text'

type Waiver = {
  id: number
  name: string
  content: string
}

type Props = {
  waivers: Waiver[]
  onAllWaiversAgreed: (agreed: boolean) => void
  allWaiversAgreed: boolean
}

const WaiversAgreement: React.FC<Props> = ({
  waivers,
  onAllWaiversAgreed,
  allWaiversAgreed,
}) => {
  const [agreedWaivers, setAgreedWaivers] = useState<{
    [key: number]: boolean
  }>({})
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState<{
    [key: number]: boolean
  }>({})
  const waiverRefs = useRef<{ [key: number]: HTMLDivElement | null }>({})

  useEffect(() => {
    if (
      allWaiversAgreed &&
      waivers?.length > 0 &&
      Object.keys(agreedWaivers).length === 0
    ) {
      setAgreedWaivers(
        waivers.reduce((acc, _, index) => ({ ...acc, [index]: true }), {})
      )
      setHasScrolledToBottom(
        waivers.reduce((acc, _, index) => ({ ...acc, [index]: true }), {})
      )
    }
  }, [agreedWaivers, allWaiversAgreed, waivers])

  const handleScroll =
    (index: number) => (e: React.UIEvent<HTMLDivElement>) => {
      const element = e.target as HTMLDivElement
      const isBottom =
        Math.abs(
          element.scrollHeight - element.scrollTop - element.clientHeight
        ) < 1

      if (isBottom) {
        setHasScrolledToBottom((prev) => ({ ...prev, [index]: true }))
      }
    }

  const handleCheckboxChange = (index: number) => (e: CheckboxChangeEvent) => {
    setAgreedWaivers((prev) => ({ ...prev, [index]: e.target.checked }))
  }

  useEffect(() => {
    // Check if content is smaller than container for each waiver
    waivers.forEach((_, index) => {
      const element = waiverRefs.current[index]
      if (element && element.scrollHeight <= element.clientHeight) {
        setHasScrolledToBottom((prev) => ({ ...prev, [index]: true }))
      }
    })
  }, [waivers])

  useEffect(() => {
    const allAgreed =
      Object.keys(agreedWaivers).length === waivers.length &&
      Object.values(agreedWaivers).every((agreed) => agreed)
    onAllWaiversAgreed(allAgreed)
  }, [agreedWaivers, waivers.length, onAllWaiversAgreed])

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 24px;
      `}
    >
      {waivers.map((waiver, index) => {
        return (
          <div
            key={waiver.id}
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
              border-bottom: 1px solid ${colors.NEUTRAL_600};
              padding-bottom: 16px;
            `}
          >
            <Text
              variant='paragraphSmall'
              weight='regular'
              color={colors.NEUTRAL_50}
            >
              {waiver.name}
            </Text>

            <div
              ref={(el) => (waiverRefs.current[index] = el)}
              onScroll={handleScroll(index)}
              css={css`
                border: 1px solid ${colors.NEUTRAL_100};
                border-radius: 4px;
                padding: 8px 16px;
                background-color: ${colors.NEUTRAL_600};
                max-height: 400px;
                overflow-y: auto;
              `}
            >
              <div
                css={css`
                  font-size: 14px;
                  line-height: 1.5;
                  font-weight: 400;
                  letter-spacing: 0px;
                  color: ${colors.NEUTRAL_0};
                `}
                dangerouslySetInnerHTML={{
                  __html: waiver.content?.replace(/\n/g, '<br />'),
                }}
              />
            </div>

            <Checkbox
              disabled={!hasScrolledToBottom[index]}
              checked={agreedWaivers[index]}
              onChange={handleCheckboxChange(index)}
            >
              <Text variant='paragraphSmall'>
                I Have Read and Agree to the Terms Above.
              </Text>
            </Checkbox>
          </div>
        )
      })}
    </div>
  )
}

export default WaiversAgreement
