/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { t } from '@sportninja/common/i18n'
import React, { useCallback } from 'react'
import FormSheet from 'src/components/Form/FormSheet'
import {
  orgForm,
  scheduleForm,
  teamForm,
} from '../../../components/Form/form-configs'
import colors from '@sportninja/common/constants/appColors'

const MySVG = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='205'
      height='200'
      viewBox='0 0 205 200'
      fill='none'
    >
      <g clipPath='url(#clip0_4283_1101)'>
        <path
          d='M130.128 44.9794H74.9186C73.6604 44.9809 72.4543 45.4814 71.5647 46.371C70.675 47.2606 70.1746 48.4668 70.173 49.7249V172.429L69.5403 172.622L55.9965 176.769C55.3547 176.965 54.6614 176.898 54.0688 176.583C53.4763 176.268 53.033 175.731 52.8361 175.089L12.5496 43.4956C12.3536 42.8537 12.4204 42.1603 12.7354 41.5676C13.0504 40.975 13.5879 40.5317 14.2296 40.3351L35.1005 33.9444L95.6059 15.4242L116.477 9.03353C116.794 8.93578 117.128 8.90162 117.459 8.93301C117.79 8.9644 118.111 9.06072 118.405 9.21646C118.698 9.37221 118.958 9.58431 119.17 9.84064C119.381 10.097 119.54 10.3925 119.637 10.7103L129.935 44.3466L130.128 44.9794Z'
          fill='#F2F2F2'
        />
        <path
          d='M142.178 44.3502L129.766 3.81071C129.56 3.13525 129.222 2.50709 128.773 1.96214C128.324 1.41718 127.771 0.966117 127.148 0.634707C126.524 0.303298 125.841 0.0980389 125.138 0.0306686C124.435 -0.0367018 123.725 0.0351363 123.05 0.24207L93.7068 9.22378L33.2047 27.7472L3.86154 36.7321C2.49841 37.1506 1.35705 38.0929 0.687971 39.3522C0.018897 40.6114 -0.123224 42.0847 0.292811 43.4486L42.7115 181.996C43.0494 183.097 43.7314 184.061 44.6572 184.746C45.583 185.431 46.704 185.801 47.8557 185.802C48.3887 185.802 48.9187 185.722 49.428 185.565L69.5427 179.408L70.1754 179.212V178.551L69.5427 178.744L49.2414 184.96C48.0381 185.327 46.7386 185.202 45.6277 184.612C44.5169 184.021 43.6854 183.015 43.3158 181.812L0.900367 43.2619C0.717218 42.6662 0.653386 42.0401 0.712524 41.4197C0.771661 40.7992 0.952601 40.1965 1.24499 39.6461C1.53739 39.0957 1.93549 38.6083 2.4165 38.212C2.8975 37.8157 3.45196 37.5181 4.04814 37.3363L33.3913 28.3514L93.8936 9.83121L123.237 0.846341C123.689 0.70834 124.159 0.637977 124.632 0.637536C125.646 0.639814 126.634 0.966926 127.449 1.57096C128.264 2.17499 128.865 3.0242 129.162 3.99421L141.516 44.3502L141.713 44.983H142.371L142.178 44.3502Z'
          fill='#3F3D56'
        />
        <path
          d='M38.8665 40.4385C38.2567 40.438 37.6631 40.2422 37.1728 39.8797C36.6825 39.5172 36.3213 39.0071 36.1421 38.4243L32.0672 25.1141C31.9577 24.7565 31.9197 24.3809 31.9554 24.0087C31.9911 23.6365 32.0997 23.2749 32.2751 22.9447C32.4506 22.6145 32.6893 22.3221 32.9778 22.0841C33.2662 21.8462 33.5987 21.6674 33.9563 21.558L89.6178 4.51721C90.3399 4.29687 91.1199 4.37202 91.7866 4.72616C92.4533 5.0803 92.9523 5.6845 93.174 6.40615L97.249 19.7165C97.4692 20.4386 97.3939 21.2185 97.0398 21.8852C96.6857 22.5519 96.0816 23.0509 95.36 23.2727L39.6983 40.3135C39.4288 40.3962 39.1484 40.4383 38.8665 40.4385Z'
          fill='#F2B400'
        />
        <path
          d='M60.2129 14.2222C63.7074 14.2222 66.5402 11.3894 66.5402 7.89487C66.5402 4.40036 63.7074 1.5675 60.2129 1.5675C56.7184 1.5675 53.8855 4.40036 53.8855 7.89487C53.8855 11.3894 56.7184 14.2222 60.2129 14.2222Z'
          fill='#F2B400'
        />
        <path
          d='M60.2127 11.9016C62.4256 11.9016 64.2194 10.1077 64.2194 7.89487C64.2194 5.68204 62.4256 3.88818 60.2127 3.88818C57.9999 3.88818 56.2061 5.68204 56.2061 7.89487C56.2061 10.1077 57.9999 11.9016 60.2127 11.9016Z'
          fill='white'
        />
        <path
          d='M190.709 184.182H83.7768C83.0638 184.181 82.3803 183.897 81.8762 183.393C81.372 182.889 81.0885 182.205 81.0876 181.493V53.3631C81.0884 52.6501 81.372 51.9666 81.8762 51.4625C82.3803 50.9583 83.0638 50.6748 83.7768 50.674H190.709C191.422 50.6748 192.106 50.9584 192.61 51.4625C193.114 51.9666 193.398 52.6502 193.398 53.3631V181.493C193.398 182.205 193.114 182.889 192.61 183.393C192.106 183.897 191.422 184.181 190.709 184.182Z'
          fill='#E6E6E6'
        />
        <path
          d='M141.514 44.3466H74.9183C73.4925 44.3486 72.1257 44.9159 71.1175 45.9241C70.1093 46.9323 69.5421 48.2991 69.54 49.7249V178.74L70.1728 178.547V49.7249C70.1743 48.4667 70.6748 47.2606 71.5644 46.371C72.454 45.4813 73.6602 44.9809 74.9183 44.9793H141.71L141.514 44.3466ZM199.567 44.3466H74.9183C73.4925 44.3486 72.1257 44.9159 71.1175 45.9241C70.1093 46.9323 69.5421 48.2991 69.54 49.7249V194.622C69.5421 196.048 70.1093 197.414 71.1175 198.423C72.1257 199.431 73.4925 199.998 74.9183 200H199.567C200.993 199.998 202.36 199.431 203.368 198.423C204.376 197.414 204.944 196.048 204.946 194.622V49.7249C204.944 48.2991 204.376 46.9323 203.368 45.9241C202.36 44.9159 200.993 44.3486 199.567 44.3466ZM204.313 194.622C204.311 195.88 203.811 197.086 202.921 197.976C202.032 198.865 200.826 199.366 199.567 199.367H74.9183C73.6602 199.366 72.454 198.865 71.5644 197.976C70.6748 197.086 70.1743 195.88 70.1728 194.622V49.7249C70.1743 48.4667 70.6748 47.2606 71.5644 46.371C72.454 45.4813 73.6602 44.9809 74.9183 44.9793H199.567C200.826 44.9809 202.032 45.4813 202.921 46.371C203.811 47.2606 204.311 48.4667 204.313 49.7249V194.622Z'
          fill='#3F3D56'
        />
        <path
          d='M166.349 58.2668H108.137C107.382 58.2659 106.658 57.9657 106.125 57.4319C105.591 56.8981 105.291 56.1744 105.29 55.4195V41.4993C105.291 40.7444 105.591 40.0206 106.125 39.4868C106.658 38.9531 107.382 38.6528 108.137 38.6519H166.349C167.104 38.6528 167.828 38.9531 168.361 39.4868C168.895 40.0206 169.195 40.7444 169.196 41.4993V55.4195C169.195 56.1744 168.895 56.8981 168.361 57.4319C167.828 57.9657 167.104 58.2659 166.349 58.2668Z'
          fill='#F2B400'
        />
        <path
          d='M137.243 39.6011C140.737 39.6011 143.57 36.7682 143.57 33.2737C143.57 29.7792 140.737 26.9464 137.243 26.9464C133.748 26.9464 130.916 29.7792 130.916 33.2737C130.916 36.7682 133.748 39.6011 137.243 39.6011Z'
          fill='#F2B400'
        />
        <path
          d='M137.243 37.1277C139.371 37.1277 141.097 35.4022 141.097 33.2737C141.097 31.1452 139.371 29.4198 137.243 29.4198C135.114 29.4198 133.389 31.1452 133.389 33.2737C133.389 35.4022 135.114 37.1277 137.243 37.1277Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_4283_1101'>
          <rect
            width='204.892'
            height='200'
            fill='white'
            transform='translate(0.0541992)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

const EmptyOrg = ({
  IS_PUBLIC = false,
  syncSalesforce = false,
  hockeySport = null,
  onAddSchedule = () => {},
  onAddOrg = () => {},
  onAddTeam = () => {},
  permission = null,
  sportOptions = [],
  sports = [],
}) => {
  const renderTeamButton = useCallback(
    ({ onClick }) => (
      <a style={{ color: colors.AMERICAN_YELLOW }} onClick={onClick}>
        Teams
      </a>
    ),
    []
  )

  const renderScheduleButton = useCallback(
    ({ onClick }) => (
      <a style={{ color: colors.AMERICAN_YELLOW }} onClick={onClick}>
        Competition
      </a>
    ),
    []
  )

  const renderOrgButton = useCallback(
    () => (
      <a style={{ color: colors.AMERICAN_YELLOW }} onClick={onAddOrg}>
        Organizations
      </a>
    ),
    []
  )

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 32,
        gap: 12,
      }}
    >
      <style>
        {`
          h1 {
            font-size: 32px;
            font-weight: 600;
            line-height: 100%;
            color: #CFD1D4;
            margin-top: 16px;
            margin-bottom: 8px;
          }
          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            color: #CFD1D4;
          }
          a {
            color: ${colors.PRIMARY};
            cursor: pointer;
          }
        `}
      </style>
      <MySVG />
      <h1 id='organization-empty'>YOUR ORGANIZATION IS EMPTY</h1>
      <p>
        You can get started by creating a new{' '}
        <span
          css={css`
            color: ${colors.PRIMARY};
            cursor: pointer;
          `}
          onClick={onAddSchedule}
        >
          Competition
        </span>{' '}
        {/* <FormSheet
          key='add-schedule'
          Button={renderScheduleButton}
          form={scheduleForm(
            { isParentPublic: IS_PUBLIC },
            true,
            syncSalesforce,
            sportOptions ? sportOptions[0] : null,
            false,
            false,
            sportOptions,
            sports ? sports[0].id : null,
            sports
          )}
          label={t('Web:createCompetition')}
          onSubmit={(...args) => {
            onAddSchedule(...args).then(() => {
              window.location.reload()
            })
          }}
          title={t('Web:createANewCompetition')}
        />{' '} */}
        and adding Teams to it in the Setup tab.
        {/* <a
          css={css``}
          target='_blank'
          rel='noreferrer'
        >
          adding Teams to it in the Setup tab.
        </a> */}
      </p>
      {/* Removing this for now. */}
      {/* <div
        style={{
          width: 620,
          textAlign: 'left',
        }}
      >
        <p
          style={{
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '150%',
            color: colors.ATTENDANCE_GRAY,
          }}
        >
          Organizations are used to organize different sports, leagues, or other
          organizational hierarchies. Create your teams/rosters in the desired
          Organization. Competitions are where the games are scored/played, so
          be sure to create one to start scoring.
        </p>
      </div> */}
    </div>
  )
}

export default EmptyOrg
