import React from 'react'
import PropTypes from 'prop-types'

import { getSelfUser, getManagedUsers } from '../../selectors/users'
import { bindActionToPromise } from '../../actions/utils'
// import userActions from '../../actions/user'
import inviteActions from '../../actions/invite'
import usersActions from '../../actions/users'

import * as utils from './utils'
import {
  getAllPlayerTypes,
  getAllTeamOfficialTypes,
  getSports,
} from '../../selectors/types'

const withInvite = (WrappedComponent) => {
  class WithInvite extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        accepted: false,
        acceptedNames: { first: false, last: false },
        error: false,
        data: null,
        loading: { page: true, accept: false },
        invite: null,
        acceptedFormat: null,
        show_underage_workflow: false,
      }

      const { match, route } = this.props

      this.INVITE_ID =
        (route && route.params && route.params.inviteId) ||
        (match && match.params && match.params.inviteId)
    }

    setup = async () => {
      try {
        const data = await utils.onInviteMount(
          this.INVITE_ID,
          this.props.readInvite
          // this.props.readManagedUsers
        )
        const urlParams = new URLSearchParams(window?.location?.search)
        const acceptedFormat = urlParams?.get('accepted_format')
        this.setState({
          accepted: false,
          error: false,
          data,
          invite: data,
          acceptedFormat,
          show_underage_workflow: data?.show_underage_workflow,
          ask_position: data?.ask_position,
          player_id: data?.player_id,
          team_official_id: data?.team_official_id,
        })
      } catch (e) {
        this.setState({
          accepted: true,
          error: true,
          data: e?.invite?.data,
          invite: e?.invite,
          acceptedFormat: e?.invite?.accepted_format,
          show_underage_workflow: e?.invite?.show_underage_workflow,
          ask_position: e?.invite?.ask_position,
          player_id: e?.invite?.player_id,
          team_official_id: e?.invite?.team_official_id,
        })
        this.setError(e.message, e.title, e?.invite)
      } finally {
        this.setLoading('page', false)
      }
    }

    componentDidMount() {
      this.setup()
    }

    async componentDidUpdate(prevProps) {
      if (
        (this.props.match && this.props.match.params.inviteId) !==
        (prevProps.match && prevProps.match.params.inviteId)
      ) {
        this.INVITE_ID = this.props.match.params.inviteId
        this.setLoading('page', true)
        this.setup()
      }
    }

    setError = (message, title, invite = null) =>
      this.setState({ error: { message, title, invite } })

    setLoading = (type, state) =>
      this.setState((prevState) => ({
        loading: { ...prevState.loading, [type]: state },
      }))

    /**
     * Handler for accepting an invite with yourself, or an existing managed person
     *
     * 1. Accept invite with:
     * 1a. your own person ID or,
     * 1b. an existing managed person's person ID
     */
    onConfirm = async (userId, selectedPositionId) => {
      this.setLoading('accept', true)
      const isPlayer = this.state.data?.player_id
      const isTeamOfficial = this.state.data?.team_official_id
      try {
        await this.props.acceptInvite(
          this.INVITE_ID,
          userId,
          this.state.acceptedFormat,
          selectedPositionId,
          isPlayer ? 'PLAYER' : isTeamOfficial ? 'TEAM_OFFICIAL' : 'OTHER',
          this.state.ask_position
        )
        this.setState({ accepted: true })
      } catch (e) {
        if (e.status === 409) {
          this.setError(
            'It appears you might already be logged in under a different email address. Please log out from all locations before accepting this invite.',
            'Oops!'
          )
        } else {
          this.setError(e.message)
        }
      } finally {
        this.setLoading('accept', false)
      }
    }

    /**
     * @deprecated
     * Handler for accepting an invite with a brand new managed person
     *
     * 1. Accept invite with no `user_id` supplied
     * 2. Using the person ID from the invite, update it with first and last name
     */
    onCreate = async (form) => {
      const { invitee } = utils.getInviteObject(this.state.data)

      this.setLoading('accept', true)

      try {
        await this.props.acceptInvite(this.INVITE_ID)
        await this.props.updateUser(invitee.id, form)
        this.setState({
          accepted: true,
          acceptedNames: { first: form.name_first, last: form.name_last },
        })
      } catch (e) {
        this.setError(e.message)
      } finally {
        this.setLoading('accept', false)
      }
    }

    render() {
      const { accepted, acceptedNames, data, error, loading } = this.state
      const {
        playerTypes,
        teamOfficialTypes,
        sports,
        managedUsers,
        selfUser = {},
        ...rest
      } = this.props

      const {
        invitee,
        inviter,
        message,
        roleTypeId,
        sport_id,
        player_id,
        ask_position,
        team_official_id,
        team_official_type_id,
        player_type_id,
        destination,
      } = utils.getInviteObject(data)

      const { fullName: inviterName = '' } = inviter
      const removeTheFromName = inviterName.replace(/$the[/s]/i, '')

      const isInviteForSelf = invitee.fullName === selfUser.fullName
      const existingUserIdx = !managedUsers
        ? -1
        : managedUsers.findIndex(
            (user) =>
              invitee.nameFirst === user.nameFirst &&
              invitee.nameLast === user.nameLast
          )

      const isNewInvite = !isInviteForSelf && existingUserIdx < 0

      return (
        <WrappedComponent
          accepted={accepted}
          acceptedNames={acceptedNames}
          error={error}
          existingUserIdx={existingUserIdx}
          invitee={invitee}
          inviter={inviter}
          inviterName={removeTheFromName}
          isInviteForSelf={isInviteForSelf}
          isNewInvite={isNewInvite}
          loading={loading}
          managedUsers={managedUsers}
          message={message}
          onConfirm={this.onConfirm}
          onCreate={this.onCreate}
          roleTypeId={roleTypeId}
          self={selfUser}
          shouldDisplayWaiver={data?.show_waiver}
          waiverData={data?.waiver_content}
          invite={this.state.invite}
          acceptedFormat={this.state.acceptedFormat}
          show_underage_workflow={this.state.show_underage_workflow}
          playerTypes={playerTypes}
          teamOfficialTypes={teamOfficialTypes}
          sports={sports}
          sport_id={sport_id}
          player_id={player_id}
          ask_position={ask_position}
          team_official_id={team_official_id}
          team_official_type_id={team_official_type_id}
          player_type_id={player_type_id}
          destination={destination}
          {...rest}
        />
      )
    }
  }
  return WithInvite
}

export const inviteStateProps = (state) => {
  return {
    sports: getSports(state),
    teamOfficialTypes: getAllTeamOfficialTypes(state),
    playerTypes: getAllPlayerTypes(state),
    managedUsers: getManagedUsers(state),
    selfUser: getSelfUser(state),
  }
}

export const inviteDispatchProps = (dispatch) => {
  return {
    acceptInvite: bindActionToPromise(dispatch, inviteActions.accept.request),
    readInvite: bindActionToPromise(dispatch, inviteActions.read.request),

    // readManagedUsers: bindActionToPromise(dispatch, userActions.readManagedUsers.request),
    updateUser: bindActionToPromise(dispatch, usersActions.update.request),
  }
}

export default withInvite
