import { createImage, createButton, createDiv } from '../helpers/DriverJsHelper'
import sportNinjaLogo from '../pages/Home/sportninja_logo_full.png'
import demoLeagueLogo from '../pages/Home/demo_league.png'
import newLeagueLogo from '../pages/Home/new_league.png'
import { setTutorial } from '@sportninja/common/utils/TutorialHelper'
import { tutorialKeys } from '@sportninja/common/constants/userGuiding'

export const getHomeScreenSteps = (driverObj, leagueId) => {
  const homeScreenSteps = [
    {
      popover: {
        title: 'Welcome to SportNinja',
        description:
          'We are the ultimate hub for real-time Digital Scoring and Streaming. Enjoy live updates on web and mobile for players, league officials, and fans.',
        showButtons: ['next', 'close'],
        onPopoverRender: (popover, { config, state }) => {
          const image = createImage(sportNinjaLogo, 'SportNinja Logo', {
            marginTop: '16px',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            width: '300px',
          })
          popover.wrapper.insertBefore(image, popover.wrapper.firstChild)
        },
      },
    },
    {
      popover: {
        title: 'How Would You Like to Start?',
        description: ' ',
        showButtons: ['close'],
        onPopoverRender: (popover, { config, state }) => {
          const container = createDiv({
            display: 'flex',
            justifyContent: 'space-between',
            gap: '16px',
            marginTop: '16px',
          })

          const firstOption = createDiv({
            width: '200px',
          })
          const firstImage = createImage(demoLeagueLogo, 'Product Tour', {
            width: '150px',
            marginBottom: '16px',
          })
          const firstButton = createButton('Product Tour', {
            width: '200px',
            height: '50px',
          })
          firstOption.appendChild(firstImage)
          firstOption.appendChild(firstButton)
          container.appendChild(firstOption)
          const secondOption = createDiv({
            width: '200px',
          })
          const secondImage = createImage(newLeagueLogo, 'Create your league', {
            width: '150px',
            marginBottom: '16px',
          })
          const secondButton = createButton('Create Your League', {
            width: '200px',
            height: '50px',
          })
          secondOption.appendChild(secondImage)
          secondOption.appendChild(secondButton)
          container.appendChild(secondOption)

          popover.description.appendChild(container)

          firstButton.addEventListener('click', () => {
            driverObj.moveNext()
          })

          secondButton.addEventListener('click', () => {
            driverObj.drive(4)
          })
        },
      },
    },
    {
      popover: {
        showButtons: ['close', 'next'],
        title: "Let's Start Your Tour",
        description:
          "Let's kick things off with a Sandbox League to explore all the exciting possibilities with SportNinja.",
      },
    },
    {
      element: '#soccer-sandbox-leagues',
      popover: {
        showButtons: ['close'],
        description: ' ',
        side: 'right',
        align: 'start',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText = 'Click on the\nSoccer Demo League'
          const description = createDiv({
            maxWidth: '300px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'left',
          })
          description.innerText = descriptionText
          popover.description.appendChild(description)
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_1, true)
          const driverActiveElement = document.querySelector(
            '.driver-active-element'
          )
          if (driverActiveElement) {
            driverActiveElement.style.border = '2px solid #F2B400'
            driverActiveElement.style.borderRadius = '8px'
          }
          const soccerSandboxLeagues = document.querySelector(
            '#soccer-sandbox-leagues'
          )
          if (soccerSandboxLeagues) {
            soccerSandboxLeagues.addEventListener('click', () => {
              if (driverActiveElement) {
                driverActiveElement.style.border = ''
                driverActiveElement.style.borderRadius = ''
              }
            })
          }
        },
      },
    },
    {
      element: '.tutorial-your-league',
      popover: {
        showButtons: ['close'],
        description: ' ',
        side: 'right',
        align: 'start',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText =
            'You can always find access to your League\nfrom this section on the home screen.\nClick on your Organization to begin.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'left',
            marginTop: '16px',
          })
          description.innerText = descriptionText
          popover.description.appendChild(description)
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, true)
          const driverActiveElement = document.querySelector(
            '.driver-active-element'
          )
          if (driverActiveElement) {
            driverActiveElement.style.border = '2px solid #F2B400'
            driverActiveElement.style.borderRadius = '8px'
          }
          const soccerSandboxLeagues = document.querySelector(
            '.tutorial-your-league'
          )
          if (soccerSandboxLeagues) {
            soccerSandboxLeagues.addEventListener('click', () => {
              if (driverActiveElement) {
                driverActiveElement.style.border = ''
                driverActiveElement.style.borderRadius = ''
              }
            })
          }
        },
      },
    },
  ]
  return homeScreenSteps
}

export const getOrgScreenSteps = (driverObj) => {
  const orgScreenSteps = [
    {
      element: '#sandbox-title',
      popover: {
        title: '🎉 Success!',
        description:
          "Your Sandbox League has been created. Let's see how things work in SportNinja",
        showButtons: ['close', 'next'],
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#sandbox-tabs',
      popover: {
        description: ' ',
        showButtons: ['close', 'next'],
        side: 'bottom',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText =
            'Here you will find tabs to access your Competitions, \nTeams, Announcements, Reports, Officials, and Users.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'left',
            marginTop: '16px',
          })
          description.innerText = descriptionText
          popover.description.appendChild(description)
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#sandbox-schedule-name',
      popover: {
        description: ' ',
        showButtons: ['close'],
        side: 'right',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText =
            'Competitions are where all games are played and scored.\nClick on this Competition to see the Schedule, Standings, Stats and more.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText
          popover.description.appendChild(description)
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_2, true)
          // Finishes the first step of the tutorial
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_1, false)
          const driverActiveElement = document.querySelector(
            '.driver-active-element'
          )
          if (driverActiveElement) {
            driverActiveElement.style.border = '2px solid #F2B400'
            driverActiveElement.style.borderRadius = '8px'
          }
          const soccerSandboxLeagues = document.querySelector(
            '#sandbox-schedule-name'
          )
          if (soccerSandboxLeagues) {
            soccerSandboxLeagues.addEventListener('click', () => {
              if (driverActiveElement) {
                driverActiveElement.style.border = ''
                driverActiveElement.style.borderRadius = ''
              }
            })
          }
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
  ]
  return orgScreenSteps
}

export const getScheduleScreenSteps = (driverObj) => {
  const scheduleScreenSteps = [
    {
      element: '#sandbox-tabs-schedule',
      popover: {
        description: ' ',
        showButtons: ['close', 'next'],
        side: 'bottom',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText =
            'Here you will find tabs to access the Schedule, Standings, Stats, Suspensions and much more...'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText
          popover.description.appendChild(description)
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_2, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#sandbox-tabs-id-standings',
      popover: {
        description: ' ',
        showButtons: ['close'],
        side: 'bottom',
        onPopoverRender: (popover, { config, state }) => {
          popover.wrapper.style.maxWidth = '300px'
          const descriptionText =
            'Check the "Standings" tab to see how the teams are ranked.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'left',
            marginTop: '16px',
          })
          description.innerText = descriptionText
          popover.description.appendChild(description)

          const driverActiveElement = document.querySelector(
            '.driver-active-element'
          )
          if (driverActiveElement) {
            driverActiveElement.style.border = '2px solid #F2B400'
            driverActiveElement.style.borderRadius = '8px'
            driverActiveElement.style.paddingLeft = '4px'
            driverActiveElement.style.paddingRight = '4px'
            driverActiveElement.style.paddingTop = '4px'
            driverActiveElement.style.paddingBottom = '4px'
          }
          const button = document.getElementById('sandbox-tabs-id-standings')
          button.addEventListener('click', () => {
            if (driverActiveElement) {
              driverActiveElement.style.border = ''
              driverActiveElement.style.borderRadius = ''
              driverActiveElement.style.paddingLeft = ''
              driverActiveElement.style.paddingRight = ''
            }
            driverObj.destroy()
            setTimeout(() => {
              driverObj.moveTo(2)
            }, 5000)
          })
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_2, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#sandbox-tabs-id-statistics',
      popover: {
        description: ' ',
        showButtons: ['close'],
        side: 'bottom',
        onPopoverRender: (popover, { config, state }) => {
          popover.wrapper.style.maxWidth = '300px'
          const descriptionText =
            'Check the "Statistics" tab to check out player performance.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'left',
            marginTop: '16px',
          })
          description.innerText = descriptionText
          popover.description.appendChild(description)

          const button = document.getElementById('sandbox-tabs-id-statistics')
          if (button) {
            button.setAttribute('aria-controls', 'driver-popover-content')
            button.style.zIndex = '10001'
            button.classList.add('driver-active-element')
            button.classList.add('driver-active')
          }
          const driverActiveElement = document.querySelector(
            '.driver-active-element'
          )
          if (driverActiveElement) {
            driverActiveElement.style.border = '2px solid #F2B400'
            driverActiveElement.style.borderRadius = '8px'
            driverActiveElement.style.paddingLeft = '4px'
            driverActiveElement.style.paddingRight = '4px'
            driverActiveElement.style.paddingTop = '4px'
            driverActiveElement.style.paddingBottom = '4px'
          }
          if (button) {
            button.addEventListener('click', () => {
              if (driverActiveElement) {
                driverActiveElement.style.border = ''
                driverActiveElement.style.borderRadius = ''
                driverActiveElement.style.paddingLeft = ''
                driverActiveElement.style.paddingRight = ''
              }
              driverObj.destroy()
              setTimeout(() => {
                driverObj.moveTo(3)
              }, 3000)
            })
          }
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_2, false)
          driverObj.destroy()
        },
      },
    },
    {
      popover: {
        title: "Let's Jump Into Action!",
        description:
          "Everything is fine with Standings and Statistics, let's see how to score a game",
        showButtons: ['close', 'next'],
        onNextClick: () => {
          const button = document.getElementById('sandbox-tabs-id-schedule')
          setTimeout(() => {
            button.click()
            driverObj.moveNext()
          }, 500)
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_2, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#sandbox-tabs-id-schedule',
      popover: {
        description: ' ',
        showButtons: ['close', 'next'],
        side: 'bottom',
        onPopoverRender: (popover, { config, state }) => {
          popover.wrapper.style.maxWidth = '300px'
          const descriptionText =
            'Here is the Schedule tab, click on it to see the list of games.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'left',
            marginTop: '16px',
          })
          description.innerText = descriptionText
          popover.description.appendChild(description)
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_2, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#sandbox-filters-sort-order',
      popover: {
        title: 'Change the Sort Order',
        description:
          'You can change the sort order of the games by clicking on the "Oldest" or "Newest" button.',
        showButtons: ['close', 'next'],
        onNextClick: () => {
          const button = document.getElementById('game-sort-desc')
          button.click()
          setTimeout(() => {
            driverObj.moveNext()
          }, 1000)
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_2, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#sandbox-schedule-games',
      popover: {
        description: ' ',
        showButtons: ['close'],
        side: 'bottom',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText = 'Click here to open the game page.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText
          popover.description.appendChild(description)
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_2, false)
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_3, true)
          const driverActiveElement = document.querySelector(
            '.driver-active-element'
          )
          if (driverActiveElement) {
            driverActiveElement.style.border = '2px solid #F2B400'
            driverActiveElement.style.borderRadius = '8px'
          }
          const soccerSandboxLeagues = document.querySelector(
            '#sandbox-schedule-games'
          )
          if (soccerSandboxLeagues) {
            soccerSandboxLeagues.style.zIndex = '10001'
            soccerSandboxLeagues.addEventListener('click', () => {
              if (driverActiveElement) {
                driverActiveElement.style.border = ''
                driverActiveElement.style.borderRadius = ''
              }
            })
          }
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_2, false)
          driverObj.destroy()
        },
      },
    },
  ]

  return scheduleScreenSteps
}

export const getGameDetailsScreenSteps = (driverObj) => {
  const gameDetailsScreenSteps = [
    {
      element: '#tutorial-score-button',
      popover: {
        title: 'Let’s Score This Game!',
        description:
          'SportNinja uses Mobile and Tablet devices for Scoring. Click on the "Score" button to download the app. All games are available for scoring in the mobile app.',
        showButtons: ['close'],
        side: 'left',
        onPopoverRender: (popover, { config, state }) => {
          // Ensure that the tutorial is not shown again
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_3, false)
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_3, false)
          driverObj.destroy()
        },
      },
    },
  ]
  return gameDetailsScreenSteps
}

export const getOrgScreenCreateYourLeagueSteps = (driverObj) => {
  const orgScreenSteps = [
    {
      element: '#organization-empty',
      popover: {
        title: 'Before You Start!',
        description: ' ',
        showButtons: ['close', 'next'],
        side: 'bottom',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText =
            'Organizations are used to organize different sports, leagues, or other organizational hierarchies.\nCreate your teams/rosters in the desired Organization.\nCompetitions are where the games are scored/played, so be sure to create one to start scoring.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#tutorial-dropdown',
      popover: {
        title: 'Creating New Entities',
        description: ' ',
        showButtons: ['close'],
        side: 'left',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText =
            'You can create Organizations, Teams, Competitions, and Locations directly from this dropdown.\nIt is almost everything that you need to set up your league in SportNinja.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)
          const button = document.getElementById('tutorial-button')
          button.addEventListener('click', () => {
            setTimeout(() => {
              driverObj.moveNext()
            }, 500)
          })
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#team-tutorial',
      popover: {
        description: ' ',
        showButtons: ['close'],
        side: 'left',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText = 'Click here to create a new Team.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)

          const driverActiveElement = document.querySelector(
            '.driver-active-element'
          )
          if (driverActiveElement) {
            driverActiveElement.style.border = '2px solid #F2B400'
            driverActiveElement.style.borderRadius = '8px'
            driverActiveElement.style.paddingLeft = '4px'
            driverActiveElement.style.paddingRight = '4px'
          }
          const button = document.getElementById('team-tutorial')
          button.addEventListener('click', () => {
            setTimeout(() => {
              driverObj.moveNext()
            }, 500)
          })
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#tutorial-team-name',
      popover: {
        description: ' ',
        showButtons: ['close', 'next'],
        side: 'right',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText = 'Enter a Team name here.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)
          const teamNameInput = document.getElementById('tutorial-team-name')
          const nextButton = popover.nextButton
          nextButton.disabled = true
          nextButton.style.opacity = '0.5' // Set the opacity to 0.5 when disabled
          const handleInputChange = () => {
            if (teamNameInput.value.length < 3) {
              nextButton.disabled = true
              nextButton.style.opacity = '0.5' // Set the opacity to 0.5 when disabled
            } else {
              nextButton.disabled = false
              nextButton.style.opacity = '1' // Set the opacity to 1 when enabled
            }
          }
          teamNameInput.addEventListener('input', handleInputChange)
        },
        onNextClick: () => {
          driverObj.moveNext()
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#form-buttom-submit',
      popover: {
        description: ' ',
        showButtons: ['close'],
        side: 'left',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText = 'Click on "Submit" to create this Team'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
    {
      popover: {
        title: 'Congratulations!',
        description: ' ',
        showButtons: ['close', 'next'],
        side: 'left',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText =
            'Great job creating your first Team!\nNow, it is time to create your first Location.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n')
          popover.description.appendChild(description)
        },
        onNextClick: () => {
          driverObj.moveNext()
          setTimeout(() => {
            const button = document.getElementById('hidden-location-tutorial')
            button?.click()
          }, 3000)
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
  ]
  return orgScreenSteps
}

export const getOrgScreenCreateYourLocationSteps = (driverObj) => {
  const orgScreenSteps = [
    {
      element: '#tutorial-dropdown-teams',
      popover: {
        description: ' ',
        showButtons: ['close'],
        side: 'left',
        onPopoverRender: (popover, { config, state }) => {
          popover.wrapper.style.maxWidth = '300px'
          const descriptionText =
            'By pressing the "New" button, you can open the dropdown to create a new Location.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'left',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)
          const button = document.getElementById('tutorial-button-teams')
          button.addEventListener('click', () => {
            setTimeout(() => {
              driverObj.moveNext()
            }, 500)
          })
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#location-tutorial',
      popover: {
        description: ' ',
        showButtons: ['close'],
        side: 'left',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText = 'Click here to create a new Location.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)

          const driverActiveElement = document.querySelector(
            '.driver-active-element'
          )
          if (driverActiveElement) {
            driverActiveElement.style.border = '2px solid #F2B400'
            driverActiveElement.style.borderRadius = '8px'
            driverActiveElement.style.paddingLeft = '4px'
            driverActiveElement.style.paddingRight = '4px'
          }

          const button = document.getElementById('location-tutorial')
          button.addEventListener('click', () => {
            if (driverActiveElement) {
              driverActiveElement.style.border = ''
              driverActiveElement.style.borderRadius = ''
              driverActiveElement.style.paddingLeft = ''
              driverActiveElement.style.paddingRight = ''
            }
            setTimeout(() => {
              driverObj.moveNext()
            }, 500)
          })
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#location-modal',
      popover: {
        description: ' ',
        showButtons: ['close'],
        side: 'right',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText =
            'Fill out all the required information.\n Then click on "Submit" to create the Location.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)
          setTimeout(() => {
            driverObj?.destroy()
          }, 4000)
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
  ]
  return orgScreenSteps
}

export const getOrgScreenCreateYourScheduleSteps = (driverObj) => {
  const orgScreenSteps = [
    {
      popover: {
        title: 'Congratulations!',
        description: ' ',
        showButtons: ['close', 'next'],
        side: 'left',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText =
            'Great job creating your first Location!\nNow, it is time to create your Competition.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#tutorial-dropdown-teams',
      popover: {
        description: ' ',
        showButtons: ['close'],
        side: 'left',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText =
            'By pressing the "New" button, you can open the dropdown to create a new Competition.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)
          const button = document.getElementById('tutorial-button-teams')
          button.addEventListener('click', () => {
            setTimeout(() => {
              driverObj.moveTo(2)
            }, 500)
          })
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '#schedule-tutorial',
      popover: {
        description: ' ',
        showButtons: ['close'],
        side: 'left',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText = 'Here you can create your first Competition.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)

          const driverActiveElement = document.querySelector(
            '.driver-active-element'
          )
          if (driverActiveElement) {
            driverActiveElement.style.border = '2px solid #F2B400'
            driverActiveElement.style.borderRadius = '8px'
            driverActiveElement.style.paddingLeft = '4px'
            driverActiveElement.style.paddingRight = '4px'
          }

          const button = document.getElementById('schedule-tutorial')
          button.addEventListener('click', () => {
            if (driverActiveElement) {
              driverActiveElement.style.border = ''
              driverActiveElement.style.borderRadius = ''
              driverActiveElement.style.paddingLeft = ''
              driverActiveElement.style.paddingRight = ''
            }
            setTimeout(() => {
              driverObj.moveNext()
            }, 500)
          })
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
    {
      element: '.scrolling-container',
      popover: {
        description: ' ',
        showButtons: ['close'],
        side: 'right',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText =
            'Fill out all the required information.\n Then click on "Submit" to create the Competition.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)
          setTimeout(() => {
            driverObj?.destroy()
          }, 4000)
        },
        // onCloseClick: async () => {
        //   setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
        //   driverObj.destroy()
        // },
      },
    },
    {
      popover: {
        description: ' ',
        showButtons: ['close', 'next'],
        side: 'left',
        title: 'Congratulations!',
        onPopoverRender: (popover, { config, state }) => {
          const descriptionText =
            'You just created your first Competition!\nNow you can create Games and start Scoring.'
          const description = createDiv({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            textAlign: 'center',
            marginTop: '16px',
          })
          description.innerText = descriptionText.replace(/\n/g, '\n\n')
          popover.description.appendChild(description)
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
        },
        onCloseClick: async () => {
          setTutorial(tutorialKeys.NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1, false)
          driverObj.destroy()
        },
      },
    },
  ]
  return orgScreenSteps
}
