/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useState } from 'react'
import { getPlayerTypesBySportId } from '@sportninja/common/selectors/types'
import { connect } from 'react-redux'
import colors from '@sportninja/common/constants/appColors'
import req from '@sportninja/common/api/request'

import ActionButton from '../../../components/ActionButton'
import TextModal from '../../../components/Modal/TextModal'
import Toggler from '../../../components/Toggler'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { Flex } from '../../../components/Layout'
import RosterEditingTeam from './RosterEditingTeam'

const InnerRosterEdit = ({
  isGenericGame = false,
  requireUniqueJerseyNumbers = true,
  gameId,
  homeGameRosterId,
  visitingGameRosterId,
  homeTeamId,
  visitingTeamId,
  playerTypes,
  setDirty,

  goals,
  offenses,
  goalieChanges,
  isSoccer = false,
}) => {
  const [isReady, setIsReady] = useState(false)
  const [tab, setTab] = useState('home')

  useEffect(() => {
    req(`/games/${gameId}/rollback`, {
      method: 'PUT',
    }).then(() => {
      setIsReady(true)
    })
  }, [])

  if (!isReady) {
    return (
      <Flex column alignItems='center'>
        <p>Please wait, setting up rosters...</p>
        <LoadingSpinner />
      </Flex>
    )
  }

  return (
    <>
      <Toggler
        title='Roster'
        activeBtn={tab === 'home' ? 'left' : 'right'}
        left='Home'
        right='Visitor'
        onClick={(isHome) => setTab(isHome ? 'home' : 'visitor')}
      />

      {tab === 'home' ? (
        <RosterEditingTeam
          key='home'
          gameId={gameId}
          gameRosterId={homeGameRosterId}
          playerTypes={playerTypes}
          setDirty={setDirty}
          goals={goals}
          offenses={offenses}
          goalieChanges={goalieChanges}
          isGenericGame={isGenericGame}
          requireUniqueJerseyNumbers={requireUniqueJerseyNumbers}
          teamId={homeTeamId}
          isSoccer={isSoccer}
        />
      ) : (
        <RosterEditingTeam
          key='visitor'
          gameId={gameId}
          gameRosterId={visitingGameRosterId}
          playerTypes={playerTypes}
          setDirty={setDirty}
          goals={goals}
          offenses={offenses}
          goalieChanges={goalieChanges}
          isGenericGame={isGenericGame}
          requireUniqueJerseyNumbers={requireUniqueJerseyNumbers}
          teamId={visitingTeamId}
          isSoccer={isSoccer}
        />
      )}
    </>
  )
}

const RosterEditing = ({
  isGenericGame = false,
  requireUniqueJerseyNumbers = true,
  isSoccer = false,
  button,
  gameId,
  homeGameRosterId,
  visitingGameRosterId,
  onExitRosterEditing,

  goals,
  offenses,
  goalieChanges,

  // Redux
  playerTypes,
  homeTeamId,
  visitingTeamId,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isReprocessing, setIsReprocessing] = useState(false)
  const [error, setError] = useState(false)
  const [dirty, setDirty] = useState(false)

  return (
    <div>
      {button ? (
        button(() => setIsOpen(true))
      ) : (
        <ActionButton
          icon='clipboard-user'
          label='Edit Rosters'
          title='Edit Rosters'
          onClick={() => {
            setIsOpen((o) => !o)
          }}
          css={css`
            margin-left: 16px;
          `}
        />
      )}
      <TextModal
        title={
          error
            ? 'Error'
            : isReprocessing
            ? 'Updating Game Stats'
            : 'Edit Game Roster'
        }
        titleRight={
          !isReprocessing &&
          !error && (
            <div
              css={css`
                margin-left: auto;
                margin-bottom: 32px;
                display: flex;
                align-items: center;
                max-width: 300px;
                text-align: right;
                line-height: 15px;
                color: ${colors.DEFAULT_FLAIR};
              `}
            >
              Changes are saved automatically. Stats will update once you close
              this window.
            </div>
          )
        }
        unmountOnClose
        hideClose={isReprocessing}
        isOpen={isOpen}
        toggle={async () => {
          if (error) {
            setIsOpen(false)
            setError(false)
            return
          }

          if (isReprocessing) {
            return
          }

          // This modal appears on the timeline page. So, keep track of when it's opened
          // so that when it's closed, we can run a callback method supplied by the game
          // page. This method should reprocess the game stats, then re-request the game
          // and player rosters so they are up to date for use by the timeline component.
          setIsReprocessing(true)
          try {
            await req(`/games/${gameId}/process`, {
              method: 'PUT',
            })
            await onExitRosterEditing()
            setIsOpen(false)
            setDirty(false)
          } catch (e) {
            setError(e)
          } finally {
            setIsReprocessing(false)
          }
        }}
        css={
          isReprocessing || error
            ? css`
                h1 {
                  flex: 1;
                  text-align: center;
                }
              `
            : css`
                width: unset;
                max-width: unset;
                min-width: 744px;
              `
        }
      >
        {isReprocessing || error ? (
          <Flex column alignItems='center'>
            <p
              css={css`
                ${error &&
                css`
                  color: ${colors.FIERY_RED};
                `}
                word-break: break-word;
              `}
            >
              {error ? error.message : 'Please wait...'}
            </p>
            {error && <p>Please try again later.</p>}
            {!error && <LoadingSpinner />}
          </Flex>
        ) : (
          <InnerRosterEdit
            gameId={gameId}
            homeGameRosterId={homeGameRosterId}
            visitingGameRosterId={visitingGameRosterId}
            homeTeamId={homeTeamId}
            visitingTeamId={visitingTeamId}
            playerTypes={playerTypes}
            setDirty={setDirty}
            goals={goals}
            offenses={offenses}
            goalieChanges={goalieChanges}
            isGenericGame={isGenericGame}
            requireUniqueJerseyNumbers={requireUniqueJerseyNumbers}
            isSoccer={isSoccer}
          />
        )}
      </TextModal>
    </div>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    playerTypes: getPlayerTypesBySportId(state, sportId),
  }
}

export default connect(mapStateToProps)(RosterEditing)
