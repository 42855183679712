import { isCanlan } from '../utils/customer-name'

const baseColors = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  RED: '#FF0000',
  GREEN: '#7CFC00',
  DARK_GREEN: '#008000',
  GRAY: '#787878',
  LIGHT_GRAY: '#A3A3A3',
  LIGHTER_GRAY: '#e6e6e6',
  VERY_LIGHT_GRAY: '#EBEBEB',
  BLUE_GRAY: '#6b7b97',
  BLACK_PEARL: '#1C2024',
  BLUE: '#147efb',
  LIGHT_BLUE: '#add8e6',
  HEADER_BG_2: '#1b1f25',
  HEADER: '#27303E',
  BG: '#FFFFFF',
  ALT_BG: '#181A1D',
  SECONDARY_BG: '#f9f9f9',
  TEXT: '#000000',
  ERROR: '#FF0000',
  ERROR_LIGHT: '#E55C5C',
  GAME_SETUP_BG: '#0d0d0e',
  GAME_SETUP_GREEN: '#40C168',
  PLACEHOLDER_TEXT: '#8C8C8C',
  STATUS_BTN_BG: '#525f74',
  CYAN_BLUE: '#147efb',
  DARK_GRAY: '#a9a9a9',
  BLACK_R: '#0d0d0e',
  CORAL: '#FF824C',
  METALLIC_YELLOW: '#FDCC0D',
  C_YELLOW: '#FFAF00',
  MINION_YELLOW: '#F5E050',
  GREEN_GOBLIN: '#10ad32',
  AMERICAN_YELLOW: '#f2b400',
  FIERY_RED: '#d91b1b',
  ATTENDANCE_GRAY: '#9fa3a9',
  FLORAL_WHITE: '#FFF9EE',
  SOLITUDE: '#E7E8E9',
  NOTIFICATIONS_BLACK: '#0F1928',
  SOFT_STEEL: '#3F4753',
  LIGHT_SHADE_OF_GRAY: '#fafafa',
  PALE_SKY: '#6F757E',
  SUCCESS_GREEN: '#5ccc74',
  LINK_WATER: '#cfd1d4',
  ORANGE: '#FFA500',
  TUTU: '#FCDEDE',
  ANTI_FLASH_WHITE: '#f3f3f4',
  ANOTHER_BLUE: '#38D1FF',
  SLATE_GRAY: '#5c636b',
  GRAY_95: '#f2f2f2',
  INFO_DARK: '#1966B5',
  INFO_LIGHT: '#80BAFF',
  CAUTION_DARK: '#AC590B',
  SUCCESS_DARK: '#048039',
  PLATINUM: '#E5E5E5',
  GHOST_WHITE: '#F3F3F4',
  BLACK_RU: '#1B1D22',
  DARK_RED: '#860000',
  RICH_ELECTRIC_BLUE: '#52b2de',
  NOMINEE: '#336073',
  SAPPHIRE: '#2B5BA5',
  NOTIFICATION_BLUE_GRAY: '#82878F',
  ALICE_BLUE: '#EBF4FF',
  HARMONIES: '#3894FF',
  // New Colors from the guides
  PRIMARY_50: '#fef8e6',
  PRIMARY_75: '#fae096',
  PRIMARY_100: '#f7d46b',
  PRIMARY_200: '#f4c12b',
  PRIMARY_300: '#f2b400',
  PRIMARY_400: '#a97e00',
  PRIMARY_500: '#946e00',
  SECONDARY_50: '#e9eaec',
  SECONDARY_75: '#a6aab0',
  SECONDARY_100: '#4E5561',
  SECONDARY_200: '#323A48',
  SECONDARY_300: '#27303e',
  SECONDARY_400: '#1b222b',
  SECONDARY_500: '#181d26',
  SUCCESS_50: '#e7f7eb',
  SUCCESS_75: '#9dddab',
  SUCCESS_100: '#74cf88',
  SUCCESS_200: '#39bb55',
  SUCCESS_300: '#10ad32',
  SUCCESS_400: '#0b7923',
  SUCCESS_500: '#0a6a1f',
  INFO_50: '#ebf4ff',
  INFO_75: '#add3ff',
  INFO_100: '#8cc1ff',
  INFO_200: '#5aa6ff',
  INFO_300: '#3894ff',
  INFO_400: '#2768b3',
  INFO_500: '#225a9c',
  WARNING_50: '#fff9e6',
  WARNING_75: '#ffe699',
  WARNING_100: '#ffdb6f',
  WARNING_200: '#ffcc31',
  WARNING_300: '#ffc107',
  WARNING_400: '#b38705',
  WARNING_500: '#9c7604',
  ERROR_50: '#fbe8e8',
  ERROR_75: '#efa2a2',
  ERROR_100: '#e97b7b',
  ERROR_200: '#df4242',
  ERROR_300: '#d91b1b',
  ERROR_400: '#981313',
  ERROR_500: '#841010',
  NEUTRAL_0: '#ffffff',
  NEUTRAL_10: '#fafafa',
  NEUTRAL_20: '#f5f5f5',
  NEUTRAL_30: '#ebebec',
  NEUTRAL_40: '#dfdfe0',
  NEUTRAL_50: '#c1c2c3',
  NEUTRAL_60: '#b2b3b5',
  NEUTRAL_70: '#a5a7a9',
  NEUTRAL_80: '#96989b',
  NEUTRAL_90: '#87898c',
  NEUTRAL_100: '#797b7e',
  NEUTRAL_200: '#6a6c70',
  NEUTRAL_300: '#5b5d61',
  NEUTRAL_400: '#4e5155',
  NEUTRAL_500: '#3f4247',
  NEUTRAL_600: '#33363b',
  NEUTRAL_700: '#21252a',
  NEUTRAL_800: '#12161c',
  NEUTRAL_900: '#060a10',
  // Registration Colors
  REGISTRATION_BACKGROUND: '#12161C',
  REGISTRATION_SECOND_BACKGROUND: '#21252A',
  VERY_DARK_GRAYISH_BLUE: '#33363B',
  STORM_GRAY: '#797b7e',
  GREEN_APPLE: '#39bb55',
  RED_APPLE: '#df4242',
  MAGNESIUM: '#c1c2c3',
  CHARADE: '#3f4247',
}

const sportNinjaColors = {
  ...baseColors,
  DEFAULT_FLAIR: baseColors.AMERICAN_YELLOW,
  DEFAULT_FLAIR_HOVER: baseColors.CAUTION_DARK,
  PRIMARY: '#F2B400',
  PRIMARY_LIGHT: '#F2CD61',
  SPLASH_BG: baseColors.BLACK_PEARL,
  AUTH_BG: '#FFFFFF',
  AUTH_INPUT_BORDER: '#b2bdce',
  ERROR_BORDER: '#bd7900',
  HEADER_TINT: baseColors.BLACK,
  WEB_INDICATOR: '#ffc81c',
  TEAM_SETUP_SUCCESS_TEXT: '#d08f00',
  PROFILE_FLAG: baseColors.ALT_BG,
}

const canlanColors = {
  ...baseColors,
  DEFAULT_FLAIR: baseColors.CYAN_BLUE,
  DEFAULT_FLAIR_HOVER: baseColors.INFO_DARK,
  PRIMARY: '#004FFF',
  PRIMARY_LIGHT: '#004FFF',
  SPLASH_BG: '#1e1e1e',
  AUTH_BG: '#121212',
  AUTH_INPUT_BORDER: baseColors.WHITE,
  ERROR_BORDER: 'rgba(255, 50, 50, 1)',
  HEADER_TINT: baseColors.WHITE,
  WEB_INDICATOR: '#007fff',
  TEAM_SETUP_SUCCESS_TEXT: '#0058A0',
  PROFILE_FLAG: '#004FFF',
}

export const colors = isCanlan ? canlanColors : sportNinjaColors

export default colors
