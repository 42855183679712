/* eslint-disable react-native/no-inline-styles */
/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react'
import {
  InfoCircleOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { Alert, Image, Switch, Upload } from 'antd'
import dayjs from 'dayjs'
import {
  paragraphSmall,
  weightRegular,
  weightSemiBold,
} from 'src/components/css'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import { TextInput } from 'src/components/ts-components/TextInput'
import CopyComponent from '../../CopyComponent'
import { useScheduleContext } from '../ScheduleContext'
import '../styles.scss'
import DeleteModal from 'src/components/Modal/DeleteModal'
import req from '@sportninja/common/api/request'
import { paragraphSmallStyle } from 'src/pages/RegistrationInviteV2/steps/Main'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import Text from 'src/components/ts-components/Text'
import RangePicker from 'src/components/ts-components/RangePicker'
import { SwitchInput } from 'src/components/ts-components/SwitchInput'

const seasonTypes = [
  {
    label: 'Spring / Summer',
    value: 'Spring / Summer',
  },
  {
    label: 'Fall / Winter',
    value: 'Fall / Winter',
  },
  {
    label: 'Tournament',
    value: 'Tournament',
  },
]

const seasonCurrencies = [
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'CAD',
    value: 'CAD',
  },
]

export const Separator = () => {
  return (
    <div
      css={css`
        border-bottom: 1px solid #3f4753;
      `}
    />
  )
}

const DeleteButton = ({ ...props }) => {
  return (
    <button
      css={css`
        display: flex;
        padding: 8px 16px 8px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid ${colors.ERROR_200};
        background: ${colors.ERROR_200};
        box-shadow: 0px 2px 0px 0px rgba(5, 145, 255, 0.1);
      `}
      {...props}
    >
      <DeleteOutlined
        css={css`
          font-size: 24px;
          color: ${colors.NEUTRAL_0};
        `}
        width={24}
        height={24}
      />
      <Text variant='heading6' weight='semiBold' color={colors.NEUTRAL_0}>
        DELETE {isCanlan ? 'SEASON' : 'COMPETITION'}
      </Text>
    </button>
  )
}

type GeneralFormProps = {
  onCancelPressed: () => void
  triggerRefresh: () => void
  setTab: (tab: string) => void
  isMobile: boolean
}

const GeneralForm: React.FC<GeneralFormProps> = ({
  onCancelPressed,
  triggerRefresh,
  isMobile,
}) => {
  const {
    id,
    isEdit,
    imageUrl,
    setImageUrl,
    fieldErrors,
    customError,
    name,
    setName,
    sports,
    genericSports,
    selectedSport,
    setSelectedSport,
    competitionDateRange,
    setCompetitionDateRange,
    displayCanlanForm,
    setSelectedSeasonType,
    setSelectedSeasonCurrency,
    selectedSeasonType,
    selectedSeasonCurrency,
    taxRate,
    setTaxRate,
    privacySettings,
    setPrivacySettings,
    setSportId,
    setGenericSportId,
    genericId,
    onCancel,
    sportOptions,
    handleChange,
    onlineRegistrationOpen,
    setOnlineRegistrationOpen,
    isRegistrationEnabled,
    setIsRegistrationEnabled,
    backgroundImageUrl,
    setBackgroundImageUrl,
    setImageChanged,
    setBackgroundImageChanged,
    beforeUpload,
    handleChangeBackground,
    isYouthLeague,
    setIsYouthLeague,
    disableTeamChat,
    setDisableTeamChat,
    playoffCompetition,
    setPlayoffCompetition,
    isArchive,
    setIsArchive,
  } = useScheduleContext()

  const selectOptions = [
    {
      label: (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <span
            css={css`
              ${paragraphSmallStyle}
              ${weightSemiBold}
              color: ${colors.PRIMARY};
              text-transform: uppercase;
            `}
          >
            Full Stats
          </span>
          <QuestionCircleOutlined
            style={{
              fontSize: 16,
              color: colors.PRIMARY,
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(
                'https://support.sportninja.com/scoring-2-versions-basic-vs-full-stats',
                '_blank'
              )
            }}
          />
        </div>
      ),
      title: 'fullStats',
      options: sports
        ?.map((option) => ({
          value: option.id,
          label: `${option.name_full}`,
          is_generic: false,
        }))
        .filter((option) => !option.label.toLowerCase().includes('generic'))
        ?.sort((a, b) => {
          return a?.label?.localeCompare(b?.label)
        }),
    },
    {
      label: (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <span
            css={css`
              ${paragraphSmallStyle}
              ${weightSemiBold}
              color: ${colors.PRIMARY};
              text-transform: uppercase;
            `}
          >
            Basic Stats
          </span>
          <QuestionCircleOutlined
            style={{
              fontSize: 16,
              color: colors.PRIMARY,
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(
                'https://support.sportninja.com/scoring-2-versions-basic-vs-full-stats',
                '_blank'
              )
            }}
          />
        </div>
      ),
      title: 'basicStats',
      options: genericSports
        ?.map((option) => ({
          value: option.id,
          label: `${option.name}`,
          is_generic: true,
        }))
        ?.sort((a, b) => {
          return a?.label?.localeCompare(b?.label)
        }),
    },
  ]

  // This function is used to filter the options in the select dropdown
  // when we group the options
  const filterOptionGroup = useCallback((search, optionOrGroup) => {
    const isGroup = Array.isArray(optionOrGroup.options)
    if (isGroup) {
      return false
    }
    return optionOrGroup.label.toLowerCase().includes(search.toLowerCase())
  }, [])

  return (
    <div
      css={css`
        padding-top: ${!isEdit ? '16px' : '8px'};
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 16px;
        height: 100%;
        .ant-upload-wrapper .ant-upload-select {
          width: 100% !important;
        }
        ${isMobile &&
        css`
          padding-left: 8px;
          padding-right: 8px;
          width: 100%;
          overflow-y: auto;
        `}
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
        `}
      >
        <Text variant='captionLarge' weight='semiBold' color={colors.NEUTRAL_0}>
          General Info
        </Text>
        {isEdit ? (
          <div>
            <CopyComponent id={id} />
          </div>
        ) : null}
      </div>
      {imageUrl ? (
        <div
          css={css`
            display: flex;
            padding: 8px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            background-color: ${colors.SECONDARY_200};
            border: 1px solid ${colors.SECONDARY_100};
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <Image
              src={
                imageUrl ||
                '/images/generic_placeholders/placeholder_competition.png'
              }
              alt='competition logo'
              width={56}
              height={56}
              css={css`
                border-radius: 4px;
                object-fit: cover;
              `}
              preview={false}
            />
            <DeleteOutlined
              css={css`
                color: ${colors.ERROR_200};
                font-size: 24px;
                cursor: pointer;
              `}
              onClick={() => {
                setImageChanged(true)
                setImageUrl(null)
              }}
            />
          </div>
        </div>
      ) : (
        <Upload
          beforeUpload={beforeUpload}
          onChange={handleChange}
          showUploadList={false}
          action={undefined}
          css={css`
            .ant-upload-wrapper .ant-upload {
              width: 100% !important;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              padding: 8px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 8px;
              border: 1px dashed ${colors.SECONDARY_100};
              cursor: pointer;
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;
              `}
            >
              <div
                css={css`
                  display: flex;
                  width: 48px;
                  height: 48px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border-radius: 4px;
                  background: ${colors.PRIMARY};
                `}
              >
                <div
                  css={css`
                    display: flex;
                    width: 24px;
                    height: 24px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: ${colors.WHITE};
                  `}
                >
                  {/* antd plus icon */}
                  <PlusCircleOutlined
                    css={css`
                      font-size: 24px;
                      color: ${colors.SECONDARY_300};
                      background-color: ${colors.PRIMARY};
                    `}
                  />
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 4px;
                  `}
                >
                  <Text
                    variant='paragraphSmall'
                    weight='regular'
                    color={colors.NEUTRAL_0}
                  >
                    {isCanlan ? 'Season Logo' : 'Competition Logo'}
                  </Text>
                </div>
                <Text
                  variant='paragraphSmall'
                  weight='medium'
                  color={colors.PRIMARY}
                >
                  Click to Upload
                </Text>
              </div>
            </div>
          </div>
        </Upload>
      )}
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          ${isMobile &&
          css`
            width: 100%;
            flex-direction: column;
          `}
        `}
      >
        {/* Competition Name */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            ${isMobile &&
            css`
              width: 100%;
            `}
          `}
        >
          <TextInput
            name={'name'}
            value={name}
            setValue={setName}
            fieldErrors={fieldErrors}
            customError={customError}
            label={`${isCanlan ? 'Season' : 'Competition'} Name *`}
          />
        </div>
        {/* Abbreviation (up to 8 characters)* */}
        {/* <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            ${isMobile &&
            css`
              width: 100%;
            `}
          `}
        >
          <TextInput
            name={'abbreviation'}
            value={abbreviation}
            setValue={setAbbreviation}
            fieldErrors={fieldErrors}
            customError={customError}
            label={'Abbreviation (up to 8 characters) *'}
            maxLength={8}
          />
        </div> */}
      </div>
      {/* Sport And Start / End Date */}
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          ${isMobile &&
          css`
            flex-direction: column;
          `}
        `}
      >
        {/* Sport */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            ${isMobile &&
            css`
              width: 100%;
            `}
          `}
        >
          <SelectInput
            placeholder={'Select Sport'}
            label={'Sport *'}
            disabled={isEdit}
            options={selectOptions}
            filterOption={filterOptionGroup}
            name={'sport'}
            value={selectedSport || ''}
            showSearch={true}
            onChange={(value) => {
              const selectedSportFromSelect = sportOptions?.find(
                (s) => s.id === value
              )
              if (selectedSportFromSelect.is_generic) {
                setSportId(genericId)
                setGenericSportId(selectedSportFromSelect.id)
              } else {
                setSportId(selectedSportFromSelect.id)
                setGenericSportId(null)
              }
              setSelectedSport(value)
            }}
            fieldErrors={fieldErrors}
            customError={customError}
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            ${isMobile &&
            css`
              width: 100%;
            `}
          `}
        >
          <RangePicker
            name={'competitionDateRange'}
            starts_at={competitionDateRange.starts_at}
            ends_at={competitionDateRange.ends_at}
            customError={customError}
            fieldErrors={fieldErrors}
            label={'Start and End Date *'}
            onChange={(e) => {
              if (e) {
                const [starts_at, ends_at] = e
                setCompetitionDateRange({
                  starts_at: dayjs(starts_at).utc().format('YYYY-MM-DD'),
                  ends_at: dayjs(ends_at).utc().format('YYYY-MM-DD'),
                })
              }
            }}
          />
        </div>
      </div>
      {/* Youth Competition */}
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          width: 100%;
        `}
      >
        <SwitchInput
          title='Youth League'
          subtitle='Ensures That Invitations And Waivers Are Sent To Parents/Guardians.'
          value={isYouthLeague}
          onChange={(value) => {
            setIsYouthLeague(value)
          }}
        />
      </div>
      {/* Chat Settings */}
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          width: 100%;
        `}
      >
        <SwitchInput
          title='Enable Team Chat'
          subtitle='Allow Participants To Communicate Through The Chat Feature For This Competition.'
          value={!disableTeamChat}
          onChange={(value) => {
            setDisableTeamChat(!value)
          }}
        />
      </div>
      {/* Playoff Competition */}
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          width: 100%;
        `}
      >
        <SwitchInput
          title='Playoff Competition'
          subtitle={
            <Text
              variant='paragraphXSmall'
              weight='regular'
              color={colors.NEUTRAL_50}
            >
              Learn more about playoff competitions by clicking{' '}
              <a
                href='https://support.sportninja.com/league-competition-management-6#tournaments-playoffs'
                target='_blank'
                rel='noopener noreferrer'
                css={css`
                  color: ${colors.PRIMARY};
                  text-decoration: underline;
                `}
              >
                here
              </a>
              .
            </Text>
          }
          value={playoffCompetition}
          onChange={(value) => {
            setPlayoffCompetition(value)
          }}
          disabled={true}
        />
      </div>
      <Separator />
      {/* Canlan Form, PT I */}
      {displayCanlanForm ? (
        <>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
            `}
          >
            {/* Season Type */}
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
              `}
            >
              <SelectInput
                label={'Season Type*'}
                disabled={false}
                options={seasonTypes}
                name={'season_type'}
                value={selectedSeasonType}
                onChange={setSelectedSeasonType}
                fieldErrors={fieldErrors}
                customError={customError}
              />
            </div>
            {/* Season Currency */}
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
              `}
            >
              <SelectInput
                label={'Season Currency*'}
                disabled={false}
                options={seasonCurrencies}
                name={'season_currency'}
                value={selectedSeasonCurrency}
                onChange={setSelectedSeasonCurrency}
                fieldErrors={fieldErrors}
                customError={customError}
              />
            </div>
          </div>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
              `}
            >
              <TextInput
                name={'tax_rate'}
                value={taxRate !== null ? taxRate.toString() : ''}
                setValue={(value) =>
                  setTaxRate(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label={'Tax Rate*'}
                type={'number'}
              />
            </div>
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            {/* Online Registration Open - Canlan Salesforce Settings */}
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;
                opacity: 0.7;
              `}
            >
              <InfoCircleOutlined
                width={16}
                height={16}
                css={css`
                  color: ${colors.PRIMARY};
                `}
              />
              <p
                css={css`
                  ${paragraphSmall}
                  ${weightRegular}
              color: ${colors.WHITE};
                `}
              >
                Online Registration Open?
              </p>
            </div>
            <Switch
              defaultChecked
              onChange={(checked) => {
                setOnlineRegistrationOpen(checked)
              }}
              value={onlineRegistrationOpen}
              size='small'
            />
          </div>
        </>
      ) : null}
      {displayCanlanForm ? <Separator /> : null}
      {/* Playoff Competition - Removing this for now */}
      {/* <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            opacity: 0.7;
          `}
        >
          <InfoCircleOutlined
            width={16}
            height={16}
            css={css`
              color: ${colors.PRIMARY};
            `}
          />
          <p
            css={css`
              ${paragraphSmall}
              ${weightRegular}
              color: ${colors.WHITE};
            `}
          >
            Playoff Competition
          </p>
        </div>
        <Switch
          defaultChecked
          onChange={(checked) => {
            setPlayoffCompetition(checked)
          }}
          value={playoffCompetition}
          size='small'
          disabled={true}
        />
      </div> */}
      {/* Registration ON/OFF */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          gap: 16px;
          align-self: stretch;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
          `}
        >
          <Text
            variant='captionLarge'
            weight='semiBold'
            color={colors.NEUTRAL_0}
          >
            Registration
          </Text>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            width: 100%;
          `}
        >
          <SwitchInput
            title={`Enable Registration/Payments For This ${
              isCanlan ? 'Season' : 'Competition'
            }`}
            subtitle='This Feature Enables You To Accept Registrations. To Process Payments, Configure A Payment Provider In Account Settings.'
            value={isRegistrationEnabled}
            onChange={(value) => {
              setIsRegistrationEnabled(value)
            }}
          />
        </div>
        {isRegistrationEnabled ? (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              width: 100%;
            `}
          >
            <Alert
              message={
                <Text
                  variant='paragraphSmall'
                  weight='regular'
                  color={colors.INFO_100}
                  style={'width: 100%; margin-left: 8px;'}
                >
                  Setting Up This Feature For The First Time? Contact Us -
                  We&apos;re Here To Help -{' '}
                  <a
                    href='mailto:support@sportninja.com'
                    css={css`
                      color: ${colors.INFO_100};
                      font-weight: 700;
                      text-decoration: underline;
                    `}
                  >
                    support@sportninja.com
                  </a>{' '}
                </Text>
              }
              type='info'
              showIcon
              icon={
                <InfoCircleOutlined
                  css={css`
                    font-size: 20px;
                    color: ${colors.INFO_200};
                  `}
                />
              }
              css={css`
                width: 100%;
                border-radius: 8px;
                border: 1px solid ${colors.INFO_200};
                background: ${'#0E243F'};
              `}
            />
          </div>
        ) : null}
      </div>
      <Separator />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
          `}
        >
          <Text
            variant='captionLarge'
            weight='semiBold'
            color={colors.NEUTRAL_0}
          >
            Privacy
          </Text>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            width: 100%;
          `}
        >
          <SwitchInput
            title={`Set ${isCanlan ? 'Season' : 'Competition'} as Private`}
            subtitle={`Private ${
              isCanlan ? 'Seasons' : 'Competitions'
            } Can Only Be Viewed And Followed By People That Have Been Invited By Admins.`}
            value={privacySettings === 'private'}
            onChange={(value) => {
              setPrivacySettings(value ? 'private' : 'public')
            }}
          />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            width: 100%;
          `}
        >
          <SwitchInput
            title='Archive'
            subtitle='Archived Competitions Will Be Only Visible To Admins'
            value={isArchive}
            onChange={(value) => {
              setIsArchive(value)
            }}
          />
        </div>
      </div>
      <Separator />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 16px;
          ${isEdit ? '' : 'margin-bottom: 16px;'}
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <Text
              variant='captionSmall'
              weight='semiBold'
              color={colors.NEUTRAL_50}
            >
              Customization
            </Text>
          </div>
        </div>

        {backgroundImageUrl ? (
          <div
            css={css`
              display: flex;
              padding: 8px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 8px;
              background-color: ${colors.SECONDARY_200};
              border: 1px solid ${colors.SECONDARY_100};
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                flex: 1 0 0;
              `}
            >
              <Image
                src={backgroundImageUrl}
                alt='background'
                width={160}
                height={48}
                css={css`
                  border-radius: 4px;
                  object-fit: cover;
                `}
                preview={false}
              />
              <DeleteOutlined
                css={css`
                  color: ${colors.ERROR_200};
                  font-size: 24px;
                  cursor: pointer;
                `}
                onClick={() => {
                  setBackgroundImageChanged(true)
                  setBackgroundImageUrl(null)
                }}
              />
            </div>
          </div>
        ) : (
          <Upload
            beforeUpload={beforeUpload}
            onChange={handleChangeBackground}
            showUploadList={false}
            action={undefined}
            css={css`
              .ant-upload-wrapper .ant-upload {
                width: 100% !important;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                padding: 8px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                border-radius: 8px;
                border: 1px dashed ${colors.SECONDARY_100};
                cursor: pointer;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  flex: 1 0 0;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    width: 48px;
                    height: 48px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: ${colors.PRIMARY};
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      width: 24px;
                      height: 24px;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      border-radius: 4px;
                      background: ${colors.WHITE};
                    `}
                  >
                    {/* antd plus icon */}
                    <PlusCircleOutlined
                      css={css`
                        font-size: 24px;
                        color: ${colors.SECONDARY_300};
                        background-color: ${colors.PRIMARY};
                      `}
                    />
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 4px;
                    `}
                  >
                    <Text
                      variant='paragraphSmall'
                      weight='regular'
                      color={colors.NEUTRAL_0}
                    >
                      Header Background
                    </Text>
                    <Text
                      variant='paragraphXSmall'
                      weight='medium'
                      color={colors.NEUTRAL_100}
                    >
                      (Recommended: 1440x300)
                    </Text>
                  </div>
                  <Text
                    variant='paragraphSmall'
                    weight='medium'
                    color={colors.PRIMARY}
                  >
                    Click to Upload
                  </Text>
                </div>
              </div>
            </div>
          </Upload>
        )}
      </div>
      {isEdit ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
          `}
        >
          <Separator />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
              `}
            >
              <Text
                variant='captionSmall'
                weight='semiBold'
                color={colors.NEUTRAL_50}
              >
                Danger Zone
              </Text>
            </div>
            <Text
              variant='paragraphXSmall'
              weight='regular'
              color={colors.NEUTRAL_100}
            >
              Permanently Delete The {isCanlan ? 'Season' : 'Competition'} And
              All Info Related.
            </Text>
            <DeleteModal
              title='Delete Competition'
              message={`Are you sure you want to delete ${name}? This cannot be undone.`}
              onDelete={async (shouldForce = false) => {
                const query: any = {}
                if (shouldForce) {
                  query.force = 1
                }
                await req(`/${ENTITY_TYPES.schedule}/${id}`, {
                  method: 'DELETE',
                  query,
                })
                triggerRefresh()
                onCancel()
                onCancelPressed()
              }}
              Button={DeleteButton}
              useForceDelete={true}
              forceDeleteText={undefined}
            />
          </div>
          <Separator />
        </div>
      ) : null}
    </div>
  )
}

export default GeneralForm
