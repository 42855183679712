/** @jsxImportSource @emotion/react */
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import css from '@emotion/css/macro'
import { t } from '@sportninja/common/i18n'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'

import { border, font, zIndex } from '../../../components/css'
import Form from '../../../components/Form'
import { FormButton, InputWrapper } from '../../../components/Form/css'
import { canlanDivisionForm } from '../../../components/Form/form-configs'
import FormInput from '../../../components/Form/FormInput'
import { Flex } from '../../../components/Layout'
import { SearchMenu } from '../../../components/SearchMenu'
import { getSearchTypes } from '../../../components/SearchMenu/search-menu-helpers'
import { FollowBtnButton } from '../../../components/FollowBtn'
import Icon from '../../../components/Icon'
import Tooltip from '../../../components/StatisticsTable/Tooltip'
import utils from './utils'
import colors from '@sportninja/common/constants/appColors'
import EditPanelRosterSelect from './EditPanelRosterSelect'
import EditEntitySubTitle from '../../EditEntitySubTitle'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { getErrorMessage } from '@sportninja/common/utils/utils'

const ResultButton = ({ disabled, isSelected, onClick, result }) => {
  if (!isSelected && disabled) {
    return false
  }
  return (
    <FollowBtnButton onClick={onClick.bind(this, result)}>
      {t(isSelected ? 'common:selected' : 'common:select')}
    </FollowBtnButton>
  )
}

/**
 * Recursively searches an array of objects for an object with a matching `id` property.
 * @param {Array<Object>} children - The array of objects to search.
 * @param {string} id - The `id` property to search for.
 * @returns {(Object|false)} - The first object in the array with a matching `id` property, or `false` if no match is found.
 */
const findNodeInChildren = (children, id) => {
  if (!children || children.length === 0) {
    return false
  }
  const result = children.find((child) => child.id === id)
  if (result) {
    return result
  }
  for (let i = 0; i < children.length; i++) {
    const child = children[i]
    if (child.children && child.children.length > 0) {
      const result = findNodeInChildren(child.children, id)
      if (result) {
        return result
      }
    }
  }
  return false
}

/**
 * Finds the type of the parent node of a given node in a tree.
 * @param {Object} node - The node to find the parent type of.
 * @param {Array<string>} cursor - The cursor representing the path to the node in the tree.
 * @param {Array<Object>} treeData - The array of objects representing the tree.
 * @returns {(string|null)} - The subtitle of the parent node, or `null` if the parent node cannot be found.
 */
const getParentNodeType = (node, cursor, treeData) => {
  if (!node || !cursor || !treeData || treeData.length === 0) {
    return
  }
  if (cursor.length === 0 || cursor.length === 1) {
    return
  }
  // get the antipenultimate node
  const antepenultimate = cursor[cursor.length - 2]
  if (!antepenultimate) {
    return
  }
  const result = findNodeInChildren(treeData, antepenultimate)
  if (!result) {
    return
  } else {
    return result?.subtitle || null
  }
}

const EditPanel = ({
  cursor,
  depthMetadata,
  disableEditing,
  node,
  onCancel,
  onSubmit,
  parentOrgId,
  scheduleId,
  searchAllTeams,
  setSearchAllTeams,
  sourceRosterForCopy,
  setSourceRosterForCopy,
  showMinGameCutoff,
  showSalesforceSettings,
  teams,
  treeData,
  isSoccer,
  sportId = null,
  genericSportId = null,
  setFinishedSubmitting = () => {},
  finishedSubmitting = false,
}) => {
  const mounted = useRef(true)
  const submitRef = useRef(null)
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({})
  const [error, setError] = useState(false)
  const [disableTeamForm, setDisableTeamForm] = useState(false)
  const [displayCompCanlanForm, setDisplayCompCanlanForm] = useState(false)

  const isStateSet = utils.hasKey.bind(this, state)
  const isInitialState = node.initialState === true
  const isTeamType = utils.isTeamNode(node)
  const isNew = node.id === 'New'
  // i.e. other nodes at this depth exist, used for disabling certain inputs
  const hasCousinNodes =
    depthMetadata[cursor.length - 1] &&
    depthMetadata[cursor.length - 1].count > 1
  const isCompFormDisabled = isStateSet('team_id') || isStateSet('teamName')
  const isTeamFormDisabled =
    isStateSet('title') || isStateSet('subtitle') || disableTeamForm

  const isDivision = node.subtitle === 'Division'

  const onSave = async () => {
    setError(false)
    setLoading(true)
    const values = { ...node, ...state }

    try {
      await onSubmit(values)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage || t('errors:somethingWentWrongPleaseTryAgain'))
      setLoading(false)
    }
  }

  // on load handler
  useLayoutEffect(() => {
    mounted.current = true
    const keyHandler = (event) => {
      if (event) {
        if (event.keyCode === 13) {
          submitRef?.current?.click()
        }
        if (event.keyCode === 27) {
          onCancel()
        }
      }
    }

    document.addEventListener('keyup', keyHandler)

    return () => {
      mounted.current = false
      document.removeEventListener('keyup', keyHandler)
    }
  }, [])

  useEffect(() => {
    if (mounted.current) {
      // Must reset local state, since this component does not unmount if user
      // selects different competition node types without dismissing panel
      setReadyToSubmit(false)
      setLoading(false)
      setState(false)
      setError(false)
      setDisableTeamForm(false)
      setDisplayCompCanlanForm(false)
      setSourceRosterForCopy(false)
    }
  }, [node])

  useEffect(() => {
    let readyToSubmit = false

    if (
      (isStateSet('selected_teams') && state?.selected_teams?.length > 0) ||
      isStateSet('teamName')
    ) {
      // Team form
      readyToSubmit = true
    } else {
      // Competition form
      const hasTitleState = isStateSet('title')
      const hasSubtitleState = isStateSet('subtitle')
      const hasTitleNode = utils.hasKey(node, 'title') && node.title.length > 0
      const hasSubtitleNode =
        utils.hasKey(node, 'subtitle') && node.subtitle.length > 0

      if (isNew) {
        // If this is a new competition, then check that the user has filled out
        // the title and either the type is pre-filled, or was filled out manually.
        readyToSubmit = hasTitleState && (hasSubtitleState || hasSubtitleNode)
      } else {
        if (hasTitleState) {
          readyToSubmit = hasSubtitleNode && state.title !== node.title
        } else if (hasSubtitleState) {
          readyToSubmit = hasTitleNode && state.subtitle !== node.subtitle
        } else {
          readyToSubmit = true
        }
      }
    }

    if (mounted.current) {
      setReadyToSubmit(readyToSubmit)
    }
  }, [node, state])

  const title =
    (isTeamType || isInitialState) && !isTeamFormDisabled
      ? 'Select or create a team'
      : isNew
      ? `Create a new ${node.subtitle || 'conference / division'}`
      : `Edit ${node.subtitle}`

  const onChange = ({ target: { name, value } }) => {
    const newState = { ...state, [name]: value }
    if (typeof value === 'undefined' || value.length === 0) {
      delete newState[name]
    }
    setState(newState)
  }

  const searchTypes = getSearchTypes()
  const customSearchTypes = {
    [ENTITY_TYPES.team]: searchTypes[ENTITY_TYPES.team],
  }

  customSearchTypes[[ENTITY_TYPES.team]].slug = searchAllTeams
    ? `schedules/${scheduleId}/teams/rootsearch`
    : `organizations/${parentOrgId}/teams`

  const onResultClick = (result) => {
    if (!state?.selected_teams) {
      onChange({
        target: {
          name: 'selected_teams',
          value: [result.id],
        },
      })
    } else {
      let selectedTeams = state?.selected_teams
      if (selectedTeams.filter((st) => st === result.id).length > 0) {
        selectedTeams = selectedTeams.filter((st) => st !== result.id)
      } else {
        selectedTeams.push(result.id)
      }
      onChange({
        target: {
          name: 'selected_teams',
          value: selectedTeams,
        },
      })
    }
  }

  const isRosterSelectActive =
    isStateSet('selected_teams') && state.selected_teams?.length > 0

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        border: ${border};
        padding: 20px;
        margin-left: 8px;
        max-height: inherit;
        min-height: inherit;
        position: relative;
        overflow: scroll;
        scroll-behavior: auto;
        border-radius: 8px;
      `}
    >
      <Flex
        noFlex
        alignItems='center'
        justifyContent='space-between'
        css={css`
          margin-bottom: 20px;

          ${isRosterSelectActive &&
          css`
            visibility: hidden;
            pointer-events: none;
          `}
        `}
      >
        <h1
          css={css`
            ${font.title};
            font-size: 24px;
            font-weight: bold;
          `}
        >
          {title}
        </h1>
        <Tooltip text='Press Escape to cancel. Press Enter to save.'>
          <Icon name='question-circle' fontSize={20} />
        </Tooltip>
      </Flex>
      {!isTeamType && !isCompFormDisabled ? (
        <div>
          {node.subtitle === 'Season' && node?.id ? (
            <EditEntitySubTitle
              entityType={ENTITY_TYPES.schedule}
              id={node?.id}
            />
          ) : null}
          {node.subtitle === 'Division' && node?.id ? (
            <EditEntitySubTitle
              entityType={ENTITY_TYPES.division}
              id={node?.id}
            />
          ) : null}
          {node.subtitle === 'Conference' && node?.id ? (
            <EditEntitySubTitle
              entityType={ENTITY_TYPES.conference}
              id={node?.id}
            />
          ) : null}
          {isCanlan && node?.salesforce_id ? (
            <EditEntitySubTitle
              entityType={ENTITY_TYPES.salesforce}
              id={node?.salesforce_id}
            />
          ) : null}
        </div>
      ) : null}
      {(isTeamType || isInitialState) && !isTeamFormDisabled && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex: 1;
            max-height: 600px !important;

            ${InputWrapper} {
              padding-left: 0 !important;
              padding-right: 0 !important;
            }

            margin-bottom: ${isInitialState && '16px'};

            .search-menu-results {
              opacity: ${isStateSet('teamName') ? 0.5 : 1};
            }

            ${isRosterSelectActive &&
            css`
              visibility: hidden;
              pointer-events: none;
            `}
          `}
        >
          <Flex
            noFlex
            alignItems='center'
            css={css`
              margin-bottom: 8px;
            `}
          >
            <FormInput
              onChange={() => setSearchAllTeams(!searchAllTeams)}
              disabled={isStateSet('teamName')}
              input={{
                name: 'search-filter-checkbox',
                label: 'Search All Teams In Root Organization',
                type: 'checkbox',
                checked: searchAllTeams,
                noFlex: true,
              }}
            />
            {/* Remove the tooltip as requested on https://sportninja.atlassian.net/browse/SN-3267 */}
            {/* <Tooltip
              textClassName={css`
                background: black;
                max-width: 200px;
              `}
              text="Check the box to search all teams in SportNinja. Uncheck to search only this competition's parent organization."
            >
              <Icon
                name='question-circle'
                fontSize={14}
                css={css`
                  margin-left: 6px;
                `}
              />
            </Tooltip> */}
          </Flex>

          <SearchMenu
            sportId={sportId}
            genericSportId={genericSportId}
            alwaysShowPager
            key={searchAllTeams}
            disabled={isStateSet('teamName')}
            autoComplete='off'
            hideLabel
            filter={(item) => {
              return !teams.find((nodeId) => nodeId.indexOf(item.id) > -1)
            }}
            searchTypes={customSearchTypes}
            onResultClick={onResultClick}
            ResultButton={({ result, ...props }) => {
              const isSelected =
                state?.selected_teams?.filter((st) => st === result.id).length >
                0
              return (
                <ResultButton
                  disabled={isStateSet('teamName')}
                  result={result}
                  {...props}
                  onClick={onResultClick}
                  isSelected={isSelected}
                />
              )
            }}
          />

          <FormInput
            disabled={loading || isTeamFormDisabled}
            wrapperClass={css`
              flex: unset;
              margin-top: 16px;
            `}
            input={{
              name: 'teamName',
              label: 'or, Create a new team',
              placeholder: 'e.g. The Rockets, NSWC Bantam A1, etc.',
              readOnly: isStateSet('team_id'),
              autoComplete: 'off',
              onChange,
            }}
          />
          {isInitialState && !isCompFormDisabled && (
            <Fragment>
              <p
                css={css`
                  ${font.title}
                  font-size: 20px;
                  font-weight: bold;
                  margin: 16px;

                  text-align: center;
                `}
              >
                OR
              </p>
              <h1
                css={css`
                  ${font.title};
                  font-size: 24px;
                  font-weight: bold;
                `}
              >
                Create a new competition
              </h1>
            </Fragment>
          )}
        </div>
      )}

      {!isTeamType && !isCompFormDisabled ? (
        <Form
          css={css`
            display: flex;
            flex-direction: column;
            flex: 1;

            .form-buttons {
              margin-top: auto;
            }
          `}
          // Removing this as part of a bug found on SN-4347
          // onFormChanged={(values) => {
          //   setDisplayCompCanlanForm(values?.subtitle === 'Division')
          //   setDisableTeamForm(
          //     values?.title?.length > 0 || values?.subtitle?.length > 0
          //   )
          // }}
          key={node.id}
          form={canlanDivisionForm(
            node,
            {
              title: disableEditing,
              subtitle: disableEditing || hasCousinNodes,
              game_cutoff: !showMinGameCutoff,
            },
            (isDivision || displayCompCanlanForm) && showSalesforceSettings,
            getParentNodeType(node, cursor, treeData)
          )}
          onCancel={onCancel}
          onError={() => {}}
          onSubmit={onSubmit}
          finishedSubmitting={finishedSubmitting}
          setFinishedSubmitting={setFinishedSubmitting}
        />
      ) : (
        <div
          css={css`
            display: flex;
            margin-top: 40px;
            flex-direction: column;

            ${isRosterSelectActive &&
            css`
              visibility: hidden;
              pointer-events: none;
            `}
          `}
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <FormButton
              disabled={loading}
              css={css`
                align-self: flex-end;
              `}
              onClick={onCancel}
            >
              Cancel
            </FormButton>

            <FormButton
              ref={submitRef}
              disabled={!readyToSubmit}
              busy={loading}
              isSubmit
              css={css`
                align-self: flex-end;
              `}
              onClick={onSave}
            >
              Create
            </FormButton>
          </div>
          {error && (
            <div
              css={css`
                margin-top: 20px;
                text-align: center;
                color: red;
              `}
            >
              {error}
            </div>
          )}
        </div>
      )}

      {isRosterSelectActive && (
        <EditPanelRosterSelect
          onChange={onChange}
          sourceRosterForCopy={sourceRosterForCopy}
          setSourceRosterForCopy={setSourceRosterForCopy}
          onSave={onSave}
          teamId={state.selected_teams?.[0]}
          loading={loading}
          error={error}
          scheduleId={scheduleId}
        />
      )}
    </div>
  )
}

export default EditPanel
