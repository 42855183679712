import req from '@sportninja/common/api/request'
import { useCallback, useEffect, useState } from 'react'

export interface Team {
  uid: string
  team_player_roster_id: string
  name: string
  image: string | null
  website: string | null
  email: string | null
  phone_number: string | null
  notes: string | null
}

export interface Schedule {
  uid: string
  name: string
  start_date: string
  end_date: string
  sport_id: string
  generic_sport_id: string
}

export interface RegistrationInfo {
  id: number
  email: string
  name_first: string
  name_last: string
  birth_date: string | null
  address: string | null
  city: string | null
  country: string | null
  parent_first_name: string | null
  parent_last_name: string | null
  parent_email: string | null
  parent_phone_number: string | null
  emergency_first_name: string | null
  emergency_last_name: string | null
  emergency_email: string | null
  emergency_phone_number: string | null
  emergency_relationship: string | null
  doctor_name: string | null
  doctor_phone_number: string | null
  known_allergies: string | null
  medical_conditions: string | null
  preferred_position: string | null
  preferred_jersey_number: string | null
  preferred_dominant_side: string | null
  preferred_jersey_size: string | null
  preferred_tshirt_size: string | null
  preferred_short_size: string | null
  preferred_governing_body_number_1: string | null
  preferred_governing_body_number_2: string | null
  preferred_governing_body_number_3: string | null
  preferred_governing_body_number_4: string | null
  notes: string | null
  created_at: string | null
  updated_at: string | null
  postal_code: string | null
  province: string | null
  other_membership_number: string | null
  player_type_id: string | null
  player_type: {
    id: string
    name: string
  } | null
}

export interface ImageFormat {
  id: string
  filename: string
  full_path: string
  mime_type: string
  width: number
  height: number
}

export interface Image {
  id: string
  filename: string
  full_path: string
  mime_type: string
  width: number
  height: number
  formats: ImageFormat[]
}

export interface TeamOfficial {
  id: string
  name_first: string | null
  name_middle: string | null
  name_last: string | null
  image: Image | null
  player_number: string | null
  play_direction: string | null
  is_affiliate: boolean
  is_injured: boolean
  is_playing: boolean
  is_starting: boolean
  is_proposed_starting: boolean
  is_suspended: boolean
  attendance_status: string | null
  customer_identifier: string
  sf_id: string | null
  suspensions: any[]
  is_signed_waiver: boolean
  league_registration_number: string | null
  player_type: string | null
}

export interface Player {
  id: string
  name_first: string | null
  name_middle: string | null
  name_last: string | null
  full_name: string | null
  birth_date: string | null
  height: number | null
  weight: number | null
  created_at: string
  gender: string | null
  invitation_id: string | null
  player_number: string | null
  play_direction: string | null
  is_affiliate: boolean
  is_injured: boolean
  is_playing: boolean
  is_starting: boolean
  is_proposed_starting: boolean
  is_suspended: boolean
  attendance_status: string | null
  customer_identifier: string
  sf_id: string | null
  suspensions: any[]
  email: string
  phone_number: string | null
  is_accepted: boolean
  is_invited: boolean
  is_validated: boolean
  is_signed_waiver: boolean
  league_registration_number: string | null
  player_type: {
    id: string
    name: string
  } | null
  image: Image | null
}

export interface BillingItem {
  uid: string
  user_type: string
  user_id: string
  team_official_id: string
  payment_type: string
  due_date: string
  subtotal: number
  taxtotal: number
  total: number
  paid: boolean
  currency: number
  charge: any | null
  single_payment: boolean
  status_message: string | null
  failures: number
  description: string
  display_subtotal: number
  refunded: boolean
  refund_subtotal: number
}

export interface RegistrationDetails {
  uid: string
  type: string
  team: Team
  schedule: Schedule
  registration_info: RegistrationInfo
  player: Player
  team_official?: TeamOfficial
  registration_type: string
  registration_type_id: number
  conditions: string | null
  condition_type: any[]
  status: string
  status_id: number
  is_comp: number
  billing: BillingItem[]
  created_at: string
  updated_at: string
  is_coupon: boolean
  coupon: {
    id: string
    code: string
    discount: number
    discount_amount: number
    discount_type: 'percentage' | 'fixed'
    subtotal: number
    is_active: boolean
    registration_id: string
    start_date: string | null
    end_date: string | null
  } | null
}

export const useRegistrationDetails = (
  scheduleId?: string,
  registrationId?: string
) => {
  const [data, setData] = useState<RegistrationDetails | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = useCallback(
    async (shouldRefresh = true) => {
      if (!scheduleId || !registrationId) {
        setError(
          new Error(
            !scheduleId && !registrationId
              ? 'Schedule ID and Registration ID are required'
              : !scheduleId
              ? 'Schedule ID is required'
              : 'Registration ID is required'
          )
        )
        return
      }
      if (shouldRefresh) {
        setLoading(true)
      }
      setError(null)

      try {
        const response = await req(
          `/registration/${scheduleId}/admin/team-registration/${registrationId}`
        )
        setData(response.data)
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error('An error occurred while fetching registration details')
        )
      } finally {
        if (shouldRefresh) {
          setLoading(false)
        }
      }
    },
    [scheduleId, registrationId]
  )

  useEffect(() => {
    if (scheduleId && registrationId) {
      fetchData()
    } else {
      setError(
        new Error(
          !scheduleId && !registrationId
            ? 'Schedule ID and Registration ID are required'
            : !scheduleId
            ? 'Schedule ID is required'
            : 'Registration ID is required'
        )
      )
      setLoading(false)
    }
  }, [fetchData, scheduleId, registrationId])

  return {
    data,
    loading,
    error,
    refetch: fetchData,
  }
}
