/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { CloseOutlined } from '@ant-design/icons'
import { App, Modal, Tabs } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import { paragraphMedium, weightRegular } from 'src/components/css'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import Button from 'src/components/ts-components/Button'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  OrganizationModalContext,
  OrganizationModalContextType,
  OrganizationModalContextWrapper,
} from './context'
import GeneralSettings from './forms/GeneralSettings'
import { Organization } from '@sportninja/common/types/Organization'
import { Account } from '@sportninja/common/types/Account'
import Settings from './forms/Settings'
import Integrations from './forms/Integrations'
import Text from '../ts-components/Text'
import ContactInfo from './forms/ContactInfo'
// import Customization from './forms/Customization'
import useWindowSize from 'src/hooks/useWindowSize'
import { useModalScroll } from 'src/hooks/useModalScroll'

const labelById = {
  0: 'General Info',
  1: 'Contact Info',
  2: 'Settings',
  3: 'Integrations',
  4: 'Customization',
}
const styles = css`
  .my-modal-body {
  }
  .my-modal-mask {
    backdrop-filter: blur(1px);
  }
  .my-modal-header {
    border-bottom: 1px dotted ${colors.FIERY_RED};
  }
  .my-modal-footer {
    color: ${colors.FIERY_RED};
  }
  .my-modal-content {
    border: 1px solid #333;
  }
`
const classNames = {
  body: styles['my-modal-body'],
  mask: styles['my-modal-mask'],
  header: styles['my-modal-header'],
  footer: styles['my-modal-footer'],
  content: styles['my-modal-content'],
}
const modalStyles = {
  header: {
    backgroundColor: colors.SECONDARY_300,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  body: {
    borderBottom: `1px solid ${colors.SOFT_STEEL}`,
    borderTop: `1px solid ${colors.SOFT_STEEL}`,
    backgroundColor: colors.SECONDARY_300,
  },
  content: {
    backgroundColor: colors.SECONDARY_300,
    paddingTop: '8px',
    paddingBottom: '16px',
    paddingLeft: '0',
    paddingRight: '0',
  },
  wrapper: {
    zIndex: 10001,
  },
  mask: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(6, 10, 16, 0.8)',
    zIndex: 10000,
  },
  footer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

type OrganizationModalProps = {
  isFormOpen: boolean
  setIsFormOpen: Dispatch<SetStateAction<boolean>>
  organization: Organization
  setOrganization: (organization: Organization | null) => void
  account: Account
  refresh: () => void
  parentOrgId: string | null
  account_id: string | null
}

const OrganizationModal = ({
  isFormOpen,
  setIsFormOpen,
  organization,
  setOrganization,
  refresh,
  parentOrgId = null,
  account_id = null,
}: OrganizationModalProps) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width <= 768, [width])
  const isEdit = useMemo(() => organization?.id !== undefined, [organization])
  // CHECK THE URL TO SEE IF THE USER IS ON THEIR OWN ORG PAGE
  const isOnOwnOrgPage = useMemo(() => {
    return organization?.id === window.location.pathname.split('/')[2]
  }, [organization])

  const [activeTab, setActiveTab] = useState('0')
  const { message } = App.useApp()

  const { openModal, closeModal } = useModalScroll()
  useEffect(() => {
    if (isFormOpen) {
      openModal()
    } else {
      closeModal()
    }
  }, [closeModal, isFormOpen, openModal])

  const getFormById = (id) => {
    switch (id) {
      case '0':
        return <GeneralSettings />
      case '1':
        return <ContactInfo />
      case '2':
        return <Settings />
      case '3':
        return <Integrations />
      // case '4':
      //   return <Customization />
      default:
        return null
    }
  }

  const getFooter = useCallback(
    (context: OrganizationModalContextType) => {
      const { clearState, state, onUpdate, onCreate } = context
      return (
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            gap: 16px;
            width: 50%;
            margin-left: auto;
          `}
        >
          <Button
            text='Cancel'
            onClick={() => {
              clearState()
              setOrganization(null)
              setIsFormOpen(false)
              setActiveTab('0')
            }}
            variant='secondary'
            disabled={state.loading}
          />
          <Button
            text='Save'
            onClick={() => {
              if (isEdit) {
                onUpdate()
              } else {
                onCreate()
              }
            }}
            variant='primary'
            disabled={state.loading || state.contact_info_error}
          />
        </div>
      )
    },
    [isEdit, setIsFormOpen]
  )

  const getHeader = useCallback(() => {
    return (
      <Text variant='heading3' weight='semiBold' color={colors.WHITE}>
        {isEdit
          ? `Edit ${isCanlan ? 'League' : 'Organization'}`
          : `Create ${isCanlan ? 'League' : 'Organization'}`}
      </Text>
    )
  }, [isEdit])

  const closeIcon = useCallback(() => {
    return (
      <CloseOutlined
        css={css`
          color: ${colors.SOLITUDE};
          font-size: 16px;
        `}
      />
    )
  }, [])

  return (
    <>
      <OrganizationModalContextWrapper
        organization={organization}
        setOrganization={setOrganization}
        isEdit={isEdit}
        messageApi={message}
        setIsFormOpen={setIsFormOpen}
        refresh={refresh}
        parentOrgId={parentOrgId}
        setActiveTab={setActiveTab}
        isOnOwnOrgPage={isOnOwnOrgPage}
        accountId={account_id}
      >
        <OrganizationModalContext.Consumer>
          {(context) => {
            if (!context) {
              return null
            }
            const { clearState, state } = context
            return (
              <Modal
                css={css`
                  display: flex;
                  flex: 1;
                  background: ${colors.SECONDARY_400} !important;
                  height: ${isMobile ? '100vh' : '90vh'} !important;
                  margin: ${isMobile ? '0' : '24px'};
                  padding: 0;
                  max-width: ${isMobile ? '100%' : '1080px'};
                  top: ${isMobile ? '0' : '50%'};
                  .ant-modal-content {
                    height: ${isMobile ? '100vh' : '90vh'} !important;
                    border-radius: ${isMobile ? '0' : '8px'};
                    display: flex;
                    flex-direction: column;
                  }
                  .ant-modal-body {
                    flex: 1;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                  }
                  .ant-tabs-tabpane {
                    background: ${colors.SECONDARY_300} !important;
                  }
                  .ant-tabs-nav {
                    min-height: ${isMobile ? 'auto' : '600px'} !important;
                  }
                  .ant-tabs-tab {
                    height: 40px !important;
                  }
                  /* if the tab is active, change the background color */
                  .ant-tabs-tab-active {
                    background-color: #26303e;
                  }
                  .ant-tabs-tab-btn {
                    ${paragraphMedium}
                    ${weightRegular}
                  }
                  .ant-tabs-tab-btn[aria-selected='true'] {
                    color: ${colors.PRIMARY}!important;
                  }
                  .ant-tabs-tab-btn[aria-selected='false'] {
                    color: ${colors.NEUTRAL_30}!important;
                  }
                  .ant-tabs-tab.ant-tabs-tab-disabled {
                    color: ${colors.NEUTRAL_30}!important;
                    cursor: not-allowed !important;
                    opacity: 0.5 !important;
                  }
                `}
                width={isMobile ? '100%' : 1080}
                closeIcon={closeIcon()}
                title={getHeader()}
                footer={getFooter(context)}
                open={isFormOpen}
                onOk={() => {
                  clearState()
                  setOrganization(null)
                  setIsFormOpen(false)
                }}
                onCancel={() => {
                  clearState()
                  setOrganization(null)
                  setIsFormOpen(false)
                }}
                centered
                classNames={classNames}
                styles={modalStyles}
                maskClosable={false}
              >
                <Tabs
                  activeKey={activeTab}
                  defaultActiveKey='0'
                  onChange={(key) => setActiveTab(key)}
                  tabPosition={'left'}
                  css={css`
                    display: flex;
                    flex: 1;
                    height: 100%;
                    background: ${colors.SECONDARY_400} !important;
                    .ant-tabs-tabpane {
                      background: ${colors.SECONDARY_300} !important;
                    }
                    .ant-tabs-nav {
                      min-height: 600px !important;
                    }
                    .ant-tabs-tab {
                      height: 40px !important;
                    }
                    /* if the tab is active, change the background color */
                    .ant-tabs-tab-active {
                      background-color: #26303e;
                    }
                    .ant-tabs-tab-btn {
                      ${paragraphMedium}
                      ${weightRegular}
                    }
                    .ant-tabs-tab-btn[aria-selected='true'] {
                      color: ${colors.PRIMARY}!important;
                    }
                    .ant-tabs-tab-btn[aria-selected='false'] {
                      color: ${colors.NEUTRAL_30}!important;
                    }
                    .ant-tabs-tab.ant-tabs-tab-disabled {
                      color: ${colors.NEUTRAL_30}!important;
                      cursor: not-allowed !important;
                      opacity: 0.5 !important;
                    }
                  `}
                  items={new Array(4).fill(null).map((_, i) => {
                    const id = String(i)
                    return {
                      label: labelById[id],
                      key: id,
                      children: getFormById(id),
                      disabled:
                        (id === '2' && !isEdit) || (id === '1' && !state?.name),
                    }
                  })}
                  className='tab-options'
                />
              </Modal>
            )
          }}
        </OrganizationModalContext.Consumer>
      </OrganizationModalContextWrapper>
    </>
  )
}

export default OrganizationModal
