/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import React, { useCallback, useEffect, useState } from 'react'
import req from '@sportninja/common/api/request'

import { tutorialKeys } from '@sportninja/common/constants/userGuiding'
import {
  getTutorial,
  setTutorial,
} from '@sportninja/common/utils/TutorialHelper'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import storage from '@sportninja/common/utils/storage'
import { driver } from 'driver.js'
import { observer } from 'mobx-react-lite'
import { getHomeScreenSteps } from 'src/constants/TutorialSteps'
import { useMobxStore } from 'src/state'
import '../../styles/tutorial-styles.scss'
import { RECENTS_KEY_NAME } from '../utils'
import HomeSidebarNewPost from './HomeSidebarNewPost'
import { SidebarHeader, SidebarInner, SidebarWrap } from './SidebarComponents'
import HomeSidebarList from './HomeSidebarList'
import {
  ApartmentOutlined,
  ClockCircleOutlined,
  TrophyOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons'
import colors from '@sportninja/common/constants/appColors'

const HOME_SIDEBAR_TUTORIAL_KEY = 'SIDEBAR_STATE'

const tabOrder = [
  'organizations',
  'competitions',
  'teams',
  'sandboxLeagues',
  'recentOrganizations',
  'recentCompetitions',
  'recentTeams',
]

const isRunningOnLocalhost = () => {
  return (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  )
}

const filterForAdminAndUpdatePermission = (response) => {
  const { data, meta } = response
  if (!data || !meta || !meta?.permissions) {
    return []
  }

  const onlyNonSandbox = data.filter(
    (org) => !org.name_full.includes('Sandbox')
  )

  return onlyNonSandbox.filter((d) => {
    const permission = meta.permissions[d?.id]
    return permission?.update || permission?.admin
  })
}

const filterForUpdatePermission = (response) => {
  const { data, meta } = response
  if (!data || !meta || !meta?.permissions) {
    return []
  }
  const onlyNonSandbox = data.filter(
    (org) => !org.name_full.includes('Sandbox')
  )
  return onlyNonSandbox.filter((d) => {
    const permission = meta.permissions[d?.id]
    return permission?.update || permission?.post
  })
}

const encodeTabStates = (states) => {
  return tabOrder.map((tab) => (states[tab] ? '1' : '0')).join('')
}

const decodeTabStates = (encodedStates) => {
  const states = {}
  encodedStates.split('').forEach((state, index) => {
    states[tabOrder[index]] = state === '1'
  })
  return states
}

const HomeSidebar = observer(
  ({
    user,
    refreshFeed,
    isLoggedIn,
    setFeedOrgs,
    setFeedTeams,
    setFeedScheds,
  }) => {
    const [isOnTutorial, setIsOnTutorial] = useState(false)
    const [tabStates, setTabStates] = useState({
      organizations: true,
      competitions: false,
      teams: false,
      sandboxLeagues: true,
      recentOrganizations: false,
      recentCompetitions: false,
      recentTeams: false,
    })

    const toggleTabState = async (tabName) => {
      const newTabStates = {
        ...tabStates,
        [tabName]: !tabStates[tabName],
      }
      setTabStates(newTabStates)

      try {
        const encodedStates = encodeTabStates(newTabStates)
        localStorage.setItem(HOME_SIDEBAR_TUTORIAL_KEY, encodedStates)
      } catch (e) {
        console.error('Failed to save tab states:', e)
      }
    }

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const [orgs, setOrgs] = useState([])
    const [scheds, setSched] = useState([])
    const [teams, setTeams] = useState([])

    const fetchTabStates = useCallback(async () => {
      try {
        const tabs = localStorage.getItem(HOME_SIDEBAR_TUTORIAL_KEY)
        if (tabs) {
          const savedTabStates = decodeTabStates(tabs)
          setTabStates((prevState) => ({ ...prevState, ...savedTabStates }))
        } else {
          // If we have no saved tab states, we need to set the default ones
          if (teams.length > 0) {
            setTabStates((prevState) => ({ ...prevState, teams: true }))
          } else if (scheds.length > 0) {
            setTabStates((prevState) => ({ ...prevState, competitions: true }))
          } else if (orgs.length > 0) {
            setTabStates((prevState) => ({ ...prevState, organizations: true }))
          }
        }
      } catch (e) {
        console.error('Failed to fetch tab states:', e)
      }
    }, [teams.length, scheds.length, orgs.length])

    useEffect(() => {
      setLoading(true)
      setError(false)
      const promises = [
        req('/users/me/organizations'),
        req('/users/me/schedules'),
        req('/users/me/teams'),
        fetchTabStates(),
      ]
      Promise.all(promises)
        .then(([org, sched, teams]) => {
          setOrgs(filterForAdminAndUpdatePermission(org))
          setSched(sched?.data || [])
          setTeams(teams?.data || [])
          setFeedOrgs(filterForUpdatePermission(org))
          setFeedTeams(filterForUpdatePermission(teams))
          setFeedScheds(filterForUpdatePermission(sched))
          setLoading(false)
        })
        .catch((e) => {
          setError(e?.message || 'There was a problem.')
        })
    }, [])

    const driverObj = driver({
      allowClose: false,
      popoverClass: 'driverjs-theme',
      showProgress: false,
    })
    const [recents, setRecents] = useState({})
    const {
      me: {
        state: { account, me },
      },
    } = useMobxStore()

    useEffect(() => {
      const startTutorial = async () => {
        try {
          const tutorialInfo = await getTutorial(
            tutorialKeys.NEW_ACCOUNT_WELCOME_TO_SPORTNINJA_TUTORIAL_COUNT
          )
          let tutorialCount = 1
          if (tutorialInfo?.tutorialStep !== null) {
            tutorialCount = Number(tutorialInfo?.tutorialStep?.value)
            if (tutorialCount >= 2) {
              return
            }
            await setTutorial(
              tutorialKeys.NEW_ACCOUNT_WELCOME_TO_SPORTNINJA_TUTORIAL_COUNT,
              tutorialCount + 1
            )
          } else {
            await setTutorial(
              tutorialKeys.NEW_ACCOUNT_WELCOME_TO_SPORTNINJA_TUTORIAL_COUNT,
              1
            )
          }
          if (tutorialCount >= 2) {
            return
          }
          // This value will be only set to true when the user accepts the
          // invite as a business user
          setIsOnTutorial(true)
          const isBusinessUserSignUp = await getTutorial(
            tutorialKeys.NEW_ACCOUNT_USER_BUSINESS
          )
          if (isBusinessUserSignUp.tutorialStep.value === 'true') {
            setTimeout(() => {
              const steps = getHomeScreenSteps(
                driverObj,
                account?.organization?.url.split('/').pop()
              )
              driverObj.setSteps(steps)
              driverObj.drive()
            }, 1000)
          }
        } catch (e) {
          console.error(e)
        }
      }
      const shouldRun = me && me?.account_id && !isCanlan && isLoggedIn
      if (shouldRun) {
        startTutorial()
      }
    }, [me, storage])

    useEffect(() => {
      try {
        const jsonFromStorage = localStorage.getItem(RECENTS_KEY_NAME)
        if (jsonFromStorage) {
          const object = JSON.parse(jsonFromStorage)
          if (object?.uid === user.id) {
            setRecents(object)
          } else {
            localStorage.removeItem(RECENTS_KEY_NAME)
          }
        }
      } catch (e) {}
    }, [user.id])

    return (
      <SidebarWrap>
        <SidebarInner>
          <SidebarHeader
            imageId={user?.imageId}
            name={user?.fullName}
            subTitle='View Profile'
            to={ROUTES.PROFILE.ROOT}
          />
          <div
            css={css`
              margin-top: 16px;
              margin-bottom: 8px;
              height: 1px;
              background: ${colors.SOFT_STEEL};
            `}
          />
          {orgs.length > 0 ? (
            <>
              <HomeSidebarList
                tutorialId='tutorial-your-league'
                title={`${
                  isCanlan ? 'Leagues/Organizations' : 'Organizations'
                }`}
                data={orgs}
                icon={
                  <ApartmentOutlined
                    css={css`
                      font-size: 16px;
                      color: ${colors.PRIMARY}!important;
                    `}
                  />
                }
                baseRoute={ROUTES.ORG_ROOT}
                fallback='/images/generic_placeholders/placeholder_organization.png'
                isOpen={tabStates.organizations}
                onToggle={() => toggleTabState('organizations')}
                displayGetStarted={isOnTutorial ? true : false}
              />
            </>
          ) : null}
          {scheds.length > 0 ? (
            <>
              <HomeSidebarList
                title={`${isCanlan ? 'Seasons' : 'Competitions'}`}
                data={scheds}
                icon={
                  <TrophyOutlined
                    css={css`
                      font-size: 16px;
                      color: ${colors.PRIMARY}!important;
                    `}
                  />
                }
                baseRoute={ROUTES.SCHEDULE_ROOT}
                fallback='/images/generic_placeholders/placeholder_competition.png'
                isOpen={tabStates.competitions}
                onToggle={() => toggleTabState('competitions')}
              />
            </>
          ) : null}
          {teams.length > 0 ? (
            <>
              <HomeSidebarList
                title='Teams'
                data={teams}
                icon={
                  <SafetyCertificateOutlined
                    css={css`
                      font-size: 16px;
                      color: ${colors.PRIMARY}!important;
                    `}
                  />
                }
                baseRoute={ROUTES.TEAM_ROOT}
                fallback='/images/generic_placeholders/placeholder_team.png'
                isOpen={tabStates.teams}
                onToggle={() => toggleTabState('teams')}
              />
            </>
          ) : null}
          {!isCanlan && me?.account_id && !loading ? (
            <>
              <HomeSidebarList
                title='Sandbox Leagues'
                data={[
                  {
                    id: 'soccer-sandbox-leagues',
                    fullName: 'Soccer Demo League',
                    onClick: () => {
                      driverObj.destroy()
                    },
                  },
                  {
                    id: 'hockey-sandbox-leagues',
                    fullName: 'Hockey Demo League',
                  },
                ]}
                icon={
                  <TrophyOutlined
                    css={css`
                      font-size: 16px;
                      color: ${colors.PRIMARY}!important;
                    `}
                  />
                }
                baseRoute={ROUTES.SANDBOX}
                fallback='/images/generic_placeholders/placeholder_competition.png'
                displayCount={false}
                isOpen={isOnTutorial ? true : tabStates.sandboxLeagues}
                onToggle={() => toggleTabState('sandboxLeagues')}
              />
            </>
          ) : null}
          {recents[ENTITY_TYPES.org] &&
            recents[ENTITY_TYPES.org].filter(
              (item) => item.id !== account?.organization?.url.split('/').pop()
            ).length > 0 && (
              <>
                <HomeSidebarList
                  title={`Recent ${isCanlan ? 'Leagues' : 'Organizations'}`}
                  data={recents[ENTITY_TYPES.org].filter(
                    (item) =>
                      item.id !== account?.organization?.url.split('/').pop()
                  )}
                  icon={
                    <ClockCircleOutlined
                      css={css`
                        font-size: 16px;
                        color: ${colors.PRIMARY}!important;
                      `}
                    />
                  }
                  baseRoute={ROUTES.ORG_ROOT}
                  fallback='/images/generic_placeholders/placeholder_organization.png'
                  displayCount={false}
                  isOpen={tabStates.recentOrganizations}
                  onToggle={() => toggleTabState('recentOrganizations')}
                />
              </>
            )}

          {recents[ENTITY_TYPES.schedule] &&
            recents[ENTITY_TYPES.schedule]?.length > 0 && (
              <>
                <HomeSidebarList
                  title={`Recent ${isCanlan ? 'Seasons' : 'Competitions'}`}
                  data={recents[ENTITY_TYPES.schedule]}
                  icon={
                    <ClockCircleOutlined
                      css={css`
                        font-size: 16px;
                        color: ${colors.PRIMARY}!important;
                      `}
                    />
                  }
                  baseRoute={ROUTES.SCHEDULE_ROOT}
                  fallback='/images/generic_placeholders/placeholder_competition.png'
                  displayCount={false}
                  isOpen={tabStates.recentCompetitions}
                  onToggle={() => toggleTabState('recentCompetitions')}
                />
              </>
            )}
          {recents[ENTITY_TYPES.team] &&
            recents[ENTITY_TYPES.team]?.length > 0 && (
              <>
                <HomeSidebarList
                  title='Recent Teams'
                  data={recents[ENTITY_TYPES.team]}
                  icon={
                    <ClockCircleOutlined
                      css={css`
                        font-size: 16px;
                        color: ${colors.PRIMARY}!important;
                      `}
                    />
                  }
                  baseRoute={ROUTES.TEAM_ROOT}
                  fallback='/images/generic_placeholders/placeholder_team.png'
                  displayCount={false}
                  isOpen={tabStates.recentTeams}
                  onToggle={() => toggleTabState('recentTeams')}
                />
              </>
            )}
        </SidebarInner>
      </SidebarWrap>
    )
  }
)

export default HomeSidebar
