/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import Text from 'src/components/ts-components/Text'
import { useRegistrationSetupContext } from '../../context'
import { App, Image, Upload } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import colors from '@sportninja/common/constants/appColors'
import { SwitchInput } from 'src/components/ts-components/SwitchInput'

const Customization = () => {
  const { state, setState } = useRegistrationSetupContext()
  const { message } = App.useApp()

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const handleChangeBackground = (info: any) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setState({
        ...state,
        customBackgroundImage: reader.result as string,
        customBackgroundImageChanged: true,
      })
    })
    if (info.file.originFileObj) {
      reader.readAsDataURL(info.file.originFileObj)
    }
  }

  return (
    <div
      css={css`
        display: flex;
        padding: 16px;
        gap: 16px;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <Text variant='captionLarge' weight='semiBold'>
          Customization
        </Text>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          align-self: stretch;
          flex-direction: row;
          gap: 16px;
        `}
      >
        {state.customBackgroundImage ? (
          <div
            css={css`
              display: flex;
              padding: 8px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 8px;
              background-color: ${colors.SECONDARY_100};
              border: 1px solid ${colors.SECONDARY_200};
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                flex: 1 0 0;
              `}
            >
              <Image
                src={state.customBackgroundImage}
                alt='background'
                width={160}
                height={48}
                css={css`
                  border-radius: 4px;
                  object-fit: cover;
                `}
                preview={false}
              />
              <DeleteOutlined
                css={css`
                  color: ${colors.ERROR_200};
                  font-size: 24px;
                  cursor: pointer;
                `}
                onClick={() => {
                  setState({
                    ...state,
                    customBackgroundImage: '',
                    customBackgroundImageChanged: true,
                  })
                }}
              />
            </div>
          </div>
        ) : (
          <Upload
            beforeUpload={beforeUpload}
            onChange={handleChangeBackground}
            showUploadList={false}
            action={undefined}
          >
            <div
              css={css`
                display: flex;
                padding: 8px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                border-radius: 8px;
                border: 1px dashed ${colors.SECONDARY_200};
                width: 100%;
                cursor: pointer;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  flex: 1 0 0;
                  align-self: stretch;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    width: 48px;
                    height: 48px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: ${colors.PRIMARY};
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      width: 24px;
                      height: 24px;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      border-radius: 4px;
                      background: ${colors.WHITE};
                    `}
                  >
                    <PlusOutlined
                      css={css`
                        font-size: 24px;
                        color: ${colors.HEADER};
                        background-color: ${colors.PRIMARY};
                      `}
                    />
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 4px;
                    `}
                  >
                    <Text
                      variant='paragraphSmall'
                      weight='regular'
                      color={colors.NEUTRAL_0}
                    >
                      Custom Cover
                    </Text>
                    <Text
                      variant='paragraphXSmall'
                      weight='medium'
                      color={colors.NEUTRAL_100}
                    >
                      (Recommended: 1440x300)
                    </Text>
                  </div>
                  <Text
                    variant='paragraphSmall'
                    weight='medium'
                    color={colors.PRIMARY}
                  >
                    Click to Upload
                  </Text>
                </div>
              </div>
            </div>
          </Upload>
        )}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 24px;
          align-self: stretch;
        `}
      >
        <SwitchInput
          title='Dark Mode'
          subtitle='All Texts Will Be Presented In Black'
          value={state.darkMode}
          onChange={(value) => {
            setState({ ...state, darkMode: value })
          }}
        />
      </div>
    </div>
  )
}

export default Customization
