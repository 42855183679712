/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'

import { Flex } from '../Layout'
import { FormButton } from '../Form/css'
import { Mobile } from '../Responsive'
import { paragraphMedium, weightRegular } from '../css'
import colors from '@sportninja/common/constants/appColors'

const AuthSecondaryLink = ({ buttonText, onClick, text }) => {
  return (
    <Mobile>
      {(isMobile) => (
        <Flex
          noFlex
          alignItems={isMobile ? 'flex-start' : 'center'}
          justifyContent='space-between'
          css={css`
            margin-top: ${isMobile ? '40' : '100'}px;
          `}
          column={isMobile}
        >
          <div
            css={css`
              ${paragraphMedium}
              ${weightRegular}
              color: ${colors.NEUTRAL_50};
              width: auto;
              margin-right: auto;
            `}
          >
            {text}
          </div>
          <FormButton
            onClick={onClick}
            css={css`
              height: 42px;
              flex-grow: 1;
              width: ${!isMobile && 'unset'};
              margin-left: ${!isMobile && '28px'};
              margin-top: ${isMobile && '16px'};
            `}
          >
            {buttonText}
          </FormButton>
        </Flex>
      )}
    </Mobile>
  )
}

export default AuthSecondaryLink
