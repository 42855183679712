/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import scoringActions from '@sportninja/common/actions/scoring'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import { ACTIONS } from '@sportninja/common/reducers/game-timeline-parser'
import { t } from '@sportninja/common/i18n'
import dayjs from 'dayjs'

import Sheet from '../../../components/Sheet'
import Form from '../../../components/Form'
import { SelectFormInput } from '../../../components/Select'
import {
  scoringGoalForm,
  scoringGoalieChangeForm,
  scoringPenaltyForm,
} from '../../../components/Form/form-configs'
import { FormButton, FormRow } from '../../../components/Form/css'
import { Mobile } from '../../../components/Responsive'
import Icon from '../../../components/Icon'
import { font } from '../../../components/css'
import { getPlayingRoster } from '.'

const findPlayerById = (players, id) => {
  if (!players) {
    return null
  }
  if (!id) {
    return null
  }
  return players[id]
}

const EventForm = ({
  createGoal,
  createGoalChange,
  createPenalty,
  gameId,
  onCancel,
  periods,
  onComplete,
  roster,
  teamId,
  toggleOpen,
  type,
  started_at,
  isSoccer = false,
  homeGameRoster = [],
  visitingGameRoster = [],
  sportId = null,
}) => {
  let form
  let onSubmit

  switch (type) {
    case ACTIONS.goal: {
      form = scoringGoalForm(
        { periods, roster, started_at },
        { includePeriod: true },
        isSoccer,
        sportId
      )
      onSubmit = async (form) => {
        const { period, ...body } = form
        if (body.player_id === 'unassigned') {
          body.player_id = null
          body.is_bench_edit = true
        }
        await createGoal(gameId, period, { ...body, team_id: teamId })
        await onComplete()
        toggleOpen({ forceClose: true })
      }
      break
    }
    case ACTIONS.goalieChange: {
      form = scoringGoalieChangeForm(
        { periods, roster },
        { includePeriod: true },
        isSoccer,
        sportId
      )
      onSubmit = async (form) => {
        const { period, ...body } = form
        const player = findPlayerById(roster, body.goalie_id)
        body.player_id = body.goalie_id || null
        body.previous_player_id = body.previous_goalie_id || null
        body.player_type_id = player?.playerTypeId || null
        await createGoalChange(gameId, period, { ...body, team_id: teamId })
        await onComplete()
        toggleOpen({ forceClose: true })
      }
      break
    }
    case ACTIONS.penalty: {
      form = scoringPenaltyForm(
        { periods, roster },
        { includePeriod: true },
        isSoccer,
        sportId
      )
      onSubmit = async (form) => {
        const { period, ...body } = form
        await createPenalty(gameId, period, { ...body, team_id: teamId })
        await onComplete()
        toggleOpen({ forceClose: true })
      }
      break
    }
    default:
      return false
  }

  return <Form key={type} form={form} onCancel={onCancel} onSubmit={onSubmit} />
}

const mapDispatchToProps = (dispatch) => {
  return {
    createGoal: bindActionToPromise(
      dispatch,
      scoringActions.goals.create.request
    ),
    createGoalChange: bindActionToPromise(
      dispatch,
      scoringActions.goalieChanges.create.request
    ),
    createPenalty: bindActionToPromise(
      dispatch,
      scoringActions.penalties.create.request
    ),
  }
}

const ConnectedEventForm = connect(null, mapDispatchToProps)(EventForm)

const AddNewTimelineEvent = ({
  league_game_number,
  started_at,
  ended_at,
  homeTeam,
  homeGameRoster,
  gameId,
  periods,
  visitingTeam,
  visitingGameRoster,
  disabled,
  onComplete,
  isSoccer = false,
  sportId = null,
}) => {
  const TYPES = {
    [ACTIONS.goal]: {
      label: t('common:goal'),
      title: t('Web:addNewGoal'),
    },
    [ACTIONS.goalieChange]: {
      label: isSoccer ? 'Substitution' : t('common:goalieChange'),
      title: isSoccer ? 'Add new Substitution' : t('Web:addNewGoalieChange'),
    },
    [ACTIONS.penalty]: {
      label: isSoccer ? 'Foul' : t('common:penalty'),
      title: isSoccer ? 'Add new Foul' : t('Web:addNewPenalty'),
    },
  }

  const [type, setType] = useState('')
  const [teamId, setTeamId] = useState('')

  const title = type !== '' ? TYPES[type].title : t('Web:addNewEvent')
  const onTypeChange = ({ target }) => setType(target.value)
  const onTeamChange = ({ target }) => setTeamId(target.value)

  let roster = {}
  if (homeTeam && visitingTeam) {
    roster = getPlayingRoster(
      teamId === homeTeam.id ? homeGameRoster : visitingGameRoster
    )
  }

  const handleCancel = (onClick) => {
    onClick()
    setType('')
    setTeamId('')
  }

  return (
    <Sheet
      button={(toggleOpen) => (
        <div
          css={css`
            width: 170px;
            min-width: 125px;
            margin-left: 8px;
          `}
        >
          <FormButton
            disabled={disabled}
            onClick={handleCancel.bind(this, toggleOpen)}
            css={css`
              height: 40px;
            `}
          >
            Add Event
            <Icon
              name='plus'
              css={css`
                margin-left: 12px;
              `}
            />
          </FormButton>
        </div>
      )}
      title={title}
    >
      {(toggleOpen) => {
        return (
          <Fragment>
            <div
              css={css`
                margin-top: -16px;
                margin-bottom: 30px;
                font-size: 13px;
                line-height: 14px;
              `}
            >
              <div
                css={css`
                  font-size: 15px;
                  ${font.title}
                  font-weight: 700;
                  margin-bottom: 4px;
                `}
              >
                Game #{league_game_number}
              </div>
              <div>Home: {homeTeam?.name_full}</div>
              <div>Visitor: {visitingTeam?.name_full}</div>
              <div>Start: {dayjs(started_at).format('MMM D, h:mm a z')}</div>
              <div>End: {dayjs(ended_at).format('MMM D, h:mm a z')}</div>
            </div>
            <Mobile>
              {(isMobile) => (
                <FormRow
                  css={css`
                    margin-bottom: 20px;
                  `}
                  noFlex
                  column={isMobile}
                >
                  <SelectFormInput
                    name='type'
                    label={t('Web:typeOfEvent')}
                    onChange={onTypeChange}
                    required
                  >
                    <option value='' disabled>
                      {t('Web:selectTypeOfEvent')}:
                    </option>
                    <option disabled>-----</option>
                    {Object.keys(TYPES).map((key) => {
                      return (
                        <option key={key} value={key}>
                          {TYPES[key].label}
                        </option>
                      )
                    })}
                  </SelectFormInput>
                  <SelectFormInput
                    name='team'
                    label={t('util:capitalize', { text: t('common:team') })}
                    onChange={onTeamChange}
                    required
                  >
                    <option value='' disabled>
                      {t('Web:selectATeam')}:
                    </option>
                    <option disabled>-----</option>
                    {homeTeam && (
                      <option value={homeTeam.id}>{homeTeam.name_full}</option>
                    )}
                    {visitingTeam && (
                      <option value={visitingTeam.id}>
                        {visitingTeam.name_full}
                      </option>
                    )}
                  </SelectFormInput>
                </FormRow>
              )}
            </Mobile>

            {type !== '' && teamId !== '' && (
              <ConnectedEventForm
                gameId={gameId}
                onCancel={handleCancel.bind(this, toggleOpen)}
                periods={periods}
                roster={roster}
                teamId={teamId}
                toggleOpen={toggleOpen}
                type={type}
                onComplete={onComplete}
                started_at={started_at}
                isSoccer={isSoccer}
                homeGameRoster={homeGameRoster}
                visitingGameRoster={visitingGameRoster}
                sportId={sportId}
              />
            )}
          </Fragment>
        )
      }}
    </Sheet>
  )
}

export default AddNewTimelineEvent
