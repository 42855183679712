/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import CurrencyInputField from 'react-currency-input-field'
import Text from '../Text'
import { paragraphMedium, weightRegular } from 'src/components/css'
import { ErrorLabel } from '../ErrorLabel'
import { SerializedStyles } from '@emotion/react'
import { useState, useEffect } from 'react'

type CurrencyInputProps = {
  label: string
  value: number
  disabled?: boolean
  onChange: (value: number) => void
  min?: number
  fieldErrors?: string[]
  customError?: string
  name: string
  selectStyle?: SerializedStyles
}

/**
 * CurrencyInput component that uses react-currency-input-field
 * Always displays in CAD/USD format with 2 decimal places
 * Only triggers onChange on blur to preserve decimal formatting
 *
 * @param {CurrencyInputProps} props - The component props
 * @returns {JSX.Element} - The rendered component
 */
const CurrencyInput = ({
  label,
  value,
  disabled,
  onChange,
  min = 0,
  fieldErrors = [],
  customError = '',
  name,
  selectStyle,
}: CurrencyInputProps) => {
  // Convert the value to string for the input field
  // This ensures proper handling of values like 0
  const stringValue = value?.toString() || '0'

  // Internal state to track input value during typing
  const [internalValue, setInternalValue] = useState(stringValue)

  /**
   * Ensures the value has decimal places to avoid formatting issues
   * @param {string} value - The value to format
   * @returns {string} - The formatted value with decimal places
   */
  const ensureDecimalFormat = (value: string): string => {
    // If the value doesn't contain a decimal point, add ".00"
    if (!value.includes('.')) {
      return `${value}.00`
    }
    return value
  }

  // Update internal value when prop value changes
  useEffect(() => {
    setInternalValue(ensureDecimalFormat(stringValue))
  }, [stringValue])

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: ${label ? '8px' : '0'};
        flex: 1 0 0;
        align-self: stretch;
        width: 100%;
      `}
    >
      <Text
        variant='paragraphSmall'
        color={colors.WHITE}
        weight='regular'
        style={`
          align-self: stretch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
      >
        {label}
      </Text>
      <CurrencyInputField
        min={min}
        id={name}
        name={name}
        prefix='$'
        fixedDecimalLength={2}
        placeholder='$0.00'
        value={internalValue}
        decimalsLimit={2}
        decimalScale={2}
        decimalSeparator='.'
        groupSeparator=','
        allowDecimals={true}
        onValueChange={(v) => {
          // Update internal value during typing without triggering parent onChange
          setInternalValue(v || '0')
        }}
        onBlur={() => {
          // Only trigger parent onChange when the field loses focus
          onChange(parseFloat(internalValue))
        }}
        disabled={disabled}
        allowNegativeValue={false}
        step={1}
        css={[
          css`
            height: 40px !important;
            border-radius: 8px;
            border: 1px solid ${colors.SECONDARY_100};
            background: ${colors.SECONDARY_200};
            width: 100%;
            padding: 6px 8px !important;
            ${paragraphMedium}
            ${weightRegular}
            ${fieldErrors[name] || fieldErrors?.includes(name)
              ? `border-color: ${colors.ERROR};`
              : ''}
            ${disabled
              ? `color: ${colors.NEUTRAL_100};`
              : `color: ${colors.WHITE};`}
            &:focus {
              outline: none;
              border-color: ${colors.PRIMARY_100};
              box-shadow: 0 0 0 1px ${colors.PRIMARY_100};
            }
            &::placeholder {
              color: ${colors.NEUTRAL_100};
            }
            transition: border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
          `,
          selectStyle,
        ]}
      />
      <ErrorLabel
        fieldErrors={fieldErrors}
        customError={customError}
        name={name}
      />
    </div>
  )
}

export default CurrencyInput
