/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  SettingFilled,
  PlusCircleFilled,
  MoreOutlined,
  UpOutlined,
  DownOutlined,
  SwapOutlined,
} from '@ant-design/icons'
import { Dropdown, Table, Tooltip, App } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import Text from '../../Text'
import { formatCurrency } from '@sportninja/common/utils/utils'
import { RegistrationTableDivision } from 'src/pages/Schedule/PlayerRegistration/helpers'
import React, { useCallback, useMemo, useState } from 'react'
import {
  Currency,
  RegisteredPlayer,
} from '@sportninja/common/types/Registration'
import {
  paragraphXSmall,
  weightRegular,
  paragraphMedium,
} from 'src/components/css'
import PlayerDetailsTable from './PlayerDetailsTable'
import ManageTeamsModal from './ManageTeamsModal'
import useWindowSize from 'src/hooks/useWindowSize'

// Styles
const tableStyles = css`
  .ant-table {
    background: transparent;
  }

  .ant-table {
    border-radius: 8px;
    overflow: hidden;
  }
  .ant-table-thead {
    height: 32px !important;
  }
  .ant-table-thead > tr > th {
    ${paragraphXSmall}
    ${weightRegular}
    background-color: ${colors.SECONDARY_300};
    color: ${colors.NEUTRAL_70};
    text-transform: uppercase;
    white-space: nowrap;
  }
  .ant-table-thead > tr > th:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .ant-table-thead > tr > th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .ant-table-thead > tr > td {
    ${paragraphMedium}
    ${weightRegular}
    color: ${colors.WHITE};
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-table-tbody {
    background-color: transparent;
  }
  .ant-table-tbody > tr {
    border-radius: 8px;
  }
  .ant-table-tbody > tr:nth-of-type(even) {
    background-color: ${colors.SECONDARY_300};
  }
  .ant-table-tbody > tr:nth-of-type(odd) {
    background-color: ${colors.SECONDARY_300};
  }
  .ant-table-tbody > tr > td:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .ant-table-tbody > tr > td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .ant-table-tbody > tr:last-child > td {
    border-bottom: none !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 3px solid ${colors.SECONDARY_500} !important;
  }
  .ant-table-cell {
    vertical-align: middle !important;
  }
  th.ant-table-cell::before {
    display: none !important;
  }
  .expanded-row {
    background-color: #181d26 !important;
  }
  table {
    min-width: 800px;
  }
`

// Types
interface DivisionTableProps {
  DeleteBucketButton: any
  division: RegistrationTableDivision
  index: number
  openDivisions: { [key: number]: boolean }
  toggleDivision: (index: number) => void
  onPressDivisionSetup: (division: RegistrationTableDivision) => void
  setAdminTeamRegisterModalOpen: (open: boolean) => void
  setCurrentDivision: (division: RegistrationTableDivision | null) => void
  columns: any
  getFilteredTeams: (teams: any[]) => any[]
  teamToCancel: any
  onRegStatusChange: (props: any) => void
  message: any
  DeleteButton: any
  currency: Currency
  scheduleId: string
  setDivisionToDelete: (division: RegistrationTableDivision) => void
  setIsDivisionCloneOpen: (isOpen: boolean) => void
  setDivisionToClone: (division: RegistrationTableDivision) => void
  setIsRegistrationDetailsModalOpen: (isOpen: boolean) => void
  setRegistrationDetailId: (id: string) => void
  handlePlayerStatusChange: (
    value: number,
    playerId: string,
    player: any,
    divisionId: string
  ) => void
  loadingPlayerIds: string[]
  playerToCancel: any
  onPlayerRegStatusChange: (props: any) => void
  PlayerDeleteButton: any
  getFilteredPlayers: (players: RegisteredPlayer[]) => RegisteredPlayer[]
  refetch: (displayLoading?: boolean) => void
  setIsBucketModalOpen: (open: boolean) => void
  setBucketScheduleRegistrationId: (id: string) => void
  allDivisions: RegistrationTableDivision[]
  setScheduleRegistrationId: (id: string) => void
  selectedRowKeys: React.Key[]
  setSelectedRowKeys: (keys: React.Key[]) => void
  selectedPlayers: RegisteredPlayer[]
  setSelectedPlayers: (players: RegisteredPlayer[]) => void
  setIsManagePlayersModalOpen: (isOpen: boolean) => void
  expandedRows: string[]
  setExpandedRows: (rows: string[]) => void
}

// Helper function to calculate color based on revenue value
const getRevenueColor = (
  revenue: number,
  allDivisions: RegistrationTableDivision[]
) => {
  const revenues = allDivisions
    .filter((d) => d.revenue !== undefined && d.revenue !== null)
    .map((d) => d.revenue)

  if (revenues.length === 0) {
    return colors.NEUTRAL_0
  }

  const min = Math.min(...revenues)
  const max = Math.max(...revenues)
  const range = max - min

  // Calculate 30% thresholds
  const lowerThreshold = min + range * 0.3
  const upperThreshold = max - range * 0.3

  // If its 0, use NEUTRAL_0
  if (revenue === 0) {
    return colors.NEUTRAL_0
  }

  // If value is within 30% of min, use SUCCESS_75
  if (revenue <= lowerThreshold) {
    return colors.SUCCESS_75
  }

  // If value is within 30% of max, use SUCCESS_200
  if (revenue >= upperThreshold) {
    return colors.SUCCESS_200
  }

  // For values in between thresholds, use SUCCESS_100
  return colors.SUCCESS_100
}

/**
 * DivisionTable Component
 * Displays division information and registered teams with expandable player details
 */
const DivisionTable: React.FC<DivisionTableProps> = ({
  division,
  index,
  openDivisions,
  toggleDivision,
  onPressDivisionSetup,
  setAdminTeamRegisterModalOpen,
  setCurrentDivision,
  columns,
  getFilteredTeams,
  getFilteredPlayers,
  currency,
  scheduleId,
  setDivisionToDelete,
  setIsDivisionCloneOpen,
  setDivisionToClone,
  setIsRegistrationDetailsModalOpen,
  setRegistrationDetailId,
  handlePlayerStatusChange,
  loadingPlayerIds,
  refetch,
  setIsBucketModalOpen,
  setBucketScheduleRegistrationId,
  allDivisions,
  setScheduleRegistrationId,
  selectedRowKeys,
  setSelectedRowKeys,
  selectedPlayers,
  setSelectedPlayers,
  setIsManagePlayersModalOpen,
  expandedRows,
  setExpandedRows,
}) => {
  const [selectedTeamKeys, setSelectedTeamKeys] = useState<React.Key[]>([])
  const [isManageTeamsModalOpen, setIsManageTeamsModalOpen] = useState(false)
  const { message } = App.useApp()

  // Memoize filtered teams
  const filteredTeams = useMemo(() => {
    const teams = getFilteredTeams(division?.teams)?.sort((a, b) => {
      if (a.is_bucket && !b.is_bucket) {
        return -1
      }
      if (!a.is_bucket && b.is_bucket) {
        return 1
      }
      return 0
    })
    return teams || []
  }, [division?.teams, getFilteredTeams])

  // Expand/collapse all rows when division is expanded/collapsed
  // useEffect(() => {
  //   if (openDivisions[index]) {
  //     // Combine filter and map into a single reduce operation to avoid multiple array iterations
  //     // This improves performance by only traversing the array once
  //     const expandedTeamIds = filteredTeams.reduce((acc, team) => {
  //       if (team.registered_players?.length > 0) {
  //         acc.push(team.id)
  //       }
  //       return acc
  //     }, [] as string[])
  //     // Set expanded rows state with the collected IDs
  //     setExpandedRows((prev) => [...prev, ...expandedTeamIds])
  //   } else {
  //     setExpandedRows([])
  //   }
  // }, [openDivisions, index, filteredTeams])

  // Handlers
  const handleExpandRow = useCallback(
    (record: any) => (
      <PlayerDetailsTable
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedPlayers={selectedPlayers}
        setSelectedPlayers={setSelectedPlayers}
        players={getFilteredPlayers(record.registered_players)}
        teamId={record.id}
        teamRosterId={record.team_roster_id}
        currency={currency}
        scheduleId={scheduleId}
        divisionId={record.division_id}
        setIsRegistrationDetailsModalOpen={setIsRegistrationDetailsModalOpen}
        setRegistrationDetailId={setRegistrationDetailId}
        handlePlayerStatusChange={handlePlayerStatusChange}
        loadingPlayerIds={loadingPlayerIds}
        refetch={refetch}
        setIsManagePlayersModalOpen={setIsManagePlayersModalOpen}
      />
    ),
    [
      selectedRowKeys,
      selectedPlayers,
      getFilteredPlayers,
      currency,
      scheduleId,
      handlePlayerStatusChange,
      loadingPlayerIds,
      refetch,
    ]
  )

  const renderExpandedRow = useCallback(
    (record: any) => (
      <div
        css={css`
          /* margin-left: 40px; */
        `}
      >
        {handleExpandRow(record)}
      </div>
    ),
    [handleExpandRow]
  )

  const handleRegistrationClick = useCallback(() => {
    if (division?.registration === null) {
      return
    }
    setScheduleRegistrationId(division?.registration?.id)
    setAdminTeamRegisterModalOpen(true)
    setCurrentDivision(division)
  }, [
    division,
    setAdminTeamRegisterModalOpen,
    setCurrentDivision,
    setScheduleRegistrationId,
  ])

  // Handle row selection changes
  const handleTeamSelectionChange = useCallback(
    (selectedKeys: React.Key[], _selectedRows: any[]) => {
      setSelectedTeamKeys(selectedKeys)
    },
    []
  )

  // Row selection configuration
  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedTeamKeys,
      onChange: handleTeamSelectionChange,
      type: 'checkbox' as const,
      getCheckboxProps: (record: any) => ({
        disabled: record.registration_status === 5 || record.is_bucket, // Disable selection for cancelled teams and buckets
      }),
    }),
    [selectedTeamKeys, handleTeamSelectionChange]
  )

  // Handle team movement
  const handleTeamMove = useCallback(() => {
    if (selectedTeamKeys.length === 0) {
      message.warning('Please select teams to move.')
      return
    }
    setIsManageTeamsModalOpen(true)
  }, [selectedTeamKeys, filteredTeams])

  const handleClearTeamSelection = useCallback(() => {
    setSelectedTeamKeys([])
  }, [])

  // Memoize the table rendering
  const teamsTable = useMemo(() => {
    if (!filteredTeams?.length || !openDivisions[index]) {
      return null
    }

    // Add move icon to the last column when teams are selected
    const enhancedColumns = [...columns]
    if (enhancedColumns.length > 0) {
      const lastColumnIndex = enhancedColumns.length - 1
      enhancedColumns[lastColumnIndex] = {
        ...enhancedColumns[lastColumnIndex],
        title: (
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding-right: 8px;
              width: 100%;
            `}
          >
            <Tooltip
              title={
                selectedTeamKeys.length > 0
                  ? 'Move Teams'
                  : 'Select At Least One Team To Move'
              }
            >
              <SwapOutlined
                css={css`
                  color: ${selectedTeamKeys.length > 0
                    ? colors.PRIMARY
                    : colors.NEUTRAL_100};
                  font-size: 20px;
                  cursor: ${selectedTeamKeys.length > 0
                    ? 'pointer'
                    : 'not-allowed'};
                  &:hover {
                    opacity: ${selectedTeamKeys.length > 0 ? '0.8' : '1'};
                  }
                `}
                onClick={(e) => {
                  e.stopPropagation()
                  if (selectedTeamKeys.length > 0) {
                    handleTeamMove()
                  }
                }}
              />
            </Tooltip>
          </div>
        ),
      }
    }

    return (
      <div
        className='teams-table-wrapper'
        css={css`
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          margin-top: 4px;

          .ant-table-row-expand-icon-cell {
            width: 20px !important;
            padding: 4px 4px !important;
          }

          .ant-table-selection-column {
            padding-left: 4px !important;
            width: 28px !important;
            min-width: 28px !important;
          }
          .ant-checkbox-wrapper {
            margin-left: -8px;
          }
          .ant-table-row-expand-icon-cell {
            padding-right: 0 !important;
          }
          .ant-table-expand-icon-col {
            width: 30px !important;
          }
        `}
      >
        <Table
          css={tableStyles}
          rowSelection={rowSelection}
          columns={enhancedColumns}
          dataSource={filteredTeams}
          pagination={false}
          size='small'
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: ' ' }}
          expandable={{
            expandedRowRender: renderExpandedRow,
            expandedRowKeys: expandedRows,
            onExpandedRowsChange: (expandedRowsKeys) => {
              setExpandedRows(expandedRowsKeys as string[])
            },
            rowExpandable: (record) =>
              getFilteredPlayers(record.registered_players)?.length > 0,
            indentSize: 0,
            expandedRowClassName: () => 'expanded-row',
          }}
        />
      </div>
    )
  }, [
    filteredTeams,
    openDivisions,
    index,
    columns,
    renderExpandedRow,
    expandedRows,
    getFilteredPlayers,
    rowSelection,
    selectedTeamKeys,
    handleTeamMove,
  ])

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
      `}
    >
      <MemoizedDivisionHeader
        division={division}
        index={index}
        openDivisions={openDivisions}
        toggleDivision={toggleDivision}
        onPressDivisionSetup={onPressDivisionSetup}
        handleRegistrationClick={handleRegistrationClick}
        setDivisionToDelete={setDivisionToDelete}
        setIsDivisionCloneOpen={setIsDivisionCloneOpen}
        setDivisionToClone={setDivisionToClone}
        setIsBucketModalOpen={setIsBucketModalOpen}
        setBucketScheduleRegistrationId={setBucketScheduleRegistrationId}
        allDivisions={allDivisions}
        setScheduleRegistrationId={setScheduleRegistrationId}
      />
      {teamsTable}
      <ManageTeamsModal
        isOpen={isManageTeamsModalOpen}
        onClose={() => setIsManageTeamsModalOpen(false)}
        selectedTeams={filteredTeams.filter((team) =>
          selectedTeamKeys.includes(team.team_roster_id)
        )}
        onClearSelection={handleClearTeamSelection}
        allDivisions={allDivisions}
        onSuccess={() => {
          setIsManageTeamsModalOpen(false)
          refetch()
        }}
        originDivisionId={division.id}
      />
    </div>
  )
}

// Subcomponents
interface DivisionHeaderProps {
  division: RegistrationTableDivision
  index: number
  openDivisions: { [key: number]: boolean }
  toggleDivision: (index: number) => void
  onPressDivisionSetup: (division: RegistrationTableDivision) => void
  handleRegistrationClick: () => void
  setDivisionToDelete: (division: RegistrationTableDivision) => void
  setIsDivisionCloneOpen: (isOpen: boolean) => void
  setDivisionToClone: (division: RegistrationTableDivision) => void
  setIsBucketModalOpen: (isOpen: boolean) => void
  setBucketScheduleRegistrationId: (id: string) => void
  allDivisions: RegistrationTableDivision[]
  setScheduleRegistrationId: (id: string) => void
}

const renderHeaderCell = (
  label: string,
  value: string | number | null,
  divisionNotSet: boolean = false,
  allDivisions?: RegistrationTableDivision[],
  isRevenue: boolean = false,
  revenue?: number,
  isMoney: boolean = false
) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      align-items: ${isMoney ? 'flex-end' : 'flex-start'};
      flex: 1 0 0;
      @media (max-width: 768px) {
        width: 100%;
      }
    `}
  >
    <Text
      variant='paragraphXSmall'
      weight='regular'
      style={`
        align-self: stretch;
        color: ${colors.NEUTRAL_100};
        text-transform: uppercase;
      `}
    >
      {label}
    </Text>
    {value ? (
      <Text
        variant='paragraphSmall'
        weight='regular'
        style={`
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          align-self: stretch;
          overflow: hidden;
          color: ${
            isRevenue && revenue !== undefined && allDivisions
              ? getRevenueColor(revenue, allDivisions)
              : colors.WHITE
          };
          text-overflow: ellipsis;
        `}
      >
        {value}
      </Text>
    ) : null}
    {divisionNotSet ? (
      <Text
        variant='paragraphSmall'
        weight='regular'
        color={colors.PRIMARY}
        style={`
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          align-self: stretch;
          overflow: hidden;
          color: ${colors.PRIMARY};
          text-overflow: ellipsis;
        `}
      >
        Not Set
      </Text>
    ) : null}
  </div>
)

const renderMoneyHeaderCell = (
  label: string,
  value: string | number | null,
  divisionNotSet: boolean = false,
  allDivisions?: RegistrationTableDivision[],
  isRevenue: boolean = false,
  revenue?: number
) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      flex: 1 0 0;
      @media (max-width: 768px) {
        width: 100%;
        align-items: flex-end;
        text-align: right;
      }
    `}
  >
    <Text
      variant='paragraphXSmall'
      weight='regular'
      style={`
        align-self: stretch;
        color: ${colors.NEUTRAL_100};
        text-transform: uppercase;
      `}
    >
      {label}
    </Text>
    {value ? (
      <Text
        variant='paragraphSmall'
        weight='regular'
        style={`
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          align-self: stretch;
          overflow: hidden;
          color: ${
            isRevenue && revenue !== undefined && allDivisions
              ? getRevenueColor(revenue, allDivisions)
              : colors.WHITE
          };
          text-overflow: ellipsis;
        `}
      >
        {value}
      </Text>
    ) : null}
    {divisionNotSet ? (
      <Text
        variant='paragraphSmall'
        weight='regular'
        color={colors.PRIMARY}
        style={`
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          align-self: stretch;
          overflow: hidden;
          color: ${colors.PRIMARY};
          text-overflow: ellipsis;
        `}
      >
        Not Set
      </Text>
    ) : null}
  </div>
)

const DivisionHeader: React.FC<DivisionHeaderProps> = ({
  division,
  index,
  openDivisions,
  toggleDivision,
  onPressDivisionSetup,
  handleRegistrationClick,
  setDivisionToDelete,
  setIsDivisionCloneOpen,
  setDivisionToClone,
  setIsBucketModalOpen,
  setBucketScheduleRegistrationId,
  allDivisions,
}) => {
  const divisionNotSet = useMemo(
    () => division?.registration === null,
    [division?.registration]
  )
  const isSeason = useMemo(() => division?.isSeason, [division?.isSeason])
  const hasTeams = useMemo(
    () =>
      division?.teams?.filter((t) => t?.registration_status !== 5)?.length > 0,
    [division?.teams]
  )
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < 768, [width])

  const handleToggle = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (isSeason || divisionNotSet || !hasTeams) {
        return
      }
      toggleDivision(index)
    },
    [isSeason, divisionNotSet, hasTeams, toggleDivision, index]
  )

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        background: ${colors.SECONDARY_300};
        min-height: 53px;
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          padding: 16px;
        }
      `}
    >
      <div
        css={css`
          opacity: ${divisionNotSet || isSeason ? 0.5 : 1};
          display: flex;
          padding: 8px 16px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          border-radius: 8px;
          background: ${colors.SECONDARY_200};
          min-height: 53px;
          @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            padding: 16px;
          }
          width: 100%;
        `}
      >
        {openDivisions[index] ? (
          <DownOutlined
            onClick={handleToggle}
            css={css`
              color: ${colors.WHITE};
              font-size: 16px;
              cursor: ${isSeason || divisionNotSet || !hasTeams
                ? 'not-allowed !important'
                : 'pointer !important'};
              opacity: ${isSeason || divisionNotSet || !hasTeams ? 0.5 : 1};
              @media (max-width: 768px) {
                align-self: flex-end;
              }
            `}
          />
        ) : (
          <UpOutlined
            onClick={handleToggle}
            css={css`
              transform: rotate(90deg);
              color: ${colors.WHITE};
              font-size: 16px;
              cursor: ${isSeason || divisionNotSet || !hasTeams
                ? 'not-allowed !important'
                : 'pointer !important'};
              opacity: ${isSeason || divisionNotSet || !hasTeams ? 0.5 : 1};
              @media (max-width: 768px) {
                align-self: flex-end;
              }
            `}
          />
        )}

        {renderHeaderCell('Division', division?.name, false)}
        {renderHeaderCell('Gender', division?.gender, divisionNotSet)}
        {renderHeaderCell(
          'Players',
          divisionNotSet ? null : division?.totalPlayers?.toString(),
          divisionNotSet
        )}
        {renderHeaderCell(
          'Teams',
          divisionNotSet ? null : division?.totalTeams?.toString(),
          divisionNotSet
        )}
        {renderHeaderCell(
          'Unasgd Groups',
          divisionNotSet ? null : division?.bucketTeams?.toString(),
          divisionNotSet
        )}

        {!division?.singlePayment
          ? renderMoneyHeaderCell(
              'Deposit',
              divisionNotSet
                ? null
                : division?.deposit === null
                ? null
                : formatCurrency(
                    division?.deposit,
                    division?.currency?.name || 'CAD'
                  ),
              divisionNotSet
            )
          : renderHeaderCell('One Time Pymt', 'Yes', divisionNotSet)}

        {renderMoneyHeaderCell(
          'Team Cost',
          divisionNotSet
            ? null
            : division?.cost === null
            ? 'N/A'
            : formatCurrency(division?.cost, division?.currency?.name || 'CAD'),
          divisionNotSet
        )}

        {renderMoneyHeaderCell(
          'Player Cost',
          divisionNotSet
            ? null
            : division?.playerCost === null
            ? 'N/A'
            : formatCurrency(
                division?.playerCost,
                division?.currency?.name || 'CAD'
              ),
          divisionNotSet
        )}

        {renderMoneyHeaderCell(
          'Revenue',
          divisionNotSet
            ? null
            : formatCurrency(
                division?.revenue,
                division?.currency?.name || 'CAD'
              ),
          divisionNotSet,
          allDivisions,
          true,
          division?.revenue
        )}

        <div
          css={css`
            display: flex;
            align-items: flex-start;
            gap: 8px;
            @media (max-width: 768px) {
              width: 100%;
              justify-content: flex-end;
            }
          `}
        >
          {!divisionNotSet && !isSeason && (
            <Dropdown
              menu={{
                items: [
                  {
                    key: '1',
                    label: 'Add Team',
                    onClick: handleRegistrationClick,
                  },
                  {
                    key: '2',
                    label: 'Add Unassigned Group',
                    onClick: () => {
                      setBucketScheduleRegistrationId(
                        division?.registration?.id || ''
                      )
                      setIsBucketModalOpen(true)
                    },
                  },
                ],
              }}
              trigger={['click']}
              placement='bottomRight'
            >
              <PlusCircleFilled
                css={css`
                  color: ${colors.PRIMARY};
                  font-size: 20px;
                  cursor: pointer;
                `}
              />
            </Dropdown>
          )}
          {!divisionNotSet && !isSeason && (
            <>
              <Tooltip title='Division Settings' placement='top'>
                <SettingFilled
                  css={css`
                    color: ${colors.PRIMARY};
                    font-size: 20px;
                    cursor: pointer;
                  `}
                  onClick={() => onPressDivisionSetup(division)}
                />
              </Tooltip>
              <Tooltip title='More Actions' placement='top'>
                <Dropdown
                  menu={{
                    items: [
                      division?.registration !== null
                        ? {
                            key: '2',
                            label: 'Clone',
                            onClick: () => {
                              setDivisionToClone(division)
                              setIsDivisionCloneOpen(true)
                            },
                          }
                        : null,
                      {
                        key: '3',
                        label: 'Delete',
                        onClick: () => {
                          setDivisionToDelete(division)
                          document
                            .getElementById('delete-division-button')
                            ?.click()
                        },
                      },
                    ],
                  }}
                  trigger={['click']}
                  placement='bottomRight'
                >
                  <MoreOutlined
                    css={css`
                      color: ${colors.PRIMARY};
                      font-size: 20px;
                      cursor: pointer;
                    `}
                    onClick={() => {}}
                  />
                </Dropdown>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {divisionNotSet && (
        <Tooltip title='Division Settings' placement='top'>
          <SettingFilled
            onClick={() => onPressDivisionSetup(division)}
            css={css`
              color: ${colors.PRIMARY};
              font-size: 20px;
              cursor: pointer;
              opacity: 1;
              position: absolute;
              right: 104px;
              ${isMobile ? 'right: 32px;' : ''}
            `}
          />
        </Tooltip>
      )}
    </div>
  )
}

// Memoize DivisionHeader component
const MemoizedDivisionHeader = React.memo(DivisionHeader)

export default React.memo(DivisionTable)
