/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import Text from 'src/components/ts-components/Text'
import colors from '@sportninja/common/constants/appColors'
import { Modal } from 'antd'
import Button from 'src/components/ts-components/Button'
import useWindowSize from 'src/hooks/useWindowSize'
import { CloseOutlined } from '@ant-design/icons'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import DraggableItem from './DraggableItem'
import { StatType } from '@sportninja/common/types'

interface TiebreakerModalProps {
  isOpen: boolean
  onClose: () => void
  onSave: (selectedStats: number[]) => void
  currentStats: number[]
  statTypes: StatType[]
  defaultSortBy: number | null
}

const styles = css`
  .my-modal-body {
  }
  .my-modal-mask {
    backdrop-filter: blur(1px);
  }
  .my-modal-header {
    border-bottom: 1px dotted ${colors.FIERY_RED};
  }
  .my-modal-footer {
    color: ${colors.FIERY_RED};
  }
  .my-modal-content {
    border: 1px solid #333;
  }
`

const classNames = {
  body: styles['my-modal-body'],
  mask: styles['my-modal-mask'],
  header: styles['my-modal-header'],
  footer: styles['my-modal-footer'],
  content: styles['my-modal-content'],
}

const modalStyles = {
  header: {
    backgroundColor: colors.SECONDARY_300,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  body: {
    borderBottom: `1px solid ${colors.SOFT_STEEL}`,
    borderTop: `1px solid ${colors.SOFT_STEEL}`,
    backgroundColor: colors.SECONDARY_300,
  },
  content: {
    backgroundColor: colors.SECONDARY_300,
    paddingTop: '8px',
    paddingBottom: '16px',
    paddingLeft: '0',
    paddingRight: '0',
  },
  wrapper: {
    zIndex: 10002,
  },
  mask: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(6, 10, 16, 0.8)',
    zIndex: 10001,
  },
  footer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

/** Interface for the sortable items with all required properties */
interface SortableItem {
  id: string
  label: string
  value: number
  isEnabled: boolean
  description: string
  abbreviation: string
  sortPosition: number
}

const TiebreakerModal: React.FC<TiebreakerModalProps> = ({
  isOpen,
  onClose,
  onSave,
  currentStats,
  statTypes,
  defaultSortBy,
}) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width <= 768, [width])

  // Initialize items state with all statTypes and their enabled status
  const [items, setItems] = useState<SortableItem[]>(() => {
    // Filter out the defaultSortBy stat
    const filteredStats = statTypes.filter((stat) => stat.id !== defaultSortBy)

    // Create a map for quick lookup of stat positions in currentStats
    const currentStatsPositions = new Map(
      currentStats.map((statId, index) => [statId, index])
    )

    // Transform stats into items with additional properties
    const allItems = filteredStats.map((stat) => ({
      id: stat.id.toString(),
      label: stat.name,
      value: stat.id,
      isEnabled: currentStats.includes(stat.id),
      description: stat.description,
      abbreviation: stat.abbreviation,
      // Store the position from currentStats, or Infinity for disabled items
      sortPosition: currentStatsPositions.has(stat.id)
        ? currentStatsPositions.get(stat.id)!
        : Infinity,
    }))

    // Sort items based on enabled status and original currentStats order
    return allItems.sort((a, b) => {
      if (a.isEnabled && b.isEnabled) {
        // Both enabled - sort by their position in currentStats
        return a.sortPosition - b.sortPosition
      }
      if (a.isEnabled !== b.isEnabled) {
        // One is enabled, one is disabled
        return a.isEnabled ? -1 : 1
      }
      // Both disabled - sort alphabetically
      return a.label.localeCompare(b.label)
    })
  })

  // Effect to reorder items when their enabled state changes
  useEffect(() => {
    setItems((prevItems) => {
      const newItems = [...prevItems]
      return newItems.sort((a, b) => {
        if (a.isEnabled && b.isEnabled) {
          // Both enabled - maintain their current order
          return 0
        }
        if (a.isEnabled !== b.isEnabled) {
          // One is enabled, one is disabled
          return a.isEnabled ? -1 : 1
        }
        // Both disabled - sort alphabetically
        return a.label.localeCompare(b.label)
      })
    })
  }, [items.map((item) => item.isEnabled).join(',')])

  // Configure DnD sensors
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  // Handle drag end event
  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over.id)
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }, [])

  // Handle toggle switch with automatic reordering
  const handleToggle = useCallback((id: string, enabled: boolean) => {
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item) =>
        item.id === id ? { ...item, isEnabled: enabled } : item
      )

      // Sort items to put enabled ones at the top
      return updatedItems.sort((a, b) => {
        if (a.isEnabled === b.isEnabled) return 0
        return a.isEnabled ? -1 : 1
      })
    })
  }, [])

  // Handle save
  const handleSave = useCallback(() => {
    const enabledItems = items
      .filter((item) => item.isEnabled)
      .map((item) => item.value)
    onSave(enabledItems)
  }, [items, onSave])

  const getFooter = useCallback(() => {
    return (
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          gap: 16px;
          width: ${isMobile ? '100%' : '50%'};
          margin-left: ${isMobile ? '0' : 'auto'};
          @media (max-width: 768px) {
            width: 100%;
            flex-direction: column;
          }
        `}
      >
        <Button
          text='Cancel'
          onClick={() => {
            onClose()
          }}
          variant='secondary'
        />
        <Button
          text='Save'
          onClick={handleSave}
          variant='primary'
          disabled={false}
        />
      </div>
    )
  }, [isMobile, handleSave, onClose])

  const getHeader = useCallback(() => {
    return (
      <Text variant='heading3' weight='semiBold' color={colors.WHITE}>
        Customize Secondary Sorting Rules
      </Text>
    )
  }, [])

  const closeIcon = useCallback(() => {
    return (
      <CloseOutlined
        css={css`
          color: ${colors.SOLITUDE};
          font-size: 18px;
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            color: ${colors.WHITE};
          }
        `}
      />
    )
  }, [])

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      classNames={classNames}
      styles={modalStyles}
      css={css`
        display: flex;
        flex: 1;
        background: ${colors.SECONDARY_400} !important;
        height: ${isMobile ? '100vh' : '90vh'} !important;
        max-height: ${isMobile ? '100vh' : '1080px'} !important;
        margin: ${isMobile ? '0' : '24px'};
        padding: 0;
        max-width: ${isMobile ? '100%' : '40vw'};
        top: ${isMobile ? '0' : '50%'};
        .ant-modal-content {
          height: ${isMobile ? '100vh' : '90vh'} !important;
          max-height: ${isMobile ? '100vh' : '1080px'} !important;
          border-radius: ${isMobile ? '0' : '8px'};
          display: flex;
          flex-direction: column;
        }
        .ant-modal-body {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          padding: 24px;
          background: ${colors.SECONDARY_300};
        }
      `}
      width={isMobile ? '100%' : '40vw'}
      centered={!isMobile}
      maskClosable={false}
      closeIcon={closeIcon()}
      title={getHeader()}
      footer={getFooter()}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 0 8px;
          height: 100%;
          overflow-y: auto;
        `}
      >
        <Text
          variant='paragraphMedium'
          color={colors.NEUTRAL_0}
          weight='regular'
        >
          Drag and drop to set the order of secondary sorting rules for this
          competition.
          <br />
          Toggle options on or off to match the competition&apos;s requirements.
        </Text>
        <div
          css={css`
            width: 100%;
            border-bottom: 1px solid ${colors.SECONDARY_200};
          `}
        />
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={items.map((item) => item.id)}
            strategy={verticalListSortingStrategy}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
              `}
            >
              {items
                .map((item) => ({
                  ...item,
                  // Replace IN-DIV with DIV for sorting purposes
                  sortKey:
                    item.abbreviation === 'IN-DIV' ? 'DIV' : item.abbreviation,
                }))
                // .sort((a, b) => a.sortKey.localeCompare(b.sortKey))
                .map((item) => (
                  <DraggableItem
                    key={item.id}
                    id={item.id.toString()}
                    label={`${
                      item.abbreviation === 'IN-DIV' ? 'DIV' : item.abbreviation
                    } (${item.label})`}
                    subtitle={item.description}
                    isEnabled={item.isEnabled}
                    onToggle={handleToggle}
                  />
                ))}
            </div>
          </SortableContext>
        </DndContext>
      </div>
    </Modal>
  )
}

export default TiebreakerModal
