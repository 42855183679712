/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import styled from 'styled-components'
import { RightOutlined } from '@ant-design/icons'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const CardWrapper = styled.div`
  background-color: ${colors.SECONDARY_300};
  border: 1px solid ${colors.SECONDARY_200};
  border-radius: 8px;
  padding: 16px;
  width: 300px;
  color: white;
  width: 100%;
  margin-bottom: 16px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid ${colors.SOFT_STEEL};
  padding-bottom: 8px;
`

const Title = styled.h2`
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  color: ${colors.WHITE};
`

const ViewMore = styled.a`
  color: ${colors.PRIMARY};
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
`

const TeamInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Team = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
`

const TeamLogo = styled.img`
  width: 32px !important;
  height: 32px !important;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  object-fit: contain;
`

const TeamDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const TeamName = styled.span`
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: ${colors.WHITE};
  margin: 0;
`

const TeamStatus = styled.span`
  color: ${colors.NEUTRAL_50};
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  margin: 0;
`

const Score = styled.span<{ iswinner: string }>`
  font-size: 28px;
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
  font-weight: 600;
  line-height: 33px;
  color: ${colors.WHITE};
  border-bottom: ${({ iswinner }) =>
    iswinner === 'true' ? `2px solid ${colors.PRIMARY}` : 'none'};
  min-width: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const ViewMoreText = styled.p`
  color: ${colors.PRIMARY};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.4px;
  width: 100%;
`

interface LastGameProps {
  homeTeam: {
    name: string
    logo: string
    score: number
    team_id: string
  }
  visitorTeam: {
    name: string
    logo: string
    score: number
    team_id: string
  }
  game_id: string
}

const LastGameCard: React.FC<LastGameProps> = ({
  homeTeam,
  visitorTeam,
  game_id,
}) => {
  return (
    <CardWrapper>
      <Header>
        <Title>Last Game</Title>
        <span
          onClick={() => {
            window.location.href = `/game/${game_id}`
          }}
          css={css`
            cursor: pointer;
          `}
        >
          <ViewMore>
            <ViewMoreText>View more</ViewMoreText>
            <RightOutlined style={{ fontSize: '12px' }} />
          </ViewMore>
        </span>
      </Header>
      <TeamInfo>
        <Team href={`/team/${homeTeam.team_id}`}>
          <TeamLogo
            src={
              homeTeam.logo ||
              '/images/generic_placeholders/placeholder_team.png'
            }
            alt={`${homeTeam.name} logo`}
            onError={(e) => {
              e.currentTarget.src =
                '/images/generic_placeholders/placeholder_team.png'
            }}
          />
          <TeamDetails>
            <TeamName>{homeTeam.name}</TeamName>
            <TeamStatus>Home</TeamStatus>
          </TeamDetails>
        </Team>
        <Score iswinner={(homeTeam.score > visitorTeam.score).toString()}>
          {homeTeam.score.toString()}
        </Score>
      </TeamInfo>
      <div style={{ height: 16 }} />
      <TeamInfo>
        <Team href={`/team/${visitorTeam.team_id}`}>
          <TeamLogo
            src={
              visitorTeam.logo ||
              '/images/generic_placeholders/placeholder_team.png'
            }
            alt={`${visitorTeam.name} logo`}
            onError={(e) => {
              e.currentTarget.src =
                '/images/generic_placeholders/placeholder_team.png'
            }}
          />
          <TeamDetails>
            <TeamName>{visitorTeam.name}</TeamName>
            <TeamStatus>Visitor</TeamStatus>
          </TeamDetails>
        </Team>
        <Score iswinner={(visitorTeam.score > homeTeam.score).toString()}>
          {visitorTeam.score.toString()}
        </Score>
      </TeamInfo>
    </CardWrapper>
  )
}

export default LastGameCard
