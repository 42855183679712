import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useMemo,
} from 'react'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import {
  createSchedule,
  updateSchedule,
  updateScheduleSettings,
} from 'src/helpers/EntityCreationHelper'
import {
  SportSettings,
  StandingsSettings,
  StatType,
} from '@sportninja/common/types'
import { DaysmartSyncIntervalEnum } from '@sportninja/common/constants/DaysmartSyncInterval'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { MessageInstance } from 'antd/es/message/interface'
import { useHistory } from 'react-router-dom'

dayjs.extend(utc)

// Define the initial state interface
interface InitialState {
  is_tournament?: boolean
  daysmart_facilities_as_sub_resources?: boolean
  daysmart_send_invitation?: boolean
  daysmart_season_id?: string
  daysmart_sync_interval?: DaysmartSyncIntervalEnum
  daysmart_season_name?: string
  name_full?: string
  is_public?: boolean
  sport_id?: string
  image?: {
    full_path?: string
  }
  is_registration_on?: boolean
  is_youth_league?: boolean
  disable_team_chat?: boolean
  tax_rate?: number
  season_type?: any
  season_currency?: any
  online_registration_open?: boolean
  settings?: {
    tax_rate?: number
    season_type?: any
    season_currency?: any
    online_registration_open?: boolean
    game?: {
      scoring?: {
        require_time_game_event?: boolean
        require_goal_type?: boolean
        require_goal_configuration?: boolean
      }
      timing?: {
        default_period_length?: number
        default_overtime_length?: number
      }
      signatures?: {
        skip_official?: boolean
        skip_team_official?: boolean
      }
      skip_lineup?: boolean
      require_officials?: boolean
      require_unique_jersey_numbers?: boolean
      require_waiver_signature?: boolean
    }
    stats?: {
      goals?: {
        per?: {
          forfeit?: number
        }
      }
      points?: {
        wins?: number
        losses?: number
        ties?: number
        overtime_loss?: number
        shootout_loss?: number
        overtime_win?: number
      }
    }
    standings?: {
      sort_order?: {
        stat_id?: number
        tie_breaker_stats?: number[]
      }
    }
  }
  starts_at?: string
  ends_at?: string
  abbreviation?: string
  generic_sport_id?: string
  // TODO: Add background_image to the initial state
  background_image?: {
    full_path?: string
  }
  email?: string
  website?: string
  phone?: string
  support_email?: string
  support_website?: string
  support_phone?: string
  is_archive?: boolean
}

// Define the types for state variables
interface ScheduleState {
  sports?: any[]
  displayCanlanForm?: boolean
  id?: string
  sportOptions: any[]
  genericId: string | null
  isLoading: boolean
  error: string | null
  privacySettings: 'public' | 'private'
  imageChanged: boolean
  isEdit: boolean
  name: string
  abbreviation: string
  taxRate: number | null
  selectedSport: string | null | undefined
  sportId: string | null | undefined
  genericSportId: string | null | undefined
  competitionDateRange: {
    starts_at: string | Dayjs
    ends_at: string | Dayjs
  }
  playoffCompetition: boolean
  imageUrl: string | null
  selectedSeasonType: any
  onlineRegistrationOpen: boolean
  selectedSeasonCurrency: any
  isRegistrationEnabled: boolean
  skipTeamOfficialsSignatures: boolean
  skipOfficialsSignatures: boolean
  skipStartingLineupSelection: boolean
  requireGameOfficials: boolean
  timingPeriodLength: number | null
  timingExtraPeriodLength: number | null
  goalsPerForfeit: number | null
  wins: number | null
  losses: number | null
  ties: number | null
  overtimerWin: number | null
  overtimeLoss: number | null
  shootoutLoss: number | null
  fieldErrors: string[]
  customError: any
  contactInfo: {
    email?: string | null | undefined
    website?: string | null | undefined
    phone?: string | null | undefined
    support_email?: string | null | undefined
    support_website?: string | null | undefined
    support_phone?: string | null | undefined
  }
  requireWaiverSignature: boolean
  isYouthLeague: boolean
  disableTeamChat: boolean
  isArchive: boolean
  defaultSortBy: number | null
  tiebreakerStats: number[]
}

// Define the context type
interface ScheduleContextProps extends ScheduleState {
  backgroundImageChanged: boolean
  setBackgroundImageChanged: React.Dispatch<React.SetStateAction<boolean>>
  setSportOptions: React.Dispatch<React.SetStateAction<any[]>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<React.SetStateAction<string | null>>
  setPrivacySettings: React.Dispatch<React.SetStateAction<'public' | 'private'>>
  setImageChanged: React.Dispatch<React.SetStateAction<boolean>>
  setName: React.Dispatch<React.SetStateAction<string>>
  setAbbreviation: React.Dispatch<React.SetStateAction<string>>
  setTaxRate: React.Dispatch<React.SetStateAction<number | null>>
  setSelectedSport: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >
  setSportId: React.Dispatch<React.SetStateAction<string | null>>
  setGenericSportId: React.Dispatch<React.SetStateAction<string | null>>
  setCompetitionDateRange: React.Dispatch<
    React.SetStateAction<{
      starts_at: string
      ends_at: string
    }>
  >
  setPlayoffCompetition: React.Dispatch<React.SetStateAction<boolean>>
  setImageUrl: React.Dispatch<React.SetStateAction<string | null>>
  setSelectedSeasonType: React.Dispatch<React.SetStateAction<any>>
  setOnlineRegistrationOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedSeasonCurrency: React.Dispatch<React.SetStateAction<any>>
  setIsRegistrationEnabled: React.Dispatch<React.SetStateAction<boolean>>
  setSkipTeamOfficialsSignatures: React.Dispatch<React.SetStateAction<boolean>>
  setSkipOfficialsSignatures: React.Dispatch<React.SetStateAction<boolean>>
  setSkipStartingLineupSelection: React.Dispatch<React.SetStateAction<boolean>>
  setRequireGameOfficials: React.Dispatch<React.SetStateAction<boolean>>

  setTimingPeriodLength: React.Dispatch<React.SetStateAction<number | null>>
  setTimingExtraPeriodLength: React.Dispatch<
    React.SetStateAction<number | null>
  >
  setGoalsPerForfeit: React.Dispatch<React.SetStateAction<number | null>>
  setWins: React.Dispatch<React.SetStateAction<number | null>>
  setLosses: React.Dispatch<React.SetStateAction<number | null>>
  setTies: React.Dispatch<React.SetStateAction<number | null>>
  setOvertimeWin: React.Dispatch<React.SetStateAction<number | null>>
  setOvertimeLoss: React.Dispatch<React.SetStateAction<number | null>>
  setShootoutLoss: React.Dispatch<React.SetStateAction<number | null>>
  setFieldErrors: React.Dispatch<React.SetStateAction<string[]>>
  setCustomError: any
  onCancel: () => void
  onSubmit: () => Promise<any>
  beforeUpload: (file: any) => boolean
  handleChange: (info: any) => void
  handleChangeBackground: (info: any) => void
  backgroundImageUrl: string | null
  setBackgroundImageUrl: React.Dispatch<React.SetStateAction<string | null>>
  genericSports?: any[]
  daySmartSeasonId: string
  daySmartSeasonName: string
  daySmartSyncInterval: DaysmartSyncIntervalEnum
  daySmartSendInvitation: boolean
  setDaySmartSendInvitation: React.Dispatch<React.SetStateAction<boolean>>
  setDaySmartSyncInterval: React.Dispatch<
    React.SetStateAction<DaysmartSyncIntervalEnum>
  >
  daySmartFacilitiesAsSubResources: boolean
  setDaySmartFacilitiesAsSubResources: React.Dispatch<
    React.SetStateAction<boolean>
  >
  hasChangedSettings: boolean
  contactInfo: {
    email?: string | null | undefined
    website?: string | null | undefined
    phone?: string | null | undefined
    support_email?: string | null | undefined
    support_website?: string | null | undefined
    support_phone?: string | null | undefined
  }
  setContactInfo: React.Dispatch<
    React.SetStateAction<{
      email?: string | null | undefined
      website?: string | null | undefined
      phone?: string | null | undefined
      support_email?: string | null | undefined
      support_website?: string | null | undefined
      support_phone?: string | null | undefined
    }>
  >
  requireUniqueJerseyNumbers: boolean
  setRequireUniqueJerseyNumbers: React.Dispatch<React.SetStateAction<boolean>>
  requireWaiverSignature: boolean
  setRequireWaiverSignature: React.Dispatch<React.SetStateAction<boolean>>
  onSubmitGeneralForm: () => Promise<any>
  onSubmitContactInfo: () => Promise<any>
  onSubmitSettings: () => Promise<any>
  onSubmitCustomization: () => Promise<any>
  onSubmitDaysmart: () => Promise<any>
  isSyncing: boolean
  setIsSyncing: React.Dispatch<React.SetStateAction<boolean>>
  message: MessageInstance | undefined
  hasFilledNameAndAbbreviationAndDates: boolean
  isYouthLeague: boolean
  setIsYouthLeague: React.Dispatch<React.SetStateAction<boolean>>
  disableTeamChat: boolean
  setDisableTeamChat: React.Dispatch<React.SetStateAction<boolean>>
  isArchive: boolean
  setIsArchive: React.Dispatch<React.SetStateAction<boolean>>
  requireTimeGameEvent: boolean
  setRequireTimeGameEvent: React.Dispatch<React.SetStateAction<boolean>>
  requireGoalType: boolean
  setRequireGoalType: React.Dispatch<React.SetStateAction<boolean>>
  requireGoalConfirmation: boolean
  setRequireGoalConfirmation: React.Dispatch<React.SetStateAction<boolean>>
  defaultSortBy: number | null
  setDefaultSortBy: React.Dispatch<React.SetStateAction<number | null>>
  tiebreakerStats: number[]
  setTiebreakerStats: React.Dispatch<React.SetStateAction<number[]>>
  statTypes: StatType[]
  defaultStandingsSettingsForSport: StandingsSettings | null | undefined
}

const createAbbreviation = (companyName) => {
  let text = companyName.replace(/\s/g, '') // Remove all whitespaces
  // clear any special characters or emojis
  text = text.replace(/[^a-zA-Z0-9]/g, '')
  // Check if input is valid
  if (!text) {
    return '-' // Return an empty string if the input is empty or invalid
  }
  if (typeof text !== 'string') {
    return '-' // Return an empty string if the input is not a string
  }
  if (text.length < 5) {
    return text.toUpperCase() // Return the input in uppercase if it has less than 5 characters
  }
  // Get the first 5 characters of the text
  const abbreviation = text.slice(0, 5)

  // Ensure the abbreviation has at least one character and return it in uppercase
  return abbreviation.length > 0 ? abbreviation.toUpperCase() : ''
}

// Create the context
export const ScheduleContext = createContext<ScheduleContextProps | undefined>(
  undefined
)

// Create the provider component
const ScheduleProvider: React.FC<{
  children: ReactNode
  initialState?: InitialState
  sports?: any[]
  genericSports?: any[]
  id?: string
  displayCanlanForm?: boolean
  parentOrgId?: string
  defaultScheduleSettingsForAllSports: SportSettings[]
  existingScheduleSettings?: SportSettings
  onFinishTutorial?: () => void
  message?: MessageInstance
  closeForm: () => void
  triggerRefresh: () => void
  setIsCompetitionSetupModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setNewCompetitionId: React.Dispatch<React.SetStateAction<string | null>>
  allStatTypes: StatType[]
}> = ({
  children,
  initialState,
  sports,
  genericSports,
  id,
  displayCanlanForm,
  parentOrgId,
  defaultScheduleSettingsForAllSports,
  existingScheduleSettings = null,
  onFinishTutorial,
  message,
  closeForm,
  triggerRefresh,
  setIsCompetitionSetupModalOpen,
  setNewCompetitionId,
  allStatTypes,
}) => {
  // @ts-ignore
  const history = useHistory()
  const [sportOptions, setSportOptions] = useState<any[]>(() =>
    sports && genericSports
      ? [
          ...sports
            .map((option) => ({
              ...option,
              name: `${option.name_full} (Full Stats)`,
              is_generic: false,
            }))
            .filter((option) => !option.name.toLowerCase().includes('generic')),
          ...genericSports.map((option) => ({
            ...option,
            name: `${option.name} (Basic Stats)`,
            is_generic: true,
          })),
        ]
      : []
  )

  const genericId = sports?.find((sport) =>
    sport?.name.toLowerCase().includes('generic')
  )?.id
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [privacySettings, setPrivacySettings] = useState<'public' | 'private'>(
    'public'
  )
  const [imageChanged, setImageChanged] = useState(false)
  const isEdit = Boolean(initialState?.name_full)
  const [name, setName] = useState('')
  const [abbreviation, setAbbreviation] = useState('')
  const [taxRate, setTaxRate] = useState<number | null>(null)
  const [selectedSport, setSelectedSport] = useState<string | null | undefined>(
    initialState ? initialState.sport_id : undefined
  )
  const [sportId, setSportId] = useState<string | null>(
    initialState?.sport_id || null
  )
  const [genericSportId, setGenericSportId] = useState<string | null>(null)
  const [competitionDateRange, setCompetitionDateRange] = useState({
    starts_at: '',
    ends_at: '',
  })
  const [playoffCompetition, setPlayoffCompetition] = useState(false)
  const [imageUrl, setImageUrl] = useState<string | null>(
    getImageThumbnailId(initialState) || null
  )
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<string | null>(
    initialState?.background_image?.full_path || null
  )
  const [backgroundImageChanged, setBackgroundImageChanged] = useState(false)
  const [selectedSeasonType, setSelectedSeasonType] = useState<any>(null)
  const [onlineRegistrationOpen, setOnlineRegistrationOpen] = useState(false)
  const [selectedSeasonCurrency, setSelectedSeasonCurrency] =
    useState<any>(null)
  const [isRegistrationEnabled, setIsRegistrationEnabled] = useState<boolean>(
    initialState?.is_registration_on || false
  )
  const [skipTeamOfficialsSignatures, setSkipTeamOfficialsSignatures] =
    useState(false)
  const [requireGameOfficials, setRequireGameOfficials] = useState(false)
  const [skipOfficialsSignatures, setSkipOfficialsSignatures] = useState(false)
  const [skipStartingLineupSelection, setSkipStartingLineupSelection] =
    useState(false)
  const [requireUniqueJerseyNumbers, setRequireUniqueJerseyNumbers] =
    useState(false)
  const [requireWaiverSignature, setRequireWaiverSignature] = useState(false)
  const [isYouthLeague, setIsYouthLeague] = useState<boolean>(
    initialState?.is_youth_league || false
  )
  const [disableTeamChat, setDisableTeamChat] = useState<boolean>(
    initialState?.disable_team_chat || false
  )

  const [timingPeriodLength, setTimingPeriodLength] = useState<number | null>(
    null
  )

  const [timingExtraPeriodLength, setTimingExtraPeriodLength] = useState<
    number | null
  >(null)
  const [goalsPerForfeit, setGoalsPerForfeit] = useState<number | null>(null)
  const [wins, setWins] = useState<number | null>(null)
  const [losses, setLosses] = useState<number | null>(null)
  const [ties, setTies] = useState<number | null>(null)
  const [overtimerWin, setOvertimeWin] = useState<number | null>(null)
  const [overtimeLoss, setOvertimeLoss] = useState<number | null>(null)
  const [shootoutLoss, setShootoutLoss] = useState<number | null>(null)
  const [requireTimeGameEvent, setRequireTimeGameEvent] =
    useState<boolean>(false)
  const [requireGoalType, setRequireGoalType] = useState<boolean>(false)
  const [requireGoalConfirmation, setRequireGoalConfirmation] =
    useState<boolean>(false)
  const [fieldErrors, setFieldErrors] = useState<string[]>([])
  const [customError, setCustomError] = useState({
    name: null,
    sport: null,
    starts_at: null,
    ends_at: null,
    abbreviation: null,
    phone: null,
    website: null,
  })
  const daySmartSeasonName: string = initialState?.daysmart_season_name || ''
  const daySmartSeasonId: string = initialState?.daysmart_season_id || ''
  const [daySmartSyncInterval, setDaySmartSyncInterval] =
    useState<DaysmartSyncIntervalEnum>(
      initialState?.daysmart_sync_interval || DaysmartSyncIntervalEnum.NONE
    )
  const [daySmartSendInvitation, setDaySmartSendInvitation] = useState(
    initialState?.daysmart_send_invitation || false
  )
  const [
    daySmartFacilitiesAsSubResources,
    setDaySmartFacilitiesAsSubResources,
  ] = useState(initialState?.daysmart_facilities_as_sub_resources || false)

  const [contactInfo, setContactInfo] = useState<{
    email?: string | null
    website?: string | null
    phone?: string | null
    support_email?: string | null
    support_website?: string | null
    support_phone?: string | null
  }>({})

  const [isSyncing, setIsSyncing] = useState(false)
  const hasFilledNameAndAbbreviationAndDates = useMemo(() => {
    return Boolean(
      name &&
        abbreviation &&
        name.length > 0 &&
        abbreviation.length > 0 &&
        competitionDateRange.starts_at &&
        competitionDateRange.ends_at &&
        selectedSport
    )
  }, [
    name,
    abbreviation,
    competitionDateRange.starts_at,
    competitionDateRange.ends_at,
    selectedSport,
  ])

  const invalidContactInfo = useMemo(() => {
    return (
      fieldErrors.includes('phone') ||
      fieldErrors.includes('email') ||
      fieldErrors.includes('website') ||
      fieldErrors.includes('support_phone') ||
      fieldErrors.includes('support_email') ||
      fieldErrors.includes('support_website')
    )
  }, [fieldErrors])

  const defaultStandingsSettingsForSport =
    defaultScheduleSettingsForAllSports.find(
      (setting) => setting.id === sportId
    )?.settings?.standings

  // Track initial values
  const [initialValues] = useState({
    syncInterval: daySmartSyncInterval,
    sendInvitation: daySmartSendInvitation,
    facilitiesAsSubResources: daySmartFacilitiesAsSubResources,
  })

  // Check if form has changes
  const hasChanges = useMemo(() => {
    return (
      initialValues.syncInterval !== daySmartSyncInterval ||
      initialValues.sendInvitation !== daySmartSendInvitation ||
      initialValues.facilitiesAsSubResources !==
        daySmartFacilitiesAsSubResources
    )
  }, [
    daySmartSyncInterval,
    daySmartSendInvitation,
    daySmartFacilitiesAsSubResources,
    initialValues,
  ])

  const hasChangedSettings = useMemo(() => {
    if (!existingScheduleSettings) {
      return false
    }
    if (
      existingScheduleSettings?.settings?.stats?.goals?.per?.forfeit !== null &&
      goalsPerForfeit !==
        existingScheduleSettings?.settings?.stats?.goals?.per?.forfeit
    ) {
      return true
    }
    if (
      existingScheduleSettings?.settings?.stats?.points?.wins !== null &&
      wins !== existingScheduleSettings?.settings?.stats?.points?.wins
    ) {
      return true
    }
    if (
      existingScheduleSettings?.settings?.stats?.points?.losses !== null &&
      losses !== existingScheduleSettings?.settings?.stats?.points?.losses
    ) {
      return true
    }
    if (
      existingScheduleSettings?.settings?.stats?.points?.ties !== null &&
      ties !== existingScheduleSettings?.settings?.stats?.points?.ties
    ) {
      return true
    }
    if (
      existingScheduleSettings?.settings?.stats?.points?.overtime_win !==
        null &&
      overtimerWin !==
        existingScheduleSettings?.settings?.stats?.points?.overtime_win
    ) {
      return true
    }
    if (
      existingScheduleSettings?.settings?.stats?.points?.overtime_loss !==
        null &&
      overtimeLoss !==
        existingScheduleSettings?.settings?.stats?.points?.overtime_loss
    ) {
      return true
    }
    if (
      existingScheduleSettings?.settings?.stats?.points?.shootout_loss !==
        null &&
      shootoutLoss !==
        existingScheduleSettings?.settings?.stats?.points?.shootout_loss
    ) {
      return true
    }
    return false
  }, [
    existingScheduleSettings,
    goalsPerForfeit,
    wins,
    losses,
    ties,
    overtimerWin,
    overtimeLoss,
    shootoutLoss,
  ])

  const [isArchive, setIsArchive] = useState<boolean>(
    initialState?.is_archive || false
  )

  const [defaultSortBy, setDefaultSortBy] = useState<number | null>(
    initialState?.settings?.standings?.sort_order?.stat_id || null
  )
  const [tiebreakerStats, setTiebreakerStats] = useState<number[]>(
    initialState?.settings?.standings?.sort_order?.tie_breaker_stats || []
  )

  const statTypes = useMemo(() => {
    return allStatTypes.filter((stat) => stat.sport_id === sportId)
  }, [allStatTypes, sportId])

  useEffect(() => {
    if (existingScheduleSettings && isEdit) {
      setRequireGameOfficials(
        existingScheduleSettings?.settings?.game?.require_officials || false
      )
      setSkipOfficialsSignatures(
        existingScheduleSettings?.settings?.game?.signatures?.skip_official ||
          false
      )
      setSkipTeamOfficialsSignatures(
        existingScheduleSettings?.settings?.game?.signatures
          ?.skip_team_official || false
      )
      setSkipStartingLineupSelection(
        existingScheduleSettings?.settings?.game?.skip_lineup || false
      )
      setTimingPeriodLength(
        existingScheduleSettings?.settings?.game?.timing
          ?.default_period_length || null
      )
      setTimingExtraPeriodLength(
        existingScheduleSettings?.settings?.game?.timing
          ?.default_overtime_length || null
      )
      setGoalsPerForfeit(
        existingScheduleSettings?.settings?.stats?.goals?.per?.forfeit || 0
      )
      setWins(existingScheduleSettings?.settings?.stats?.points?.wins || 0)
      setLosses(existingScheduleSettings?.settings?.stats?.points?.losses || 0)
      setTies(existingScheduleSettings?.settings?.stats?.points?.ties || 0)
      setOvertimeWin(
        existingScheduleSettings?.settings?.stats?.points?.overtime_win || 0
      )
      setOvertimeLoss(
        existingScheduleSettings?.settings?.stats?.points?.overtime_loss || 0
      )
      setShootoutLoss(
        existingScheduleSettings?.settings?.stats?.points?.shootout_loss || 0
      )
      setRequireUniqueJerseyNumbers(
        existingScheduleSettings?.settings?.game
          ?.require_unique_jersey_numbers || false
      )
      setRequireWaiverSignature(
        existingScheduleSettings?.settings?.game?.require_waiver_signature ||
          false
      )
      setRequireGoalType(
        existingScheduleSettings?.settings?.game?.scoring?.require_goal_type ||
          false
      )
      setRequireGoalConfirmation(
        existingScheduleSettings?.settings?.game?.scoring
          ?.require_goal_configuration || false
      )
      setRequireTimeGameEvent(
        existingScheduleSettings?.settings?.game?.scoring
          ?.require_time_game_event || false
      )
      setDefaultSortBy(
        existingScheduleSettings?.settings?.standings?.sort_order?.stat_id ||
          null
      )
      setTiebreakerStats(
        existingScheduleSettings?.settings?.standings?.sort_order
          ?.tie_breaker_stats || []
      )
    }
  }, [existingScheduleSettings, isEdit])

  useEffect(() => {
    if (
      !defaultScheduleSettingsForAllSports ||
      defaultScheduleSettingsForAllSports.length === 0 ||
      !sportId ||
      isEdit ||
      fieldErrors?.length > 0
    ) {
      return
    }
    const defaultSettings = defaultScheduleSettingsForAllSports.find(
      (setting) => setting.id === sportId
    )
    setSkipOfficialsSignatures(
      defaultSettings?.settings?.game?.signatures?.skip_official || false
    )
    setSkipTeamOfficialsSignatures(
      defaultSettings?.settings?.game?.signatures?.skip_team_official || false
    )
    setSkipStartingLineupSelection(
      defaultSettings?.settings?.game?.skip_lineup || false
    )
    setRequireUniqueJerseyNumbers(
      defaultSettings?.settings?.game?.require_unique_jersey_numbers || false
    )
    setRequireWaiverSignature(
      defaultSettings?.settings?.game?.require_waiver_signature || false
    )
    setRequireTimeGameEvent(
      defaultSettings?.settings?.game?.scoring?.require_time_game_event || false
    )
    setRequireGoalType(
      defaultSettings?.settings?.game?.scoring?.require_goal_type || false
    )
    setRequireGoalConfirmation(
      defaultSettings?.settings?.game?.scoring?.require_goal_configuration ||
        false
    )
    setTimingPeriodLength(
      defaultSettings?.settings?.game?.timing?.default_period_length || null
    )
    setTimingExtraPeriodLength(
      defaultSettings?.settings?.game?.timing?.default_overtime_length || null
    )
    setRequireGameOfficials(
      defaultSettings?.settings?.game?.require_officials || false
    )
    setGoalsPerForfeit(
      defaultSettings?.settings?.stats?.goals?.per?.forfeit || 0
    )
    setWins(defaultSettings?.settings?.stats?.points?.wins || 0)
    setLosses(defaultSettings?.settings?.stats?.points?.losses || 0)
    setTies(defaultSettings?.settings?.stats?.points?.ties || 0)
    setOvertimeWin(defaultSettings?.settings?.stats?.points?.overtime_win || 0)
    setOvertimeLoss(
      defaultSettings?.settings?.stats?.points?.overtime_loss || 0
    )
    setShootoutLoss(
      defaultSettings?.settings?.stats?.points?.shootout_loss || 0
    )
    setDefaultSortBy(
      defaultSettings?.settings?.standings?.sort_order?.stat_id || null
    )
    setTiebreakerStats(
      defaultSettings?.settings?.standings?.sort_order?.tie_breaker_stats || []
    )
  }, [
    defaultScheduleSettingsForAllSports,
    fieldErrors?.length,
    isEdit,
    sportId,
  ])

  useEffect(() => {
    // We only want to set the abbreviation if it's not already set
    // aka if it's an edit, we don't want to overwrite the existing abbreviation
    if (name && name?.length > 0 && !initialState?.abbreviation) {
      setAbbreviation(createAbbreviation(name))
    }
  }, [initialState?.abbreviation, name])

  useEffect(() => {
    if (initialState && initialState?.starts_at && initialState?.ends_at) {
      setPlayoffCompetition(initialState?.is_tournament || false)
      setCompetitionDateRange({
        starts_at: dayjs(initialState?.starts_at).utc().format('YYYY-MM-DD'),
        ends_at: dayjs(initialState?.ends_at).utc().format('YYYY-MM-DD'),
      })
      setName(initialState?.name_full || '')
      setAbbreviation(initialState?.abbreviation || '')
      setSelectedSport(initialState?.sport_id)
      setPrivacySettings(initialState?.is_public ? 'public' : 'private')
      setImageUrl(getImageThumbnailId(initialState) || null)
      setBackgroundImageUrl(initialState?.background_image?.full_path || null)
      setIsRegistrationEnabled(initialState?.is_registration_on || false)
      setIsYouthLeague(initialState?.is_youth_league || false)
      setDisableTeamChat(initialState?.disable_team_chat || false)
      setTaxRate(
        initialState?.settings?.tax_rate || initialState?.tax_rate || null
      )
      setSelectedSeasonType(
        initialState?.settings?.season_type || initialState?.season_type || null
      )
      setSelectedSeasonCurrency(
        initialState?.settings?.season_currency ||
          initialState?.season_currency ||
          null
      )
      setOnlineRegistrationOpen(
        initialState?.settings?.online_registration_open ||
          initialState?.online_registration_open ||
          false
      )
      if (initialState?.generic_sport_id) {
        setGenericSportId(initialState?.generic_sport_id)
        const sport = sportOptions.find(
          (s) => s.id === initialState?.generic_sport_id
        )
        setSportId(sport?.sport_id)
        setSelectedSport(sport?.id)
      } else {
        setSportId(initialState?.sport_id || null)
        setSelectedSport(initialState?.sport_id)
        setGenericSportId(null)
      }
      initialState?.daysmart_sync_interval &&
        setDaySmartSyncInterval(initialState?.daysmart_sync_interval)
      initialState?.daysmart_send_invitation &&
        setDaySmartSendInvitation(initialState?.daysmart_send_invitation)
      initialState?.daysmart_facilities_as_sub_resources &&
        setDaySmartFacilitiesAsSubResources(
          initialState?.daysmart_facilities_as_sub_resources
        )
      initialState?.settings?.game?.signatures &&
        setSkipOfficialsSignatures(
          initialState?.settings?.game?.signatures?.skip_official || false
        )
      initialState?.settings?.game?.signatures &&
        setSkipTeamOfficialsSignatures(
          initialState?.settings?.game?.signatures?.skip_team_official || false
        )
      initialState?.settings?.game &&
        setSkipStartingLineupSelection(
          initialState?.settings?.game?.skip_lineup || false
        )
      initialState?.settings?.game &&
        setRequireUniqueJerseyNumbers(
          initialState?.settings?.game?.require_unique_jersey_numbers || false
        )
      initialState?.settings?.game &&
        setRequireWaiverSignature(
          initialState?.settings?.game?.require_waiver_signature || false
        )
      initialState?.settings?.game &&
        setRequireGameOfficials(
          initialState?.settings?.game?.require_officials || false
        )
      initialState?.settings?.game?.timing &&
        setTimingPeriodLength(
          initialState?.settings?.game?.timing?.default_period_length || null
        )
      initialState?.settings?.game?.timing &&
        setTimingExtraPeriodLength(
          initialState?.settings?.game?.timing?.default_overtime_length || null
        )
      initialState?.settings?.game?.scoring &&
        setRequireTimeGameEvent(
          initialState?.settings?.game?.scoring?.require_time_game_event ||
            false
        )
      initialState?.settings?.game?.scoring &&
        setRequireGoalType(
          initialState?.settings?.game?.scoring?.require_goal_type || false
        )
      initialState?.settings?.game?.scoring &&
        setRequireGoalConfirmation(
          initialState?.settings?.game?.scoring?.require_goal_configuration ||
            false
        )
      initialState?.settings?.stats?.goals &&
        setGoalsPerForfeit(
          initialState?.settings?.stats?.goals?.per?.forfeit || 0
        )
      initialState?.settings?.stats?.points &&
        setWins(initialState?.settings?.stats?.points?.wins || 0)
      initialState?.settings?.stats?.points &&
        setLosses(initialState?.settings?.stats?.points?.losses || 0)
      initialState?.settings?.stats?.points &&
        setTies(initialState?.settings?.stats?.points?.ties || 0)
      initialState?.settings?.stats?.points &&
        setOvertimeWin(initialState?.settings?.stats?.points?.overtime_win || 0)
      initialState?.settings?.stats?.points &&
        setOvertimeLoss(
          initialState?.settings?.stats?.points?.overtime_loss || 0
        )
      initialState?.settings?.stats?.points &&
        setShootoutLoss(
          initialState?.settings?.stats?.points?.shootout_loss || 0
        )
      initialState?.email &&
        setContactInfo((prev) => ({ ...prev, email: initialState?.email }))
      initialState?.website &&
        setContactInfo((prev) => ({ ...prev, website: initialState?.website }))
      initialState?.phone &&
        setContactInfo((prev) => ({ ...prev, phone: initialState?.phone }))
      initialState?.support_email &&
        setContactInfo((prev) => ({
          ...prev,
          support_email: initialState?.support_email,
        }))
      initialState?.support_website &&
        setContactInfo((prev) => ({
          ...prev,
          support_website: initialState?.support_website,
        }))
      initialState?.support_phone &&
        setContactInfo((prev) => ({
          ...prev,
          support_phone: initialState?.support_phone,
        }))
      initialState?.settings?.standings?.sort_order?.stat_id &&
        setDefaultSortBy(initialState?.settings?.standings?.sort_order?.stat_id)
      initialState?.settings?.standings?.sort_order?.tie_breaker_stats &&
        setTiebreakerStats(
          initialState?.settings?.standings?.sort_order?.tie_breaker_stats
        )
    }
  }, [initialState, sportOptions])

  const onCancel = () => {
    setName('')
    setAbbreviation('')
    setTaxRate(null)
    setSelectedSport(null)
    setSportId(null)
    setGenericSportId(null)
    setCompetitionDateRange({
      starts_at: dayjs().utc().format('YYYY-MM-DD'),
      ends_at: dayjs().utc().format('YYYY-MM-DD'),
    })
    setPlayoffCompetition(false)
    setImageUrl(null)
    setSelectedSeasonType(null)
    setOnlineRegistrationOpen(false)
    setSelectedSeasonCurrency(null)
    setIsRegistrationEnabled(false)
    setSkipTeamOfficialsSignatures(false)
    setSkipOfficialsSignatures(false)
    setSkipStartingLineupSelection(false)
    setRequireGameOfficials(false)
    setRequireUniqueJerseyNumbers(false)
    setRequireWaiverSignature(false)
    setTimingPeriodLength(null)
    setTimingExtraPeriodLength(null)
    setGoalsPerForfeit(null)
    setWins(null)
    setLosses(null)
    setTies(null)
    setOvertimeWin(null)
    setOvertimeLoss(null)
    setShootoutLoss(null)
    setFieldErrors([])
    setCustomError({
      name: null,
      sport: null,
      starts_at: null,
      ends_at: null,
      abbreviation: null,
      phone: null,
      website: null,
    })
    setContactInfo({
      email: null,
      website: null,
      phone: null,
      support_email: null,
      support_website: null,
      support_phone: null,
    })
    setDefaultSortBy(null)
    setTiebreakerStats([])
  }

  const onSubmit = async () => {
    setIsLoading(true)
    setError(null)
    setFieldErrors([])
    setCustomError({
      name: null,
      sport: null,
      starts_at: null,
      ends_at: null,
      abbreviation: null,
      phone: null,
      website: null,
    })
    try {
      let response
      if (!isEdit) {
        response = await createSchedule(
          {
            abbreviation: abbreviation,
            starts_at: competitionDateRange.starts_at,
            ends_at: competitionDateRange.ends_at,
            is_registration_on: isRegistrationEnabled,
            sport_type: sportOptions.find((sport) => sport.id === selectedSport)
              ?.name,
            sport_id: sportId || sportOptions[0]?.id,
            generic_sport_id: genericSportId,
            is_public: privacySettings === 'public' ? true : false,
            name: name,
            season_type: selectedSeasonType,
            season_currency: selectedSeasonCurrency,
            tax_rate: taxRate,
            online_registration_open: onlineRegistrationOpen,
            website: contactInfo?.website,
            email: contactInfo?.email,
            phone: contactInfo?.phone,
            support_email: contactInfo?.support_email,
            support_website: contactInfo?.support_website,
            support_phone: contactInfo?.support_phone,
            is_youth_league: isYouthLeague,
            disable_team_chat: disableTeamChat,
            is_archive: isArchive,
          },
          parentOrgId,
          imageUrl,
          backgroundImageUrl,
          imageChanged,
          backgroundImageChanged
        )
        await updateScheduleSettings(response?.data?.id, {
          game: {
            scoring: {
              require_time_game_event: requireTimeGameEvent,
              require_goal_type: requireGoalType,
              require_goal_configuration: requireGoalConfirmation,
            },
            timing: {
              default_period_length: timingPeriodLength,
              default_overtime_length: timingExtraPeriodLength,
            },
            signatures: {
              skip_official: skipOfficialsSignatures,
              skip_team_official: skipTeamOfficialsSignatures,
            },
            skip_lineup: skipStartingLineupSelection,
            require_officials: requireGameOfficials,
            require_unique_jersey_numbers: requireUniqueJerseyNumbers,
            require_waiver_signature: requireWaiverSignature,
          },
          stats: {
            goals: {
              per: {
                forfeit: goalsPerForfeit,
              },
            },
            points: {
              wins: wins,
              losses: losses,
              ties: ties,
              overtime_loss: overtimeLoss,
              shootout_loss: shootoutLoss,
              overtime_win: overtimerWin,
            },
          },
          standings: {
            sort_order: {
              stat_id: defaultSortBy,
              tie_breaker_stats: tiebreakerStats,
            },
          },
        })
        return response
      } else {
        response = await updateSchedule(
          {
            starts_at: competitionDateRange.starts_at,
            ends_at: competitionDateRange.ends_at,
            is_registration_on: isRegistrationEnabled,
            sport_type: sports?.find((sport) => sport.id === selectedSport)
              ?.name,
            is_public: privacySettings === 'public' ? true : false,
            name: name,
            name_full: name,
            season_type: selectedSeasonType,
            season_currency: selectedSeasonCurrency,
            tax_rate: taxRate,
            online_registration_open: onlineRegistrationOpen,
            daysmart_sync_interval: daySmartSyncInterval,
            daysmart_send_invitation: daySmartSendInvitation,
            daysmart_facilities_as_sub_resources:
              daySmartFacilitiesAsSubResources,
            abbreviation: abbreviation,
            website: contactInfo?.website,
            email: contactInfo?.email,
            phone: contactInfo?.phone,
            support_email: contactInfo?.support_email,
            support_website: contactInfo?.support_website,
            support_phone: contactInfo?.support_phone,
            is_youth_league: isYouthLeague,
            disable_team_chat: disableTeamChat,
            is_archive: isArchive,
          },
          id,
          imageUrl,
          backgroundImageUrl,
          imageChanged,
          backgroundImageChanged
        )
        await updateScheduleSettings(id, {
          game: {
            scoring: {
              require_time_game_event: requireTimeGameEvent,
              require_goal_type: requireGoalType,
              require_goal_configuration: requireGoalConfirmation,
            },
            timing: {
              default_period_length: timingPeriodLength,
              default_overtime_length: timingExtraPeriodLength,
            },
            signatures: {
              skip_official: skipOfficialsSignatures,
              skip_team_official: skipTeamOfficialsSignatures,
            },
            skip_lineup: skipStartingLineupSelection,
            require_officials: requireGameOfficials,
            require_unique_jersey_numbers: requireUniqueJerseyNumbers,
            require_waiver_signature: requireWaiverSignature,
          },
          stats: {
            goals: {
              per: {
                forfeit: goalsPerForfeit,
              },
            },
            points: {
              wins: wins,
              losses: losses,
              ties: ties,
              overtime_loss: overtimeLoss,
              shootout_loss: shootoutLoss,
              overtime_win: overtimerWin,
            },
          },
          standings: {
            sort_order: {
              stat_id: defaultSortBy,
              tie_breaker_stats: tiebreakerStats,
            },
          },
        })
      }
      try {
        if (onFinishTutorial) {
          onFinishTutorial()
        }
      } catch (e) {
        console.error(e)
      }

      return response
    } catch (e: any) {
      if (e?.invalid_fields) {
        setCustomErrors(e.invalid_fields)
        setIsLoading(false)
        throw e
      }
      const errorMessage = getErrorMessage(e)
      message?.open({
        type: 'error',
        content: errorMessage,
      })
      throw e
    } finally {
      setIsLoading(false)
    }
  }

  const setCustomErrors = (invalid_fields) => {
    if (invalid_fields.name_full) {
      setCustomErroByField('name', invalid_fields.name_full)
    }
    if (invalid_fields.name) {
      setCustomErroByField('name', invalid_fields.name)
    }
    if (invalid_fields.name) {
      setCustomErroByField('name_full', invalid_fields.name_full)
    }
    if (invalid_fields.abbreviation) {
      setCustomErroByField('abbreviation', invalid_fields.abbreviation)
    }
    if (invalid_fields.starts_at) {
      setCustomErroByField('starts_at', invalid_fields.starts_at)
    }
    if (invalid_fields.ends_at) {
      setCustomErroByField('ends_at', invalid_fields.ends_at)
    }
    if (invalid_fields.sport_id) {
      setCustomErroByField('sport_type', invalid_fields.sport_id)
    }
    if (invalid_fields.sport_type) {
      setCustomErroByField('sport_type', invalid_fields.sport_type)
    }
  }

  const setCustomErroByField = (field, lmessage) => {
    setCustomError((prev) => ({ ...prev, [field]: lmessage }))
    setFieldErrors((prevErrors) => [...new Set([...prevErrors, field])])
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    if (img) {
      reader.readAsDataURL(img)
    }
  }
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message?.open({
        type: 'error',
        content: 'You can only upload JPG/PNG file!',
      })
      setError('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message?.open({
        type: 'error',
        content: 'Image must smaller than 2MB!',
      })
      setError('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }
  const handleChange = (info) => {
    try {
      setImageChanged(true)
      setIsLoading(true)
      getBase64(info.file.originFileObj, (url) => {
        setIsLoading(false)
        setImageUrl(url)
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeBackground = (info) => {
    try {
      setBackgroundImageChanged(true)
      setIsLoading(true)
      getBase64(info.file.originFileObj, (url) => {
        setIsLoading(false)
        setBackgroundImageUrl(url)
      })
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmitGeneralForm = async () => {
    try {
      if (isLoading) {
        return
      }
      if (!isEdit && !hasFilledNameAndAbbreviationAndDates) {
        return
      }
      if (isEdit && !hasFilledNameAndAbbreviationAndDates) {
        return
      }
      const response = await onSubmit()
      if (isEdit) {
        message?.open({
          type: 'success',
          content: `${
            isCanlan ? 'Season' : 'Competition'
          } Updated Successfully`,
        })
      } else {
        message?.open({
          type: 'success',
          content: `${
            isCanlan ? 'Season' : 'Competition'
          } Created Successfully`,
        })
        setTimeout(() => {
          // history.push(ROUTES.SCHEDULE_ROOT + `/${response?.data?.id}/setup`)
          setIsCompetitionSetupModalOpen(true)
          setNewCompetitionId(response?.data?.id)
        }, 500)
      }
      triggerRefresh()
      closeForm()
    } catch (e) {
      console.error('Error submitting form', e)
    }
  }

  const onSubmitContactInfo = async () => {
    try {
      if (isLoading) {
        return
      }
      if (invalidContactInfo) {
        return
      }
      const response = await onSubmit()
      if (isEdit) {
        message?.open({
          type: 'success',
          content: `${
            isCanlan ? 'Season' : 'Competition'
          } Updated Successfully`,
        })
      } else {
        message?.open({
          type: 'success',
          content: `${
            isCanlan ? 'Season' : 'Competition'
          } Created Successfully`,
        })
        setTimeout(() => {
          // history.push(ROUTES.SCHEDULE_ROOT + `/${response?.data?.id}/setup`)
          setIsCompetitionSetupModalOpen(true)
          setNewCompetitionId(response?.data?.id)
        }, 500)
      }
      triggerRefresh()
      closeForm()
    } catch (e: any) {
      const errorMessage = getErrorMessage(e)
      if (e?.invalid_fields) {
        if (e?.invalid_fields?.name || e?.invalid_fields?.abbreviation) {
          message?.open({
            type: 'error',
            content: errorMessage,
          })
        }
      }
    }
  }

  const onSubmitSettings = async () => {
    try {
      if (isLoading) {
        return
      }
      // If no settings have changed, we don't need to reprocess the schedule
      if (!hasChangedSettings) {
        const response = await onSubmit()
        if (isEdit) {
          message?.open({
            type: 'success',
            content: `${
              isCanlan ? 'Season' : 'Competition'
            } Updated Successfully`,
          })
        } else {
          message?.open({
            type: 'success',
            content: `${
              isCanlan ? 'Season' : 'Competition'
            } Created Successfully`,
          })
          closeForm()
          setTimeout(() => {
            // history.push(ROUTES.SCHEDULE_ROOT + `/${response?.data?.id}/setup`)
            setIsCompetitionSetupModalOpen(true)
            setNewCompetitionId(response?.data?.id)
          }, 500)
          return
        }
        triggerRefresh()
        closeForm()
      } else {
        // click the reprocess hidden button
        const reprocessHiddenButton = document.getElementById(
          'reprocess-hidden-button'
        )
        if (reprocessHiddenButton) {
          reprocessHiddenButton.click()
        }
      }
    } catch (e: any) {
      const errorMessage = getErrorMessage(e)
      if (e?.invalid_fields) {
        if (e?.invalid_fields?.name || e?.invalid_fields?.abbreviation) {
          setError(errorMessage)
        }
      }
    }
  }

  const onSubmitCustomization = async () => {
    try {
      if (isLoading) {
        return
      }
      const response = await onSubmit()
      if (isEdit) {
        // @ts-ignore
        message?.open({
          type: 'success',
          content: `${
            isCanlan ? 'Season' : 'Competition'
          } Updated Successfully`,
        })
      } else {
        message?.open({
          type: 'success',
          content: `${
            isCanlan ? 'Season' : 'Competition'
          } Created Successfully`,
        })
        setTimeout(() => {
          // history.push(ROUTES.SCHEDULE_ROOT + `/${response?.data?.id}/setup`)
          setIsCompetitionSetupModalOpen(true)
          setNewCompetitionId(response?.data?.id)
        }, 500)
      }
      triggerRefresh()
      closeForm()
    } catch (e: any) {
      const errorMessage = getErrorMessage(e)
      if (e?.invalid_fields) {
        if (e?.invalid_fields?.name || e?.invalid_fields?.abbreviation) {
          setError(errorMessage)
        }
      }
    }
  }

  const onSubmitDaysmart = async () => {
    try {
      if (isLoading || isSyncing || !hasChanges) {
        return
      }
      await onSubmit()
      if (isEdit) {
        message?.open({
          type: 'success',
          content: `${
            isCanlan ? 'Season' : 'Competition'
          } Updated Successfully`,
        })
      } else {
        message?.open({
          type: 'success',
          content: `${
            isCanlan ? 'Season' : 'Competition'
          } Created Successfully`,
        })
      }
      triggerRefresh()
      closeForm()
    } catch (e: any) {
      const errorMessage = getErrorMessage(e)
      if (e?.invalid_fields) {
        if (e?.invalid_fields?.name || e?.invalid_fields?.abbreviation) {
          setError(errorMessage)
        }
      }
    }
  }

  return (
    <ScheduleContext.Provider
      value={{
        sports,
        displayCanlanForm,
        id,
        sportOptions,
        genericId,
        isLoading,
        error,
        privacySettings,
        imageChanged,
        isEdit,
        name,
        abbreviation,
        taxRate,
        selectedSport,
        sportId,
        genericSportId,
        competitionDateRange,
        playoffCompetition,
        imageUrl,
        backgroundImageUrl,
        setBackgroundImageUrl,
        selectedSeasonType,
        onlineRegistrationOpen,
        selectedSeasonCurrency,
        isRegistrationEnabled,
        skipTeamOfficialsSignatures,
        skipOfficialsSignatures,
        skipStartingLineupSelection,
        requireGameOfficials,
        timingPeriodLength,
        timingExtraPeriodLength,
        goalsPerForfeit,
        wins,
        losses,
        ties,
        overtimerWin,
        overtimeLoss,
        shootoutLoss,
        fieldErrors,
        customError,
        setSportOptions,
        setIsLoading,
        setError,
        setPrivacySettings,
        setImageChanged,
        setName,
        setAbbreviation,
        setTaxRate,
        setSelectedSport,
        setSportId,
        setGenericSportId,
        setCompetitionDateRange,
        setPlayoffCompetition,
        setImageUrl,
        setSelectedSeasonType,
        setOnlineRegistrationOpen,
        setSelectedSeasonCurrency,
        setIsRegistrationEnabled,
        setSkipTeamOfficialsSignatures,
        setSkipOfficialsSignatures,
        setSkipStartingLineupSelection,
        setTimingPeriodLength,
        setTimingExtraPeriodLength,
        setRequireGameOfficials,
        setGoalsPerForfeit,
        setWins,
        setLosses,
        setTies,
        setOvertimeWin,
        setOvertimeLoss,
        setShootoutLoss,
        setFieldErrors,
        setCustomError,
        onCancel,
        onSubmit,
        beforeUpload,
        handleChange,
        handleChangeBackground,
        genericSports,
        daySmartSeasonName,
        daySmartSyncInterval,
        daySmartSendInvitation,
        daySmartFacilitiesAsSubResources,
        setDaySmartSyncInterval,
        setDaySmartSendInvitation,
        setDaySmartFacilitiesAsSubResources,
        daySmartSeasonId,
        hasChangedSettings,
        contactInfo,
        setContactInfo,
        requireUniqueJerseyNumbers,
        setRequireUniqueJerseyNumbers,
        requireWaiverSignature,
        setRequireWaiverSignature,
        onSubmitGeneralForm,
        onSubmitContactInfo,
        onSubmitSettings,
        onSubmitCustomization,
        onSubmitDaysmart,
        isSyncing,
        setIsSyncing,
        message,
        hasFilledNameAndAbbreviationAndDates,
        isYouthLeague,
        setIsYouthLeague,
        disableTeamChat,
        setDisableTeamChat,
        backgroundImageChanged,
        setBackgroundImageChanged,
        isArchive,
        setIsArchive,
        requireTimeGameEvent,
        setRequireTimeGameEvent,
        requireGoalType,
        setRequireGoalType,
        requireGoalConfirmation,
        setRequireGoalConfirmation,
        defaultSortBy,
        setDefaultSortBy,
        tiebreakerStats,
        setTiebreakerStats,
        statTypes,
        defaultStandingsSettingsForSport,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  )
}

// Custom hook to use the ScheduleContext
const useScheduleContext = () => {
  const context = useContext(ScheduleContext)
  if (context === undefined) {
    throw new Error('useScheduleContext must be used within a ScheduleProvider')
  }
  return context
}

export { ScheduleProvider, useScheduleContext }
