import { StatTypes } from '../constants/StatsTypes'

/**
 * Creates table columns based on the provided stats.
 *
 * @param {Array} stats - The array of stats.
 * @param {Function} setSortBy - The function to set the sort by value.
 * @param {Function} setDirection - The function to set the sort direction.
 * @returns {Array} The array of table columns.
 */
export const createTableColumns = (
  stats,
  setSortBy = () => {},
  setDirection = () => {}
) => {
  return stats.map((stat) => ({
    title: stat.abbr,
    key: stat.abbr,
    sorter: (a, b, sortOrder) => {
      setSortBy(stat.abbr)
      const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
      setDirection(direction)
    },
    sortDirections: ['ascend', 'descend', 'ascend'],
    dataIndex: stat.abbr,
    showSorterTooltip: {
      title: stat.name,
    },
  }))
}

/**
 * Creates table columns configuration from an API response data.
 *
 * @param {Array} apiResponseData - The API response data containing stats.
 * @param {Function} setSortBy - The function to set the sort by value.
 * @param {Function} setDirection - The function to set the sort direction.
 * @returns {Array} The table columns configuration.
 */
export const createTableColumnsFromApiResponse = (
  apiResponseData,
  setSortBy = () => {},
  setDirection = () => {}
) => {
  // Flatten all stats from all items in the response data into a single array
  const allStats = apiResponseData.reduce(
    (acc, item) => [...acc, ...item.stats],
    []
  )

  // Extract unique stats based on the 'abbr' property to avoid duplicates
  const uniqueStats = Array.from(
    new Map(allStats.map((stat) => [stat.abbr, stat])).values()
  )

  // Generate columns configuration from the unique stats
  return uniqueStats.map((stat) => ({
    title: stat.abbr === 'P' ? 'PTS' : stat.abbr,
    key: stat.abbr,
    sorter: (a, b, sortOrder) => {
      setSortBy(stat.abbr)
      const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
      setDirection(direction)
    },
    sortDirections: ['ascend', 'descend', 'ascend'],
    dataIndex: stat.abbr,
    showSorterTooltip: {
      title: stat.name,
    },
  }))
}

/**
 * Filters the columns based on the requested stats.
 *
 * @param {Array} columns - The array of columns to filter.
 * @param {Array} requestedStats - The array of requested stats.
 * @returns {Array} - The filtered array of columns.
 */
export const filterColumns = (columns, requestedStats) => {
  // Filter columns based on whether their key (abbreviation) is included in requestedStats
  return requestedStats
    .map((key) => columns.find((column) => column.key === key))
    .filter(Boolean)
}

export const findStatByAbbreviation = (abbreviation, sport = 'hockey') => {
  for (const key in StatTypes) {
    if (
      StatTypes[key].abbreviation === abbreviation &&
      StatTypes[key].sportId === sport
    ) {
      return StatTypes[key]
    }
  }
  return null // Not found
}

/**
 * Builds an array of columns for displaying statistics.
 *
 * @param {string[]} statsArray - An array of statistic keys.
 * @returns {Object[]} - An array of column objects.
 */
export const buildStatsColumns = (statsArray, sport = 'hockey') => {
  const columnWidthPercentage = 100 / statsArray.length // Calculate equal width
  return statsArray.map((key) => {
    const statType = findStatByAbbreviation(key, sport)
    return {
      title: statType?.altAbbreviation || statType?.abbreviation || key,
      key,
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: key,
      showSorterTooltip: {
        title: statType?.name || key,
      },
      width: `${columnWidthPercentage}%`, // Add the width property
    }
  })
}

/**
 * Transforms player data based on filtered columns.
 * @param {Array} apiResponseData - The API response data containing player information.
 * @param {Array} filteredColumnKeys - The keys of the filtered columns.
 * @returns {Array} - The transformed player data.
 */
export const transformPlayerData = (apiResponseData, filteredColumnKeys) => {
  // Dynamically build player data based on filtered columns
  return apiResponseData.map((item, index) => {
    const playerData = {
      key: index,
      player: item,
      team: item,
      season: item?.season, // Assuming there's a 'season' field directly under each item
      PN: item?.player?.player_number, // Assuming there's a 'player_number' field under 'player'
    }

    // Iterate over each stat in the filtered columns and add it to the player data
    filteredColumnKeys.forEach((key) => {
      const stat = item.stats.find((s) => s.abbr === key)
      playerData[key] = stat ? stat.value : 0 // Set to 0 if not found
    })

    return playerData
  })
}

/**
 * Gets the description of a stat based on the default stat type and abbreviations.
 * This function is used to determine the default sort description for the Statistics table.
 * It searches through the abbreviations object to find the key that matches the stat abbreviation.
 *
 * @param {Object} defaultStatType - The default stat type object containing id, name, and abbreviation
 * @param {Object} abbreviations - Object containing stat abbreviations mapping
 * @returns {string} The key from abbreviations object that matches the stat, or the stat name if not found
 */
export const getStatDescription = (defaultStatType, abbreviations = {}) => {
  if (!defaultStatType) {
    return ''
  }

  // Search through the abbreviations object to find the key that matches the stat abbreviation
  for (const key in abbreviations) {
    if (abbreviations[key] === defaultStatType.abbreviation) {
      return key
    }
  }

  // If not found by abbreviation, try to find by name in StatTypes
  for (const key in StatTypes) {
    const stat = StatTypes[key]
    if (
      stat.abbreviation === defaultStatType.abbreviation ||
      stat.name.toLowerCase() === defaultStatType.name.toLowerCase()
    ) {
      // Look for this stat in abbreviations
      for (const abbrevKey in abbreviations) {
        if (
          abbrevKey.toLowerCase() === stat.name.toLowerCase() ||
          abbreviations[abbrevKey] === stat.abbreviation
        ) {
          return abbrevKey
        }
      }
      return stat.name.toLowerCase()
    }
  }

  // If no match is found, return the stat name
  return defaultStatType.name.toLowerCase()
}
