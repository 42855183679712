/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import cn from 'classnames'

import { font, heading5, weightSemiBold } from '../../../components/css'
import { Flex } from '../../../components/Layout'
import Picture from '../../../components/Picture'
import { SLOT_HEIGHT } from './constants'
import { Link } from 'react-router-dom'

const Slot = ({
  team,
  isTopScore,
  selected,
  teamStats = {},
  onClick = () => {},
  shouldDisplayScoreInsteadOfPoints = false,
  isMockPool = false,
  isSingleOrDoubleElimination = false,
  onClickTeamName = () => {},
}) => {
  const {
    id,
    team_id,
    seed,
    name_full,
    team_slot_type_id,
    score,
    isMockTeam,
    gameDidntEnd,
  } = team

  const imageId = getImageThumbnailId(team)
  const isSelected = selected?.id === team_id || selected?.id === id

  return (
    <Flex
      className={cn('slot', {
        highlighted: isSelected,
      })}
      alignItems='center'
      justifyContent='space-between'
      css={css`
        cursor: pointer;
        height: ${SLOT_HEIGHT}px;
        max-height: ${SLOT_HEIGHT}px;
        position: relative;
        padding-left: 8px;
        border-top: 1px solid ${colors.PALE_SKY};
        background-color: ${isSelected
          ? 'rgba(255, 255, 255, 0.1)'
          : colors.HEADER};
        // if this is isSingleOrDoubleElimination, add hover effect
        ${isSingleOrDoubleElimination
          ? `&:hover {
            background-color: ${colors.SECONDARY_200};
          }`
          : ''}
      `}
      onClick={onClick}
    >
      {team_slot_type_id !== 2 && (
        <Link to={onClickTeamName()}>
          <Picture
            square
            fit
            size='kxxsmall'
            iconName='user-friends'
            imageId={imageId}
            css={css`
              margin-right: 12px;
            `}
          />
        </Link>
      )}
      <Flex
        column
        justifyContent='center'
        css={css`
          margin-right: 12px;
        `}
      >
        <div
          css={css`
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 4px;
            /* ensure the only fits the necessary space */
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {name_full}
        </div>

        <div
          css={css`
            font-size: 12px;
            color: ${colors.NEUTRAL_50};
          `}
        >
          {teamStats?.scoreString || ''}
        </div>
      </Flex>

      {shouldDisplayScoreInsteadOfPoints ? (
        <Flex
          noFlex
          alignItems='center'
          justifyContent='center'
          className='slot-points'
          css={css`
            width: 40px;
            height: 100%;
            ${font.title}
            font-weight: 700;
            font-size: 24px;
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            background-color: ${isTopScore
              ? colors.DEFAULT_FLAIR
              : colors.SOFT_STEEL};
            ${heading5}
            ${weightSemiBold}
          `}
        >
          {isMockPool || gameDidntEnd ? '-' : score || '0'}
        </Flex>
      ) : (
        <Flex
          noFlex
          alignItems='center'
          justifyContent='center'
          className='slot-points'
          css={css`
            width: 40px;
            height: 100%;
            ${font.title}
            font-weight: 700;
            font-size: 24px;
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            background-color: ${isTopScore
              ? colors.PRIMARY
              : colors.SECONDARY_200};
            ${heading5}
            ${weightSemiBold}
          `}
        >
          {typeof teamStats?.points === 'string' ? teamStats.points : '-'}
        </Flex>
      )}

      {seed > 0 && (
        <Flex
          noFlex
          alignItems='center'
          justifyContent='center'
          className='slot-seed'
          css={css`
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 16px;
            height: 16px;
            z-index: 1;

            color: ${colors.LINK_WATER};
            background-color: #1a2332;
            font-size: 10px;
            font-weight: 500;
          `}
        >
          {seed}
        </Flex>
      )}
    </Flex>
  )
}

export default Slot
