/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import type { Action } from '../stepsController'
import colors from '@sportninja/common/constants/appColors'
// import {
//   paragraphLarge,
//   paragraphSmall,
//   weightBold,
//   weightRegular,
// } from 'src/components/css'
import {
  UserOutlined,
  ArrowRightOutlined,
  BankOutlined,
} from '@ant-design/icons'
import Text from 'src/components/ts-components/Text'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { isCanlan } from '@sportninja/common/utils/customer-name'

export interface StepProps {
  stepDispatcher: React.Dispatch<Action>
}

export const UserSelection = ({ stepDispatcher }: StepProps) => {
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const isTrial = searchParams.get('trial') === 'true'
    if (isCanlan) {
      return
    }
    if (isTrial) {
      stepDispatcher({ type: 'set', payload: 2 })
    }
  }, [location.search, stepDispatcher])

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 16px;
      `}
    >
      <Text variant='heading3' weight='semiBold' color={colors.NEUTRAL_0}>
        Choose Your Account Type
      </Text>
      <div
        onClick={() => stepDispatcher({ type: 'set', payload: 1 })}
        css={css`
          display: flex;
          padding: 16px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          border-radius: 8px;
          background: ${colors.SECONDARY_300};
          cursor: pointer;
        `}
      >
        {/* left icon */}
        <div
          css={css`
            display: flex;
            padding: 8px;
            align-items: center;
            gap: 8px;
            border-radius: 24px;
            background: rgba(27, 34, 43, 0.5);
          `}
        >
          <UserOutlined
            css={css`
              color: ${colors.PRIMARY};
              font-size: 20px;
            `}
          />
        </div>
        {/* Texts */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1 0 0;
          `}
        >
          <Text
            variant='paragraphMedium'
            weight='bold'
            color={colors.NEUTRAL_0}
          >
            Create a Personal Account
          </Text>
          <Text
            variant='paragraphSmall'
            weight='regular'
            color={colors.PRIMARY}
          >
            Get started as a player or member
          </Text>
        </div>
        {/* right icon   */}
        <ArrowRightOutlined
          css={css`
            color: ${colors.PRIMARY};
            font-size: 24px;
          `}
        />
      </div>
      <div
        onClick={() => stepDispatcher({ type: 'set', payload: 2 })}
        css={css`
          display: flex;
          padding: 16px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          border-radius: 8px;
          background: ${colors.SECONDARY_300};
          cursor: pointer;
        `}
      >
        {/* left icon */}
        <div
          css={css`
            display: flex;
            padding: 8px;
            align-items: center;
            gap: 8px;
            border-radius: 24px;
            background: rgba(27, 34, 43, 0.5);
          `}
        >
          <BankOutlined
            css={css`
              color: ${colors.PRIMARY};
              font-size: 20px;
            `}
          />
        </div>
        {/* Texts */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1 0 0;
          `}
        >
          <Text
            variant='paragraphMedium'
            weight='bold'
            color={colors.NEUTRAL_0}
          >
            Create a Business Account
          </Text>
          <Text
            variant='paragraphSmall'
            weight='regular'
            color={colors.PRIMARY}
          >
            Manage leagues effortlessly
          </Text>
        </div>
        {/* right icon   */}
        <ArrowRightOutlined
          css={css`
            color: ${colors.PRIMARY};
            font-size: 24px;
          `}
        />
      </div>
      {/* <div
        onClick={() => stepDispatcher({ type: 'set', payload: 1 })}
        css={css`
          display: flex;
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 56px;
          padding-right: 56px;
          justify-content: center;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          border-radius: 8px;
          border: 2px solid ${colors.PRIMARY};
          cursor: pointer;
          height: 90px;
          &:hover {
            background-color: ${colors.PRIMARY};
            #join {
              color: ${colors.HEADER};
            }
            #existing {
              color: ${colors.HEADER};
            }
            #divider-1 {
              border-color: ${colors.HEADER};
            }
          }
        `}
      >
        <p
          id='join'
          css={css`
            ${paragraphLarge}
            ${weightBold}
            color: ${colors.WHITE};
            text-align: right;
            width: 80px;
          `}
        >
          JOIN
        </p>
        <div
          id='divider-1'
          css={css`
            height: 50px;
            border: 1px solid ${colors.WHITE};
          `}
        />
        <p
          id='existing'
          css={css`
            ${paragraphSmall}
            ${weightRegular}
            color: ${colors.WHITE};
            width: 220px;
          `}
        >
          an EXISTING Organization,
          <br /> League or Team
        </p>
      </div>
      <div
        onClick={() => stepDispatcher({ type: 'set', payload: 2 })}
        css={css`
          display: flex;
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 56px;
          padding-right: 56px;
          justify-content: center;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          border-radius: 8px;
          border: 2px solid ${colors.PRIMARY};
          cursor: pointer;
          height: 90px;
          &:hover {
            background-color: ${colors.PRIMARY};
            #create {
              color: ${colors.HEADER};
            }
            #new-org {
              color: ${colors.HEADER};
            }
            #divider-2 {
              border-color: ${colors.HEADER};
            }
          }
        `}
      >
        <p
          id='create'
          css={css`
            ${paragraphLarge}
            ${weightBold}
            color: ${colors.WHITE};
            text-align: right;
            width: 80px;
          `}
        >
          CREATE
        </p>
        <div
          id='divider-2'
          css={css`
            height: 50px;
            border: 1px solid ${colors.WHITE};
          `}
        />
        <p
          id='new-org'
          css={css`
            ${paragraphSmall}
            ${weightRegular}
            color: ${colors.WHITE};
            width: 220px;
          `}
        >
          a NEW Organization
          <br /> or League
        </p>
      </div> */}
    </div>
  )
}
