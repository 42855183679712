/** @jsxImportSource @emotion/react */
import { useParams } from 'react-router-dom'
import ScheduleBracket from '../Schedule/ScheduleBracket'
import css from '@emotion/css/macro'

const PlayoffMobile = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <div
      css={css`
        height: 100vh;
        width: 100vw;
        padding-left: 16px;
      `}
    >
      <ScheduleBracket id={id} canUpdate={false} />
    </div>
  )
}

export default PlayoffMobile
