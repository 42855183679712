import { useState, useCallback } from 'react'
import SessionError from '../helpers/SessionError'
import req from '../api/request'

/**
 * Helper function to handle API errors
 * @param {Error} error - The caught error
 * @throws {SessionError} - Throws a formatted session error
 */
const handleApiError = (error: any) => {
  if (error.response) {
    throw new SessionError(
      error.response.data.message || 'An error occurred',
      error.response.status
    )
  }
  throw new SessionError('Network error', 'NETWORK_ERROR')
}

/**
 * Enum for registration steps
 */
export enum RegistrationSteps {
  CREATED = 'created',
  SELECT_YOUR_DIVISION = 'select_division',
  SELECT_YOUR_ROLE = 'registration_type',
  SIGN_UP = 'sign_up',
  TEAM_REGISTER = 'team_register',
  REGISTRATION_PAYMENT = 'payment',
  VALIDATE_EMAIL = 'validate_email',
  CONFIRM = 'confirm',
  WAIVER = 'waiver',
}

/**
 * Interface for player information
 */
export interface PlayerInfo {
  firstName: string
  lastName: string
  birthDate: string
  email: string
}

/**
 * Interface for address information
 */
export interface AddressInfo {
  address: string
  city: string
  country: string
  zipCode: string
  stateProvince: string
}

export interface TeamRegistrationParams {
  coupon_code_id: string
  team_official_id: string
  team_name: string
  team_id: string
  postal_code: string
  country_code: string
  team_official_array: Array<any>
  user_id: string
  currency_id: string
  player_array: Array<any>
  province: string
  team_player_roster_id?: string
}

export interface PlayerRegistrationParams {
  team_id: string
  team_name: string
  user_id: string
  province?: string
  coupon_code_id?: string
  team_player_roster_id?: string
}

// Types definitions for better type safety
interface SessionState {
  session: {
    id: string
    registration_step: string
    registration_type_id: number
    root_schedule_id: string
    root_schedule_name: string
    division_id: string | null
    division_name: string | null
    team_id: string | null
    team_name: string | null
    player_id: string | null
    player_array: any[] | null
    team_official_id: string | null
    team_official_array: any[] | null
    bearer_token: string | null
    user: any | null
    coupon_code: string | null
    currency_id: string | null
    payment_type: string | null
    discount: number | null
    subtotal: number | null
    tax: number | null
    total: number | null
    paid: boolean
    postal_code: string | null
    country_code: string
    created_at: string
    updated_at: string
    client_secret: string
    connected_account_id: string
    billing_schedule: any[]
  }
  registration: {
    id: string
    name: string
    abbreviation: string
    image: string | null
    type: string
    sport_id: string
    registration: {
      id: string
      name: string
      schedule_id: string
      registration_type_id: number
      root_schedule_id: string
      root_schedule: {
        id: string
        name: string
        name_full: string
        type: string
        abbreviation: string
        starts_at: string
        ends_at: string
        is_tournament: boolean
        tournament_status: number
        tournament_can_sync_regular_season: boolean
        owned_by_account_id: string
        is_public: boolean
        is_registration_on: boolean
        sport_id: string
        organization_id: string
        image: string | null
        [key: string]: any
      }
      currency: {
        id: string
        name: string
        name_full: string | null
        abbreviation: string | null
      }
      divisions: Array<{
        id: string
        name: string
        schedule_id: string
        currency: {
          id: string
          name: string
          name_full: string | null
          abbreviation: string | null
        }
        division_gender: {
          id: string
          name: string
          name_full: string | null
          abbreviation: string | null
        }
        division_age: {
          id: string
          name: string
          name_full: string | null
          abbreviation: string | null
        }
        team_cost: number
        deposit: number
        [key: string]: any
      }>
      [key: string]: any
    }
    divisions: Array<{
      id: string
      name: string
      schedule_id: string
      currency: {
        id: string
        name: string
        name_full: string | null
        abbreviation: string | null
      }
      division_gender: {
        id: string
        name: string
        name_full: string | null
        abbreviation: string | null
      }
      division_age: {
        id: string
        name: string
        name_full: string | null
        abbreviation: string | null
      }
      team_cost: number
      deposit: number
      [key: string]: any
    }>
  }
  billing: any | null
  step: number
  division: {
    id: string
    name: string
    schedule_id: string
    currency: {
      id: string
      name: string
      name_full: string | null
      abbreviation: string | null
    }
    division_gender: {
      id: string
      name: string
      name_full: string | null
      abbreviation: string | null
    }
    division_age: {
      id: string
      name: string
      name_full: string | null
      abbreviation: string | null
    }
    team_cost: number
    deposit: number
    [key: string]: any
  } | null
  selectedTeam: {
    id: string
    name: string
    image: string | null
  } | null
}

interface CouponInfo {
  discount: number
  type: string
  id: string
  code: string
  subtotal: number
  discount_amount: number
  max_uses: number
}

// Type Definitions
// Role
export interface Role {
  id: string
  name: 'Player' | 'Team' | null
}

interface UseSessionManagementProps {
  teamOfficialType: any
  role: any
  currency: any
  selectedPlayer: any
  playerInfoState: any
  addressState: any
  parentInfoState: any
  emergencyInfoState: any
  preferencesState: any
  setZipCode: (zipCode: string) => void
  setCountry: (country: string) => void
  setAdditionalTeamOfficials: (additionalTeamOfficials: any[]) => void
  sessionState: any
  setSessionState: (sessionState: any) => void
}

/**
 * Custom hook for managing registration session state and operations
 * @param {Object} params - Hook parameters
 * @param {Object} params.initialState - Initial session state
 * @param {Object} params.teamOfficialType - Team official type
 * @param {Object} params.role - Role object
 * @param {Object} params.currency - Currency object
 * @param {Object} params.selectedPlayer - Selected player information
 * @param {Object} params.playerInfoState - Player info state
 * @param {Object} params.addressState - Address state
 * @param {Object} params.parentInfoState - Parent info state
 * @param {Object} params.emergencyInfoState - Emergency info state
 * @param {Object} params.preferencesState - Preferences state
 * @param {Function} params.setZipCode - Function to set zip code
 * @param {Function} params.setCountry - Function to set country
 * @param {Function} params.setAdditionalTeamOfficials - Function to set additional team officials
 */
const useSessionManagement = ({
  teamOfficialType,
  role,
  currency,
  selectedPlayer,
  playerInfoState,
  addressState,
  parentInfoState,
  emergencyInfoState,
  preferencesState,
  setZipCode,
  setCountry,
  setAdditionalTeamOfficials,
  sessionState,
  setSessionState,
}: UseSessionManagementProps) => {
  const [couponInfo, setCouponInfo] = useState<CouponInfo | null>(null)

  /**
   * Generic session update helper to reduce code duplication
   * @param {string} step - Registration step
   * @param {Object} additionalData - Additional data to include in the update
   */
  const updateSession = useCallback(
    async (step: string, additionalData = {}) => {
      try {
        const { data } = await req(
          `/registration/session/${sessionState?.session?.id}`,
          {
            method: 'PUT',
            body: JSON.stringify({
              registration_step: step,
              ...additionalData,
            }),
          }
        )

        setSessionState((prev: SessionState) => {
          return {
            ...prev,
            session: data,
          }
        })
        return data
      } catch (error) {
        console.error(`Error updating session for step ${step}:`, error)
        throw error
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessionState]
  )

  /**
   * Helper function to build player data object
   * @param selectedPlayer - Selected player information
   * @param playerInfoState - Player info state
   * @param addressState - Address state
   * @param parentInfoState - Parent info state
   * @param emergencyInfoState - Emergency info state
   * @param preferencesState - Preferences state
   * @returns Formatted player data object
   */
  const buildPlayerData = useCallback(
    (
      localSelectedPlayer,
      localPlayerInfoState,
      localAddressState,
      localParentInfoState,
      localEmergencyInfoState,
      localPreferencesState,
      province
    ) => ({
      name_first:
        localSelectedPlayer?.id === 'new'
          ? localPlayerInfoState?.firstName
          : localSelectedPlayer?.name_first ||
            localPlayerInfoState?.firstName ||
            '',
      name_last:
        localSelectedPlayer?.id === 'new'
          ? playerInfoState?.lastName || ''
          : localSelectedPlayer?.name_last ||
            localPlayerInfoState?.lastName ||
            '',
      birth_date: localPlayerInfoState?.birthDate || '',
      address: localAddressState?.address || '',
      email: localPlayerInfoState?.email || '',
      city: localAddressState?.city || '',
      country: localAddressState?.country || '',
      postal_code: localAddressState?.zipCode || '',
      parent_first_name: localParentInfoState?.firstName || '',
      parent_last_name: localParentInfoState?.lastName || '',
      parent_email: localParentInfoState?.email || '',
      parent_phone_number: localParentInfoState?.phone || '',
      emergency_first_name: localEmergencyInfoState?.firstName || '',
      emergency_last_name: localEmergencyInfoState?.lastName || '',
      emergency_email: localEmergencyInfoState?.email || '',
      emergency_relationship: localEmergencyInfoState?.relationship || '',
      emergency_phone_number: localEmergencyInfoState?.phone || '',
      doctor_name: localEmergencyInfoState?.doctorName || '',
      doctor_phone_number: localEmergencyInfoState?.doctorPhone || '',
      known_allergies: localEmergencyInfoState?.allergies || '',
      // preferred_position: localPreferencesState?.preferredPosition || '',
      player_type_id: localPreferencesState?.preferredPosition || '',
      preferred_jersey_number: localPreferencesState?.jerseyNumber || '',
      preferred_dominant_side: localPreferencesState?.dominantSide || '',
      preferred_jersey_size: localPreferencesState?.jerseySize || '',
      preferred_tshirt_size: localPreferencesState?.tshirtSize || '',
      preferred_short_size: localPreferencesState?.shortSize || '',
      medical_conditions: localEmergencyInfoState?.medicalConditions || '',
      additional_comments_notes: localPreferencesState?.notes || '',
      hockey_usa_number: localPreferencesState?.hockeyUSANumber || '',
      hockey_canada_number: localPreferencesState?.hockeyCanadaNumber || '',
      custom_membership_number:
        localPreferencesState?.otherMembershipNumber || '',
      province: province || '',
      new_player: localSelectedPlayer?.id === 'new' ? true : false,
      existing_player_id:
        localSelectedPlayer?.id !== 'new' ? localSelectedPlayer?.id || '' : '',
      notes: localPreferencesState?.notes || '',
      preferred_governing_body_number_1:
        localPreferencesState?.otherMembershipNumber || '',
    }),
    [playerInfoState]
  )

  /**
   * Updates the session role for a player or team official
   * @param selectedRole - The role object containing role information
   * @param selectedPlayerId - The ID of the selected player
   */
  const updateSessionRole = useCallback(
    async (selectedRole: Role, selectedPlayerId: string, userId?: string) => {
      const isTeamOfficial = selectedRole?.id === teamOfficialType?.id

      await updateSession(RegistrationSteps.SELECT_YOUR_ROLE, {
        registration_type_id: selectedRole?.id,
        player_id: isTeamOfficial ? null : selectedPlayerId,
        team_official_id: isTeamOfficial ? selectedPlayerId : null,
        user_id: userId,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateSession, teamOfficialType, sessionState]
  )

  /**
   * Validates a coupon code for a specific division
   * @param code - The coupon code to validate
   * @param divisionId - The ID of the division
   */
  const validateCoupon = useCallback(
    async (code: string, divisionId: string) => {
      try {
        const res = await req(`/registration/${divisionId}/coupon/verify`, {
          method: 'POST',
          body: JSON.stringify({ code }),
        })

        if (!res?.data) {
          throw new SessionError('Invalid coupon response', 'INVALID_RESPONSE')
        }

        setCouponInfo({
          discount: res.data.discount,
          type: res.data.discount_type,
          id: res.data.id,
          code: res.data.code,
          subtotal: res.data.subtotal,
          discount_amount: res.data.discount_amount,
          max_uses: res.data.max_uses,
        })
      } catch (error) {
        handleApiError(error)
      }
    },
    [setCouponInfo]
  )

  /**
   * Updates the session division
   * @param divisionId - The ID of the selected division
   */
  const updateSessionDivision = useCallback(
    async (divisionId: string, userId?: string) => {
      await updateSession(RegistrationSteps.SELECT_YOUR_DIVISION, {
        division_id: divisionId,
        user_id: userId,
      })
    },
    [updateSession]
  )

  /**
   * Updates the session with team registration information
   * @param params - Team registration parameters
   */
  const updateSessionTeamRegister = useCallback(
    async ({
      coupon_code_id,
      team_official_id,
      team_name,
      team_id,
      postal_code,
      country_code,
      team_official_array,
      user_id,
      currency_id,
      player_array,
      province,
      team_player_roster_id,
    }: TeamRegistrationParams) => {
      await updateSession(RegistrationSteps.TEAM_REGISTER, {
        coupon_code_id,
        team_official_id,
        team_name,
        team_id,
        postal_code,
        country_code,
        team_official_array,
        user_id,
        currency_id,
        player_array,
        registration_type_id: role?.id,
        province,
        team_player_roster_id: team_player_roster_id || '',
      })
    },
    [updateSession, role]
  )

  /**
   * Updates the session with player registration information
   * @param params - Player registration parameters
   */
  const updateSessionPlayerRegister = useCallback(
    async ({
      team_id,
      team_name,
      user_id,
      province,
      coupon_code_id,
      team_player_roster_id,
    }: PlayerRegistrationParams) => {
      const playerData = buildPlayerData(
        selectedPlayer,
        playerInfoState,
        addressState,
        parentInfoState,
        emergencyInfoState,
        preferencesState,
        province
      )

      // Ensure all player data properties have at least empty string values
      const sanitizedPlayerData = Object.keys(playerData).reduce((acc, key) => {
        const value = playerData[key]
        // Handle special case for new_player boolean
        if (key === 'new_player') {
          acc[key] = value ?? true
        } else {
          acc[key] = value ?? ''
        }
        return acc
      }, {} as typeof playerData)

      await updateSession(RegistrationSteps.TEAM_REGISTER, {
        currency_id: currency?.id || 1,
        province_id: addressState?.stateProvince || 1,
        country_code: addressState?.country || 1,
        postal_code: addressState?.zipCode || '',
        address: addressState?.address || '',
        team_id,
        team_name: team_name || '',
        player_array: [sanitizedPlayerData],
        user_id,
        registration_type_id: role?.id,
        coupon_code_id,
        team_player_roster_id: team_player_roster_id || '',
      })
    },
    [
      addressState,
      buildPlayerData,
      currency?.id,
      emergencyInfoState,
      parentInfoState,
      playerInfoState,
      preferencesState,
      role?.id,
      selectedPlayer,
      updateSession,
    ]
  )

  /**
   * Updates the session with sign-up information
   * @param token - Bearer token
   * @param divisionId - Division ID
   * @param newUserId - New user ID
   */
  const updateSessionSignUp = useCallback(
    async (token: string, divisionId: string, newUserId: string) => {
      await updateSession(RegistrationSteps.SELECT_YOUR_DIVISION, {
        bearer_token: token,
        division_id: divisionId,
        user_id: newUserId,
      })
    },
    [updateSession]
  )

  /**
   * Updates the session to waiver step
   */
  const updateSessionWaiver = useCallback(
    async (signed: boolean) => {
      await updateSession(RegistrationSteps.WAIVER, {
        signed_waiver: signed,
      })
    },
    [updateSession]
  )

  /**
   * Updates the session to payment step
   */
  const updateSessionPayment = useCallback(async () => {
    await updateSession(RegistrationSteps.REGISTRATION_PAYMENT)
  }, [updateSession])

  /**
   * Updates the session to email verification step
   */
  const updateSessionVerifyEmail = useCallback(async () => {
    await updateSession(RegistrationSteps.VALIDATE_EMAIL)
  }, [updateSession])

  /**
   * Updates the session to confirm submission step
   */
  const updateSessionConfirmSubmission = useCallback(async () => {
    await updateSession(RegistrationSteps.CONFIRM)
  }, [updateSession])

  /**
   * Deletes the current session
   */
  const deleteSession = useCallback(async () => {
    try {
      localStorage.removeItem(
        `registration_session_${sessionState?.registration?.id}`
      )

      setSessionState((prev) => {
        const newState: SessionState = {
          ...prev,
          session: null as any,
          registration: null as any,
          billing: null as any,
          step: 0,
          division: null as any,
          selectedTeam: null as any,
        }
        return newState
      })

      setZipCode('')
      setCountry('')
      setAdditionalTeamOfficials([])

      await req(`/registration/session/${sessionState?.session?.id}`, {
        method: 'DELETE',
      })
    } catch (error) {
      handleApiError(error)
    }
  }, [
    sessionState,
    setSessionState,
    setZipCode,
    setCountry,
    setAdditionalTeamOfficials,
  ])

  /**
   * Resets the current session
   */
  const resetSession = useCallback(async () => {
    try {
      // Removend under the SN-5500 ticket
      // await req(`/registration/session/${sessionState?.session?.id}/reset`, {
      //   method: 'PUT',
      // })
      await req(`/registration/session/${sessionState?.session?.id}`, {
        method: 'DELETE',
      })
    } catch (error) {
      handleApiError(error)
    }
  }, [sessionState])

  return {
    sessionState,
    couponInfo,
    updateSession,
    setCouponInfo,
    updateSessionRole,
    updateSessionDivision,
    updateSessionTeamRegister,
    updateSessionPlayerRegister,
    updateSessionSignUp,
    updateSessionWaiver,
    updateSessionPayment,
    updateSessionVerifyEmail,
    updateSessionConfirmSubmission,
    deleteSession,
    resetSession,
    validateCoupon,
  }
}

export default useSessionManagement
