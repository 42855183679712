/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import usersActions from '@sportninja/common/actions/users'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import req from '@sportninja/common/api/request'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { getSelfUser } from '@sportninja/common/selectors/users'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'

import { font } from '../../components/css'
import { selfForm } from '../../components/Form/form-configs'
import FormSheet from '../../components/Form/FormSheet'
import Icon from '../../components/Icon'
import { Flex } from '../../components/Layout'
import Bio from '../../components/PageTitle/Bio'
import Picture from '../../components/Picture'
import { useGetSuspensionsByPlayer } from '../../components/Suspensions/useGetSuspensions'

import { media, Mobile } from '../../components/Responsive'
import Suspensions from '../../components/Suspensions'
import {
  ProfileMenu,
  ProfileName,
  ProfileTop,
  ProfileWrap,
} from './ProfileComponents'
import ProfileMembership from './ProfileMembership'
import StatsBySeason from './v2/StatsBySeason'
import useWindowSize from 'src/hooks/useWindowSize'

const onEditError = (_, error) => {
  if (error.invalid_fields) {
    const errors = {}
    if (error.invalid_fields.height) {
      errors.height = t('errors:heightMustBeIn')
    }
    if (error.invalid_fields.weight) {
      errors.weight = t('errors:weightMustBeIn')
    }
    return errors
  }
}

const EditButton = ({ onClick }) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < 768, [width])
  return (
    <button
      type='button'
      onClick={onClick}
      css={css`
        ${font.body}
        font-size: 16px;
        position: absolute;
        right: 0;
        color: ${colors.DEFAULT_FLAIR};

        transition: filter 0.2s ease-in-out;

        &:hover {
          color: white;

          i {
            color: white;
          }
        }

        ${isMobile &&
        `
          width: 34px;
          top: 90px;
          left: 28px;
          right: 0;
        `}
      `}
    >
      <Icon
        name='pencil'
        fontSize={isMobile ? 24 : 14}
        color={colors.DEFAULT_FLAIR}
        css={css`
          margin-right: 11px;
        `}
      />
      {isMobile ? '' : 'Edit Profile'}
    </button>
  )
}

const StatsSeasonComponent = ({ playerIds, setShowStats }) => (
  <StatsBySeason playerIds={playerIds} setShowStats={setShowStats} />
)

const EditButtonComponent = (props) => <EditButton {...props} />

const Profile = ({ selfUser, updateUser, isInFrame }) => {
  const [item, setItem] = useState({})
  const [selfPlayerIds, setSelfPlayerIds] = useState([])
  const [loadedPlayers, setLoadedPlayers] = useState(false)
  const [showStats, setShowStats] = useState(true)

  const readItem = async () => {
    req('/users/me').then((response) => {
      setItem(response.data)
    })
  }

  const { loading, suspensions } = useGetSuspensionsByPlayer()

  useEffect(() => {
    readItem()

    req(`/users/${selfUser?.id}/players`).then((response) => {
      if (response.data.length > 0) {
        setSelfPlayerIds(response.data.map((p) => p.id))
        setLoadedPlayers(true)
        setShowStats(true)
      } else {
        setShowStats(false)
        setLoadedPlayers(true)
      }
    })
  }, [selfUser?.id])

  const pages = useMemo(
    () =>
      [
        showStats && {
          text: t('Web:statistics'),
          to: ROUTES.PROFILE.ROOT,
          component: (
            <StatsSeasonComponent
              playerIds={selfPlayerIds}
              setShowStats={setShowStats}
            />
          ),
        },
        {
          text: 'Membership',
          to: ROUTES.PROFILE.MEMBERSHIP,
          component: <ProfileMembership />,
        },
        {
          text: 'Suspensions',
          to: ROUTES.PROFILE.SUSPENSIONS,
          component: (
            <Suspensions
              isProfileView
              loading={loading}
              suspensions={suspensions}
            />
          ),
        },
      ].filter((p) => typeof p !== 'undefined' && typeof p !== 'boolean'),
    [showStats, selfPlayerIds, loading, suspensions]
  )

  const BASE_ROUTE = ROUTES.PROFILE.ROOT

  return (
    <ProfileWrap baseRoute={BASE_ROUTE} pages={pages} loaded={loadedPlayers}>
      <ProfileTop>
        <Flex
          css={css`
            position: relative;
          `}
        >
          <Mobile>
            {(isMobile) => {
              return (
                <>
                  <Picture
                    size={isMobile ? 'medium' : 'xlarge'}
                    imageId={getImageThumbnailId(item)}
                  />

                  <FormSheet
                    key='edit'
                    form={selfForm(selfUser, true)}
                    label={t('Web:editProfile')}
                    onError={onEditError}
                    onSubmit={async (...args) => {
                      await updateUser(selfUser.id, ...args)
                      await readItem()
                      return true
                    }}
                    title={t('Web:editProfile')}
                    Button={EditButtonComponent}
                  />
                </>
              )
            }}
          </Mobile>
          <Flex
            column
            justifyContent='center'
            css={css`
              margin-left: 16px;

              ${media.mobile} {
                margin-top: 0px;
              }
            `}
          >
            <ProfileName item={item} />
            <Bio
              birthDate={item.birth_date}
              height={item.height}
              weight={item.weight}
            />

            {suspensions &&
              suspensions
                .filter((s) => s.is_active)
                .map((suspension) => (
                  <Flex
                    key={suspension.id}
                    noFlex
                    alignItems='center'
                    css={css`
                      background-color: ${colors.FIERY_RED};
                      border-radius: 8px;
                      padding: 6px 12px;
                      margin-top: 16px;
                    `}
                  >
                    <Icon
                      name='exclamation-circle'
                      fontSize={18}
                      css={css`
                        margin-right: 8px;
                      `}
                    />
                    {suspension?.end_date ? (
                      <>
                        {' '}
                        You&apos;re Suspended Until{' '}
                        {dayjs(suspension.end_date)
                          .utc()
                          .format('MMM D, YYYY')}{' '}
                        ({suspension.description})
                      </>
                    ) : (
                      <>
                        You&apos;re Suspended Indefinitely (
                        {suspension.description})
                      </>
                    )}
                  </Flex>
                ))}
          </Flex>
        </Flex>
      </ProfileTop>
      <ProfileMenu baseRoute={BASE_ROUTE} pages={pages} isInFrame={isInFrame} />
    </ProfileWrap>
  )
}

const mapStateToProps = () => {
  return (state) => {
    return {
      selfUser: getSelfUser(state),
      isInFrame: state.auth.inFrame,
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: bindActionToPromise(dispatch, usersActions.update.request),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
